import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { BuildingCategoryEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IBuildingCategoryConstructor extends ICodeListConstructor {
    isBridge?: boolean;
}

export class BuildingCategory extends CodeList {
    public isBridge?: boolean;

    constructor(ctor: IBuildingCategoryConstructor) {
        super(ctor);

        if (ctor != null) {
            this.isBridge = ctor.isBridge;
        }
    }

    public static fromService(codeList: BuildingCategoryEntity) {
        return new BuildingCategory(CodeList.baseFromService<IBuildingCategoryConstructor>('BuildingCategoryEntity', codeList, {
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-handrail-' + codeList.Image : 'sprite-handrail-no-photo-available',
            isBridge: codeList.IsBridge != null ? codeList.IsBridge : false
        } as IBuildingCategoryConstructor));
    }

    public override cloneDeep(): BuildingCategory {
        const codeList: BuildingCategory = super.cloneDeep() as BuildingCategory;

        codeList.isBridge = this.isBridge;

        return codeList;
    }
}
