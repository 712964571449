import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';

import { DesignPe } from '../../../shared/entities/design-pe';
import {
    CalculationMethodState
} from '../../../shared/generated-modules/Hilti.PE.Core.Common.Enums';
import {
    AdvancedCalculationType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';

interface IDefaultCalculationMethodComponentInput {
    design: DesignPe; // Design might not be present in user-service on this popup init, so we have to pass it here.
    calculationType: AdvancedCalculationType;
}

@Component({
    templateUrl: './default-calculation-method.component.html',
    styleUrls: ['./default-calculation-method.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DefaultCalculationMethodComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IDefaultCalculationMethodComponentInput>;
    public pendingSave = false;
    public applyToAllFeatures!: CheckboxButtonProps<boolean>;

    constructor(
        public localization: LocalizationService,
        private userSettings: UserSettingsService,
        private calculationService: CalculationServicePE,
    ) { }

    public get titleKey() {
        if (this.calculationType == AdvancedCalculationType.BPRigidityCheck) {
            return 'Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.BPRigidityCheck.Title';
        }

        return 'Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.Title';
    }

    public get messageKey() {
        if (this.calculationType == AdvancedCalculationType.BPRigidityCheck) {
            return `Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.BPRigidityCheck.Message.${this.design?.designStandard.displayKey}`;
        }

        return 'Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.Message';
    }

    public get infoKey() {
        if (this.calculationType == AdvancedCalculationType.BPRigidityCheck) {
            return 'Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.BPRigidityCheck.Info';
        }

        return undefined;
    }

    public get infoUrl() {
        if (this.calculationType == AdvancedCalculationType.BPRigidityCheck) {
            return this.design?.region.bpRigidityCheckUrl;
        }

        return undefined;
    }

    private get calculationType() {
        return this.modalInstance.input?.calculationType;
    }

    private get design() {
        return this.modalInstance.input?.design;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing((result) => {
            return result == 'saved';
        });

        this.applyToAllFeatures = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemId: 'apply-to-all-features',
            itemText: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.ApplyToAll')
        });
    }

    public no() {
        this.setDefaultCalculationMethod(CalculationMethodState.WithoutAdvancedBaseplate);
    }

    public async yes() {
        if (this.design != null) {
            // Update model and run calculation
            await this.calculationService.calculateAsync(
                this.design,
                (design) => {
                    design.calculationType = this.calculationType ?? AdvancedCalculationType.Unknown;
                }
            );

            // Update settings
            this.setDefaultCalculationMethod(CalculationMethodState.WithAdvancedBaseplate);
        }
    }

    public setDefaultCalculationMethod(value: CalculationMethodState) {
        if (this.pendingSave) {
            return;
        }

        this.pendingSave = true;

        this.userSettings.settings.application.advancedCalculation.calculationMethodState.value =
            SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.applyToAllFeatures)
                ? value
                : CalculationMethodState.AlwaysAskMe;

        this.userSettings.save()
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.modalInstance.close('saved');
            });
    }

    public translate(key?: string) {
        return key ? this.localization.getString(key) : null;
    }
}
