<pe-modal-header
    [title]="translate('Agito.Hilti.Profis3.DlubalExport.Title')"
    [modalInstance]="modalInstance"
    [closeButtonDisabled]="submitted || preventClosing || lockedControls">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="export()" novalidate>
    <pe-modal-body>
        <div class="title-button">
            <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalExport.ExportDataTitle')"></p>
            <pe-button
                class="modal-button"
                [look]="'Default'"
                (buttonClick)="refresh()"
                [text]="translate('Agito.Hilti.Profis3.DlubalExport.Refresh')"
                [disabled]="lockedControls || submitted">
            </pe-button>
        </div>

        <div class="block">
            <pe-checkbox-button
                [items]="exportCheckbox.items"
                [selectedValues]="exportCheckbox.selectedValues"
                (selectedValuesChange)="exportCheckbox.selectedValues = $any($event).detail"
                [disabled]="lockedControls">
            </pe-checkbox-button>
        </div>

        <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalExport.NodesToApplyTitle')"></p>
        <div class="block">
            <pe-radio-button
                [items]="sourceFileRadio.items"
                [selectedValue]="sourceFileRadio.selectedValue"
                (selectedValueChange)="sourceFileRadio.selectedValue = $any($event).detail; sourceFileRadioSelectedValueChange()"
                [disabled]="lockedControls">
            </pe-radio-button>
        </div>

        <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.Nodes')"></p>
        <div class="box grow">
            <pe-checkbox-button
                [items]="nodesCheckbox.items"
                [selectedValues]="nodesCheckbox.selectedValues"
                (selectedValuesChange)="nodesCheckbox.selectedValues = $any($event).detail"
                [disabled]="lockedControls">
            </pe-checkbox-button>
        </div>

        <div class="info-block">
            <pe-button
                class="modal-button"
                [look]="'Default'"
                (buttonClick)="manualSelection()"
                [text]="lockedControls ? translate('Agito.Hilti.Profis3.DlubalImport.SelectionFinished') : translate('Agito.Hilti.Profis3.DlubalImport.ManualSelection')"
                [disabled]="(!rstabLiveInstance && !rfemLiveInstance) || submitted"
                width="250px">
            </pe-button>

            <div [hidden]="!nodeErrorVisible">
                <div class="info" [innerHtml]="nodeErrorText"></div>
                <div class="info" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.NodesInfo')"></div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <div [ngbTooltip]="tooltip" #manualSelectionTooltip="ngbTooltip" [autoClose]="false" triggers="manual">
            <pe-button
                type="submit"
                class="modal-button"
                [look]="'Cta'"
                [disabled]="(ngForm.enabled && !ngForm.valid) || !validSourceSelected || lockedControls || submitted">
                <pe-loading-button
                    [loading]="submitted"
                    [text]="translate('Agito.Hilti.Profis3.DlubalExport.Export')">
                </pe-loading-button>
            </pe-button>
        </div>
    </pe-modal-footer>
</form>
