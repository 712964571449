<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.FatigueLoadsInfo.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="fatigue-loads-info-container">
        <div>
            <div class="disclaimer">
                <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.FatigueLoadCombinations.Disclaimer.Part1')"></p>
            </div>
            <img [src]="imagePart1" alt="first image" />
        </div>

        <div>
            <div class="disclaimer">
                <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.FatigueLoadCombinations.Disclaimer.Part2')"></p>
            </div>
            <img [src]="imagePart2" alt="second image" />
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        class="modal-button"
        [look]="'Default'"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.Cancel')">
    </pe-button>

    <pe-button
        class="modal-button"
        [look]="'Cta'"
        (buttonClick)="openDetailedCalculationOutput()"
        [text]="translate('Agito.Hilti.Profis3.FatigueLoadCombinations.ShowDetailedCalculationsOutput')"
        [disabled]="isDetailedOutputDisabled">
    </pe-button>
</pe-modal-footer>
