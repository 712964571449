import {
    MetalDeckEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';

import { CodeList } from '../code-lists/code-list';

export class MetalDeck extends CodeList {

    public static fromService(codeList: MetalDeckEntity) {
        return new MetalDeck(CodeList.baseFromService('MetalDeckEntity', codeList));
    }

    public override cloneDeep(): MetalDeck {
        return super.cloneDeep() as MetalDeck;
    }
}
