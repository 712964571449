<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.SelectMaterial.Title')"
    [closeButtonId]="'select-material-close-button'"
    [closeButtonDisabled]="pending != null">
</pe-modal-header>

<pe-modal-body>
    <div class="no-padding">
        <div class="steel-guideline-type">
            <div class="steel-guideline-type-control">
                <span class="data-title" [innerHtml]="translate('Agito.Hilti.Profis3.SelectMaterial.SteelGuidelineType')"></span>

                <pe-dropdown
                    class="steel-guideline-type-dropdown"
                    [id]="steelGuidelineTypeDropdown.id"
                    [items]="steelGuidelineTypeDropdown.items"
                    [selectedValue]="steelGuidelineTypeDropdown.selectedValue"
                    (selectedValueChange)="steelGuidelineTypeDropdown.selectedValue = $any($event).detail; steelGuidelineTypeChanged()"
                    [validators]="[requiredValidator]">
                </pe-dropdown>
            </div>
            <span class="local-steel-material-disclaimer" [innerHtml]="localSteelMaterialDisclaimer"></span>
        </div>

        <div class="popup-table">
            <div class="popup-custom-material" *ngIf="customMaterialSelected">
                <div class="popup-custom-material-controls">
                    <div class="popup-custom-material-control">
                        <pe-text-box
                            [id]="nameTextBox.id"
                            [title]="nameTextBox.title"
                            [value]="nameTextBox.value"
                            (inputBlur)="customMaterialNameChanged($any($event).detail)"
                            [maxLength]="nameTextBox.maxLength"
                            [updateOnBlur]="true"
                            [marginBottom]="'None'">
                        </pe-text-box>
                    </div>

                    <div class="popup-custom-material-control">
                        <pe-numeric-text-box
                            [id]="fyTextBox.id"
                            [title]="fyTextBox.title"
                            [value]="fyTextBox.value"
                            (valueChange)="fyTextBox.value = $any($event).detail"
                            [unit]="fyTextBox.unit"
                            [minValue]="fyTextBox.minValue"
                            [maxValue]="fyTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true">
                        </pe-numeric-text-box>
                    </div>

                    <div class="popup-custom-material-control">
                        <pe-numeric-text-box
                            [id]="fuTextBox.id"
                            [title]="fuTextBox.title"
                            [value]="fuTextBox.value"
                            (valueChange)="fuTextBox.value = $any($event).detail"
                            [unit]="fuTextBox.unit"
                            [minValue]="fuTextBox.minValue"
                            [maxValue]="fuTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true">
                        </pe-numeric-text-box>
                    </div>

                    <div class="popup-custom-material-control">
                        <pe-numeric-text-box
                            [id]="esTextBox.id"
                            [title]="esTextBox.title"
                            [value]="esTextBox.value"
                            (valueChange)="esTextBox.value = $any($event).detail"
                            [unit]="esTextBox.unit"
                            [minValue]="esTextBox.minValue"
                            [maxValue]="esTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true">
                        </pe-numeric-text-box>
                    </div>

                    <div class="popup-custom-material-control">
                        <pe-numeric-text-box
                            [id]="densityTextBox.id"
                            [title]="densityTextBox.title"
                            [value]="densityTextBox.value"
                            (valueChange)="densityTextBox.value = $any($event).detail"
                            [unit]="densityTextBox.unit"
                            [minValue]="densityTextBox.minValue"
                            [maxValue]="densityTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true">
                        </pe-numeric-text-box>
                    </div>

                    <div class="popup-custom-material-control">
                        <pe-numeric-text-box
                            [id]="poissonTextBox.id"
                            [title]="poissonTextBox.title"
                            [value]="poissonTextBox.value"
                            (valueChange)="poissonTextBox.value = $any($event).detail"
                            [unit]="poissonTextBox.unit"
                            [minValue]="poissonTextBox.minValue"
                            [maxValue]="poissonTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>

            <div class="table-header" *ngIf="!customMaterialSelected">
                <div class="table-row">
                    <div class="table-cell name-cell" [innerHtml]="translate('Agito.Hilti.Profis3.SelectMaterial.Name')"></div>
                    <div class="table-cell type-cell" [innerHtml]="translate('Agito.Hilti.Profis3.SelectMaterial.Type')"></div>
                    <div class="favorite-cell"></div>
                </div>
            </div>

            <div class="table-body">
                <div *ngFor="let item of sortedItems"
                    id="popup-{{item.id}}-button"
                    class="table-row modal-grid-button"
                    [class.selected]="userSelectedValue == item.id"
                    [class.disabled]="isDisabled(item) || pending != null"
                    (click)="select(item)">
                    <div class="table-cell name-cell" [innerHtml]="item.name"></div>

                    <div class="table-cell type-cell" [innerHtml]="formatType(item)"></div>

                    <div class="favorite-cell">
                        <button class="favorite-button" *ngIf="item.id != null"
                            (click)="$event.preventDefault(); $event.stopPropagation(); favoriteToggle(item.id)"
                            type="button"
                            [ngbTooltip]="favoriteTooltip">
                            <span class="favorite-image pe-ui-pe-sprite" [ngClass]="getFavoriteSprite(item.id)"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        *ngIf="isApplyToAllVisible"
        [id]="'select-material-apply-to-all-button'"
        [look]="'Default'"
        class="modal-button"
        (buttonClick)="applyToAll()"
        [disabled]="pending != null || isApplyDisabled">
        <pe-loading-button
            [loading]="pending == pendingTypeEnum.applyToAll"
            [text]="materialButtonText">
        </pe-loading-button>
    </pe-button>

    <pe-button
        [id]="'select-material-apply-button'"
        [look]="'Cta'"
        class="modal-button"
        (buttonClick)="apply()"
        [disabled]="pending != null || isApplyDisabled">
        <pe-loading-button
            [loading]="pending == pendingTypeEnum.select"
            [text]="translate('Agito.Hilti.Profis3.SelectMaterial.Apply')">
        </pe-loading-button>
    </pe-button>
</pe-modal-footer>
