import { DesignPe } from '../../../shared/entities/design-pe';
import { LocalizationService } from '../../services/localization.service';
import { AdvancedInput, IAdvancedInputProps } from '../../components/main-menu/advanced-input/AdvancedInput';
import { ModalService } from '../../services/modal.service';

export function initializeAdvancedInput(
    design: DesignPe,
    controlProps: IAdvancedInputProps,
    localizationService: LocalizationService,
    modalService: ModalService,
    displayKey?: string,
) {
    controlProps.type = AdvancedInput;
    controlProps.buttonText = displayKey ? localizationService.getString(displayKey) : '';

    const onStateChanged = () => {
        return {};
    };

    controlProps.clicked = () => {
        design.usageCounter.SmartAnchor_AdvancedFiltersClicked++;
        modalService.openAdvancedInputs();
    };

    return {
        controlProps,
        onStateChanged
    };
}
