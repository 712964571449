import { Injectable } from '@angular/core';
import { UserServiceInjected } from '@profis-engineering/pe-ui-common/services/user.common';
import { DesignStandard } from '../../shared/entities/code-lists/design-standard';
import { DesignPe } from '../../shared/entities/design-pe';
import { IDetailedDisplayDesign } from '../../shared/entities/display-design';
import { ConcreteSafetyFactorGammaC, DesignType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

@Injectable({
    providedIn: 'root'
})
export class UserService extends UserServiceInjected<DesignPe> {

    public isAsadDebugEnabled = false;
    public isAsadForSingleEnabled = false;
    public allowedAnchorAIRegions: number[] = [];

    public mapDisplayDesignToDesign(displayDesign: IDetailedDisplayDesign) {
        this.design.designName = displayDesign.name;
        this.design.designMethodGroup = displayDesign.designMethodGroup;
        this.design.designStandard = displayDesign.designStandard as DesignStandard;
        this.design.templateName = displayDesign.templateName;
        this.design.projectId = displayDesign.projectId;
        this.design.projectName = displayDesign.projectName;
        this.design.unitLength = displayDesign.unitLength;
        this.design.unitArea = displayDesign.unitArea;
        this.design.unitStress = displayDesign.unitStress;
        this.design.unitForce = displayDesign.unitForce;
        this.design.unitMoment = displayDesign.unitMoment;
        this.design.unitTemperature = displayDesign.unitTemperature;
        this.design.unitForcePerLength = displayDesign.unitForcePerLength;
        this.design.unitMomentPerLength = displayDesign.unitMomentPerLength;
        this.design.unitLengthLarge = displayDesign.unitLengthLarge;
        this.design.unitStressSmall = displayDesign.unitStressSmall;
        this.design.unitDensity = displayDesign.unitDensity;
        this.design.unitAreaPerLength = displayDesign.unitAreaPerLength;

        if (this.design.designType.id == DesignType.Concrete) {
            this.design.concreteEtagEnOnly = displayDesign.EtagEnOnly as boolean;
        }
        else if (this.design.designType.id == DesignType.Handrail) {
            this.design.handrailEtagEnOnly = displayDesign.EtagEnOnly as boolean;
        }

        this.design.ostInputsData = displayDesign.ostInputsEnabled as boolean;
        this.design.anchorPlateFactor = displayDesign.anchorPlateFactor as number;
        this.design.safetyFactorForPermamentLoads = displayDesign.safetyFactorForPermanentLoads as number;
        this.design.safetyFactorForVariableLoads = displayDesign.safetyFactorForVariableLoads as number;
        this.design.edgeDistanceDisplayType = displayDesign.edgeDistanceDisplayType as number;
        this.design.minimumAnchorToProfileDistance = displayDesign.minimumAnchorToProfileDistance as number;
        this.design.minimumConcreteCover = displayDesign.minimumConcreteCover as number;
        this.design.concreteSafetyFactorGammaC = displayDesign.concreteSafetyFactorGammaC as ConcreteSafetyFactorGammaC;
        this.design.handrailDisplacementLimit = displayDesign.handrailDisplacementLimit as number;
        this.design.weldCapacityFactor = displayDesign.weldsCapacityFactor as number;
        this.design.concreteCapacityFactor = displayDesign.concreteCapacityFactor as number;
        this.design.materialSafetyFactor = displayDesign.materialSafetyFactor as number;
        this.design.steelCapacityFactor = displayDesign.steelCapacityFactor as number;
        this.design.steelSafetyFactorGammaM0 = displayDesign.steelSafetyFactorGammaM0 as number;
        this.design.steelSafetyFactorGammaM1 = displayDesign.steelSafetyFactorGammaM1 as number;
        this.design.steelSafetyFactorGammaM2 = displayDesign.steelSafetyFactorGammaM2 as number;
        this.design.steelSafetyFactorGammaM5 = displayDesign.steelSafetyFactorGammaM5 as number;
        this.design.jointCoefficientBj = displayDesign.jointCoefficientBj as number;
        this.design.effectiveArea = displayDesign.effectiveArea as number;
        this.design.effectiveAreaAISC = displayDesign.effectiveAreaAISC as number;
        this.design.loadDistributionAngle = displayDesign.loadDistributionAngle as number;
        this.design.alphaCC = displayDesign.alphaCC as number;
        this.design.limitPlasticStrain = displayDesign.limitPlasticStrain as number;
        this.design.divisionOfSurfaceOfCHS = displayDesign.divisionOfSurfaceOfCHS as number;
        this.design.numberOfElementsOfEdge = displayDesign.numberOfElementsOfEdge as number;
        this.design.numberOfAnalysisIterations = displayDesign.numberOfAnalysisIterations as number;
        this.design.divergentIterationsCount = displayDesign.divergentIterationsCount as number;
        this.design.minimumSizeOfElement = displayDesign.minimumSizeOfElement as number;
        this.design.maximumSizeOfElement = displayDesign.maximumSizeOfElement as number;
        this.design.useULSStresses = displayDesign.useULSStresses as boolean;
        this.design.concreteInCompressionMethod = displayDesign.concreteInCompressionMethod as number;
        this.design.inSteelSafetyFactorGammaM0 = displayDesign.inSteelSafetyFactorGammaM0 as number;
        this.design.inSteelSafetyFactorGammaM1 = displayDesign.inSteelSafetyFactorGammaM1 as number;
        this.design.inSteelSafetyFactorGammaMw = displayDesign.inSteelSafetyFactorGammaMw as number;
        this.design.inSteelSafetyFactorGammaMb = displayDesign.inSteelSafetyFactorGammaMb as number;
        this.design.measureAnchorPlate = displayDesign.measureAnchorPlate as number;
        this.design.measureBaseMaterialEdgeFrom = displayDesign.measureBaseMaterialEdgeFrom as number;
    }

    public override set isAsadEnabled(isAsadEnabled: boolean) {
        this.baseService.isAsadEnabled = isAsadEnabled;
    }

    public override get isAsadEnabled() {
        return this.baseService.isAsadEnabled;
    }
}
