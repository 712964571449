import belgium from './Belgium.png';
import czechRepublic from './CzechRepublic.png';
import france from './France.png';
import netherlands from './Netherlands.png';
import poland from './Poland.png';
import slovakia from './Slovakia.png';
import spain from './Spain.png';
import switzerland from './Switzerland.png';

const images = {
    'Belgium.png': belgium,
    'CzechRepublic.png': czechRepublic,
    'France.png': france,
    'Netherlands.png': netherlands,
    'Poland.png': poland,
    'Slovakia.png': slovakia,
    'Spain.png': spain,
    'Switzerland.png': switzerland
} satisfies Record<string, string>;

export default images as typeof images & { [imageName: string]: string };
