import {
    WeldLocationEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class WeldLocation extends CodeList {

    public static fromService(codeList: WeldLocationEntity) {
        return new WeldLocation(CodeList.baseFromService('WeldLocationEntity', codeList));
    }

    public override cloneDeep(): WeldLocation {
        return super.cloneDeep() as WeldLocation;
    }
}
