import { BuildingAreaEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class BuildingArea extends CodeList {

    public static fromService(codeList: BuildingAreaEntity) {
        return new BuildingArea(CodeList.baseFromService('BuildingAreaEntity', codeList));
    }

    public override cloneDeep(): BuildingArea {
        return super.cloneDeep() as BuildingArea;
    }
}
