export function registerGlobalStyles() {
    const sheet = new CSSStyleSheet();
    sheet.replaceSync(`
        :root {
            --pe-ui-pe-color-light-malachite-greenish-gray: #d8f4df;
            --pe-ui-pe-color-chilli: #a80015;
            --pe-ui-pe-color-white: #fff;
            --pe-ui-pe-color-dark-malachite-green: #117a26;
            --pe-ui-pe-color-iron: #524f53;
            --pe-ui-pe-color-light-tangeloish-gray: #e4e1df;
            --pe-ui-pe-color-medium-gray: #bab9ba;
            --pe-ui-pe-color-light-gray: #f3f2f0;
            --pe-ui-pe-color-light-yellowish-gray: #d8d8d3;
            --pe-ui-pe-color-light-gambogeish-gray: #e7e5e2;
            --pe-ui-pe-color-eggplant: #261d24;
        }`);
    document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
}