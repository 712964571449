import {
    Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { ISelectProfileComponentInput } from '../../../shared/components/select-profile';
import {
    ProfileFamily as ProfileFamilyEntity
} from '../../../shared/entities/code-lists/profile-family';
import {
    ProfileStandard as ProfileStandardEntity
} from '../../../shared/entities/code-lists/profile-standard';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    ProfileStandard
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

interface ISelectProfileItem {
    value?: number;
    image?: string;
    name?: string;
}

@Component({
    templateUrl: './select-profile.component.html',
    styleUrls: ['./select-profile.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectProfileComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<ISelectProfileComponentInput>;

    public items!: ISelectProfileItem[];
    public profileStandardDropdown!: DropdownProps<ProfileStandard>;

    constructor(
        private user: UserService,
        private localizationService: LocalizationService,
        private numberService: NumberService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get profileFamilies() {
        const allowedValues = this.modalInstance.input?.allowedValues ?? [];
        const profileFamilies = this.user.design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamilyEntity[];
        return profileFamilies.filter(family => allowedValues.includes(family.id ?? 0));
    }

    public get profileStandards() {
        const profileStandards = this.user.design.designData.designCodeLists[DesignCodeList.ProfileStandard] as ProfileStandardEntity[];
        return profileStandards.filter(standard => this.profileFamilies.some(family => family.profileStandard == standard.id));
    }

    public get selectedValue() {
        return this.modalInstance.input?.selectedValue ?? 0;
    }

    public set selectedValue(value: number) {
        if (this.modalInstance.input != null) {
            this.modalInstance.input.selectedValue = value;
        }
    }

    public get title() {
        return this.modalInstance.input?.popupTitle;
    }

    public get classNumberOfColumns() {
        return `column-number-${this.numberOfColumns}`;
    }

    public get numberOfColumns() {
        return this.modalInstance.input?.numberOfColumns || 4;
    }

    public get numberOfRows() {
        if (this.items == null) {
            return 0;
        }

        return Math.ceil(this.items.length / this.numberOfColumns);
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-profile-b',
            'sprite-profile-b-left',
            'sprite-profile-b-none',
            'sprite-profile-b-sides',
            'sprite-profile-c',
            'sprite-profile-cb',
            'sprite-profile-c-eightpoints',
            'sprite-profile-c-fourpoints',
            'sprite-profile-c-none',
            'sprite-profile-c-sixpoints',
            'sprite-profile-d',
            'sprite-profile-i',
            'sprite-profile-i-all',
            'sprite-profile-i-all-small',
            'sprite-profile-i-none',
            'sprite-profile-i-sides',
            'sprite-profile-i-top',
            'sprite-profile-l',
            'sprite-profile-p',
            'sprite-profile-r',
            'sprite-profile-sb',
            'sprite-profile-sh-all',
            'sprite-profile-sh-both',
            'sprite-profile-sh-none',
            'sprite-profile-sh-sides',
            'sprite-profile-sh-top',
            'sprite-profile-sr',
            'sprite-profile-sr-left',
            'sprite-profile-sr-none',
            'sprite-profile-sr-sides',
            'sprite-profile-t',
            'sprite-profile-t-none',
            'sprite-profile-u',
            'sprite-profile-u-all',
            'sprite-profile-u-left',
            'sprite-profile-u-none',
            'sprite-profile-u-right',
            'sprite-profile-u-sides',
            'sprite-profile-u-top',
            'sprite-profile-z'
        );

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        this.profileStandardDropdown = {
            id: 'select-profile-profile-standard-dropdown',
            items: this.profileStandards.map(standard => ({
                value: standard.id as ProfileStandard,
                text: standard.getTranslatedNameText(codeListDeps)
            }) as DropdownItem<ProfileStandard>)
        };

        if (this.selectedValue != null) {
            const selectedProfileStandard = this.profileFamilies.find(family => (family.id ?? 0) == this.selectedValue)?.profileStandard;
            if (selectedProfileStandard != null) {
                this.onProfileStandardDropdown(selectedProfileStandard);
            }
        }
    }

    public isLastColumn(index: number) {
        return index % this.numberOfColumns == this.numberOfColumns - 1;
    }

    public isLastRow(index: number) {
        return index >= (this.numberOfRows - 1) * this.numberOfColumns;
    }

    public identifyItem: TrackByFunction<ISelectProfileItem> = function (_: number, item: ISelectProfileItem) {
        return item.value;
    };

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close(result?: string) {
        this.modalInstance.close(result);
    }

    public async select(value?: number) {
        if (value != null) {
            this.selectedValue = value;

            if (this.modalInstance.input?.onSelect != null) {
                await this.modalInstance.input?.onSelect(value);
            }
        }

        this.close();
    }

    public onProfileStandardDropdown(value: ProfileStandard) {
        this.profileStandardDropdown.selectedValue = value;

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        const filteredProfileFamilies = this.profileFamilies.filter(family => family.profileStandard == value);
        this.items = filteredProfileFamilies.map((family): ISelectProfileItem => ({
            value: family.id,
            name: family.getTranslatedNameText(codeListDeps) ?? '',
            image: family.image
        }));
    }

    public getItemImage(image?: string) {
        return image ?? '';
    }
}
