<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.InputHandrailLoads.Title')"
    [closeButtonDisabled]="submitted">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="body-wrapper">
            <div class="section horizontal-loads">
                <div class="section-title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.HorizontalLoads')"></div>

                <div class="section-row">
                    <div class="section-column">
                        <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.LinearLoads')"></p>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="linearLoadOutwardsTextBox.id"
                                [title]="linearLoadOutwardsTextBox.title"
                                [value]="linearLoadOutwardsTextBox.value"
                                (valueChange)="linearLoadOutwardsTextBox.value = $any($event).detail"
                                [unit]="linearLoadOutwardsTextBox.unit"
                                [minValue]="linearLoadOutwardsTextBox.minValue"
                                [maxValue]="linearLoadOutwardsTextBox.maxValue"
                                (isValid)="linearLoadOutwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="linearLoadInwardsTextBox.id"
                                [title]="linearLoadInwardsTextBox.title"
                                [value]="linearLoadInwardsTextBox.value"
                                (valueChange)="linearLoadInwardsTextBox.value = $any($event).detail"
                                [unit]="linearLoadInwardsTextBox.unit"
                                [minValue]="linearLoadInwardsTextBox.minValue"
                                [maxValue]="linearLoadInwardsTextBox.maxValue"
                                (isValid)="linearLoadInwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="linearLoadOutwardsHeightTextBox.id"
                                [title]="linearLoadOutwardsHeightTextBox.title"
                                [value]="linearLoadOutwardsHeightTextBox.value"
                                (valueChange)="linearLoadOutwardsHeightTextBox.value = $any($event).detail"
                                [unit]="linearLoadOutwardsHeightTextBox.unit"
                                [minValue]="linearLoadOutwardsHeightTextBox.minValue"
                                [maxValue]="linearLoadOutwardsHeightTextBox.maxValue"
                                (isValid)="linearLoadOutwardsHeightTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="linearLoadInwardsHeightTextBox.id"
                                [title]="linearLoadInwardsHeightTextBox.title"
                                [value]="linearLoadInwardsHeightTextBox.value"
                                (valueChange)="linearLoadInwardsHeightTextBox.value = $any($event).detail"
                                [unit]="linearLoadInwardsHeightTextBox.unit"
                                [minValue]="linearLoadInwardsHeightTextBox.minValue"
                                [maxValue]="linearLoadInwardsHeightTextBox.maxValue"
                                (isValid)="linearLoadInwardsHeightTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="section-column">
                        <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.PunctualLoads')"></p>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="punctualLoadOutwardsTextBox.id"
                                [title]="punctualLoadOutwardsTextBox.title"
                                [value]="punctualLoadOutwardsTextBox.value"
                                (valueChange)="punctualLoadOutwardsTextBox.value = $any($event).detail"
                                [unit]="punctualLoadOutwardsTextBox.unit"
                                [minValue]="punctualLoadOutwardsTextBox.minValue"
                                [maxValue]="punctualLoadOutwardsTextBox.maxValue"
                                (isValid)="punctualLoadOutwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="punctualLoadInwardsTextBox.id"
                                [title]="punctualLoadInwardsTextBox.title"
                                [value]="punctualLoadInwardsTextBox.value"
                                (valueChange)="punctualLoadInwardsTextBox.value = $any($event).detail"
                                [unit]="punctualLoadInwardsTextBox.unit"
                                [minValue]="punctualLoadInwardsTextBox.minValue"
                                [maxValue]="punctualLoadInwardsTextBox.maxValue"
                                (isValid)="punctualLoadInwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row"
                            *ngIf="!isPropertyHidden(propertyMetaData.Loads_InfillPunctualLoadOutwards.id)">
                            <pe-numeric-text-box
                                [id]="infillPunctualLoadOutwardsTextBox.id"
                                [title]="infillPunctualLoadOutwardsTextBox.title"
                                [value]="infillPunctualLoadOutwardsTextBox.value"
                                (valueChange)="infillPunctualLoadOutwardsTextBox.value = $any($event).detail"
                                [unit]="infillPunctualLoadOutwardsTextBox.unit"
                                [minValue]="infillPunctualLoadOutwardsTextBox.minValue"
                                [maxValue]="infillPunctualLoadOutwardsTextBox.maxValue"
                                (isValid)="infillPunctualLoadOutwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row"
                            *ngIf="!isPropertyHidden(propertyMetaData.Loads_InfillPunctualLoadInwards.id)">
                            <pe-numeric-text-box
                                [id]="infillPunctualLoadInwardsTextBox.id"
                                [title]="infillPunctualLoadInwardsTextBox.title"
                                [value]="infillPunctualLoadInwardsTextBox.value"
                                (valueChange)="infillPunctualLoadInwardsTextBox.value = $any($event).detail"
                                [unit]="infillPunctualLoadInwardsTextBox.unit"
                                [minValue]="infillPunctualLoadInwardsTextBox.minValue"
                                [maxValue]="infillPunctualLoadInwardsTextBox.maxValue"
                                (isValid)="infillPunctualLoadInwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="punctualLoadOutwardsHeightTextBox.id"
                                [title]="punctualLoadOutwardsHeightTextBox.title"
                                [value]="punctualLoadOutwardsHeightTextBox.value"
                                (valueChange)="punctualLoadOutwardsHeightTextBox.value = $any($event).detail"
                                [unit]="punctualLoadOutwardsHeightTextBox.unit"
                                [minValue]="punctualLoadOutwardsHeightTextBox.minValue"
                                [maxValue]="punctualLoadOutwardsHeightTextBox.maxValue"
                                (isValid)="punctualLoadOutwardsHeightTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="punctualLoadInwardsHeightTextBox.id"
                                [title]="punctualLoadInwardsHeightTextBox.title"
                                [value]="punctualLoadInwardsHeightTextBox.value"
                                (valueChange)="punctualLoadInwardsHeightTextBox.value = $any($event).detail"
                                [unit]="punctualLoadInwardsHeightTextBox.unit"
                                [minValue]="punctualLoadInwardsHeightTextBox.minValue"
                                [maxValue]="punctualLoadInwardsHeightTextBox.maxValue"
                                (isValid)="punctualLoadInwardsHeightTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <pe-checkbox-button
                            *ngIf="!isPropertyHidden(propertyMetaData.Loads_PunctualLoadInWorstPosition.id)"
                            class="control-row"
                            [id]="punctualLoadInWorstPositionCheckbox.id"
                            [items]="punctualLoadInWorstPositionCheckbox.items"
                            [selectedValues]="punctualLoadInWorstPositionCheckbox.selectedValues"
                            (selectedValuesChange)="punctualLoadInWorstPositionCheckbox.selectedValues = $any($event).detail"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Loads_PunctualLoadInWorstPosition.id)">
                        </pe-checkbox-button>
                    </div>
                </div>
            </div>

            <div class="section vertical-loads">
                <div class="section-title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.VerticalLoads')"></div>

                <div class="section-row">
                    <div class="section-column">
                        <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.LinearLoads')"></p>

                        <div class="control-row">
                            <pe-numeric-text-box
                                [id]="verticalLoadTextBox.id"
                                [title]="verticalLoadTextBox.title"
                                [value]="verticalLoadTextBox.value"
                                (valueChange)="verticalLoadTextBox.value = $any($event).detail"
                                [unit]="verticalLoadTextBox.unit"
                                [minValue]="verticalLoadTextBox.minValue"
                                [maxValue]="verticalLoadTextBox.maxValue"
                                (isValid)="verticalLoadTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="section-column"
                        [ngStyle]="{ 'visibility': isPropertyHidden(propertyMetaData.Loads_VerticalPunctualLoad.id) ? 'hidden' : 'visible' }">
                        <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.PunctualLoads')"></p>

                        <div class="control-row"
                            *ngIf="!isPropertyHidden(propertyMetaData.Loads_VerticalPunctualLoad.id)">
                            <pe-numeric-text-box
                                [id]="verticalPunctualLoadTextBox.id"
                                [title]="verticalPunctualLoadTextBox.title"
                                [value]="verticalPunctualLoadTextBox.value"
                                (valueChange)="verticalPunctualLoadTextBox.value = $any($event).detail"
                                [unit]="verticalPunctualLoadTextBox.unit"
                                [minValue]="verticalPunctualLoadTextBox.minValue"
                                [maxValue]="verticalPunctualLoadTextBox.maxValue"
                                (isValid)="verticalPunctualLoadTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <pe-checkbox-button
                            *ngIf="!isPropertyHidden(propertyMetaData.Loads_VerticalPunctualLoadInWorstPosition.id)"
                            class="control-row"
                            [id]="verticalPunctualLoadInWorstPositionCheckbox.id"
                            [items]="verticalPunctualLoadInWorstPositionCheckbox.items"
                            [selectedValues]="verticalPunctualLoadInWorstPositionCheckbox.selectedValues"
                            (selectedValuesChange)="verticalPunctualLoadInWorstPositionCheckbox.selectedValues = $any($event).detail"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Loads_VerticalPunctualLoadInWorstPosition.id)">
                        </pe-checkbox-button>
                    </div>
                </div>
            </div>

            <div class="section infill-loads"
                *ngIf="!isPropertyHidden(propertyMetaData.Loads_InfillLoadOutwards.id) || !isPropertyHidden(propertyMetaData.Loads_InfillLoadInwards.id)">
                <div class="section-title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.InfillLoads')"></div>

                <div class="section-row">
                    <div class="section-column">
                        <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.InputHandrailLoads.AreaLoads')"></p>

                        <div class="control-row"
                            *ngIf="!isPropertyHidden(propertyMetaData.Loads_InfillLoadOutwards.id)">
                            <pe-numeric-text-box
                                [id]="infillLoadOutwardsTextBox.id"
                                [title]="infillLoadOutwardsTextBox.title"
                                [value]="infillLoadOutwardsTextBox.value"
                                (valueChange)="infillLoadOutwardsTextBox.value = $any($event).detail"
                                [unit]="infillLoadOutwardsTextBox.unit"
                                [minValue]="infillLoadOutwardsTextBox.minValue"
                                [maxValue]="infillLoadOutwardsTextBox.maxValue"
                                (isValid)="infillLoadOutwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-row"
                            *ngIf="!isPropertyHidden(propertyMetaData.Loads_InfillLoadInwards.id)">
                            <pe-numeric-text-box
                                [id]="infillLoadInwardsTextBox.id"
                                [title]="infillLoadInwardsTextBox.title"
                                [value]="infillLoadInwardsTextBox.value"
                                (valueChange)="infillLoadInwardsTextBox.value = $any($event).detail"
                                [unit]="infillLoadInwardsTextBox.unit"
                                [minValue]="infillLoadInwardsTextBox.minValue"
                                [maxValue]="infillLoadInwardsTextBox.maxValue"
                                (isValid)="infillLoadInwardsTextBox.isValid = $any($event).detail"
                                [updateOnBlur]="true"
                                [required]="true">
                            </pe-numeric-text-box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            class="modal-button"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.InputHandrailLoads.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            class="modal-button"
            type="submit"
            [look]="'Cta'"
            [disabled]="!formValid || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.Profis3.InputHandrailLoads.Save')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
