<div id="weld-curves" class="box-section" *ngIf="hasWeldCurves">
    <pe-section
        [look]="collapseSectionBoxLook"
        [title]="translate('Agito.Hilti.Profis3.WeldCurves.Title')"
        [collapseTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')"
        [collapsed]="collapsed"
        (collapsedChange)="collapsed = $any($event).detail; collapsedChange.emit(collapsed)">
        <div class="weld-curves-diagram-container-outer">
            <pe-dropdown
                [id]="'welds-dropdown'"
                [items]="weldsDropdownItems"
                [selectedValue]="selectedWeld"
                (selectedValueChange)="weldsItemChanged($any($event).detail)">
            </pe-dropdown>

            <div class="weld-curves-diagram-container">
                <div class="unit-stress">[{{unitStressName}}]</div>

                <!-- vertical line -->
                <svg class="vertical-line">
                    <line class="line" x1="5" y1="0" x2="5" y2="180" />
                    <line class="line" x1="5" y1="0" x2="1" y2="10" />
                    <line class="line" x1="5" y1="0" x2="9" y2="10" />
                </svg>

                <!-- horizontal line -->
                <svg class="horizontal-line">
                    <line class="line" x1="0" y1="5" x2="220" y2="5" />
                    <line class="line" x1="215" y1="5" x2="205" y2="9" />
                    <line class="line" x1="215" y1="5" x2="205" y2="1" />
                </svg>

                <div class="weld-curves-diagram">
                    <div class="weld-curves-diagram-column"
                        *ngFor="let x of getWeldCurvesDiagramData; index as i"
                        [ngbTooltip]="translationFormatService.getLocalizedStringWithTranslationFormat(x.title, true)"
                        [ngStyle]="{ 'height': x.height + '%' }">
                        <div *ngIf="x.height == 100"
                            class="weld-curves-diagram-column-max-value"
                            [style.left]="i == 0 ? '0' : ''"
                            [style.right]="i == weldCurvesDiagramDataSize - 1 ? '0' : ''">
                            <span [innerHtml]="getMaxWeldCurvesDiagramValue"></span>
                        </div>
                    </div>
                </div>

                <div class="unit-length">
                    <div>[{{unitLengthName}}]</div>
                </div>
            </div>
        </div>
    </pe-section>
</div>
