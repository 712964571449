import { CodeList, ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Design, ICalculateOptions, IDesignStateBase } from '@profis-engineering/pe-ui-common/entities/design';
import { IButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IImageNameRadioGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-radio-group-props';
import { IImageNameSelectionGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';
import { ILabelProps } from '@profis-engineering/pe-ui-common/entities/main-menu/Label-props';
import { IMenu, IRegion } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { BaseControl, Button, DropDown, ImageNameSelectionGroup, Label, Menu, RadioButtonGroup, Region, Tab, TabGroup, TextBox, ToggleButtonGroup, UIPropertyBaseControl } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IPopupGridPartialProps, IPopupGridProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { IRadioButtonGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/radio-button-group-props';
import { ITabGroupItem, ITabGroupProps } from '@profis-engineering/pe-ui-common/entities/main-menu/tab-group-props';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IToggleButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { IToggleButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import { IToggleImageButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import { IModalGridComponentInput, IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { IMenuServiceCodeListIdWithItems, IMenuServiceDropdownItemProps, IMenuServiceExtensions, MenuControl, TabItem } from '@profis-engineering/pe-ui-common/services/menu.common';
import cloneDeep from 'lodash-es/cloneDeep';
import uniqBy from 'lodash-es/uniqBy';
import { AdvancedCalculationType } from '../../shared/entities/code-lists/advanced-calculation-type';
import { AnchorEmbedmentDepth } from '../../shared/entities/code-lists/anchor-embedment-depth';
import { AnchorFamily as AnchorFamilyEntity } from '../../shared/entities/code-lists/anchor-family';
import { AnchorSize as AnchorSizeEntity } from '../../shared/entities/code-lists/anchor-size';
import { AnchorType as AnchorTypeEntity } from '../../shared/entities/code-lists/anchor-type';
import { BrickStrength } from '../../shared/entities/code-lists/brick-strength';
import { ReinforcementShearCondition } from '../../shared/entities/code-lists/reinforcement-shear-condition';
import { ReinforcementTensionCondition } from '../../shared/entities/code-lists/reinforcement-tension-condition';
import { ShearLoadDistributionType } from '../../shared/entities/code-lists/shear-load-distribution-type';
import { DesignCodeList } from '../../shared/entities/design-code-list';
import { DesignPe } from '../../shared/entities/design-pe';
import { createKBLink } from '../../shared/entities/KB-link';
import { MainMenuModal } from '../../shared/entities/main-menu';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import { TranslationFormat } from '../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation';
import { UIProperty } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { DesignStandard, DesignType, EmbedmentDepthOptimizationType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { isAnchorFamilyMarkedAsNew, isAnchorTypeMarkedAsNew } from '../../shared/helpers/anchor-helper';
import { DesignMethodGroupHelper } from '../../shared/helpers/design-method-group-helper';
import { PropertyMetaData } from '../../shared/properties/properties';
import { getButtonDisplayText } from '../helpers/main-menu-helpers/menu-helper';
import { isSmartAnchorToggleAllowed } from '../helpers/smart-anchor-helper';
import { getSpriteAsIconStyle, getSpriteAsIconStyleResponsive } from '../sprites';
import { CalculationServicePE } from './calculation-pe.service';
import { CodeListService } from './code-list.service';
import { FavoritesService } from './favorites.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { LicenseService } from './license.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { ModalService } from './modal.service';
import { NavigationService } from './navigation.service';
import { SmartAnchorOrderService } from './smart-anchor-order.service';
import { TourService } from './tour.service';
import { TranslationFormatService } from './translation-format.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

export class MenuServiceExtensions implements IMenuServiceExtensions {
    private static readonly controlsSupportingHoverEffect: number[] = [
        2278
    ];

    private static readonly controlsSupportingAlternatingItemsBackground: number[] = [
        2278
    ];


    constructor(
        private readonly modalService: ModalService,
        private readonly navigationService: NavigationService,
        private readonly userService: UserService,
        private readonly localizationService: LocalizationService,
        private readonly calculationService: CalculationServicePE,
        private readonly loggerService: LoggerService,
        private readonly unitService: UnitService,
        private readonly codeListService: CodeListService,
        private readonly userSettingsService: UserSettingsService,
        private readonly favoritesService: FavoritesService,
        private readonly translationFormatService: TranslationFormatService,
        private readonly smartAnchorOrderService: SmartAnchorOrderService,
        private readonly tourService: TourService,
        private readonly licenseService: LicenseService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService
    ) { }


    public get isStandardUser() {
        return this.userService.hasFreeLicense || this.userService.hasfloatingLimitReached;
    }


    // Data
    public validate(design: DesignPe) {
        if (design.designData.designCodeLists == null) {
            throw new Error('Design code lists not set. Probably design is not created yet.');
        }
    }

    public getMenuStructure(): Menu<BaseControl<string>, string> | undefined {
        const menu = this.navigationService.initialize().Menus.find((menu) => menu.Id == MenuType.Menu3D);
        if (menu != null) {
            return cloneDeep(menu);
        }

        return undefined;
    }

    public getMenuCommands(design: DesignPe): Record<string, (navigationControl?: BaseControl<string> | undefined) => void> {
        return {
            ['OpenOnSiteTestsValuesPopup']: this.openOnSiteTests.bind(this, design)
        };
    }

    public getMenuRegionCommands(): Record<string, () => void> {
        return {};
    }

    public getMenuModals(): Record<number, (input?: object | undefined) => IModalOpened> {
        return {
            [MainMenuModal.selectProfile]: (input?: object) => this.modalService.openSelectProfile(input),
            [MainMenuModal.profileSize]: (input?: object) => this.modalService.openProfileSize(input),
            [MainMenuModal.baseplateSize]: (input?: object) => this.modalService.openBaseplateSize(input),
            [MainMenuModal.brickSize]: (input?: object) => this.modalService.openBrickSize(input),
            [MainMenuModal.onSiteTests]: () => this.modalService.openOnSiteTests(),
            [MainMenuModal.selectMaterial]: (input?: object) => this.modalService.openSelectMaterial(input)
        };
    }

    // Common
    public calculateAsync(design: DesignPe, changeFn?: ((design: DesignPe) => void) | undefined, options?: ICalculateOptions | undefined): Promise<ICalculationResult> {
        return this.calculationService.calculateAsync(design, changeFn, options);
    }

    public trackUsage(navigationControl: UIPropertyBaseControl<string>, controlProps: IControlProps, design: DesignPe): void {
        if (navigationControl.UIPropertyId != null) {
            switch (navigationControl.UIPropertyId) {
                case PropertyMetaData.Optimize_PostDistance.id:
                    design.usageCounter.PostDistance_Optimization++;
                    break;
                case PropertyMetaData.Optimize_AnchorOffset.id:
                    design.usageCounter.AnchorOffset_Optimization++;
                    break;
                case PropertyMetaData.AnchorPlate_ShowStressDistribution.id:
                    design.usageCounter.AnchorPlate_ShowStressDistribution++;
                    break;
                case PropertyMetaData.AnchorPlate_ShowOptimizedAnchorPlateThickness.id:
                    design.usageCounter.AnchorPlate_ShowOptimizedAnchorPlateThickness++;
                    break;
                case PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id:
                    design.usageCounter.SmartAnchor_SuggestedAnchorSelected++;
                    break;
                case PropertyMetaData.SmartAnchor_ShowMore.id:
                    design.usageCounter.SmartAnchor_SeeMoreClicked++;
                    break;
                case PropertyMetaData.SmartAnchor_Enabled.id:
                    design.usageCounter.SmartAnchor_ToggleChanged++;
                    break;
            }
        }
    }

    public getDesignCodeList(codelistName: string) {
        return DesignCodeList[codelistName as keyof typeof DesignCodeList];
    }

    public getProjectCodeList(codelistName: string) {
        return ProjectCodeList[codelistName as keyof typeof ProjectCodeList];
    }

    public getCodeListItems(navigationControl: UIPropertyBaseControl<string>, design: DesignPe, codeList: number) {
        let codeListItems: CodeList[];

        if (codeList != null && (codeListItems = design.designData.designCodeLists[codeList]) != null) {
            return codeListItems;
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    public getCodeListIdWithItems(design: DesignPe, navigationControl: UIPropertyBaseControl<string>, codelistName?: string | undefined): IMenuServiceCodeListIdWithItems | undefined {
        const designCodeList = this.getDesignCodeList(codelistName ?? navigationControl.CodelistName ?? '');
        const projectCodeList = this.getProjectCodeList(codelistName ?? navigationControl.CodelistName ?? '');

        let codeListItems: CodeList[] | undefined = undefined;
        if (designCodeList != null) {
            codeListItems = this.getCodeListItems(navigationControl, design, designCodeList);
        }
        else if (projectCodeList != null) {
            codeListItems = this.getProjectCodeListItems(navigationControl, design, projectCodeList);
        }

        return {
            codeList: designCodeList,
            codeListItems: codeListItems ?? []
        };
    }

    public getCodeListItemText(design: DesignPe, codeList: number, codeListItem: CodeList, codeListDeps: ICodeListTextDeps): string | undefined {
        switch (codeList) {
            case DesignCodeList.ReinforcementShearCondition: {
                const reinforcementShearConditionCodeListItem = codeListItem as ReinforcementShearCondition;
                if (design.designMethodGroup?.id != null && reinforcementShearConditionCodeListItem.perDesignMethodGroupDisplayKeyExtensions?.[design.designMethodGroup.id] != null) {
                    return this.localizationService.getString(codeListItem.nameResourceKey + '.' + reinforcementShearConditionCodeListItem.perDesignMethodGroupDisplayKeyExtensions[design.designMethodGroup.id]);
                }
                break;
            }
            case DesignCodeList.ReinforcementTensionCondition: {
                const reinforcementTensionConditionCodeListItem = codeListItem as ReinforcementTensionCondition;
                if (design.designMethodGroup?.id != null && reinforcementTensionConditionCodeListItem.perDesignMethodGroupDisplayKeyExtensions?.[design.designMethodGroup.id] != null) {
                    return this.localizationService.getString(codeListItem.nameResourceKey + '.' + reinforcementTensionConditionCodeListItem.perDesignMethodGroupDisplayKeyExtensions[design.designMethodGroup.id]);
                }
                break;
            }
            case DesignCodeList.AnchorEmbedmentDepth:
                return this.getAnchorEmbedmentDepthText(codeListItem as AnchorEmbedmentDepth, design.unitLength);
            case DesignCodeList.BrickStrength:
                return this.getBrickStrengthText(codeListItem as BrickStrength, design.unitStress);
            case DesignCodeList.ShearLoadDistributionType:
                return this.getShearLoadDistributionTypeText(codeListItem as ShearLoadDistributionType, design);
        }

        return codeListItem.getTranslatedNameText(codeListDeps);
    }

    public getDesignStandard(design: DesignPe): CodeList | undefined {
        return this.codeListService.projectCodeLists[ProjectCodeList.DesignStandard].find(d => d.id == design.designStandard.id);
    }


    public getTabControlId(name: string): string | undefined {
        return `tab-${name}`;
    }

    public updateTabDisplayKey(): void {
        /* Nothing to do. */
    }

    public updateTabs(design: DesignPe, tabs: Tab<BaseControl<string>, string>[]): void {
        // CR: this is wrong and the order should be returned by the server
        // custom sort order for handrail
        if (design.designType.id == DesignType.Handrail) {
            const i = tabs.findIndex(tab => tab.Name == 'ProfilesTab');
            let j = tabs.findIndex(tab => tab.Name == 'ApplicationTab');
            if (i >= 0 && j >= 0) {
                const el = tabs.splice(i, 1);
                tabs.splice(++j, 0, el[0]);
            }
        }
    }

    public isFavoritesTabHidden(design: DesignPe): boolean {
        return design.model[UIProperty.SmartAnchor_Enabled] as boolean ?? false;
    }

    public isTabDisabled(design: DesignPe, tab: string): boolean {
        const isSmartAnchorEnabled = design.model[UIProperty.SmartAnchor_Enabled] as boolean;

        if (isSmartAnchorEnabled && !this.getSmartAnchorMenuTabs().includes(tab)) {
            return true;
        }

        return false;
    }

    public onTabSelected(design: DesignPe, tab: string) {

        if (tab === 'tab-SmartAnchorTab' && isSmartAnchorToggleAllowed(design) && this.userService.isAsadEnabled && !this.isStandardUser && !this.userSettingsService.settings.smartdesignvirtualtourseen.value) {
            const smartDesignTour = this.tourService.smartDesignTour();
            smartDesignTour.oncomplete(() => { this.saveSmartDesignTour(); });
            smartDesignTour.onexit(() => { this.saveSmartDesignTour(); });
        }
    }

    public getRegionDisplayId(_tabName: string, regionName: string): string | undefined {
        return `region-${regionName}`;
    }

    public getRegionId(_tabName: string, regionName: string): string | undefined {
        return regionName;
    }

    public getRegionFavoritesId(id: string): string | undefined {
        return this.favoritesService.getMenuRegionIdForFavorites(id, DesignType.Concrete);
    }

    public setRegionKbFields(design: DesignPe, selectedRegionId: number, navigationRegion: Region<BaseControl<string>, string>, region: IRegion): void {
        if (design.designStandard.id == DesignStandard.ACI) {
            if (navigationRegion.KBNumberAciRegion != null) {
                region.kbNumber = navigationRegion.KBNumberAciRegion[selectedRegionId];
            }

            const selectedRegionKBUrl = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value as number).profis3KBUrlAci;
            region.kbLinkTemplate = selectedRegionKBUrl ?? this.userSettingsService.getCommonRegionById(KnownRegion.UnitedStates).profis3KBUrlAci;

            region.kbTooltip = navigationRegion.KBTooltipDisplayKey;
        }
    }


    public updateControlKBNumberAci(): void {
        /* Nothing to do. */
    }


    public clientHidden(uiPropertyId: number, design: DesignPe): boolean {
        switch (uiPropertyId) {
            case UIProperty.AnchorPlate_NotOwnedLearnMore:
            case UIProperty.AnchorPlate_NotOwnedLearnMoreInfo:
                if (design.region.id == KnownRegion.Australia) {
                    return this.userSettingsService.settings.application.general.regionId.value != KnownRegion.Australia;
                }

                if (design.region.id == KnownRegion.NewZealand) {
                    return this.userSettingsService.settings.application.general.regionId.value != KnownRegion.NewZealand;
                }

                return this.userSettingsService.getProfis3Url == null || this.userSettingsService.getProfis3Url.length == 0;

            default:
                return false;
        }
    }


    public formatDisplayStringModule(textKey: string, design: DesignPe) {
        const variables =
        {
            DesignMethodReference: this.localizationService.getString(textKey + '.DesignMethodReference.' + design.designMethodGroup?.displayKey, { optional: true }),
            MetalDeckReference: this.localizationService.getString(`${textKey}.${design.metalDeckType}`, { optional: true })
        };
        return formatKeyValue(this.localizationService.getString(textKey), variables);
    }


    public getFormattedKBLinkByRegionSpecificTemplate(design: DesignPe, kbNumberRegion: { [key: string]: string }): string | undefined {
        const designStandardId = design.designStandard.id;

        if (designStandardId == DesignStandard.ACI) {
            const selectedRegion = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value as number);
            if (kbNumberRegion?.[selectedRegion.id] != null && selectedRegion.profis3KBUrlAci != null) {
                return createKBLink(selectedRegion.profis3KBUrlAci, kbNumberRegion[selectedRegion.id]);
            }
        }

        return undefined;
    }

    public getLocalizedStringWithTranslationFormat(translationFormat: TranslationFormat) {
        return this.translationFormatService.getLocalizedStringWithTranslationFormat(translationFormat);
    }

    // Controls
    public createMainMenuControlModule() {
        return undefined as any;
    }

    // TextBox
    public overrideTextBoxProps(design: DesignPe, controlProps: ITextBoxProps, navigationControl: TextBox): void {
        controlProps.unitService = this.unitService;

        const value = navigationControl.UIPropertyId != null
            ? design.model[navigationControl.UIPropertyId] as any
            : null;

        controlProps.value = this.formatTextboxValue(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number, undefined, navigationControl.FixedDecimals);
        controlProps.valueTitle = this.formatTextboxValueTitle(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number);
    }

    public overrideTextBoxUnitGroup(): void {
        /* Nothing to do. */
    }

    public setTextBoxOnStateChanged(state: IDesignStateBase, controlProps: ITextBoxProps, navigationControl: TextBox<string>): void {
        const value = navigationControl.UIPropertyId != null
            ? state.model[navigationControl.UIPropertyId] as any
            : null;

        controlProps.valueTitle = this.formatTextboxValueTitle(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number);
        controlProps.value = this.formatTextboxValue(value, navigationControl.UnitGroup, navigationControl.UIPropertyId as number, undefined, navigationControl.FixedDecimals);
    }

    // Dropdown
    public overrideDropdownProps(design: DesignPe, controlProps: IDropdownProps, navigationControl: DropDown<string>): void {
        this.showDropdownAddsOn(navigationControl, controlProps);
    }

    public getDropdownItemProps(design: DesignPe, controlProps: IDropdownProps, navigationControl: DropDown<string>, designCodeList: number): IMenuServiceDropdownItemProps | undefined {
        const projectCodeList = this.getProjectCodeList(navigationControl.CodelistName ?? '');

        return {
            addOnActive: (codeListItem: CodeList) => {
                if (projectCodeList == ProjectCodeList.AdvancedCalculationType) {
                    return (codeListItem as AdvancedCalculationType).addOnActive;
                }

                return false;
            },
            addOnText: (codeListItem: CodeList) => {
                if (projectCodeList == ProjectCodeList.AdvancedCalculationType) {
                    let translationKey = (codeListItem as AdvancedCalculationType).addOnDisplayKey;
                    if (translationKey == null) {
                        return undefined;
                    }

                    // In case of floating limit reached, license is simulated to standard and we want to use different translation
                    if (this.licenseService.floatingLimitReached) {
                        translationKey = `${translationKey}.NotAvailable`;
                    }

                    return this.localizationService.getString(translationKey);
                }

                return undefined;
            },
            isNew: (codeListItem: CodeList) => {
                switch (designCodeList) {
                    case DesignCodeList.AnchorType:
                    case DesignCodeList.AnchorTypeAC58:
                        return isAnchorTypeMarkedAsNew(codeListItem.id, design) ?? false;

                    case DesignCodeList.AnchorFamily:
                    case DesignCodeList.AnchorFamilyAC58:
                        return isAnchorFamilyMarkedAsNew(codeListItem.id, design) ?? false;

                    default:
                        return false;
                }
            },
            internalPortfolioOnly: (codeListItem: CodeList) => {
                if (DesignMethodGroupHelper.IsLrfdBased(design.designMethodGroup?.id)) {
                    switch (designCodeList) {
                        case DesignCodeList.AnchorSizeAC58: {
                            const anchorSizeData = design.designData.designCodeLists[DesignCodeList.AnchorSizeAC58] as AnchorSizeEntity[];
                            return anchorSizeData.find(x => x.id == codeListItem.id)?.internalPortfolioOnlyRegions?.includes(design.region.id) ?? false;
                        }

                        case DesignCodeList.AnchorTypeAC58: {
                            const anchorTypeData = design.designData.designCodeLists[DesignCodeList.AnchorTypeAC58] as AnchorTypeEntity[];
                            return anchorTypeData.find(x => x.id == codeListItem.id)?.internalPortfolioOnly ?? false;
                        }

                        case DesignCodeList.AnchorFamilyAC58: {
                            const anchorFamilyData = design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamilyEntity[];
                            return anchorFamilyData.find(x => x.id == codeListItem.id)?.internalPortfolioOnly ?? false;
                        }

                        default:
                            return false;
                    }
                }

                switch (designCodeList) {
                    case DesignCodeList.AnchorSize: {
                        const anchorSizeData = design.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSizeEntity[];
                        return anchorSizeData.find(x => x.id == codeListItem.id)?.internalPortfolioOnlyRegions?.includes(design.region.id) ?? false;
                    }
                    case DesignCodeList.AnchorType: {
                        const anchorTypeData = design.designData.designCodeLists[DesignCodeList.AnchorType] as AnchorTypeEntity[];
                        return anchorTypeData.find(x => x.id == codeListItem.id)?.internalPortfolioOnly ?? false;
                    }

                    case DesignCodeList.AnchorFamily: {
                        const anchorFamilyData = design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamilyEntity[];
                        return anchorFamilyData.find(x => x.id == codeListItem.id)?.internalPortfolioOnly ?? false;
                    }

                    default:
                        return false;
                }
            }
        };
    }

    public setDropdownOnAllowedValuesChanged(design: DesignPe, controlProps: IDropdownProps, navigationControl: DropDown<string>): void {
        this.showDropdownAddsOn(navigationControl, controlProps);
    }

    public setDropdownOnStateChanged(design: DesignPe, designCodeList: number, state: IDesignStateBase, menu: IMenu, navigationControl: DropDown<string>, controlProps: IDropdownProps, onStateChangeFn: (formatTextFn: (codeListItem: CodeList, unit?: UnitType | undefined) => string, unit?: UnitType | undefined) => IDropdownProps): IDropdownProps | undefined {
        switch (designCodeList) {
            case DesignCodeList.AnchorEmbedmentDepth: {
                const anchorEmbedmentDepthCodeList = this.getCodeListItems(navigationControl, design, designCodeList) as AnchorEmbedmentDepth[];
                const control = menu.controls[controlProps.controlId] as IDropdownProps;

                // allowedValues is null when dropdown is not visible
                const uiPropertyId = navigationControl.UIPropertyId as number;
                const allowedValues = design.properties.get(uiPropertyId).allowedValues;

                return {
                    selectedValue: state.model[uiPropertyId] as number,
                    items: allowedValues != null ? (control.items ?? []).map(item => ({
                        value: item.value,
                        text: this.getAnchorEmbedmentDepthText(anchorEmbedmentDepthCodeList.find(x => x.id == item.value), design.unitLength)
                    })) : [],
                    initialItems: [...(control.initialItems ?? [])]
                } as IDropdownProps;
            }

            case DesignCodeList.BrickStrength:
                return onStateChangeFn(this.getBrickStrengthText.bind(this), design.unitStress);

            default:
                return ({ selectedValue: state.model[navigationControl.UIPropertyId as number] } as any) as IDropdownProps;
        }
    }

    // Checkbox
    public overrideCheckboxProps(_design: DesignPe, controlProps: ICheckboxProps) {
        if (controlProps.iconImage != null) {
            controlProps.iconImageStyle = getSpriteAsIconStyle(controlProps.iconImage);
            controlProps.iconImageSelectedStyle = getSpriteAsIconStyle(`${controlProps.iconImage}-selected`);
        }
    }

    // CheckboxGroup
    public overrideCheckboxGroupProps() {
        /* Nothing to do. */
    }

    // RadioButton
    public overrideRadioButtonProps() {
        /* Nothing to do. */
    }

    // RadioButtonGroup
    public overrideRadioButtonGroupProps(_design: Design, controlProps: IRadioButtonGroupProps, navigationControl: RadioButtonGroup): void {
        controlProps.itemsHoverable = this.controlSupportsHoverEffect(navigationControl.Id);
        controlProps.alternateItemsBackgrounds = this.controlSupportsAlternatingItemsBackground(navigationControl.Id);
    }

    public applyProperSingleImage(): void {
        /* Nothing to do. */
    }

    public getApplySortOrderItemIds(design: DesignPe, codeList: number): number[] | undefined {
        switch (codeList) {
            case DesignCodeList.SmartAnchorCategory:
                return this.smartAnchorOrderService.categoriesOrder;
            case DesignCodeList.SmartAnchorApplication:
                return this.smartAnchorOrderService.applicationsOrder.find(x => x.categoryId === design.smartAnchorCategory)?.applicationIds;
            default:
                break;
        }

        return undefined;
    }

    public getRadioGroupParentId(design: DesignPe, codeList: number): number | undefined {
        if (codeList == DesignCodeList.SmartAnchorApplication) {
            return design.smartAnchorCategory;
        }
        return undefined;
    }

    // Button
    public overrideButtonProps(_design: DesignPe, controlProps: IButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }
    }

    public getButtonDisplayText(design: DesignPe, navigationControl: Button<string>): string | undefined {
        return getButtonDisplayText(design, navigationControl, this.localizationService);
    }

    // ButtonGroup
    public overrideButtonGroupProps() {
        /* Nothing to do. */
    }

    public updateButtonGroupItemProps(_design: DesignPe, _navigationControl: Button, item: IButtonGroupItem) {
        if (item.image != null && item.image != '') {
            item.imageStyle = getSpriteAsIconStyle(item.image);
        }
    }

    // ToggleButton
    public overrideToggleButtonProps(_design: DesignPe, controlProps: IToggleButtonProps) {
        controlProps.imageStyle = this.getIconStyleForImage(controlProps.image);
    }

    // ToggleImageButton
    public overrideToggleImageButtonProps(_design: DesignPe, controlProps: IToggleImageButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }

        if (controlProps.alternateImage != null) {
            controlProps.alternateImageStyle = getSpriteAsIconStyle(controlProps.alternateImage);
        }
    }

    // ToggleButtonGroup
    public overrideToggleButtonGroupProps() {
        /* Nothing to do. */
    }

    public updateToggleButtonGroupItemCodeListProps() {
        /* Nothing to do. */
    }

    public updateToggleButtonGroupItemProps(_design: DesignPe, _navigationControl: ToggleButtonGroup, item: IToggleButtonGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public getToggleButtonGroupAllowedValues(codeList: number, allowedValues: number[]): number[] {
        switch (codeList) {
            case DesignCodeList.StiffenerLayout:
            case DesignCodeList.AnchorLayout:
                if (allowedValues != null) {
                    return allowedValues.concat([0]); // 0 is custom
                }
                break;

            case DesignCodeList.PlateShape:
                if (allowedValues != null) {
                    return allowedValues.concat([6]); // 6 is custom
                }
                break;
            default:
                break;
        }
        return allowedValues;
    }

    // Label
    public overrideLabelProps(_design: DesignPe, controlProps: ILabelProps, navigationControl: Label<string>): void {
        if (navigationControl.UIPropertyId == PropertyMetaData.AnchorPlate_OptimizedThicknessMessage.id) {
            controlProps.paddingLeft = 26;
        }
        else if (navigationControl.UIPropertyId == PropertyMetaData.AnchorPlate_NotOwnedLearnMoreInfo.id) {
            controlProps.customClass = 'not-owned-info';
        }
    }

    // Group
    public overrideGroupProps() {
        /* Nothing to do. */
    }

    public setGroupChildProps() {
        /* Nothing to do. */
    }

    // Rotate
    public overrideRotateProps() {
        /* Nothing to do. */
    }

    // PopupGrid
    public overridePopupGridProps(design: DesignPe, controlProps: IPopupGridProps, navigationControl: UIPropertyBaseControl<string>) {
        if (design.designStandard.id == DesignStandard.ACI) {
            const selectedRegion = this.userSettingsService.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value as number);
            if (navigationControl.KBNumberAciRegion != null && navigationControl.KBNumberAciRegion[selectedRegion.id] == 'DEFAULT') {
                if (selectedRegion.profis3KBUrl != null) {
                    controlProps.kbLink = selectedRegion.profis3KBUrl;
                }
                else {
                    controlProps.kbLink = this.userSettingsService.getCommonRegionById(KnownRegion.UnitedStates).profis3KBUrl;
                }

                controlProps.kbTooltip = navigationControl.KBTooltipDisplayKey;
            }
        }
    }

    public customizePopupGridModal(modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions, codeList: number, design: DesignPe): void {
        switch (codeList) {
            case DesignCodeList.BrickLayout:
                modalProps.numberOfColumns = 1;
                break;
            case DesignCodeList.SeismicDesignType:
            case DesignCodeList.SeismicTensionType:
            case DesignCodeList.SeismicShearType:
                modalProps.numberOfColumns = 1;
                modalOpts.size = 'lg';
                break;
            case DesignCodeList.BuildingCategory:
                modalProps.numberOfColumns = 3;

                modalProps.warning = design.region.id != KnownRegion.Italy
                    ? this.localizationService.getString('Agito.Hilti.Profis3.ModalGrid.BuildingCategory.LocalRegulationsWarning')
                    : this.localizationService.getString('Agito.Hilti.Profis3.ModalGrid.BuildingCategory.LocalRegulationsWarning.Italy');

                modalOpts.size = 'lg';
                break;
            default:
                modalOpts.size = 'lg';
                break;
        }
    }

    public customizePopupGridItems(items: IModalGridItem<number>[], design: DesignPe, codeList: number): IModalGridItem<number>[] {
        const elementStyle: IIconStyle = {};
        if (codeList == DesignCodeList.BrickGroup && DesignMethodGroupHelper.IsLrfdBased(design.designMethodGroup?.id)) {
            items = items.map((item): IModalGridItem<number> => ({
                ...item,
                subDescription: (item.description ?? '').includes('Grout-filled') ? '' : item.subDescription
            }));
        }

        const responsiveStyleCodeLists = [
            DesignCodeList.BrickGroup,
            DesignCodeList.BrickLayout,
            DesignCodeList.BuildingCategory,
        ];
        const useResponsiveDesign = responsiveStyleCodeLists.some(x => x == codeList);
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = useResponsiveDesign
                    ? {
                        elementStyle,
                        afterElementStyle: getSpriteAsIconStyleResponsive(item.image)
                    }
                    : {
                        elementStyle: {
                            ...elementStyle,
                            ...getSpriteAsIconStyle(item.image)
                        }
                    };
            }
        });

        return items;
    }

    public getPopupGridHideShowDescriptionOnButton(codeList: number): boolean {
        switch (codeList) {
            case DesignCodeList.SeismicDesignType:
            case DesignCodeList.SeismicTensionType:
            case DesignCodeList.SeismicShearType:
                return true;
            default:
                return false;
        }
    }

    // PopupGridPartial
    public overridePopupGridPartialProps() {
        /* Nothing to do. */
    }

    public customizePopupGridPartialControl(controlProps: IPopupGridPartialProps, codeList: number): void {
        if (codeList == DesignCodeList.HandrailApplication) {
            controlProps.numberOfButtons = 6;
        }
        else {
            controlProps.numberOfButtons = 3;
        }
    }

    public customizePopupGridPartialItems(items: IModalGridItem<number>[], _design: DesignPe, codeList: number): IModalGridItem<number>[] {
        const elementStyle: IIconStyle = {};
        if (codeList == DesignCodeList.HandrailApplication) {
            elementStyle['width'] = '47px';
        }

        const responsiveStyleCodeLists = [
            DesignCodeList.HandrailApplication,
        ];

        const useResponsiveDesign = responsiveStyleCodeLists.some(x => x == codeList);
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = useResponsiveDesign
                    ? {
                        elementStyle,
                        afterElementStyle: getSpriteAsIconStyleResponsive(item.image)
                    }
                    : {
                        elementStyle: {
                            ...elementStyle,
                            ...getSpriteAsIconStyle(item.image)
                        }
                    };
            }
        });

        return items;
    }

    public customizePopupGridPartialModal(modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions, codeList: number): void {
        if (codeList == DesignCodeList.HandrailApplication) {
            modalProps.numberOfColumns = 3;
        }
        else {
            modalOpts.size = 'lg';
        }
    }

    // Integrations
    public overrideDlubalImportExportProps() {
        /* Nothing to do. */
    }

    public overrideSAP2000ImportExportProps() {
        /* Nothing to do. */
    }

    public overrideRobotImportExportProps() {
        /* Nothing to do. */
    }

    public overrideETABSImportExportProps() {
        /* Nothing to do. */
    }

    public overrideStaadProImportExportProps() {
        /* Nothing to do. */
    }

    // ImageNameRadioGroup
    public overrideImageNameRadioGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameRadioGroupItemProps(_design: DesignPe, _navigationControl: UIPropertyBaseControl, item: IImageNameRadioGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public async updateImageNameRadioGroupSortOrder(design: DesignPe, designCodeList: number, newSortOrder: number[]): Promise<void> {
        switch (designCodeList) {
            case DesignCodeList.SmartAnchorCategory:
                await this.smartAnchorOrderService.updateCategorySortOrder(newSortOrder);
                break;
            case DesignCodeList.SmartAnchorApplication:
                await this.smartAnchorOrderService.updateApplicationSortOrder(design.smartAnchorCategory, newSortOrder);
                break;
        }

        // update sort order in database and internal (local)
        // this.smartAnchorOrder.updateItemsSortOrderInternal(newSortOrder, parentId);
    }

    // ImageNameSelectionGroup
    public overrideImageNameSelectionGroupProps() {
        /* Nothing to do. */
    }

    public updateImageNameSelectionGroupItemProps(_design: DesignPe, _navigationControl: ImageNameSelectionGroup, item: IImageNameSelectionGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    // SwitchWithDescription
    public overrideSwitchWithDescriptionProps() {
        /* Nothing to do. */
    }

    // RangeSlider
    public overrideRangeSliderProps() {
        /* Nothing to do. */
    }

    // TabGroup
    public overrideTabGroupProps() {
        /* Nothing to do. */
    }

    public overrideTabGroupItems(controlProps: ITabGroupProps, navigationControl: TabGroup<string>): void {
        // TODO: https://jira.hilti.com/browse/BUDQPI-33
        // It creates Profile/Concrete TabGroup in left menu
        if (navigationControl.Id == 3201) {
            const item1: ITabGroupItem = { displayText: this.localizationService.getString('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.RegionProfile'), value: 1, tag: '3201' };
            const item2: ITabGroupItem = { displayText: this.localizationService.getString('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.RegionConcrete'), value: 2, tag: '3200' };
            controlProps.items = [item1, item2];

            const item3: TabItem = { DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabSmartAnchor.RegionConcrete', Name: 'Concrete', Tag: '3200' };
            const item4: TabItem = { DisplayKey: 'Agito.Hilti.Profis3.Navigation.TabSmartAnchor.RegionProfile', Name: 'Profile', Tag: '3201' };
            navigationControl.Tabs = [item4, item3];
        }
    }

    public getTabGroupItemsByTag(childNavigationControls: UIPropertyBaseControl<string>[], tab: TabItem, navigationControl: TabGroup<string>): UIPropertyBaseControl<string>[] {
        let itemsByTag: MenuControl[] = [];
        // Add child control to the correct tab
        if (navigationControl.Id == 3201) {
            if (tab.Tag == '3200') {
                const controls = [2268, 2269, 2274, 2275];
                itemsByTag = childNavigationControls.filter(x => x.Id != null && (x.Id < 2217 || controls.includes(x.Id)));
            }
            else if (tab.Tag == '3201') {
                const controls = [2217, 2218, 2270, 2271, 2272, 2273];
                itemsByTag = childNavigationControls.filter(x => x.Id != null && controls.includes(x.Id));
            }
        }
        else {
            itemsByTag = childNavigationControls.filter(x => x.ParentControlTag == tab.Tag);
        }
        return itemsByTag;
    }

    public setFooterControlVisibility(design: DesignPe, menu: IMenu): IMenu | undefined {
        if (menu.footer?.controls?.['control-SmartAnchor.AdvancedInput']) {
            if (design.model[UIProperty.SmartAnchor_Enabled] as boolean) {
                menu.footer.controls['control-SmartAnchor.AdvancedInput'].hidden = false;
            } else {
                menu.footer.controls['control-SmartAnchor.AdvancedInput'].hidden = true;
            }
        }
        return menu;
    }

    // Helpers
    private getProjectCodeListItems(navigationControl: UIPropertyBaseControl, design: Design, codeList: ProjectCodeList) {
        let codeListItems: CodeList[];
        if (codeList != null && (codeListItems = this.codeListService.projectCodeLists[codeList]) != null) {
            const uniqCodeListItems = uniqBy(codeListItems, x => x.id);
            if (uniqCodeListItems.length != codeListItems.length) {
                console.error(`Duplicate id found in codelist ${ProjectCodeList[codeList]} for control ${navigationControl.Name}`);
            }

            return uniqCodeListItems;
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    private logMissingCodeList(navigationControl: UIPropertyBaseControl, design: Design) {
        const isVisible = navigationControl.UIPropertyId == null || !design.properties.get(navigationControl.UIPropertyId).hidden;

        if (isVisible) {
            this.loggerService.log(`Missing code list: ${navigationControl.CodelistName}`, LogType.warn);
        }
    }

    private getAnchorEmbedmentDepthText(codeListItem?: AnchorEmbedmentDepth, unit?: UnitType) {
        unit = unit ?? this.userService.design.unitLength;

        const defaultUnit: UnitType = unit;
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(codeListItem?.hef as number, internalUnit, defaultUnit), defaultUnit);
    }

    private getBrickStrengthText(codeListItem: BrickStrength, unit?: UnitType) {
        unit = unit ?? this.userService.design.unitLength;

        const defaultUnit: UnitType = unit;
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Stress);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(codeListItem.fbVertical as number, internalUnit, defaultUnit), defaultUnit);
    }

    private getShearLoadDistributionTypeText(codeListItem: ShearLoadDistributionType, design?: DesignPe) {
        const property = design?.properties.get(UIProperty.Loads_ShearLoadDistributionType) ?? null;
        const text = property?.itemsTexts[codeListItem.id]?.displayKey ?? '';
        return this.localizationService.getString(text);
    }

    private showDropdownAddsOn(navigationControl: DropDown, controlProps: IDropdownProps) {
        const projectCodeList = this.getProjectCodeList(navigationControl.CodelistName ?? '');
        if (projectCodeList == ProjectCodeList.AdvancedCalculationType) {
            controlProps.showAddsOn = !this.featuresVisibilityInfoService.hasAdvancedFeature;
        }
    }

    private getIconStyleForImage(image: string | undefined): IIconStyle | undefined {
        if (image == null || image == '') {
            return undefined;
        }

        return getSpriteAsIconStyle(image);
    }

    private getSmartAnchorMenuTabs() {
        return ['tab-home', 'tab-favorites', 'tab-expand', 'tab-SmartAnchorTab'];
    }

    private formatTextboxValue(value: any, unitGroup: UnitGroup | undefined, uiPropertyId: number, precision?: number, toFixed?: boolean) {
        if (unitGroup) {
            const unitValue = this.unitService.convertInternalValueToDefaultUnitValue(value as number, unitGroup);
            return this.unitService.formatUnitValue(unitValue, precision ?? this.unitService.getPrecision(unitValue.unit, uiPropertyId), undefined, undefined, uiPropertyId, toFixed);
        }

        return this.unitService.formatNumber(this.unitService.parseNumber(value as string), precision ?? this.unitService.getPrecision(UnitType.None, uiPropertyId), toFixed);
    }

    private formatTextboxValueTitle(value: any, unitGroup: UnitGroup | undefined, uiPropertyId: number) {
        const displayedUnitValue = this.formatTextboxValue(value, unitGroup, uiPropertyId);

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const exactUnitValue = this.formatTextboxValue(value, unitGroup, uiPropertyId, maxPrecision);

        if (displayedUnitValue?.length < exactUnitValue?.length) {
            return exactUnitValue;
        }

        return undefined;
    }

    private openOnSiteTests(design: Design) {
        if (design.model[UIProperty.AnchorLayout_EmbedmentDepthOptimizationType] == EmbedmentDepthOptimizationType.UserSelected ||
            design.properties.get(PropertyMetaData.AnchorLayout_EmbedmentDepthOptimizationType.id).hidden) {
            this.modalService.openOnSiteTests();
        }
        else {
            this.modalService.openConfirmChange({
                id: 'on-site-tests-embedment-depth',
                title: this.localizationService.getString('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Title'),
                message: this.localizationService.getString('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Message'),
                confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Confirm'),
                onConfirm: (modal) => {
                    modal.close();
                }
            });
        }
    }

    private async saveSmartDesignTour() {
        this.userSettingsService.settings.smartdesignvirtualtourseen.value = true;
        await this.userSettingsService.save();
    }

    private controlSupportsHoverEffect(navigationControlId: number | undefined): boolean {
        if (navigationControlId == null) {
            return false;
        }

        return MenuServiceExtensions.controlsSupportingHoverEffect.some(c => c === navigationControlId);
    }

    private controlSupportsAlternatingItemsBackground(navigationControlId: number | undefined): boolean {
        if (navigationControlId == null) {
            return false;
        }

        return MenuServiceExtensions.controlsSupportingAlternatingItemsBackground.some(c => c === navigationControlId);
    }
}
