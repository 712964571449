import { HandrailConnectionTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class HandrailConnectionType extends CodeList {
    public static fromService(codeList: HandrailConnectionTypeEntity) {
        return new HandrailConnectionType(CodeList.baseFromService('HandrailConnectionTypeEntity', codeList));
    }

    public override cloneDeep(): HandrailConnectionType {
        return super.cloneDeep() as HandrailConnectionType;
    }
}
