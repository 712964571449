import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    SteelTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface ISteelTypeConstructor extends ICodeListConstructor {
    isStainlessSteel: boolean;
    gamma_m0: number;
    gamma_m1: number;
    gamma_m2: number;
    allowedForSteelGuideline: boolean;
    allowedForRegion: boolean;
}

export class SteelType extends CodeList {

    public isStainlessSteel!: boolean;
    public gamma_m0!: number;
    public gamma_m1!: number;
    public gamma_m2!: number;
    public allowedForSteelGuideline!: boolean;
    public allowedForRegion!: boolean;

    constructor(codeList: ISteelTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.isStainlessSteel = codeList.isStainlessSteel;
            this.gamma_m0 = codeList.gamma_m0;
            this.gamma_m1 = codeList.gamma_m1;
            this.gamma_m2 = codeList.gamma_m2;
            this.allowedForSteelGuideline = codeList.allowedForSteelGuideline;
            this.allowedForRegion = codeList.allowedForRegion;
        }
    }

    public static fromService(codeList: SteelTypeEntity) {
        return new SteelType(CodeList.baseFromService<ISteelTypeConstructor>('SteelTypeEntity', codeList, {
            isStainlessSteel: codeList.IsStainlessSteel,
            gamma_m0: codeList.Gamma_M0,
            gamma_m1: codeList.Gamma_M1,
            gamma_m2: codeList.Gamma_M2,
            allowedForSteelGuideline: codeList.AllowedForSteelGuideline,
            allowedForRegion: codeList.AllowedForRegion
        } as ISteelTypeConstructor));
    }

    public override cloneDeep(): SteelType {
        const codeList: SteelType = super.cloneDeep() as SteelType;

        codeList.isStainlessSteel = this.isStainlessSteel;
        codeList.gamma_m0 = this.gamma_m0;
        codeList.gamma_m1 = this.gamma_m1;
        codeList.gamma_m2 = this.gamma_m2;
        codeList.allowedForSteelGuideline = this.allowedForSteelGuideline;
        codeList.allowedForRegion = this.allowedForRegion;

        return codeList;
    }
}
