import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { EmbedmentOptimizationTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class EmbedmentOptimizationType extends CodeList {

    public static fromService(codeList: EmbedmentOptimizationTypeEntity) {
        return new EmbedmentOptimizationType(CodeList.baseFromService<ICodeListConstructor>('EmbedmentOptimizationTypeEntity', codeList));
    }

    public override cloneDeep(): EmbedmentOptimizationType {
        return super.cloneDeep() as EmbedmentOptimizationType;
    }
}
