import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { AnchorEmbedmentDepthEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IAnchorEmbedmentDepthConstructor extends ICodeListConstructor {
    hef?: number;
}

export class AnchorEmbedmentDepth extends CodeList {
    public hef?: number;

    constructor(codeList: IAnchorEmbedmentDepthConstructor) {
        super(codeList);

        if (codeList != null) {
            this.hef = codeList.hef;
        }
    }

    public static fromService(codeList: AnchorEmbedmentDepthEntity) {
        return new AnchorEmbedmentDepth(CodeList.baseFromService<IAnchorEmbedmentDepthConstructor>('AnchorEmbedmentDepthEntity', codeList, {
            hef: codeList.Hef
        } as IAnchorEmbedmentDepthConstructor));
    }

    public override cloneDeep() {
        const codeList: AnchorEmbedmentDepth = super.cloneDeep() as AnchorEmbedmentDepth;
        codeList.hef = this.hef;

        return codeList;
    }
}
