export interface SharedEnvironmentData {
    readonly translate: boolean;
    readonly baseUrl: string;
    readonly useDevFeatures: boolean;
    readonly applicationVersion: string;
    readonly enableMenuFavorites: boolean;

    readonly externalQuantityCalculatorApplicationUrl: string;
    readonly thirdPartyInterfaceDownloadUrl: string;

    readonly integrationServicesServerEnabled: boolean;
    readonly integrationServicesServerUrl: string;

    readonly userSettingsWebServiceUrl: string;
}

export function fromService(data: SharedEnvironmentData): SharedEnvironmentData {
    return {
        translate: data.translate,
        baseUrl: data.baseUrl,
        useDevFeatures: data.useDevFeatures,
        applicationVersion: data.applicationVersion,
        enableMenuFavorites: data.enableMenuFavorites,

        externalQuantityCalculatorApplicationUrl: data.externalQuantityCalculatorApplicationUrl,
        thirdPartyInterfaceDownloadUrl: data.thirdPartyInterfaceDownloadUrl,

        integrationServicesServerEnabled: data.integrationServicesServerEnabled,
        integrationServicesServerUrl: data.integrationServicesServerUrl,

        userSettingsWebServiceUrl: data.userSettingsWebServiceUrl
    };
}
