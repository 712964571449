import {
    InvokeOptions, SignalRConnectionBase, SignalRServiceBase
} from '@profis-engineering/pe-ui-common/services/signalr.common';
import {
    OptimizeDesignInput, OptimizeDesignOutput
} from '../entities/signalr';

export abstract class SignalRConnectionPe extends SignalRConnectionBase {
    public abstract asadOptimize(request: OptimizeDesignInput, options: InvokeOptions): Promise<OptimizeDesignOutput>;

    public abstract get connectionUrl(): string;
    public abstract get requestData(): string | object;
    public abstract get responseData(): string | object;
    public abstract get requestId(): string;
}

/**
 * SignalRService base
 */
export abstract class SignalRServicePeBase extends SignalRServiceBase {
    public abstract common: SignalRConnectionPe;
    public abstract asad: SignalRConnectionPe;
}
