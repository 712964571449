<div id="smart-baseplate-section" class="smart-baseplate-design box-section box-section-white"
    [ngClass]="showSolutionsList ? 'smart-baseplate-design-height' : 'blank-panel-height'"
    [class.no-border-bottom]="((!showExpandedPanel && resultsFound) || (startedSearch  && !optimizing)) && !cancelClicked">
    <pe-resizer [disabled]="!showExpandedPanel || !showSolutionsList"
        [targetHtmlElement]="smartBaselateSectionContainer" [parentHtmlElement]="parentElement" [correction]="-16"
        (sizeChange)="onResize()">
        <div class="smart-baseplate-design-content">
            <div class="box-section-content">
                <div class="buttons">
                    <button class="button-anchor-ai" (click)="optimize()"
                        [disabled]="(optimizing) || !isAsadCalculable || isLoading">
                        <span *ngIf="!optimizing"
                            [ngClass]="!isAsadCalculable || isLoading ? 'pe-ui-pe-sprite-search' : 'pe-ui-pe-sprite-search-red'"
                            class="image pe-ui-pe-sprite"></span>
                        <pe-loading *ngIf="optimizing" class="optimize-loader" [width]="36" [height]="9"></pe-loading>
                        <span *ngIf="!optimizing"
                            [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.SearchSolutions.Button')"></span>
                        <span *ngIf="optimizing" class="margin-left-10"
                            [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.SearchSolutions.Button2')"></span>
                    </button>
                    <button *ngIf="optimizing" class="button-anchor-ai cancel-searching" (click)="cancelOptimization()">
                        <span>&times;</span>
                    </button>
                </div>
            </div>

            <div class="after-optimizing" *ngIf="showSolutionsList">
                <div class="preferences-and-sort-by">
                    <div class="button button-primary preferences-button" (click)="openPreference()">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" transform="translate(4 4)" fill="white" fill-opacity="0.01" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M19.642 14.25H17.8206V10H19.642V11.2143H22.0706V13.0357H19.642V14.25ZM9.92773 13.0357V11.2143H16.6063V13.0357H9.92773ZM12.3563 18.4643V17.25H14.1777V21.5H12.3563V20.2857H9.92773V18.4643H12.3563ZM22.0705 20.2857V18.4643H15.392V20.2857H22.0705Z"
                                fill="white" />
                        </svg>
                    </div>
                    <div class="content-controls sort-by-button">
                        <pe-dropdown class="control" [id]="anchorAIResultSortDropdown.id"
                            [items]="anchorAIResultSortDropdown.items"
                            [notSelectedText]="anchorAIResultSortDropdown.notSelectedText"
                            [selectedValue]="anchorAIResultSortDropdown.selectedValue"
                            (selectedValueChange)="setSortByCondition($any($event).detail, true)">
                        </pe-dropdown>
                    </div>
                    <div [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')" class="expand-collapse"
                        (click)="expandCollapsePanel()">
                        <span *ngIf="!showExpandedPanel" class="pe-ui-pe-sprite pe-ui-pe-sprite-lines-expanded"></span>
                        <span *ngIf="showExpandedPanel" class="pe-ui-pe-sprite pe-ui-pe-sprite-lines"></span>
                    </div>
                </div>
                <div *ngIf="tcoEnabled">
                    <div class="tco-checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="onlyShowResultsWithTco" (change)="this.filter(this.filterParameter)"/>
                            Only show results with TCO
                        </label>
                    </div>
                </div>
            </div>

            <div class="last-selected-solution" *ngIf="showLastSelectedSolution">
                    <div class="image-section">
                        <div>
                            <span
                                class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-{{getImage(lastSelectedSolution.optimizeCaseDetails?.fastenerFamilyId)}}"></span>
                        </div>
                        <span class="anchor-name"
                            [innerHtml]="getName(lastSelectedSolution.optimizeCaseDetails?.partialFamilyId, lastSelectedSolution.optimizeCaseDetails?.fastenerId, lastSelectedSolution.optimizeCaseOutput?.embedmentDepth)"></span>
                    </div>
                    <div class="utilization-section">
                        <span class="utlization-span" [innerHtml]="getUtilization(lastSelectedSolution) + '%'"></span>
                        <span class="back-border"
                            class="number-of-anchors-by-image no-of-anchors-span image pe-ui-pe-sprite pe-ui-pe-sprite-anchor-ai-40-{{lastSelectedSolution.optimizeCaseDetails?.numberOfAnchors ?? 0}}"></span>
                    </div>
                    <div class="text-wrapper">
                        <div class="anchor-plate">
                            <span>{{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.AnchorPlateSize')}}
                                ({{currentUnitValue}})</span>
                            <span class="font-bold">{{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.Length')}}:
                                {{formatLength(lastSelectedSolution.optimizeCaseOutput?.anchorPlateHeight)}} x
                                {{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.Width')}}:
                                {{formatLength(lastSelectedSolution.optimizeCaseOutput?.anchorPlateWidth)}} x
                                {{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.Thickness')}}:
                                {{formatLength(anchorPlateThickness)}}</span>
                        </div>
                        <div class="material">
                            <span
                                [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.CorrosionsMaterials')"></span>
                            <span class="font-bold">{{getSelectedConstructionOptionName(lastSelectedSolution)}}</span>
                        </div>
                        <div class="requirement-and-features flex-row">
                            <span class="requirement-width"
                                [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.RequirementFeatures')"></span>
                            <div class="bulb-icon mar-top-m-5" (click)="openSolutionDetail(lastSelectedSolution)">
                                <span class="pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                            </div>
                        </div>
                    </div>
            </div>

            <div class="results-list" *ngIf="showExpandedPanel && showSolutionsList">
                <div (click)="apply(result)" class="optimize-solution"
                    *ngFor="let result of visibleOptimized; index as i; let last = last"
                    [ngClass]="{'last-child': last, 'item-selected': result == appliedResult}">

                    <div class="result-item">
                        <div class="item-image-section" (click)="itemClick(result)">
                            <div>
                                <span
                                    class="item-image pe-ui-pe-sprite sprite-responsive pe-ui-pe-{{getImage(result.optimizeCaseDetails?.fastenerFamilyId)}}"></span>
                            </div>
                            <span class="anchor-name"
                                [innerHtml]="getName(result.optimizeCaseDetails?.partialFamilyId, result.optimizeCaseDetails?.fastenerId, result.optimizeCaseOutput?.embedmentDepth)"></span>
                        </div>
                        <div class="item-utilization-section" (click)="itemClick(result)">
                            <span class="utlization-span" [innerHtml]="getUtilization(result) + '%'"></span>
                            <span [class.back-border]="result == appliedResult"
                                class="number-of-anchors-by-image no-of-anchors-span image pe-ui-pe-sprite pe-ui-pe-sprite-anchor-ai-40-{{result.optimizeCaseDetails?.numberOfAnchors ?? 0}}"></span>
                        </div>
                        <!-- This div is a dirty implementation of BUSCHKBT-38 and will be changed in the future -->
                        <div *ngIf="tcoEnabled">
                            <div *ngIf="result.optimizeCaseOutput?.TCO">
                                <span style="padding-right: 5px;">TCO: {{result.optimizeCaseOutput?.TCO?.toFixed(2)}}EUR</span><br/>
                                <span style="padding-right: 5px;">Baseplate: {{calculateTcoPercentage(result.optimizeCaseOutput?.BaseplateCosts, result.optimizeCaseOutput?.TCO)}}%</span>
                                <span style="padding-right: 5px;">Anchor: {{calculateTcoPercentage(result.optimizeCaseOutput?.AnchorsCosts, result.optimizeCaseOutput?.TCO)}}%</span><br/>
                                <span style="padding-right: 5px;">Installation: {{calculateTcoPercentage(result.optimizeCaseOutput?.InstallationCosts, result.optimizeCaseOutput?.TCO)}}%</span>
                                <span style="padding-right: 5px;">Time: {{secondsToHumanReadible(result.optimizeCaseOutput?.InstallationTime)}}</span><br/>
                            </div>
                            <div *ngIf="!result.optimizeCaseOutput?.TCO">
                                <span style="padding-right: 5px;">No TCO data</span>
                            </div>
                        </div>
                        <div class="expanded-item expanded-item-flex"
                            *ngIf="expandedItemIndex == result.optimizeCaseId">
                            <div class="expanded-item-flex mar-top-10">
                                <span>{{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.AnchorPlateSize')}}
                                    ({{currentUnitValue}})</span>
                                <span
                                    class="font-bold">{{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.Length')}}:
                                    {{formatLength(result.optimizeCaseOutput?.anchorPlateHeight)}} x
                                    {{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.Width')}}:
                                    {{formatLength(result.optimizeCaseOutput?.anchorPlateWidth)}} x
                                    {{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.Thickness')}}:
                                    {{formatLength(anchorPlateThickness)}}</span>
                            </div>
                            <div class="expanded-item-flex mar-top-10">
                                <span
                                    [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.CorrosionsMaterials')"></span>
                                <span class="font-bold">{{getSelectedConstructionOptionName(result)}}</span>
                            </div>
                            <div class="expanded-item-flex flex-row mar-top-10">
                                <span class="requirement-width"
                                    [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.RequirementFeatures')"></span>
                                <div class="bulb-icon mar-top-m-5" (click)="openSolutionDetail(result)">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-result-found" *ngIf="startedSearch && !optimizing && !cancelClicked">
                <div class="not-found-text">
                    <div class="no-text">
                        <span style="margin-top: -4px;" class="pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                        <span>{{translate('Agito.Hilti.Profis3.SmartBasePlate.SearchSolutions.NotFound')}}
                        </span>
                    </div>
                    <button class="not-found-button"
                        (click)="startedSearch = false;">{{translate('Agito.Hilti.Profis3.SmartBasePlate.SearchSolutions.Button.Close')}}</button>

                </div>
            </div>
            <div class="filter-panel" *ngIf="showSolutionsList" [hidden]="!showFilter">
                <pe-smart-baseplate-filter [filterParameters]="filterParameter"
                    (baseplateFilterChange)="baseplateFilterChange($any($event).detail)">
                </pe-smart-baseplate-filter>
            </div>

            <div class="bottom" *ngIf="(showExpandedPanel && showSolutionsList) || showLastSelectedSolution">
                <div class="last-selected-solution" *ngIf="showLastSelectedSolution">
                    <p class="text">{{translate('Agito.Hilti.Profis3.SmartBasePlate.LastSelectedSolution')}}</p>
                </div>

                <div class="results-of-text-container" *ngIf="!showLastSelectedSolution">
                    <p class="results-of-text" [innerHtml]="visibleOptimized.length"></p>
                    <p class="results-of-text">{{translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionsFound')}}</p>
                </div>
            </div>
        </div>
    </pe-resizer>
</div>
