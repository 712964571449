import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { CanvasContext3d } from '@profis-engineering/gl-model/canvas-context-3d';
import { IPointResult } from '@profis-engineering/gl-model/components/base-component';
import {
    getText2DFromGlobal, getText2DString, isText2DEnabled, updateText2D
} from '@profis-engineering/gl-model/consoleUpdatePropertyMode';
import { RenderType, View2dModeType } from '@profis-engineering/gl-model/gl-model';
import { calculateLoadsVisibilityInfo } from '@profis-engineering/pe-gl-model/components/handrail-base-material-helper';
import { getOrCreateContext3d } from '@profis-engineering/pe-gl-model/context-3d';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { MathService } from '../../services/math.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { TooltipService } from '../../services/tooltip.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { GLEvent, GLModelBaseComponent } from './GLModelBase';
import { Center2dUpdate } from './Update/2D/Center2dUpdate';
import { AnchorUpdate } from './Update/AnchorUpdate';
import { ConcreteBaseMaterialUpdate } from './Update/ConcreteBaseMaterialUpdate';
import { CoordinateSystemUpdate } from './Update/CoordinateSystemUpdate';
import { ForceUpdate } from './Update/ForceUpdate';
import { HandrailBaseMaterialUpdate } from './Update/HandrailBaseMaterialUpdate';
import { HandrailUpdate } from './Update/HandrailUpdate';
import { MasonryBaseMaterialUpdate } from './Update/MasonryBaseMaterialUpdate';
import { MetalDeckBaseMaterialUpdate } from './Update/MetalDeckBaseMaterialUpdate';
import { MomentUpdate } from './Update/MomentUpdate';
import { PlateDimensionsUpdate } from './Update/PlateDimensionsUpdate';
import { PlateUpdate } from './Update/PlateUpdate';
import { ProfileUpdate } from './Update/ProfileUpdate';
import { SceneCoordinateSystemUpdate } from './Update/SceneCoordinateSystemUpdate';
import { StiffenerUpdate } from './Update/StiffenerUpdate';
import { SupplementaryReinforcementUpdate } from './Update/SupplementaryReinforcementUpdate';
import { environment } from '../../../environments/environmentPe';
import { DesignPe } from '../../../shared/entities/design-pe';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { Update } from '@profis-engineering/gl-model/base-update';
import { IGlModelPeComponent } from '../../../shared/components/gl-model';
import { GLModelPe, GLModelUpdatePe, IModelPe, IScreenShotSettingsPe, DesignType, IResetRotation, CheckbotVector3, IomParameter, Cut, BeamGlModelEntity, StiffeningPlateGlModelEntity, CheckbotWeld, CheckbotBolt } from '@profis-engineering/pe-gl-model/gl-model';
import { ShearLugUpdate } from './Update/ShearLugUpdate';
import { WeldsUpdate } from './Update/WeldsUpdate';

import { GLImages } from '@profis-engineering/gl-model/images';
import existingStructureConcrete from '@profis-engineering/gl-model/images/existing-concrete.jpg';
import fontTexture from '../../gl-model/font-texture';
import newStructureConcrete from '@profis-engineering/gl-model/images/new-concrete.jpg';
import reinforcement from '@profis-engineering/gl-model/images/rebar.jpg';
import { MaterialCachePe } from '@profis-engineering/pe-gl-model/cache/material-cache';
import { MeshCachePe } from '@profis-engineering/pe-gl-model/cache/mesh-cache';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { Vector2 } from '@babylonjs/core/Maths/math.vector.js';

export interface IPointsEditorPoint {
    id: number;
    x: number;
    y: number;
}

const enum Stepper {
    increment,
    decrement
}

@Component({
    templateUrl: './gl-model.component.html',
    styleUrls: ['./gl-model.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class GlModelComponent extends GLModelBaseComponent<GLModelPe> implements OnDestroy, IGlModelPeComponent {

    @Input()
    public zoomToFit = (extraInfo: IScreenShotSettingsPe) => this.zoomToFitInternal(extraInfo);
    @Input()
    public update = (model: IModelPe, replace?: boolean, changeDetection?: boolean) => this.updateInternal(model, replace, changeDetection);
    @Input()
    public getModel = () => this.getModelInternal();
    @Input()
    public clearSelected = () => this.clearSelectedInternal();
    @Input()
    public moveAnchorPoint2d = (index: number, x: number, y: number) => this.moveAnchorPoint2dInternal(index, x, y);
    @Input()
    public movePlatePoint2d = (index: number, x: number, y: number) => this.movePlatePoint2dInternal(index, x, y);
    @Input()
    public deleteAnchorPoint2d = (index: number) => this.deleteAnchorPoint2dInternal(index);
    @Input()
    public deletePlatePoint2d = (index: number) => this.deletePlatePoint2dInternal(index);
    @Input()
    public addAnchorPoint2d = (x: number, y: number) => this.addAnchorPoint2dInternal(x, y);
    @Input()
    public addPlatePoint2d = (x: number, y: number) => this.addPlatePoint2dInternal(x, y);
    @Input()
    public resizeNextFrame = (which?: number, renderCount?: number) => this.resizeNextFrameInternal(which, renderCount);
    @Input()
    public resetRotation = (update?: IResetRotation) => this.resetRotationInternal(update);
    @Input()
    public resetCamera = () => this.resetCameraInternal();
    @Input()
    public createDesignScreenshot = (extraInfo: IScreenShotSettingsPe) => this.createDesignScreenshotInternal(extraInfo);
    @Input()
    public createDesignScreenshot2D = (extraInfo: IScreenShotSettingsPe, view2dMode: View2dModeType) => this.createDesignScreenshot2DInternal(extraInfo, view2dMode);
    @Input()
    public unselectAllPlatePoints = () => this.unselectAllPlatePointsInternal();
    @Input()
    public propertyValueChanged = (changes: Change[], design: DesignPe, update: Update, model?: IModelPe) => this.propertyValueChangedInternal(changes, design, update, model);
    @Input()
    public resetHighlightedComponents = () => this.resetHighlightedComponentsInternal();
    @Input()
    public cameraZoomIn = () => this.cameraZoomInInternal();
    @Input()
    public cameraZoomOut = () => this.cameraZoomOutInternal();
    @Input()
    public cameraZoom = (percentage: number) => this.cameraZoomInternal(percentage);
    @Input()
    public weldClicked = new EventEmitter<string>();

    @Output()
    public fontsLoaded = new EventEmitter<void>();

    @Output()
    public zoom = new EventEmitter<number>();

    @Output()
    public selectTab = new EventEmitter<string>();

    @Output()
    public positionsChanged = new EventEmitter<Record<string, boolean>>();

    @Output()
    public draggingSelectionChanged = new EventEmitter<boolean>();


    public Stepper: Record<keyof typeof Stepper, Stepper> = {
        increment: Stepper.increment,
        decrement: Stepper.decrement
    };

    private textEditor!: HTMLInputElement | undefined;

    constructor(
        unitService: UnitService,
        numberService: NumberService,
        localizationService: LocalizationService,
        userService: UserService,
        mathService: MathService,
        tooltipService: TooltipService,
        modalService: ModalService,
        userSettingsService: UserSettingsService,
        calculationServicePE: CalculationServicePE,
        changeDetection: ChangeDetectorRef,
        ngZone: NgZone,
        sharedEnvironmentService: SharedEnvironmentService,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        super(
            unitService,
            numberService,
            localizationService,
            userService,
            mathService,
            tooltipService,
            modalService,
            userSettingsService,
            calculationServicePE,
            changeDetection,
            ngZone,
            sharedEnvironmentService
        );

        if (typeof window == 'object') {
            (window as any).updateText2D = updateText2D;
            (window as any).getText2DFromGlobal = getText2DFromGlobal;
            (window as any).getText2DString = getText2DString;
            (window as any).isText2DEnabled = isText2DEnabled;
        }
    }

    public override ngOnDestroy(): void {
        this.textEditor?.remove();

        super.ngOnDestroy();
    }

    public clearSelectedInternal() {
        this.glModel3d.clearSelected();
    }

    public async createDesignScreenshotInternal(extraInfo: IScreenShotSettingsPe): Promise<string> {
        if (extraInfo.loadsVisibilityInfo == null) {
            const combination = this.getCombination();
            const loadCaseId = (this.design.designType.id == DesignType.Handrail && this.design.designData.reportData?.LoadCombinationItems != null && this.design.designData.reportData.DecisiveLoadCombinationIndex != null) ? this.design.designData.reportData.LoadCombinationItems[this.design.designData.reportData.DecisiveLoadCombinationIndex].Id : null;
            const modifyLoads: boolean = (this.design.designType.id == DesignType.Handrail) ? !this.design.showAllLoads : true;

            if (combination != null) {
                extraInfo.loadsVisibilityInfo = {
                    designType: this.design.designType.id ?? 0,
                    modifyLoads,
                    preview: false,
                    forceX: combination.ForceX,
                    forceY: combination.ForceY,
                    forceZ: combination.ForceZ,
                    momentX: combination.MomentX,
                    momentY: combination.MomentY,
                    momentZ: combination.MomentZ,
                    ...calculateLoadsVisibilityInfo(loadCaseId ?? '')
                };
            }
            else {
                extraInfo.loadsVisibilityInfo = {
                    designType: this.design.designType.id ?? 0,
                    modifyLoads: false,
                    preview: false,
                    showInwardsWindLoad: false,
                    showLinearInwardsLoad: false,
                    showLinearOutwardsLoad: false,
                    showOutwardsWindLoad: false,
                    showVerticalLoad: false
                };
            }
        }

        return await this.glModel3d.createDesignScreenshot(extraInfo);
    }

    private getCombination() {
        const loadCombinationsVisibility = this.design.loadCombinations != null && this.design.loadCombinations?.length > 1 && this.design.isLoadCombinationActive;
        const loadCombinationsSelected = this.design.loadCombinations?.find((loadCombination) => loadCombination.Id == this.design.reportLoadCombination);

        if (loadCombinationsVisibility) {
            this.design.reportLoadCombination = loadCombinationsSelected?.Id ?? '';
        }

        const combinationsVisibility = loadCombinationsVisibility ? loadCombinationsSelected : null;
        return (this.design.designType.id == DesignType.Handrail && this.design.designData.reportData?.DecisiveLoadCombinationIndex != null) ?
            this.design.loadCombinations?.[this.design.designData.reportData.DecisiveLoadCombinationIndex] :
            combinationsVisibility;
    }

    public async createDesignScreenshot2DInternal(extraInfo: IScreenShotSettingsPe, view2dMode: View2dModeType): Promise<string> {
        return await this.glModel3d.createDesignScreenshot2D(extraInfo, view2dMode);
    }

    public zoomToFitInternal(extraInfo: IScreenShotSettingsPe) {
        this.glModel3d.zoomToFit(extraInfo);
    }

    public addPlatePoint2dInternal(x: number, y: number): IPointResult | undefined {
        return this.glModel3d.addPlatePoint2d(x, y);
    }

    public movePlatePoint2dInternal(index: number, x: number, y: number): IPointResult | undefined {
        return this.glModel3d.movePlatePoint2d(index, x, y);
    }

    public deletePlatePoint2dInternal(index: number): IPointResult | undefined {
        return this.glModel3d.deletePlatePoint2d(index);
    }

    public addAnchorPoint2dInternal(x: number, y: number): IPointResult | undefined {
        return this.glModel3d.addAnchorPoint2d(x, y);
    }

    public resetRotationInternal(update?: IResetRotation) {
        this.glModel3d.resetRotation(update);
    }

    public moveAnchorPoint2dInternal(index: number, x: number, y: number): IPointResult | undefined {
        return this.glModel3d.moveAnchorPoint2d(index, x, y);
    }

    public deleteAnchorPoint2dInternal(index: number): IPointResult | undefined {
        return this.glModel3d.deleteAnchorPoint2d(index);
    }

    public unselectAllPlatePointsInternal() {
        this.glModel3d.unselectAllPlatePoints();
    }

    public resetHighlightedComponentsInternal() {
        this.glModel3d.resetHighlightedComponents();
    }

    public stepperClick(stepper: Stepper) {
        const unitValue = this.unitService.parseUnknownUnitValue(this.glModel3d.textEditorValue ?? '');

        if (unitValue != null && !Number.isNaN(unitValue.value)) {
            const stepValue = this.unitService.incDecValueByUnit(unitValue.unit);
            unitValue.value = (stepper == Stepper.increment ? unitValue.value + stepValue : unitValue.value - stepValue);

            this.glModel3d.textEditorValue = this.unitService.formatUnitValue(unitValue);
            this.glModel3d.textEditorValueChange();
        }
    }

    protected createGLModelBase3D(): GLModelPe {
        const nativeElement = this.elementRef.nativeElement;
        this.textEditor = nativeElement.shadowRoot?.querySelector<HTMLInputElement>('.gl-model-text-editor') ?? undefined;

        setTimeout(() => {
            // canvas is not the correct size yet so we wait
            this.resizeInternal();

            // append the text editor to body
            if (this.textEditor) {
                document.body.appendChild(this.textEditor);
            }
        });

        const modelUpdate: GLModelUpdatePe = {
            center2dCtor: Center2dUpdate,
            anchorCtor: AnchorUpdate,
            concreteBaseMaterialCtor: ConcreteBaseMaterialUpdate,
            coordinateSystemCtor: CoordinateSystemUpdate,
            forceCtor: ForceUpdate,
            handrailCtor: HandrailUpdate,
            handrailBaseMaterialCtor: HandrailBaseMaterialUpdate,
            masonryBaseMaterialCtor: MasonryBaseMaterialUpdate,
            metalDeckBaseMaterialCtor: MetalDeckBaseMaterialUpdate,
            momentCtor: MomentUpdate,
            plateCtor: PlateUpdate,
            plateDimensionsCtor: PlateDimensionsUpdate,
            profileCtor: ProfileUpdate,
            shearLugCtor: ShearLugUpdate,
            weldsCtor: WeldsUpdate,
            sceneCoordinateSystemCtor: SceneCoordinateSystemUpdate,
            supplementaryReinforcementCtor: SupplementaryReinforcementUpdate,
            stiffenerCtor: StiffenerUpdate
        };

        const images: GLImages = {
            existingStructureConcrete,
            newStructureConcrete,
            reinforcement,
            fontTexture
        };

        const context3d = getOrCreateContext3d(this.context3dKey, () => new CanvasContext3d(this.context3dKey, MaterialCachePe, MeshCachePe));
        context3d.parentContainer = nativeElement;

        this.mapCheckbotBeams();
        this.mapCheckbotPlates();
        this.mapCheckbotWelds();
        this.mapCheckbotBolts();

        // run outside angular zone because of requestAnimationFrame
        return this.ngZone.runOutsideAngular(() => new GLModelPe({
            showDebugLayer: environment.debugGlModel,
            context3d: context3d,
            images: images,
            renderType: this.continuousRender ? RenderType.Continuous : RenderType.Auto,
            textEditor: this.textEditor,
            // we bind glModel values in html (like glModel3d.textEditorVisible) so we need to call angular change detection to check for html changes
            textEditorChange: () => this.changeDetection.detectChanges(),
            unitConverter: this.glUnitConverter,
            propertyInfo: this.glPropertyInfo,
            mathCalculator: this.glMathCalculator,
            tooltip: this.glTooltip,
            eventNotifier: this.glEventNotifier,
            imageMeshProvider: this.glImageMeshProvider,
            inputSettings: this.glInputSettings,
            model: this.model,
            modelUpdate: modelUpdate
        }));
    }

    protected override onEvent(event: GLEvent, eventArgs?: unknown) {
        NgZone.assertInAngularZone();

        switch (event) {
            case 'Fonts_Loaded':
                this.fontsLoaded.next();
                break;
            case 'Zoom':
                this.zoom.next(eventArgs as number);
                break;
            case 'Select_Tab':
                this.selectTab.next(eventArgs as string);
                break;
            case 'positionsChanged':
                this.positionsChanged.next(eventArgs as Record<string, boolean>);
                break;
            case 'draggingSelectionChanged':
                this.draggingSelectionChanged.next(eventArgs as boolean);
                break;
            case 'weldClicked':
                this.weldClicked.next(eventArgs as string);
                break;
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private mapCheckbotBeams(){
        // model.beams are not updated from UIProperty because they are not expected to change from user input.
        if (this.design.projectDesign?.Beams) {
            this.model.beams = this.design.projectDesign?.Beams.map(beam => {
                return {
                    startPosition: {
                        x: beam.StartPosition.X,
                        y: beam.StartPosition.Y,
                        z: beam.StartPosition.Z,
                    } as CheckbotVector3,
                    endPosition: {
                        x: beam.EndPosition.X,
                        y: beam.EndPosition.Y,
                        z: beam.EndPosition.Z,
                    } as CheckbotVector3,
                    name: beam.BeamName,
                    profileParameters: (beam.ProfileParameters ?? []).map(parameter => {
                        return {
                            name: parameter.Name,
                            value: parameter.Value
                        } as IomParameter;
                    }),
                    shape: beam.BeamShape,
                    cuts: beam.Cuts.map(cut => {
                        return {
                            planePoint: {
                                x: cut.PlanePoint.X,
                                y: cut.PlanePoint.Y,
                                z: cut.PlanePoint.Z,
                            } as CheckbotVector3,
                            normalVector: {
                                x: cut.NormalVector.X,
                                y: cut.NormalVector.Y,
                                z: cut.NormalVector.Z,
                            } as CheckbotVector3
                        } as Cut;
                    })
                } as BeamGlModelEntity;
            }) ?? [];
        } else {
            this.model.beams = [];
        }
    }

    private mapCheckbotPlates() {
        // model.plates are not updated from UIProperty because they are not expected to change from user input.
        if (this.design.projectDesign?.Plates) {
            this.model.plates = this.design.projectDesign?.Plates.map(plate => {
                return {
                    name: plate.Name,
                    origin: {
                        x: plate.Origin.X,
                        y: plate.Origin.Y,
                        z: plate.Origin.Z
                    } as CheckbotVector3,
                    thickness: plate.Thickness,
                    outlinePoints: plate.OutlinePoints.map(point => {
                        return new Vector2(point.X, point.Y);
                    })
                } as StiffeningPlateGlModelEntity;
            });
        }
    }

    private mapCheckbotWelds() {
        // model.checkbotWelds are not updated from UIProperty because they are not expected to change from user input.
        if (this.design.projectDesign?.CheckbotWelds) {
            this.model.checkbotWelds = this.design.projectDesign?.CheckbotWelds.map(weld => {
                return {
                    startPosition: {
                        x: weld.StartPosition.X,
                        y: weld.StartPosition.Y,
                        z: weld.StartPosition.Z
                    },
                    endPosition: {
                        x: weld.EndPosition.X,
                        y: weld.EndPosition.Y,
                        z: weld.EndPosition.Z
                    }
                } as CheckbotWeld;
            });
        }
    }

    private mapCheckbotBolts() {
        // model.checkbotBolts are not updated from UIProperty because they are not expected to change from user input.
        if (this.design.projectDesign?.CheckbotBolts) {
            this.model.checkbotBolts = this.design.projectDesign?.CheckbotBolts.map(bolt => {
                return {
                    origin: {
                        x: bolt.Origin.X,
                        y: bolt.Origin.Y,
                        z: bolt.Origin.Z
                    },
                    length: bolt.Length,
                    diameter: bolt.Diameter
                } as CheckbotBolt;
            });
        }
    }
}

