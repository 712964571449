import cloneDeep from 'lodash-es/cloneDeep';

import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import { DesignPe, ReportData } from '../../shared/entities/design-pe';
import {
    LoadCombination
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { RegionHub } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { LocalizationService } from '../services/localization.service';

export function concatFilterLoadCombinations(existingCombinations: LoadCombination[], newCombinations: LoadCombination[], maxLoadCombinations: number, checkDynamic: boolean) {
    // Filter existing Load Combinations (also, check dynamic loads!)
    const existingFiltered = cloneDeep(existingCombinations || [])
        .filter((lc) => loadCombinationHasValue(lc, checkDynamic));

    // Filter new Load Combinations
    const newFiltered = newCombinations
        .filter((lc) => loadCombinationHasValue(lc, checkDynamic));

    // Concatenate the two and consider the max Load Combinations limitation
    const retVal = [...existingFiltered, ...newFiltered]
        .slice(0, maxLoadCombinations);

    // Ensure at least one Load Combination is left
    if (retVal.length < 1) {
        if (existingCombinations.length > 0) {
            retVal.push(existingCombinations[0]);
        }
        else if (newCombinations.length > 0) {
            retVal.push(newCombinations[0]);
        }
    }
    return retVal;
}

export function loadCombinationHasValue(loadCombination: LoadCombination, checkDynamic: boolean) {
    let retVal = isValueNotEmpty(loadCombination.ForceX) ||
        isValueNotEmpty(loadCombination.ForceY) ||
        isValueNotEmpty(loadCombination.ForceZ) ||
        isValueNotEmpty(loadCombination.MomentX) ||
        isValueNotEmpty(loadCombination.MomentY) ||
        isValueNotEmpty(loadCombination.MomentZ);

    if (!retVal && checkDynamic) {
        retVal = retVal ||
            isValueNotEmpty(loadCombination.DynamicForceX) ||
            isValueNotEmpty(loadCombination.DynamicForceY) ||
            isValueNotEmpty(loadCombination.DynamicForceZ) ||
            isValueNotEmpty(loadCombination.DynamicMomentX) ||
            isValueNotEmpty(loadCombination.DynamicMomentY) ||
            isValueNotEmpty(loadCombination.DynamicMomentZ);
    }

    return retVal;
}

export function areLoadCombinationsAvailable(reportData: ReportData, overrideLoadCombinationsEnabled = false) {
    if (reportData == null) {
        return false;
    }

    return reportData.LoadCombinationCount > 1
        &&
        (
            reportData.LoadCombinationsEnabled
            ||
            reportData.IsHandrailCBFEMCalculation
            ||
            overrideLoadCombinationsEnabled
        );
}

export function getLoadCombinationNumberText(design: DesignPe, localizationService: LocalizationService, loadCombinationIndex: number, cbfemOnly: boolean) {
    if (design.isCBFEMCalculation || design.isHandrailCBFEMCalculation) {
        return getLoadCombinationNumberTextInternal(localizationService, loadCombinationIndex);
    }

    if (!cbfemOnly) {
        const loadCombinationId = design.selectedLoadCombinationId;
        if (loadCombinationId == null) {
            // This can happen if there are multiple decisive load combinations (e.g. for Handrail one load
            // combination is decisive for anchor design and another for handrail design)

            // Try obtaining load combination id using loadCombinationIndex!
            if (loadCombinationIndex == null || design.loadCombinations.length <= loadCombinationIndex) {
                return null;
            }

            return getLoadCombinationNumberTextInternal(localizationService, loadCombinationIndex);
        }

        return getLoadCombinationNumberTextById(design, localizationService, loadCombinationId);
    }

    return null;
}

export function getLoadCombinationNumberTextById(design: DesignPe, localizationService: LocalizationService, loadCombinationId: string) {
    const loadCaseIndex = design.loadCombinations.findIndex(load => load.Id == loadCombinationId);
    return getLoadCombinationNumberTextInternal(localizationService, loadCaseIndex);
}

export function areAllLoadsForLedgerAngleAvailable(regionHub: number, isCBFEMCalculation: boolean, isLedgerAngle: boolean) {
    return !isLedgerAngle ? true :
        (
            regionHub != RegionHub.W1_HNA
        )
        && !isCBFEMCalculation;
}

function getLoadCombinationNumberTextInternal(localizationService: LocalizationService, loadCombinationIndex: number) {
    const loadCombString = localizationService.getString('Agito.Hilti.Profis3.AnchorPlate.RecommendedCombinationMessage');
    return formatKeyValue(loadCombString, { combination_id: (loadCombinationIndex + 1).toString() });
}

function isValueNotEmpty(value: number | null | undefined) {
    return value != null && value != 0;
}
