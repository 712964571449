import {
    Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';

import { ShearLugShapeType } from '@profis-engineering/pe-gl-model/components/shear-lug';
import { DesignPe, ReportData } from '../../../shared/entities/design-pe';
import {
    IUtilization, IUtilizationDetail, utilizations
} from '../../../shared/entities/utilization';
import {
    UtilizationCombinationEntity as ICombination, UtilizationGroupBaseEntity,
    UtilizationShearEntity as IShear, UtilizationTensionEntity as ITension, UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    HandrailPostPosition
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CollapsingControls } from '../../entities/collapsing-controls';
import {
    areLoadCombinationsAvailable, getLoadCombinationNumberText
} from '../../helpers/load-combination-helper';
import { getProperty } from '../../helpers/object-helper';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites } from '../../sprites';

interface IWorstResult {
    valueError: boolean;
    textError: boolean;
    image: string;
    textKey: string;
    value: number;
}

@Component({
    templateUrl: './utilizations.component.html',
    styleUrls: ['./utilizations-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UtilizationsComponent implements OnInit {
    @Input()
    public id = '';

    @Input()
    public tension?: ITension;

    @Input()
    public shear?: IShear;

    @Input()
    public combination?: ICombination;

    @Input()
    public isMasonry = false;

    @Input()
    public design?: DesignPe;

    public collapsed = false;
    public tensionOpend = false;
    public shearOpend = false;
    public combinationOpend = false;
    public shearConcreteBvcOpend = false;

    public readonly utilizations = utilizations;

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private numberService: NumberService,
        private modal: ModalService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get hasUtilizations() {
        return ((this.tension != null && this.tension.Decisive != null) ||
            (this.shear != null && this.shear.Decisive != null) ||
            (this.combination != null && this.combination.Decisive != null)) &&
            this.design?.designData?.reportData != null;
        }

    public get showUtilizations() {
        return this.hasUtilizations;
    }

    public get areLoadCombinationsAvailable() {
        return areLoadCombinationsAvailable(this.design?.designData?.reportData as ReportData);
    }

    public get loadCombination() {
        if (this.design == null) {
            return null;
        }

        return getLoadCombinationNumberText(this.design, this.localizationService, this.design?.designData?.reportData?.DecisiveLoadCombinationIndex ?? 0, false);
    }

    public get utilizationValue() {
        if (this.combination == null || this.combination.Decisive == null ||
            this.tension == null || this.tension.Decisive == null ||
            this.shear == null || this.shear.Decisive == null) {

            return null;
        }

        return Math.max(this.combination.Decisive.Percentage, this.tension.Decisive.Percentage, this.shear.Decisive.Percentage);
    }

    public get tensionDetails() {
        return this.tension != null &&
            (this.tension.Steel != null || this.tension.ConcreteBreakout != null || this.tension.Pullout != null || this.tension.PulloutBond != null ||
                this.tension.CombinedPulloutBreakout != null || this.tension.SustainedPullout != null || this.tension.Splitting != null ||
                this.tension.ConcreteBlowout != null || this.tension.BrickBreakout != null || this.tension.BrickPullout != null ||
                this.tension.Bond != null || this.tension.Overall != null || this.tension.SupplementaryReinforcementAnchorage != null || this.tension.SupplementaryReinforcementSteel != null ||
                this.tension.SurfaceReinforcementSteel != null);
    }

    public get shearDetails() {
        return this.shear != null &&
            (this.shear.Steel != null || this.shear.ConcreteBvc != null || this.shear.ConcreteEdgeBreakout != null || this.shear.ConcreteEdgeBreakoutParallel != null || this.shear.Pryout != null ||
                this.shear.BrickEdge != null || this.shear.BrickLocal != null || this.shear.BrickPushout != null || this.shear.Bond != null || this.shear.Overall != null ||
                this.shear.SupplementaryReinforcementSteel != null || this.shear.SupplementaryReinforcementAnchorage != null || this.shear.SurfaceReinforcementSteel != null ||
                this.shear.MasonryCrushing != null || this.shear.AnchorageStrength != null
            );
    }

    public get combinationDetails() {
        return this.combination != null &&
            (this.combination.Steel != null || this.combination.Concrete != null);
    }

    public get isShearConcreteBvcPresent() {
        return this.shear != null && this.shear.ConcreteBvc != null;
    }

    public get showShearConcreteElements() {
        return !this.isShearConcreteBvcPresent || this.shearConcreteBvcOpend;
    }

    public get isShearLugDoubleFlatBarPresent() {
        return this.design?.model[UIProperty.Profile_ShearLugFamilyId] == ShearLugShapeType.DoubleBar;
    }
    public get utilizationInvalid() {
        return this.combination == null || this.combination.Decisive == null || this.combination.Decisive.ValueError || this.combination.Decisive.TextError ||
            this.tension == null || this.tension.Decisive == null || this.tension.Decisive.ValueError || this.tension.Decisive.TextError ||
            this.shear == null || this.shear.Decisive == null || this.shear.Decisive.ValueError || this.shear.Decisive.TextError;
    }

    public get tensionWorst() {
        if (this.tension == null) {
            return null;
        }

        let worst: UtilizationValueEntity | null = null;
        let image: string | null = null;
        let textKey: string | null = null;

        if (this.tension.BrickBreakout != null) {
            worst = this.tension.BrickBreakout;
            image = this.image('tension-breakout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.BrickBreakout';
        }

        if (this.tension.BrickPullout != null && (worst == null || this.tension.BrickPullout.Value > worst.Value)) {
            worst = this.tension.BrickPullout;
            image = this.image('tension-brick-pullout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.BrickPullout';
        }

        if (this.tension.CombinedPulloutBreakout != null && (worst == null || this.tension.CombinedPulloutBreakout.Value > worst.Value)) {
            worst = this.tension.CombinedPulloutBreakout;
            image = this.image('tension-pullout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.CombinedPulloutBreakout';
        }

        if (this.tension.ConcreteBlowout != null && (worst == null || this.tension.ConcreteBlowout.Value > worst.Value)) {
            worst = this.tension.ConcreteBlowout;
            image = this.image('tension-blowout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.ConcreteBlowout';
        }

        if (this.tension.ConcreteBreakout != null && (worst == null || this.tension.ConcreteBreakout.Value > worst.Value)) {
            worst = this.tension.ConcreteBreakout;
            image = this.image('tension-breakout');
            textKey = this.design?.designType.id == DesignType.Masonry ? 'Agito.Hilti.Profis3.Utilizations.Tension.MasonryBreakout' : 'Agito.Hilti.Profis3.Utilizations.Tension.ConcreteBreakout';
        }

        if (this.tension.Pullout != null && (worst == null || this.tension.Pullout.Value > worst.Value)) {
            worst = this.tension.Pullout;
            image = this.image('tension-pullout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.Pullout';
        }

        if (this.tension.PulloutBond != null && (worst == null || this.tension.PulloutBond.Value > worst.Value)) {
            worst = this.tension.PulloutBond;
            image = this.image('tension-pullout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.PulloutBond';
        }

        if (this.tension.Splitting != null && (worst == null || this.tension.Splitting.Value > worst.Value)) {
            worst = this.tension.Splitting;
            image = this.image('tension-splitting');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.Splitting';
        }

        if (this.tension.Steel != null && (worst == null || this.tension.Steel.Value > worst.Value)) {
            worst = this.tension.Steel;
            image = this.image('tension-steel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.Steel';
        }

        if (this.tension.SustainedPullout != null && (worst == null || this.tension.SustainedPullout.Value > worst.Value)) {
            worst = this.tension.SustainedPullout;
            image = this.image('tension-pullout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.SustainedPullout';
        }

        if (this.tension.Bond != null && (worst == null || this.tension.Bond.Value > worst.Value)) {
            worst = this.tension.Bond;
            image = this.image('tension-bond');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.Bond';
        }

        if (this.tension.Overall != null && (worst == null || this.tension.Overall.Value > worst.Value)) {
            worst = this.tension.Overall;
            image = this.image('tension-overall');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.Overall';
        }

        if (this.tension.SupplementaryReinforcementSteel != null && (worst == null || this.tension.SupplementaryReinforcementSteel.Value > worst.Value)) {
            worst = this.tension.SupplementaryReinforcementSteel;
            image = this.image('tension-supplementary-reinforcement-steel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.SupplementaryReinforcementSteel';
        }

        if (this.tension.SupplementaryReinforcementAnchorage != null && (worst == null || this.tension.SupplementaryReinforcementAnchorage.Value > worst.Value)) {
            worst = this.tension.SupplementaryReinforcementAnchorage;
            image = this.image('tension-supplementary-reinforcement-anchorage');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.SupplementaryReinforcementAnchorage';
        }

        if (this.tension.SurfaceReinforcementSteel != null && (worst == null || this.tension.SurfaceReinforcementSteel.Value > worst.Value)) {
            worst = this.tension.SurfaceReinforcementSteel;
            image = this.image('tension-surface-reinforcement-steel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Tension.SurfaceReinforcementSteel';
        }

        if (worst == null) {
            return null;
        }

        return {
            image,
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        } as IWorstResult;
    }

    public get shearWorst() {
        if (this.shear == null) {
            return null;
        }

        let worst: UtilizationValueEntity | null = null;
        let image: string | null = null;
        let textKey: string | null = null;

        if (this.shear.BrickEdge != null) {
            worst = this.shear.BrickEdge;
            image = this.image('shear-breakout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.BrickEdge';
        }

        if (this.shear.BrickLocal != null && (worst == null || this.shear.BrickLocal.Value > worst.Value)) {
            worst = this.shear.BrickLocal;
            image = this.image('shear-local-brick');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.BrickLocal';
        }

        if (this.shear.BrickPushout != null && (worst == null || this.shear.BrickPushout.Value > worst.Value)) {
            worst = this.shear.BrickPushout;
            image = this.image('shear-brick-pullout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.BrickPushout';
        }

        if (this.shear.ConcreteBvc != null && (worst == null || this.shear.ConcreteBvc.Value > worst.Value)) {
            worst = this.shear.ConcreteBvc;
            image = this.image('shear-breakout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteBvc';
        }

        if (this.shear.ConcreteEdgeBreakout != null && (worst == null || this.shear.ConcreteEdgeBreakout.Value > worst.Value)) {
            worst = this.shear.ConcreteEdgeBreakout;
            image = this.image('shear-breakout');
            textKey = this.design?.designType.id == DesignType.Masonry ? 'Agito.Hilti.Profis3.Utilizations.Shear.MasonryEdgeBreakout' : 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteEdgeBreakout';
        }

        if (this.shear.ConcreteEdgeBreakoutParallel != null && (worst == null || this.shear.ConcreteEdgeBreakoutParallel.Value > worst.Value)) {
            worst = this.shear.ConcreteEdgeBreakoutParallel;
            image = this.image('shear-breakout-parallel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteEdgeBreakoutParallel';
        }

        if (this.shear.BearingStrength != null && (worst == null || this.shear.BearingStrength.Value > worst.Value)) {
            worst = this.shear.BearingStrength;
            image = this.image('shear-concrete-bearing');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.BearingStrength';
        }

        if (this.shear.StressLimit != null && (worst == null || this.shear.StressLimit.Value > worst.Value)) {
            worst = this.shear.StressLimit;
            image = this.image('shear-additive-concrete-bearing');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.StressLimit';
        }

        if (this.shear.Pryout != null && (worst == null || this.shear.Pryout.Value > worst.Value)) {
            worst = this.shear.Pryout;
            image = this.image('shear-pryout');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.Pryout';
        }

        if (this.shear.Steel != null && (worst == null || this.shear.Steel.Value > worst.Value)) {
            worst = this.shear.Steel;
            image = this.image('shear-steel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.Steel';
        }

        if (this.shear.Bond != null && (worst == null || this.shear.Bond.Value > worst.Value)) {
            worst = this.shear.Bond;
            image = this.image('shear-bond');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.Bond';
        }

        if (this.shear.Overall != null && (worst == null || this.shear.Overall.Value > worst.Value)) {
            worst = this.shear.Overall;
            image = this.image('shear-overall');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.Overall';
        }

        if (this.shear.SupplementaryReinforcementSteel != null && (worst == null || this.shear.SupplementaryReinforcementSteel.Value > worst.Value)) {
            worst = this.shear.SupplementaryReinforcementSteel;
            image = this.image('shear-supplementary-reinforcement-steel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.SupplementaryReinforcementSteel';
        }

        if (this.shear.SupplementaryReinforcementAnchorage != null && (worst == null || this.shear.SupplementaryReinforcementAnchorage.Value > worst.Value)) {
            worst = this.shear.SupplementaryReinforcementAnchorage;
            image = this.image('shear-supplementary-reinforcement-anchorage');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.SupplementaryReinforcementAnchorage';
        }

        if (this.shear.SurfaceReinforcementSteel != null && (worst == null || this.shear.SurfaceReinforcementSteel.Value > worst.Value)) {
            worst = this.shear.SurfaceReinforcementSteel;
            image = this.image('shear-surface-reinforcement-steel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.SurfaceReinforcementSteel';
        }

        if (this.shear.MasonryCrushing != null && (worst == null || this.shear.MasonryCrushing.Value > worst.Value)) {
            worst = this.shear.MasonryCrushing;
            image = this.image('crushing');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.MasonryCrushing';
        }

        if (this.shear.AnchorageStrength != null && (worst == null || this.shear.AnchorageStrength.Value > worst.Value)) {
            worst = this.shear.AnchorageStrength;
            image = this.image('shear-bond');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Shear.AnchorageStrength';
        }

        if (worst == null) {
            return null;
        }

        return {
            image,
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        } as IWorstResult;
    }

    public get combinationWorst() {
        if (this.combination == null) {
            return null;
        }

        let worst: UtilizationValueEntity | null = null;
        let image: string | null = null;
        let textKey: string | null = null;

        if (this.combination.Concrete != null) {
            worst = this.combination.Concrete;
            image = this.image('combination-concrete');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Combination.Concrete';
        }

        if (this.combination.Steel != null && (worst == null || this.combination.Steel.Value > worst.Value)) {
            worst = this.combination.Steel;
            image = this.image('combination-steel');
            textKey = 'Agito.Hilti.Profis3.Utilizations.Combination.Steel';
        }

        if (worst == null) {
            return null;
        }

        return {
            image,
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        } as IWorstResult;
    }

    public get tensionLength() {
        if (this.tension == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.tension);
        let count = 0;
        props.forEach((propertyName) => {
            if (this.tension != null && getProperty(this.tension, propertyName) != null && propertyName != 'Decisive') {
                count++;
            }
        });

        return count;
    }

    public get shearLength() {
        if (this.shear == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.shear);
        let count = 0;
        props.forEach((propertyName) => {
            if (this.shear != null && getProperty(this.shear, propertyName) != null && propertyName != 'Decisive') {
                count++;
            }
        });

        return count;
    }

    public get combinationLength() {
        if (this.combination == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.combination);
        let count = 0;

        props.forEach((propertyName) => {
            if (this.combination != null && getProperty(this.combination, propertyName) != null && propertyName != 'Decisive') {
                count++;
            }
        });

        return count;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-notification-alert-small',
            'sprite-anchor-design',
            'sprite-tension-blowout',
            'sprite-tension-breakout',
            'sprite-tension-combined',
            'sprite-tension-pullout',
            'sprite-tension-splitting',
            'sprite-tension-steel',
            'sprite-tension-supplementary-reinforcement-anchorage',
            'sprite-tension-supplementary-reinforcement-steel',
            'sprite-tension-surface-reinforcement-steel',
            'sprite-shear-breakout',
            'sprite-shear-breakout-parallel',
            'sprite-shear-pryout',
            'sprite-shear-steel',
            'sprite-shear-stress',
            'sprite-shear-supplementary-reinforcement-anchorage',
            'sprite-shear-supplementary-reinforcement-steel',
            'sprite-shear-surface-reinforcement-steel',
            'sprite-combination-concrete',
            'sprite-combination-steel',
            'sprite-masonry-shear-bond',
            'sprite-masonry-shear-breakout',
            'sprite-masonry-shear-pryout',
            'sprite-masonry-crushing',
            'sprite-masonry-shear-brick-pullout',
            'sprite-masonry-shear-local-brick',
            'sprite-masonry-shear-overall',
            'sprite-masonry-shear-steel',
            'sprite-masonry-tension-bond',
            'sprite-masonry-tension-breakout',
            'sprite-masonry-tension-brick-pullout',
            'sprite-masonry-tension-overall',
            'sprite-masonry-tension-pullout',
            'sprite-masonry-tension-steel',
            'sprite-arrow-up',
            'sprite-arrow-down',
            'sprite-shear-concrete-bearing',
            'sprite-shear-additive-concrete-bearing'
        );

        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.Utilization);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public isHandrailPostPositionAvailable() {
        return this.design?.designData.projectDesign?.ProjectDesignType == DesignType.Handrail
            && this.design?.designData?.reportData?.DecisiveHandrailPostPosition != null;
    }

    public transformValue(value: number | null) {
        return this.numberService.format(value ?? 0, 0) + '%';
    }

    public image(image: string) {
        if (image == null || image == '') {
            return null;
        }

        return `pe-ui-pe-sprite-${this.isMasonry ? 'masonry-' : ''}${image}`;
    }

    public showWarning(percentage: number, utilization?: UtilizationGroupBaseEntity) {
        if (utilization != null) {
            return Object.getOwnPropertyNames(utilization).length > 1 ?
                false
                :
                this.design?.designType.id == DesignType.Concrete && percentage > 100;
        }
        else {
            return this.design?.designType.id == DesignType.Concrete && percentage > 100;
        }
    }

    public openAnchorDesignHelp(utilizationProperty: string, utilizationSubCategoryProperty?: string) {

        // BUDQBP-29960 This condition is added to accommodate the change for showing stress limit only for double flat bar in shear lug.
        if (!this.isShearLugDoubleFlatBarPresent) {
            delete (this.shear as any).StressLimit;
        }

        const props = {
            category: utilizationProperty,
            subcategory: utilizationSubCategoryProperty,
            tension: this.tension,
            shear: this.shear,
            combination: this.combination,
            utilizations: this.utilizations(this.design?.designType),
        };
        this.modal.openAnchorDesignHelp(props);
    }

    public getPostPositionTranslationKey() {
        switch (this.design?.designData?.reportData?.DecisiveHandrailPostPosition) {
            case HandrailPostPosition.Edge:
                return 'Agito.Hilti.Profis3.HandrailPostPosition.Edge';
            case HandrailPostPosition.Middle:
                return 'Agito.Hilti.Profis3.HandrailPostPosition.Middle';
            default:
                throw new Error('Unknown handrail post position');
        }
    }

    public changeUtilizationPropertyOpend(property: string) {
        switch (property) {
            case 'tension':
                this.tensionOpend = !this.tensionOpend;
                break;
            case 'shear':
                this.shearOpend = !this.shearOpend;
                break;
            case 'combination':
                this.combinationOpend = !this.combinationOpend;
                break;
        }
    }

    public getUtilizationOpendFromProperty(property: string): boolean {
        return getProperty(this, property + 'Opend') as boolean;
    }

    public getUtilizationWorstFromProperty(property: string): IWorstResult {
        return getProperty(this, property + 'Worst') as IWorstResult;
    }

    public getUtilizationLengthFromProperty(property: string): number {
        return getProperty(this, property + 'Length') as number;
    }

    public getUtilizationDetailsFromProperty(property: string): boolean {
        return getProperty(this, property + 'Details') as boolean;
    }

    public getUtilizationFromProperty(property: string): UtilizationGroupBaseEntity | undefined {
        return getProperty(this, property) as UtilizationGroupBaseEntity;
    }

    public getUtilizationValueEntityFromDetail(property: string, detailProperty: string): UtilizationValueEntity {
        return this.getDetailProperty(this, property, detailProperty) as UtilizationValueEntity;
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.Utilization, collapsed);
    }

    public trackUtilizationByProperty: TrackByFunction<IUtilization> = (_: number, utilization: IUtilization) => utilization.property;
    public trackUtilizationDetailByProperty: TrackByFunction<IUtilizationDetail> = (_: number, utilizationDetail: IUtilizationDetail) => utilizationDetail.property;

    private getDetailProperty(object: object, property: string, detailProperty: string) {
        const propertyObj = getProperty(object, property);
        return getProperty(propertyObj, detailProperty);
    }
}
