<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.DetailedCalculationOutput.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="calculation-output-text-area-container">
        <div class="section">
            <textarea id="calculation-output-area"
                class="calculation-output-text-area control-row input"
                [ngModel]="calculationOutput" readonly>
            </textarea>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        class="modal-button"
        [look]="'Default'"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.Cancel')">
    </pe-button>

    <pe-button
        class="modal-button"
        [look]="'Cta'"
        (buttonClick)="copyToClipBoard()"
        [text]="translate('Agito.Hilti.Profis3.DetailedCalculationOutput.CopyToClipboard')">
    </pe-button>
</pe-modal-footer>
