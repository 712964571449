export enum ReportDetails {
    AdvanceBaseplate = 1, // PE
    WeldDesign = 2, // PE
    AnchorPlateDesign = 3, // PE
    HandrailSafetyDesign = 4, // PE
    IncludeItemNumbers = 5, // PE & C2C
    ParallelDesign = 6, // PE
    TrimbleConnectUpload = 7, // PE & C2C
    IncludeAnchorCalculation = 8, // C2C
    IncludeApprovals = 9 // C2C
}
