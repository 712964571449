import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { ISuggestedAnchorProps, SuggestedAnchor } from '../../components/main-menu/suggested-anchor/SuggestedAnchor';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { getSpriteAsIconStyleResponsive } from '../../sprites';
import { updateMainMenuControl, updateUiProperty } from './menu-helper';

export function initializeSelectSuggestedAnchor(
    calculationService: CalculationServicePE,
    design: DesignPe,
    controlProps: ISuggestedAnchorProps,
    localizationService: LocalizationService,
    uiPropertyId: number,
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,) {

    controlProps.type = SuggestedAnchor;
    const propertyValue = design.model[uiPropertyId] as number;
    controlProps.isUserAnchorChosen = design.model[UIProperty.AnchorUserChosen] as boolean;

    const getSuggestedAnchorDataFn = (anchorFamilyId?: number) => {
        let buttonText: string | undefined;
        let tag: string | undefined;
        let buttonImage: string | undefined;
        let buttonImageStyle: IResponsiveIconStyle | undefined;

        if (anchorFamilyId != null) {
            const anchorFamilies = design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

            const selectedAnchorFamily = anchorFamilies.find((anchorFamily) => anchorFamily.id == anchorFamilyId);
            buttonText = selectedAnchorFamily?.getTranslatedNameText({ localizationService });
            tag = selectedAnchorFamily?.detailed?.tag;

            buttonImage = selectedAnchorFamily?.image;
            if (buttonImage != null && buttonImage != '') {
                buttonImageStyle = {
                    elementStyle: {},
                    afterElementStyle: getSpriteAsIconStyleResponsive(buttonImage)
                };
            }
        }

        return {
            buttonText,
            tag,
            buttonImage,
            buttonImageStyle
        };
    };

    const buttonData = getSuggestedAnchorDataFn(propertyValue);
    controlProps.buttonText = buttonData.buttonText;
    controlProps.tag = buttonData.tag;
    controlProps.buttonImage = buttonData.buttonImage;
    controlProps.buttonImageStyle = buttonData.buttonImageStyle;

    // This control is used also for read-only currently selected anchor on smart anchor tab
    if (uiPropertyId == PropertyMetaData.SmartAnchor_CurrentlySelectedAnchor.id) {
        controlProps.value = propertyValue;
        controlProps.disabled = true;
        controlProps.checked = true;
        controlProps.isSmartBaseplateEnable = design.model[UIProperty.SmartAnchor_Enabled] as boolean;
        controlProps.isUserAnchorChosen = design.model[UIProperty.AnchorUserChosen] as boolean;
    }

    controlProps.checkedChanged = (checked) => {
        // change menu
        setState(menu => updateMainMenuControl<ISuggestedAnchorProps>(menu, controlProps.controlId, { checked } as any));

        updateUiProperty(calculationService, design, uiPropertyId, checked);
    };

    const onStateChanged = (state: IDesignState) => {
        const buttonData = getSuggestedAnchorDataFn(state.model[uiPropertyId] as number);
        return {
            buttonText: buttonData.buttonText,
            tag: buttonData.tag,
            buttonImage: buttonData.buttonImage,
            buttonImageStyle: buttonData.buttonImageStyle,
            value: state.model[uiPropertyId],
            isUserAnchorChosen:  design.model[UIProperty.AnchorUserChosen]
        } as ISuggestedAnchorProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}
