import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { LoadCombinationHNAEquationEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { DesignMethodHNA } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from '../code-lists/code-list';

export interface ILoadCombinationHNAEquationConstructor extends ICodeListConstructor {
    designMethodHNA?: DesignMethodHNA;
    descriptionFormat?: string;
    descriptionParams?: number[];
}

export class LoadCombinationHNAEquation extends CodeList {
    public designMethodHNA?: DesignMethodHNA;
    public descriptionFormat?: string;
    public descriptionParams?: number[];

    constructor(codeList: ILoadCombinationHNAEquationConstructor) {
        super(codeList);

        if (codeList != null) {
            this.designMethodHNA = codeList.designMethodHNA;
            this.descriptionFormat = codeList.descriptionFormat;
            this.descriptionParams = codeList.descriptionParams != null ? codeList.descriptionParams.slice() : undefined;
        }
    }

    public static fromService(codeList: LoadCombinationHNAEquationEntity) {
        return new LoadCombinationHNAEquation(CodeList.baseFromService<ILoadCombinationHNAEquationConstructor>('LoadCombinationHNAEquationEntity', codeList, {
            designMethodHNA: codeList.DesignMethodHNA,
            descriptionFormat: codeList.DescriptionFormat,
            descriptionParams: codeList.DescriptionParams
        } as ILoadCombinationHNAEquationConstructor));
    }

    public override cloneDeep() {
        const codeList: LoadCombinationHNAEquation = super.cloneDeep() as LoadCombinationHNAEquation;

        codeList.designMethodHNA = this.designMethodHNA;
        codeList.descriptionFormat = this.descriptionFormat;
        codeList.descriptionParams = this.descriptionParams != null ? this.descriptionParams.slice() : undefined;

        return codeList;
    }
}
