<div class="filter-container">
    <div class="filter-label">
        <span>{{translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.Title')}}</span>
        <span *ngIf="this.appliedFiltersCount" class="clearLink" (click)="reset()">
            {{translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.ClearFilter') +' ('+ this.appliedFiltersCount +
            ')'}}
        </span>
    </div>
    <div class="filter-warapper">
        <div class="filter-content">
            <div class="anchor-post-filter" *ngIf="anchorLayoutToggleList.length > 0">
                <pe-toggle-button-group class="anchor-post-filter-items"
                    [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.NoOfAnchors')"
                    [items]="anchorLayoutToggleList" [type]="typeOfToggle" [model]="selectedAnchorLayouts"
                    (modelChange)="changeNoOfAnchorsPostFilter($any($event).detail);" [unselectable]="true">
                </pe-toggle-button-group>
            </div>
        </div>

        <div class="filter-content">
            <div class="anchor-post-filter slider" *ngIf="sizeListOptionDdl">
                <pe-multiselect-dropdown [id]="sizeListOptionDdl.id" [title]="sizeListOptionDdl.title"
                    [notSelectedText]="sizeListOptionDdl.notSelectedText" [items]="sizeListOptionDdl.items"
                    [selectedValue]="sizeListOptionDdl.selectedValue"
                    (selectedValuesChange)="anchorSizeOptionChange($any($event).detail)"
                    [validators]="sizeListOptionDdl.validators" [selectedValues]="sizeListOptionDdl.selectedValues">

                </pe-multiselect-dropdown>
            </div>
        </div>

        <div class="filter-content">
            <div class="anchor-post-filter slider" *ngIf="constructionOptionDdl">
                <div class="filter-panel-label slider no-border-bottom">
                    <b>{{translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.CorrosionsMaterials')}}</b>
                </div>
                <pe-multiselect-dropdown [id]="constructionOptionDdl.id" [title]="constructionOptionDdl.title"
                    [notSelectedText]="constructionOptionDdl.notSelectedText" [items]="constructionOptionDdl.items"
                    [selectedValue]="constructionOptionDdl.selectedValue"
                    (selectedValuesChange)="constructionSelectionChange($any($event).detail)"
                    [validators]="constructionOptionDdl.validators"
                    [selectedValues]="constructionOptionDdl.selectedValues">

                </pe-multiselect-dropdown>
            </div>
        </div>

        <div class="filter-content">
            <div class="anchor-post-filter slider" *ngIf="utilizationOptions">
                <pe-slider [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.Utilization')"
                    [value]="lowUtilizationValue" [highValue]="highUtilizationValue" [sliderType]="rangeSlider"
                    [options]="utilizationOptions" (highValueChange)="highUtilizationChange($any($event).detail)"
                    (valueChange)="lowUtilizationChange($any($event).detail)"></pe-slider>
            </div>
        </div>

        <div class="filter-content">
            <div class="anchor-post-filter slider" *ngIf="embedmentDepthOptions">
                <pe-slider [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.EmbedmentDepth')"
                    [value]="selectedEmbedmentDepth" [sliderType]="singleSlider" [unitGroup]="embedmentUnitGroup"
                    [options]="embedmentDepthOptions"
                    (valueChange)="selectedEmbedmentDepthChange($any($event).detail)"></pe-slider>
            </div>
        </div>

        <div class="filter-content">
            <div class="anchor-post-filter slider no-border-bottom"
                *ngIf="xDirectionOptions && xDirectionOptions.floor">
                <div class="filter-panel-label slider no-border-bottom">
                    <b>{{translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.BaseplateSize')}}</b>
                </div>
                <pe-slider [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.XDirection')"
                    [value]="xDirectionValue" [sliderType]="singleSlider" [unitGroup]="embedmentUnitGroup"
                    [options]="xDirectionOptions" (valueChange)="xDirectionChange($any($event).detail)"></pe-slider>
            </div>
            <div class="anchor-post-filter slider no-border-bottom" *ngIf="yDirectionOptions && yDirectionOptions.floor">
                <pe-slider [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.YDirection')"
                    [value]="yDirectionValue" [sliderType]="singleSlider" [unitGroup]="embedmentUnitGroup"
                    [options]="yDirectionOptions" (valueChange)="yDirectionChange($any($event).detail)"></pe-slider>
            </div>
        </div>
    </div>
</div>