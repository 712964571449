import { DrillingMethodEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class DrillingMethod extends CodeList {

    public static fromService(codeList: DrillingMethodEntity) {
        return new DrillingMethod(CodeList.baseFromService('DrillingMethodEntity', codeList));
    }

    public override cloneDeep(): DrillingMethod {
        return super.cloneDeep() as DrillingMethod;
    }
}
