import { EdgeReinforcementEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class EdgeReinforcement extends CodeList {

    public static fromService(codeList: EdgeReinforcementEntity) {
        return new EdgeReinforcement(CodeList.baseFromService('EdgeReinforcementEntity', codeList));
    }

    public override cloneDeep(): EdgeReinforcement {
        return super.cloneDeep() as EdgeReinforcement;
    }
}
