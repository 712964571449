import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    SmartAnchorApplicationEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface ISmartAnchorApplication extends ICodeListConstructor {
    categoryId: number;
}

export class SmartAnchorApplication extends CodeList {
    public categoryId!: number;

    constructor(codeList: ISmartAnchorApplication) {
        super(codeList);

        if (codeList != null) {
            this.categoryId = codeList.categoryId;
        }
    }

    public static fromService(codeList: SmartAnchorApplicationEntity) {
        return new SmartAnchorApplication(CodeList.baseFromService<ISmartAnchorApplication>('SmartAnchorApplicationEntity', codeList,
            {
                image: 'sprite-smart-anchor-' + codeList.Image,
                categoryId: codeList.CategoryId
            } as ISmartAnchorApplication));
    }

    public override cloneDeep(): SmartAnchorApplication {
        const codeList: SmartAnchorApplication = super.cloneDeep() as SmartAnchorApplication;
        codeList.categoryId = this.categoryId;

        return codeList;
    }
}
