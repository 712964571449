import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CleaningMethodEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class CleaningMethod extends CodeList {

    public static fromService(codeList: CleaningMethodEntity) {
        return new CleaningMethod(CodeList.baseFromService<ICodeListConstructor>('CleaningMethodEntity', codeList));
    }

    public override cloneDeep(): CleaningMethod {
        return super.cloneDeep() as CleaningMethod;
    }
}
