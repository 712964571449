import {
    ChangeDetectorRef, Component, ElementRef, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import { AnchorFilterGroup } from '../../../shared/entities/code-lists/anchor-filter-group';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe } from '../../../shared/entities/design-pe';
import { IAnchor } from '../../../shared/entities/select-anchor-models';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { SelectAnchorFilterGroupItem } from '../select-anchor/select-anchor.common';
import { SelectAnchorComponent } from '../select-anchor/select-anchor.component';

interface IMultiSelectAnchorConstructor {
    items?: SelectAnchorFilterGroupItem[];
}

class MultiSelectAnchors {
    public items?: SelectAnchorFilterGroupItem[];

    constructor(ctor?: IMultiSelectAnchorConstructor) {
        if (ctor != null) {
            this.items = ctor.items;
        }
    }
}

@Component({
    templateUrl: './multiselect-anchor.component.html',
    styleUrls: ['./multiselect-anchor.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MultiselectAnchorComponent extends SelectAnchorComponent implements OnInit {
    public isSelectOrCancelClicked: boolean;
    public multipleSelectedAnchors!: MultiSelectAnchors;
    public isMaxAnchorsSelected: boolean;
    public selectedAnchorsArray: any = [];
    private readonly maxAnchorCount = 3;

    constructor(
        localization: LocalizationService,
        user: UserService,
        unit: UnitService,
        userSettings: UserSettingsService,
        featuresVisibilityInfo: FeaturesVisibilityInfoService,
        modal: ModalService,
        offlineService: OfflineService,
        calculationService: CalculationServicePE,
        sharedEnvironmentData: SharedEnvironmentService,
        elementRef: ElementRef<HTMLElement>,
        changeDetectorRef: ChangeDetectorRef
    ) {

        super(localization, user, unit, userSettings, featuresVisibilityInfo, modal,
            offlineService, calculationService, sharedEnvironmentData,
            elementRef, changeDetectorRef);

        this.isMaxAnchorsSelected = false;
        this.isSelectOrCancelClicked = false;
    }

    public override get calculateAllDisabled() {
        return this.design.designData.reportData?.CalculateAllDisabled || this.calculateAllLoading || this.smartAnchorEnabled;
    }

    public get SmartAnchorUserSelectedAnchorGroups() {
        return <any>this.design.model[UIProperty.SmartAnchor_UserSelectedAnchorGroupsForAsad];
    }

    public get smartAnchorEnabled() {
        return this.design.model[UIProperty.SmartAnchor_Enabled] as boolean;
    }

    public getMultiSelectAnchorItemCheckbox(checkbox: CheckboxButtonProps<boolean> | undefined) {
        return checkbox as CheckboxButtonProps<boolean>;
    }

    public identifySelectAnchorItem: TrackByFunction<SelectAnchorFilterGroupItem> = (_: number, selectAnchorItem: SelectAnchorFilterGroupItem) => {
        return selectAnchorItem.id;
    };

    public override async selectAnchor(anchor: IAnchor) {
        const selectedAnchors = this.multipleSelectedAnchors.items?.filter(x => x.checkbox?.selectedValues?.has(true));
        const currentAnchor = ((this.multipleSelectedAnchors.items?.filter(x => x.id === anchor.id) as SelectAnchorFilterGroupItem[])[0].checkbox as CheckboxButtonProps<boolean>);
        const isAnchorAlreadySelected = selectedAnchors?.some(x => x.id === anchor.id) ?? false;

        if (selectedAnchors != undefined && selectedAnchors.length <= this.maxAnchorCount) {
            currentAnchor.selectedValues = new Set([!isAnchorAlreadySelected]);
            this.multiSelectAnchorChange();
        }
    }

    protected override getFilterGroups(design: DesignPe): AnchorFilterGroup[] {
        const filterGroups = design.designData.designCodeLists[DesignCodeList.AnchorFilterGroup] as AnchorFilterGroup[];

        if (this.smartAnchorEnabled) {
            const keyCleaning = 'Agito.Hilti.Profis3.CodeList.AnchorFilterGroupEntity.Cleaning';
            const keyProductivityHealthSafety = 'Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.ProductivityHealthSafety';
            const filterGroupsClone = cloneDeep(filterGroups);

            if (filterGroupsClone.some(x => x.nameResourceKey == keyCleaning)) {
                const index = filterGroupsClone.findIndex(x => x.nameResourceKey == keyCleaning);
                filterGroupsClone[index].nameResourceKey = keyProductivityHealthSafety;

                return filterGroupsClone;
            }
        }

        return filterGroups;
    }

    public onCancelButtonClick() {
        this.isSelectOrCancelClicked = true;
        this.close();
    }

    public getItem(items?: SelectAnchorFilterGroupItem[], id?: number) {
        return items?.filter(x => x.id === id);
    }

    public multiSelectAnchorChange() {
        const selectedAnchors = this.multipleSelectedAnchors.items?.filter(x => x.checkbox?.selectedValues?.has(true));

        if (selectedAnchors != undefined && selectedAnchors.length >= this.maxAnchorCount) {
            for (const item of this.multipleSelectedAnchors.items as SelectAnchorFilterGroupItem[]) {
                if (selectedAnchors.filter(x => x.id == item.id).length > 0) {
                    (item.checkbox as CheckboxButtonProps<boolean>).disabled = false;
                } else {
                    (item.checkbox as CheckboxButtonProps<boolean>).disabled = true;
                }
            }
            this.isMaxAnchorsSelected = true;
        } else {
            for (const item of this.multipleSelectedAnchors.items as SelectAnchorFilterGroupItem[]) {
                (item.checkbox as CheckboxButtonProps<boolean>).disabled = false;
            }
            this.isMaxAnchorsSelected = false;
        }

        this.selectedAnchorsArray = [];
        selectedAnchors?.forEach(item => {
            this.selectedAnchorsArray.push(item.id);
        });
    }

    public disableAnchorRow(anchor: IAnchor) {
        if (this.isMaxAnchorsSelected) {
            const selectedAnchor = ((this.multipleSelectedAnchors.items?.filter(x => x.id === anchor.id) as SelectAnchorFilterGroupItem[])[0].checkbox as CheckboxButtonProps<boolean>);
            if (selectedAnchor.selectedValues?.has(true)) {
                return false;
            } else {
                return true;
            }
        }
        else {
            return false;
        }
    }

    public highlightSelectedRow(anchor: IAnchor) {
        const selectedAnchor = ((this.multipleSelectedAnchors.items?.filter(x => x.id === anchor.id) as SelectAnchorFilterGroupItem[])[0]?.checkbox as CheckboxButtonProps<boolean>);
        if (selectedAnchor?.selectedValues?.has(true)) {
            return true;
        } else {
            return false;
        }
    }
    public onSelectButtonClick() {
        this.calculationService.calculateAsync(this.design, (design) => {
            design.model[UIProperty.SmartAnchor_UserSelectedAnchorGroupsForAsad] = this.selectedAnchorsArray;
        });
        this.isSelectOrCancelClicked = true;
        this.close();
    }

    public override setModalInstanceClosing() {
        // don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            if (this.calculateAllLoading) {
                return false;
            }

            if (this.isSelectOrCancelClicked) {
                return true;
            }

            this.setFiltersToProperties();
            this.calculationService.calculateAsync(this.design);
            return true;
        });
    }

    protected override refreshAnchors() {
        this.anchors = this.filterAnchors();

        this.multipleSelectedAnchors = new MultiSelectAnchors({
            items: this.anchorsData.map((anchorFilter) => new SelectAnchorFilterGroupItem({
                id: anchorFilter.id,
                checkbox: SimpleCheckboxButtonHelper.createSimpleCheckbox({
                    id: `multi-select-anchor-${anchorFilter.id}-checkbox`,
                    itemText: ''
                })
            })),
        });

        this.checkPreselectedAnchors();

        if (this.selectedAnchorsArray.length > 0) {
            for (const item of this.multipleSelectedAnchors.items as SelectAnchorFilterGroupItem[]) {
                if (this.selectedAnchorsArray.includes(item.id)) {
                    (item.checkbox as CheckboxButtonProps<boolean>).selectedValues = new Set([true]);
                }
            }
        }

        this.multiSelectAnchorChange();
        this.initializeAnchorFamilySprites();
    }

    private checkPreselectedAnchors() {
        if (this.SmartAnchorUserSelectedAnchorGroups != undefined && this.SmartAnchorUserSelectedAnchorGroups.length > 0) {
            for (const item of this.multipleSelectedAnchors.items as SelectAnchorFilterGroupItem[]) {
                if (this.SmartAnchorUserSelectedAnchorGroups?.includes(item.id)) {
                    (item.checkbox as CheckboxButtonProps<boolean>).selectedValues = new Set([true]);
                }
            }
        }
    }
}
