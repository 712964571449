import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    IAnchorNeedSolutionText, INeedSolutionText
} from '../../../shared/components/anchor-need-solution-popup';
import {
    AnchorFamily
} from '../../../shared/entities/code-lists/anchor-family';
import {
    SmartAnchorApplication
} from '../../../shared/entities/code-lists/smart-anchor-application';
import {
    SmartAnchorCategory
} from '../../../shared/entities/code-lists/smart-anchor-category';
import {
    ISmartAnchorNeedsSolutionTextsAnchorGroup, SmartAnchorNeedsSolutionTextsAnchorGroup
} from '../../../shared/entities/code-lists/smart-anchor-needs-solution-texts-anchor-group';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { AsadOptimizeCase, DesignPe } from '../../../shared/entities/design-pe';
import { anchorIcons, getAnchorIconTooltipTranslationKey, isAnchorFamilyMarkedAsNew, SelectAnchorIcon } from '../../../shared/helpers/anchor-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';

import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { getProperty } from '../../helpers/object-helper';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';

@Component({
    templateUrl: './solution-detail.component.html',
    styleUrls: ['./solution-detail.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SolutionDetailComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance<AsadOptimizeCase>;

    public anchor!: IAnchorNeedSolutionText;
    public icons = anchorIcons;

    private needsSolutionTextsAnchorGroup?: ISmartAnchorNeedsSolutionTextsAnchorGroup[];
    private design: DesignPe;

    constructor(
        private localizationService: LocalizationService,
        private userService: UserService,
        private unitService: UnitService,
        private numberService: NumberService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        this.design = this.userService.design;
    }

    public get embedmentDepth() {
        if (this.optimizeCaseOutput?.embedmentDepth == null) {
            if (this.optimizeCaseOutput != null) {
                this.optimizeCaseOutput.embedmentDepth = 0;
            }
        }

        return this.formatLength(this.optimizeCaseOutput?.embedmentDepth);
    }

    public get baseplateHeight() {
        return this.formatLength(this.optimizeCaseOutput?.anchorPlateHeight);
    }

    public get baseplateWidth() {
        return this.formatLength(this.optimizeCaseOutput?.anchorPlateWidth);
    }

    public get anchorCount() {
        return this.optimizeCaseDetails?.numberOfAnchors ?? 0;
    }

    public get isFastenerAutoCleaningAllowed() {
        return this.optimizeCaseDetails?.isAutoCleaningAllowed;
    }

    private get optimizeCaseOutput() {
        return this.modalInstance?.input?.optimizeCaseOutput;
    }

    private get optimizeCaseDetails() {
        return this.modalInstance?.input?.optimizeCaseDetails;
    }

    private get anchorIds() {
        return this.design.properties.get(PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id).allowedValues;
    }

    private get smartAnchorCategory() {
        if (this.design.smartAnchorCategory != null) {
            return this.design.smartAnchorCategory;
        }

        // Category not selected, use the first category (used by Virtual Tour!)
        const codeList = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorCategory] as SmartAnchorCategory[];
        return codeList[0].id;
    }

    private get smartAnchorApplication() {
        if (this.design.smartAnchorApplication != null) {
            return this.design.smartAnchorApplication;
        }

        const codeList = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorApplication] as SmartAnchorApplication[];
        const ret = codeList.find((x) => x.categoryId == this.smartAnchorCategory);
        return ret?.id;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-anchor-no-cleaning-required',
            'sprite-anchor-approved-automatic-cleaning',
            'sprite-anchor-tensile-zone-cracked-concrete',
            'sprite-anchor-fatigue',
            'sprite-anchor-shock',
            'sprite-anchor-seismic',
            'sprite-anchor-small-edge-dist-and-spacing',
            'sprite-anchor-variable-embedment-depth',
            'sprite-anchor-npp-approval-di-bt',
            'sprite-anchor-fire-resistant',
            'sprite-anchor-clean-tech',
            'sprite-anchor-stainless-steel-hcr',
            'sprite-anchor-shallow-embedment-depth',
            'sprite-anchor-diamond-cored-holes-suitable',
            'sprite-anchor-family-no-photo-available',
            'sprite-anchors-1-1',
            'sprite-anchors-2-1',
            'sprite-anchors-2-2',
            'sprite-anchors-3-1',
            'sprite-anchors-3-2',
            'sprite-anchors-3-3',
            'sprite-anchors-3-4',
            'sprite-anchors-4-1',
            'sprite-anchors-4-2',
            'sprite-anchors-4-3',
            'sprite-anchors-6-1',
            'sprite-anchors-6-2',
            'sprite-anchors-6-3',
            'sprite-anchors-8-1',
            'sprite-anchors-8-2',
            'sprite-anchors-8-3',
            'sprite-anchors-9-1',
            'sprite-anchors-12-1',
            'sprite-anchors-16-1',
        );

        this.setNeedsSolutionTextsByRegionAndApplicationId();
        this.setAnchorData();
        this.initializeAnchorSprite();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public getProperty(object: object, property: string) {
        return getProperty(object, property);
    }

    public getBaseplateDimensions(width: string, height: string) {
        return `${width} x ${height}`;
    }

    public close() {
        this.modalInstance.close();
    }

    public formatLength(value?: number) {
        return this.unitService.formatInternalValueAsDefault((value ?? 0), UnitGroup.Length);
    }

    public getIconTooltip(icon: SelectAnchorIcon) {
        const isHNABased = isHnaBasedDesignStandard(this.design.designStandard.id);
        const key = getAnchorIconTooltipTranslationKey(icon, isHNABased);

        return this.localizationService.getString(key);
    }

    private getBoolean(value?: boolean) {
        return value ?? false;
    }

    private setAnchorData() {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

        const anchor = (codeList)
            .find((a) => this.optimizeCaseDetails?.fastenerFamilyId == a.id && a.detailed != undefined && a.detailed != null);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        this.anchor = {
            id: anchor?.id ?? 0,
            name: anchor?.getTranslatedNameText(codeListDeps) ?? '',
            image: anchor?.image ? 'pe-ui-pe-' + anchor.image : '',
            holeDiameters: anchor?.detailed?.holeDiameters as number[],
            fixtureThicknesMin: anchor?.detailed?.fixtureThicknesMin ?? 0,
            fixtureThicknesMax: anchor?.detailed?.fixtureThicknesMax ?? 0,
            description: this.formatDescription(anchor?.detailed?.descriptionFormat ?? '', anchor?.detailed?.descriptionKeys as string[]),
            isNoCleaningRequired: this.getBoolean(anchor?.detailed?.isZeroCleaningAllowed),
            isAutomaticCleaningApproved: this.getBoolean(anchor?.detailed?.isAutomaticCleaningApproved),
            isCrackedConcreteAllowed: this.getBoolean(anchor?.detailed?.isCrackedConcreteAllowed),
            isFatigueAllowed: this.getBoolean(anchor?.detailed?.isFatigueAllowed),
            isShock: this.getBoolean(anchor?.detailed?.isShock),
            isSeismicAllowed: this.getBoolean(anchor?.detailed?.isSeismicAllowed),
            isSmallEdgeDistAndSpacing: this.getBoolean(anchor?.detailed?.isSmallEdgeDistanceAndSpacing),
            isVariableEmbedment: this.getBoolean(anchor?.detailed?.isVariableEmbedment),
            isNPPApprovalDiBt: this.getBoolean(anchor?.detailed?.isNuclearApproved),
            isFireAllowed: this.getBoolean(anchor?.detailed?.isFireAllowed),
            isCleanTech: this.getBoolean(anchor?.detailed?.isCleanTech),
            isStainlessSteelOrHCRAvailable: this.getBoolean(anchor?.detailed?.isStainlessSteelOrHCRAvailable),
            isShallowEmbedmentDepth: this.getBoolean(anchor?.detailed?.isShallowEmbedmentDepth),
            isDiamondCoredHolesSuitable: this.getBoolean(anchor?.detailed?.isDiamondDrillingSuitable),
            tag: anchor?.detailed?.tag != null && anchor.detailed.tag.length > 0 ? this.translate(anchor.detailed.tag) : '',
            tested: this.getBoolean(anchor?.detailed?.tested),
            isNew: isAnchorFamilyMarkedAsNew(anchor?.id ?? 0, this.design) ?? false,
            needsSolutionTexts: this.needsSolutionTextsTranslations(anchor?.id ?? 0) as INeedSolutionText[],
            internalPortfolioOnly: anchor?.internalPortfolioOnly ?? false
        };
    }

    private setNeedsSolutionTextsByRegionAndApplicationId() {
        const needsSolutiontexts = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorNeedsSolutionTextsAnchorGroup] as SmartAnchorNeedsSolutionTextsAnchorGroup[];

        this.needsSolutionTextsAnchorGroup = needsSolutiontexts?.filter((x) =>
            this.anchorIds?.includes(x.anchorGroupId) &&
            x.regionId == this.design.region.id &&
            x.applicationId == this.smartAnchorApplication);
    }

    private formatDescription(descriptionFormat: string, descriptionKeys: string[]) {
        return format(descriptionFormat, ...descriptionKeys.map((descriptionKey) => this.translate(descriptionKey)));
    }

    private getNeedsSolutionTextsByAnchorGroupId(anchorGroupId: number) {
        return this.needsSolutionTextsAnchorGroup?.find((x) => x.anchorGroupId == anchorGroupId);
    }

    private needsSolutionTextsTranslations(anchorGroupId: number) {
        const needsSolutionTextsCodeList = this.getNeedsSolutionTextsByAnchorGroupId(anchorGroupId);

        return needsSolutionTextsCodeList?.needsSolutionTexts.map((x): INeedSolutionText => ({
            need: this.translate('Agito.Hilti.Profis3.SmartAnchor.Needs.' + x.Item1),
            solutionText: this.translate('Agito.Hilti.Profis3.SmartAnchor.SolutionTexts.' + x.Item2)
        }));
    }

    private initializeAnchorSprite() {
        if (this.anchor.image != '') {
            includeSprites(this.elementRef.nativeElement.shadowRoot, this.anchor.image.substring(9) as Sprite);
        }
    }
}
