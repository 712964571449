import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignPe } from '../../../shared/entities/design-pe';
import {
    DeflectionEntity, UtilizationGroupBaseEntity, UtilizationPostEntity, UtilizationRailEntity,
    UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    CalculationStatus, HandrailPostPosition
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    DesignType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { getProperty } from '../../helpers/object-helper';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

interface IWorstResult {
    valueError: boolean;
    textError: boolean;
    textKey: string;
    value: number;
}

interface IUtilization {
    readonly text: string;
    readonly property: string;
    readonly unit?: 'Length';
    readonly showDeflectionInfo: boolean;
    readonly details: ReadonlyArray<{
        readonly text: string;
        readonly property: string;
    }>;
}

@Component({
    templateUrl: './handrail-design.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss', './handrail-design.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class HandrailDesignComponent implements OnInit {
    @Input()
    public id!: string;

    @Input()
    public rail!: UtilizationRailEntity;

    @Input()
    public post!: UtilizationPostEntity;

    @Input()
    public deflection!: DeflectionEntity;

    @Input()
    public design!: DesignPe;

    public collapsed = false;

    public railOpend = false;
    public postOpend = false;
    public deflectionOpend = false;
    public connectionOpend = false;

    public CalculationStatusOK = CalculationStatus.OK;

    public readonly utilizations: ReadonlyArray<IUtilization> = [
        {
            text: 'Agito.Hilti.Profis3.HandrailDesign.Rail',
            property: 'rail',
            showDeflectionInfo: false,
            details: [
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Rail.KinkResistance',
                    property: 'KinkResistance'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Rail.ShearForce',
                    property: 'ShearForce'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Rail.Bending',
                    property: 'Bending'
                }
            ]
        },
        {
            text: 'Agito.Hilti.Profis3.HandrailDesign.Post',
            property: 'post',
            showDeflectionInfo: false,
            details: [
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.KinkResistance',
                    property: 'KinkResistance'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.BucklingProof',
                    property: 'BucklingProof'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.ShearForce',
                    property: 'ShearForce'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.Bending',
                    property: 'Bending'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.FrameNodeConnectionNormal',
                    property: 'FrameNodeConnectionNormal'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.FrameNodeConnectionBending',
                    property: 'FrameNodeConnectionBending'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.NormalChordFaceElastic',
                    property: 'NormalChordFaceElastic'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.NormalPunchingShear',
                    property: 'NormalPunchingShear'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Post.ShearNormalConnectionRegion',
                    property: 'ShearNormalConnectionRegion'
                }
            ]
        },
        {
            text: 'Agito.Hilti.Profis3.HandrailDesign.Connection',
            property: 'connection',
            showDeflectionInfo: false,
            details: [
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Connection.Plates',
                    property: 'Plates'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Connection.Welds',
                    property: 'Welds'
                }
            ]
        },
        {
            text: 'Agito.Hilti.Profis3.HandrailDesign.Deflection',
            property: 'deflection',
            unit: 'Length',
            showDeflectionInfo: true,
            details: [
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Deflection.Post',
                    property: 'Post'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Deflection.Rail',
                    property: 'Rail'
                },
                {
                    text: 'Agito.Hilti.Profis3.HandrailDesign.Deflection.Total',
                    property: 'Total'
                }
            ]
        }
    ];

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private userSettings: UserSettingsService,
        private modalService: ModalService,
        private unitService: UnitService,
        private numberService: NumberService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get postLength() {
        if (this.post == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.post);
        let count = 0;
        props.forEach((propertyName) => {
            if (getProperty(this.post, propertyName) != null && propertyName != 'Decisive') {
                count++;
            }
        });

        return count;
    }

    public get deflectionLength() {
        if (this.deflection == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.deflection);
        let count = 0;
        props.forEach((propertyName) => {
            if (getProperty(this.deflection, propertyName) != null && propertyName != 'Decisive' && propertyName != 'Total') {
                count++;
            }
        });

        return count;
    }

    public get railLength() {
        if (this.rail == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.rail);
        let count = 0;
        props.forEach((propertyName) => {
            if (getProperty(this.rail, propertyName) != null && propertyName != 'Decisive') {
                count++;
            }
        });

        return count;
    }

    public get connectionLength() {
        if (this.connection == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.connection);
        let count = 0;
        props.forEach((propertyName) => {
            if (this.connection && getProperty(this.connection, propertyName) != null && propertyName != 'Decisive') {
                count++;
            }
        });

        return count;
    }

    public get railDetails() {
        return this.rail != null;
    }

    public get postDetails() {
        return this.post != null;
    }

    public get deflectionDetails() {
        return this.deflection != null;
    }

    public get connectionDetails() {
        return this.connection != null;
    }

    public get railWorst(): IWorstResult | undefined {
        if (this.rail == null) {
            return undefined;
        }

        let worst: UtilizationValueEntity | undefined = undefined;
        let textKey = '';

        if (this.rail.ShearForce != null) {
            worst = this.rail.ShearForce;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Rail.ShearForce';
        }

        if (this.rail.Bending != null && (worst == null || this.rail.Bending.Value > worst.Value)) {
            worst = this.rail.Bending;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Rail.Bending';
        }

        if (worst == null) {
            return undefined;
        }

        return {
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        };
    }

    public get postWorst(): IWorstResult | undefined {
        if (this.post == null) {
            return undefined;
        }

        let worst: UtilizationValueEntity | undefined = undefined;
        let textKey = '';

        if (this.post.BucklingProof != null) {
            worst = this.post.BucklingProof;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.BucklingProof';
        }

        if (this.isWorseResult(worst?.Value, this.post.ShearForce?.Value)) {
            worst = this.post.ShearForce;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.ShearForce';
        }

        if (this.isWorseResult(worst?.Value, this.post.Bending?.Value)) {
            worst = this.post.Bending;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.Bending';
        }

        if (this.isWorseResult(worst?.Value, this.post.FrameNodeConnectionNormal?.Value)) {
            worst = this.post.FrameNodeConnectionNormal;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.FrameNodeConnectionNormal';
        }

        if (this.isWorseResult(worst?.Value, this.post.FrameNodeConnectionBending?.Value)) {
            worst = this.post.FrameNodeConnectionBending;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.FrameNodeConnectionBending';
        }

        if (this.isWorseResult(worst?.Value, this.post.NormalChordFaceElastic?.Value)) {
            worst = this.post.NormalChordFaceElastic;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.NormalChordFaceElastic';
        }

        if (this.isWorseResult(worst?.Value, this.post.NormalPunchingShear?.Value)) {
            worst = this.post.NormalPunchingShear;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.NormalPunchingShear';
        }

        if (this.isWorseResult(worst?.Value, this.post.ShearNormalConnectionRegion?.Value)) {
            worst = this.post.ShearNormalConnectionRegion;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Post.ShearNormalConnectionRegion';
        }

        if (worst == null) {
            return undefined;
        }

        return {
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        };
    }

    public get connection() {
        if (this.design.baseplateDesignData == null) {
            return null;
        }

        return this.design.baseplateDesignData.CalculationSummary;
    }

    public get connectionWorst(): IWorstResult | undefined {
        if (this.connection == null) {
            return undefined;
        }

        let worst: UtilizationValueEntity | undefined = undefined;
        let textKey = '';

        if (this.connection.Plates != null) {
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Connection.Plates';
            worst = this.connection.Plates;
        }

        if (this.connection.Welds != null && (worst == null || this.connection.Welds.Value > worst.Value)) {
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Connection.Welds';
            worst = this.connection.Welds;
        }

        if (worst == null) {
            return undefined;
        }

        return {
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        };
    }

    public get deflectionWorst(): IWorstResult | undefined {
        if (this.deflection == null) {
            return undefined;
        }

        let worst: UtilizationValueEntity | undefined = undefined;
        let textKey = '';

        if (this.deflection.Post != null) {
            worst = this.deflection.Post;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Deflection.Post';
        }

        if (this.deflection.Rail != null && (worst == null || this.deflection.Rail.Value > worst.Value)) {
            worst = this.deflection.Rail;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Deflection.Rail';
        }

        if (this.deflection.Total != null && (worst == null || this.deflection.Total.Value > worst.Value)) {
            worst = this.deflection.Total;
            textKey = 'Agito.Hilti.Profis3.HandrailDesign.Deflection.Total';
        }

        if (worst == null) {
            return undefined;
        }

        return {
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        };
    }

    public get handrailValue() {
        if (this.deflection == null || this.deflection.Decisive == null ||
            this.rail == null || this.rail.Decisive == null ||
            this.post == null || this.post.Decisive == null) {

            return null;
        }

        return this.numberService.round(Math.max(this.deflection.Decisive.Percentage, this.rail.Decisive.Percentage, this.post.Decisive.Percentage), 0);
    }

    public get handrailInvalid() {
        return this.deflection == null || this.deflection.Decisive == null || this.deflection.Decisive.ValueError || this.deflection.Decisive.TextError ||
            this.rail == null || this.rail.Decisive == null || this.rail.Decisive.ValueError || this.rail.Decisive.TextError ||
            this.post == null || this.post.Decisive == null || this.post.Decisive.ValueError || this.post.Decisive.TextError;
    }

    public get isDeflectionLimitsVisible() {
        return this.userService.design?.region?.id == KnownRegion.Hungary;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info',
            'sprite-arrow-up',
            'sprite-arrow-down'
        );

        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.HandrailDesign);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public openDeflectionLimits() {
        this.modalService.openDeflectionLimits();
    }

    public transformValue(value: number, unit?: string) {
        if (unit == 'Length') {
            const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);
            const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);

            return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit), defaultUnit);
        }

        return this.numberService.format(value, 0) + '%';
    }

    public getPostPositionTranslationKey() {
        switch (this.design.designData.reportData?.DecisiveHandrailPostPosition) {
            case HandrailPostPosition.Edge:
                return 'Agito.Hilti.Profis3.HandrailPostPosition.Edge';
            case HandrailPostPosition.Middle:
                return 'Agito.Hilti.Profis3.HandrailPostPosition.Middle';
            default:
                throw new Error('Unknown handrail post position');
        }
    }

    public isHandrailPostPositionAvailable() {
        return this.design.designData?.projectDesign?.ProjectDesignType == DesignType.Handrail && this.design.designData?.reportData?.DecisiveHandrailPostPosition != null;
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.HandrailDesign, collapsed);
    }

    public changeUtilizationPropertyOpend(property: string) {
        switch (property) {
            case 'rail':
                this.railOpend = !this.railOpend;
                break;
            case 'post':
                this.postOpend = !this.postOpend;
                break;
            case 'deflection':
                this.deflectionOpend = !this.deflectionOpend;
                break;
            case 'connection':
                this.connectionOpend = !this.connectionOpend;
                break;
        }
    }

    public getUtilizationFromProperty(property: string): UtilizationGroupBaseEntity {
        return getProperty(this, property) as UtilizationGroupBaseEntity;
    }

    public getUtilizationOpendFromProperty(property: string): boolean {
        return getProperty(this, `${property}Opend`) as boolean;
    }

    public getUtilizationWorstFromProperty(property: string): IWorstResult {
        return getProperty(this, `${property}Worst`) as IWorstResult;
    }

    public getUtilizationLengthFromProperty(property: string): number {
        return getProperty(this, `${property}Length`) as number;
    }

    public getUtilizationDetailsFromProperty(property: string): boolean {
        return getProperty(this, `${property}Details`) as boolean;
    }

    public getUtilizationValueEntityFromDetail(property: string, detailProperty: string): UtilizationValueEntity {
        return this.getDetailProperty(this, property, detailProperty) as UtilizationValueEntity;
    }

    private getDetailProperty(object: object, property: string, detailProperty: string) {
        const propertyObj = getProperty(object, property);
        return getProperty(propertyObj, detailProperty);
    }

    private isWorseResult(worst: number | undefined, utilization: number | undefined) {
        if (utilization == null) {
            return false;
        }

        if (worst == null) {
            return true;
        }

        return utilization > worst;
    }
}
