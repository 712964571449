<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.Windloads.Title')"
    [closeButtonId]="closeButtonId"
    [closeButtonStyle]="closeButtonStyle"
    [closeButtonDisabled]="submitted"
    [preventDefaultClose]="true"
    (closing)="close()">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate
    [hidden]="activeScreen != displayTypeEnum.Default">
    <pe-modal-body>
        <div class="section">
            <div class="section-row">
                <div class="orography section-column separator"
                    *ngIf="!isPropertyHidden(propertyMetaData.Application_TerrainType.id)">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.Windloads.Orography')"></p>

                    <div class="image-container orography-container control-row"
                        *ngIf="isOrographyRelevant && !isPropertyHidden(propertyMetaData.Application_TerrainType.id)">
                        <div class="image orography-image" *ngIf="terrainTypeImage">
                            <img [src]="terrainTypeImage" alt="terrain type" />
                            <div class="pe-ui-pe-sprite pe-ui-pe-sprite-open-image open-button orography-button"
                                (click)="setActiveScreen(displayTypeEnum.Orography)" [attr.disabled]="submitted ? true : null">
                            </div>
                        </div>

                        <pe-dropdown
                            *ngIf="!isPropertyHidden(propertyMetaData.Application_TerrainType.id)"
                            [id]="terrainTypeDropdown.id"
                            [title]="terrainTypeDropdown.title"
                            [items]="terrainTypeDropdown.items"
                            [selectedValue]="terrainTypeDropdown.selectedValue"
                            (selectedValueChange)="terrainTypeDropdown.selectedValue = $any($event).detail; onTerrainTypeChange()"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_TerrainType.id)"
                            (isValid)="terrainTypeDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row multiple" *ngIf="isOrographyRelevant">
                        <div class="control-column" *ngIf="!isPropertyHidden(propertyMetaData.Application_H.id)">
                            <pe-numeric-text-box
                                [id]="inputHTextBox.id"
                                [title]="inputHTextBox.title"
                                [unit]="inputHTextBox.unit"
                                [minValue]="inputHTextBox.minValue"
                                [maxValue]="inputHTextBox.maxValue"
                                [value]="inputHTextBox.value"
                                (valueChange)="inputHTextBox.value = $any($event).detail"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_H.id)"
                                (isValid)="inputHTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-column" *ngIf="!isPropertyHidden(propertyMetaData.Application_X.id)">
                            <pe-numeric-text-box
                                [id]="inputXTextBox.id"
                                [title]="inputXTextBox.title"
                                [unit]="inputXTextBox.unit"
                                [minValue]="inputXTextBox.minValue"
                                [maxValue]="inputXTextBox.maxValue"
                                [value]="inputXTextBox.value"
                                (valueChange)="inputXTextBox.value = $any($event).detail"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_X.id)"
                                (isValid)="inputXTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="control-row multiple" *ngIf="isOrographyRelevant">
                        <div class="control-column" *ngIf="!isPropertyHidden(propertyMetaData.Application_Lu.id)">
                            <pe-numeric-text-box
                                [id]="inputLuTextBox.id"
                                [title]="inputLuTextBox.title"
                                [unit]="inputLuTextBox.unit"
                                [minValue]="inputLuTextBox.minValue"
                                [maxValue]="inputLuTextBox.maxValue"
                                [value]="inputLuTextBox.value"
                                (valueChange)="inputLuTextBox.value = $any($event).detail"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_Lu.id)"
                                (isValid)="inputLuTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-column"
                            *ngIf="!(isPropertyHidden(propertyMetaData.Application_Ld.id) || isInputLdHidden)">
                            <pe-numeric-text-box
                                [id]="inputLdTextBox.id"
                                [title]="inputLdTextBox.title"
                                [unit]="inputLdTextBox.unit"
                                [minValue]="inputLdTextBox.minValue"
                                [maxValue]="inputLdTextBox.maxValue"
                                [value]="inputLdTextBox.value"
                                (valueChange)="inputLdTextBox.value = $any($event).detail"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_Ld.id)"
                                (isValid)="inputLdTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <pe-checkbox-button
                        class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_IsOrographyRelevant.id)"
                        [id]="isOrographyNotRelevantCheckbox.id"
                        [items]="isOrographyNotRelevantCheckbox.items"
                        [selectedValues]="isOrographyNotRelevantCheckbox.selectedValues"
                        (selectedValuesChange)="onIsOrographyNotRelevantChange($any($event).detail)"
                        [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_IsOrographyRelevant.id)">
                    </pe-checkbox-button>
                </div>

                <div class="environment section-column separator">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.Windloads.Environment')"></p>

                    <div class="image-container wind-zone-container control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_WindZone.id) && windZoneImage && isPropertyHidden(propertyMetaData.Application_ZipCodeHandrailLoadsId.id)">
                        <div class="image wind-zone-image">
                            <img [src]="windZoneImage" alt="wind zone" />
                            <div class="pe-ui-pe-sprite pe-ui-pe-sprite-open-image open-button wind-zone-button"
                                (click)="setActiveScreen(displayTypeEnum.Environment)" [attr.disabled]="submitted ? true : null">
                            </div>
                        </div>
                    </div>

                    <pe-text-box
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_ZipCodeHandrailLoadsId.id)"
                        [id]="zipCodeFilterTextBox.id"
                        [title]="zipCodeFilterTextBox.title"
                        [value]="zipCodeFilterTextBox.value"
                        (valueChange)="zipCodeFilterTextBox.value = $any($event).detail"
                        (inputBlur)="onZipCodeFilterChanged()"
                        [validationData]="zipCodeFilterTextBox.validationData"
                        (isValid)="zipCodeFilterTextBox.isValid = $any($event).detail"
                        [preventFormSubmit]="true"
                        [disabled]="submitted || zipCodeLoading || isPropertyDisabled(propertyMetaData.Application_ZipCodeHandrailLoadsId.id)">
                    </pe-text-box>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_ZipCodeHandrailLoadsId.id)">
                        <pe-dropdown
                            [id]="zipCodeCityDropdown.id"
                            [title]="zipCodeCityDropdown.title"
                            [items]="zipCodeCityDropdown.items"
                            [selectedValue]="zipCodeCityDropdown.selectedValue"
                            (selectedValueChange)="onZipCodeCityChanged($any($event).detail)"
                            [disabled]="submitted || zipCodeLoading || isPropertyDisabled(propertyMetaData.Application_ZipCodeHandrailLoadsId.id) || (zipCodeCityDropdown.items?.length ?? 0) < 2"
                            [validators]="[requiredValidator]"
                            (isValid)="zipCodeCityDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row multiple"
                        *ngIf="(!isPropertyHidden(propertyMetaData.Application_WindZone.id) && (windZoneDropdown?.items?.length ?? 0) > 0) || (!isPropertyHidden(propertyMetaData.Application_Altitude.id))">
                        <pe-dropdown
                            *ngIf="!isPropertyHidden(propertyMetaData.Application_WindZone.id) && (windZoneDropdown?.items?.length ?? 0) > 0"
                            class="control-column"
                            [id]="windZoneDropdown.id"
                            [title]="windZoneDropdown.title"
                            [items]="windZoneDropdown.items"
                            [selectedValue]="windZoneDropdown.selectedValue"
                            (selectedValueChange)="windZoneDropdown.selectedValue = $any($event).detail; onTerrainTypeChange()"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_WindZone.id)"
                            (isValid)="windZoneDropdown.isValid = $any($event).detail">
                        </pe-dropdown>

                        <div class="control-column"
                            *ngIf="!isPropertyHidden(propertyMetaData.Application_Altitude.id)">
                            <pe-numeric-text-box
                                [id]="altitudeTextBox.id"
                                [title]="altitudeTextBox.title"
                                [unit]="altitudeTextBox.unit"
                                [minValue]="altitudeTextBox.minValue"
                                [maxValue]="altitudeTextBox.maxValue"
                                [value]="altitudeTextBox.value"
                                (valueChange)="altitudeTextBox.value = $any($event).detail"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_Altitude.id)"
                                (isValid)="altitudeTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="control-row multiple"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_DistanceToSeaside.id)">
                        <div class="control-column">
                            <pe-numeric-text-box
                                [id]="distanceToSeasideTextBox.id"
                                [title]="distanceToSeasideTextBox.title"
                                [unit]="distanceToSeasideTextBox.unit"
                                [minValue]="distanceToSeasideTextBox.minValue"
                                [maxValue]="distanceToSeasideTextBox.maxValue"
                                [value]="distanceToSeasideTextBox.value"
                                (valueChange)="distanceToSeasideTextBox.value = $any($event).detail"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_DistanceToSeaside.id)"
                                (isValid)="distanceToSeasideTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="control-row"
                        *ngIf="(!isPropertyHidden(propertyMetaData.Application_CityAndStateId.id) && isCountyAndCommune)">
                        <pe-dropdown
                            [id]="stateDropdown.id"
                            [title]="stateDropdown.title"
                            [items]="stateDropdown.items"
                            [selectedValue]="stateDropdown.selectedValue"
                            (selectedValueChange)="stateDropdown.selectedValue = $any($event).detail; onStateDropdownSelectedValueChange()"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_CityAndStateId.id)"
                            (isValid)="stateDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row"
                        *ngIf="(!isPropertyHidden(propertyMetaData.Application_CityAndStateId.id) && isCountyAndCommune)">
                        <pe-dropdown
                            [id]="cityDropdown.id"
                            [title]="cityDropdown.title"
                            [items]="cityDropdown.items"
                            [selectedValue]="cityDropdown.selectedValue"
                            (selectedValueChange)="cityDropdown.selectedValue = $any($event).detail;"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_CityAndStateId.id)"
                            (isValid)="cityDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_TerrainCategoryId.id)">
                        <pe-dropdown
                            [id]="terrainCategoryDropdown.id"
                            [title]="terrainCategoryDropdown.title"
                            [items]="terrainCategoryDropdown.items"
                            [selectedValue]="terrainCategoryDropdown.selectedValue"
                            (selectedValueChange)="terrainCategoryDropdown.selectedValue = $any($event).detail"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_TerrainCategoryId.id)"
                            (isValid)="terrainCategoryDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row multiple"
                        *ngIf="(!isPropertyHidden(propertyMetaData.Application_CityAndStateId.id) && !isCountyAndCommune)">
                        <div class="control-column">
                            <pe-dropdown
                                [id]="stateDropdown.id"
                                [title]="stateDropdown.title"
                                [items]="stateDropdown.items"
                                [selectedValue]="stateDropdown.selectedValue"
                                (selectedValueChange)="stateDropdown.selectedValue = $any($event).detail; onStateDropdownSelectedValueChange()"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_CityAndStateId.id)"
                                (isValid)="stateDropdown.isValid = $any($event).detail">
                            </pe-dropdown>
                        </div>

                        <div class="control-column">
                            <pe-dropdown
                                [id]="cityDropdown.id"
                                [title]="cityDropdown.title"
                                [items]="cityDropdown.items"
                                [selectedValue]="cityDropdown.selectedValue"
                                (selectedValueChange)="cityDropdown.selectedValue = $any($event).detail"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_CityAndStateId.id)"
                                (isValid)="cityDropdown.isValid = $any($event).detail">
                            </pe-dropdown>
                        </div>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_RoughnessClassId.id)">
                        <pe-dropdown
                            [id]="roughnessClassDropdown.id"
                            [title]="roughnessClassDropdown.title"
                            [items]="roughnessClassDropdown.items"
                            [selectedValue]="roughnessClassDropdown.selectedValue"
                            (selectedValueChange)="roughnessClassDropdown.selectedValue = $any($event).detail"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_RoughnessClassId.id)"
                            (isValid)="roughnessClassDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_ReturnPeriod.id)">
                        <div class="control-column">
                            <pe-numeric-text-box
                                [id]="returnPeriodTextBox.id"
                                [title]="returnPeriodTextBox.title"
                                [minValue]="returnPeriodTextBox.minValue"
                                [maxValue]="returnPeriodTextBox.maxValue"
                                [value]="returnPeriodTextBox.value"
                                (valueChange)="returnPeriodTextBox.value = $any($event).detail"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_ReturnPeriod.id)"
                                (isValid)="returnPeriodTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_DistanceUpwindToShorelineId.id) && !isDistanceUpwindToShorelineDisabled">
                        <pe-dropdown
                            [id]="distanceUpwindToShorelineDropdown.id"
                            [title]="distanceUpwindToShorelineDropdown.title"
                            [items]="distanceUpwindToShorelineDropdown.items"
                            [selectedValue]="distanceUpwindToShorelineDropdown.selectedValue"
                            (selectedValueChange)="distanceUpwindToShorelineDropdown.selectedValue = $any($event).detail"
                            [disabled]="submitted || isDistanceUpwindToShorelineDisabled"
                            (isValid)="distanceUpwindToShorelineDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_DistanceInsideTownTerrainId.id) && !isDistanceInsideTownTerrainDisabled">
                        <pe-dropdown
                            [id]="distanceInsideTownTerrainDropdown.id"
                            [title]="distanceInsideTownTerrainDropdown.title"
                            [items]="distanceInsideTownTerrainDropdown.items"
                            [selectedValue]="distanceInsideTownTerrainDropdown.selectedValue"
                            (selectedValueChange)="distanceInsideTownTerrainDropdown.selectedValue = $any($event).detail"
                            [disabled]="submitted || isDistanceInsideTownTerrainDisabled"
                            (isValid)="distanceInsideTownTerrainDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_BasicWindVelocityId.id)">
                        <pe-dropdown
                            [id]="basicWindVelocityDropdown.id"
                            [title]="basicWindVelocityDropdown.title"
                            [items]="basicWindVelocityDropdown.items"
                            [selectedValue]="basicWindVelocityDropdown.selectedValue"
                            (selectedValueChange)="basicWindVelocityDropdown.selectedValue = $any($event).detail"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_BasicWindVelocityId.id)"
                            (isValid)="basicWindVelocityDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Loads_FundamentalWindVelocity.id)">
                        <pe-numeric-text-box
                            [id]="fundamentalWindVelocityTextBox.id"
                            [title]="fundamentalWindVelocityTextBox.title"
                            [unit]="fundamentalWindVelocityTextBox.unit"
                            [minValue]="fundamentalWindVelocityTextBox.minValue"
                            [maxValue]="fundamentalWindVelocityTextBox.maxValue"
                            [value]="fundamentalWindVelocityTextBox.value"
                            (valueChange)="fundamentalWindVelocityTextBox.value = $any($event).detail"
                            (enterPressed)="save(ngForm)"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Loads_FundamentalWindVelocity.id)"
                            (isValid)="fundamentalWindVelocityTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_ReferenceWindVelocityId.id)">
                        <pe-dropdown
                            [id]="referenceWindVelocityDropdown.id"
                            [title]="referenceWindVelocityDropdown.title"
                            [items]="referenceWindVelocityDropdown.items"
                            [selectedValue]="referenceWindVelocityDropdown.selectedValue"
                            (selectedValueChange)="referenceWindVelocityDropdown.selectedValue = $any($event).detail"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_ReferenceWindVelocityId.id)"
                            (isValid)="referenceWindVelocityDropdown.isValid = $any($event).detail">
                        </pe-dropdown>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_WindVelocityPressure.id)">
                        <pe-numeric-text-box
                            [id]="referenceVelocityPressureTextBox.id"
                            [title]="referenceVelocityPressureTextBox.title"
                            [unit]="referenceVelocityPressureTextBox.unit"
                            [minValue]="referenceVelocityPressureTextBox.minValue"
                            [maxValue]="referenceVelocityPressureTextBox.maxValue"
                            [value]="referenceVelocityPressureTextBox.value"
                            (valueChange)="referenceVelocityPressureTextBox.value = $any($event).detail"
                            (enterPressed)="save(ngForm)"
                            [precision]="referenceVelocityPressureTextBox.precision"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_WindVelocityPressure.id)"
                            (isValid)="referenceVelocityPressureTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_WindPressure.id)">
                        <pe-numeric-text-box
                            [id]="windPressureTextBox.id"
                            [title]="windPressureTextBox.title"
                            [unit]="windPressureTextBox.unit"
                            [precision]="windPressureTextBox.precision"
                            [minValue]="windPressureTextBox.minValue"
                            [maxValue]="windPressureTextBox.maxValue"
                            [value]="windPressureTextBox.value"
                            (valueChange)="windPressureTextBox.value = $any($event).detail"
                            (enterPressed)="save(ngForm)"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_WindPressure.id)"
                            (isValid)="windPressureTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_WindUplift.id)">
                        <pe-numeric-text-box
                            [id]="windUpliftTextBox.id"
                            [title]="windUpliftTextBox.title"
                            [unit]="windUpliftTextBox.unit"
                            [precision]="windUpliftTextBox.precision"
                            [minValue]="windUpliftTextBox.minValue"
                            [maxValue]="windUpliftTextBox.maxValue"
                            [value]="windUpliftTextBox.value"
                            (valueChange)="windUpliftTextBox.value = $any($event).detail"
                            (enterPressed)="save(ngForm)"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_WindUplift.id)"
                            (isValid)="windUpliftTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>
                </div>

                <div class="handrail section-column separator"
                    *ngIf="isBuildingDimensionsVisible || isBuildingZoneVisible">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.Windloads.Dimensions')"></p>

                    <div class="image-container dimensions-image-container control-row">
                        <div class="image dimensions-image" *ngIf="dimensionsImage">
                            <img [src]="dimensionsImage" alt="dimensions" />
                            <div class="pe-ui-pe-sprite pe-ui-pe-sprite-open-image open-button dimensions-button"
                                (click)="setActiveScreen(displayTypeEnum.Dimensions)"
                                [attr.disabled]="submitted ? true : null"></div>
                        </div>
                    </div>

                    <pe-checkbox-button
                        class="control-row"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_IsFreeStandingWall.id)"
                        [id]="isFreeStandingWallCheckbox.id"
                        [title]="isFreeStandingWallCheckbox.title"
                        [items]="isFreeStandingWallCheckbox.items"
                        [selectedValues]="isFreeStandingWallCheckbox.selectedValues"
                        (selectedValuesChange)="isFreeStandingWallCheckbox.selectedValues = $any($event).detail; onIsFreeStandingWallChange()"
                        [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_IsFreeStandingWall.id)">
                    </pe-checkbox-button>

                    <div class="control-row"
                        *ngIf="isFreeStandingWallCategory || isBridgeCategory">
                        <pe-numeric-text-box
                            [id]="handrailLengthTextBox.id"
                            [title]="handrailLengthTranslation"
                            [unit]="handrailLengthTextBox.unit"
                            [minValue]="handrailLengthTextBox.minValue"
                            [maxValue]="handrailLengthTextBox.maxValue"
                            [value]="handrailLengthTextBox.value"
                            (valueChange)="handrailLengthTextBox.value = $any($event).detail; onHandrailDimensionsChange()"
                            (enterPressed)="save(ngForm)"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="submitted"
                            (isValid)="handrailLengthTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row multiple" *ngIf="isBuildingCategory">
                        <div class="control-column">
                            <pe-numeric-text-box
                                [id]="buildingWidthTextBox.id"
                                [title]="buildingWidthTextBox.title"
                                [unit]="buildingWidthTextBox.unit"
                                [minValue]="buildingWidthTextBox.minValue"
                                [maxValue]="buildingWidthTextBox.maxValue"
                                [value]="buildingWidthTextBox.value"
                                (valueChange)="buildingWidthTextBox.value = $any($event).detail; onBuildingWidthChange()"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_BuildingWidth.id)"
                                (isValid)="buildingWidthTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-column">
                            <pe-numeric-text-box
                                [id]="buildingHeightTextBox.id"
                                [title]="buildingHeightTextBox.title"
                                [unit]="buildingHeightTextBox.unit"
                                [minValue]="buildingHeightTextBox.minValue"
                                [maxValue]="buildingHeightTextBox.maxValue"
                                [value]="buildingHeightTextBox.value"
                                (valueChange)="buildingHeightTextBox.value = $any($event).detail; onHandrailDimensionsChange()"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_BuildingHeight.id)"
                                (isValid)="buildingHeightTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="control-row multiple">
                        <div class="control-column" *ngIf="isBuildingCategory">
                            <pe-numeric-text-box
                                [id]="buildingDepthTextBox.id"
                                [title]="buildingDepthTextBox.title"
                                [unit]="buildingDepthTextBox.unit"
                                [minValue]="buildingDepthTextBox.minValue"
                                [maxValue]="buildingDepthTextBox.maxValue"
                                [value]="buildingDepthTextBox.value"
                                (valueChange)="buildingDepthTextBox.value = $any($event).detail; onBuildingDepthChange()"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_BuildingDepth.id)"
                                (isValid)="buildingDepthTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>

                        <div class="control-column">
                            <pe-numeric-text-box
                                [id]="handrailHeightOverGroundTextBox.id"
                                [title]="handrailHeightOverGroundTextBox.title"
                                [unit]="handrailHeightOverGroundTextBox.unit"
                                [value]="handrailHeightOverGroundTextBox.value"
                                (valueChange)="handrailHeightOverGroundTextBox.value = $any($event).detail; onHandrailDimensionsChange()"
                                (enterPressed)="save(ngForm)"
                                [updateOnBlur]="true"
                                [required]="true"
                                [disabled]="submitted"
                                (isValid)="handrailHeightOverGroundTextBox.isValid = $any($event).detail">
                            </pe-numeric-text-box>
                        </div>
                    </div>

                    <div class="control-row"
                        *ngIf="isFreeStandingWallCategory || isBridgeCategory">
                        <pe-numeric-text-box
                            [id]="handrailHeightTextBox.id"
                            [title]="handrailHeightTextBox.title"
                            [unit]="handrailHeightTextBox.unit"
                            [minValue]="handrailHeightTextBox.minValue"
                            [maxValue]="handrailHeightTextBox.maxValue"
                            [value]="handrailHeightTextBox.value"
                            (valueChange)="handrailHeightTextBox.value = $any($event).detail"
                            (enterPressed)="save(ngForm)"
                            [updateOnBlur]="true"
                            [required]="true"
                            [disabled]="true"
                            (isValid)="handrailHeightTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <p class="title-strike title" [innerHtml]="buildingZoneTranslation"></p>

                    <div class="image-container installation-zone-container control-row">
                        <div class="image installation-zone-image" *ngIf="installationZoneImage">
                            <img [src]="installationZoneImage" alt="installation zone" />
                            <div class="pe-ui-pe-sprite pe-ui-pe-sprite-open-image open-button installation-zone-button"
                                (click)="setActiveScreen(displayTypeEnum.InstallationZone)"
                                [attr.disabled]="submitted ? true : null"></div>
                        </div>
                    </div>

                    <p class="text-label" [innerHtml]="translate('Agito.Hilti.Profis3.Windloads.BuildingZoneExplanation')"></p>

                    <div class="control-row building-zone-group"
                        *ngIf="!isPropertyHidden(propertyMetaData.Application_BuildingZone.id)">
                        <div class="content">
                            <pe-radio-button
                                class="radio data-item"
                                [items]="buildingZoneGroupItems"
                                [selectedValue]="buildingZoneGroupSelected"
                                (selectedValueChange)="buildingZoneGroupSelected = $any($event).detail"
                                [disabled]="submitted || isPropertyDisabled(propertyMetaData.Application_BuildingZone.id)">
                            </pe-radio-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            class="modal-button"
            id="windloads-cancel-button"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.Windloads.Cancel')"
            [disabled]="submitted || zipCodeLoading">
        </pe-button>

        <pe-button
            class="modal-button"
            type="submit"
            id="windloads-save-button"
            [look]="'Cta'"
            [disabled]="isSaveDisabled(ngForm)">
            <pe-loading-button
                [loading]="submitted || zipCodeLoading"
                [text]="translate('Agito.Hilti.Profis3.Windloads.Save')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>

<div class="modal-image-full" *ngIf="terrainTypeImage" [hidden]="activeScreen != displayTypeEnum.Orography">
    <img [src]="terrainTypeImage" alt="terrain type" />

    <div class="image-link" *ngIf="!offline.isOffline">
        <div>
            {{imageSourceTranslation}}
            <span class="image-link-url" (click)="openLink(terrainTypeImage)">{{terrainTypeImageText}}</span>
        </div>
    </div>
</div>

<div class="modal-image-full" *ngIf="windZoneImage" [hidden]="activeScreen != displayTypeEnum.Environment">
    <img [src]="windZoneImage" alt="wind zone" />

    <div class="image-link" *ngIf="windZoneImageSourceExists">
        {{imageSourceTranslation}} {{windZoneImageSourceTranslation}}
    </div>
</div>

<div class="modal-image-full" *ngIf="installationZoneImage" [hidden]="activeScreen != displayTypeEnum.InstallationZone">
    <img [src]="installationZoneImage" alt="installation zone" />

    <div class="image-link" *ngIf="!offline.isOffline">
        <div>
            {{imageSourceTranslation}}
            <span class="image-link-url" (click)="openLink(installationZoneImage)">{{installationZoneImageText}}</span>
        </div>
    </div>
</div>

<div class="modal-image-full" *ngIf="dimensionsImage" [hidden]="activeScreen != displayTypeEnum.Dimensions">
    <img [src]="dimensionsImage" alt="dimensions" />

    <div class="image-link" *ngIf="!offline.isOffline">
        <div>
            {{imageSourceTranslation}}
            <span class="image-link-url" (click)="openLink(dimensionsImage)">{{dimensionsImageText}}</span>
        </div>
    </div>
</div>
