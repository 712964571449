import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import circularThroughMixedProfileImage from '../../../images/handrail-horizontal-post-connection/circular-through-mixed-profile.png';
import circularTransverseMixedProfileImage from '../../../images/handrail-horizontal-post-connection/circular-transverse-mixed-profile.png';
import squareThroughMixedProfileImage from '../../../images/handrail-horizontal-post-connection/square-through-mixed-profile.png';
import squareTransverseMixedProfileImage from '../../../images/handrail-horizontal-post-connection/square-transverse-mixed-profile.png';

import { ProfileFamily } from '../../../shared/entities/code-lists/profile-family';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    ProfileShapeType as ProfileShape
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './horizontal-post-profile-connection.component.html',
    styleUrls: ['./horizontal-post-profile-connection.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class HorizontalPostProfileConnectionComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public transverseImage!: string;
    public throughImage!: string;

    constructor(
        private user: UserService,
        private localization: LocalizationService
    ) { }

    ngOnInit(): void {
        this.setImages();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    private setImages() {
        if (this.user.design) {
            const codeList = this.user.design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];
            const profile = codeList.find((p) => p.id == this.user.design.handrailPostProfileFamilyId);

            if (profile != null) {
                switch (profile.shape) {
                    case ProfileShape.Cylinder:
                    case ProfileShape.Pipe:
                        this.transverseImage = circularTransverseMixedProfileImage;
                        this.throughImage = circularThroughMixedProfileImage;
                        break;

                    case ProfileShape.Rectangular:
                    case ProfileShape.SquareBar:
                    case ProfileShape.SquareHollow:
                        this.transverseImage = squareTransverseMixedProfileImage;
                        this.throughImage = squareThroughMixedProfileImage;
                        break;
                }
            }
        }
    }
}
