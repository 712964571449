import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    ReinforcementTensionConditionEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface IReinforcementTensionConditionConstructor extends ICodeListConstructor {
    perDesignMethodGroupDisplayKeyExtensions?: { [Key: string]: string };
}

export class ReinforcementTensionCondition extends CodeList {
    public perDesignMethodGroupDisplayKeyExtensions?: { [Key: string]: string };

    constructor(codeList: IReinforcementTensionConditionConstructor) {
        super(codeList);

        if (codeList != null) {
            this.perDesignMethodGroupDisplayKeyExtensions = codeList.perDesignMethodGroupDisplayKeyExtensions;
        }
    }

    public static fromService(codeList: ReinforcementTensionConditionEntity) {
        const reinforcementTensionCondition = new ReinforcementTensionCondition(CodeList.baseFromService<ICodeListConstructor>('ReinforcementTensionConditionEntity', codeList));

        // set additional display keys
        reinforcementTensionCondition.perDesignMethodGroupDisplayKeyExtensions = codeList.PerDesignMethodGroupDisplayKeyExtensions;

        return reinforcementTensionCondition;
    }

    public override cloneDeep(): ReinforcementTensionCondition {
        const codeList = super.cloneDeep() as ReinforcementTensionCondition;

        codeList.perDesignMethodGroupDisplayKeyExtensions = this.perDesignMethodGroupDisplayKeyExtensions;

        return codeList;
    }
}
