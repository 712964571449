import escape from 'lodash-es/escape';

import { HttpResponseBase } from '@angular/common/http';
import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import {
    CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    LoadType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    IntegrationDataResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities';
import {
    DataIntegrationRequestType, DataIntegrationType, ErrorType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    RobotLoadCombinationRequest, RobotLoadCombinationResponse, RobotNodeSelectionResponse,
    RobotResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Robot';
import {
    RobotLoadCombinationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Robot.Enums';
import {
    RobotBaseLoadCombinationInitialData, RobotLoadCase, RobotLoadCombinationData,
    RobotLoadCombinationInitialData
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Robot.Models';
import {
    UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';

import { environment } from '../../../environments/environmentPe';
import { concatFilterLoadCombinations } from '../../helpers/load-combination-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { GuidService } from '../../services/guid.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { includeSprites } from '../../sprites';
import { getProperty } from '../../helpers/object-helper';
import { SharedEnvironmentService } from '../../services/shared-environment.service';

interface IUserNotification {
    readonly controlEnableFlag: string;
    readonly buttonTranslationKey: string;
    readonly notificationTranslationKey: string;
}

interface RobotLoadCombinationDataExtension extends RobotLoadCombinationInitialData {
    FormattedName?: string;
}

@Component({
    templateUrl: './robot-import.component.html',
    styleUrls: ['./robot-import.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class RobotImportComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance;

    public submitted = true;
    public refreshingNodes = false;
    public refreshOverlayVisible = false;

    public activeModelName?: string;
    public detectedNodes: string[] = [];
    public userNotifications: IUserNotification[] = [];

    public availableLoadsCheckbox!: CheckboxButtonProps<RobotLoadCombinationDataExtension>;

    public isCommunicationStateError = false;
    public areActiveModelCalculationsMissing = false;
    public isActiveObjectMissing = false;
    public isActiveModelMissing = false;
    public isActiveObjectVersionNotSupported = false;


    private isIntegrationsWorkflowRetry = false;

    private readonly allUserNotifications: ReadonlyArray<IUserNotification> = [
        {
            controlEnableFlag: 'isCommunicationStateError',
            buttonTranslationKey: 'Agito.Hilti.Profis3.RobotImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.RobotImport.IsCommunicationError'
        },
        {
            controlEnableFlag: 'areActiveModelCalculationsMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.RobotImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.RobotImport.RefreshOverlayDescription'
        },
        {
            controlEnableFlag: 'isActiveObjectMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.RobotImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.RobotImport.MissingObjectName'
        },
        {
            controlEnableFlag: 'isActiveModelMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.RobotImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.RobotImport.MissingModelName'
        },
        {
            controlEnableFlag: 'isActiveObjectVersionNotSupported',
            buttonTranslationKey: 'Agito.Hilti.Profis3.RobotImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.RobotImport.IsActiveObjectVersionNotSupported'
        }
    ];

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private guid: GuidService,
        private modal: ModalService,
        private integrationsData: IntegrationsDataService,
        private calculationService: CalculationServicePE,
        private sharedEnvironmentData: SharedEnvironmentService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    // Indicates if the modal should be prevented from closing
    public get preventClosing() {
        if (this.refreshOverlayVisible) {
            return this.submitted || this.refreshingNodes;
        }

        return this.submitted;
    }

    public get hasAvailableLoads() {
        return (this.availableLoadsCheckbox.items?.length ?? 0) > 0;
    }

    public get detectedNodesFormatted() {
        return this.detectedNodes.join(', ');
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-refresh-l-dark',
            'sprite-info-tooltip'
        );

        // Don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            return this.preventClosing
                ? false
                : true;
        });

        // controls
        this.availableLoadsCheckbox = {
            items: [],
            selectedValues: new Set()
        };

        this.integrationInit();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    /**
     * Refreshes the instance connection
     * @param isRecursiveRefresh If false, allow one recursive refresh, otherwise no additional refreshes will happen after running this function
     */
    public refreshInstance(isRecursiveRefresh?: boolean) {
        this.isIntegrationsWorkflowRetry = (isRecursiveRefresh == null) ?
            false :
            isRecursiveRefresh;

        this.isActiveObjectMissing = false;
        this.isActiveModelMissing = false;
        this.areActiveModelCalculationsMissing = false;
        this.isCommunicationStateError = false;

        this.activeModelName = undefined;
        this.detectedNodes = [];

        this.availableLoadsCheckbox.items = [];
        this.availableLoadsCheckbox.selectedValues = new Set();

        this.submitted = true;
        this.updateUserNotifications();

        this.checkLiveInstance();
    }

    public formatAvailableLoadName(
        point: RobotLoadCombinationDataExtension,
        ind?: number): string {
        const loadComposition = this.generateLoadCombinationName(point.LoadCases);

        const indexLabel = (ind == null) ?
            '' :
            `${ind + 1};`;

        const loadCombinationType = this.determineRobotLoadCombinationType(point);

        return `${indexLabel}${point.NodeNumber};${loadCombinationType};${point.LoadCombinationName}; ${loadComposition}`;
    }

    ////
    // Other
    ////
    // Closes the Robot import modal
    public close() {
        this.modalInstance.close();
    }

    public dismiss(reason?: string) {
        this.modalInstance.dismiss(reason);
    }

    // Opens the import info pop-up
    public openCombinationImportPopup() {
        this.modal.openConfirmChange({
            id: 'Robot-import-combinationImport-popup',
            title: this.localization.getString('Agito.Hilti.Profis3.RobotImport.CombinationImportPopup.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.RobotImport.CombinationImportPopup.Text'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.RobotImport.CombinationImportPopup.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    // Selects all load combinations
    public selectAll() {
        if (this.submitted) {
            return;
        }

        const selectionCount = this.availableLoadsCheckbox.selectedValues?.size;

        let newCheckedStatus = false;
        if (selectionCount != this.availableLoadsCheckbox.items?.length) {
            newCheckedStatus = true;
        }

        this.availableLoadsCheckbox.items?.forEach(v => {
            if (newCheckedStatus) {
                this.availableLoadsCheckbox.selectedValues?.add(v.value);
            }
            else {
                if (this.availableLoadsCheckbox.selectedValues?.has(v.value)) {
                    this.availableLoadsCheckbox.selectedValues.delete(v.value);
                }
            }
        });
    }

    public isButtonDisabled(allowEmptyArray = false): boolean {
        if (this.refreshOverlayVisible ||
            this.submitted ||
            this.errorOccurred()) {
            return true;
        }

        if (!allowEmptyArray &&
            !this.validLoadCombinationSelection()) {
            return true;
        }

        return false;
    }

    public errorOccurred(): boolean {
        return this.refreshOverlayVisible ||
            this.isActiveObjectMissing ||
            this.isActiveModelMissing ||
            this.isActiveObjectVersionNotSupported ||
            this.areActiveModelCalculationsMissing;
    }

    // Import the selected loads
    public import() {
        if ((this.availableLoadsCheckbox.selectedValues?.size ?? 0) < 1) {
            this.submitted = false;
            this.close();

            return;
        }

        this.submitted = true;

        this.user.design.usageCounter.RobotImport++;

        this.getLoadCombinations();
    }

    private integrationInit(): void {
        this.submitted = true;

        this.checkLiveInstance();
    }

    private checkLiveInstance(): void {
        this.integrationsData.requestData<RobotResponse>(
            DataIntegrationType.Robot,
            DataIntegrationRequestType.RunningInstance,
            () => {
                this.handleServiceError();
            },
            (response) => {
                this.onRunningInstanceResponseReceived(response);
            })
            .catch((err) => this.handleServiceError(err, true));
    }

    private validateResponse(response: IntegrationDataResponse) {
        if (response.ErrorType != ErrorType.None) {
            if (!this.isIntegrationsWorkflowRetry) {
                this.refreshInstance(true);
                return false;
            }

            if (response.ErrorType == ErrorType.NoInstanceRunning) {
                this.isActiveObjectMissing = true;
            }
            else if (response.ErrorType == ErrorType.UnsupportedVersion) {
                this.isActiveObjectVersionNotSupported = true;
            }
            else if (response.ErrorType == ErrorType.ModelNotOpened) {
                this.isActiveModelMissing = true;
            }
            else if (response.ErrorType == ErrorType.NoSelectedNodes) {
                this.areActiveModelCalculationsMissing = true;
            }
            else if (response.ErrorType == ErrorType.IntegrationInternalError) {
                this.isCommunicationStateError = true;
            }

            this.submitted = false;
            this.updateUserNotifications();

            return false;
        }

        return true;
    }

    private onRunningInstanceResponseReceived(response: RobotResponse): void {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            return;
        }

        this.activeModelName = response.ProjectName;

        this.integrationsData.requestData(
            DataIntegrationType.Robot,
            DataIntegrationRequestType.GetSelectedNodes,
            () => {
                this.handleServiceError();
            },
            (response2) => {
                this.onSelectedNodesResponseReceived(response2 as RobotNodeSelectionResponse);
            },
            null,
            55000);
    }

    private onSelectedNodesResponseReceived(response: RobotNodeSelectionResponse): void {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            return;
        }

        if (response.InitialLoadCombinationData == null ||
            response.InitialLoadCombinationData.length == 0) {
            this.submitted = false;
            this.areActiveModelCalculationsMissing = true;
            this.updateUserNotifications();
            return;
        }

        // set all available points for display.
        const initialLoadCombinationData: RobotLoadCombinationDataExtension[] = [];
        response.InitialLoadCombinationData.forEach(pt => {
            this.addInitialNode(initialLoadCombinationData, pt);
        });

        this.updateAvailableLoadsCheckboxItems(initialLoadCombinationData);

        this.submitted = false;
    }

    private onReadLoadCombinationResponseReceived(response: RobotLoadCombinationResponse): void {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            return;
        }

        this.addImportedLoadCombinations(response.LoadCombinations);
    }

    private getLoadCombinations(): void {
        const requestData: RobotBaseLoadCombinationInitialData[] = [];

        this.availableLoadsCheckbox.selectedValues?.forEach(lc => {
            const baseLoadCombination: RobotBaseLoadCombinationInitialData = {
                ComponentNumber: lc.ComponentNumber,
                LoadCombinationName: lc.FormattedName ?? '',
                LoadCombinationNumber: lc.LoadCombinationNumber,
                NodeNumber: lc.NodeNumber,
                Type: lc.Type
            };

            requestData.push(baseLoadCombination);
        });

        const loadCombinationsRequest = {
            RequestType: DataIntegrationRequestType.GetLoadCombinations,
            DataIntegrationType: DataIntegrationType.Robot,
            InitialLoadCombinationDataSelection: requestData
        } as RobotLoadCombinationRequest;

        this.integrationsData.requestData(
            DataIntegrationType.Robot,
            DataIntegrationRequestType.GetLoadCombinations,
            () => {
                this.handleServiceError();
            },
            (response) => {
                this.onReadLoadCombinationResponseReceived(response as RobotLoadCombinationResponse);
            },
            loadCombinationsRequest)
            .catch((err) => this.handleServiceError(err, true));
    }

    ////
    // Shared
    ////
    private addInitialNode(availableLoads: RobotLoadCombinationDataExtension[], node: RobotLoadCombinationInitialData): void {
        const extendedLoadCombination: RobotLoadCombinationDataExtension = {
            LoadCombinationName: node.LoadCombinationName,
            FormattedName: undefined,
            LoadCases: node.LoadCases,
            Type: node.Type,
            NodeNumber: node.NodeNumber,
            ComponentNumber: node.ComponentNumber,
            LoadCombinationNumber: node.LoadCombinationNumber
        };

        extendedLoadCombination.FormattedName = this.formatAvailableLoadName(extendedLoadCombination);

        availableLoads.push(extendedLoadCombination);

        const nodeNumber = node.NodeNumber.toString();
        if (this.detectedNodes.indexOf(nodeNumber) == -1) {
            this.detectedNodes.push(nodeNumber);
        }
    }

    private addImportedLoadCombinations(loadCombinations: RobotLoadCombinationData[]): void {
        const newLoads: LoadCombination[] = [];

        loadCombinations.forEach(lc => {
            newLoads.push({
                Name: lc.Name,
                Description: null as unknown as string,
                Id: this.guid.new(),
                ForceX: lc.ForceX,
                ForceY: lc.ForceY,
                ForceZ: lc.ForceZ,
                MomentX: lc.MomentX,
                MomentY: lc.MomentY,
                MomentZ: lc.MomentZ,
                DynamicForceX: null as unknown as number,
                DynamicForceY: null as unknown as number,
                DynamicForceZ: null as unknown as number,
                DynamicMomentX: null as unknown as number,
                DynamicMomentY: null as unknown as number,
                DynamicMomentZ: null as unknown as number,
                ActiveLoadType: LoadType.Static,
                Tension: null as unknown as UtilizationValueEntity,
                Shear: null as unknown as UtilizationValueEntity,
                Combination: null as unknown as UtilizationValueEntity,
                ResultMessages: [],
                IsWizardGenerated: false,
                HasSustainedLoads: false,
                LoadCharacteristic: null as unknown as number
            });

            return true;
        });

        this.calculationService.calculateAsync(this.user.design,
            (design) => {
                // Filter out empty load combinations and consider max number of combinations
                design.loadCombinations = concatFilterLoadCombinations(design.loadCombinations, newLoads, environment.maxLoadCombinations, false);
            },
            {
                suppressLoadingFlag: true,
                importingLoadCases: true
            }
        )
            .then(() => {
                this.submitted = false;
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    // Displays an error message modal
    private handleServiceError(response?: HttpResponseBase, logError = false) {
        if (logError && response instanceof Error) {
            console.error(response);
        }

        this.submitted = false;

        this.modal.openAlertError(
            this.localization.getString('Agito.Hilti.Profis3.RobotImport.ServiceError.Title'),
            this.createDownloadUrlMessage('Agito.Hilti.Profis3.RobotImport.ServiceError.Message'),
            {
                response,
                endPointUrl: this.integrationsData.requestUrl,
                requestPayload: this.integrationsData.requestDataObject
            }
        );
    }

    private determineRobotLoadCombinationType(point: RobotLoadCombinationDataExtension) {
        let result = '';

        if (point.Type == RobotLoadCombinationType.CaseCombination) {
            result = 'CaseCombo';
        }
        else if (point.Type == RobotLoadCombinationType.CodeCombination) {
            result = 'CodeCombo';
        }
        else if (point.Type == RobotLoadCombinationType.Mobile) {
            result = 'Mobile';
        }
        else if (point.Type == RobotLoadCombinationType.Simple) {
            result = 'Simple';
        }

        return result;
    }

    private generateLoadCombinationName(loadComposition: RobotLoadCase[]): string {
        let res = '';

        if (loadComposition.length == 1) {
            return loadComposition[0].Name;
        }

        for (let i = 0; i < loadComposition.length; i++) {
            res += `${loadComposition[i].Factor.toFixed(3)} ${loadComposition[i].Name}`;

            if (i != loadComposition.length - 1) {
                res += ' + ';
            }
        }

        return res;
    }

    ////
    // /Shared
    ////
    private validLoadCombinationSelection(): boolean {
        if (
            (this.availableLoadsCheckbox.items?.length ?? 0) < 1
            ||
            (this.availableLoadsCheckbox.selectedValues?.size ?? 0) < 1
        ) {
            return false;
        }

        return true;
    }

    private createDownloadUrlMessage(messageKey: string) {
        return `<html lang="en">${formatKeyValue(escape(this.localization.getString(messageKey)), {
            downloadUrl: `<a href="${escape(this.sharedEnvironmentData.data?.thirdPartyInterfaceDownloadUrl)}" class="download-link" target="_blank">here.</a>`
        })}</html>`;
    }

    private updateAvailableLoadsCheckboxItems(loads: RobotLoadCombinationDataExtension[]) {
        const checkboxItems: CheckboxButtonItem<RobotLoadCombinationDataExtension>[] = [];
        const checkboxSelectedValues = new Set<RobotLoadCombinationDataExtension>();

        loads.forEach((load, index) => {
            const item: CheckboxButtonItem<RobotLoadCombinationDataExtension> = {
                text: this.formatAvailableLoadName(load, index),
                value: load,
                disabled: !load.LoadCombinationName
            };

            checkboxItems.push(item);
        });

        this.availableLoadsCheckbox.items = checkboxItems;
        this.availableLoadsCheckbox.selectedValues = checkboxSelectedValues;
    }

    private updateUserNotifications() {
        const retVal: IUserNotification[] = [];

        for (const userNotification of this.allUserNotifications) {
            if (getProperty(this, userNotification.controlEnableFlag)) {
                retVal.push(userNotification);
            }
        }

        this.userNotifications = retVal;
    }
}
