import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignMethodHNA, FatigueLoadType, LoadType, StaticLoadType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class MomentUpdate extends BaseUpdate {

    @propertyValueChanged(UIProperty.Loads_LoadType)
    private Loads_LoadType(_loadType: LoadType, model: IModelPe, design: DesignPe) {
        if (!model.moment) {
            throw new Error('model moment is undefined');
        }

        model.moment.hasVariableLoads = design.isVariableLoadsPresent;
        model.moment.alternatingLoads = design.alternatingLoads;
        model.moment.isDynamicFatigue = design.isDynamicFatigue;
        model.moment.isFatigueExpertMode = design.isFatigueExpertMode;
    }

    @propertyValueChanged(UIProperty.Loads_StaticLoadType)
    private Loads_StaticLoadType(_staticLoadType: StaticLoadType, model: IModelPe, design: DesignPe) {
        if (!model.moment) {
            throw new Error('model moment is undefined');
        }

        model.moment.hasVariableLoads = design.isVariableLoadsPresent;
    }

    @propertyValueChanged(UIProperty.Loads_FatigueLoadType)
    private Loads_FatigueLoadType(_fatigueLoadType: FatigueLoadType, model: IModelPe, design: DesignPe) {
        if (!model.moment) {
            throw new Error('model moment is undefined');
        }

        model.moment.alternatingLoads = design.alternatingLoads;
    }

    @propertyValueChanged(UIProperty.Loads_DesignMethodHNA)
    private Loads_DesignMethodHNA(_value: DesignMethodHNA, model: IModelPe, design: DesignPe) {
        if (!model.moment) {
            throw new Error('model moment is undefined');
        }

        model.moment.hasSustainedLoads = design.isSustainedLoadsPresent;
    }

    @propertyValueChanged(UIProperty.AnchorLayout_AnchorFamily)
    private AnchorLayout_AnchorFamily(_value: number, model: IModelPe, design: DesignPe) {
        if (model.moment) {
            model.moment.hasSustainedLoads = design.isSustainedLoadsPresent;
        }
    }

    @propertyValueChanged(UIProperty.Loads_MomentX)
    private Loads_MomentX(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.design.X = value;
    }

    @propertyValueChanged(UIProperty.Loads_MomentY)
    private Loads_MomentY(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.design.Y = value;
    }

    @propertyValueChanged(UIProperty.Loads_MomentZ)
    private Loads_MomentZ(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.design.Z = value;
    }

    @propertyValueChanged(UIProperty.Loads_MomentXVariable)
    private Loads_MomentXVariable(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.variable.X = value;
    }

    @propertyValueChanged(UIProperty.Loads_MomentYVariable)
    private Loads_MomentYVariable(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.variable.Y = value;
    }

    @propertyValueChanged(UIProperty.Loads_MomentZVariable)
    private Loads_MomentZVariable(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.variable.Z = value;
    }

    @propertyValueChanged(UIProperty.Loads_MomentXSustained)
    private Loads_MomentXSustained(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.sustained.X = value;
    }

    @propertyValueChanged(UIProperty.Loads_MomentYSustained)
    private Loads_MomentYSustained(value: number, model: IModelPe) {
        if (!model.moment?.loads) {
            throw new Error('model moment loads are undefined');
        }

        model.moment.loads.sustained.Y = value;
    }

    @propertyValueChanged(UIProperty.Loads_IsFatigueExpertMode)
    private Loads_IsFatigueExpertMode(value: boolean, model: IModelPe, design: DesignPe) {
        if (!model.moment) {
            throw new Error('model moment is undefined');
        }

        model.moment.isDynamicFatigue = design.isDynamicFatigue;
        model.moment.isFatigueExpertMode = value;
    }

    @propertyValueChanged(UIProperty.Loads_LoadsPositionZ)
    private Loads_LoadsPositionZ(value: number, model: IModelPe) {
        if (!model.moment) {
            throw new Error('model moment is undefined');
        }

        model.moment.loadsPositionZ = value;
    }
}
