import {
    ChangeDetectorRef, Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignPe, ReportData } from '../../../shared/entities/design-pe';
import {
    AnchorForces
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import {
    DesignType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CollapsingControls } from '../../entities/collapsing-controls';
import {
    areLoadCombinationsAvailable, getLoadCombinationNumberText
} from '../../helpers/load-combination-helper';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

interface IAnchorLoad {
    id: number;
    N: string;
    Qx: string;
    Qy: string;
}

@Component({
    templateUrl: './anchor-loads.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss', './anchor-loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AnchorLoadsComponent implements OnInit {
    @Input()
    public id!: string;

    @Input()
    public design?: DesignPe;

    @Input()
    public parentElement!: HTMLElement;

    public collapsed = false;
    public tableElement!: string;
    public anchorLoadsElement!: string;

    private fullHeight = 0;
    private anchors: IAnchorLoad[] = [];
    private anchorForces: AnchorForces[] = [];
    private resizerTriggerPosition!: { y: number };
    private resizerOverlayElement!: HTMLElement;
    private continueResizingFn!: (event: MouseEvent) => void;
    private endResizingFn!: (event: MouseEvent) => void;
    private resizeAnchorLoadsElement!: string;

    constructor(
        public localizationService: LocalizationService,
        private user: UserService,
        private userSettings: UserSettingsService,
        private unit: UnitService,
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get showAnchorLoads() {
        return this.user.design != null
            && this.design?.designData?.reportData?.AnchorForces
            && this.design.designData.reportData.AnchorForces.length > 0;
    }

    public get areLoadCombinationsAvailable() {
        return areLoadCombinationsAvailable(
            this.design?.designData?.reportData as ReportData,
            this.design?.designType.id == DesignType.Handrail  // Always show load combination number for Handrail
        );
    }

    public get loadCombination() {
        if (this.design == null) {
            return null;
        }

        return getLoadCombinationNumberText(this.design, this.localizationService, this.design.designData?.reportData?.DecisiveLoadCombinationIndex ?? 0, false);
    }

    public get anchorsArray(): IAnchorLoad[] {
        if (!this.equalArrays(this.anchorForces, this.design?.designData?.reportData?.AnchorForces)) {
            this.anchors = [];
            this.fillAnchorArray();

            // Anchor array has changed; since we have other properties checking it we need to perform change detection here.
            this.changeDetector.detectChanges();
        }

        return this.anchors;
    }

    public get defaultUnit() {
        const unitGroup = UnitGroup.Force;
        return this.unit.getDefaultUnit(unitGroup, this.design);
    }

    public get showResizer() {
        const numOfAnchors = this.anchors.length;
        if (numOfAnchors <= 1) {
            return false;
        }
        else if (this.collapsed) {
            return false;
        }
        else {
            return true;
        }
    }

    private get maxHeight() {
        const numOfAnchors = this.anchors.length;
        return numOfAnchors * 30 + 30 + 4;
    }

    private get minHeight() {
        return 30 + 31;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-anchor-loads'
        );

        this.resizeAnchorLoadsElement = '#resize-anchor-loads';
        this.anchorLoadsElement = '.anchor-loads';
        this.tableElement = '.anchor-loads-table';
        this.fillAnchorArray();
        this.continueResizingFn = this.continueResizing.bind(this);
        this.endResizingFn = this.endResizing.bind(this);

        this.resizerOverlayElement = document.createElement('div');
        this.resizerOverlayElement.setAttribute('id', 'resize-drag-handle-horizontal');

        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.AnchorLoads);
        setTimeout(() => this.setMinimumHeight());
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public getLocalizedTextAndForceUnit(key: string) {
        const unitGroup = UnitGroup.Force;
        return `${this.localizationService.getString(key)} [${this.unit.formatUnit(this.unit.getDefaultUnit(unitGroup))}]`;
    }

    public trackAnchorLoadById: TrackByFunction<IAnchorLoad> = (_: number, anchorLoad: IAnchorLoad) => anchorLoad.id;

    public setMinimumHeight() {
        let minimumHeight = 0;
        if (!this.collapsed) {
            minimumHeight = this.minHeight;
        }

        // if anchorLoadsElement is even shown
        if (this.showAnchorLoads) {
            const anchorLoadsElement = this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>(this.anchorLoadsElement);
            if (anchorLoadsElement != null) {
                anchorLoadsElement.style.minHeight = minimumHeight + 'px';
            }
        }
    }

    public beginResizing(event: MouseEvent) {
        event.preventDefault();
        const mouse = this.calculateMouseInsideElement(event, this.parentElement);
        const position = this.parentElement.getBoundingClientRect();
        this.resizerTriggerPosition = mouse;

        this.resizerOverlayElement.style.top = '-1px';
        this.resizerOverlayElement.style.left = 0 + 'px';
        this.resizerOverlayElement.style.width = position?.width + 'px';

        this.parentElement.append(this.resizerOverlayElement);

        document.addEventListener('mousemove', this.continueResizingFn, false);
        document.addEventListener('mouseup', this.endResizingFn, false);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.AnchorLoads, collapsed);

        setTimeout(() => {
            this.setHeight();
            this.setMinimumHeight();
        });
    }

    private equalArrays(array1: any[], array2?: any[]) {
        return array1.length == array2?.length && array1.every((val, index) => val == array2[index]);
    }

    private fillAnchorArray(): IAnchorLoad[] {
        if (this.design?.designData?.reportData?.AnchorForces) {
            this.anchorForces = this.design?.designData?.reportData?.AnchorForces;
            const unitGroup = UnitGroup.Force;
            const unit = this.unit.getDefaultUnit(unitGroup, this.design);

            for (let i = 0; i < this.anchorForces.length; i++) {
                const n = this.unit.convertInternalValueToDefaultValue(this.anchorForces[i].N, unitGroup);
                const qx = this.unit.convertInternalValueToDefaultValue(this.anchorForces[i].Qx, unitGroup);
                const qy = this.unit.convertInternalValueToDefaultValue(this.anchorForces[i].Qy, unitGroup);
                this.anchors.push({
                    id: i + 1,
                    N: this.unit.formatUnitValueArgs(n, unit, undefined, undefined, undefined, null, false),
                    Qx: this.unit.formatUnitValueArgs(qx, unit, undefined, undefined, undefined, null, false),
                    Qy: this.unit.formatUnitValueArgs(qy, unit, undefined, undefined, undefined, null, false)
                });
            }
        }

        this.setHeight();
        return this.anchors;
    }

    private setHeight() {
        const anchorLoadsElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.anchorLoadsElement) as HTMLElement;

        if (this.collapsed) {
            anchorLoadsElt.style.height = '';
            anchorLoadsElt.style.overflowY = '';
        }
        else {
            const numOfAnchors = this.anchors.length;
            const tableElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.tableElement) as HTMLElement;

            if (numOfAnchors == 1) {
                this.fullHeight = numOfAnchors * 30 + 31 + 33;
                this.setHeightLessThenOrFourAnchors(tableElt, anchorLoadsElt);
            }

            else if (numOfAnchors <= 4) {
                this.fullHeight = numOfAnchors * 30 + 31 + 33 + 1;
                this.setHeightLessThenOrFourAnchors(tableElt, anchorLoadsElt);
            }

            else {
                this.fullHeight = 4 * 30 + 31 + 33 + 1;
                this.setHeightMoreThenFourAnchors(tableElt, anchorLoadsElt);
            }

            if (anchorLoadsElt != null) {
                anchorLoadsElt.style.height = this.fullHeight + 'px';
            }
        }
    }

    private setHeightLessThenOrFourAnchors(tableElt: HTMLElement, anchorLoadsElt: HTMLElement) {
        if (tableElt != null) {
            tableElt.style.height = this.fullHeight - 33 + 'px';
        }

        if (anchorLoadsElt != null) {
            anchorLoadsElt.style.overflowY = '';
        }
    }

    private setHeightMoreThenFourAnchors(tableElt: HTMLElement, anchorLoadsElt: HTMLElement) {
        const tableHeight = 4 * 30 + 30 + 1;  // default height with 4 anchors
        if (tableElt != null) {
            tableElt.style.height = tableHeight + 'px';
            tableElt.style.overflowY = 'scroll';
        }

        if (anchorLoadsElt != null) {
            anchorLoadsElt.style.width = 'auto';
        }
    }

    private continueResizing(event: MouseEvent) {
        event.preventDefault();

        const tableElt = this.elementRef.nativeElement.shadowRoot?.querySelector(this.tableElement) as HTMLElement;
        if (tableElt && tableElt.offsetParent) {
            this.resizerOverlayElement.style.top = event.clientY + (tableElt.offsetParent.scrollTop - 50) + 'px'; // -50 used to adjust the overlay element
        }
    }

    private endResizing(event: MouseEvent) {
        event.preventDefault();

        const mouse = this.calculateMouseInsideElement(event, this.parentElement);

        const diffMouse = {
            y: mouse.y - this.resizerTriggerPosition.y - 31 // -31 used to adjust the overlay element
        };

        this.resizeDiv(diffMouse.y);

        this.resizerOverlayElement.remove();

        document.removeEventListener('mousemove', this.continueResizingFn, false);
        document.removeEventListener('mouseup', this.endResizingFn, false);
    }

    private resizeDiv(sizeDifference: number) {
        if (sizeDifference == 0) {
            return;
        }

        const resizeElement = this.elementRef.nativeElement.shadowRoot?.querySelector(this.resizeAnchorLoadsElement) as HTMLElement;
        const maxHeight = this.maxHeight;
        const minHeight = this.minHeight;
        const elementHeight = resizeElement.offsetHeight;
        const height = elementHeight + sizeDifference;

        const tableElement = this.elementRef.nativeElement.shadowRoot?.querySelector(this.tableElement) as HTMLElement;
        if (height > maxHeight) {
            tableElement.style.height = maxHeight + 'px';
            resizeElement.style.overflowY = '';
            tableElement.style.overflowY = '';
            resizeElement.style.height = maxHeight + 31 + 1 + 'px';
        }
        else if (height < minHeight) {
            tableElement.style.height = minHeight + 'px';
            resizeElement.style.height = minHeight + 31 + 1 + 'px';
            tableElement.style.overflowY = 'auto';
        }
        else {
            tableElement.style.height = height + 'px';
            resizeElement.style.height = height + 31 + 1 + 'px';
            tableElement.style.overflowY = 'auto';
        }
    }

    private calculateMouseInsideElement(event: MouseEvent, element: HTMLElement) {
        return {
            y: event.pageY - (element.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop))
        };
    }
}
