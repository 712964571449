import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DesignType } from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { trySendingUsingFetch } from '@profis-engineering/pe-ui-common/helpers/browser';
import { DateWithTimezone, getJSONDateWithTimezoneOffset } from '@profis-engineering/pe-ui-common/helpers/date-time-helper';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';
import { environment } from '../../environments/environmentPe';
import { TrackingUsageCounterPe } from '../../shared/entities/tracking-usage-counter';
import {
    BaseplateDesignDataEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import { DesignLocationType, ProjectOpenType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import { ProjectDesignBaseEntity } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { DesignType as DesignTypeEnum } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    OnProjectCloseRequest, SaveTrackingActDataRequest, TrackOnProjectCloseRequest,
    TrackOnProjectDesignOpenRequest, TrackOnProjectTemplateOpenRequest, UsageCounter, UserDetails
} from '../../shared/generated-modules/Hilti.PE.Core.Tracking.Entities';
import { ApiService } from './api.service';
import { CommonTrackingService } from './common-tracking.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import {
    TrackDesignChangeRequest,
    TrackOnOpenRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Entities';

/**
 * proxy for tracking service that tracks design details
 * tracking service is responsible for:
 * - tracking details when new design is created or existing design is opened
 * - tracking details when design is closed
 */
@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private apiService: ApiService,
        private commonTrackingService: CommonTrackingService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private localizationService: LocalizationService,
        private loggerService: LoggerService,
        private featureVisbility: FeaturesVisibilityService
    ) { }

    private get newTrackingEnabled() {
        return this.featureVisbility.isFeatureEnabled('PE_Tracking_ArchChange');
    }

    public async saveTrackingActData(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string | undefined,
        designLocation: DesignLocationType,
        uiVersion: string,
        useDevFeatures: boolean,
        isNewHomePage: boolean,
        designType?: DesignType
    ): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        usageCounter.DateClosed = new Date();

        const url = `${environment.trackingWebServiceUrl}SaveTrackingActData`;
        const data: SaveTrackingActDataRequest = {
            DesignId: designId,
            ProjectDesign: projectDesign,
            Language: this.localizationService.selectedLanguage,
            UsageCounter: usageCounter,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            ProjectOpenType: projectOpenType,
            BaseplateDesignDataExists: this.userService.design != null
                ? this.userService.design.baseplateDesignData != null
                : false,
            BaseplateDesignTiming: this.userService.design != null ? this.userService.design.designData.reportData.BaseplateDesignTiming : undefined,
            UseDevFeatures: useDevFeatures,
            UIVersion: uiVersion,
            CreatedFromTemplate: createdFromTemplate,
            TemplateId: templateId ?? '',
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value ?? undefined,
            DesignLocation: isNewHomePage ? designLocation : undefined // Only for new home page
        };

        if (this.newTrackingEnabled) {
           return await this.sendTrackingActData(data, designId, designType, templateId);
        }

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }


    public async trackOnTemplateOpen(templateId: string, designType: DesignTypeEnum): Promise<void> {
        if (this.newTrackingEnabled) {
            const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectTemplateOpen`;

            return await this.sendTrackingData(url, designType, templateId, true);
        }

        const date = getJSONDateWithTimezoneOffset();
        const url = `${environment.trackingWebServiceUrl}TrackOnProjectTemplateOpen`;
        const data = this.getTrackOnProjectTemplateOpenRequest(templateId, date, designType);

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnTemplateClose(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string | undefined,
        designLocation: DesignLocationType,
        uiVersion: string,
        useDevFeatures?: boolean,
        isNewHomePage?: boolean
    ): Promise<void> {
        const url = `${environment.trackingWebServiceUrl}TrackOnProjectTemplateClose`;
        const data = this.getDesignTrackingData(projectDesign, usageCounter, projectOpenType, designId, createdFromTemplate, templateId, designLocation, uiVersion, useDevFeatures, isNewHomePage);

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }


    public async trackOnDesignOpen(designType: DesignType, designId: string, uiVersion: string): Promise<void> {
        if (this.newTrackingEnabled) {
            const url = `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectDesignOpen`;

            return await this.sendTrackingData(url, designType.id, designId , false);
        }

        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        const url = `${environment.trackingWebServiceUrl}TrackOnProjectDesignOpen`;

        const data: TrackOnProjectDesignOpenRequest = {
            DesignId: designId,
            DesignType: designType.id,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            UIVersion: uiVersion
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }

    public async trackOnDesignClose(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string | undefined,
        uiVersion: string,
        useDevFeatures?: boolean,
        isNewHomePage?: boolean,
        baseplateDesignData?: BaseplateDesignDataEntity,
        baseplateDesignTiming?: string,
        designLocation?: DesignLocationType
    ): Promise<void> {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        usageCounter.DateClosed = new Date();
        const url = `${environment.trackingWebServiceUrl}TrackOnProjectDesignClose`;
        const data: TrackOnProjectCloseRequest = {
            DesignId: designId,
            ProjectDesign: projectDesign,
            Language: this.localizationService.selectedLanguage,
            UsageCounter: usageCounter,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            ProjectOpenType: projectOpenType,
            BaseplateDesignData: baseplateDesignData,
            BaseplateDesignTiming: baseplateDesignTiming,
            UseDevFeatures: useDevFeatures,
            UIVersion: uiVersion,
            CreatedFromTemplate: createdFromTemplate,
            TemplateId: templateId as string,
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value ?? undefined,
            DesignLocation: isNewHomePage ? designLocation : undefined // Only for new home page
        };

        const request = new HttpRequest('POST', url, data, {
            responseType: 'json'
        });

        await this.apiService.request(request, { supressErrorMessage: true });
    }


    public async handleDesignBrowserUnload(
        sessionKey: string,
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: TrackingUsageCounterPe,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string | undefined,
        designLocation: DesignLocationType,
        uiVersion: string,
        useDevFeatures: boolean,
        isNewHomePage: boolean
    ): Promise<void> {
        const trackingData = this.getDesignTrackingData(
            projectDesign,
            usageCounter.toUsageCounter(),
            projectOpenType,
            designId,
            createdFromTemplate,
            templateId,
            designLocation,
            uiVersion,
            useDevFeatures,
            isNewHomePage
        );

        const data: OnProjectCloseRequest = {
            DesignId: designId,
            SessionKey: sessionKey,
            TrackingData: trackingData
        };

        const url = `${environment.baseplateApplicationWebServiceUiApiUrl}OnProjectCloseSync`;
        const jsonData = data != null
            ? JSON.stringify(data)
            : '';
        const httpHeaders = this.userService.getHeaders(url, true);
        const trackingPromise = trySendingUsingFetch(url, httpHeaders, jsonData, this.loggerService)
            .catch((error) => {
                console.error(error);
                this.loggerService.log(error.message, LogType.error, error);
            })
            .then(() => {
                if (data != null) {
                    usageCounter.resetCounter();
                }
            });
        return trackingPromise;
    }


    private getDesignTrackingData(
        projectDesign: ProjectDesignBaseEntity,
        usageCounter: UsageCounter,
        projectOpenType: ProjectOpenType,
        designId: string,
        createdFromTemplate: boolean,
        templateId: string | undefined,
        designLocation: DesignLocationType,
        uiVersion: string,
        useDevFeatures?: boolean,
        isNewHomePage?: boolean): TrackOnProjectCloseRequest {
        // set date
        const date = getJSONDateWithTimezoneOffset();
        // set user details
        const userDetails = this.getUserDetails(date.timezoneOffset);

        return {
            DesignId: designId,
            ProjectDesign: projectDesign,
            Language: this.localizationService.selectedLanguage,
            UsageCounter: usageCounter,
            Timestamp: date.timestamp as any,
            UserDetails: userDetails,
            ProjectOpenType: projectOpenType,
            BaseplateDesignData: this.userService.design?.baseplateDesignData,
            BaseplateDesignTiming: this.userService.design?.designData.reportData.BaseplateDesignTiming,
            UseDevFeatures: useDevFeatures,
            UIVersion: uiVersion,
            CreatedFromTemplate: createdFromTemplate,
            TemplateId: templateId as string,
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value ?? undefined,
            DesignLocation: isNewHomePage ? designLocation : undefined // Only for new home page
        };
    }

    private getTrackOnProjectTemplateOpenRequest(templateId: string, date: DateWithTimezone, designType: DesignTypeEnum): TrackOnProjectTemplateOpenRequest {
        return {
            DesignType: designType,
            TemplateId: templateId,
            Timestamp: date.timestamp as any,
            UIVersion: this.sharedEnvironmentService.data?.applicationVersion as string,
            UserDetails: this.getUserDetails(date.timezoneOffset),
        };
    }

    private getUserDetails(timezoneOffset: number): UserDetails {
        const srcData = this.commonTrackingService.getTrackingUserData(timezoneOffset);

        return {
            BrowserType: srcData.BrowserType,
            OperatingSystem: srcData.OperatingSystem,
            TimezoneOffset: srcData.TimezoneOffset ?? 0,
            UserIP: srcData.UserIpAddress,
            Username: srcData.UserName,
            UUID: srcData.UserId,
            DiagnosticsAgreement: srcData.DiagnosticsAgreement,
            CustomerId: srcData.CustomerId,
            CustomerOriginId: srcData.CustomerOriginId,
            SalesOrg: srcData.SalesOrg,
            CustomerType: srcData.CustomerType,
            License: srcData.License,
            IsTrial: srcData.IsTrial,
            CountryOfResidence: srcData.CountryOfResidence,
            CustomerCountry: srcData.CustomerCountry,
            BuyingCustomerOriginId: srcData.BuyingCustomerOriginId,
            BuyingSalesOrg: srcData.BuyingSalesOrg,
            BuyingCustomerType: srcData.BuyingCustomerType
        };
    }

    private getDesignTypeName(designType: DesignTypeEnum) {
        switch (designType) {
            case DesignTypeEnum.Concrete:
                return 'Concrete';
            case DesignTypeEnum.Handrail:
                return 'Handrail';
            case DesignTypeEnum.Masonry:
                return 'Masonry';
            case DesignTypeEnum.MetalDeck:
                return 'COMD';
            default:
                return 'Undefined';
        }
    }

    private async sendTrackingData(url: string, designTypeId: number, id: string, isTemplate: boolean) {
        try {
            const date = getJSONDateWithTimezoneOffset();
            const userDetails = this.commonTrackingService.getUserDetails(date.timezoneOffset);

            const data: TrackOnOpenRequest = {
                DesignType: designTypeId,
                DesignId: !isTemplate ? id : '',
                TrackingData: {
                    userDetails,
                    uiVersion: this.sharedEnvironmentService.data?.applicationVersion,
                    designTypeName: this.getDesignTypeName(designTypeId)
                },
                TemplateId: isTemplate ? id : '',
                UseDirectTracking: true
            };

            const request = new HttpRequest('POST', url, data, {
                responseType: 'json'
            });

            await this.apiService.request(request, { supressErrorMessage: true });
        }
        catch (error) {
            console.log(error);
        }
    }

    private async sendTrackingActData(trackingData: any, designId: string, designType?: DesignType, templateId?: string) {
        try {
            const url = templateId != null ? `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectTemplateChange` : `${environment.peTrackingServiceUrl}Tracking/TrackOnProjectDesignChange`;

            const data: TrackDesignChangeRequest = {
                DesignType: designType?.id as number,
                TrackingData: trackingData,
                DesignId: designId,
                TemplateId: templateId ?? '',
                UseDirectTracking: true
            };

            const request = new HttpRequest<TrackDesignChangeRequest>('POST', url, data, {
                responseType: 'json'
            });

            await this.apiService.request(request, { supressErrorMessage: true });
        }
        catch (error) {
            console.log(error);
        }
    }
}
