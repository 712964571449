import { Injectable } from '@angular/core';
import {
    ReportTemplateServiceInjected
} from '@profis-engineering/pe-ui-common/services/report-template.common';

@Injectable({
    providedIn: 'root'
})
export class ReportTemplateService extends ReportTemplateServiceInjected {
}
