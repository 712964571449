import { HoleConditionEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class HoleCondition extends CodeList {

    public static fromService(codeList: HoleConditionEntity) {
        return new HoleCondition(CodeList.baseFromService('HoleConditionEntity', codeList));
    }

    public override cloneDeep(): HoleCondition {
        return super.cloneDeep() as HoleCondition;
    }
}
