import {
    Component, ElementRef, Input, OnChanges, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    IModalGridComponentInput, IModalGridItem
} from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { randomString } from '@profis-engineering/pe-ui-common/helpers/random';

import { Region } from '../../../shared/entities/code-lists/region';
import { createKBLink } from '../../../shared/entities/KB-link';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites, Sprite } from '../../sprites';

const forceClose = 'forced';

@Component({
    templateUrl: './modal-grid.component.html',
    styleUrls: ['./modal-grid.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ModalGridComponent<TItem extends IModalGridItem<any>> implements OnInit, OnChanges {
    @Input()
    public modalInstance!: ModalInstance<IModalGridComponentInput<TItem>>;

    public id!: string;
    private initializedSpriteImages: string[] = [];

    constructor(
        public localization: LocalizationService,
        private codeList: CodeListService,
        private userSettingsService: UserSettingsService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get title() {
        return this.modalInstance.input?.popupTitle;
    }

    public get warning() {
        return this.modalInstance.input?.warning;
    }

    public get showWarning() {
        return this.modalInstance.input?.warning != null;
    }

    public get items() {
        return this.modalInstance.input?.items;
    }

    public get selectedItem() {
        return this.modalInstance.input?.selectedItem;
    }
    public set selectedItem(item: TItem | undefined) {
        if (this.modalInstance.input != null) {
            this.modalInstance.input.selectedItem = item;
        }
    }

    public get preventClose() {
        return this.modalInstance.input?.preventClose;
    }

    public get numberOfColumns() {
        return this.modalInstance.input?.numberOfColumns || 4;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info-tooltip'
        );
        this.id = this.modalInstance.input?.id ?? randomString(8);

        this.modalInstance.setOnClosing((result) => {
            if (this.preventClose && result !== forceClose) {
                return false;
            }

            return true;
        });
    }

    ngOnChanges(): void {
        if (this.items) {
            for (const i of this.items) {
                this.addImage(i.image ?? '');
            }
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public getItemImage(item: TItem) {
        return item?.image ?? '';
    }

    public getItemkbNumberRegion(item: TItem) {
        return item?.kbNumberRegion ?? { key: '' };
    }

    public getItemkbTooltip(item: TItem) {
        return item?.kbTooltip ?? '';
    }

    public getItemPropertyValue(item: TItem, property: string) {
        type ObjectKey = keyof typeof item;

        return item[property as ObjectKey];
    }

    public identifyItem: TrackByFunction<TItem> = function (_: number, item: TItem) {
        return item.value;
    };

    public close(result?: string) {
        this.modalInstance.close(result);
    }

    public async select(item: TItem) {
        this.selectedItem = item;

        if (this.modalInstance.input?.onSelect != null) {
            await this.modalInstance.input?.onSelect(item);
        }

        this.close(forceClose);
    }

    public openKBLink(event: MouseEvent, kbNumberRegion: { [key: string]: string }) {
        event.stopPropagation();

        const selectedRegion = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value ?? 0);
        if (selectedRegion === undefined || selectedRegion.profis3KBUrlAci == null || kbNumberRegion == null || kbNumberRegion[selectedRegion.id ?? 0] == null) {
            return;
        }

        window.open(createKBLink(selectedRegion.profis3KBUrlAci, kbNumberRegion[selectedRegion.id ?? 0]) ?? '', '_blank');
    }

    public isKbTooltipEnabled(kbNumberRegion: { [key: string]: string | undefined }) {
        const selectedRegion = this.getRegionById(this.userSettingsService.settings.application.general.regionId.value ?? 0);
        return selectedRegion?.profis3KBUrlAci != null
            && kbNumberRegion != null
            && kbNumberRegion[selectedRegion.id ?? 0] != null;
    }

    private getRegionById(regionId: number) {
        const regionCodeList = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private addImage(image: string) {
        if (image && !this.initializedSpriteImages.includes(image)) {
            includeSprites(this.elementRef.nativeElement.shadowRoot, image as Sprite);
            this.initializedSpriteImages.push(image);
        }
    }
}
