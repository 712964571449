import { Injectable } from '@angular/core';
import {
    ClipboardService as ClipboardServiceCommon
} from '@profis-engineering/pe-ui-common/services/clipboard.common';

@Injectable({
    providedIn: 'root'
})
export class ClipboardService extends ClipboardServiceCommon {
}
