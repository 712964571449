import {
    AnchorLayoutSymmetryEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class AnchorLayoutSymmetry extends CodeList {
    public static fromService(codeList: AnchorLayoutSymmetryEntity) {
        return new AnchorLayoutSymmetry(CodeList.baseFromService('AnchorLayoutSymmetryEntity', codeList,
        {
            image: 'sprite-' + codeList.Image
        }));
    }

    public override cloneDeep(): AnchorLayoutSymmetry {
        const codeList: AnchorLayoutSymmetry = super.cloneDeep() as AnchorLayoutSymmetry;

        return codeList;
    }
}
