import { CustomLoadCategoryEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class CustomLoadCategory extends CodeList {
    public static fromService(codeList: CustomLoadCategoryEntity) {
        return new CustomLoadCategory(CodeList.baseFromService('CustomLoadCategoryEntity', codeList));
    }

    public override cloneDeep(): CustomLoadCategory {
        return super.cloneDeep() as CustomLoadCategory;
    }
}

