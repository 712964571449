import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { LoadType } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './loads-input.component.html',
    styleUrls: ['./loads-input.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsInputComponent implements OnInit {

    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;

    public forceZTextBox!: NumericTextBoxProps;
    public forceXTextBox!: NumericTextBoxProps;
    public forceYTextBox!: NumericTextBoxProps;

    public momentZTextBox!: NumericTextBoxProps;
    public momentXTextBox!: NumericTextBoxProps;
    public momentYTextBox!: NumericTextBoxProps;

    public title!: string;
    public subtitle!: string;

    public propertyMetaData = PropertyMetaData;

    private pendingSave = false;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private unitService: UnitService,
        private calculationService: CalculationServicePE
    ) { }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        this.title = this.design.loadType == LoadType.Seismic
            ? this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.TitleSeismic')
            : this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.TitleDefault');

        this.subtitle = isHnaBasedDesignStandard(this.design.designStandard.id)
            ? this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.SubtitleFactored')
            : this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.SubtitleDesign');

        let valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_ForceZ.id);
        this.forceZTextBox = {
            id: 'input-loads-forceZ',
            title: this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.ForceZ'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Force),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.forceZ
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_ForceX.id);
        this.forceXTextBox = {
            id: 'input-loads-forceX',
            title: this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.ForceX'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Force),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.forceX
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_ForceY.id);
        this.forceYTextBox = {
            id: 'input-loads-forceY',
            title: this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.ForceY'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Force),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.forceY
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_MomentZ.id);
        this.momentZTextBox = {
            id: 'input-loads-momentZ',
            title: this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.MomentZ'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Moment),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.momentZ
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_MomentX.id);
        this.momentXTextBox = {
            id: 'input-loads-momentX',
            title: this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.MomentX'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Moment),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.momentX
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_MomentY.id);
        this.momentYTextBox = {
            id: 'input-loads-momentY',
            title: this.localizationService.getString('Agito.Hilti.Profis3.LoadsInput.MomentY'),
            unit: this.unitService.getDefaultUnit(UnitGroup.Moment),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.momentY
        };
    }

    public get design() {
        return this.userService.design;
    }

    public save(form: NgForm) {
        if (this.submitted || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.calculationService.calculateAsync(this.design,
            (design) => {
                design.forceZ = this.forceZTextBox.value ?? 0;
                design.forceX = this.forceXTextBox.value ?? 0;
                design.forceY = this.forceYTextBox.value ?? 0;

                design.momentZ = this.momentZTextBox.value ?? 0;
                design.momentX = this.momentXTextBox.value ?? 0;
                design.momentY = this.momentYTextBox.value ?? 0;
            },
            { suppressLoadingFlag: true }
        )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private getPropertyValueRange(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        const retVal: IValueRange = {
            min: propertyInfo.min,
            max: propertyInfo.max
        };
        return retVal;
    }

}
