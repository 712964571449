import {
    ShearLoadDistributionTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class ShearLoadDistributionType extends CodeList {

    public static fromService(codeList: ShearLoadDistributionTypeEntity) {
        return new ShearLoadDistributionType(CodeList.baseFromService('ShearLoadDistributionTypeEntity', codeList));
    }

    public override cloneDeep(): ShearLoadDistributionType {
        return super.cloneDeep() as ShearLoadDistributionType;
    }
}
