<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.ShearLoadDistribution')"
    [closeButtonDisabled]="false">
</pe-modal-header>

<pe-modal-body>
    <div class="buttons-section">
        <pe-button
            class="shear-load-button"
            (buttonClick)="changeSelectedShearLoadDistributionType(1)"
            [look]="selectedShearLoadDistributionType == 1 ? 'Cta' : 'Default'"
            [text]="translate(rowClosesToEdgeTranslationKey)"
            [height]="'56px'">
        </pe-button>
        <pe-button
            class="shear-load-button"
            (buttonClick)="changeSelectedShearLoadDistributionType(2)"
            [look]="selectedShearLoadDistributionType == 2 ? 'Cta' : 'Default'"
            [text]="translate(upToFirstThreeRowsTranslationKey)"
            [height]="'56px'">
        </pe-button>
        <pe-button
            class="shear-load-button"
            (buttonClick)="changeSelectedShearLoadDistributionType(3)"
            [look]="selectedShearLoadDistributionType == 3 ? 'Cta' : 'Default'"
            [text]="translate(bandApproachTranslationKey)"
            [height]="'56px'">
        </pe-button>
    </div>
    <div [innerHtml]="selectedShearLoadDistributionInfo"></div>
</pe-modal-body>
