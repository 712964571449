import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CladdingProfileEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { ProfileShapeType as ProfileShape } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from './code-list';

export interface ICladdingProfileConstructor extends ICodeListConstructor {
    width?: number;
    height?: number;
    profileShapeType?: ProfileShape;
}

export class CladdingProfile extends CodeList {
    public Width?: number;
    public Height?: number;
    public ProfileShapeType?: ProfileShape;

    constructor(ctor: ICladdingProfileConstructor) {
        super(ctor);

        if (ctor != null) {
            this.Width = ctor.width;
            this.Height = ctor.height;
            this.ProfileShapeType = ctor.profileShapeType;
        }
    }

    public static fromService(codeList: CladdingProfileEntity) {
        return new CladdingProfile(CodeList.baseFromService<ICladdingProfileConstructor>('CladdingProfile', codeList, {
            width: codeList.Height,
            height: codeList.Width,
            profileShapeType: codeList.ProfileShapeType
        } as ICladdingProfileConstructor));
    }

    public override cloneDeep(): CladdingProfile {
        return super.cloneDeep() as CladdingProfile;
    }
}
