import cloneDeep from 'lodash-es/cloneDeep';

import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import {
    AnchorFamilyDetailedEntity as IAnchorFamilyDetailedEntity, AnchorFamilyEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    AnchorFilterType, InstallationType
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    AnchorType
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.TechnicalData.Enums';
import { CodeList } from './code-list';

export interface IAnchorFamilyDetailed {
    allowedInAnchorFilters: AnchorFilterType[];
    holeDiameters: number[];
    fixtureThicknesMin: number;
    fixtureThicknesMax: number;
    installationType: InstallationType;
    defaultSortOrder: number;
    descriptionFormat: string;
    descriptionKeys: string[];
    isZeroCleaningAllowed: boolean;
    isAutomaticCleaningApproved: boolean;
    isCrackedConcreteAllowed: boolean;
    isFatigueAllowed: boolean;
    isVariableEmbedment: boolean;
    isShock: boolean;
    isSeismicAllowed: boolean;
    isFireAllowed: boolean;
    isCleanTech: boolean;
    isSmallEdgeDistanceAndSpacing: boolean;
    isStainlessSteelOrHCRAvailable: boolean;
    isNuclearApproved: boolean;
    isShallowEmbedmentDepth: boolean;
    isDiamondDrillingSuitable: boolean;
    tag?: string;
    tested: boolean;
    filteredFasteners?: number[];
    showWHGCoatingMessage: boolean;
}

export interface IAnchorFamilyConstructor extends ICodeListConstructor {
    castInPlace?: string;
    anchorType?: AnchorType;
    anchorTypeRaw?: AnchorType;
    detailed?: IAnchorFamilyDetailed;
    nameMech?: string;
    countersunk?: boolean;
    internalPortfolioOnly?: boolean;
}

export class AnchorFamily extends CodeList {
    public castInPlace?: string;
    public anchorType?: AnchorType;
    public anchorTypeRaw?: AnchorType;
    public detailed?: IAnchorFamilyDetailed;
    public mechName?: string;
    public countersunk?: boolean;
    public internalPortfolioOnly?: boolean;

    constructor(codeList: IAnchorFamilyConstructor) {
        super(codeList);

        if (codeList != null) {
            this.castInPlace = codeList.castInPlace;
            this.anchorType = codeList.anchorType;
            this.anchorTypeRaw = codeList.anchorTypeRaw;
            this.detailed = cloneDeep(codeList.detailed);
            this.mechName = codeList.nameMech;
            this.countersunk = codeList.countersunk;
            this.internalPortfolioOnly = codeList.internalPortfolioOnly;
        }
    }

    public static fromService(codeList: AnchorFamilyEntity) {
        return new AnchorFamily(CodeList.baseFromService<IAnchorFamilyConstructor>('AnchorFamilyEntity', codeList, {
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-anchor-family-' + codeList.Image : 'sprite-anchor-family-no-photo-available',
            anchorType: codeList.AnchorType,
            anchorTypeRaw: codeList.AnchorTypeRaw,
            castInPlace: codeList.CastInPlace,
            nameMech: codeList.NameMech,
            countersunk: codeList.Countersunk,
            internalPortfolioOnly: codeList.InternalPortfolioOnly,

        } as IAnchorFamilyConstructor));
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        if (this.name == null || this.name == '') {
            return undefined;
        }

        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        const years = codeListDeps.localizationService.getString('Agito.Hilti.Profis3.Anchors.Years') ?? '';
        return formatKeyValue(this.name, { years });
    }

    public override cloneDeep() {
        const codeList: AnchorFamily = super.cloneDeep() as AnchorFamily;

        codeList.castInPlace = this.castInPlace;
        codeList.anchorType = this.anchorType;
        codeList.anchorTypeRaw = this.anchorTypeRaw;
        codeList.detailed = cloneDeep(this.detailed);
        codeList.mechName = this.mechName;
        codeList.countersunk = this.countersunk;
        codeList.internalPortfolioOnly = this.internalPortfolioOnly;
        return codeList;
    }

    public setDetailedFromService(detailed: IAnchorFamilyDetailedEntity) {
        const allowedInAnchorFilters = detailed.AllowedInAnchorFilters != null
            ? detailed.AllowedInAnchorFilters.slice().map(x => x as AnchorFilterType)
            : [];

        this.detailed = {
            allowedInAnchorFilters,
            holeDiameters: detailed.HoleDiameters,
            fixtureThicknesMin: detailed.FixtureThicknesMin,
            fixtureThicknesMax: detailed.FixtureThicknesMax,
            installationType: detailed.InstallationType,
            defaultSortOrder: detailed.DefaultSortOrder,
            descriptionFormat: detailed.DescriptionFormat,
            descriptionKeys: detailed.DescriptionKeys,
            isZeroCleaningAllowed: detailed.IsZeroCleaningAllowed,
            isAutomaticCleaningApproved: detailed.IsAutomaticCleaningApproved,
            isCrackedConcreteAllowed: detailed.IsCrackedConcreteAllowed,
            isFatigueAllowed: detailed.IsFatigueAllowed,
            isVariableEmbedment: detailed.IsVariableEmbedment,
            isShock: detailed.IsShock,
            isSeismicAllowed: detailed.IsSeismicAllowed_ETAG || detailed.IsSeismicAllowed_HNA,
            isFireAllowed: detailed.IsFireAllowed,
            isCleanTech: detailed.IsCleanTech,
            isSmallEdgeDistanceAndSpacing: detailed.IsSmallEdgeDistanceAndSpacing,
            isStainlessSteelOrHCRAvailable: detailed.IsStainlessSteelOrHCRAvailable,
            isNuclearApproved: detailed.IsNuclearApproved_EU || detailed.IsNuclearApproved_HNA,
            isShallowEmbedmentDepth: detailed.IsShallowEmbedmentDepth,
            isDiamondDrillingSuitable: detailed.IsDiamondDrillingSuitable,
            tag: !detailed.Tested ? 'Agito.Hilti.Profis3.Anchors.Untested.Tag' : undefined,
            tested : detailed.Tested,
            showWHGCoatingMessage: detailed.ShowWHGCoatingMessage,
            filteredFasteners: detailed.FilteredFasteners != null ? detailed.FilteredFasteners.slice() : undefined,
        };
    }
}
