import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import {
    IAnchorDimensions, IDefineAnchorsComponentInput, IDefineAnchorsValues
} from '../../../shared/components/define-anchors';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';

@Component({
    templateUrl: './define-anchors.component.html',
    styleUrls: ['./define-anchors.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DefineAnchorsComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IDefineAnchorsComponentInput>;

    public numberOfAnchorsXValid = false;
    public spacingOfAnchorsXValid = false;
    public edgeOfAnchorsXValid = false;
    public numberOfAnchorsYValid = false;
    public spacingOfAnchorsYValid = false;
    public edgeOfAnchorsYValid = false;
    public numberOfAnchors4XValid = false;
    public spacingOfAnchors4XValid = false;

    public numberOfAnchors!: IAnchorDimensions;
    public spacingOfAnchors!: IAnchorDimensions;
    public edgeOfAnchors!: IAnchorDimensions;
    public activeValue!: number | number[];

    public requiredValidator = Validators.required;
    public unitLength!: Unit;

    public readonly minSpacing = 1;
    public readonly maxSpacing = 100000;

    public readonly minEdge = 1;
    public readonly maxEdge = 100000;

    private readonly minAnchors = 1;
    private readonly maxAnchors = 99;
    private readonly defaultSpacing = 50;
    private readonly defaultAnchors = 3;
    private readonly defaultEdge = 15;

    constructor(
        public localization: LocalizationService,
        private unit: UnitService,
        private changeDetector: ChangeDetectorRef
    ) { }

    public get formValid() {
        if (this.activeValue == 4) {
            return this.numberOfAnchors4XValid
                && this.spacingOfAnchors4XValid;
        }

        return this.numberOfAnchorsXValid
            && this.spacingOfAnchorsXValid
            && this.numberOfAnchorsYValid
            && this.spacingOfAnchorsYValid
            &&
            (
                this.activeValue != 3
                ||
                this.edgeOfAnchorsXValid
                && this.edgeOfAnchorsYValid
            );
    }

    ngOnInit(): void {
        this.unitLength = this.unit.getDefaultUnit(UnitGroup.Length);

        if (this.modalInstance.input != null) {
            this.numberOfAnchors = this.modalInstance.input.values
                ? this.modalInstance.input.values.numberOfAnchors
                : { x: this.defaultAnchors, y: this.defaultAnchors };

            this.spacingOfAnchors = this.modalInstance.input.values
                ? this.modalInstance.input.values.spacingOfAnchors
                : { x: this.defaultSpacing, y: this.defaultSpacing };

            this.edgeOfAnchors = this.modalInstance.input.values
                ? this.modalInstance.input.values.edgeOfAnchors
                : { x: this.defaultEdge, y: this.defaultEdge };

            this.activeValue = this.modalInstance.input.activeValue() ?? 1;

            // recalculate max y if we open new custom layout modal
            const maxAnchorsY = this.calculateMaxAnchors(this.numberOfAnchors.x);
            if (maxAnchorsY < this.minAnchors) {
                this.numberOfAnchors = { x: this.defaultAnchors, y: this.defaultAnchors };
            }
            else if (this.numberOfAnchors.y > maxAnchorsY) {
                this.numberOfAnchors.y = maxAnchorsY;
            }
        }
    }

    public numberOfAnchorsXChanged(value: number) {
        const item = this.numberOfAnchors;
        const newVal = this.enforceMinMax(value, item.y);
        if (newVal != value) {
            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();
            item.x = newVal;
        }
    }

    public numberOfAnchorsYChanged(value: number) {
        const item = this.numberOfAnchors;
        const newVal = this.enforceMinMax(value, item.x);
        if (newVal != value) {
            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();
            item.y = newVal;
        }
    }

    public save(form: NgForm) {
        if (!this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        if (this.modalInstance.input?.onChange != null) {
            const retVal: IDefineAnchorsValues = {
                numberOfAnchors: this.numberOfAnchors,
                spacingOfAnchors: this.spacingOfAnchors,
                edgeOfAnchors: this.edgeOfAnchors
            };
            this.modalInstance.input.onChange(retVal);
        }
        this.close();
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    private enforceMinMax(value: number, opposite: number) {
        if (Number.isNaN(value)) {
            return this.defaultAnchors;
        }

        if (value < this.minAnchors) {
            return this.minAnchors;
        }

        const maxAnchors = this.calculateMaxAnchors(opposite);
        if (value > maxAnchors) {
            return maxAnchors;
        }

        return value;
    }

    private calculateMaxAnchors(opposite: number) {
        switch (this.activeValue) {
            case 1:
                return Math.floor(this.maxAnchors / opposite);
            case 2:
                return Math.floor((this.maxAnchors + 4 - 2 * opposite) / 2);
            case 3:
                return Math.floor((this.maxAnchors - 2 * opposite) / 2);
            case 4:
            default:
                return this.maxAnchors;
        }
    }
}
