import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    WeldLocation as PropertyWeldLocation
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { WeldCurve, WeldLocation } from '@profis-engineering/pe-gl-model/components/welds';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';


export class WeldsUpdate extends BaseUpdate {

    public static propertyWeldLocationToModalWeldLocation(weldLocation: PropertyWeldLocation) {
        switch (weldLocation) {
            case PropertyWeldLocation.Butt:
                return WeldLocation.Butt;
            case PropertyWeldLocation.Double:
                return WeldLocation.Double;
            case PropertyWeldLocation.Front:
                return WeldLocation.Front;
            case PropertyWeldLocation.None:
                return WeldLocation.None;
            case PropertyWeldLocation.Rear:
                return WeldLocation.Rear;

            case PropertyWeldLocation.Unknown:
            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown weld location.');
        }
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private OnPropertiesChange(model: IModelPe, design: DesignPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.weldOnWebs = design.weldOnWebs ?? false;
        model.welds.weldOnFlanges = design.weldOnFlanges ?? false;
        model.welds.directWelds = design.directWelds;

        model.welds.weldCurves = design.baseplateDesignData?.WeldCurves?.map((x): WeldCurve => ({
            name: x.Name,
            stiffenerKey: x.StiffenerKey,
            weldOrientation: x.WeldOrientation as number
        }));
    }

    @propertyValueChanged(UIProperty.AnchorPlate_WebWeldThickness)
    private AnchorPlate_WebWeldThickness(value: number, model: IModelPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.webWeldThickness = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_WebWeldLocation)
    private AnchorPlate_WebWeldLocation(value: PropertyWeldLocation, model: IModelPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.webWeldLocation = WeldsUpdate.propertyWeldLocationToModalWeldLocation(value);
    }

    @propertyValueChanged(UIProperty.AnchorPlate_FlangeWeldThickness)
    private AnchorPlate_FlangeWeldThickness(value: number, model: IModelPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.flangeWeldThickness = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_FlangeWeldLocation)
    private AnchorPlate_FlangeWeldLocation(value: PropertyWeldLocation, model: IModelPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.flangeWeldLocation = WeldsUpdate.propertyWeldLocationToModalWeldLocation(value);
    }

    @propertyValueChanged(UIProperty.AnchorPlate_DirectWeldThickness)
    private AnchorPlate_DirectWeldThickness(value: number, model: IModelPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.directWeldThickness = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_DirectWeldLocation)
    private AnchorPlate_DirectWeldLocation(value: PropertyWeldLocation, model: IModelPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.directWeldLocation = WeldsUpdate.propertyWeldLocationToModalWeldLocation(value);
    }

    @propertyValueChanged(UIProperty.AnchorPlate_DirectWeldLength)
    private AnchorPlate_DirectWeldLength(value: PropertyWeldLocation, model: IModelPe) {
        if (!model.welds) {
            throw new Error('model welds is undefined');
        }

        model.welds.directWeldLength = value;
    }
}
