import { Component, Input, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environmentPe';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

interface IMultipleApprovalsComponentInput {
    files: string[];
    availableLanguages?: string[][];
}

@Component({
    templateUrl: './multiple-approvals.component.html',
    styleUrls: ['./multiple-approvals.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MultipleApprovalsComponent {
    @Input()
    public modalInstance!: ModalInstance<IMultipleApprovalsComponentInput>;

    constructor(
        private user: UserService,
        private userSettingsService: UserSettingsService,
        private offline: OfflineService,
        private localizationService: LocalizationService
    ) { }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public openApproval(index: number) {
        this.user.design.usageCounter.Approval++;
        const openUrl = this.getApprovalUrl(index);
        this.offline.nativeLocalPathOpen(openUrl, this.files[index].toString(), true, true);
    }

    private getApprovalUrl(index: number): string {
        const culture = this.userSettingsService.getLanguage().culture?.toUpperCase() ?? '';
        const cultureParts = culture.split('-');
        let lang = cultureParts[0];

        if (this.availableLanguages.length > index) {

            if (!this.availableLanguages[index].some(row => row.includes(lang))) {
                lang = 'EN';
            }
        }
        else {
            if (!this.availableLanguages.some(row => row.includes(lang))) {
                lang = 'EN';
            }
        }

        return `${environment.baseUrl}approvals/${lang}/${this.files[index]}`;
    }

    public get files(): string[] {
        return this.modalInstance.input?.files ?? [];
    }

    private get availableLanguages(): string[][] {
        return this.modalInstance.input?.availableLanguages ?? [];
    }
}

