import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IBrickSizeComponentInput } from '../../../shared/components/brick-size';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { MainMenuModal } from '../../../shared/entities/main-menu';
import { BrickSize as BrickSizePropertyValue } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { BrickSize, IBrickSizeProps } from '../../components/main-menu/brick-size/BrickSize';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { updateMainMenuControl } from './menu-helper';

export function initializeBrickSize(
    design: DesignPe,
    controlProps: IBrickSizeProps,
    localizationService: LocalizationService,
    unitService: UnitService,
    uiPropertyId: number,
    modals: { [modal: number]: (input?: object) => IModalOpened },
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {

    controlProps.type = BrickSize;

    const formatText = (brickSize: BrickSizePropertyValue) => {
        if (brickSize == null) {
            return localizationService.getString('Agito.Hilti.Profis3.BrickSize.ButtonNoTitle');
        }

        const defaultUnit = unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);
        const precision = unitService.getPrecision(defaultUnit);

        const width = brickSize.Width != null ? unitService.convertUnitValueArgsToUnit(brickSize.Width, internalUnit, defaultUnit) : null;
        const height = brickSize.Height != null ? unitService.convertUnitValueArgsToUnit(brickSize.Height, internalUnit, defaultUnit) : null;
        const thickness = brickSize.Thickness != null ? unitService.convertUnitValueArgsToUnit(brickSize.Thickness, internalUnit, defaultUnit) : null;

        const widthFormated = width != null ? `${localizationService.getString('Agito.Hilti.Profis3.BrickSize.WidthSign')}: ${unitService.formatNumber(width, precision)}` : null;
        const heightFormated = height != null ? `${localizationService.getString('Agito.Hilti.Profis3.BrickSize.HeightSign')}: ${unitService.formatNumber(height, precision)}` : null;
        const thicknessFormated = thickness != null ? `${localizationService.getString('Agito.Hilti.Profis3.BrickSize.ThicknessSign')}: ${unitService.formatNumber(thickness, precision)}` : null;

        return `${widthFormated}${heightFormated != null ? ' ' + heightFormated : ''}${thicknessFormated != null ? ' ' + thicknessFormated : ''}`;
    };

    const propertyValue = design.model[uiPropertyId] as BrickSizePropertyValue;
    controlProps.brickSize = propertyValue;
    controlProps.brickSizeFormatted = formatText(propertyValue);

    controlProps.clicked = () => {
        const modalOpenFn = modals[MainMenuModal.brickSize];
        const modalInput: IBrickSizeComponentInput = {
            onSave: (brickSize) => {
                // change menu
                setState(menu => updateMainMenuControl<IBrickSizeProps>(menu, controlProps.controlId, { brickSize, brickSizeFormatted: formatText(brickSize) } as any));
            }
        };
        modalOpenFn(modalInput);
    };

    const onStateChanged = (state: IDesignState) => {
        return ({
            brickSize: state.model[uiPropertyId],
            brickSizeFormatted: formatText(state.model[uiPropertyId] as BrickSizePropertyValue)
        } as any) as IBrickSizeProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}
