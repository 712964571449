
import {
    SupplementaryReinforcementDirectionOfCastingEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class SupplementaryReinforcementDirectionOfCasting extends CodeList {

    public static fromService(codeList: SupplementaryReinforcementDirectionOfCastingEntity) {
        return new SupplementaryReinforcementDirectionOfCasting(CodeList.baseFromService('SupplementaryReinforcementDirectionOfCastingEntity', codeList));
    }

    public override cloneDeep(): SupplementaryReinforcementDirectionOfCasting {
        return super.cloneDeep() as SupplementaryReinforcementDirectionOfCasting;
    }
}
