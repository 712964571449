import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { buildHtmlTooltip } from '@profis-engineering/pe-ui-common/helpers/tooltip-helper';
import { getMenuControlImageReactData, getReactCSSProperties } from '../../../helpers/main-menu-helpers/react-helpers';
import { getSpriteAsIconStyleResponsive } from '../../../sprites';
import styles from './react-select-anchor.css';

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

export interface ISelectAnchorProps extends IControlProps {
    buttonText?: string;
    buttonImage?: string;
    buttonImageStyle?: IResponsiveIconStyle;
    isNew?: boolean;
    internalPortfolioOnly?: boolean;
    tag?: string;
    clicked?: () => void;
}

class _SelectAnchor extends React.PureComponent<ISelectAnchorProps> {
    private noPhotoImageData: React.CSSProperties | undefined;
    public static styleSheets = [sheet];

    constructor(props: ISelectAnchorProps) {
        super(props);

        this.noPhotoImageData = getReactCSSProperties(getSpriteAsIconStyleResponsive('sprite-anchor-family-no-photo-available'));
        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const buttonId = `${this.props.controlId}-button`;
        const tooltip = this.props.title != null && this.props.title != '' ? null : buildHtmlTooltip(this.props.tooltip, this.props.tooltipTitle);
        const newText = this.props.localization?.getString('Agito.Hilti.Profis3.Anchors.New');
        const internalPortfolioOnlyText = this.props.localization?.getString('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio').charAt(0);
        const internalPortfolioOnlyTooltip = this.props.localization?.getString('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip');

        const tooltipTitle = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;
        const tooltipText = this.props.disabled ? this.props.tooltipDisabled : this.props.tooltip;
        const shortTag = this.props.tag != null && this.props.tag.length > 0 ? this.props.localization?.getString(this.props.tag).charAt(0) : null;

        const anchorImageData = getMenuControlImageReactData(
            this.props.buttonImage,
            this.props.buttonImageStyle?.elementStyle,
            this.props.buttonImageStyle?.afterElementStyle
        );
        const useResponsiveStyle = anchorImageData.elementAfterStyle != null;

        return (
            <div data-control-id={this.props.controlId} className={`react-select-anchor control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={buttonId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <button
                    data-control-id={buttonId}
                    className='button-rich select-anchor-button'
                    type='button'
                    disabled={this.props.disabled}
                    onClick={this.onClick}
                    data-tip={tooltip}
                    data-html={tooltip != null ? true : null}>
                    <div className='select-anchor-button-content button-rich-content'>
                        <div className='button-rich-image'>
                            <div className='image-wrapper'>
                                <span className='no-image sprite sprite-responsive'>
                                    <span className='sprite-responsive-after' style={this.noPhotoImageData}></span>
                                </span>

                                <div className='anchor-image-container'>
                                    <span className={`anchor-image sprite sprite-responsive ${anchorImageData.spriteName ?? ''}`} style={anchorImageData.elementStyle}>
                                        {
                                            useResponsiveStyle
                                                ? <span className='sprite-responsive-after' style={anchorImageData.elementAfterStyle}></span>
                                                : null
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='button-rich-seperator' />
                        <p className='anchor-name button-rich-text'>{this.props.buttonText ?? ''}</p>
                        {shortTag != null && shortTag.length > 0 ? <span className='tag'>{shortTag}</span> : null}
                        {this.props.isNew ? <span className='new'>{newText}</span> : null}
                        {this.props.internalPortfolioOnly ? <span className='tag' data-tip={internalPortfolioOnlyTooltip} data-html={internalPortfolioOnlyTooltip != null ? true : null}>{internalPortfolioOnlyText}</span> : null}
                        <span className='sprite sprite-arrow-right button-rich-caret'></span>
                    </div>
                </button>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        if (this.props.clicked != null) {
            this.props.clicked();
        }
    }
}

export const SelectAnchor: typeof _SelectAnchor & ContolsStyleSheets = _SelectAnchor;