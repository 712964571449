'use strict';

import { UIPropertyConfig } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';

export interface IPropertyMetaData {
    id: number;
    name: string;
    minValue?: number;
    maxValue?: number;
    defaultValue?: number;
}

const None: IPropertyMetaData = {
    id: 0,
    name: 'None',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AdditionalFiltersButton: IPropertyMetaData = {
    id: 3200,
    name: 'AdditionalFiltersButton',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AdvancedBaseplateCalculation_AlphaCC: IPropertyMetaData = {
    id: 1052,
    name: 'AdvancedBaseplateCalculation_AlphaCC',
    minValue: 0.8,
    maxValue: 1,
    defaultValue: undefined,
};

const AdvancedBaseplateCalculation_ConcreteInCompressionMethod: IPropertyMetaData = {
    id: 2006,
    name: 'AdvancedBaseplateCalculation_ConcreteInCompressionMethod',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AdvancedBaseplateCalculation_DivergentIterationsCount: IPropertyMetaData = {
    id: 1015,
    name: 'AdvancedBaseplateCalculation_DivergentIterationsCount',
    minValue: 2,
    maxValue: 10,
    defaultValue: 6,
};

const AdvancedBaseplateCalculation_DivisionOfArcsOfRHS: IPropertyMetaData = {
    id: 1012,
    name: 'AdvancedBaseplateCalculation_DivisionOfArcsOfRHS',
    minValue: 0,
    maxValue: undefined,
    defaultValue: 3,
};

const AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS: IPropertyMetaData = {
    id: 1011,
    name: 'AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS',
    minValue: 0,
    maxValue: undefined,
    defaultValue: 32,
};

const AdvancedBaseplateCalculation_EffectiveArea: IPropertyMetaData = {
    id: 1003,
    name: 'AdvancedBaseplateCalculation_EffectiveArea',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: 0.1,
};

const AdvancedBaseplateCalculation_EffectiveAreaAISC: IPropertyMetaData = {
    id: 880,
    name: 'AdvancedBaseplateCalculation_EffectiveAreaAISC',
    minValue: 0.01,
    maxValue: 0.9,
    defaultValue: 0.1,
};

const AdvancedBaseplateCalculation_JointCoefficientBj: IPropertyMetaData = {
    id: 1002,
    name: 'AdvancedBaseplateCalculation_JointCoefficientBj',
    minValue: 0.6,
    maxValue: 1,
    defaultValue: 0.67,
};

const AdvancedBaseplateCalculation_LimitPlasticStrain: IPropertyMetaData = {
    id: 1005,
    name: 'AdvancedBaseplateCalculation_LimitPlasticStrain',
    minValue: 0,
    maxValue: 30,
    defaultValue: 5,
};

const AdvancedBaseplateCalculation_LoadDistributionAngle: IPropertyMetaData = {
    id: 1004,
    name: 'AdvancedBaseplateCalculation_LoadDistributionAngle',
    minValue: 0,
    maxValue: 1.5707963267949,
    defaultValue: 0.588175957922089,
};

const AdvancedBaseplateCalculation_MaximumSizeOfElement: IPropertyMetaData = {
    id: 1017,
    name: 'AdvancedBaseplateCalculation_MaximumSizeOfElement',
    minValue: 1,
    maxValue: undefined,
    defaultValue: 50,
};

const AdvancedBaseplateCalculation_MinimumSizeOfElement: IPropertyMetaData = {
    id: 1016,
    name: 'AdvancedBaseplateCalculation_MinimumSizeOfElement',
    minValue: 1,
    maxValue: undefined,
    defaultValue: 10,
};

const AdvancedBaseplateCalculation_NumberOfAnalysisIterations: IPropertyMetaData = {
    id: 1014,
    name: 'AdvancedBaseplateCalculation_NumberOfAnalysisIterations',
    minValue: 5,
    maxValue: 50,
    defaultValue: 25,
};

const AdvancedBaseplateCalculation_NumberOfElementsOfEdge: IPropertyMetaData = {
    id: 1013,
    name: 'AdvancedBaseplateCalculation_NumberOfElementsOfEdge',
    minValue: 0,
    maxValue: undefined,
    defaultValue: 8,
};

const AdvancedBaseplateCalculation_StopAtLimitStrain: IPropertyMetaData = {
    id: 1001,
    name: 'AdvancedBaseplateCalculation_StopAtLimitStrain',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AdvancedBaseplateCalculation_UseULSStresses: IPropertyMetaData = {
    id: 1053,
    name: 'AdvancedBaseplateCalculation_UseULSStresses',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_AnchorFamily: IPropertyMetaData = {
    id: 201,
    name: 'AnchorLayout_AnchorFamily',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_AnchorFilters: IPropertyMetaData = {
    id: 216,
    name: 'AnchorLayout_AnchorFilters',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_AnchorLayoutSymmetry: IPropertyMetaData = {
    id: 2260,
    name: 'AnchorLayout_AnchorLayoutSymmetry',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_AnchorTorqueType: IPropertyMetaData = {
    id: 450,
    name: 'AnchorLayout_AnchorTorqueType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ApprovalLanguages: IPropertyMetaData = {
    id: 851,
    name: 'AnchorLayout_ApprovalLanguages',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ApprovalLanguages_STO: IPropertyMetaData = {
    id: 1057,
    name: 'AnchorLayout_ApprovalLanguages_STO',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ApprovalLanguages_UKTA: IPropertyMetaData = {
    id: 2258,
    name: 'AnchorLayout_ApprovalLanguages_UKTA',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ArticleNumberCapsule: IPropertyMetaData = {
    id: 790,
    name: 'AnchorLayout_ArticleNumberCapsule',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ArticleNumberChemical: IPropertyMetaData = {
    id: 779,
    name: 'AnchorLayout_ArticleNumberChemical',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ArticleNumberInsert: IPropertyMetaData = {
    id: 789,
    name: 'AnchorLayout_ArticleNumberInsert',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ArticleNumberInsertAlternative: IPropertyMetaData = {
    id: 2262,
    name: 'AnchorLayout_ArticleNumberInsertAlternative',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ArticleNumberMechanical: IPropertyMetaData = {
    id: 778,
    name: 'AnchorLayout_ArticleNumberMechanical',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ArticleNumberSieveSleeve: IPropertyMetaData = {
    id: 811,
    name: 'AnchorLayout_ArticleNumberSieveSleeve',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_CipAh: IPropertyMetaData = {
    id: 2123,
    name: 'AnchorLayout_CipAh',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_CipK8: IPropertyMetaData = {
    id: 2103,
    name: 'AnchorLayout_CipK8',
    minValue: 1,
    maxValue: 4,
    defaultValue: undefined,
};

const AnchorLayout_CipMinimumEdgeDistance: IPropertyMetaData = {
    id: 2102,
    name: 'AnchorLayout_CipMinimumEdgeDistance',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_CipMinSpacing: IPropertyMetaData = {
    id: 2101,
    name: 'AnchorLayout_CipMinSpacing',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_CmdRotateAnticlockwise: IPropertyMetaData = {
    id: 204,
    name: 'AnchorLayout_CmdRotateAnticlockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_CmdRotateClockwise: IPropertyMetaData = {
    id: 203,
    name: 'AnchorLayout_CmdRotateClockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_CorrosionMaterial: IPropertyMetaData = {
    id: 3231,
    name: 'AnchorLayout_CorrosionMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_CustomLayoutPoints: IPropertyMetaData = {
    id: 215,
    name: 'AnchorLayout_CustomLayoutPoints',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ElasticModulus: IPropertyMetaData = {
    id: 2000,
    name: 'AnchorLayout_ElasticModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ElongationLength: IPropertyMetaData = {
    id: 2002,
    name: 'AnchorLayout_ElongationLength',
    minValue: 50.8,
    maxValue: 635,
    defaultValue: 120.015,
};

const AnchorLayout_EmbedmentDepthFixedMultiple: IPropertyMetaData = {
    id: 213,
    name: 'AnchorLayout_EmbedmentDepthFixedMultiple',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_EmbedmentDepthOptimizationType: IPropertyMetaData = {
    id: 214,
    name: 'AnchorLayout_EmbedmentDepthOptimizationType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_EmbedmentDepthVariable: IPropertyMetaData = {
    id: 212,
    name: 'AnchorLayout_EmbedmentDepthVariable',
    minValue: 0,
    maxValue: 1000,
    defaultValue: undefined,
};

const AnchorLayout_Fastener: IPropertyMetaData = {
    id: 2059,
    name: 'AnchorLayout_Fastener',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_FillHolesETAG: IPropertyMetaData = {
    id: 206,
    name: 'AnchorLayout_FillHolesETAG',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_FillHolesSOFA: IPropertyMetaData = {
    id: 205,
    name: 'AnchorLayout_FillHolesSOFA',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_HeadDiameter: IPropertyMetaData = {
    id: 2100,
    name: 'AnchorLayout_HeadDiameter',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_HeadThickness: IPropertyMetaData = {
    id: 2099,
    name: 'AnchorLayout_HeadThickness',
    minValue: 2,
    maxValue: 500,
    defaultValue: undefined,
};

const AnchorLayout_Hnom: IPropertyMetaData = {
    id: 2089,
    name: 'AnchorLayout_Hnom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_IsAnchorENFatigueCompliant: IPropertyMetaData = {
    id: 1165,
    name: 'AnchorLayout_IsAnchorENFatigueCompliant',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_IsNutAndWasher: IPropertyMetaData = {
    id: 447,
    name: 'AnchorLayout_IsNutAndWasher',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_IsReducedInstallTorque: IPropertyMetaData = {
    id: 209,
    name: 'AnchorLayout_IsReducedInstallTorque',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_Layout: IPropertyMetaData = {
    id: 200,
    name: 'AnchorLayout_Layout',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_LayoutCustom: IPropertyMetaData = {
    id: 736,
    name: 'AnchorLayout_LayoutCustom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_MasonryFastenerConfiguration: IPropertyMetaData = {
    id: 874,
    name: 'AnchorLayout_MasonryFastenerConfiguration',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_MinConcreteCover: IPropertyMetaData = {
    id: 451,
    name: 'AnchorLayout_MinConcreteCover',
    minValue: 9.525,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorLayout_MinRebarSize: IPropertyMetaData = {
    id: 452,
    name: 'AnchorLayout_MinRebarSize',
    minValue: 9.525,
    maxValue: 57.3278,
    defaultValue: undefined,
};

const AnchorLayout_OffsetXFromAnchorPlate: IPropertyMetaData = {
    id: 428,
    name: 'AnchorLayout_OffsetXFromAnchorPlate',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_OffsetYFromAnchorPlate: IPropertyMetaData = {
    id: 429,
    name: 'AnchorLayout_OffsetYFromAnchorPlate',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_OnlyUKTAApprovals: IPropertyMetaData = {
    id: 2263,
    name: 'AnchorLayout_OnlyUKTAApprovals',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_Radius: IPropertyMetaData = {
    id: 140,
    name: 'AnchorLayout_Radius',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorLayout_Rotate: IPropertyMetaData = {
    id: 500,
    name: 'AnchorLayout_Rotate',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_SeismicFillingSet: IPropertyMetaData = {
    id: 798,
    name: 'AnchorLayout_SeismicFillingSet',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ShowFullInternalPortfolio: IPropertyMetaData = {
    id: 2259,
    name: 'AnchorLayout_ShowFullInternalPortfolio',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_Size: IPropertyMetaData = {
    id: 211,
    name: 'AnchorLayout_Size',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_SizeAC58: IPropertyMetaData = {
    id: 2256,
    name: 'AnchorLayout_SizeAC58',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_Spacing_SX: IPropertyMetaData = {
    id: 120,
    name: 'AnchorLayout_Spacing_SX',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorLayout_Spacing_SY: IPropertyMetaData = {
    id: 130,
    name: 'AnchorLayout_Spacing_SY',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorLayout_TensileArea: IPropertyMetaData = {
    id: 2001,
    name: 'AnchorLayout_TensileArea',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_Type: IPropertyMetaData = {
    id: 210,
    name: 'AnchorLayout_Type',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_TypeAC58: IPropertyMetaData = {
    id: 2255,
    name: 'AnchorLayout_TypeAC58',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ViewApproval: IPropertyMetaData = {
    id: 519,
    name: 'AnchorLayout_ViewApproval',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ViewApproval_STO: IPropertyMetaData = {
    id: 1058,
    name: 'AnchorLayout_ViewApproval_STO',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ViewApproval_UKTA: IPropertyMetaData = {
    id: 2257,
    name: 'AnchorLayout_ViewApproval_UKTA',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_ViewCSTBDocument: IPropertyMetaData = {
    id: 3227,
    name: 'AnchorLayout_ViewCSTBDocument',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorLayout_WasherDiameter: IPropertyMetaData = {
    id: 448,
    name: 'AnchorLayout_WasherDiameter',
    minValue: 2.54,
    maxValue: 152.4,
    defaultValue: undefined,
};

const AnchorLayout_WasherThickness: IPropertyMetaData = {
    id: 449,
    name: 'AnchorLayout_WasherThickness',
    minValue: 2.54,
    maxValue: 50.8,
    defaultValue: undefined,
};

const AnchorPlate_AdjustThickness: IPropertyMetaData = {
    id: 2003,
    name: 'AnchorPlate_AdjustThickness',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_AdvancedCalculation: IPropertyMetaData = {
    id: 1019,
    name: 'AnchorPlate_AdvancedCalculation',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_AdvancedSettings: IPropertyMetaData = {
    id: 1020,
    name: 'AnchorPlate_AdvancedSettings',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_AnchorPlateSize: IPropertyMetaData = {
    id: 749,
    name: 'AnchorPlate_AnchorPlateSize',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_CalculationType: IPropertyMetaData = {
    id: 1018,
    name: 'AnchorPlate_CalculationType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_CmdRotateAnticlockwise: IPropertyMetaData = {
    id: 108,
    name: 'AnchorPlate_CmdRotateAnticlockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_CmdRotateClockwise: IPropertyMetaData = {
    id: 107,
    name: 'AnchorPlate_CmdRotateClockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_CustomGroutCompressiveStrength: IPropertyMetaData = {
    id: 112,
    name: 'AnchorPlate_CustomGroutCompressiveStrength',
    minValue: 1,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const AnchorPlate_CustomGroutCompressiveStrength_Asad: IPropertyMetaData = {
    id: 2130,
    name: 'AnchorPlate_CustomGroutCompressiveStrength_Asad',
    minValue: 1,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const AnchorPlate_CustomLayoutPoints: IPropertyMetaData = {
    id: 115,
    name: 'AnchorPlate_CustomLayoutPoints',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_CustomPlateMaterial: IPropertyMetaData = {
    id: 1109,
    name: 'AnchorPlate_CustomPlateMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_CustomStiffenerMaterial: IPropertyMetaData = {
    id: 1110,
    name: 'AnchorPlate_CustomStiffenerMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_Diameter: IPropertyMetaData = {
    id: 104,
    name: 'AnchorPlate_Diameter',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldGravity: IPropertyMetaData = {
    id: 2070,
    name: 'AnchorPlate_DirectWeldGravity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldLength: IPropertyMetaData = {
    id: 2063,
    name: 'AnchorPlate_DirectWeldLength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldLocation: IPropertyMetaData = {
    id: 2061,
    name: 'AnchorPlate_DirectWeldLocation',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldMaterial: IPropertyMetaData = {
    id: 2060,
    name: 'AnchorPlate_DirectWeldMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldMaterialBetaW: IPropertyMetaData = {
    id: 2069,
    name: 'AnchorPlate_DirectWeldMaterialBetaW',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldMaterialDensity: IPropertyMetaData = {
    id: 2067,
    name: 'AnchorPlate_DirectWeldMaterialDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldMaterialEModulus: IPropertyMetaData = {
    id: 2066,
    name: 'AnchorPlate_DirectWeldMaterialEModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldMaterialPoisson: IPropertyMetaData = {
    id: 2068,
    name: 'AnchorPlate_DirectWeldMaterialPoisson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldMaterialUltimateStrength: IPropertyMetaData = {
    id: 2064,
    name: 'AnchorPlate_DirectWeldMaterialUltimateStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldMaterialYieldStrength: IPropertyMetaData = {
    id: 2065,
    name: 'AnchorPlate_DirectWeldMaterialYieldStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_DirectWeldThickness: IPropertyMetaData = {
    id: 2071,
    name: 'AnchorPlate_DirectWeldThickness',
    minValue: 0,
    maxValue: 12,
    defaultValue: undefined,
};

const AnchorPlate_Exists: IPropertyMetaData = {
    id: 100,
    name: 'AnchorPlate_Exists',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldGravity: IPropertyMetaData = {
    id: 1164,
    name: 'AnchorPlate_FlangeWeldGravity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldLegLength: IPropertyMetaData = {
    id: 1086,
    name: 'AnchorPlate_FlangeWeldLegLength',
    minValue: 3,
    maxValue: 15,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldLocation: IPropertyMetaData = {
    id: 1042,
    name: 'AnchorPlate_FlangeWeldLocation',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldMaterial: IPropertyMetaData = {
    id: 1041,
    name: 'AnchorPlate_FlangeWeldMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldMaterialBetaW: IPropertyMetaData = {
    id: 1135,
    name: 'AnchorPlate_FlangeWeldMaterialBetaW',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldMaterialDensity: IPropertyMetaData = {
    id: 1133,
    name: 'AnchorPlate_FlangeWeldMaterialDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldMaterialEModulus: IPropertyMetaData = {
    id: 1132,
    name: 'AnchorPlate_FlangeWeldMaterialEModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldMaterialPoisson: IPropertyMetaData = {
    id: 1134,
    name: 'AnchorPlate_FlangeWeldMaterialPoisson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldMaterialUltimateStrength: IPropertyMetaData = {
    id: 1130,
    name: 'AnchorPlate_FlangeWeldMaterialUltimateStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldMaterialYieldStrength: IPropertyMetaData = {
    id: 1131,
    name: 'AnchorPlate_FlangeWeldMaterialYieldStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_FlangeWeldThickness: IPropertyMetaData = {
    id: 1040,
    name: 'AnchorPlate_FlangeWeldThickness',
    minValue: 3,
    maxValue: 15,
    defaultValue: undefined,
};

const AnchorPlate_Grout: IPropertyMetaData = {
    id: 113,
    name: 'AnchorPlate_Grout',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_Grout_Asad: IPropertyMetaData = {
    id: 2131,
    name: 'AnchorPlate_Grout_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_Height: IPropertyMetaData = {
    id: 102,
    name: 'AnchorPlate_Height',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorPlate_IsCompressiveStrengthOfMortarHigh: IPropertyMetaData = {
    id: 512,
    name: 'AnchorPlate_IsCompressiveStrengthOfMortarHigh',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_IsPlateMaterialCustom: IPropertyMetaData = {
    id: 1112,
    name: 'AnchorPlate_IsPlateMaterialCustom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_IsStiffenerMaterialCustom: IPropertyMetaData = {
    id: 1113,
    name: 'AnchorPlate_IsStiffenerMaterialCustom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleLength: IPropertyMetaData = {
    id: 2086,
    name: 'AnchorPlate_LedgerAngleLength',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleProfileCustomHeight: IPropertyMetaData = {
    id: 2083,
    name: 'AnchorPlate_LedgerAngleProfileCustomHeight',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleProfileCustomThickness: IPropertyMetaData = {
    id: 2085,
    name: 'AnchorPlate_LedgerAngleProfileCustomThickness',
    minValue: 0.1,
    maxValue: 1000,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleProfileCustomWidth: IPropertyMetaData = {
    id: 2084,
    name: 'AnchorPlate_LedgerAngleProfileCustomWidth',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleProfileFamilyId: IPropertyMetaData = {
    id: 2080,
    name: 'AnchorPlate_LedgerAngleProfileFamilyId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleProfileSizeId: IPropertyMetaData = {
    id: 2081,
    name: 'AnchorPlate_LedgerAngleProfileSizeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleProfileSizeName: IPropertyMetaData = {
    id: 2082,
    name: 'AnchorPlate_LedgerAngleProfileSizeName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_LedgerAngleShape: IPropertyMetaData = {
    id: 2088,
    name: 'AnchorPlate_LedgerAngleShape',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_MaterialDensity: IPropertyMetaData = {
    id: 1122,
    name: 'AnchorPlate_MaterialDensity',
    minValue: 1000,
    maxValue: 10000,
    defaultValue: 7850,
};

const AnchorPlate_MaterialEModulus: IPropertyMetaData = {
    id: 1121,
    name: 'AnchorPlate_MaterialEModulus',
    minValue: 100000,
    maxValue: 1000000,
    defaultValue: 200000,
};

const AnchorPlate_MaterialPoisson: IPropertyMetaData = {
    id: 1123,
    name: 'AnchorPlate_MaterialPoisson',
    minValue: 0.1,
    maxValue: 0.5,
    defaultValue: 0.2,
};

const AnchorPlate_MaterialUltimateStrength: IPropertyMetaData = {
    id: 1119,
    name: 'AnchorPlate_MaterialUltimateStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 235,
};

const AnchorPlate_MaterialYieldStrength: IPropertyMetaData = {
    id: 1120,
    name: 'AnchorPlate_MaterialYieldStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 360,
};

const AnchorPlate_NotOwnedLearnMore: IPropertyMetaData = {
    id: 1043,
    name: 'AnchorPlate_NotOwnedLearnMore',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_NotOwnedLearnMoreInfo: IPropertyMetaData = {
    id: 1044,
    name: 'AnchorPlate_NotOwnedLearnMoreInfo',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_OptimizedThicknessMessage: IPropertyMetaData = {
    id: 813,
    name: 'AnchorPlate_OptimizedThicknessMessage',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_PlateShape: IPropertyMetaData = {
    id: 106,
    name: 'AnchorPlate_PlateShape',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_PlateShapeCustom: IPropertyMetaData = {
    id: 735,
    name: 'AnchorPlate_PlateShapeCustom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_Rotate: IPropertyMetaData = {
    id: 510,
    name: 'AnchorPlate_Rotate',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_ShowOptimizedAnchorPlateThickness: IPropertyMetaData = {
    id: 757,
    name: 'AnchorPlate_ShowOptimizedAnchorPlateThickness',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_ShowStressDistribution: IPropertyMetaData = {
    id: 681,
    name: 'AnchorPlate_ShowStressDistribution',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_SideLength: IPropertyMetaData = {
    id: 103,
    name: 'AnchorPlate_SideLength',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorPlate_StandoffDistance: IPropertyMetaData = {
    id: 110,
    name: 'AnchorPlate_StandoffDistance',
    minValue: 0,
    maxValue: 1000,
    defaultValue: undefined,
};

const AnchorPlate_StandoffDistance_Asad: IPropertyMetaData = {
    id: 2128,
    name: 'AnchorPlate_StandoffDistance_Asad',
    minValue: 0,
    maxValue: 1000,
    defaultValue: undefined,
};

const AnchorPlate_StandoffNutAndWasherHeight: IPropertyMetaData = {
    id: 2261,
    name: 'AnchorPlate_StandoffNutAndWasherHeight',
    minValue: 0,
    maxValue: 1000,
    defaultValue: undefined,
};

const AnchorPlate_StandoffRestraintLevel: IPropertyMetaData = {
    id: 111,
    name: 'AnchorPlate_StandoffRestraintLevel',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const AnchorPlate_StandoffRestraintLevel_Asad: IPropertyMetaData = {
    id: 2129,
    name: 'AnchorPlate_StandoffRestraintLevel_Asad',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const AnchorPlate_StandoffType: IPropertyMetaData = {
    id: 114,
    name: 'AnchorPlate_StandoffType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StandoffType_Asad: IPropertyMetaData = {
    id: 2132,
    name: 'AnchorPlate_StandoffType_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerCount: IPropertyMetaData = {
    id: 1045,
    name: 'AnchorPlate_StiffenerCount',
    minValue: 0,
    maxValue: 8,
    defaultValue: 4,
};

const AnchorPlate_StiffenerHeight: IPropertyMetaData = {
    id: 1028,
    name: 'AnchorPlate_StiffenerHeight',
    minValue: 50,
    maxValue: 300,
    defaultValue: 120,
};

const AnchorPlate_StiffenerHorizontalEdgeLength: IPropertyMetaData = {
    id: 1029,
    name: 'AnchorPlate_StiffenerHorizontalEdgeLength',
    minValue: 0,
    maxValue: 500,
    defaultValue: 20,
};

const AnchorPlate_StiffenerLayout: IPropertyMetaData = {
    id: 1046,
    name: 'AnchorPlate_StiffenerLayout',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerLayoutCustom: IPropertyMetaData = {
    id: 2012,
    name: 'AnchorPlate_StiffenerLayoutCustom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerMaterial: IPropertyMetaData = {
    id: 1022,
    name: 'AnchorPlate_StiffenerMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerMaterialDensity: IPropertyMetaData = {
    id: 1139,
    name: 'AnchorPlate_StiffenerMaterialDensity',
    minValue: 1000,
    maxValue: 10000,
    defaultValue: 7850,
};

const AnchorPlate_StiffenerMaterialEModulus: IPropertyMetaData = {
    id: 1138,
    name: 'AnchorPlate_StiffenerMaterialEModulus',
    minValue: 100000,
    maxValue: 1000000,
    defaultValue: 200000,
};

const AnchorPlate_StiffenerMaterialPoisson: IPropertyMetaData = {
    id: 1140,
    name: 'AnchorPlate_StiffenerMaterialPoisson',
    minValue: 0.1,
    maxValue: 0.5,
    defaultValue: 0.2,
};

const AnchorPlate_StiffenerMaterialUltimateStrength: IPropertyMetaData = {
    id: 1136,
    name: 'AnchorPlate_StiffenerMaterialUltimateStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 235,
};

const AnchorPlate_StiffenerMaterialYieldStrength: IPropertyMetaData = {
    id: 1137,
    name: 'AnchorPlate_StiffenerMaterialYieldStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 360,
};

const AnchorPlate_StiffenerRadialOffset: IPropertyMetaData = {
    id: 1077,
    name: 'AnchorPlate_StiffenerRadialOffset',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerShape: IPropertyMetaData = {
    id: 1023,
    name: 'AnchorPlate_StiffenerShape',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerThickness: IPropertyMetaData = {
    id: 1024,
    name: 'AnchorPlate_StiffenerThickness',
    minValue: 4,
    maxValue: 50,
    defaultValue: 4,
};

const AnchorPlate_StiffenerVerticalEdgeLength: IPropertyMetaData = {
    id: 1030,
    name: 'AnchorPlate_StiffenerVerticalEdgeLength',
    minValue: 0,
    maxValue: 500,
    defaultValue: 20,
};

const AnchorPlate_StiffenerWeldGravity: IPropertyMetaData = {
    id: 1162,
    name: 'AnchorPlate_StiffenerWeldGravity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldLegLength: IPropertyMetaData = {
    id: 1088,
    name: 'AnchorPlate_StiffenerWeldLegLength',
    minValue: 3,
    maxValue: 15,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldLocation: IPropertyMetaData = {
    id: 1034,
    name: 'AnchorPlate_StiffenerWeldLocation',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldMaterial: IPropertyMetaData = {
    id: 1032,
    name: 'AnchorPlate_StiffenerWeldMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldMaterialBetaW: IPropertyMetaData = {
    id: 1146,
    name: 'AnchorPlate_StiffenerWeldMaterialBetaW',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldMaterialDensity: IPropertyMetaData = {
    id: 1144,
    name: 'AnchorPlate_StiffenerWeldMaterialDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldMaterialEModulus: IPropertyMetaData = {
    id: 1143,
    name: 'AnchorPlate_StiffenerWeldMaterialEModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldMaterialPoisson: IPropertyMetaData = {
    id: 1145,
    name: 'AnchorPlate_StiffenerWeldMaterialPoisson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldMaterialUltimateStrength: IPropertyMetaData = {
    id: 1141,
    name: 'AnchorPlate_StiffenerWeldMaterialUltimateStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldMaterialYieldStrength: IPropertyMetaData = {
    id: 1142,
    name: 'AnchorPlate_StiffenerWeldMaterialYieldStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWeldThickness: IPropertyMetaData = {
    id: 1033,
    name: 'AnchorPlate_StiffenerWeldThickness',
    minValue: 3,
    maxValue: 15,
    defaultValue: undefined,
};

const AnchorPlate_StiffenerWidth: IPropertyMetaData = {
    id: 1027,
    name: 'AnchorPlate_StiffenerWidth',
    minValue: 50,
    maxValue: 500,
    defaultValue: 50,
};

const AnchorPlate_StressImage: IPropertyMetaData = {
    id: 1054,
    name: 'AnchorPlate_StressImage',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_Thickness: IPropertyMetaData = {
    id: 109,
    name: 'AnchorPlate_Thickness',
    minValue: 3,
    maxValue: 500,
    defaultValue: undefined,
};

const AnchorPlate_UseBaseplate: IPropertyMetaData = {
    id: 743,
    name: 'AnchorPlate_UseBaseplate',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_VerifyDiaphragmShear: IPropertyMetaData = {
    id: 2150,
    name: 'AnchorPlate_VerifyDiaphragmShear',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldGravity: IPropertyMetaData = {
    id: 1163,
    name: 'AnchorPlate_WebWeldGravity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldLegLength: IPropertyMetaData = {
    id: 1087,
    name: 'AnchorPlate_WebWeldLegLength',
    minValue: 3,
    maxValue: 15,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldLocation: IPropertyMetaData = {
    id: 1038,
    name: 'AnchorPlate_WebWeldLocation',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldMaterial: IPropertyMetaData = {
    id: 1037,
    name: 'AnchorPlate_WebWeldMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldMaterialBetaW: IPropertyMetaData = {
    id: 1129,
    name: 'AnchorPlate_WebWeldMaterialBetaW',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldMaterialDensity: IPropertyMetaData = {
    id: 1127,
    name: 'AnchorPlate_WebWeldMaterialDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldMaterialEModulus: IPropertyMetaData = {
    id: 1126,
    name: 'AnchorPlate_WebWeldMaterialEModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldMaterialPoisson: IPropertyMetaData = {
    id: 1128,
    name: 'AnchorPlate_WebWeldMaterialPoisson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldMaterialUltimateStrength: IPropertyMetaData = {
    id: 1124,
    name: 'AnchorPlate_WebWeldMaterialUltimateStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldMaterialYieldStrength: IPropertyMetaData = {
    id: 1125,
    name: 'AnchorPlate_WebWeldMaterialYieldStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_WebWeldThickness: IPropertyMetaData = {
    id: 1036,
    name: 'AnchorPlate_WebWeldThickness',
    minValue: 3,
    maxValue: 15,
    defaultValue: undefined,
};

const AnchorPlate_WeldingType: IPropertyMetaData = {
    id: 1085,
    name: 'AnchorPlate_WeldingType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorPlate_Width: IPropertyMetaData = {
    id: 101,
    name: 'AnchorPlate_Width',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const AnchorPlate_SteelType: IPropertyMetaData = {
    id: 686,
    name: 'AnchorPlate_SteelType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const AnchorUserChosen: IPropertyMetaData = {
    id: 3205,
    name: 'AnchorUserChosen',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_Altitude: IPropertyMetaData = {
    id: 720,
    name: 'Application_Altitude',
    minValue: 0,
    maxValue: 1100000,
    defaultValue: undefined,
};

const Application_Application: IPropertyMetaData = {
    id: 642,
    name: 'Application_Application',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_BarsSpacing: IPropertyMetaData = {
    id: 1096,
    name: 'Application_BarsSpacing',
    minValue: 10,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_BasicWindVelocityId: IPropertyMetaData = {
    id: 723,
    name: 'Application_BasicWindVelocityId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_BeamBottomSegment: IPropertyMetaData = {
    id: 712,
    name: 'Application_BeamBottomSegment',
    minValue: 0,
    maxValue: 1000,
    defaultValue: undefined,
};

const Application_BeamToBeamDistance: IPropertyMetaData = {
    id: 640,
    name: 'Application_BeamToBeamDistance',
    minValue: 100,
    maxValue: 5000,
    defaultValue: undefined,
};

const Application_BeamType: IPropertyMetaData = {
    id: 639,
    name: 'Application_BeamType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_BuildingCategory: IPropertyMetaData = {
    id: 643,
    name: 'Application_BuildingCategory',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_BuildingDepth: IPropertyMetaData = {
    id: 696,
    name: 'Application_BuildingDepth',
    minValue: 1,
    maxValue: 100000,
    defaultValue: undefined,
};

const Application_BuildingHeight: IPropertyMetaData = {
    id: 695,
    name: 'Application_BuildingHeight',
    minValue: 1,
    maxValue: 100000,
    defaultValue: undefined,
};

const Application_BuildingWidth: IPropertyMetaData = {
    id: 694,
    name: 'Application_BuildingWidth',
    minValue: 1,
    maxValue: 100000,
    defaultValue: undefined,
};

const Application_BuildingZone: IPropertyMetaData = {
    id: 697,
    name: 'Application_BuildingZone',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_CalculatePostAndRailDeflection: IPropertyMetaData = {
    id: 748,
    name: 'Application_CalculatePostAndRailDeflection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_CityAndStateId: IPropertyMetaData = {
    id: 722,
    name: 'Application_CityAndStateId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_Cladding: IPropertyMetaData = {
    id: 692,
    name: 'Application_Cladding',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const Application_CladdingBarOffsetFromFloor: IPropertyMetaData = {
    id: 1097,
    name: 'Application_CladdingBarOffsetFromFloor',
    minValue: 0,
    maxValue: 200,
    defaultValue: undefined,
};

const Application_CladdingBarOffsetFromRail: IPropertyMetaData = {
    id: 1098,
    name: 'Application_CladdingBarOffsetFromRail',
    minValue: 0,
    maxValue: 200,
    defaultValue: undefined,
};

const Application_CladdingBarsCount: IPropertyMetaData = {
    id: 1095,
    name: 'Application_CladdingBarsCount',
    minValue: 2,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_CladdingHeight: IPropertyMetaData = {
    id: 764,
    name: 'Application_CladdingHeight',
    minValue: 100,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_CladdingOffset: IPropertyMetaData = {
    id: 763,
    name: 'Application_CladdingOffset',
    minValue: 25,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_CladdingProfile: IPropertyMetaData = {
    id: 1099,
    name: 'Application_CladdingProfile',
    minValue: 0,
    maxValue: 200,
    defaultValue: undefined,
};

const Application_CladdingType: IPropertyMetaData = {
    id: 1094,
    name: 'Application_CladdingType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_CladdingWidth: IPropertyMetaData = {
    id: 765,
    name: 'Application_CladdingWidth',
    minValue: 100,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_ConnectionType: IPropertyMetaData = {
    id: 2058,
    name: 'Application_ConnectionType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_DistanceInsideTownTerrainId: IPropertyMetaData = {
    id: 726,
    name: 'Application_DistanceInsideTownTerrainId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_DistanceToSeaside: IPropertyMetaData = {
    id: 721,
    name: 'Application_DistanceToSeaside',
    minValue: 0,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Application_DistanceUpwindToShorelineId: IPropertyMetaData = {
    id: 725,
    name: 'Application_DistanceUpwindToShorelineId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_EnvironmentType: IPropertyMetaData = {
    id: 637,
    name: 'Application_EnvironmentType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_H: IPropertyMetaData = {
    id: 714,
    name: 'Application_H',
    minValue: 1,
    maxValue: 1100000,
    defaultValue: undefined,
};

const Application_HandrailHeightOverGround: IPropertyMetaData = {
    id: 709,
    name: 'Application_HandrailHeightOverGround',
    minValue: 0,
    maxValue: 100000,
    defaultValue: undefined,
};

const Application_HandrailLength: IPropertyMetaData = {
    id: 699,
    name: 'Application_HandrailLength',
    minValue: 1,
    maxValue: 100000,
    defaultValue: undefined,
};

const Application_IsFreeStandingWall: IPropertyMetaData = {
    id: 698,
    name: 'Application_IsFreeStandingWall',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_IsOrographyRelevant: IPropertyMetaData = {
    id: 718,
    name: 'Application_IsOrographyRelevant',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_Ld: IPropertyMetaData = {
    id: 716,
    name: 'Application_Ld',
    minValue: 1,
    maxValue: 1000000,
    defaultValue: undefined,
};

const Application_Lu: IPropertyMetaData = {
    id: 715,
    name: 'Application_Lu',
    minValue: 1,
    maxValue: 1000000,
    defaultValue: undefined,
};

const Application_NonCarryingLayer: IPropertyMetaData = {
    id: 758,
    name: 'Application_NonCarryingLayer',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_ReferenceWindVelocityId: IPropertyMetaData = {
    id: 727,
    name: 'Application_ReferenceWindVelocityId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_ReturnPeriod: IPropertyMetaData = {
    id: 1062,
    name: 'Application_ReturnPeriod',
    minValue: 2,
    maxValue: 100,
    defaultValue: 50,
};

const Application_RoughnessClassId: IPropertyMetaData = {
    id: 724,
    name: 'Application_RoughnessClassId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_TerrainCategoryId: IPropertyMetaData = {
    id: 719,
    name: 'Application_TerrainCategoryId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_TerrainType: IPropertyMetaData = {
    id: 713,
    name: 'Application_TerrainType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_Windloads: IPropertyMetaData = {
    id: 690,
    name: 'Application_Windloads',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_WindPressure: IPropertyMetaData = {
    id: 1091,
    name: 'Application_WindPressure',
    minValue: 0,
    maxValue: 0.003,
    defaultValue: undefined,
};

const Application_WindUplift: IPropertyMetaData = {
    id: 1092,
    name: 'Application_WindUplift',
    minValue: 0,
    maxValue: 0.003,
    defaultValue: undefined,
};

const Application_WindVelocityPressure: IPropertyMetaData = {
    id: 728,
    name: 'Application_WindVelocityPressure',
    minValue: 0,
    maxValue: 0.0033,
    defaultValue: undefined,
};

const Application_WindZone: IPropertyMetaData = {
    id: 693,
    name: 'Application_WindZone',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Application_X: IPropertyMetaData = {
    id: 717,
    name: 'Application_X',
    minValue: 0,
    maxValue: 1000000,
    defaultValue: undefined,
};

const Application_ZipCodeHandrailLoadsId: IPropertyMetaData = {
    id: 1105,
    name: 'Application_ZipCodeHandrailLoadsId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ActiveBeam: IPropertyMetaData = {
    id: 751,
    name: 'BaseMaterial_ActiveBeam',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BalustradeHeight: IPropertyMetaData = {
    id: 670,
    name: 'BaseMaterial_BalustradeHeight',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_BalustradeWidth: IPropertyMetaData = {
    id: 671,
    name: 'BaseMaterial_BalustradeWidth',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_BeamLength: IPropertyMetaData = {
    id: 665,
    name: 'BaseMaterial_BeamLength',
    minValue: 125,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_BeamLeverArm: IPropertyMetaData = {
    id: 666,
    name: 'BaseMaterial_BeamLeverArm',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_BrickCharacteristic: IPropertyMetaData = {
    id: 2153,
    name: 'BaseMaterial_BrickCharacteristic',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickData: IPropertyMetaData = {
    id: 638,
    name: 'BaseMaterial_BrickData',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickGroup: IPropertyMetaData = {
    id: 529,
    name: 'BaseMaterial_BrickGroup',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickHeight: IPropertyMetaData = {
    id: 611,
    name: 'BaseMaterial_BrickHeight',
    minValue: 40,
    maxValue: 5000,
    defaultValue: undefined,
};

const BaseMaterial_BrickLayout: IPropertyMetaData = {
    id: 530,
    name: 'BaseMaterial_BrickLayout',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickLayoutStartLeft: IPropertyMetaData = {
    id: 619,
    name: 'BaseMaterial_BrickLayoutStartLeft',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickLength: IPropertyMetaData = {
    id: 612,
    name: 'BaseMaterial_BrickLength',
    minValue: 40,
    maxValue: 5000,
    defaultValue: undefined,
};

const BaseMaterial_BrickSize: IPropertyMetaData = {
    id: 613,
    name: 'BaseMaterial_BrickSize',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickSizeId: IPropertyMetaData = {
    id: 2152,
    name: 'BaseMaterial_BrickSizeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickStrength: IPropertyMetaData = {
    id: 533,
    name: 'BaseMaterial_BrickStrength',
    minValue: 1,
    maxValue: 100,
    defaultValue: undefined,
};

const BaseMaterial_BrickStrengthAC58: IPropertyMetaData = {
    id: 2254,
    name: 'BaseMaterial_BrickStrengthAC58',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickStrengthId: IPropertyMetaData = {
    id: 532,
    name: 'BaseMaterial_BrickStrengthId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickStrengthType: IPropertyMetaData = {
    id: 531,
    name: 'BaseMaterial_BrickStrengthType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_BrickWidth: IPropertyMetaData = {
    id: 610,
    name: 'BaseMaterial_BrickWidth',
    minValue: 40,
    maxValue: 5000,
    defaultValue: undefined,
};

const BaseMaterial_CleaningMethod: IPropertyMetaData = {
    id: 631,
    name: 'BaseMaterial_CleaningMethod',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteCharacteristic: IPropertyMetaData = {
    id: 1,
    name: 'BaseMaterial_ConcreteCharacteristic',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteCharacteristic_Asad: IPropertyMetaData = {
    id: 2110,
    name: 'BaseMaterial_ConcreteCharacteristic_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteGrade: IPropertyMetaData = {
    id: 2,
    name: 'BaseMaterial_ConcreteGrade',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteGrade_Asad: IPropertyMetaData = {
    id: 2111,
    name: 'BaseMaterial_ConcreteGrade_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteGradeType: IPropertyMetaData = {
    id: 85,
    name: 'BaseMaterial_ConcreteGradeType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthNegative: IPropertyMetaData = {
    id: 669,
    name: 'BaseMaterial_ConcreteLengthNegative',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthNegativeAnchor: IPropertyMetaData = {
    id: 786,
    name: 'BaseMaterial_ConcreteLengthNegativeAnchor',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthNegativeInfinity: IPropertyMetaData = {
    id: 680,
    name: 'BaseMaterial_ConcreteLengthNegativeInfinity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthNegativeInfinityAnchor: IPropertyMetaData = {
    id: 788,
    name: 'BaseMaterial_ConcreteLengthNegativeInfinityAnchor',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthPositive: IPropertyMetaData = {
    id: 668,
    name: 'BaseMaterial_ConcreteLengthPositive',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthPositiveAnchor: IPropertyMetaData = {
    id: 785,
    name: 'BaseMaterial_ConcreteLengthPositiveAnchor',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthPositiveInfinity: IPropertyMetaData = {
    id: 679,
    name: 'BaseMaterial_ConcreteLengthPositiveInfinity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteLengthPositiveInfinityAnchor: IPropertyMetaData = {
    id: 787,
    name: 'BaseMaterial_ConcreteLengthPositiveInfinityAnchor',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteReinforcement: IPropertyMetaData = {
    id: 14,
    name: 'BaseMaterial_ConcreteReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConcreteType: IPropertyMetaData = {
    id: 3228,
    name: 'BaseMaterial_ConcreteType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConstrainedEdgeBottom: IPropertyMetaData = {
    id: 618,
    name: 'BaseMaterial_ConstrainedEdgeBottom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConstrainedEdgeLeft: IPropertyMetaData = {
    id: 616,
    name: 'BaseMaterial_ConstrainedEdgeLeft',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConstrainedEdgeRight: IPropertyMetaData = {
    id: 615,
    name: 'BaseMaterial_ConstrainedEdgeRight',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ConstrainedEdgeTop: IPropertyMetaData = {
    id: 617,
    name: 'BaseMaterial_ConstrainedEdgeTop',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_CubeStrength: IPropertyMetaData = {
    id: 807,
    name: 'BaseMaterial_CubeStrength',
    minValue: 0,
    maxValue: 120,
    defaultValue: undefined,
};

const BaseMaterial_CubeStrength_Asad: IPropertyMetaData = {
    id: 2168,
    name: 'BaseMaterial_CubeStrength_Asad',
    minValue: 0,
    maxValue: 120,
    defaultValue: undefined,
};

const BaseMaterial_CustomCompressiveStrength: IPropertyMetaData = {
    id: 3,
    name: 'BaseMaterial_CustomCompressiveStrength',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const BaseMaterial_CustomDensity: IPropertyMetaData = {
    id: 1101,
    name: 'BaseMaterial_CustomDensity',
    minValue: 1000,
    maxValue: 5000,
    defaultValue: undefined,
};

const BaseMaterial_CustomElasticity: IPropertyMetaData = {
    id: 1103,
    name: 'BaseMaterial_CustomElasticity',
    minValue: 10000,
    maxValue: 50000,
    defaultValue: undefined,
};

const BaseMaterial_CustomJointMaterialStrength: IPropertyMetaData = {
    id: 2252,
    name: 'BaseMaterial_CustomJointMaterialStrength',
    minValue: 5,
    maxValue: 50,
    defaultValue: undefined,
};

const BaseMaterial_CustomPoisson: IPropertyMetaData = {
    id: 1102,
    name: 'BaseMaterial_CustomPoisson',
    minValue: 0.1,
    maxValue: 0.5,
    defaultValue: undefined,
};

const BaseMaterial_CustomShearOfSteel: IPropertyMetaData = {
    id: 1104,
    name: 'BaseMaterial_CustomShearOfSteel',
    minValue: 5000,
    maxValue: 20000,
    defaultValue: undefined,
};

const BaseMaterial_CylinderStrength: IPropertyMetaData = {
    id: 808,
    name: 'BaseMaterial_CylinderStrength',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const BaseMaterial_CylinderStrength_Asad: IPropertyMetaData = {
    id: 2167,
    name: 'BaseMaterial_CylinderStrength_Asad',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const BaseMaterial_DeckThickness: IPropertyMetaData = {
    id: 831,
    name: 'BaseMaterial_DeckThickness',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_DrillingMethod: IPropertyMetaData = {
    id: 12,
    name: 'BaseMaterial_DrillingMethod',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_DrillingMethod_Asad: IPropertyMetaData = {
    id: 2126,
    name: 'BaseMaterial_DrillingMethod_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_EdgeReinforcement: IPropertyMetaData = {
    id: 15,
    name: 'BaseMaterial_EdgeReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_EdgeReinforcementHNA: IPropertyMetaData = {
    id: 92,
    name: 'BaseMaterial_EdgeReinforcementHNA',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXNegative: IPropertyMetaData = {
    id: 35,
    name: 'BaseMaterial_EdgeXNegative',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXNegative_Asad: IPropertyMetaData = {
    id: 2114,
    name: 'BaseMaterial_EdgeXNegative_Asad',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXNegativeFromAnchor: IPropertyMetaData = {
    id: 39,
    name: 'BaseMaterial_EdgeXNegativeFromAnchor',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXPositive: IPropertyMetaData = {
    id: 34,
    name: 'BaseMaterial_EdgeXPositive',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXPositive_Asad: IPropertyMetaData = {
    id: 2113,
    name: 'BaseMaterial_EdgeXPositive_Asad',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXPositiveFromAnchor: IPropertyMetaData = {
    id: 38,
    name: 'BaseMaterial_EdgeXPositiveFromAnchor',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYNegative: IPropertyMetaData = {
    id: 37,
    name: 'BaseMaterial_EdgeYNegative',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYNegative_Asad: IPropertyMetaData = {
    id: 2116,
    name: 'BaseMaterial_EdgeYNegative_Asad',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYNegativeFromAnchor: IPropertyMetaData = {
    id: 41,
    name: 'BaseMaterial_EdgeYNegativeFromAnchor',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYPositive: IPropertyMetaData = {
    id: 36,
    name: 'BaseMaterial_EdgeYPositive',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYPositive_Asad: IPropertyMetaData = {
    id: 2115,
    name: 'BaseMaterial_EdgeYPositive_Asad',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYPositiveFromAnchor: IPropertyMetaData = {
    id: 40,
    name: 'BaseMaterial_EdgeYPositiveFromAnchor',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_FasteningOption: IPropertyMetaData = {
    id: 632,
    name: 'BaseMaterial_FasteningOption',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_HeadJointCompletelyFilled: IPropertyMetaData = {
    id: 2004,
    name: 'BaseMaterial_HeadJointCompletelyFilled',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_HeadJointSelection: IPropertyMetaData = {
    id: 2264,
    name: 'BaseMaterial_HeadJointSelection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_HoleCondition: IPropertyMetaData = {
    id: 446,
    name: 'BaseMaterial_HoleCondition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_HoleType: IPropertyMetaData = {
    id: 13,
    name: 'BaseMaterial_HoleType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_HoleType_Asad: IPropertyMetaData = {
    id: 2127,
    name: 'BaseMaterial_HoleType_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_InstallationDirection: IPropertyMetaData = {
    id: 1084,
    name: 'BaseMaterial_InstallationDirection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsAnchorReinforcement: IPropertyMetaData = {
    id: 86,
    name: 'BaseMaterial_IsAnchorReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsAnchorReinforcementShear: IPropertyMetaData = {
    id: 88,
    name: 'BaseMaterial_IsAnchorReinforcementShear',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsAnchorReinforcementTension: IPropertyMetaData = {
    id: 87,
    name: 'BaseMaterial_IsAnchorReinforcementTension',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsCornerReinforcement: IPropertyMetaData = {
    id: 89,
    name: 'BaseMaterial_IsCornerReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXNegativeFromAnchorReinforced: IPropertyMetaData = {
    id: 47,
    name: 'BaseMaterial_IsEdgeXNegativeFromAnchorReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXNegativeLocked: IPropertyMetaData = {
    id: 51,
    name: 'BaseMaterial_IsEdgeXNegativeLocked',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXNegativeReinforced: IPropertyMetaData = {
    id: 43,
    name: 'BaseMaterial_IsEdgeXNegativeReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXNegativeReinforced_Asad: IPropertyMetaData = {
    id: 2118,
    name: 'BaseMaterial_IsEdgeXNegativeReinforced_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXPositiveFromAnchorReinforced: IPropertyMetaData = {
    id: 46,
    name: 'BaseMaterial_IsEdgeXPositiveFromAnchorReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXPositiveLocked: IPropertyMetaData = {
    id: 50,
    name: 'BaseMaterial_IsEdgeXPositiveLocked',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXPositiveReinforced: IPropertyMetaData = {
    id: 42,
    name: 'BaseMaterial_IsEdgeXPositiveReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeXPositiveReinforced_Asad: IPropertyMetaData = {
    id: 2117,
    name: 'BaseMaterial_IsEdgeXPositiveReinforced_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYNegativeFromAnchorReinforced: IPropertyMetaData = {
    id: 49,
    name: 'BaseMaterial_IsEdgeYNegativeFromAnchorReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYNegativeLocked: IPropertyMetaData = {
    id: 53,
    name: 'BaseMaterial_IsEdgeYNegativeLocked',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYNegativeReinforced: IPropertyMetaData = {
    id: 45,
    name: 'BaseMaterial_IsEdgeYNegativeReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYNegativeReinforced_Asad: IPropertyMetaData = {
    id: 2120,
    name: 'BaseMaterial_IsEdgeYNegativeReinforced_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYPositiveFromAnchorReinforced: IPropertyMetaData = {
    id: 48,
    name: 'BaseMaterial_IsEdgeYPositiveFromAnchorReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYPositiveLocked: IPropertyMetaData = {
    id: 52,
    name: 'BaseMaterial_IsEdgeYPositiveLocked',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYPositiveReinforced: IPropertyMetaData = {
    id: 44,
    name: 'BaseMaterial_IsEdgeYPositiveReinforced',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsEdgeYPositiveReinforced_Asad: IPropertyMetaData = {
    id: 2119,
    name: 'BaseMaterial_IsEdgeYPositiveReinforced_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsLightweightConcrete: IPropertyMetaData = {
    id: 453,
    name: 'BaseMaterial_IsLightweightConcrete',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsLightweightConcrete_Asad: IPropertyMetaData = {
    id: 2161,
    name: 'BaseMaterial_IsLightweightConcrete_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsNonLoadBearingLayerPresent: IPropertyMetaData = {
    id: 759,
    name: 'BaseMaterial_IsNonLoadBearingLayerPresent',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsPlasteredWall: IPropertyMetaData = {
    id: 605,
    name: 'BaseMaterial_IsPlasteredWall',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_IsSplittingReinforcement: IPropertyMetaData = {
    id: 16,
    name: 'BaseMaterial_IsSplittingReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_JointMaterial: IPropertyMetaData = {
    id: 602,
    name: 'BaseMaterial_JointMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_JointThicknessHorizontal: IPropertyMetaData = {
    id: 601,
    name: 'BaseMaterial_JointThicknessHorizontal',
    minValue: 1,
    maxValue: 25,
    defaultValue: undefined,
};

const BaseMaterial_JointThicknessVertical: IPropertyMetaData = {
    id: 600,
    name: 'BaseMaterial_JointThicknessVertical',
    minValue: 1,
    maxValue: 25,
    defaultValue: undefined,
};

const BaseMaterial_LightweightConcreteLambdaA: IPropertyMetaData = {
    id: 454,
    name: 'BaseMaterial_LightweightConcreteLambdaA',
    minValue: 0.75,
    maxValue: 1,
    defaultValue: undefined,
};

const BaseMaterial_LightweightConcreteLambdaA_Asad: IPropertyMetaData = {
    id: 2162,
    name: 'BaseMaterial_LightweightConcreteLambdaA_Asad',
    minValue: 0.75,
    maxValue: 1,
    defaultValue: undefined,
};

const BaseMaterial_MasonryAnchorPosition: IPropertyMetaData = {
    id: 837,
    name: 'BaseMaterial_MasonryAnchorPosition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeXNegative: IPropertyMetaData = {
    id: 634,
    name: 'BaseMaterial_MasonryEdgeXNegative',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeXNegativeFromAnchor: IPropertyMetaData = {
    id: 738,
    name: 'BaseMaterial_MasonryEdgeXNegativeFromAnchor',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeXPositive: IPropertyMetaData = {
    id: 633,
    name: 'BaseMaterial_MasonryEdgeXPositive',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeXPositiveFromAnchor: IPropertyMetaData = {
    id: 737,
    name: 'BaseMaterial_MasonryEdgeXPositiveFromAnchor',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeYNegative: IPropertyMetaData = {
    id: 636,
    name: 'BaseMaterial_MasonryEdgeYNegative',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeYNegativeFromAnchor: IPropertyMetaData = {
    id: 740,
    name: 'BaseMaterial_MasonryEdgeYNegativeFromAnchor',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeYPositive: IPropertyMetaData = {
    id: 635,
    name: 'BaseMaterial_MasonryEdgeYPositive',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryEdgeYPositiveFromAnchor: IPropertyMetaData = {
    id: 739,
    name: 'BaseMaterial_MasonryEdgeYPositiveFromAnchor',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_MasonryThickness: IPropertyMetaData = {
    id: 872,
    name: 'BaseMaterial_MasonryThickness',
    minValue: 1,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeck: IPropertyMetaData = {
    id: 869,
    name: 'BaseMaterial_MetalDeck',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckAnchorOffset: IPropertyMetaData = {
    id: 830,
    name: 'BaseMaterial_MetalDeckAnchorOffset',
    minValue: 0,
    maxValue: 2540,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckAnchorPosition: IPropertyMetaData = {
    id: 826,
    name: 'BaseMaterial_MetalDeckAnchorPosition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckFluteBaseWidth: IPropertyMetaData = {
    id: 827,
    name: 'BaseMaterial_MetalDeckFluteBaseWidth',
    minValue: 25.4,
    maxValue: 2540,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckFluteDepth: IPropertyMetaData = {
    id: 824,
    name: 'BaseMaterial_MetalDeckFluteDepth',
    minValue: 0,
    maxValue: 2540,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckFluteDistance: IPropertyMetaData = {
    id: 821,
    name: 'BaseMaterial_MetalDeckFluteDistance',
    minValue: 0,
    maxValue: 2540,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckFlutePeakWidth: IPropertyMetaData = {
    id: 828,
    name: 'BaseMaterial_MetalDeckFlutePeakWidth',
    minValue: 25.4,
    maxValue: 2540,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckLowerFluteWidth: IPropertyMetaData = {
    id: 822,
    name: 'BaseMaterial_MetalDeckLowerFluteWidth',
    minValue: 25.4,
    maxValue: 2540,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckTotalFluteDepth: IPropertyMetaData = {
    id: 829,
    name: 'BaseMaterial_MetalDeckTotalFluteDepth',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckType: IPropertyMetaData = {
    id: 825,
    name: 'BaseMaterial_MetalDeckType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_MetalDeckUpperFluteWidth: IPropertyMetaData = {
    id: 823,
    name: 'BaseMaterial_MetalDeckUpperFluteWidth',
    minValue: 25.4,
    maxValue: 2540,
    defaultValue: undefined,
};

const BaseMaterial_NearestJointDistance: IPropertyMetaData = {
    id: 873,
    name: 'BaseMaterial_NearestJointDistance',
    minValue: 0,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const BaseMaterial_NonLoadBearingLayer: IPropertyMetaData = {
    id: 676,
    name: 'BaseMaterial_NonLoadBearingLayer',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_OffsetX: IPropertyMetaData = {
    id: 674,
    name: 'BaseMaterial_OffsetX',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_OffsetY: IPropertyMetaData = {
    id: 675,
    name: 'BaseMaterial_OffsetY',
    minValue: 0,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_PlasterThickness: IPropertyMetaData = {
    id: 606,
    name: 'BaseMaterial_PlasterThickness',
    minValue: 1,
    maxValue: 200,
    defaultValue: undefined,
};

const BaseMaterial_PlasterYoungsModulus: IPropertyMetaData = {
    id: 607,
    name: 'BaseMaterial_PlasterYoungsModulus',
    minValue: 100,
    maxValue: 30000,
    defaultValue: undefined,
};

const BaseMaterial_PrismStrength: IPropertyMetaData = {
    id: 809,
    name: 'BaseMaterial_PrismStrength',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const BaseMaterial_ReinforcementConditionShear: IPropertyMetaData = {
    id: 90,
    name: 'BaseMaterial_ReinforcementConditionShear',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_ReinforcementConditionTension: IPropertyMetaData = {
    id: 91,
    name: 'BaseMaterial_ReinforcementConditionTension',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_StairHeight: IPropertyMetaData = {
    id: 672,
    name: 'BaseMaterial_StairHeight',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_StairLength: IPropertyMetaData = {
    id: 673,
    name: 'BaseMaterial_StairLength',
    minValue: 1,
    maxValue: 10000,
    defaultValue: undefined,
};

const BaseMaterial_Temperature: IPropertyMetaData = {
    id: 835,
    name: 'BaseMaterial_Temperature',
    minValue: -100,
    maxValue: 200,
    defaultValue: undefined,
};

const BaseMaterial_TemperatureLongTerm: IPropertyMetaData = {
    id: 11,
    name: 'BaseMaterial_TemperatureLongTerm',
    minValue: -100,
    maxValue: 200,
    defaultValue: undefined,
};

const BaseMaterial_TemperatureLongTerm_Asad: IPropertyMetaData = {
    id: 2125,
    name: 'BaseMaterial_TemperatureLongTerm_Asad',
    minValue: -100,
    maxValue: 200,
    defaultValue: undefined,
};

const BaseMaterial_TemperatureShortTerm: IPropertyMetaData = {
    id: 10,
    name: 'BaseMaterial_TemperatureShortTerm',
    minValue: -100,
    maxValue: 200,
    defaultValue: undefined,
};

const BaseMaterial_TemperatureShortTerm_Asad: IPropertyMetaData = {
    id: 2124,
    name: 'BaseMaterial_TemperatureShortTerm_Asad',
    minValue: -100,
    maxValue: 200,
    defaultValue: undefined,
};

const BaseMaterial_Thickness: IPropertyMetaData = {
    id: 33,
    name: 'BaseMaterial_Thickness',
    minValue: 1,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_Thickness_Asad: IPropertyMetaData = {
    id: 2112,
    name: 'BaseMaterial_Thickness_Asad',
    minValue: 1,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_TorquingType: IPropertyMetaData = {
    id: 877,
    name: 'BaseMaterial_TorquingType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_UseCategory: IPropertyMetaData = {
    id: 630,
    name: 'BaseMaterial_UseCategory',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_VerticalJointsFilled: IPropertyMetaData = {
    id: 614,
    name: 'BaseMaterial_VerticalJointsFilled',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_WHGApplication: IPropertyMetaData = {
    id: 2148,
    name: 'BaseMaterial_WHGApplication',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_WHGType: IPropertyMetaData = {
    id: 2149,
    name: 'BaseMaterial_WHGType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXNegativeFromAnchorPlateEdge: IPropertyMetaData = {
    id: 848,
    name: 'BaseMaterial_EdgeXNegativeFromAnchorPlateEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXNegativeFromConcreteEdge: IPropertyMetaData = {
    id: 844,
    name: 'BaseMaterial_EdgeXNegativeFromConcreteEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXNegativeFromProfileCenter: IPropertyMetaData = {
    id: 840,
    name: 'BaseMaterial_EdgeXNegativeFromProfileCenter',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXPositiveFromAnchorPlateEdge: IPropertyMetaData = {
    id: 847,
    name: 'BaseMaterial_EdgeXPositiveFromAnchorPlateEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXPositiveFromConcreteEdge: IPropertyMetaData = {
    id: 843,
    name: 'BaseMaterial_EdgeXPositiveFromConcreteEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeXPositiveFromProfileCenter: IPropertyMetaData = {
    id: 839,
    name: 'BaseMaterial_EdgeXPositiveFromProfileCenter',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYNegativeFromAnchorPlateEdge: IPropertyMetaData = {
    id: 850,
    name: 'BaseMaterial_EdgeYNegativeFromAnchorPlateEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYNegativeFromConcreteEdge: IPropertyMetaData = {
    id: 846,
    name: 'BaseMaterial_EdgeYNegativeFromConcreteEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYNegativeFromProfileCenter: IPropertyMetaData = {
    id: 842,
    name: 'BaseMaterial_EdgeYNegativeFromProfileCenter',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYPositiveFromAnchorPlateEdge: IPropertyMetaData = {
    id: 849,
    name: 'BaseMaterial_EdgeYPositiveFromAnchorPlateEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYPositiveFromConcreteEdge: IPropertyMetaData = {
    id: 845,
    name: 'BaseMaterial_EdgeYPositiveFromConcreteEdge',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseMaterial_EdgeYPositiveFromProfileCenter: IPropertyMetaData = {
    id: 841,
    name: 'BaseMaterial_EdgeYPositiveFromProfileCenter',
    minValue: 0,
    maxValue: 15000,
    defaultValue: undefined,
};

const BaseplateThickness: IPropertyMetaData = {
    id: 3203,
    name: 'BaseplateThickness',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Checkbot_IOM_BeamsDescription: IPropertyMetaData = {
    id: 2943,
    name: 'Checkbot_IOM_BeamsDescription',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const ConstructionOptions_CorrosionResistant: IPropertyMetaData = {
    id: 2098,
    name: 'ConstructionOptions_CorrosionResistant',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const ConstructionOptions_GalvanizedSteel: IPropertyMetaData = {
    id: 2094,
    name: 'ConstructionOptions_GalvanizedSteel',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const ConstructionOptions_MechanicallyGalvanized: IPropertyMetaData = {
    id: 2096,
    name: 'ConstructionOptions_MechanicallyGalvanized',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const ConstructionOptions_Sheradized: IPropertyMetaData = {
    id: 2095,
    name: 'ConstructionOptions_Sheradized',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const ConstructionOptions_StainlessSteel: IPropertyMetaData = {
    id: 2097,
    name: 'ConstructionOptions_StainlessSteel',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_AreFactoredSustainedLoadsActive: IPropertyMetaData = {
    id: 1055,
    name: 'Loads_AreFactoredSustainedLoadsActive',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_AreLoadCombinationsActive: IPropertyMetaData = {
    id: 420,
    name: 'Loads_AreLoadCombinationsActive',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_CmdETABSImport: IPropertyMetaData = {
    id: 1072,
    name: 'Loads_CmdETABSImport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_CmdExport: IPropertyMetaData = {
    id: 756,
    name: 'Loads_CmdExport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_CmdImport: IPropertyMetaData = {
    id: 755,
    name: 'Loads_CmdImport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_CmdRobotImport: IPropertyMetaData = {
    id: 1066,
    name: 'Loads_CmdRobotImport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_CmdSAP2000Import: IPropertyMetaData = {
    id: 1063,
    name: 'Loads_CmdSAP2000Import',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_CmdStaadProImport: IPropertyMetaData = {
    id: 1080,
    name: 'Loads_CmdStaadProImport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_Combi_psi_0_h: IPropertyMetaData = {
    id: 818,
    name: 'Loads_Combi_psi_0_h',
    minValue: 0,
    maxValue: 1,
    defaultValue: undefined,
};

const Loads_Combi_psi_0_h_wind: IPropertyMetaData = {
    id: 820,
    name: 'Loads_Combi_psi_0_h_wind',
    minValue: 0,
    maxValue: 1,
    defaultValue: undefined,
};

const Loads_Combi_psi_0_v: IPropertyMetaData = {
    id: 819,
    name: 'Loads_Combi_psi_0_v',
    minValue: 0,
    maxValue: 1,
    defaultValue: undefined,
};

const Loads_CompressiveStress: IPropertyMetaData = {
    id: 604,
    name: 'Loads_CompressiveStress',
    minValue: 0,
    maxValue: 10,
    defaultValue: undefined,
};

const Loads_ConcreteEtagEnOnly: IPropertyMetaData = {
    id: 879,
    name: 'Loads_ConcreteEtagEnOnly',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_CustomizeDetails: IPropertyMetaData = {
    id: 652,
    name: 'Loads_CustomizeDetails',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_DeadLoad: IPropertyMetaData = {
    id: 641,
    name: 'Loads_DeadLoad',
    minValue: 0,
    maxValue: 20,
    defaultValue: undefined,
};

const Loads_DeleteLoad: IPropertyMetaData = {
    id: 2041,
    name: 'Loads_DeleteLoad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_DesignMethodHNA: IPropertyMetaData = {
    id: 477,
    name: 'Loads_DesignMethodHNA',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_DesignMethodHNA_Asad: IPropertyMetaData = {
    id: 2155,
    name: 'Loads_DesignMethodHNA_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_DesignMethodSelection: IPropertyMetaData = {
    id: 878,
    name: 'Loads_DesignMethodSelection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_DlubalImportExport: IPropertyMetaData = {
    id: 805,
    name: 'Loads_DlubalImportExport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_EmergencyExits: IPropertyMetaData = {
    id: 760,
    name: 'Loads_EmergencyExits',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_ETABSImportExport: IPropertyMetaData = {
    id: 1071,
    name: 'Loads_ETABSImportExport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_ExportRisa: IPropertyMetaData = {
    id: 1061,
    name: 'Loads_ExportRisa',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_FactoredSustainedLoadsFactor: IPropertyMetaData = {
    id: 1056,
    name: 'Loads_FactoredSustainedLoadsFactor',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: 1,
};

const Loads_FatigueLoadCycles: IPropertyMetaData = {
    id: 2266,
    name: 'Loads_FatigueLoadCycles',
    minValue: 1,
    maxValue: 100000000000,
    defaultValue: undefined,
};

const Loads_FatigueLoadType: IPropertyMetaData = {
    id: 708,
    name: 'Loads_FatigueLoadType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_FireDuration: IPropertyMetaData = {
    id: 416,
    name: 'Loads_FireDuration',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_FireExposure: IPropertyMetaData = {
    id: 413,
    name: 'Loads_FireExposure',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_FlatBarResistance: IPropertyMetaData = {
    id: 1100,
    name: 'Loads_FlatBarResistance',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_ForceX: IPropertyMetaData = {
    id: 400,
    name: 'Loads_ForceX',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_ForceXVariable: IPropertyMetaData = {
    id: 406,
    name: 'Loads_ForceXVariable',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_ForceY: IPropertyMetaData = {
    id: 401,
    name: 'Loads_ForceY',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_ForceYVariable: IPropertyMetaData = {
    id: 407,
    name: 'Loads_ForceYVariable',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_ForceZ: IPropertyMetaData = {
    id: 402,
    name: 'Loads_ForceZ',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_ForceZSustained: IPropertyMetaData = {
    id: 1067,
    name: 'Loads_ForceZSustained',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_ForceZVariable: IPropertyMetaData = {
    id: 408,
    name: 'Loads_ForceZVariable',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_FundamentalWindVelocity: IPropertyMetaData = {
    id: 871,
    name: 'Loads_FundamentalWindVelocity',
    minValue: 24,
    maxValue: 27,
    defaultValue: undefined,
};

const Loads_Gamma_perm_inf: IPropertyMetaData = {
    id: 654,
    name: 'Loads_Gamma_perm_inf',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_Gamma_perm_sup: IPropertyMetaData = {
    id: 653,
    name: 'Loads_Gamma_perm_sup',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_Gamma_var_h_bridge: IPropertyMetaData = {
    id: 657,
    name: 'Loads_Gamma_var_h_bridge',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_Gamma_var_h_building: IPropertyMetaData = {
    id: 655,
    name: 'Loads_Gamma_var_h_building',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_Gamma_var_h_wind: IPropertyMetaData = {
    id: 659,
    name: 'Loads_Gamma_var_h_wind',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_Gamma_var_h_wind_bridge: IPropertyMetaData = {
    id: 761,
    name: 'Loads_Gamma_var_h_wind_bridge',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_Gamma_var_v_bridge: IPropertyMetaData = {
    id: 658,
    name: 'Loads_Gamma_var_v_bridge',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_Gamma_var_v_building: IPropertyMetaData = {
    id: 656,
    name: 'Loads_Gamma_var_v_building',
    minValue: 1,
    maxValue: 2,
    defaultValue: undefined,
};

const Loads_GammaZero: IPropertyMetaData = {
    id: 2147,
    name: 'Loads_GammaZero',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: 1.2,
};

const Loads_HandrailDesignMethodSelection: IPropertyMetaData = {
    id: 1059,
    name: 'Loads_HandrailDesignMethodSelection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_HandrailEtagEnOnly: IPropertyMetaData = {
    id: 1060,
    name: 'Loads_HandrailEtagEnOnly',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_HandrailMoreInfoOnSOFA: IPropertyMetaData = {
    id: 2160,
    name: 'Loads_HandrailMoreInfoOnSOFA',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_HandrailSafetyClass : IPropertyMetaData = {
    id: 1167,
    name: 'Loads_HandrailSafetyClass ',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_HandrailStandOffDesignMethodSelection: IPropertyMetaData = {
    id: 2157,
    name: 'Loads_HandrailStandOffDesignMethodSelection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_ImportLoadCombinations: IPropertyMetaData = {
    id: 518,
    name: 'Loads_ImportLoadCombinations',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_InfillLoadInwards: IPropertyMetaData = {
    id: 855,
    name: 'Loads_InfillLoadInwards',
    minValue: 0,
    maxValue: 10,
    defaultValue: undefined,
};

const Loads_InfillLoadOutwards: IPropertyMetaData = {
    id: 854,
    name: 'Loads_InfillLoadOutwards',
    minValue: 0,
    maxValue: 10,
    defaultValue: undefined,
};

const Loads_InfillPunctualLoadInwards: IPropertyMetaData = {
    id: 1090,
    name: 'Loads_InfillPunctualLoadInwards',
    minValue: 0,
    maxValue: 3000,
    defaultValue: undefined,
};

const Loads_InfillPunctualLoadOutwards: IPropertyMetaData = {
    id: 1089,
    name: 'Loads_InfillPunctualLoadOutwards',
    minValue: 0,
    maxValue: 3000,
    defaultValue: undefined,
};

const Loads_InputHandrailLoads: IPropertyMetaData = {
    id: 651,
    name: 'Loads_InputHandrailLoads',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_IsBridge: IPropertyMetaData = {
    id: 834,
    name: 'Loads_IsBridge',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_IsFatigueExpertMode: IPropertyMetaData = {
    id: 2005,
    name: 'Loads_IsFatigueExpertMode',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_LinearLoadInwards: IPropertyMetaData = {
    id: 645,
    name: 'Loads_LinearLoadInwards',
    minValue: 0,
    maxValue: 4,
    defaultValue: undefined,
};

const Loads_LinearLoadInwardsHeight: IPropertyMetaData = {
    id: 647,
    name: 'Loads_LinearLoadInwardsHeight',
    minValue: 0,
    maxValue: 100000,
    defaultValue: undefined,
};

const Loads_LinearLoadOutwards: IPropertyMetaData = {
    id: 644,
    name: 'Loads_LinearLoadOutwards',
    minValue: 0,
    maxValue: 4,
    defaultValue: undefined,
};

const Loads_LinearLoadOutwardsHeight: IPropertyMetaData = {
    id: 646,
    name: 'Loads_LinearLoadOutwardsHeight',
    minValue: 0,
    maxValue: 100000,
    defaultValue: undefined,
};

const Loads_LoadCombinationHNAWizard: IPropertyMetaData = {
    id: 838,
    name: 'Loads_LoadCombinationHNAWizard',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_LoadCombinations: IPropertyMetaData = {
    id: 513,
    name: 'Loads_LoadCombinations',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_LoadCombinationsCalculationType: IPropertyMetaData = {
    id: 780,
    name: 'Loads_LoadCombinationsCalculationType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_LoadsPositionZ: IPropertyMetaData = {
    id: 2087,
    name: 'Loads_LoadsPositionZ',
    minValue: 0,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_LoadType: IPropertyMetaData = {
    id: 734,
    name: 'Loads_LoadType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_MomentX: IPropertyMetaData = {
    id: 403,
    name: 'Loads_MomentX',
    minValue: -1000000000000,
    maxValue: 1000000000000,
    defaultValue: undefined,
};

const Loads_MomentXSustained: IPropertyMetaData = {
    id: 1068,
    name: 'Loads_MomentXSustained',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_MomentXVariable: IPropertyMetaData = {
    id: 409,
    name: 'Loads_MomentXVariable',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_MomentY: IPropertyMetaData = {
    id: 404,
    name: 'Loads_MomentY',
    minValue: -1000000000000,
    maxValue: 1000000000000,
    defaultValue: undefined,
};

const Loads_MomentYSustained: IPropertyMetaData = {
    id: 1069,
    name: 'Loads_MomentYSustained',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_MomentYVariable: IPropertyMetaData = {
    id: 410,
    name: 'Loads_MomentYVariable',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_MomentZ: IPropertyMetaData = {
    id: 405,
    name: 'Loads_MomentZ',
    minValue: -1000000000000,
    maxValue: 1000000000000,
    defaultValue: undefined,
};

const Loads_MomentZVariable: IPropertyMetaData = {
    id: 411,
    name: 'Loads_MomentZVariable',
    minValue: -1000000000,
    maxValue: 1000000000,
    defaultValue: undefined,
};

const Loads_MoreInfoOnFatigue: IPropertyMetaData = {
    id: 2265,
    name: 'Loads_MoreInfoOnFatigue',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_MoreInfoOnFire: IPropertyMetaData = {
    id: 3229,
    name: 'Loads_MoreInfoOnFire',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_MoreInfoOnSOFA: IPropertyMetaData = {
    id: 2159,
    name: 'Loads_MoreInfoOnSOFA',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_NewAndImportLoad: IPropertyMetaData = {
    id: 2015,
    name: 'Loads_NewAndImportLoad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_OnSiteTestsValues: IPropertyMetaData = {
    id: 859,
    name: 'Loads_OnSiteTestsValues',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_OnSiteTestsValues_N_Rk: IPropertyMetaData = {
    id: 860,
    name: 'Loads_OnSiteTestsValues_N_Rk',
    minValue: 0,
    maxValue: 10000000000,
    defaultValue: undefined,
};

const Loads_OnSiteTestsValues_V_Rk: IPropertyMetaData = {
    id: 861,
    name: 'Loads_OnSiteTestsValues_V_Rk',
    minValue: 0,
    maxValue: 10000000000,
    defaultValue: undefined,
};

const Loads_OnSiteTestType: IPropertyMetaData = {
    id: 862,
    name: 'Loads_OnSiteTestType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_PhiNonductile: IPropertyMetaData = {
    id: 466,
    name: 'Loads_PhiNonductile',
    minValue: 0.4,
    maxValue: 1,
    defaultValue: undefined,
};

const Loads_PunctualLoadInwards: IPropertyMetaData = {
    id: 649,
    name: 'Loads_PunctualLoadInwards',
    minValue: 0,
    maxValue: 2000,
    defaultValue: undefined,
};

const Loads_PunctualLoadInwardsHeight: IPropertyMetaData = {
    id: 753,
    name: 'Loads_PunctualLoadInwardsHeight',
    minValue: 0,
    maxValue: 100000,
    defaultValue: undefined,
};

const Loads_PunctualLoadInWorstPosition: IPropertyMetaData = {
    id: 857,
    name: 'Loads_PunctualLoadInWorstPosition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_PunctualLoadOutwards: IPropertyMetaData = {
    id: 648,
    name: 'Loads_PunctualLoadOutwards',
    minValue: 0,
    maxValue: 2000,
    defaultValue: undefined,
};

const Loads_PunctualLoadOutwardsHeight: IPropertyMetaData = {
    id: 752,
    name: 'Loads_PunctualLoadOutwardsHeight',
    minValue: 0,
    maxValue: 100000,
    defaultValue: undefined,
};

const Loads_PunctualLoadRailInwards: IPropertyMetaData = {
    id: 876,
    name: 'Loads_PunctualLoadRailInwards',
    minValue: 0,
    maxValue: 2000,
    defaultValue: undefined,
};

const Loads_PunctualLoadRailOutwards: IPropertyMetaData = {
    id: 875,
    name: 'Loads_PunctualLoadRailOutwards',
    minValue: 0,
    maxValue: 2000,
    defaultValue: undefined,
};

const Loads_RobotImportExport: IPropertyMetaData = {
    id: 1065,
    name: 'Loads_RobotImportExport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SafetyLevel: IPropertyMetaData = {
    id: 2146,
    name: 'Loads_SafetyLevel',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SAP2000ImportExport: IPropertyMetaData = {
    id: 1064,
    name: 'Loads_SAP2000ImportExport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SeismicCategory: IPropertyMetaData = {
    id: 419,
    name: 'Loads_SeismicCategory',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SeismicDesignType: IPropertyMetaData = {
    id: 412,
    name: 'Loads_SeismicDesignType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SeismicDisplacementsType: IPropertyMetaData = {
    id: 511,
    name: 'Loads_SeismicDisplacementsType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SeismicDLSShear: IPropertyMetaData = {
    id: 415,
    name: 'Loads_SeismicDLSShear',
    minValue: 0.1,
    maxValue: 10000,
    defaultValue: undefined,
};

const Loads_SeismicDLSTension: IPropertyMetaData = {
    id: 414,
    name: 'Loads_SeismicDLSTension',
    minValue: 0.1,
    maxValue: 10000,
    defaultValue: undefined,
};

const Loads_SeismicIntensityFactor: IPropertyMetaData = {
    id: 2144,
    name: 'Loads_SeismicIntensityFactor',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SeismicLoadContribution: IPropertyMetaData = {
    id: 417,
    name: 'Loads_SeismicLoadContribution',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SeismicShearType: IPropertyMetaData = {
    id: 468,
    name: 'Loads_SeismicShearType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SeismicTensionType: IPropertyMetaData = {
    id: 467,
    name: 'Loads_SeismicTensionType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_SelectedLoadCombination: IPropertyMetaData = {
    id: 514,
    name: 'Loads_SelectedLoadCombination',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_ShearLoadDistributionType: IPropertyMetaData = {
    id: 2267,
    name: 'Loads_ShearLoadDistributionType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_ShowAllLoads: IPropertyMetaData = {
    id: 710,
    name: 'Loads_ShowAllLoads',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_StaadProImportExport: IPropertyMetaData = {
    id: 1079,
    name: 'Loads_StaadProImportExport',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_StandOffDesignMethodSelection: IPropertyMetaData = {
    id: 2156,
    name: 'Loads_StandOffDesignMethodSelection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_StaticLoadType: IPropertyMetaData = {
    id: 418,
    name: 'Loads_StaticLoadType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_StaticShearType: IPropertyMetaData = {
    id: 731,
    name: 'Loads_StaticShearType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_StaticTensionType: IPropertyMetaData = {
    id: 730,
    name: 'Loads_StaticTensionType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_StructuralMember: IPropertyMetaData = {
    id: 2145,
    name: 'Loads_StructuralMember',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_UseLoadCombinations_SE: IPropertyMetaData = {
    id: 1166,
    name: 'Loads_UseLoadCombinations_SE',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_UseOnSiteTestsData: IPropertyMetaData = {
    id: 858,
    name: 'Loads_UseOnSiteTestsData',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Loads_VerticalLoad: IPropertyMetaData = {
    id: 650,
    name: 'Loads_VerticalLoad',
    minValue: 0,
    maxValue: 1,
    defaultValue: undefined,
};

const Loads_VerticalPunctualLoad: IPropertyMetaData = {
    id: 856,
    name: 'Loads_VerticalPunctualLoad',
    minValue: 0,
    maxValue: 2000,
    defaultValue: undefined,
};

const Loads_VerticalPunctualLoadInWorstPosition: IPropertyMetaData = {
    id: 853,
    name: 'Loads_VerticalPunctualLoadInWorstPosition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_AnchorLayout: IPropertyMetaData = {
    id: 534,
    name: 'Optimize_AnchorLayout',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_AnchorOffset: IPropertyMetaData = {
    id: 812,
    name: 'Optimize_AnchorOffset',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_AnchorPlatePosition: IPropertyMetaData = {
    id: 521,
    name: 'Optimize_AnchorPlatePosition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_AnchorPlateSize: IPropertyMetaData = {
    id: 520,
    name: 'Optimize_AnchorPlateSize',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_AxialSpacing: IPropertyMetaData = {
    id: 522,
    name: 'Optimize_AxialSpacing',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_BaseMaterialThickness: IPropertyMetaData = {
    id: 523,
    name: 'Optimize_BaseMaterialThickness',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_BaseplateSize: IPropertyMetaData = {
    id: 528,
    name: 'Optimize_BaseplateSize',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_EdgeDistance: IPropertyMetaData = {
    id: 524,
    name: 'Optimize_EdgeDistance',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_FindSolution: IPropertyMetaData = {
    id: 833,
    name: 'Optimize_FindSolution',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Optimize_PostDistance: IPropertyMetaData = {
    id: 810,
    name: 'Optimize_PostDistance',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const OptimizeOnEmbedmentDepth: IPropertyMetaData = {
    id: 3204,
    name: 'OptimizeOnEmbedmentDepth',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_AnchorPlateFactor: IPropertyMetaData = {
    id: 769,
    name: 'Option_AnchorPlateFactor',
    minValue: 0,
    maxValue: 2,
    defaultValue: undefined,
};

const Option_ConcreteCapacityFactor: IPropertyMetaData = {
    id: 1074,
    name: 'Option_ConcreteCapacityFactor',
    minValue: 0.1,
    maxValue: 1,
    defaultValue: 0.6,
};

const Option_ConcreteSafetyFactorGammaC: IPropertyMetaData = {
    id: 2014,
    name: 'Option_ConcreteSafetyFactorGammaC',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: 1,
};

const Option_CoordinateSystemCenter: IPropertyMetaData = {
    id: 795,
    name: 'Option_CoordinateSystemCenter',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_CoordinateSystemCenterX: IPropertyMetaData = {
    id: 796,
    name: 'Option_CoordinateSystemCenterX',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_CoordinateSystemCenterY: IPropertyMetaData = {
    id: 797,
    name: 'Option_CoordinateSystemCenterY',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_CustomPictures: IPropertyMetaData = {
    id: 2158,
    name: 'Option_CustomPictures',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_DesignMethodGroupId: IPropertyMetaData = {
    id: 526,
    name: 'Option_DesignMethodGroupId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_DesignMethodGroupId_Asad: IPropertyMetaData = {
    id: 3230,
    name: 'Option_DesignMethodGroupId_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_DesignName: IPropertyMetaData = {
    id: 434,
    name: 'Option_DesignName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_DesignStandard: IPropertyMetaData = {
    id: 444,
    name: 'Option_DesignStandard',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_EdgeDistanceDisplayType: IPropertyMetaData = {
    id: 781,
    name: 'Option_EdgeDistanceDisplayType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ETAGOnly: IPropertyMetaData = {
    id: 432,
    name: 'Option_ETAGOnly',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_FaxNumber: IPropertyMetaData = {
    id: 474,
    name: 'Option_FaxNumber',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_HandrailDisplacementLimit: IPropertyMetaData = {
    id: 832,
    name: 'Option_HandrailDisplacementLimit',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_HandrailDisplacementLimitBridges: IPropertyMetaData = {
    id: 1106,
    name: 'Option_HandrailDisplacementLimitBridges',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_INSteelSafetyFactorGammaM0: IPropertyMetaData = {
    id: 2008,
    name: 'Option_INSteelSafetyFactorGammaM0',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: 1.1,
};

const Option_INSteelSafetyFactorGammaM1: IPropertyMetaData = {
    id: 2009,
    name: 'Option_INSteelSafetyFactorGammaM1',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: 1.25,
};

const Option_INSteelSafetyFactorGammaMb: IPropertyMetaData = {
    id: 2011,
    name: 'Option_INSteelSafetyFactorGammaMb',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: 1.25,
};

const Option_INSteelSafetyFactorGammaMw: IPropertyMetaData = {
    id: 2010,
    name: 'Option_INSteelSafetyFactorGammaMw',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: 1.25,
};

const Option_MaterialSafetyFactor: IPropertyMetaData = {
    id: 1049,
    name: 'Option_MaterialSafetyFactor',
    minValue: 0.1,
    maxValue: 100,
    defaultValue: 1,
};

const Option_MeasureAnchorPlate: IPropertyMetaData = {
    id: 3208,
    name: 'Option_MeasureAnchorPlate',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_MeasureBaseMaterialEdgeFrom: IPropertyMetaData = {
    id: 3209,
    name: 'Option_MeasureBaseMaterialEdgeFrom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_MinimumAnchorToProfileDistance: IPropertyMetaData = {
    id: 806,
    name: 'Option_MinimumAnchorToProfileDistance',
    minValue: 0,
    maxValue: 100000,
    defaultValue: undefined,
};

const Option_MinimumConcreteCover: IPropertyMetaData = {
    id: 2040,
    name: 'Option_MinimumConcreteCover',
    minValue: 10,
    maxValue: 60,
    defaultValue: 25,
};

const Option_NoteCombinedTensionAndShearLoad: IPropertyMetaData = {
    id: 802,
    name: 'Option_NoteCombinedTensionAndShearLoad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_NoteDisplacements: IPropertyMetaData = {
    id: 803,
    name: 'Option_NoteDisplacements',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_NoteInstallationData: IPropertyMetaData = {
    id: 804,
    name: 'Option_NoteInstallationData',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_NoteLoadCaseResultingAnchorForces: IPropertyMetaData = {
    id: 799,
    name: 'Option_NoteLoadCaseResultingAnchorForces',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_NoteShearLoad: IPropertyMetaData = {
    id: 801,
    name: 'Option_NoteShearLoad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_NoteTensionLoad: IPropertyMetaData = {
    id: 800,
    name: 'Option_NoteTensionLoad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_OstInputsEnabled: IPropertyMetaData = {
    id: 1051,
    name: 'Option_OstInputsEnabled',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_RegionId: IPropertyMetaData = {
    id: 443,
    name: 'Option_RegionId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportAddress: IPropertyMetaData = {
    id: 472,
    name: 'Option_ReportAddress',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportCompanyName: IPropertyMetaData = {
    id: 471,
    name: 'Option_ReportCompanyName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportContactPerson: IPropertyMetaData = {
    id: 470,
    name: 'Option_ReportContactPerson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportEmail: IPropertyMetaData = {
    id: 475,
    name: 'Option_ReportEmail',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportFasteningPoint: IPropertyMetaData = {
    id: 469,
    name: 'Option_ReportFasteningPoint',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportFirstPage: IPropertyMetaData = {
    id: 463,
    name: 'Option_ReportFirstPage',
    minValue: 1,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportLanguageLcid: IPropertyMetaData = {
    id: 462,
    name: 'Option_ReportLanguageLcid',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportLoadCombination: IPropertyMetaData = {
    id: 608,
    name: 'Option_ReportLoadCombination',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportNotes: IPropertyMetaData = {
    id: 465,
    name: 'Option_ReportNotes',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportPaperSizeId: IPropertyMetaData = {
    id: 460,
    name: 'Option_ReportPaperSizeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportPhoneNumber: IPropertyMetaData = {
    id: 473,
    name: 'Option_ReportPhoneNumber',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportTemplateId: IPropertyMetaData = {
    id: 464,
    name: 'Option_ReportTemplateId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_ReportTypeId: IPropertyMetaData = {
    id: 461,
    name: 'Option_ReportTypeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_SafetyFactorForPermamentLoads: IPropertyMetaData = {
    id: 770,
    name: 'Option_SafetyFactorForPermamentLoads',
    minValue: 0,
    maxValue: 2,
    defaultValue: undefined,
};

const Option_SafetyFactorForVariableLoads: IPropertyMetaData = {
    id: 771,
    name: 'Option_SafetyFactorForVariableLoads',
    minValue: 0,
    maxValue: 2,
    defaultValue: undefined,
};

const Option_StainlessSteel: IPropertyMetaData = {
    id: 817,
    name: 'Option_StainlessSteel',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_SteelCapacityFactor: IPropertyMetaData = {
    id: 1073,
    name: 'Option_SteelCapacityFactor',
    minValue: 0.1,
    maxValue: 1,
    defaultValue: 0.9,
};

const Option_SteelGuideline: IPropertyMetaData = {
    id: 2007,
    name: 'Option_SteelGuideline',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_SteelSafetyFactorGammaM0: IPropertyMetaData = {
    id: 814,
    name: 'Option_SteelSafetyFactorGammaM0',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: undefined,
};

const Option_SteelSafetyFactorGammaM1: IPropertyMetaData = {
    id: 815,
    name: 'Option_SteelSafetyFactorGammaM1',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: undefined,
};

const Option_SteelSafetyFactorGammaM2: IPropertyMetaData = {
    id: 816,
    name: 'Option_SteelSafetyFactorGammaM2',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: undefined,
};

const Option_SteelSafetyFactorGammaM5: IPropertyMetaData = {
    id: 870,
    name: 'Option_SteelSafetyFactorGammaM5',
    minValue: 1,
    maxValue: 1.5,
    defaultValue: undefined,
};

const Option_STOSteelSafetyFactorGammaC: IPropertyMetaData = {
    id: 1083,
    name: 'Option_STOSteelSafetyFactorGammaC',
    minValue: 1,
    maxValue: 1.1,
    defaultValue: 1,
};

const Option_TrimbleConnectFolderId: IPropertyMetaData = {
    id: 794,
    name: 'Option_TrimbleConnectFolderId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_TrimbleConnectLocation: IPropertyMetaData = {
    id: 792,
    name: 'Option_TrimbleConnectLocation',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_TrimbleConnectReportName: IPropertyMetaData = {
    id: 793,
    name: 'Option_TrimbleConnectReportName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_TrimbleConnectUpload: IPropertyMetaData = {
    id: 791,
    name: 'Option_TrimbleConnectUpload',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitArea: IPropertyMetaData = {
    id: 438,
    name: 'Option_UnitArea',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitAreaPerLength: IPropertyMetaData = {
    id: 2044,
    name: 'Option_UnitAreaPerLength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitDensity: IPropertyMetaData = {
    id: 1107,
    name: 'Option_UnitDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitForce: IPropertyMetaData = {
    id: 440,
    name: 'Option_UnitForce',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitForcePerLength: IPropertyMetaData = {
    id: 677,
    name: 'Option_UnitForcePerLength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitLength: IPropertyMetaData = {
    id: 437,
    name: 'Option_UnitLength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitLengthLarge: IPropertyMetaData = {
    id: 767,
    name: 'Option_UnitLengthLarge',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitMoment: IPropertyMetaData = {
    id: 441,
    name: 'Option_UnitMoment',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitMomentPerLength: IPropertyMetaData = {
    id: 1078,
    name: 'Option_UnitMomentPerLength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitStress: IPropertyMetaData = {
    id: 439,
    name: 'Option_UnitStress',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitStressSmall: IPropertyMetaData = {
    id: 768,
    name: 'Option_UnitStressSmall',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_UnitTemperature: IPropertyMetaData = {
    id: 442,
    name: 'Option_UnitTemperature',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Option_WeldsCapacityFactor: IPropertyMetaData = {
    id: 1070,
    name: 'Option_WeldsCapacityFactor',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_Angle: IPropertyMetaData = {
    id: 308,
    name: 'Profile_Angle',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_CmdEccentricityRotateAnticlockwise: IPropertyMetaData = {
    id: 733,
    name: 'Profile_CmdEccentricityRotateAnticlockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_CmdEccentricityRotateClockwise: IPropertyMetaData = {
    id: 732,
    name: 'Profile_CmdEccentricityRotateClockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_CmdEccentricityRotatePostAnticlockwise: IPropertyMetaData = {
    id: 777,
    name: 'Profile_CmdEccentricityRotatePostAnticlockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_CmdEccentricityRotatePostClockwise: IPropertyMetaData = {
    id: 776,
    name: 'Profile_CmdEccentricityRotatePostClockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_CustomFlangeThickness: IPropertyMetaData = {
    id: 304,
    name: 'Profile_CustomFlangeThickness',
    minValue: 0.1,
    maxValue: 1000,
    defaultValue: undefined,
};

const Profile_CustomFlangeThickness_Asad: IPropertyMetaData = {
    id: 2165,
    name: 'Profile_CustomFlangeThickness_Asad',
    minValue: 0.1,
    maxValue: 1000,
    defaultValue: undefined,
};

const Profile_CustomHeight: IPropertyMetaData = {
    id: 302,
    name: 'Profile_CustomHeight',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const Profile_CustomHeight_Asad: IPropertyMetaData = {
    id: 2163,
    name: 'Profile_CustomHeight_Asad',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const Profile_CustomProfileMaterial: IPropertyMetaData = {
    id: 1108,
    name: 'Profile_CustomProfileMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_CustomThickness: IPropertyMetaData = {
    id: 307,
    name: 'Profile_CustomThickness',
    minValue: 0.1,
    maxValue: 1000,
    defaultValue: undefined,
};

const Profile_CustomThickness_Asad: IPropertyMetaData = {
    id: 2166,
    name: 'Profile_CustomThickness_Asad',
    minValue: 0.1,
    maxValue: 1000,
    defaultValue: undefined,
};

const Profile_CustomWidth: IPropertyMetaData = {
    id: 303,
    name: 'Profile_CustomWidth',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const Profile_CustomWidth_Asad: IPropertyMetaData = {
    id: 2164,
    name: 'Profile_CustomWidth_Asad',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const Profile_CustomWidth2: IPropertyMetaData = {
    id: 1081,
    name: 'Profile_CustomWidth2',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const Profile_DoubleFlatBarCustomWidth: IPropertyMetaData = {
    id: 3206,
    name: 'Profile_DoubleFlatBarCustomWidth',
    minValue: 0.3,
    maxValue: 100,
    defaultValue: undefined,
};

const Profile_FlangeWeldThickness: IPropertyMetaData = {
    id: 705,
    name: 'Profile_FlangeWeldThickness',
    minValue: 0,
    maxValue: 12,
    defaultValue: undefined,
};

const Profile_HandrailPostAngle: IPropertyMetaData = {
    id: 775,
    name: 'Profile_HandrailPostAngle',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailPostProfileFamilyId: IPropertyMetaData = {
    id: 746,
    name: 'Profile_HandrailPostProfileFamilyId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailRailAngle: IPropertyMetaData = {
    id: 772,
    name: 'Profile_HandrailRailAngle',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailRailCmdEccentricityRotateAnticlockwise: IPropertyMetaData = {
    id: 774,
    name: 'Profile_HandrailRailCmdEccentricityRotateAnticlockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailRailCmdEccentricityRotateClockwise: IPropertyMetaData = {
    id: 773,
    name: 'Profile_HandrailRailCmdEccentricityRotateClockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailRailProfileFamilyId: IPropertyMetaData = {
    id: 742,
    name: 'Profile_HandrailRailProfileFamilyId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailRailProfileSizeId: IPropertyMetaData = {
    id: 744,
    name: 'Profile_HandrailRailProfileSizeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailRailProfileSizeName: IPropertyMetaData = {
    id: 745,
    name: 'Profile_HandrailRailProfileSizeName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailRailProfileSteelType: IPropertyMetaData = {
    id: 747,
    name: 'Profile_HandrailRailProfileSteelType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HandrailSafetyDesign: IPropertyMetaData = {
    id: 754,
    name: 'Profile_HandrailSafetyDesign',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostMaterialDensity: IPropertyMetaData = {
    id: 1160,
    name: 'Profile_HorizontalPostMaterialDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostMaterialEModulus: IPropertyMetaData = {
    id: 1159,
    name: 'Profile_HorizontalPostMaterialEModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostMaterialPoisson: IPropertyMetaData = {
    id: 1161,
    name: 'Profile_HorizontalPostMaterialPoisson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostMaterialUltimateStrength: IPropertyMetaData = {
    id: 1157,
    name: 'Profile_HorizontalPostMaterialUltimateStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostMaterialYieldStrength: IPropertyMetaData = {
    id: 1158,
    name: 'Profile_HorizontalPostMaterialYieldStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_IsProfileMaterialCustom: IPropertyMetaData = {
    id: 1111,
    name: 'Profile_IsProfileMaterialCustom',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_MaterialDensity: IPropertyMetaData = {
    id: 1117,
    name: 'Profile_MaterialDensity',
    minValue: 1000,
    maxValue: 10000,
    defaultValue: 7850,
};

const Profile_MaterialEModulus: IPropertyMetaData = {
    id: 1116,
    name: 'Profile_MaterialEModulus',
    minValue: 100000,
    maxValue: 1000000,
    defaultValue: 200000,
};

const Profile_MaterialPoisson: IPropertyMetaData = {
    id: 1118,
    name: 'Profile_MaterialPoisson',
    minValue: 0.1,
    maxValue: 0.5,
    defaultValue: 0.2,
};

const Profile_MaterialUltimateStrength: IPropertyMetaData = {
    id: 1114,
    name: 'Profile_MaterialUltimateStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 235,
};

const Profile_MaterialYieldStrength: IPropertyMetaData = {
    id: 1115,
    name: 'Profile_MaterialYieldStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 360,
};

const Profile_OffsetX: IPropertyMetaData = {
    id: 305,
    name: 'Profile_OffsetX',
    minValue: -10000,
    maxValue: 10000,
    defaultValue: undefined,
};

const Profile_OffsetY: IPropertyMetaData = {
    id: 306,
    name: 'Profile_OffsetY',
    minValue: -10000,
    maxValue: 10000,
    defaultValue: undefined,
};

const Profile_PostMaterialDensity: IPropertyMetaData = {
    id: 1155,
    name: 'Profile_PostMaterialDensity',
    minValue: 1000,
    maxValue: 10000,
    defaultValue: 7850,
};

const Profile_PostMaterialEModulus: IPropertyMetaData = {
    id: 1154,
    name: 'Profile_PostMaterialEModulus',
    minValue: 100000,
    maxValue: 1000000,
    defaultValue: 200000,
};

const Profile_PostMaterialPoisson: IPropertyMetaData = {
    id: 1156,
    name: 'Profile_PostMaterialPoisson',
    minValue: 0.1,
    maxValue: 0.5,
    defaultValue: 0.2,
};

const Profile_PostMaterialUltimateStrength: IPropertyMetaData = {
    id: 1152,
    name: 'Profile_PostMaterialUltimateStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 235,
};

const Profile_PostMaterialYieldStrength: IPropertyMetaData = {
    id: 1153,
    name: 'Profile_PostMaterialYieldStrength',
    minValue: 100,
    maxValue: 1000,
    defaultValue: 360,
};

const Profile_ProfileFamilyId: IPropertyMetaData = {
    id: 300,
    name: 'Profile_ProfileFamilyId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ProfileFamilyId_Asad: IPropertyMetaData = {
    id: 2121,
    name: 'Profile_ProfileFamilyId_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ProfileSizeId: IPropertyMetaData = {
    id: 301,
    name: 'Profile_ProfileSizeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ProfileSizeId_Asad: IPropertyMetaData = {
    id: 2122,
    name: 'Profile_ProfileSizeId_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ProfileSizeName: IPropertyMetaData = {
    id: 320,
    name: 'Profile_ProfileSizeName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_RailMaterialDensity: IPropertyMetaData = {
    id: 1150,
    name: 'Profile_RailMaterialDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_RailMaterialEModulus: IPropertyMetaData = {
    id: 1149,
    name: 'Profile_RailMaterialEModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_RailMaterialPoisson: IPropertyMetaData = {
    id: 1151,
    name: 'Profile_RailMaterialPoisson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_RailMaterialUltimateStrength: IPropertyMetaData = {
    id: 1147,
    name: 'Profile_RailMaterialUltimateStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_RailMaterialYieldStrength: IPropertyMetaData = {
    id: 1148,
    name: 'Profile_RailMaterialYieldStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ShearLugAngle: IPropertyMetaData = {
    id: 3218,
    name: 'Profile_ShearLugAngle',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ShearLugCmdRotateAnticlockwise: IPropertyMetaData = {
    id: 3220,
    name: 'Profile_ShearLugCmdRotateAnticlockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ShearLugCmdRotateClockwise: IPropertyMetaData = {
    id: 3219,
    name: 'Profile_ShearLugCmdRotateClockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ShearLugCustomDepth: IPropertyMetaData = {
    id: 3215,
    name: 'Profile_ShearLugCustomDepth',
    minValue: 0,
    maxValue: 1270,
    defaultValue: undefined,
};

const Profile_ShearLugCustomLength: IPropertyMetaData = {
    id: 3212,
    name: 'Profile_ShearLugCustomLength',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const Profile_ShearLugCustomThickness: IPropertyMetaData = {
    id: 3214,
    name: 'Profile_ShearLugCustomThickness',
    minValue: 0.1,
    maxValue: 1000,
    defaultValue: undefined,
};

const Profile_ShearLugCustomWidth: IPropertyMetaData = {
    id: 3213,
    name: 'Profile_ShearLugCustomWidth',
    minValue: 0.3,
    maxValue: 5000,
    defaultValue: undefined,
};

const Profile_ShearLugFamilyId: IPropertyMetaData = {
    id: 3211,
    name: 'Profile_ShearLugFamilyId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ShearLugSizeId: IPropertyMetaData = {
    id: 3216,
    name: 'Profile_ShearLugSizeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ShearLugSizeName: IPropertyMetaData = {
    id: 3217,
    name: 'Profile_ShearLugSizeName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_ShowWeldDesign: IPropertyMetaData = {
    id: 701,
    name: 'Profile_ShowWeldDesign',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_SteelType: IPropertyMetaData = {
    id: 702,
    name: 'Profile_SteelType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_UseProfile: IPropertyMetaData = {
    id: 741,
    name: 'Profile_UseProfile',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_UseShearLug: IPropertyMetaData = {
    id: 3210,
    name: 'Profile_UseShearLug',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_Utilization: IPropertyMetaData = {
    id: 704,
    name: 'Profile_Utilization',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const Profile_WebWeldLocation: IPropertyMetaData = {
    id: 2136,
    name: 'Profile_WebWeldLocation',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldMaterial: IPropertyMetaData = {
    id: 2137,
    name: 'Profile_WebWeldMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldMaterialBetaW: IPropertyMetaData = {
    id: 2143,
    name: 'Profile_WebWeldMaterialBetaW',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldMaterialDensity: IPropertyMetaData = {
    id: 2141,
    name: 'Profile_WebWeldMaterialDensity',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldMaterialEModulus: IPropertyMetaData = {
    id: 2140,
    name: 'Profile_WebWeldMaterialEModulus',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldMaterialPoisson: IPropertyMetaData = {
    id: 2142,
    name: 'Profile_WebWeldMaterialPoisson',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldMaterialUltimateStrength: IPropertyMetaData = {
    id: 2138,
    name: 'Profile_WebWeldMaterialUltimateStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldMaterialYieldStrength: IPropertyMetaData = {
    id: 2139,
    name: 'Profile_WebWeldMaterialYieldStrength',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_WebWeldThickness: IPropertyMetaData = {
    id: 706,
    name: 'Profile_WebWeldThickness',
    minValue: 0,
    maxValue: 12,
    defaultValue: undefined,
};

const Profile_HorizontalPostProfileConnection: IPropertyMetaData = {
    id: 864,
    name: 'Profile_HorizontalPostProfileConnection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostProfileFamilyId: IPropertyMetaData = {
    id: 868,
    name: 'Profile_HorizontalPostProfileFamilyId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostProfileSizeId: IPropertyMetaData = {
    id: 865,
    name: 'Profile_HorizontalPostProfileSizeId',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostProfileSizeName: IPropertyMetaData = {
    id: 867,
    name: 'Profile_HorizontalPostProfileSizeName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_HorizontalPostSteelTypeHandrail: IPropertyMetaData = {
    id: 866,
    name: 'Profile_HorizontalPostSteelTypeHandrail',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_IsHorizontalPostProfilePresent: IPropertyMetaData = {
    id: 863,
    name: 'Profile_IsHorizontalPostProfilePresent',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_SteelTypeHandrail: IPropertyMetaData = {
    id: 750,
    name: 'Profile_SteelTypeHandrail',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Profile_UsePinConnection: IPropertyMetaData = {
    id: 1093,
    name: 'Profile_UsePinConnection',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const ProjectDesignType: IPropertyMetaData = {
    id: 445,
    name: 'ProjectDesignType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const ProjectName: IPropertyMetaData = {
    id: 435,
    name: 'ProjectName',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_AddMoreUserSelectedAnchors: IPropertyMetaData = {
    id: 3226,
    name: 'SmartAnchor_AddMoreUserSelectedAnchors',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_AnchorGroupsForAsad: IPropertyMetaData = {
    id: 3207,
    name: 'SmartAnchor_AnchorGroupsForAsad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Application: IPropertyMetaData = {
    id: 2046,
    name: 'SmartAnchor_Application',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Category: IPropertyMetaData = {
    id: 2045,
    name: 'SmartAnchor_Category',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_CurrentlySelectedAnchor: IPropertyMetaData = {
    id: 2050,
    name: 'SmartAnchor_CurrentlySelectedAnchor',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Enabled: IPropertyMetaData = {
    id: 2090,
    name: 'SmartAnchor_Enabled',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_EnableTco: IPropertyMetaData = {
    id: 2169,
    name: 'SmartAnchor_EnableTco',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_IsLastSelectedSolution: IPropertyMetaData = {
    id: 3232,
    name: 'SmartAnchor_IsLastSelectedSolution',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_OptimizationOptions_AnchorPosition: IPropertyMetaData = {
    id: 3199,
    name: 'SmartAnchor_OptimizationOptions_AnchorPosition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_OptimizationOptions_ProfilePosition: IPropertyMetaData = {
    id: 3198,
    name: 'SmartAnchor_OptimizationOptions_ProfilePosition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_OptimizationOptions_UtilizationSlider: IPropertyMetaData = {
    id: 3197,
    name: 'SmartAnchor_OptimizationOptions_UtilizationSlider',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_PlateHidden: IPropertyMetaData = {
    id: 3182,
    name: 'SmartAnchor_PlateHidden',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_SelectCategoryAndApplication: IPropertyMetaData = {
    id: 3224,
    name: 'SmartAnchor_SelectCategoryAndApplication',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_SelectPreferredAnchor: IPropertyMetaData = {
    id: 3221,
    name: 'SmartAnchor_SelectPreferredAnchor',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_ShowMore: IPropertyMetaData = {
    id: 2049,
    name: 'SmartAnchor_ShowMore',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_SuggestedAnchorFamily: IPropertyMetaData = {
    id: 2048,
    name: 'SmartAnchor_SuggestedAnchorFamily',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_SuggestedAnchorSize: IPropertyMetaData = {
    id: 2047,
    name: 'SmartAnchor_SuggestedAnchorSize',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Tco_AnchorDiscount: IPropertyMetaData = {
    id: 2172,
    name: 'SmartAnchor_Tco_AnchorDiscount',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Tco_SteelPrice: IPropertyMetaData = {
    id: 2170,
    name: 'SmartAnchor_Tco_SteelPrice',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Tco_WorkPrice: IPropertyMetaData = {
    id: 2171,
    name: 'SmartAnchor_Tco_WorkPrice',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Text_ConcreteReinforcement: IPropertyMetaData = {
    id: 2092,
    name: 'SmartAnchor_Text_ConcreteReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Text_CorrisionMaterial: IPropertyMetaData = {
    id: 9999,
    name: 'SmartAnchor_Text_CorrisionMaterial',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Text_InstallationCondition: IPropertyMetaData = {
    id: 2091,
    name: 'SmartAnchor_Text_InstallationCondition',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_Text_Temprature: IPropertyMetaData = {
    id: 2093,
    name: 'SmartAnchor_Text_Temprature',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_UserSelectedAnchorGroupsForAsad: IPropertyMetaData = {
    id: 3223,
    name: 'SmartAnchor_UserSelectedAnchorGroupsForAsad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SmartAnchor_UserSelectedAnchorSizes: IPropertyMetaData = {
    id: 3225,
    name: 'SmartAnchor_UserSelectedAnchorSizes',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Solution_CmdSpecificationText: IPropertyMetaData = {
    id: 430,
    name: 'Solution_CmdSpecificationText',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Solution_SpecificationText: IPropertyMetaData = {
    id: 431,
    name: 'Solution_SpecificationText',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Stiffener_CustomStiffener: IPropertyMetaData = {
    id: 2013,
    name: 'Stiffener_CustomStiffener',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ApplyTensionValuesToShear: IPropertyMetaData = {
    id: 2052,
    name: 'SupplementaryReinforcement_ApplyTensionValuesToShear',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_CBottomEffective: IPropertyMetaData = {
    id: 2042,
    name: 'SupplementaryReinforcement_CBottomEffective',
    minValue: 0,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_CEdge: IPropertyMetaData = {
    id: 2033,
    name: 'SupplementaryReinforcement_CEdge',
    minValue: 10,
    maxValue: 60,
    defaultValue: 25,
};

const SupplementaryReinforcement_CEdgeEffective: IPropertyMetaData = {
    id: 2035,
    name: 'SupplementaryReinforcement_CEdgeEffective',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_CEdgeEffectiveLeft: IPropertyMetaData = {
    id: 2056,
    name: 'SupplementaryReinforcement_CEdgeEffectiveLeft',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_CEdgeEffectiveRight: IPropertyMetaData = {
    id: 2057,
    name: 'SupplementaryReinforcement_CEdgeEffectiveRight',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_CmdRotateAnticlockwise: IPropertyMetaData = {
    id: 2025,
    name: 'SupplementaryReinforcement_CmdRotateAnticlockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_CmdRotateClockwise: IPropertyMetaData = {
    id: 2024,
    name: 'SupplementaryReinforcement_CmdRotateClockwise',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ConcreteReinforcement: IPropertyMetaData = {
    id: 2030,
    name: 'SupplementaryReinforcement_ConcreteReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ConcreteReinforcement_Asad: IPropertyMetaData = {
    id: 2133,
    name: 'SupplementaryReinforcement_ConcreteReinforcement_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_CTop: IPropertyMetaData = {
    id: 2034,
    name: 'SupplementaryReinforcement_CTop',
    minValue: 10,
    maxValue: 60,
    defaultValue: 25,
};

const SupplementaryReinforcement_CTopEffective: IPropertyMetaData = {
    id: 2036,
    name: 'SupplementaryReinforcement_CTopEffective',
    minValue: 0,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_EdgeReinforcement: IPropertyMetaData = {
    id: 2031,
    name: 'SupplementaryReinforcement_EdgeReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_EdgeReinforcement_Asad: IPropertyMetaData = {
    id: 2134,
    name: 'SupplementaryReinforcement_EdgeReinforcement_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_FirstRebarCenterOffset: IPropertyMetaData = {
    id: 2039,
    name: 'SupplementaryReinforcement_FirstRebarCenterOffset',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_Height: IPropertyMetaData = {
    id: 2037,
    name: 'SupplementaryReinforcement_Height',
    minValue: 25,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_IsSplittingReinforcement: IPropertyMetaData = {
    id: 2032,
    name: 'SupplementaryReinforcement_IsSplittingReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_IsSplittingReinforcement_Asad: IPropertyMetaData = {
    id: 2135,
    name: 'SupplementaryReinforcement_IsSplittingReinforcement_Asad',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_PositionTolerance: IPropertyMetaData = {
    id: 2021,
    name: 'SupplementaryReinforcement_PositionTolerance',
    minValue: 0,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ReinforcementCategory: IPropertyMetaData = {
    id: 2016,
    name: 'SupplementaryReinforcement_ReinforcementCategory',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ReinforcementDiameter: IPropertyMetaData = {
    id: 2018,
    name: 'SupplementaryReinforcement_ReinforcementDiameter',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ReinforcementSpacing: IPropertyMetaData = {
    id: 2027,
    name: 'SupplementaryReinforcement_ReinforcementSpacing',
    minValue: 50,
    maxValue: 500,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ReinforcementType: IPropertyMetaData = {
    id: 2017,
    name: 'SupplementaryReinforcement_ReinforcementType',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_ReinforcementUtilization: IPropertyMetaData = {
    id: 2028,
    name: 'SupplementaryReinforcement_ReinforcementUtilization',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const SupplementaryReinforcement_Rotate: IPropertyMetaData = {
    id: 2023,
    name: 'SupplementaryReinforcement_Rotate',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_SurfaceNumber: IPropertyMetaData = {
    id: 2020,
    name: 'SupplementaryReinforcement_SurfaceNumber',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_SurfaceReinforcement: IPropertyMetaData = {
    id: 2019,
    name: 'SupplementaryReinforcement_SurfaceReinforcement',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_SurfaceUtilization: IPropertyMetaData = {
    id: 2029,
    name: 'SupplementaryReinforcement_SurfaceUtilization',
    minValue: 0,
    maxValue: 100,
    defaultValue: undefined,
};

const SupplementaryReinforcement_TensionLeftWidth: IPropertyMetaData = {
    id: 2055,
    name: 'SupplementaryReinforcement_TensionLeftWidth',
    minValue: 0,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_TensionReinforcementSpacing: IPropertyMetaData = {
    id: 2053,
    name: 'SupplementaryReinforcement_TensionReinforcementSpacing',
    minValue: 50,
    maxValue: 300,
    defaultValue: undefined,
};

const SupplementaryReinforcement_TensionRightWidth: IPropertyMetaData = {
    id: 2054,
    name: 'SupplementaryReinforcement_TensionRightWidth',
    minValue: 0,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_Width: IPropertyMetaData = {
    id: 2038,
    name: 'SupplementaryReinforcement_Width',
    minValue: 25,
    maxValue: undefined,
    defaultValue: undefined,
};

const SupplementaryReinforcement_YieldStrength: IPropertyMetaData = {
    id: 2026,
    name: 'SupplementaryReinforcement_YieldStrength',
    minValue: 200,
    maxValue: 600,
    defaultValue: undefined,
};

const SupplementaryReinforcement_DirectionOfCasting: IPropertyMetaData = {
    id: 2022,
    name: 'SupplementaryReinforcement_DirectionOfCasting',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};

const Update_PhasedOutFastenerFamily: IPropertyMetaData = {
    id: 2043,
    name: 'Update_PhasedOutFastenerFamily',
    minValue: undefined,
    maxValue: undefined,
    defaultValue: undefined,
};


export const PropertyMetaData = {
    getById: (id: UIPropertyId): IPropertyMetaData => {
        return PropertyMetaData[id];
    },

    getByName: (name: UIPropertyName): IPropertyMetaData => {
        return PropertyMetaData[name];
    },

		None: None,
		0: None,
		AdditionalFiltersButton: AdditionalFiltersButton,
		3200: AdditionalFiltersButton,
		AdvancedBaseplateCalculation_AlphaCC: AdvancedBaseplateCalculation_AlphaCC,
		1052: AdvancedBaseplateCalculation_AlphaCC,
		AdvancedBaseplateCalculation_ConcreteInCompressionMethod: AdvancedBaseplateCalculation_ConcreteInCompressionMethod,
		2006: AdvancedBaseplateCalculation_ConcreteInCompressionMethod,
		AdvancedBaseplateCalculation_DivergentIterationsCount: AdvancedBaseplateCalculation_DivergentIterationsCount,
		1015: AdvancedBaseplateCalculation_DivergentIterationsCount,
		AdvancedBaseplateCalculation_DivisionOfArcsOfRHS: AdvancedBaseplateCalculation_DivisionOfArcsOfRHS,
		1012: AdvancedBaseplateCalculation_DivisionOfArcsOfRHS,
		AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS: AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS,
		1011: AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS,
		AdvancedBaseplateCalculation_EffectiveArea: AdvancedBaseplateCalculation_EffectiveArea,
		1003: AdvancedBaseplateCalculation_EffectiveArea,
		AdvancedBaseplateCalculation_EffectiveAreaAISC: AdvancedBaseplateCalculation_EffectiveAreaAISC,
		880: AdvancedBaseplateCalculation_EffectiveAreaAISC,
		AdvancedBaseplateCalculation_JointCoefficientBj: AdvancedBaseplateCalculation_JointCoefficientBj,
		1002: AdvancedBaseplateCalculation_JointCoefficientBj,
		AdvancedBaseplateCalculation_LimitPlasticStrain: AdvancedBaseplateCalculation_LimitPlasticStrain,
		1005: AdvancedBaseplateCalculation_LimitPlasticStrain,
		AdvancedBaseplateCalculation_LoadDistributionAngle: AdvancedBaseplateCalculation_LoadDistributionAngle,
		1004: AdvancedBaseplateCalculation_LoadDistributionAngle,
		AdvancedBaseplateCalculation_MaximumSizeOfElement: AdvancedBaseplateCalculation_MaximumSizeOfElement,
		1017: AdvancedBaseplateCalculation_MaximumSizeOfElement,
		AdvancedBaseplateCalculation_MinimumSizeOfElement: AdvancedBaseplateCalculation_MinimumSizeOfElement,
		1016: AdvancedBaseplateCalculation_MinimumSizeOfElement,
		AdvancedBaseplateCalculation_NumberOfAnalysisIterations: AdvancedBaseplateCalculation_NumberOfAnalysisIterations,
		1014: AdvancedBaseplateCalculation_NumberOfAnalysisIterations,
		AdvancedBaseplateCalculation_NumberOfElementsOfEdge: AdvancedBaseplateCalculation_NumberOfElementsOfEdge,
		1013: AdvancedBaseplateCalculation_NumberOfElementsOfEdge,
		AdvancedBaseplateCalculation_StopAtLimitStrain: AdvancedBaseplateCalculation_StopAtLimitStrain,
		1001: AdvancedBaseplateCalculation_StopAtLimitStrain,
		AdvancedBaseplateCalculation_UseULSStresses: AdvancedBaseplateCalculation_UseULSStresses,
		1053: AdvancedBaseplateCalculation_UseULSStresses,
		AnchorLayout_AnchorFamily: AnchorLayout_AnchorFamily,
		201: AnchorLayout_AnchorFamily,
		AnchorLayout_AnchorFilters: AnchorLayout_AnchorFilters,
		216: AnchorLayout_AnchorFilters,
		AnchorLayout_AnchorLayoutSymmetry: AnchorLayout_AnchorLayoutSymmetry,
		2260: AnchorLayout_AnchorLayoutSymmetry,
		AnchorLayout_AnchorTorqueType: AnchorLayout_AnchorTorqueType,
		450: AnchorLayout_AnchorTorqueType,
		AnchorLayout_ApprovalLanguages: AnchorLayout_ApprovalLanguages,
		851: AnchorLayout_ApprovalLanguages,
		AnchorLayout_ApprovalLanguages_STO: AnchorLayout_ApprovalLanguages_STO,
		1057: AnchorLayout_ApprovalLanguages_STO,
		AnchorLayout_ApprovalLanguages_UKTA: AnchorLayout_ApprovalLanguages_UKTA,
		2258: AnchorLayout_ApprovalLanguages_UKTA,
		AnchorLayout_ArticleNumberCapsule: AnchorLayout_ArticleNumberCapsule,
		790: AnchorLayout_ArticleNumberCapsule,
		AnchorLayout_ArticleNumberChemical: AnchorLayout_ArticleNumberChemical,
		779: AnchorLayout_ArticleNumberChemical,
		AnchorLayout_ArticleNumberInsert: AnchorLayout_ArticleNumberInsert,
		789: AnchorLayout_ArticleNumberInsert,
		AnchorLayout_ArticleNumberInsertAlternative: AnchorLayout_ArticleNumberInsertAlternative,
		2262: AnchorLayout_ArticleNumberInsertAlternative,
		AnchorLayout_ArticleNumberMechanical: AnchorLayout_ArticleNumberMechanical,
		778: AnchorLayout_ArticleNumberMechanical,
		AnchorLayout_ArticleNumberSieveSleeve: AnchorLayout_ArticleNumberSieveSleeve,
		811: AnchorLayout_ArticleNumberSieveSleeve,
		AnchorLayout_CipAh: AnchorLayout_CipAh,
		2123: AnchorLayout_CipAh,
		AnchorLayout_CipK8: AnchorLayout_CipK8,
		2103: AnchorLayout_CipK8,
		AnchorLayout_CipMinimumEdgeDistance: AnchorLayout_CipMinimumEdgeDistance,
		2102: AnchorLayout_CipMinimumEdgeDistance,
		AnchorLayout_CipMinSpacing: AnchorLayout_CipMinSpacing,
		2101: AnchorLayout_CipMinSpacing,
		AnchorLayout_CmdRotateAnticlockwise: AnchorLayout_CmdRotateAnticlockwise,
		204: AnchorLayout_CmdRotateAnticlockwise,
		AnchorLayout_CmdRotateClockwise: AnchorLayout_CmdRotateClockwise,
		203: AnchorLayout_CmdRotateClockwise,
		AnchorLayout_CorrosionMaterial: AnchorLayout_CorrosionMaterial,
		3231: AnchorLayout_CorrosionMaterial,
		AnchorLayout_CustomLayoutPoints: AnchorLayout_CustomLayoutPoints,
		215: AnchorLayout_CustomLayoutPoints,
		AnchorLayout_ElasticModulus: AnchorLayout_ElasticModulus,
		2000: AnchorLayout_ElasticModulus,
		AnchorLayout_ElongationLength: AnchorLayout_ElongationLength,
		2002: AnchorLayout_ElongationLength,
		AnchorLayout_EmbedmentDepthFixedMultiple: AnchorLayout_EmbedmentDepthFixedMultiple,
		213: AnchorLayout_EmbedmentDepthFixedMultiple,
		AnchorLayout_EmbedmentDepthOptimizationType: AnchorLayout_EmbedmentDepthOptimizationType,
		214: AnchorLayout_EmbedmentDepthOptimizationType,
		AnchorLayout_EmbedmentDepthVariable: AnchorLayout_EmbedmentDepthVariable,
		212: AnchorLayout_EmbedmentDepthVariable,
		AnchorLayout_Fastener: AnchorLayout_Fastener,
		2059: AnchorLayout_Fastener,
		AnchorLayout_FillHolesETAG: AnchorLayout_FillHolesETAG,
		206: AnchorLayout_FillHolesETAG,
		AnchorLayout_FillHolesSOFA: AnchorLayout_FillHolesSOFA,
		205: AnchorLayout_FillHolesSOFA,
		AnchorLayout_HeadDiameter: AnchorLayout_HeadDiameter,
		2100: AnchorLayout_HeadDiameter,
		AnchorLayout_HeadThickness: AnchorLayout_HeadThickness,
		2099: AnchorLayout_HeadThickness,
		AnchorLayout_Hnom: AnchorLayout_Hnom,
		2089: AnchorLayout_Hnom,
		AnchorLayout_IsAnchorENFatigueCompliant: AnchorLayout_IsAnchorENFatigueCompliant,
		1165: AnchorLayout_IsAnchorENFatigueCompliant,
		AnchorLayout_IsNutAndWasher: AnchorLayout_IsNutAndWasher,
		447: AnchorLayout_IsNutAndWasher,
		AnchorLayout_IsReducedInstallTorque: AnchorLayout_IsReducedInstallTorque,
		209: AnchorLayout_IsReducedInstallTorque,
		AnchorLayout_Layout: AnchorLayout_Layout,
		200: AnchorLayout_Layout,
		AnchorLayout_LayoutCustom: AnchorLayout_LayoutCustom,
		736: AnchorLayout_LayoutCustom,
		AnchorLayout_MasonryFastenerConfiguration: AnchorLayout_MasonryFastenerConfiguration,
		874: AnchorLayout_MasonryFastenerConfiguration,
		AnchorLayout_MinConcreteCover: AnchorLayout_MinConcreteCover,
		451: AnchorLayout_MinConcreteCover,
		AnchorLayout_MinRebarSize: AnchorLayout_MinRebarSize,
		452: AnchorLayout_MinRebarSize,
		AnchorLayout_OffsetXFromAnchorPlate: AnchorLayout_OffsetXFromAnchorPlate,
		428: AnchorLayout_OffsetXFromAnchorPlate,
		AnchorLayout_OffsetYFromAnchorPlate: AnchorLayout_OffsetYFromAnchorPlate,
		429: AnchorLayout_OffsetYFromAnchorPlate,
		AnchorLayout_OnlyUKTAApprovals: AnchorLayout_OnlyUKTAApprovals,
		2263: AnchorLayout_OnlyUKTAApprovals,
		AnchorLayout_Radius: AnchorLayout_Radius,
		140: AnchorLayout_Radius,
		AnchorLayout_Rotate: AnchorLayout_Rotate,
		500: AnchorLayout_Rotate,
		AnchorLayout_SeismicFillingSet: AnchorLayout_SeismicFillingSet,
		798: AnchorLayout_SeismicFillingSet,
		AnchorLayout_ShowFullInternalPortfolio: AnchorLayout_ShowFullInternalPortfolio,
		2259: AnchorLayout_ShowFullInternalPortfolio,
		AnchorLayout_Size: AnchorLayout_Size,
		211: AnchorLayout_Size,
		AnchorLayout_SizeAC58: AnchorLayout_SizeAC58,
		2256: AnchorLayout_SizeAC58,
		AnchorLayout_Spacing_SX: AnchorLayout_Spacing_SX,
		120: AnchorLayout_Spacing_SX,
		AnchorLayout_Spacing_SY: AnchorLayout_Spacing_SY,
		130: AnchorLayout_Spacing_SY,
		AnchorLayout_TensileArea: AnchorLayout_TensileArea,
		2001: AnchorLayout_TensileArea,
		AnchorLayout_Type: AnchorLayout_Type,
		210: AnchorLayout_Type,
		AnchorLayout_TypeAC58: AnchorLayout_TypeAC58,
		2255: AnchorLayout_TypeAC58,
		AnchorLayout_ViewApproval: AnchorLayout_ViewApproval,
		519: AnchorLayout_ViewApproval,
		AnchorLayout_ViewApproval_STO: AnchorLayout_ViewApproval_STO,
		1058: AnchorLayout_ViewApproval_STO,
		AnchorLayout_ViewApproval_UKTA: AnchorLayout_ViewApproval_UKTA,
		2257: AnchorLayout_ViewApproval_UKTA,
		AnchorLayout_ViewCSTBDocument: AnchorLayout_ViewCSTBDocument,
		3227: AnchorLayout_ViewCSTBDocument,
		AnchorLayout_WasherDiameter: AnchorLayout_WasherDiameter,
		448: AnchorLayout_WasherDiameter,
		AnchorLayout_WasherThickness: AnchorLayout_WasherThickness,
		449: AnchorLayout_WasherThickness,
		AnchorPlate_AdjustThickness: AnchorPlate_AdjustThickness,
		2003: AnchorPlate_AdjustThickness,
		AnchorPlate_AdvancedCalculation: AnchorPlate_AdvancedCalculation,
		1019: AnchorPlate_AdvancedCalculation,
		AnchorPlate_AdvancedSettings: AnchorPlate_AdvancedSettings,
		1020: AnchorPlate_AdvancedSettings,
		AnchorPlate_AnchorPlateSize: AnchorPlate_AnchorPlateSize,
		749: AnchorPlate_AnchorPlateSize,
		AnchorPlate_CalculationType: AnchorPlate_CalculationType,
		1018: AnchorPlate_CalculationType,
		AnchorPlate_CmdRotateAnticlockwise: AnchorPlate_CmdRotateAnticlockwise,
		108: AnchorPlate_CmdRotateAnticlockwise,
		AnchorPlate_CmdRotateClockwise: AnchorPlate_CmdRotateClockwise,
		107: AnchorPlate_CmdRotateClockwise,
		AnchorPlate_CustomGroutCompressiveStrength: AnchorPlate_CustomGroutCompressiveStrength,
		112: AnchorPlate_CustomGroutCompressiveStrength,
		AnchorPlate_CustomGroutCompressiveStrength_Asad: AnchorPlate_CustomGroutCompressiveStrength_Asad,
		2130: AnchorPlate_CustomGroutCompressiveStrength_Asad,
		AnchorPlate_CustomLayoutPoints: AnchorPlate_CustomLayoutPoints,
		115: AnchorPlate_CustomLayoutPoints,
		AnchorPlate_CustomPlateMaterial: AnchorPlate_CustomPlateMaterial,
		1109: AnchorPlate_CustomPlateMaterial,
		AnchorPlate_CustomStiffenerMaterial: AnchorPlate_CustomStiffenerMaterial,
		1110: AnchorPlate_CustomStiffenerMaterial,
		AnchorPlate_Diameter: AnchorPlate_Diameter,
		104: AnchorPlate_Diameter,
		AnchorPlate_DirectWeldGravity: AnchorPlate_DirectWeldGravity,
		2070: AnchorPlate_DirectWeldGravity,
		AnchorPlate_DirectWeldLength: AnchorPlate_DirectWeldLength,
		2063: AnchorPlate_DirectWeldLength,
		AnchorPlate_DirectWeldLocation: AnchorPlate_DirectWeldLocation,
		2061: AnchorPlate_DirectWeldLocation,
		AnchorPlate_DirectWeldMaterial: AnchorPlate_DirectWeldMaterial,
		2060: AnchorPlate_DirectWeldMaterial,
		AnchorPlate_DirectWeldMaterialBetaW: AnchorPlate_DirectWeldMaterialBetaW,
		2069: AnchorPlate_DirectWeldMaterialBetaW,
		AnchorPlate_DirectWeldMaterialDensity: AnchorPlate_DirectWeldMaterialDensity,
		2067: AnchorPlate_DirectWeldMaterialDensity,
		AnchorPlate_DirectWeldMaterialEModulus: AnchorPlate_DirectWeldMaterialEModulus,
		2066: AnchorPlate_DirectWeldMaterialEModulus,
		AnchorPlate_DirectWeldMaterialPoisson: AnchorPlate_DirectWeldMaterialPoisson,
		2068: AnchorPlate_DirectWeldMaterialPoisson,
		AnchorPlate_DirectWeldMaterialUltimateStrength: AnchorPlate_DirectWeldMaterialUltimateStrength,
		2064: AnchorPlate_DirectWeldMaterialUltimateStrength,
		AnchorPlate_DirectWeldMaterialYieldStrength: AnchorPlate_DirectWeldMaterialYieldStrength,
		2065: AnchorPlate_DirectWeldMaterialYieldStrength,
		AnchorPlate_DirectWeldThickness: AnchorPlate_DirectWeldThickness,
		2071: AnchorPlate_DirectWeldThickness,
		AnchorPlate_Exists: AnchorPlate_Exists,
		100: AnchorPlate_Exists,
		AnchorPlate_FlangeWeldGravity: AnchorPlate_FlangeWeldGravity,
		1164: AnchorPlate_FlangeWeldGravity,
		AnchorPlate_FlangeWeldLegLength: AnchorPlate_FlangeWeldLegLength,
		1086: AnchorPlate_FlangeWeldLegLength,
		AnchorPlate_FlangeWeldLocation: AnchorPlate_FlangeWeldLocation,
		1042: AnchorPlate_FlangeWeldLocation,
		AnchorPlate_FlangeWeldMaterial: AnchorPlate_FlangeWeldMaterial,
		1041: AnchorPlate_FlangeWeldMaterial,
		AnchorPlate_FlangeWeldMaterialBetaW: AnchorPlate_FlangeWeldMaterialBetaW,
		1135: AnchorPlate_FlangeWeldMaterialBetaW,
		AnchorPlate_FlangeWeldMaterialDensity: AnchorPlate_FlangeWeldMaterialDensity,
		1133: AnchorPlate_FlangeWeldMaterialDensity,
		AnchorPlate_FlangeWeldMaterialEModulus: AnchorPlate_FlangeWeldMaterialEModulus,
		1132: AnchorPlate_FlangeWeldMaterialEModulus,
		AnchorPlate_FlangeWeldMaterialPoisson: AnchorPlate_FlangeWeldMaterialPoisson,
		1134: AnchorPlate_FlangeWeldMaterialPoisson,
		AnchorPlate_FlangeWeldMaterialUltimateStrength: AnchorPlate_FlangeWeldMaterialUltimateStrength,
		1130: AnchorPlate_FlangeWeldMaterialUltimateStrength,
		AnchorPlate_FlangeWeldMaterialYieldStrength: AnchorPlate_FlangeWeldMaterialYieldStrength,
		1131: AnchorPlate_FlangeWeldMaterialYieldStrength,
		AnchorPlate_FlangeWeldThickness: AnchorPlate_FlangeWeldThickness,
		1040: AnchorPlate_FlangeWeldThickness,
		AnchorPlate_Grout: AnchorPlate_Grout,
		113: AnchorPlate_Grout,
		AnchorPlate_Grout_Asad: AnchorPlate_Grout_Asad,
		2131: AnchorPlate_Grout_Asad,
		AnchorPlate_Height: AnchorPlate_Height,
		102: AnchorPlate_Height,
		AnchorPlate_IsCompressiveStrengthOfMortarHigh: AnchorPlate_IsCompressiveStrengthOfMortarHigh,
		512: AnchorPlate_IsCompressiveStrengthOfMortarHigh,
		AnchorPlate_IsPlateMaterialCustom: AnchorPlate_IsPlateMaterialCustom,
		1112: AnchorPlate_IsPlateMaterialCustom,
		AnchorPlate_IsStiffenerMaterialCustom: AnchorPlate_IsStiffenerMaterialCustom,
		1113: AnchorPlate_IsStiffenerMaterialCustom,
		AnchorPlate_LedgerAngleLength: AnchorPlate_LedgerAngleLength,
		2086: AnchorPlate_LedgerAngleLength,
		AnchorPlate_LedgerAngleProfileCustomHeight: AnchorPlate_LedgerAngleProfileCustomHeight,
		2083: AnchorPlate_LedgerAngleProfileCustomHeight,
		AnchorPlate_LedgerAngleProfileCustomThickness: AnchorPlate_LedgerAngleProfileCustomThickness,
		2085: AnchorPlate_LedgerAngleProfileCustomThickness,
		AnchorPlate_LedgerAngleProfileCustomWidth: AnchorPlate_LedgerAngleProfileCustomWidth,
		2084: AnchorPlate_LedgerAngleProfileCustomWidth,
		AnchorPlate_LedgerAngleProfileFamilyId: AnchorPlate_LedgerAngleProfileFamilyId,
		2080: AnchorPlate_LedgerAngleProfileFamilyId,
		AnchorPlate_LedgerAngleProfileSizeId: AnchorPlate_LedgerAngleProfileSizeId,
		2081: AnchorPlate_LedgerAngleProfileSizeId,
		AnchorPlate_LedgerAngleProfileSizeName: AnchorPlate_LedgerAngleProfileSizeName,
		2082: AnchorPlate_LedgerAngleProfileSizeName,
		AnchorPlate_LedgerAngleShape: AnchorPlate_LedgerAngleShape,
		2088: AnchorPlate_LedgerAngleShape,
		AnchorPlate_MaterialDensity: AnchorPlate_MaterialDensity,
		1122: AnchorPlate_MaterialDensity,
		AnchorPlate_MaterialEModulus: AnchorPlate_MaterialEModulus,
		1121: AnchorPlate_MaterialEModulus,
		AnchorPlate_MaterialPoisson: AnchorPlate_MaterialPoisson,
		1123: AnchorPlate_MaterialPoisson,
		AnchorPlate_MaterialUltimateStrength: AnchorPlate_MaterialUltimateStrength,
		1119: AnchorPlate_MaterialUltimateStrength,
		AnchorPlate_MaterialYieldStrength: AnchorPlate_MaterialYieldStrength,
		1120: AnchorPlate_MaterialYieldStrength,
		AnchorPlate_NotOwnedLearnMore: AnchorPlate_NotOwnedLearnMore,
		1043: AnchorPlate_NotOwnedLearnMore,
		AnchorPlate_NotOwnedLearnMoreInfo: AnchorPlate_NotOwnedLearnMoreInfo,
		1044: AnchorPlate_NotOwnedLearnMoreInfo,
		AnchorPlate_OptimizedThicknessMessage: AnchorPlate_OptimizedThicknessMessage,
		813: AnchorPlate_OptimizedThicknessMessage,
		AnchorPlate_PlateShape: AnchorPlate_PlateShape,
		106: AnchorPlate_PlateShape,
		AnchorPlate_PlateShapeCustom: AnchorPlate_PlateShapeCustom,
		735: AnchorPlate_PlateShapeCustom,
		AnchorPlate_Rotate: AnchorPlate_Rotate,
		510: AnchorPlate_Rotate,
		AnchorPlate_ShowOptimizedAnchorPlateThickness: AnchorPlate_ShowOptimizedAnchorPlateThickness,
		757: AnchorPlate_ShowOptimizedAnchorPlateThickness,
		AnchorPlate_ShowStressDistribution: AnchorPlate_ShowStressDistribution,
		681: AnchorPlate_ShowStressDistribution,
		AnchorPlate_SideLength: AnchorPlate_SideLength,
		103: AnchorPlate_SideLength,
		AnchorPlate_StandoffDistance: AnchorPlate_StandoffDistance,
		110: AnchorPlate_StandoffDistance,
		AnchorPlate_StandoffDistance_Asad: AnchorPlate_StandoffDistance_Asad,
		2128: AnchorPlate_StandoffDistance_Asad,
		AnchorPlate_StandoffNutAndWasherHeight: AnchorPlate_StandoffNutAndWasherHeight,
		2261: AnchorPlate_StandoffNutAndWasherHeight,
		AnchorPlate_StandoffRestraintLevel: AnchorPlate_StandoffRestraintLevel,
		111: AnchorPlate_StandoffRestraintLevel,
		AnchorPlate_StandoffRestraintLevel_Asad: AnchorPlate_StandoffRestraintLevel_Asad,
		2129: AnchorPlate_StandoffRestraintLevel_Asad,
		AnchorPlate_StandoffType: AnchorPlate_StandoffType,
		114: AnchorPlate_StandoffType,
		AnchorPlate_StandoffType_Asad: AnchorPlate_StandoffType_Asad,
		2132: AnchorPlate_StandoffType_Asad,
		AnchorPlate_StiffenerCount: AnchorPlate_StiffenerCount,
		1045: AnchorPlate_StiffenerCount,
		AnchorPlate_StiffenerHeight: AnchorPlate_StiffenerHeight,
		1028: AnchorPlate_StiffenerHeight,
		AnchorPlate_StiffenerHorizontalEdgeLength: AnchorPlate_StiffenerHorizontalEdgeLength,
		1029: AnchorPlate_StiffenerHorizontalEdgeLength,
		AnchorPlate_StiffenerLayout: AnchorPlate_StiffenerLayout,
		1046: AnchorPlate_StiffenerLayout,
		AnchorPlate_StiffenerLayoutCustom: AnchorPlate_StiffenerLayoutCustom,
		2012: AnchorPlate_StiffenerLayoutCustom,
		AnchorPlate_StiffenerMaterial: AnchorPlate_StiffenerMaterial,
		1022: AnchorPlate_StiffenerMaterial,
		AnchorPlate_StiffenerMaterialDensity: AnchorPlate_StiffenerMaterialDensity,
		1139: AnchorPlate_StiffenerMaterialDensity,
		AnchorPlate_StiffenerMaterialEModulus: AnchorPlate_StiffenerMaterialEModulus,
		1138: AnchorPlate_StiffenerMaterialEModulus,
		AnchorPlate_StiffenerMaterialPoisson: AnchorPlate_StiffenerMaterialPoisson,
		1140: AnchorPlate_StiffenerMaterialPoisson,
		AnchorPlate_StiffenerMaterialUltimateStrength: AnchorPlate_StiffenerMaterialUltimateStrength,
		1136: AnchorPlate_StiffenerMaterialUltimateStrength,
		AnchorPlate_StiffenerMaterialYieldStrength: AnchorPlate_StiffenerMaterialYieldStrength,
		1137: AnchorPlate_StiffenerMaterialYieldStrength,
		AnchorPlate_StiffenerRadialOffset: AnchorPlate_StiffenerRadialOffset,
		1077: AnchorPlate_StiffenerRadialOffset,
		AnchorPlate_StiffenerShape: AnchorPlate_StiffenerShape,
		1023: AnchorPlate_StiffenerShape,
		AnchorPlate_StiffenerThickness: AnchorPlate_StiffenerThickness,
		1024: AnchorPlate_StiffenerThickness,
		AnchorPlate_StiffenerVerticalEdgeLength: AnchorPlate_StiffenerVerticalEdgeLength,
		1030: AnchorPlate_StiffenerVerticalEdgeLength,
		AnchorPlate_StiffenerWeldGravity: AnchorPlate_StiffenerWeldGravity,
		1162: AnchorPlate_StiffenerWeldGravity,
		AnchorPlate_StiffenerWeldLegLength: AnchorPlate_StiffenerWeldLegLength,
		1088: AnchorPlate_StiffenerWeldLegLength,
		AnchorPlate_StiffenerWeldLocation: AnchorPlate_StiffenerWeldLocation,
		1034: AnchorPlate_StiffenerWeldLocation,
		AnchorPlate_StiffenerWeldMaterial: AnchorPlate_StiffenerWeldMaterial,
		1032: AnchorPlate_StiffenerWeldMaterial,
		AnchorPlate_StiffenerWeldMaterialBetaW: AnchorPlate_StiffenerWeldMaterialBetaW,
		1146: AnchorPlate_StiffenerWeldMaterialBetaW,
		AnchorPlate_StiffenerWeldMaterialDensity: AnchorPlate_StiffenerWeldMaterialDensity,
		1144: AnchorPlate_StiffenerWeldMaterialDensity,
		AnchorPlate_StiffenerWeldMaterialEModulus: AnchorPlate_StiffenerWeldMaterialEModulus,
		1143: AnchorPlate_StiffenerWeldMaterialEModulus,
		AnchorPlate_StiffenerWeldMaterialPoisson: AnchorPlate_StiffenerWeldMaterialPoisson,
		1145: AnchorPlate_StiffenerWeldMaterialPoisson,
		AnchorPlate_StiffenerWeldMaterialUltimateStrength: AnchorPlate_StiffenerWeldMaterialUltimateStrength,
		1141: AnchorPlate_StiffenerWeldMaterialUltimateStrength,
		AnchorPlate_StiffenerWeldMaterialYieldStrength: AnchorPlate_StiffenerWeldMaterialYieldStrength,
		1142: AnchorPlate_StiffenerWeldMaterialYieldStrength,
		AnchorPlate_StiffenerWeldThickness: AnchorPlate_StiffenerWeldThickness,
		1033: AnchorPlate_StiffenerWeldThickness,
		AnchorPlate_StiffenerWidth: AnchorPlate_StiffenerWidth,
		1027: AnchorPlate_StiffenerWidth,
		AnchorPlate_StressImage: AnchorPlate_StressImage,
		1054: AnchorPlate_StressImage,
		AnchorPlate_Thickness: AnchorPlate_Thickness,
		109: AnchorPlate_Thickness,
		AnchorPlate_UseBaseplate: AnchorPlate_UseBaseplate,
		743: AnchorPlate_UseBaseplate,
		AnchorPlate_VerifyDiaphragmShear: AnchorPlate_VerifyDiaphragmShear,
		2150: AnchorPlate_VerifyDiaphragmShear,
		AnchorPlate_WebWeldGravity: AnchorPlate_WebWeldGravity,
		1163: AnchorPlate_WebWeldGravity,
		AnchorPlate_WebWeldLegLength: AnchorPlate_WebWeldLegLength,
		1087: AnchorPlate_WebWeldLegLength,
		AnchorPlate_WebWeldLocation: AnchorPlate_WebWeldLocation,
		1038: AnchorPlate_WebWeldLocation,
		AnchorPlate_WebWeldMaterial: AnchorPlate_WebWeldMaterial,
		1037: AnchorPlate_WebWeldMaterial,
		AnchorPlate_WebWeldMaterialBetaW: AnchorPlate_WebWeldMaterialBetaW,
		1129: AnchorPlate_WebWeldMaterialBetaW,
		AnchorPlate_WebWeldMaterialDensity: AnchorPlate_WebWeldMaterialDensity,
		1127: AnchorPlate_WebWeldMaterialDensity,
		AnchorPlate_WebWeldMaterialEModulus: AnchorPlate_WebWeldMaterialEModulus,
		1126: AnchorPlate_WebWeldMaterialEModulus,
		AnchorPlate_WebWeldMaterialPoisson: AnchorPlate_WebWeldMaterialPoisson,
		1128: AnchorPlate_WebWeldMaterialPoisson,
		AnchorPlate_WebWeldMaterialUltimateStrength: AnchorPlate_WebWeldMaterialUltimateStrength,
		1124: AnchorPlate_WebWeldMaterialUltimateStrength,
		AnchorPlate_WebWeldMaterialYieldStrength: AnchorPlate_WebWeldMaterialYieldStrength,
		1125: AnchorPlate_WebWeldMaterialYieldStrength,
		AnchorPlate_WebWeldThickness: AnchorPlate_WebWeldThickness,
		1036: AnchorPlate_WebWeldThickness,
		AnchorPlate_WeldingType: AnchorPlate_WeldingType,
		1085: AnchorPlate_WeldingType,
		AnchorPlate_Width: AnchorPlate_Width,
		101: AnchorPlate_Width,
		AnchorPlate_SteelType: AnchorPlate_SteelType,
		686: AnchorPlate_SteelType,
		AnchorUserChosen: AnchorUserChosen,
		3205: AnchorUserChosen,
		Application_Altitude: Application_Altitude,
		720: Application_Altitude,
		Application_Application: Application_Application,
		642: Application_Application,
		Application_BarsSpacing: Application_BarsSpacing,
		1096: Application_BarsSpacing,
		Application_BasicWindVelocityId: Application_BasicWindVelocityId,
		723: Application_BasicWindVelocityId,
		Application_BeamBottomSegment: Application_BeamBottomSegment,
		712: Application_BeamBottomSegment,
		Application_BeamToBeamDistance: Application_BeamToBeamDistance,
		640: Application_BeamToBeamDistance,
		Application_BeamType: Application_BeamType,
		639: Application_BeamType,
		Application_BuildingCategory: Application_BuildingCategory,
		643: Application_BuildingCategory,
		Application_BuildingDepth: Application_BuildingDepth,
		696: Application_BuildingDepth,
		Application_BuildingHeight: Application_BuildingHeight,
		695: Application_BuildingHeight,
		Application_BuildingWidth: Application_BuildingWidth,
		694: Application_BuildingWidth,
		Application_BuildingZone: Application_BuildingZone,
		697: Application_BuildingZone,
		Application_CalculatePostAndRailDeflection: Application_CalculatePostAndRailDeflection,
		748: Application_CalculatePostAndRailDeflection,
		Application_CityAndStateId: Application_CityAndStateId,
		722: Application_CityAndStateId,
		Application_Cladding: Application_Cladding,
		692: Application_Cladding,
		Application_CladdingBarOffsetFromFloor: Application_CladdingBarOffsetFromFloor,
		1097: Application_CladdingBarOffsetFromFloor,
		Application_CladdingBarOffsetFromRail: Application_CladdingBarOffsetFromRail,
		1098: Application_CladdingBarOffsetFromRail,
		Application_CladdingBarsCount: Application_CladdingBarsCount,
		1095: Application_CladdingBarsCount,
		Application_CladdingHeight: Application_CladdingHeight,
		764: Application_CladdingHeight,
		Application_CladdingOffset: Application_CladdingOffset,
		763: Application_CladdingOffset,
		Application_CladdingProfile: Application_CladdingProfile,
		1099: Application_CladdingProfile,
		Application_CladdingType: Application_CladdingType,
		1094: Application_CladdingType,
		Application_CladdingWidth: Application_CladdingWidth,
		765: Application_CladdingWidth,
		Application_ConnectionType: Application_ConnectionType,
		2058: Application_ConnectionType,
		Application_DistanceInsideTownTerrainId: Application_DistanceInsideTownTerrainId,
		726: Application_DistanceInsideTownTerrainId,
		Application_DistanceToSeaside: Application_DistanceToSeaside,
		721: Application_DistanceToSeaside,
		Application_DistanceUpwindToShorelineId: Application_DistanceUpwindToShorelineId,
		725: Application_DistanceUpwindToShorelineId,
		Application_EnvironmentType: Application_EnvironmentType,
		637: Application_EnvironmentType,
		Application_H: Application_H,
		714: Application_H,
		Application_HandrailHeightOverGround: Application_HandrailHeightOverGround,
		709: Application_HandrailHeightOverGround,
		Application_HandrailLength: Application_HandrailLength,
		699: Application_HandrailLength,
		Application_IsFreeStandingWall: Application_IsFreeStandingWall,
		698: Application_IsFreeStandingWall,
		Application_IsOrographyRelevant: Application_IsOrographyRelevant,
		718: Application_IsOrographyRelevant,
		Application_Ld: Application_Ld,
		716: Application_Ld,
		Application_Lu: Application_Lu,
		715: Application_Lu,
		Application_NonCarryingLayer: Application_NonCarryingLayer,
		758: Application_NonCarryingLayer,
		Application_ReferenceWindVelocityId: Application_ReferenceWindVelocityId,
		727: Application_ReferenceWindVelocityId,
		Application_ReturnPeriod: Application_ReturnPeriod,
		1062: Application_ReturnPeriod,
		Application_RoughnessClassId: Application_RoughnessClassId,
		724: Application_RoughnessClassId,
		Application_TerrainCategoryId: Application_TerrainCategoryId,
		719: Application_TerrainCategoryId,
		Application_TerrainType: Application_TerrainType,
		713: Application_TerrainType,
		Application_Windloads: Application_Windloads,
		690: Application_Windloads,
		Application_WindPressure: Application_WindPressure,
		1091: Application_WindPressure,
		Application_WindUplift: Application_WindUplift,
		1092: Application_WindUplift,
		Application_WindVelocityPressure: Application_WindVelocityPressure,
		728: Application_WindVelocityPressure,
		Application_WindZone: Application_WindZone,
		693: Application_WindZone,
		Application_X: Application_X,
		717: Application_X,
		Application_ZipCodeHandrailLoadsId: Application_ZipCodeHandrailLoadsId,
		1105: Application_ZipCodeHandrailLoadsId,
		BaseMaterial_ActiveBeam: BaseMaterial_ActiveBeam,
		751: BaseMaterial_ActiveBeam,
		BaseMaterial_BalustradeHeight: BaseMaterial_BalustradeHeight,
		670: BaseMaterial_BalustradeHeight,
		BaseMaterial_BalustradeWidth: BaseMaterial_BalustradeWidth,
		671: BaseMaterial_BalustradeWidth,
		BaseMaterial_BeamLength: BaseMaterial_BeamLength,
		665: BaseMaterial_BeamLength,
		BaseMaterial_BeamLeverArm: BaseMaterial_BeamLeverArm,
		666: BaseMaterial_BeamLeverArm,
		BaseMaterial_BrickCharacteristic: BaseMaterial_BrickCharacteristic,
		2153: BaseMaterial_BrickCharacteristic,
		BaseMaterial_BrickData: BaseMaterial_BrickData,
		638: BaseMaterial_BrickData,
		BaseMaterial_BrickGroup: BaseMaterial_BrickGroup,
		529: BaseMaterial_BrickGroup,
		BaseMaterial_BrickHeight: BaseMaterial_BrickHeight,
		611: BaseMaterial_BrickHeight,
		BaseMaterial_BrickLayout: BaseMaterial_BrickLayout,
		530: BaseMaterial_BrickLayout,
		BaseMaterial_BrickLayoutStartLeft: BaseMaterial_BrickLayoutStartLeft,
		619: BaseMaterial_BrickLayoutStartLeft,
		BaseMaterial_BrickLength: BaseMaterial_BrickLength,
		612: BaseMaterial_BrickLength,
		BaseMaterial_BrickSize: BaseMaterial_BrickSize,
		613: BaseMaterial_BrickSize,
		BaseMaterial_BrickSizeId: BaseMaterial_BrickSizeId,
		2152: BaseMaterial_BrickSizeId,
		BaseMaterial_BrickStrength: BaseMaterial_BrickStrength,
		533: BaseMaterial_BrickStrength,
		BaseMaterial_BrickStrengthAC58: BaseMaterial_BrickStrengthAC58,
		2254: BaseMaterial_BrickStrengthAC58,
		BaseMaterial_BrickStrengthId: BaseMaterial_BrickStrengthId,
		532: BaseMaterial_BrickStrengthId,
		BaseMaterial_BrickStrengthType: BaseMaterial_BrickStrengthType,
		531: BaseMaterial_BrickStrengthType,
		BaseMaterial_BrickWidth: BaseMaterial_BrickWidth,
		610: BaseMaterial_BrickWidth,
		BaseMaterial_CleaningMethod: BaseMaterial_CleaningMethod,
		631: BaseMaterial_CleaningMethod,
		BaseMaterial_ConcreteCharacteristic: BaseMaterial_ConcreteCharacteristic,
		1: BaseMaterial_ConcreteCharacteristic,
		BaseMaterial_ConcreteCharacteristic_Asad: BaseMaterial_ConcreteCharacteristic_Asad,
		2110: BaseMaterial_ConcreteCharacteristic_Asad,
		BaseMaterial_ConcreteGrade: BaseMaterial_ConcreteGrade,
		2: BaseMaterial_ConcreteGrade,
		BaseMaterial_ConcreteGrade_Asad: BaseMaterial_ConcreteGrade_Asad,
		2111: BaseMaterial_ConcreteGrade_Asad,
		BaseMaterial_ConcreteGradeType: BaseMaterial_ConcreteGradeType,
		85: BaseMaterial_ConcreteGradeType,
		BaseMaterial_ConcreteLengthNegative: BaseMaterial_ConcreteLengthNegative,
		669: BaseMaterial_ConcreteLengthNegative,
		BaseMaterial_ConcreteLengthNegativeAnchor: BaseMaterial_ConcreteLengthNegativeAnchor,
		786: BaseMaterial_ConcreteLengthNegativeAnchor,
		BaseMaterial_ConcreteLengthNegativeInfinity: BaseMaterial_ConcreteLengthNegativeInfinity,
		680: BaseMaterial_ConcreteLengthNegativeInfinity,
		BaseMaterial_ConcreteLengthNegativeInfinityAnchor: BaseMaterial_ConcreteLengthNegativeInfinityAnchor,
		788: BaseMaterial_ConcreteLengthNegativeInfinityAnchor,
		BaseMaterial_ConcreteLengthPositive: BaseMaterial_ConcreteLengthPositive,
		668: BaseMaterial_ConcreteLengthPositive,
		BaseMaterial_ConcreteLengthPositiveAnchor: BaseMaterial_ConcreteLengthPositiveAnchor,
		785: BaseMaterial_ConcreteLengthPositiveAnchor,
		BaseMaterial_ConcreteLengthPositiveInfinity: BaseMaterial_ConcreteLengthPositiveInfinity,
		679: BaseMaterial_ConcreteLengthPositiveInfinity,
		BaseMaterial_ConcreteLengthPositiveInfinityAnchor: BaseMaterial_ConcreteLengthPositiveInfinityAnchor,
		787: BaseMaterial_ConcreteLengthPositiveInfinityAnchor,
		BaseMaterial_ConcreteReinforcement: BaseMaterial_ConcreteReinforcement,
		14: BaseMaterial_ConcreteReinforcement,
		BaseMaterial_ConcreteType: BaseMaterial_ConcreteType,
		3228: BaseMaterial_ConcreteType,
		BaseMaterial_ConstrainedEdgeBottom: BaseMaterial_ConstrainedEdgeBottom,
		618: BaseMaterial_ConstrainedEdgeBottom,
		BaseMaterial_ConstrainedEdgeLeft: BaseMaterial_ConstrainedEdgeLeft,
		616: BaseMaterial_ConstrainedEdgeLeft,
		BaseMaterial_ConstrainedEdgeRight: BaseMaterial_ConstrainedEdgeRight,
		615: BaseMaterial_ConstrainedEdgeRight,
		BaseMaterial_ConstrainedEdgeTop: BaseMaterial_ConstrainedEdgeTop,
		617: BaseMaterial_ConstrainedEdgeTop,
		BaseMaterial_CubeStrength: BaseMaterial_CubeStrength,
		807: BaseMaterial_CubeStrength,
		BaseMaterial_CubeStrength_Asad: BaseMaterial_CubeStrength_Asad,
		2168: BaseMaterial_CubeStrength_Asad,
		BaseMaterial_CustomCompressiveStrength: BaseMaterial_CustomCompressiveStrength,
		3: BaseMaterial_CustomCompressiveStrength,
		BaseMaterial_CustomDensity: BaseMaterial_CustomDensity,
		1101: BaseMaterial_CustomDensity,
		BaseMaterial_CustomElasticity: BaseMaterial_CustomElasticity,
		1103: BaseMaterial_CustomElasticity,
		BaseMaterial_CustomJointMaterialStrength: BaseMaterial_CustomJointMaterialStrength,
		2252: BaseMaterial_CustomJointMaterialStrength,
		BaseMaterial_CustomPoisson: BaseMaterial_CustomPoisson,
		1102: BaseMaterial_CustomPoisson,
		BaseMaterial_CustomShearOfSteel: BaseMaterial_CustomShearOfSteel,
		1104: BaseMaterial_CustomShearOfSteel,
		BaseMaterial_CylinderStrength: BaseMaterial_CylinderStrength,
		808: BaseMaterial_CylinderStrength,
		BaseMaterial_CylinderStrength_Asad: BaseMaterial_CylinderStrength_Asad,
		2167: BaseMaterial_CylinderStrength_Asad,
		BaseMaterial_DeckThickness: BaseMaterial_DeckThickness,
		831: BaseMaterial_DeckThickness,
		BaseMaterial_DrillingMethod: BaseMaterial_DrillingMethod,
		12: BaseMaterial_DrillingMethod,
		BaseMaterial_DrillingMethod_Asad: BaseMaterial_DrillingMethod_Asad,
		2126: BaseMaterial_DrillingMethod_Asad,
		BaseMaterial_EdgeReinforcement: BaseMaterial_EdgeReinforcement,
		15: BaseMaterial_EdgeReinforcement,
		BaseMaterial_EdgeReinforcementHNA: BaseMaterial_EdgeReinforcementHNA,
		92: BaseMaterial_EdgeReinforcementHNA,
		BaseMaterial_EdgeXNegative: BaseMaterial_EdgeXNegative,
		35: BaseMaterial_EdgeXNegative,
		BaseMaterial_EdgeXNegative_Asad: BaseMaterial_EdgeXNegative_Asad,
		2114: BaseMaterial_EdgeXNegative_Asad,
		BaseMaterial_EdgeXNegativeFromAnchor: BaseMaterial_EdgeXNegativeFromAnchor,
		39: BaseMaterial_EdgeXNegativeFromAnchor,
		BaseMaterial_EdgeXPositive: BaseMaterial_EdgeXPositive,
		34: BaseMaterial_EdgeXPositive,
		BaseMaterial_EdgeXPositive_Asad: BaseMaterial_EdgeXPositive_Asad,
		2113: BaseMaterial_EdgeXPositive_Asad,
		BaseMaterial_EdgeXPositiveFromAnchor: BaseMaterial_EdgeXPositiveFromAnchor,
		38: BaseMaterial_EdgeXPositiveFromAnchor,
		BaseMaterial_EdgeYNegative: BaseMaterial_EdgeYNegative,
		37: BaseMaterial_EdgeYNegative,
		BaseMaterial_EdgeYNegative_Asad: BaseMaterial_EdgeYNegative_Asad,
		2116: BaseMaterial_EdgeYNegative_Asad,
		BaseMaterial_EdgeYNegativeFromAnchor: BaseMaterial_EdgeYNegativeFromAnchor,
		41: BaseMaterial_EdgeYNegativeFromAnchor,
		BaseMaterial_EdgeYPositive: BaseMaterial_EdgeYPositive,
		36: BaseMaterial_EdgeYPositive,
		BaseMaterial_EdgeYPositive_Asad: BaseMaterial_EdgeYPositive_Asad,
		2115: BaseMaterial_EdgeYPositive_Asad,
		BaseMaterial_EdgeYPositiveFromAnchor: BaseMaterial_EdgeYPositiveFromAnchor,
		40: BaseMaterial_EdgeYPositiveFromAnchor,
		BaseMaterial_FasteningOption: BaseMaterial_FasteningOption,
		632: BaseMaterial_FasteningOption,
		BaseMaterial_HeadJointCompletelyFilled: BaseMaterial_HeadJointCompletelyFilled,
		2004: BaseMaterial_HeadJointCompletelyFilled,
		BaseMaterial_HeadJointSelection: BaseMaterial_HeadJointSelection,
		2264: BaseMaterial_HeadJointSelection,
		BaseMaterial_HoleCondition: BaseMaterial_HoleCondition,
		446: BaseMaterial_HoleCondition,
		BaseMaterial_HoleType: BaseMaterial_HoleType,
		13: BaseMaterial_HoleType,
		BaseMaterial_HoleType_Asad: BaseMaterial_HoleType_Asad,
		2127: BaseMaterial_HoleType_Asad,
		BaseMaterial_InstallationDirection: BaseMaterial_InstallationDirection,
		1084: BaseMaterial_InstallationDirection,
		BaseMaterial_IsAnchorReinforcement: BaseMaterial_IsAnchorReinforcement,
		86: BaseMaterial_IsAnchorReinforcement,
		BaseMaterial_IsAnchorReinforcementShear: BaseMaterial_IsAnchorReinforcementShear,
		88: BaseMaterial_IsAnchorReinforcementShear,
		BaseMaterial_IsAnchorReinforcementTension: BaseMaterial_IsAnchorReinforcementTension,
		87: BaseMaterial_IsAnchorReinforcementTension,
		BaseMaterial_IsCornerReinforcement: BaseMaterial_IsCornerReinforcement,
		89: BaseMaterial_IsCornerReinforcement,
		BaseMaterial_IsEdgeXNegativeFromAnchorReinforced: BaseMaterial_IsEdgeXNegativeFromAnchorReinforced,
		47: BaseMaterial_IsEdgeXNegativeFromAnchorReinforced,
		BaseMaterial_IsEdgeXNegativeLocked: BaseMaterial_IsEdgeXNegativeLocked,
		51: BaseMaterial_IsEdgeXNegativeLocked,
		BaseMaterial_IsEdgeXNegativeReinforced: BaseMaterial_IsEdgeXNegativeReinforced,
		43: BaseMaterial_IsEdgeXNegativeReinforced,
		BaseMaterial_IsEdgeXNegativeReinforced_Asad: BaseMaterial_IsEdgeXNegativeReinforced_Asad,
		2118: BaseMaterial_IsEdgeXNegativeReinforced_Asad,
		BaseMaterial_IsEdgeXPositiveFromAnchorReinforced: BaseMaterial_IsEdgeXPositiveFromAnchorReinforced,
		46: BaseMaterial_IsEdgeXPositiveFromAnchorReinforced,
		BaseMaterial_IsEdgeXPositiveLocked: BaseMaterial_IsEdgeXPositiveLocked,
		50: BaseMaterial_IsEdgeXPositiveLocked,
		BaseMaterial_IsEdgeXPositiveReinforced: BaseMaterial_IsEdgeXPositiveReinforced,
		42: BaseMaterial_IsEdgeXPositiveReinforced,
		BaseMaterial_IsEdgeXPositiveReinforced_Asad: BaseMaterial_IsEdgeXPositiveReinforced_Asad,
		2117: BaseMaterial_IsEdgeXPositiveReinforced_Asad,
		BaseMaterial_IsEdgeYNegativeFromAnchorReinforced: BaseMaterial_IsEdgeYNegativeFromAnchorReinforced,
		49: BaseMaterial_IsEdgeYNegativeFromAnchorReinforced,
		BaseMaterial_IsEdgeYNegativeLocked: BaseMaterial_IsEdgeYNegativeLocked,
		53: BaseMaterial_IsEdgeYNegativeLocked,
		BaseMaterial_IsEdgeYNegativeReinforced: BaseMaterial_IsEdgeYNegativeReinforced,
		45: BaseMaterial_IsEdgeYNegativeReinforced,
		BaseMaterial_IsEdgeYNegativeReinforced_Asad: BaseMaterial_IsEdgeYNegativeReinforced_Asad,
		2120: BaseMaterial_IsEdgeYNegativeReinforced_Asad,
		BaseMaterial_IsEdgeYPositiveFromAnchorReinforced: BaseMaterial_IsEdgeYPositiveFromAnchorReinforced,
		48: BaseMaterial_IsEdgeYPositiveFromAnchorReinforced,
		BaseMaterial_IsEdgeYPositiveLocked: BaseMaterial_IsEdgeYPositiveLocked,
		52: BaseMaterial_IsEdgeYPositiveLocked,
		BaseMaterial_IsEdgeYPositiveReinforced: BaseMaterial_IsEdgeYPositiveReinforced,
		44: BaseMaterial_IsEdgeYPositiveReinforced,
		BaseMaterial_IsEdgeYPositiveReinforced_Asad: BaseMaterial_IsEdgeYPositiveReinforced_Asad,
		2119: BaseMaterial_IsEdgeYPositiveReinforced_Asad,
		BaseMaterial_IsLightweightConcrete: BaseMaterial_IsLightweightConcrete,
		453: BaseMaterial_IsLightweightConcrete,
		BaseMaterial_IsLightweightConcrete_Asad: BaseMaterial_IsLightweightConcrete_Asad,
		2161: BaseMaterial_IsLightweightConcrete_Asad,
		BaseMaterial_IsNonLoadBearingLayerPresent: BaseMaterial_IsNonLoadBearingLayerPresent,
		759: BaseMaterial_IsNonLoadBearingLayerPresent,
		BaseMaterial_IsPlasteredWall: BaseMaterial_IsPlasteredWall,
		605: BaseMaterial_IsPlasteredWall,
		BaseMaterial_IsSplittingReinforcement: BaseMaterial_IsSplittingReinforcement,
		16: BaseMaterial_IsSplittingReinforcement,
		BaseMaterial_JointMaterial: BaseMaterial_JointMaterial,
		602: BaseMaterial_JointMaterial,
		BaseMaterial_JointThicknessHorizontal: BaseMaterial_JointThicknessHorizontal,
		601: BaseMaterial_JointThicknessHorizontal,
		BaseMaterial_JointThicknessVertical: BaseMaterial_JointThicknessVertical,
		600: BaseMaterial_JointThicknessVertical,
		BaseMaterial_LightweightConcreteLambdaA: BaseMaterial_LightweightConcreteLambdaA,
		454: BaseMaterial_LightweightConcreteLambdaA,
		BaseMaterial_LightweightConcreteLambdaA_Asad: BaseMaterial_LightweightConcreteLambdaA_Asad,
		2162: BaseMaterial_LightweightConcreteLambdaA_Asad,
		BaseMaterial_MasonryAnchorPosition: BaseMaterial_MasonryAnchorPosition,
		837: BaseMaterial_MasonryAnchorPosition,
		BaseMaterial_MasonryEdgeXNegative: BaseMaterial_MasonryEdgeXNegative,
		634: BaseMaterial_MasonryEdgeXNegative,
		BaseMaterial_MasonryEdgeXNegativeFromAnchor: BaseMaterial_MasonryEdgeXNegativeFromAnchor,
		738: BaseMaterial_MasonryEdgeXNegativeFromAnchor,
		BaseMaterial_MasonryEdgeXPositive: BaseMaterial_MasonryEdgeXPositive,
		633: BaseMaterial_MasonryEdgeXPositive,
		BaseMaterial_MasonryEdgeXPositiveFromAnchor: BaseMaterial_MasonryEdgeXPositiveFromAnchor,
		737: BaseMaterial_MasonryEdgeXPositiveFromAnchor,
		BaseMaterial_MasonryEdgeYNegative: BaseMaterial_MasonryEdgeYNegative,
		636: BaseMaterial_MasonryEdgeYNegative,
		BaseMaterial_MasonryEdgeYNegativeFromAnchor: BaseMaterial_MasonryEdgeYNegativeFromAnchor,
		740: BaseMaterial_MasonryEdgeYNegativeFromAnchor,
		BaseMaterial_MasonryEdgeYPositive: BaseMaterial_MasonryEdgeYPositive,
		635: BaseMaterial_MasonryEdgeYPositive,
		BaseMaterial_MasonryEdgeYPositiveFromAnchor: BaseMaterial_MasonryEdgeYPositiveFromAnchor,
		739: BaseMaterial_MasonryEdgeYPositiveFromAnchor,
		BaseMaterial_MasonryThickness: BaseMaterial_MasonryThickness,
		872: BaseMaterial_MasonryThickness,
		BaseMaterial_MetalDeck: BaseMaterial_MetalDeck,
		869: BaseMaterial_MetalDeck,
		BaseMaterial_MetalDeckAnchorOffset: BaseMaterial_MetalDeckAnchorOffset,
		830: BaseMaterial_MetalDeckAnchorOffset,
		BaseMaterial_MetalDeckAnchorPosition: BaseMaterial_MetalDeckAnchorPosition,
		826: BaseMaterial_MetalDeckAnchorPosition,
		BaseMaterial_MetalDeckFluteBaseWidth: BaseMaterial_MetalDeckFluteBaseWidth,
		827: BaseMaterial_MetalDeckFluteBaseWidth,
		BaseMaterial_MetalDeckFluteDepth: BaseMaterial_MetalDeckFluteDepth,
		824: BaseMaterial_MetalDeckFluteDepth,
		BaseMaterial_MetalDeckFluteDistance: BaseMaterial_MetalDeckFluteDistance,
		821: BaseMaterial_MetalDeckFluteDistance,
		BaseMaterial_MetalDeckFlutePeakWidth: BaseMaterial_MetalDeckFlutePeakWidth,
		828: BaseMaterial_MetalDeckFlutePeakWidth,
		BaseMaterial_MetalDeckLowerFluteWidth: BaseMaterial_MetalDeckLowerFluteWidth,
		822: BaseMaterial_MetalDeckLowerFluteWidth,
		BaseMaterial_MetalDeckTotalFluteDepth: BaseMaterial_MetalDeckTotalFluteDepth,
		829: BaseMaterial_MetalDeckTotalFluteDepth,
		BaseMaterial_MetalDeckType: BaseMaterial_MetalDeckType,
		825: BaseMaterial_MetalDeckType,
		BaseMaterial_MetalDeckUpperFluteWidth: BaseMaterial_MetalDeckUpperFluteWidth,
		823: BaseMaterial_MetalDeckUpperFluteWidth,
		BaseMaterial_NearestJointDistance: BaseMaterial_NearestJointDistance,
		873: BaseMaterial_NearestJointDistance,
		BaseMaterial_NonLoadBearingLayer: BaseMaterial_NonLoadBearingLayer,
		676: BaseMaterial_NonLoadBearingLayer,
		BaseMaterial_OffsetX: BaseMaterial_OffsetX,
		674: BaseMaterial_OffsetX,
		BaseMaterial_OffsetY: BaseMaterial_OffsetY,
		675: BaseMaterial_OffsetY,
		BaseMaterial_PlasterThickness: BaseMaterial_PlasterThickness,
		606: BaseMaterial_PlasterThickness,
		BaseMaterial_PlasterYoungsModulus: BaseMaterial_PlasterYoungsModulus,
		607: BaseMaterial_PlasterYoungsModulus,
		BaseMaterial_PrismStrength: BaseMaterial_PrismStrength,
		809: BaseMaterial_PrismStrength,
		BaseMaterial_ReinforcementConditionShear: BaseMaterial_ReinforcementConditionShear,
		90: BaseMaterial_ReinforcementConditionShear,
		BaseMaterial_ReinforcementConditionTension: BaseMaterial_ReinforcementConditionTension,
		91: BaseMaterial_ReinforcementConditionTension,
		BaseMaterial_StairHeight: BaseMaterial_StairHeight,
		672: BaseMaterial_StairHeight,
		BaseMaterial_StairLength: BaseMaterial_StairLength,
		673: BaseMaterial_StairLength,
		BaseMaterial_Temperature: BaseMaterial_Temperature,
		835: BaseMaterial_Temperature,
		BaseMaterial_TemperatureLongTerm: BaseMaterial_TemperatureLongTerm,
		11: BaseMaterial_TemperatureLongTerm,
		BaseMaterial_TemperatureLongTerm_Asad: BaseMaterial_TemperatureLongTerm_Asad,
		2125: BaseMaterial_TemperatureLongTerm_Asad,
		BaseMaterial_TemperatureShortTerm: BaseMaterial_TemperatureShortTerm,
		10: BaseMaterial_TemperatureShortTerm,
		BaseMaterial_TemperatureShortTerm_Asad: BaseMaterial_TemperatureShortTerm_Asad,
		2124: BaseMaterial_TemperatureShortTerm_Asad,
		BaseMaterial_Thickness: BaseMaterial_Thickness,
		33: BaseMaterial_Thickness,
		BaseMaterial_Thickness_Asad: BaseMaterial_Thickness_Asad,
		2112: BaseMaterial_Thickness_Asad,
		BaseMaterial_TorquingType: BaseMaterial_TorquingType,
		877: BaseMaterial_TorquingType,
		BaseMaterial_UseCategory: BaseMaterial_UseCategory,
		630: BaseMaterial_UseCategory,
		BaseMaterial_VerticalJointsFilled: BaseMaterial_VerticalJointsFilled,
		614: BaseMaterial_VerticalJointsFilled,
		BaseMaterial_WHGApplication: BaseMaterial_WHGApplication,
		2148: BaseMaterial_WHGApplication,
		BaseMaterial_WHGType: BaseMaterial_WHGType,
		2149: BaseMaterial_WHGType,
		BaseMaterial_EdgeXNegativeFromAnchorPlateEdge: BaseMaterial_EdgeXNegativeFromAnchorPlateEdge,
		848: BaseMaterial_EdgeXNegativeFromAnchorPlateEdge,
		BaseMaterial_EdgeXNegativeFromConcreteEdge: BaseMaterial_EdgeXNegativeFromConcreteEdge,
		844: BaseMaterial_EdgeXNegativeFromConcreteEdge,
		BaseMaterial_EdgeXNegativeFromProfileCenter: BaseMaterial_EdgeXNegativeFromProfileCenter,
		840: BaseMaterial_EdgeXNegativeFromProfileCenter,
		BaseMaterial_EdgeXPositiveFromAnchorPlateEdge: BaseMaterial_EdgeXPositiveFromAnchorPlateEdge,
		847: BaseMaterial_EdgeXPositiveFromAnchorPlateEdge,
		BaseMaterial_EdgeXPositiveFromConcreteEdge: BaseMaterial_EdgeXPositiveFromConcreteEdge,
		843: BaseMaterial_EdgeXPositiveFromConcreteEdge,
		BaseMaterial_EdgeXPositiveFromProfileCenter: BaseMaterial_EdgeXPositiveFromProfileCenter,
		839: BaseMaterial_EdgeXPositiveFromProfileCenter,
		BaseMaterial_EdgeYNegativeFromAnchorPlateEdge: BaseMaterial_EdgeYNegativeFromAnchorPlateEdge,
		850: BaseMaterial_EdgeYNegativeFromAnchorPlateEdge,
		BaseMaterial_EdgeYNegativeFromConcreteEdge: BaseMaterial_EdgeYNegativeFromConcreteEdge,
		846: BaseMaterial_EdgeYNegativeFromConcreteEdge,
		BaseMaterial_EdgeYNegativeFromProfileCenter: BaseMaterial_EdgeYNegativeFromProfileCenter,
		842: BaseMaterial_EdgeYNegativeFromProfileCenter,
		BaseMaterial_EdgeYPositiveFromAnchorPlateEdge: BaseMaterial_EdgeYPositiveFromAnchorPlateEdge,
		849: BaseMaterial_EdgeYPositiveFromAnchorPlateEdge,
		BaseMaterial_EdgeYPositiveFromConcreteEdge: BaseMaterial_EdgeYPositiveFromConcreteEdge,
		845: BaseMaterial_EdgeYPositiveFromConcreteEdge,
		BaseMaterial_EdgeYPositiveFromProfileCenter: BaseMaterial_EdgeYPositiveFromProfileCenter,
		841: BaseMaterial_EdgeYPositiveFromProfileCenter,
		BaseplateThickness: BaseplateThickness,
		3203: BaseplateThickness,
		Checkbot_IOM_BeamsDescription: Checkbot_IOM_BeamsDescription,
		2943: Checkbot_IOM_BeamsDescription,
		ConstructionOptions_CorrosionResistant: ConstructionOptions_CorrosionResistant,
		2098: ConstructionOptions_CorrosionResistant,
		ConstructionOptions_GalvanizedSteel: ConstructionOptions_GalvanizedSteel,
		2094: ConstructionOptions_GalvanizedSteel,
		ConstructionOptions_MechanicallyGalvanized: ConstructionOptions_MechanicallyGalvanized,
		2096: ConstructionOptions_MechanicallyGalvanized,
		ConstructionOptions_Sheradized: ConstructionOptions_Sheradized,
		2095: ConstructionOptions_Sheradized,
		ConstructionOptions_StainlessSteel: ConstructionOptions_StainlessSteel,
		2097: ConstructionOptions_StainlessSteel,
		Loads_AreFactoredSustainedLoadsActive: Loads_AreFactoredSustainedLoadsActive,
		1055: Loads_AreFactoredSustainedLoadsActive,
		Loads_AreLoadCombinationsActive: Loads_AreLoadCombinationsActive,
		420: Loads_AreLoadCombinationsActive,
		Loads_CmdETABSImport: Loads_CmdETABSImport,
		1072: Loads_CmdETABSImport,
		Loads_CmdExport: Loads_CmdExport,
		756: Loads_CmdExport,
		Loads_CmdImport: Loads_CmdImport,
		755: Loads_CmdImport,
		Loads_CmdRobotImport: Loads_CmdRobotImport,
		1066: Loads_CmdRobotImport,
		Loads_CmdSAP2000Import: Loads_CmdSAP2000Import,
		1063: Loads_CmdSAP2000Import,
		Loads_CmdStaadProImport: Loads_CmdStaadProImport,
		1080: Loads_CmdStaadProImport,
		Loads_Combi_psi_0_h: Loads_Combi_psi_0_h,
		818: Loads_Combi_psi_0_h,
		Loads_Combi_psi_0_h_wind: Loads_Combi_psi_0_h_wind,
		820: Loads_Combi_psi_0_h_wind,
		Loads_Combi_psi_0_v: Loads_Combi_psi_0_v,
		819: Loads_Combi_psi_0_v,
		Loads_CompressiveStress: Loads_CompressiveStress,
		604: Loads_CompressiveStress,
		Loads_ConcreteEtagEnOnly: Loads_ConcreteEtagEnOnly,
		879: Loads_ConcreteEtagEnOnly,
		Loads_CustomizeDetails: Loads_CustomizeDetails,
		652: Loads_CustomizeDetails,
		Loads_DeadLoad: Loads_DeadLoad,
		641: Loads_DeadLoad,
		Loads_DeleteLoad: Loads_DeleteLoad,
		2041: Loads_DeleteLoad,
		Loads_DesignMethodHNA: Loads_DesignMethodHNA,
		477: Loads_DesignMethodHNA,
		Loads_DesignMethodHNA_Asad: Loads_DesignMethodHNA_Asad,
		2155: Loads_DesignMethodHNA_Asad,
		Loads_DesignMethodSelection: Loads_DesignMethodSelection,
		878: Loads_DesignMethodSelection,
		Loads_DlubalImportExport: Loads_DlubalImportExport,
		805: Loads_DlubalImportExport,
		Loads_EmergencyExits: Loads_EmergencyExits,
		760: Loads_EmergencyExits,
		Loads_ETABSImportExport: Loads_ETABSImportExport,
		1071: Loads_ETABSImportExport,
		Loads_ExportRisa: Loads_ExportRisa,
		1061: Loads_ExportRisa,
		Loads_FactoredSustainedLoadsFactor: Loads_FactoredSustainedLoadsFactor,
		1056: Loads_FactoredSustainedLoadsFactor,
		Loads_FatigueLoadCycles: Loads_FatigueLoadCycles,
		2266: Loads_FatigueLoadCycles,
		Loads_FatigueLoadType: Loads_FatigueLoadType,
		708: Loads_FatigueLoadType,
		Loads_FireDuration: Loads_FireDuration,
		416: Loads_FireDuration,
		Loads_FireExposure: Loads_FireExposure,
		413: Loads_FireExposure,
		Loads_FlatBarResistance: Loads_FlatBarResistance,
		1100: Loads_FlatBarResistance,
		Loads_ForceX: Loads_ForceX,
		400: Loads_ForceX,
		Loads_ForceXVariable: Loads_ForceXVariable,
		406: Loads_ForceXVariable,
		Loads_ForceY: Loads_ForceY,
		401: Loads_ForceY,
		Loads_ForceYVariable: Loads_ForceYVariable,
		407: Loads_ForceYVariable,
		Loads_ForceZ: Loads_ForceZ,
		402: Loads_ForceZ,
		Loads_ForceZSustained: Loads_ForceZSustained,
		1067: Loads_ForceZSustained,
		Loads_ForceZVariable: Loads_ForceZVariable,
		408: Loads_ForceZVariable,
		Loads_FundamentalWindVelocity: Loads_FundamentalWindVelocity,
		871: Loads_FundamentalWindVelocity,
		Loads_Gamma_perm_inf: Loads_Gamma_perm_inf,
		654: Loads_Gamma_perm_inf,
		Loads_Gamma_perm_sup: Loads_Gamma_perm_sup,
		653: Loads_Gamma_perm_sup,
		Loads_Gamma_var_h_bridge: Loads_Gamma_var_h_bridge,
		657: Loads_Gamma_var_h_bridge,
		Loads_Gamma_var_h_building: Loads_Gamma_var_h_building,
		655: Loads_Gamma_var_h_building,
		Loads_Gamma_var_h_wind: Loads_Gamma_var_h_wind,
		659: Loads_Gamma_var_h_wind,
		Loads_Gamma_var_h_wind_bridge: Loads_Gamma_var_h_wind_bridge,
		761: Loads_Gamma_var_h_wind_bridge,
		Loads_Gamma_var_v_bridge: Loads_Gamma_var_v_bridge,
		658: Loads_Gamma_var_v_bridge,
		Loads_Gamma_var_v_building: Loads_Gamma_var_v_building,
		656: Loads_Gamma_var_v_building,
		Loads_GammaZero: Loads_GammaZero,
		2147: Loads_GammaZero,
		Loads_HandrailDesignMethodSelection: Loads_HandrailDesignMethodSelection,
		1059: Loads_HandrailDesignMethodSelection,
		Loads_HandrailEtagEnOnly: Loads_HandrailEtagEnOnly,
		1060: Loads_HandrailEtagEnOnly,
		Loads_HandrailMoreInfoOnSOFA: Loads_HandrailMoreInfoOnSOFA,
		2160: Loads_HandrailMoreInfoOnSOFA,
		Loads_HandrailSafetyClass: Loads_HandrailSafetyClass,
		1167: Loads_HandrailSafetyClass,
		Loads_HandrailStandOffDesignMethodSelection: Loads_HandrailStandOffDesignMethodSelection,
		2157: Loads_HandrailStandOffDesignMethodSelection,
		Loads_ImportLoadCombinations: Loads_ImportLoadCombinations,
		518: Loads_ImportLoadCombinations,
		Loads_InfillLoadInwards: Loads_InfillLoadInwards,
		855: Loads_InfillLoadInwards,
		Loads_InfillLoadOutwards: Loads_InfillLoadOutwards,
		854: Loads_InfillLoadOutwards,
		Loads_InfillPunctualLoadInwards: Loads_InfillPunctualLoadInwards,
		1090: Loads_InfillPunctualLoadInwards,
		Loads_InfillPunctualLoadOutwards: Loads_InfillPunctualLoadOutwards,
		1089: Loads_InfillPunctualLoadOutwards,
		Loads_InputHandrailLoads: Loads_InputHandrailLoads,
		651: Loads_InputHandrailLoads,
		Loads_IsBridge: Loads_IsBridge,
		834: Loads_IsBridge,
		Loads_IsFatigueExpertMode: Loads_IsFatigueExpertMode,
		2005: Loads_IsFatigueExpertMode,
		Loads_LinearLoadInwards: Loads_LinearLoadInwards,
		645: Loads_LinearLoadInwards,
		Loads_LinearLoadInwardsHeight: Loads_LinearLoadInwardsHeight,
		647: Loads_LinearLoadInwardsHeight,
		Loads_LinearLoadOutwards: Loads_LinearLoadOutwards,
		644: Loads_LinearLoadOutwards,
		Loads_LinearLoadOutwardsHeight: Loads_LinearLoadOutwardsHeight,
		646: Loads_LinearLoadOutwardsHeight,
		Loads_LoadCombinationHNAWizard: Loads_LoadCombinationHNAWizard,
		838: Loads_LoadCombinationHNAWizard,
		Loads_LoadCombinations: Loads_LoadCombinations,
		513: Loads_LoadCombinations,
		Loads_LoadCombinationsCalculationType: Loads_LoadCombinationsCalculationType,
		780: Loads_LoadCombinationsCalculationType,
		Loads_LoadsPositionZ: Loads_LoadsPositionZ,
		2087: Loads_LoadsPositionZ,
		Loads_LoadType: Loads_LoadType,
		734: Loads_LoadType,
		Loads_MomentX: Loads_MomentX,
		403: Loads_MomentX,
		Loads_MomentXSustained: Loads_MomentXSustained,
		1068: Loads_MomentXSustained,
		Loads_MomentXVariable: Loads_MomentXVariable,
		409: Loads_MomentXVariable,
		Loads_MomentY: Loads_MomentY,
		404: Loads_MomentY,
		Loads_MomentYSustained: Loads_MomentYSustained,
		1069: Loads_MomentYSustained,
		Loads_MomentYVariable: Loads_MomentYVariable,
		410: Loads_MomentYVariable,
		Loads_MomentZ: Loads_MomentZ,
		405: Loads_MomentZ,
		Loads_MomentZVariable: Loads_MomentZVariable,
		411: Loads_MomentZVariable,
		Loads_MoreInfoOnFatigue: Loads_MoreInfoOnFatigue,
		2265: Loads_MoreInfoOnFatigue,
		Loads_MoreInfoOnFire: Loads_MoreInfoOnFire,
		3229: Loads_MoreInfoOnFire,
		Loads_MoreInfoOnSOFA: Loads_MoreInfoOnSOFA,
		2159: Loads_MoreInfoOnSOFA,
		Loads_NewAndImportLoad: Loads_NewAndImportLoad,
		2015: Loads_NewAndImportLoad,
		Loads_OnSiteTestsValues: Loads_OnSiteTestsValues,
		859: Loads_OnSiteTestsValues,
		Loads_OnSiteTestsValues_N_Rk: Loads_OnSiteTestsValues_N_Rk,
		860: Loads_OnSiteTestsValues_N_Rk,
		Loads_OnSiteTestsValues_V_Rk: Loads_OnSiteTestsValues_V_Rk,
		861: Loads_OnSiteTestsValues_V_Rk,
		Loads_OnSiteTestType: Loads_OnSiteTestType,
		862: Loads_OnSiteTestType,
		Loads_PhiNonductile: Loads_PhiNonductile,
		466: Loads_PhiNonductile,
		Loads_PunctualLoadInwards: Loads_PunctualLoadInwards,
		649: Loads_PunctualLoadInwards,
		Loads_PunctualLoadInwardsHeight: Loads_PunctualLoadInwardsHeight,
		753: Loads_PunctualLoadInwardsHeight,
		Loads_PunctualLoadInWorstPosition: Loads_PunctualLoadInWorstPosition,
		857: Loads_PunctualLoadInWorstPosition,
		Loads_PunctualLoadOutwards: Loads_PunctualLoadOutwards,
		648: Loads_PunctualLoadOutwards,
		Loads_PunctualLoadOutwardsHeight: Loads_PunctualLoadOutwardsHeight,
		752: Loads_PunctualLoadOutwardsHeight,
		Loads_PunctualLoadRailInwards: Loads_PunctualLoadRailInwards,
		876: Loads_PunctualLoadRailInwards,
		Loads_PunctualLoadRailOutwards: Loads_PunctualLoadRailOutwards,
		875: Loads_PunctualLoadRailOutwards,
		Loads_RobotImportExport: Loads_RobotImportExport,
		1065: Loads_RobotImportExport,
		Loads_SafetyLevel: Loads_SafetyLevel,
		2146: Loads_SafetyLevel,
		Loads_SAP2000ImportExport: Loads_SAP2000ImportExport,
		1064: Loads_SAP2000ImportExport,
		Loads_SeismicCategory: Loads_SeismicCategory,
		419: Loads_SeismicCategory,
		Loads_SeismicDesignType: Loads_SeismicDesignType,
		412: Loads_SeismicDesignType,
		Loads_SeismicDisplacementsType: Loads_SeismicDisplacementsType,
		511: Loads_SeismicDisplacementsType,
		Loads_SeismicDLSShear: Loads_SeismicDLSShear,
		415: Loads_SeismicDLSShear,
		Loads_SeismicDLSTension: Loads_SeismicDLSTension,
		414: Loads_SeismicDLSTension,
		Loads_SeismicIntensityFactor: Loads_SeismicIntensityFactor,
		2144: Loads_SeismicIntensityFactor,
		Loads_SeismicLoadContribution: Loads_SeismicLoadContribution,
		417: Loads_SeismicLoadContribution,
		Loads_SeismicShearType: Loads_SeismicShearType,
		468: Loads_SeismicShearType,
		Loads_SeismicTensionType: Loads_SeismicTensionType,
		467: Loads_SeismicTensionType,
		Loads_SelectedLoadCombination: Loads_SelectedLoadCombination,
		514: Loads_SelectedLoadCombination,
		Loads_ShearLoadDistributionType: Loads_ShearLoadDistributionType,
		2267: Loads_ShearLoadDistributionType,
		Loads_ShowAllLoads: Loads_ShowAllLoads,
		710: Loads_ShowAllLoads,
		Loads_StaadProImportExport: Loads_StaadProImportExport,
		1079: Loads_StaadProImportExport,
		Loads_StandOffDesignMethodSelection: Loads_StandOffDesignMethodSelection,
		2156: Loads_StandOffDesignMethodSelection,
		Loads_StaticLoadType: Loads_StaticLoadType,
		418: Loads_StaticLoadType,
		Loads_StaticShearType: Loads_StaticShearType,
		731: Loads_StaticShearType,
		Loads_StaticTensionType: Loads_StaticTensionType,
		730: Loads_StaticTensionType,
		Loads_StructuralMember: Loads_StructuralMember,
		2145: Loads_StructuralMember,
		Loads_UseLoadCombinations_SE: Loads_UseLoadCombinations_SE,
		1166: Loads_UseLoadCombinations_SE,
		Loads_UseOnSiteTestsData: Loads_UseOnSiteTestsData,
		858: Loads_UseOnSiteTestsData,
		Loads_VerticalLoad: Loads_VerticalLoad,
		650: Loads_VerticalLoad,
		Loads_VerticalPunctualLoad: Loads_VerticalPunctualLoad,
		856: Loads_VerticalPunctualLoad,
		Loads_VerticalPunctualLoadInWorstPosition: Loads_VerticalPunctualLoadInWorstPosition,
		853: Loads_VerticalPunctualLoadInWorstPosition,
		Optimize_AnchorLayout: Optimize_AnchorLayout,
		534: Optimize_AnchorLayout,
		Optimize_AnchorOffset: Optimize_AnchorOffset,
		812: Optimize_AnchorOffset,
		Optimize_AnchorPlatePosition: Optimize_AnchorPlatePosition,
		521: Optimize_AnchorPlatePosition,
		Optimize_AnchorPlateSize: Optimize_AnchorPlateSize,
		520: Optimize_AnchorPlateSize,
		Optimize_AxialSpacing: Optimize_AxialSpacing,
		522: Optimize_AxialSpacing,
		Optimize_BaseMaterialThickness: Optimize_BaseMaterialThickness,
		523: Optimize_BaseMaterialThickness,
		Optimize_BaseplateSize: Optimize_BaseplateSize,
		528: Optimize_BaseplateSize,
		Optimize_EdgeDistance: Optimize_EdgeDistance,
		524: Optimize_EdgeDistance,
		Optimize_FindSolution: Optimize_FindSolution,
		833: Optimize_FindSolution,
		Optimize_PostDistance: Optimize_PostDistance,
		810: Optimize_PostDistance,
		OptimizeOnEmbedmentDepth: OptimizeOnEmbedmentDepth,
		3204: OptimizeOnEmbedmentDepth,
		Option_AnchorPlateFactor: Option_AnchorPlateFactor,
		769: Option_AnchorPlateFactor,
		Option_ConcreteCapacityFactor: Option_ConcreteCapacityFactor,
		1074: Option_ConcreteCapacityFactor,
		Option_ConcreteSafetyFactorGammaC: Option_ConcreteSafetyFactorGammaC,
		2014: Option_ConcreteSafetyFactorGammaC,
		Option_CoordinateSystemCenter: Option_CoordinateSystemCenter,
		795: Option_CoordinateSystemCenter,
		Option_CoordinateSystemCenterX: Option_CoordinateSystemCenterX,
		796: Option_CoordinateSystemCenterX,
		Option_CoordinateSystemCenterY: Option_CoordinateSystemCenterY,
		797: Option_CoordinateSystemCenterY,
		Option_CustomPictures: Option_CustomPictures,
		2158: Option_CustomPictures,
		Option_DesignMethodGroupId: Option_DesignMethodGroupId,
		526: Option_DesignMethodGroupId,
		Option_DesignMethodGroupId_Asad: Option_DesignMethodGroupId_Asad,
		3230: Option_DesignMethodGroupId_Asad,
		Option_DesignName: Option_DesignName,
		434: Option_DesignName,
		Option_DesignStandard: Option_DesignStandard,
		444: Option_DesignStandard,
		Option_EdgeDistanceDisplayType: Option_EdgeDistanceDisplayType,
		781: Option_EdgeDistanceDisplayType,
		Option_ETAGOnly: Option_ETAGOnly,
		432: Option_ETAGOnly,
		Option_FaxNumber: Option_FaxNumber,
		474: Option_FaxNumber,
		Option_HandrailDisplacementLimit: Option_HandrailDisplacementLimit,
		832: Option_HandrailDisplacementLimit,
		Option_HandrailDisplacementLimitBridges: Option_HandrailDisplacementLimitBridges,
		1106: Option_HandrailDisplacementLimitBridges,
		Option_INSteelSafetyFactorGammaM0: Option_INSteelSafetyFactorGammaM0,
		2008: Option_INSteelSafetyFactorGammaM0,
		Option_INSteelSafetyFactorGammaM1: Option_INSteelSafetyFactorGammaM1,
		2009: Option_INSteelSafetyFactorGammaM1,
		Option_INSteelSafetyFactorGammaMb: Option_INSteelSafetyFactorGammaMb,
		2011: Option_INSteelSafetyFactorGammaMb,
		Option_INSteelSafetyFactorGammaMw: Option_INSteelSafetyFactorGammaMw,
		2010: Option_INSteelSafetyFactorGammaMw,
		Option_MaterialSafetyFactor: Option_MaterialSafetyFactor,
		1049: Option_MaterialSafetyFactor,
		Option_MeasureAnchorPlate: Option_MeasureAnchorPlate,
		3208: Option_MeasureAnchorPlate,
		Option_MeasureBaseMaterialEdgeFrom: Option_MeasureBaseMaterialEdgeFrom,
		3209: Option_MeasureBaseMaterialEdgeFrom,
		Option_MinimumAnchorToProfileDistance: Option_MinimumAnchorToProfileDistance,
		806: Option_MinimumAnchorToProfileDistance,
		Option_MinimumConcreteCover: Option_MinimumConcreteCover,
		2040: Option_MinimumConcreteCover,
		Option_NoteCombinedTensionAndShearLoad: Option_NoteCombinedTensionAndShearLoad,
		802: Option_NoteCombinedTensionAndShearLoad,
		Option_NoteDisplacements: Option_NoteDisplacements,
		803: Option_NoteDisplacements,
		Option_NoteInstallationData: Option_NoteInstallationData,
		804: Option_NoteInstallationData,
		Option_NoteLoadCaseResultingAnchorForces: Option_NoteLoadCaseResultingAnchorForces,
		799: Option_NoteLoadCaseResultingAnchorForces,
		Option_NoteShearLoad: Option_NoteShearLoad,
		801: Option_NoteShearLoad,
		Option_NoteTensionLoad: Option_NoteTensionLoad,
		800: Option_NoteTensionLoad,
		Option_OstInputsEnabled: Option_OstInputsEnabled,
		1051: Option_OstInputsEnabled,
		Option_RegionId: Option_RegionId,
		443: Option_RegionId,
		Option_ReportAddress: Option_ReportAddress,
		472: Option_ReportAddress,
		Option_ReportCompanyName: Option_ReportCompanyName,
		471: Option_ReportCompanyName,
		Option_ReportContactPerson: Option_ReportContactPerson,
		470: Option_ReportContactPerson,
		Option_ReportEmail: Option_ReportEmail,
		475: Option_ReportEmail,
		Option_ReportFasteningPoint: Option_ReportFasteningPoint,
		469: Option_ReportFasteningPoint,
		Option_ReportFirstPage: Option_ReportFirstPage,
		463: Option_ReportFirstPage,
		Option_ReportLanguageLcid: Option_ReportLanguageLcid,
		462: Option_ReportLanguageLcid,
		Option_ReportLoadCombination: Option_ReportLoadCombination,
		608: Option_ReportLoadCombination,
		Option_ReportNotes: Option_ReportNotes,
		465: Option_ReportNotes,
		Option_ReportPaperSizeId: Option_ReportPaperSizeId,
		460: Option_ReportPaperSizeId,
		Option_ReportPhoneNumber: Option_ReportPhoneNumber,
		473: Option_ReportPhoneNumber,
		Option_ReportTemplateId: Option_ReportTemplateId,
		464: Option_ReportTemplateId,
		Option_ReportTypeId: Option_ReportTypeId,
		461: Option_ReportTypeId,
		Option_SafetyFactorForPermamentLoads: Option_SafetyFactorForPermamentLoads,
		770: Option_SafetyFactorForPermamentLoads,
		Option_SafetyFactorForVariableLoads: Option_SafetyFactorForVariableLoads,
		771: Option_SafetyFactorForVariableLoads,
		Option_StainlessSteel: Option_StainlessSteel,
		817: Option_StainlessSteel,
		Option_SteelCapacityFactor: Option_SteelCapacityFactor,
		1073: Option_SteelCapacityFactor,
		Option_SteelGuideline: Option_SteelGuideline,
		2007: Option_SteelGuideline,
		Option_SteelSafetyFactorGammaM0: Option_SteelSafetyFactorGammaM0,
		814: Option_SteelSafetyFactorGammaM0,
		Option_SteelSafetyFactorGammaM1: Option_SteelSafetyFactorGammaM1,
		815: Option_SteelSafetyFactorGammaM1,
		Option_SteelSafetyFactorGammaM2: Option_SteelSafetyFactorGammaM2,
		816: Option_SteelSafetyFactorGammaM2,
		Option_SteelSafetyFactorGammaM5: Option_SteelSafetyFactorGammaM5,
		870: Option_SteelSafetyFactorGammaM5,
		Option_STOSteelSafetyFactorGammaC: Option_STOSteelSafetyFactorGammaC,
		1083: Option_STOSteelSafetyFactorGammaC,
		Option_TrimbleConnectFolderId: Option_TrimbleConnectFolderId,
		794: Option_TrimbleConnectFolderId,
		Option_TrimbleConnectLocation: Option_TrimbleConnectLocation,
		792: Option_TrimbleConnectLocation,
		Option_TrimbleConnectReportName: Option_TrimbleConnectReportName,
		793: Option_TrimbleConnectReportName,
		Option_TrimbleConnectUpload: Option_TrimbleConnectUpload,
		791: Option_TrimbleConnectUpload,
		Option_UnitArea: Option_UnitArea,
		438: Option_UnitArea,
		Option_UnitAreaPerLength: Option_UnitAreaPerLength,
		2044: Option_UnitAreaPerLength,
		Option_UnitDensity: Option_UnitDensity,
		1107: Option_UnitDensity,
		Option_UnitForce: Option_UnitForce,
		440: Option_UnitForce,
		Option_UnitForcePerLength: Option_UnitForcePerLength,
		677: Option_UnitForcePerLength,
		Option_UnitLength: Option_UnitLength,
		437: Option_UnitLength,
		Option_UnitLengthLarge: Option_UnitLengthLarge,
		767: Option_UnitLengthLarge,
		Option_UnitMoment: Option_UnitMoment,
		441: Option_UnitMoment,
		Option_UnitMomentPerLength: Option_UnitMomentPerLength,
		1078: Option_UnitMomentPerLength,
		Option_UnitStress: Option_UnitStress,
		439: Option_UnitStress,
		Option_UnitStressSmall: Option_UnitStressSmall,
		768: Option_UnitStressSmall,
		Option_UnitTemperature: Option_UnitTemperature,
		442: Option_UnitTemperature,
		Option_WeldsCapacityFactor: Option_WeldsCapacityFactor,
		1070: Option_WeldsCapacityFactor,
		Profile_Angle: Profile_Angle,
		308: Profile_Angle,
		Profile_CmdEccentricityRotateAnticlockwise: Profile_CmdEccentricityRotateAnticlockwise,
		733: Profile_CmdEccentricityRotateAnticlockwise,
		Profile_CmdEccentricityRotateClockwise: Profile_CmdEccentricityRotateClockwise,
		732: Profile_CmdEccentricityRotateClockwise,
		Profile_CmdEccentricityRotatePostAnticlockwise: Profile_CmdEccentricityRotatePostAnticlockwise,
		777: Profile_CmdEccentricityRotatePostAnticlockwise,
		Profile_CmdEccentricityRotatePostClockwise: Profile_CmdEccentricityRotatePostClockwise,
		776: Profile_CmdEccentricityRotatePostClockwise,
		Profile_CustomFlangeThickness: Profile_CustomFlangeThickness,
		304: Profile_CustomFlangeThickness,
		Profile_CustomFlangeThickness_Asad: Profile_CustomFlangeThickness_Asad,
		2165: Profile_CustomFlangeThickness_Asad,
		Profile_CustomHeight: Profile_CustomHeight,
		302: Profile_CustomHeight,
		Profile_CustomHeight_Asad: Profile_CustomHeight_Asad,
		2163: Profile_CustomHeight_Asad,
		Profile_CustomProfileMaterial: Profile_CustomProfileMaterial,
		1108: Profile_CustomProfileMaterial,
		Profile_CustomThickness: Profile_CustomThickness,
		307: Profile_CustomThickness,
		Profile_CustomThickness_Asad: Profile_CustomThickness_Asad,
		2166: Profile_CustomThickness_Asad,
		Profile_CustomWidth: Profile_CustomWidth,
		303: Profile_CustomWidth,
		Profile_CustomWidth_Asad: Profile_CustomWidth_Asad,
		2164: Profile_CustomWidth_Asad,
		Profile_CustomWidth2: Profile_CustomWidth2,
		1081: Profile_CustomWidth2,
		Profile_DoubleFlatBarCustomWidth: Profile_DoubleFlatBarCustomWidth,
		3206: Profile_DoubleFlatBarCustomWidth,
		Profile_FlangeWeldThickness: Profile_FlangeWeldThickness,
		705: Profile_FlangeWeldThickness,
		Profile_HandrailPostAngle: Profile_HandrailPostAngle,
		775: Profile_HandrailPostAngle,
		Profile_HandrailPostProfileFamilyId: Profile_HandrailPostProfileFamilyId,
		746: Profile_HandrailPostProfileFamilyId,
		Profile_HandrailRailAngle: Profile_HandrailRailAngle,
		772: Profile_HandrailRailAngle,
		Profile_HandrailRailCmdEccentricityRotateAnticlockwise: Profile_HandrailRailCmdEccentricityRotateAnticlockwise,
		774: Profile_HandrailRailCmdEccentricityRotateAnticlockwise,
		Profile_HandrailRailCmdEccentricityRotateClockwise: Profile_HandrailRailCmdEccentricityRotateClockwise,
		773: Profile_HandrailRailCmdEccentricityRotateClockwise,
		Profile_HandrailRailProfileFamilyId: Profile_HandrailRailProfileFamilyId,
		742: Profile_HandrailRailProfileFamilyId,
		Profile_HandrailRailProfileSizeId: Profile_HandrailRailProfileSizeId,
		744: Profile_HandrailRailProfileSizeId,
		Profile_HandrailRailProfileSizeName: Profile_HandrailRailProfileSizeName,
		745: Profile_HandrailRailProfileSizeName,
		Profile_HandrailRailProfileSteelType: Profile_HandrailRailProfileSteelType,
		747: Profile_HandrailRailProfileSteelType,
		Profile_HandrailSafetyDesign: Profile_HandrailSafetyDesign,
		754: Profile_HandrailSafetyDesign,
		Profile_HorizontalPostMaterialDensity: Profile_HorizontalPostMaterialDensity,
		1160: Profile_HorizontalPostMaterialDensity,
		Profile_HorizontalPostMaterialEModulus: Profile_HorizontalPostMaterialEModulus,
		1159: Profile_HorizontalPostMaterialEModulus,
		Profile_HorizontalPostMaterialPoisson: Profile_HorizontalPostMaterialPoisson,
		1161: Profile_HorizontalPostMaterialPoisson,
		Profile_HorizontalPostMaterialUltimateStrength: Profile_HorizontalPostMaterialUltimateStrength,
		1157: Profile_HorizontalPostMaterialUltimateStrength,
		Profile_HorizontalPostMaterialYieldStrength: Profile_HorizontalPostMaterialYieldStrength,
		1158: Profile_HorizontalPostMaterialYieldStrength,
		Profile_IsProfileMaterialCustom: Profile_IsProfileMaterialCustom,
		1111: Profile_IsProfileMaterialCustom,
		Profile_MaterialDensity: Profile_MaterialDensity,
		1117: Profile_MaterialDensity,
		Profile_MaterialEModulus: Profile_MaterialEModulus,
		1116: Profile_MaterialEModulus,
		Profile_MaterialPoisson: Profile_MaterialPoisson,
		1118: Profile_MaterialPoisson,
		Profile_MaterialUltimateStrength: Profile_MaterialUltimateStrength,
		1114: Profile_MaterialUltimateStrength,
		Profile_MaterialYieldStrength: Profile_MaterialYieldStrength,
		1115: Profile_MaterialYieldStrength,
		Profile_OffsetX: Profile_OffsetX,
		305: Profile_OffsetX,
		Profile_OffsetY: Profile_OffsetY,
		306: Profile_OffsetY,
		Profile_PostMaterialDensity: Profile_PostMaterialDensity,
		1155: Profile_PostMaterialDensity,
		Profile_PostMaterialEModulus: Profile_PostMaterialEModulus,
		1154: Profile_PostMaterialEModulus,
		Profile_PostMaterialPoisson: Profile_PostMaterialPoisson,
		1156: Profile_PostMaterialPoisson,
		Profile_PostMaterialUltimateStrength: Profile_PostMaterialUltimateStrength,
		1152: Profile_PostMaterialUltimateStrength,
		Profile_PostMaterialYieldStrength: Profile_PostMaterialYieldStrength,
		1153: Profile_PostMaterialYieldStrength,
		Profile_ProfileFamilyId: Profile_ProfileFamilyId,
		300: Profile_ProfileFamilyId,
		Profile_ProfileFamilyId_Asad: Profile_ProfileFamilyId_Asad,
		2121: Profile_ProfileFamilyId_Asad,
		Profile_ProfileSizeId: Profile_ProfileSizeId,
		301: Profile_ProfileSizeId,
		Profile_ProfileSizeId_Asad: Profile_ProfileSizeId_Asad,
		2122: Profile_ProfileSizeId_Asad,
		Profile_ProfileSizeName: Profile_ProfileSizeName,
		320: Profile_ProfileSizeName,
		Profile_RailMaterialDensity: Profile_RailMaterialDensity,
		1150: Profile_RailMaterialDensity,
		Profile_RailMaterialEModulus: Profile_RailMaterialEModulus,
		1149: Profile_RailMaterialEModulus,
		Profile_RailMaterialPoisson: Profile_RailMaterialPoisson,
		1151: Profile_RailMaterialPoisson,
		Profile_RailMaterialUltimateStrength: Profile_RailMaterialUltimateStrength,
		1147: Profile_RailMaterialUltimateStrength,
		Profile_RailMaterialYieldStrength: Profile_RailMaterialYieldStrength,
		1148: Profile_RailMaterialYieldStrength,
		Profile_ShearLugAngle: Profile_ShearLugAngle,
		3218: Profile_ShearLugAngle,
		Profile_ShearLugCmdRotateAnticlockwise: Profile_ShearLugCmdRotateAnticlockwise,
		3220: Profile_ShearLugCmdRotateAnticlockwise,
		Profile_ShearLugCmdRotateClockwise: Profile_ShearLugCmdRotateClockwise,
		3219: Profile_ShearLugCmdRotateClockwise,
		Profile_ShearLugCustomDepth: Profile_ShearLugCustomDepth,
		3215: Profile_ShearLugCustomDepth,
		Profile_ShearLugCustomLength: Profile_ShearLugCustomLength,
		3212: Profile_ShearLugCustomLength,
		Profile_ShearLugCustomThickness: Profile_ShearLugCustomThickness,
		3214: Profile_ShearLugCustomThickness,
		Profile_ShearLugCustomWidth: Profile_ShearLugCustomWidth,
		3213: Profile_ShearLugCustomWidth,
		Profile_ShearLugFamilyId: Profile_ShearLugFamilyId,
		3211: Profile_ShearLugFamilyId,
		Profile_ShearLugSizeId: Profile_ShearLugSizeId,
		3216: Profile_ShearLugSizeId,
		Profile_ShearLugSizeName: Profile_ShearLugSizeName,
		3217: Profile_ShearLugSizeName,
		Profile_ShowWeldDesign: Profile_ShowWeldDesign,
		701: Profile_ShowWeldDesign,
		Profile_SteelType: Profile_SteelType,
		702: Profile_SteelType,
		Profile_UseProfile: Profile_UseProfile,
		741: Profile_UseProfile,
		Profile_UseShearLug: Profile_UseShearLug,
		3210: Profile_UseShearLug,
		Profile_Utilization: Profile_Utilization,
		704: Profile_Utilization,
		Profile_WebWeldLocation: Profile_WebWeldLocation,
		2136: Profile_WebWeldLocation,
		Profile_WebWeldMaterial: Profile_WebWeldMaterial,
		2137: Profile_WebWeldMaterial,
		Profile_WebWeldMaterialBetaW: Profile_WebWeldMaterialBetaW,
		2143: Profile_WebWeldMaterialBetaW,
		Profile_WebWeldMaterialDensity: Profile_WebWeldMaterialDensity,
		2141: Profile_WebWeldMaterialDensity,
		Profile_WebWeldMaterialEModulus: Profile_WebWeldMaterialEModulus,
		2140: Profile_WebWeldMaterialEModulus,
		Profile_WebWeldMaterialPoisson: Profile_WebWeldMaterialPoisson,
		2142: Profile_WebWeldMaterialPoisson,
		Profile_WebWeldMaterialUltimateStrength: Profile_WebWeldMaterialUltimateStrength,
		2138: Profile_WebWeldMaterialUltimateStrength,
		Profile_WebWeldMaterialYieldStrength: Profile_WebWeldMaterialYieldStrength,
		2139: Profile_WebWeldMaterialYieldStrength,
		Profile_WebWeldThickness: Profile_WebWeldThickness,
		706: Profile_WebWeldThickness,
		Profile_HorizontalPostProfileConnection: Profile_HorizontalPostProfileConnection,
		864: Profile_HorizontalPostProfileConnection,
		Profile_HorizontalPostProfileFamilyId: Profile_HorizontalPostProfileFamilyId,
		868: Profile_HorizontalPostProfileFamilyId,
		Profile_HorizontalPostProfileSizeId: Profile_HorizontalPostProfileSizeId,
		865: Profile_HorizontalPostProfileSizeId,
		Profile_HorizontalPostProfileSizeName: Profile_HorizontalPostProfileSizeName,
		867: Profile_HorizontalPostProfileSizeName,
		Profile_HorizontalPostSteelTypeHandrail: Profile_HorizontalPostSteelTypeHandrail,
		866: Profile_HorizontalPostSteelTypeHandrail,
		Profile_IsHorizontalPostProfilePresent: Profile_IsHorizontalPostProfilePresent,
		863: Profile_IsHorizontalPostProfilePresent,
		Profile_SteelTypeHandrail: Profile_SteelTypeHandrail,
		750: Profile_SteelTypeHandrail,
		Profile_UsePinConnection: Profile_UsePinConnection,
		1093: Profile_UsePinConnection,
		ProjectDesignType: ProjectDesignType,
		445: ProjectDesignType,
		ProjectName: ProjectName,
		435: ProjectName,
		SmartAnchor_AddMoreUserSelectedAnchors: SmartAnchor_AddMoreUserSelectedAnchors,
		3226: SmartAnchor_AddMoreUserSelectedAnchors,
		SmartAnchor_AnchorGroupsForAsad: SmartAnchor_AnchorGroupsForAsad,
		3207: SmartAnchor_AnchorGroupsForAsad,
		SmartAnchor_Application: SmartAnchor_Application,
		2046: SmartAnchor_Application,
		SmartAnchor_Category: SmartAnchor_Category,
		2045: SmartAnchor_Category,
		SmartAnchor_CurrentlySelectedAnchor: SmartAnchor_CurrentlySelectedAnchor,
		2050: SmartAnchor_CurrentlySelectedAnchor,
		SmartAnchor_Enabled: SmartAnchor_Enabled,
		2090: SmartAnchor_Enabled,
		SmartAnchor_EnableTco: SmartAnchor_EnableTco,
		2169: SmartAnchor_EnableTco,
		SmartAnchor_IsLastSelectedSolution: SmartAnchor_IsLastSelectedSolution,
		3232: SmartAnchor_IsLastSelectedSolution,
		SmartAnchor_OptimizationOptions_AnchorPosition: SmartAnchor_OptimizationOptions_AnchorPosition,
		3199: SmartAnchor_OptimizationOptions_AnchorPosition,
		SmartAnchor_OptimizationOptions_ProfilePosition: SmartAnchor_OptimizationOptions_ProfilePosition,
		3198: SmartAnchor_OptimizationOptions_ProfilePosition,
		SmartAnchor_OptimizationOptions_UtilizationSlider: SmartAnchor_OptimizationOptions_UtilizationSlider,
		3197: SmartAnchor_OptimizationOptions_UtilizationSlider,
		SmartAnchor_PlateHidden: SmartAnchor_PlateHidden,
		3182: SmartAnchor_PlateHidden,
		SmartAnchor_SelectCategoryAndApplication: SmartAnchor_SelectCategoryAndApplication,
		3224: SmartAnchor_SelectCategoryAndApplication,
		SmartAnchor_SelectPreferredAnchor: SmartAnchor_SelectPreferredAnchor,
		3221: SmartAnchor_SelectPreferredAnchor,
		SmartAnchor_ShowMore: SmartAnchor_ShowMore,
		2049: SmartAnchor_ShowMore,
		SmartAnchor_SuggestedAnchorFamily: SmartAnchor_SuggestedAnchorFamily,
		2048: SmartAnchor_SuggestedAnchorFamily,
		SmartAnchor_SuggestedAnchorSize: SmartAnchor_SuggestedAnchorSize,
		2047: SmartAnchor_SuggestedAnchorSize,
		SmartAnchor_Tco_AnchorDiscount: SmartAnchor_Tco_AnchorDiscount,
		2172: SmartAnchor_Tco_AnchorDiscount,
		SmartAnchor_Tco_SteelPrice: SmartAnchor_Tco_SteelPrice,
		2170: SmartAnchor_Tco_SteelPrice,
		SmartAnchor_Tco_WorkPrice: SmartAnchor_Tco_WorkPrice,
		2171: SmartAnchor_Tco_WorkPrice,
		SmartAnchor_Text_ConcreteReinforcement: SmartAnchor_Text_ConcreteReinforcement,
		2092: SmartAnchor_Text_ConcreteReinforcement,
		SmartAnchor_Text_CorrisionMaterial: SmartAnchor_Text_CorrisionMaterial,
		9999: SmartAnchor_Text_CorrisionMaterial,
		SmartAnchor_Text_InstallationCondition: SmartAnchor_Text_InstallationCondition,
		2091: SmartAnchor_Text_InstallationCondition,
		SmartAnchor_Text_Temprature: SmartAnchor_Text_Temprature,
		2093: SmartAnchor_Text_Temprature,
		SmartAnchor_UserSelectedAnchorGroupsForAsad: SmartAnchor_UserSelectedAnchorGroupsForAsad,
		3223: SmartAnchor_UserSelectedAnchorGroupsForAsad,
		SmartAnchor_UserSelectedAnchorSizes: SmartAnchor_UserSelectedAnchorSizes,
		3225: SmartAnchor_UserSelectedAnchorSizes,
		Solution_CmdSpecificationText: Solution_CmdSpecificationText,
		430: Solution_CmdSpecificationText,
		Solution_SpecificationText: Solution_SpecificationText,
		431: Solution_SpecificationText,
		Stiffener_CustomStiffener: Stiffener_CustomStiffener,
		2013: Stiffener_CustomStiffener,
		SupplementaryReinforcement_ApplyTensionValuesToShear: SupplementaryReinforcement_ApplyTensionValuesToShear,
		2052: SupplementaryReinforcement_ApplyTensionValuesToShear,
		SupplementaryReinforcement_CBottomEffective: SupplementaryReinforcement_CBottomEffective,
		2042: SupplementaryReinforcement_CBottomEffective,
		SupplementaryReinforcement_CEdge: SupplementaryReinforcement_CEdge,
		2033: SupplementaryReinforcement_CEdge,
		SupplementaryReinforcement_CEdgeEffective: SupplementaryReinforcement_CEdgeEffective,
		2035: SupplementaryReinforcement_CEdgeEffective,
		SupplementaryReinforcement_CEdgeEffectiveLeft: SupplementaryReinforcement_CEdgeEffectiveLeft,
		2056: SupplementaryReinforcement_CEdgeEffectiveLeft,
		SupplementaryReinforcement_CEdgeEffectiveRight: SupplementaryReinforcement_CEdgeEffectiveRight,
		2057: SupplementaryReinforcement_CEdgeEffectiveRight,
		SupplementaryReinforcement_CmdRotateAnticlockwise: SupplementaryReinforcement_CmdRotateAnticlockwise,
		2025: SupplementaryReinforcement_CmdRotateAnticlockwise,
		SupplementaryReinforcement_CmdRotateClockwise: SupplementaryReinforcement_CmdRotateClockwise,
		2024: SupplementaryReinforcement_CmdRotateClockwise,
		SupplementaryReinforcement_ConcreteReinforcement: SupplementaryReinforcement_ConcreteReinforcement,
		2030: SupplementaryReinforcement_ConcreteReinforcement,
		SupplementaryReinforcement_ConcreteReinforcement_Asad: SupplementaryReinforcement_ConcreteReinforcement_Asad,
		2133: SupplementaryReinforcement_ConcreteReinforcement_Asad,
		SupplementaryReinforcement_CTop: SupplementaryReinforcement_CTop,
		2034: SupplementaryReinforcement_CTop,
		SupplementaryReinforcement_CTopEffective: SupplementaryReinforcement_CTopEffective,
		2036: SupplementaryReinforcement_CTopEffective,
		SupplementaryReinforcement_EdgeReinforcement: SupplementaryReinforcement_EdgeReinforcement,
		2031: SupplementaryReinforcement_EdgeReinforcement,
		SupplementaryReinforcement_EdgeReinforcement_Asad: SupplementaryReinforcement_EdgeReinforcement_Asad,
		2134: SupplementaryReinforcement_EdgeReinforcement_Asad,
		SupplementaryReinforcement_FirstRebarCenterOffset: SupplementaryReinforcement_FirstRebarCenterOffset,
		2039: SupplementaryReinforcement_FirstRebarCenterOffset,
		SupplementaryReinforcement_Height: SupplementaryReinforcement_Height,
		2037: SupplementaryReinforcement_Height,
		SupplementaryReinforcement_IsSplittingReinforcement: SupplementaryReinforcement_IsSplittingReinforcement,
		2032: SupplementaryReinforcement_IsSplittingReinforcement,
		SupplementaryReinforcement_IsSplittingReinforcement_Asad: SupplementaryReinforcement_IsSplittingReinforcement_Asad,
		2135: SupplementaryReinforcement_IsSplittingReinforcement_Asad,
		SupplementaryReinforcement_PositionTolerance: SupplementaryReinforcement_PositionTolerance,
		2021: SupplementaryReinforcement_PositionTolerance,
		SupplementaryReinforcement_ReinforcementCategory: SupplementaryReinforcement_ReinforcementCategory,
		2016: SupplementaryReinforcement_ReinforcementCategory,
		SupplementaryReinforcement_ReinforcementDiameter: SupplementaryReinforcement_ReinforcementDiameter,
		2018: SupplementaryReinforcement_ReinforcementDiameter,
		SupplementaryReinforcement_ReinforcementSpacing: SupplementaryReinforcement_ReinforcementSpacing,
		2027: SupplementaryReinforcement_ReinforcementSpacing,
		SupplementaryReinforcement_ReinforcementType: SupplementaryReinforcement_ReinforcementType,
		2017: SupplementaryReinforcement_ReinforcementType,
		SupplementaryReinforcement_ReinforcementUtilization: SupplementaryReinforcement_ReinforcementUtilization,
		2028: SupplementaryReinforcement_ReinforcementUtilization,
		SupplementaryReinforcement_Rotate: SupplementaryReinforcement_Rotate,
		2023: SupplementaryReinforcement_Rotate,
		SupplementaryReinforcement_SurfaceNumber: SupplementaryReinforcement_SurfaceNumber,
		2020: SupplementaryReinforcement_SurfaceNumber,
		SupplementaryReinforcement_SurfaceReinforcement: SupplementaryReinforcement_SurfaceReinforcement,
		2019: SupplementaryReinforcement_SurfaceReinforcement,
		SupplementaryReinforcement_SurfaceUtilization: SupplementaryReinforcement_SurfaceUtilization,
		2029: SupplementaryReinforcement_SurfaceUtilization,
		SupplementaryReinforcement_TensionLeftWidth: SupplementaryReinforcement_TensionLeftWidth,
		2055: SupplementaryReinforcement_TensionLeftWidth,
		SupplementaryReinforcement_TensionReinforcementSpacing: SupplementaryReinforcement_TensionReinforcementSpacing,
		2053: SupplementaryReinforcement_TensionReinforcementSpacing,
		SupplementaryReinforcement_TensionRightWidth: SupplementaryReinforcement_TensionRightWidth,
		2054: SupplementaryReinforcement_TensionRightWidth,
		SupplementaryReinforcement_Width: SupplementaryReinforcement_Width,
		2038: SupplementaryReinforcement_Width,
		SupplementaryReinforcement_YieldStrength: SupplementaryReinforcement_YieldStrength,
		2026: SupplementaryReinforcement_YieldStrength,
		SupplementaryReinforcement_DirectionOfCasting: SupplementaryReinforcement_DirectionOfCasting,
		2022: SupplementaryReinforcement_DirectionOfCasting,
		Update_PhasedOutFastenerFamily: Update_PhasedOutFastenerFamily,
		2043: Update_PhasedOutFastenerFamily,
};

export interface UIProperties {
    AdditionalFiltersButton: UIPropertyConfig;
    AdvancedBaseplateCalculation_AlphaCC: UIPropertyConfig;
    AdvancedBaseplateCalculation_ConcreteInCompressionMethod: UIPropertyConfig;
    AdvancedBaseplateCalculation_DivergentIterationsCount: UIPropertyConfig;
    AdvancedBaseplateCalculation_DivisionOfArcsOfRHS: UIPropertyConfig;
    AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS: UIPropertyConfig;
    AdvancedBaseplateCalculation_EffectiveArea: UIPropertyConfig;
    AdvancedBaseplateCalculation_EffectiveAreaAISC: UIPropertyConfig;
    AdvancedBaseplateCalculation_JointCoefficientBj: UIPropertyConfig;
    AdvancedBaseplateCalculation_LimitPlasticStrain: UIPropertyConfig;
    AdvancedBaseplateCalculation_LoadDistributionAngle: UIPropertyConfig;
    AdvancedBaseplateCalculation_MaximumSizeOfElement: UIPropertyConfig;
    AdvancedBaseplateCalculation_MinimumSizeOfElement: UIPropertyConfig;
    AdvancedBaseplateCalculation_NumberOfAnalysisIterations: UIPropertyConfig;
    AdvancedBaseplateCalculation_NumberOfElementsOfEdge: UIPropertyConfig;
    AdvancedBaseplateCalculation_StopAtLimitStrain: UIPropertyConfig;
    AdvancedBaseplateCalculation_UseULSStresses: UIPropertyConfig;
    AnchorLayout_AnchorFamily: UIPropertyConfig;
    AnchorLayout_AnchorFilters: UIPropertyConfig;
    AnchorLayout_AnchorLayoutSymmetry: UIPropertyConfig;
    AnchorLayout_AnchorTorqueType: UIPropertyConfig;
    AnchorLayout_ApprovalLanguages: UIPropertyConfig;
    AnchorLayout_ApprovalLanguages_STO: UIPropertyConfig;
    AnchorLayout_ApprovalLanguages_UKTA: UIPropertyConfig;
    AnchorLayout_ArticleNumberCapsule: UIPropertyConfig;
    AnchorLayout_ArticleNumberChemical: UIPropertyConfig;
    AnchorLayout_ArticleNumberInsert: UIPropertyConfig;
    AnchorLayout_ArticleNumberInsertAlternative: UIPropertyConfig;
    AnchorLayout_ArticleNumberMechanical: UIPropertyConfig;
    AnchorLayout_ArticleNumberSieveSleeve: UIPropertyConfig;
    AnchorLayout_CipAh: UIPropertyConfig;
    AnchorLayout_CipK8: UIPropertyConfig;
    AnchorLayout_CipMinimumEdgeDistance: UIPropertyConfig;
    AnchorLayout_CipMinSpacing: UIPropertyConfig;
    AnchorLayout_CmdRotateAnticlockwise: UIPropertyConfig;
    AnchorLayout_CmdRotateClockwise: UIPropertyConfig;
    AnchorLayout_CorrosionMaterial: UIPropertyConfig;
    AnchorLayout_CustomLayoutPoints: UIPropertyConfig;
    AnchorLayout_ElasticModulus: UIPropertyConfig;
    AnchorLayout_ElongationLength: UIPropertyConfig;
    AnchorLayout_EmbedmentDepthFixedMultiple: UIPropertyConfig;
    AnchorLayout_EmbedmentDepthOptimizationType: UIPropertyConfig;
    AnchorLayout_EmbedmentDepthVariable: UIPropertyConfig;
    AnchorLayout_Fastener: UIPropertyConfig;
    AnchorLayout_FillHolesETAG: UIPropertyConfig;
    AnchorLayout_FillHolesSOFA: UIPropertyConfig;
    AnchorLayout_HeadDiameter: UIPropertyConfig;
    AnchorLayout_HeadThickness: UIPropertyConfig;
    AnchorLayout_Hnom: UIPropertyConfig;
    AnchorLayout_IsAnchorENFatigueCompliant: UIPropertyConfig;
    AnchorLayout_IsNutAndWasher: UIPropertyConfig;
    AnchorLayout_IsReducedInstallTorque: UIPropertyConfig;
    AnchorLayout_Layout: UIPropertyConfig;
    AnchorLayout_LayoutCustom: UIPropertyConfig;
    AnchorLayout_MasonryFastenerConfiguration: UIPropertyConfig;
    AnchorLayout_MinConcreteCover: UIPropertyConfig;
    AnchorLayout_MinRebarSize: UIPropertyConfig;
    AnchorLayout_OffsetXFromAnchorPlate: UIPropertyConfig;
    AnchorLayout_OffsetYFromAnchorPlate: UIPropertyConfig;
    AnchorLayout_OnlyUKTAApprovals: UIPropertyConfig;
    AnchorLayout_Radius: UIPropertyConfig;
    AnchorLayout_Rotate: UIPropertyConfig;
    AnchorLayout_SeismicFillingSet: UIPropertyConfig;
    AnchorLayout_ShowFullInternalPortfolio: UIPropertyConfig;
    AnchorLayout_Size: UIPropertyConfig;
    AnchorLayout_SizeAC58: UIPropertyConfig;
    AnchorLayout_Spacing_SX: UIPropertyConfig;
    AnchorLayout_Spacing_SY: UIPropertyConfig;
    AnchorLayout_TensileArea: UIPropertyConfig;
    AnchorLayout_Type: UIPropertyConfig;
    AnchorLayout_TypeAC58: UIPropertyConfig;
    AnchorLayout_ViewApproval: UIPropertyConfig;
    AnchorLayout_ViewApproval_STO: UIPropertyConfig;
    AnchorLayout_ViewApproval_UKTA: UIPropertyConfig;
    AnchorLayout_ViewCSTBDocument: UIPropertyConfig;
    AnchorLayout_WasherDiameter: UIPropertyConfig;
    AnchorLayout_WasherThickness: UIPropertyConfig;
    AnchorPlate_AdjustThickness: UIPropertyConfig;
    AnchorPlate_AdvancedCalculation: UIPropertyConfig;
    AnchorPlate_AdvancedSettings: UIPropertyConfig;
    AnchorPlate_AnchorPlateSize: UIPropertyConfig;
    AnchorPlate_CalculationType: UIPropertyConfig;
    AnchorPlate_CmdRotateAnticlockwise: UIPropertyConfig;
    AnchorPlate_CmdRotateClockwise: UIPropertyConfig;
    AnchorPlate_CustomGroutCompressiveStrength: UIPropertyConfig;
    AnchorPlate_CustomGroutCompressiveStrength_Asad: UIPropertyConfig;
    AnchorPlate_CustomLayoutPoints: UIPropertyConfig;
    AnchorPlate_CustomPlateMaterial: UIPropertyConfig;
    AnchorPlate_CustomStiffenerMaterial: UIPropertyConfig;
    AnchorPlate_Diameter: UIPropertyConfig;
    AnchorPlate_DirectWeldGravity: UIPropertyConfig;
    AnchorPlate_DirectWeldLength: UIPropertyConfig;
    AnchorPlate_DirectWeldLocation: UIPropertyConfig;
    AnchorPlate_DirectWeldMaterial: UIPropertyConfig;
    AnchorPlate_DirectWeldMaterialBetaW: UIPropertyConfig;
    AnchorPlate_DirectWeldMaterialDensity: UIPropertyConfig;
    AnchorPlate_DirectWeldMaterialEModulus: UIPropertyConfig;
    AnchorPlate_DirectWeldMaterialPoisson: UIPropertyConfig;
    AnchorPlate_DirectWeldMaterialUltimateStrength: UIPropertyConfig;
    AnchorPlate_DirectWeldMaterialYieldStrength: UIPropertyConfig;
    AnchorPlate_DirectWeldThickness: UIPropertyConfig;
    AnchorPlate_Exists: UIPropertyConfig;
    AnchorPlate_FlangeWeldGravity: UIPropertyConfig;
    AnchorPlate_FlangeWeldLegLength: UIPropertyConfig;
    AnchorPlate_FlangeWeldLocation: UIPropertyConfig;
    AnchorPlate_FlangeWeldMaterial: UIPropertyConfig;
    AnchorPlate_FlangeWeldMaterialBetaW: UIPropertyConfig;
    AnchorPlate_FlangeWeldMaterialDensity: UIPropertyConfig;
    AnchorPlate_FlangeWeldMaterialEModulus: UIPropertyConfig;
    AnchorPlate_FlangeWeldMaterialPoisson: UIPropertyConfig;
    AnchorPlate_FlangeWeldMaterialUltimateStrength: UIPropertyConfig;
    AnchorPlate_FlangeWeldMaterialYieldStrength: UIPropertyConfig;
    AnchorPlate_FlangeWeldThickness: UIPropertyConfig;
    AnchorPlate_Grout: UIPropertyConfig;
    AnchorPlate_Grout_Asad: UIPropertyConfig;
    AnchorPlate_Height: UIPropertyConfig;
    AnchorPlate_IsCompressiveStrengthOfMortarHigh: UIPropertyConfig;
    AnchorPlate_IsPlateMaterialCustom: UIPropertyConfig;
    AnchorPlate_IsStiffenerMaterialCustom: UIPropertyConfig;
    AnchorPlate_LedgerAngleLength: UIPropertyConfig;
    AnchorPlate_LedgerAngleProfileCustomHeight: UIPropertyConfig;
    AnchorPlate_LedgerAngleProfileCustomThickness: UIPropertyConfig;
    AnchorPlate_LedgerAngleProfileCustomWidth: UIPropertyConfig;
    AnchorPlate_LedgerAngleProfileFamilyId: UIPropertyConfig;
    AnchorPlate_LedgerAngleProfileSizeId: UIPropertyConfig;
    AnchorPlate_LedgerAngleProfileSizeName: UIPropertyConfig;
    AnchorPlate_LedgerAngleShape: UIPropertyConfig;
    AnchorPlate_MaterialDensity: UIPropertyConfig;
    AnchorPlate_MaterialEModulus: UIPropertyConfig;
    AnchorPlate_MaterialPoisson: UIPropertyConfig;
    AnchorPlate_MaterialUltimateStrength: UIPropertyConfig;
    AnchorPlate_MaterialYieldStrength: UIPropertyConfig;
    AnchorPlate_NotOwnedLearnMore: UIPropertyConfig;
    AnchorPlate_NotOwnedLearnMoreInfo: UIPropertyConfig;
    AnchorPlate_OptimizedThicknessMessage: UIPropertyConfig;
    AnchorPlate_PlateShape: UIPropertyConfig;
    AnchorPlate_PlateShapeCustom: UIPropertyConfig;
    AnchorPlate_Rotate: UIPropertyConfig;
    AnchorPlate_ShowOptimizedAnchorPlateThickness: UIPropertyConfig;
    AnchorPlate_ShowStressDistribution: UIPropertyConfig;
    AnchorPlate_SideLength: UIPropertyConfig;
    AnchorPlate_StandoffDistance: UIPropertyConfig;
    AnchorPlate_StandoffDistance_Asad: UIPropertyConfig;
    AnchorPlate_StandoffNutAndWasherHeight: UIPropertyConfig;
    AnchorPlate_StandoffRestraintLevel: UIPropertyConfig;
    AnchorPlate_StandoffRestraintLevel_Asad: UIPropertyConfig;
    AnchorPlate_StandoffType: UIPropertyConfig;
    AnchorPlate_StandoffType_Asad: UIPropertyConfig;
    AnchorPlate_StiffenerCount: UIPropertyConfig;
    AnchorPlate_StiffenerHeight: UIPropertyConfig;
    AnchorPlate_StiffenerHorizontalEdgeLength: UIPropertyConfig;
    AnchorPlate_StiffenerLayout: UIPropertyConfig;
    AnchorPlate_StiffenerLayoutCustom: UIPropertyConfig;
    AnchorPlate_StiffenerMaterial: UIPropertyConfig;
    AnchorPlate_StiffenerMaterialDensity: UIPropertyConfig;
    AnchorPlate_StiffenerMaterialEModulus: UIPropertyConfig;
    AnchorPlate_StiffenerMaterialPoisson: UIPropertyConfig;
    AnchorPlate_StiffenerMaterialUltimateStrength: UIPropertyConfig;
    AnchorPlate_StiffenerMaterialYieldStrength: UIPropertyConfig;
    AnchorPlate_StiffenerRadialOffset: UIPropertyConfig;
    AnchorPlate_StiffenerShape: UIPropertyConfig;
    AnchorPlate_StiffenerThickness: UIPropertyConfig;
    AnchorPlate_StiffenerVerticalEdgeLength: UIPropertyConfig;
    AnchorPlate_StiffenerWeldGravity: UIPropertyConfig;
    AnchorPlate_StiffenerWeldLegLength: UIPropertyConfig;
    AnchorPlate_StiffenerWeldLocation: UIPropertyConfig;
    AnchorPlate_StiffenerWeldMaterial: UIPropertyConfig;
    AnchorPlate_StiffenerWeldMaterialBetaW: UIPropertyConfig;
    AnchorPlate_StiffenerWeldMaterialDensity: UIPropertyConfig;
    AnchorPlate_StiffenerWeldMaterialEModulus: UIPropertyConfig;
    AnchorPlate_StiffenerWeldMaterialPoisson: UIPropertyConfig;
    AnchorPlate_StiffenerWeldMaterialUltimateStrength: UIPropertyConfig;
    AnchorPlate_StiffenerWeldMaterialYieldStrength: UIPropertyConfig;
    AnchorPlate_StiffenerWeldThickness: UIPropertyConfig;
    AnchorPlate_StiffenerWidth: UIPropertyConfig;
    AnchorPlate_StressImage: UIPropertyConfig;
    AnchorPlate_Thickness: UIPropertyConfig;
    AnchorPlate_UseBaseplate: UIPropertyConfig;
    AnchorPlate_VerifyDiaphragmShear: UIPropertyConfig;
    AnchorPlate_WebWeldGravity: UIPropertyConfig;
    AnchorPlate_WebWeldLegLength: UIPropertyConfig;
    AnchorPlate_WebWeldLocation: UIPropertyConfig;
    AnchorPlate_WebWeldMaterial: UIPropertyConfig;
    AnchorPlate_WebWeldMaterialBetaW: UIPropertyConfig;
    AnchorPlate_WebWeldMaterialDensity: UIPropertyConfig;
    AnchorPlate_WebWeldMaterialEModulus: UIPropertyConfig;
    AnchorPlate_WebWeldMaterialPoisson: UIPropertyConfig;
    AnchorPlate_WebWeldMaterialUltimateStrength: UIPropertyConfig;
    AnchorPlate_WebWeldMaterialYieldStrength: UIPropertyConfig;
    AnchorPlate_WebWeldThickness: UIPropertyConfig;
    AnchorPlate_WeldingType: UIPropertyConfig;
    AnchorPlate_Width: UIPropertyConfig;
    AnchorPlate_SteelType: UIPropertyConfig;
    AnchorUserChosen: UIPropertyConfig;
    Application_Altitude: UIPropertyConfig;
    Application_Application: UIPropertyConfig;
    Application_BarsSpacing: UIPropertyConfig;
    Application_BasicWindVelocityId: UIPropertyConfig;
    Application_BeamBottomSegment: UIPropertyConfig;
    Application_BeamToBeamDistance: UIPropertyConfig;
    Application_BeamType: UIPropertyConfig;
    Application_BuildingCategory: UIPropertyConfig;
    Application_BuildingDepth: UIPropertyConfig;
    Application_BuildingHeight: UIPropertyConfig;
    Application_BuildingWidth: UIPropertyConfig;
    Application_BuildingZone: UIPropertyConfig;
    Application_CalculatePostAndRailDeflection: UIPropertyConfig;
    Application_CityAndStateId: UIPropertyConfig;
    Application_Cladding: UIPropertyConfig;
    Application_CladdingBarOffsetFromFloor: UIPropertyConfig;
    Application_CladdingBarOffsetFromRail: UIPropertyConfig;
    Application_CladdingBarsCount: UIPropertyConfig;
    Application_CladdingHeight: UIPropertyConfig;
    Application_CladdingOffset: UIPropertyConfig;
    Application_CladdingProfile: UIPropertyConfig;
    Application_CladdingType: UIPropertyConfig;
    Application_CladdingWidth: UIPropertyConfig;
    Application_ConnectionType: UIPropertyConfig;
    Application_DistanceInsideTownTerrainId: UIPropertyConfig;
    Application_DistanceToSeaside: UIPropertyConfig;
    Application_DistanceUpwindToShorelineId: UIPropertyConfig;
    Application_EnvironmentType: UIPropertyConfig;
    Application_H: UIPropertyConfig;
    Application_HandrailHeightOverGround: UIPropertyConfig;
    Application_HandrailLength: UIPropertyConfig;
    Application_IsFreeStandingWall: UIPropertyConfig;
    Application_IsOrographyRelevant: UIPropertyConfig;
    Application_Ld: UIPropertyConfig;
    Application_Lu: UIPropertyConfig;
    Application_NonCarryingLayer: UIPropertyConfig;
    Application_ReferenceWindVelocityId: UIPropertyConfig;
    Application_ReturnPeriod: UIPropertyConfig;
    Application_RoughnessClassId: UIPropertyConfig;
    Application_TerrainCategoryId: UIPropertyConfig;
    Application_TerrainType: UIPropertyConfig;
    Application_Windloads: UIPropertyConfig;
    Application_WindPressure: UIPropertyConfig;
    Application_WindUplift: UIPropertyConfig;
    Application_WindVelocityPressure: UIPropertyConfig;
    Application_WindZone: UIPropertyConfig;
    Application_X: UIPropertyConfig;
    Application_ZipCodeHandrailLoadsId: UIPropertyConfig;
    BaseMaterial_ActiveBeam: UIPropertyConfig;
    BaseMaterial_BalustradeHeight: UIPropertyConfig;
    BaseMaterial_BalustradeWidth: UIPropertyConfig;
    BaseMaterial_BeamLength: UIPropertyConfig;
    BaseMaterial_BeamLeverArm: UIPropertyConfig;
    BaseMaterial_BrickCharacteristic: UIPropertyConfig;
    BaseMaterial_BrickData: UIPropertyConfig;
    BaseMaterial_BrickGroup: UIPropertyConfig;
    BaseMaterial_BrickHeight: UIPropertyConfig;
    BaseMaterial_BrickLayout: UIPropertyConfig;
    BaseMaterial_BrickLayoutStartLeft: UIPropertyConfig;
    BaseMaterial_BrickLength: UIPropertyConfig;
    BaseMaterial_BrickSize: UIPropertyConfig;
    BaseMaterial_BrickSizeId: UIPropertyConfig;
    BaseMaterial_BrickStrength: UIPropertyConfig;
    BaseMaterial_BrickStrengthAC58: UIPropertyConfig;
    BaseMaterial_BrickStrengthId: UIPropertyConfig;
    BaseMaterial_BrickStrengthType: UIPropertyConfig;
    BaseMaterial_BrickWidth: UIPropertyConfig;
    BaseMaterial_CleaningMethod: UIPropertyConfig;
    BaseMaterial_ConcreteCharacteristic: UIPropertyConfig;
    BaseMaterial_ConcreteCharacteristic_Asad: UIPropertyConfig;
    BaseMaterial_ConcreteGrade: UIPropertyConfig;
    BaseMaterial_ConcreteGrade_Asad: UIPropertyConfig;
    BaseMaterial_ConcreteGradeType: UIPropertyConfig;
    BaseMaterial_ConcreteLengthNegative: UIPropertyConfig;
    BaseMaterial_ConcreteLengthNegativeAnchor: UIPropertyConfig;
    BaseMaterial_ConcreteLengthNegativeInfinity: UIPropertyConfig;
    BaseMaterial_ConcreteLengthNegativeInfinityAnchor: UIPropertyConfig;
    BaseMaterial_ConcreteLengthPositive: UIPropertyConfig;
    BaseMaterial_ConcreteLengthPositiveAnchor: UIPropertyConfig;
    BaseMaterial_ConcreteLengthPositiveInfinity: UIPropertyConfig;
    BaseMaterial_ConcreteLengthPositiveInfinityAnchor: UIPropertyConfig;
    BaseMaterial_ConcreteReinforcement: UIPropertyConfig;
    BaseMaterial_ConcreteType: UIPropertyConfig;
    BaseMaterial_ConstrainedEdgeBottom: UIPropertyConfig;
    BaseMaterial_ConstrainedEdgeLeft: UIPropertyConfig;
    BaseMaterial_ConstrainedEdgeRight: UIPropertyConfig;
    BaseMaterial_ConstrainedEdgeTop: UIPropertyConfig;
    BaseMaterial_CubeStrength: UIPropertyConfig;
    BaseMaterial_CubeStrength_Asad: UIPropertyConfig;
    BaseMaterial_CustomCompressiveStrength: UIPropertyConfig;
    BaseMaterial_CustomDensity: UIPropertyConfig;
    BaseMaterial_CustomElasticity: UIPropertyConfig;
    BaseMaterial_CustomJointMaterialStrength: UIPropertyConfig;
    BaseMaterial_CustomPoisson: UIPropertyConfig;
    BaseMaterial_CustomShearOfSteel: UIPropertyConfig;
    BaseMaterial_CylinderStrength: UIPropertyConfig;
    BaseMaterial_CylinderStrength_Asad: UIPropertyConfig;
    BaseMaterial_DeckThickness: UIPropertyConfig;
    BaseMaterial_DrillingMethod: UIPropertyConfig;
    BaseMaterial_DrillingMethod_Asad: UIPropertyConfig;
    BaseMaterial_EdgeReinforcement: UIPropertyConfig;
    BaseMaterial_EdgeReinforcementHNA: UIPropertyConfig;
    BaseMaterial_EdgeXNegative: UIPropertyConfig;
    BaseMaterial_EdgeXNegative_Asad: UIPropertyConfig;
    BaseMaterial_EdgeXNegativeFromAnchor: UIPropertyConfig;
    BaseMaterial_EdgeXPositive: UIPropertyConfig;
    BaseMaterial_EdgeXPositive_Asad: UIPropertyConfig;
    BaseMaterial_EdgeXPositiveFromAnchor: UIPropertyConfig;
    BaseMaterial_EdgeYNegative: UIPropertyConfig;
    BaseMaterial_EdgeYNegative_Asad: UIPropertyConfig;
    BaseMaterial_EdgeYNegativeFromAnchor: UIPropertyConfig;
    BaseMaterial_EdgeYPositive: UIPropertyConfig;
    BaseMaterial_EdgeYPositive_Asad: UIPropertyConfig;
    BaseMaterial_EdgeYPositiveFromAnchor: UIPropertyConfig;
    BaseMaterial_FasteningOption: UIPropertyConfig;
    BaseMaterial_HeadJointCompletelyFilled: UIPropertyConfig;
    BaseMaterial_HeadJointSelection: UIPropertyConfig;
    BaseMaterial_HoleCondition: UIPropertyConfig;
    BaseMaterial_HoleType: UIPropertyConfig;
    BaseMaterial_HoleType_Asad: UIPropertyConfig;
    BaseMaterial_InstallationDirection: UIPropertyConfig;
    BaseMaterial_IsAnchorReinforcement: UIPropertyConfig;
    BaseMaterial_IsAnchorReinforcementShear: UIPropertyConfig;
    BaseMaterial_IsAnchorReinforcementTension: UIPropertyConfig;
    BaseMaterial_IsCornerReinforcement: UIPropertyConfig;
    BaseMaterial_IsEdgeXNegativeFromAnchorReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeXNegativeLocked: UIPropertyConfig;
    BaseMaterial_IsEdgeXNegativeReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeXNegativeReinforced_Asad: UIPropertyConfig;
    BaseMaterial_IsEdgeXPositiveFromAnchorReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeXPositiveLocked: UIPropertyConfig;
    BaseMaterial_IsEdgeXPositiveReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeXPositiveReinforced_Asad: UIPropertyConfig;
    BaseMaterial_IsEdgeYNegativeFromAnchorReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeYNegativeLocked: UIPropertyConfig;
    BaseMaterial_IsEdgeYNegativeReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeYNegativeReinforced_Asad: UIPropertyConfig;
    BaseMaterial_IsEdgeYPositiveFromAnchorReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeYPositiveLocked: UIPropertyConfig;
    BaseMaterial_IsEdgeYPositiveReinforced: UIPropertyConfig;
    BaseMaterial_IsEdgeYPositiveReinforced_Asad: UIPropertyConfig;
    BaseMaterial_IsLightweightConcrete: UIPropertyConfig;
    BaseMaterial_IsLightweightConcrete_Asad: UIPropertyConfig;
    BaseMaterial_IsNonLoadBearingLayerPresent: UIPropertyConfig;
    BaseMaterial_IsPlasteredWall: UIPropertyConfig;
    BaseMaterial_IsSplittingReinforcement: UIPropertyConfig;
    BaseMaterial_JointMaterial: UIPropertyConfig;
    BaseMaterial_JointThicknessHorizontal: UIPropertyConfig;
    BaseMaterial_JointThicknessVertical: UIPropertyConfig;
    BaseMaterial_LightweightConcreteLambdaA: UIPropertyConfig;
    BaseMaterial_LightweightConcreteLambdaA_Asad: UIPropertyConfig;
    BaseMaterial_MasonryAnchorPosition: UIPropertyConfig;
    BaseMaterial_MasonryEdgeXNegative: UIPropertyConfig;
    BaseMaterial_MasonryEdgeXNegativeFromAnchor: UIPropertyConfig;
    BaseMaterial_MasonryEdgeXPositive: UIPropertyConfig;
    BaseMaterial_MasonryEdgeXPositiveFromAnchor: UIPropertyConfig;
    BaseMaterial_MasonryEdgeYNegative: UIPropertyConfig;
    BaseMaterial_MasonryEdgeYNegativeFromAnchor: UIPropertyConfig;
    BaseMaterial_MasonryEdgeYPositive: UIPropertyConfig;
    BaseMaterial_MasonryEdgeYPositiveFromAnchor: UIPropertyConfig;
    BaseMaterial_MasonryThickness: UIPropertyConfig;
    BaseMaterial_MetalDeck: UIPropertyConfig;
    BaseMaterial_MetalDeckAnchorOffset: UIPropertyConfig;
    BaseMaterial_MetalDeckAnchorPosition: UIPropertyConfig;
    BaseMaterial_MetalDeckFluteBaseWidth: UIPropertyConfig;
    BaseMaterial_MetalDeckFluteDepth: UIPropertyConfig;
    BaseMaterial_MetalDeckFluteDistance: UIPropertyConfig;
    BaseMaterial_MetalDeckFlutePeakWidth: UIPropertyConfig;
    BaseMaterial_MetalDeckLowerFluteWidth: UIPropertyConfig;
    BaseMaterial_MetalDeckTotalFluteDepth: UIPropertyConfig;
    BaseMaterial_MetalDeckType: UIPropertyConfig;
    BaseMaterial_MetalDeckUpperFluteWidth: UIPropertyConfig;
    BaseMaterial_NearestJointDistance: UIPropertyConfig;
    BaseMaterial_NonLoadBearingLayer: UIPropertyConfig;
    BaseMaterial_OffsetX: UIPropertyConfig;
    BaseMaterial_OffsetY: UIPropertyConfig;
    BaseMaterial_PlasterThickness: UIPropertyConfig;
    BaseMaterial_PlasterYoungsModulus: UIPropertyConfig;
    BaseMaterial_PrismStrength: UIPropertyConfig;
    BaseMaterial_ReinforcementConditionShear: UIPropertyConfig;
    BaseMaterial_ReinforcementConditionTension: UIPropertyConfig;
    BaseMaterial_StairHeight: UIPropertyConfig;
    BaseMaterial_StairLength: UIPropertyConfig;
    BaseMaterial_Temperature: UIPropertyConfig;
    BaseMaterial_TemperatureLongTerm: UIPropertyConfig;
    BaseMaterial_TemperatureLongTerm_Asad: UIPropertyConfig;
    BaseMaterial_TemperatureShortTerm: UIPropertyConfig;
    BaseMaterial_TemperatureShortTerm_Asad: UIPropertyConfig;
    BaseMaterial_Thickness: UIPropertyConfig;
    BaseMaterial_Thickness_Asad: UIPropertyConfig;
    BaseMaterial_TorquingType: UIPropertyConfig;
    BaseMaterial_UseCategory: UIPropertyConfig;
    BaseMaterial_VerticalJointsFilled: UIPropertyConfig;
    BaseMaterial_WHGApplication: UIPropertyConfig;
    BaseMaterial_WHGType: UIPropertyConfig;
    BaseMaterial_EdgeXNegativeFromAnchorPlateEdge: UIPropertyConfig;
    BaseMaterial_EdgeXNegativeFromConcreteEdge: UIPropertyConfig;
    BaseMaterial_EdgeXNegativeFromProfileCenter: UIPropertyConfig;
    BaseMaterial_EdgeXPositiveFromAnchorPlateEdge: UIPropertyConfig;
    BaseMaterial_EdgeXPositiveFromConcreteEdge: UIPropertyConfig;
    BaseMaterial_EdgeXPositiveFromProfileCenter: UIPropertyConfig;
    BaseMaterial_EdgeYNegativeFromAnchorPlateEdge: UIPropertyConfig;
    BaseMaterial_EdgeYNegativeFromConcreteEdge: UIPropertyConfig;
    BaseMaterial_EdgeYNegativeFromProfileCenter: UIPropertyConfig;
    BaseMaterial_EdgeYPositiveFromAnchorPlateEdge: UIPropertyConfig;
    BaseMaterial_EdgeYPositiveFromConcreteEdge: UIPropertyConfig;
    BaseMaterial_EdgeYPositiveFromProfileCenter: UIPropertyConfig;
    BaseplateThickness: UIPropertyConfig;
    Checkbot_IOM_BeamsDescription: UIPropertyConfig;
    ConstructionOptions_CorrosionResistant: UIPropertyConfig;
    ConstructionOptions_GalvanizedSteel: UIPropertyConfig;
    ConstructionOptions_MechanicallyGalvanized: UIPropertyConfig;
    ConstructionOptions_Sheradized: UIPropertyConfig;
    ConstructionOptions_StainlessSteel: UIPropertyConfig;
    Loads_AreFactoredSustainedLoadsActive: UIPropertyConfig;
    Loads_AreLoadCombinationsActive: UIPropertyConfig;
    Loads_CmdETABSImport: UIPropertyConfig;
    Loads_CmdExport: UIPropertyConfig;
    Loads_CmdImport: UIPropertyConfig;
    Loads_CmdRobotImport: UIPropertyConfig;
    Loads_CmdSAP2000Import: UIPropertyConfig;
    Loads_CmdStaadProImport: UIPropertyConfig;
    Loads_Combi_psi_0_h: UIPropertyConfig;
    Loads_Combi_psi_0_h_wind: UIPropertyConfig;
    Loads_Combi_psi_0_v: UIPropertyConfig;
    Loads_CompressiveStress: UIPropertyConfig;
    Loads_ConcreteEtagEnOnly: UIPropertyConfig;
    Loads_CustomizeDetails: UIPropertyConfig;
    Loads_DeadLoad: UIPropertyConfig;
    Loads_DeleteLoad: UIPropertyConfig;
    Loads_DesignMethodHNA: UIPropertyConfig;
    Loads_DesignMethodHNA_Asad: UIPropertyConfig;
    Loads_DesignMethodSelection: UIPropertyConfig;
    Loads_DlubalImportExport: UIPropertyConfig;
    Loads_EmergencyExits: UIPropertyConfig;
    Loads_ETABSImportExport: UIPropertyConfig;
    Loads_ExportRisa: UIPropertyConfig;
    Loads_FactoredSustainedLoadsFactor: UIPropertyConfig;
    Loads_FatigueLoadCycles: UIPropertyConfig;
    Loads_FatigueLoadType: UIPropertyConfig;
    Loads_FireDuration: UIPropertyConfig;
    Loads_FireExposure: UIPropertyConfig;
    Loads_FlatBarResistance: UIPropertyConfig;
    Loads_ForceX: UIPropertyConfig;
    Loads_ForceXVariable: UIPropertyConfig;
    Loads_ForceY: UIPropertyConfig;
    Loads_ForceYVariable: UIPropertyConfig;
    Loads_ForceZ: UIPropertyConfig;
    Loads_ForceZSustained: UIPropertyConfig;
    Loads_ForceZVariable: UIPropertyConfig;
    Loads_FundamentalWindVelocity: UIPropertyConfig;
    Loads_Gamma_perm_inf: UIPropertyConfig;
    Loads_Gamma_perm_sup: UIPropertyConfig;
    Loads_Gamma_var_h_bridge: UIPropertyConfig;
    Loads_Gamma_var_h_building: UIPropertyConfig;
    Loads_Gamma_var_h_wind: UIPropertyConfig;
    Loads_Gamma_var_h_wind_bridge: UIPropertyConfig;
    Loads_Gamma_var_v_bridge: UIPropertyConfig;
    Loads_Gamma_var_v_building: UIPropertyConfig;
    Loads_GammaZero: UIPropertyConfig;
    Loads_HandrailDesignMethodSelection: UIPropertyConfig;
    Loads_HandrailEtagEnOnly: UIPropertyConfig;
    Loads_HandrailMoreInfoOnSOFA: UIPropertyConfig;
    Loads_HandrailSafetyClass : UIPropertyConfig;
    Loads_HandrailStandOffDesignMethodSelection: UIPropertyConfig;
    Loads_ImportLoadCombinations: UIPropertyConfig;
    Loads_InfillLoadInwards: UIPropertyConfig;
    Loads_InfillLoadOutwards: UIPropertyConfig;
    Loads_InfillPunctualLoadInwards: UIPropertyConfig;
    Loads_InfillPunctualLoadOutwards: UIPropertyConfig;
    Loads_InputHandrailLoads: UIPropertyConfig;
    Loads_IsBridge: UIPropertyConfig;
    Loads_IsFatigueExpertMode: UIPropertyConfig;
    Loads_LinearLoadInwards: UIPropertyConfig;
    Loads_LinearLoadInwardsHeight: UIPropertyConfig;
    Loads_LinearLoadOutwards: UIPropertyConfig;
    Loads_LinearLoadOutwardsHeight: UIPropertyConfig;
    Loads_LoadCombinationHNAWizard: UIPropertyConfig;
    Loads_LoadCombinations: UIPropertyConfig;
    Loads_LoadCombinationsCalculationType: UIPropertyConfig;
    Loads_LoadsPositionZ: UIPropertyConfig;
    Loads_LoadType: UIPropertyConfig;
    Loads_MomentX: UIPropertyConfig;
    Loads_MomentXSustained: UIPropertyConfig;
    Loads_MomentXVariable: UIPropertyConfig;
    Loads_MomentY: UIPropertyConfig;
    Loads_MomentYSustained: UIPropertyConfig;
    Loads_MomentYVariable: UIPropertyConfig;
    Loads_MomentZ: UIPropertyConfig;
    Loads_MomentZVariable: UIPropertyConfig;
    Loads_MoreInfoOnFatigue: UIPropertyConfig;
    Loads_MoreInfoOnFire: UIPropertyConfig;
    Loads_MoreInfoOnSOFA: UIPropertyConfig;
    Loads_NewAndImportLoad: UIPropertyConfig;
    Loads_OnSiteTestsValues: UIPropertyConfig;
    Loads_OnSiteTestsValues_N_Rk: UIPropertyConfig;
    Loads_OnSiteTestsValues_V_Rk: UIPropertyConfig;
    Loads_OnSiteTestType: UIPropertyConfig;
    Loads_PhiNonductile: UIPropertyConfig;
    Loads_PunctualLoadInwards: UIPropertyConfig;
    Loads_PunctualLoadInwardsHeight: UIPropertyConfig;
    Loads_PunctualLoadInWorstPosition: UIPropertyConfig;
    Loads_PunctualLoadOutwards: UIPropertyConfig;
    Loads_PunctualLoadOutwardsHeight: UIPropertyConfig;
    Loads_PunctualLoadRailInwards: UIPropertyConfig;
    Loads_PunctualLoadRailOutwards: UIPropertyConfig;
    Loads_RobotImportExport: UIPropertyConfig;
    Loads_SafetyLevel: UIPropertyConfig;
    Loads_SAP2000ImportExport: UIPropertyConfig;
    Loads_SeismicCategory: UIPropertyConfig;
    Loads_SeismicDesignType: UIPropertyConfig;
    Loads_SeismicDisplacementsType: UIPropertyConfig;
    Loads_SeismicDLSShear: UIPropertyConfig;
    Loads_SeismicDLSTension: UIPropertyConfig;
    Loads_SeismicIntensityFactor: UIPropertyConfig;
    Loads_SeismicLoadContribution: UIPropertyConfig;
    Loads_SeismicShearType: UIPropertyConfig;
    Loads_SeismicTensionType: UIPropertyConfig;
    Loads_SelectedLoadCombination: UIPropertyConfig;
    Loads_ShearLoadDistributionType: UIPropertyConfig;
    Loads_ShowAllLoads: UIPropertyConfig;
    Loads_StaadProImportExport: UIPropertyConfig;
    Loads_StandOffDesignMethodSelection: UIPropertyConfig;
    Loads_StaticLoadType: UIPropertyConfig;
    Loads_StaticShearType: UIPropertyConfig;
    Loads_StaticTensionType: UIPropertyConfig;
    Loads_StructuralMember: UIPropertyConfig;
    Loads_UseLoadCombinations_SE: UIPropertyConfig;
    Loads_UseOnSiteTestsData: UIPropertyConfig;
    Loads_VerticalLoad: UIPropertyConfig;
    Loads_VerticalPunctualLoad: UIPropertyConfig;
    Loads_VerticalPunctualLoadInWorstPosition: UIPropertyConfig;
    Optimize_AnchorLayout: UIPropertyConfig;
    Optimize_AnchorOffset: UIPropertyConfig;
    Optimize_AnchorPlatePosition: UIPropertyConfig;
    Optimize_AnchorPlateSize: UIPropertyConfig;
    Optimize_AxialSpacing: UIPropertyConfig;
    Optimize_BaseMaterialThickness: UIPropertyConfig;
    Optimize_BaseplateSize: UIPropertyConfig;
    Optimize_EdgeDistance: UIPropertyConfig;
    Optimize_FindSolution: UIPropertyConfig;
    Optimize_PostDistance: UIPropertyConfig;
    OptimizeOnEmbedmentDepth: UIPropertyConfig;
    Option_AnchorPlateFactor: UIPropertyConfig;
    Option_ConcreteCapacityFactor: UIPropertyConfig;
    Option_ConcreteSafetyFactorGammaC: UIPropertyConfig;
    Option_CoordinateSystemCenter: UIPropertyConfig;
    Option_CoordinateSystemCenterX: UIPropertyConfig;
    Option_CoordinateSystemCenterY: UIPropertyConfig;
    Option_CustomPictures: UIPropertyConfig;
    Option_DesignMethodGroupId: UIPropertyConfig;
    Option_DesignMethodGroupId_Asad: UIPropertyConfig;
    Option_DesignName: UIPropertyConfig;
    Option_DesignStandard: UIPropertyConfig;
    Option_EdgeDistanceDisplayType: UIPropertyConfig;
    Option_ETAGOnly: UIPropertyConfig;
    Option_FaxNumber: UIPropertyConfig;
    Option_HandrailDisplacementLimit: UIPropertyConfig;
    Option_HandrailDisplacementLimitBridges: UIPropertyConfig;
    Option_INSteelSafetyFactorGammaM0: UIPropertyConfig;
    Option_INSteelSafetyFactorGammaM1: UIPropertyConfig;
    Option_INSteelSafetyFactorGammaMb: UIPropertyConfig;
    Option_INSteelSafetyFactorGammaMw: UIPropertyConfig;
    Option_MaterialSafetyFactor: UIPropertyConfig;
    Option_MeasureAnchorPlate: UIPropertyConfig;
    Option_MeasureBaseMaterialEdgeFrom: UIPropertyConfig;
    Option_MinimumAnchorToProfileDistance: UIPropertyConfig;
    Option_MinimumConcreteCover: UIPropertyConfig;
    Option_NoteCombinedTensionAndShearLoad: UIPropertyConfig;
    Option_NoteDisplacements: UIPropertyConfig;
    Option_NoteInstallationData: UIPropertyConfig;
    Option_NoteLoadCaseResultingAnchorForces: UIPropertyConfig;
    Option_NoteShearLoad: UIPropertyConfig;
    Option_NoteTensionLoad: UIPropertyConfig;
    Option_OstInputsEnabled: UIPropertyConfig;
    Option_RegionId: UIPropertyConfig;
    Option_ReportAddress: UIPropertyConfig;
    Option_ReportCompanyName: UIPropertyConfig;
    Option_ReportContactPerson: UIPropertyConfig;
    Option_ReportEmail: UIPropertyConfig;
    Option_ReportFasteningPoint: UIPropertyConfig;
    Option_ReportFirstPage: UIPropertyConfig;
    Option_ReportLanguageLcid: UIPropertyConfig;
    Option_ReportLoadCombination: UIPropertyConfig;
    Option_ReportNotes: UIPropertyConfig;
    Option_ReportPaperSizeId: UIPropertyConfig;
    Option_ReportPhoneNumber: UIPropertyConfig;
    Option_ReportTemplateId: UIPropertyConfig;
    Option_ReportTypeId: UIPropertyConfig;
    Option_SafetyFactorForPermamentLoads: UIPropertyConfig;
    Option_SafetyFactorForVariableLoads: UIPropertyConfig;
    Option_StainlessSteel: UIPropertyConfig;
    Option_SteelCapacityFactor: UIPropertyConfig;
    Option_SteelGuideline: UIPropertyConfig;
    Option_SteelSafetyFactorGammaM0: UIPropertyConfig;
    Option_SteelSafetyFactorGammaM1: UIPropertyConfig;
    Option_SteelSafetyFactorGammaM2: UIPropertyConfig;
    Option_SteelSafetyFactorGammaM5: UIPropertyConfig;
    Option_STOSteelSafetyFactorGammaC: UIPropertyConfig;
    Option_TrimbleConnectFolderId: UIPropertyConfig;
    Option_TrimbleConnectLocation: UIPropertyConfig;
    Option_TrimbleConnectReportName: UIPropertyConfig;
    Option_TrimbleConnectUpload: UIPropertyConfig;
    Option_UnitArea: UIPropertyConfig;
    Option_UnitAreaPerLength: UIPropertyConfig;
    Option_UnitDensity: UIPropertyConfig;
    Option_UnitForce: UIPropertyConfig;
    Option_UnitForcePerLength: UIPropertyConfig;
    Option_UnitLength: UIPropertyConfig;
    Option_UnitLengthLarge: UIPropertyConfig;
    Option_UnitMoment: UIPropertyConfig;
    Option_UnitMomentPerLength: UIPropertyConfig;
    Option_UnitStress: UIPropertyConfig;
    Option_UnitStressSmall: UIPropertyConfig;
    Option_UnitTemperature: UIPropertyConfig;
    Option_WeldsCapacityFactor: UIPropertyConfig;
    Profile_Angle: UIPropertyConfig;
    Profile_CmdEccentricityRotateAnticlockwise: UIPropertyConfig;
    Profile_CmdEccentricityRotateClockwise: UIPropertyConfig;
    Profile_CmdEccentricityRotatePostAnticlockwise: UIPropertyConfig;
    Profile_CmdEccentricityRotatePostClockwise: UIPropertyConfig;
    Profile_CustomFlangeThickness: UIPropertyConfig;
    Profile_CustomFlangeThickness_Asad: UIPropertyConfig;
    Profile_CustomHeight: UIPropertyConfig;
    Profile_CustomHeight_Asad: UIPropertyConfig;
    Profile_CustomProfileMaterial: UIPropertyConfig;
    Profile_CustomThickness: UIPropertyConfig;
    Profile_CustomThickness_Asad: UIPropertyConfig;
    Profile_CustomWidth: UIPropertyConfig;
    Profile_CustomWidth_Asad: UIPropertyConfig;
    Profile_CustomWidth2: UIPropertyConfig;
    Profile_DoubleFlatBarCustomWidth: UIPropertyConfig;
    Profile_FlangeWeldThickness: UIPropertyConfig;
    Profile_HandrailPostAngle: UIPropertyConfig;
    Profile_HandrailPostProfileFamilyId: UIPropertyConfig;
    Profile_HandrailRailAngle: UIPropertyConfig;
    Profile_HandrailRailCmdEccentricityRotateAnticlockwise: UIPropertyConfig;
    Profile_HandrailRailCmdEccentricityRotateClockwise: UIPropertyConfig;
    Profile_HandrailRailProfileFamilyId: UIPropertyConfig;
    Profile_HandrailRailProfileSizeId: UIPropertyConfig;
    Profile_HandrailRailProfileSizeName: UIPropertyConfig;
    Profile_HandrailRailProfileSteelType: UIPropertyConfig;
    Profile_HandrailSafetyDesign: UIPropertyConfig;
    Profile_HorizontalPostMaterialDensity: UIPropertyConfig;
    Profile_HorizontalPostMaterialEModulus: UIPropertyConfig;
    Profile_HorizontalPostMaterialPoisson: UIPropertyConfig;
    Profile_HorizontalPostMaterialUltimateStrength: UIPropertyConfig;
    Profile_HorizontalPostMaterialYieldStrength: UIPropertyConfig;
    Profile_IsProfileMaterialCustom: UIPropertyConfig;
    Profile_MaterialDensity: UIPropertyConfig;
    Profile_MaterialEModulus: UIPropertyConfig;
    Profile_MaterialPoisson: UIPropertyConfig;
    Profile_MaterialUltimateStrength: UIPropertyConfig;
    Profile_MaterialYieldStrength: UIPropertyConfig;
    Profile_OffsetX: UIPropertyConfig;
    Profile_OffsetY: UIPropertyConfig;
    Profile_PostMaterialDensity: UIPropertyConfig;
    Profile_PostMaterialEModulus: UIPropertyConfig;
    Profile_PostMaterialPoisson: UIPropertyConfig;
    Profile_PostMaterialUltimateStrength: UIPropertyConfig;
    Profile_PostMaterialYieldStrength: UIPropertyConfig;
    Profile_ProfileFamilyId: UIPropertyConfig;
    Profile_ProfileFamilyId_Asad: UIPropertyConfig;
    Profile_ProfileSizeId: UIPropertyConfig;
    Profile_ProfileSizeId_Asad: UIPropertyConfig;
    Profile_ProfileSizeName: UIPropertyConfig;
    Profile_RailMaterialDensity: UIPropertyConfig;
    Profile_RailMaterialEModulus: UIPropertyConfig;
    Profile_RailMaterialPoisson: UIPropertyConfig;
    Profile_RailMaterialUltimateStrength: UIPropertyConfig;
    Profile_RailMaterialYieldStrength: UIPropertyConfig;
    Profile_ShearLugAngle: UIPropertyConfig;
    Profile_ShearLugCmdRotateAnticlockwise: UIPropertyConfig;
    Profile_ShearLugCmdRotateClockwise: UIPropertyConfig;
    Profile_ShearLugCustomDepth: UIPropertyConfig;
    Profile_ShearLugCustomLength: UIPropertyConfig;
    Profile_ShearLugCustomThickness: UIPropertyConfig;
    Profile_ShearLugCustomWidth: UIPropertyConfig;
    Profile_ShearLugFamilyId: UIPropertyConfig;
    Profile_ShearLugSizeId: UIPropertyConfig;
    Profile_ShearLugSizeName: UIPropertyConfig;
    Profile_ShowWeldDesign: UIPropertyConfig;
    Profile_SteelType: UIPropertyConfig;
    Profile_UseProfile: UIPropertyConfig;
    Profile_UseShearLug: UIPropertyConfig;
    Profile_Utilization: UIPropertyConfig;
    Profile_WebWeldLocation: UIPropertyConfig;
    Profile_WebWeldMaterial: UIPropertyConfig;
    Profile_WebWeldMaterialBetaW: UIPropertyConfig;
    Profile_WebWeldMaterialDensity: UIPropertyConfig;
    Profile_WebWeldMaterialEModulus: UIPropertyConfig;
    Profile_WebWeldMaterialPoisson: UIPropertyConfig;
    Profile_WebWeldMaterialUltimateStrength: UIPropertyConfig;
    Profile_WebWeldMaterialYieldStrength: UIPropertyConfig;
    Profile_WebWeldThickness: UIPropertyConfig;
    Profile_HorizontalPostProfileConnection: UIPropertyConfig;
    Profile_HorizontalPostProfileFamilyId: UIPropertyConfig;
    Profile_HorizontalPostProfileSizeId: UIPropertyConfig;
    Profile_HorizontalPostProfileSizeName: UIPropertyConfig;
    Profile_HorizontalPostSteelTypeHandrail: UIPropertyConfig;
    Profile_IsHorizontalPostProfilePresent: UIPropertyConfig;
    Profile_SteelTypeHandrail: UIPropertyConfig;
    Profile_UsePinConnection: UIPropertyConfig;
    ProjectDesignType: UIPropertyConfig;
    ProjectName: UIPropertyConfig;
    SmartAnchor_AddMoreUserSelectedAnchors: UIPropertyConfig;
    SmartAnchor_AnchorGroupsForAsad: UIPropertyConfig;
    SmartAnchor_Application: UIPropertyConfig;
    SmartAnchor_Category: UIPropertyConfig;
    SmartAnchor_CurrentlySelectedAnchor: UIPropertyConfig;
    SmartAnchor_Enabled: UIPropertyConfig;
    SmartAnchor_EnableTco: UIPropertyConfig;
    SmartAnchor_IsLastSelectedSolution: UIPropertyConfig;
    SmartAnchor_OptimizationOptions_AnchorPosition: UIPropertyConfig;
    SmartAnchor_OptimizationOptions_ProfilePosition: UIPropertyConfig;
    SmartAnchor_OptimizationOptions_UtilizationSlider: UIPropertyConfig;
    SmartAnchor_PlateHidden: UIPropertyConfig;
    SmartAnchor_SelectCategoryAndApplication: UIPropertyConfig;
    SmartAnchor_SelectPreferredAnchor: UIPropertyConfig;
    SmartAnchor_ShowMore: UIPropertyConfig;
    SmartAnchor_SuggestedAnchorFamily: UIPropertyConfig;
    SmartAnchor_SuggestedAnchorSize: UIPropertyConfig;
    SmartAnchor_Tco_AnchorDiscount: UIPropertyConfig;
    SmartAnchor_Tco_SteelPrice: UIPropertyConfig;
    SmartAnchor_Tco_WorkPrice: UIPropertyConfig;
    SmartAnchor_Text_ConcreteReinforcement: UIPropertyConfig;
    SmartAnchor_Text_CorrisionMaterial: UIPropertyConfig;
    SmartAnchor_Text_InstallationCondition: UIPropertyConfig;
    SmartAnchor_Text_Temprature: UIPropertyConfig;
    SmartAnchor_UserSelectedAnchorGroupsForAsad: UIPropertyConfig;
    SmartAnchor_UserSelectedAnchorSizes: UIPropertyConfig;
    Stiffener_CustomStiffener: UIPropertyConfig;
    SupplementaryReinforcement_ApplyTensionValuesToShear: UIPropertyConfig;
    SupplementaryReinforcement_CBottomEffective: UIPropertyConfig;
    SupplementaryReinforcement_CEdge: UIPropertyConfig;
    SupplementaryReinforcement_CEdgeEffective: UIPropertyConfig;
    SupplementaryReinforcement_CEdgeEffectiveLeft: UIPropertyConfig;
    SupplementaryReinforcement_CEdgeEffectiveRight: UIPropertyConfig;
    SupplementaryReinforcement_CmdRotateAnticlockwise: UIPropertyConfig;
    SupplementaryReinforcement_CmdRotateClockwise: UIPropertyConfig;
    SupplementaryReinforcement_ConcreteReinforcement: UIPropertyConfig;
    SupplementaryReinforcement_ConcreteReinforcement_Asad: UIPropertyConfig;
    SupplementaryReinforcement_CTop: UIPropertyConfig;
    SupplementaryReinforcement_CTopEffective: UIPropertyConfig;
    SupplementaryReinforcement_EdgeReinforcement: UIPropertyConfig;
    SupplementaryReinforcement_EdgeReinforcement_Asad: UIPropertyConfig;
    SupplementaryReinforcement_FirstRebarCenterOffset: UIPropertyConfig;
    SupplementaryReinforcement_Height: UIPropertyConfig;
    SupplementaryReinforcement_IsSplittingReinforcement: UIPropertyConfig;
    SupplementaryReinforcement_IsSplittingReinforcement_Asad: UIPropertyConfig;
    SupplementaryReinforcement_PositionTolerance: UIPropertyConfig;
    SupplementaryReinforcement_ReinforcementCategory: UIPropertyConfig;
    SupplementaryReinforcement_ReinforcementDiameter: UIPropertyConfig;
    SupplementaryReinforcement_ReinforcementSpacing: UIPropertyConfig;
    SupplementaryReinforcement_ReinforcementType: UIPropertyConfig;
    SupplementaryReinforcement_ReinforcementUtilization: UIPropertyConfig;
    SupplementaryReinforcement_Rotate: UIPropertyConfig;
    SupplementaryReinforcement_SurfaceNumber: UIPropertyConfig;
    SupplementaryReinforcement_SurfaceReinforcement: UIPropertyConfig;
    SupplementaryReinforcement_SurfaceUtilization: UIPropertyConfig;
    SupplementaryReinforcement_TensionLeftWidth: UIPropertyConfig;
    SupplementaryReinforcement_TensionReinforcementSpacing: UIPropertyConfig;
    SupplementaryReinforcement_TensionRightWidth: UIPropertyConfig;
    SupplementaryReinforcement_Width: UIPropertyConfig;
    SupplementaryReinforcement_YieldStrength: UIPropertyConfig;
    SupplementaryReinforcement_DirectionOfCasting: UIPropertyConfig;
    Update_PhasedOutFastenerFamily: UIPropertyConfig;
}

export type UIPropertyId =
0 | 3200 | 1052 | 2006 | 1015 | 1012 | 1011 | 1003 | 880 | 1002 | 1005 | 1004 |
1017 | 1016 | 1014 | 1013 | 1001 | 1053 | 201 | 216 | 2260 | 450 |
851 | 1057 | 2258 | 790 | 779 | 789 | 2262 | 778 | 811 | 2123 |
2103 | 2102 | 2101 | 204 | 203 | 3231 | 215 | 2000 | 2002 | 213 |
214 | 212 | 2059 | 206 | 205 | 2100 | 2099 | 2089 | 1165 | 447 |
209 | 200 | 736 | 874 | 451 | 452 | 428 | 429 | 2263 | 140 |
500 | 798 | 2259 | 211 | 2256 | 120 | 130 | 2001 | 210 | 2255 |
519 | 1058 | 2257 | 3227 | 448 | 449 | 2003 | 1019 | 1020 | 749 |
1018 | 108 | 107 | 112 | 2130 | 115 | 1109 | 1110 | 104 | 2070 |
2063 | 2061 | 2060 | 2069 | 2067 | 2066 | 2068 | 2064 | 2065 | 2071 |
100 | 1164 | 1086 | 1042 | 1041 | 1135 | 1133 | 1132 | 1134 | 1130 |
1131 | 1040 | 113 | 2131 | 102 | 512 | 1112 | 1113 | 2086 | 2083 |
2085 | 2084 | 2080 | 2081 | 2082 | 2088 | 1122 | 1121 | 1123 | 1119 |
1120 | 1043 | 1044 | 813 | 106 | 735 | 510 | 757 | 681 | 103 |
110 | 2128 | 2261 | 111 | 2129 | 114 | 2132 | 1045 | 1028 | 1029 |
1046 | 2012 | 1022 | 1139 | 1138 | 1140 | 1136 | 1137 | 1077 | 1023 |
1024 | 1030 | 1162 | 1088 | 1034 | 1032 | 1146 | 1144 | 1143 | 1145 |
1141 | 1142 | 1033 | 1027 | 1054 | 109 | 743 | 2150 | 1163 | 1087 |
1038 | 1037 | 1129 | 1127 | 1126 | 1128 | 1124 | 1125 | 1036 | 1085 |
101 | 686 | 3205 | 720 | 642 | 1096 | 723 | 712 | 640 | 639 |
643 | 696 | 695 | 694 | 697 | 748 | 722 | 692 | 1097 | 1098 |
1095 | 764 | 763 | 1099 | 1094 | 765 | 2058 | 726 | 721 | 725 |
637 | 714 | 709 | 699 | 698 | 718 | 716 | 715 | 758 | 727 |
1062 | 724 | 719 | 713 | 690 | 1091 | 1092 | 728 | 693 | 717 |
1105 | 751 | 670 | 671 | 665 | 666 | 2153 | 638 | 529 | 611 |
530 | 619 | 612 | 613 | 2152 | 533 | 2254 | 532 | 531 | 610 |
631 | 1 | 2110 | 2 | 2111 | 85 | 669 | 786 | 680 | 788 |
668 | 785 | 679 | 787 | 14 | 3228 | 618 | 616 | 615 | 617 |
807 | 2168 | 3 | 1101 | 1103 | 2252 | 1102 | 1104 | 808 | 2167 |
831 | 12 | 2126 | 15 | 92 | 35 | 2114 | 39 | 34 | 2113 |
38 | 37 | 2116 | 41 | 36 | 2115 | 40 | 632 | 2004 | 2264 |
446 | 13 | 2127 | 1084 | 86 | 88 | 87 | 89 | 47 | 51 |
43 | 2118 | 46 | 50 | 42 | 2117 | 49 | 53 | 45 | 2120 |
48 | 52 | 44 | 2119 | 453 | 2161 | 759 | 605 | 16 | 602 |
601 | 600 | 454 | 2162 | 837 | 634 | 738 | 633 | 737 | 636 |
740 | 635 | 739 | 872 | 869 | 830 | 826 | 827 | 824 | 821 |
828 | 822 | 829 | 825 | 823 | 873 | 676 | 674 | 675 | 606 |
607 | 809 | 90 | 91 | 672 | 673 | 835 | 11 | 2125 | 10 |
2124 | 33 | 2112 | 877 | 630 | 614 | 2148 | 2149 | 848 | 844 |
840 | 847 | 843 | 839 | 850 | 846 | 842 | 849 | 845 | 841 |
3203 | 2943 | 2098 | 2094 | 2096 | 2095 | 2097 | 1055 | 420 | 1072 |
756 | 755 | 1066 | 1063 | 1080 | 818 | 820 | 819 | 604 | 879 |
652 | 641 | 2041 | 477 | 2155 | 878 | 805 | 760 | 1071 | 1061 |
1056 | 2266 | 708 | 416 | 413 | 1100 | 400 | 406 | 401 | 407 |
402 | 1067 | 408 | 871 | 654 | 653 | 657 | 655 | 659 | 761 |
658 | 656 | 2147 | 1059 | 1060 | 2160 | 1167 | 2157 | 518 | 855 |
854 | 1090 | 1089 | 651 | 834 | 2005 | 645 | 647 | 644 | 646 |
838 | 513 | 780 | 2087 | 734 | 403 | 1068 | 409 | 404 | 1069 |
410 | 405 | 411 | 2265 | 3229 | 2159 | 2015 | 859 | 860 | 861 |
862 | 466 | 649 | 753 | 857 | 648 | 752 | 876 | 875 | 1065 |
2146 | 1064 | 419 | 412 | 511 | 415 | 414 | 2144 | 417 | 468 |
467 | 514 | 2267 | 710 | 1079 | 2156 | 418 | 731 | 730 | 2145 |
1166 | 858 | 650 | 856 | 853 | 534 | 812 | 521 | 520 | 522 |
523 | 528 | 524 | 833 | 810 | 3204 | 769 | 1074 | 2014 | 795 |
796 | 797 | 2158 | 526 | 3230 | 434 | 444 | 781 | 432 | 474 |
832 | 1106 | 2008 | 2009 | 2011 | 2010 | 1049 | 3208 | 3209 | 806 |
2040 | 802 | 803 | 804 | 799 | 801 | 800 | 1051 | 443 | 472 |
471 | 470 | 475 | 469 | 463 | 462 | 608 | 465 | 460 | 473 |
464 | 461 | 770 | 771 | 817 | 1073 | 2007 | 814 | 815 | 816 |
870 | 1083 | 794 | 792 | 793 | 791 | 438 | 2044 | 1107 | 440 |
677 | 437 | 767 | 441 | 1078 | 439 | 768 | 442 | 1070 | 308 |
733 | 732 | 777 | 776 | 304 | 2165 | 302 | 2163 | 1108 | 307 |
2166 | 303 | 2164 | 1081 | 3206 | 705 | 775 | 746 | 772 | 774 |
773 | 742 | 744 | 745 | 747 | 754 | 1160 | 1159 | 1161 | 1157 |
1158 | 1111 | 1117 | 1116 | 1118 | 1114 | 1115 | 305 | 306 | 1155 |
1154 | 1156 | 1152 | 1153 | 300 | 2121 | 301 | 2122 | 320 | 1150 |
1149 | 1151 | 1147 | 1148 | 3218 | 3220 | 3219 | 3215 | 3212 | 3214 |
3213 | 3211 | 3216 | 3217 | 701 | 702 | 741 | 3210 | 704 | 2136 |
2137 | 2143 | 2141 | 2140 | 2142 | 2138 | 2139 | 706 | 864 | 868 |
865 | 867 | 866 | 863 | 750 | 1093 | 445 | 435 | 3226 | 3207 |
2046 | 2045 | 2050 | 2090 | 2169 | 3232 | 3199 | 3198 | 3197 | 3182 |
3224 | 3221 | 2049 | 2048 | 2047 | 2172 | 2170 | 2171 | 2092 | 9999 |
2091 | 2093 | 3223 | 3225 | 430 | 431 | 2013 | 2052 | 2042 | 2033 |
2035 | 2056 | 2057 | 2025 | 2024 | 2030 | 2133 | 2034 | 2036 | 2031 |
2134 | 2039 | 2037 | 2032 | 2135 | 2021 | 2016 | 2018 | 2027 | 2017 |
2028 | 2023 | 2020 | 2019 | 2029 | 2055 | 2053 | 2054 | 2038 | 2026 |
2022 | 2043;

export type UIPropertyName =
'None' | 'AdditionalFiltersButton' | 'AdvancedBaseplateCalculation_AlphaCC' | 'AdvancedBaseplateCalculation_ConcreteInCompressionMethod' | 'AdvancedBaseplateCalculation_DivergentIterationsCount' | 'AdvancedBaseplateCalculation_DivisionOfArcsOfRHS' | 'AdvancedBaseplateCalculation_DivisionOfSurfaceOfCHS' | 'AdvancedBaseplateCalculation_EffectiveArea' | 'AdvancedBaseplateCalculation_EffectiveAreaAISC' | 'AdvancedBaseplateCalculation_JointCoefficientBj' | 'AdvancedBaseplateCalculation_LimitPlasticStrain' | 'AdvancedBaseplateCalculation_LoadDistributionAngle' |
'AdvancedBaseplateCalculation_MaximumSizeOfElement' | 'AdvancedBaseplateCalculation_MinimumSizeOfElement' | 'AdvancedBaseplateCalculation_NumberOfAnalysisIterations' | 'AdvancedBaseplateCalculation_NumberOfElementsOfEdge' | 'AdvancedBaseplateCalculation_StopAtLimitStrain' | 'AdvancedBaseplateCalculation_UseULSStresses' | 'AnchorLayout_AnchorFamily' | 'AnchorLayout_AnchorFilters' | 'AnchorLayout_AnchorLayoutSymmetry' | 'AnchorLayout_AnchorTorqueType' |
'AnchorLayout_ApprovalLanguages' | 'AnchorLayout_ApprovalLanguages_STO' | 'AnchorLayout_ApprovalLanguages_UKTA' | 'AnchorLayout_ArticleNumberCapsule' | 'AnchorLayout_ArticleNumberChemical' | 'AnchorLayout_ArticleNumberInsert' | 'AnchorLayout_ArticleNumberInsertAlternative' | 'AnchorLayout_ArticleNumberMechanical' | 'AnchorLayout_ArticleNumberSieveSleeve' | 'AnchorLayout_CipAh' |
'AnchorLayout_CipK8' | 'AnchorLayout_CipMinimumEdgeDistance' | 'AnchorLayout_CipMinSpacing' | 'AnchorLayout_CmdRotateAnticlockwise' | 'AnchorLayout_CmdRotateClockwise' | 'AnchorLayout_CorrosionMaterial' | 'AnchorLayout_CustomLayoutPoints' | 'AnchorLayout_ElasticModulus' | 'AnchorLayout_ElongationLength' | 'AnchorLayout_EmbedmentDepthFixedMultiple' |
'AnchorLayout_EmbedmentDepthOptimizationType' | 'AnchorLayout_EmbedmentDepthVariable' | 'AnchorLayout_Fastener' | 'AnchorLayout_FillHolesETAG' | 'AnchorLayout_FillHolesSOFA' | 'AnchorLayout_HeadDiameter' | 'AnchorLayout_HeadThickness' | 'AnchorLayout_Hnom' | 'AnchorLayout_IsAnchorENFatigueCompliant' | 'AnchorLayout_IsNutAndWasher' |
'AnchorLayout_IsReducedInstallTorque' | 'AnchorLayout_Layout' | 'AnchorLayout_LayoutCustom' | 'AnchorLayout_MasonryFastenerConfiguration' | 'AnchorLayout_MinConcreteCover' | 'AnchorLayout_MinRebarSize' | 'AnchorLayout_OffsetXFromAnchorPlate' | 'AnchorLayout_OffsetYFromAnchorPlate' | 'AnchorLayout_OnlyUKTAApprovals' | 'AnchorLayout_Radius' |
'AnchorLayout_Rotate' | 'AnchorLayout_SeismicFillingSet' | 'AnchorLayout_ShowFullInternalPortfolio' | 'AnchorLayout_Size' | 'AnchorLayout_SizeAC58' | 'AnchorLayout_Spacing_SX' | 'AnchorLayout_Spacing_SY' | 'AnchorLayout_TensileArea' | 'AnchorLayout_Type' | 'AnchorLayout_TypeAC58' |
'AnchorLayout_ViewApproval' | 'AnchorLayout_ViewApproval_STO' | 'AnchorLayout_ViewApproval_UKTA' | 'AnchorLayout_ViewCSTBDocument' | 'AnchorLayout_WasherDiameter' | 'AnchorLayout_WasherThickness' | 'AnchorPlate_AdjustThickness' | 'AnchorPlate_AdvancedCalculation' | 'AnchorPlate_AdvancedSettings' | 'AnchorPlate_AnchorPlateSize' |
'AnchorPlate_CalculationType' | 'AnchorPlate_CmdRotateAnticlockwise' | 'AnchorPlate_CmdRotateClockwise' | 'AnchorPlate_CustomGroutCompressiveStrength' | 'AnchorPlate_CustomGroutCompressiveStrength_Asad' | 'AnchorPlate_CustomLayoutPoints' | 'AnchorPlate_CustomPlateMaterial' | 'AnchorPlate_CustomStiffenerMaterial' | 'AnchorPlate_Diameter' | 'AnchorPlate_DirectWeldGravity' |
'AnchorPlate_DirectWeldLength' | 'AnchorPlate_DirectWeldLocation' | 'AnchorPlate_DirectWeldMaterial' | 'AnchorPlate_DirectWeldMaterialBetaW' | 'AnchorPlate_DirectWeldMaterialDensity' | 'AnchorPlate_DirectWeldMaterialEModulus' | 'AnchorPlate_DirectWeldMaterialPoisson' | 'AnchorPlate_DirectWeldMaterialUltimateStrength' | 'AnchorPlate_DirectWeldMaterialYieldStrength' | 'AnchorPlate_DirectWeldThickness' |
'AnchorPlate_Exists' | 'AnchorPlate_FlangeWeldGravity' | 'AnchorPlate_FlangeWeldLegLength' | 'AnchorPlate_FlangeWeldLocation' | 'AnchorPlate_FlangeWeldMaterial' | 'AnchorPlate_FlangeWeldMaterialBetaW' | 'AnchorPlate_FlangeWeldMaterialDensity' | 'AnchorPlate_FlangeWeldMaterialEModulus' | 'AnchorPlate_FlangeWeldMaterialPoisson' | 'AnchorPlate_FlangeWeldMaterialUltimateStrength' |
'AnchorPlate_FlangeWeldMaterialYieldStrength' | 'AnchorPlate_FlangeWeldThickness' | 'AnchorPlate_Grout' | 'AnchorPlate_Grout_Asad' | 'AnchorPlate_Height' | 'AnchorPlate_IsCompressiveStrengthOfMortarHigh' | 'AnchorPlate_IsPlateMaterialCustom' | 'AnchorPlate_IsStiffenerMaterialCustom' | 'AnchorPlate_LedgerAngleLength' | 'AnchorPlate_LedgerAngleProfileCustomHeight' |
'AnchorPlate_LedgerAngleProfileCustomThickness' | 'AnchorPlate_LedgerAngleProfileCustomWidth' | 'AnchorPlate_LedgerAngleProfileFamilyId' | 'AnchorPlate_LedgerAngleProfileSizeId' | 'AnchorPlate_LedgerAngleProfileSizeName' | 'AnchorPlate_LedgerAngleShape' | 'AnchorPlate_MaterialDensity' | 'AnchorPlate_MaterialEModulus' | 'AnchorPlate_MaterialPoisson' | 'AnchorPlate_MaterialUltimateStrength' |
'AnchorPlate_MaterialYieldStrength' | 'AnchorPlate_NotOwnedLearnMore' | 'AnchorPlate_NotOwnedLearnMoreInfo' | 'AnchorPlate_OptimizedThicknessMessage' | 'AnchorPlate_PlateShape' | 'AnchorPlate_PlateShapeCustom' | 'AnchorPlate_Rotate' | 'AnchorPlate_ShowOptimizedAnchorPlateThickness' | 'AnchorPlate_ShowStressDistribution' | 'AnchorPlate_SideLength' |
'AnchorPlate_StandoffDistance' | 'AnchorPlate_StandoffDistance_Asad' | 'AnchorPlate_StandoffNutAndWasherHeight' | 'AnchorPlate_StandoffRestraintLevel' | 'AnchorPlate_StandoffRestraintLevel_Asad' | 'AnchorPlate_StandoffType' | 'AnchorPlate_StandoffType_Asad' | 'AnchorPlate_StiffenerCount' | 'AnchorPlate_StiffenerHeight' | 'AnchorPlate_StiffenerHorizontalEdgeLength' |
'AnchorPlate_StiffenerLayout' | 'AnchorPlate_StiffenerLayoutCustom' | 'AnchorPlate_StiffenerMaterial' | 'AnchorPlate_StiffenerMaterialDensity' | 'AnchorPlate_StiffenerMaterialEModulus' | 'AnchorPlate_StiffenerMaterialPoisson' | 'AnchorPlate_StiffenerMaterialUltimateStrength' | 'AnchorPlate_StiffenerMaterialYieldStrength' | 'AnchorPlate_StiffenerRadialOffset' | 'AnchorPlate_StiffenerShape' |
'AnchorPlate_StiffenerThickness' | 'AnchorPlate_StiffenerVerticalEdgeLength' | 'AnchorPlate_StiffenerWeldGravity' | 'AnchorPlate_StiffenerWeldLegLength' | 'AnchorPlate_StiffenerWeldLocation' | 'AnchorPlate_StiffenerWeldMaterial' | 'AnchorPlate_StiffenerWeldMaterialBetaW' | 'AnchorPlate_StiffenerWeldMaterialDensity' | 'AnchorPlate_StiffenerWeldMaterialEModulus' | 'AnchorPlate_StiffenerWeldMaterialPoisson' |
'AnchorPlate_StiffenerWeldMaterialUltimateStrength' | 'AnchorPlate_StiffenerWeldMaterialYieldStrength' | 'AnchorPlate_StiffenerWeldThickness' | 'AnchorPlate_StiffenerWidth' | 'AnchorPlate_StressImage' | 'AnchorPlate_Thickness' | 'AnchorPlate_UseBaseplate' | 'AnchorPlate_VerifyDiaphragmShear' | 'AnchorPlate_WebWeldGravity' | 'AnchorPlate_WebWeldLegLength' |
'AnchorPlate_WebWeldLocation' | 'AnchorPlate_WebWeldMaterial' | 'AnchorPlate_WebWeldMaterialBetaW' | 'AnchorPlate_WebWeldMaterialDensity' | 'AnchorPlate_WebWeldMaterialEModulus' | 'AnchorPlate_WebWeldMaterialPoisson' | 'AnchorPlate_WebWeldMaterialUltimateStrength' | 'AnchorPlate_WebWeldMaterialYieldStrength' | 'AnchorPlate_WebWeldThickness' | 'AnchorPlate_WeldingType' |
'AnchorPlate_Width' | 'AnchorPlate_SteelType' | 'AnchorUserChosen' | 'Application_Altitude' | 'Application_Application' | 'Application_BarsSpacing' | 'Application_BasicWindVelocityId' | 'Application_BeamBottomSegment' | 'Application_BeamToBeamDistance' | 'Application_BeamType' |
'Application_BuildingCategory' | 'Application_BuildingDepth' | 'Application_BuildingHeight' | 'Application_BuildingWidth' | 'Application_BuildingZone' | 'Application_CalculatePostAndRailDeflection' | 'Application_CityAndStateId' | 'Application_Cladding' | 'Application_CladdingBarOffsetFromFloor' | 'Application_CladdingBarOffsetFromRail' |
'Application_CladdingBarsCount' | 'Application_CladdingHeight' | 'Application_CladdingOffset' | 'Application_CladdingProfile' | 'Application_CladdingType' | 'Application_CladdingWidth' | 'Application_ConnectionType' | 'Application_DistanceInsideTownTerrainId' | 'Application_DistanceToSeaside' | 'Application_DistanceUpwindToShorelineId' |
'Application_EnvironmentType' | 'Application_H' | 'Application_HandrailHeightOverGround' | 'Application_HandrailLength' | 'Application_IsFreeStandingWall' | 'Application_IsOrographyRelevant' | 'Application_Ld' | 'Application_Lu' | 'Application_NonCarryingLayer' | 'Application_ReferenceWindVelocityId' |
'Application_ReturnPeriod' | 'Application_RoughnessClassId' | 'Application_TerrainCategoryId' | 'Application_TerrainType' | 'Application_Windloads' | 'Application_WindPressure' | 'Application_WindUplift' | 'Application_WindVelocityPressure' | 'Application_WindZone' | 'Application_X' |
'Application_ZipCodeHandrailLoadsId' | 'BaseMaterial_ActiveBeam' | 'BaseMaterial_BalustradeHeight' | 'BaseMaterial_BalustradeWidth' | 'BaseMaterial_BeamLength' | 'BaseMaterial_BeamLeverArm' | 'BaseMaterial_BrickCharacteristic' | 'BaseMaterial_BrickData' | 'BaseMaterial_BrickGroup' | 'BaseMaterial_BrickHeight' |
'BaseMaterial_BrickLayout' | 'BaseMaterial_BrickLayoutStartLeft' | 'BaseMaterial_BrickLength' | 'BaseMaterial_BrickSize' | 'BaseMaterial_BrickSizeId' | 'BaseMaterial_BrickStrength' | 'BaseMaterial_BrickStrengthAC58' | 'BaseMaterial_BrickStrengthId' | 'BaseMaterial_BrickStrengthType' | 'BaseMaterial_BrickWidth' |
'BaseMaterial_CleaningMethod' | 'BaseMaterial_ConcreteCharacteristic' | 'BaseMaterial_ConcreteCharacteristic_Asad' | 'BaseMaterial_ConcreteGrade' | 'BaseMaterial_ConcreteGrade_Asad' | 'BaseMaterial_ConcreteGradeType' | 'BaseMaterial_ConcreteLengthNegative' | 'BaseMaterial_ConcreteLengthNegativeAnchor' | 'BaseMaterial_ConcreteLengthNegativeInfinity' | 'BaseMaterial_ConcreteLengthNegativeInfinityAnchor' |
'BaseMaterial_ConcreteLengthPositive' | 'BaseMaterial_ConcreteLengthPositiveAnchor' | 'BaseMaterial_ConcreteLengthPositiveInfinity' | 'BaseMaterial_ConcreteLengthPositiveInfinityAnchor' | 'BaseMaterial_ConcreteReinforcement' | 'BaseMaterial_ConcreteType' | 'BaseMaterial_ConstrainedEdgeBottom' | 'BaseMaterial_ConstrainedEdgeLeft' | 'BaseMaterial_ConstrainedEdgeRight' | 'BaseMaterial_ConstrainedEdgeTop' |
'BaseMaterial_CubeStrength' | 'BaseMaterial_CubeStrength_Asad' | 'BaseMaterial_CustomCompressiveStrength' | 'BaseMaterial_CustomDensity' | 'BaseMaterial_CustomElasticity' | 'BaseMaterial_CustomJointMaterialStrength' | 'BaseMaterial_CustomPoisson' | 'BaseMaterial_CustomShearOfSteel' | 'BaseMaterial_CylinderStrength' | 'BaseMaterial_CylinderStrength_Asad' |
'BaseMaterial_DeckThickness' | 'BaseMaterial_DrillingMethod' | 'BaseMaterial_DrillingMethod_Asad' | 'BaseMaterial_EdgeReinforcement' | 'BaseMaterial_EdgeReinforcementHNA' | 'BaseMaterial_EdgeXNegative' | 'BaseMaterial_EdgeXNegative_Asad' | 'BaseMaterial_EdgeXNegativeFromAnchor' | 'BaseMaterial_EdgeXPositive' | 'BaseMaterial_EdgeXPositive_Asad' |
'BaseMaterial_EdgeXPositiveFromAnchor' | 'BaseMaterial_EdgeYNegative' | 'BaseMaterial_EdgeYNegative_Asad' | 'BaseMaterial_EdgeYNegativeFromAnchor' | 'BaseMaterial_EdgeYPositive' | 'BaseMaterial_EdgeYPositive_Asad' | 'BaseMaterial_EdgeYPositiveFromAnchor' | 'BaseMaterial_FasteningOption' | 'BaseMaterial_HeadJointCompletelyFilled' | 'BaseMaterial_HeadJointSelection' |
'BaseMaterial_HoleCondition' | 'BaseMaterial_HoleType' | 'BaseMaterial_HoleType_Asad' | 'BaseMaterial_InstallationDirection' | 'BaseMaterial_IsAnchorReinforcement' | 'BaseMaterial_IsAnchorReinforcementShear' | 'BaseMaterial_IsAnchorReinforcementTension' | 'BaseMaterial_IsCornerReinforcement' | 'BaseMaterial_IsEdgeXNegativeFromAnchorReinforced' | 'BaseMaterial_IsEdgeXNegativeLocked' |
'BaseMaterial_IsEdgeXNegativeReinforced' | 'BaseMaterial_IsEdgeXNegativeReinforced_Asad' | 'BaseMaterial_IsEdgeXPositiveFromAnchorReinforced' | 'BaseMaterial_IsEdgeXPositiveLocked' | 'BaseMaterial_IsEdgeXPositiveReinforced' | 'BaseMaterial_IsEdgeXPositiveReinforced_Asad' | 'BaseMaterial_IsEdgeYNegativeFromAnchorReinforced' | 'BaseMaterial_IsEdgeYNegativeLocked' | 'BaseMaterial_IsEdgeYNegativeReinforced' | 'BaseMaterial_IsEdgeYNegativeReinforced_Asad' |
'BaseMaterial_IsEdgeYPositiveFromAnchorReinforced' | 'BaseMaterial_IsEdgeYPositiveLocked' | 'BaseMaterial_IsEdgeYPositiveReinforced' | 'BaseMaterial_IsEdgeYPositiveReinforced_Asad' | 'BaseMaterial_IsLightweightConcrete' | 'BaseMaterial_IsLightweightConcrete_Asad' | 'BaseMaterial_IsNonLoadBearingLayerPresent' | 'BaseMaterial_IsPlasteredWall' | 'BaseMaterial_IsSplittingReinforcement' | 'BaseMaterial_JointMaterial' |
'BaseMaterial_JointThicknessHorizontal' | 'BaseMaterial_JointThicknessVertical' | 'BaseMaterial_LightweightConcreteLambdaA' | 'BaseMaterial_LightweightConcreteLambdaA_Asad' | 'BaseMaterial_MasonryAnchorPosition' | 'BaseMaterial_MasonryEdgeXNegative' | 'BaseMaterial_MasonryEdgeXNegativeFromAnchor' | 'BaseMaterial_MasonryEdgeXPositive' | 'BaseMaterial_MasonryEdgeXPositiveFromAnchor' | 'BaseMaterial_MasonryEdgeYNegative' |
'BaseMaterial_MasonryEdgeYNegativeFromAnchor' | 'BaseMaterial_MasonryEdgeYPositive' | 'BaseMaterial_MasonryEdgeYPositiveFromAnchor' | 'BaseMaterial_MasonryThickness' | 'BaseMaterial_MetalDeck' | 'BaseMaterial_MetalDeckAnchorOffset' | 'BaseMaterial_MetalDeckAnchorPosition' | 'BaseMaterial_MetalDeckFluteBaseWidth' | 'BaseMaterial_MetalDeckFluteDepth' | 'BaseMaterial_MetalDeckFluteDistance' |
'BaseMaterial_MetalDeckFlutePeakWidth' | 'BaseMaterial_MetalDeckLowerFluteWidth' | 'BaseMaterial_MetalDeckTotalFluteDepth' | 'BaseMaterial_MetalDeckType' | 'BaseMaterial_MetalDeckUpperFluteWidth' | 'BaseMaterial_NearestJointDistance' | 'BaseMaterial_NonLoadBearingLayer' | 'BaseMaterial_OffsetX' | 'BaseMaterial_OffsetY' | 'BaseMaterial_PlasterThickness' |
'BaseMaterial_PlasterYoungsModulus' | 'BaseMaterial_PrismStrength' | 'BaseMaterial_ReinforcementConditionShear' | 'BaseMaterial_ReinforcementConditionTension' | 'BaseMaterial_StairHeight' | 'BaseMaterial_StairLength' | 'BaseMaterial_Temperature' | 'BaseMaterial_TemperatureLongTerm' | 'BaseMaterial_TemperatureLongTerm_Asad' | 'BaseMaterial_TemperatureShortTerm' |
'BaseMaterial_TemperatureShortTerm_Asad' | 'BaseMaterial_Thickness' | 'BaseMaterial_Thickness_Asad' | 'BaseMaterial_TorquingType' | 'BaseMaterial_UseCategory' | 'BaseMaterial_VerticalJointsFilled' | 'BaseMaterial_WHGApplication' | 'BaseMaterial_WHGType' | 'BaseMaterial_EdgeXNegativeFromAnchorPlateEdge' | 'BaseMaterial_EdgeXNegativeFromConcreteEdge' |
'BaseMaterial_EdgeXNegativeFromProfileCenter' | 'BaseMaterial_EdgeXPositiveFromAnchorPlateEdge' | 'BaseMaterial_EdgeXPositiveFromConcreteEdge' | 'BaseMaterial_EdgeXPositiveFromProfileCenter' | 'BaseMaterial_EdgeYNegativeFromAnchorPlateEdge' | 'BaseMaterial_EdgeYNegativeFromConcreteEdge' | 'BaseMaterial_EdgeYNegativeFromProfileCenter' | 'BaseMaterial_EdgeYPositiveFromAnchorPlateEdge' | 'BaseMaterial_EdgeYPositiveFromConcreteEdge' | 'BaseMaterial_EdgeYPositiveFromProfileCenter' |
'BaseplateThickness' | 'Checkbot_IOM_BeamsDescription' | 'ConstructionOptions_CorrosionResistant' | 'ConstructionOptions_GalvanizedSteel' | 'ConstructionOptions_MechanicallyGalvanized' | 'ConstructionOptions_Sheradized' | 'ConstructionOptions_StainlessSteel' | 'Loads_AreFactoredSustainedLoadsActive' | 'Loads_AreLoadCombinationsActive' | 'Loads_CmdETABSImport' |
'Loads_CmdExport' | 'Loads_CmdImport' | 'Loads_CmdRobotImport' | 'Loads_CmdSAP2000Import' | 'Loads_CmdStaadProImport' | 'Loads_Combi_psi_0_h' | 'Loads_Combi_psi_0_h_wind' | 'Loads_Combi_psi_0_v' | 'Loads_CompressiveStress' | 'Loads_ConcreteEtagEnOnly' |
'Loads_CustomizeDetails' | 'Loads_DeadLoad' | 'Loads_DeleteLoad' | 'Loads_DesignMethodHNA' | 'Loads_DesignMethodHNA_Asad' | 'Loads_DesignMethodSelection' | 'Loads_DlubalImportExport' | 'Loads_EmergencyExits' | 'Loads_ETABSImportExport' | 'Loads_ExportRisa' |
'Loads_FactoredSustainedLoadsFactor' | 'Loads_FatigueLoadCycles' | 'Loads_FatigueLoadType' | 'Loads_FireDuration' | 'Loads_FireExposure' | 'Loads_FlatBarResistance' | 'Loads_ForceX' | 'Loads_ForceXVariable' | 'Loads_ForceY' | 'Loads_ForceYVariable' |
'Loads_ForceZ' | 'Loads_ForceZSustained' | 'Loads_ForceZVariable' | 'Loads_FundamentalWindVelocity' | 'Loads_Gamma_perm_inf' | 'Loads_Gamma_perm_sup' | 'Loads_Gamma_var_h_bridge' | 'Loads_Gamma_var_h_building' | 'Loads_Gamma_var_h_wind' | 'Loads_Gamma_var_h_wind_bridge' |
'Loads_Gamma_var_v_bridge' | 'Loads_Gamma_var_v_building' | 'Loads_GammaZero' | 'Loads_HandrailDesignMethodSelection' | 'Loads_HandrailEtagEnOnly' | 'Loads_HandrailMoreInfoOnSOFA' | 'Loads_HandrailSafetyClass' | 'Loads_HandrailStandOffDesignMethodSelection' | 'Loads_ImportLoadCombinations' | 'Loads_InfillLoadInwards' |
'Loads_InfillLoadOutwards' | 'Loads_InfillPunctualLoadInwards' | 'Loads_InfillPunctualLoadOutwards' | 'Loads_InputHandrailLoads' | 'Loads_IsBridge' | 'Loads_IsFatigueExpertMode' | 'Loads_LinearLoadInwards' | 'Loads_LinearLoadInwardsHeight' | 'Loads_LinearLoadOutwards' | 'Loads_LinearLoadOutwardsHeight' |
'Loads_LoadCombinationHNAWizard' | 'Loads_LoadCombinations' | 'Loads_LoadCombinationsCalculationType' | 'Loads_LoadsPositionZ' | 'Loads_LoadType' | 'Loads_MomentX' | 'Loads_MomentXSustained' | 'Loads_MomentXVariable' | 'Loads_MomentY' | 'Loads_MomentYSustained' |
'Loads_MomentYVariable' | 'Loads_MomentZ' | 'Loads_MomentZVariable' | 'Loads_MoreInfoOnFatigue' | 'Loads_MoreInfoOnFire' | 'Loads_MoreInfoOnSOFA' | 'Loads_NewAndImportLoad' | 'Loads_OnSiteTestsValues' | 'Loads_OnSiteTestsValues_N_Rk' | 'Loads_OnSiteTestsValues_V_Rk' |
'Loads_OnSiteTestType' | 'Loads_PhiNonductile' | 'Loads_PunctualLoadInwards' | 'Loads_PunctualLoadInwardsHeight' | 'Loads_PunctualLoadInWorstPosition' | 'Loads_PunctualLoadOutwards' | 'Loads_PunctualLoadOutwardsHeight' | 'Loads_PunctualLoadRailInwards' | 'Loads_PunctualLoadRailOutwards' | 'Loads_RobotImportExport' |
'Loads_SafetyLevel' | 'Loads_SAP2000ImportExport' | 'Loads_SeismicCategory' | 'Loads_SeismicDesignType' | 'Loads_SeismicDisplacementsType' | 'Loads_SeismicDLSShear' | 'Loads_SeismicDLSTension' | 'Loads_SeismicIntensityFactor' | 'Loads_SeismicLoadContribution' | 'Loads_SeismicShearType' |
'Loads_SeismicTensionType' | 'Loads_SelectedLoadCombination' | 'Loads_ShearLoadDistributionType' | 'Loads_ShowAllLoads' | 'Loads_StaadProImportExport' | 'Loads_StandOffDesignMethodSelection' | 'Loads_StaticLoadType' | 'Loads_StaticShearType' | 'Loads_StaticTensionType' | 'Loads_StructuralMember' |
'Loads_UseLoadCombinations_SE' | 'Loads_UseOnSiteTestsData' | 'Loads_VerticalLoad' | 'Loads_VerticalPunctualLoad' | 'Loads_VerticalPunctualLoadInWorstPosition' | 'Optimize_AnchorLayout' | 'Optimize_AnchorOffset' | 'Optimize_AnchorPlatePosition' | 'Optimize_AnchorPlateSize' | 'Optimize_AxialSpacing' |
'Optimize_BaseMaterialThickness' | 'Optimize_BaseplateSize' | 'Optimize_EdgeDistance' | 'Optimize_FindSolution' | 'Optimize_PostDistance' | 'OptimizeOnEmbedmentDepth' | 'Option_AnchorPlateFactor' | 'Option_ConcreteCapacityFactor' | 'Option_ConcreteSafetyFactorGammaC' | 'Option_CoordinateSystemCenter' |
'Option_CoordinateSystemCenterX' | 'Option_CoordinateSystemCenterY' | 'Option_CustomPictures' | 'Option_DesignMethodGroupId' | 'Option_DesignMethodGroupId_Asad' | 'Option_DesignName' | 'Option_DesignStandard' | 'Option_EdgeDistanceDisplayType' | 'Option_ETAGOnly' | 'Option_FaxNumber' |
'Option_HandrailDisplacementLimit' | 'Option_HandrailDisplacementLimitBridges' | 'Option_INSteelSafetyFactorGammaM0' | 'Option_INSteelSafetyFactorGammaM1' | 'Option_INSteelSafetyFactorGammaMb' | 'Option_INSteelSafetyFactorGammaMw' | 'Option_MaterialSafetyFactor' | 'Option_MeasureAnchorPlate' | 'Option_MeasureBaseMaterialEdgeFrom' | 'Option_MinimumAnchorToProfileDistance' |
'Option_MinimumConcreteCover' | 'Option_NoteCombinedTensionAndShearLoad' | 'Option_NoteDisplacements' | 'Option_NoteInstallationData' | 'Option_NoteLoadCaseResultingAnchorForces' | 'Option_NoteShearLoad' | 'Option_NoteTensionLoad' | 'Option_OstInputsEnabled' | 'Option_RegionId' | 'Option_ReportAddress' |
'Option_ReportCompanyName' | 'Option_ReportContactPerson' | 'Option_ReportEmail' | 'Option_ReportFasteningPoint' | 'Option_ReportFirstPage' | 'Option_ReportLanguageLcid' | 'Option_ReportLoadCombination' | 'Option_ReportNotes' | 'Option_ReportPaperSizeId' | 'Option_ReportPhoneNumber' |
'Option_ReportTemplateId' | 'Option_ReportTypeId' | 'Option_SafetyFactorForPermamentLoads' | 'Option_SafetyFactorForVariableLoads' | 'Option_StainlessSteel' | 'Option_SteelCapacityFactor' | 'Option_SteelGuideline' | 'Option_SteelSafetyFactorGammaM0' | 'Option_SteelSafetyFactorGammaM1' | 'Option_SteelSafetyFactorGammaM2' |
'Option_SteelSafetyFactorGammaM5' | 'Option_STOSteelSafetyFactorGammaC' | 'Option_TrimbleConnectFolderId' | 'Option_TrimbleConnectLocation' | 'Option_TrimbleConnectReportName' | 'Option_TrimbleConnectUpload' | 'Option_UnitArea' | 'Option_UnitAreaPerLength' | 'Option_UnitDensity' | 'Option_UnitForce' |
'Option_UnitForcePerLength' | 'Option_UnitLength' | 'Option_UnitLengthLarge' | 'Option_UnitMoment' | 'Option_UnitMomentPerLength' | 'Option_UnitStress' | 'Option_UnitStressSmall' | 'Option_UnitTemperature' | 'Option_WeldsCapacityFactor' | 'Profile_Angle' |
'Profile_CmdEccentricityRotateAnticlockwise' | 'Profile_CmdEccentricityRotateClockwise' | 'Profile_CmdEccentricityRotatePostAnticlockwise' | 'Profile_CmdEccentricityRotatePostClockwise' | 'Profile_CustomFlangeThickness' | 'Profile_CustomFlangeThickness_Asad' | 'Profile_CustomHeight' | 'Profile_CustomHeight_Asad' | 'Profile_CustomProfileMaterial' | 'Profile_CustomThickness' |
'Profile_CustomThickness_Asad' | 'Profile_CustomWidth' | 'Profile_CustomWidth_Asad' | 'Profile_CustomWidth2' | 'Profile_DoubleFlatBarCustomWidth' | 'Profile_FlangeWeldThickness' | 'Profile_HandrailPostAngle' | 'Profile_HandrailPostProfileFamilyId' | 'Profile_HandrailRailAngle' | 'Profile_HandrailRailCmdEccentricityRotateAnticlockwise' |
'Profile_HandrailRailCmdEccentricityRotateClockwise' | 'Profile_HandrailRailProfileFamilyId' | 'Profile_HandrailRailProfileSizeId' | 'Profile_HandrailRailProfileSizeName' | 'Profile_HandrailRailProfileSteelType' | 'Profile_HandrailSafetyDesign' | 'Profile_HorizontalPostMaterialDensity' | 'Profile_HorizontalPostMaterialEModulus' | 'Profile_HorizontalPostMaterialPoisson' | 'Profile_HorizontalPostMaterialUltimateStrength' |
'Profile_HorizontalPostMaterialYieldStrength' | 'Profile_IsProfileMaterialCustom' | 'Profile_MaterialDensity' | 'Profile_MaterialEModulus' | 'Profile_MaterialPoisson' | 'Profile_MaterialUltimateStrength' | 'Profile_MaterialYieldStrength' | 'Profile_OffsetX' | 'Profile_OffsetY' | 'Profile_PostMaterialDensity' |
'Profile_PostMaterialEModulus' | 'Profile_PostMaterialPoisson' | 'Profile_PostMaterialUltimateStrength' | 'Profile_PostMaterialYieldStrength' | 'Profile_ProfileFamilyId' | 'Profile_ProfileFamilyId_Asad' | 'Profile_ProfileSizeId' | 'Profile_ProfileSizeId_Asad' | 'Profile_ProfileSizeName' | 'Profile_RailMaterialDensity' |
'Profile_RailMaterialEModulus' | 'Profile_RailMaterialPoisson' | 'Profile_RailMaterialUltimateStrength' | 'Profile_RailMaterialYieldStrength' | 'Profile_ShearLugAngle' | 'Profile_ShearLugCmdRotateAnticlockwise' | 'Profile_ShearLugCmdRotateClockwise' | 'Profile_ShearLugCustomDepth' | 'Profile_ShearLugCustomLength' | 'Profile_ShearLugCustomThickness' |
'Profile_ShearLugCustomWidth' | 'Profile_ShearLugFamilyId' | 'Profile_ShearLugSizeId' | 'Profile_ShearLugSizeName' | 'Profile_ShowWeldDesign' | 'Profile_SteelType' | 'Profile_UseProfile' | 'Profile_UseShearLug' | 'Profile_Utilization' | 'Profile_WebWeldLocation' |
'Profile_WebWeldMaterial' | 'Profile_WebWeldMaterialBetaW' | 'Profile_WebWeldMaterialDensity' | 'Profile_WebWeldMaterialEModulus' | 'Profile_WebWeldMaterialPoisson' | 'Profile_WebWeldMaterialUltimateStrength' | 'Profile_WebWeldMaterialYieldStrength' | 'Profile_WebWeldThickness' | 'Profile_HorizontalPostProfileConnection' | 'Profile_HorizontalPostProfileFamilyId' |
'Profile_HorizontalPostProfileSizeId' | 'Profile_HorizontalPostProfileSizeName' | 'Profile_HorizontalPostSteelTypeHandrail' | 'Profile_IsHorizontalPostProfilePresent' | 'Profile_SteelTypeHandrail' | 'Profile_UsePinConnection' | 'ProjectDesignType' | 'ProjectName' | 'SmartAnchor_AddMoreUserSelectedAnchors' | 'SmartAnchor_AnchorGroupsForAsad' |
'SmartAnchor_Application' | 'SmartAnchor_Category' | 'SmartAnchor_CurrentlySelectedAnchor' | 'SmartAnchor_Enabled' | 'SmartAnchor_EnableTco' | 'SmartAnchor_IsLastSelectedSolution' | 'SmartAnchor_OptimizationOptions_AnchorPosition' | 'SmartAnchor_OptimizationOptions_ProfilePosition' | 'SmartAnchor_OptimizationOptions_UtilizationSlider' | 'SmartAnchor_PlateHidden' |
'SmartAnchor_SelectCategoryAndApplication' | 'SmartAnchor_SelectPreferredAnchor' | 'SmartAnchor_ShowMore' | 'SmartAnchor_SuggestedAnchorFamily' | 'SmartAnchor_SuggestedAnchorSize' | 'SmartAnchor_Tco_AnchorDiscount' | 'SmartAnchor_Tco_SteelPrice' | 'SmartAnchor_Tco_WorkPrice' | 'SmartAnchor_Text_ConcreteReinforcement' | 'SmartAnchor_Text_CorrisionMaterial' |
'SmartAnchor_Text_InstallationCondition' | 'SmartAnchor_Text_Temprature' | 'SmartAnchor_UserSelectedAnchorGroupsForAsad' | 'SmartAnchor_UserSelectedAnchorSizes' | 'Solution_CmdSpecificationText' | 'Solution_SpecificationText' | 'Stiffener_CustomStiffener' | 'SupplementaryReinforcement_ApplyTensionValuesToShear' | 'SupplementaryReinforcement_CBottomEffective' | 'SupplementaryReinforcement_CEdge' |
'SupplementaryReinforcement_CEdgeEffective' | 'SupplementaryReinforcement_CEdgeEffectiveLeft' | 'SupplementaryReinforcement_CEdgeEffectiveRight' | 'SupplementaryReinforcement_CmdRotateAnticlockwise' | 'SupplementaryReinforcement_CmdRotateClockwise' | 'SupplementaryReinforcement_ConcreteReinforcement' | 'SupplementaryReinforcement_ConcreteReinforcement_Asad' | 'SupplementaryReinforcement_CTop' | 'SupplementaryReinforcement_CTopEffective' | 'SupplementaryReinforcement_EdgeReinforcement' |
'SupplementaryReinforcement_EdgeReinforcement_Asad' | 'SupplementaryReinforcement_FirstRebarCenterOffset' | 'SupplementaryReinforcement_Height' | 'SupplementaryReinforcement_IsSplittingReinforcement' | 'SupplementaryReinforcement_IsSplittingReinforcement_Asad' | 'SupplementaryReinforcement_PositionTolerance' | 'SupplementaryReinforcement_ReinforcementCategory' | 'SupplementaryReinforcement_ReinforcementDiameter' | 'SupplementaryReinforcement_ReinforcementSpacing' | 'SupplementaryReinforcement_ReinforcementType' |
'SupplementaryReinforcement_ReinforcementUtilization' | 'SupplementaryReinforcement_Rotate' | 'SupplementaryReinforcement_SurfaceNumber' | 'SupplementaryReinforcement_SurfaceReinforcement' | 'SupplementaryReinforcement_SurfaceUtilization' | 'SupplementaryReinforcement_TensionLeftWidth' | 'SupplementaryReinforcement_TensionReinforcementSpacing' | 'SupplementaryReinforcement_TensionRightWidth' | 'SupplementaryReinforcement_Width' | 'SupplementaryReinforcement_YieldStrength' |
'SupplementaryReinforcement_DirectionOfCasting' | 'Update_PhasedOutFastenerFamily';
