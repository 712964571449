import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import supplementaryReinforcementShearImg from '../../../images/supplementary-reinforcement/supplementary-reinforcement-shear.png';
import supplementaryReinforcementTensionImage from '../../../images/supplementary-reinforcement/supplementary-reinforcement-tension.png';

@Component({
    templateUrl: './supplementary-reinforcement-info.component.html',
    styleUrls: ['./supplementary-reinforcement-info.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SupplementaryReinforcementInfoComponent implements OnInit {
    public supplementaryReinforcementShearImage!: string;
    public supplementaryReinforcementTensionImage!: string;

    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.supplementaryReinforcementShearImage = supplementaryReinforcementShearImg;
        this.supplementaryReinforcementTensionImage = supplementaryReinforcementTensionImage;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
