import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    DesignMethodGroup as DesignMethodGroupEnum, ShearLoadDistributionTypes
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from '../../services/localization.service';
import equivalentBreakoutImage from '../../../images/shear-equivalent-breakout-body.png';
import rowClosestToEdgeImage from '../../../images/shear-row-closest-to-edge.png';
import upToFirstThreeRowsImage from '../../../images/shear-up-to-first-three-rows.png';

export interface IShearLoadDistributionTypeComponentInput {
    shearLoadDistributionType: ShearLoadDistributionTypes;
    designMethodGroup?: number;
}

@Component({
    templateUrl: './shear-load-distribution-type-popup.component.html',
    styleUrls: ['./shear-load-distribution-type-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ShearLoadDistributionTypeComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IShearLoadDistributionTypeComponentInput>;

    public rowClosesToEdgeTranslationKey = '';
    public upToFirstThreeRowsTranslationKey = '';
    public bandApproachTranslationKey = 'Agito.Hilti.Profis3.CodeList.ShearLoadDistributionTypeEntity.BandApproach';

    public selectedShearLoadDistributionType?: ShearLoadDistributionTypes;
    public selectedShearLoadDistributionInfo = '';

    constructor(
        public localization: LocalizationService,
    ) { }


    ngOnInit(): void {
        this.changeSelectedShearLoadDistributionType(this.modalInstance.input?.shearLoadDistributionType ?? 0);

        this.rowClosesToEdgeTranslationKey = `Agito.Hilti.Profis3.CodeList.ShearLoadDistributionTypeEntity.RowClosestToEdge.${this.designMethodGroupName(this.modalInstance.input?.designMethodGroup)}`;
        this.upToFirstThreeRowsTranslationKey = `Agito.Hilti.Profis3.CodeList.ShearLoadDistributionTypeEntity.UpToFirstThreeRows.${this.designMethodGroupName(this.modalInstance.input?.designMethodGroup)}`;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public changeSelectedShearLoadDistributionType(shearLoadDistributionType: number) {
        this.selectedShearLoadDistributionType = shearLoadDistributionType;
        this.selectedShearLoadDistributionInfo = this.getSelectedShearLoadDistributionInfo();
    }


    private designMethodGroupName(designMethodGroup?: number): string {
        switch (designMethodGroup) {
            case DesignMethodGroupEnum.ACI31808:
            case DesignMethodGroupEnum.ACI31811:
                return 'ACI31811';
            case DesignMethodGroupEnum.ACI31814:
                return 'ACI31814';
            case DesignMethodGroupEnum.ACI31819:
                return 'ACI31819';
            case DesignMethodGroupEnum.CSAA23314:
                return 'CSAA23314';
            case DesignMethodGroupEnum.CSAA23319:
                return 'CSAA23319';
            default:
                return '';
        }
    }

    private getSelectedShearLoadDistributionInfo() {
        switch (this.selectedShearLoadDistributionType) {
            case ShearLoadDistributionTypes.UpToFirstThreeRows:
                return this.getSelectedShearLoadDistributionInfoUpToFirstThreeRows();
            case ShearLoadDistributionTypes.BandApproach:
                return this.getSelectedShearLoadDistributionInfoBandApproach();
            case ShearLoadDistributionTypes.RowClosestToEdge:
                return this.getSelectedShearLoadDistributionInfoRowClosestToEdge();
        }

        return '';
    }

    private getSelectedShearLoadDistributionInfoUpToFirstThreeRows() {
        const msg1 = this.localization.getString('Agito.Hilti.Profis3.Loads.ShearLoadDistributionType.Message1');
        const msg2 = this.localization.getString('Agito.Hilti.Profis3.Loads.ShearLoadDistributionType.Message2');
        const msg3 = this.localization.getString('Agito.Hilti.Profis3.Loads.ShearLoadDistributionType.Message3');

        return `
<img class="band-approach-img" src="${upToFirstThreeRowsImage}" alt="Shear Load Distribution" />
<p>${msg1}</p>
<div>${msg2}</div>
<p>${msg3}</p>`;
    }

    private getSelectedShearLoadDistributionInfoBandApproach() {
        const msg1 = this.localization.getString('Agito.Hilti.Profis3.Loads.ShearLoadDistributionType.BandApproach.Message1');
        const msg2 = this.localization.getString('Agito.Hilti.Profis3.Loads.ShearLoadDistributionType.BandApproach.Message2');

        return `
<img class="band-approach-img" src="${equivalentBreakoutImage}" alt="Shear Load Distribution - Band Approach" />
<p>${msg1}</p>
<div>${msg2}</div>`;
    }

    private getSelectedShearLoadDistributionInfoRowClosestToEdge() {
        const msg1 = this.localization.getString('Agito.Hilti.Profis3.Loads.ShearLoadDistributionType.RowClosestToEdge');

        return `
<img class="band-approach-img" src="${rowClosestToEdgeImage}" alt="Shear Load Distribution" />
<p>${msg1}</p>`;
    }
}
