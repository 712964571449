import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { MainMenuModal } from '../../../shared/entities/main-menu';
import { BaseplateSize as BaseplateSizePropertyValue } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { IBaseplateSizeComponentInput } from '../../../shared/components/baseplate-size';
import { updateMainMenuControl } from './menu-helper';
import { BaseplateSize, IBaseplateSizeProps } from '../../components/main-menu/baseplate-size/BaseplateSize';

export function initializeBaseplateSize(
    design: DesignPe,
    controlProps: IBaseplateSizeProps,
    localizationService: LocalizationService,
    unitService: UnitService,
    uiPropertyId: number,
    modals: { [modal: number]: (input?: object) => IModalOpened },
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {

    controlProps.type = BaseplateSize;

    const formatText = (baseplateSize: BaseplateSizePropertyValue) => {
        if (baseplateSize == null) {
            return localizationService.getString('Agito.Hilti.Profis3.BaseplateSize.ButtonNoTitle');
        }

        const defaultUnit = unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = unitService.getInternalUnit(UnitGroup.Length);
        const precision = unitService.getPrecision(defaultUnit);

        const width = baseplateSize.Width != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.Width, internalUnit, defaultUnit) : null;
        const height = baseplateSize.Height != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.Height, internalUnit, defaultUnit) : null;
        const thickness = baseplateSize.Thickness != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.Thickness, internalUnit, defaultUnit) : null;
        const sideLength = baseplateSize.SideLength != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.SideLength, internalUnit, defaultUnit) : null;
        const diameter = baseplateSize.Diameter != null ? unitService.convertUnitValueArgsToUnit(baseplateSize.Diameter, internalUnit, defaultUnit) : null;

        const widthFormated = width != null ? `${localizationService.getString('Agito.Hilti.Profis3.BaseplateSize.WidthSign')}: ${unitService.formatNumber(width, precision)}` : null;
        const heightFormated = height != null ? `${localizationService.getString('Agito.Hilti.Profis3.BaseplateSize.HeightSign')}: ${unitService.formatNumber(height, precision)}` : null;
        const thicknessFormated = thickness != null ? `${localizationService.getString('Agito.Hilti.Profis3.BaseplateSize.ThicknessSign')}: ${unitService.formatNumber(thickness, precision)}` : null;
        const sideLengthFormated = sideLength != null ? `${localizationService.getString('Agito.Hilti.Profis3.BaseplateSize.SideLengthSign')}: ${unitService.formatNumber(sideLength, precision)}` : null;
        const diameterFormated = diameter != null ? `${localizationService.getString('Agito.Hilti.Profis3.BaseplateSize.DiameterSign')}: ${unitService.formatNumber(diameter, precision)}` : null;

        let result = '';

        if (baseplateSize.WidthVisible) {
            result += widthFormated + ' ';
        }
        if (baseplateSize.HeightVisible) {
            result += heightFormated + ' ';
        }
        if (baseplateSize.SideLengthVisible) {
            result += sideLengthFormated + ' ';
        }
        if (baseplateSize.DiameterVisible) {
            result += diameterFormated + ' ';
        }
        if (baseplateSize.ThicknessVisible) {
            result += thicknessFormated + ' ';
        }

        return result;
    };

    const formatTitle = (title: string) => {
        if (title == null || title == '') {
            return undefined;
        }

        const defaultUnit = unitService.getDefaultUnit(UnitGroup.Length);
        const defaultUnitFormated = unitService.formatUnit(defaultUnit);

        return `${title} [${defaultUnitFormated}]`;
    };

    const propertyValue = design.model[uiPropertyId] as BaseplateSizePropertyValue;
    controlProps.baseplateSize = propertyValue;
    controlProps.baseplateSizeFormatted = formatText(propertyValue);
    controlProps.controlTitleFormatted = formatTitle(controlProps.title);

    controlProps.clicked = () => {
        const modalOpenFn = modals[MainMenuModal.baseplateSize];
        const modalInput: IBaseplateSizeComponentInput = {
            onSave: (baseplateSize) => {
                // change menu
                setState(menu => updateMainMenuControl<IBaseplateSizeProps>(menu, controlProps.controlId, {
                    baseplateSize,
                    baseplateSizeFormatted: formatText(baseplateSize)
                } as any));
            }
        };
        modalOpenFn(modalInput);
    };

    const onStateChanged = (state: IDesignState) => {
        return ({
            controlTitleFormatted: formatTitle(controlProps.title),
            baseplateSize: state.model[uiPropertyId],
            baseplateSizeFormatted: formatText(state.model[uiPropertyId] as BaseplateSizePropertyValue)
        } as any) as IBaseplateSizeProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}
