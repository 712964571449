import { Injectable } from '@angular/core';
import {
    DesignTemplateServiceInjected
} from '@profis-engineering/pe-ui-common/services/design-template.common';

@Injectable({
    providedIn: 'root'
})
export class DesignTemplateService extends DesignTemplateServiceInjected {
}
