import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { environment } from '../../../environments/environmentPe';
import { UtilizationValueEntity } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    LoadCombinationEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    LoadType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { GuidService } from '../../services/guid.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './ram-import-loads.component.html',
    styleUrls: ['./ram-import-loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class RamImportLoadsComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;
    public standardLicenseLimit = 10;

    public availableLoadsCheckbox!: CheckboxButtonProps<LoadCombinationEntity>;

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private guid: GuidService,
        private unit: UnitService,
        private licenseService: LicenseService,
        private calculationService: CalculationServicePE
    ) { }

    public get showInfoMessages() {
        return this.isStandardLicense;
    }

    private get forceUnit() {
        return `${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Force))}`;
    }

    private get momentUnit() {
        return `${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Moment))}`;
    }

    private get loadCombinationLimit() {
        // In case of importing RAM file with standard license there is a limit number of load combinations to import
        return this.isStandardLicense ? this.standardLicenseLimit : environment.maxLoadCombinations;
    }

    private get isStandardLicense() {
        return this.licenseService.isStandardLicense();
    }

    ngOnInit(): void {
        // controls
        this.availableLoadsCheckbox = {
            items: [],
            selectedValues: new Set()
        };

        // We need ProjectDesignConcreteEntity
        const loads = (this.user.design.designData.projectDesign as any).Loads.LoadCombinations as LoadCombinationEntity[];

        this.init(loads);
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public import() {
        if (this.submitted) {
            return;
        }

        this.submitted = true;

        this.calculationService.calculateAsync(
            this.user.design,
            (design) => {
                design.loadCombinations = this.extractSelectedLoads(this.availableLoadsCheckbox?.selectedValues);
            },
            {
                suppressLoadingFlag: true,
                importingLoadCases: true
            }
        )
            .then(() => {
                this.submitted = false;
                this.modalInstance.close();
            }).catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    public onSelectedLoadsChange() {
        this.filterLoadCombinations();
    }

    private init(loads: LoadCombinationEntity[]) {
        const checkboxItems: CheckboxButtonItem<LoadCombinationEntity>[] = [];


        loads.forEach((load) => {
            const item: CheckboxButtonItem<LoadCombinationEntity> = {
                text: this.formatAvailableLoadName(load),
                value: load
            };

            checkboxItems.push(item);
        });

        this.availableLoadsCheckbox.items = checkboxItems;
        this.availableLoadsCheckbox.selectedValues = this.applyLimitToSelectedValues(checkboxItems);

        this.filterLoadCombinations();
    }

    private applyLimitToSelectedValues(checkboxItems: CheckboxButtonItem<LoadCombinationEntity>[]) {
        const checkboxSelectedValues = new Set<LoadCombinationEntity>();

        checkboxItems.slice(0, this.loadCombinationLimit).forEach((item) => {
            checkboxSelectedValues.add(item.value);
        });

        return checkboxSelectedValues;
    }

    private filterLoadCombinations() {
        if (!this.isStandardLicense) {
            return;
        }

        this.availableLoadsCheckbox?.items?.forEach((item) => {
            item.disabled = (this.availableLoadsCheckbox?.selectedValues?.size ?? 0) >= this.standardLicenseLimit && !this.availableLoadsCheckbox?.selectedValues?.has(item.value);
        });
    }

    private formatValue(value: number, unitGroup: UnitGroup) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unit.getDefaultUnit(unitGroup);
        const internalUnit = this.unit.getInternalUnit(unitGroup);
        const precision = this.unit.getPrecision(defaultUnit);

        const defaultValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(defaultValue, precision);
    }

    private formatAvailableLoadName(load: LoadCombinationEntity): string {
        return `${load.Comment}
        {
            fx: ${this.formatValue(load.ForceX, UnitGroup.Force)}${this.forceUnit},
            fy: ${this.formatValue(load.ForceY, UnitGroup.Force)}${this.forceUnit},
            fz: ${this.formatValue(load.ForceZ, UnitGroup.Force)}${this.forceUnit},
            mx: ${this.formatValue(load.MomentX, UnitGroup.Moment)}${this.momentUnit},
            my: ${this.formatValue(load.MomentY, UnitGroup.Moment)}${this.momentUnit},
            mz: ${this.formatValue(load.MomentZ, UnitGroup.Moment)}${this.momentUnit}
        }`;
    }

    private extractSelectedLoads(selectedLoads: Set<LoadCombinationEntity> | undefined) {
        const loads: LoadCombination[] = [];

        selectedLoads?.forEach(lc => {
            loads.push({
                Name: lc.Comment,
                Description: null as unknown as string,
                Id: this.guid.new(),
                ForceX: lc.ForceX,
                ForceY: lc.ForceY,
                ForceZ: lc.ForceZ,
                MomentX: lc.MomentX,
                MomentY: lc.MomentY,
                MomentZ: lc.MomentZ,
                DynamicForceX: null as unknown as number,
                DynamicForceY: null as unknown as number,
                DynamicForceZ: null as unknown as number,
                DynamicMomentX: null as unknown as number,
                DynamicMomentY: null as unknown as number,
                DynamicMomentZ: null as unknown as number,
                ActiveLoadType: LoadType.Static,
                Tension: null as unknown as UtilizationValueEntity,
                Shear: null as unknown as UtilizationValueEntity,
                Combination: null as unknown as UtilizationValueEntity,
                ResultMessages: [],
                IsWizardGenerated: false,
                HasSustainedLoads: false,
                LoadCharacteristic: null as unknown as number
            });

            return true;
        });

        return loads;
    }
}
