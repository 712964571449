import { DesignExternalMetaData } from '@profis-engineering/pe-ui-common/services/document.common';
import { DesignPe } from '../../shared/entities/design-pe';

export interface DesignExternalMetaDataPe extends DesignExternalMetaData {
    calculationType: number;
    handrailConnectionType: number;
}

export function getMetaDataFromDesign(design: DesignPe): DesignExternalMetaDataPe {
    const productName = design.anchorType?.name != null && design.anchorSize?.name != null
        ? `${design.anchorType.name} ${design.anchorSize.name}`
        : undefined;

    const ret: DesignExternalMetaDataPe = {
        id: design.id,
        name: design.designName,
        region: design.region.id,
        standard: design.designStandardId,
        designType: design.designType.id,
        approvalNumber: design.approvalNumber,
        designMethod: design.designMethodGroup?.id,

        productName: productName,
        calculationType: design.calculationType,
        handrailConnectionType: design.handrailConnectionType
    };
    return ret;
}
