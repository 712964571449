import { Injectable } from '@angular/core';
import {
    SessionStorageServiceInjected
} from '@profis-engineering/pe-ui-common/services/session-storage.common';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService extends SessionStorageServiceInjected {
}

export const storageKey = {
    navigationTourStep: 'navigationTourStep'
};
