<div class="content-group" *ngIf="dataLoaded">
    <div class="content design-region">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.MetalDeck.General')">
        </p>

        <div class="content-controls">
            <pe-dropdown class="control"
                [id]="metalDeckRegionDropdown.id"
                [title]="metalDeckRegionDropdown.title"
                [items]="metalDeckRegionDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckRegion"
                (selectedValueChange)="onMetalDeckRegionDropdownChange($any($event).detail)"
                [disabled]="submitted">
            </pe-dropdown>

            <div class="design-type-status control-row info">
                <p [class.error]="!allowMetalDeckQuickStart" [innerHtml]="allowMetalDeckStatus"></p>
            </div>
        </div>
        <div class="content-controls">
            <span class="local-steel-material-disclaimer"
                [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer')"></span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="isMetalDeckNone && allowMetalDeckQuickStart">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.MetalDeck.Units')"></p>

        <div class="content-controls">
            <pe-dropdown class="control"
                [id]="metalDeckLengthDropdown.id"
                [title]="metalDeckLengthDropdown.title"
                [items]="metalDeckLengthDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckLength"
                (selectedValueChange)="metalDeckFixing.metalDeckLength = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckAreaDropdown.id"
                [title]="metalDeckAreaDropdown.title"
                [items]="metalDeckAreaDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckArea"
                (selectedValueChange)="metalDeckFixing.metalDeckArea = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckStressDropdown.id"
                [title]="metalDeckStressDropdown.title"
                [items]="metalDeckStressDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckStress"
                (selectedValueChange)="metalDeckFixing.metalDeckStress = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckForceDropdown.id"
                [title]="metalDeckForceDropdown.title"
                [items]="metalDeckForceDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckForce"
                (selectedValueChange)="metalDeckFixing.metalDeckForce = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckMomentDropdown.id"
                [title]="metalDeckMomentDropdown.title"
                [items]="metalDeckMomentDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckMoment"
                (selectedValueChange)="metalDeckFixing.metalDeckMoment = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckTemperatureDropdown.id"
                [title]="metalDeckTemperatureDropdown.title"
                [items]="metalDeckTemperatureDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckTemperature"
                (selectedValueChange)="metalDeckFixing.metalDeckTemperature = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckForcePerLengthDropdown.id"
                [title]="metalDeckForcePerLengthDropdown.title"
                [items]="metalDeckForcePerLengthDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckForcePerLength"
                (selectedValueChange)="metalDeckFixing.metalDeckForcePerLength = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckMomentPerLengthDropdown.id"
                [title]="metalDeckMomentPerLengthDropdown.title"
                [items]="metalDeckMomentPerLengthDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckMomentPerLength"
                (selectedValueChange)="metalDeckFixing.metalDeckMomentPerLength = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown class="control"
                [id]="metalDeckDensityDropdown.id"
                [title]="metalDeckDensityDropdown.title"
                [items]="metalDeckDensityDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckDensity"
                (selectedValueChange)="metalDeckFixing.metalDeckDensity = $any($event).detail"
                [disabled]="submitted">
            </pe-dropdown>

            <div class="control-row metal-deck-anchor-plate-factor">
                <pe-numeric-text-box
                    [id]="metalDeckAnchorPlateFactor.id"
                    [title]="metalDeckAnchorPlateFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('metalDeckAnchorPlateFactor')"
                    [value]="metalDeckFixing.metalDeckAnchorPlateFactor"
                    (valueChange)="metalDeckFixing.metalDeckAnchorPlateFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('metalDeckAnchorPlateFactor')"
                    [minValue]="metalDeckAnchorPlateFactor.minValue"
                    [maxValue]="metalDeckAnchorPlateFactor.maxValue"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator"
                    [disabled]="submitted"
                    [tooltip]="metalDeckAnchorPlateFactor.tooltip">
                </pe-numeric-text-box>
            </div>

            <div class="application-settings-input-placeholder-container control-row"
                *ngIf="isMinimumAnchorToProfileDistanceAvailable(metalDeckFixing.metalDeckDesignStandardId ?? 0)">
                <pe-numeric-text-box
                    [id]="metalDeckMinimumAnchorToProfileDistance.id"
                    [title]="metalDeckMinimumAnchorToProfileDistance.title"
                    [placeholder]="getNumericTextBoxPlaceholder('metalDeckMinimumAnchorToProfileDistance')"
                    [value]="metalDeckFixing.metalDeckMinimumAnchorToProfileDistance"
                    (valueChange)="metalDeckFixing.metalDeckMinimumAnchorToProfileDistance = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('metalDeckMinimumAnchorToProfileDistance')"
                    [minValue]="metalDeckMinimumAnchorToProfileDistance.minValue"
                    [maxValue]="metalDeckMinimumAnchorToProfileDistance.maxValue"
                    [disabled]="submitted">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content calculation" *ngIf="isMetalDeckNone && allowMetalDeckQuickStart">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorCalculation')"></p>

        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown class="control control-design-standard-method"
                        [id]="metalDeckDesignStandardDropdown.id"
                        [title]="metalDeckDesignStandardDropdown.title"
                        [items]="metalDeckDesignStandardDropdown.items"
                        [selectedValue]="metalDeckFixing.metalDeckDesignStandardId"
                        (selectedValueChange)="onMetalDeckDesignStandardChange($any($event).detail)"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-standard-note" *ngIf="selectedMetalDeckDesignStandardDescription != null"
                        [innerHtml]="translate(selectedMetalDeckDesignStandardDescription)"></div>
                </div>
            </div>
            <div class="design-method-group control-row column">
                <div class="content">
                    <pe-dropdown class="control control-design-standard-method"
                        [id]="metalDeckDesignMethodDropdown.id"
                        [title]="metalDeckDesignMethodDropdown.title"
                        [items]="metalDeckDesignMethodDropdown.items"
                        [selectedValue]="metalDeckFixing.metalDeckDesignMethodGroupId"
                        (selectedValueChange)="onMetalDeckDesignMethodChange($any($event).detail)"
                        [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-method-note" *ngIf="selectedMetalDeckDesignMethodDescription != null"
                        [innerHtml]="translate(selectedMetalDeckDesignMethodDescription)">
                    </div>
                </div>
            </div>
            <div class="technical-data control-row column">
                <label class="control-label sub-title"
                    [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.MetalDeck.TechnicalData')"></label>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="isMetalDeckNone && allowMetalDeckQuickStart && isAdvancedCalculationPossible">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.SteelCalculation')"></p>

        <div class="content fixed-inputs" *ngIf="isAdvancedCalculationPossible">
            <pe-dropdown class="control"
                [id]="metalDeckSteelCalculationMethodDropdown.id"
                [title]="metalDeckSteelCalculationMethodDropdown.title"
                [items]="metalDeckSteelCalculationMethodDropdown.items"
                [selectedValue]="metalDeckFixing.metalDeckSteelCalculationMethod"
                (selectedValueChange)="metalDeckFixing.metalDeckSteelCalculationMethod = $any($event).detail"
                [disabled]="metalDeckSteelCalculationMethodDropdown.disabled || submitted">
            </pe-dropdown>
        </div>
        <div class="content-controls" *ngIf="metalDeckDesignStandardAiscBased">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box
                    [id]="metalDeckMaterialSafetyFactor.id"
                    [title]="metalDeckMaterialSafetyFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('metalDeckMaterialSafetyFactor')"
                    [value]="metalDeckFixing.metalDeckMaterialSafetyFactor"
                    (valueChange)="metalDeckFixing.metalDeckMaterialSafetyFactor = $any($event).detail"
                    [unit]="getNumericTextBoxUnit('metalDeckMaterialSafetyFactor')"
                    [minValue]="metalDeckMaterialSafetyFactor.minValue"
                    [maxValue]="metalDeckMaterialSafetyFactor.maxValue"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator"
                    [disabled]="submitted">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
