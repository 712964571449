import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    ProfileSizeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    ProfileFormingNorme
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import { CodeList } from '../code-lists/code-list';

export interface IProfileSizeConstructor extends ICodeListConstructor {
    width?: number;
    width2?: number;
    height?: number;
    thickness?: number;
    flangeThickness?: number;
    profileFamilyId?: number;
    isPostAndRailDesignRelevant: boolean;
    formingNorme: ProfileFormingNorme;
}

export class ProfileSize extends CodeList {
    public width?: number;
    public width2?: number;
    public height?: number;
    public thickness?: number;
    public flangeThickness?: number;
    public profileFamilyId?: number;
    public isPostAndRailDesignRelevant!: boolean;
    public formingNorme!: ProfileFormingNorme;

    constructor(codeList: IProfileSizeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.width = codeList.width;
            this.width2 = codeList.width2;
            this.height = codeList.height;
            this.thickness = codeList.thickness;
            this.flangeThickness = codeList.flangeThickness;
            this.profileFamilyId = codeList.profileFamilyId;
            this.isPostAndRailDesignRelevant = codeList.isPostAndRailDesignRelevant;
            this.formingNorme = codeList.formingNorme;
        }
    }

    public static fromService(codeList: ProfileSizeEntity) {
        return new ProfileSize(CodeList.baseFromService<IProfileSizeConstructor>('ProfileSizeEntity', codeList, {
            width: codeList.Width,
            width2: codeList.Width2,
            height: codeList.Length,
            thickness: codeList.Thickness,
            flangeThickness: codeList.FlangeThickness,
            profileFamilyId: codeList.ProfileFamilyId,
            isPostAndRailDesignRelevant: codeList.IsPostAndRailDesignRelevant,
            formingNorme: codeList.FormingNorme
        } as IProfileSizeConstructor));
    }

    public override cloneDeep() {
        const codeList: ProfileSize = super.cloneDeep() as ProfileSize;

        codeList.width = this.width;
        codeList.width2 = this.width2;
        codeList.height = this.height;
        codeList.thickness = this.thickness;
        codeList.flangeThickness = this.flangeThickness;
        codeList.profileFamilyId = this.profileFamilyId;
        codeList.isPostAndRailDesignRelevant = this.isPostAndRailDesignRelevant;
        codeList.formingNorme = this.formingNorme;

        return codeList;
    }
}
