import {
    SmartAnchorCategoryEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class SmartAnchorCategory extends CodeList {
    public static fromService(codeList: SmartAnchorCategoryEntity) {
        return new SmartAnchorCategory(CodeList.baseFromService('SmartAnchorCategoryEntity', codeList,
        {
            image: 'sprite-smart-anchor-' + codeList.Image
        }));
    }

    public override cloneDeep(): SmartAnchorCategory {
        const codeList: SmartAnchorCategory = super.cloneDeep() as SmartAnchorCategory;

        return codeList;
    }
}
