import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import styles from './react-advanced-input.css';

export interface IAdvancedInputProps extends IControlProps {
    buttonText?: string;
    clicked?: () => void;
}

let sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

class _AdvancedInput extends React.PureComponent<IAdvancedInputProps>{
    public static styleSheets = [sheet];
    constructor(props: IAdvancedInputProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }
    public override render() {
        if (this.props.hidden) {
            return;
        }

        return (
            <button data-control-id={this.props.controlId} className='react-sticky-button' onClick={this.onClick}>{this.props.buttonText}</button>
        );
    }

    private onClick() {

        if (this.props.clicked) {
            this.props.clicked();
        }
    }
}

export const AdvancedInput: typeof _AdvancedInput & ContolsStyleSheets = _AdvancedInput;
