import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { IBaseplateSizeComponentInput } from '../../../shared/components/baseplate-size';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './baseplate-size.component.html',
    styleUrls: ['./baseplate-size.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BaseplateSizeComponent implements OnInit {
    public submitted = false;
    public pendingSave = false;

    public widthTextBox!: NumericTextBoxProps;
    public heightTextBox!: NumericTextBoxProps;
    public sideLengthTextBox!: NumericTextBoxProps;
    public diameterTextBox!: NumericTextBoxProps;
    public thicknessTextBox!: NumericTextBoxProps;

    public widthVisible = false;
    public heightVisible = false;
    public sideLengthVisible = false;
    public diameterVisible = false;
    public thicknessVisible = false;

    public requiredValidator = Validators.required;

    @Input()
    public modalInstance!: ModalInstance<IBaseplateSizeComponentInput>;

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private calculationService: CalculationServicePE
    ) { }

    public get formValid() {
        return (!this.widthVisible || this.widthTextBox.isValid)
            && (!this.heightVisible || this.heightTextBox.isValid)
            && (!this.sideLengthVisible || this.sideLengthTextBox.isValid)
            && (!this.diameterVisible || this.diameterTextBox.isValid)
            && (!this.thicknessVisible || this.thicknessTextBox.isValid);
    }

    private get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        let valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorPlate_Width.id);
        this.widthTextBox = {
            id: 'baseplate-size-input-width',
            title: this.localization.getString('Agito.Hilti.Profis3.BaseplateSize.Width'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorPlate_Height.id);
        this.heightTextBox = {
            id: 'baseplate-size-input-height',
            title: this.localization.getString('Agito.Hilti.Profis3.BaseplateSize.Height'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorPlate_SideLength.id);
        this.sideLengthTextBox = {
            id: 'baseplate-size-input-sidelength',
            title: this.localization.getString('Agito.Hilti.Profis3.BaseplateSize.SideLength'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorPlate_Diameter.id);
        this.diameterTextBox = {
            id: 'baseplate-size-input-diameter',
            title: this.localization.getString('Agito.Hilti.Profis3.BaseplateSize.Diameter'),
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.AnchorPlate_Thickness.id);
        this.thicknessTextBox = {
            id: 'baseplate-size-input-thickness',
            title: this.localization.getString('Agito.Hilti.Profis3.BaseplateSize.Thickness'),
            tooltip: {
                title: this.localization.getString('Agito.Hilti.Profis3.BaseplateSize.Thickness.Tooltip.Title'),
                content: this.localization.getString('Agito.Hilti.Profis3.BaseplateSize.Thickness.Tooltip'),
            },
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        if (this.design.baseplateSize != null) {
            this.widthTextBox.value = this.design.baseplateSize.Width;
            this.widthVisible = this.design.baseplateSize.WidthVisible;

            this.heightTextBox.value = this.design.baseplateSize.Height;
            this.heightVisible = this.design.baseplateSize.HeightVisible;

            this.sideLengthTextBox.value = this.design.baseplateSize.SideLength;
            this.sideLengthVisible = this.design.baseplateSize.SideLengthVisible;

            this.diameterTextBox.value = this.design.baseplateSize.Diameter;
            this.diameterVisible = this.design.baseplateSize.DiameterVisible;

            this.thicknessTextBox.value = this.design.baseplateSize.Thickness;
            this.thicknessVisible = this.design.baseplateSize.ThicknessVisible;
        }
    }

    public save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.calculationService
            .calculateAsync(this.design,
                (design: { baseplateSize: { Width: number; WidthVisible: boolean; Height: number; HeightVisible: boolean; SideLength: number; SideLengthVisible: boolean; Diameter: number; DiameterVisible: boolean; Thickness: number; ThicknessVisible: boolean } }) => {
                    design.baseplateSize = {
                        Width: this.widthTextBox.value as number,
                        WidthVisible: this.widthVisible,

                        Height: this.heightTextBox.value as number,
                        HeightVisible: this.heightVisible,

                        SideLength: this.sideLengthTextBox.value as number,
                        SideLengthVisible: this.sideLengthVisible,

                        Diameter: this.diameterTextBox.value as number,
                        DiameterVisible: this.diameterVisible,

                        Thickness: this.thicknessTextBox.value as number,
                        ThicknessVisible: this.thicknessVisible,
                    };
                },
                { suppressLoadingFlag: true }
            )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                if (this.modalInstance.input?.onSave != null) {
                    this.modalInstance.input.onSave(this.design.baseplateSize);
                }

                this.close();
            })
            .catch((err: any) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    private getPropertyValueRange(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        const retVal: IValueRange = {
            min: propertyInfo.min,
            max: propertyInfo.max
        };
        return retVal;
    }
}
