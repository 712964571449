import { ComponentRef, ElementRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';

interface ComponentElement {
    _ngElementStrategy: ComponentNgElementStrategy;
}

interface ComponentNgElementStrategy {
    hasInputChanges: boolean;
    componentRef: ComponentRef<unknown>;
}

interface Component extends OnChanges, OnInit {
    elementRef: ElementRef<HTMLElement>;
    componentInitialized: boolean | undefined;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function elementsComponent(componentType: Function) : void {
    const ngOnInit = componentType.prototype.ngOnInit;
    const ngOnChanges = componentType.prototype.ngOnChanges;

    let initOnChanges: boolean | undefined = undefined;

    componentType.prototype.ngOnChanges = function (this: Component, changes: SimpleChanges) {
        if (initOnChanges != null) {
            ngOnChanges?.call(this, changes);
        }

        if (initOnChanges) {
            ngOnInit?.call(this);
            if (this.componentInitialized === false) {
                this.componentInitialized = true;
            }

            initOnChanges = false;
        }
    };

    componentType.prototype.ngOnInit = function (this: Component) {
        if (this.elementRef == null) {
            throw new Error('elementRef not found on component');
        }

        const element = this.elementRef.nativeElement as unknown as ComponentElement;

        requestAnimationFrame(() => {
            // we might not get a call to ngOnChanges if an input was not changed or provided
            if (element._ngElementStrategy.hasInputChanges) {
                initOnChanges = true;
            }
            else {
                initOnChanges = false;

                ngOnInit?.call(this);
                if (this.componentInitialized === false) {
                    this.componentInitialized = true;
                }
            }
        });
    };
}
