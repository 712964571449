<div class="content-group" *ngIf="dataLoaded">
    <div class="content design-region">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Masonry.General')"></p>

        <div class="content-controls">
            <pe-dropdown [id]="masonryRegionDropdown.id"
                [items]="masonryRegionDropdown.items"
                [selectedValue]="masonryFixing.masonryRegion"
                (selectedValueChange)="onMasonryRegionDropdownChange($any($event).detail)"
                [title]="masonryRegionDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <div class="design-type-status control-row info">
                <p [class.error]="!allowMasonryQuickStart">{{allowMasonryStatus}}</p>
            </div>
        </div>
        <div class="content-controls">
            <span class="local-steel-material-disclaimer"
                [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer')"></span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="allowMasonryQuickStart && isMasonryNone">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Masonry.Units')"></p>

        <div class="content-controls">
            <pe-dropdown [id]="masonryLengthDropdown.id"
                [items]="masonryLengthDropdown.items"
                [selectedValue]="masonryFixing.masonryLength"
                (selectedValueChange)="masonryFixing.masonryLength=$any($event).detail"
                [title]="masonryLengthDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="masonryAreaDropdown.id"
                [items]="masonryAreaDropdown.items"
                [selectedValue]="masonryFixing.masonryArea"
                (selectedValueChange)="masonryFixing.masonryArea=$any($event).detail"
                [title]="masonryAreaDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="masonryStressDropdown.id"
                [items]="masonryStressDropdown.items"
                [selectedValue]="masonryFixing.masonryStress"
                (selectedValueChange)="masonryFixing.masonryStress=$any($event).detail"
                [title]="masonryStressDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="masonryForceDropdown.id"
                [items]="masonryForceDropdown.items"
                [selectedValue]="masonryFixing.masonryForce"
                (selectedValueChange)="masonryFixing.masonryForce=$any($event).detail"
                [title]="masonryForceDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="masonryMomentDropdown.id"
                [items]="masonryMomentDropdown.items"
                [selectedValue]="masonryFixing.masonryMoment"
                (selectedValueChange)="masonryFixing.masonryMoment=$any($event).detail"
                [title]="masonryMomentDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="masonryTemperatureDropdown.id"
                [items]="masonryTemperatureDropdown.items"
                [selectedValue]="masonryFixing.masonryTemperature"
                (selectedValueChange)="masonryFixing.masonryTemperature=$any($event).detail"
                [title]="masonryTemperatureDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>

            <div class="control-row masonry-anchor-plate-factor">
                <pe-numeric-text-box [id]="masonryAnchorPlateFactor.id"
                    [title]="masonryAnchorPlateFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('masonryAnchorPlateFactor')"
                    [value]="masonryFixing.masonryAnchorPlateFactor"
                    (valueChange)="masonryFixing.masonryAnchorPlateFactor=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('masonryAnchorPlateFactor')"
                    [minValue]="masonryAnchorPlateFactor.minValue"
                    [maxValue]="masonryAnchorPlateFactor.maxValue" [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator"
                    [tooltip]="masonryAnchorPlateFactor.tooltip">
                </pe-numeric-text-box>
            </div>

            <div class="control-row masonry-permanent-load"
                *ngIf="areSafetyFactorsForMasonryVisible">
                <pe-numeric-text-box [id]="masonrySafetyFactorForPermanentLoads.id"
                    [title]="masonrySafetyFactorForPermanentLoads.title"
                    [placeholder]="getNumericTextBoxPlaceholder('masonrySafetyFactorForPermanentLoads')"
                    [value]="masonryFixing.masonrySafetyFactorForPermanentLoads"
                    (valueChange)="masonryFixing.masonrySafetyFactorForPermanentLoads=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('masonrySafetyFactorForPermanentLoads')"
                    [minValue]="masonrySafetyFactorForPermanentLoads.minValue"
                    [maxValue]="masonrySafetyFactorForPermanentLoads.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>

            <div class="control-row masonry-variable-load"
                *ngIf="areSafetyFactorsForMasonryVisible">
                <pe-numeric-text-box [id]="masonrySafetyFactorForVariableLoads.id"
                    [title]="masonrySafetyFactorForVariableLoads.title"
                    [placeholder]="getNumericTextBoxPlaceholder('masonrySafetyFactorForVariableLoads')"
                    [value]="masonryFixing.masonrySafetyFactorForVariableLoads"
                    (valueChange)="masonryFixing.masonrySafetyFactorForVariableLoads=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('masonrySafetyFactorForVariableLoads')"
                    [minValue]="masonrySafetyFactorForVariableLoads.minValue"
                    [maxValue]="masonrySafetyFactorForVariableLoads.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <pe-dropdown [id]="masonryEdgeDistanceDisplay.id"
                [items]="masonryEdgeDistanceDisplay.items"
                [selectedValue]="masonryFixing.masonryEdgeDistanceDisplay"
                (selectedValueChange)="masonryFixing.masonryEdgeDistanceDisplay=$any($event).detail"
                [title]="masonryEdgeDistanceDisplay.title" class="control"
                [disabled]="submitted" [tooltip]="masonryEdgeDistanceDisplay.tooltip">
            </pe-dropdown>
            <div class="application-settings-input-placeholder-container control-row"
                *ngIf="isMinimumAnchorToProfileDistanceAvailable(masonryFixing.masonryDesignStandardId)">

                <pe-numeric-text-box [id]="masonryMinimumAnchorToProfileDistance.id"
                    [title]="masonryMinimumAnchorToProfileDistance.title"
                    [placeholder]="getNumericTextBoxPlaceholder('masonryMinimumAnchorToProfileDistance')"
                    [value]="masonryFixing.masonryMinimumAnchorToProfileDistance"
                    (valueChange)="masonryFixing.masonryMinimumAnchorToProfileDistance=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('masonryMinimumAnchorToProfileDistance')"
                    [minValue]="masonryMinimumAnchorToProfileDistance.minValue"
                    [maxValue]="masonryMinimumAnchorToProfileDistance.maxValue"
                    [disabled]="submitted">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
    <div class="content calculation" *ngIf="allowMasonryQuickStart && isMasonryNone">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorCalculation')"></p>

        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown [id]="masonryDesignStandardDropdown.id"
                        [items]="masonryDesignStandardDropdown.items"
                        [selectedValue]="masonryFixing.masonryDesignStandardId"
                        (selectedValueChange)="onMasonryDesignStandardChange($any($event).detail)"
                        [title]="masonryDesignStandardDropdown.title"
                        class="control control-design-standard-method" [disabled]="submitted">
                    </pe-dropdown>
                    <div class="design-standard-note"
                        *ngIf="selectedMasonryDesignStandardDescription != null"
                        [innerHtml]="translate(selectedMasonryDesignStandardDescription)"></div>
                </div>
            </div>
            <div class="design-method-group control-row column">
                <div class="content">
                    <pe-dropdown [id]="masonryDesignMethodDropdown.id"
                        [items]="masonryDesignMethodDropdown.items"
                        [selectedValue]="masonryFixing.masonryDesignMethodGroupId"
                        (selectedValueChange)="onMasonryDesignMethodChange($any($event).detail)"
                        [title]="masonryDesignMethodDropdown.title"
                        class="control control-design-standard-method" [disabled]="submitted">
                    </pe-dropdown>
                    <div class="design-method-note"
                        *ngIf="selectedMasonryDesignMethodDescription != null"
                        [innerHtml]="translate(selectedMasonryDesignMethodDescription)">
                    </div>
                </div>
            </div>
            <div class="technical-data control-row column">
                <label class="control-label sub-title"
                    [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Masonry.TechnicalData')"></label>

                <pe-checkbox-button
                    class="checkbox"
                    *ngIf="isHiltiTechnicalDataRelevantMasonry"
                    [items]="masonryTechnicalData.items"
                    [selectedValues]="masonryTechnicalData.selectedValues"
                    (selectedValuesChange)="onMasonryTechnicalDataChange()"
                    [disabled]="submitted">
                </pe-checkbox-button>
            </div>
        </div>
    </div>
</div>
