import {
    getCodeListTextDeps, ICodeLists
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    DisplayDesignType, IDetailedDisplayDesign as IDetailedDisplayDesignCommon,
    IDisplayDesign as IDisplayDesignCommon
} from '@profis-engineering/pe-ui-common/entities/display-design';
import {
    DocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    CommonCodeList, CommonCodeListServiceBase
} from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import { DesignType as DesignTypeEntity } from '../entities/code-lists/design-type';
import { DesignCodeList } from '../entities/design-code-list';
import { ProjectCodeList } from '../enums/project-code-list';
import {
    MeasureAnchorPlateMode, MeasureBaseMaterialEdgeFromMode
} from '../generated-modules/Hilti.PE.Core.Common.Enums';
import {
    ProjectDesignBaseEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    AdvancedCalculationType, BrickDataSources, ConcreteInCompressionMethod,
    ConcreteSafetyFactorGammaC, DesignType, HandrailConnectionType, SteelGuideline
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    DesignTemplateInternalEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Template';
import { fromPeUnit, toPeUnit } from '../helpers/unit-helper';
import { CodeListServiceBase } from '../services/code-list.service.base';
import { DesignMethodGroup } from './code-lists/design-method-group';
import { DesignStandard } from './code-lists/design-standard';
import { SteelType } from './code-lists/steel-type';

export interface IDisplayDesign extends IDisplayDesignCommon {
    designStandard: DesignStandard;
    designMethodGroup: DesignMethodGroup;
    integrationDocument?: DocumentModel;
    calculationTypeText: string;
    isCBFEM: boolean;
}

export interface IDetailedDisplayDesign extends IDetailedDisplayDesignCommon {
    designStandard?: DesignStandard;
    designMethodGroup?: DesignMethodGroup;
    integrationDocument?: DocumentModel;
    EtagEnOnly?: boolean;
    brickData: BrickDataSources;
    ostInputsEnabled?: boolean;
    templateName?: string;
    anchorPlateFactor?: number;
    safetyFactorForPermanentLoads?: number;
    safetyFactorForVariableLoads?: number;
    edgeDistanceDisplayType?: number;
    minimumAnchorToProfileDistance?: number;
    concreteSafetyFactorGammaC?: ConcreteSafetyFactorGammaC;
    handrailDisplacementLimit?: number;
    handrailDisplacementLimitBridges?: number;
    jointCoefficientBj?: number;
    effectiveArea?: number;
    effectiveAreaAISC?: number;
    loadDistributionAngle?: number;
    alphaCC?: number;
    limitPlasticStrain?: number;
    divisionOfSurfaceOfCHS?: number;
    numberOfElementsOfEdge?: number;
    numberOfAnalysisIterations?: number;
    divergentIterationsCount?: number;
    minimumSizeOfElement?: number;
    maximumSizeOfElement?: number;
    concreteCapacityFactor?: number;
    materialSafetyFactor?: number;
    steelCapacityFactor?: number;
    steelSafetyFactorGammaM0?: number;
    steelSafetyFactorGammaM1?: number;
    steelSafetyFactorGammaM2?: number;
    steelSafetyFactorGammaM5?: number;
    weldsCapacityFactor?: number;
    useULSStresses?: boolean;
    steelType?: SteelType;
    concreteInCompressionMethod?: ConcreteInCompressionMethod;
    inSteelSafetyFactorGammaM0?: number;
    inSteelSafetyFactorGammaM1?: number;
    inSteelSafetyFactorGammaMw?: number;
    inSteelSafetyFactorGammaMb?: number;
    partialReductionFactorConcrete?: number;
    partialReductionFactorSteel?: number;
    partialReductionFactorFire?: number;
    steelGuideline?: SteelGuideline;
    minimumConcreteCover?: number;
    measureAnchorPlate?: MeasureAnchorPlateMode;
    measureBaseMaterialEdgeFrom?: MeasureBaseMaterialEdgeFromMode;
}

export function detailedDisplayDesignToProjectDesign(projectDesign: ProjectDesignBaseEntity, displayDesign: IDetailedDisplayDesign) {
    projectDesign.Options.DesignMethodGroup = getNumberValueWithDefault(displayDesign.designMethodGroup?.id);
    projectDesign.Options.DesignStandard = getNumberValueWithDefault(displayDesign.designStandard?.id);
    projectDesign.Options.UnitLength = toPeUnit(displayDesign.unitLength);
    projectDesign.Options.UnitArea = toPeUnit(displayDesign.unitArea);
    projectDesign.Options.UnitStress = toPeUnit(displayDesign.unitStress);
    projectDesign.Options.UnitForce = toPeUnit(displayDesign.unitForce);
    projectDesign.Options.UnitMoment = toPeUnit(displayDesign.unitMoment);
    projectDesign.Options.UnitTemperature = toPeUnit(displayDesign.unitTemperature);
    projectDesign.Options.UnitForcePerLength = toPeUnit(displayDesign.unitForcePerLength);
    projectDesign.Options.UnitMomentPerLength = toPeUnit(displayDesign.unitMomentPerLength);
    projectDesign.Options.UnitLengthLarge = toPeUnit(displayDesign.unitLengthLarge);
    projectDesign.Options.UnitStressSmall = toPeUnit(displayDesign.unitStressSmall);
    projectDesign.Options.UnitDensity = toPeUnit(displayDesign.unitDensity);
    projectDesign.Options.EtagEnOnly = getBooleanValueWithDefault(displayDesign.EtagEnOnly);
    projectDesign.Options.OstInputsEnabled = displayDesign.ostInputsEnabled;

    projectDesign.Options.AnchorPlateFactor = displayDesign.anchorPlateFactor;
    projectDesign.Options.SafetyFactorForPermanentLoads = displayDesign.safetyFactorForPermanentLoads;
    projectDesign.Options.SafetyFactorForVariableLoads = displayDesign.safetyFactorForVariableLoads;
    projectDesign.Options.EdgeDistanceDisplayType = getNumberValueWithDefault(displayDesign.edgeDistanceDisplayType);
    projectDesign.Options.MinimumAnchorToProfileDistance = displayDesign.minimumAnchorToProfileDistance;
    projectDesign.Options.ConcreteSafetyFactorGammaC = displayDesign.concreteSafetyFactorGammaC;
    projectDesign.Options.HandrailDisplacementLimit = displayDesign.handrailDisplacementLimit;
    projectDesign.Options.HandrailDisplacementLimitBridges = displayDesign.handrailDisplacementLimitBridges;
    projectDesign.Options.WeldsCapacityFactor = displayDesign.weldsCapacityFactor;
    projectDesign.Options.ConcreteCapacityFactor = displayDesign.concreteCapacityFactor;
    projectDesign.Options.MaterialSafetyFactor = displayDesign.materialSafetyFactor;
    projectDesign.Options.SteelCapacityFactor = displayDesign.steelCapacityFactor;
    projectDesign.Options.SteelSafetyFactorGammaM0 = displayDesign.steelSafetyFactorGammaM0;
    projectDesign.Options.SteelSafetyFactorGammaM1 = displayDesign.steelSafetyFactorGammaM1;
    projectDesign.Options.SteelSafetyFactorGammaM2 = displayDesign.steelSafetyFactorGammaM2;
    projectDesign.Options.SteelSafetyFactorGammaM5 = displayDesign.steelSafetyFactorGammaM5;
    projectDesign.Options.INSteelSafetyFactorGammaM0 = displayDesign.inSteelSafetyFactorGammaM0;
    projectDesign.Options.INSteelSafetyFactorGammaM1 = displayDesign.inSteelSafetyFactorGammaM1;
    projectDesign.Options.INSteelSafetyFactorGammaMw = displayDesign.inSteelSafetyFactorGammaMw;
    projectDesign.Options.INSteelSafetyFactorGammaMb = displayDesign.inSteelSafetyFactorGammaMb;
    projectDesign.Options.SteelGuideline = getNumberValueWithDefault(displayDesign.steelGuideline);
    projectDesign.Options.MinimumConcreteCover = displayDesign.minimumConcreteCover;
    projectDesign.Options.MeasureAnchorPlate = displayDesign.measureAnchorPlate as unknown as MeasureAnchorPlateMode;
    projectDesign.Options.MeasureBaseMaterialEdgeFrom = displayDesign.measureBaseMaterialEdgeFrom as unknown as MeasureBaseMaterialEdgeFromMode;

    projectDesign.Options.AdvancedBaseplateCalculation.JointCoefficientBj = displayDesign.jointCoefficientBj;
    projectDesign.Options.AdvancedBaseplateCalculation.EffectiveArea = displayDesign.effectiveArea;
    projectDesign.Options.AdvancedBaseplateCalculation.EffectiveAreaAISC = displayDesign.effectiveAreaAISC;
    projectDesign.Options.AdvancedBaseplateCalculation.LoadDistributionAngle = displayDesign.loadDistributionAngle;
    projectDesign.Options.AdvancedBaseplateCalculation.AlphaCC = displayDesign.alphaCC;
    projectDesign.Options.AdvancedBaseplateCalculation.LimitPlasticStrain = displayDesign.limitPlasticStrain;
    projectDesign.Options.AdvancedBaseplateCalculation.DivisionOfSurfaceOfCHS = displayDesign.divisionOfSurfaceOfCHS;
    projectDesign.Options.AdvancedBaseplateCalculation.NumberOfElementsOfEdge = displayDesign.numberOfElementsOfEdge;
    projectDesign.Options.AdvancedBaseplateCalculation.NumberOfAnalysisIterations = displayDesign.numberOfAnalysisIterations;
    projectDesign.Options.AdvancedBaseplateCalculation.DivergentIterationsCount = displayDesign.divergentIterationsCount;
    projectDesign.Options.AdvancedBaseplateCalculation.MinimumSizeOfElement = displayDesign.minimumSizeOfElement;
    projectDesign.Options.AdvancedBaseplateCalculation.MaximumSizeOfElement = displayDesign.maximumSizeOfElement;
    projectDesign.Options.AdvancedBaseplateCalculation.UseULSStresses = displayDesign.useULSStresses;
    projectDesign.Options.AdvancedBaseplateCalculation.ConcreteInCompressionMethod = displayDesign.concreteInCompressionMethod;

    return projectDesign;
}

export function projectDesignToDetailedDisplayDesign(
    projectDesign: ProjectDesignBaseEntity,
    documentDesign: IDesignListItem | DesignTemplateInternalEntity,
    codeListService: CodeListServiceBase,
    commonCodeListService: CommonCodeListServiceBase,
    localizationService: LocalizationServiceBase,
    designCodeLists?: ICodeLists) {
    let designId: string;
    let designName: string;
    let projectId: string;
    let projectName: string;
    let integrationDocument: DocumentModel | undefined;
    let designType: DesignTypeEntity | undefined = undefined;
    let designStandard: DesignStandard | undefined = undefined;
    let designMethodGroup: DesignMethodGroup | undefined = undefined;
    let region: CommonRegion | undefined = undefined;
    let steelType: SteelType | undefined;
    let isTemplate: boolean;

    const regions = commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
    const designTypes = codeListService.projectCodeLists[ProjectCodeList.DesignType] as DesignTypeEntity[];
    const designStandards = codeListService.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];
    const designMethodGroups = codeListService.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[];

    designStandard = designStandards.find(standard => standard.id == projectDesign.Options.DesignStandard);
    designMethodGroup = designMethodGroups.find(dMeth => dMeth.id == projectDesign.Options.DesignMethodGroup);
    region = regions.find(region => region.id == projectDesign.Options.RegionId);

    if (designCodeLists != null && projectDesign.AnchorPlate?.SteelTypeId != null) {
        const codeList = designCodeLists[DesignCodeList.SteelType] as SteelType[];
        steelType = codeList.find(steelType => steelType.id == projectDesign.AnchorPlate.SteelTypeId);
    }

    // fill the entity based on whether the design is a template or not
    if ('metaData' in documentDesign) {
        const design = documentDesign;

        isTemplate = false;
        designId = design.id;
        designName = design.designName;
        projectId = design.projectId;
        projectName = design.projectName;
        integrationDocument = design.integrationDocument;

        if (design.metaData?.designType != null) {
            designType = designTypes.find(type => type.id == design.metaData.designType);
        }
    }
    else {
        const design = documentDesign;

        designId = design.DesignTemplateDocumentId;
        designName = design.DesignTemplateName;
        designType = designTypes.find(type => type.id == design.DesignTypeId);
        projectName = designType?.getTranslatedNameText(getCodeListTextDeps(localizationService)) ?? '';
        projectId = null as unknown as string;
        integrationDocument = null as unknown as DocumentModel;
        isTemplate = true;
    }

    const design: IDetailedDisplayDesign = {
        id: designId,
        name: designName,
        projectId,
        projectName,
        region: region as CommonRegion,
        designStandard,
        designMethodGroup,
        designType: designType?.id ?? DesignType.Unknown,
        displayDesignType: isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
        integrationDocument,
        unitLength: fromPeUnit(projectDesign.Options.UnitLength),
        unitArea: fromPeUnit(projectDesign.Options.UnitArea),
        unitStress: fromPeUnit(projectDesign.Options.UnitStress),
        unitForce: fromPeUnit(projectDesign.Options.UnitForce),
        unitMoment: fromPeUnit(projectDesign.Options.UnitMoment),
        unitTemperature: fromPeUnit(projectDesign.Options.UnitTemperature),
        unitForcePerLength: fromPeUnit(projectDesign.Options.UnitForcePerLength),
        unitMomentPerLength: fromPeUnit(projectDesign.Options.UnitMomentPerLength),
        unitLengthLarge: fromPeUnit(projectDesign.Options.UnitLengthLarge),
        unitStressSmall: fromPeUnit(projectDesign.Options.UnitStressSmall),
        unitDensity: fromPeUnit(projectDesign.Options.UnitDensity),
        unitAreaPerLength: fromPeUnit(projectDesign.Options.UnitAreaPerLength),
        EtagEnOnly: projectDesign.Options.EtagEnOnly,
        brickData: BrickDataSources.ETA,
        ostInputsEnabled: projectDesign.Options.OstInputsEnabled,
        concreteCapacityFactor: projectDesign.Options.ConcreteCapacityFactor,
        materialSafetyFactor: projectDesign.Options.MaterialSafetyFactor,
        steelCapacityFactor: projectDesign.Options.SteelCapacityFactor,
        weldsCapacityFactor: projectDesign.Options.WeldsCapacityFactor,
        templateName: isTemplate ? designName : '',
        jointCoefficientBj: projectDesign.Options.AdvancedBaseplateCalculation.JointCoefficientBj,
        effectiveArea: projectDesign.Options.AdvancedBaseplateCalculation.EffectiveArea,
        effectiveAreaAISC: projectDesign.Options.AdvancedBaseplateCalculation.EffectiveAreaAISC,
        loadDistributionAngle: projectDesign.Options.AdvancedBaseplateCalculation.LoadDistributionAngle,
        alphaCC: projectDesign.Options.AdvancedBaseplateCalculation.AlphaCC,
        limitPlasticStrain: projectDesign.Options.AdvancedBaseplateCalculation.LimitPlasticStrain,
        divisionOfSurfaceOfCHS: projectDesign.Options.AdvancedBaseplateCalculation.DivisionOfSurfaceOfCHS,
        numberOfElementsOfEdge: projectDesign.Options.AdvancedBaseplateCalculation.NumberOfElementsOfEdge,
        numberOfAnalysisIterations: projectDesign.Options.AdvancedBaseplateCalculation.NumberOfAnalysisIterations,
        divergentIterationsCount: projectDesign.Options.AdvancedBaseplateCalculation.DivergentIterationsCount,
        minimumSizeOfElement: projectDesign.Options.AdvancedBaseplateCalculation.MinimumSizeOfElement,
        maximumSizeOfElement: projectDesign.Options.AdvancedBaseplateCalculation.MaximumSizeOfElement,
        useULSStresses: projectDesign.Options.AdvancedBaseplateCalculation.UseULSStresses,
        anchorPlateFactor: projectDesign.Options.AnchorPlateFactor,
        safetyFactorForPermanentLoads: projectDesign.Options.SafetyFactorForPermanentLoads,
        safetyFactorForVariableLoads: projectDesign.Options.SafetyFactorForVariableLoads,
        edgeDistanceDisplayType: projectDesign.Options.EdgeDistanceDisplayType,
        minimumAnchorToProfileDistance: projectDesign.Options.MinimumAnchorToProfileDistance,
        concreteSafetyFactorGammaC: projectDesign.Options.ConcreteSafetyFactorGammaC,
        handrailDisplacementLimit: projectDesign.Options.HandrailDisplacementLimit,
        steelSafetyFactorGammaM0: projectDesign.Options.SteelSafetyFactorGammaM0,
        steelSafetyFactorGammaM1: projectDesign.Options.SteelSafetyFactorGammaM1,
        steelSafetyFactorGammaM2: projectDesign.Options.SteelSafetyFactorGammaM2,
        steelSafetyFactorGammaM5: projectDesign.Options.SteelSafetyFactorGammaM5,
        steelType,
        concreteInCompressionMethod: projectDesign.Options.AdvancedBaseplateCalculation.ConcreteInCompressionMethod,
        inSteelSafetyFactorGammaM0: projectDesign.Options.INSteelSafetyFactorGammaM0,
        inSteelSafetyFactorGammaM1: projectDesign.Options.INSteelSafetyFactorGammaM1,
        inSteelSafetyFactorGammaMw: projectDesign.Options.INSteelSafetyFactorGammaMw,
        inSteelSafetyFactorGammaMb: projectDesign.Options.INSteelSafetyFactorGammaMb,
        steelGuideline: projectDesign.Options.SteelGuideline,
        minimumConcreteCover: projectDesign.Options.MinimumConcreteCover,
        measureAnchorPlate: projectDesign.Options.MeasureAnchorPlate,
        measureBaseMaterialEdgeFrom: projectDesign.Options.MeasureBaseMaterialEdgeFrom
    };

    return design;
}

function getNumberValueWithDefault(value?: number, defaultValue = 0) {
    return value ?? defaultValue;
}

function getBooleanValueWithDefault(value?: boolean, defaultValue = false) {
    return value ?? defaultValue;
}

export function isCBFEMCalculation(calculationType?: AdvancedCalculationType): boolean {
    return [AdvancedCalculationType.Realistic, AdvancedCalculationType.FEM, AdvancedCalculationType.BPRigidityCheck].includes(calculationType ?? AdvancedCalculationType.Unknown);
}

export function isHandrailCBFEMCalculation(designType: DesignType, handrailConnectionType?: HandrailConnectionType) {
    if (designType != DesignType.Handrail || !handrailConnectionType) {
        return false;
    }

    switch (handrailConnectionType) {
        case HandrailConnectionType.FrontWelded90Degree:
        case HandrailConnectionType.BottomWelded90Degree:
        case HandrailConnectionType.TopDirectWelded:
            return false;
        default:
            return true;
    }
}

export function getCalculationTypeText(designType: DesignType, calculationType: number, connectionType: number, localizationService: LocalizationServiceBase): string {
    if (isCBFEMCalculation(calculationType) || isHandrailCBFEMCalculation(designType as number, connectionType)) {
        return localizationService.getString('Agito.Hilti.Profis3.ExportReports.CBFEM');
    }

    return localizationService.getString('Agito.Hilti.Profis3.ExportReports.Rigid');
}
