import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    SupplementaryReinforcementType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import {
    SupplementaryReinforcementCategory
} from '@profis-engineering/pe-gl-model/components/supplementary-reinforcement-helper';

import {
    SupplementaryReinforcementDiameter
} from '../../../../shared/entities/code-lists/supplementary-reinforcement-diameter';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class SupplementaryReinforcementUpdate extends BaseUpdate {

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged(UIProperty.SupplementaryReinforcement_CEdgeEffective)
    private CEdge_Effective(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.cEdgeEffective = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_CEdgeEffectiveLeft)
    private CEdge_EffectiveLeft(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.cEdgeEffectiveLeft = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_CEdgeEffectiveRight)
    private CEdge_EffectiveRight(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.cEdgeEffectiveRight = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_CTopEffective)
    private CTop_Effective(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.cTopEffective = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_CBottomEffective)
    private CBottom_Effective(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.cBottomEffective = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_ReinforcementDiameter)
    private ReinforcementDiameter(diameterId: number, model: IModelPe, design: DesignPe) {
        if (diameterId != null && model.supplementaryReinforcement) {
            const codelist = design.designData.designCodeLists[DesignCodeList.SupplementaryReinforcementDiameter] as SupplementaryReinforcementDiameter[];
            const reinforcementDiameter = codelist.find(x => x.id == diameterId);

            model.supplementaryReinforcement.reinforcementDiameter = reinforcementDiameter?.diameter;
        }
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_ReinforcementSpacing)
    private ReinforcementSpacing(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        if (model.supplementaryReinforcement.reinforcementCategory == SupplementaryReinforcementCategory.Shear) {
            model.supplementaryReinforcement.reinforcementSpacing = value;
        }
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_TensionReinforcementSpacing)
    private ReinforcementTensionSpacing(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        if (model.supplementaryReinforcement.reinforcementCategory == SupplementaryReinforcementCategory.Tension) {
            model.supplementaryReinforcement.reinforcementSpacing = value;
        }
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_SurfaceReinforcement)
    private SurfaceReinforcementDiameter(diameterId: number, model: IModelPe, design: DesignPe) {
        if (diameterId != null && model.supplementaryReinforcement) {
            const codelist = design.designData.designCodeLists[DesignCodeList.SupplementaryReinforcementDiameter] as SupplementaryReinforcementDiameter[];
            const reinforcementDiameter = codelist.find(x => x.id == diameterId);

            model.supplementaryReinforcement.surfaceReinforcementDiameter = reinforcementDiameter?.diameter;
        }
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_ReinforcementType)
    private SurfaceReinforcementType(value: SupplementaryReinforcementType, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.reinforcementType = (value as number);
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_ReinforcementCategory)
    private SupplementaryReinforcementCategory(value: SupplementaryReinforcementCategory, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.reinforcementCategory = (value as number);
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_FirstRebarCenterOffset)
    private FirstRebarCenterOffset(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.firstRebarCenterOffset = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_Rotate)
    private SupplementaryReinforcementRotation(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.rotation = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_Width)
    private Width(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.width = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_Height)
    private Height(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.height = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_TensionLeftWidth)
    private TensionLeftWidth(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.TensionLeftWidth = value;
    }

    @propertyValueChanged(UIProperty.SupplementaryReinforcement_TensionRightWidth)
    private TensionRightWidth(value: number, model: IModelPe) {
        if (!model.supplementaryReinforcement) {
            throw new Error('model supplementaryReinforcement is undefined');
        }

        model.supplementaryReinforcement.TensionRightWidth = value;
    }
}
