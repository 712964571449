<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="'CASE DETAILS'"
    [closeButtonId]="'asad-optimize-details-modal-close-button'">
</pe-modal-header>

<pe-modal-body>
    <pe-section [title]="'General'" *ngIf="optimizeCaseDetails">
        <p class="item">FastenerId: {{optimizeCaseDetails.fastenerId}}</p>
        <p class="item">Anchors: {{optimizeCaseDetails.numberOfAnchors}}</p>
        <p class="item">Layout: {{optimizeCaseDetails.layoutNumber}}</p>
        <p class="item">ExitFlag: {{optimizeCaseOutput == null ? '' : (optimizeCaseOutput.exitFlag == null ? '-' : optimizeCaseOutput.exitFlag)}}</p>
        <p class="item">FastenerFamilyId: {{optimizeCaseDetails.fastenerFamilyId}}</p>
        <p class="item">IsAtToolAllowed: {{optimizeCaseDetails.isAtToolAllowed}}</p>
        <p class="item">IsAutoCleaningAllowed: {{optimizeCaseDetails.isAutoCleaningAllowed}}</p>
        <p class="item">EngineeringValue: {{optimizeCaseDetails.engineeringValue}}</p>

        <ng-container *ngIf="optimizeCaseOutput && optimizeCaseOutput.exitFlag != null && optimizeCaseOutput.exitFlag >= 0">
            <p class="item">SearchAlgorithm: {{searchAlgorithmToString(optimizeCaseOutput.searchAlgorithm)}}</p>
            <p class="item">Area: {{optimizeCaseOutput.area}}</p>
            <p class="item">Evaluations: {{optimizeCaseOutput.constraintEvaluationsCount}}</p>
            <p class="item">CalculationTime: {{optimizeCaseOutput.calculationTime.toFixed(2) + ' sec'}}</p>
            <p class="item">AnchorPlateWidth: {{formatLength(optimizeCaseOutput.anchorPlateWidth)}}</p>
            <p class="item">AnchorPlateHeight: {{formatLength(optimizeCaseOutput.anchorPlateHeight)}}</p>
            <p class="item">ProfileEccentricityX: {{formatLength(optimizeCaseOutput.profileEccentricityX)}}</p>
            <p class="item">ProfileEccentricityY: {{formatLength(optimizeCaseOutput.profileEccentricityY)}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.embedmentDepth != null">EmbedmentDepth: {{formatLength(optimizeCaseOutput.embedmentDepth)}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.fillHoles != null">FillHoles: {{optimizeCaseOutput.fillHoles}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.isEdgeXNegativeReinforced != null">IsEdgeXNegativeReinforced: {{optimizeCaseOutput.isEdgeXNegativeReinforced}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.isEdgeXPositiveReinforced != null">IsEdgeXPositiveReinforced: {{optimizeCaseOutput.isEdgeXPositiveReinforced}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.isEdgeYNegativeReinforced != null">IsEdgeYNegativeReinforced: {{optimizeCaseOutput.isEdgeYNegativeReinforced}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.isEdgeYPositiveReinforced != null">IsEdgeYPositiveReinforced: {{optimizeCaseOutput.isEdgeYPositiveReinforced}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.baseMaterialEdgeXNegative != null">BaseMaterialEdgeXNegative: {{formatLength(optimizeCaseOutput.baseMaterialEdgeXNegative)}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.baseMaterialEdgeXPositive != null">BaseMaterialEdgeXPositive: {{formatLength(optimizeCaseOutput.baseMaterialEdgeXPositive)}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.baseMaterialEdgeYNegative != null">BaseMaterialEdgeYNegative: {{formatLength(optimizeCaseOutput.baseMaterialEdgeYNegative)}}</p>
            <p class="item" *ngIf="optimizeCaseOutput.baseMaterialEdgeYPositive != null">BaseMaterialEdgeYPositive: {{formatLength(optimizeCaseOutput.baseMaterialEdgeYPositive)}}</p>
        </ng-container>
    </pe-section>

    <ng-container *ngIf="optimizeCaseOutput && optimizeCaseOutput.exitFlag != null && optimizeCaseOutput.exitFlag >= 0">
        <pe-section [title]="'Anchor Coordinates - ' + optimizeCaseDetails?.numberOfAnchors + ' [' + lengthUnitString + ']'" [collapsed]="true">
            <table class="field-value anchor-coordinates-table">
                <caption>Anchor coordinates table</caption>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>x</th>
                        <th>y</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let anchor of optimizeCaseOutput.anchorCoordinates; index as ii">
                        <td>{{ii}}</td>
                        <td>{{formatLengthWithoutUnit(anchor.x)}}</td>
                        <td>{{formatLengthWithoutUnit(anchor.y)}}</td>
                    </tr>
                </tbody>
            </table>
        </pe-section>

        <pe-section [title]="'Utilizations - ' + maxUtilizations" [collapsed]="true">
            <p class="utilization-row" *ngFor="let utilization of optimizeCaseOutput.utilizations | keyvalue">{{utilization.key}}: {{utilization.value != null ? formatPercentage(utilization.value * 100) : 'None'}}</p>
            <p class="field-value" *ngIf="keys(optimizeCaseOutput.utilizations).length == 0">None</p>
        </pe-section>
    </ng-container>

    <ng-container *ngIf="includeLogs">
        <pe-section [title]="'Logs'" [collapsed]="true">
            <div class="logs-container" [class.fullscreen]="logsFullscreen">
                <textarea class="field-value logs-textarea" name="logs" [ngModel]="logs" readonly autocomplete="off"
                    autocorrect="off" autocapitalize="off" spellcheck="false"></textarea>
                <div class="logs-buttons">
                    <pe-button
                        class="modal-button button field-value"
                        id="asad-optimize-details-modal-logs-save-button"
                        [look]="'Default'"
                        (buttonClick)="saveLogs()"
                        text="Save">
                    </pe-button>

                    <pe-button
                        *ngIf="!logsFullscreen"
                        class="modal-button button field-value"
                        id="asad-optimize-details-modal-logs-open-button"
                        [look]="'Default'"
                        (buttonClick)="logsFullscreen = true"
                        text="Full screen">
                    </pe-button>

                    <pe-button
                        *ngIf="logsFullscreen"
                        class="modal-button button field-value"
                        id="asad-optimize-details-modal-logs-close-button"
                        [look]="'Default'"
                        (buttonClick)="logsFullscreen = false"
                        text="Close">
                    </pe-button>
                </div>
            </div>
        </pe-section>
    </ng-container>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        class="modal-button"
        id="asad-optimize-details-modal-close-button"
        [look]="'Default'"
        (buttonClick)="close()"
        [text]="'Close'">
    </pe-button>
</pe-modal-footer>
