import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import fatigueCombinationsPart1Image from '../../../images/fatigue-loads-info/fatigue-combinations-part1.png';
import fatigueCombinationsPart2Image from '../../../images/fatigue-loads-info/fatigue-combinations-part2.png';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './fatigue-loads-info.component.html',
    styleUrls: ['./fatigue-loads-info.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class FatigueLoadsInfoComponent {
    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        private user: UserService,
        private modal: ModalService,
        private localization: LocalizationService
    ) { }

    public get isDetailedOutputDisabled() {
        return this.user.design.designData.reportData?.DetailedCalculationOutput == null
            || this.user.design.designData.reportData.DetailedCalculationOutput.length == 0;
    }

    public get imagePart1() {
        return fatigueCombinationsPart1Image;
    }

    public get imagePart2() {
        return fatigueCombinationsPart2Image;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public openDetailedCalculationOutput() {
        this.modal.openDetailedCalculationOutput();
    }
}
