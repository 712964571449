import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import {
    AnchorTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IAnchorTypeConstructor extends ICodeListConstructor {
    familyId?: number;
    CIPLongPlate?: boolean;
    internalPortfolioOnly?: boolean;
}

export class AnchorType extends CodeList {
    public familyId?: number;
    public cipLongPlate?: boolean;
    public internalPortfolioOnly?: boolean;

    constructor(codeList: IAnchorTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.familyId = codeList.familyId;
            this.cipLongPlate = codeList.CIPLongPlate;
            this.internalPortfolioOnly = codeList.internalPortfolioOnly;
        }
    }

    public static fromService(codeList: AnchorTypeEntity) {
        return new AnchorType(CodeList.baseFromService<IAnchorTypeConstructor>('AnchorTypeEntity', codeList, {
            familyId: codeList.FamilyId,
            CIPLongPlate: codeList.CIPLongPlate,
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-anchor-family-' + codeList.Image.replace(/\..+$/, '') : 'sprite-anchor-family-no-photo-available',
            tag: codeList.Tag ? 'Agito.Hilti.Profis3.Anchors.Untested.Tag' : undefined,
            internalPortfolioOnly: codeList.InternalPortfolioOnly
        } as IAnchorTypeConstructor));
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        const years = codeListDeps.localizationService.getString('Agito.Hilti.Profis3.Anchors.Years');
        return formatKeyValue(this.name ?? '', { years });
    }

    public override cloneDeep(): AnchorType {
        const codeList: AnchorType = super.cloneDeep() as AnchorType;

        codeList.familyId = this.familyId;
        codeList.cipLongPlate = this.cipLongPlate;
        codeList.internalPortfolioOnly = this.internalPortfolioOnly;

        return codeList;
    }
}
