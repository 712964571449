<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="modalTitle"
    [closeButtonId]="'select-profile-close-button'">
</pe-modal-header>

<pe-modal-body>
    <div class="popup-wrapper">
        <div class="popup-filter" *ngIf="showProfileFilter">
            <div class="left">
                <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.SelectProfile.Title')"></span>
            </div>
            <div class="right">
                <pe-dropdown
                    [id]="profileFilterDropdown.id"
                    [items]="profileFilterDropdown.items"
                    [selectedValue]="profileFilterDropdown.selectedValue"
                    (selectedValueChange)="onProfileFilterDropdownChange($any($event).detail)">
                </pe-dropdown>
            </div>
        </div>

        <div class="popup-table" #profileSizeTable>
            <div class="table-header">
                <div class="table-row">
                    <div class="table-cell" *ngFor="let column of columns" [innerHtml]="column.title"></div>
                    <div class="favorite-cell"></div>
                </div>
            </div>
            <div class="table-body"
                [class.scroll-with-filter]="showProfileFilter">
                <div *ngFor="let item of sortedItems"
                    [id]="'popup-' + item.id + '-button'"
                    class="table-row modal-grid-button"
                    (click)="select(item)"
                    [class.selected]="selectedValue == item.id"
                    [class.odd]="isOdd(item)"
                    [class.even]="isEven(item)">
                    <div class="table-cell" *ngFor="let column of columns"
                        [class.filtered-out]="isClassFilteredOutEnabled(item)"
                        [innerHtml]="getItemPropertyValue(item, column.property)">
                    </div>

                    <div class="favorite-cell">
                        <button *ngIf="item.id != null" type="button"
                            class="favorite-button"
                            (click)="$event.preventDefault(); $event.stopPropagation(); favoriteToggle(item.id)"
                            [ngbTooltip]="favoriteTooltip">
                            <span class="favorite-image pe-ui-pe-sprite" [ngClass]="getFavoriteSprite(item.id)"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</pe-modal-body>
