import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import {
    DistanceInsideTownTerrainEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface IDistanceInsideTownTerrain extends ICodeListConstructor {
    value: number;
}

export class DistanceInsideTownTerrain extends CodeList {
    public value!: number;

    constructor(codelist: IDistanceInsideTownTerrain) {
        super(codelist);

        if (codelist != null) {
            this.value = codelist.value;
        }
    }

    public static fromService(codeList: DistanceInsideTownTerrainEntity) {
        return new DistanceInsideTownTerrain(CodeList.baseFromService<IDistanceInsideTownTerrain>('DistanceInsideTownTerrainEntity', codeList, {
            value: codeList.Value
        } as IDistanceInsideTownTerrain));
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        if (codeListDeps.numberService == null) {
            throw new Error('codeListDeps.numberService not set!');
        }

        const number = codeListDeps.numberService.format(this.value, Number.MAX_SAFE_INTEGER);
        return `${number} km`;
    }

    public override cloneDeep(): DistanceInsideTownTerrain {
        const codelist: DistanceInsideTownTerrain = super.cloneDeep() as DistanceInsideTownTerrain;

        codelist.value = this.value;

        return codelist;
    }
}
