import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import {
    SeismicDesignTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface ISeismicDesignTypeConstructor extends ICodeListConstructor {
    descriptionResourceKey?: string;
}

export class SeismicDesignType extends CodeList {
    public descriptionResourceKey?: string;

    constructor(codeList: ISeismicDesignTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.descriptionResourceKey = codeList.descriptionResourceKey;
        }
    }

    public static fromService(codeList: SeismicDesignTypeEntity) {
        const seismicDesignType = new SeismicDesignType(CodeList.baseFromService<ICodeListConstructor>('SeismicDesignTypeEntity', codeList, {
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-' + codeList.Image : 'sprite-seismic-type-no-photo-available'
        } as ICodeListConstructor));

        // set description resource key
        seismicDesignType.descriptionResourceKey = seismicDesignType.nameResourceKey != null && seismicDesignType.nameResourceKey != '' ? seismicDesignType.nameResourceKey + '.Description' : undefined;

        return seismicDesignType;
    }

    public override getTranslatedDescriptionText(codeListDeps: ICodeListTextDeps) {
        if (this.descriptionResourceKey == null || this.descriptionResourceKey == '') {
            return undefined;
        }

        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        return codeListDeps.localizationService.getString(this.descriptionResourceKey);
    }

    public override cloneDeep(): SeismicDesignType {
        const codeList = super.cloneDeep() as SeismicDesignType;

        codeList.descriptionResourceKey = this.descriptionResourceKey;

        return codeList;
    }
}
