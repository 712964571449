import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import {
    SeismicShearTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface ISeismicShearTypeConstructor extends ICodeListConstructor {
    descriptionResourceKey?: string;
    kbNumberRegion?: { [Key: string]: string };
    kbTooltip?: string;
}

export class SeismicShearType extends CodeList {
    public descriptionResourceKey?: string;

    constructor(codeList: ISeismicShearTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.descriptionResourceKey = codeList.descriptionResourceKey;
            this.kbNumberRegion = codeList.kbNumberRegion;
            this.kbTooltip = codeList.kbTooltip;
        }
    }

    public static fromService(codeList: SeismicShearTypeEntity) {
        const seismicshearType = new SeismicShearType(CodeList.baseFromService<ICodeListConstructor>('SeismicShearTypeEntity', codeList, {
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-' + codeList.Image : 'sprite-seismic-type-no-photo-available'
        } as ICodeListConstructor));

        // set description resource key
        seismicshearType.descriptionResourceKey = seismicshearType.nameResourceKey != null && seismicshearType.nameResourceKey != '' ? seismicshearType.nameResourceKey + '.Description' : undefined;
        seismicshearType.kbNumberRegion = codeList.KBNumberAciRegion;
        seismicshearType.kbTooltip = codeList.KBTooltipDisplayKeyAci;
        return seismicshearType;
    }

    public override getTranslatedDescriptionText(codeListDeps: ICodeListTextDeps) {
        if (this.descriptionResourceKey == null || this.descriptionResourceKey == '') {
            return undefined;
        }

        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        return codeListDeps.localizationService.getString(this.descriptionResourceKey);
    }

    public override cloneDeep(): SeismicShearType {
        const codeList = super.cloneDeep() as SeismicShearType;

        codeList.descriptionResourceKey = this.descriptionResourceKey;
        codeList.kbNumberRegion = this.kbNumberRegion;
        codeList.kbTooltip = this.kbTooltip;

        return codeList;
    }
}
