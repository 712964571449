<pe-modal-header [modalInstance]="modalInstance" [title]="title">
</pe-modal-header>

<pe-modal-body >
    <div class="image-wrapper">
        <img [src]="image" alt="title">
    </div>
    <span class="learn-more" [innerHtml]="learnMore"></span>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        [look]="'Primary'"
        (buttonClick)="close()"
        [text]="localizationService.getString('Agito.Hilti.Profis3.Ok')">
    </pe-button>
</pe-modal-footer>