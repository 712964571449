import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToggleButtonGroupItem, ToggleButtonGroupType } from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { LocalizationService } from '../../services/localization.service';
import { getSpriteAsIconStyle, Sprite } from '../../sprites';
import { SliderType } from '@profis-engineering/pe-ui-common/components/slider/slider.common';
import { UnitService } from '../../services/unit.service';
import { Options } from '@angular-slider/ngx-slider';
import { IAsadPreferencesFilteringParameters, IBaseplateFilterOutput } from '../../../shared/entities/baseplate-inputs';
import { MultiSelectDropdownProps, DropdownItem } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { AnchorFilterType } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { SmartAnchorOrderService } from '../../services/smart-anchor-order.service';

export enum NoOfAnchor {
  Unknown = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Six = 6,
  Eight = 8,
  Nine = 9,
  Twelve = 12,
  Sixteen = 16
}

@Component({
  templateUrl: './smart-baseplate-filter.component.html',
  styleUrls: ['./smart-baseplate-filter.component.scss']
})
export class SmartBaseplateFilterComponent implements OnChanges, OnInit {


  @Input()
  filterParameters!: IAsadPreferencesFilteringParameters;

  @Output()
  baseplateFilterChange = new EventEmitter<IBaseplateFilterOutput>();

  anchorLayoutList: number[] = [];
  selectedAnchorLayouts: number[] = [];
  selectedEmbedmentDepth = 0;
  lowUtilizationValue?: number;
  highUtilizationValue?: number;
  xDirectionValue?: number;
  yDirectionValue?: number;

  baseplateFilterOutput!: IBaseplateFilterOutput;
  anchorLayoutToggleList: ToggleButtonGroupItem[] = [];
  typeOfToggle = ToggleButtonGroupType.multiple;
  singleSlider: SliderType = SliderType.Single;
  rangeSlider = SliderType.Range;
  embedmentUnitGroup: UnitGroup = UnitGroup.Length;
  utilizationUnitGroup: UnitGroup = UnitGroup.Length;
  loadSprites: [NoOfAnchor, Sprite][] = [];
  loadToIconMap: Map<NoOfAnchor, IIconStyle> = new Map<NoOfAnchor, IIconStyle>();
  constructionOptions: DropdownItem<AnchorFilterType>[] = [];

  sizeListOptionDdl: MultiSelectDropdownProps<string> = {
    id: 'size-list-design-options',
    items: [],
    selectedValues: [],
  };
  constructionOptionDdl: MultiSelectDropdownProps<AnchorFilterType> = {
    id: 'construction-design-options',
    items: [],
    selectedValues: [],
  };

  private userSelectedEmbedmentDepth?: number;
  private userSelectedXDirection?: number;
  private userSelectedYDirection?: number;
  private userSelectedLowUtilization?: number;
  private userSelectedHighUtilization?: number;

  appliedFiltersCount?: number;
  embedmentDepthOptions!: Options;
  xDirectionOptions!: Options;
  yDirectionOptions!: Options;
  utilizationOptions!: Options;

  constructor(
    public localizationService: LocalizationService,
    public smartAnchorOrderService: SmartAnchorOrderService,
    public unitService: UnitService
  ) {
  }

  private formatLength(value: number): string {
    return value ? this.unitService.formatInternalValueAsDefault(value, UnitGroup.Length) : '';
  }


  ngOnInit(): void {
    this.baseplateFilterOutput = {
      baseplateWidth: 0,
      baseplateHeight: 0,
      constructionOption: [],
      embedmentDepth: 0,
      maxUtilization: 0,
      minUtilization: 0,
      selectedAnchorLayout: [],
      selectedAnchors: [],
      selectedAnchorSizes: []
    };

    this.loadSprites = [
      [NoOfAnchor.One, 'sprite-anchor-ai-1'],
      [NoOfAnchor.Two, 'sprite-anchor-ai-2'],
      [NoOfAnchor.Three, 'sprite-anchor-ai-3'],
      [NoOfAnchor.Four, 'sprite-anchor-ai-4'],
      [NoOfAnchor.Six, 'sprite-anchor-ai-6'],
      [NoOfAnchor.Eight, 'sprite-anchor-ai-8'],
      [NoOfAnchor.Nine, 'sprite-anchor-ai-9'],
      [NoOfAnchor.Twelve, 'sprite-anchor-ai-12'],
      [NoOfAnchor.Sixteen, 'sprite-anchor-ai-16'],
    ];

    this.loadToIconMap = new Map<NoOfAnchor, IIconStyle>(this.loadSprites.map(m => [m[0], getSpriteAsIconStyle(m[1])]));

    this.constructionOptionDdl.items = this.smartAnchorOrderService.constructionOptions;
    this.sizeListOptionDdl.title = this.translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.AnchorSizes');
    this.sizeListOptionDdl.notSelectedText = this.translate('Agito.Hilti.Profis3.Select');
    this.constructionOptionDdl.title = this.translate('Agito.Hilti.Profis3.SmartBasePlate.FilterPanel.Type');
    this.constructionOptionDdl.notSelectedText = this.translate('Agito.Hilti.Profis3.Select');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterParameters']) {
      this.setFilterOption();
    }
  }

  reset() {
    // Anchor layout
    this.baseplateFilterOutput.selectedAnchorLayout = [];
    this.selectedAnchorLayouts = [];
    this.filterParameters.selectedAnchorLayouts = [];

    // Anchor sizes
    this.baseplateFilterOutput.selectedAnchorSizes = [];
    this.sizeListOptionDdl.selectedValues = [];

    // Construction options
    this.baseplateFilterOutput.constructionOption = [];
    this.constructionOptionDdl.selectedValues = [];

    // Utilizations
    this.userSelectedLowUtilization = undefined;
    this.baseplateFilterOutput.minUtilization = this.filterParameters.utilizationMin;
    this.userSelectedHighUtilization = undefined;
    this.baseplateFilterOutput.maxUtilization = this.filterParameters.utilizationMax;

    // Embedment depth
    this.userSelectedEmbedmentDepth = undefined;
    this.baseplateFilterOutput.embedmentDepth = this.filterParameters.embedmentDepthMax;

    // Baseplate width
    this.userSelectedXDirection = undefined;
    this.baseplateFilterOutput.baseplateWidth = this.filterParameters.baseplateWidthMax ?? 0;

    // Baseplate height
    this.userSelectedYDirection = undefined;
    this.baseplateFilterOutput.baseplateHeight = this.filterParameters.baseplateHeightMax ?? 0;

    this.setFilterOption();
    this.changeBaseplateFilter();
  }

  updateAppliedFiltersCount() {
    this.appliedFiltersCount = 0;

    if (this.selectedAnchorLayouts.length) {
      this.appliedFiltersCount++;
    }

    if (this.sizeListOptionDdl.selectedValues?.length) {
      this.appliedFiltersCount++;
    }

    if (this.constructionOptionDdl.selectedValues?.length) {
      this.appliedFiltersCount++;
    }

    if ((this.userSelectedLowUtilization && this.userSelectedLowUtilization != this.filterParameters.utilizationMin * 100) ||
      (this.userSelectedHighUtilization && this.userSelectedHighUtilization != this.filterParameters.utilizationMax * 100)) {
      this.appliedFiltersCount++;
    }

    if (this.userSelectedEmbedmentDepth && this.userSelectedEmbedmentDepth != this.filterParameters.embedmentDepthMax) {
      this.appliedFiltersCount++;
    }

    if (this.userSelectedXDirection && this.userSelectedXDirection != this.filterParameters.baseplateWidthMax) {
      this.appliedFiltersCount++;
    }

    if (this.userSelectedYDirection && this.userSelectedYDirection != this.filterParameters.baseplateHeightMax) {
      this.appliedFiltersCount++;
    }

    return this.appliedFiltersCount;
  }

  private setFilterOption() {
    this.anchorLayoutList = this.filterParameters.anchorLayoutList ?? [];

    if (this.anchorLayoutList.length > 0) {
      this.anchorLayoutToggleList = this.mapAnchorLayoutPointsToggle();
    }

    this.selectedAnchorLayouts = this.filterParameters.selectedAnchorLayouts ?? [];

    if (this.filterParameters.anchorSizeList && this.filterParameters.anchorSizeList.length > 0) {
      const sizeItemList: any[] = [];
      this.filterParameters.anchorSizeList.forEach((e, index) => {
        sizeItemList.push({ id: index, value: e, text: e });
      });

      this.sizeListOptionDdl.items = sizeItemList;
    }

    if (!this.userSelectedEmbedmentDepth) {
      this.selectedEmbedmentDepth = this.filterParameters.embedmentDepthMax;
    }

    this.embedmentDepthOptions = {
      ceil: this.filterParameters.embedmentDepthMax,
      floor: this.filterParameters.embedmentDepthMin,
      step: 0.001,
      showTicksValues: false,
      showSelectionBar: true,
      animate: false,
      animateOnMove: false,
      translate: (value: number): string => {
        return this.formatLength(value);
      }
    };

    if (!this.userSelectedXDirection) {
      this.xDirectionValue = this.filterParameters.baseplateWidthMax ?? 0;
    }

    this.xDirectionOptions = {
      ceil: this.filterParameters.baseplateWidthMax,
      floor: this.filterParameters.baseplateWidthMin,
      step: 0.001,
      showTicksValues: false,
      showSelectionBar: true,
      animate: false,
      animateOnMove: false,
      translate: (value: number): string => {
        return this.formatLength(value);
      }
    };

    if (!this.userSelectedYDirection) {
      this.yDirectionValue = this.filterParameters.baseplateHeightMax ?? 0;
    }

    this.yDirectionOptions = {
      ceil: this.filterParameters.baseplateHeightMax,
      floor: this.filterParameters.baseplateHeightMin,
      step: 0.001,
      showTicksValues: false,
      showSelectionBar: true,
      animate: false,
      animateOnMove: false,
      translate: (value: number): string => {
        return this.formatLength(value);
      }
    };

    if (!this.userSelectedLowUtilization) {
      this.lowUtilizationValue = this.filterParameters.utilizationMin * 100;
    }

    if (!this.userSelectedHighUtilization) {
      this.highUtilizationValue = this.filterParameters.utilizationMax * 100;
    }

    this.utilizationOptions = {
      step: 1,
      showTicksValues: false,
      showSelectionBar: true,
      animate: false,
      animateOnMove: false,
      noSwitching: true,
      floor: 0,
      ceil: 100,
      minLimit: this.filterParameters.utilizationMin * 100,
      maxLimit: this.filterParameters.utilizationMax * 100,
      translate: (value: number): string => {
        return value.toFixed(0) + '%';
      }
    };
  }

  public translate(key: string) {
    return this.localizationService.getString(key);
  }

  private mapAnchorLayoutPointsToggle() {
    const loadTypeToggleItems: ToggleButtonGroupItem<NoOfAnchor>[] = [];

    for (const anchorFilter of this.anchorLayoutList) {

      switch (anchorFilter) {
        case NoOfAnchor.One:
          loadTypeToggleItems.push({
            value: NoOfAnchor.One,
            image: this.loadToIconMap.get(NoOfAnchor.One),
          });
          break;
        case NoOfAnchor.Two:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Two,
            image: this.loadToIconMap.get(NoOfAnchor.Two),
          });
          break;
        case NoOfAnchor.Three:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Three,
            image: this.loadToIconMap.get(NoOfAnchor.Three),
          });
          break;
        case NoOfAnchor.Four:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Four,
            image: this.loadToIconMap.get(NoOfAnchor.Four),
          });
          break;
        case NoOfAnchor.Six:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Six,
            image: this.loadToIconMap.get(NoOfAnchor.Six),
          });
          break;
        case NoOfAnchor.Eight:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Eight,
            image: this.loadToIconMap.get(NoOfAnchor.Eight),
          });
          break;
        case NoOfAnchor.Nine:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Nine,
            image: this.loadToIconMap.get(NoOfAnchor.Nine),
          });
          break;
        case NoOfAnchor.Twelve:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Twelve,
            image: this.loadToIconMap.get(NoOfAnchor.Twelve),
          });
          break;
        case NoOfAnchor.Sixteen:
          loadTypeToggleItems.push({
            value: NoOfAnchor.Sixteen,
            image: this.loadToIconMap.get(NoOfAnchor.Sixteen),
          });
          break;
      }

    }
    return loadTypeToggleItems;
  }

  changeNoOfAnchorsPostFilter(e: number[]) {
    this.baseplateFilterOutput.selectedAnchorLayout = e;
    this.changeBaseplateFilter();
  }

  selectedEmbedmentDepthChange(e: number) {
    this.userSelectedEmbedmentDepth = e;
    this.selectedEmbedmentDepth = e;
    this.baseplateFilterOutput.embedmentDepth = this.selectedEmbedmentDepth ?? 0;
    this.changeBaseplateFilter();
  }

  xDirectionChange(e: number) {
    this.xDirectionValue = e;
    this.userSelectedXDirection = e;
    this.baseplateFilterOutput.baseplateWidth = (this.xDirectionValue ?? 0);
    this.changeBaseplateFilter();
  }

  yDirectionChange(e: number) {
    this.yDirectionValue = e;
    this.userSelectedYDirection = e;
    this.baseplateFilterOutput.baseplateHeight = (this.yDirectionValue ?? 0);
    this.changeBaseplateFilter();
  }

  lowUtilizationChange(e: number) {
    this.userSelectedLowUtilization = e;
    this.lowUtilizationValue = e;
    this.baseplateFilterOutput.minUtilization = e / 100;
    this.changeBaseplateFilter();
  }

  highUtilizationChange(e: number) {
    this.highUtilizationValue = e;
    this.userSelectedHighUtilization = e;
    this.baseplateFilterOutput.maxUtilization = e / 100;
    this.changeBaseplateFilter();
  }

  constructionSelectionChange(e: AnchorFilterType[]) {
    this.constructionOptionDdl.selectedValues = e;
    this.baseplateFilterOutput.constructionOption = e;
    this.changeBaseplateFilter();
  }

  anchorSizeOptionChange(e: string[]) {
    this.sizeListOptionDdl.selectedValues = e;
    this.baseplateFilterOutput.selectedAnchorSizes = e;
    this.changeBaseplateFilter();
  }

  private changeBaseplateFilter() {
    this.updateAppliedFiltersCount();
    this.baseplateFilterChange.emit(this.baseplateFilterOutput);
  }
}
