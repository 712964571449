import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import {
    IIntegrationsNotificationPopupComponentInput
} from '../../../shared/components/integrations-notification-popup';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './integrations-notification-popup.component.html',
    styleUrls: ['./integrations-notification-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class IntegrationsNotificationPopupComponent {
    @Input()
    modalInstance!: ModalInstance<IIntegrationsNotificationPopupComponentInput>;

    constructor(
        private localization: LocalizationService,
    ) { }

    public get id() {
        return this.modalInstance.input?.id ?? 'integrations-notification-popup';
    }

    public get title() {
        const text = this.modalInstance.input?.title;
        return text != null && text != ''
            ? this.localization.sanitizeText(text, LocalizationService.SubSup)
            : '&nbsp;';
    }

    public get message() {
        const text = this.modalInstance.input?.message;
        return text != null && text != ''
            ? this.localization.sanitizeText(text, LocalizationService.PBrB)
            : '&nbsp;';
    }

    public get confirmButtonText() {
        const text = this.modalInstance.input?.confirmButtonText;
        return text != null && text != ''
            ? this.localization.sanitizeText(text, LocalizationService.SubSup)
            : '&nbsp;';
    }

    public confirm() {
        this.modalInstance.close(true);
    }

    public dismiss(reason?: string) {
        this.modalInstance.dismiss(reason);
    }
}
