import {
    WHGTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class WHGType extends CodeList {

    public static fromService(codeList: WHGTypeEntity) {
        return new WHGType(CodeList.baseFromService('WHGTypeEntity', codeList));
    }

    public override cloneDeep(): WHGType {
        return super.cloneDeep() as WHGType;
    }
}
