import {
    ICodeListConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import {
    BrickStrengthLrfdEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IBrickStrengthAc58Constructor extends ICodeListConstructor {
    value: number;
}

export class BrickStrengthAc58 extends CodeList {
    public value?: number;

    constructor(codeList: IBrickStrengthAc58Constructor) {
        super(codeList);

        if (codeList != null) {
            this.value = codeList.value;
        }
    }

    public static fromService(codeList: BrickStrengthLrfdEntity) {
        return new BrickStrengthAc58(CodeList.baseFromService<IBrickStrengthAc58Constructor>('BrickStrengthAc58Entity', codeList, {
            value: codeList.Value
        } as IBrickStrengthAc58Constructor));
    }

    public override cloneDeep() {
        const codeList: BrickStrengthAc58 = super.cloneDeep() as BrickStrengthAc58;

        codeList.value = this.value;

        return codeList;
    }
}
