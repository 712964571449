import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';

import { DesignPe } from '../../../shared/entities/design-pe';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './input-handrail-loads.component.html',
    styleUrls: ['./input-handrail-loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class InputHandrailLoadsComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance;

    public submitted = false;

    public propertyMetaData = PropertyMetaData;

    public linearLoadOutwardsTextBox!: NumericTextBoxProps;
    public linearLoadInwardsTextBox!: NumericTextBoxProps;
    public linearLoadOutwardsHeightTextBox!: NumericTextBoxProps;
    public linearLoadInwardsHeightTextBox!: NumericTextBoxProps;
    public punctualLoadOutwardsTextBox!: NumericTextBoxProps;
    public punctualLoadInwardsTextBox!: NumericTextBoxProps;
    public infillPunctualLoadOutwardsTextBox!: NumericTextBoxProps;
    public infillPunctualLoadInwardsTextBox!: NumericTextBoxProps;
    public punctualLoadOutwardsHeightTextBox!: NumericTextBoxProps;
    public punctualLoadInwardsHeightTextBox!: NumericTextBoxProps;
    public punctualLoadInWorstPositionCheckbox!: CheckboxButtonProps<boolean>;
    public verticalLoadTextBox!: NumericTextBoxProps;
    public verticalPunctualLoadTextBox!: NumericTextBoxProps;
    public verticalPunctualLoadInWorstPositionCheckbox!: CheckboxButtonProps<boolean>;
    public infillLoadOutwardsTextBox!: NumericTextBoxProps;
    public infillLoadInwardsTextBox!: NumericTextBoxProps;

    private pendingSave = false;

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private calculationService: CalculationServicePE
    ) { }

    public get formValid() {
        return this.linearLoadOutwardsTextBox.isValid
            && this.linearLoadInwardsTextBox.isValid
            && this.linearLoadOutwardsHeightTextBox.isValid
            && this.linearLoadInwardsHeightTextBox.isValid
            && this.punctualLoadOutwardsTextBox.isValid
            && this.punctualLoadInwardsTextBox.isValid
            && (this.isPropertyHidden(PropertyMetaData.Loads_InfillPunctualLoadOutwards.id) || this.infillPunctualLoadOutwardsTextBox.isValid)
            && (this.isPropertyHidden(PropertyMetaData.Loads_InfillPunctualLoadInwards.id) || this.infillPunctualLoadInwardsTextBox.isValid)
            && this.punctualLoadOutwardsHeightTextBox.isValid
            && this.punctualLoadInwardsHeightTextBox.isValid
            && this.verticalLoadTextBox.isValid
            && (this.isPropertyHidden(PropertyMetaData.Loads_VerticalPunctualLoad.id) || this.verticalPunctualLoadTextBox.isValid)
            && (this.isPropertyHidden(PropertyMetaData.Loads_InfillLoadOutwards.id) || this.infillLoadOutwardsTextBox.isValid)
            && (this.isPropertyHidden(PropertyMetaData.Loads_InfillLoadInwards.id) || this.infillLoadInwardsTextBox.isValid);
    }

    private get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });


        let valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_LinearLoadOutwards.id);
        this.linearLoadOutwardsTextBox = {
            id: 'input-handrail-loads-linear-load-outwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.LinearLoadOutwards'),
            value: this.design.linearLoadOutwards,
            unit: this.design.unitForcePerLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_LinearLoadInwards.id);
        this.linearLoadInwardsTextBox = {
            id: 'input-handrail-loads-linear-load-inwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.LinearLoadInwards'),
            value: this.design.linearLoadInwards,
            unit: this.design.unitForcePerLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_LinearLoadOutwardsHeight.id);
        this.linearLoadOutwardsHeightTextBox = {
            id: 'input-handrail-loads-height-of-outwards-linear-loads',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.HeightOfOutwardsLinearLoads'),
            value: this.design.linearLoadOutwardsHeight,
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_LinearLoadInwardsHeight.id);
        this.linearLoadInwardsHeightTextBox = {
            id: 'input-handrail-loads-height-of-inwards-linear-loads',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.HeightOfInwardsLinearLoads'),
            value: this.design.linearLoadInwardsHeight,
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_PunctualLoadOutwards.id);
        this.punctualLoadOutwardsTextBox = {
            id: 'input-handrail-loads-punctual-load-outwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.PunctualLoadOutwards'),
            value: this.design.punctualLoadOutwards,
            unit: this.design.unitForce,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_PunctualLoadInwards.id);
        this.punctualLoadInwardsTextBox = {
            id: 'input-handrail-loads-punctual-load-inwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.PunctualLoadInwards'),
            value: this.design.punctualLoadInwards,
            unit: this.design.unitForce,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_InfillPunctualLoadOutwards.id);
        this.infillPunctualLoadOutwardsTextBox = {
            id: 'input-handrail-loads-infill-punctual-load-outwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.InfillPunctualLoadOutwards'),
            value: this.design.infillPunctualLoadOutwards,
            unit: this.design.unitForce,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_InfillPunctualLoadInwards.id);
        this.infillPunctualLoadInwardsTextBox = {
            id: 'input-handrail-loads-infill-punctual-load-inwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.InfillPunctualLoadInwards'),
            value: this.design.infillPunctualLoadInwards,
            unit: this.design.unitForce,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_PunctualLoadOutwardsHeight.id);
        this.punctualLoadOutwardsHeightTextBox = {
            id: 'input-handrail-loads-height-of-outwards-punctual-loads',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.HeightOfOutwardsPunctualLoads'),
            value: this.design.punctualLoadOutwardsHeight,
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_PunctualLoadInwardsHeight.id);
        this.punctualLoadInwardsHeightTextBox = {
            id: 'input-handrail-loads-height-of-inwards-punctual-loads',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.HeightOfInwardsPunctualLoads'),
            value: this.design.punctualLoadInwardsHeight,
            unit: this.design.unitLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        this.punctualLoadInWorstPositionCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            id: 'input-handrail-loads-punctual-load-in-worst-position',
            checked: this.design.punctualLoadInWorstPosition,
            itemText: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.PointLoadActingInWorstPosition'),
        });

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_VerticalLoad.id);
        this.verticalLoadTextBox = {
            id: 'input-handrail-loads-vertical-load',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.VerticalLinearLoad'),
            value: this.design.verticalLoad,
            unit: this.design.unitForcePerLength,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_VerticalPunctualLoad.id);
        this.verticalPunctualLoadTextBox = {
            id: 'input-handrail-loads-vertical-punctual-load',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.VerticalPunctualLoad'),
            value: this.design.verticalPunctualLoad,
            unit: this.design.unitForce,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        this.verticalPunctualLoadInWorstPositionCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            id: 'input-handrail-loads-vertical-punctual-load-in-worst-position',
            checked: this.design.verticalPunctualLoadInWorstPosition,
            itemText: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.VerticalPointLoadActingInWorstPosition')
        });

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_InfillLoadOutwards.id);
        this.infillLoadOutwardsTextBox = {
            id: 'input-handrail-loads-infill-load-outwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.InfillLoadOutwards'),
            value: this.design.infillLoadOutwards,
            unit: this.design.unitStressSmall,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_InfillLoadInwards.id);
        this.infillLoadInwardsTextBox = {
            id: 'input-handrail-loads-infill-load-inwards',
            title: this.localization.getString('Agito.Hilti.Profis3.InputHandrailLoads.InfillLoadInwards'),
            value: this.design.infillLoadInwards,
            unit: this.design.unitStressSmall,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.calculationService
            .calculateAsync(this.design,
                (design) => {
                    design.linearLoadOutwards = this.linearLoadOutwardsTextBox.value ?? 0;
                    design.linearLoadInwards = this.linearLoadInwardsTextBox.value ?? 0;
                    design.linearLoadOutwardsHeight = this.linearLoadOutwardsHeightTextBox.value ?? 0;
                    design.linearLoadInwardsHeight = this.linearLoadInwardsHeightTextBox.value ?? 0;
                    design.punctualLoadOutwards = this.punctualLoadOutwardsTextBox.value ?? 0;
                    design.punctualLoadInwards = this.punctualLoadInwardsTextBox.value ?? 0;

                    design.punctualLoadOutwardsHeight = this.punctualLoadOutwardsHeightTextBox.value ?? 0;
                    design.punctualLoadInwardsHeight = this.punctualLoadInwardsHeightTextBox.value ?? 0;

                    design.verticalLoad = this.verticalLoadTextBox.value ?? 0;

                    this.checkAndSaveHiddenProperties(design);
                },
                { suppressLoadingFlag: true }
            )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    public close() {
        this.modalInstance.close();
    }

    public isPropertyDisabled(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.disabled : true;
    }

    public isPropertyHidden(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.hidden : true;
    }

    private getPropertyValueRange(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        const retVal: IValueRange = {
            min: propertyInfo.min,
            max: propertyInfo.max
        };
        return retVal;
    }

    private checkAndSaveHiddenProperties(design: DesignPe) {
        if (!this.isPropertyHidden(PropertyMetaData.Loads_InfillPunctualLoadOutwards.id)) {
            design.infillPunctualLoadOutwards = this.infillPunctualLoadOutwardsTextBox.value ?? 0;
        }

        if (!this.isPropertyHidden(PropertyMetaData.Loads_InfillPunctualLoadInwards.id)) {
            design.infillPunctualLoadInwards = this.infillPunctualLoadInwardsTextBox.value ?? 0;
        }

        if (!this.isPropertyHidden(PropertyMetaData.Loads_PunctualLoadInWorstPosition.id)) {
            design.punctualLoadInWorstPosition = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.punctualLoadInWorstPositionCheckbox) ?? false;
        }

        if (!this.isPropertyHidden(PropertyMetaData.Loads_VerticalPunctualLoad.id)) {
            design.verticalPunctualLoad = this.verticalPunctualLoadTextBox.value ?? 0;
        }

        if (!this.isPropertyHidden(PropertyMetaData.Loads_InfillLoadOutwards.id)) {
            design.infillLoadOutwards = this.infillLoadOutwardsTextBox.value ?? 0;
        }

        if (!this.isPropertyHidden(PropertyMetaData.Loads_InfillLoadInwards.id)) {
            design.infillLoadInwards = this.infillLoadInwardsTextBox.value ?? 0;
        }
    }
}
