import {
    IAdvancedCalculationApplicationSettings
} from '@profis-engineering/pe-ui-common/entities/user-settings';

import { IAdvancedCalculationData } from '../entities/advanced-calculation-inputs';
import { IDetailedDisplayDesign } from '../entities/display-design';
import {
    AdvancedBaseplateCalculationDataEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';

export class AdvancedCalculationInputsHelper {
    public static loadAdvancedCalculationData(advancedCalculation: IAdvancedCalculationApplicationSettings, advancedCalculationData: IAdvancedCalculationData) {
        advancedCalculationData.alphaCC = advancedCalculation.alphaCC.value as number;
        advancedCalculationData.concreteInCompressionMethod = advancedCalculation.concreteInCompressionMethod.value as number;
        advancedCalculationData.divergentIterationsCount = advancedCalculation.divergentIterationsCount.value as number;
        advancedCalculationData.divisionOfArcsOfRHS = advancedCalculation.divisionOfArcsOfRHS.value as number;   // XXX: not used for design
        advancedCalculationData.divisionOfSurfaceOfCHS = advancedCalculation.divisionOfSurfaceOfCHS.value as number;
        advancedCalculationData.effectiveArea = advancedCalculation.effectiveArea.value as number;
        advancedCalculationData.effectiveAreaAISC = advancedCalculation.effectiveAreaAISC.value as number;
        advancedCalculationData.jointCoefficientBj = advancedCalculation.jointCoefficientBj.value as number;
        advancedCalculationData.limitPlasticStrain = advancedCalculation.limitPlasticStrain.value as number;
        advancedCalculationData.loadDistributionAngle = advancedCalculation.loadDistributionAngle.value as number;
        advancedCalculationData.maximumSizeOfElement = advancedCalculation.maximumSizeOfElement.value as number;
        advancedCalculationData.minimumSizeOfElement = advancedCalculation.minimumSizeOfElement.value as number;
        advancedCalculationData.numberOfAnalysisIterations = advancedCalculation.numberOfAnalysisIterations.value as number;
        advancedCalculationData.numberOfElementsOfEdge = advancedCalculation.numberOfElementsOfEdge.value as number;
        advancedCalculationData.useULSStresses = advancedCalculation.useULSStresses.value as boolean;
        return advancedCalculationData;
    }

    public static updateUserSettings(advancedCalculation: IAdvancedCalculationApplicationSettings, advancedCalculationData: IAdvancedCalculationData) {
        advancedCalculation.alphaCC.value = advancedCalculationData.alphaCC ?? null;
        advancedCalculation.concreteInCompressionMethod.value = advancedCalculationData.concreteInCompressionMethod ?? null;
        advancedCalculation.divergentIterationsCount.value = advancedCalculationData.divergentIterationsCount ?? null;
        advancedCalculation.divisionOfArcsOfRHS.value = advancedCalculationData.divisionOfArcsOfRHS ?? null;
        advancedCalculation.divisionOfSurfaceOfCHS.value = advancedCalculationData.divisionOfSurfaceOfCHS ?? null;
        advancedCalculation.effectiveArea.value = advancedCalculationData.effectiveArea ?? null;
        advancedCalculation.effectiveAreaAISC.value = advancedCalculationData.effectiveAreaAISC ?? null;
        advancedCalculation.jointCoefficientBj.value = advancedCalculationData.jointCoefficientBj ?? null;
        advancedCalculation.limitPlasticStrain.value = advancedCalculationData.limitPlasticStrain ?? null;
        advancedCalculation.loadDistributionAngle.value = advancedCalculationData.loadDistributionAngle ?? null;
        advancedCalculation.maximumSizeOfElement.value = advancedCalculationData.maximumSizeOfElement ?? null;
        advancedCalculation.minimumSizeOfElement.value = advancedCalculationData.minimumSizeOfElement ?? null;
        advancedCalculation.numberOfAnalysisIterations.value = advancedCalculationData.numberOfAnalysisIterations ?? null;
        advancedCalculation.numberOfElementsOfEdge.value = advancedCalculationData.numberOfElementsOfEdge ?? null;
        advancedCalculation.useULSStresses.value = advancedCalculationData.useULSStresses ?? null;
    }

    public static updateDesign(design: IDetailedDisplayDesign, advancedCalculationData: IAdvancedCalculationData) {
        design.jointCoefficientBj = advancedCalculationData.jointCoefficientBj;
        design.effectiveArea = advancedCalculationData.effectiveArea;
        design.effectiveAreaAISC = advancedCalculationData.effectiveAreaAISC;
        design.loadDistributionAngle = advancedCalculationData.loadDistributionAngle;
        design.alphaCC = advancedCalculationData.alphaCC;
        design.limitPlasticStrain = advancedCalculationData.limitPlasticStrain;
        design.divisionOfSurfaceOfCHS = advancedCalculationData.divisionOfSurfaceOfCHS;
        design.numberOfElementsOfEdge = advancedCalculationData.numberOfElementsOfEdge;
        design.numberOfAnalysisIterations = advancedCalculationData.numberOfAnalysisIterations;
        design.divergentIterationsCount = advancedCalculationData.divergentIterationsCount;
        design.minimumSizeOfElement = advancedCalculationData.minimumSizeOfElement;
        design.maximumSizeOfElement = advancedCalculationData.maximumSizeOfElement;
        design.useULSStresses = advancedCalculationData.useULSStresses;
        design.concreteInCompressionMethod = advancedCalculationData.concreteInCompressionMethod;
    }

    public static loadFromDesign(design: IDetailedDisplayDesign, advancedCalculationData: IAdvancedCalculationData) {
        advancedCalculationData.effectiveAreaAISC = design.effectiveAreaAISC;
        advancedCalculationData.effectiveArea = design.effectiveArea;
        advancedCalculationData.jointCoefficientBj = design.jointCoefficientBj;
        advancedCalculationData.loadDistributionAngle = design.loadDistributionAngle;
        advancedCalculationData.alphaCC = design.alphaCC;
        advancedCalculationData.limitPlasticStrain = design.limitPlasticStrain;
        advancedCalculationData.divisionOfSurfaceOfCHS = design.divisionOfSurfaceOfCHS;
        advancedCalculationData.numberOfElementsOfEdge = design.numberOfElementsOfEdge;
        advancedCalculationData.numberOfAnalysisIterations = design.numberOfAnalysisIterations;
        advancedCalculationData.divergentIterationsCount = design.divergentIterationsCount;
        advancedCalculationData.minimumSizeOfElement = design.minimumSizeOfElement;
        advancedCalculationData.maximumSizeOfElement = design.maximumSizeOfElement;
        advancedCalculationData.useULSStresses = design.useULSStresses;
        advancedCalculationData.concreteInCompressionMethod = design.concreteInCompressionMethod;
    }

    public static getAdvancedBaseplateEntity(advancedCalculationData: IAdvancedCalculationData) {
        const advancedBaseplateCalculation: AdvancedBaseplateCalculationDataEntity = {
            JointCoefficientBj: advancedCalculationData.jointCoefficientBj,
            EffectiveArea: advancedCalculationData.effectiveArea,
            EffectiveAreaAISC: advancedCalculationData.effectiveAreaAISC,
            LoadDistributionAngle: advancedCalculationData.loadDistributionAngle,
            AlphaCC: advancedCalculationData.alphaCC,
            LimitPlasticStrain: advancedCalculationData.limitPlasticStrain,
            DivisionOfArcsOfRHS: advancedCalculationData.divisionOfArcsOfRHS,
            DivisionOfSurfaceOfCHS: advancedCalculationData.divisionOfSurfaceOfCHS,
            NumberOfElementsOfEdge: advancedCalculationData.numberOfElementsOfEdge,
            NumberOfAnalysisIterations: advancedCalculationData.numberOfAnalysisIterations,
            DivergentIterationsCount: advancedCalculationData.divergentIterationsCount,
            MinimumSizeOfElement: advancedCalculationData.minimumSizeOfElement,
            MaximumSizeOfElement: advancedCalculationData.maximumSizeOfElement,
            UseULSStresses: advancedCalculationData.useULSStresses,
            ConcreteInCompressionMethod: advancedCalculationData.concreteInCompressionMethod,
            StopAtLimitStrain: false
        };
        return advancedBaseplateCalculation;
    }
}
