<div id="integration-notification-popup-modal-content-container">
    <pe-modal-header
        [modalInstance]="modalInstance"
        [title]="title"
        [closeButtonId]="id + '-close-button'"
        [preventDefaultClose]="true"
        (closing)="dismiss('x-click')">
    </pe-modal-header>

    <pe-modal-body>
        <div class="modal-message" [innerHtml]="message"></div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button [id]="id + '-button-2'" [look]="'Cta'" (buttonClick)="confirm()" [text]="confirmButtonText"></pe-button>
    </pe-modal-footer>
</div>
