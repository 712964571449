import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { environment } from '../../../environments/environmentPe';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './whg-info.component.html',
    styleUrls: ['./whg-info.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class WhgInfoComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public whgMessage = '';

    constructor(
        private localizationService: LocalizationService
    ) { }

    public ngOnInit(): void {
        this.whgMessage = this.localizationService.getString('Agito.Hilti.Profis3.WHGInfo.Description', { tags: LocalizationService.A })
            .replace('{whgInfoLink}', environment.whgInfoLink);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
