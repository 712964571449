<div class="content-group" *ngIf="dataLoaded">
    <div class="content design-region">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Handrail.General')"></p>

        <div class="content-controls">
            <pe-dropdown [id]="handrailRegionDropdown.id"
                [items]="handrailRegionDropdown.items"
                [selectedValue]="handrailFixing.handrailRegion"
                (selectedValueChange)="onHandrailRegionDropdownChange($any($event).detail)"
                [title]="handrailRegionDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>

            <div class="design-type-status control-row info">
                <p [class.error]="!allowHandrailQuickStart">{{allowHandrailStatus}}</p>
            </div>
        </div>
        <div class="content-controls">
            <span class="local-steel-material-disclaimer"
                [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer')"></span>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="allowHandrailQuickStart && isHandrailNone">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Handrail.Units')"></p>

        <div class="content-controls">
            <pe-dropdown [id]="handrailLengthLargeDropdown.id"
                [items]="handrailLengthLargeDropdown.items"
                [selectedValue]="handrailFixing.handrailLengthLarge"
                (selectedValueChange)="handrailFixing.handrailLengthLarge=$any($event).detail"
                [title]="handrailLengthLargeDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="handrailLengthDropdown.id"
                [items]="handrailLengthDropdown.items"
                [selectedValue]="handrailFixing.handrailLength"
                (selectedValueChange)="handrailFixing.handrailLength=$any($event).detail"
                [title]="handrailLengthDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="handrailAreaDropdown.id"
                [items]="handrailAreaDropdown.items"
                [selectedValue]="handrailFixing.handrailArea"
                (selectedValueChange)="handrailFixing.handrailArea=$any($event).detail"
                [title]="handrailAreaDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="handrailStressDropdown.id"
                [items]="handrailStressDropdown.items"
                [selectedValue]="handrailFixing.handrailStress"
                (selectedValueChange)="handrailFixing.handrailStress=$any($event).detail"
                [title]="handrailStressDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="handrailStressSmallDropdown.id"
                [items]="handrailStressSmallDropdown.items"
                [selectedValue]="handrailFixing.handrailStressSmall"
                (selectedValueChange)="handrailFixing.handrailStressSmall=$any($event).detail"
                [title]="handrailStressSmallDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="handrailForceDropdown.id"
                [items]="handrailForceDropdown.items"
                [selectedValue]="handrailFixing.handrailForce"
                (selectedValueChange)="handrailFixing.handrailForce=$any($event).detail"
                [title]="handrailForceDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>
            <pe-dropdown [id]="handrailMomentDropdown.id"
                [items]="handrailMomentDropdown.items"
                [selectedValue]="handrailFixing.handrailMoment"
                (selectedValueChange)="handrailFixing.handrailMoment=$any($event).detail"
                [title]="handrailMomentDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown [id]="handrailTemperatureDropdown.id"
                [items]="handrailTemperatureDropdown.items"
                [selectedValue]="handrailFixing.handrailTemperature"
                (selectedValueChange)="handrailFixing.handrailTemperature=$any($event).detail"
                [title]="handrailTemperatureDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>

            <pe-dropdown [id]="handrailForcePerLengthDropdown.id"
                [items]="handrailForcePerLengthDropdown.items"
                [selectedValue]="handrailFixing.handrailForcePerLength"
                (selectedValueChange)="handrailFixing.handrailForcePerLength=$any($event).detail"
                [title]="handrailForcePerLengthDropdown.title" class="control"
                [disabled]="submitted">
            </pe-dropdown>

            <div class="control-row handrail-anchor-plate-factor">
                <pe-numeric-text-box [id]="handrailAnchorPlateFactor.id"
                    [title]="handrailAnchorPlateFactor.title"
                    [placeholder]="getNumericTextBoxPlaceholder('handrailAnchorPlateFactor')"
                    [value]="handrailFixing.handrailAnchorPlateFactor"
                    (valueChange)="handrailFixing.handrailAnchorPlateFactor=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('handrailAnchorPlateFactor')"
                    [minValue]="handrailAnchorPlateFactor.minValue"
                    [maxValue]="handrailAnchorPlateFactor.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator"
                    [tooltip]="handrailAnchorPlateFactor.tooltip">
                </pe-numeric-text-box>
            </div>

            <pe-dropdown [id]="handrailEdgeDistanceDisplay.id"
                [items]="handrailEdgeDistanceDisplay.items"
                [selectedValue]="handrailFixing.handrailEdgeDistanceDisplay"
                (selectedValueChange)="handrailFixing.handrailEdgeDistanceDisplay=$any($event).detail"
                [title]="handrailEdgeDistanceDisplay.title" class="control"
                [disabled]="submitted" [tooltip]="handrailEdgeDistanceDisplay.tooltip">
            </pe-dropdown>

            <div class="application-settings-input-placeholder-container control-row"
                *ngIf="isMinimumAnchorToProfileDistanceAvailable(handrailFixing.handrailDesignStandardId)">
                <pe-numeric-text-box [id]="handrailMinimumAnchorToProfileDistance.id"
                    [title]="handrailMinimumAnchorToProfileDistance.title"
                    [placeholder]="getNumericTextBoxPlaceholder('handrailMinimumAnchorToProfileDistance')"
                    [value]="handrailFixing.handrailMinimumAnchorToProfileDistance"
                    (valueChange)="handrailFixing.handrailMinimumAnchorToProfileDistance=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('handrailMinimumAnchorToProfileDistance')"
                    [minValue]="handrailMinimumAnchorToProfileDistance.minValue"
                    [maxValue]="handrailMinimumAnchorToProfileDistance.maxValue"
                    [disabled]="submitted">
                </pe-numeric-text-box>
            </div>
            <div class="control-row handrail-displacement-limit">
                <pe-numeric-text-box [id]="handrailDisplacementLimit.id"
                    [title]="handrailDisplacementLimit.title"
                    [placeholder]="getNumericTextBoxPlaceholder('handrailDisplacementLimit')"
                    [value]="handrailFixing.handrailDisplacementLimit"
                    (valueChange)="handrailFixing.handrailDisplacementLimit=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('handrailDisplacementLimit')"
                    [minValue]="handrailDisplacementLimit.minValue"
                    [maxValue]="handrailDisplacementLimit.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator"
                    [prefix]="handrailDisplacementLimit.prefix"
                    [infoPopup]="isDeflectionLimitsVisible ? handrailDisplacementLimit.infoPopup : undefined"
                    [infoPopupTooltip]="isDeflectionLimitsVisible ? handrailDisplacementLimit.infoPopupTooltip : undefined">
                </pe-numeric-text-box>
            </div>
            <div class="control-row handrail-displacement-limit">
                <pe-numeric-text-box [id]="handrailDisplacementLimitBridges.id"
                    [title]="handrailDisplacementLimitBridges.title"
                    [placeholder]="getNumericTextBoxPlaceholder('handrailDisplacementLimitBridges')"
                    [value]="handrailFixing.handrailDisplacementLimitBridges"
                    (valueChange)="handrailFixing.handrailDisplacementLimitBridges=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('handrailDisplacementLimitBridges')"
                    [minValue]="handrailDisplacementLimitBridges.minValue"
                    [maxValue]="handrailDisplacementLimitBridges.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator"
                    [prefix]="handrailDisplacementLimitBridges.prefix">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content calculation" *ngIf="allowHandrailQuickStart && isHandrailNone">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorCalculation')"></p>

        <div class="content-controls">
            <div class="design-standard control-row column">
                <div class="content">
                    <pe-dropdown [id]="handrailDesignStandardDropdown.id"
                        [items]="handrailDesignStandardDropdown.items"
                        [selectedValue]="handrailFixing.handrailDesignStandardId"
                        (selectedValueChange)="onHandrailDesignStandardChange($any($event).detail)"
                        [title]="handrailDesignStandardDropdown.title"
                        class="control control-design-standard-method" [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-standard-note"
                        *ngIf="selectedHandrailDesignStandardDescription != null"
                        [innerHtml]="translate(selectedHandrailDesignStandardDescription)"></div>
                </div>
            </div>
            <div class="design-method-group control-row column">
                <div class="content">
                    <pe-dropdown [id]="handrailDesignMethodDropdown.id"
                        [items]="handrailDesignMethodDropdown.items"
                        [selectedValue]="handrailFixing.handrailDesignMethodGroupId"
                        (selectedValueChange)="onHandrailDesignMethodChange($any($event).detail)"
                        [title]="handrailDesignMethodDropdown.title"
                        class="control control-design-standard-method" [disabled]="submitted">
                    </pe-dropdown>

                    <div class="design-method-note"
                        *ngIf="selectedHandrailDesignMethodDescription != null"
                        [innerHtml]="translate(selectedHandrailDesignMethodDescription)">
                    </div>
                </div>

                <pe-checkbox-button *ngIf="isHandrailEtagEnOnlyCheckboxAvailable"
                    [items]="handrailEtagEnOnly.items"
                    [selectedValues]="handrailEtagEnOnly.selectedValues"
                    (selectedValuesChange)="onHandrailEtagEnOnlyChange()"
                    class="checkbox"
                    [disabled]="submitted">
                </pe-checkbox-button>
            </div>
            <div class="technical-data control-row column">
                <label class="control-label sub-title"
                [innerHtml]="translate('Agito.Hilti.Profis3.ApplicationSettings.Handrail.TechnicalData')"></label>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs"
        *ngIf="allowHandrailQuickStart && isHandrailNone && areSafetyFactorsForHandrailVisible">
        <p class="title-strike" [innerHtml]="translate('Agito.Hilti.Profis3.AddEditDesign.SteelCalculation')"></p>

        <div class="content-controls" *ngIf="areSafetyFactorsForHandrailVisible">
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="handrailSteelSafetyFactorGammaM0.id"
                    [title]="handrailSteelSafetyFactorGammaM0.title"
                    [placeholder]="getNumericTextBoxPlaceholder('handrailSteelSafetyFactorGammaM0')"
                    [value]="handrailFixing.handrailSteelSafetyFactorGammaM0"
                    (valueChange)="handrailFixing.handrailSteelSafetyFactorGammaM0=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('handrailSteelSafetyFactorGammaM0')"
                    [minValue]="handrailSteelSafetyFactorGammaM0.minValue"
                    [maxValue]="handrailSteelSafetyFactorGammaM0.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="handrailSteelSafetyFactorGammaM1.id"
                    [title]="handrailSteelSafetyFactorGammaM1.title"
                    [placeholder]="getNumericTextBoxPlaceholder('handrailSteelSafetyFactorGammaM1')"
                    [value]="handrailFixing.handrailSteelSafetyFactorGammaM1"
                    (valueChange)="handrailFixing.handrailSteelSafetyFactorGammaM1=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('handrailSteelSafetyFactorGammaM1')"
                    [minValue]="handrailSteelSafetyFactorGammaM1.minValue"
                    [maxValue]="handrailSteelSafetyFactorGammaM1.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
            <div class="application-settings-input-container control-row">
                <pe-numeric-text-box [id]="handrailSteelSafetyFactorGammaM2.id"
                    [title]="handrailSteelSafetyFactorGammaM2.title"
                    [placeholder]="getNumericTextBoxPlaceholder('handrailSteelSafetyFactorGammaM2')"
                    [value]="handrailFixing.handrailSteelSafetyFactorGammaM2"
                    (valueChange)="handrailFixing.handrailSteelSafetyFactorGammaM2=$any($event).detail"
                    [unit]="getNumericTextBoxUnit('handrailSteelSafetyFactorGammaM2')"
                    [minValue]="handrailSteelSafetyFactorGammaM2.minValue"
                    [maxValue]="handrailSteelSafetyFactorGammaM2.maxValue"
                    [disabled]="submitted"
                    [decimalSeparator]="getNumericTextBoxDecimalSeparator"
                    [groupSeparator]="getNumericTextBoxGroupSeparator">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
