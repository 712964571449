

import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Design } from '@profis-engineering/pe-ui-common/entities/design';
import {
    DocumentHelper as DocumentHelperCommon
} from '@profis-engineering/pe-ui-common/helpers/document-helper';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import {
    BrowserServiceBase
} from '@profis-engineering/pe-ui-common/services/browser.common';
import {
    DocumentServiceBase
} from '@profis-engineering/pe-ui-common/services/document.common';
import {
    LocalizationServiceBase
} from '@profis-engineering/pe-ui-common/services/localization.common';
import {
    OfflineServiceBase
} from '@profis-engineering/pe-ui-common/services/offline.common';

import { environment } from '../../environments/environmentPe';
import { DesignPe } from '../../shared/entities/design-pe';
import {
    GenerateDesignXmlRequest
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    ProjectDesignBaseEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';

export class DocumentHelper extends DocumentHelperCommon {
    public static download(
        apiService: ApiServiceBase,
        browser: BrowserServiceBase,
        localization: LocalizationServiceBase,
        document: DocumentServiceBase,
        offline: OfflineServiceBase,
        design: DesignPe,
        overwrite?: boolean) {

        return this.downloadInternal({ apiService, browser, localization, document, offline }, design, this.generateBlob, overwrite);
    }
    public static generateBlob(design: Design, apiService: ApiServiceBase, browser: BrowserServiceBase, document: DocumentServiceBase) {
        if ((design as DesignPe).designData.projectDesign == null) {
            throw new Error('ProjectDesign not set.');
        }

        if (design.designName == null || design.designName == '') {
            throw new Error('designName not set.');
        }

        if (!design.isTemplate) {
            const project = document.findProjectByDesignId(design.id);
            if (project == null) {
                throw new Error('Design is not in any projects.');
            }
        }

        const url = `${environment.baseplateApplicationWebServiceUrl}GenerateDesignXml`;
        const data: GenerateDesignXmlRequest = {
            ProjectDesign: (design as DesignPe).designData.projectDesign as ProjectDesignBaseEntity
        };
        const request = new HttpRequest('POST', url, data, {
            responseType: 'blob',
            headers: new HttpHeaders({
                Accept: 'application/xml'
            })
        });

        return apiService.request<Blob>(request)
            .then(response => response.body) as Promise<Blob>;
    }
}
