<div class="anchor-plate-design utilizations box-section box-section-white" *ngIf="moment?.Decisive != null">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-plate"></div>
            </div>
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorPlateDesign')"></span>
            <span class="bar-subtitle" *ngIf="!collapsed && isHandrailPostPositionAvailable"
                [innerHtml]="postPositionText"></span>
        </div>

        <pe-utilization-bar [collapsed]="collapsed" [utilizationInvalid]="utilizationInvalid" [utilizationValue]="utilizationValue"></pe-utilization-bar>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content">
        <div class="utilization" *ngIf="moment?.Decisive != null"
            [class.invalid-value]="moment?.Decisive?.ValueError"
            [class.invalid-text]="moment?.Decisive?.TextError">
            <div [attr.id]="id + '-moment-button'" class="utilization-button">
                <div class="button-content">
                    <div class="text-progress-container">
                        <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorPlateDesign.Moment')"></p>
                        <div class="progress-percent-container" *ngIf="moment?.Decisive?.Percentage != null">
                            <div class="progress-percent" [style.width]="moment?.Decisive?.Percentage + '%'">
                            </div>
                        </div>
                    </div>

                    <div class="number-container">
                        <div class="details" *ngIf="momentOpend">
                            <div class="details-line" *ngIf="moment?.Bending != null"
                                [class.invalid-value]="moment?.Bending?.ValueError"
                                [class.invalid-text]="moment?.Bending?.TextError">
                                <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorPlateDesign.Moment.Bending')"></p>
                                <p class="number" [innerHtml]="transformValue(moment?.Bending?.Value)"></p>
                            </div>
                        </div>

                        <p class="number" *ngIf="!momentDetails" [innerHtml]="transformValue(moment?.Decisive?.Value)"></p>

                        <div class="worst-result details-line" *ngIf="momentWorst != null && !momentOpend"
                            [class.invalid-value]="momentWorst.valueError"
                            [class.invalid-text]="momentWorst.textError">
                            <span class="pe-ui-pe-sprite" [class]="momentWorst.image"></span>
                            <p class="text" [innerHtml]="translate(momentWorst.textKey)"></p>
                            <p class="number" [innerHtml]="transformValue(momentWorst.value)"></p>
                        </div>
                    </div>

                    <div class="design-right-collapse-container" [class.opened]="momentOpend"
                        *ngIf="momentDetails && momentLength > 1">

                        <span class="design-right-collapse-line"></span>

                        <button type="button" class="design-right-collapse-button"
                            (click)="momentOpend = !momentOpend">
                            <span class="pe-ui-pe-sprite" [ngClass]="momentOpend ? 'pe-ui-pe-sprite-arrow-up' : 'pe-ui-pe-sprite-arrow-down'"></span>
                        </button>

                        <span class="design-right-collapse-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="details-line" *ngIf="moment?.Thickness != null"
            [class.invalid-value]="moment?.Thickness?.ValueError"
            [class.invalid-text]="moment?.Thickness?.TextError">
            <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorPlateDesign.Moment.Thickness')"></p>
            <p class="number" [innerHtml]="transformValue(moment?.Thickness?.Value, 'Length')"></p>
        </div>
    </div>
</div>
