import {
    StandOffEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class StandOff extends CodeList {

    public static fromService(codeList: StandOffEntity) {
        return new StandOff(CodeList.baseFromService('StandOffEntity', codeList));
    }

    public override cloneDeep(): StandOff {
        return super.cloneDeep() as StandOff;
    }
}
