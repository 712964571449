import uniqBy from 'lodash-es/uniqBy';

import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import { CodeList } from '../../../shared/entities/code-lists/code-list';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { PropertyMetaData } from '../../../shared/properties/properties';
import {
    ISuggestedAnchorGroupItem, ISuggestedAnchorGroupProps, SuggestedAnchorGroup
} from '../../components/main-menu/suggested-anchor-group/SuggestedAnchorGroup';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { getSpriteAsIconStyleResponsive } from '../../sprites';
import { isAnchorFamilyMarkedAsNew, updateMainMenuControl, updateUiProperty } from './menu-helper';

export function initializeSelectSuggestedAnchorGroup(
    codeListService: CodeListService,
    calculationService: CalculationServicePE,
    modalService: ModalService,
    loggerService: LoggerService,
    design: DesignPe,
    controlProps: ISuggestedAnchorGroupProps,
    localizationService: LocalizationService,
    numberService: NumberService,
    uiPropertyId: number,
    controlName: string,
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
    codelistName?: string) {
    controlProps.type = SuggestedAnchorGroup;
    controlProps.checkedValue = design.model[uiPropertyId] as number;
    controlProps.checkedValues = design.model[UIProperty.SmartAnchor_AnchorGroupsForAsad] as number[] ?? [];
    controlProps.isSmartBaseplateEnable = design.model[UIProperty.SmartAnchor_Enabled] == true;

    const getSuggestedAnchorDataFn = (anchorFamilyId?: number) => {
        let text: string | undefined;
        let tag: string | undefined;
        let image: string | undefined;
        let imageStyle: IResponsiveIconStyle | undefined;

        if (anchorFamilyId != null) {
            const anchorFamilies = design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

            const selectedAnchorFamily = anchorFamilies.find((anchorFamily) => anchorFamily.id == anchorFamilyId);
            text = selectedAnchorFamily?.getTranslatedNameText({ localizationService });
            tag = selectedAnchorFamily?.detailed?.tag;

            image = selectedAnchorFamily?.image;
            if (selectedAnchorFamily?.image != null && selectedAnchorFamily.image != '') {
                imageStyle = {
                    elementStyle: {},
                    afterElementStyle: getSpriteAsIconStyleResponsive(selectedAnchorFamily.image, !selectedAnchorFamily.detailed?.tested)
                };
            }
        }

        return {
            buttonText: text,
            buttonImage: image,
            buttonImageStyle: imageStyle,
            tag: tag
        };
    };

    const formatDisplayString = (textKey: string | undefined, design: DesignPe) => {
        if (textKey == undefined || textKey.length == 0) {
            return undefined;
        }

        const variables = { DesignMethodReference: localizationService.getString(textKey + '.DesignMethodReference.' + design.designMethodGroup?.displayKey, { optional: true }) };

        return formatKeyValue(localizationService.getString(textKey), variables);
    };

    const codeListMapperFn = (codeListItem: CodeList) => {
        const buttonData = getSuggestedAnchorDataFn(codeListItem.id);

        return {
            value: codeListItem.id,
            text: codeListItem.getTranslatedNameText({ localizationService, numberService }),
            tooltip: formatDisplayString(codeListItem.tooltipDisplayKey, design),
            tooltipTitle: formatDisplayString(codeListItem.tooltipTitleDisplayKey, design),
            uiPropertyId: uiPropertyId,
            isNew: isAnchorFamilyMarkedAsNew(codeListItem.id, design),
            buttonText: buttonData.buttonText,
            tag: buttonData.tag,
            buttonImage: buttonData.buttonImage,
            buttonImageStyle: buttonData.buttonImageStyle
        } as ISuggestedAnchorGroupItem;
    };

    let codeListItems: CodeList[] | undefined;

    if (codelistName != null && codelistName != '') {
        const designCodeList = getDesignCodeList(codelistName);
        const projectCodeList = getProjectCodeList(codelistName);

        if (designCodeList != null) {
            codeListItems = getCodeListItems(loggerService, codelistName, design, designCodeList, uiPropertyId);
        }
        else if (projectCodeList != null) {
            codeListItems = getProjectCodeListItems(codeListService, loggerService, uiPropertyId, codelistName, design, controlName, projectCodeList);
        }
    }

    controlProps.initialItems = codeListItems?.map((codeListItem) => codeListMapperFn(codeListItem));
    controlProps.items = getSuggestedAnchorGroupFilteredItems(controlProps, uiPropertyId, design);

    controlProps.valueChanged = (value, checked) => {
        let anchorGroupsForAsad: number[] = [];
        if (design.model[UIProperty.SmartAnchor_Enabled] == true) {
            anchorGroupsForAsad = design.model[UIProperty.SmartAnchor_AnchorGroupsForAsad] as number[] ?? [];
            anchorGroupsForAsad = anchorGroupsForAsad.filter(x => x != value);

            if (checked) {
                anchorGroupsForAsad.push(value);
            }
        }

        // change menu
        setState(menu => updateMainMenuControl<ISuggestedAnchorGroupProps>(menu, controlProps.controlId, { checkedValue: value, checkedValues: anchorGroupsForAsad }));

        // when smartanchor is enabled
        if (design.model[UIProperty.SmartAnchor_Enabled] as boolean) {
            updateUiProperty(calculationService, design, UIProperty.SmartAnchor_AnchorGroupsForAsad, anchorGroupsForAsad);
        }
        else {
            updateUiProperty(calculationService, design, uiPropertyId, value);
        }

        if (checked && !design.model[UIProperty.SmartAnchor_Enabled]) {
            trackUsagePe(uiPropertyId, design);
        }
    };

    controlProps.infoClicked = () => {
        design.usageCounter.SmartAnchor_InfoClicked++;
        modalService.openAnchorNeedSolutionPopup();
    };

    const onStateChanged = (state: IDesignState) => {
        const items = getSuggestedAnchorGroupFilteredItems(controlProps, uiPropertyId, design);

        return ({
            checkedValue: state.model[uiPropertyId],
            checkedValues: state.model[UIProperty.SmartAnchor_AnchorGroupsForAsad],
            isSmartBaseplateEnable: design.model[UIProperty.SmartAnchor_Enabled] == true,
            items
        } as any) as ISuggestedAnchorGroupProps;
    };

    const onAllowedValuesChanged = (design: DesignPe, propertyIds: number[]) => {
        if (!propertyIds.some((id) => id == uiPropertyId)) {
            return {} as ISuggestedAnchorGroupProps;
        }

        const items = getSuggestedAnchorGroupFilteredItems(controlProps, uiPropertyId, design);
        return ({ items } as any) as ISuggestedAnchorGroupProps;
    };

    return {
        controlProps,
        onStateChanged,
        onAllowedValuesChanged
    };
}

function getSuggestedAnchorGroupFilteredItems(controlProps: ISuggestedAnchorGroupProps, uiPropertyId: number, design: DesignPe) {
    // filter group items
    const allowedValues = design.properties.get(uiPropertyId).allowedValues;
    const disabledValues = design.properties.get(uiPropertyId).disabledValues;

    let values = disabledValues;
    if (allowedValues != null) {
        values = values != null ? [...allowedValues, ...values] : allowedValues;
    }

    const displayValuesSortIndex = values?.reduceRight((obj, val, index) => { obj[val] = index; return obj; }, {} as Record<number, number>);

    // Items
    // displayValues == null:    no filtering, displays all initial items
    // displayValues == {}:      applies filtering, displays no items
    // displayValues == { ... }  applies filtering, display some items
    const items = controlProps.initialItems?.filter((item) =>
        displayValuesSortIndex != null
            ? displayValuesSortIndex[item.value] != null
            : true);

    items?.forEach((item) => { item.disabled = disabledValues?.includes(item.value) ?? false; });

    // Sort suggested anchor based on display values.
    items?.sort((a, b) => {
        return (displayValuesSortIndex?.[a.value] ?? 0) - (displayValuesSortIndex?.[b.value] ?? 0);
    });

    return items;
}

function trackUsagePe(uiProperty: number, design: DesignPe) {
    switch (uiProperty) {
        case PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id:
            design.usageCounter.SmartAnchor_SuggestedAnchorSelected++;
            break;
        case PropertyMetaData.SmartAnchor_ShowMore.id:
            design.usageCounter.SmartAnchor_SeeMoreClicked++;
            break;
    }

}

function getProjectCodeListItems(codeListService: CodeListService, loggerService: LoggerService, uiPropertyId: number, codelistName: string, design: DesignPe, navigationControlName: string, codeList: ProjectCodeList) {
    let codeListItems: CodeList[];

    if (codeList != null && (codeListItems = codeListService.projectCodeLists[codeList]) != null) {
        const uniqCodeListItems = uniqBy(codeListItems, x => x.id);
        if (uniqCodeListItems.length != codeListItems.length) {
            console.error(`Duplicate id found in codelist ${ProjectCodeList[codeList]} for control ${navigationControlName}`);
        }
        return uniqCodeListItems;
    }

    logMissingCodeList(loggerService, codelistName, uiPropertyId, design);
    return undefined;
}

function getDesignCodeList(codelistName: string) {
    return DesignCodeList[codelistName as keyof typeof DesignCodeList];
}

function getProjectCodeList(codelistName: string) {
    return ProjectCodeList[codelistName as keyof typeof ProjectCodeList];
}

function getCodeListItems(loggerService: LoggerService, codelistName: string, design: DesignPe, codeList: DesignCodeList, uiPropertyId: number) {
    let codeListItems: CodeList[];

    if (codeList != null && (codeListItems = design.designData.designCodeLists[codeList]) != null) {
        return codeListItems;
    }

    logMissingCodeList(loggerService, codelistName, uiPropertyId, design);
    return undefined;
}

function logMissingCodeList(loggerService: LoggerService, codelistName: string, uiPropertyId: number, design: DesignPe) {
    if (!design.properties.get(uiPropertyId).hidden) {
        loggerService.log(`Missing code list: ${codelistName}`, LogType.warn);
    }
}
