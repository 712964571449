import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';
import hollowImg  from '../../../images/head-joint-selection/head-joint-selection-hollow.png';
import solidImg from '../../../images/head-joint-selection/head-joint-selection-solid.png';

export type HeadJointSelection = 'HeadJointSelectionHollow' | 'HeadJointSelectionSolid';

export interface HeadJointSelectionInfoPopupInput {
    headJointSelection: HeadJointSelection;
}

@Component({
    templateUrl: './head-joint-selection-info-popup.component.html',
    styleUrls: ['./head-joint-selection-info-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class HeadJointSelectionInfoPopupComponent {

    @Input()
    public modalInstance!: ModalInstance<HeadJointSelectionInfoPopupInput>;

    public hollowImage!: string;
    public solidImage!: string;
    private learnMoreLink = 'https://www.hilti.com/content/dam/urgent-folder/w1/pdf/tech-guides/AC%2058_Anchor%20Design%20Guide.pdf';

    constructor(public localizationService: LocalizationService) { }

    public get title() {
        switch(this.modalInstance.input?.headJointSelection) {
            case 'HeadJointSelectionHollow': return this.localizationService.getString('Agito.Hilti.Profis3.CodeList.HeadJointSelectionEntity.Hollow');
            case 'HeadJointSelectionSolid':  return this.localizationService.getString('Agito.Hilti.Profis3.CodeList.HeadJointSelectionEntity.Solid');
            default: throw Error('Invalid head joint selection');
        }
    }

    public get image() {
        switch(this.modalInstance.input?.headJointSelection) {
            case 'HeadJointSelectionHollow': return hollowImg;
            case 'HeadJointSelectionSolid':  return solidImg;
            default: throw Error('Invalid head joint selection');
        }
    }

    public get learnMore() {
        return this.localizationService.getString('Agito.Hilti.Profis3.HeadJointSelectionInfoPopup.LearnMore').replace('{LearnMoreLink}', this.learnMoreLink);
    }

    public close() {
        this.modalInstance.close();
    }
}
