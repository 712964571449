import { CladdingTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class CladdingType extends CodeList {

    public static fromService(codeList: CladdingTypeEntity) {
        return new CladdingType(CodeList.baseFromService('CladdingTypeEntity', codeList));
    }

    public override cloneDeep(): CladdingType {
        return super.cloneDeep() as CladdingType;
    }
}
