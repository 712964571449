import { DropdownType } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { TooltipType } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { buildHtmlTooltip } from '@profis-engineering/pe-ui-common/helpers/tooltip-helper';
import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import styles from './react-multiselect-dropdown.css';
import { LocalizationService } from '../../../services/localization.service';

const ControlHeader = (window as any).pe.reactComponents.ControlHeader;
const keyEnter = 13;
const keyUp = 38;
const keyDown = 40;
const keyTab = 9;
const keySpace = 32;
const allOptionValue = Number.MAX_SAFE_INTEGER;

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

interface IDropdownState {
    isOpened: boolean;
    highlightedValue: number;
    selectedItems: number[];
}

interface IDropdownItemProps {
    isIndeterminate: boolean;
    controlId: string;
    value: number;
    key: string;
    text: string;
    selected: boolean;
    tag: string;
    isNew: boolean;
    clicked: (value: number) => void;
    localization?: LocalizationService;
}

export interface IMultiSelectDropdownProps extends IControlProps {
    initialItems?: IMultiSelectDropdownItem[];
    items?: IMultiSelectDropdownItem[];
    selectedValues?: number[];
    showAddsOn?: boolean;
    dropdownType?: DropdownType;
    valueChanged?: (value: number[]) => void;
    infoClicked?: () => void;
    onClick?: (isOpen: boolean) => void;
}

export interface IMultiSelectDropdownItem {
    value: number;
    text: string;
    tag?: string;
    isNew?: boolean;
    uiPropertyId?: number;
    disabled?: boolean;
}

export class _MultiSelectDropdown extends React.PureComponent<IMultiSelectDropdownProps, IDropdownState> {
    public static styleSheets = [sheet];

    private controlContainer: HTMLDivElement | null;
    private dropdownItems: HTMLDivElement | null;
    private button: HTMLButtonElement | null;
    private scrollIntoHighlightedFlag: boolean;
    private focusOnButtonFlag: boolean;
    private searchText: string | null;
    private searchTimeout: number | null;
    private options = new Array<IMultiSelectDropdownItem>;
    private selectedValues = new Array<number>;
    private isAllSelected = false;
    private keyDownFlag: boolean;

    constructor(props: IMultiSelectDropdownProps) {
        super(props);

        if (!props.items) {
            props.items = [];
        }

        this.prepareOptions();
        this.onClick = this.onClick.bind(this);
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onInfoClick = this.onInfoClick.bind(this);
        this.keyDownFlag = false;
        this.controlContainer = null;
        this.dropdownItems = null;
        this.button = null;
        this.searchTimeout = null;
        this.searchText = null;

        this.state = {
            isOpened: false,
            highlightedValue: allOptionValue,
            selectedItems: this.props.selectedValues ?? []
        };

        this.scrollIntoHighlightedFlag = false;
        this.focusOnButtonFlag = false;
    }

    private prepareOptions() {
        const selectAllText = this.props?.localization?.getString('Agito.Hilti.Profis3.MultiSelectDropdown.SelectAll');

        this.options = [];

        const allCheckbox = {
            id: `-all-option`,
            value: allOptionValue,
            text: selectAllText
        } as IMultiSelectDropdownItem;


        if (this.props.items) {
            this.options.push(allCheckbox);
            this.options.push(...this.props.items);
        }

        this.isAllSelected = this.props.items?.length == this.selectedValues.length;
    }

    public override componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
    }

    private setIndex() {
        let highlightedValue = allOptionValue;

        if (this.props.selectedValues?.length && this.props.selectedValues?.length !== this.props.items?.length) {
            const [firstItem] = this.getSelectedItems(this.props);
            highlightedValue = this.options.find(x => x.value == firstItem.value)?.value ?? allOptionValue;
        }

        this.setState({
            isOpened: false,
            highlightedValue: highlightedValue
        });
    }

    public override componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    public override componentDidUpdate() {
        if (this.scrollIntoHighlightedFlag) {
            this.scrollIntoHighlighted();
        }

        if (this.focusOnButtonFlag
            && !this.props.hidden
            && !this.props.disabled
        ) {
            this.button?.focus();
        }

        this.scrollIntoHighlightedFlag = false;
        this.focusOnButtonFlag = false;
    }

    public override render() {

        if (this.props.hidden || !this.props.items) {
            return null;
        }

        this.prepareOptions();
        this.selectedValues = this.props.selectedValues ?? [];

        const typeClass = this.props.dropdownType == DropdownType.inline ? 'inline' : '';
        const opendClass = this.state.isOpened ? 'opend' : '';
        const caretClass = this.state.isOpened ? 'caret-up' : 'caret-down';
        const buttonId = `${this.props.controlId}-button`;
        const infoTooltipKey = 'Agito.Hilti.Profis3.ControlTooltip.Popup';

        return (
            <div data-control-id={this.props.controlId}
                className={`react-dropdown control ${this.props.sizeClass}`}
                data-tip={this.tooltip}
                data-html={this.tooltip != null ? true : null}>
                <div className='header-container'>
                    <ControlHeader
                        text={this.props.title}
                        controlId={buttonId}
                        tooltip={this.tooltipText}
                        tooltipTitle={this.tooltipTitle}
                        localization={this.props.localization}
                        infoClick={this.tooltipType == TooltipType.Popup ? this.onInfoClick : undefined}
                        infoTooltip={this.tooltipType == TooltipType.Popup ? infoTooltipKey : undefined}
                    />
                </div>

                <div
                    className='control-container'
                    ref={(e) => this.controlContainer = e}
                    onKeyDown={this.onKeyDown}
                    onKeyPress={this.onKeyPress}>
                    <button
                        data-control-id={buttonId}
                        className='dropdown-button'
                        type='button'
                        disabled={this.props.disabled}
                        onClick={this.onClick}
                        ref={(e) => this.button = e}
                        data-tip={this.tooltip}
                        data-html={this.tooltip != null ? true : null}>
                        <div className='dropdown-button-container'>
                            <div className='button-item item'>
                                <p className='text' dangerouslySetInnerHTML={{ __html: this.headerText }}></p>
                                {this.Tag ? <span className='tag'>{this.props.localization?.getString(this.Tag)}</span> : null}
                                {this.isNew ? <span className='new'>{this.props.localization?.getString('Agito.Hilti.Profis3.Anchors.New')}</span> : null}
                            </div>
                            <span className='space'></span>
                            <div className='caret-container'>
                                <div className={`caret ${caretClass}`}></div>
                            </div>
                        </div>
                    </button>
                    <div
                        className={`dropdown-items ${typeClass} ${opendClass}`}
                        data-tip={this.tooltip}
                        data-html={this.tooltip != null ? true : null}
                        ref={(e) => this.dropdownItems = e} >
                        {
                            this.options.map((item) =>
                                <DropdownItem
                                    controlId={`${this.props.controlId}-${item.value}`}
                                    key={`${this.props.controlId}-${item.value}`}
                                    value={item.value}
                                    text={item.text}
                                    selected={this.itemSelected(item)}
                                    tag={item.tag ?? ''}
                                    isNew={item.isNew ?? false}
                                    clicked={this.onItemClick}
                                    localization={this.props?.localization as LocalizationService}
                                    isIndeterminate={this.isIndeterminate(item)}
                                />)
                        }
                    </div>
                </div>
            </div>
        );
    }

    private isIndeterminate(item: IMultiSelectDropdownItem) {
        const isIndeterminate =
            (
                this.selectedValues &&
                item.value == allOptionValue &&
                this.selectedValues?.length > 0 &&
                this.selectedValues?.length != this.props.items?.filter(item => !item.disabled).length
            );

        if (isIndeterminate) {
            this.isAllSelected = false;
        }

        return isIndeterminate;
    }

    private getSelectedItems(props: IMultiSelectDropdownProps) {
        if (props.selectedValues && props.items != null) {
            return props.items.filter(item => props.selectedValues?.includes(item.value));
        }

        return [];
    }

    private itemSelected(item: IMultiSelectDropdownItem) {

        if (this.selectedValues) {
            if (item.value == allOptionValue) {
                return this.selectedValues?.length > 0 &&
                    this.selectedValues.length == this.props.items?.filter(x => !x.disabled).length;
            }
            return this.selectedValues.includes(item.value);
        }

        return false;
    }

    private onInfoClick() {
        if (this.props.infoClicked != null) {
            this.props.infoClicked();
        }
    }

    private onClick(_: React.MouseEvent) {
        if (this.keyDownFlag) {
            this.keyDownFlag = false;
        }
        else {
            if (this.state.isOpened) {
                this.close();
            }
            else {
                this.open();
            }

            if (this.props.onClick != null) {
                this.props.onClick(!this.state.isOpened);
            }
        }
    }

    private onDocumentClick(event: MouseEvent) {
        const target = event.composedPath()[0] as HTMLElement;
        if (!this.controlContainer?.contains(target)) {
            this.close();
        }
    }

    private onItemClick(value?: number) {
        if (value && this.selectedValues) {

            if (value == allOptionValue) {
                this.allSelected();
            }

            else if (this.selectedValues.length > 0 && this.selectedValues.includes(value)) {
                this.selectedValues = this.selectedValues.filter(item => item !== value)
            }
            else {
                this.selectedValues.push(value);
            }
        }

        this.isAllSelected = this.selectedValues?.length == this.props.items?.filter(x => !x.disabled).length;

        this.setState({
            isOpened: true,
            highlightedValue: this.options.find(x => x.value == value)?.value ?? allOptionValue
        })

        this.updateSelectedValues();
    }

    private onKeyDown(event: React.KeyboardEvent) {
        if (this.state.isOpened && this.props.items) {
            const eventCode = event.which || event.keyCode || event.charCode;
            const highlightedIndex = this.options.findIndex((item) => item.value == this.state.highlightedValue);

            if (highlightedIndex < 0) {
                return;
            }

            switch (eventCode) {
                case keyEnter:
                case keyTab:
                case keySpace:
                    {
                        event.preventDefault();
                        this.keyDownFlag = true;
                        this.onItemClick(this.state.highlightedValue);
                        this.scrollIntoHighlightedFlag = true;

                        return;
                    }

                case keyDown:
                    {
                        event.preventDefault();
                        const nextIndex = highlightedIndex + 1 < this.options.length ? highlightedIndex + 1 : highlightedIndex;
                        this.setState((prevState) => {
                            return {
                                isOpened: prevState.isOpened,
                                highlightedValue: this.options[nextIndex].value
                            };
                        });
                        this.scrollIntoHighlightedFlag = true;

                        return;
                    }

                case keyUp:
                    {
                        event.preventDefault();
                        const prevIndex = highlightedIndex - 1 >= 0 ? highlightedIndex - 1 : highlightedIndex;
                        this.setState((prevState) => {
                            return {
                                isOpened: prevState.isOpened,
                                highlightedValue: this.options[prevIndex].value
                            };
                        });
                        this.scrollIntoHighlightedFlag = true;

                        return;
                    }
            }
        }
    }

    private onKeyPress(event: React.KeyboardEvent) {
        if (this.state.isOpened && this.props.items) {
            event.preventDefault();
            const eventCode = event.which || event.keyCode || event.charCode;

            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.searchTimeout = null;
                this.searchText = '';
            }, 1000);

            this.searchText += String.fromCharCode(eventCode);

            const itemToHighlight = this.props.items.find((item) => item.text.substr(0, this.searchText?.length).toLowerCase() == this.searchText?.toLowerCase());

            if (itemToHighlight != null) {
                this.setState((prevState) => {
                    return {
                        isOpened: prevState.isOpened,
                        highlightedValue: itemToHighlight.value
                    };
                });
                this.scrollIntoHighlightedFlag = true;
            }
        }
    }

    private open() {
        if (!this.state.isOpened && this.props.items?.length) {
            this.setState({
                isOpened: true,
            });

            this.scrollIntoHighlightedFlag = true;

            this.searchText = '';
        }
    }

    private close() {
        if (this.state.isOpened) {
            this.setState({
                isOpened: false,
            });

            this.focusOnButtonFlag = true;

            this.searchText = null;
            if (this.searchTimeout != null) {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = null;
            }
            this.setIndex();
        }
    }

    public allSelected() {
        this.isAllSelected = !this.isAllSelected;

        if (this.isAllSelected) {
            this.selectedValues = this.props.items?.filter(x => !x.disabled).map(x => x.value) ?? [];
        }
        else {
            this.selectedValues = [];
        }
    }

    public updateSelectedValues() {
        this.setState({
            selectedItems: this.selectedValues ?? []
        }, () => {
            if (this.props.valueChanged != null) {
                this.props.valueChanged(this.state.selectedItems);
            }
        });
    }

    private scrollIntoHighlighted() {
        if (!this.dropdownItems) {
            return;
        }

        const offset = 2;
        const selectedIndex = this.options.findIndex(
            (item) => item.value == this.state.highlightedValue
        );

        if (selectedIndex != -1) {
            const selectedElement = this.dropdownItems.querySelectorAll('.dropdown-item')[selectedIndex] as HTMLElement;
            const dropdownFullHeight = this.dropdownItems.scrollHeight;
            const dropdownHeight = this.dropdownItems.offsetHeight;

            // we have a scrollbar
            if (dropdownFullHeight > dropdownHeight) {
                const selectedElementHeight = selectedElement.offsetHeight;
                const dropdownTop = this.dropdownItems.scrollTop;
                const dropdownBottom = this.dropdownItems.scrollTop + dropdownHeight;

                // missing top
                if (selectedElement.offsetTop < dropdownTop) {
                    this.dropdownItems.scrollTop = selectedElement.offsetTop - offset;
                }
                // missing bottom
                else if (selectedElement.offsetTop + selectedElementHeight + offset * 2 > dropdownBottom) {
                    this.dropdownItems.scrollTop = selectedElement.offsetTop - dropdownHeight + selectedElementHeight + offset * 2;
                }
            }
        }
    }

    private get tooltipType() {
        const tooltipDisabledOnly = this.props.tooltipTitle == null && this.props.tooltipDisabledTitle != null && this.props.tooltip == null && this.props.tooltipDisabled != null;

        return !this.props.disabled && tooltipDisabledOnly ? null : this.props.tooltipType;
    }

    private get tooltipTitle() {
        const tooltipTitleTmp = this.props.disabled ? this.props.tooltipDisabledTitle : this.props.tooltipTitle;

        return this.tooltipType != TooltipType.Popup ? tooltipTitleTmp : undefined;
    }

    private get tooltipText() {
        const tooltipTextTmp = this.props.disabled && this.props.tooltipDisabled != null ? this.props.tooltipDisabled : this.props.tooltip;

        return this.tooltipType != TooltipType.Popup ? tooltipTextTmp : undefined;
    }

    private get tooltip() {

        return this.props.title != null && this.props.title != '' && !this.props.disabled ? null : buildHtmlTooltip(this.tooltipText, this.tooltipTitle);
    }

    private get Tag() {
        const selectedItems = this.getSelectedItems(this.props);

        if (selectedItems?.length) {
            return selectedItems.find(x => x.tag)?.tag;
        }

        return null;
    }

    private get isNew() {
        const selectedItems = this.getSelectedItems(this.props);

        if (selectedItems?.length) {
            return selectedItems.find(x => x.isNew);
        }

        return null;
    }

    private get headerText() {
        const selectedItems = this.getSelectedItems(this.props);

        if (selectedItems?.length) {
            const [firstItem] = selectedItems;

            return (
                '<span class="label-text">' + firstItem.text + '</span>' +
                (
                    selectedItems?.length > 1 ?
                        '<span class="chip">' + '+' + (selectedItems?.length - 1) + '</span>' : ''
                )
            );
        }
        else {
            return this.props.localization?.getString('Agito.Hilti.Profis3.Select') ?? '';
        }
    }
}

class DropdownItem extends React.PureComponent<IDropdownItemProps> {

    constructor(props: IDropdownItemProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    public override render() {
        const selectedClass = this.itemSelected ? 'selected' : '';
        const text = this.props.text != null ? this.props.text : '';
        const tag = this.props.tag != null ? this.props.tag.charAt(0) : null;
        const newText = this.props.localization?.getString('Agito.Hilti.Profis3.Anchors.New');
        return (
            <button
                data-control-id={this.props.controlId}
                className={`dropdown-item ${selectedClass}`}
                type='button'
                onClick={this.onClick} >
                <div className={'item'}>
                    <span className={'checkbox-container'}>

                        <input
                            type='checkbox'
                            checked={this.itemSelected}
                            onChange={e => { }}
                            ref={input => {
                                if (input) {
                                    input.indeterminate = this.props.isIndeterminate;
                                }
                            }}
                        />
                        <span className={this.props.isIndeterminate ? 'indeterminate' : 'checkmark'}></span>
                    </span>
                    <p className={'text'}>{text}</p>
                    {tag != null ? <span className={'tag'}>{tag}</span> : null}
                    {this.props.isNew ? <span className={'new'}>{newText}</span> : null}
                </div>
            </button>
        );
    }

    private onClick(event: React.MouseEvent) {
        event.preventDefault();

        if (this.props.clicked != null) {
            this.props.clicked(this.props.value);
        }
    }

    private get itemSelected() {
        return this.props.selected;
    }
}

export const MultiselectDropdown: typeof _MultiSelectDropdown & ContolsStyleSheets = _MultiSelectDropdown