import {
    LoadTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';

import { CodeList } from '../code-lists/code-list';

export class LoadType extends CodeList {

    public static fromService(codeList: LoadTypeEntity) {
        return new LoadType(CodeList.baseFromService('LoadTypeEntity', codeList));
    }

    public override cloneDeep(): LoadType {
        return super.cloneDeep() as LoadType;
    }
}
