import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { ClipboardService } from '../../services/clipboard.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './detailed-calculation-output.component.html',
    styleUrls: ['./detailed-calculation-output.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DetailedCalculationOutputComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public calculationOutput = '';

    constructor(
        private user: UserService,
        private clipboard: ClipboardService,
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.calculationOutput = this.user.design.designData.reportData?.DetailedCalculationOutput ?? '';
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public copyToClipBoard() {
        this.clipboard.copy(this.calculationOutput);
    }
}
