import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import {
    propertyValueChanged, scopeCheckChanged, Update
} from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { PlateShape, StandoffType } from '@profis-engineering/pe-gl-model/components/plate';
import {
    calculatePlatePointsArgs, IPointsValues, plateConstants
} from '@profis-engineering/pe-gl-model/components/plate-helper';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import {
    AnchorFamily
} from '../../../../shared/entities/code-lists/anchor-family';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { DesignPe } from '../../../../shared/entities/design-pe';
import {
    MeasureAnchorPlateMode
} from '../../../../shared/generated-modules/Hilti.PE.Core.Common.Enums';
import {
    MeasureScopeCheckParameterItem as ScopeCheck
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    MeasureResultType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    BaseplateSize, UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    Point2DEntity
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { PlateShape as PropertyPlateShape, StandoffType as PropertyStandoffType } from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { DesignMethodGroupHelper } from '../../../../shared/helpers/design-method-group-helper';

export class PlateUpdate extends BaseUpdate {

    public static propertyPlateShapeToModalPlateShape(plateShape: PropertyPlateShape) {
        switch (plateShape) {
            case PropertyPlateShape.Rectangular:
                return PlateShape.Rectangular;
            case PropertyPlateShape.Circular:
                return PlateShape.Circular;
            case PropertyPlateShape.Diamond:
                return PlateShape.Diamond;
            case PropertyPlateShape.Parallelogram:
                return PlateShape.Parallelogram;
            case PropertyPlateShape.Custom:
                return PlateShape.Custom;
            case PropertyPlateShape.LedgerAngle:
                return PlateShape.LedgerAngle;
            case PropertyPlateShape.None:
            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown plate shape.');
        }
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private OnPropertiesChange(model: IModelPe, design: DesignPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }
        model.plate.showNotEditableInfo = design.hasETAG();
        model.plate.depthR = design.designData.reportData?.ShowRigidBasePlateDisclaimer ?? false;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_LedgerAngleShape, Update.Server)
    private AnchorPlate_LedgerAngleShape(value: PropertyPlateShape, model: IModelPe) {
        this.AnchorPlate_PlateShape(value, model);
    }

    @propertyValueChanged(UIProperty.AnchorPlate_PlateShape, Update.Server)
    private AnchorPlate_PlateShape(value: PropertyPlateShape, model: IModelPe) {
        if (value != null && model.plate) {
            model.plate.shape = PlateUpdate.propertyPlateShapeToModalPlateShape(value);
            this.updatePlatePoints(model);
        }
    }

    @propertyValueChanged(UIProperty.AnchorPlate_CustomLayoutPoints, Update.Server)
    private AnchorPlate_CustomLayoutPoints(points: Point2DEntity[], model: IModelPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }
        model.plate.points = points?.map(point => new Vector2(point.X, point.Y)) ?? [];
    }

    @propertyValueChanged(UIProperty.AnchorPlate_AnchorPlateSize)
    private AnchorPlate_AnchorPlateSize(size: BaseplateSize, model: IModelPe, design: DesignPe) {
        if (size != null && model.plate && model.anchor) {
            model.plate.lengthA = size.Width;
            model.plate.lengthB = size.Height;
            model.plate.height = size.Thickness;

            if (size.DiameterVisible) {
                model.plate.lengthA = size.Diameter;
            }

            this.updatePlatePoints(model);

            model.plate.isControllingDimension = design.measureAnchorPlate == null
                ? true
                : design.measureAnchorPlate == MeasureAnchorPlateMode.UsingOverallWidthAndHeight;
            model.anchor.isControllingDimension = design.measureAnchorPlate == null
                ? true
                : design.measureAnchorPlate == MeasureAnchorPlateMode.FromAnchorCenterToPlateEdge;
        }
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StandoffType)
    private AnchorPlate_StandoffType(value: PropertyStandoffType, model: IModelPe, design: DesignPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }
        model.plate.standoffFill = value == PropertyStandoffType.WithGrouting;
        model.plate.standoffType = this.propertyStandoffTypeToModalStandoffType(value);

        if (value == null || value == PropertyStandoffType.None || PropertyStandoffType.Unknown) {
            model.plate.standoffHeight = undefined;
        }
        else {
            model.plate.standoffHeight = design.model[UIProperty.AnchorPlate_StandoffDistance] as number;
        }
    }

    @propertyValueChanged(UIProperty.AnchorPlate_Width)
    @propertyValueChanged(UIProperty.AnchorPlate_Diameter)
    private AnchorPlate_Width(value: number, model: IModelPe, _design: DesignPe, _useDevFeatures: boolean, update: Update) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        let width = value;

        if (width < 1) {
            width = 1;
        }

        if (update == Update.Client) {
            if (width > 10000) {
                width = 10000;
            }
        }

        model.plate.lengthA = width;

        this.updatePlatePoints(model);
    }

    @propertyValueChanged(UIProperty.AnchorPlate_Height)
    private AnchorPlate_Height(value: number, model: IModelPe, _design: DesignPe, _useDevFeatures: boolean, update: Update) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        let height = value;

        if (height < 1) {
            height = 1;
        }

        if (update == Update.Client) {
            if (height > 10000) {
                height = 10000;
            }
        }

        model.plate.lengthB = height;

        this.updatePlatePoints(model);
    }

    @propertyValueChanged(UIProperty.AnchorPlate_Thickness)
    private AnchorPlate_Thickness(value: number, model: IModelPe, _design: DesignPe, _useDevFeatures: boolean, update: Update) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        let thickness = value;

        if (thickness < 1) {
            thickness = 1;
        }

        if (update == Update.Client) {
            if (thickness > 500) {
                thickness = 500;
            }
        }

        model.plate.height = thickness;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StandoffDistance)
    private AnchorPlate_StandoffDistance(value: number, model: IModelPe, _design: DesignPe, _useDevFeatures: boolean, update: Update) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        let standoff = value;

        if (standoff < 0) {
            standoff = 0;
        }

        if (update == Update.Client) {
            if (standoff > 1000) {
                standoff = 1000;
            }
        }

        model.plate.standoffHeight = standoff;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StandoffNutAndWasherHeight)
    private AnchorPlate_StandoffNutAndWasherHeight(value: number, model: IModelPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        model.plate.standoffNutAndWasherHeight = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_LedgerAngleProfileCustomHeight)
    private AnchorPlate_LedgerAngleProfileCustomHeight(value: number, model: IModelPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        model.plate.ledgerAngleProfileHeight = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_LedgerAngleProfileCustomThickness)
    private AnchorPlate_LedgerAngleProfileCustomThickness(value: number, model: IModelPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        model.plate.ledgerAngleProfileThickness = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_Rotate)
    private AnchorPlate_Rotate(value: number, model: IModelPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        if (value != null) {
            model.plate.angleY = -value;
        }
        else {
            model.plate.angleY = 0;
        }

        this.updatePlatePoints(model);
    }

    @propertyValueChanged(UIProperty.AnchorLayout_AnchorFamily)
    private AnchorLayout_AnchorFamily(anchorFamilyId: number, model: IModelPe, design: DesignPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        if (anchorFamilyId != null) {
            const codeList = DesignMethodGroupHelper.IsLrfdBased(design.designMethodGroup?.id)
                ? design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
                : design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

            const anchorFamily = codeList.find((anchorFamily) => anchorFamily.id == anchorFamilyId);

            model.plate.castInPlace = anchorFamily?.castInPlace == 'S';
        }
        else {
            model.plate.castInPlace = false;
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_Spacing_SX)
    @propertyValueChanged(UIProperty.AnchorLayout_Spacing_SY)
    @propertyValueChanged(UIProperty.AnchorLayout_OffsetXFromAnchorPlate)
    @propertyValueChanged(UIProperty.AnchorLayout_OffsetYFromAnchorPlate)
    private AnchorLayout_OffsetYFromAnchorPlate(value: number, model: IModelPe, design: DesignPe) {
        if (value != null && model.plate && model.anchor) {
            model.plate.isControllingDimension = design.measureAnchorPlate == MeasureAnchorPlateMode.Unknown || design.measureAnchorPlate == MeasureAnchorPlateMode.UsingOverallWidthAndHeight;
            model.anchor.isControllingDimension = design.measureAnchorPlate == MeasureAnchorPlateMode.Unknown || design.measureAnchorPlate == MeasureAnchorPlateMode.FromAnchorCenterToPlateEdge;
        }
    }

    @propertyValueChanged(UIProperty.SmartAnchor_PlateHidden)
    private AnchorPlate_SmartAnchor_PlateHidden(value: boolean, model: IModelPe, design: DesignPe) {
        if (value != null) {
            model.isAsadWorkflow = (design.model[UIProperty.SmartAnchor_PlateHidden] as boolean ?? false);
        }
    }

    /* SCOPE CHECK CHANGED */
    @scopeCheckChanged()
    private scopeChecksChanged(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        model.plate.invalid = model.plate.invalid ?? {};

        model.plate.invalid.lengthA = false;
        model.plate.invalid.lengthB = false;
        model.plate.invalid.thickness = false;
        model.plate.invalid.standoff = false;

        if (scopeChecks != null) {
            model.plate.invalid.lengthA = scopeChecks[MeasureResultType.AnchorPlateLengthA] != null;
            model.plate.invalid.lengthB = scopeChecks[MeasureResultType.AnchorPlateLengthB] != null;
            model.plate.invalid.thickness = scopeChecks[MeasureResultType.AnchorPlateThickness] != null;
            model.plate.invalid.standoff = scopeChecks[MeasureResultType.AnchorPlateStandoffDistance] != null;
        }
    }

    private propertyStandoffTypeToModalStandoffType(standoffType: PropertyStandoffType) {
        switch (standoffType) {
            case PropertyStandoffType.WithoutClamping:
                return StandoffType.SingleNut;
            case PropertyStandoffType.WithClamping:
                return StandoffType.DoubleNut;
            case PropertyStandoffType.WithPlaster:
            case PropertyStandoffType.WithGrouting:
            case PropertyStandoffType.None:
            case PropertyStandoffType.Unknown:
            case undefined:
            case null:
                return StandoffType.NoNut;

            default:
                throw new Error('Unknown standoff type.');
        }
    }

    private updatePlatePoints(model: IModelPe) {
        if (!model.plate) {
            throw new Error('model plate is undefined');
        }

        if (model.plate.shape != null) {
            let stairWidth: number | undefined = undefined;
            let stairHeight: number | undefined = undefined;

            if (model.handrailBaseMaterial != null) {
                stairWidth = model.handrailBaseMaterial.stairWidth;
                stairHeight = model.handrailBaseMaterial.stairHeight;
            }

            const values: IPointsValues = {
                plate: model.plate,
                stairWidth,
                stairHeight,
                circlePrecision: plateConstants.circularPlatePointsCount,
                ignoreRotation: false
            };

            model.plate.points = calculatePlatePointsArgs(values, this.cache);
        }
        else {
            model.plate.points = [];
        }
    }
}
