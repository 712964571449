import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    StiffenerLayoutEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    StiffenerCrossSectionPart, StiffenerLocation
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from '../code-lists/code-list';

export interface IStiffenerLayoutConstructor extends ICodeListConstructor {
    stiffenerLocation: StiffenerLocation;
    stiffenerCrossSectionPart: StiffenerCrossSectionPart;
    stiffenerCount: number;
}

export class StiffenerLayout extends CodeList {
    public stiffenerLocation!: StiffenerLocation;
    public stiffenerCrossSectionPart!: StiffenerCrossSectionPart;
    public stiffenerCount!: number;

    constructor(codeList: IStiffenerLayoutConstructor) {
        super(codeList);

        if (codeList != null) {
            this.stiffenerLocation = codeList.stiffenerLocation;
            this.stiffenerCrossSectionPart = codeList.stiffenerCrossSectionPart;
            this.stiffenerCount = codeList.stiffenerCount;
        }
    }

    public static fromService(codeList: StiffenerLayoutEntity) {
        return new StiffenerLayout(CodeList.baseFromService<IStiffenerLayoutConstructor>('StiffenerLayoutEntity', codeList, {
            stiffenerLocation: codeList.StiffenerLocation,
            stiffenerCrossSectionPart: codeList.StiffenerCrossSectionPart,
            stiffenerCount: codeList.StiffenerCount
        } as IStiffenerLayoutConstructor));
    }

    public override cloneDeep(): StiffenerLayout {
        const codeList: StiffenerLayout = super.cloneDeep() as StiffenerLayout;

        codeList.stiffenerLocation = this.stiffenerLocation;
        codeList.stiffenerCrossSectionPart = this.stiffenerCrossSectionPart;
        codeList.stiffenerCount = this.stiffenerCount;

        return codeList;
    }
}
