import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import { Center2dPe } from '@profis-engineering/pe-gl-model/components/2d/center-2d';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitValue } from '@profis-engineering/pe-ui-common/services/unit.common';
import { IGlModelPeComponent } from '../../../shared/components/gl-model';
import { DesignPe } from '../../../shared/entities/design-pe';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { PointsTableType } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Navigation';
import { Controls2dEditor, IMenu2dContext } from '../../../shared/services/menu-2d.service.base';
import { IModifiedItem, IPointTableAddInput, IPointsTableItem, IPointsTableProps, ITableItemBase, PointsTable } from '../../components/main-menu/points-table/PointsTable';
import { UnitService } from '../../services/unit.service';
import { updateMainMenuControl } from './menu-helper';

export function initializePointsTable(controlProps: IPointsTableProps, unitService: UnitService) {
    controlProps.type = PointsTable;
    controlProps.unit = unitService;

    return {
        controlProps
    };
}


export function createPointsTable2d(design: DesignPe, glModelComponent: IGlModelPeComponent, navControlName: string, pointsTableProps: IPointsTableProps) {
    const model = glModelComponent?.getModel();

    switch (navControlName) {
        case Controls2dEditor.AnchorNodes: {
            pointsTableProps.items = model?.anchor?.points?.map(p => {
                const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p.position);
                return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y } as ITableItemBase;
            });
            pointsTableProps.isEditable = isAnchorEditable(design);
            return PointsTableType.Anchor;
        }
        case Controls2dEditor.BaseplateNodes: {
            pointsTableProps.items = model?.plate?.points?.map(p => {
                const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p);
                return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y } as ITableItemBase;
            });
            pointsTableProps.isEditable = isAnchorEditable(design);
            return PointsTableType.Plate;
        }
        default:
            return undefined;
    }
}

export function onCommitPointTable(design: DesignPe, context: IMenu2dContext, type: number, unitService: UnitService, item: IModifiedItem) {
    const value = item.x ?? item.y ?? '';

    const unitValue = unitService.parseUnitValue(value, UnitGroup.Length);

    if ((unitValue != null && !Number.isNaN(unitValue.value))) {
        const internalValue = unitService.convertUnitValueToUnit(minMaxFilter(design, unitValue, unitService), unitService.getInternalUnit(UnitGroup.Length));

        const result = getPointTableCommitResult(context, item, value, internalValue, type, unitService);

        if (result != null) {
            const propertyId = getPointTablePropertyId(type);
            context.save2dState({ [propertyId as number]: true }, false);
        }
    }

    updatePointsTable(context, type);
}

export function onChangePointsTableCell(context: IMenu2dContext, type: number, item: IModifiedItem) {
    const setString = (items: IPointsTableItem[], item: IModifiedItem) => {
        if (item.x != null) {
            items[item.id].formattedX = item.x;
        }

        if (item.y != null) {
            items[item.id].formattedY = item.y;
        }

        if (item.shape != null) {
            items[item.id].shape = item.shape;
        }

        if (item.bond != null) {
            items[item.id].bond = item.bond;
        }
    };

    const items = getPointsTableItems(context, type);
    setString(items, item);
    context.setState(menu => updateMainMenuControl(menu, `control-${getPointTableControlName(type)}`, { items } as any));
}

export function onDeletePointTable(context: IMenu2dContext, type: number, index: number) {
    const result = getPointTableDeleteResult(context, index, type);
    if (result != null) {
        updatePointsTable(context, type);

        const propertyId = getPointTablePropertyId(type);
        context.save2dState({ [propertyId as number]: true }, false);
    }
}

export function onChangeAddPointTable(context: IMenu2dContext, type: number, item: IPointTableAddInput) {
    const input = { addInput: item } as any;
    context.setState(menu => updateMainMenuControl(menu, `control-${getPointTableControlName(type)}`, input));
}

export function onCommitAddPointTable(unitService: UnitService, design: DesignPe, context: IMenu2dContext, type: number, item: IPointTableAddInput
) {
    const unitValueX = unitService.parseUnitValue(item.x ?? '', UnitGroup.Length);
    const unitValueY = unitService.parseUnitValue(item.y ?? '', UnitGroup.Length);

    if (unitValueX != null && !Number.isNaN(unitValueX.value) && unitValueY != null && !Number.isNaN(unitValueY.value)) {
        const internalUnitValueX = unitService.convertUnitValueToUnit(minMaxFilter(design, unitValueX, unitService), unitService.getInternalUnit(UnitGroup.Length));
        const internalUnitValueY = unitService.convertUnitValueToUnit(minMaxFilter(design, unitValueY, unitService), unitService.getInternalUnit(UnitGroup.Length));
        const addInputItem: IPointTableAddInput = {} as IPointTableAddInput;

        const result = getPointTableCommitAddResult(context, design, item, internalUnitValueX, internalUnitValueY, addInputItem, type);

        updatePointTableAddInput(context, type, addInputItem);
        if (result != null) {
            updatePointsTable(context, type);

            const propertyId = getPointTablePropertyId(type);
            context.save2dState({ [propertyId as number]: true }, false);
        }
    }
    else {
        updatePointTableAddInput(
            context,
            type,
            {
                x: unitValueX != null && !Number.isNaN(unitValueX.value) ? unitService.formatUnitValue(minMaxFilter(design, unitValueX, unitService)) : undefined,
                y: unitValueY != null && !Number.isNaN(unitValueY.value) ? unitService.formatUnitValue(minMaxFilter(design, unitValueY, unitService)) : undefined,
            }
        );
    }
}

function getPointsTableItems(context: IMenu2dContext, type: PointsTableType) {
    const model = context.glModelComponent?.getModel();
    switch (type) {
        case PointsTableType.Anchor:
            return model?.anchor?.points?.map(p => {
                const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p.position);
                return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y } as IPointsTableItem;
            }) ?? [];
        case PointsTableType.Plate:
            return model?.plate?.points?.map(p => {
                const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p);
                return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y } as IPointsTableItem;
            }) ?? [];
        default:
            return [];
    }
}

function getPointTablePropertyId(type: PointsTableType) {
    switch (type) {
        case PointsTableType.Anchor:
            return UIProperty.AnchorLayout_CustomLayoutPoints;
        case PointsTableType.Plate:
            return UIProperty.AnchorPlate_CustomLayoutPoints;
        default:
            return undefined;
    }
}

function minMaxFilter(design: DesignPe, unitValue: UnitValue, unitService: UnitService) {
    const limits = getDistancePointLimitations(design);
    const maxPointDistance = unitService.convertUnitValueArgsToUnit(limits.max, unitService.getInternalUnit(UnitGroup.Length), unitService.getDefaultUnit(UnitGroup.Length));
    const minPointDistance = unitService.convertUnitValueArgsToUnit(limits.min, unitService.getInternalUnit(UnitGroup.Length), unitService.getDefaultUnit(UnitGroup.Length));

    if (unitValue.value > maxPointDistance) {
        unitValue.value = maxPointDistance;
    }
    else if (unitValue.value < minPointDistance) {
        unitValue.value = minPointDistance;
    }

    return unitValue;
}

export function updatePointsTable(context: IMenu2dContext, type: PointsTableType) {
    const model = context.glModelComponent?.getModel();

    switch (type) {
        case PointsTableType.Anchor:
            context.setState(menu => updateMainMenuControl(menu, `control-${Controls2dEditor.AnchorNodes}`, {
                items: model?.anchor?.points?.map(p => {
                    const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p.position);
                    return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y } as IPointsTableItem;
                })
            } as any));
            break;
        case PointsTableType.Plate:
            context.setState(menu => updateMainMenuControl(menu, `control-${Controls2dEditor.BaseplateNodes}`, {
                items: model?.plate?.points?.map(p => {
                    const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p);
                    return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y } as IPointsTableItem;
                })
            } as any));
            break;
    }
}

function getPointTableCommitResult(context: IMenu2dContext, item: IModifiedItem, value: string, internalValue: UnitValue, type: PointsTableType, unitService: UnitService) {
    const glModelPeComponent = context.glModelComponent;
    const model = glModelPeComponent?.getModel();
    switch (type) {
        case PointsTableType.Anchor: {
            /* eslint-disable  @typescript-eslint/no-non-null-assertion */
            const anchorPointPosition = model!.anchor!.points![item.id].position;
            const internalValueAnchor = getInternalAbsolutePointValue(item, model, internalValue);
            if (hasPointTableItemChanged(unitService, item, value, internalValueAnchor, model, anchorPointPosition)) {
                return item.x != null
                    ? context.glModelComponent.moveAnchorPoint2d(item.id, internalValueAnchor, anchorPointPosition.y)
                    : context.glModelComponent.moveAnchorPoint2d(item.id, anchorPointPosition.x, internalValueAnchor);
            }
            break;
        }
        case PointsTableType.Plate: {
            /* eslint-disable  @typescript-eslint/no-non-null-assertion */
            const platePointPosition = model!.plate!.points![item.id];
            const internalValuePlate = getInternalAbsolutePointValue(item, model, internalValue);
            if (hasPointTableItemChanged(unitService, item, value, internalValuePlate, model, platePointPosition)) {
                return item.x != null
                    ? glModelPeComponent.movePlatePoint2d(item.id, internalValuePlate, platePointPosition.y)
                    : glModelPeComponent.movePlatePoint2d(item.id, platePointPosition.x, internalValuePlate);
            }
            break;
        }
        default:
            break;
    }

    return undefined;
}

function getDistancePointLimitations(design: DesignPe) {
    const max = design.properties.get(UIProperty.BaseMaterial_EdgeXPositive).max || design.properties.get(UIProperty.BaseMaterial_ConcreteLengthPositive).max || 15000;
    const min = -max;
    return {
        min,
        max
    };
}

function isAnchorEditable(design: DesignPe) {
    return !(design.properties.get(UIProperty.AnchorLayout_LayoutCustom).disabled || design.properties.get(UIProperty.AnchorLayout_LayoutCustom).hidden);
}

function getInternalAbsolutePointValue(item: IModifiedItem, model: IModelPe, internalValue: UnitValue) {
    return item.x != null
        ? Center2dPe.calculateXAbsoluteFromCenter(model, internalValue.value)
        : Center2dPe.calculateYAbsoluteFromCenter(model, internalValue.value);
}

function hasPointTableItemChanged(unitService: UnitService, item: IModifiedItem, value: string, internalValueAbsolute: number, model: IModelPe, oldPosition: Vector2) {
    if (item.x != null && oldPosition.x == internalValueAbsolute ||
        item.y != null && oldPosition.y == internalValueAbsolute) {
        return false;
    }

    // Check if text hasn't changed
    const oldPositionCenter = Center2dPe.calculatePointRelativeToCenter(model, oldPosition);
    const valueFormatted = unitService.formatInternalValueAsDefault(
        item.x != null
            ? oldPositionCenter.x
            : oldPositionCenter.y,
        UnitGroup.Length
    );

    if (value == valueFormatted) {
        return false;
    }

    return true;
}

function getPointTableControlName(type: PointsTableType) {
    switch (type) {
        case PointsTableType.Anchor:
            return Controls2dEditor.AnchorNodes;
        case PointsTableType.Plate:
            return Controls2dEditor.BaseplateNodes;
        default:
            return undefined;
    }
}

function getPointTableDeleteResult(context: IMenu2dContext, index: number, type: PointsTableType) {
    const glModelPeComponent = context.glModelComponent ;
    switch (type) {
        case PointsTableType.Anchor:
            return glModelPeComponent.deleteAnchorPoint2d(index);
        case PointsTableType.Plate:
            return glModelPeComponent.deletePlatePoint2d(index);
        default:
            return undefined;
    }
}

function updatePointTableAddInput(context: IMenu2dContext, type: number, item: IPointTableAddInput) {
    const input = { addInput: item } as any;
    context.setState(menu => updateMainMenuControl(menu, `control-${getPointTableControlName(type)}`, input));
}

function getPointTableCommitAddResult(context: IMenu2dContext, _design: DesignPe, _item: IPointTableAddInput, internalUnitValueX: UnitValue, internalUnitValueY: UnitValue, _addInputItem: IPointTableAddInput, type: PointsTableType) {
    const glModelPeComponent = context.glModelComponent;
    const model = glModelPeComponent?.getModel();
    switch (type) {
        case PointsTableType.Anchor: {
            return glModelPeComponent?.addAnchorPoint2d(Center2dPe.calculateXAbsoluteFromCenter(model, internalUnitValueX.value), Center2dPe.calculateYAbsoluteFromCenter(model, internalUnitValueY.value));
        }
        case PointsTableType.Plate: {
            return glModelPeComponent?.addPlatePoint2d(Center2dPe.calculateXAbsoluteFromCenter(model, internalUnitValueX.value), Center2dPe.calculateYAbsoluteFromCenter(model, internalUnitValueY.value));
        }
        default:
            return undefined;
    }
}