import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import seismicFillingSetImage from '../../../images/select-anchor/hilti-seismic-filling-set.png';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './seismic-filling-set-modal.component.html',
    styleUrls: ['./seismic-filling-set-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SeismicFillingSetModalComponent implements OnInit {
    public hiltiSeismicFillingSet!: string;

    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.hiltiSeismicFillingSet = seismicFillingSetImage;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
