import { Injectable } from '@angular/core';
import { Feature as FeatureCommon } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { FeaturesVisibilityInfoServiceBase } from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';
import { FeatureVisibilityInfoEntity } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { FeaturePe } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display.Enums';
import { LocalizationService } from './localization.service';

export type Feature = FeatureCommon | FeaturePe;

@Injectable({
  providedIn: 'root'
})
export class FeaturesVisibilityInfoService extends FeaturesVisibilityInfoServiceBase {
    protected baseService!: FeaturesVisibilityInfoServiceBase;
    private featuresPeVisibilityInfo: { [feature: number]: FeatureVisibilityInfoEntity } = {};

    constructor(
        private localization: LocalizationService
    ) {
        super();
    }


    public setBaseService(baseService: FeaturesVisibilityInfoServiceBase) {
        this.baseService = baseService;
    }

    public setDataFromService(featuresVisibilityInfo: FeatureVisibilityInfoEntity[]) {
        this.featuresPeVisibilityInfo = featuresVisibilityInfo.reduce((featuresVisibilityInfo, disabledFeature) => {
            featuresVisibilityInfo[disabledFeature.Feature] = disabledFeature;
            return featuresVisibilityInfo;
        }, {} as { [feature: number]: FeatureVisibilityInfoEntity });
    }


    /**
     * Value indicating whether integrations are enabled or disabled. Shortcut for:
     * !featuresVisibilityInfo.isDisabled(Feature.Application_Settings_InterfacesToStructuralCalculationSoftware)
     */
    public get areIntegrationsEnabled(): boolean {
        return this.baseService.areIntegrationsEnabled;
    }

    public get hasAdvancedFeature(): boolean {
        return this.baseService.hasAdvancedFeature;
    }


    public isHidden(feature: Feature, regionId?: number): boolean {
        // Check PE-specific feature
        if (this.isHiddenPe(feature as FeaturePe, regionId)) {
            return true;
        }

        // Check Common feature
        return this.baseService.isHidden(feature as FeatureCommon, regionId);
    }

    public isDisabled(feature: Feature, regionId?: number): boolean {
        // Check PE-specific feature
        if (this.isDisabledPe(feature as FeaturePe, regionId)) {
            return true;
        }

        // Check Common feature
        return this.baseService.isDisabled(feature as FeatureCommon, regionId);
    }

    public tooltip(feature: Feature): string {
        // Check PE-specific feature
        const tooltip = this.tooltipPe(feature as FeaturePe);
        if (tooltip != null) {
            return tooltip;
        }

        // Check Common feature
        return this.baseService.tooltip(feature as FeatureCommon);
    }


    private isHiddenPe(feature: FeaturePe, regionId?: number) {
        const result = this.featuresPeVisibilityInfo[feature];
        return (result?.Hidden ?? false)
            && (regionId == null || result.DisabledRegions == null || result.DisabledRegions.some(x => x == regionId));
    }

    private isDisabledPe(feature: FeaturePe, regionId?: number) {
        const result = this.featuresPeVisibilityInfo[feature];
        return (result?.Disabled ?? false)
            && (regionId == null || result.DisabledRegions == null || result.DisabledRegions.some(x => x == regionId));
    }

    private tooltipPe(feature: FeaturePe) {
        const result = this.featuresPeVisibilityInfo[feature];
        if (result?.TooltipDisplayKey != null) {
            return this.localization.getString(result.TooltipDisplayKey);
        }

        return undefined;
    }
}
