import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit, ViewEncapsulation, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IAsadPreferencesFilteringParameters, IBaseplateResultsPreferenceInput } from '../../../shared/entities/baseplate-inputs';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';

@Component({
    templateUrl: './baseplate-results-preference.component.html',
    styleUrls: ['./baseplate-results-preference.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BaseplateResultsPreferenceComponent implements OnInit, OnChanges {
    @Input()
    public modalInstance!: ModalInstance<IBaseplateResultsPreferenceInput>;

    public noCleaningRequired = false;
    public noAutomaticCleaning = false;
    public isAdaptiveTorqueAllowed = false;
    public stars: number[] = [1, 2, 3, 4, 5];
    public selectedValueOfStars = 0;
    public clickedValueOfStars = 0;
    public numberOfResults = 0;

    private updateButtonTextStaticText = '';

    public get embedmentDepthBoundLowRounded() {
        return this.formatLength(this.embedmentDepthOptions.floor);
    }

    public get embedmentDepthBoundHighRounded() {
        return this.formatLength(this.embedmentDepthOptions.ceil);
    }

    public get baseplateSizeOptionsLowRounded() {
        return this.formatArea(this.modalInstance.input?.boundParams.baseplateSizeBoundLow);
    }

    public get baseplateSizeOptionsHighRounded() {
        return this.formatArea(this.modalInstance.input?.boundParams.baseplateSizeBoundHigh);
    }

    public get updateButtonText() {
        return `${this.updateButtonTextStaticText} (${this.getNumbersOfResult()})`;
    }

    public get updateButtonDisabled() {
        return this.getNumbersOfResult() < 1;
    }

    public baseplateSizeLowValue = 0;
    public baseplateSizeHighValue = 0;
    public baseplateSizeOptions: Options = {
        hideLimitLabels: true,
        hidePointerLabels: true,
        enforceStep: false,
        showSelectionBar: true
    };

    public numberOfAnchorsLowValue = 0;
    public numberOfAnchorsHighValue = 0;
    public numberOfAnchorsOptions: Options = {
        hideLimitLabels: true,
        hidePointerLabels: true,
        showSelectionBar: true
    };

    public embedmentDepthLowValue = 0;
    public embedmentDepthHighValue = 0;
    public embedmentDepthOptions: Options = {
        hideLimitLabels: true,
        hidePointerLabels: true,
        enforceStep: false,
        showSelectionBar: true
    };

    public utilizationLowValue = 0;
    public utilizationHighValue = 0;
    public utilizationOptions: Options = {
        hideLimitLabels: true,
        hidePointerLabels: true,
        enforceStep: false,
        showSelectionBarEnd: true
    };

    constructor(
        private unitService: UnitService,
        private localizationService: LocalizationService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        if (!this.modalInstance.input) {
            throw new Error('missing input data');
        }

        this.updateButtonTextStaticText = this.translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.UpdateResult.Button');

        this.numberOfAnchorsOptions.ceil = this.modalInstance.input.boundParams.numberOfAnchorsBoundHigh;
        this.numberOfAnchorsOptions.floor = this.modalInstance.input.boundParams.numberOfAnchorsBoundLow;
        this.embedmentDepthOptions.ceil = this.modalInstance.input.boundParams.embedmentDepthBoundHigh;
        this.embedmentDepthOptions.floor = this.modalInstance.input.boundParams.embedmentDepthBoundLow;
        this.baseplateSizeOptions.ceil = this.modalInstance.input.boundParams.baseplateSizeBoundHigh;
        this.baseplateSizeOptions.floor = this.modalInstance.input.boundParams.baseplateSizeBoundLow;
        this.utilizationOptions.ceil = this.modalInstance.input.boundParams.utilizationBoundHigh * 100;
        this.utilizationOptions.floor = this.modalInstance.input.boundParams.utilizationBoundLow * 100;
        this.numberOfAnchorsHighValue = this.modalInstance.input.params.numberOfAnchorsMax;
        this.numberOfAnchorsLowValue = this.modalInstance.input.params.numberOfAnchorsMin;
        this.embedmentDepthHighValue = this.modalInstance.input.params.embedmentDepthMax;
        this.embedmentDepthLowValue = this.modalInstance.input.params.embedmentDepthMin;
        this.baseplateSizeHighValue = this.modalInstance.input.params.baseplateSizeMax;
        this.baseplateSizeLowValue = this.modalInstance.input.params.baseplateSizeMin;
        this.utilizationHighValue = this.modalInstance.input.params.utilizationMax * 100;
        this.utilizationLowValue = this.modalInstance.input.params.utilizationMin * 100;
        this.noCleaningRequired = this.modalInstance.input.params.isNoCleaningRequired;
        this.noAutomaticCleaning = this.modalInstance.input.params.isNoAutomaticCleaning;
        this.isAdaptiveTorqueAllowed = this.modalInstance.input.params.isAdaptiveTorqueAllowed;
    }

    ngOnChanges(): void {
        this.getNumbersOfResult();
    }

    public close() {
        this.modalInstance.close();
    }

    countStar(star: number) {
        this.selectedValueOfStars = star;
        this.clickedValueOfStars = this.selectedValueOfStars;
    }

    hoverStar(star: number) {
        this.selectedValueOfStars = star;
    }

    onLeaveStar() {
        this.selectedValueOfStars = this.clickedValueOfStars;
    }

    public changeDetector() {
        this.changeDetectorRef.detectChanges();
    }

    public update() {
        const parameters: IAsadPreferencesFilteringParameters = {
            numberOfAnchorsMin: this.numberOfAnchorsLowValue,
            numberOfAnchorsMax: this.numberOfAnchorsHighValue,
            embedmentDepthMin: this.embedmentDepthLowValue,
            embedmentDepthMax: this.embedmentDepthHighValue,
            baseplateSizeMin: this.baseplateSizeLowValue,
            baseplateSizeMax: this.baseplateSizeHighValue,
            utilizationMin: this.utilizationLowValue / 100,
            utilizationMax: this.utilizationHighValue / 100,
            isNoAutomaticCleaning: this.noAutomaticCleaning,
            isNoCleaningRequired: this.noCleaningRequired,
            isAdaptiveTorqueAllowed: this.isAdaptiveTorqueAllowed
        };
        this.modalInstance.input?.fn(parameters);
        this.close();
    }

    public formatLength(value?: number) {
        return value ? this.unitService.formatInternalValueAsDefault(value, UnitGroup.Length) : '';
    }

    public formatArea(value?: number) {
        return value ? this.unitService.formatInternalValueAsDefault(value, UnitGroup.Area) : '';
    }

    public formatLengthWithoutUnit(value: number) {
        return this.formatWithoutUnit(value, UnitGroup.Length);
    }

    public formatAreaWithoutUnit(value: number) {
        return this.formatWithoutUnit(value, UnitGroup.Area);
    }

    public formatWithoutUnit(value: number, unitGroup: UnitGroup) {
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit), Unit.None);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private getNumbersOfResult() {
        const parameters: IAsadPreferencesFilteringParameters = {
            numberOfAnchorsMin: this.numberOfAnchorsLowValue,
            numberOfAnchorsMax: this.numberOfAnchorsHighValue,
            embedmentDepthMin: this.embedmentDepthLowValue,
            embedmentDepthMax: this.embedmentDepthHighValue,
            baseplateSizeMin: this.baseplateSizeLowValue,
            baseplateSizeMax: this.baseplateSizeHighValue,
            utilizationMin: this.utilizationLowValue / 100,
            utilizationMax: this.utilizationHighValue / 100,
            isNoAutomaticCleaning: this.noAutomaticCleaning,
            isNoCleaningRequired: this.noCleaningRequired,
            isAdaptiveTorqueAllowed: this.isAdaptiveTorqueAllowed
        };

        return this.modalInstance.input?.getNumbersOfResults(parameters) ?? 0;
    }
}
