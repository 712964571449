import { ConcreteCharacteristicEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class ConcreteCharacteristic extends CodeList {

    public static fromService(codeList: ConcreteCharacteristicEntity) {
        return new ConcreteCharacteristic(CodeList.baseFromService('ConcreteCharacteristicEntity', codeList));
    }

    public override cloneDeep(): ConcreteCharacteristic {
        return super.cloneDeep() as ConcreteCharacteristic;
    }
}
