<div id="resize-info-section" class="info-section box-section box-section-white"
    [class.openedPosition]="!collapsed">
    <!--
        Note: change this value if CSS is changed!
        correction: accomodates for target element's margins and other fixes
    -->
    <pe-resizer
        [disabled]="productHidden || collapsed"
        [targetHtmlElement]="infoSectionContainer"
        [parentHtmlElement]="parentElement"
        [correction]="-16"
        (sizeChange)="onResize()">
        <div *ngIf="design != null" id="info-section-tour" class="box-section-header">
            <span class="info-section-header-collapsed-anchor-image pe-ui-pe-sprite pe-ui-pe-sprite-anchor-search"></span>

            <span class="box-section-title"
                *ngIf="!collapsed"
                [innerHtml]="translate(design.globalMetaProperties.infoSectionTranslationKey)"></span>

            <div class="info-section-header-collapsed"
                [class.info-section-shortcuts-hidden]="!collapsed">
                <div class="info-section-header-collapsed-anchor-name" [ngbTooltip]="productName" [innerHtml]="productName"></div>
            </div>

            <pe-collapse
                [container]="'box-section-content'"
                [collapsed]="collapsed"
                (collapsedChange)="onInfoSectionCollapse($any($event).detail)">
            </pe-collapse>
        </div>

        <div *ngIf="design != null" class="box-section-content">
            <div class="info-section-anchor-detailed info-section-region">
                <div id="info-section-content" class="info-section-region-content">
                    <div class="info-section-calculate">
                        <pe-button
                            [id]="'pe-portfolio-filter-button'"
                            class="button button-default button-icon"
                            [look]="'DefaultIcon'"
                            [disabled]="design.isReadOnlyDesignMode"
                            (buttonClick)="openSelectProduct()"
                            [ngbTooltip]="filterButtonTooltip">
                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-filter"></span>
                        </pe-button>

                        <div class="tooltip-wrapper" [ngbTooltip]="getCalcAllTooltip">
                            <pe-button
                                [id]="'pe-portfolio-calculateall-button'"
                                class="button button-default calculate-all"
                                [look]="'Default'"
                                width="100%"
                                height="34px"
                                [text]="translate('Agito.Hilti.Profis3.SelectAnchor.CalculateAll')"
                                [disabled]="isCalculateAllDisabled"
                                (buttonClick)="openCalculateAll()">
                            </pe-button>
                        </div>
                    </div>

                    <div class="info-section-active-anchor-filters-title" *ngIf="displayedFilters.length > 3" [innerHtml]="filtersTitle"></div>

                    <div *ngIf="displayedFilters.length > 3"
                        class="design-right-collapse-container"
                        [class.opened]="filtersOpened">
                        <span class="design-right-collapse-line"></span>

                        <button
                            type="button"
                            class="design-right-collapse-button"
                            (click)="filtersOpened = !filtersOpened">
                            <span class="pe-ui-pe-sprite" [ngClass]="filtersOpened ? 'pe-ui-pe-sprite-arrow-up' : 'pe-ui-pe-sprite-arrow-down'"></span>
                        </button>

                        <span class="design-right-collapse-line"></span>
                    </div>

                    <div class="info-section-anchor-filters"
                        [class.max-show-filters]="displayedFilters.length > 5"
                        *ngIf="displayedFilters.length > 0 && (filtersOpened || displayedFilters.length < 4)">
                        <div class="info-section-anchor-filter-container"
                            *ngFor="let filter of displayedFilters; trackBy: trackFilterById">
                            <div class="info-section-anchor-filter-remove-button">
                                <div class="info-section-anchor-filter-remove-button-container">
                                    <button
                                        type="button"
                                        class="info-section-anchor-filter-remove-button"
                                        [disabled]="design.isReadOnlyDesignMode"
                                        (click)="removeProductFilter(filter.id)">
                                        <span class="pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-x"></span>
                                    </button>

                                    <div class="line-seperator"></div>

                                    <div class="info-section-anchor-detailed-anchor-name" [innerHtml]="translate(filter.title)"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- anchors -->
                    <div class="info-section-anchor-detailed-anchors"
                        *ngIf="!productHidden && anchors != null && anchors.length > 0">
                        <div id="info-section-anchor-list" class="info-section-anchor-detailed-anchor info-section-anchor-detailed-down-anchor"
                            *ngFor="let anchor of anchors; trackBy: trackAnchorById">
                            <div class="info-section-anchor-detailed-anchor-select-button"
                                [class.active]="anchor.id == getProductFamilyId()"
                                [attr.disabled]="design.isReadOnlyDesignMode ? true : null"
                                (click)="setProduct(anchor.id)">
                                <div class="info-section-anchor-detailed-anchor-select-button-container">
                                    <div class="info-section-anchor-detailed-anchor-image">
                                        <div class="no-image-container">
                                            <span
                                                class="no-image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-anchor-family-no-photo-available"></span>
                                        </div>
                                        <span class="pe-ui-pe-sprite sprite-responsive" [ngClass]="'pe-ui-pe-' + anchor.image"></span>
                                    </div>

                                    <div class="line-seperator"></div>

                                    <div class="info-section-anchor-detailed-anchor-name">
                                        {{ anchor.name }}
                                        <div class="new" *ngIf="anchor.isNew" [innerHtml]="translate('Agito.Hilti.Profis3.Anchors.New')"></div>
                                    </div>

                                    <span class="tag" *ngIf="anchor.tag" [innerHtml]="shortTag(anchor.tag)"></span>
                                    <span
                                        class="tag"
                                        *ngIf="anchor.internalPortfolioOnly"
                                        [ngbTooltip]="translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio.Tooltip')"
                                        [innerHtml]="shortTag(translate('Agito.Hilti.Profis3.Anchors.ShowFullInternalPortfolio'))">
                                    </span>

                                    <button
                                        type="button"
                                        class="info-section-anchor-detailed-anchor-favorite"
                                        (click)="favoriteToggle(anchor.id); $any($event).preventDefault(); $any($event).stopPropagation()"
                                        [ngbTooltip]="favoriteTooltip">
                                        <span class="pe-ui-pe-sprite" [ngClass]="getFavoriteSpriteAnchor(anchor.id)"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="info-section-anchor-detailed-type-size-embedment-depth-container"
                        *ngIf="(!typeHidden && !sizeHidden && !embedmentDepthHidden && !productHidden)">
                        <div class="info-section-anchor-detailed-type-size-container"
                            *ngIf="!typeHidden || !sizeHidden">
                            <div class="info-section-anchor-detailed-dropdown info-section-anchor-detailed-dropdown-type"
                                *ngIf="!typeHidden">
                                <pe-dropdown
                                    [title]="typeDropdown.title"
                                    [notSelectedText]="typeDropdown.notSelectedText"
                                    [tooltip]="typeDropdown.tooltip"
                                    [items]="typeDropdown.items"
                                    [tags]="typeDropdownTags"
                                    [openUp]="typeDropdown.openUp"
                                    [itemsMaxHeight]="typeDropdown.itemsMaxHeight"
                                    [selectedValue]="design.model[propertyMetaDataEnum.AnchorLayout_Type.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnum.AnchorLayout_Type.id] = $any($event).detail; runCalculation()"
                                    [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_Type.id).disabled">
                                </pe-dropdown>
                            </div>

                            <div class="info-section-anchor-detailed-dropdown info-section-anchor-detailed-dropdown-size"
                                *ngIf="!sizeHidden">
                                <pe-dropdown
                                    [title]="sizeDropdown.title"
                                    [notSelectedText]="sizeDropdown.notSelectedText"
                                    [tooltip]="sizeDropdown.tooltip"
                                    [items]="sizeDropdown.items"
                                    [tags]="sizeDropdownTags"
                                    [openUp]="sizeDropdown.openUp"
                                    [itemsMaxHeight]="sizeDropdown.itemsMaxHeight"
                                    [selectedValue]="design.model[propertyMetaDataEnum.AnchorLayout_Size.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnum.AnchorLayout_Size.id] = $any($event).detail; runCalculation()"
                                    [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_Size.id).disabled">
                                </pe-dropdown>
                            </div>
                        </div>

                        <pe-button
                            *ngIf="!approvalStoProperty.hidden"
                            class="button view-approval sto-approval"
                            [look]="'Default'"
                            width="100%"
                            [text]="translate(viewApprovalTranslationKeyRussianBased)"
                            (buttonClick)="openSTOApproval()"
                            [disabled]="approvalStoProperty.disabled">
                        </pe-button>

                        <pe-button
                            *ngIf="!approvalUktaProperty.hidden"
                            class="button view-approval ukta-approval"
                            [look]="'Default'"
                            width="100%"
                            [text]="translate(viewApprovalTranslationKeyUKTA)"
                            (buttonClick)="openUKTAApproval()"
                            [disabled]="approvalUktaProperty.disabled">
                        </pe-button>

                        <pe-button
                            *ngIf="showApprovalButton"
                            [id]="'pe-portfolio-viewetaapproval-button'"
                            class="button button-default view-approval"
                            [look]="'Default'"
                            width="100%"
                            [text]="translate(viewApprovalTranslationKey)"
                            (buttonClick)="openApproval()"
                            [disabled]="approvalProperty.disabled">
                        </pe-button>

                        <div class="info-section-cstb-approval-button">
                            <pe-button *ngIf="!approvalCSTBProperty.hidden" [id]="'pe-portfolio-viewcstbbutton-button'" class="button"
                                [look]="'Default'" width="100%" [text]="translate(viewCSTBButtonTranslationKey)" (buttonClick)="openApproval()"
                                [disabled]="approvalCSTBProperty.disabled">
                            </pe-button>
                        </div>

                        <div class="info-section-anchor-detailed-article-number-container">

                            <div class="info-section-anchor-detailed-article-number-container-element"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberMechanical.id).hidden">
                                <div class="info-section-anchor-detailed-article-number-content">
                                    <pe-label
                                        [id]="'pe-portfolio-adhesiveitem-number'"
                                        [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberMechanical')"
                                        [text]="design.model[propertyMetaDataEnum.AnchorLayout_ArticleNumberMechanical.id]"
                                        [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberMechanical.id).disabled"
                                        [tooltip]="articleNumberMechanicalTooltip">
                                    </pe-label>
                                </div>
                            </div>

                            <div class="info-section-anchor-detailed-article-number-container-element"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberInsert.id).hidden">
                                <div class="info-section-anchor-detailed-article-number-content">
                                    <pe-label
                                        [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberInsert')"
                                        [text]="design.model[propertyMetaDataEnum.AnchorLayout_ArticleNumberInsert.id]"
                                        [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberInsert.id).disabled"
                                        [tooltip]="articleNumberInsertTooltip">
                                    </pe-label>
                                </div>
                            </div>

                            <div class="info-section-anchor-detailed-article-number-container-element"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberInsertAlternative.id).hidden">
                                <div class="info-section-anchor-detailed-article-number-content">
                                    <pe-label
                                        [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberInsertAlternative')"
                                        [text]="design.model[propertyMetaDataEnum.AnchorLayout_ArticleNumberInsertAlternative.id]"
                                        [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberInsertAlternative.id).disabled"
                                        [tooltip]="articleNumberInsertAlternativeTooltip">
                                    </pe-label>
                                </div>
                            </div>

                            <div class="info-section-anchor-detailed-article-number-container-element"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberChemical.id).hidden">
                                <div class="info-section-anchor-detailed-article-number-content">
                                    <pe-label
                                        [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberChemical')"
                                        [text]="design.model[propertyMetaDataEnum.AnchorLayout_ArticleNumberChemical.id]"
                                        [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberChemical.id).disabled"
                                        [tooltip]="articleNumberChemicalTooltip">
                                    </pe-label>
                                </div>
                            </div>

                            <div class="info-section-anchor-detailed-article-number-container-element"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberCapsule.id).hidden">
                                <div class="info-section-anchor-detailed-article-number-content">
                                    <pe-label
                                        [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberCapsule')"
                                        [text]="design.model[propertyMetaDataEnum.AnchorLayout_ArticleNumberCapsule.id]"
                                        [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberCapsule.id).disabled"
                                        [tooltip]="articleNumberCapsuleTooltip">
                                    </pe-label>
                                </div>
                            </div>

                            <div class="info-section-anchor-detailed-article-number-container-element"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberSieveSleeve.id).hidden">
                                <div class="info-section-anchor-detailed-article-number-content">
                                    <pe-label
                                        [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.ArticleNumberSieveSleeve')"
                                        [text]="design.model[propertyMetaDataEnum.AnchorLayout_ArticleNumberSieveSleeve.id]"
                                        [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_ArticleNumberSieveSleeve.id).disabled"
                                        [tooltip]="articleNumberSieveSleeveTooltip">
                                    </pe-label>
                                </div>
                            </div>
                        </div>

                        <div class="info-section-anchor-detailed-eu-cip-container">
                            <pe-numeric-text-box class="info-section-anchor-detailed-eu-cip-container-element"
                                [title]="translate(design.properties.get(propertyMetaDataEnum.AnchorLayout_HeadThickness.id).titleDisplayKey)"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_HeadThickness.id).hidden"
                                [value]="design.model[propertyMetaDataEnum.AnchorLayout_HeadThickness.id]"
                                (valueChange)="design.model[propertyMetaDataEnum.AnchorLayout_HeadThickness.id] = $any($event).detail; runCalculation()"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_HeadThickness.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box class="info-section-anchor-detailed-eu-cip-container-element"
                                [title]="translate(design.properties.get(propertyMetaDataEnum.AnchorLayout_HeadDiameter.id).titleDisplayKey)"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_HeadDiameter.id).hidden"
                                [value]="design.model[propertyMetaDataEnum.AnchorLayout_HeadDiameter.id]"
                                (valueChange)="design.model[propertyMetaDataEnum.AnchorLayout_HeadDiameter.id] = $any($event).detail; runCalculation()"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_HeadDiameter.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box class="info-section-anchor-detailed-eu-cip-container-element"
                                [title]="translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.CipMinSpacing')"
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_CipMinSpacing.id).hidden"
                                [value]="design.model[propertyMetaDataEnum.AnchorLayout_CipMinSpacing.id]"
                                (valueChange)="design.model[propertyMetaDataEnum.AnchorLayout_CipMinSpacing.id] = $any($event).detail; runCalculation()"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_CipMinSpacing.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_CipMinimumEdgeDistance.id).hidden"
                                class="info-section-anchor-detailed-eu-cip-container-element"
                                [title]="translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.CipMinimumEdgeDistance')"
                                [value]="design.model[propertyMetaDataEnum.AnchorLayout_CipMinimumEdgeDistance.id]"
                                (valueChange)="design.model[propertyMetaDataEnum.AnchorLayout_CipMinimumEdgeDistance.id] = $any($event).detail; runCalculation()"
                                [unit]="design.unitLength"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_CipMinimumEdgeDistance.id).disabled">
                            </pe-numeric-text-box>

                            <pe-numeric-text-box
                                *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_CipK8.id).hidden"
                                class="info-section-anchor-detailed-eu-cip-container-element"
                                [title]="translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.CipK8')"
                                [value]="design.model[propertyMetaDataEnum.AnchorLayout_CipK8.id]"
                                (valueChange)="design.model[propertyMetaDataEnum.AnchorLayout_CipK8.id] = $any($event).detail; runCalculation()"
                                [updateOnBlur]="true"
                                [debounceStepperChanges]="true"
                                [required]="true"
                                [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_CipK8.id).disabled">
                            </pe-numeric-text-box>
                        </div>

                        <div class="info-section-anchor-detailed-embedment-depth-container"
                            *ngIf="!embedmentDepthHidden">
                            <pe-control-title
                                [title]="translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.EmbedmentDepth')"
                                [tooltip]="embedmentDepthTooltip">
                            </pe-control-title>

                            <div class="info-section-anchor-detailed-embedment-depth-content">
                                <pe-toggle-button-group
                                    class="info-section-anchor-detailed-embedment-depth-type"
                                    *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_EmbedmentDepthOptimizationType.id).hidden"
                                    [items]="embedmentDepthTypeToggleButtonGroup.items"
                                    [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_EmbedmentDepthOptimizationType.id).disabled"
                                    [model]="design.model[propertyMetaDataEnum.AnchorLayout_EmbedmentDepthOptimizationType.id]"
                                    (modelChange)="design.model[propertyMetaDataEnum.AnchorLayout_EmbedmentDepthOptimizationType.id] = $any($event).detail; runCalculation()">
                                </pe-toggle-button-group>

                                <pe-numeric-text-box
                                    class="info-section-anchor-detailed-embedment-depth-value-input"
                                    *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_EmbedmentDepthVariable.id).hidden"
                                    [value]="design.model[propertyMetaDataEnum.AnchorLayout_EmbedmentDepthVariable.id]"
                                    (valueChange)="design.model[propertyMetaDataEnum.AnchorLayout_EmbedmentDepthVariable.id] = $any($event).detail; runCalculation()"
                                    [unit]="design.unitLength"
                                    [updateOnBlur]="true"
                                    [debounceStepperChanges]="true"
                                    [required]="true"
                                    [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_EmbedmentDepthVariable.id).disabled">
                                </pe-numeric-text-box>

                                <pe-dropdown
                                    class="info-section-anchor-detailed-embedment-depth-value-dropdown"
                                    *ngIf="!design.properties.get(propertyMetaDataEnum.AnchorLayout_EmbedmentDepthFixedMultiple.id).hidden"
                                    [notSelectedText]="embedmentDepthValueDropdown.notSelectedText"
                                    [items]="embedmentDepthValueDropdown.items"
                                    [openUp]="true"
                                    [selectedValue]="design.model[propertyMetaDataEnum.AnchorLayout_EmbedmentDepthFixedMultiple.id]"
                                    (selectedValueChange)="design.model[propertyMetaDataEnum.AnchorLayout_EmbedmentDepthFixedMultiple.id] = $any($event).detail; runCalculation()"
                                    [disabled]="design.properties.get(propertyMetaDataEnum.AnchorLayout_EmbedmentDepthFixedMultiple.id).disabled">
                                </pe-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="info-section-space-filler"></div>
                </div>
            </div>
        </div>
    </pe-resizer>
</div>
