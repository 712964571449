<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.HorizontalPostProfileConnection.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="connection-types">
        <div class="connection-description">
            <p [innerHtml]="translate('Agito.Hilti.Profis3.HorizontalPostProfileConnection.TransverseConnection')"></p>
        </div>
        <div>
            <img [src]="transverseImage" alt="transverse image">
        </div>

        <div class="connection-description">
            <p [innerHtml]="translate('Agito.Hilti.Profis3.HorizontalPostProfileConnection.ThroughConnection')"></p>
        </div>
        <div>
            <img [src]="throughImage" alt="through image">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.HorizontalPostProfileConnection.Ok')"></pe-button>
</pe-modal-footer>

