<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.Realistic.Title')"
    [closeButtonDisabled]="isCalculationRunning">
</pe-modal-header>

<pe-modal-body>
    <div>
        <div class="disclaimer">
            <p class="text">{{shortDisclaimer}}</p>

            <a (click)="openMoreDetailsPopup()" [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.DoneCalculation.MoreDetails')"></a>
        </div>

        <div class="load-combination" *ngIf="isLoadCombinationDropdownVisible">
            <div class="load-combination-label" [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.LoadCombination')">
            </div>

            <pe-dropdown
                class="load-combination-control control-row"
                id="advanced-baseplate-calculation-load-combination-dropdown"
                [ngbTooltip]="loadCombinationTooltip"
                [items]="loadCombinationDropdown.items"
                [selectedValue]="loadCombinationDropdown.selectedValue"
                (selectedValueChange)="onSelectedLoadCombinationChange($any($event).detail)"
                [disabled]="(!design.isHandrailCBFEMCalculation && initialDesignReportData?.BaseplateDesignData?.SelectedLoadCombiantionId != null) || isCalculationRunning">
            </pe-dropdown>
        </div>

        <div class="calculation-result">
            <div class="preview-row">
                <div class="control-row click-through">
                    <pe-button
                        class="button-default button button-icon button-reset-camera click-reset"
                        [look]="'DefaultIcon'"
                        (buttonClick)="resetCamera()"
                        [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.ResetCamera')">
                        <span class="pe-ui-pe-sprite pe-ui-pe-sprite-center"></span>
                    </pe-button>

                    <div class="button-grp click-reset">
                        <pe-button
                            class="button"
                            *ngIf="isDisplayOptionButtonVisible(textureDisplayEnum.PlasticStrain)"
                            [look]="getDisplayOptionButtonLook(textureDisplayEnum.PlasticStrain)"
                            [width]="'150px'"
                            [height]="'34px'"
                            (buttonClick)="selectDisplayOption(textureDisplayEnum.PlasticStrain)"
                            [text]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.DisplayOption.PlasticStrain')">
                        </pe-button>
                        <pe-button
                            class="button"
                            *ngIf="isDisplayOptionButtonVisible(textureDisplayEnum.Deformation)"
                            [look]="getDisplayOptionButtonLook(textureDisplayEnum.Deformation)"
                            [width]="'150px'"
                            [height]="'34px'"
                            (buttonClick)="selectDisplayOption(textureDisplayEnum.Deformation)"
                            [text]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.DisplayOption.Deformation')">
                        </pe-button>
                        <pe-button
                            class="button"
                            *ngIf="isDisplayOptionButtonVisible(textureDisplayEnum.StressInConcrete)"
                            [look]="getDisplayOptionButtonLook(textureDisplayEnum.StressInConcrete)"
                            [width]="'150px'"
                            [height]="'34px'"
                            (buttonClick)="selectDisplayOption(textureDisplayEnum.StressInConcrete)"
                            [text]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.DisplayOption.ConcreteStresses')">
                        </pe-button>
                    </div>
                </div>

                <div class="stress-check-legend-wrapper click-through">
                    <div class="stress-check-legend stress-check-position" *ngIf="isStressCheckVisible">
                        <div class="legend-strip" [style.background]="'url(' + stressCheckLegend + ')'">
                        </div>

                        <div class="legend-values">
                            <span class="legend-max-value" [style.position]="'relative'"
                                [style.top]="legendMaxTopOffset + 'px'">{{stressCheckLegendMax}}</span>
                            <span class="legend-min-value">{{stressCheckLegendMin}}</span>
                        </div>
                    </div>
                </div>

                <div class="model-wrapper" (wheel)="$event.preventDefault()">
                    <pe-baseplate-design-gl-model #glModelRigid
                        [context3dKey]="glModelRigidProps.context3dKey"
                        [useBaseplateDesignComparisonData]="glModelRigidProps.useBaseplateDesignComparisonData"
                        [model]="glModelRigidProps.model"
                        [isHidden]="glModelRigidProps.isHidden"
                        (loaded)="glModelRigidProps.onLoaded != null ? glModelRigidProps.onLoaded() : null">
                    </pe-baseplate-design-gl-model>
                </div>

                <div class="model-wrapper" (wheel)="$event.preventDefault()">
                    <pe-baseplate-design-gl-model #glModelRealistic
                        [context3dKey]="glModelRealisticProps.context3dKey"
                        [model]="glModelRealisticProps.model"
                        [isHidden]="glModelRealisticProps.isHidden"
                        (loaded)="glModelRealisticProps.onLoaded != null ? glModelRealisticProps.onLoaded() : null">
                    </pe-baseplate-design-gl-model>
                </div>
            </div>

            <div class="table-results">
                <div class="table-row table-title">
                    <div class="col-20"></div>

                    <div [class]="hnaTitleStyle"
                        [ngbTooltip]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.Tooltip')">
                        {{rigidResultTitle}}
                    </div>
                    <div [class]="hnaTitleStyle"
                        [ngbTooltip]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.Tooltip')">
                        {{realisticResultTitle}}
                    </div>
                </div>

                <div class="table-row">
                    <div class="col-20 table-subtitle"
                        [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.TableRow.AnchorForces')"></div>
                    <div class="col-80 table-subtitle"></div>
                </div>

                <div class="table-row" *ngFor="let anchor of anchors; trackBy: trackAnchorByName">
                    <div class="col-20 align-center">{{anchor.name}}</div>
                    <div class="col-40 align-center">{{anchor.rigid}}</div>
                    <div class="col-40 align-center">{{anchor.realistic}}</div>
                </div>

                <div class="table-row" *ngIf="baseplatePlasticStrain != null">
                    <div class="col-20 table-subtitle"
                        [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.TableRow.BaseplatePlasticStrain')"></div>
                    <div class="col-40 align-center">{{baseplatePlasticStrain.rigid}}</div>
                    <div class="col-40 align-center" [class]="validatePlasticStrain"
                        [ngbTooltip]="plasticStrainTooltip">{{baseplatePlasticStrain.realistic}}</div>
                </div>

                <div class="table-row" *ngIf="!design.isHandrailCBFEMCalculation">
                    <div class="col-20 table-subtitle"
                        [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.TableRow.BaseplateDeformation')"></div>
                    <div class="col-40 align-center">{{baseplateDeformation?.rigid}}</div>
                    <div class="col-40 align-center">{{baseplateDeformation?.realistic}}</div>
                </div>
            </div>
        </div>

        <div class="agreement" *ngIf="!isSelectedCalculationConfirmed">
            <p class="text">{{calculationCompletedText}}</p>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        *ngIf="isSelectedCalculationConfirmed"
        [id]="'advanced-baseplate-calculation-close-button'"
        [look]="'Default'"
        class="modal-button"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.Button.Close')"
        [disabled]="isCalculationRunning">
    </pe-button>

    <pe-button
        *ngIf="!isSelectedCalculationConfirmed"
        [id]="'advanced-baseplate-calculation-cancel-button'"
        [look]="'Default'"
        class="modal-button"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.Button.Cancel')"
        [disabled]="isCalculationRunning">
    </pe-button>

    <pe-button
        *ngIf="!isSelectedCalculationConfirmed"
        [look]="'Cta'"
        class="modal-button"
        (buttonClick)="confirm()"
        [disabled]="isCalculationRunning">
        <pe-loading-button
            [loading]="isCalculationRunning"
            [text]="translate('Agito.Hilti.Profis3.AdvancedCalculationResults.Button.Confirm')">
        </pe-loading-button>
    </pe-button>
</pe-modal-footer>
