import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    TerrainTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class TerrainType extends CodeList {

    public static fromService(codeList: TerrainTypeEntity) {
        return new TerrainType(CodeList.baseFromService('TerrainTypeEntity', codeList, {
            image: codeList['Image'] != null && codeList['Image'] != '' ? codeList['Image'] : undefined,
        } as ICodeListConstructor));
    }

    public override cloneDeep(): TerrainType {
        return super.cloneDeep() as TerrainType;
    }
}
