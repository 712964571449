import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import {
    RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { DesignPe } from '../../../shared/entities/design-pe';

import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './building-type-customize-details.component.html',
    styleUrls: ['./building-type-customize-details.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BuildingTypeCustomizeDetailsComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public submitted = false;

    public isBridgeRadio!: RadioButtonProps<boolean>;

    public gammaPermanentSupTextBox!: NumericTextBoxProps;
    public gammaPermanentInfTextBox!: NumericTextBoxProps;

    public gammaVarHBuildingTextBox!: NumericTextBoxProps;
    public gammaVarVBuildingTextBox!: NumericTextBoxProps;
    public gammaVarHWindTextBox!: NumericTextBoxProps;

    public gammaVarHBridgeTextBox!: NumericTextBoxProps;
    public gammaVarVBridgeTextBox!: NumericTextBoxProps;
    public gammaVarHWindBridgeTextBox!: NumericTextBoxProps;

    public combiPsi0HTextBox!: NumericTextBoxProps;
    public combiPsi0VTextBox!: NumericTextBoxProps;
    public combiPsi0HWindTextBox!: NumericTextBoxProps;

    public requiredValidator = Validators.required;

    private pendingSave = false;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private calculationService: CalculationServicePE,
    ) { }

    public get formValid() {
        const isBridge = this.isBridgeRadio.selectedValue ?? false;

        return this.gammaPermanentSupTextBox.isValid
            && this.gammaPermanentInfTextBox.isValid
            && (
                isBridge
                && this.gammaVarHBuildingTextBox.isValid
                && this.gammaVarVBuildingTextBox.isValid
                && this.gammaVarHWindTextBox.isValid
                ||
                !isBridge
                && this.gammaVarHBridgeTextBox.isValid
                && this.gammaVarVBridgeTextBox.isValid
                && this.gammaVarHWindBridgeTextBox.isValid
            )
            && this.combiPsi0HTextBox.isValid
            && this.combiPsi0VTextBox.isValid
            && this.combiPsi0HWindTextBox.isValid;
    }

    private get design() {
        return this.userService.design;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        // set values
        this.isBridgeRadio = {
            items: [
                {
                    id: 'building-category-loads-radio-button-building',
                    text: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.BuildingLoads'),
                    value: false
                },
                {
                    id: 'building-category-loads-radio-button-bridge',
                    text: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.BridgeLoads'),
                    value: true
                }
            ],
            selectedValue: this.userService.design.isBridge
        };

        let valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_perm_sup.id);
        this.gammaPermanentSupTextBox = {
            id: 'building-type-customize-details-gamma-permanent-sup',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaPermanentSup'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaPermanentSup.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaPermanentSup
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_perm_inf.id);
        this.gammaPermanentInfTextBox = {
            id: 'building-type-customize-details-gamma-permanent-inf',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaPermanentInf'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaPermanentInf.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaPermanentInf
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_var_h_building.id);
        this.gammaVarHBuildingTextBox = {
            id: 'building-type-customize-details-gamma-varh-building',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHBuilding'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHBuilding.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaVarHBuilding
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_var_v_building.id);
        this.gammaVarVBuildingTextBox = {
            id: 'building-type-customize-details-gamma-varv-building',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarVBuilding'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarVBuilding.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaVarVBuilding
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_var_h_wind.id);
        this.gammaVarHWindTextBox = {
            id: 'building-type-customize-details-gamma-varh-wind',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHWind'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHWind.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaVarHWind
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_var_h_bridge.id);
        this.gammaVarHBridgeTextBox = {
            id: 'building-type-customize-details-gamma-varh-bridge',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHBridge'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHBridge.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaVarHBridge
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_var_v_bridge.id);
        this.gammaVarVBridgeTextBox = {
            id: 'building-type-customize-details-gamma-varv-bridge',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarVBridge'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarVBridge.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaVarVBridge
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Gamma_var_h_wind_bridge.id);
        this.gammaVarHWindBridgeTextBox = {
            id: 'building-type-customize-details-gamma-var-h-wind-bridge',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHWindBridge'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.GammaVarHWindBridge.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.gammaVarHWindBridge
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Combi_psi_0_h.id);
        this.combiPsi0HTextBox = {
            id: 'building-type-customize-details-combi-psi-0-h',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.CombiPsi0H'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.CombiPsi0H.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.combiPsi0H
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Combi_psi_0_v.id);
        this.combiPsi0VTextBox = {
            id: 'building-type-customize-details-combi-psi-0-v',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.CombiPsi0V'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.CombiPsi0V.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.combiPsi0V
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_Combi_psi_0_h_wind.id);
        this.combiPsi0HWindTextBox = {
            id: 'building-type-customize-details-combi-psi-0-h-wind',
            title: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.CombiPsi0HWind'),
            tooltip: this.translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.CombiPsi0HWind.Tooltip'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.combiPsi0HWind
        };
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.calculationService
            .calculateAsync(this.design,
                (design) => {
                    this.setDesignData(design);
                },
                { suppressLoadingFlag: true }
            )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    public close() {
        this.modalInstance.close();
    }

    private getPropertyValueRange(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        const retVal: IValueRange = {
            min: propertyInfo.min,
            max: propertyInfo.max
        };
        return retVal;
    }

    private setDesignData(design: DesignPe) {
        design.gammaPermanentSup = this.gammaPermanentSupTextBox.value ?? 0;
        design.gammaPermanentInf = this.gammaPermanentInfTextBox.value ?? 0;

        design.gammaVarHWindBridge = this.gammaVarHWindBridgeTextBox.value ?? 0;

        design.gammaVarHBuilding = this.gammaVarHBuildingTextBox.value ?? 0;
        design.gammaVarVBuilding = this.gammaVarVBuildingTextBox.value ?? 0;
        design.gammaVarHBridge = this.gammaVarHBridgeTextBox.value ?? 0;
        design.gammaVarVBridge = this.gammaVarVBridgeTextBox.value ?? 0;
        design.gammaVarHWind = this.gammaVarHWindTextBox.value ?? 0;

        design.combiPsi0H = this.combiPsi0HTextBox.value ?? 0;
        design.combiPsi0V = this.combiPsi0VTextBox.value ?? 0;
        design.combiPsi0HWind = this.combiPsi0HWindTextBox.value ?? 0;

        if (this.isBridgeRadio.selectedValue != design.isBridge) {
            design.isBridge = this.isBridgeRadio.selectedValue ?? false;
        }
    }
}
