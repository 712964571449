import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    CustomStiffenerEntity
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    StiffenerCrossSectionPart as PropertyStiffenerCrossSectionPart,
    StiffenerLocation as PropertyStiffenerLocation
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import {
    StiffenerCrossSectionPart, StiffenerLocation
} from '@profis-engineering/pe-gl-model/components/stiffener-helper';

import { StiffenerLayout } from '../../../../shared/entities/code-lists/stiffener-layout';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class StiffenerUpdate extends BaseUpdate {

    public static mapStiffenerLocation(value?: PropertyStiffenerLocation) {
        switch (value) {
            case PropertyStiffenerLocation.All:
                return StiffenerLocation.All;
            case PropertyStiffenerLocation.Center:
                return StiffenerLocation.Center;
            case PropertyStiffenerLocation.Front:
                return StiffenerLocation.Front;
            case PropertyStiffenerLocation.None:
                return StiffenerLocation.None;
            case PropertyStiffenerLocation.Rear:
                return StiffenerLocation.Rear;

            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown weld location.');
        }
    }

    public static mapStiffenerCrossSectionPart(value?: PropertyStiffenerCrossSectionPart) {
        switch (value) {
            case PropertyStiffenerCrossSectionPart.All:
                return StiffenerCrossSectionPart.All;
            case PropertyStiffenerCrossSectionPart.BottomFlange1:
                return StiffenerCrossSectionPart.BottomFlange1;
            case PropertyStiffenerCrossSectionPart.Flanges:
                return StiffenerCrossSectionPart.Flanges;
            case PropertyStiffenerCrossSectionPart.None:
                return StiffenerCrossSectionPart.None;
            case PropertyStiffenerCrossSectionPart.TopFlange1:
                return StiffenerCrossSectionPart.TopFlange1;
            case PropertyStiffenerCrossSectionPart.Web1:
                return StiffenerCrossSectionPart.Web1;
            case PropertyStiffenerCrossSectionPart.Web2:
                return StiffenerCrossSectionPart.Web2;
            case PropertyStiffenerCrossSectionPart.Webs:
                return StiffenerCrossSectionPart.Webs;

            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown weld location.');
        }
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private OnPropertiesChange(model: IModelPe, design: DesignPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.weldOnStiffeners = design.weldOnStiffeners ?? false;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerHeight)
    private AnchorPlate_StiffenerHeight(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.height = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerWidth)
    private AnchorPlate_StiffenerWidth(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.width = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerHorizontalEdgeLength)
    private AnchorPlate_StiffenerHorizontalEdgeLength(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.horizontalEdgeLength = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerVerticalEdgeLength)
    private AnchorPlate_StiffenerVerticalEdgeLength(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.verticalEdgeLength = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerThickness)
    private AnchorPlate_StiffenerThickness(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.thickness = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerShape)
    private AnchorPlate_StiffenerShape(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.shape = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerLayout)
    private AnchorPlate_StiffenerLayout(value: number, model: IModelPe, design: DesignPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        if (value != null && value != 0) {
            const codeList = design.designData.designCodeLists[DesignCodeList.StiffenerLayout] as StiffenerLayout[];
            const layout = codeList.find((layout) => layout.id == value);

            // update stiffener location and cross section part based on stiffener layout selected
            model.stiffener.location = StiffenerUpdate.mapStiffenerLocation(layout?.stiffenerLocation);
            model.stiffener.section = StiffenerUpdate.mapStiffenerCrossSectionPart(layout?.stiffenerCrossSectionPart);
            model.stiffener.stiffenerCount = layout?.stiffenerCount;
            model.stiffener.isCustomStiffener = false;
        }
        else if (value == 0) {
            model.stiffener.isCustomStiffener = true;
        }
        else {
            model.stiffener.location = model.stiffener.section = undefined;
        }

        model.stiffener.isStiffenerPresent = design.isStiffenerPresent;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerWeldThickness)
    private AnchorPlate_StiffenerWeldThickness(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.weldThickness = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerWeldLocation)
    private AnchorPlate_StiffenerWeldLocation(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.weldLocation = value;
    }

    @propertyValueChanged(UIProperty.AnchorPlate_StiffenerRadialOffset)
    private AnchorPlate_StiffenerRadialOffset(value: number, model: IModelPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.radialOffset = value;
    }

    @propertyValueChanged(UIProperty.Stiffener_CustomStiffener)
    private AnchorPlate_Stiffener_CustomStiffener(value: CustomStiffenerEntity[], model: IModelPe, design: DesignPe) {
        if (!model.stiffener) {
            throw new Error('model stiffener is undefined');
        }

        model.stiffener.customStiffeners = value;
        model.stiffener.isStiffenerPresent = design.isStiffenerPresent;
    }
}
