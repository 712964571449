import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, TrackByFunction, ViewChild, ViewEncapsulation } from '@angular/core';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import { cloneModel } from '@profis-engineering/gl-model/gl-model-helper';
import { DesignType, IModelPe, TextureDisplay } from '@profis-engineering/pe-gl-model/gl-model';

import {
    DropdownItem,
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { Context3dKey } from '@profis-engineering/pe-ui-common/entities/context-3d';
import {
    ModalInstance
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    formatKeyValue
} from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import stressCheckLegendAdvancedUlsImage from '../../../images/stress-check-legend-advanced-uls.png';
import stressCheckLegendAdvancedImage from '../../../images/stress-check-legend-advanced.png';
import {
    AdvancedBaseplateCalculationComponentInput
} from '../../../shared/components/advanced-baseplate-calculation-popup';
import {
    BaseplateDesignGLModelProps, IBaseplateDesignGLModelComponent
} from '../../../shared/components/gl-model';
import {
    ReportData
} from '../../../shared/entities/design-pe';
import {
    CalculationResultEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    BaseplateDesignDataEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    RegionHub
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    AdvancedCalculationType,
    DesignMethodGroup, DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    PropertyMetaData
} from '../../../shared/properties/properties';
import {
    areLoadCombinationsAvailable
} from '../../helpers/load-combination-helper';
import {
    CalculationServicePE
} from '../../services/calculation-pe.service';
import {
    LocalizationService
} from '../../services/localization.service';
import {
    ModalService
} from '../../services/modal.service';
import {
    NumberService
} from '../../services/number.service';
import {
    UnitService
} from '../../services/unit.service';
import {
    UserSettingsService
} from '../../services/user-settings.service';
import {
    UserService
} from '../../services/user.service';
import {
    includeSprites
} from '../../sprites';

interface IAnchorForcesComparison {
    name: string;
    realistic: string;
    rigid: string;
}

enum PlasticStrainLevel {
    Normal,
    Limit
}

@Component({
    templateUrl: './advanced-baseplate-calculation.component.html',
    styleUrls: ['./advanced-baseplate-calculation.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AdvancedBaseplateCalculationComponent implements OnInit, AfterViewInit {
    public anchors?: IAnchorForcesComparison[];
    public baseplateDeformation?: { realistic: string; rigid: string };
    public baseplatePlasticStrain?: { realistic: string; rigid: string };
    public maximumAnchorDeviation?: number;
    public loadCombinationDropdown!: Pick<DropdownProps<string>, 'items' | 'selectedValue'>;

    @Input()
    public modalInstance!: ModalInstance<AdvancedBaseplateCalculationComponentInput>;

    @ViewChild('glModelRigid')
    public glModelRigidElementRef!: ElementRef<IBaseplateDesignGLModelComponent>;

    @ViewChild('glModelRealistic')
    public glModelRealisticElementRef!: ElementRef<IBaseplateDesignGLModelComponent>;

    public glModelRigidProps: Pick<BaseplateDesignGLModelProps, 'context3dKey' | 'model' | 'onLoaded' | 'isHidden' | 'useBaseplateDesignComparisonData'> = {};
    public glModelRealisticProps: Pick<BaseplateDesignGLModelProps, 'context3dKey' | 'model' | 'onLoaded' | 'isHidden'> = {};

    public progress?: number;
    public statusText?: string;
    public isCalculationRunning = false;

    public selectedDisplayOption = TextureDisplay.Deformation;
    public textureDisplayEnum = {
        PlasticStrain: TextureDisplay.PlasticStrain,
        Deformation: TextureDisplay.Deformation,
        StressInConcrete: TextureDisplay.StressInConcrete,
    };

    private baseplateDesignData?: BaseplateDesignDataEntity;

    private calculationResults!: Record<string, CalculationResultEntity>;
    private firstInit = true;

    constructor(
        private localizationService: LocalizationService,
        private userService: UserService,
        private unitService: UnitService,
        private numberService: NumberService,
        private modalService: ModalService,
        private userSettingsService: UserSettingsService,
        private calculationService: CalculationServicePE,
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get isSelectedCalculationConfirmed() {
        return this.isCalculationConfirmed(this.loadCombinationDropdown.selectedValue);
    }

    public get design() {
        return this.userService.design;
    }

    public get plasticStrainTooltip() {
        if (this.plasticStrainLevel == PlasticStrainLevel.Limit) {
            return formatKeyValue(this.translate('Agito.Hilti.Profis3.AdvancedCalculationResults.TableRow.LimitPlasticStrainExceed'), {
                limitPlasticStrainPercentage: this.unitService.formatInternalValueAsDefault(this.limitPlasticStrain ?? 0, UnitGroup.Percentage)
            });
        }

        return null;
    }

    public get validatePlasticStrain() {
        if (this.plasticStrainLevel == PlasticStrainLevel.Limit) {
            return 'red';
        }

        return null;
    }

    public get isLoadCombinationDropdownVisible() {
        const reportData = this.design?.designData?.reportData ?? null as unknown as ReportData;
        return areLoadCombinationsAvailable(reportData)
            && !this.isLoadCharacteristicSet;   // Load Characteristic is incompatible with Load Combinations.
    }

    public get initialDesignReportData() {
        return this.modalInstance.input?.designReportData;
    }

    public get shortDisclaimer() {
        return this.translate(`Agito.Hilti.Profis3.AdvancedCalculationResults.DoneCalculation.ShortDisclaimer${this.translationKeySuffix(true)}`);
    }

    public get loadCombinationTooltip() {
        if (this.initialDesignReportData?.BaseplateDesignData?.SelectedLoadCombiantionId != null) {
            return this.translate('Agito.Hilti.Profis3.AdvancedCalculationResults.LoadCombination.Tooltip.Disabled');
        }

        return null;
    }

    public get calculationCompletedText() {
        const plateThickness = this.design?.designData?.projectDesign?.AnchorPlate?.Thickness ?? 0;
        const calculationString = this.translate(`Agito.Hilti.Profis3.AdvancedCalculationResults.DoneCalculation.Agreement${this.translationKeySuffix(false)}`);
        return formatKeyValue(calculationString, { thickness: this.unitService.formatInternalValueAsDefault(plateThickness, UnitGroup.Length) });
    }

    public get isStressCheckVisible() {
        return this.baseplateDesignImageRealistic != null;
    }

    public get stressCheckLegend() {
        return this.useULSLegend
            ? stressCheckLegendAdvancedUlsImage
            : stressCheckLegendAdvancedImage;
    }

    public get legendMaxTopOffset() {
        return this.useULSLegend ? 23 : 0;
    }

    public get stressCheckLegendMax() {
        if (this.baseplateDesignImageRealistic == null) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(this.baseplateDesignImageUnitGroup);
        const internalUnit = this.unitService.getInternalUnit(this.baseplateDesignImageUnitGroup);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(this.baseplateDesignImageRealistic.LegendMaxValue || 0, internalUnit, defaultUnit), defaultUnit);
    }

    public get stressCheckLegendMin() {
        if (this.baseplateDesignImageRealistic == null) {
            return null;
        }

        const defaultUnit = this.unitService.getDefaultUnit(this.baseplateDesignImageUnitGroup);
        const internalUnit = this.unitService.getInternalUnit(this.baseplateDesignImageUnitGroup);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(this.baseplateDesignImageRealistic.LegendMinValue || 0, internalUnit, defaultUnit), defaultUnit);
    }

    // HNA has different titles than other areas.
    public get realisticResultTitle() {
        const translation = 'Agito.Hilti.Profis3.AdvancedCalculationResults.TableCol.RealisticCalculation';
        if (this.isHna) {
            return this.translate(translation + '.HNA');
        }

        return this.translate(translation);
    }

    public get rigidResultTitle() {
        const translation = 'Agito.Hilti.Profis3.AdvancedCalculationResults.TableCol.RigidBaseplateAssumption';
        if (this.isHna) {
            return this.translate(translation + '.HNA');
        }

        return this.translate(translation);
    }

    public get hnaTitleStyle() {
        if (this.isHna) {
            return 'col-50';
        }

        return 'col-40';
    }

    private get isHna() {
        return this.design?.region?.hubId == RegionHub.W1_HNA;
    }

    private get limitPlasticStrain() {
        if (this.design?.designData?.projectDesign?.Options.AdvancedBaseplateCalculation?.LimitPlasticStrain != null) {
            return this.design.designData.projectDesign.Options.AdvancedBaseplateCalculation.LimitPlasticStrain;
        }

        return PropertyMetaData.AdvancedBaseplateCalculation_LimitPlasticStrain.defaultValue;
    }

    private get plasticStrain() {
        if (this.baseplateDesignData != null && this.baseplateDesignData.AnchorPlateUtilizations != null) {
            return this.numberService.round(this.baseplateDesignData.AnchorPlateUtilizations.UtilizationPlasticStrain.MaxStrain, this.unitService.getPrecision(this.unitService.getInternalUnit(UnitGroup.Percentage)));
        }

        return undefined;
    }

    private get plasticStrainLevel() {
        if (this.isHna) {
            const plasticStrain = this.plasticStrain ?? 0;
            const limitPlasticStrain = this.limitPlasticStrain ?? 0;

            if (plasticStrain >= limitPlasticStrain) {
                return PlasticStrainLevel.Limit;
            }
        }

        return PlasticStrainLevel.Normal;
    }

    private get isLoadCharacteristicSet() {
        return this.design.loadCombinations.some((load) => load.LoadCharacteristic != null);
    }

    private get glModelRigid() {
        return this.glModelRigidElementRef.nativeElement;
    }

    private get glModelRealistic() {
        return this.glModelRealisticElementRef.nativeElement;
    }

    private get baseplateDesignImageRealistic() {
        if (this.baseplateDesignData == null) {
            return null;
        }

        switch (this.selectedDisplayOption) {
            case TextureDisplay.Deformation:
                return this.baseplateDesignData.DisplacementMesh;
            case TextureDisplay.StressInConcrete:
                return this.baseplateDesignData.ConcreteStressMesh;
            case TextureDisplay.PlasticStrain:
                return this.baseplateDesignData.StrainMesh;

            default:
                throw new Error('Unsupported baseplate design display option.');
        }
    }

    private get useULSLegend() {
        const textureDisplay = this.selectedDisplayOption;
        return this.design.useULSStresses && (textureDisplay == TextureDisplay.EquivalentStress || textureDisplay == TextureDisplay.PlasticStrain);
    }

    private get baseplateDesignImageUnitGroup() {
        switch (this.selectedDisplayOption) {
            case TextureDisplay.Deformation: return UnitGroup.Length;
            case TextureDisplay.StressInConcrete: return UnitGroup.Stress;
            case TextureDisplay.PlasticStrain: return UnitGroup.Percentage;
            default: throw new Error('Unsupported baseplate design display option.');
        }
    }

    public ngOnInit(): void {
        // don't close the modal if calculation is running
        this.modalInstance.setOnClosing(() => {
            return this.isCalculationRunning
                ? false
                : true;
        });

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-center'
        );

        this.progress = 0;
        this.statusText = '';
        this.calculationResults = {};
        this.selectedDisplayOption = this.design.isHandrailCBFEMCalculation
            ? TextureDisplay.PlasticStrain
            : TextureDisplay.Deformation;

        if (this.design?.designData?.pendingCalculationResult != null) { // no need to recalculate pending result, so add it here if it exists
            this.calculationResults[this.design.designData.pendingCalculationResult.DesignReportDataClient.BaseplateDesignData?.SelectedLoadCombiantionId ?? 'decisive'] = this.design.designData.pendingCalculationResult;
        }

        const isLcDecisive = (lc: LoadCombination) => {
            if (this.initialDesignReportData?.BaseplateDesignData?.SelectedLoadCombiantionId != null) { // no lc is not decisive if user manually selects one lc
                return false;
            }

            return this.isLcDecisive(this.design.loadCombinations, lc.Id, this.initialDesignReportData?.DecisiveLoadCombinationIndex)
                || this.isLcDecisive(this.design.loadCombinations, lc.Id, this.initialDesignReportData?.DecisiveLoadCombinationIndexHandrail)
                || this.isLcDecisive(this.design.loadCombinations, lc.Id, this.initialDesignReportData?.DecisiveLoadCombinationIndexAnchorPlate)
                || this.isLcDecisive(this.design.loadCombinations, lc.Id, this.initialDesignReportData?.DecisiveLoadCombinationIndexProfile)
                || this.isLcDecisive(this.design.loadCombinations, lc.Id, this.initialDesignReportData?.DecisiveLoadCombinationIndexBaseMaterial)
                || this.isLcDecisive(this.design.loadCombinations, lc.Id, this.initialDesignReportData?.DecisiveLoadCombinationIndexStiffeners)
                || this.isLcDecisive(this.design.loadCombinations, lc.Id, this.initialDesignReportData?.DecisiveLoadCombinationIndexWelds);
        };

        this.loadCombinationDropdown = {
            items: [],
            selectedValue: this.initialDesignReportData?.BaseplateDesignData?.SelectedLoadCombiantionId ?? 'decisive'
        };
        if (this.isLoadCombinationDropdownVisible) {
            this.loadCombinationDropdown.items = [
                // add null as decisive
                {
                    value: 'decisive',
                    text: this.translate('Agito.Hilti.Profis3.AdvancedCalculationResults.LoadCombination.None')
                },
                // add list of load combinations
                ...(this.design.loadCombinations ?? []).map((loadCombination): DropdownItem<string> => ({
                    value: loadCombination.Id,
                    text: (isLcDecisive(loadCombination) ? '* ' : '') + loadCombination.Name
                })),
            ];
        }
    }

    public ngAfterViewInit(): void {
        this.initGLModel();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close(result?: string) {
        return this.modalInstance.close(result);
    }

    public confirm() {
        const maximumAnchorDeviation = this.maximumAnchorDeviation ?? 0;
        const plasticStrain = this.plasticStrain ?? 0;

        if (this.isHna && (this.plasticStrainLevel != PlasticStrainLevel.Normal || Math.abs(maximumAnchorDeviation) >= 20)) {
            if (this.maximumAnchorDeviation == 0) {
                const message = this.translate('Agito.Hilti.Profis3.SelectMaterial.AdvancedCalculationPopup.Message.HNA.NoDeviationPercentage');

                this.openConfirmCalculationPopup(formatKeyValue(message, {
                    plasticStrainPercentage: this.unitService.formatInternalValueAsDefault(plasticStrain, UnitGroup.Percentage)
                }));
            }
            else {
                const message = this.translate('Agito.Hilti.Profis3.SelectMaterial.AdvancedCalculationPopup.Message.HNA');

                this.openConfirmCalculationPopup(formatKeyValue(message, {
                    plasticStrainPercentage: this.unitService.formatInternalValueAsDefault(plasticStrain, UnitGroup.Percentage),
                    deviationPercentage: (this.numberService.format(maximumAnchorDeviation, 0) + '%')
                }));
            }

            return;
        }

        if (Math.abs(maximumAnchorDeviation) >= 20) {
            const message = this.translate('Agito.Hilti.Profis3.SelectMaterial.AdvancedCalculationPopup.Message.ETA');

            this.openConfirmCalculationPopup(formatKeyValue(message, {
                deviationPercentage: (this.numberService.format(maximumAnchorDeviation, 0) + '%')
            }));
        }
        else {
            const selectedLoad = this.loadCombinationDropdown.selectedValue;
            const calculationResults = selectedLoad != null
                ? this.calculationResults[selectedLoad]
                : null as any as CalculationResultEntity;
            this.calculationService.confirmCalculationResult(this.design, calculationResults);
            this.design.reloadState();
            return this.close('confirmedRealisticCalculation');
        }
    }

    public openMoreDetailsPopup() {
        this.modalService.openConfirmChange({
            id: 'tooltip-popup',
            title: this.translate('Agito.Hilti.Profis3.AdvancedCalculationResults.DoneCalculation.DetailedDisclaimer.Tooltip.Title'),
            message: this.translate('Agito.Hilti.Profis3.AdvancedCalculationResults.DoneCalculation.DetailedDisclaimer.Tooltip'),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.ControlTooltip.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    public async onSelectedLoadCombinationChange(loadCombinationId: string): Promise<void> {
        const oldValue = this.loadCombinationDropdown.selectedValue;
        this.loadCombinationDropdown.selectedValue = loadCombinationId;

        let data: BaseplateDesignDataEntity | undefined;
        if (this.isCalculationConfirmed(loadCombinationId)) {
            data = this.design.baseplateDesignData;
        }
        else if (this.calculationResults[loadCombinationId] != null) {
            data = this.calculationResults[loadCombinationId].DesignReportDataClient.BaseplateDesignData;
        }
        else {
            this.isCalculationRunning = true;

            try {
                const result = await this.calculationService.performAdvancedBaseplateCalculation(this.design, loadCombinationId);
                this.calculationResults[loadCombinationId] = result;

                data = result.DesignReportDataClient.BaseplateDesignData;
            }
            catch (error) {
                // revert select to old value on calculation fail
                if (this.loadCombinationDropdown.selectedValue !== oldValue) {
                    // The value is changed inside the same cycle so change detection
                    // needs to be run again before the new change
                    this.changeDetectorRef.detectChanges();
                    this.loadCombinationDropdown.selectedValue = oldValue;
                }

                throw error;
            }
            finally {
                this.isCalculationRunning = false;
            }
        }

        this.setBaseplateDesignData(data);

        this.glModelRigid.update({ textureDisplay: this.selectedDisplayOption });
        this.glModelRealistic.update({ textureDisplay: this.selectedDisplayOption });
    }

    public resetCamera() {
        this.glModelRigid.resetCamera();
        this.glModelRealistic.resetCamera();
    }

    public getDisplayOptionButtonLook(displayOption: TextureDisplay) {
        return displayOption == this.selectedDisplayOption ? 'Primary' : 'Default';
    }

    public isDisplayOptionButtonVisible(displayOption: TextureDisplay) {
        switch (displayOption) {
            case TextureDisplay.PlasticStrain:
                return this.design.calculationType != AdvancedCalculationType.BPRigidityCheck;

            case TextureDisplay.Deformation:
                return !this.design.isHandrailCBFEMCalculation;

            case TextureDisplay.StressInConcrete:
                return !this.design.isHandrailCBFEMCalculation;
        }

        return false;
    }

    public selectDisplayOption(displayOption: TextureDisplay) {
        this.selectedDisplayOption = displayOption;

        this.glModelRigid.update({ textureDisplay: displayOption });
        this.glModelRealistic.update({ textureDisplay: displayOption });
    }

    public trackAnchorByName: TrackByFunction<IAnchorForcesComparison> = function (_: number, item: IAnchorForcesComparison) {
        return item.name;
    };


    private isLcDecisive(loadCombinations: LoadCombination[], loadCombinationId: string, loadCombinationIndex?: number) {
        return loadCombinationIndex != null
            && loadCombinationId == loadCombinations[loadCombinationIndex].Id;
    }

    private isCalculationConfirmed(loadCombinationId?: string) {
        return this.design.baseplateDesignData != null
            && (this.design.baseplateDesignData.SelectedLoadCombiantionId ?? 'decisive') == loadCombinationId;
    }

    private openConfirmCalculationPopup(message: string) {
        return this.modalService.openConfirmChange({
            id: 'advanced-calculation-maximum-anchor-deviation-popup',
            title: this.translate('Agito.Hilti.Profis3.SelectMaterial.AdvancedCalculationPopup.Title'),
            message,
            confirmButtonText: this.translate('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Confirm'),
            onConfirm: (modal) => {
                modal.close();
                const selectedLoad = this.loadCombinationDropdown.selectedValue;
                const calculationResults = selectedLoad != null
                    ? this.calculationResults[selectedLoad]
                    : null as any as CalculationResultEntity;
                this.calculationService.confirmCalculationResult(this.design, calculationResults);
                this.design.reloadState();
                return this.close('confirmedRealisticCalculation');
            }
        });
    }

    private translationKeySuffix(shortDisclaimerSuffix: boolean) {
        if (this.isHna) {
            return '.HNA';
        }

        if (this.design.designStandard.id == DesignStandard.SATS) {
            return '.SATS';
        }

        if (this.design.designStandard.id == DesignStandard.STO) {
            return shortDisclaimerSuffix && this.design.designMethodGroup?.id == DesignMethodGroup.SP63 ? '.SP63' : '.STO';
        }

        return '.ETA';
    }

    private createAnchorsObject(data?: BaseplateDesignDataEntity): IAnchorForcesComparison[] {
        if (data == null || data.AnchorForces.length != data.ComparisonData.AnchorForces.length) {
            return [];
        }

        this.maximumAnchorDeviation = 0;
        const anchorString = this.translate('Agito.Hilti.Profis3.AdvancedCalculationResults.AnchorIndex');
        const anchors: IAnchorForcesComparison[] = [];

        // If default unit is set to kN or Kip then use 1 decimal place, else 0
        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Force);
        const decimals = defaultUnit == Unit.kN || defaultUnit == Unit.Kip ? 1 : 0;

        for (let i = 0; i < data.AnchorForces.length; i++) {
            const deviation = this.calculateDeviation(data.AnchorForces[i].N, data.ComparisonData.AnchorForces[i].N, decimals);
            if (deviation != null && Math.abs(this.maximumAnchorDeviation) < Math.abs(deviation)) {
                this.maximumAnchorDeviation = this.numberService.round(deviation, 0);
            }

            const deviationPercentage = deviation == null
                ? '-%'
                : this.unitService.formatInternalValueAsDefault(deviation, UnitGroup.Percentage, 0);

            anchors.push({
                name: formatKeyValue(anchorString, { index: (i + 1) + '' }),
                realistic: this.unitService.formatInternalValueAsDefault(data.AnchorForces[i].N, UnitGroup.Force, decimals) + ' (' + deviationPercentage + ')',
                rigid: this.unitService.formatInternalValueAsDefault(data.ComparisonData.AnchorForces[i].N, UnitGroup.Force, decimals)
            });

        }
        return anchors;
    }

    private calculateDeviation(anchorForce: number, anchorForceComparison: number, decimals: number) {
        if (anchorForce < 1000 && anchorForceComparison < 1000) {
            return undefined;
        }

        const anchorForceConverted = this.numberService.round(this.unitService.convertInternalValueToDefaultValue(anchorForce, UnitGroup.Force), decimals);
        const anchorForceComparisonConverted = this.numberService.round(this.unitService.convertInternalValueToDefaultValue(anchorForceComparison, UnitGroup.Force), decimals);

        if (anchorForceComparisonConverted == 0) {
            if (anchorForceConverted >= 1) {
                return Number.POSITIVE_INFINITY;
            }

            if (anchorForceConverted <= -1) {
                return Number.NEGATIVE_INFINITY;
            }

            return 0;
        }

        return 100 * (anchorForceConverted / anchorForceComparisonConverted - 1);
    }

    private initGLModel(): void {
        const initialModel: IModelPe = {
            textureDisplay: this.selectedDisplayOption,
            meshVisible: this.userSettingsService.settings.applicationModelDisplayOptions.mesh.value ?? false,
            anchor: {
                anchorNumberingVisible: true
            },
            profile: {
                useTallProfile: true
            }
        };

        if (this.design.designType.id == DesignType.Handrail) {
            initialModel.handrail = {
                hideCladding: true,
                hideRail: true,
                showOnlyActiveBeam: true
            };
            initialModel.handrailBaseMaterial = {
                hideBaseMaterial: true,
                stairHeight: this.design.stairHeight,
                stairWidth: this.design.stairWidth
            };
        }

        let glModelRigidLoaded = false;
        let glModelRealisticLoaded = false;

        const onLoad = (component: IBaseplateDesignGLModelComponent) => () => {
            component.propertyValueChanged(null as unknown as Change[], this.design, Update.ServerAndClient);

            setTimeout(() => {
                component.resize();

                setTimeout(() => {
                    component.zoomToFit();
                    component.renderNextFrame();

                    glModelRigidLoaded = glModelRigidLoaded || component === this.glModelRigid;
                    glModelRealisticLoaded = glModelRealisticLoaded || component === this.glModelRealistic;

                    if (glModelRigidLoaded && glModelRealisticLoaded) {
                        this.glModelRigidProps.isHidden = false;
                        this.glModelRealisticProps.isHidden = false;
                    }

                    if (this.firstInit) {
                        this.setBaseplateDesignData(this.initialDesignReportData?.BaseplateDesignData);
                        this.glModelRigid.update({ textureDisplay: this.selectedDisplayOption });
                        this.glModelRealistic.update({ textureDisplay: this.selectedDisplayOption });
                        this.changeDetectorRef.detectChanges();
                        this.firstInit = false;
                    }
                });
            });
        };

        this.glModelRigidProps = {
            context3dKey: Context3dKey.Rigid,
            useBaseplateDesignComparisonData: true,
            model: cloneModel(initialModel),
            isHidden: true,
            onLoaded: onLoad(this.glModelRigid)
        };

        this.glModelRealisticProps = {
            context3dKey: Context3dKey.Flex,
            model: cloneModel(initialModel),
            isHidden: true,
            onLoaded: onLoad(this.glModelRealistic)
        };

        this.changeDetectorRef.detectChanges();
    }

    private setBaseplateDesignData(data?: BaseplateDesignDataEntity) {
        this.baseplateDesignData = data;
        this.anchors = this.createAnchorsObject(data);
        this.baseplateDeformation = {
            realistic: this.unitService.formatInternalValueAsDefault(data?.MaxDeformation ?? 0, UnitGroup.Length),
            rigid: this.unitService.formatInternalValueAsDefault(data?.ComparisonData?.MaxDeformation ?? 0, UnitGroup.Length)
        };
        this.baseplatePlasticStrain = data?.AnchorPlateUtilizations == null
            ? undefined
            : {
                realistic: this.unitService.formatInternalValueAsDefault(data.AnchorPlateUtilizations.UtilizationPlasticStrain.MaxStrain, UnitGroup.Percentage),
                rigid: this.translate('Agito.Hilti.Profis3.AdvancedCalculationResults.BaseplatePlasticStrain.None')
            };

        if (data != null) {
            this.glModelRealistic.updateBaseplateData(data);
            this.glModelRigid.updateBaseplateData(data);
        }
    }
}
