import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { CodeList } from '../entities/code-lists/code-list';
import { Region } from '../entities/code-lists/region';
import { SteelGuideline as SteelGuidelineEntity } from '../entities/code-lists/steel-guideline';
import {
    ProjectCodeList
} from '../enums/project-code-list';
import { DesignStandard as DesignStandardEnum, SteelGuideline } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export function getAllowedSteelGuidelines(projectCodeLists: { [codeList: number]: CodeList[] }, designStandardId: number | undefined, regionId: number) {
    return (projectCodeLists[ProjectCodeList.SteelGuidelines] as SteelGuidelineEntity[])
        .filter((sg) =>
            sg.allowedDesignStandardRegions?.some(x => x.designStandardId == designStandardId && (x.regionId == null || x.regionId == regionId))
        );
}

export function findSteelCalculationMethod(projectCodeLists: { [codeList: number]: CodeList[] }, designStandardId: number | undefined, region: Region) {
    const allowedSteelGuideLines = getAllowedSteelGuidelines(projectCodeLists, designStandardId, region?.id ?? SpecialRegion.Default);

    let steelGuideline = SteelGuideline.None;
    if (allowedSteelGuideLines.length > 0) {
        if ((designStandardId == DesignStandardEnum.EC2 || designStandardId == DesignStandardEnum.MS) && allowedSteelGuideLines.some(x => x.id == region.defaultEurocodeDesignStandardSteelGuideLine)) {
            steelGuideline = region.defaultEurocodeDesignStandardSteelGuideLine as SteelGuideline;
        }
        else if (region.defaultSteelGuideline != null && allowedSteelGuideLines.some(x => x.id == region.defaultSteelGuideline)) {
            steelGuideline = region.defaultSteelGuideline as SteelGuideline;
        }
        else {
            steelGuideline = allowedSteelGuideLines[0].id as SteelGuideline;
        }
    }

    return steelGuideline;
}
