import { EmbedmentTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class EmbedmentType extends CodeList {
    public static fromService(codeList: EmbedmentTypeEntity) {
        return new EmbedmentType(CodeList.baseFromService('EmbedmentTypeEntity', codeList));
    }

    public override cloneDeep(): EmbedmentType {
        return super.cloneDeep() as EmbedmentType;
    }
}
