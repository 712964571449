import {
    TrackingUsageCounter as TrackingUsageCounterBase
} from '@profis-engineering/pe-ui-common/entities/tracking-usage-counter';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';

import { UsageCounter } from '../generated-modules/Hilti.PE.Core.Tracking.Entities';

export class TrackingUsageCounterPe extends TrackingUsageCounterBase {
    private adjustAnchorSpacing!: number;
    private adjustBaseMaterialThickness!: number;
    private adjustEdgeDistance!: number;
    private adjustPlatePosition!: number;
    private adjustPlateSize!: number;
    private anchorChangedInInfoSection!: number;
    private anchorOffsetOptimization!: number;
    private anchorPlateShowOptimizedAnchorPlateThickness!: number;
    private anchorPlateShowStressDistribution!: number;
    private basePlateHoleDiameterFilter!: number;
    private basePlateThicknessFilter!: number;
    private calculateAdvancedBaseplate!: number;
    private calculateAll!: number;
    private calculateThickness!: number;
    private designExportedToCad!: number;
    private designExportedToIntegrationServices!: number;
    private dlubalImport!: number;
    private dlubalExport!: number;
    private sap2000Import!: number;
    private robotImport!: number;
    private eTabsImport!: number;
    private staadProImport!: number;
    private ramImport!: number;
    private downloadSourceFile!: number;
    private ideaFileExport!: number;
    private lcWizardUsed!: number;
    private optimizeAnchorLayout!: number;
    private optimizeBaseplateSize!: number;
    private postDistanceOptimization!: number;
    private reportCreatedWithBaseplateDesign!: number;
    private reportCreatedWithHandrailDesign!: number;
    private reportCreatedWithWeldDesign!: number;
    private smartAnchorInfoClicked!: number;
    private smartAnchorSeeMoreClicked!: number;
    private smartAnchorSuggestedAnchorSelected!: number;
    private _SmartAnchor_ToggleChanged: number;
    private _SmartAnchor_AdvancedFiltersClicked: number;
    private _SmartAnchorSearchSolutionsClicked!: number;
    private _SmartAnchorSearchSolutionsSortClicked!: number;
    private _SmartAnchorSearchSolutionsPreferenceClicked!: number;
    private _SmartAnchorSolutionsFound!: number;
    private _SmartAnchorSolutionsTotalTime!: number;
    private _SmartAnchor_PreferredAnchorClicked!: number;
    private _SmartAnchor_SortBy!: string;
    private _SmartAnchor_SolutionClicked!: number;
    private _SmartAnchor_FilteredSolutions!: number;
    private _SmartAnchor_FirstSolutionTime!: number;
    private generalClicks!: number;
    private calculationsTriggered!: number;
    private _LearnMore_AdvancedCalculationTypesClicked!: number;
    private _LearnMore_SOFAClicked!: number;
    private _LearnMore_FireClicked!: number;
    private _CalculationDetailedOutput!: number;

    constructor(logger: LoggerServiceBase) {
        super(logger);
        this.resetCounter();

        this._SmartAnchor_AdvancedFiltersClicked = 0;
        this._SmartAnchor_ToggleChanged = 0;
    }


    public get AdjustAnchorSpacing(): number {
        return this.adjustAnchorSpacing;
    }
    public set AdjustAnchorSpacing(value: number) {
        this.logSimplePropertyChange('AdjustAnchorSpacing', this.adjustAnchorSpacing, value);
        this.adjustAnchorSpacing = value;
    }

    public get AdjustBaseMaterialThickness(): number {
        return this.adjustBaseMaterialThickness;
    }
    public set AdjustBaseMaterialThickness(value: number) {
        this.logSimplePropertyChange('AdjustBaseMaterialThickness', this.adjustBaseMaterialThickness, value);
        this.adjustBaseMaterialThickness = value;
    }

    public get AdjustEdgeDistance(): number {
        return this.adjustEdgeDistance;
    }
    public set AdjustEdgeDistance(value: number) {
        this.logSimplePropertyChange('AdjustEdgeDistance', this.adjustEdgeDistance, value);
        this.adjustEdgeDistance = value;
    }

    public get AdjustPlatePosition(): number {
        return this.adjustPlatePosition;
    }
    public set AdjustPlatePosition(value: number) {
        this.logSimplePropertyChange('AdjustPlatePosition', this.adjustPlatePosition, value);
        this.adjustPlatePosition = value;
    }

    public get AdjustPlateSize(): number {
        return this.adjustPlateSize;
    }
    public set AdjustPlateSize(value: number) {
        this.logSimplePropertyChange('AdjustPlateSize', this.adjustPlateSize, value);
        this.adjustPlateSize = value;
    }

    public get AnchorChangedInInfoSection(): number {
        return this.anchorChangedInInfoSection;
    }
    public set AnchorChangedInInfoSection(value: number) {
        this.logSimplePropertyChange('AnchorChangedInInfoSection', this.anchorChangedInInfoSection, value);
        this.anchorChangedInInfoSection = value;
    }

    public get AnchorOffset_Optimization(): number {
        return this.anchorOffsetOptimization;
    }
    public set AnchorOffset_Optimization(value: number) {
        this.logSimplePropertyChange('AnchorOffset_Optimization', this.anchorOffsetOptimization, value);
        this.anchorOffsetOptimization = value;
    }

    public get AnchorPlate_ShowOptimizedAnchorPlateThickness(): number {
        return this.anchorPlateShowOptimizedAnchorPlateThickness;
    }
    public set AnchorPlate_ShowOptimizedAnchorPlateThickness(value: number) {
        this.logSimplePropertyChange('AnchorPlate_ShowOptimizedAnchorPlateThickness', this.anchorPlateShowOptimizedAnchorPlateThickness, value);
        this.anchorPlateShowOptimizedAnchorPlateThickness = value;
    }

    public get AnchorPlate_ShowStressDistribution(): number {
        return this.anchorPlateShowStressDistribution;
    }
    public set AnchorPlate_ShowStressDistribution(value: number) {
        this.logSimplePropertyChange('AnchorPlate_ShowStressDistribution', this.anchorPlateShowStressDistribution, value);
        this.anchorPlateShowStressDistribution = value;
    }

    public get BasePlateHoleDiameterFilter(): number {
        return this.basePlateHoleDiameterFilter;
    }
    public set BasePlateHoleDiameterFilter(value: number) {
        this.logSimplePropertyChange('BasePlateHoleDiameterFilter', this.basePlateHoleDiameterFilter, value);
        this.basePlateHoleDiameterFilter = value;
    }

    public get BasePlateThicknessFilter(): number {
        return this.basePlateThicknessFilter;
    }
    public set BasePlateThicknessFilter(value: number) {
        this.logSimplePropertyChange('BasePlateThicknessFilter', this.basePlateThicknessFilter, value);
        this.basePlateThicknessFilter = value;
    }

    public get CalculateAdvancedBaseplate(): number {
        return this.calculateAdvancedBaseplate;
    }
    public set CalculateAdvancedBaseplate(value: number) {
        this.logSimplePropertyChange('CalculateAdvancedBaseplate', this.calculateAdvancedBaseplate, value);
        this.calculateAdvancedBaseplate = value;
    }

    public get CalculateAll(): number {
        return this.calculateAll;
    }
    public set CalculateAll(value: number) {
        this.logSimplePropertyChange('CalculateAll', this.calculateAll, value);
        this.calculateAll = value;
    }

    public get CalculateThickness(): number {
        return this.calculateThickness;
    }
    public set CalculateThickness(value: number) {
        this.logSimplePropertyChange('CalculateThickness', this.calculateThickness, value);
        this.calculateThickness = value;
    }

    public get DesignExportedToCAD(): number {
        return this.designExportedToCad;
    }
    public set DesignExportedToCAD(value: number) {
        this.logSimplePropertyChange('DesignExportedToCAD', this.designExportedToCad, value);
        this.designExportedToCad = value;
    }

    public get DesignExportedToIntegrationServices(): number {
        return this.designExportedToIntegrationServices;
    }
    public set DesignExportedToIntegrationServices(value: number) {
        this.logSimplePropertyChange('DesignExportedToIntegrationServices', this.designExportedToIntegrationServices, value);
        this.designExportedToIntegrationServices = value;
    }

    public get DlubalImport(): number {
        return this.dlubalImport;
    }
    public set DlubalImport(value: number) {
        this.logSimplePropertyChange('DlubalImport', this.dlubalImport, value);
        this.dlubalImport = value;
    }

    public get DlubalExport(): number {
        return this.dlubalExport;
    }
    public set DlubalExport(value: number) {
        this.logSimplePropertyChange('DlubalExport', this.dlubalExport, value);
        this.dlubalExport = value;
    }

    public get SAP2000Import(): number {
        return this.sap2000Import;
    }
    public set SAP2000Import(value: number) {
        this.logSimplePropertyChange('SAP2000Import', this.sap2000Import, value);
        this.sap2000Import = value;
    }

    public get RobotImport(): number {
        return this.robotImport;
    }
    public set RobotImport(value: number) {
        this.logSimplePropertyChange('RobotImport', this.robotImport, value);
        this.robotImport = value;
    }

    public get EtabsImport(): number {
        return this.eTabsImport;
    }
    public set EtabsImport(value: number) {
        this.logSimplePropertyChange('EtabsImport', this.eTabsImport, value);
        this.eTabsImport = value;
    }

    public get StaadProImport(): number {
        return this.staadProImport;
    }
    public set StaadProImport(value: number) {
        this.logSimplePropertyChange('StaadProImport', this.staadProImport, value);
        this.staadProImport = value;
    }

    public get RamImport(): number {
        return this.ramImport;
    }
    public set RamImport(value: number) {
        this.logSimplePropertyChange('RamImport', this.ramImport, value);
        this.ramImport = value;
    }

    public get DownloadSourceFile(): number {
        return this.downloadSourceFile;
    }
    public set DownloadSourceFile(value: number) {
        this.logSimplePropertyChange('DownloadSourceFile', this.downloadSourceFile, value);
        this.downloadSourceFile = value;
    }

    public get IdeaFileExport(): number {
        return this.ideaFileExport;
    }
    public set IdeaFileExport(value: number) {
        this.logSimplePropertyChange('IdeaFileExport', this.ideaFileExport, value);
        this.ideaFileExport = value;
    }

    public get LCWizardUsed(): number {
        return this.lcWizardUsed;
    }
    public set LCWizardUsed(value: number) {
        this.logSimplePropertyChange('LCWizardUsed', this.lcWizardUsed, value);
        this.lcWizardUsed = value;
    }

    public get OptimizeAnchorLayout(): number {
        return this.optimizeAnchorLayout;
    }
    public set OptimizeAnchorLayout(value: number) {
        this.logSimplePropertyChange('OptimizeAnchorLayout', this.optimizeAnchorLayout, value);
        this.optimizeAnchorLayout = value;
    }

    public get OptimizeBaseplateSize(): number {
        return this.optimizeBaseplateSize;
    }
    public set OptimizeBaseplateSize(value: number) {
        this.logSimplePropertyChange('OptimizeBaseplateSize', this.optimizeBaseplateSize, value);
        this.optimizeBaseplateSize = value;
    }

    public get PostDistance_Optimization(): number {
        return this.postDistanceOptimization;
    }
    public set PostDistance_Optimization(value: number) {
        this.logSimplePropertyChange('PostDistance_Optimization', this.postDistanceOptimization, value);
        this.postDistanceOptimization = value;
    }

    public get ReportCreatedWithBaseplateDesign(): number {
        return this.reportCreatedWithBaseplateDesign;
    }
    public set ReportCreatedWithBaseplateDesign(value: number) {
        this.logSimplePropertyChange('ReportCreatedWithBaseplateDesign', this.reportCreatedWithBaseplateDesign, value);
        this.reportCreatedWithBaseplateDesign = value;
    }

    public get ReportCreatedWithHandrailDesign(): number {
        return this.reportCreatedWithHandrailDesign;
    }
    public set ReportCreatedWithHandrailDesign(value: number) {
        this.logSimplePropertyChange('ReportCreatedWithHandrailDesign', this.reportCreatedWithHandrailDesign, value);
        this.reportCreatedWithHandrailDesign = value;
    }

    public get ReportCreatedWithWeldDesign(): number {
        return this.reportCreatedWithWeldDesign;
    }
    public set ReportCreatedWithWeldDesign(value: number) {
        this.logSimplePropertyChange('ReportCreatedWithWeldDesign', this.reportCreatedWithWeldDesign, value);
        this.reportCreatedWithWeldDesign = value;
    }

    public get SmartAnchor_InfoClicked(): number {
        return this.smartAnchorInfoClicked;
    }
    public set SmartAnchor_InfoClicked(value: number) {
        this.logSimplePropertyChange('SmartAnchor_InfoClicked', this.smartAnchorInfoClicked, value);
        this.smartAnchorInfoClicked = value;
    }

    public get SmartAnchor_SeeMoreClicked(): number {
        return this.smartAnchorSeeMoreClicked;
    }
    public set SmartAnchor_SeeMoreClicked(value: number) {
        this.logSimplePropertyChange('SmartAnchor_SeeMoreClicked', this.smartAnchorSeeMoreClicked, value);
        this.smartAnchorSeeMoreClicked = value;
    }

    public get SmartAnchor_SuggestedAnchorSelected(): number {
        return this.smartAnchorSuggestedAnchorSelected;
    }
    public set SmartAnchor_SuggestedAnchorSelected(value: number) {
        this.logSimplePropertyChange('SmartAnchor_SuggestedAnchorSelected', this.smartAnchorSuggestedAnchorSelected, value);
        this.smartAnchorSuggestedAnchorSelected = value;
    }

    public get SmartAnchor_ToggleChanged(): number {
        return this._SmartAnchor_ToggleChanged;
    }
    public set SmartAnchor_ToggleChanged(value: number) {
        this.logSimplePropertyChange('SmartAnchor_Enabled', this._SmartAnchor_ToggleChanged, value);
        this._SmartAnchor_ToggleChanged = value;
    }

    public get SmartAnchor_AdvancedFiltersClicked(): number {
        return this._SmartAnchor_AdvancedFiltersClicked;
    }
    public set SmartAnchor_AdvancedFiltersClicked(value: number) {
        this.logSimplePropertyChange('SmartAnchor_AdvancedFiltersClicked', this._SmartAnchor_AdvancedFiltersClicked, value);
        this._SmartAnchor_AdvancedFiltersClicked = value;
    }

    public get SmartAnchor_SearchSolutionsClicked(): number {
        return this._SmartAnchorSearchSolutionsClicked;
    }
    public set SmartAnchor_SearchSolutionsClicked(value: number) {
        this.logSimplePropertyChange('SmartAnchor_SearchSolutionsClicked', this._SmartAnchorSearchSolutionsClicked, value);
        this._SmartAnchorSearchSolutionsClicked = value;
    }

    public get SmartAnchor_SortClicked(): number {
        return this._SmartAnchorSearchSolutionsSortClicked;
    }
    public set SmartAnchor_SortClicked(value: number) {
        this.logSimplePropertyChange('SmartAnchor_SortClicked', this._SmartAnchorSearchSolutionsSortClicked, value);
        this._SmartAnchorSearchSolutionsSortClicked = value;
    }

    public get SmartAnchor_PreferenceClicked(): number {
        return this._SmartAnchorSearchSolutionsPreferenceClicked;
    }
    public set SmartAnchor_PreferenceClicked(value: number) {
        this.logSimplePropertyChange('SmartAnchor_PreferenceClicked', this._SmartAnchorSearchSolutionsPreferenceClicked, value);
        this._SmartAnchorSearchSolutionsPreferenceClicked = value;
    }

    public get SmartAnchor_SolutionsFound(): number {
        return this._SmartAnchorSolutionsFound;
    }
    public set SmartAnchor_SolutionsFound(value: number) {
        this.logSimplePropertyChange('SmartAnchor_SolutionsFound', this._SmartAnchorSolutionsFound, value);
        this._SmartAnchorSolutionsFound = value;
    }

    public get SmartAnchor_SolutionsTotalTime(): number {
        return this._SmartAnchorSolutionsTotalTime;
    }
    public set SmartAnchor_SolutionsTotalTime(value: number) {
        this.logSimplePropertyChange('SmartAnchor_SolutionsTotalTime', this._SmartAnchorSolutionsTotalTime, value);
        this._SmartAnchorSolutionsTotalTime = value;
    }

    public get SmartAnchor_PreferredAnchorClicked(): number {
        return this._SmartAnchor_PreferredAnchorClicked;
    }
    public set SmartAnchor_PreferredAnchorClicked(value: number) {
        this.logSimplePropertyChange('SmartAnchor_PreferredAnchorClicked', this._SmartAnchor_PreferredAnchorClicked, value);
        this._SmartAnchor_PreferredAnchorClicked = value;
    }

    public get SmartAnchor_SortBy(): string{
        return this._SmartAnchor_SortBy;
    }
    public set SmartAnchor_SortBy(value: string){
        this.logSimplePropertyChange('SmartAnchor_SortBy',this._SmartAnchor_SortBy,value);
        this._SmartAnchor_SortBy = value;
    }

    public get SmartAnchor_SolutionClicked(): number{
        return this._SmartAnchor_SolutionClicked;
    }
    public set SmartAnchor_SolutionClicked(value: number){
        this.logSimplePropertyChange('SmartAnchor_SolutionClicked', this._SmartAnchor_SolutionClicked,value);
        this._SmartAnchor_SolutionClicked=value;
    }

    public get SmartAnchor_FilteredSolutions(): number{
        return this._SmartAnchor_FilteredSolutions;
    }
    public set SmartAnchor_FilteredSolutions(value: number){
        this.logSimplePropertyChange('SmartAnchor_FilteredSolutions',this._SmartAnchor_FilteredSolutions,value);
        this._SmartAnchor_FilteredSolutions=value;
    }

    public get SmartAnchor_FirstSolutionTime():number{
        return this._SmartAnchor_FirstSolutionTime;
    }
    public set SmartAnchor_FirstSolutionTime(value: number){
        this.logSimplePropertyChange('SmartAnchor_FirstSolutionTime',this._SmartAnchor_FilteredSolutions,value);
        this._SmartAnchor_FirstSolutionTime=value;
    }

    public get GeneralClicks():number{
        return this.generalClicks;
    }
    public set GeneralClicks(value: number){
        this.logSimplePropertyChange('GeneralClicks',this.generalClicks,value);
        this.generalClicks=value;
    }

    public get CalculationsTriggered():number{
        return this.calculationsTriggered;
    }
    public set CalculationsTriggered(value: number){
        this.logSimplePropertyChange('CalculationsTriggered',this.calculationsTriggered,value);
        this.calculationsTriggered=value;
    }

    public get LearnMore_AdvancedCalculationTypesClicked(): number {
        return this._LearnMore_AdvancedCalculationTypesClicked;
    }

    public set LearnMore_AdvancedCalculationTypesClicked(value: number) {
        this.logSimplePropertyChange('LearnMore_AdvancedCalculationTypesClicked', this._LearnMore_AdvancedCalculationTypesClicked, value);
        this._LearnMore_AdvancedCalculationTypesClicked = value;
    }

    public get LearnMore_SOFAClicked(): number {
        return this._LearnMore_SOFAClicked;
    }

    public set LearnMore_SOFAClicked(value: number) {
        this.logSimplePropertyChange('LearnMore_SOFAClicked', this._LearnMore_SOFAClicked, value);
        this._LearnMore_SOFAClicked = value;
    }

    public get LearnMore_FireClicked(): number {
        return this._LearnMore_FireClicked;
    }

    public set LearnMore_FireClicked(value: number) {
        this.logSimplePropertyChange('LearnMore_FireClicked', this._LearnMore_FireClicked, value);
        this._LearnMore_FireClicked = value;
    }

    public get CalculationDetailedOutput(): number {
        return this._CalculationDetailedOutput;
    }
    public set CalculationDetailedOutput(value: number) {
        this.logSimplePropertyChange('CalculationDetailedOutput', this._CalculationDetailedOutput, value);
        this._CalculationDetailedOutput = value;
    }

    /**
     * Transfer to JSON object recognized by back end BL.
     */
    public toUsageCounter(): UsageCounter {
        return {
            AdjustAnchorSpacing: this.AdjustAnchorSpacing,
            AdjustBaseMaterialThickness: this.AdjustBaseMaterialThickness,
            AdjustEdgeDistance: this.AdjustEdgeDistance,
            AdjustPlatePosition: this.AdjustPlatePosition,
            AdjustPlateSize: this.AdjustPlateSize,
            BasePlateHoleDiameterFilter: this.BasePlateHoleDiameterFilter,
            BasePlateThicknessFilter: this.BasePlateThicknessFilter,
            CalculateAll: this.CalculateAll,
            CalculateThickness: this.CalculateThickness,
            DownloadSourceFile: this.DownloadSourceFile,
            FilterGroupsUsed: this.filterGroupsUsed,
            FilterUsed: this.FilterUsed,
            ModelUIPropertiesChanged: this.modelUIPropertiesChanged as any,
            OnlineTechnicalInformation: this.OnlineTechnicalInformation,
            OptimizeAnchorLayout: this.OptimizeAnchorLayout,
            OptimizeBaseplateSize: this.OptimizeBaseplateSize,
            ReportsCreated: this.ReportsCreated,
            PostDistance_Optimization: this.PostDistance_Optimization,
            AnchorOffset_Optimization: this.AnchorOffset_Optimization,
            TwoDEditor: this.TwoDEditor,
            Zoom: this.Zoom,
            DesignExportedToCAD: this.DesignExportedToCAD,
            DesignExportedToIntegrationServices: this.DesignExportedToIntegrationServices,
            DesignExportedToDevice: this.DesignExportedToDevice,
            DesignExportedToTrimple: this.DesignExportedToTrimple,
            Undo: this.Undo,
            Redo: this.Redo,
            ReportCreatedWithWeldDesign: this.ReportCreatedWithWeldDesign,
            ReportCreatedWithBaseplateDesign: this.ReportCreatedWithBaseplateDesign,
            ReportCreatedWithHandrailDesign: this.ReportCreatedWithHandrailDesign,
            ReportCreatedWithItemNumbers: this.ReportCreatedWithItemNumbers,
            ReportCreatedWithDefaultTemplate: this.ReportCreatedWithDefaultTemplate,
            ReportCreatedWithUserTemplate: this.ReportCreatedWithUserTemplate,
            ReportCreatedWithCustomTemplate: this.ReportCreatedWithCustomTemplate,
            DlubalImport: this.DlubalImport,
            DlubalExport: this.DlubalExport,
            SAP2000Import: this.SAP2000Import,
            RobotImport: this.RobotImport,
            EtabsImport: this.EtabsImport,
            StaadProImport: this.StaadProImport,
            RamImport: this.RamImport,
            ExcelImportLoad: this.ExcelImportLoad,
            LCWizardUsed: this.LCWizardUsed,
            CalculateAdvancedBaseplate: this.CalculateAdvancedBaseplate,
            AnchorPlate_ShowOptimizedAnchorPlateThickness: this.AnchorPlate_ShowOptimizedAnchorPlateThickness,
            AnchorPlate_ShowStressDistribution: this.AnchorPlate_ShowStressDistribution,
            SmartAnchor_SuggestedAnchorSelected: this.smartAnchorSuggestedAnchorSelected,
            SmartAnchor_InfoClicked: this.smartAnchorInfoClicked,
            SmartAnchor_SeeMoreClicked: this.smartAnchorSeeMoreClicked,
            AnchorChangedInInfoSection: this.AnchorChangedInInfoSection,
            DuplicateDesign: this.DuplicateDesign,
            ImportExistingDesign: this.ImportExistingDesign,
            MenuOpened: this.MenuOpened,
            QuantityCalculatorExport: this.QuantityCalculatorExport,
            SpecificationTextExport: this.SpecificationTextExport,
            IdeaFileExport: this.IdeaFileExport,
            DateAccessed: this.DateAccessed,
            DateClosed: this.DateClosed,
            Approval: this.Approval,
            ShortReportsCreated: this.ShortReportsCreated,
            SmartAnchor_ToggleChanged: this.SmartAnchor_ToggleChanged,
            SmartAnchor_AdvancedFiltersClicked: this.SmartAnchor_AdvancedFiltersClicked,
            SmartAnchor_SearchSolutionsClicked: this._SmartAnchorSearchSolutionsClicked,
            SmartAnchor_SortClicked: this._SmartAnchorSearchSolutionsSortClicked,
            SmartAnchor_PreferenceClicked: this._SmartAnchorSearchSolutionsPreferenceClicked,
            SmartAnchor_SolutionsFound:  this._SmartAnchorSolutionsFound,
            SmartAnchor_SolutionsTotalTime: this._SmartAnchorSolutionsTotalTime,
            SmartAnchor_PreferredAnchorClicked: this._SmartAnchor_PreferredAnchorClicked,
            SmartAnchor_SortBy: this._SmartAnchor_SortBy,
            SmartAnchor_SolutionClicked: this._SmartAnchor_SolutionClicked,
            SmartAnchor_FilteredSolutions: this._SmartAnchor_FilteredSolutions,
            SmartAnchor_FirstSolutionTime: this._SmartAnchor_FirstSolutionTime,
            GeneralClicks: this.GeneralClicks,
            CalculationsTriggered: this.CalculationsTriggered,
            LearnMore_AdvancedCalculationTypesClicked: this._LearnMore_AdvancedCalculationTypesClicked,
            LearnMore_SOFAClicked: this._LearnMore_SOFAClicked,
            UserSpecificationText: this.UserSpecificationText ?? '',
            LearnMore_FireClicked: this._LearnMore_FireClicked,
            CalculationDetailedOutput: this._CalculationDetailedOutput
        };
    }

    /**
     * reset counter.
     */
    public override resetCounter() {
        super.resetCounter();

        this.adjustAnchorSpacing = 0;
        this.adjustBaseMaterialThickness = 0;
        this.adjustEdgeDistance = 0;
        this.adjustPlatePosition = 0;
        this.adjustPlateSize = 0;
        this.anchorChangedInInfoSection = 0;
        this.anchorOffsetOptimization = 0;
        this.anchorPlateShowOptimizedAnchorPlateThickness = 0;
        this.anchorPlateShowStressDistribution = 0;
        this.basePlateHoleDiameterFilter = 0;
        this.basePlateThicknessFilter = 0;
        this.calculateAdvancedBaseplate = 0;
        this.calculateAll = 0;
        this.calculateThickness = 0;
        this.designExportedToCad = 0;
        this.designExportedToIntegrationServices = 0;
        this.dlubalImport = 0;
        this.dlubalExport = 0;
        this.sap2000Import = 0;
        this.robotImport = 0;
        this.eTabsImport = 0;
        this.staadProImport = 0;
        this.ramImport = 0;
        this.downloadSourceFile = 0;
        this.ideaFileExport = 0;
        this.lcWizardUsed = 0;
        this.optimizeAnchorLayout = 0;
        this.optimizeBaseplateSize = 0;
        this.postDistanceOptimization = 0;
        this.reportCreatedWithBaseplateDesign = 0;
        this.reportCreatedWithHandrailDesign = 0;
        this.reportCreatedWithWeldDesign = 0;
        this.smartAnchorInfoClicked = 0;
        this.smartAnchorSeeMoreClicked = 0;
        this.smartAnchorSuggestedAnchorSelected = 0;
        this._SmartAnchor_ToggleChanged = 0;
        this._SmartAnchorSearchSolutionsClicked = 0;
        this._SmartAnchorSearchSolutionsSortClicked = 0;
        this._SmartAnchorSearchSolutionsPreferenceClicked = 0;
        this._SmartAnchorSolutionsFound = 0;
        this._SmartAnchorSolutionsTotalTime = 0;
        this._SmartAnchor_PreferredAnchorClicked = 0;
        this._SmartAnchor_SortBy = '';
        this._SmartAnchor_SolutionClicked = 0;
        this._SmartAnchor_FilteredSolutions = 0;
        this._SmartAnchor_FirstSolutionTime = 0;
        this.generalClicks = 0;
        this.calculationsTriggered = 0;
        this._LearnMore_AdvancedCalculationTypesClicked = 0;
        this._LearnMore_SOFAClicked = 0;
        this._LearnMore_FireClicked = 0;
        this._CalculationDetailedOutput = 0;
    }
}
