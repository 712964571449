import {
    StiffenerShapeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class StiffenerShape extends CodeList {

    public static fromService(codeList: StiffenerShapeEntity) {
        return new StiffenerShape(CodeList.baseFromService('StiffenerShapeEntity', codeList));
    }

    public override cloneDeep(): StiffenerShape {
        return super.cloneDeep() as StiffenerShape;
    }
}
