import { Injectable } from '@angular/core';
import {
    Unit
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    UnitPrecision, UnitServiceBase, UnitServiceInjected
} from '@profis-engineering/pe-ui-common/services/unit.common';

import {
    UIProperty
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { PropertyMetaData, UIPropertyId } from '../../shared/properties/properties';

@Injectable({
    providedIn: 'root'
})
export class UnitService extends UnitServiceInjected {
    private baseService!: UnitServiceBase;

    public unitPrecision: UnitPrecision = {
        getPrecision: (unit: UnitType) => {
            return this.baseService.getPrecision(unit);
        },

        getPrecisionForProperty: (uiProperty: number, unit: UnitType) => {
            const additionalPrecision = (this as any)['getPrecision_' + PropertyMetaData.getById(uiProperty as UIPropertyId).name]?.(unit) ?? 0;
            return this.unitPrecision.getPrecision(unit) + additionalPrecision;
        }
    };

    public override setBaseService(baseService: UnitServiceBase): void {
        this.baseService = baseService;
        super.setBaseService(baseService);
        this.getPrecision = this._getPrecision;
        this.formatUnitValue = baseService.formatUnitValue;
        this.formatUnitValueArgs = baseService.formatUnitValueArgs;
    }

    public declare getPrecision: (unit: UnitType, uiProperty?: UIProperty, unitPrecision?: UnitPrecision) => number;

    private _getPrecision(unit: UnitType, uiProperty?: UIProperty) {
        return this.baseService.getPrecision(unit, uiProperty, this.unitPrecision);
    }

    public getPrecision_BaseMaterial_CubeStrength(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 1;
            case Unit.PSI:
                return 1;
            case Unit.KSI:
                return 1;
            default:
                return 0;
        }
    }

    public getPrecision_BaseMaterial_CylinderStrength(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_BaseMaterial_PrismStrength(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_BaseMaterial_CubeStrength_Asad(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_BaseMaterial_CylinderStrength_Asad(unit: Unit) {
        return this.getPrecision_BaseMaterial_CubeStrength(unit);
    }

    public getPrecision_Profile_CustomHeight(unit: Unit) {
        switch (unit) {
            case Unit.mm:
                return 0;
            case Unit.cm:
                return 0;
            case Unit.m:
                return 1;
            case Unit.ft:
                return 1;
            case Unit.mi:
                return 1;
            default:
                return 0;
        }
    }

    public getPrecision_Profile_CustomWidth(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomFlangeThickness(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomThickness(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomHeight_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomWidth_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomFlangeThickness_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Profile_CustomThickness_Asad(unit: Unit) {
        return this.getPrecision_Profile_CustomHeight(unit);
    }

    public getPrecision_Loads_SeismicDLSShear(unit: Unit) {
        switch (unit) {
            case Unit.mm:
                return 1;
            case Unit.cm:
                return 2;
            case Unit.inch:
                return 2;
            case Unit.ft:
                return 2;
            default:
                return 0;
        }
    }

    public getPrecision_Loads_SeismicDLSTension(unit: Unit) {
        return this.getPrecision_Loads_SeismicDLSShear(unit);
    }

    public getPrecision_Loads_CompressiveStress(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 2;
            case Unit.PSI:
                return 2;
            case Unit.KSI:
                return 2;
            default:
                return 0;
        }
    }

    public getPrecision_Application_WindVelocityPressure(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 6;
            case Unit.PSI:
                return 6;
            case Unit.KSI:
                return 6;
            case Unit.kNm2:
                return 6;
            case Unit.Nm2:
                return 6;
            case Unit.kgfcm2:
                return 6;
            default:
                return 0;
        }
    }

    public getPrecision_Application_WindPressure(unit: Unit) {
        return this.getPrecision_Application_WindVelocityPressure(unit);
    }

    public getPrecision_Application_WindUplift(unit: Unit) {
        return this.getPrecision_Application_WindVelocityPressure(unit);
    }

    public getPrecision_BaseMaterial_BrickStrength(unit: Unit) {
        switch (unit) {
            case Unit.Nmm2:
                return 0;
            case Unit.PSI:
                return 0;
            case Unit.KSI:
                return 3;
            default:
                return 0;
        }
    }
}
