import {
    ProfileStandardEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class ProfileStandard extends CodeList {

    public static fromService(codeList: ProfileStandardEntity) {
        return new ProfileStandard(CodeList.baseFromService('ProfileStandardEntity', codeList));
    }

    public override cloneDeep(): ProfileStandard {
        return super.cloneDeep() as ProfileStandard;
    }
}
