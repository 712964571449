import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import barProfilePositionImg from '../../../images/custom-stiffener-position/position-bar-profile.png';
import iProfilePositionImg from '../../../images/custom-stiffener-position/position-i-profile.png';
import pipeProfilePositionImg from '../../../images/custom-stiffener-position/position-pipe-profile.png';
import tProfilePositionImg from '../../../images/custom-stiffener-position/position-t-profile.png';
import {
    ProfileShapeType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './custom-stiffener-position-info.component.html',
    styleUrls: ['./custom-stiffener-position-info.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CustomStiffenerPositionInfoComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public iProfileImage!: string;
    public barProfileImage!: string;
    public pipeProfileImage!: string;
    public tProfileImage!: string;

    constructor(
        private user: UserService,
        public localization: LocalizationService,
    ) { }

    public get profilePositionImage() {
        switch (this.design.profileFamily?.shape) {
            case ProfileShapeType.I:
                return this.iProfileImage;

            case ProfileShapeType.SquareBar:
            case ProfileShapeType.Bar:
                return this.barProfileImage;

            case ProfileShapeType.Pipe:
                return this.pipeProfileImage;

            case ProfileShapeType.T:
                return this.tProfileImage;

            default:
                throw new Error('Invalid profile shape');
        }
    }

    private get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        this.iProfileImage = iProfilePositionImg;
        this.barProfileImage = barProfilePositionImg;
        this.pipeProfileImage = pipeProfilePositionImg;
        this.tProfileImage = tProfilePositionImg;
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }
}
