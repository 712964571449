import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalculationType, IBaseDesign as IBaseDesignBase } from '@profis-engineering/pe-ui-common/entities/design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { AnchorEmbedmentDepth } from '../../shared/entities/code-lists/anchor-embedment-depth';
import { AnchorFamily } from '../../shared/entities/code-lists/anchor-family';
import { AnchorFilter } from '../../shared/entities/code-lists/anchor-filter';
import { AnchorFilterGroup } from '../../shared/entities/code-lists/anchor-filter-group';
import { AnchorPositionsAsadClass } from '../../shared/entities/code-lists/anchor-position-asad';
import { AnchorSize } from '../../shared/entities/code-lists/anchor-size';
import { AnchorTorqueType } from '../../shared/entities/code-lists/anchor-torque-type';
import { BeamType } from '../../shared/entities/code-lists/beam-type';
import { BrickGroup } from '../../shared/entities/code-lists/brick-group';
import { BrickLayout } from '../../shared/entities/code-lists/brick-layout';
import { BrickStrength } from '../../shared/entities/code-lists/brick-strength';
import { BrickStrengthAc58 } from '../../shared/entities/code-lists/brick-strength-ac58';
import { BuildingArea } from '../../shared/entities/code-lists/building-area';
import {
    BuildingCategory
} from '../../shared/entities/code-lists/building-category';
import { CladdingProfile } from '../../shared/entities/code-lists/cladding-profile';
import { CleaningMethod } from '../../shared/entities/code-lists/cleaning-method';
import { ConcreteGrade } from '../../shared/entities/code-lists/concrete-grade';
import {
    MasonryAnchorPosition
} from '../../shared/entities/code-lists/masonry-anchor-position';
import {
    MetalDeckAnchorPosition
} from '../../shared/entities/code-lists/metal-deck-anchor-position';
import {
    StiffenerShape
} from '../../shared/entities/code-lists/stiffener-shape';
import {
    SupplementaryReinforcementType
} from '../../shared/entities/code-lists/supplementary-reinforcement-type';

import {
    AnchorLayout as AnchorLayoutEntity
} from '../../shared/entities/code-lists/anchor-layout';
import {
    AnchorLayoutSymmetry as AnchorLayoutSymmetryEntity
} from '../../shared/entities/code-lists/anchor-layout-symmetry';
import {
    AnchorType as AnchorTypeEntity
} from '../../shared/entities/code-lists/anchor-type';
import {
    BrickSize as BrickSizeCodelist
} from '../../shared/entities/code-lists/brick-size';
import {
    CladdingType as CladdingTypeEntity
} from '../../shared/entities/code-lists/cladding-type';
import {
    ConcreteCharacteristic as ConcreteCharacteristicEntity
} from '../../shared/entities/code-lists/concrete-characteristic';
import {
    ConcreteReinforcement as ConcreteReinforcementEntity
} from '../../shared/entities/code-lists/concrete-reinforcement';
import {
    ConcreteType as ConcreteTypeEntity
} from '../../shared/entities/code-lists/concrete-type';
import {
    CoordinateSystemCenter
} from '../../shared/entities/code-lists/coordinate-system-center';
import {
    CustomLoadCategory
} from '../../shared/entities/code-lists/custom-load-category';
import { DeckThickness } from '../../shared/entities/code-lists/deck-thickness';
import {
    DesignMethodHNA as DesignMethodHNAEntity
} from '../../shared/entities/code-lists/design-method-HNA';
import { DistanceInsideTownTerrain } from '../../shared/entities/code-lists/distance-inside-town-terrain';
import { DistanceUpwindToShoreline } from '../../shared/entities/code-lists/distance-upwind-to-shoreline';
import {
    DrillingMethod as DrillingMethodEntity
} from '../../shared/entities/code-lists/drilling-method';
import { EdgeReinforcement } from '../../shared/entities/code-lists/edge-reinforcement';
import { EmbedmentOptimizationType } from '../../shared/entities/code-lists/embedment-optimization-type';
import { EmbedmentType } from '../../shared/entities/code-lists/embedment-type';
import { EnvironmentType } from '../../shared/entities/code-lists/environment-type';
import {
    FasteningOption
} from '../../shared/entities/code-lists/fastening-option';
import {
    FatigueLoadType as FatigueLoadTypeEntity
} from '../../shared/entities/code-lists/fatigue-load-type';
import { FireDuration } from '../../shared/entities/code-lists/fire-duration';
import { FireExposure } from '../../shared/entities/code-lists/fire-exposure';
import { Grout } from '../../shared/entities/code-lists/grout';
import { GroutType } from '../../shared/entities/code-lists/grout-type';
import {
    HandrailApplicationType as HandrailApplicationTypeEntity
} from '../../shared/entities/code-lists/handrail-application-type';
import {
    HandrailConnectionType as HandrailConnectionTypeEntity
} from '../../shared/entities/code-lists/handrail-connection-type';
import { HandrailSafetyClass } from '../../shared/entities/code-lists/handrail-safety-class';
import { HeadJointSelection } from '../../shared/entities/code-lists/head-joint-selection';
import { HoleCondition } from '../../shared/entities/code-lists/hole-condition';
import { HoleType } from '../../shared/entities/code-lists/hole-type';
import {
    HorizontalPostProfileConnectionType
} from '../../shared/entities/code-lists/horizontal-post-profile-connection-type';
import { InstallationDirection } from '../../shared/entities/code-lists/installation-direction';
import {
    InstallationType
} from '../../shared/entities/code-lists/installation-type';
import { JointMaterial } from '../../shared/entities/code-lists/joint-material';
import {
    LoadCombinationHNAEquation
} from '../../shared/entities/code-lists/load-combination-HNA-equation';
import { LoadCombinationHNAFactors } from '../../shared/entities/code-lists/load-combination-HNA-factors';
import {
    LoadType as LoadTypeEntity
} from '../../shared/entities/code-lists/load-type';
import {
    MasonryFastenerConfiguration
} from '../../shared/entities/code-lists/masonry-fastener-configuration';
import { MasonryUseCategory } from '../../shared/entities/code-lists/masonry-use-category';
import { MetalDeck } from '../../shared/entities/code-lists/metal-deck';
import {
    PlateShape as PlateShapeEntity
} from '../../shared/entities/code-lists/plate-shape';
import { ProfileFamily } from '../../shared/entities/code-lists/profile-family';
import { ProfilePositionsAsadClass } from '../../shared/entities/code-lists/profile-position-asad';
import { ProfileSize } from '../../shared/entities/code-lists/profile-size';
import {
    ProfileStandard
} from '../../shared/entities/code-lists/profile-standard';
import { ReinforcementShearCondition } from '../../shared/entities/code-lists/reinforcement-shear-condition';
import { ReinforcementTensionCondition } from '../../shared/entities/code-lists/reinforcement-tension-condition';
import { SafetyLevel } from '../../shared/entities/code-lists/safety-level';
import { SeismicCategory } from '../../shared/entities/code-lists/seismic-category';
import { SeismicDesignType } from '../../shared/entities/code-lists/seismic-design-type';
import { SeismicDisplacementsType } from '../../shared/entities/code-lists/seismic-displacements-type';
import { SeismicIntensityFactor } from '../../shared/entities/code-lists/seismic-intensity-factor';
import {
    SeismicShearType as SeismicShearTypeEntity
} from '../../shared/entities/code-lists/seismic-shear-type';
import {
    SeismicTensionType as SeismicTensionTypeEntity
} from '../../shared/entities/code-lists/seismic-tension-type';
import { ShearLoadDistributionType } from '../../shared/entities/code-lists/shear-load-distribution-type';
import { SmartAnchorApplication } from '../../shared/entities/code-lists/smart-anchor-application';
import { SmartAnchorCategory } from '../../shared/entities/code-lists/smart-anchor-category';
import { SmartAnchorNeedsSolutionTextsAnchorGroup } from '../../shared/entities/code-lists/smart-anchor-needs-solution-texts-anchor-group';
import { StandOff } from '../../shared/entities/code-lists/stand-off';
import { StandOffDesignMethod } from '../../shared/entities/code-lists/stand-off-design-method';
import {
    StaticLoadType as StaticLoadTypeEntity
} from '../../shared/entities/code-lists/static-load-type';
import { SteelType } from '../../shared/entities/code-lists/steel-type';
import { StiffenerCustomProfileSides } from '../../shared/entities/code-lists/stiffener-custom-profile-sides';
import { StiffenerLayout } from '../../shared/entities/code-lists/stiffener-layout';
import { StiffenerLayoutPosition } from '../../shared/entities/code-lists/stiffener-layout-position';
import {
    SupplementaryReinforcementCategory
} from '../../shared/entities/code-lists/supplementary-reinforcement-category';
import { SupplementaryReinforcementDiameter } from '../../shared/entities/code-lists/supplementary-reinforcement-diameter';
import {
    SupplementaryReinforcementDirectionOfCasting
} from '../../shared/entities/code-lists/supplementary-reinforcement-direction-of-casting';
import { TerrainCategory } from '../../shared/entities/code-lists/terrain-category';
import { TerrainRoughness } from '../../shared/entities/code-lists/terrain-roughness';
import { TerrainType } from '../../shared/entities/code-lists/terrain-type';
import { TorquingType } from '../../shared/entities/code-lists/torquing-type';
import {
    WeldLocation as WeldLocationEntity
} from '../../shared/entities/code-lists/weld-location';
import {
    WeldingType as WeldingTypeEntity
} from '../../shared/entities/code-lists/welding-type';
import { WHGType } from '../../shared/entities/code-lists/whg-type';
import { WindLoadCity } from '../../shared/entities/code-lists/wind-load-city';
import { WindLoadState } from '../../shared/entities/code-lists/wind-load-state';
import { WindVelocity } from '../../shared/entities/code-lists/wind-velocity';
import { WindZone } from '../../shared/entities/code-lists/wind-zone';
import { DesignCodeList } from '../../shared/entities/design-code-list';
import { DesignPe, ICalculateInternalOptions, ICodeLists, ReportData } from '../../shared/entities/design-pe';
import { CalculationResultEntity as ICalculationResultEntity, IdeaProjectFileResponse, NewDesignDataEntity } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    DesignReportDataClientEntity as IDesignReportDataClientEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    DesignCodeLists as IDesignCodeLists
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    ProjectDesignBaseEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    GenerateReportRequest
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import { UIProperties } from '../../shared/properties/properties';
import {
    CalculateDesignRequestData, CalculationServiceBasePE, IBaseDesign, ICalculationResult
} from '../../shared/services/calculation.common';

@Injectable({
    providedIn: 'root'
})
export class CalculationServicePE extends CalculationServiceBasePE {
    public setBaseService(baseService: CalculationServiceBasePE) {
        this.createAndOpen = baseService.createAndOpen.bind(baseService);
        this.createAndOpenFromProjectDesignFile = baseService.createAndOpenFromProjectDesignFile.bind(baseService);
        this.openFromProjectDesign = baseService.openFromProjectDesign.bind(baseService);
        this.openFromDocumentDesign = baseService.openFromDocumentDesign.bind(baseService);
        this.createAndOpenFromProjectDesign = baseService.createAndOpenFromProjectDesign.bind(baseService);
        this.createFromProjectDesign = baseService.createFromProjectDesign.bind(baseService);
        this.updateDesignFromExternalFile = baseService.updateDesignFromExternalFile.bind(baseService);
        this.createAndOpenTemplate = baseService.createAndOpenTemplate.bind(baseService);
        this.calculateAdvancedBaseplateAll = baseService.calculateAdvancedBaseplateAll.bind(baseService);
        this.calculateAdvancedBaseplate = baseService.calculateAdvancedBaseplate.bind(baseService);
        this.generateAndDownloadReportSignalR = baseService.generateAndDownloadReportSignalR.bind(baseService);
        this.calculateAllSignalR = baseService.calculateAllSignalR.bind(baseService);
        this.calculateAsync = baseService.calculateAsync.bind(baseService);
        this.calculate = baseService.calculate.bind(baseService);
        this.updateReportDataFromModel = baseService.updateReportDataFromModel.bind(baseService);
        this.updateReportData = baseService.updateReportData.bind(baseService);
        this.updateFromProperties = baseService.updateFromProperties.bind(baseService);
        this.updateCalculationData = baseService.updateCalculationData.bind(baseService);
        this.confirmCalculationResult = baseService.confirmCalculationResult.bind(baseService);
        this.performAdvancedBaseplateCalculation = baseService.performAdvancedBaseplateCalculation.bind(baseService);
        this.wizardHNALoadTypeSeismicDialogChain = baseService.wizardHNALoadTypeSeismicDialogChain.bind(baseService);
        this.generateIdeaFile = baseService.generateIdeaFile.bind(baseService);
        this.createProjectDesignFromFile = baseService.createProjectDesignFromFile.bind(baseService);
        this.loadState = baseService.loadState.bind(baseService);
        this.undo = baseService.undo.bind(baseService);
        this.redo = baseService.redo.bind(baseService);
        this.getCustomImages = baseService.getCustomImages.bind(baseService);
        this.uploadImages = baseService.uploadImages.bind(baseService);

        // using createDesignCodeLists from pe-ui-pe instead of deprecated one in pe-ui
        baseService.createDesignCodeLists = this.createDesignCodeLists.bind(baseService);
    }

    public createDesignCodeLists(serviceDesignCodeLists: IDesignCodeLists): ICodeLists {
        const designCodeLists: ICodeLists = {};

        if (serviceDesignCodeLists != null) {
            designCodeLists[DesignCodeList.AnchorFilter] = serviceDesignCodeLists.AnchorFilters?.map((codeList) => AnchorFilter.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorFilterGroup] = serviceDesignCodeLists.AnchorFilterGroups?.map((codeList) => AnchorFilterGroup.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorEmbedmentType] = serviceDesignCodeLists.AnchorEmbedmentTypes?.map((codeList) => EmbedmentType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorFamily] = serviceDesignCodeLists.AnchorFamilies?.map((codeList) => AnchorFamily.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorFamilyAC58] = serviceDesignCodeLists.AnchorFamiliesAc58?.map((codeList) => AnchorFamily.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorLayout] = serviceDesignCodeLists.AnchorLayouts?.map((codeList) => AnchorLayoutEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorLayoutVisible] = serviceDesignCodeLists.AnchorLayoutsVisible?.map((codeList) => AnchorLayoutEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorLayoutSymmetry] = serviceDesignCodeLists.AnchorLayoutSymmetry?.map((codeList) => AnchorLayoutSymmetryEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorSize] = serviceDesignCodeLists.AnchorSizes?.map((codeList) => AnchorSize.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorSizeAC58] = serviceDesignCodeLists.AnchorSizesAc58?.map((codeList) => AnchorSize.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorType] = serviceDesignCodeLists.AnchorTypes?.map((codeList) => AnchorTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorTypeAC58] = serviceDesignCodeLists.AnchorTypesAc58?.map((codeList) => AnchorTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorEmbedmentDepth] = serviceDesignCodeLists.AnchorEmbedmentDepths?.map((codeList) => AnchorEmbedmentDepth.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorTorqueType] = serviceDesignCodeLists.AnchorTorqueTypes?.map((codeList) => AnchorTorqueType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ConcreteType] = serviceDesignCodeLists.ConcreteTypes?.map((codeList) => ConcreteTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ConcreteCharacteristic] = serviceDesignCodeLists.ConcreteCharacteristics?.map((codeList) => ConcreteCharacteristicEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ConcreteGrade] = serviceDesignCodeLists.ConcreteGrades?.map((codeList) => ConcreteGrade.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ConcreteReinforcement] = serviceDesignCodeLists.ConcreteReinforcements?.map((codeList) => ConcreteReinforcementEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DesignMethodHNA] = serviceDesignCodeLists.DesignMethodHNAs?.map((codeList) => DesignMethodHNAEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DrillingMethod] = serviceDesignCodeLists.DrillingMethods?.map((codeList) => DrillingMethodEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.EdgeReinforcement] = serviceDesignCodeLists.EdgeReinforcements?.map((codeList) => EdgeReinforcement.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.EmbedmentOptimizationType] = serviceDesignCodeLists.EmbedmentOptimizationTypes?.map((codeList) => EmbedmentOptimizationType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FatigueLoadType] = serviceDesignCodeLists.FatigueLoadTypes?.map((codeList) => FatigueLoadTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FireExposure] = serviceDesignCodeLists.FireExposures?.map((codeList) => FireExposure.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FireDuration] = serviceDesignCodeLists.FireDurations?.map((codeList) => FireDuration.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.Grout] = serviceDesignCodeLists.Grouts?.map((codeList) => Grout.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.GroutType] = serviceDesignCodeLists.GroutTypes?.map((codeList) => GroutType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HoleCondition] = serviceDesignCodeLists.HoleConditions?.map((codeList) => HoleCondition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HoleType] = serviceDesignCodeLists.HoleTypes?.map((codeList) => HoleType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.InstallationType] = serviceDesignCodeLists.InstallationTypes?.map((codeList) => InstallationType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.LoadType] = serviceDesignCodeLists.LoadTypes?.map((codeList) => LoadTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.PlateShape] = serviceDesignCodeLists.PlateShapes?.map((codeList) => PlateShapeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfileFamily] = serviceDesignCodeLists.ProfileFamilies?.map((codeList) => ProfileFamily.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfileSize] = serviceDesignCodeLists.ProfileSizes?.map((codeList) => ProfileSize.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ReinforcementShearCondition] = serviceDesignCodeLists.ReinforcementShearConditions?.map((codeList) => ReinforcementShearCondition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ReinforcementTensionCondition] = serviceDesignCodeLists.ReinforcementTensionConditions?.map((codeList) => ReinforcementTensionCondition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicCategory] = serviceDesignCodeLists.SeismicCategories?.map((codeList) => SeismicCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicDesignType] = serviceDesignCodeLists.SeismicDesignTypes?.map((codeList) => SeismicDesignType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicDisplacementsType] = serviceDesignCodeLists.SeismicDisplacementTypes?.map((codeList) => SeismicDisplacementsType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicShearType] = serviceDesignCodeLists.SeismicShearTypes?.map((codeList) => SeismicShearTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicTensionType] = serviceDesignCodeLists.SeismicTensionTypes?.map((codeList) => SeismicTensionTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StandOff] = serviceDesignCodeLists.StandOffs?.map((codeList) => StandOff.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StaticLoadType] = serviceDesignCodeLists.StaticLoadTypes?.map((codeList) => StaticLoadTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickLayout] = serviceDesignCodeLists.BrickLayouts?.map((codeList) => BrickLayout.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickGroup] = serviceDesignCodeLists.BrickGroups?.map((codeList) => BrickGroup.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickStrength] = serviceDesignCodeLists.BrickStrengths?.map((codeList) => BrickStrength.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.JointMaterial] = serviceDesignCodeLists.JointMaterials?.map((codeList) => JointMaterial.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MasonryUseCategory] = serviceDesignCodeLists.MasonryUseCategories?.map((codeList) => MasonryUseCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MasonryFastenerConfiguration] = serviceDesignCodeLists.MasonryFastenerConfigurations?.map((codeList) => MasonryFastenerConfiguration.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CleaningMethod] = serviceDesignCodeLists.CleaningMethods?.map((codeList) => CleaningMethod.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.FasteningOption] = serviceDesignCodeLists.FasteningOptions?.map((codeList) => FasteningOption.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.EnvironmentType] = serviceDesignCodeLists.EnvironmentTypes?.map((codeList) => EnvironmentType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BeamType] = serviceDesignCodeLists.BeamTypes?.map((codeList) => BeamType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HorizontalPostProfileConnectionType] = serviceDesignCodeLists.HorizontalPostProfileConnectionTypes?.map((codeList) => HorizontalPostProfileConnectionType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CustomLoadCategory] = serviceDesignCodeLists.CustomLoadCategories?.map((codeList) => CustomLoadCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HandrailApplication] = serviceDesignCodeLists.HandrailApplication?.map((codeList) => HandrailApplicationTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HandrailConnectionType] = serviceDesignCodeLists.HandrailConnectionTypes?.map((codeList) => HandrailConnectionTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BuildingCategory] = serviceDesignCodeLists.BuildingCategories?.map((codeList) => BuildingCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SteelType] = serviceDesignCodeLists.SteelTypes?.map((codeList) => SteelType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindZone] = serviceDesignCodeLists.WindZones?.map((codeList) => WindZone.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BuildingArea] = serviceDesignCodeLists.BuildingAreas?.map((codeList) => BuildingArea.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DistanceInsideTownTerrain] = serviceDesignCodeLists.DistancesInsideTownTerrain?.map((codeList) => DistanceInsideTownTerrain.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DistanceUpwindToShoreline] = serviceDesignCodeLists.DistancesUpwindToShoreline?.map((codeList) => DistanceUpwindToShoreline.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TerrainCategory] = serviceDesignCodeLists.TerrainCategories?.map((codeList) => TerrainCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TerrainRoughness] = serviceDesignCodeLists.TerrainRoughnessClasses?.map((codeList) => TerrainRoughness.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindLoadCity] = serviceDesignCodeLists.WindLoadCities?.map((codeList) => WindLoadCity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindLoadState] = serviceDesignCodeLists.WindLoadStates?.map((codeList) => WindLoadState.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WindVelocity] = serviceDesignCodeLists.WindVelocities?.map((codeList) => WindVelocity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TerrainType] = serviceDesignCodeLists.TerrainTypes?.map((codeList) => TerrainType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CoordinateSystemCenter] = serviceDesignCodeLists.CoordinateSystemCenters?.map((codeList) => CoordinateSystemCenter.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MetalDeck] = serviceDesignCodeLists.MetalDeck?.map((codeList) => MetalDeck.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MetalDeckAnchorPosition] = serviceDesignCodeLists.MetalDeckAnchorPosition?.map((codeList) => MetalDeckAnchorPosition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.MasonryAnchorPosition] = serviceDesignCodeLists.MasonryAnchorPosition?.map((codeList) => MasonryAnchorPosition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.LoadCombinationHNAEquation] = serviceDesignCodeLists.LoadCombinationHNAEquations?.map((codeList) => LoadCombinationHNAEquation.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.LoadCombinationHNAFactors] = serviceDesignCodeLists.LoadCombinationHNAFactors?.map((codeList) => LoadCombinationHNAFactors.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.TorquingType] = serviceDesignCodeLists.TorquingTypes?.map((codeList) => TorquingType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WeldLocations] = serviceDesignCodeLists.WeldLocations?.map((codeList) => WeldLocationEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerShape] = serviceDesignCodeLists.StiffenerShape?.map((codeList) => StiffenerShape.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerLayout] = serviceDesignCodeLists.StiffenerLayout?.map((codeList) => StiffenerLayout.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerLayoutPosition] = serviceDesignCodeLists.StiffenerLayoutPositions?.map((codeList) => StiffenerLayoutPosition.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfileStandard] = serviceDesignCodeLists.ProfileStandards?.map((codeList) => ProfileStandard.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.InstallationDirection] = serviceDesignCodeLists.InstallationDirections?.map((codeList) => InstallationDirection.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WeldingType] = serviceDesignCodeLists.WeldingType?.map((codeList) => WeldingTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CladdingType] = serviceDesignCodeLists.CladdingType?.map((codeList) => CladdingTypeEntity.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.CladdingProfile] = serviceDesignCodeLists.CladdingProfiles?.map((codeList) => CladdingProfile.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.DeckThickness] = serviceDesignCodeLists.DeckThicknesses?.map((codeList) => DeckThickness.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StiffenerCustomProfileSides] = serviceDesignCodeLists.StiffenerCustomProfileSides?.map((codeList) => StiffenerCustomProfileSides.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementDirectionOfCasting] = serviceDesignCodeLists.SupplementaryReinforcementDirectionsOfCasting?.map((codeList) => SupplementaryReinforcementDirectionOfCasting.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementType] = serviceDesignCodeLists.SupplementaryReinforcementTypes?.map((codeList) => SupplementaryReinforcementType.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementDiameter] = serviceDesignCodeLists.SupplementaryReinforcementDiameters?.map((codeList) => SupplementaryReinforcementDiameter.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SmartAnchorCategory] = serviceDesignCodeLists.SmartAnchorCategories?.map((codeList) => SmartAnchorCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SmartAnchorApplication] = serviceDesignCodeLists.SmartAnchorApplications?.map((codeList) => SmartAnchorApplication.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SmartAnchorNeedsSolutionTextsAnchorGroup] = serviceDesignCodeLists.SmartAnchorNeedsSolutionTextsAnchorGroup?.map((codeList) => SmartAnchorNeedsSolutionTextsAnchorGroup.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SupplementaryReinforcementCategory] = serviceDesignCodeLists.SupplementaryReinforcementCategories?.map((codeList) => SupplementaryReinforcementCategory.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HandrailSafetyClass] = serviceDesignCodeLists.HandrailSafetyClass?.map((codeList) => HandrailSafetyClass?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.AnchorPositionsAsad] = serviceDesignCodeLists.AnchorPositionsAsad?.map((codeList) => AnchorPositionsAsadClass?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ProfilePositionsAsad] = serviceDesignCodeLists.ProfilePositionsAsad?.map((codeList) => ProfilePositionsAsadClass?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SeismicIntensityFactor] = serviceDesignCodeLists.SeismicIntensityFactor?.map((codeList) => SeismicIntensityFactor?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.SafetyLevel] = serviceDesignCodeLists.SafetyLevel?.map((codeList) => SafetyLevel?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.WHGType] = serviceDesignCodeLists.WHGType?.map((codeList) => WHGType?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickSize] = serviceDesignCodeLists.BrickSizes?.map((codeList) => BrickSizeCodelist?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.StandOffDesignMethod] = serviceDesignCodeLists.StandOffDesignMethods?.map((codeList) => StandOffDesignMethod?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.BrickStrengthAC58] = serviceDesignCodeLists.BrickStrengthAc58?.map((codeList) => BrickStrengthAc58?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.HeadJointSelection] = serviceDesignCodeLists.HeadJointSelection?.map((codeList) => HeadJointSelection?.fromService(codeList)) || [];
            designCodeLists[DesignCodeList.ShearLoadDistributionType] = serviceDesignCodeLists.ShearLoadDistributionType?.map((codeList) => ShearLoadDistributionType?.fromService(codeList)) || [];
        }

        return designCodeLists;
    }

    // CalculationServiceBasePE methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public createAndOpen(_dataEntity: NewDesignDataEntity): Promise<DesignPe> {
        return {} as Promise<DesignPe>;
    }

    public createAndOpenFromProjectDesignFile(
        _projectDesign: ProjectDesignBaseEntity, _projectId: string, _name?: string,
        _designCreated?: (projectDesign: ProjectDesignBaseEntity, name: string) => Promise<void> | void,
        _ignoreConflict?: boolean, _disableCalcMessages?: boolean, _trackingEnabled?: boolean): Promise<DesignPe> {
        return {} as Promise<DesignPe>;
    }

    public openFromProjectDesign(_projectDesign: ProjectDesignBaseEntity, _designId: string, _trackingEnabled = true, _openOnly = false, _disableCalcMessages?: boolean) {
        return {} as Promise<ICalculationResult> ;
    }

    public openFromDocumentDesign(_documentDesign: IBaseDesign, _openOnly: boolean): Promise<ICalculationResult> {
        return {} as Promise<any>;
    }

    public createAndOpenFromProjectDesign(_projectDesign: ProjectDesignBaseEntity, _projectId: string, _templateId?: string, _enableTracking?: boolean): Promise<DesignPe> {
        return {} as Promise<DesignPe>;
    }

    public createFromProjectDesign(_projectDesign: ProjectDesignBaseEntity, _projectId: string, _openOnly: boolean): Promise<DesignPe> {
        return {} as Promise<DesignPe>;
    }

    public updateDesignFromExternalFile(_oldDesign: IBaseDesignBase, _projectDesign: ProjectDesignBaseEntity, _disableCalcMessages?: boolean): Promise<DesignPe> {
        return {} as Promise<DesignPe>;
    }

    public createAndOpenTemplate(_projectDesign: ProjectDesignBaseEntity, _templateId: string, _templateName: string): Promise<DesignPe> {
        return {} as Promise<DesignPe>;
    }

    public calculateAdvancedBaseplateAll(_design: DesignPe): Promise<void> {
        return {} as Promise<void>;
    }

    public calculateAdvancedBaseplate(_design: DesignPe, _selectedLoadCaseId?: string | null): Promise<void> {
        return {} as Promise<void>;
    }

    public generateAndDownloadReportSignalR(_design: DesignPe, _generateReportData: GenerateReportRequest, _changes: Change[], _downloadPdf: boolean, _skipTracking = false): Promise<Blob> {
        return {} as Promise<Blob>;
    }

    public calculateAllSignalR(_design: DesignPe, _onProgress?: (progress: unknown) => void): Promise<void> {
        return {} as Promise<void>;
    }

    public calculateAsync(_design: DesignPe, _changeFn?: (design: DesignPe) => void, _options?: ICalculateInternalOptions): Promise<ICalculationResult> {
        return {} as Promise<ICalculationResult>;
    }

    public calculate(_design: DesignPe, _calculateId: string, _options: CalculateDesignRequestData): void {
        return;
    }

    public updateReportDataFromModel(_design: DesignPe): void {
        return;
    }

    public updateReportData(_design: DesignPe, _reportData: IDesignReportDataClientEntity): ReportData {
        return {} as ReportData;
    }

    public updateFromProperties(_design: DesignPe, _uiProperties: UIProperties, _keepMissingProperties?: boolean): {
        modelChanges: {
            [property: string]: Change;
        };
        propertyChanges: IPropertyChanges;
    } {
        return {} as {
            modelChanges: {
                [property: string]: Change;
            };
            propertyChanges: IPropertyChanges;
        };
    }

    public updateCalculationData(
        _design: DesignPe,
        _data: ICalculationResultEntity,
        _calculationLanguage: string,
        _calculationType: CalculationType,
        _messagesClosedDeferred?: Deferred<void>,
        _disableCalcMessages?: boolean,
        _keepMissingProperties?: boolean
    ): {
        [property: string]: Change;
    } {
        return {} as {
            [property: string]: Change;
        };
    }

    public confirmCalculationResult(_design: DesignPe, _result: ICalculationResultEntity): void {
        return;
    }

    public performAdvancedBaseplateCalculation(_design: DesignPe, _selectedLoadCaseId?: string): Promise<ICalculationResultEntity> {
        return {} as Promise<ICalculationResultEntity>;
    }

    public wizardHNALoadTypeSeismicDialogChain(_design: DesignPe, _onClose: () => void) {
        return;
    }

    public generateIdeaFile(_design: DesignPe): Promise<HttpResponse<IdeaProjectFileResponse>> {
        return {} as Promise<HttpResponse<IdeaProjectFileResponse>>;
    }

    public createProjectDesignFromFile(_projectDesignFile: File | Blob, _projectName: string, _designName: string, _ignoreErrors?: boolean): Promise<ProjectDesignBaseEntity> {
        return {} as Promise<ProjectDesignBaseEntity>;
    }

    public loadState(_design: DesignPe, _index: number) {
        return;
    }

    public undo(_design: DesignPe) {
        return {} as Promise<void>;
    }

    public redo(_design: DesignPe) {
        return {} as Promise<void>;
    }

    public getCustomImages(_imageNames: string[]): Promise<string[]> {
        return {} as Promise<string[]>;
    }

    public uploadImages(_designId: string, _images: string[]): Promise<string[]> {
        return {} as Promise<string[]>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
