<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.Title')">
</pe-modal-header>

<form class="form" name="form" (ngSubmit)="save()" novalidate>
    <pe-modal-body>
        <p class="text-label info-box input-info" [innerHtml]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.Intro')">
        </p>

        <div class="section-row">
            <div class="section-col-left">
                <div class="radio data-item">
                    <pe-radio-button
                        [items]="[buildingCategoryLoadsRadioItems[0]]"
                        [selectedValue]="onSiteTestType"
                        (selectedValueChange)="buildingCategoryLoadsRadioSelectedValueChange($any($event).detail)">
                    </pe-radio-button>
                </div>
            </div>

            <div class="section-col-right">
                <div class="direction-row">
                    <div class="control-left-label">
                        <pe-control-title [title]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.NRk1')">
                        </pe-control-title>
                    </div>
                    <div class="stepper-container">
                        <pe-numeric-text-box
                            [value]="nrk1TextBox.value"
                            (valueChange)="nrk1TextBox.value = $any($event).detail"
                            [unit]="unitForce"
                            [minValue]="nrk1TextBox.minValue"
                            [maxValue]="nrk1TextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="nrk1TextBox.isValid = $any($event).detail"
                            [disabled]="submitted || onSiteTestType != OnSiteTestTypePullout">
                        </pe-numeric-text-box>
                    </div>
                </div>

                <div class="direction-row">
                    <div class="control-left-label">
                        <pe-control-title [title]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.VRk1')">
                        </pe-control-title>
                    </div>
                    <div class="stepper-container">
                        <pe-numeric-text-box
                            [value]="vrk1TextBox.value"
                            (valueChange)="vrk1TextBox.value = $any($event).detail"
                            [unit]="unitForce"
                            [minValue]="vrk1TextBox.minValue"
                            [maxValue]="vrk1TextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="vrk1TextBox.isValid = $any($event).detail"
                            [disabled]="submitted || onSiteTestType != OnSiteTestTypePullout">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-row">
            <div class="section-col-left">
                <div class="radio data-item">
                    <pe-radio-button
                        [items]="[buildingCategoryLoadsRadioItems[1]]"
                        [selectedValue]="onSiteTestType"
                        (selectedValueChange)="buildingCategoryLoadsRadioSelectedValueChange($any($event).detail)">
                    </pe-radio-button>
                </div>
            </div>

            <div class="section-col-right">
                <div class="direction-row">
                    <div class="control-left-label">
                        <pe-control-title [title]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.NRk2')">
                        </pe-control-title>
                    </div>
                    <div class="stepper-container">
                        <pe-numeric-text-box
                            [value]="nrk2TextBox.value"
                            (valueChange)="nrk2TextBox.value = $any($event).detail"
                            [unit]="unitForce"
                            [minValue]="nrk2TextBox.minValue"
                            [maxValue]="nrk2TextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="nrk2TextBox.isValid = $any($event).detail"
                            [disabled]="submitted || onSiteTestType != OnSiteTestTypeProofLoad">
                        </pe-numeric-text-box>
                    </div>
                </div>

                <div class="direction-row">
                    <div class="control-left-label">
                        <pe-control-title [title]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.VRk2')">
                        </pe-control-title>
                    </div>
                    <div class="stepper-container">
                        <pe-numeric-text-box
                            [value]="vrk2TextBox.value"
                            (valueChange)="vrk2TextBox.value = $any($event).detail"
                            [unit]="unitForce"
                            [minValue]="vrk2TextBox.minValue"
                            [maxValue]="vrk2TextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="vrk2TextBox.isValid = $any($event).detail"
                            [disabled]="submitted || onSiteTestType != OnSiteTestTypeProofLoad">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        </div>

        <p class="text-label info-box important-notes" [innerHtml]="translate(onSiteTestsNotes)"></p>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'on-site-tests-cancel-button'"
            [look]="'Default'"
            class="modal-button button"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'on-site-tests-save-button'"
            [look]="'Cta'"
            type="submit"
            class="modal-button button"
            [disabled]="!formValid || submitted">
            <pe-loading-button [loading]="submitted" [text]="translate('Agito.Hilti.Profis3.Main.OnSiteTests.OK')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
