import { ContolsStyleSheets, IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { getMenuControlImageReactData, getReactCSSProperties } from '../../../helpers/main-menu-helpers/react-helpers';
import { getSpriteAsIconStyleResponsive } from '../../../sprites';
import styles from './react-suggested-anchor.css';

const keyEnter = 13;
const keySpace = 32;

const sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

const ControlHeader = (window as any).pe.reactComponents.ControlHeader;

export interface ISuggestedAnchorProps extends IControlProps {
    text?: string;
    checked?: boolean;
    value?: number;
    isNew?: boolean;
    isSmartBaseplateEnable?: boolean;
    isUserAnchorChosen?: boolean;
    buttonText?: string;
    buttonImage?: string;
    buttonImageStyle?: IResponsiveIconStyle;
    tag?: string;
    checkedChanged?: (checked: boolean, value: number) => void;
    deleteIconClicked?: (value: number) => void;
}
class _SuggestedAnchor extends React.PureComponent<ISuggestedAnchorProps> {
    public static styleSheets = [sheet];

    private input: HTMLInputElement | null;
    private noAnchorPhotoImageData: React.CSSProperties | undefined;

    constructor(props: ISuggestedAnchorProps) {
        super(props);

        this.noAnchorPhotoImageData = getReactCSSProperties(getSpriteAsIconStyleResponsive('sprite-anchor-family-no-photo-available'));
        this.input = null;
        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onDeleteIconClick = this.onDeleteIconClick.bind(this);
    }

    public override render() {
        if (this.props.hidden) {
            return null;
        }

        const inputId = `${this.props.controlId}-radio`;
        const checked = this.props.checked ?? false;
        const buttonId = `${this.props.controlId}-delete-button`;

        let tabIndex = 0;
        let tooltipTitle = this.props.tooltipTitle;
        let tooltipText = this.props.tooltip;
        let disableClassName = '';
        if (this.props.disabled) {
            tabIndex = -1;
            tooltipTitle = this.props.tooltipDisabledTitle;
            tooltipText = this.props.tooltipDisabled;
            disableClassName = 'disabled';
        }

        const shortTag = (this.props.tag != null && this.props.tag.length > 0 ? this.props.localization?.getString(this.props.tag).charAt(0) : '') ?? '';
        const newTag = this.props.isNew ? this.props.localization?.getString('Agito.Hilti.Profis3.Anchors.New') : null;

        const suggestedAnchorImageData = getMenuControlImageReactData(
            this.props.buttonImage,
            this.props.buttonImageStyle?.elementStyle,
            this.props.buttonImageStyle?.afterElementStyle
        );
        const useResponsiveStyle = suggestedAnchorImageData.elementAfterStyle != null;

        return (
            <div data-control-id={this.props.controlId} className={`react-suggested-anchor control ${this.props.sizeClass}`}>
                <ControlHeader
                    text={this.props.title}
                    controlId={inputId}
                    tooltip={tooltipText}
                    tooltipTitle={tooltipTitle}
                    localization={this.props.localization}
                />

                <div className='control-container'>
                    {
                        this.props.isSmartBaseplateEnable ?

                            <div onClick={this.onClick} className={"button-rich select-anchor mb-1 " + (checked ? 'button-selected-bg' : '') + ' ' + disableClassName}>
                                <div className='select-anchor-content button-rich-content'>
                                    <div className='button-rich-image'>
                                        <div className='image-wrapper'>
                                            <span className='no-image sprite sprite-responsive'>
                                                <span className='sprite-responsive-after' style={this.noAnchorPhotoImageData}></span>
                                            </span>

                                            <span className='anchor-image-container'>
                                                <span className={`anchor-image sprite sprite-responsive ${suggestedAnchorImageData.spriteName ?? ''}`} style={suggestedAnchorImageData.elementStyle}>
                                                    {
                                                        useResponsiveStyle
                                                            ? <span className='sprite-responsive-after' style={suggestedAnchorImageData.elementAfterStyle}></span>
                                                            : null
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        {shortTag.length > 0 ? <span className='tag'>{shortTag}</span> : null}
                                        {this.props.isNew ? <span className='new'>{newTag}</span> : null}
                                    </div>
                                </div>
                                <p className='anchor-name button-rich-text-smart'>
                                    {this.props.buttonText ?? ''}
                                </p>
                                {
                                    this.props.isUserAnchorChosen ?
                                <div  className={`anchor-name-radio ${disableClassName}`}>
                                    <div className='option-checkbox-and-text'>
                                        <label className='option-checkbox'>
                                            <input
                                                type="checkbox"
                                                id="scales"
                                                onChange={this.onChange}
                                                checked={checked}
                                                disabled={this.props.disabled}
                                                name="scales"
                                                ref={(e) => this.input = e}
                                            />
                                            <span className='checkmark'></span>
                                        </label>
                                    </div>
                                </div>
                                :
                                <div  className='delete-icon'>
                                    <button
                                        data-control-id={buttonId}
                                        onClick={this.onDeleteIconClick}
                                        className={`image sprite sprite-delete-active`}
                                        type='button'>
                                    </button>
                                </div>
                                }
                            </div>
                            :
                            <div className='button-rich select-anchor'>
                                <div className='select-anchor-content button-rich-content'>
                                    <div className='button-rich-image'>
                                        <div className='image-wrapper'>
                                            <span className='no-image sprite sprite-responsive'>
                                                <span className='sprite-responsive-after' style={this.noAnchorPhotoImageData}></span>
                                            </span>

                                            <div className='anchor-image-container'>
                                                <span className={`anchor-image sprite sprite-responsive ${suggestedAnchorImageData.spriteName ?? ''}`} style={suggestedAnchorImageData.elementStyle}>
                                                    {
                                                        useResponsiveStyle
                                                            ? <span className='sprite-responsive-after' style={suggestedAnchorImageData.elementAfterStyle}></span>
                                                            : null
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='button-rich-seperator' />
                                    <div
                                        className={`anchor-name-radio ${disableClassName}`}>
                                        <p
                                            className='anchor-name button-rich-text'
                                            onClick={this.onClick}>
                                            {this.props.buttonText ?? ''}
                                        </p>
                                        {shortTag.length > 0 ? <span className='tag'>{shortTag}</span> : null}
                                        {this.props.isNew ? <span className='new'>{newTag}</span> : null}

                                        <div
                                            className='radio-button'
                                            onClick={this.onClick}
                                            tabIndex={tabIndex}
                                            onKeyPress={this.onKeyPress} >
                                            <input
                                                data-control-id={inputId}
                                                type='radio'
                                                checked={checked}
                                                onChange={this.onChange}
                                                disabled={this.props.disabled}
                                                ref={(e) => this.input = e} />
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        );
    }

    private onClick(event: React.MouseEvent) {
        event.preventDefault();
        this.input?.click();
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked;

        if (this.props.checkedChanged != null) {
            this.props.checkedChanged(isChecked, this.props.value!);
        }
    }

    private onKeyPress(event: React.KeyboardEvent) {
        const eventCode = event.which || event.keyCode || event.charCode;

        if (eventCode == keyEnter || eventCode == keySpace) {
            event.preventDefault();
            this.input?.click();
        }
    }

    private onDeleteIconClick() {
        if (this.props.deleteIconClicked != null) {
            this.props.deleteIconClicked(this.props.value!);
        }
    }
}

export const SuggestedAnchor: typeof _SuggestedAnchor & ContolsStyleSheets = _SuggestedAnchor