<div class="custom-stiffeners-container">
    <div class="border"></div>
    <div class="custom-stiffeners-table-container">
        <div class="custom-stiffeners-Title">
            <div class="centered-text" [innerHtml]="translate('Agito.Hilti.Profis3.CustomStiffeners.Title')"></div>

            <div class="collapse">
                <button class="collapse-button" (click)="collapsedChanged()" [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                    <span class="pe-ui-pe-sprite" [class.pe-ui-pe-sprite-lines]="collapsed" [class.pe-ui-pe-sprite-lines-expanded]="!collapsed"></span>
                </button>
            </div>
        </div>

        <div class="custom-stiffeners-header" *ngIf="!collapsed">
            <div class="header-column column-rank">#</div>

            <div class="header-column column column-name">
                <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.CustomStiffeners.Name')"></span>
            </div>

            <div class="header-column column column-location" *ngIf="isLocationVisible">
                <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.CustomStiffeners.Location')"></span>
                <button type="button"
                    class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip kbTooltip bottom-right-abs"
                    (click)="openLocationInfo()">
                </button>
            </div>

            <div class="header-column column column-position">
                <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.CustomStiffeners.Position')"></span>
                <button type="button"
                    class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip kbTooltip bottom-right-abs"
                    (click)="openPositionInfo()">
                </button>
            </div>

            <div class="header-column column column-width">
                <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.CustomStiffeners.Width')"></span>
            </div>

            <div class="header-column column column-horizontal-edge-length" *ngIf="showStiffenerEdgeLength">
                <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.CustomStiffeners.HorizontalEdgeLength')"></span>
            </div>

            <div class="header-column column column-vertical-edge-length" *ngIf="showStiffenerEdgeLength">
                <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.CustomStiffeners.VerticalEdgeLength')"></span>
            </div>

            <div class="header-column column-delete">
                <button id="delete-all-button"
                    class="delete-button"
                    (click)="deleteStiffeners()"
                    [disabled]="deleteAllDisabled"
                    tooltip-enable="deleteAllDisabled"
                    [ngbTooltip]="translate('Agito.Hilti.Profis3.CustomStiffeners.DeleteAll.Tooltip')"
                    type="button">
                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-trash"></span>
                </button>
            </div>
        </div>

        <div class="custom-stiffeners-body" *ngIf="!collapsed">
            <div class="custom-stiffeners-row" *ngFor="let customStiffener of design.customStiffeners; index as i">
                <div class="column-rank">
                    <div class="text" [innerHtml]="i + 1"></div>
                </div>

                <div class="column column-name bordered-column" [ngbTooltip]="customStiffeners[i].Name">
                    <pe-text-box class="name-text-box"
                        [id]="'custom-stiffeners-name-' + i"
                        [value]="customStiffener.Name"
                        [updateOnBlur]="true"
                        (inputBlur)="nameChanged($any($event).detail, customStiffener)"
                        [marginBottom]="'None'"
                        [borderTop]="'None'"
                        [borderBottom]="'None'">
                    </pe-text-box>
                </div>

                <div *ngIf="isLocationVisible" class="column column-location"
                    [ngbTooltip]="stiffenerLocationTooltip(i)">
                    <pe-dropdown class="control-column location-dropdown"
                        [id]="'custom-stiffeners-location-dropdown-' + i"
                        [items]="allowedStiffenerLocations"
                        [selectedValue]="customStiffener.ProfileSide"
                        (selectedValueChange)="locationChanged($any($event).detail, customStiffener)"
                        [borderTop]="'None'"
                        [borderBottom]="'None'"
                        [itemsMaxHeight]="100">
                    </pe-dropdown>
                </div>

                <div class="column column-position" *ngIf="!isPipeProfile">
                    <pe-numeric-text-box
                        [id]="'custom-stiffener-position-' + i"
                        [value]="convertInternalToDefaultStiffenerOffset(customStiffener.Position, customStiffener.ProfileSide)"
                        [unit]="design.unitLength"
                        [minValue]="0"
                        [maxValue]="getMaxStiffenerOffset(customStiffener.ProfileSide)"
                        [disabled]="isStiffenerPositionConrolDisabled(customStiffener.ProfileSide)"
                        [updateOnBlur]="true"
                        [required]="true"
                        [showStepper]="false"
                        (valueChange)="positionChanged($any($event).detail, customStiffener)"
                        [borderTop]="'None'"
                        [borderBottom]="'None'">
                    </pe-numeric-text-box>
                </div>

                <div class="column column-position" *ngIf="isPipeProfile">
                    <pe-numeric-text-box
                        [id]="'custom-stiffener-position-' + i"
                        [value]="customStiffener.RadialOffset"
                        [unit]="unitDegree"
                        [disabled]="isStiffenerPositionConrolDisabled(customStiffener.ProfileSide)"
                        [updateOnBlur]="true"
                        [required]="true"
                        [showStepper]="false"
                        (valueChange)="customStiffener.RadialOffset = $any($event).detail; runCalculation()"
                        [borderTop]="'None'"
                        [borderBottom]="'None'">
                    </pe-numeric-text-box>
                </div>

                <div class="column column-width">
                    <pe-numeric-text-box
                        [id]="'custom-stiffener-length-' + i"
                        [value]="customStiffener.Width"
                        [unit]="design.unitLength"
                        [minValue]="minStiffenerLength"
                        [maxValue]="maxStiffenerLength"
                        [updateOnBlur]="true"
                        [required]="true"
                        [showStepper]="false"
                        (valueChange)="customStiffener.Width = $any($event).detail; runCalculation()"
                        [borderTop]="'None'"
                        [borderBottom]="'None'">
                    </pe-numeric-text-box>
                </div>

                <div class="column column-horizontal-edge-length" *ngIf="showStiffenerEdgeLength">
                    <pe-numeric-text-box
                        [id]="'custom-stiffener-length-' + i"
                        [value]="customStiffener.HorizontalEdgeLength"
                        [unit]="design.unitLength"
                        [minValue]="minStiffenerHorizontalEdgeLength"
                        [maxValue]="maxStiffenerHorizontalEdgeLength"
                        [updateOnBlur]="true"
                        [required]="true"
                        [showStepper]="false"
                        (valueChange)="customStiffener.HorizontalEdgeLength = $any($event).detail; runCalculation()"
                        [borderTop]="'None'"
                        [borderBottom]="'None'">
                    </pe-numeric-text-box>
                </div>

                <div class="column column-vertical-edge-length" *ngIf="showStiffenerEdgeLength">
                    <pe-numeric-text-box
                        [id]="'custom-stiffener-length-' + i"
                        class="name-text-box"
                        [value]="customStiffener.VerticalEdgeLength"
                        [unit]="design.unitLength"
                        [minValue]="minStiffenerVerticalEdgeLength"
                        [maxValue]="maxStiffenerVerticalEdgeLength"
                        [updateOnBlur]="true"
                        [required]="true"
                        [showStepper]="false"
                        (valueChange)="customStiffener.VerticalEdgeLength = $any($event).detail; runCalculation()"
                        [borderTop]="'None'"
                        [borderBottom]="'None'">
                    </pe-numeric-text-box>
                </div>
                <div class="column-delete">
                    <div class="delete-container">
                        <button id="delete-stiffener-{{i}}-button" class="delete-button" (click)="deleteStiffener(i)"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.CustomStiffeners.Delete.Tooltip')"
                            type="button">
                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-trash"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="custom-stiffeners-footer" *ngIf="!collapsed">
            <pe-button
                class="sub-footer-button button"
                [look]="'Ghost'"
                (buttonClick)="newCustomStiffener()"
                [text]="translate('Agito.Hilti.Profis3.CustomStiffeners.Add')">
            </pe-button>
        </div>
    </div>
</div>
