
import { Injectable } from '@angular/core';
import { MenuServiceInjected } from '@profis-engineering/pe-ui-common/services/menu.common';
import { CalculationServicePE } from './calculation-pe.service';
import { CodeListService } from './code-list.service';
import { FavoritesService } from './favorites.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { LicenseService } from './license.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { MenuServiceExtensions } from './menu-extensions';
import { ModalService } from './modal.service';
import { NavigationService } from './navigation.service';
import { SmartAnchorOrderService } from './smart-anchor-order.service';
import { TourService } from './tour.service';
import { TranslationFormatService } from './translation-format.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';
import { DesignPe } from '../../shared/entities/design-pe';

@Injectable({
    providedIn: 'root'
})
export class MenuService extends MenuServiceInjected<DesignPe> {
    constructor(
        private calculationService: CalculationServicePE,
        private codeListService: CodeListService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private licenseService: LicenseService,
        private localizationService: LocalizationService,
        private loggerService: LoggerService,
        private modalService: ModalService,
        private navigationService: NavigationService,
        private smartAnchorOrderService: SmartAnchorOrderService,
        private unitService: UnitService,
        private userService: UserService,
        private userSettingsService: UserSettingsService,
        private translationFormatService: TranslationFormatService,
        private favoritesService: FavoritesService,
        private tourService: TourService
    ) {
        super();
    }

    public initialize() {
        this.setMenuExtensions(new MenuServiceExtensions(
            this.modalService,
            this.navigationService,
            this.userService,
            this.localizationService,
            this.calculationService,
            this.loggerService,
            this.unitService,
            this.codeListService,
            this.userSettingsService,
            this.favoritesService,
            this.translationFormatService,
            this.smartAnchorOrderService,
            this.tourService,
            this.licenseService,
            this.featuresVisibilityInfoService));
    }
}
