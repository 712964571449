<div class="utilizations box-section box-section-white" *ngIf="showUtilizations">
    <div class="box-section-header-with-img" *ngIf="showAddOn">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-material"></div>
            </div>
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.ConcreteUtilizations')"></span>
        </div>

        <div class="add-on" [innerHtml]="translate('Agito.Hilti.Profis3.Main.Region.AddOn')"></div>
    </div>

    <div class="box-section-header-with-img" *ngIf="hasUtilizations">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-material"></div>
            </div>
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.ConcreteUtilizations')"></span>
            <span class="bar-subtitle"
                *ngIf="!collapsed && areLoadCombinationsAvailable" [innerHtml]="loadCombination"></span>
        </div>

        <pe-utilization-bar [collapsed]="collapsed" [utilizationInvalid]="utilizationInvalid" [utilizationValue]="utilizationValue"></pe-utilization-bar>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content" *ngIf="hasUtilizations">
        <div class="utilization" *ngIf="loadBearingWorst != null"
            [class.invalid-value]="loadBearingWorst.valueError">
            <div id="{{id}}-baseplate-utilizations-button" class="utilization-button">
                <div class="button-content">
                    <div class="text-progress-container">
                        <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.ConcreteUtilizations.LoadBearingConcrete')"></p>
                        <div class="progress-percent-container" *ngIf="loadBearingWorst != null">
                            <div class="progress-percent" [ngStyle]="{ 'width': loadBearingWorst.unityCheckStress + '%' }"></div>
                        </div>
                    </div>

                    <div class="number-container">
                        <div class="worst-result details-line" *ngIf="loadBearingWorst.unityCheckStress != null"
                            [class.invalid-value]="loadBearingWorst.valueError">
                            <p class="text" [innerHtml]="translate(unityCheckStressText)"></p>
                            <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(loadBearingWorst.unityCheckStress, unitGroupEnum.Percentage)"></p>
                        </div>

                        <div class="details" *ngIf="!DG7ConcreteUtilizations && concreteOpend">
                            <div class="worst-result details-line" *ngIf="loadBearingWorst.averageStress != null"
                                [class.invalid-value]="loadBearingWorst.valueError">
                                <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.ConcreteUtilizations.SigmaConcrete')"></p>
                                <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(loadBearingWorst.averageStress, valueUnits)"></p>
                            </div>

                            <div class="worst-result details-line" *ngIf="loadBearingWorst.value != null"
                                [class.invalid-value]="loadBearingWorst.valueError">
                                <p class="text" [innerHtml]="valueText"></p>
                                <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(loadBearingWorst.value, valueUnits)"></p>
                            </div>
                        </div>
                    </div>

                    <div class="design-right-collapse-container" *ngIf="!DG7ConcreteUtilizations"
                        [class.opened]="concreteOpend">
                        <span class="design-right-collapse-line"></span>
                        <button type="button" class="design-right-collapse-button"
                            (click)="concreteOpend = !concreteOpend">
                            <span class="pe-ui-pe-sprite" [ngClass]="concreteOpend ? 'pe-ui-pe-sprite-arrow-up' : 'pe-ui-pe-sprite-arrow-down'"></span>
                        </button>
                        <span class="design-right-collapse-line"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
