import { DesignPe } from '../../shared/entities/design-pe';
import { ScopeCheck } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.ScopeChecks';
import { DesignStandard, DesignType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export function isSmartAnchorAllowed(design: DesignPe) {
    if (design.designType?.id != DesignType.Concrete) {
        return false;
    }

    const regionPe = DesignPe.getRegionPe(design);
    if (!regionPe.isSmartAnchorAvailable) {
        return false;
    }

    if (design.designStandard.id != regionPe.defaultConcreteDesignStandardId) {
        return false;
    }

    return true;
}

export function isSmartAnchorToggleAllowed(design: DesignPe) {
    if (design.designType?.id != DesignType.Concrete) {
        return false;
    }

    const regionPe = DesignPe.getRegionPe(design);
    const designStandard=design.designStandard.id;

    if (!regionPe.isSmartAnchorAvailable) {
        return false;
    }

    if (designStandard != regionPe.defaultConcreteDesignStandardId) {
        return false;
    }

    if (!(designStandard == DesignStandard.EC2 || designStandard == DesignStandard.ACI || designStandard == DesignStandard.HK
        || designStandard == DesignStandard.TW401 || designStandard == DesignStandard.KR || designStandard == DesignStandard.IS
        || designStandard == DesignStandard.NZ || designStandard == DesignStandard.SATS || designStandard == DesignStandard.CSA
        || designStandard == DesignStandard.TH)) {
        return false;
    }

    return true;
}

export function smartAnchorDefaultScopeChecks(): number[]{
    return [ScopeCheck.SC_NOLOAD, ScopeCheck.SC_SUSTAINED_LOADS, ScopeCheck.SC_NOLOAD_ALL_LOAD_COMBINATIONS, ScopeCheck.SC_BP_MULTIPLE_LOAD_COMBINATIONS];
}


export function mockHtml(region : string, regionId: string){
    const data= document.createElement('div');

    data.setAttribute('data-control-id', regionId);
    data.setAttribute('class','region box-section');
    data.setAttribute('data-drag-id', regionId);
    data.innerHTML = region;

    return data;
}
