import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation, ElementRef
} from '@angular/core';
import {
    RadioButtonItem
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import {
    LoadCombination, LoadCombinationHandrail
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignType, LoadType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    TextBoxDisplay
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';

import { InternalLoadType, LoadsComponentHelper } from '../../helpers/loads-component-helper';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { getSpriteAsIconStyle, includeSprites, Sprite } from '../../sprites';

@Component({
    templateUrl: './loads-row.component.html',
    styleUrls: ['./loads-base.scss', './loads-rows-base.scss', './loads-row.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsRowComponent implements OnInit, OnChanges {
    @Input()
    public isUtilizationVisible!: boolean;

    @Input()
    public resizerWrapper!: HTMLElement;

    @Input()
    public parentId!: string;

    @Input()
    public load!: LoadCombination & Partial<LoadCombinationHandrail>;

    @Input()
    public loadIndex!: number;

    @Input()
    public isAdd = false;

    @Input()
    public helper!: LoadsComponentHelper;

    @Input()
    public selectedLoadId!: string;

    @Input()
    public showPostAndRailDeflection = false;

    @Input()
    public disabled = false;

    @Input()
    public submitted = false;

    @Input()
    public isUtilizationCompact = false;

    @Input()
    public isHandrailUtilizationCompact = false;

    @Input()
    public isDecisiveLoadCombinationFn!: (load: LoadCombination) => boolean;

    @Output()
    public loadSelect = new EventEmitter<string>();

    @Output()
    public loadAdd = new EventEmitter<LoadCombination>();

    @Output()
    public loadChange = new EventEmitter<LoadCombination>();

    @Output()
    public loadDelete = new EventEmitter<LoadCombination>();

    @Output()
    public columnsResize = new EventEmitter<void>();

    public selectedLoadItems: RadioButtonItem<string>[] = [];
    public loadTypeToggleItems: ToggleButtonGroupItem<InternalLoadType>[] = [];

    private continueColumnResizingFn!: (event: MouseEvent) => void;
    private endColumnResizingFn!: (event: MouseEvent) => void;

    private readonly loadsRowInputNameLargeHeight: number = 65;

    private loadSprites: [InternalLoadType, Sprite][] = [
        [InternalLoadType.Static, 'sprite-anchor-shock'],
        [InternalLoadType.Seismic, 'sprite-anchor-seismic'],
        [InternalLoadType.Fatigue, 'sprite-anchor-fatigue'],
        [InternalLoadType.Fire, 'sprite-anchor-fire-resistant']
    ];

    constructor(
        private localization: LocalizationService,
        private user: UserService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-x',
            'sprite-ok',
            'sprite-warning',
            'sprite-warning-gray',
            'sprite-trash');

        this.continueColumnResizingFn = this.continueColumnResizing.bind(this);
        this.endColumnResizingFn = this.endColumnResizing.bind(this);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['load'] != null) {
            if (this.selectedLoadItems.length < 1) {
                this.selectedLoadItems.push({
                    id: '',
                    text: '',
                    value: this.load.Id
                });
                this.setSelectedLoadItemId(this.selectedLoadItems[0]);
            }
            else {
                // Load changed (probably due to the virtual scrolling)!
                this.setSelectedLoadItemId(this.selectedLoadItems[0]);
                this.selectedLoadItems[0].value = this.load.Id;
            }

            const loadToIconMap = new Map<InternalLoadType, IIconStyle>(this.loadSprites.map(m => [m[0], getSpriteAsIconStyle(m[1])]));
            this.loadTypeToggleItems = this.helper.loadLoadTypeToggleItems(this.parentId, this.loadIndex, loadToIconMap);

            if (this.loadTypeToggleItems?.length > 0) {
                this.loadTypeToggleItems.forEach(item => this.helper.setLoadTypeToggleItemId(item, this.parentId, this.loadIndex));
            }
        }

        if(this.helper.isSmartAnchorToggleON && this.helper.addingNewLoad && !this.isAdd) {
            this.helper.addingNewLoad = false;
        }
    }

    public get dataLoaded() {
        if (this.load == null) {
            return false;
        }

        if (this.helper == null) {
            return false;
        }

        if (this.design == null) {
            return false;
        }

        if (!this.isAdd && this.isDecisiveLoadCombinationFn == null) {
            return false;
        }

        return true;
    }

    public get design() {
        return this.user.design;
    }

    public get selectionDisabled() {
        return this.design.designType.id == DesignType.Handrail && this.design.showAllLoads;
    }

    public get textBoxDisplay() {
        return !this.isAdd && this.isDecisiveLoadCombinationFn(this.load)
            ? TextBoxDisplay.Bold
            : TextBoxDisplay.Normal;
    }

    public get nameInputHeight() {
        return this.helper.isLoadDynamic(this.load)
            ? this.loadsRowInputNameLargeHeight
            : undefined;
    }

    public selectLoad() {
        this.loadSelect.emit(this.load.Id);
    }

    public loadNameChanged(name: string) {
        if (name !== undefined) {
            if (this.load.Name != name) {
                this.load.Name = name;

                if (this.isAdd) {
                    // Handled when actually adding a load
                    return;
                }

                this.loadChange.emit(this.load);
            }
        }
    }

    public loadActiveLoadTypeChange(type: InternalLoadType) {
        if (type !== undefined) {
            const loadType = (type as number) as LoadType;
            if (this.load.ActiveLoadType != loadType) {
                this.load.ActiveLoadType = loadType;

                if (this.isAdd) {
                    // Handled when actually adding a load
                    return;
                }

                this.loadChange.emit(this.load);
            }
        }
    }

    public loadValueChange(property: keyof LoadCombination, value: number) {
        if (property != null && value != null) {
            if (this.load[property] != value) {
                (this.load[property] as number) = value;

                if (this.isAdd) {
                    // Handled when actually adding a load
                    return;
                }

                this.loadChange.emit(this.load);
            }
        }
    }

    public addNewLoad() {
        this.loadAdd.emit(this.helper.newLoad);
    }

    public deleteLoad(load: LoadCombination) {
        this.loadDelete.emit(load);
    }
    public isStructuralSafetyConnectionPlatesInvalid() {
        if (this.load.StructuralSafetyConnectionPlates != null) {
            return this.load.StructuralSafetyConnectionPlates.ValueError;
        }

        return false;
    }

    public isStructuralSafetyConnectionWeldsInvalid() {
        if (this.load.StructuralSafetyConnectionWelds != null) {
            return this.load.StructuralSafetyConnectionWelds.ValueError;
        }

        return false;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    private setSelectedLoadItemId(item: RadioButtonItem<string>) {
        item.id = `${this.parentId}-${this.loadIndex != null ? this.loadIndex : 'new-load'}-selected-button`;
    }


    // Resizing
    public beginColumnResizing(event: MouseEvent) {
        event.preventDefault();
        const mouse = this.helper.calculateMouseInsideElement(event, this.resizerWrapper);

        this.helper.beginColumnResizing(mouse, event.currentTarget as HTMLElement);

        // Append resized and attach events
        this.resizerWrapper.append(this.helper.resizerOverlayElement);
        document.addEventListener('mousemove', this.continueColumnResizingFn, false);
        document.addEventListener('mouseup', this.endColumnResizingFn, false);
    }

    private continueColumnResizing(event: MouseEvent) {
        event.preventDefault();
        const mouse = this.helper.calculateMouseInsideElement(event, this.resizerWrapper);

        this.helper.continueColumnResizing(mouse);
    }

    private endColumnResizing(event: MouseEvent) {
        event.preventDefault();
        const mouse = this.helper.calculateMouseInsideElement(event, this.resizerWrapper);

        this.helper.endColumnResizing(mouse);

        // Cleanup
        this.helper.resizerOverlayElement.remove();
        document.removeEventListener('mousemove', this.continueColumnResizingFn, false);
        document.removeEventListener('mouseup', this.endColumnResizingFn, false);

        this.columnsResize.emit();
    }
}
