import {
    UtilizationPlasticStrainEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign.UtilizationAnchorPlate';

export interface IVonMisesResult {
    maxStrain: number;
    maxStress: number;
    limitPlasticStrain: number;
    materialFy: number;
    materialFyDividedByGammaM0: number;
    materialFyDividedByPhiSteel: number;
    materialName: string;
    valueError: boolean;
    textError: boolean;
    percentage: number;
}

export function getVonMisesWorst(plasticStrain?: UtilizationPlasticStrainEntity) {
    if (plasticStrain == null) {
        return undefined;
    }

    const worst = plasticStrain;

    return {
        maxStress: worst.MaxStress,
        maxStrain: worst.MaxStrain,
        limitPlasticStrain: worst.LimitPlasticStrain,
        materialFy: worst.MaterialFy,
        materialFyDividedByGammaM0: worst.MaterialFyDividedByGammaM0,
        materialFyDividedByPhiSteel: worst.MaterialFyDividedByPhiSteel,
        materialName: worst.MaterialName,
        textError: !worst.CheckStatus,
        valueError: !worst.CheckStatus,
        percentage: worst.UtilizationPercentage
    } as IVonMisesResult;
}
