import AnchorType_Bonding from './AnchorType_Bonding.png';
import AnchorType_Friction from './AnchorType_Friction.png';
import AnchorType_Keying from './AnchorType_Keying.png';
import Cleaning_Automatic from './Cleaning_Automatic.png';
import Cleaning_CompressedAir from './Cleaning_CompressedAir.png';
import Cleaning_Manual from './Cleaning_Manual.png';
import CorrosionMaterial_Warning from './CorrosionMaterial_Warning.png';
import Threaded_External from './Threaded_External.png';
import Threaded_Internal from './Threaded_Internal.png';

const images = {
    'AnchorType_Bonding.png': AnchorType_Bonding,
    'AnchorType_Friction.png': AnchorType_Friction,
    'AnchorType_Keying.png': AnchorType_Keying,
    'Cleaning_Automatic.png': Cleaning_Automatic,
    'Cleaning_CompressedAir.png': Cleaning_CompressedAir,
    'Cleaning_Manual.png': Cleaning_Manual,
    'CorrosionMaterial_Warning.png': CorrosionMaterial_Warning,
    'Threaded_External.png': Threaded_External,
    'Threaded_Internal.png': Threaded_Internal
} satisfies Record<string, string>;

export default images as typeof images & { [imageName: string]: string };
