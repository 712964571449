<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.MultipleApprovals.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="section buttons-section">
        <pe-button *ngFor="let file of files; index as i"
            [look]="'Default'"

            class="button button-default button-approval"
            [id]="'multiple-approvals-cancel-button' + i"
            (click)="openApproval(i)"
            [text]="file">
        </pe-button>
    </div>
</pe-modal-body>