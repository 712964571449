import uniqBy from 'lodash-es/uniqBy';

import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import { CodeList } from '../../../shared/entities/code-lists/code-list';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    IUserSelectedAnchorGroupProps, UserSelectedAnchorGroup
} from '../../components/main-menu/user-selected-anchor-group/UserSelectedAnchorGroup';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { NumberService } from '../../services/number.service';
import { getSpriteAsIconStyleResponsive } from '../../sprites';
import { isAnchorFamilyMarkedAsNew, updateUiProperty } from './menu-helper';
import { ISuggestedAnchorGroupItem, ISuggestedAnchorGroupProps } from '../../components/main-menu/suggested-anchor-group/SuggestedAnchorGroup';
import { CalculationServicePE } from '../../services/calculation-pe.service';

export function initializeSelectUserSelectedAnchorGroup(
    codeListService: CodeListService,
    calculationService: CalculationServicePE,
    loggerService: LoggerService,
    design: DesignPe,
    controlProps: IUserSelectedAnchorGroupProps,
    localizationService: LocalizationService,
    numberService: NumberService,
    uiPropertyId: number,
    controlName: string,
    codelistName?: string) {
    controlProps.type = UserSelectedAnchorGroup;
    controlProps.checkedValue = design.model[uiPropertyId] as number;
    controlProps.isSmartBaseplateEnable = design.model[UIProperty.SmartAnchor_Enabled] as boolean;
    controlProps.isUserAnchorChosen = design.model[UIProperty.AnchorUserChosen] as boolean;

    if (design.model[UIProperty.SmartAnchor_Enabled] as boolean && !design.model[UIProperty.AnchorUserChosen]) {
        controlProps.checkedValues = design.model[UIProperty.SmartAnchor_UserSelectedAnchorGroupsForAsad] as number[] ?? [];
    }
    let codeListItems: CodeList[] | undefined;

    if (codelistName != null && codelistName != '') {
        const designCodeList = DesignCodeList[codelistName as keyof typeof DesignCodeList];
        const projectCodeList = ProjectCodeList[codelistName as keyof typeof ProjectCodeList];

        if (designCodeList != null) {
            codeListItems = getCodeListItems(loggerService, codelistName, design, designCodeList, uiPropertyId);
        }
        else if (projectCodeList != null) {
            codeListItems = getProjectCodeListItemsForAnchorData(codeListService, loggerService, uiPropertyId, codelistName, design, controlName, projectCodeList);
        }
    }
    controlProps.items = getUserSelectedAnchorGroupFilteredItems(uiPropertyId, design, localizationService, numberService, codeListItems);

    controlProps.deleteIconClicked = (value) => {
        const userSelectedAnchorGroup = design.model[UIProperty.SmartAnchor_UserSelectedAnchorGroupsForAsad] as number[];
        if (userSelectedAnchorGroup.includes(value)) {
            const index = userSelectedAnchorGroup.indexOf(value);
            userSelectedAnchorGroup.splice(index, 1);
            updateUiProperty(calculationService, design, uiPropertyId, userSelectedAnchorGroup);
        }
    };

    const onStateChanged = (state: IDesignState) => {
        const items = getUserSelectedAnchorGroupFilteredItems(uiPropertyId, design, localizationService, numberService, codeListItems);

        return ({
            checkedValue: state.model[uiPropertyId],
            checkedValues: state.model[UIProperty.SmartAnchor_AnchorGroupsForAsad],
            isSmartBaseplateEnable: design.model[UIProperty.SmartAnchor_Enabled],
            isUserAnchorChosen:  design.model[UIProperty.AnchorUserChosen],
            items
        } as any) as ISuggestedAnchorGroupProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}

function getUserSelectedAnchorGroupFilteredItems(uiPropertyId: number, design: DesignPe, localizationService: LocalizationService, numberService: NumberService, codeListItems?: CodeList[]) {
    const getUserSelectedAnchorDataFn = (anchorFamilyId?: number) => {
        let textAnchor: string | undefined;
        let tagAnchor: string | undefined;
        let imageAnchor: string | undefined;
        let imageStyleAnchor: IResponsiveIconStyle | undefined;

        if (anchorFamilyId != null) {
            const anchorFamilies = design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];
            const selectedAnchorFamily = anchorFamilies.find((anchorFamily) => anchorFamily.id == anchorFamilyId);
            textAnchor = selectedAnchorFamily?.getTranslatedNameText({ localizationService });
            tagAnchor = selectedAnchorFamily?.detailed?.tag;
            imageAnchor = selectedAnchorFamily?.image;
            if (selectedAnchorFamily?.image != null && selectedAnchorFamily.image != '') {
                imageStyleAnchor = {
                    elementStyle: {},
                    afterElementStyle: getSpriteAsIconStyleResponsive(selectedAnchorFamily.image)
                };
            }
        }
        return {
            buttonText: textAnchor,
            buttonImage: imageAnchor,
            buttonImageStyle: imageStyleAnchor,
            tag: tagAnchor
        };
    };

    const formatDisplayAnchorDataString = (textKey: string | undefined, design: DesignPe) => {
        if (textKey == undefined || textKey.length == 0) {
            return undefined;
        }
        const variables = { DesignMethodReference: localizationService.getString(textKey + '.DesignMethodReference.' + design.designMethodGroup?.displayKey, { optional: true }) };
        return formatKeyValue(localizationService.getString(textKey), variables);
    };

    const codeListItemsMapperFn = (codeListItem: CodeList) => {
        const buttonData = getUserSelectedAnchorDataFn(codeListItem.id);
        return {
            value: codeListItem.id,
            text: codeListItem.getTranslatedNameText({ localizationService, numberService }),
            tooltip: formatDisplayAnchorDataString(codeListItem.tooltipDisplayKey, design),
            tooltipTitle: formatDisplayAnchorDataString(codeListItem.tooltipTitleDisplayKey, design),
            uiPropertyId: uiPropertyId,
            isNew: isAnchorFamilyMarkedAsNew(codeListItem.id, design),
            buttonText: buttonData.buttonText,
            tag: buttonData.tag,
            buttonImage: buttonData.buttonImage,
            buttonImageStyle: buttonData.buttonImageStyle
        } as ISuggestedAnchorGroupItem;
    };
    const allowedValues =  design.model[UIProperty.SmartAnchor_UserSelectedAnchorGroupsForAsad] as number[];
    const displayValuesSortIndex = allowedValues?.reduceRight((obj, val, index) => { obj[val] = index; return obj; }, {} as Record<number, number>);
    const items = codeListItems?.filter((item)=> allowedValues?.indexOf(item.id) > -1)?.map((codeListItem) => codeListItemsMapperFn(codeListItem));
    // Sort suggested anchor based on display values.
    items?.sort((a, b) => {
        return (displayValuesSortIndex?.[a.value] ?? 0) - (displayValuesSortIndex?.[b.value] ?? 0);
    });
    return items;
}

function getProjectCodeListItemsForAnchorData(codeListService: CodeListService, loggerService: LoggerService, uiPropertyId: number, codelistName: string, design: DesignPe, navigationControlName: string, codeListForAnchor: ProjectCodeList) {
    let codeListItemsForAnchor: CodeList[];
    if (codeListForAnchor != null && (codeListItemsForAnchor = codeListService.projectCodeLists[codeListForAnchor]) != null) {
        const uniqCodeListItemsForAnchor = uniqBy(codeListItemsForAnchor, x => x.id);
        if (uniqCodeListItemsForAnchor.length != codeListItemsForAnchor.length) {
            console.error(`Duplicate id found in codelist ${ProjectCodeList[codeListForAnchor]} for control ${navigationControlName}`);
        }
        return uniqCodeListItemsForAnchor;
    }
    logMissingCodeListData(loggerService, codelistName, uiPropertyId, design);
    return undefined;
}

function getCodeListItems(loggerService: LoggerService, codelistName: string, design: DesignPe, codeList: DesignCodeList, uiPropertyId: number) {
    let codeListItemsForAnchor: CodeList[];
    if (codeList != null && (codeListItemsForAnchor = design.designData.designCodeLists[codeList]) != null) {
        return codeListItemsForAnchor;
    }
    logMissingCodeListData(loggerService, codelistName, uiPropertyId, design);
    return undefined;
}

function logMissingCodeListData(loggerService: LoggerService, codelistName: string, uiPropertyId: number, design: DesignPe) {
    if (!design.properties.get(uiPropertyId).hidden) {
        loggerService.log(`Missing code list data: ${codelistName}`, LogType.warn);
    }
}
