import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { LocalizationService } from '../../services/localization.service';
import { getSpriteAsIconStyleResponsive } from '../../sprites';
import { IInformationPanelProps, InformationPanel } from '../../components/main-menu/information-panel/InformationPanel';

export function initializeInformationPanel(
    design: DesignPe,
    controlProps: IInformationPanelProps,
    localizationService: LocalizationService,
    uiPropertyId: number,
    displayKey?: string,
    imageName?: string) {
    controlProps.type = InformationPanel;

    controlProps.panelText =  displayKey ? localizationService.getString(displayKey) : '';
    controlProps.panelImage = imageName ? `sprite-${imageName}` : '';
    controlProps.imageStyle = getSpriteAsIconStyleResponsive(controlProps.panelImage);

    const onStateChanged = (state: IDesignState) => {
        return {
            panelImage: controlProps.panelImage,
            panelText: controlProps.panelText,
            value: state.model[uiPropertyId]
        };
    };

    return {
        controlProps,
        onStateChanged
    };
}