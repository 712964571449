import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import calculationTypeInfoFlexibleImage from '../../../images/calculation-type-info-flexible.png';
import calculationTypeInfoRigidImage from '../../../images/calculation-type-info-rigid.png';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './advanced-calculation-type-info.component.html',
    styleUrls: ['./advanced-calculation-type-info.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AdvancedCalculationTypeInfoComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public rigidImage!: string;
    public flexibleImage!: string;

    constructor(
        private localizationService: LocalizationService,
        private userService: UserService,
    ) { }

    public ngOnInit(): void {
        this.rigidImage = calculationTypeInfoRigidImage;
        this.flexibleImage = calculationTypeInfoFlexibleImage;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public learnMoreClicked() {
        const design = this.userService.design;
        if (design != null) {
            design.usageCounter.LearnMore_AdvancedCalculationTypesClicked++;
        }
    }
}
