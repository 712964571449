import { NgZone, Injectable } from '@angular/core';
import { IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { IImportData } from '@profis-engineering/pe-ui-common/entities/import-data';
import { IImportDesignProvider, IProjectDesignEntity } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { CalculationServicePE } from './calculation-pe.service';
import { ProjectDesignBaseEntity } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { DesignType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { DesignPe } from '../../shared/entities/design-pe';

@Injectable({
    providedIn: 'root'
})
export class ImportDesignProviderService implements IImportDesignProvider {
    public path = UrlPath.main;

    constructor(
        private calculationServicePE: CalculationServicePE,
        private modalService: ModalService,
        private localizationService: LocalizationService,
        private ngZone: NgZone
    ) { }

    public getValidFileExtensions(): string[] {
        return ['.pe', '.pa2', '.profis3'];
    }

    public checkFile(fileContent: string, fileName?: string): boolean {
        if (this.checkPeDesign(fileContent, fileName)) {
            return true;
        }

        return false;
    }

    public async createProjectDesignEntity(projectDesign: File | Blob, projectName: string, _fileNameProposal: string, designName: string): Promise<IProjectDesignEntity> {
        return await this.ngZone.run(async () => this.calculationServicePE.createProjectDesignFromFile(projectDesign, projectName, designName, true));
    }

    public async updateDesignFromExternalFile(oldDesign: IBaseDesign, projectDesign: IProjectDesignEntity, disableCalcMessages?: boolean): Promise<DesignPe> {
        return this.ngZone.run(() => this.calculationServicePE.updateDesignFromExternalFile(oldDesign, projectDesign as ProjectDesignBaseEntity, disableCalcMessages));
    }

    public async createDesign(importData: IImportData, projectId: string, name: string, secondTry: boolean, disableCalcMessages: boolean, trackingEnabled?: boolean): Promise<IImportData> {
        const designCreated = async (projectDesign: ProjectDesignBaseEntity, name: string) => {
            if (projectDesign.ProjectDesignType == DesignType.Handrail && name.toLowerCase().endsWith('.pa2')) {
                await this.modalService.openConfirmChange({
                    id: 'import-design-p2-file',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.Main.Importpa2Dialog.Title'),
                    message: this.localizationService.getString('Agito.Hilti.Profis3.Main.Importpa2Dialog.message'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.Importpa2Dialog.OK'),
                    onConfirm: (modal) => {
                        modal.close();
                    }
                }).closed;
            }
        };

        const designOrImportData = await this.ngZone.run(async () => this.calculationServicePE.createAndOpenFromProjectDesignFile(importData.projectDesign as ProjectDesignBaseEntity, projectId, name, designCreated, !secondTry, disableCalcMessages, trackingEnabled));
        importData.design = designOrImportData;

        return importData;
    }

    public setDesignName(projectDesignEntity: IProjectDesignEntity, name: string): void {
        (projectDesignEntity as ProjectDesignBaseEntity).DesignName = name;
    }

    private checkPeDesign(fileContent: string, fileName?: string) {
        const isPeFile = fileName == null || this.getValidFileExtensions().some(ext => fileName.toLowerCase().endsWith(ext)); // If no file name is provided, we assume it is a PE file

        return isPeFile && (fileContent.startsWith('<?xml') || fileContent.startsWith('<DSProjects'));
    }
}