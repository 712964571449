import { Injectable } from '@angular/core';
import {
  QueryServiceInjected
} from '@profis-engineering/pe-ui-common/services/query.common';

@Injectable({
  providedIn: 'root'
})
export class QueryService extends QueryServiceInjected {
}
