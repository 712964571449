import { HoleTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class HoleType extends CodeList {

    public static fromService(codeList: HoleTypeEntity) {
        return new HoleType(CodeList.baseFromService('HoleTypeEntity', codeList));
    }

    public override cloneDeep(): HoleType {
        return super.cloneDeep() as HoleType;
    }
}
