import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import { UIProperty } from '../../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { CoordinateSystemCenter } from '../../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { Center2dMode } from '@profis-engineering/pe-gl-model/components/2d/center-2d';

export class Center2dUpdate extends BaseUpdate {
    public static propertyCoordinateSystemCenterToModelCenter2dMode(coordinateSystemCenter: CoordinateSystemCenter) {
        switch (coordinateSystemCenter) {
            case CoordinateSystemCenter.Baseplate:
                return Center2dMode.Baseplate;
            case CoordinateSystemCenter.Profile:
                return Center2dMode.Profile;
            case CoordinateSystemCenter.Custom:
                return Center2dMode.Custom;
            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown coordinate system center');
        }
    }

    @propertyValueChanged(UIProperty.Option_CoordinateSystemCenter)
    private Option_CoordinateSystemCenter(coordinateSystemCenter: CoordinateSystemCenter, model: IModelPe) {
        const value = coordinateSystemCenter;
        if (model.center2d)
            model.center2d.mode = Center2dUpdate.propertyCoordinateSystemCenterToModelCenter2dMode(value);
    }

    @propertyValueChanged(UIProperty.Option_CoordinateSystemCenterX)
    private Option_CoordinateSystemCenterX(value: number, model: IModelPe) {
        if (model.center2d)
            model.center2d.positionX = value;
    }

    @propertyValueChanged(UIProperty.Option_CoordinateSystemCenterY)
    private Option_CoordinateSystemCenterY(value: number, model: IModelPe) {
        if (model.center2d)
            model.center2d.positionY = value;
    }
}
