import {
    HandrailLoadInstances
} from '../../../../shared/generated-modules/Hilti.PE.Core.Calculation.Enums';
import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    BuildingCategory, DesignType, HandrailApplicationType, HandrailBeamType, HandrailConnectionType,
    HorizontalPostProfileConnectionType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    HandrailHelper
} from '../../../../shared/helpers/handrail-helper';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { propertyValueChanged } from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import {
    ApplicationType, CladdingType, ConnectionType
} from '@profis-engineering/pe-gl-model/components/handrail';
import {
    HandrailApplicationType as HandrailApplicationTypeEntity
} from '../../../../shared/entities/code-lists/handrail-application-type';
import { ProfileFamily } from '../../../../shared/entities/code-lists/profile-family';
import { ProfileSize } from '../../../../shared/entities/code-lists/profile-size';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { ProfileUpdate } from './ProfileUpdate';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class HandrailUpdate extends BaseUpdate {

    public override shouldUpdate(model: IModelPe, design: DesignPe) {
        return design.designType.id == DesignType.Handrail;
    }

    @propertyValueChanged(UIProperty.Application_Application)
    private Application_Application(applicationId: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        const codeList = design.designData.designCodeLists[DesignCodeList.HandrailApplication] as HandrailApplicationTypeEntity[];
        const application = codeList.find((application) => application.id == applicationId);

        model.handrail.applicationType = this.propertyApplicationTypeToModalApplicationType(application?.handrailApplicationType);
        model.handrail.platePosition = (application?.handrailFasteningType as number);
    }

    @propertyValueChanged(UIProperty.Application_ConnectionType)
    private Application_ConnectionType(connectionType: HandrailConnectionType, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.connectionType = this.propertyConnectionTypeToModalConnectionType(connectionType);
    }

    @propertyValueChanged(UIProperty.Application_BeamToBeamDistance)
    private Application_BeamToBeamDistance(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.beamSpacing = value;
    }

    @propertyValueChanged(UIProperty.Application_BeamType)
    private Application_BeamType(beamType: HandrailBeamType, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.beamType = (beamType as number);
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ActiveBeam)
    private BaseMaterial_ActiveBeam(activeBeam: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.activeBeam = activeBeam;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BeamLength)
    private BaseMaterial_BeamLength(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.beamLength = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BeamLeverArm)
    private BaseMaterial_BeamLeverArm(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.beamLeverArm = value;
    }

    @propertyValueChanged(UIProperty.Application_BeamBottomSegment)
    private Application_BeamBottomSegment(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.beamBottomSegment = value;
    }

    @propertyValueChanged(UIProperty.Profile_HandrailRailProfileFamilyId)
    private Profile_HandrailRailProfileFamilyId(profileId: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        if (profileId != null && profileId != 0) {
            const codeList = design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];
            const profile = codeList.find((profile) => profile.id == profileId);

            // change profile shape
            model.handrail.railShape = ProfileUpdate.propertyProfileShapeToModalProfileShape(profile?.shape);
        }
        else {
            model.handrail.railShape = undefined;
        }
    }

    @propertyValueChanged(UIProperty.Profile_HandrailRailProfileSizeId)
    private Profile_HandrailRailProfileSizeId(profileSizeId: number, model: IModelPe, design: DesignPe) {
        if (profileSizeId != null && model.handrail) {
            const codeList = design.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];
            const profileSize = codeList.find((profileSize) => profileSize.id == profileSizeId);

            model.handrail.railWidth = profileSize?.width;
            model.handrail.railHeight = profileSize?.height;
            model.handrail.railThickness = profileSize?.thickness;
            model.handrail.railFlangeThickness = profileSize?.flangeThickness;
        }
        else {
            throw new Error('Unknown profile size.');
        }
    }

    @propertyValueChanged(UIProperty.Profile_HandrailRailAngle)
    private Profile_HandrailRailAngle(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.railAngleY = -value;
    }

    @propertyValueChanged(UIProperty.Profile_HandrailPostAngle)
    private Profile_HandrailPostAngle(value: number, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.angleY = -value;
    }

    @propertyValueChanged(UIProperty.Profile_HandrailPostProfileFamilyId)
    private Profile_HandrailPostProfileFamilyId(profileId: number, model: IModelPe, design: DesignPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        if (profileId != null && profileId != 0) {
            const codeList = design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];
            const profile = codeList.find((profile) => profile.id == profileId);

            // change profile shape
            model.profile.shape = ProfileUpdate.propertyProfileShapeToModalProfileShape(profile?.shape);
        }
        else {
            model.profile.shape = undefined;
        }
    }

    @propertyValueChanged(UIProperty.Profile_HorizontalPostProfileSizeId)
    private Profile_HorizontalPostProfileSizeId(profileSizeId: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        if (profileSizeId != null) {
            const codeList = design.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];
            const profileSize = codeList.find((profileSize) => profileSize.id == profileSizeId);

            model.handrail.horizontalPostWidth = profileSize?.width;
            model.handrail.horizontalPostHeight = profileSize?.height;
            model.handrail.horizontalPostThickness = profileSize?.thickness;
            model.handrail.horizontalPostFlangeThickness = profileSize?.flangeThickness;
        }
        else {
            model.handrail.horizontalPostWidth = design.model[UIProperty.Profile_CustomWidth] as number;
            model.handrail.horizontalPostHeight = design.model[UIProperty.Profile_CustomHeight] as number;
            model.handrail.horizontalPostThickness = design.model[UIProperty.Profile_CustomThickness] as number;
            model.handrail.horizontalPostFlangeThickness = design.model[UIProperty.Profile_CustomFlangeThickness] as number;
        }
    }

    @propertyValueChanged(UIProperty.Profile_HorizontalPostProfileFamilyId)
    private Profile_HorizontalPostProfileFamilyId(profileId: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        if (profileId != null && profileId != 0) {
            const codeList = design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];
            const profile = codeList.find((profile) => profile.id == profileId);

            // change profile shape
            model.handrail.horizontalPostShape = ProfileUpdate.propertyProfileShapeToModalProfileShape(profile?.shape);
        }
        else {
            model.handrail.horizontalPostShape = undefined;
        }
    }

    @propertyValueChanged(UIProperty.Profile_IsHorizontalPostProfilePresent)
    private Profile_IsHorizontalPostProfilePresent(value: boolean, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.IsHorizontalPostProfilePresent = value;
    }

    @propertyValueChanged(UIProperty.Profile_HorizontalPostProfileConnection)
    private Profile_HorizontalPostProfileConnection(value: HorizontalPostProfileConnectionType, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.IsThroughPlate = (value == HorizontalPostProfileConnectionType.Through);
    }

    @propertyValueChanged(UIProperty.Profile_UsePinConnection)
    private Profile_UsePinConnection(value: boolean, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.UsePinConnection = value;
    }

    @propertyValueChanged(UIProperty.Application_CladdingHeight)
    private Application_CladdingHeight(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingHeight = value;
    }

    @propertyValueChanged(UIProperty.Application_CladdingWidth)
    private Application_CladdingWidth(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingWidth = value;
    }

    @propertyValueChanged(UIProperty.Application_CladdingOffset)
    private Application_CladdingOffset(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingOffset = value;
    }

    @propertyValueChanged(UIProperty.Application_CladdingBarOffsetFromFloor)
    private Application_CladdingDictanceFromFloor(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingBarConcreteOffset = value;
    }
    @propertyValueChanged(UIProperty.Application_CladdingBarOffsetFromRail)
    private Application_CladdingDictanceFromRail(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingBarRailOffset = value;
    }

    @propertyValueChanged(UIProperty.Application_CladdingType)
    private Application_CladdingType(value: CladdingType, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingType = value;
    }

    @propertyValueChanged(UIProperty.Application_CladdingProfile)
    private Application_CladdingProfile(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingProfile = value;
    }

    @propertyValueChanged(UIProperty.Application_BarsSpacing)
    private Application_BarsSpacing(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingBarSpacing = value;
    }

    @propertyValueChanged(UIProperty.Application_CladdingBarsCount)
    private Application_CladdingBarsCount(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.claddingBarCount = value;
    }

    @propertyValueChanged(UIProperty.Application_Cladding)
    @propertyValueChanged(UIProperty.Application_EnvironmentType)
    private Application_EnvironmentType(_value: void, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        this.setCladdingLinesVisibility(design, model);
    }

    @propertyValueChanged(UIProperty.Loads_DeadLoad)
    private Loads_DeadLoad(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.deadLoad = value;
    }

    @propertyValueChanged(UIProperty.Loads_VerticalLoad)
    private Loads_VerticalLoad(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.verticalLoad = value;

        model.handrail.showVerticalLoad = this.isVerticalLoadVisible(design, undefined, model.handrail.verticalLoad);
    }

    @propertyValueChanged(UIProperty.Loads_LinearLoadInwards)
    private Loads_LinearLoadInwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.linearInwardsLoad = value;

        model.handrail.showLinearInwardsLoad = this.isLinearInwardsLoadVisible(design, undefined, model.handrail.linearInwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_LinearLoadOutwards)
    private Loads_LinearLoadOutwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.linearOutwardsLoad = value;
        model.handrail.showLinearOutwardsLoad = this.isLinearOutwardsLoadVisible(design, undefined, model.handrail.linearOutwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_PunctualLoadInwards)
    private Loads_PunctualLoadInwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.punctualInwardsLoad = value;

        model.handrail.showPunctualInwardsLoad = this.isPunctualInwardsLoadVisible(design, undefined, model.handrail.punctualInwardsLoad);
        model.handrail.showPunctualInwardsLoadInWorstPosition = this.isPunctualInwardsLoadInWorstPositionVisible(design, undefined, model.handrail.punctualInwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_PunctualLoadOutwards)
    private Loads_PunctualLoadOutwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.punctualOutwardsLoad = value;

        model.handrail.showPunctualOutwardsLoad = this.isPunctualOutwardsLoadVisible(design, undefined, model.handrail.punctualOutwardsLoad);
        model.handrail.showPunctualOutwardsLoadInWorstPosition = this.isPunctualOutwardsLoadInWorstPositionVisible(design, undefined, model.handrail.punctualOutwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_PunctualLoadRailInwards)
    private Loads_PunctualRailLoadInwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.punctualRailInwardsLoad = value;
        model.handrail.showPunctualInwardsLoadInWorstPosition = this.isPunctualInwardsLoadInWorstPositionVisible(design, undefined, model.handrail.punctualInwardsLoad, model.handrail.punctualRailInwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_PunctualLoadRailOutwards)
    private Loads_PunctualRailLoadOutwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.punctualRailOutwardsLoad = value;
        model.handrail.showPunctualOutwardsLoadInWorstPosition = this.isPunctualOutwardsLoadInWorstPositionVisible(design, undefined, model.handrail.punctualOutwardsLoad, model.handrail.punctualRailOutwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_PunctualLoadInWorstPosition)
    private Loads_PunctualLoadInWorstPosition(value: string, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.showPunctualInwardsLoad = this.isPunctualInwardsLoadVisible(design, undefined, model.handrail.punctualInwardsLoad);
        model.handrail.showPunctualOutwardsLoad = this.isPunctualOutwardsLoadVisible(design, undefined, model.handrail.punctualOutwardsLoad);

        model.handrail.showPunctualInwardsLoadInWorstPosition = this.isPunctualInwardsLoadInWorstPositionVisible(design, undefined, model.handrail.punctualInwardsLoad, model.handrail.punctualRailInwardsLoad);
        model.handrail.showPunctualOutwardsLoadInWorstPosition = this.isPunctualOutwardsLoadInWorstPositionVisible(design, undefined, model.handrail.punctualOutwardsLoad, model.handrail.punctualRailOutwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_VerticalPunctualLoad)
    private Loads_VerticalPunctualLoad(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.verticalPunctualLoad = value;
        model.handrail.showVerticalPunctualLoadInWorstPosition = this.isVerticalPunctualLoadInWorstPositionVisible(design, undefined, model.handrail.verticalPunctualLoad);
    }

    @propertyValueChanged(UIProperty.Loads_VerticalPunctualLoadInWorstPosition)
    private Loads_VerticalPunctualLoadInWorstPosition(value: string, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.showVerticalPunctualLoadInWorstPosition = this.isVerticalPunctualLoadInWorstPositionVisible(design, undefined, model.handrail.verticalPunctualLoad);
    }

    @propertyValueChanged(UIProperty.Loads_InfillLoadInwards)
    private Loads_InfillLoadInwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.infillInwardsLoad = value;
        model.handrail.showInfillInwardsLoad = this.isInfillInwardsLoadVisible(design, undefined, model.handrail.infillInwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_InfillLoadOutwards)
    private Loads_InfillLoadOutwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.infillOutwardsLoad = value;
        model.handrail.showInfillOutwardsLoad = this.isInfillOutwardsLoadVisible(design, undefined, model.handrail.infillOutwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_InfillPunctualLoadInwards)
    private Loads_InfillPunctualLoadInwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.infillPunctualInwardsLoad = value;
        model.handrail.showInfillPunctualInwardsLoad = this.isInfillPunctualInwardsLoadVisible(design, undefined, model.handrail.infillPunctualInwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_InfillPunctualLoadOutwards)
    private Loads_InfillPunctualLoadOutwards(value: number, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.infillPunctualOutwardsLoad = value;
        model.handrail.showInfillPunctualOutwardsLoad = this.isInfillPunctualOutwardsLoadVisible(design, undefined, model.handrail.infillPunctualOutwardsLoad);
    }

    @propertyValueChanged(UIProperty.Loads_ShowAllLoads)
    @propertyValueChanged(UIProperty.Loads_SelectedLoadCombination)
    private Loads_ShowAllLoads(_value: void, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        const loadInstance = HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);

        model.handrail.showVerticalLoad = this.isVerticalLoadVisible(design, loadInstance);
        model.handrail.showLinearInwardsLoad = this.isLinearInwardsLoadVisible(design, loadInstance);
        model.handrail.showLinearOutwardsLoad = this.isLinearOutwardsLoadVisible(design, loadInstance);
        model.handrail.showPunctualInwardsLoad = this.isPunctualInwardsLoadVisible(design, loadInstance);
        model.handrail.showPunctualOutwardsLoad = this.isPunctualOutwardsLoadVisible(design, loadInstance);
        model.handrail.showPunctualInwardsLoadInWorstPosition = this.isPunctualInwardsLoadInWorstPositionVisible(design, loadInstance);
        model.handrail.showPunctualOutwardsLoadInWorstPosition = this.isPunctualOutwardsLoadInWorstPositionVisible(design, loadInstance);
        model.handrail.showVerticalPunctualLoadInWorstPosition = this.isVerticalPunctualLoadInWorstPositionVisible(design, loadInstance);
        model.handrail.showInfillInwardsLoad = this.isInfillInwardsLoadVisible(design, loadInstance);
        model.handrail.showInfillOutwardsLoad = this.isInfillOutwardsLoadVisible(design, loadInstance);
        model.handrail.showInfillPunctualInwardsLoad = this.isInfillPunctualInwardsLoadVisible(design, loadInstance);
        model.handrail.showInfillPunctualOutwardsLoad = this.isInfillPunctualOutwardsLoadVisible(design, loadInstance);

        this.setCladdingLinesVisibility(design, model, loadInstance);
    }

    @propertyValueChanged(UIProperty.Application_BuildingCategory)
    private Application_BuildingCategory(value: void, model: IModelPe, design: DesignPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        const loadInstance = HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);

        model.handrail.buildingCategory = design.buildingCategory?.id;
        model.handrail.showVerticalLoad = this.isVerticalLoadVisible(design, loadInstance);
        model.handrail.showLinearInwardsLoad = this.isLinearInwardsLoadVisible(design, loadInstance);
        model.handrail.showLinearOutwardsLoad = this.isLinearOutwardsLoadVisible(design, loadInstance);
        model.handrail.showPunctualInwardsLoad = this.isPunctualInwardsLoadVisible(design, loadInstance);
        model.handrail.showPunctualOutwardsLoad = this.isPunctualOutwardsLoadVisible(design, loadInstance);
        model.handrail.showPunctualInwardsLoadInWorstPosition = this.isPunctualInwardsLoadInWorstPositionVisible(design, loadInstance);
        model.handrail.showPunctualOutwardsLoadInWorstPosition = this.isPunctualOutwardsLoadInWorstPositionVisible(design, loadInstance);
        model.handrail.showVerticalPunctualLoadInWorstPosition = this.isVerticalPunctualLoadInWorstPositionVisible(design, loadInstance);
        model.handrail.showInfillInwardsLoad = this.isInfillInwardsLoadVisible(design, loadInstance);
        model.handrail.showInfillOutwardsLoad = this.isInfillOutwardsLoadVisible(design, loadInstance);
        model.handrail.showInfillPunctualInwardsLoad = this.isInfillPunctualInwardsLoadVisible(design, loadInstance);
        model.handrail.showInfillPunctualOutwardsLoad = this.isInfillPunctualOutwardsLoadVisible(design, loadInstance);
    }

    @propertyValueChanged(UIProperty.Loads_LinearLoadInwardsHeight)
    private Loads_LinearLoadInwardsHeight(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.linearInwardsLoadHeight = value;
    }

    @propertyValueChanged(UIProperty.Loads_LinearLoadOutwardsHeight)
    private Loads_LinearLoadOutwardsHeight(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.linearOutwardsLoadHeight = value;
    }

    @propertyValueChanged(UIProperty.Loads_PunctualLoadInwardsHeight)
    private Loads_PunctualLoadInwardsHeight(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.punctualInwardsLoadHeight = value;
    }

    @propertyValueChanged(UIProperty.Loads_PunctualLoadOutwardsHeight)
    private Loads_PunctualLoadOutwardsHeight(value: number, model: IModelPe) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        model.handrail.punctualOutwardsLoadHeight = value;
    }

    private isVerticalLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, verticalLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        verticalLoad = verticalLoad ?? design.verticalLoad;

        return (isCustomBuilding || (verticalLoad != null && verticalLoad != 0)) && (showAllLoads || (loadInstance & HandrailLoadInstances.Vertical) != 0);
    }

    private isLinearInwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, linearInwardsLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        linearInwardsLoad = linearInwardsLoad ?? design.linearLoadInwards;

        return (isCustomBuilding || (linearInwardsLoad != null && linearInwardsLoad != 0)) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Horizontal) != 0 && (loadInstance & HandrailLoadInstances.Inwards) != 0));
    }

    private isLinearOutwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, linearOutwardsLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        linearOutwardsLoad = linearOutwardsLoad ?? design.linearLoadOutwards;

        return (isCustomBuilding || (linearOutwardsLoad != null && linearOutwardsLoad != 0)) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Horizontal) != 0 && (loadInstance & HandrailLoadInstances.Outwards) != 0));
    }

    private isPunctualInwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, punctualInwardsLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;
        const isInWorstPosition = design.punctualLoadInWorstPosition;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        punctualInwardsLoad = punctualInwardsLoad ?? design.punctualLoadInwards;

        return (isCustomBuilding || (punctualInwardsLoad != null && punctualInwardsLoad != 0)) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Horizontal) != 0 && (loadInstance & HandrailLoadInstances.Inwards) != 0)) && (!isInWorstPosition || !isCustomBuilding);
    }

    private isPunctualOutwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, punctualOutwardsLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;
        const isInWorstPosition = design.punctualLoadInWorstPosition;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        punctualOutwardsLoad = punctualOutwardsLoad ?? design.punctualLoadOutwards;

        return (isCustomBuilding || (punctualOutwardsLoad != null && punctualOutwardsLoad != 0)) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Horizontal) != 0 && (loadInstance & HandrailLoadInstances.Outwards) != 0)) && (!isInWorstPosition || !isCustomBuilding);
    }

    private isPunctualInwardsLoadInWorstPositionVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, punctualInwardsLoad?: number, punctualInwardsRailLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;
        const isInWorstPosition = design.punctualLoadInWorstPosition;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        punctualInwardsLoad = punctualInwardsLoad ?? design.punctualLoadInwards;
        punctualInwardsRailLoad = punctualInwardsRailLoad ?? design.punctualRailLoadInwards;

        const loadValue = isCustomBuilding ? punctualInwardsLoad : punctualInwardsRailLoad;

        return (isCustomBuilding || (loadValue != null && loadValue != 0)) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Horizontal) != 0 && (loadInstance & HandrailLoadInstances.Inwards) != 0)) && isInWorstPosition;
    }

    private isPunctualOutwardsLoadInWorstPositionVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, punctualOutwardsLoad?: number, punctualOutwardsRailLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;
        const isInWorstPosition = design.punctualLoadInWorstPosition;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        punctualOutwardsLoad = punctualOutwardsLoad ?? design.punctualLoadOutwards;
        punctualOutwardsRailLoad = punctualOutwardsRailLoad ?? design.punctualRailLoadOutwards;

        const loadValue = isCustomBuilding ? punctualOutwardsLoad : punctualOutwardsRailLoad;

        return (isCustomBuilding || (loadValue != null && loadValue != 0)) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Horizontal) != 0 && (loadInstance & HandrailLoadInstances.Outwards) != 0)) && isInWorstPosition;
    }

    private isVerticalPunctualLoadInWorstPositionVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, verticalPunctualLoad?: number) {
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;
        const isInWorstPosition = design.verticalPunctualLoadInWorstPosition;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        verticalPunctualLoad = verticalPunctualLoad ?? design.verticalPunctualLoad;

        return (isCustomBuilding || (verticalPunctualLoad != null && verticalPunctualLoad != 0) || (verticalPunctualLoad != null && verticalPunctualLoad != 0)) && (showAllLoads || (loadInstance & HandrailLoadInstances.Vertical) != 0) && isInWorstPosition;
    }

    private isInfillInwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, infillInwardsLoad?: number) {
        const uiPropertyVisible = !design.isInfillLoadInwardsHidden;
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        infillInwardsLoad = infillInwardsLoad ?? design.infillLoadInwards;

        return (isCustomBuilding || (infillInwardsLoad != null && infillInwardsLoad != 0) || (infillInwardsLoad != null && infillInwardsLoad != 0)) && (showAllLoads || (loadInstance & HandrailLoadInstances.Inwards) != 0) && uiPropertyVisible;
    }

    private isInfillOutwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, infillOutwardsLoad?: number) {
        const uiPropertyVisible = !design.isInfillLoadOutwardsHIdden;
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        infillOutwardsLoad = infillOutwardsLoad ?? design.infillLoadOutwards;

        return (isCustomBuilding || (infillOutwardsLoad != null && infillOutwardsLoad != 0) || (infillOutwardsLoad != null && infillOutwardsLoad != 0)) && (showAllLoads || (loadInstance & HandrailLoadInstances.Outwards) != 0) && uiPropertyVisible;
    }

    private isInfillPunctualInwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, infillPunctualInwardsLoad?: number) {
        const uiPropertyVisible = !design.isinfillPunctualLoadInwardsHidden;
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        infillPunctualInwardsLoad = infillPunctualInwardsLoad ?? design.infillPunctualLoadInwards;

        return (isCustomBuilding || (infillPunctualInwardsLoad != null && infillPunctualInwardsLoad != 0)) && (showAllLoads || (loadInstance & HandrailLoadInstances.Inwards) != 0) && uiPropertyVisible;
    }

    private isInfillPunctualOutwardsLoadVisible(design: DesignPe, loadInstance?: HandrailLoadInstances, infillPunctualOutwardsLoad?: number) {
        const uiPropertyVisible = !design.isinfillPunctualLoadOutwardsHidden;
        const showAllLoads = design.showAllLoads;
        const isCustomBuilding = design.buildingCategory?.id == BuildingCategory.Custom;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        infillPunctualOutwardsLoad = infillPunctualOutwardsLoad ?? design.infillPunctualLoadOutwards;

        return (isCustomBuilding || (infillPunctualOutwardsLoad != null && infillPunctualOutwardsLoad != 0)) && (showAllLoads || (loadInstance & HandrailLoadInstances.Outwards) != 0) && uiPropertyVisible;
    }

    private hasInwardsCladdingLines(design: DesignPe, loadInstance?: HandrailLoadInstances, cladding?: number) {
        const hasCladding = (design.model[UIProperty.Application_CladdingType] as CladdingType) != CladdingType.None;
        const isOutside = design.model[UIProperty.Application_EnvironmentType] as boolean;
        const showAllLoads = design.showAllLoads;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        cladding = cladding ?? design.model[UIProperty.Application_Cladding] as number;

        return hasCladding && isOutside && (cladding != null && cladding != 0) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Wind) != 0 && (loadInstance & HandrailLoadInstances.Inwards) != 0));
    }

    private hasOutwardsCladdingLines(design: DesignPe, loadInstance?: HandrailLoadInstances, cladding?: number) {
        const hasCladding = (design.model[UIProperty.Application_CladdingType] as CladdingType) != CladdingType.None;
        const isOutside = design.model[UIProperty.Application_EnvironmentType] as boolean;
        const showAllLoads = design.showAllLoads;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);
        cladding = cladding ?? design.model[UIProperty.Application_Cladding] as number;

        return hasCladding && isOutside && (cladding != null && cladding != 0) && (showAllLoads || ((loadInstance & HandrailLoadInstances.Wind) != 0 && (loadInstance & HandrailLoadInstances.Outwards) != 0));
    }

    private setCladdingLinesVisibility(design: DesignPe, model: IModelPe, loadInstance?: HandrailLoadInstances) {
        if (!model.handrail) {
            throw new Error('model handrail is undefined');
        }

        const cladding = design.model[UIProperty.Application_Cladding] as number;

        loadInstance = loadInstance ?? HandrailHelper.calculateLoadInstance(design as unknown as DesignPe);

        model.handrail.hasInwardsCladdingLines = this.hasInwardsCladdingLines(design, loadInstance, cladding);
        model.handrail.hasOutwardsCladdingLines = this.hasOutwardsCladdingLines(design, loadInstance, cladding);
    }

    private propertyApplicationTypeToModalApplicationType(applicationType?: HandrailApplicationType) {
        switch (applicationType) {
            case HandrailApplicationType.Balustrade:
                return ApplicationType.Balustrade;
            case HandrailApplicationType.ConcretePlate:
                return ApplicationType.Concrete;
            case HandrailApplicationType.Stairs:
                return ApplicationType.Stairs;
            case HandrailApplicationType.Unknown:
            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown plate shape.');
        }
    }

    private propertyConnectionTypeToModalConnectionType(connectionType: HandrailConnectionType) {
        switch (connectionType) {
            case HandrailConnectionType.FrontDirectWelded:
                return ConnectionType.FrontDirectWelded;
            case HandrailConnectionType.FrontWelded90Degree:
                return ConnectionType.FrontWelded90Degree;
            case HandrailConnectionType.FrontBolted:
                return ConnectionType.FrontBolted;
            case HandrailConnectionType.FrontContinuousPlate:
                return ConnectionType.FrontContinuousPlate;
            case HandrailConnectionType.BottomDirectWelded:
                return ConnectionType.BottomDirectWelded;
            case HandrailConnectionType.BottomWelded90Degree:
                return ConnectionType.BottomWelded90Degree;
            case HandrailConnectionType.BottomBolted:
                return ConnectionType.BottomBolted;
            case HandrailConnectionType.BottomContinuousPlate:
                return ConnectionType.BottomContinuousPlate;
            case HandrailConnectionType.TopDirectWelded:
                return ConnectionType.TopDirectWelded;
            case HandrailConnectionType.TopBolted:
                return ConnectionType.TopBolted;
            case HandrailConnectionType.TopContinuousPlate:
                return ConnectionType.TopContinuousPlate;
            case HandrailConnectionType.Unknown:
            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown handrail connection type.');
        }
    }
}
