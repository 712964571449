import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { AnchorSizeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IAnchorSizeConstructor extends ICodeListConstructor {
    diameter?: number;
    internalPortfolioOnlyRegions: number[];
}

export class AnchorSize extends CodeList {
    public diameter?: number;
    public internalPortfolioOnlyRegions?: number[];

    constructor(codeList: IAnchorSizeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.diameter = codeList.diameter;
            this.internalPortfolioOnlyRegions = codeList.internalPortfolioOnlyRegions;
        }
    }

    public static fromService(codeList: AnchorSizeEntity) {
        return new AnchorSize(CodeList.baseFromService<IAnchorSizeConstructor>('AnchorSizeEntity', codeList, {
            diameter: codeList.Diameter,
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-anchor-family-' + codeList.Image.replace(/\..+$/, '') : 'sprite-anchor-family-no-photo-available',
            tag: codeList.Tag ? 'Agito.Hilti.Profis3.Anchors.Untested.Tag' : undefined,
            internalPortfolioOnlyRegions: codeList.InternalPortfolioOnlyRegions
        } as IAnchorSizeConstructor));
    }

    public override cloneDeep(): AnchorSize {
        const codeList: AnchorSize = super.cloneDeep() as AnchorSize;

        codeList.diameter = this.diameter;
        codeList.internalPortfolioOnlyRegions = this.internalPortfolioOnlyRegions;

        return codeList;
    }
}
