import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation
} from '@angular/core';
import {
    ExportReportRowBase, ExportReportRowStatus, IExportReportRowResult
} from '@profis-engineering/pe-ui-common/components/export-report-row-base/export-report-row-base.common';
import { IDisplayDesign } from '@profis-engineering/pe-ui-common/entities/display-design';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { Observable } from 'rxjs';
import { DesignPe } from '../../../shared/entities/design-pe';
import { ReportDetails } from '../../../shared/enums/export-report';
import { ProgressStatus } from '../../../shared/generated-modules/Hilti.PE.Core.Common';
import {
    DesignType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    GenerateReportRequest
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import { HandrailHelper } from '../../../shared/helpers/handrail-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { DocumentService } from '../../services/document.service';
import { LocalizationService } from '../../services/localization.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UserSettingsService } from '../../services/user-settings.service';

enum ProgressDefinitions {
    DesignOpened = 10,
    CalculationDone = 70,
    ReportGenerated = 100,
}

@Component({
    templateUrl: './export-report-row.component.html',
    styleUrls: ['./export-report-row.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportRowComponent extends ExportReportRowBase implements OnChanges {
    @Input()
    public displayDesign!: IDisplayDesign;

    @Input()
    public cancelExport!: Observable<boolean>;

    @Output()
    exportReportCompleated = new EventEmitter<IExportReportRowResult>();

    @Output()
    deleteExport = new EventEmitter<IDisplayDesign>();

    public design?: DesignPe;
    private includeDetailsInReport: Set<ReportDetails> = new Set();
    private designDefaultsChanged = false;

    constructor(
        localizationService: LocalizationService,
        changeDetectorRef: ChangeDetectorRef,
        private calculationServicePE: CalculationServicePE,
        private documentService: DocumentService,
        private translationFormatService: TranslationFormatService,
        private userSettingsService: UserSettingsService
    ) {
        super(changeDetectorRef, localizationService);
    }

    public get cancellationToken(): Deferred<void> | undefined {
        return this.design?.cancellationToken;
    }

    private get generalRegionId() {
        return this.userSettingsService.settings.application.general.regionId.value ?? KnownRegion.Unknown;
    }

    ngOnChanges(): void {
        this.cancelExport.subscribe(() => this.cancel());
        this.calculateDesignAndCreatePdf();
    }

    private async calculateDesignAndCreatePdf() {
        const documentDesign = this.documentService.findDesignById(this.displayDesign.id);
        this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.Generating');

        try {
            const projectDesign = await this.documentService.openDesignExclusivePe(documentDesign);
            this.percentage = ProgressDefinitions.DesignOpened;
            const result = await this.calculationServicePE.openFromProjectDesign(projectDesign, documentDesign.id, false, true, true);
            this.design = result.design;
            this.checkDesignDefaultsChanged();

            await this.longRunningCalculation();
            await this.cbfemCalculation();

            this.percentage = ProgressDefinitions.CalculationDone;

            if (this.design?.designData?.reportData?.CanExportReport) {
                this.setIncludeDetailsItems();
                await this.downloadReport();
            } else {
                this.canNotExportReport();
            }
        } catch (err) {
            this.handleError(err);
        }
    }

    private async cbfemCalculation(): Promise<void> {
        if (!this.design?.isCBFEMCalculation && !this.design?.isHandrailCBFEMCalculation) {
            return;
        }

        this.design.progressAdvancedBaseplateCalculation.subscribe((prog: ProgressStatus) => {
            this.message = this.translationFormatService.getLocalizedStringWithTranslationFormat(prog.ProgressText);
            this.percentage = ProgressDefinitions.DesignOpened + prog.Progress * (ProgressDefinitions.CalculationDone - ProgressDefinitions.DesignOpened) / 100;
        });
        const data = await this.calculationServicePE.performAdvancedBaseplateCalculation(this.design, this.design.isHandrailCBFEMCalculation ? this.design.selectedLoadCombinationId : undefined);
        this.calculationServicePE.confirmCalculationResult(this.design, data);
        this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.Generating');
    }

    private async longRunningCalculation(): Promise<void> {
        if (!this.design?.designData?.reportData?.IsLongRunningCalculation) {
            return;
        }

        await this.calculationServicePE.calculateAsync(this.design, undefined, { calculateLongRunning: true, forceCalculation: true });
        this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.Generating');
    }

    private async downloadReport() {
        if (!this.design) {
            throw new Error('design not defined');
        }

        const generateReportData = this.getGenerateReportRequest(this.design);
        const blob = await this.calculationServicePE.generateAndDownloadReportSignalR(this.design, generateReportData, [], false, true);
        this.checkDesignDefaultsChanged();

        this.percentage = ProgressDefinitions.ReportGenerated;
        if (!this.designDefaultsChanged) {
            this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.Generated');
        }
        this.exportResults(blob);
    }

    private checkDesignDefaultsChanged(): void {
        if (this.design?.designDefaultsChanged) {
            this.status = ExportReportRowStatus.Warning;
            this.message = this.translate('Agito.Hilti.Profis3.ExportReports.Message.DesignDefaultsChanged');
            this.designDefaultsChanged = true;
        }
    }

    private setIncludeDetailsItems(): void {
        this.includeDetailsInReport.clear();
        if (!this.design) {
            return;
        }
        this.includeDetailsInReport.add(ReportDetails.IncludeItemNumbers);

        if (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) {
            this.includeDetailsInReport.add(ReportDetails.AdvanceBaseplate);
        }

        if (this.design.showWeldDesign) {
            this.includeDetailsInReport.add(ReportDetails.WeldDesign);
        }

        if (!this.decisiveLoadCombination &&
            (this.showStressDistribution || this.design.showOptimizedAnchorPlateThickness)) {
            this.includeDetailsInReport.add(ReportDetails.AnchorPlateDesign);
        }

        if (this.isPostAndRailDesign) {
            this.includeDetailsInReport.add(ReportDetails.HandrailSafetyDesign);
        }

        if (this.design.designData.reportData?.IsSimultaneousDesignAvailable && DesignPe.getRegionPe(this.design).includeParallelDesignInReport) {
            this.includeDetailsInReport.add(ReportDetails.ParallelDesign);
        }
    }

    private getGenerateReportRequest(design: DesignPe): GenerateReportRequest {
        return {
            ImageDataUrl: '',
            ImageZoomedDataUrl: '',
            HandrailLoadCaseId: this.handrailLoadCaseId,
            IncludeWeldDesign: this.includeDetailsInReport.has(ReportDetails.WeldDesign),
            IncludeAnchorPlateDesign: this.includeDetailsInReport.has(ReportDetails.AnchorPlateDesign),
            HandrailSafetyDesign: this.includeDetailsInReport.has(ReportDetails.HandrailSafetyDesign),
            HandrailLoadCaseIdHandrail: this.handrailLoadCaseIdHandrail,
            HandrailLoadInstance: HandrailHelper.calculateLoadInstance(design),
            IncludeItemNumber: this.includeDetailsInReport.has(ReportDetails.IncludeItemNumbers),
            LoadLegendDataUrl: '',
            BaseplateStressImage: '',
            BaseplateStrainImage: '',
            IncludeAdvancedBaseplate: this.includeDetailsInReport.has(ReportDetails.AdvanceBaseplate),
            DateCultureInfo: design.region.culture ?? 'en',
            IncludeParallelDesign: this.includeDetailsInReport.has(ReportDetails.ParallelDesign),
            UserSpecificationText: this.translationFormatService.getLocalizedStringWithTranslationFormat(design.designData.reportData?.SpecificationText),
            GeneralRegionId: this.generalRegionId
        };
    }

    public get handrailLoadCaseIdHandrail(): number {
        if (this.design?.designType?.id == DesignType.Handrail) {
            return this.design?.designData.reportData?.DecisiveLoadCombinationIndexHandrail ?? this.design?.designData.reportData?.DecisiveLoadCombinationIndex ?? 0;
        }
        return 0;
    }

    public get handrailLoadCaseId(): number {
        if (this.design?.designType?.id == DesignType.Handrail) {
            return this.design?.designData.reportData?.DecisiveLoadCombinationIndex ?? 0;
        }
        return 0;
    }

    public get decisiveLoadCombination(): boolean {
        if (!this.design?.designData.reportData) {
            return false;
        }
        return this.design.designData.reportData.Combination.Decisive.Value > 100 || !this.design.designData.reportData.FeCalculationComplete;
    }

    public get showStressDistribution() {
        return this.design?.showStressDistribution ?? false;
    }

    public get isPostAndRailDesign() {
        return this.design?.designType?.id == DesignType.Handrail || (this.design?.showPostAndRailDeflection != null) ? this.design?.showPostAndRailDeflection : false;
    }
}
