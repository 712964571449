import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { DeckThicknessEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IDeckThicknessConstructor extends ICodeListConstructor {
    deckThickness?: number;
    deckThicknessMM?: number;
}

export class DeckThickness extends CodeList {
    deckThickness?: number;
    deckThicknessMM?: number;

    constructor(codeList: IDeckThicknessConstructor) {
        super(codeList);

        if (codeList != null) {
            this.deckThickness = codeList.deckThickness;
            this.deckThicknessMM = codeList.deckThicknessMM;
        }
    }

    public static fromService(codeList: DeckThicknessEntity) {
        return new DeckThickness(CodeList.baseFromService<IDeckThicknessConstructor>('DeckThicknessEntity', codeList, {
            deckThickness: codeList.DeckThickness,
            deckThicknessMM: codeList.DeckThicknessMM,
        } as IDeckThicknessConstructor));

    }

    public override cloneDeep() {
        const codeList: DeckThickness = super.cloneDeep() as DeckThickness;

        codeList.deckThickness = this.deckThickness;
        codeList.deckThicknessMM = this.deckThicknessMM;

        return codeList;
    }
}
