import { ICodeLists } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { IProperty } from '@profis-engineering/pe-ui-common/entities/design';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { LocalizationServiceBase as LocalizationService } from '@profis-engineering/pe-ui-common/services/localization.common';
import { UnitServiceBase as UnitService } from '@profis-engineering/pe-ui-common/services/unit.common';
import { IProfileSizeColumn, IProfileSizeComponentInput } from '../components/profile-size';
import { DesignType as DesignTypeEntity } from '../entities/code-lists/design-type';
import { ProfileSize } from '../entities/code-lists/profile-size';
import { DesignCodeList } from '../entities/design-code-list';
import { DesignType, ProfileShapeType } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CommonCodeList, CommonCodeListServiceBase as CommonCodeListService } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CodeListServiceBase as CodeListService } from '../services/code-list.service.base';

const profileSizeExtraPrecision: Record<number, number> = {
    [Unit.mm]: 1,
    [Unit.cm]: 1,
    [Unit.m]: 1,
    [Unit.inch]: 0,
    [Unit.ft]: 1
};

export interface IProfileSizeItem {
    id?: number;
    name: string;
    height: string;
    depth?: string;
    width: string;
    width2?: string;
    thickness: string;
    flangeThickness: string;
    codeList?: ProfileSize;
    unit: Unit;
}

export function customizeProfileSizeModal(
    modalInput: IProfileSizeComponentInput,
    designCodeLists: ICodeLists,
    unit: Unit,
    designType: DesignTypeEntity,
    uiProperty: IProperty,
    localization: LocalizationService,
    unitService: UnitService,
    commonCodeListService: CommonCodeListService,
    profileShapeType: ProfileShapeType
) {
    modalInput.columns = [
        { property: 'name' },
        { property: 'height' },
        { property: 'width' },
        { property: 'width2' },
        { property: 'thickness' },
        { property: 'flangeThickness' }
    ];
    modalInput.title = 'Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Title';
    setProfileHeaderText(modalInput.columns, unit, localization, commonCodeListService);

    modalInput.items = createProfileItemsFromCodeList(designCodeLists, uiProperty.allowedValues, unit, designType, localization, unitService, profileShapeType);

    if (modalInput.items.some(item => item.width2 == null || item.width2 == '')) {
        modalInput.columns = modalInput.columns.filter(item => item.property != 'width2');
    }

    modalInput.showProfileFilter = designType.id == DesignType.Handrail;
}

function setProfileHeaderText(columns: IProfileSizeColumn[], unitId: Unit, localization: LocalizationService, commonCodeListService: CommonCodeListService) {
    const unit = commonCodeListService.commonCodeLists[CommonCodeList.UnitLength].find((cl) => cl.id == unitId);
    const unitName = unit?.name != null
        ? unit.name
        : localization.getString(unit?.nameResourceKey ?? '');

    columns[0].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Name')}`;
    columns[1].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Height')} [${unitName}]`;
    columns[2].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Width')} [${unitName}]`;
    columns[3].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.SmallWidth')} [${unitName}]`;
    columns[4].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Thickness')} [${unitName}]`;
    columns[5].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.FlangeThickness')} [${unitName}]`;
}

function createProfileItemsFromCodeList(
    designCodeLists: ICodeLists,
    allowedValues: number[] | undefined,
    unit: Unit, designType: DesignTypeEntity,
    localization: LocalizationService,
    unitService: UnitService,
    profileShapeType: ProfileShapeType) {
    const defaultUnit: Unit = unit;
    const internaUnit = unitService.getInternalUnit(UnitGroup.Length);

    const codeList = designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];

    let items = codeList.map((profileSize): IProfileSizeItem => ({
        id: profileSize.id,
        name: profileSize.name ?? '',
        height: formatLengthNumber(profileSize.height, defaultUnit, internaUnit, unitService),
        width: formatLengthNumber(profileSize.width, defaultUnit, internaUnit, unitService),
        width2: profileSize.width2 != null && profileSize.width2 > 0
            ? formatLengthNumber(profileSize.width2, defaultUnit, internaUnit, unitService)
            : undefined,
        thickness: formatLengthNumber(profileSize.thickness, defaultUnit, internaUnit, unitService),
        flangeThickness: formatLengthNumber(profileSize.flangeThickness, defaultUnit, internaUnit, unitService),
        codeList: profileSize,
        unit
    }));

    // filter allowed values.
    items = items.filter((item) => item.id != null && allowedValues != null ? allowedValues.includes(item.id) : true);

    if (designType.id != DesignType.Handrail || (designType.id == DesignType.Handrail && profileShapeType == ProfileShapeType.DoubleBar)) { // if handrail skip custom
        items.unshift({
            id: undefined,
            name: localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Custom'),
            height: '/',
            width: '/',
            width2: '/',
            thickness: '/',
            flangeThickness: '/',
            codeList: undefined,
            unit
        });
    }

    return items;
}


export function customizeShearLugSizeModal(
    modalInput: IProfileSizeComponentInput,
    designCodeLists: ICodeLists,
    unit: Unit,
    uiProperty: IProperty,
    localization: LocalizationService,
    unitService: UnitService,
    codeListService: CodeListService,
    commonCodeListService: CommonCodeListService
) {
    modalInput.columns = [
        { property: 'name' },
        { property: 'height' },
        { property: 'width' },
        { property: 'thickness' }
    ];
    modalInput.title = 'Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlShearLugSize.Popup.Title';

    setShearLugHeaderText(modalInput.columns, unit, localization, commonCodeListService);

    modalInput.items = createShearLugItemsFromCodeList(designCodeLists, uiProperty.allowedValues, unit, localization, unitService);
    modalInput.showProfileFilter = false;
}

function setShearLugHeaderText(columns: IProfileSizeColumn[], unitId: Unit, localization: LocalizationService, commonCodeListService: CommonCodeListService) {
    const unit = commonCodeListService.commonCodeLists[CommonCodeList.UnitLength].find((cl) => cl.id == unitId);
    const unitName = unit?.name != null
        ? unit.name
        : localization.getString(unit?.nameResourceKey ?? '');

    columns[0].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Name')}`;
    columns[1].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Length')} [${unitName}]`;
    columns[2].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Width')} [${unitName}]`;
    columns[3].title = `${localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Thickness')} [${unitName}]`;
}

function createShearLugItemsFromCodeList(designCodeLists: ICodeLists, allowedValues: number[] | undefined, unit: Unit, localization: LocalizationService, unitService: UnitService) {
    const internalUnit = unitService.getInternalUnit(UnitGroup.Length);
    const codeList = designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];

    let items = codeList.map((profileSize): IProfileSizeItem => ({
        id: profileSize.id,
        name: profileSize.name ?? '',
        height: formatLengthNumber(profileSize.height, unit, internalUnit, unitService),
        width: formatLengthNumber(profileSize.width, unit, internalUnit, unitService),
        thickness: formatLengthNumber(profileSize.thickness, unit, internalUnit, unitService),
        codeList: profileSize,
        flangeThickness: '',
        unit
    }));

    // filter allowed values.
    items = items.filter((item) => item.id != null && allowedValues != null ? allowedValues.includes(item.id) : true);
    items.unshift({
        id: undefined,
        name: localization.getString('Agito.Hilti.Profis3.Navigation.TabProfiles.RegionProfile.ControlProfileSize.Popup.Custom'),
        height: '/',
        width: '/',
        thickness: '/',
        flangeThickness: '',
        codeList: undefined,
        unit
    });
    return items;
}

function formatLengthNumber(internalValue: number | undefined, defaultUnit: Unit, internaUnit: Unit, unitService: UnitService) {
    if (internalValue == null) {
        return '-';
    }

    return unitService.formatNumber(unitService.convertUnitValueArgsToUnit(internalValue, internaUnit, defaultUnit), getPrecision(defaultUnit, unitService));
}

function getPrecision(unit: Unit, unitService: UnitService) {
    return profileSizeExtraPrecision[unit] + unitService.getPrecision(unit);
}
