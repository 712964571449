<div class="steel-calculation-inputs content-group" *ngIf="componentInitialized">
    <div class="content steel-guideline">
        <div class="steel-guide-dropdown" *ngIf="steelCalculationDropdownVisible">
            <pe-dropdown
                [id]="steelGuideline.id"
                [items]="steelGuideline.items"
                [selectedValue]="data.advancedCalculationInputsData.selectedSteelGuideline"
                (selectedValueChange)="onSteelGuidelineChange($any($event).detail)"
                [disabled]="disabled || steelGuideline.disabled">
            </pe-dropdown>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="enBased">
        <div class="content-controls">
            <div class="control">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM0.id"
                    [title]="steelSafetyFactorGammaM0.title"
                    [placeholder]="steelSafetyFactorGammaM0.placeholder"
                    [unit]="noUnit"
                    [value]="data.steelSafetyFactorGammaM0"
                    (valueChange)="data.steelSafetyFactorGammaM0 = $any($event).detail"
                    [minValue]="steelSafetyFactorGammaM0.minValue"
                    [maxValue]="steelSafetyFactorGammaM0.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM1.id"
                    [title]="steelSafetyFactorGammaM1.title"
                    [placeholder]="steelSafetyFactorGammaM1.placeholder"
                    [unit]="noUnit"
                    [value]="data.steelSafetyFactorGammaM1"
                    (valueChange)="data.steelSafetyFactorGammaM1 = $any($event).detail"
                    [minValue]="steelSafetyFactorGammaM1.minValue"
                    [maxValue]="steelSafetyFactorGammaM1.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM2.id"
                    [title]="steelSafetyFactorGammaM2.title"
                    [placeholder]="steelSafetyFactorGammaM2.placeholder"
                    [unit]="noUnit"
                    [value]="data.steelSafetyFactorGammaM2"
                    (valueChange)="data.steelSafetyFactorGammaM2 = $any($event).detail"
                    [minValue]="steelSafetyFactorGammaM2.minValue"
                    [maxValue]="steelSafetyFactorGammaM2.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="aiscAndRlfdBasedR">
        <div class="content-controls">
            <div class="control">
                <pe-numeric-text-box
                    [id]="materialSafetyFactor.id"
                    [title]="materialSafetyFactor.title"
                    [placeholder]="materialSafetyFactor.placeholder"
                    [unit]="noUnit"
                    [value]="data.materialSafetyFactor"
                    (valueChange)="data.materialSafetyFactor = $any($event).detail"
                    [minValue]="materialSafetyFactor.minValue"
                    [maxValue]="materialSafetyFactor.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="asBased">
        <div class="content-controls">
            <div class="control">
                <pe-numeric-text-box
                    [id]="steelCapacityFactor.id"
                    [title]="steelCapacityFactor.title"
                    [placeholder]="steelCapacityFactor.placeholder"
                    [unit]="noUnit"
                    [value]="data.steelCapacityFactor"
                    (valueChange)="data.steelCapacityFactor = $any($event).detail"
                    [minValue]="steelCapacityFactor.minValue"
                    [maxValue]="steelCapacityFactor.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control ">
                <pe-dropdown
                    [id]="weldsCapacityFactor.id"
                    [title]="weldsCapacityFactor.title"
                    [items]="weldsCapacityFactor.items"
                    [selectedValue]="data.weldsCapacityFactor"
                    (selectedValueChange)="data.weldsCapacityFactor = $any($event).detail"
                    [disabled]="disabled">
                </pe-dropdown>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="concreteCapacityFactor.id"
                    [title]="concreteCapacityFactor.title"
                    [placeholder]="concreteCapacityFactor.placeholder"
                    [unit]="noUnit"
                    [value]="data.concreteCapacityFactor"
                    (valueChange)="data.concreteCapacityFactor = $any($event).detail"
                    [minValue]="concreteCapacityFactor.minValue"
                    [maxValue]="concreteCapacityFactor.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="stoBased">
        <div class="content-controls">
            <div class="control">
                <pe-numeric-text-box
                    [id]="stoSteelSafetyFactorGammaM.id"
                    [title]="stoSteelSafetyFactorGammaM.title"
                    [value]="stoSteelSafetyFactorGammaM.value"
                    (valueChange)="stoSteelSafetyFactorGammaM.value = $any($event).detail"
                    [disabled]="disabled || stoSteelSafetyFactorGammaM.disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="inBased">
        <div class="content-controls">
            <div class="control">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaM0.id"
                    [title]="inSteelSafetyFactorGammaM0.title"
                    [placeholder]="inSteelSafetyFactorGammaM0.placeholder"
                    [unit]="noUnit"
                    [value]="data.inSteelSafetyFactorGammaM0"
                    (valueChange)="data.inSteelSafetyFactorGammaM0 = $any($event).detail"
                    [minValue]="inSteelSafetyFactorGammaM0.minValue"
                    [maxValue]="inSteelSafetyFactorGammaM0.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaM1.id"
                    [title]="inSteelSafetyFactorGammaM1.title"
                    [placeholder]="inSteelSafetyFactorGammaM1.placeholder"
                    [unit]="noUnit"
                    [value]="data.inSteelSafetyFactorGammaM1"
                    (valueChange)="data.inSteelSafetyFactorGammaM1 = $any($event).detail"
                    [minValue]="inSteelSafetyFactorGammaM1.minValue"
                    [maxValue]="inSteelSafetyFactorGammaM1.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaMw.id"
                    [title]="inSteelSafetyFactorGammaMw.title"
                    [placeholder]="inSteelSafetyFactorGammaMw.placeholder"
                    [unit]="noUnit"
                    [value]="data.inSteelSafetyFactorGammaMw"
                    (valueChange)="data.inSteelSafetyFactorGammaMw = $any($event).detail"
                    [minValue]="inSteelSafetyFactorGammaMw.minValue"
                    [maxValue]="inSteelSafetyFactorGammaMw.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="inSteelSafetyFactorGammaMb.id"
                    [title]="inSteelSafetyFactorGammaMb.title"
                    [placeholder]="inSteelSafetyFactorGammaMb.placeholder"
                    [unit]="noUnit"
                    [value]="data.inSteelSafetyFactorGammaMb"
                    (valueChange)="data.inSteelSafetyFactorGammaMb = $any($event).detail"
                    [minValue]="inSteelSafetyFactorGammaMb.minValue"
                    [maxValue]="inSteelSafetyFactorGammaMb.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="content fixed-inputs" *ngIf="hkBased">
        <div class="content-controls">
            <div class="control">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM1.id"
                    [title]="steelSafetyFactorGammaM1.title"
                    [placeholder]="steelSafetyFactorGammaM1.placeholder"
                    [unit]="noUnit"
                    [value]="data.steelSafetyFactorGammaM1"
                    (valueChange)="data.steelSafetyFactorGammaM1 = $any($event).detail"
                    [minValue]="steelSafetyFactorGammaM1.minValue"
                    [maxValue]="steelSafetyFactorGammaM1.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control">
                <pe-numeric-text-box
                    [id]="steelSafetyFactorGammaM2.id"
                    [title]="steelSafetyFactorGammaM2.title"
                    [placeholder]="steelSafetyFactorGammaM2.placeholder"
                    [unit]="noUnit"
                    [value]="data.steelSafetyFactorGammaM2"
                    (valueChange)="data.steelSafetyFactorGammaM2 = $any($event).detail"
                    [minValue]="steelSafetyFactorGammaM2.minValue"
                    [maxValue]="steelSafetyFactorGammaM2.maxValue"
                    [disabled]="disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>
</div>
