import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import {
    DropdownItem
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    CollapseLook
} from '@profis-engineering/pe-ui-common/components/section/section.common';
import {
    UnitGroup
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { Subscription } from 'rxjs';
import {
    TranslationFormat
} from '../../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation';
import {
    WeldCurvesEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

interface IWeldDiagramDisplay {
    height: number;
    title: TranslationFormat;
}

@Component({
    templateUrl: './weld-curves.component.html',
    styleUrls: ['./weld-curves.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class WeldCurvesComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public collapsed = false;

    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    @Input()
    public weldCurves: WeldCurvesEntity[] = [];

    @Input()
    public updateGlModelSelectedWeldNameFn?: (model: IModelPe, replace?: boolean) => Promise<void>;

    @Input()
    public onWeldClicked?: EventEmitter<string>;

    public weldCurvesDiagramData?: IWeldDiagramDisplay[];
    public maxWeldCurvesDiagramValue = 0;
    public selectedWeld = 0;
    public collapseSectionBoxLook = CollapseLook.SectionBox;
    public weldsDropdownItems!: DropdownItem<number>[];

    private onWeldClickedSubscription?: Subscription;

    constructor(
        public translationFormatService: TranslationFormatService,
        private localizationService: LocalizationService,
        private userService: UserService,
        private unitService: UnitService,
        private numberService: NumberService,
        private changeDetector: ChangeDetectorRef
    ) { }

    public get hasWeldCurves() {
        return this.weldCurves != null && this.weldCurves.length > 0;
    }

    public get getWeldCurvesDiagramData() {
        return this.weldCurvesDiagramData ?? [];
    }

    public get weldCurvesDiagramDataSize() {
        return this.weldCurvesDiagramData?.length ?? 0;
    }

    public get getMaxWeldCurvesDiagramValue() {
        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Stress);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Stress);

        const roundingPrecision = this.unitService.getPrecision(defaultUnit);
        return this.numberService.format(this.unitService.convertUnitValueArgsToUnit(this.maxWeldCurvesDiagramValue, internalUnit, defaultUnit), roundingPrecision);
    }

    public get unitStressName() {
        const unitsCodeList = this.unitService.getUnitGroupCodeList(UnitGroup.Stress);
        const selectedUnit = unitsCodeList.find((unit) => unit.id == this.userService.design?.unitStress);
        return selectedUnit?.name ?? '';
    }

    public get unitLengthName() {
        const unitsCodeList = this.unitService.getUnitGroupCodeList(UnitGroup.Length);
        const selectedUnit = unitsCodeList.find((unit) => unit.id == this.userService.design?.unitLength);
        return selectedUnit?.name ?? '';
    }

    public ngOnInit(): void {
        if (this.hasWeldCurves) {
            this.updateDropdown();
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        const weldCurves = changes['weldCurves'];
        if (weldCurves != null && weldCurves.currentValue != weldCurves.previousValue) {
            this.updateDropdown();
        }

        const onWeldClicked = changes['onWeldClicked'];
        if (onWeldClicked != null && onWeldClicked.currentValue != onWeldClicked.previousValue && onWeldClicked.currentValue != null) {
            if (this.onWeldClickedSubscription != null) {
                this.onWeldClickedSubscription.unsubscribe();
            }

            this.onWeldClickedSubscription = this.onWeldClicked?.subscribe((weldName: string) => {
                const index = this.weldCurves.findIndex(item => item.Name == weldName);
                if (index != -1) {
                    this.updateSelectedValue(index);
                    this.collapsed = false;
                    this.updateGlModelSelectedWeldName(this.selectedWeld);
                    this.updateWeldCurveDiagram(this.selectedWeld);
                    this.updateUi();
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.onWeldClickedSubscription != null) {
            this.onWeldClickedSubscription.unsubscribe();
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public weldsItemChanged(value: number) {
        if (this.hasWeldCurves && this.selectedWeld != undefined && value != this.selectedWeld) {
            this.selectedWeld = value;
            this.updateGlModelSelectedWeldName(this.selectedWeld);
            this.updateWeldCurveDiagram(this.selectedWeld);
        }
    }

    private updateSelectedValue(value: number) {
        this.selectedWeld = value;
    }

    private updateDropdown() {
        if (this.weldCurves != null) {
            this.weldsDropdownItems = (this.weldCurves).map((welds, i) => ({
                value: i,
                text: welds.Name
            }) as DropdownItem<number>);

            if (this.selectedWeld == undefined || !this.weldCurves.some((_item, i) => i == this.selectedWeld)) {
                this.updateSelectedValue(0);
            }
            this.updateGlModelSelectedWeldName(this.selectedWeld);
            this.updateWeldCurveDiagram(this.selectedWeld);
            this.updateUi();
        }
    }

    private updateGlModelSelectedWeldName(value: number) {
        if (value != undefined && this.updateGlModelSelectedWeldNameFn != null) {
            this.updateGlModelSelectedWeldNameFn({
                welds: {
                    selectedWeldName: this.weldCurves[value].Name.charAt(this.weldCurves[value].Name.length - 1) == ' '
                        ? this.weldCurves[value].Name + '1'
                        : this.weldCurves[value].Name
                },
                stiffener: {
                    selectedWeldName: this.weldCurves[value].Name.charAt(this.weldCurves[value].Name.length - 1) == '1'
                        ? this.weldCurves[value].Name.substring(0, this.weldCurves[value].Name.length - 2)
                        : this.weldCurves[value].Name
                }
            });
        }
    }

    private updateWeldCurveDiagram(value: number) {
        if (value != undefined && this.hasWeldCurves) {

            const data = this.weldCurves.length > value
                ? this.weldCurves[value]
                : this.weldCurves[0]; // before and after calculation number of items can vary. If data on selected index before calculation doesn't exist (value is outside the boundaries), take value on index 0

            this.maxWeldCurvesDiagramValue = Math.max(...data.WeldStressDiagram.map(w => w.Value));
            this.weldCurvesDiagramData = data.WeldStressDiagram.map(w => ({
                height: w.Value / this.maxWeldCurvesDiagramValue * 100,
                title: w.Title
            }));
        }
    }

    private updateUi() {
        // The value is changed inside the same cycle so change detection
        // needs to be run again before the new change
        this.changeDetector.detectChanges();
    }
}
