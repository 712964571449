import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import {
    propertyValueChanged, scopeCheckChanged, Update
} from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { ProfileShape } from '@profis-engineering/pe-gl-model/components/profile';
import {
    MeasureScopeCheckParameterItem as ScopeCheck
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    MeasureResultType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    ProfileShapeType as PropertyProfileShape
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import { ProfileFamily } from '../../../../shared/entities/code-lists/profile-family';
import { ProfileSize } from '../../../../shared/entities/code-lists/profile-size';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { DesignPe } from '../../../../shared/entities/design-pe';

export class ProfileUpdate extends BaseUpdate {
    public static propertyProfileShapeToModalProfileShape(profileShape?: PropertyProfileShape) {
        switch (profileShape) {
            case PropertyProfileShape.Cylinder:
                return ProfileShape.Cylinder;
            case PropertyProfileShape.Bar:
                return ProfileShape.Bar;
            case PropertyProfileShape.I:
                return ProfileShape.I;
            case PropertyProfileShape.L:
                return ProfileShape.L;
            case PropertyProfileShape.Rectangular:
                return ProfileShape.Rectangular;
            case PropertyProfileShape.Pipe:
                return ProfileShape.Pipe;
            case PropertyProfileShape.SquareBar:
                return ProfileShape.SquareBar;
            case PropertyProfileShape.SquareHollow:
                return ProfileShape.SquareHollow;
            case PropertyProfileShape.T:
                return ProfileShape.T;
            case PropertyProfileShape.U:
                return ProfileShape.U;
            case PropertyProfileShape.Z:
                return ProfileShape.Z;
            case PropertyProfileShape.DoubleBar:
                return ProfileShape.DoubleBar;
            case PropertyProfileShape.CrossBeam:
                return ProfileShape.CrossBeam;

            case PropertyProfileShape.Unknown:
            case undefined:
            case null:
                return undefined;

            default:
                throw new Error('Unknown profile shape.');
        }
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged(UIProperty.Profile_ProfileFamilyId)
    private Profile_ProfileFamilyId(profileId: number, model: IModelPe, design: DesignPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        if (profileId != null && profileId != 0) {
            const codeList = design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];
            const profile = codeList.find((profile) => profile.id == profileId);

            // change profile shape
            model.profile.shape = ProfileUpdate.propertyProfileShapeToModalProfileShape(profile?.shape) ?? undefined;
        }
        else {
            model.profile.shape = undefined;
        }
    }

    @propertyValueChanged(UIProperty.Profile_CustomWidth)
    private Profile_CustomWidth(value: number, model: IModelPe, design: DesignPe) {
        // order of properties is "unknown" set custom size only if profile size is custom
        if (design.model[UIProperty.Profile_ProfileSizeId] == null && model.profile) {
            model.profile.width = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_CustomWidth2)
    private Profile_CustomWidth2(value: number, model: IModelPe, design: DesignPe) {
        // order of properties is "unknown" set custom size only if profile size is custom
        if (design.model[UIProperty.Profile_ProfileSizeId] == null && model.profile) {
            const profileSizes = design.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];
            const profileSizesByFamilyId = profileSizes?.filter(x => x.profileFamilyId == design.profileFamily?.id);

            const isProfileAsymetrical = profileSizesByFamilyId != null ?
                profileSizesByFamilyId.some(profile => profile.width2 != null && profile.width2 != 0) : false;

            model.profile.width2 = isProfileAsymetrical ? value : undefined;
        }
    }

    @propertyValueChanged(UIProperty.Profile_CustomHeight)
    private Profile_CustomHeight(value: number, model: IModelPe, design: DesignPe) {
        // order of properties is "unknown" set custom size only if profile size is custom
        if (design.model[UIProperty.Profile_ProfileSizeId] == null && model.profile) {
            model.profile.height = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_CustomThickness)
    private Profile_CustomThickness(value: number, model: IModelPe, design: DesignPe) {
        // order of properties is "unknown" set custom size only if profile size is custom
        if (design.model[UIProperty.Profile_ProfileSizeId] == null && model.profile) {
            model.profile.thickness = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_CustomFlangeThickness)
    private Profile_CustomFlangeThickness(value: number, model: IModelPe, design: DesignPe) {
        // order of properties is "unknown" set custom size only if profile size is custom
        if (design.model[UIProperty.Profile_ProfileSizeId] == null && model.profile) {
            model.profile.flangeThickness = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_ProfileSizeId)
    private Profile_ProfileSizeId(profileSizeId: number, model: IModelPe, design: DesignPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        const profileSizes = design.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];

        if (profileSizeId != null) {
            const profileSize = profileSizes.find((profileSize) => profileSize.id == profileSizeId);

            model.profile.width = profileSize?.width;
            model.profile.width2 = profileSize?.width2;
            model.profile.height = profileSize?.height;
            model.profile.thickness = profileSize?.thickness;
            model.profile.flangeThickness = profileSize?.flangeThickness;
            model.profile.isCustomSize = false;
        }
        else {
            const profileSizesByFamilyId = profileSizes?.filter(x => x.profileFamilyId == design.profileFamily?.id);

            const isProfileAsymetrical = profileSizesByFamilyId != null ?
                profileSizesByFamilyId.some(profile => profile.width2 != null && profile.width2 != 0) : false;

            model.profile.width = design.model[UIProperty.Profile_CustomWidth] as number;
            model.profile.width2 = isProfileAsymetrical ? design.model[UIProperty.Profile_CustomWidth2] as number : undefined;
            model.profile.height = design.model[UIProperty.Profile_CustomHeight] as number;
            model.profile.thickness = design.model[UIProperty.Profile_CustomThickness] as number;
            model.profile.flangeThickness = design.model[UIProperty.Profile_CustomFlangeThickness] as number;
            model.profile.isCustomSize = true;
        }
    }

    @propertyValueChanged(UIProperty.Profile_OffsetX)
    private Profile_OffsetX(value: number, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.offset = model.profile.offset || Vector2.Zero();
        model.profile.offset.x = value;
    }

    @propertyValueChanged(UIProperty.Profile_OffsetY)
    private Profile_OffsetY(value: number, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.offset = model.profile.offset || Vector2.Zero();
        model.profile.offset.y = value;
    }

    @propertyValueChanged(UIProperty.Profile_Angle)
    private Profile_Angle(value: number, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.angleY = -value;
    }

    @propertyValueChanged(UIProperty.Profile_UseProfile)
    private Profile_UseProfile(value: boolean, model: IModelPe, design: DesignPe, _useDevFeatures: boolean, update: Update) {
        if (value != null && model.profile) {
            const hidden = !value;

            if (model.profile.hidden != hidden) {
                // reset profile family and size
                if (model.profile.hidden != null && !hidden && update == Update.Client) {
                    const firstProfile = (design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[])[0];
                    const firstProfileSize = (design.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[]).find(profileSize => profileSize.profileFamilyId == firstProfile.id);

                    model.profile.shape = ProfileUpdate.propertyProfileShapeToModalProfileShape(firstProfile.shape);
                    model.profile.width = firstProfileSize?.width;
                    model.profile.width2 = firstProfileSize?.width2;
                    model.profile.height = firstProfileSize?.height;
                    model.profile.thickness = firstProfileSize?.thickness;
                    model.profile.flangeThickness = firstProfileSize?.flangeThickness;
                }

                model.profile.hidden = hidden;
            }
        }
    }

    @propertyValueChanged(UIProperty.Profile_ShowWeldDesign)
    private Profile_ShowWeldDesign(value: boolean, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.showWeldDesign = value;
    }

    @propertyValueChanged(UIProperty.Profile_FlangeWeldThickness)
    private Profile_FlangeWeldThickness(value: number, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.flangeWeldThickness = value;
    }

    @propertyValueChanged(UIProperty.Profile_WebWeldThickness)
    private Profile_WebWeldThickness(value: number, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.webWeldThickness = value;
    }

    /* SCOPE CHECK CHANGED */
    @scopeCheckChanged()
    private scopeChecksChanged(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (!model.profile) {
            throw new Error('model profile is undefined');
        }

        model.profile.invalid = model.profile.invalid ?? {};

        model.profile.invalid.weld = false;

        if (scopeChecks != null) {
            model.profile.invalid.weld = scopeChecks[MeasureResultType.WeldThickness] != null;
        }
    }
}
