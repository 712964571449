<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.AdvancedCalculationTypeInfo.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="calculation-types">
        <div class="calculation-type-description">
            <p [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedCalculationTypeInfo.Rigid')"></p>
        </div>
        <div class="calculation-type-image">
            <img [src]="rigidImage" alt="calculation-type-rigid">
        </div>

        <div class="calculation-type-description">
            <p [innerHtml]="translate('Agito.Hilti.Profis3.AdvancedCalculationTypeInfo.Flexible')"></p>
        </div>
        <div class="calculation-type-image">
            <img [src]="flexibleImage" alt="calculation-type-flexible">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-info-link-button class="float-left" [infoLinkKey]="'LearnMore.PE.AdvancedCalculationTypes'" (buttonClick)="learnMoreClicked()"></pe-info-link-button>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
