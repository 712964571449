<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.WHGInfo.Title')">
</pe-modal-header>

<pe-modal-body>
    <span [innerHtml]="whgMessage"></span>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [id]="'WHG-info-ok-button'" [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
