import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import barProfileLocationImg from '../../../images/custom-stiffener-location/location-bar-profile.png';
import iProfileLocationImg from '../../../images/custom-stiffener-location/location-i-profile.png';
import pipeProfileLocationImg from '../../../images/custom-stiffener-location/location-pipe-profile.png';
import tProfileLocationImg from '../../../images/custom-stiffener-location/location-t-profile.png';
import {
    ProfileShapeType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './custom-stiffener-location-info.component.html',
    styleUrls: ['./custom-stiffener-location-info.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CustomStiffenerLocationInfoComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public iProfileImage!: string;
    public barProfileImage!: string;
    public pipeProfileImage!: string;
    public tProfileImage!: string;

    constructor(
        private user: UserService,
        public localization: LocalizationService,
    ) { }

    public get profileLocationImage() {
        switch (this.design.profileFamily?.shape) {
            case ProfileShapeType.I:
                return this.iProfileImage;

            case ProfileShapeType.SquareBar:
            case ProfileShapeType.Bar:
                return this.barProfileImage;

            case ProfileShapeType.Pipe:
                return this.pipeProfileImage;

            case ProfileShapeType.T:
                return this.tProfileImage;

            default: throw new Error('Invalid profile shape');
        }
    }

    private get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        this.iProfileImage = iProfileLocationImg;
        this.barProfileImage = barProfileLocationImg;
        this.pipeProfileImage = pipeProfileLocationImg;
        this.tProfileImage = tProfileLocationImg;
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }
}
