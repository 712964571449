import { AnchorPositionEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class AnchorPositionsAsadClass extends CodeList {

    public static fromService(codeList: AnchorPositionEntity) {
        return new AnchorPositionsAsadClass(CodeList.baseFromService('AnchorPositionsAsad', codeList));
    }

    public override cloneDeep(): AnchorPositionsAsadClass {
        const codeList: AnchorPositionsAsadClass = super.cloneDeep() as AnchorPositionsAsadClass;

        return codeList;
    }
}
