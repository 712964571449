import {
    propertyValueChanged, scopeCheckChanged, Update
} from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import {
    EdgeDistance, IBaseMaterialEdges
} from '@profis-engineering/pe-gl-model/components/base-component';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import { DesignPe } from '../../../../shared/entities/design-pe';
import {
    MeasureBaseMaterialEdgeFromMode
} from '../../../../shared/generated-modules/Hilti.PE.Core.Common.Enums';
import {
    MeasureScopeCheckParameterItem as ScopeCheck
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    Dimensions, MeasureResultType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignType, EdgeDistanceDisplayType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';


export class ConcreteBaseMaterialUpdate extends BaseUpdate {

    public override shouldUpdate(model: IModelPe, design: DesignPe) {
        return design.designType.id == DesignType.Concrete;
    }

    protected baseMaterial(model: IModelPe): IBaseMaterialEdges {
        if (model.concreteBaseMaterial != null) {
            return model.concreteBaseMaterial;
        }

        if (model.metalDeckBaseMaterial != null) {
            return model.metalDeckBaseMaterial;
        }

        throw new Error('Unknown base material.');
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged(UIProperty.BaseMaterial_Thickness)
    protected BaseMaterial_Thickness(value: number, model: IModelPe, _design: DesignPe, _useDevFeatures: boolean, update: Update) {
        if (!model.concreteBaseMaterial) {
            throw new Error('model concreteBaseMaterial is undefined');
        }

        let thickness = value;

        if (thickness < 1) {
            thickness = 1;
        }

        if (update == Update.Client) {
            if (thickness > 15000) {
                thickness = 15000;
            }
        }

        model.concreteBaseMaterial.height = thickness;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeXPositiveReinforced)
    private BaseMaterial_IsEdgeXPositiveReinforced(value: boolean, model: IModelPe) {
        if (value != null) {
            this.baseMaterial(model).widthPositiveInfinity = value;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeXNegativeReinforced)
    private BaseMaterial_IsEdgeXNegativeReinforced(value: boolean, model: IModelPe) {
        if (value != null) {
            this.baseMaterial(model).widthNegativeInfinity = value;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeYPositiveReinforced)
    private BaseMaterial_IsEdgeYPositiveReinforced(value: boolean, model: IModelPe) {
        if (value != null) {
            this.baseMaterial(model).depthPositiveInfinity = value;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeYNegativeReinforced)
    private BaseMaterial_IsEdgeYNegativeReinforced(value: boolean, model: IModelPe) {
        if (value != null) {
            this.baseMaterial(model).depthNegativeInfinity = value;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXPositive)
    private BaseMaterial_EdgeXPositive(value: number, model: IModelPe) {
        if (value != null) {
            let edgeXPositive = value;

            if (edgeXPositive < 0) {
                edgeXPositive = 0;
            }

            const baseMaterial = this.baseMaterial(model);
            if (edgeXPositive != Number.POSITIVE_INFINITY) {
                baseMaterial.widthPositive = edgeXPositive;
                baseMaterial.widthPositiveInfinity = false;
            }
            else {
                baseMaterial.widthPositiveInfinity = true;
            }
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXNegative)
    private BaseMaterial_EdgeXNegative(value: number, model: IModelPe) {
        if (value != null) {
            let edgeXNegative = value;

            if (edgeXNegative < 0) {
                edgeXNegative = 0;
            }

            const baseMaterial = this.baseMaterial(model);
            if (edgeXNegative != Number.POSITIVE_INFINITY) {
                baseMaterial.widthNegative = edgeXNegative;
                baseMaterial.widthNegativeInfinity = false;
            }
            else {
                baseMaterial.widthNegativeInfinity = true;
            }
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYPositive)
    private BaseMaterial_EdgeYPositive(value: number, model: IModelPe) {
        if (value != null) {
            let edgeYPositive = value;

            if (edgeYPositive < 0) {
                edgeYPositive = 0;
            }

            const baseMaterial = this.baseMaterial(model);
            if (edgeYPositive != Number.POSITIVE_INFINITY) {
                baseMaterial.depthPositive = edgeYPositive;
                baseMaterial.depthPositiveInfinity = false;
            }
            else {
                baseMaterial.depthPositiveInfinity = true;
            }
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYNegative)
    private BaseMaterial_EdgeYNegative(value: number, model: IModelPe) {
        if (value != null) {
            let edgeYNegative = value;

            if (edgeYNegative < 0) {
                edgeYNegative = 0;
            }

            const baseMaterial = this.baseMaterial(model);
            if (edgeYNegative != Number.POSITIVE_INFINITY) {
                baseMaterial.depthNegative = edgeYNegative;
                baseMaterial.depthNegativeInfinity = false;
            }
            else {
                baseMaterial.depthNegativeInfinity = true;
            }
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXPositiveFromAnchor)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXNegativeFromAnchor)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYPositiveFromAnchor)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYNegativeFromAnchor)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXPositiveFromProfileCenter)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXNegativeFromProfileCenter)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYPositiveFromProfileCenter)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYNegativeFromProfileCenter)
    private BaseMaterial_EdgeYPositiveFromProfileCenter(value: number, model: IModelPe, design: DesignPe, useDevFeatures: boolean) {
        if (value != null && design.designType.id == DesignType.Concrete && model.concreteBaseMaterial != null && useDevFeatures) {
            model.concreteBaseMaterial.isProfileCenterControllingDimension = design.measureBaseMaterialEdgeFrom == MeasureBaseMaterialEdgeFromMode.ProfileCenter;
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_CustomLayoutPoints)
    private AnchorLayout_CustomLayoutPoints(_value: string, model: IModelPe, design: DesignPe) {
        this.BaseMaterial_EdgeXPositive(design.model[UIProperty.BaseMaterial_EdgeXPositive] as number, model);
        this.BaseMaterial_EdgeXNegative(design.model[UIProperty.BaseMaterial_EdgeXNegative] as number, model);
        this.BaseMaterial_EdgeYPositive(design.model[UIProperty.BaseMaterial_EdgeYPositive] as number, model);
        this.BaseMaterial_EdgeYNegative(design.model[UIProperty.BaseMaterial_EdgeYNegative] as number, model);
    }

    @propertyValueChanged(UIProperty.SmartAnchor_Enabled)
    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteCharacteristic_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_Thickness_Asad)
    @propertyValueChanged(UIProperty.Profile_ProfileSizeId_Asad)
    @propertyValueChanged(UIProperty.Profile_ProfileFamilyId_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteGrade_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXNegative_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeXPositive_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYNegative_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_EdgeYPositive_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeXNegativeReinforced_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeXPositiveReinforced_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeYNegativeReinforced_Asad)
    @propertyValueChanged(UIProperty.BaseMaterial_IsEdgeYPositiveReinforced_Asad)
    @propertyValueChanged(UIProperty.SmartAnchor_OptimizationOptions_UtilizationSlider)
    private Smartanchor_Enabled(value: boolean, model: IModelPe, design: DesignPe) {
        if (value != null) {
            model.isAsadWorkflow = (design.model[UIProperty.SmartAnchor_Enabled] as boolean ?? false) && (design.model[UIProperty.SmartAnchor_PlateHidden] as boolean ?? true);
        }
    }

    @propertyValueChanged(UIProperty.Option_EdgeDistanceDisplayType)
    private Option_EdgeDistanceDisplayType(value: EdgeDistanceDisplayType, model: IModelPe) {
        this.baseMaterial(model).edgeDistance = (value as number as EdgeDistance);
    }

    /* SCOPE CHECK CHANGED */
    @scopeCheckChanged()
    protected scopeChecksChanged(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (!model.concreteBaseMaterial) {
            throw new Error('model concreteBaseMaterial is undefined');
        }

        model.concreteBaseMaterial.invalid = model.concreteBaseMaterial.invalid ?? {};

        model.concreteBaseMaterial.invalid.widthPositive = false;
        model.concreteBaseMaterial.invalid.widthNegative = false;
        model.concreteBaseMaterial.invalid.depthPositive = false;
        model.concreteBaseMaterial.invalid.depthNegative = false;
        model.concreteBaseMaterial.invalid.thickness = false;

        if (scopeChecks != null) {
            const anchorToBaseMaterialEdgeScopeCheck = scopeChecks[MeasureResultType.AnchorToBaseMaterialEdge];
            let xPositiveInvalid = false;
            let xNegativeInvalid = false;
            let yPositiveInvalid = false;
            let yNegativeInvalid = false;

            if (anchorToBaseMaterialEdgeScopeCheck != null) {
                const dimensions = anchorToBaseMaterialEdgeScopeCheck.Value as Dimensions;

                xPositiveInvalid = (dimensions & Dimensions.XPositive) != 0;
                xNegativeInvalid = (dimensions & Dimensions.XNegative) != 0;
                yPositiveInvalid = (dimensions & Dimensions.YPositive) != 0;
                yNegativeInvalid = (dimensions & Dimensions.YNegative) != 0;
            }

            model.concreteBaseMaterial.invalid.widthPositive = xPositiveInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeXPositive] != null;
            model.concreteBaseMaterial.invalid.widthNegative = xNegativeInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeXNegative] != null;
            model.concreteBaseMaterial.invalid.depthPositive = yPositiveInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeYPositive] != null;
            model.concreteBaseMaterial.invalid.depthNegative = yNegativeInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeYNegative] != null;
            model.concreteBaseMaterial.invalid.thickness = scopeChecks[MeasureResultType.BaseMaterialThickness] != null;
        }
    }
}
