import { DesignStandard } from '../entities/code-lists/design-standard';
import { DesignType } from '../entities/code-lists/design-type';
import { DesignStandard as DesignStandardEnum } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { LocalizationServiceBase as LocalizationService } from '@profis-engineering/pe-ui-common/services/localization.common';

export function getDesignStandardNameByDesignStandardId(designStandardId: number) {
    switch (designStandardId) {
        case DesignStandardEnum.ACI:
            return 'ACI';
        case DesignStandardEnum.CSA:
            return 'CSA';
        case DesignStandardEnum.TW401:
            return 'TW401';
        case DesignStandardEnum.KR:
            return 'KR';
        case DesignStandardEnum.TH:
            return 'TH';
        case DesignStandardEnum.ETAG:
            return 'ETAG';
        case DesignStandardEnum.STO:
            return 'STO';
        case DesignStandardEnum.NZ:
            return 'NZ';
        case DesignStandardEnum.EC2:
            return 'EC2';
        case DesignStandardEnum.SATS:
            return 'SATS';
        case DesignStandardEnum.HK:
            return 'HK';
        case DesignStandardEnum.CN:
            return 'CN';
        case DesignStandardEnum.EOTA:
            return 'EOTA';
        case DesignStandardEnum.IS:
            return 'IS';
        case DesignStandardEnum.MS:
            return 'MS';
        default:
            throw new Error('Unknown Design standard.');
    }
}

export function isDesignStandardEurocodeBased(designStandardId: number) {
    switch (designStandardId) {
        case DesignStandardEnum.EC2:
        case DesignStandardEnum.SATS:
        case DesignStandardEnum.NZ:
        case DesignStandardEnum.HK:
        case DesignStandardEnum.CN:
        case DesignStandardEnum.IS:
        case DesignStandardEnum.MS:
            return true;

        case DesignStandardEnum.ACI:
        case DesignStandardEnum.CSA:
        case DesignStandardEnum.TW401:
        case DesignStandardEnum.KR:
        case DesignStandardEnum.TH:
        case DesignStandardEnum.ETAG:
        case DesignStandardEnum.STO:
            return false;

        default:
            throw new Error('Unknown Design standard.');
    }
}

export function isHnaBasedDesignStandard(value?: number) {
    switch (value) {
        case DesignStandardEnum.ACI:
        case DesignStandardEnum.CSA:
        case DesignStandardEnum.TW401:
        case DesignStandardEnum.KR:
        case DesignStandardEnum.TH:
            return true;

        default:
            return false;
    }
}

export function getAllowedDesignStandardsByDesignType(designStandards: DesignStandard[], designType: DesignType | undefined, designTypeRegionId: number, defaultRegionId: number) {
    // filter STO
    if (!designType?.isAllowedInDesignStandardSto) {
        designStandards = designStandards.filter((designStandard) => designStandard.id != DesignStandardEnum.STO);
    }

    const regionId = designTypeRegionId < 0
        ? defaultRegionId
        : designTypeRegionId;

    designStandards = designStandards
        .filter((designStandard) => designType?.allowedDesignStandardRegions?.some((row) => row.RegionId == regionId && designStandard.id &&
                row.DesignStandards.includes(designStandard.id)));

    return designStandards;
}

export function getGuidelineOnlyDescriptionText(designStandardId: number | undefined, localization: LocalizationService) {
        switch (designStandardId) {
            case DesignStandardEnum.IS:
               return format(localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly.DescriptionWithoutDesignMethod'), localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly.Description.DesignMethod.IndianDS'));
            case DesignStandardEnum.MS:
                return format(localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly.DescriptionWithoutDesignMethod'), localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly.Description.DesignMethod.MalaysiaDS'));
            default:
                return format(localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly.DescriptionWithoutDesignMethod'), localization.getString('Agito.Hilti.Profis3.AddEditDesign.EtagEnOnly.Description.DesignMethod.EuropeanDS'));
        }
}