import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignPe, ReportData } from '../../../shared/entities/design-pe';
import {
    ProfileUtilizationsEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import {
    DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { getVonMisesWorst } from '../../helpers/calculation-results-helper';
import {
    areLoadCombinationsAvailable, getLoadCombinationNumberText
} from '../../helpers/load-combination-helper';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

@Component({
    templateUrl: './profile-utilizations.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ProfileUtilizationsComponent implements OnInit {
    @Input()
    public id = '';

    @Input()
    public design?: DesignPe;

    @Input()
    public profileUtilizations?: ProfileUtilizationsEntity;

    public vonMisesOpend = false;
    public collapsed = false;
    public unitGroupEnum = {
        Percentage: UnitGroup.Percentage,
        Stress: UnitGroup.Stress
    };

    constructor(
        public localizationService: LocalizationService,
        public unit: UnitService,
        private user: UserService,
        private userSettings: UserSettingsService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get showUtilizations() {
        if (this.design?.hasIntegratedProfile) {
            return false;
        }

        return this.user.design != null
            && (this.hasUtilizations || this.showAddOn);
    }

    public get hasUtilizations() {
        return this.plasticStrain != null;
    }

    public get showAddOn() {
        // only show upgrade text after calculation is done
        if (this.design?.designData?.reportData?.BaseplateDesignData == null) {
            return false;
        }

        return (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) && !this.featuresVisibilityInfo.hasAdvancedFeature;
    }

    public get plasticStrain() {
        return this.profileUtilizations?.UtilizationPlasticStrain;
    }

    public get areLoadCombinationsAvailable() {
        return areLoadCombinationsAvailable(this.design?.designData?.reportData as ReportData);
    }

    public get loadCombination() {
        if (this.design == null) {
            return null;
        }

        return getLoadCombinationNumberText(this.design, this.localizationService, this.design.designData?.reportData?.DecisiveLoadCombinationIndexProfile ?? 0, true);
    }

    public get vonMisesWorst() {
        return getVonMisesWorst(this.plasticStrain);
    }

    public get isSatsStandard() {
        return this.design?.designStandard?.id == DesignStandard.SATS;
    }

    public get vonMisesHasDetails() {
        return this.vonMisesWorst != null && (this.vonMisesWorst.materialFy || this.vonMisesWorst.limitPlasticStrain);
    }

    public get utilizationInvalid() {
        return this.plasticStrain != null && !this.plasticStrain.CheckStatus;
    }

    public get utilizationValue() {
        if (this.plasticStrain == null) {
            return null;
        }

        return this.plasticStrain.UtilizationPercentage;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-tab-profiles',
            'sprite-arrow-up',
            'sprite-arrow-down'
        );

        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.ProfileUtilization);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.ProfileUtilization, collapsed);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
