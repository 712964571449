<pe-modal-header [modalInstance]="modalInstance" [title]="title">
</pe-modal-header>

<pe-modal-body>
    <div class="filters" *ngIf="profileStandards.length > 1">
        <div class="filter-wrapper">
            <div class="filter-title" [innerHtml]="translate('Agito.Hilti.Profis3.SelectProfile.ChooseProfileStandard')"></div>
            <div class="filter-control">
                <pe-dropdown
                    [id]="profileStandardDropdown.id"
                    [items]="profileStandardDropdown.items"
                    [selectedValue]="profileStandardDropdown.selectedValue"
                    (selectedValueChange)="onProfileStandardDropdown($any($event).detail)">
                </pe-dropdown>
            </div>
        </div>
    </div>

    <div class="items">
        <div
            *ngFor="let item of items; index as i; trackBy:identifyItem"
            class="item-wrapper"
            [ngClass]="classNumberOfColumns"
            [ngStyle]="{'width': (100 / numberOfColumns) + '%'}">
            <div class="item modal-grid-button" tabindex="0"
                [class.selected]="item.value === selectedValue"
                [class.no-right-border]="isLastColumn(i)"
                [class.no-bottom-border]="isLastRow(i)"
                (click)="select(item.value)">
                <div class="item-container" [ngClass]="classNumberOfColumns">
                    <div class="image-container" [ngClass]="classNumberOfColumns">
                        <span class="pe-ui-pe-sprite sprite-responsive" [ngClass]="'pe-ui-pe-' + getItemImage(item.image)"></span>
                    </div>

                    <div class="content-container" [ngClass]="classNumberOfColumns">
                        <div class="name-container">
                            <p class="name">{{item.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</pe-modal-body>
