<div class="utilizations box-section box-section-white" *ngIf="showUtilizations">
    <div class="box-section-header-with-img" *ngIf="showAddOn">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-profiles"></div>
            </div>
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations')"></span>
        </div>

        <div class="add-on" [innerHtml]="translate('Agito.Hilti.Profis3.Main.Region.AddOn')"></div>
    </div>

    <div class="box-section-header-with-img" *ngIf="hasUtilizations">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-profiles"></div>
            </div>
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations')"></span>
            <span class="bar-subtitle"
                *ngIf="!collapsed && areLoadCombinationsAvailable" [innerHtml]="loadCombination"></span>
        </div>

        <pe-utilization-bar [collapsed]="collapsed" [utilizationInvalid]="utilizationInvalid" [utilizationValue]="utilizationValue"></pe-utilization-bar>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content" *ngIf="hasUtilizations">
        <div class="utilization" *ngIf="vonMisesWorst != null"
            [class.invalid-value]="vonMisesWorst.valueError">
            <div id="{{id}}-profile-utilizations-button" class="utilization-button">
                <div class="button-content">
                    <div class="text-progress-container">
                        <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations.VonMisesStressPlasticStrain')"></p>
                        <div class="progress-percent-container" *ngIf="vonMisesWorst != null">
                            <div class="progress-percent" [ngStyle]="{ 'width': vonMisesWorst.percentage + '%' }">
                            </div>
                        </div>
                    </div>

                    <div class="number-container">
                        <div class="worst-result details-line" *ngIf="vonMisesWorst.maxStress != null"
                            [class.invalid-value]="vonMisesWorst.valueError">
                            <p *ngIf="!isSatsStandard" class="text"
                                [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations.VonMisesStress')"></p>
                            <p *ngIf="isSatsStandard" class="text"
                                [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations.VonMisesStress.SATS')"></p>
                            <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(vonMisesWorst.maxStress, unitGroupEnum.Stress)"></p>
                        </div>

                        <div class="worst-result details-line" *ngIf="vonMisesWorst.maxStrain != null"
                            [class.invalid-value]="vonMisesWorst.valueError">
                            <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations.PlasticStrain')"></p>
                            <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(vonMisesWorst.maxStrain, unitGroupEnum.Percentage)"></p>
                        </div>

                        <div class="details separator" *ngIf="vonMisesOpend">
                            <div class="text-progress-container"
                                *ngIf="(vonMisesWorst.materialName != null && vonMisesWorst.materialFy != null) || vonMisesWorst.limitPlasticStrain != null">
                                <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations.MaterialProperties')"></p>
                            </div>

                            <div class="design-right-collapse-container">
                                <span class="design-right-collapse-line"></span>
                            </div>

                            <div *ngIf="isSatsStandard" class="worst-result details-line"
                                [class.invalid-value]="vonMisesWorst.valueError">
                                <p class="equation" [innerHtml]="translate('Agito.Hilti.Profis3.BaseplateUtilizations.phiFy')"></p>
                                <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(vonMisesWorst.materialFyDividedByPhiSteel, unitGroupEnum.Stress)"></p>
                            </div>

                            <div *ngIf="!isSatsStandard" class="worst-result details-line"
                                [class.invalid-value]="vonMisesWorst.valueError">
                                <p class="equation" [innerHtml]="vonMisesWorst.materialName"></p>
                                <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(vonMisesWorst.materialFyDividedByGammaM0, unitGroupEnum.Stress)"></p>
                            </div>

                            <div class="worst-result details-line"
                                [class.invalid-value]="vonMisesWorst.valueError">
                                <p class="equation" [innerHtml]="translate('Agito.Hilti.Profis3.ProfileUtilizations.EpsilonLim')"></p>
                                <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(vonMisesWorst.limitPlasticStrain, unitGroupEnum.Percentage)"></p>
                            </div>
                        </div>
                    </div>

                    <div class="design-right-collapse-container" [class.opened]="vonMisesOpend"
                        *ngIf="vonMisesHasDetails">
                        <span class="design-right-collapse-line"></span>
                        <button type="button" class="design-right-collapse-button"
                            (click)="vonMisesOpend = !vonMisesOpend">
                            <span class="pe-ui-pe-sprite" [ngClass]="vonMisesOpend ? 'pe-ui-pe-sprite-arrow-up' : 'pe-ui-pe-sprite-arrow-down'"></span>
                        </button>
                        <span class="design-right-collapse-line"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
