import {
    SeismicIntensityFactorEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class SeismicIntensityFactor extends CodeList {

    public static fromService(codeList: SeismicIntensityFactorEntity) {
        return new SeismicIntensityFactor(CodeList.baseFromService('SeismicIntensityFactorEntity', codeList));
    }

    public override cloneDeep(): SeismicIntensityFactor {
        return super.cloneDeep() as SeismicIntensityFactor;
    }
}
