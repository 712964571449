import { Injectable } from '@angular/core';
import { IUserMenuFavorites } from '@profis-engineering/pe-ui-common/entities/favorites';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { FavoritesServiceInjected } from '@profis-engineering/pe-ui-common/services/favorites.common';
import {
    DesignType
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { ISmartAnchorItemsOrderEntity } from './smart-anchor-order.service';

export interface IFavoritesData extends IUserMenuFavorites {
    smartAnchorItems: ISmartAnchorItemsOrderEntity[];
}

const menu3DConcrete = 'menu3DConcrete';
const menu3DMasonry = 'menu3DMasonry';
const menu3DHandrail = 'menu3DHandrail';
const menu3DMetalDeck = 'menu3DMetalDeck';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService extends FavoritesServiceInjected {
    public initFavorites(data: IUserMenuFavorites) {
        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, DesignType.Concrete),
            data?.favorites?.[menu3DConcrete]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, DesignType.Masonry),
            data?.favorites?.[menu3DMasonry]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, DesignType.Handrail),
            data?.favorites?.[menu3DHandrail]
        );

        this.initFavoritesInfo(
            this.getInfo(MenuType.Menu3D, DesignType.MetalDeck),
            data?.favorites?.[menu3DMetalDeck]
        );
    }

    public getMenuRegionIdForFavorites(id: string, designType: DesignType) {
        switch (designType) {
            case DesignType.Concrete:
            case DesignType.Masonry:
            case DesignType.Handrail:
            case DesignType.MetalDeck:
                return `region-${id}`;
        }

        return undefined;
    }

    public getMenuRegionIdFromFavorites(id: string, designType: DesignType) {
        switch (designType) {
            case DesignType.Concrete:
            case DesignType.Masonry:
            case DesignType.Handrail:
            case DesignType.MetalDeck:
                return id.substring('region-'.length);
        }

        return undefined;
    }

    public getFavoritesTypePe(menuType: MenuType, designType: DesignType) {
        let favoritesType: string | undefined = undefined;

        if (menuType == MenuType.Menu3D) {
            switch (designType) {
                case DesignType.Concrete:
                    favoritesType = menu3DConcrete;
                    break;
                case DesignType.Masonry:
                    favoritesType = menu3DMasonry;
                    break;
                case DesignType.Handrail:
                    favoritesType = menu3DHandrail;
                    break;
                case DesignType.MetalDeck:
                    favoritesType = menu3DMetalDeck;
                    break;
            }
        }

        return favoritesType;
    }

    private getInfo(menuType: MenuType, designType: DesignType) {
        const favoritesType = this.getFavoritesTypePe(menuType, designType);
        if (favoritesType == null) {
            throw new Error('Could not find favorites type.');
        }

        if (this.infoBase[favoritesType] == null) {
            this.infoBase[favoritesType] = {
                favoritesType,
                dbFavorites: [],
                favorites: [],
                setDefer: new Deferred<string[]>(),
                updateId: undefined,
                updating: false
            };
        }

        return this.infoBase[favoritesType];
    }
}
