import { DesignType as DesignTypeId } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';

export function getDesignTypeSpecificKey(localization: LocalizationServiceBase, key: string, designTypeId: DesignTypeId) {
    const designTypeIdEnum = {
        Concrete: DesignTypeId.Concrete,
        Handrail: DesignTypeId.Handrail,
        Masonry: DesignTypeId.Masonry,
        MetalDeck: DesignTypeId.MetalDeck
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const designTypePart = Object.entries(designTypeIdEnum).find(([_, value]: [string, DesignTypeId]) => value == designTypeId);
    if (designTypePart != null && designTypePart.length > 0) {
        const newKey = `${key}.${designTypePart[0]}`;
        return localization.getKeyExists(newKey) ? newKey : key;
    }

    return key;
}

