<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="supplementary-reinforcement-description">
        <span class="supplementary-reinforcement-description" [innerHtml]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Description')"></span>
    </div>
    <br>

    <p class="supplementary-reinforcement-tension-title sub-title title"
        [innerHtml]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Tension.Title')">
    </p>

    <div class="supplementary-reinforcement-tension-description">
        <span class="tension-description" [innerHtml]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Tension.Description')"></span>
    </div>

    <div class="supplementary-reinforcement-tension-image">
        <img src="{{supplementaryReinforcementTensionImage}}" alt="supplementary-reinforcement-tension">
    </div>
    <br>

    <p class="supplementary-reinforcement-shear-title sub-title title"
        [innerHtml]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Shear.Title')">
    </p>

    <div class="supplementary-reinforcement-shear-description">
        <span class="shear-description" [innerHtml]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Shear.Description')"></span>
    </div>

    <div class="supplementary-reinforcement-shear-image">
        <img src="{{supplementaryReinforcementShearImage}}" alt="supplementary-reinforcement-shear">
    </div>
    <br>

    <div class="supplementary-reinforcement-verification">
        <span class="description" [innerHtml]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Verification')"></span>
    </div>
    <br>

    <div class="supplementary-reinforcement-note">
        <span class="description" [innerHtml]="translate('Agito.Hilti.Profis3.SupplementaryReinforcementInfo.Note')"></span>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [id]="'supplementary-reinforcement-info-ok-button'" [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
