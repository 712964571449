import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CodeList, getCodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { DesignType } from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { ISaveDesignResult } from '@profis-engineering/pe-ui-common/entities/save-design';
import { DesignTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import { getNumberDecimalSeparator, getNumberGroupSeparator } from '@profis-engineering/pe-ui-common/helpers/localization-helper';
import { DocumentAccessMode, IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';
import { environment } from '../../environments/environmentPe';
import { DesignMethodGroup } from '../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../shared/entities/code-lists/design-standard';
import { Region } from '../../shared/entities/code-lists/region';
import {
    getCalculationTypeText, IDetailedDisplayDesign, IDisplayDesign, isCBFEMCalculation, isHandrailCBFEMCalculation
} from '../../shared/entities/display-design';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import { ConvertDesignRequest, ConvertDesignResponse } from '../../shared/generated-modules/Hilti.PE.Core.Entities';
import { ConvertFromJsonToXmlDesignRequest, ConvertFromJsonToXmlDesignResponse, ConvertToLatestVersionDesignRequest } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import { ProjectDesingFileStorageFormat } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import { ProjectDesignBaseEntity } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignType as DesignTypeId
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { DesignExternalMetaDataPe } from '../entities/design-external-metadata';
import { DocumentHelper } from '../helpers/document-helper';
import { getSpriteAsIconStyle } from '../sprites';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { CalculationServicePE } from './calculation-pe.service';
import { CodeListService } from './code-list.service';
import { DesignTemplateService } from './design-template.service';
import { DocumentService } from './document.service';
import { FeaturesVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { LoggerService } from './logger.service';
import { NumberService } from './number.service';
import { OfflineService } from './offline.service';
import { RoutingService } from './routing.service';
import { TrackingService } from './tracking.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class DesignService {
    constructor(
        private localization: LocalizationService,
        private codeList: CodeListService,
        private userService: UserService,
        private calculationService: CalculationServicePE,
        private trackingService: TrackingService,
        private routingService: RoutingService,
        private designTemplateService: DesignTemplateService,
        private userSettingsService: UserSettingsService,
        private apiService: ApiService,
        private documentService: DocumentService,
        private numberService: NumberService,
        private browserService: BrowserService,
        private offlineService: OfflineService,
        private featuresVisibilityService: FeaturesVisibilityService,
        private logger: LoggerService
    ) { }

    public getNewDesignName(designType: DesignTypeId) {
        const designTypeObj = this.getDesignType(designType);
        if (designTypeObj != null) {
            const translatedNameText = designTypeObj.getTranslatedNameText(getCodeListTextDeps(this.localization));
            return `${translatedNameText} - ${this.localization.moment(new Date()).format('ll')}`;
        }

        return '';
    }

    public async openTemplate(designId: string) {
        try {
            const template = await this.designTemplateService.getById(designId);
            const projectDesign = (await this.convertStringToLatestDesign(template.ProjectDesign)).body as ProjectDesignBaseEntity;
            const design = await this.calculationService.createAndOpenTemplate(projectDesign, template.DesignTemplateDocumentId, template.DesignTemplateName);

            this.userService.changeDesign(undefined, design);
            void this.trackingService.trackOnTemplateOpen(design.templateId as string, design.designType.id);
            this.routingService.navigateToUrl(UrlPath.main + design.templateId);

            return {
                designId: design.id,
                path: UrlPath.main + design.id,
                design,
                success: true
            } as ISaveDesignResult;
        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
            }
            return {
                path: UrlPath.main,
                success: false
            } as ISaveDesignResult;
        }
    }

    public async newDesignFromTemplate(templateId: string, projectId?: string, designName?: string) {
        try {
            const template = await this.designTemplateService.getById(templateId);
            const response = await this.convertStringToLatestDesign(template.ProjectDesign);
            const projectDesign = response.body as ProjectDesignBaseEntity;
            const designType = (this.codeList.projectCodeLists[ProjectCodeList.DesignType]).find(x => x.id == projectDesign.ProjectDesignType);
            projectDesign.DesignName = designName ?? this.documentService.createUniqueName(
                this.createDesignName(this.localization, designType),
                Object.values(this.documentService.draftsProject.designs ?? []).map((item) => item.designName));

            if (projectId) {
                projectDesign.ProjectName = this.documentService.findProjectById(projectId)?.name as string;
            }

            const projectDesign_1 = projectDesign;
            const design = await this.calculationService.createAndOpenFromProjectDesign(projectDesign_1, projectId ?? this.documentService.draftsProject.id as string, templateId);

            const project = this.documentService.findProjectById(projectId ?? this.documentService.draftsProject.id as string);
            this.userService.changeDesign(project, design);
            this.routingService.navigateToUrl(UrlPath.main + design.id);

            return {
                designId: design.id,
                path: UrlPath.main + design.id,
                design,
                success: true
            } as ISaveDesignResult;

        } catch (err) {
            if (err instanceof Error) {
                console.error(err);
            }
            return {
                path: UrlPath.main,
                success: false
            } as ISaveDesignResult;
        }
    }

    public openFromDocumentDesign(documentDesign: IDesignListItem) {
        documentDesign.projectName = this.documentService.findProjectById(documentDesign.projectId)?.name ?? documentDesign.projectName;

        return this.documentService.openDesignExclusivePe(documentDesign)
            .then((projectDesign) => this.calculationService.openFromProjectDesign(projectDesign, documentDesign.id))
            .then((result) => {
                const design = result.design;
                this.documentService.updateDesignWithNewContentPe(
                        design.id,
                        design.projectId,
                        design.designName,
                        design.designData.projectDesign,
                        documentDesign.metaData as DesignExternalMetaDataPe,
                        undefined as unknown as IDetailedDisplayDesign,
                        false,
                        false,
                        DocumentAccessMode.Open
                    ).finally(() => {
                        this.userService.changeDesign(this.documentService.findProjectById(design.projectId), design);
                        this.routingService.navigateToUrl(UrlPath.main + documentDesign.id);
                    });
                return result;
            });
    }

    public toDisplayDesign(design: IDesignListItem, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const region = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[]).find(x => x.id == design.metaData?.region)?.commonRegion;
        const designMethodGroup = (this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[]).find(dMeth => dMeth.id == design.metaData?.designMethod) as DesignMethodGroup;
        const designType = design.metaData?.designType;

        const metadataPe = design.metaData as DesignExternalMetaDataPe;
        const calculationTypeText = getCalculationTypeText(designType, metadataPe?.calculationType, metadataPe.handrailConnectionType, this.localization);
        const isCBFEM = isCBFEMCalculation(metadataPe?.calculationType) || isHandrailCBFEMCalculation(designType, metadataPe?.handrailConnectionType);
        const designStandard = (this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[]).find(x => x.id == design.metaData.standard) as DesignStandard;

        const parentProject = this.documentService.findProjectById(design.projectId);
        const designStandardTextApprovalNumberText = this.createDesignStandardApprovalNumberText(design.metaData?.approvalNumber, designStandard, designType);
        const regionDesignStandardApprovalNumber = this.createRegionDesignStandardApprovalNumber(region, designStandard, design.metaData?.approvalNumber, designType);
        const utcTime  = this.localization.moment(design.createDate).utc(true).toDate();
        const localTime = this.localization.moment(utcTime).format('MMM D, YYYY h:mm A');
        return {
            id: design.id,
            name: design.designName,
            created: design.createDate,
            createdDisplay: () => this.featuresVisibilityService.isFeatureEnabled('PE_EnableNewHomePage') ? localTime : this.localization.moment(design.createDate).format('LL'),
            rawProject: parentProject,
            projectId: design.projectId,
            productName: design.metaData?.productName ?? '',
            designType,
            approvalNumber: design.metaData?.approvalNumber ?? '',
            calculationTypeText,
            isCBFEM,
            region: region as CommonRegion,
            designStandard,
            designMethodGroup,
            regionDesignStandardApprovalNumber,
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(design.id) : undefined,
            image: getSpriteAsIconStyle(designTypeImage),
            displayDesignType: DisplayDesignType.design,
            regionText: this.createRegionText(region),
            designStandardTextApprovalNumberText,
            integrationDocument: design.integrationDocument,
            isFavorite: design.isFavorite ?? false,
            isSharedByMe: design.isSharedByMe ?? false,
        };
    }

    public async downloadDesign(designType: DesignTypeId, name: string, designId: string) {
        const documentDesign = this.documentService.findDesignById(designId);
        if (designType != documentDesign.metaData.designType) {
            return new Deferred<void>().resolve();
        }

        const projectDesign = await this.documentService.openDesignExclusivePe(documentDesign);
        projectDesign.DesignName = name;
        const design = (await this.calculationService.openFromProjectDesign(projectDesign, designId, false)).design;

        await DocumentHelper.download(this.apiService, this.browserService, this.localization, this.documentService, this.offlineService, design, false);
        design.setSavedStateIdx();
    }

    public toDisplayDesignTemplate(template: DesignTemplateEntity, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const region = (this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[]).find(x => x.id == template.RegionId)?.commonRegion;
        const designStandard = (this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[]).find(x => x.id == template.DesignStandardId) as DesignStandard;
        const designType = (this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[]).find(x => x.id == template.DesignTypeId);
        const regionDesignStandardApprovalNumber = this.createRegionDesignStandardApprovalNumber(region, designStandard, template.ApprovalNumber, template.DesignTypeId);
        const designStandardTextApprovalNumberText = this.createDesignStandardApprovalNumberText(template.ApprovalNumber, designStandard, template.DesignTypeId);


        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        return {
            id: template.DesignTemplateDocumentId,
            name: template.DesignTemplateName,
            created: template.DateCreate,
            createdDisplay: () => this.localization.moment(template.DateCreate).format('ll'),
            projectName: designType?.getTranslatedNameText(codeListDeps) ?? '',
            productName: template.AnchorName,
            designType: template.DesignTypeId,
            approvalNumber: template.ApprovalNumber,
            isCBFEM: false,
            region: region as CommonRegion,
            designStandard,
            regionDesignStandardApprovalNumber,
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail != undefined ? getDesignThumbnail(template.DesignTemplateDocumentId) : undefined,
            displayDesignType: DisplayDesignType.template,
            regionText: this.createRegionText(region),
            designStandardTextApprovalNumberText,
            isSharedByMe: template.isSharedByMe ?? false,
        } as IDisplayDesign;
    }

    public createRegionDesignStandardApprovalNumber(region?: CommonRegion, designStandard?: DesignStandard, approvalNumber?: string, designTypeId?: DesignTypeId) {
        if (region?.id == SpecialRegion.Default) {
            const regionCodeList = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
            region = regionCodeList.find(region => region.id == this.userSettingsService.settings.application.general.regionId.value)?.commonRegion;
        }

        const designType = (this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[]).find(x => x.id == designTypeId);
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const text = [
            region != null ? region.getTranslatedNameText(codeListDeps) : null,
            designStandard != undefined ? this.localization.getString(this.getDesignTypeSpecificKey(designStandard.nameResourceKey, designType) ?? '') : null,
            approvalNumber
        ].filter((value) => value != null && value != '').join(', ');

        if (text == null || text == '') {
            return this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }

    private getDesignType(designType: DesignTypeId) {
        const designTypeCodeList = this.codeList.projectCodeLists[ProjectCodeList.DesignType];
        return designTypeCodeList.find(dt => dt.id == designType);
    }

    private convertStringToLatestDesign(projectDesignAsString: string) {
        const urlConvert = `${environment.baseplateApplicationWebServiceUrl}ConvertToLatestVersionDesign`;

        const dataConvert: ConvertToLatestVersionDesignRequest = {
            RawProjectDesign: projectDesignAsString,
            Language: this.localization.selectedLanguage,
            NumberDecimalSeparator: getNumberDecimalSeparator(this.localization.numberFormat(), this.userSettingsService),
            NumberThousandsSeparator: getNumberGroupSeparator(this.localization.numberFormat(), this.userSettingsService),
            StorageFormat: ProjectDesingFileStorageFormat.JSON
        };

        return this.apiService.request<ProjectDesignBaseEntity>(new HttpRequest('POST', urlConvert, dataConvert));
    }

    private createDesignName(localization: LocalizationService, designType?: CodeList) {
        const translatedNameText = designType?.getTranslatedNameText(getCodeListTextDeps(localization));
        return `${translatedNameText} - ${localization.moment(new Date()).format('ll')}`;
    }

    private createDesignStandardApprovalNumberText(approvalNumber?: string, designStandard?: DesignStandard, designTypeId?: number) {
        const designType = (this.codeList.projectCodeLists[ProjectCodeList.DesignType] as DesignType[]).find(x => x.id == designTypeId);
        const text = [
            designStandard != undefined ? this.localization.getString(this.getDesignTypeSpecificKey(designStandard.nameResourceKey ?? '', designType) ?? '') : undefined,
            approvalNumber
        ].filter((value) => value != undefined && value != '').join(', ');

        if (text == undefined || text == '') {
            return this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown');
        }

        return text;
    }

    private getDesignTypeSpecificKey(key?: string, designType?: DesignType) {
        if (designType == undefined) {
            return key;
        }

        const newKey = `${key}.${designType.displayKey}`;
        return this.localization.getKeyExists(newKey) ? newKey : key;
    }

    private createRegionText(region?: CommonRegion) {
        if (region?.id == SpecialRegion.Default) {
            const regionCodeList = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
            region = regionCodeList.find(region => region.id == this.userSettingsService.settings.application.general.regionId.value)?.commonRegion;
        }

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        const text = region?.getTranslatedNameText(codeListDeps) ?? '';

        return text == '' ? this.localization.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown') : text;
    }

    public async convertDesignToXml(design: string) {
        const request: ConvertDesignRequest = {
            JsonDesign: design
        };

        const url = `${environment.baseplateApplicationWebServiceUrl}ConvertDesignToXml`;

        try {
            const result = await this.apiService.request<ConvertDesignResponse>(new HttpRequest('POST', url, request));
            return result?.body?.XmlDesign;
        } catch (response: any) {
            this.logger.logServiceError(response, 'BaseplateApplication', 'ConvertJsonDesignToXml');
        }

        return undefined;
    }

    public async convertMultipleDesignsToXmls(designs: { [id: string]: string }) {
        const request: ConvertFromJsonToXmlDesignRequest = {
            Items: Object.keys(designs).map((k) => ({Id: k, Content: designs[k]}))
        };

        const url = `${environment.baseplateCalculationWebServiceUrl}ConvertFromJsonToXmlDesign`;

        try {
            const result = await this.apiService.request<ConvertFromJsonToXmlDesignResponse>(new HttpRequest('POST', url, request));
            return Object.fromEntries((result?.body?.Items ?? []).map(x => [x.Id, x.Content]));
        } catch (response: any) {
            this.logger.logServiceError(response, 'BaseplateCalculation', 'ConvertFromJsonToXmlDesign');
        }

        return {};
    }
}
