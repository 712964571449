import escape from 'lodash-es/escape';

import { HttpResponseBase } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import { environment } from '../../../environments/environmentPe';
import {
    UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    LoadType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    IntegrationDataResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities';
import {
    DataIntegrationRequestType, DataIntegrationType, ErrorType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    StaadProLoadCombinationRequest, StaadProLoadCombinationResponse, StaadProNodeSelectionResponse,
    StaadProResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.StaadPro';
import {
    StaadProBaseLoadCombinationData, StaadProLoadCombinationData, StaadProLoadComposition
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.StaadPro.Models.Loads';
import { concatFilterLoadCombinations } from '../../helpers/load-combination-helper';
import { getProperty } from '../../helpers/object-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { GuidService } from '../../services/guid.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { SharedEnvironmentService } from '../../services/shared-environment.service';

interface IUserNotification {
    readonly controlEnableFlag: string;
    readonly buttonTranslationKey: string;
    readonly notificationTranslationKey: string;
}

interface ILoadCombination {
    LoadCombination: StaadProLoadCombinationData;
    Name: string;
}

@Component({
    templateUrl: './staad-pro-import.component.html',
    styleUrls: ['./staad-pro-import.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class StaadProImportComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance;

    public readonly allUserNotifications: ReadonlyArray<IUserNotification> = [
        {
            controlEnableFlag: 'isCommunicationStateError',
            buttonTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.IsCommunicationError'
        },
        {
            controlEnableFlag: 'areActiveModelCalculationsMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.RefreshOverlayDescription'
        },
        {
            controlEnableFlag: 'isActiveObjectMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.MissingObjectName'
        },
        {
            controlEnableFlag: 'isActiveModelMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.MissingModelName'
        },
        {
            controlEnableFlag: 'isActiveObjectVersionNotSupported',
            buttonTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.StaadProImport.IsActiveObjectVersionNotSupported'
        }
    ];

    public submitted = true;
    public refreshingNodes = false;
    public refreshOverlayVisible = false;

    public activeModelName?: string;
    public detectedNodes: string[] = [];
    public userNotifications: IUserNotification[] = [];

    public isCommunicationStateError = false;
    public areActiveModelCalculationsMissing = false;
    public isActiveObjectMissing = false;
    public isActiveModelMissing = false;
    public isActiveObjectVersionNotSupported = false;

    public loadCombinationsCheckbox!: CheckboxButtonProps<ILoadCombination>;

    private isIntegrationsWorkflowRetry = false;

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private guid: GuidService,
        private modal: ModalService,
        private integrationsData: IntegrationsDataService,
        private calculationService: CalculationServicePE,
        private sharedEnvironmentData: SharedEnvironmentService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get detectedNodesFormatted() {
        return this.detectedNodes.join(', ');
    }

    /**
     * Indicates if the modal should be prevented from closing
     */
    public get preventClosing() {
        if (this.refreshOverlayVisible) {
            return this.submitted || this.refreshingNodes;
        }

        return this.submitted;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-refresh-l-dark',
            'sprite-info-tooltip'
        );

        // Don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            return this.preventClosing
                ? false
                : true;
        });

        // controls
        this.loadCombinationsCheckbox = {
            items: [],
            selectedValues: new Set()
        };

        this.newIntegrationInit();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    /**
     * Closes the Staad Pro import modal
     */
    public close() {
        this.modalInstance.close();
    }

    public dismiss(reason?: string) {
        this.modalInstance.dismiss(reason);
    }

    public isButtonDisabled(allowEmptyArray = false): boolean {
        if (this.refreshOverlayVisible ||
            this.submitted ||
            this.isActiveObjectMissing ||
            this.isActiveModelMissing ||
            this.isActiveObjectVersionNotSupported ||
            this.areActiveModelCalculationsMissing) {
            return true;
        }

        if (!allowEmptyArray && !this.validLoadCombinationSelection()) {
            return true;
        }

        return false;
    }

    /**
     * Opens the import info pop-up
     */
    public openCombinationImportPopup() {
        this.modal.openConfirmChange({
            id: 'staad-pro-import-combinationImport-popup',
            title: this.localization.getString('Agito.Hilti.Profis3.StaadProImport.CombinationImportPopup.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.StaadProImport.CombinationImportPopup.Text'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.StaadProImport.CombinationImportPopup.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    /**
     * Selects all load combinations
     */
    public selectAll() {
        if (this.submitted) {
            return;
        }

        const selectionCount = this.loadCombinationsCheckbox.selectedValues?.size;

        let newCheckedStatus = false;
        if (selectionCount != this.loadCombinationsCheckbox.items?.length) {
            newCheckedStatus = true;
        }

        this.loadCombinationsCheckbox.items?.forEach(v => {
            if (newCheckedStatus) {
                this.loadCombinationsCheckbox.selectedValues?.add(v.value);
            }
            else {
                if (this.loadCombinationsCheckbox.selectedValues?.has(v.value)) {
                    this.loadCombinationsCheckbox.selectedValues.delete(v.value);
                }
            }
        });
    }

    /**
     * Import the selected loads
     */
    public import() {
        this.user.design.usageCounter.StaadProImport++;

        this.submitted = true;
        const selectedLoads = Array.from((this.loadCombinationsCheckbox.selectedValues ?? []).values());

        this.getLoadCombinations(selectedLoads);
    }

    /**
     * Refreshes the instance connection
     * @param isRecursiveRefresh If false, allow one recursive refresh, otherwise no additional refreshes will happen after running this function
     */
    public refreshInstance(isRecursiveRefresh?: boolean) {
        this.isIntegrationsWorkflowRetry = (isRecursiveRefresh == null) ?
            false :
            isRecursiveRefresh;

        this.isActiveObjectMissing = false;
        this.isActiveModelMissing = false;
        this.isActiveObjectVersionNotSupported = false;
        this.areActiveModelCalculationsMissing = false;
        this.isCommunicationStateError = false;

        this.activeModelName = undefined;
        this.detectedNodes = [];

        this.loadCombinationsCheckbox.items = [];
        this.loadCombinationsCheckbox.selectedValues = new Set();

        this.submitted = true;
        this.updateUserNotifications();

        this.integrationsData.requestData<StaadProResponse>(
            DataIntegrationType.StaadPro,
            DataIntegrationRequestType.RunningInstance,
            () => {
                this.isActiveObjectMissing = true;
                this.updateUserNotifications();
                this.handleServiceError();
            },
            (response) => {
                this.onRunningInstanceResponseReceived(response);
            })
            .catch((err) => {
                this.isActiveObjectMissing = true;
                this.updateUserNotifications();
                this.handleServiceError(err, true);
            });
    }

    private onRunningInstanceResponseReceived(response: StaadProResponse): void {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            return;
        }

        this.activeModelName = response.FileName;

        this.integrationsData.requestData<StaadProNodeSelectionResponse>(
            DataIntegrationType.StaadPro,
            DataIntegrationRequestType.GetSelectedNodes,
            () => {
                this.handleServiceError();
            },
            (response2) => {
                this.onSelectedNodesResponseReceived(response2);
            })
            .catch((err) => this.handleServiceError(err, true));
    }

    private validLoadCombinationSelection(): boolean {
        if (
            (this.loadCombinationsCheckbox.items?.length ?? 0) < 1
            ||
            (this.loadCombinationsCheckbox.selectedValues?.size ?? 0) < 1
        ) {
            return false;
        }

        return true;
    }

    private onSelectedNodesResponseReceived(response: StaadProNodeSelectionResponse): void {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            return;
        }
        else if (response.BaseLoadCombinationData == null ||
            response.BaseLoadCombinationData.length == 0) {
            this.areActiveModelCalculationsMissing = true;
            this.submitted = false;
            this.updateUserNotifications();

            return;
        }

        this.handleSelectedNodes(response.BaseLoadCombinationData);
    }

    private handleSelectedNodes(loadCombinations: StaadProBaseLoadCombinationData[]): void {
        const loadCombinationsMapped: ILoadCombination[] = [];
        for (let i = 0; i < loadCombinations.length; i++) {
            const loadCombination = loadCombinations[i];

            this.detectedNodes.push(loadCombination.NodeNumber.toString());

            const name = this.formatNodeName(loadCombination.NodeNumber.toString(), i + 1, loadCombination.LoadComposition);

            loadCombinationsMapped.push({
                LoadCombination: loadCombination,
                Name: name
            } as ILoadCombination);
        }
        this.updateLoadCombinationsCheckboxItems(loadCombinationsMapped);

        // from detected nodes display on the left, remove duplicate node values.
        this.detectedNodes = this.detectedNodes
            .filter((elem, index, self) => {
                return index === self.indexOf(elem);
            });

        this.submitted = false;
    }

    private formatNodeName(
        pointName: string,
        iteration: number,
        load: StaadProLoadComposition) {
        let formattedLoadCombinations = `${load.Name} `;

        for (let i = 0; i < load.CompositionElements.length; i++) {
            formattedLoadCombinations += `${load.CompositionElements[i].ScaleFactor.toFixed(3)} ${load.CompositionElements[i].Name}`;

            if (i != load.CompositionElements.length - 1) {
                formattedLoadCombinations += ' + ';
            }
        }

        return `LC;${iteration};${pointName};${formattedLoadCombinations}`;
    }

    private newIntegrationInit(): void {
        this.submitted = true;

        this.integrationsData.requestData<StaadProResponse>(
            DataIntegrationType.StaadPro,
            DataIntegrationRequestType.RunningInstance,
            () => {
                this.handleServiceError();
            },
            (response) => {
                this.onRunningInstanceResponseReceived(response);
            })
            .catch((err) => this.handleServiceError(err, true));
    }

    private validateResponse(response: IntegrationDataResponse) {
        if (response.ErrorType != ErrorType.None) {
            if (!this.isIntegrationsWorkflowRetry) {
                this.refreshInstance(true);
                return false;
            }

            if (response.ErrorType == ErrorType.NoInstanceRunning) {
                this.isActiveObjectMissing = true;
            }
            else if (response.ErrorType == ErrorType.UnsupportedVersion) {
                this.isActiveObjectVersionNotSupported = true;
            }
            else if (response.ErrorType == ErrorType.ModelNotOpened) {
                this.isActiveModelMissing = true;
            }
            else if (response.ErrorType == ErrorType.NoSelectedNodes) {
                this.areActiveModelCalculationsMissing = true;
            }
            else if (response.ErrorType == ErrorType.IntegrationInternalError) {
                this.isCommunicationStateError = true;
            }

            this.submitted = false;
            this.updateUserNotifications();

            return false;
        }

        return true;
    }

    private getLoadCombinations(selectedLoads: ILoadCombination[] | []): void {
        const request = {
            DataIntegrationType: DataIntegrationType.StaadPro,
            RequestType: DataIntegrationRequestType.GetLoadCombinations,
            SelectedBaseLoadCombinationData: [] as StaadProBaseLoadCombinationData[]
        } as StaadProLoadCombinationRequest;

        // Send all selected loads to be calculated
        selectedLoads.forEach(sl => {
            request.SelectedBaseLoadCombinationData.push(sl.LoadCombination);
        });

        this.integrationsData.requestData<StaadProLoadCombinationResponse>(
            DataIntegrationType.StaadPro,
            DataIntegrationRequestType.GetLoadCombinations,
            () => {
                this.handleServiceError();
            },
            (response) => {
                const validResponse = this.validateResponse(response);
                if (!validResponse) {
                    return;
                }

                if (!response.LoadCombinationData || response.LoadCombinationData.length < 1) {
                    this.areActiveModelCalculationsMissing = true;
                    this.submitted = false;
                    this.updateUserNotifications();

                    return;
                }

                this.importLoads(response.LoadCombinationData);
            },
            request)
            .catch((err) => this.handleServiceError(err, true));
    }

    private importLoads(loadCombinations: StaadProLoadCombinationData[]): void {
        const newLoads: LoadCombination[] = [];
        for (let i = 0; i < loadCombinations.length; i++) {
            const loadCombination = loadCombinations[i];
            const loadCombinationName = this.formatNodeName(loadCombination.NodeNumber.toString(), i + 1, loadCombination.LoadComposition);

            newLoads.push({
                Name: loadCombinationName,
                Description: null as unknown as string,
                Id: this.guid.new(),
                ForceX: loadCombination.LoadCombination.ForceX,
                ForceY: loadCombination.LoadCombination.ForceY,
                ForceZ: loadCombination.LoadCombination.ForceZ,
                MomentX: loadCombination.LoadCombination.MomentX,
                MomentY: loadCombination.LoadCombination.MomentY,
                MomentZ: loadCombination.LoadCombination.MomentZ,
                DynamicForceX: null as unknown as number,
                DynamicForceY: null as unknown as number,
                DynamicForceZ: null as unknown as number,
                DynamicMomentX: null as unknown as number,
                DynamicMomentY: null as unknown as number,
                DynamicMomentZ: null as unknown as number,
                ActiveLoadType: LoadType.Static,
                Tension: null as unknown as UtilizationValueEntity,
                Shear: null as unknown as UtilizationValueEntity,
                Combination: null as unknown as UtilizationValueEntity,
                ResultMessages: [],
                IsWizardGenerated: false,
                HasSustainedLoads: false,
                LoadCharacteristic: null as unknown as number
            });
        }

        this.calculationService
            .calculateAsync(this.user.design,
                (design) => {
                    // Filter out empty load combinations and consider max number of combinations
                    design.loadCombinations = concatFilterLoadCombinations(design.loadCombinations, newLoads, environment.maxLoadCombinations, false);
                },
                {
                    suppressLoadingFlag: true,
                    importingLoadCases: true
                }
            )
            .then(() => {
                this.submitted = false;
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    private handleServiceError(response?: HttpResponseBase, logError = false): void {
        if (logError && response instanceof Error) {
            console.error(response);
        }

        this.submitted = false;

        this.modal.openAlertError(
            this.localization.getString('Agito.Hilti.Profis3.StaadProImport.ServiceError.Title'),
            this.createDownloadUrlMessage('Agito.Hilti.Profis3.StaadProImport.ServiceError.Message'),
            {
                response,
                endPointUrl: this.integrationsData.requestUrl,
                requestPayload: this.integrationsData.requestDataObject
            }
        );
    }

    private createDownloadUrlMessage(messageKey: string): string {
        const hereValue = escape(this.localization.getString('Agito.Hilti.Profis3.StaadProImport.ServiceError.Message.DownloadUrlText'));

        return `<html lang="en">${formatKeyValue(escape(this.localization.getString(messageKey)), {
            downloadUrl: `<a href="${escape(this.sharedEnvironmentData.data?.thirdPartyInterfaceDownloadUrl)}" class="download-link" target="_blank">${hereValue}</a>`
        })}</html>`;
    }

    private updateLoadCombinationsCheckboxItems(loads: ILoadCombination[]) {
        const checkboxItems: CheckboxButtonItem<ILoadCombination>[] = [];
        const checkboxSelectedValues = new Set<ILoadCombination>();

        loads.forEach((load) => {
            const item: CheckboxButtonItem<ILoadCombination> = {
                text: load.Name,
                value: load,
                disabled: !load.Name
            };

            checkboxItems.push(item);
        });

        this.loadCombinationsCheckbox.items = checkboxItems;
        this.loadCombinationsCheckbox.selectedValues = checkboxSelectedValues;
    }

    private updateUserNotifications() {
        const retVal: IUserNotification[] = [];

        for (const userNotification of this.allUserNotifications) {
            if (getProperty(this, userNotification.controlEnableFlag)) {
                retVal.push(userNotification);
            }
        }

        this.userNotifications = retVal;
    }
}
