import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    SupplementaryReinforcementDiameterEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface ISupplementaryReinforcementDiameter extends ICodeListConstructor {
    diameter: number;
}

export class SupplementaryReinforcementDiameter extends CodeList {
    public diameter!: number;

    constructor(codeList: ISupplementaryReinforcementDiameter) {
        super(codeList);

        if (codeList != null) {
            this.diameter = codeList.diameter;
        }
    }

    public static fromService(codeList: SupplementaryReinforcementDiameterEntity) {
        return new SupplementaryReinforcementDiameter(CodeList.baseFromService<ISupplementaryReinforcementDiameter>('SupplementaryReinforcementDiameterEntity', codeList, {
            diameter: codeList.Diameter
        } as ISupplementaryReinforcementDiameter));
    }

    public override cloneDeep(): SupplementaryReinforcementDiameter {
        const codeList: SupplementaryReinforcementDiameter = super.cloneDeep() as SupplementaryReinforcementDiameter;

        codeList.diameter = this.diameter;

        return codeList;
    }
}
