import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import {
    MeasureScopeCheckParameterItem as ScopeCheck
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    Dimensions, MeasureResultType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    propertyValueChanged, scopeCheckChanged
} from '@profis-engineering/gl-model/base-update';
import {
    IMetalDeckBaseMaterialModel,
    MetalDeckAnchorPosition, MetalDeckType
} from '@profis-engineering/pe-gl-model/components/metal-deck-base-material';

import { DeckThickness } from '../../../../shared/entities/code-lists/deck-thickness';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { ConcreteBaseMaterialUpdate } from './ConcreteBaseMaterialUpdate';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class MetalDeckBaseMaterialUpdate extends ConcreteBaseMaterialUpdate {

    public override shouldUpdate(_model: IModelPe, design: DesignPe) {
        return design.designType.id == DesignType.MetalDeck;
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged(UIProperty.BaseMaterial_Thickness)
    protected override BaseMaterial_Thickness(value: number, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        model.metalDeckBaseMaterial.concreteDepth = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_DeckThickness)
    protected BaseMaterial_DeckThickness(value: number, model: IModelPe, design: DesignPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        const deckThicknessCodeList = design.designData.designCodeLists[DesignCodeList.DeckThickness] as DeckThickness[];
        const deckThickness = deckThicknessCodeList.find(x => x.id == value);

        model.metalDeckBaseMaterial.deckThicknessMM = deckThickness?.deckThicknessMM;
    }

    /* SCOPE CHECK CHANGED */
    @scopeCheckChanged()
    protected override scopeChecksChanged(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        model.metalDeckBaseMaterial.invalid = model.metalDeckBaseMaterial.invalid ?? {};

        if (scopeChecks != null) {
            model.metalDeckBaseMaterial.invalid.anchorOffsetText = scopeChecks[MeasureResultType.AnchorOffset] != null;
            model.metalDeckBaseMaterial.invalid.concreteDepthText = scopeChecks[MeasureResultType.BaseMaterialThickness] != null;
            model.metalDeckBaseMaterial.invalid.fluteBaseText = scopeChecks[MeasureResultType.FluteBase] != null;
            model.metalDeckBaseMaterial.invalid.fluteDepthText = scopeChecks[MeasureResultType.FluteDepth] != null;
            model.metalDeckBaseMaterial.invalid.fluteDistanceText = scopeChecks[MeasureResultType.FluteDistance] != null;
            model.metalDeckBaseMaterial.invalid.flutePeakText = scopeChecks[MeasureResultType.FlutePeak] != null;
            model.metalDeckBaseMaterial.invalid.lowerFluteText = scopeChecks[MeasureResultType.LowerFlute] != null;
            model.metalDeckBaseMaterial.invalid.totalDepthText = scopeChecks[MeasureResultType.BaseMaterialThickness] != null;
            model.metalDeckBaseMaterial.invalid.upperFluteText = scopeChecks[MeasureResultType.UpperFlute] != null;
            model.metalDeckBaseMaterial.invalid.concreteWidthInfinityText = scopeChecks[MeasureResultType.AnchorSpacingLengthA] != null ||
                scopeChecks[MeasureResultType.AnchorSpacingLengthB] != null;

            const anchorToBaseMaterialEdgeScopeCheck = scopeChecks[MeasureResultType.AnchorToBaseMaterialEdge];
            let xPositiveInvalid = false;
            let xNegativeInvalid = false;
            let yPositiveInvalid = false;
            let yNegativeInvalid = false;

            if (anchorToBaseMaterialEdgeScopeCheck != null) {
                const dimensions = anchorToBaseMaterialEdgeScopeCheck.Value as Dimensions;

                xPositiveInvalid = (dimensions & Dimensions.XPositive) != 0;
                xNegativeInvalid = (dimensions & Dimensions.XNegative) != 0;
                yPositiveInvalid = (dimensions & Dimensions.YPositive) != 0;
                yNegativeInvalid = (dimensions & Dimensions.YNegative) != 0;
            }

            model.metalDeckBaseMaterial.invalid.widthPositiveText = xPositiveInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeXPositive] != null;
            model.metalDeckBaseMaterial.invalid.widthNegativeText = xNegativeInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeXNegative] != null;
            model.metalDeckBaseMaterial.invalid.depthPositiveText = yPositiveInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeYPositive] != null;
            model.metalDeckBaseMaterial.invalid.depthNegativeText = yNegativeInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeYNegative] != null;
        }
        else {
            const invalidMetalDeckBaseMaterial = model.metalDeckBaseMaterial.invalid;
            for (const textId in invalidMetalDeckBaseMaterial) {
                invalidMetalDeckBaseMaterial[textId as keyof typeof invalidMetalDeckBaseMaterial] = false;
            }
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MetalDeckFluteDepth)
    private BaseMaterial_MetalDeckFluteDepth(value: number, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        model.metalDeckBaseMaterial.fluteDepth = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MetalDeckFluteDistance)
    private BaseMaterial_MetalDeckFluteDistance(value: number, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        model.metalDeckBaseMaterial.fluteDistance = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MetalDeckLowerFluteWidth)
    private BaseMaterial_MetalDeckLowerFluteWidth(value: number, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        model.metalDeckBaseMaterial.lowerFluteWidth = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MetalDeckUpperFluteWidth)
    private BaseMaterial_MetalDeckUpperFluteWidth(value: number, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        model.metalDeckBaseMaterial.upperFluteWidth = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MetalDeckType)
    private BaseMaterial_MetalDeckType(value: MetalDeckType, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        model.metalDeckBaseMaterial.type = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MetalDeckAnchorPosition)
    private BaseMaterial_MetalDeckAnchorPosition(value: MetalDeckAnchorPosition, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        if (value != null && model.metalDeckBaseMaterial.anchorPosition != null && model.metalDeckBaseMaterial.anchorPosition != value) {

            const fluteDistance = model.metalDeckBaseMaterial.fluteDistance ?? 0;
            const upperFluteWidth = model.metalDeckBaseMaterial.upperFluteWidth ?? 0;
            const lowerFluteWidth = model.metalDeckBaseMaterial.lowerFluteWidth ?? 0;
            const anchorOffset = model.metalDeckBaseMaterial.anchorOffset ?? 0;
            const fluteDepth = model.metalDeckBaseMaterial.fluteDepth ?? 0;
            const concreteDepth = model.metalDeckBaseMaterial.concreteDepth ?? 0;
            // Intermediate calculations
            const fluteBaseWidth = fluteDistance - upperFluteWidth;
            const lengthBetweenLowerUpperFlute = (fluteBaseWidth / 2) - (lowerFluteWidth / 2);
            const percentageOfPossibleOffset = lengthBetweenLowerUpperFlute != 0 ? anchorOffset / lengthBetweenLowerUpperFlute : 0;

            const height = concreteDepth + fluteDepth;
            const heightBetweenLowerUpperFlute = fluteDepth * (1 - percentageOfPossibleOffset);

            // Offsets used in 3d position update
            const heightInclinedPosition = fluteDepth * percentageOfPossibleOffset;
            const offsetX = (fluteDistance - upperFluteWidth - lowerFluteWidth) / 2 + anchorOffset * 2;
            const offsetY = fluteDepth;
            const inclinedOffsetY = heightBetweenLowerUpperFlute;
            const inclinedOffsetX = lengthBetweenLowerUpperFlute;

            switch (model.metalDeckBaseMaterial.anchorPosition) {
                case MetalDeckAnchorPosition.SoffitUpperFlute:
                    this.setCamera3dPositionSoffitUpperFlute(value, offsetX, offsetY, inclinedOffsetX, inclinedOffsetY, concreteDepth);
                    break;

                case MetalDeckAnchorPosition.SoffitLowerFlute:
                    this.setCamera3dPositionSoffitLowerFlute(value, offsetX, offsetY, anchorOffset, heightInclinedPosition, height);
                    break;

                case MetalDeckAnchorPosition.SoffitInclinedFlute:
                    this.setCamera3dPositionSoffitIncludeFlute(value, inclinedOffsetX, inclinedOffsetY, anchorOffset, heightInclinedPosition);
                    break;

                case MetalDeckAnchorPosition.Top:
                    this.setCamera3dPositionTop(value, offsetX, concreteDepth, height, anchorOffset, inclinedOffsetY);
                    break;

                default:
                    throw new Error('Unknown MetalDeck anchor position');
            }
        }
        model.metalDeckBaseMaterial.anchorPosition = value;
    }

    private setCamera3dPositionTop(value: MetalDeckAnchorPosition, offsetX: number, concreteDepth: number, height: number, anchorOffset: number, inclinedOffsetY: number) {
        switch (value) {
            case MetalDeckAnchorPosition.SoffitUpperFlute:
                this.camera3dPositionUpdate(new Vector3(-offsetX, -concreteDepth, 0), false);
                break;
            case MetalDeckAnchorPosition.SoffitLowerFlute:
                this.camera3dPositionUpdate(new Vector3(0, -height, 0), false);
                break;
            case MetalDeckAnchorPosition.SoffitInclinedFlute:
                this.camera3dPositionUpdate(new Vector3(-anchorOffset * 2, -inclinedOffsetY, 0), false);
                break;
            default:
                throw new Error('Unknown MetalDeck anchor position');
        }
    }

    private setCamera3dPositionSoffitIncludeFlute(value: MetalDeckAnchorPosition, inclinedOffsetX: number, inclinedOffsetY: number, anchorOffset: number, heightInclinedPosition: number) {
        switch (value) {
            case MetalDeckAnchorPosition.SoffitUpperFlute:
                this.camera3dPositionUpdate(new Vector3(-inclinedOffsetX, inclinedOffsetY, 0), false);
                break;
            case MetalDeckAnchorPosition.SoffitLowerFlute:
                this.camera3dPositionUpdate(new Vector3(anchorOffset * 2, -heightInclinedPosition, 0), false);
                break;
            case MetalDeckAnchorPosition.Top:
                this.camera3dPositionUpdate(new Vector3(anchorOffset * 2, inclinedOffsetY, 0), false);
                break;
            default:
                throw new Error('Unknown MetalDeck anchor position');
        }
    }

    private setCamera3dPositionSoffitLowerFlute(value: MetalDeckAnchorPosition, offsetX: number, offsetY: number, anchorOffset: number, heightInclinedPosition: number, height: number) {
        switch (value) {
            case MetalDeckAnchorPosition.SoffitUpperFlute:
                this.camera3dPositionUpdate(new Vector3(-offsetX, offsetY, 0), false);
                break;
            case MetalDeckAnchorPosition.SoffitInclinedFlute:
                this.camera3dPositionUpdate(new Vector3(-anchorOffset * 2, heightInclinedPosition, 0), false);
                break;
            case MetalDeckAnchorPosition.Top:
                this.camera3dPositionUpdate(new Vector3(0, height, 0), false);
                break;
            default:
                throw new Error('Unknown MetalDeck anchor position');
        }
    }

    private setCamera3dPositionSoffitUpperFlute(value: MetalDeckAnchorPosition, offsetX: number, offsetY: number, inclinedOffsetX: number, inclinedOffsetY: number, concreteDepth: number) {
        switch (value) {
            case MetalDeckAnchorPosition.SoffitLowerFlute:
                this.camera3dPositionUpdate(new Vector3(offsetX, -offsetY, 0), false);
                break;
            case MetalDeckAnchorPosition.SoffitInclinedFlute:
                this.camera3dPositionUpdate(new Vector3(inclinedOffsetX, -inclinedOffsetY, 0), false);
                break;
            case MetalDeckAnchorPosition.Top:
                this.camera3dPositionUpdate(new Vector3(offsetX, concreteDepth, 0), false);
                break;
            default:
                throw new Error('Unknown MetalDeck anchor position');
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MetalDeckAnchorOffset)
    private BaseMaterial_MetalDeckAnchorOffset(value: number, model: IModelPe) {
        if (!model.metalDeckBaseMaterial) {
            throw new Error('model metalDeckBaseMaterial is undefined');
        }

        if (value != null && model.metalDeckBaseMaterial.anchorOffset != null && model.metalDeckBaseMaterial.anchorOffset != value) {
            let offsetX = 0;
            let offsetY = 0;

            switch (model.metalDeckBaseMaterial.anchorPosition) {
                case MetalDeckAnchorPosition.SoffitUpperFlute:
                    offsetX = model.metalDeckBaseMaterial.anchorOffset - value;
                    break;

                case MetalDeckAnchorPosition.SoffitLowerFlute:
                    offsetX = value - model.metalDeckBaseMaterial.anchorOffset;
                    break;

                case MetalDeckAnchorPosition.SoffitInclinedFlute:
                    offsetX = model.metalDeckBaseMaterial.anchorOffset - value;
                    offsetY = this.soffitInclinedFluteOffsetY(value, model.metalDeckBaseMaterial);
                    break;

                case MetalDeckAnchorPosition.Top:
                    break;

                default:
                    throw new Error('Unknown MetalDeck anchor position');
            }

            this.camera3dPositionUpdate(new Vector3(offsetX, offsetY, 0), false);
        }
        model.metalDeckBaseMaterial.anchorOffset = value;
    }

    private soffitInclinedFluteOffsetY(value: number, metalDeckBaseMaterial: IMetalDeckBaseMaterialModel): number {
        const anchorOffset = value - (metalDeckBaseMaterial.anchorOffset ?? 0);
        const fluteBaseWidth = (metalDeckBaseMaterial.fluteDistance ?? 0) - (metalDeckBaseMaterial.upperFluteWidth ?? 0);
        const lengthBetweenLowerUpperFlute = (fluteBaseWidth / 2) - ((metalDeckBaseMaterial.lowerFluteWidth ?? 0) / 2);
        const percentageOfPossibleOffset = lengthBetweenLowerUpperFlute != 0 ? anchorOffset / lengthBetweenLowerUpperFlute : 0;
        return (metalDeckBaseMaterial.fluteDepth ?? 0) * percentageOfPossibleOffset;

    }
}
