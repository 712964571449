import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AsadOptimizeScopeCheckDetailsModalInput } from '../../../shared/entities/asad-optimize-modal';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitService } from '../../services/unit.service';

@Component({
    templateUrl: './asad-optimize-scope-check-details-modal.component.html',
    styleUrls: ['./asad-optimize-scope-check-details-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AsadOptimizeScopeCheckDetailsModalComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<AsadOptimizeScopeCheckDetailsModalInput>;

    public lengthUnitString!: string;

    constructor(
        private unitService: UnitService
    ) { }

    public get scopeCheckError() {
        return this.modalInstance.input?.scopeCheckError;
    }

    public ngOnInit(): void {
        this.lengthUnitString = this.unitService.getUnitStrings(Unit.mm)[0];
    }

    public close() {
        this.modalInstance.close();
    }

    public formatLength(value: number) {
        return this.unitService.formatInternalValueAsDefault(value, UnitGroup.Length);
    }

    public formatLengthWithoutUnit(value: number) {
        return this.formatWithoutUnit(value, UnitGroup.Length);
    }

    public formatWithoutUnit(value: number, unitGroup: UnitGroup) {
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit), Unit.None);
    }
}
