<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.AnchorDesignHelp.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="anchor-design-help">
        <div class="anchor-design-help-utilizations">
            <div *ngFor="let utilization of utilizationDetails" class="utilization-container">
                <div class="utilization-header" (click)="toggleUtilizationExpanded(utilization)">
                    <span class="collapse" [ngClass]="getExpandedImage(utilization)"></span>
                    <p class="text" [innerHtml]="translate(utilization.text)"></p>
                    <span class="status pe-ui-pe-sprite {{getUtilizationCategoryWarningImage(utilization)}}"></span>
                </div>

                <div *ngFor="let utilizationSubCategory of utilization.subcategories"
                    [ngClass]="[getSelectedClass(utilizationSubCategory), utilization.expanded ? 'utilization-subcategory' : 'collapsed']"
                    (click)="setSelectedUtilizationSubCategory(utilizationSubCategory)">
                    <span class="subcategory pe-ui-pe-sprite pe-ui-pe-sprite-{{utilizationSubCategory.image}}-small"></span>
                    <p class="text" [innerHtml]="translate(utilizationSubCategory.text)"></p>
                    <span class="status pe-ui-pe-sprite {{getWarningImage(utilizationSubCategory.value)}}"></span>
                </div>
            </div>
        </div>

        <div class="anchor-design-help-description">
            <div class="anchor-design-help-image">
                <img src="{{getDescriptionImage()}}" alt="anchor-design-help-image">
            </div>
            <div class="anchor-design-help-text">
                <span [innerHtml]="getDescriptionText()"></span>
            </div>
        </div>
    </div>
</pe-modal-body>
