
export abstract class IntegrationsNotificationServiceBase {
    public abstract get connected(): boolean;
    public abstract connect(): Promise<boolean>;
}

export class IntegrationsNotificationServiceInjected extends IntegrationsNotificationServiceBase {
    protected baseService!: IntegrationsNotificationServiceBase;

    public setBaseService(baseService: IntegrationsNotificationServiceBase) {
        this.baseService = baseService;

        this.connect = baseService.connect.bind(baseService);
    }

    // IntegrationsNotificationService methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public get connected() {
        return this.baseService.connected;
    }

    public connect(): Promise<boolean> {
        return {} as Promise<boolean>;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
