import { Injectable } from '@angular/core';
import { environment } from '../../environments/environmentPe';
import { AdvancedCalculationType } from '../../shared/entities/code-lists/advanced-calculation-type';
import { DesignStandard } from '../../shared/entities/code-lists/design-standard';
import { Region } from '../../shared/entities/code-lists/region';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    AdvancedCalculationType as AdvancedCalculationTypeEnum, DesignType
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeListService } from './code-list.service';
import { FeaturesVisibilityInfoService } from './features-visibility-info.service';
import { OfflineService } from './offline.service';
import { SharedEnvironmentService } from './shared-environment.service';

@Injectable({
  providedIn: 'root'
})
export class AbpService {
    constructor(
        private codeListsService: CodeListService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private offlineService: OfflineService,
        private sharedEnvironmentService: SharedEnvironmentService
    ) { }

    public isAdvancedCalculationPossibleById(designType: DesignType, designStandardId: number, regionId: number) {
        return this.isAdvancedCalculationPossible(
            designType,
            this.getDesignStandard(designStandardId),
            this.getRegion(regionId)
        );
    }

    public isAdvancedCalculationPossible(designType: DesignType, designStandard: DesignStandard | undefined, region: Region | undefined) {
        if (designStandard == null || region == null) {
            return false;
        }

        const isAdvancedCalculationAvailable = this.isAdvancedCalculationAvailable(designType, designStandard, region);
        const hasLicense = this.featuresVisibilityInfoService.hasAdvancedFeature;

        return isAdvancedCalculationAvailable && (hasLicense || this.canUseBPRigidityCheck(designStandard, region));
    }

    public isRigidityCheckPossibleById(designStandardId: number, regionId: number) {
        return this.canUseBPRigidityCheck(
            this.getDesignStandard(designStandardId),
            this.getRegion(regionId)
        );
    }

    public canUseBPRigidityCheck(designStandard: DesignStandard | undefined, region: Region | undefined) {
        if (designStandard == null || region == null) {
            return false;
        }

        const hasLicense = this.featuresVisibilityInfoService.hasAdvancedFeature;
        const advancedCalculationTypes = this.codeListsService.projectCodeLists[ProjectCodeList.AdvancedCalculationType] as AdvancedCalculationType[];
        const bpRigidityCheckType = advancedCalculationTypes.find(x => x.id == AdvancedCalculationTypeEnum.BPRigidityCheck);

        return !hasLicense
            && (bpRigidityCheckType?.designStandardRegions.some(x => x.DesignStandard == designStandard.id && x.RegionId == region.id) ?? false);
    }

    public isAdvancedCalculationAvailable(designType: DesignType, designStandard: DesignStandard | undefined, region: Region | undefined) {
        if (designStandard == null || region == null) {
            return false;
        }

        // Not allowed for offline
        if (this.offlineService.isOffline) {
            return false;
        }

        // Allowed only for concrete and metal deck
        if (designType != DesignType.Concrete && designType != DesignType.MetalDeck) {
            return false;
        }

        let allowForRegion = false;
        let allowForDesignStandard = false;

        switch (designType) {
            case DesignType.Concrete:
                // Does not depend on region and design standard if allowedForAllRegions flag is true.
                allowForRegion = this.advancedBaseplateAvailableForRegion(region.commonRegion.id) || (this.sharedEnvironmentService.data?.useDevFeatures ?? false) || region.allowAdvancedBaseplate;
                allowForDesignStandard = designStandard.allowAdvancedBaseplate ?? false;
                break;
            case DesignType.MetalDeck:
                allowForRegion = this.advancedBaseplateAvailableForRegion(region.commonRegion.id) || (this.sharedEnvironmentService.data?.useDevFeatures ?? false) || region.allowAdvancedBaseplateMetalDeck;
                allowForDesignStandard = designStandard.allowAdvancedBaseplateMetalDeck ?? false;
                break;
            default:
                throw new Error('Unsupported design type.');
        }

        // Advanced calculation must be allowed for selected region and design standard.
        return allowForRegion && allowForDesignStandard;
    }

    public isHandrailAdvancedCalculationPossible(designType: DesignType, designStandard: DesignStandard | undefined, region: Region | undefined) {
        return this.featuresVisibilityInfoService.hasAdvancedFeature && this.isHandrailAdvancedCalculationAvailable(designType, designStandard, region);
    }

    public isHandrailAdvancedCalculationAvailable(designType: DesignType, designStandard: DesignStandard | undefined, region: Region | undefined) {
        if (designType != DesignType.Handrail) {
            return false;
        }

        if (designStandard == null || region == null) {
            return false;
        }

        // Advanced calculation must be allowed for selected region and design standard.
        return region.allowAdvancedBaseplateHandrail
            && (designStandard.allowAdvancedBaseplateHandrail ?? false);
    }

    private getDesignStandard(designStandardId: number) {
        return (this.codeListsService.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[])
            .find(x => x.id == designStandardId);
    }

    private getRegion(regionId: number) {
        return (this.codeListsService.projectCodeLists[ProjectCodeList.Region] as Region[])
            .find(x => x.id == regionId);
    }

    private advancedBaseplateAvailableForRegion(regionId: number) {
        if (environment.advancedBaseplateAvailableForRegion.toLowerCase() == 'all') {
            return true;
        }

        if (environment.advancedBaseplateAvailableForRegion.toLowerCase() == 'none') {
            return false;
        }

        return (JSON.parse(environment.advancedBaseplateAvailableForRegion) as number[]).includes(regionId);
    }
}
