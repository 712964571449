import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    RegionEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    ConcreteInCompressionMethod
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from './code-list';
import { MeasureAnchorPlateMode, MeasureBaseMaterialEdgeFromMode } from '../../generated-modules/Hilti.PE.Core.Common.Enums';

export interface IRegionConstructor extends ICodeListConstructor {
    commonRegion: CommonRegion;
    defaultConcreteDesignStandardId?: number;
    defaultMasonryDesignStandardId?: number;
    defaultHandrailDesignStandardId?: number;
    defaultMetalDeckDesignStandardId?: number;
    noHiltiTechnicalDataBrick?: boolean;
    defaultAlphaCC?: number;
    buildingCategoryUrl: string;
    windZoneImage: string;
    anchorPlateFactor: number;
    safetyFactorForPermamentLoads: number;
    safetyFactorForVariableLoads: number;
    thickness: number;
    defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard: number;
    defaultDesignMethodGroupForEurocodeDesignStandard: number;
    defaultDesignMethodGroupForISDesignStandard: number;
    defaultDesignMethodGroupForMSDesignStandard: number;
    defaultDesignMethodGroupForNZDesignStandard: number;
    ACIDesignMethodGroup: number;
    isStressCheckAllowed: boolean;
    isWeldConfigAllowed: boolean;
    isDisplacementLimitAllowed: boolean;
    defaultHandrailDisplacement: number;
    allowAdvancedBaseplate: boolean;
    defaultMaterialSafetyFactor?: number;
    isBridgesDisplacementLimitAllowed: boolean;
    defaultConcreteInCompressionMethod?: ConcreteInCompressionMethod;
    defaultEurocodeDesignStandardSteelGuideLine: number;
    allowAdvancedBaseplateMetalDeck: boolean;
    includeParallelDesignInReport: boolean;
    defaultSteelGuideline: number;
    isSmartAnchorAvailable: boolean;
    allowAdvancedBaseplateHandrail: boolean;
    isDG1ExtensionAllowed: boolean;
    defaultDesignMethodGroupForRussianDesignStandard: number;
    defaultMethodGroupForAustralianDesignStandard: number;
    defaultMethodGroupForHongKongDesignStandard: number;
    defaultDesignMethodGroupForCsaDesignStandard: number;
    defaultMeasureAnchorPlate: MeasureAnchorPlateMode;
    defaultMeasureBaseMaterialEdgeFrom: MeasureBaseMaterialEdgeFromMode;
    fireLearnMoreUrl?: string;
}

export class Region extends CodeList {
    public commonRegion!: CommonRegion;
    public defaultConcreteDesignStandardId?: number;
    public defaultMasonryDesignStandardId?: number;
    public defaultHandrailDesignStandardId?: number;
    public defaultMetalDeckDesignStandardId?: number;
    public noHiltiTechnicalDataBrick?: boolean;
    public defaultAlphaCC?: number;
    public buildingCategoryUrl!: string;
    public windZoneImage!: string;
    public anchorPlateFactor!: number;
    public safetyFactorForPermamentLoads!: number;
    public safetyFactorForVariableLoads!: number;
    public thickness!: number;
    public defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard!: number;
    public defaultDesignMethodGroupForEurocodeDesignStandard!: number;
    public defaultDesignMethodGroupForISDesignStandard!: number;
    public defaultDesignMethodGroupForMSDesignStandard!: number;
    public defaultDesignMethodGroupForNZDesignStandard!: number;
    public ACIDesignMethodGroup!: number;
    public isStressCheckAllowed!: boolean;
    public isWeldConfigAllowed!: boolean;
    public isDisplacementLimitAllowed!: boolean;
    public defaultHandrailDisplacement!: number;
    public allowAdvancedBaseplate!: boolean;
    public defaultMaterialSafetyFactor?: number;
    public isBridgesDisplacementLimitAllowed!: boolean;
    public defaultConcreteInCompressionMethod?: ConcreteInCompressionMethod;
    public defaultEurocodeDesignStandardSteelGuideLine?: number;
    public allowAdvancedBaseplateMetalDeck!: boolean;
    public defaultSteelGuideline!: number;
    public includeParallelDesignInReport!: boolean;
    public isSmartAnchorAvailable!: boolean;
    public allowAdvancedBaseplateHandrail!: boolean;
    public isDG1ExtensionAllowed!: boolean;
    public defaultDesignMethodGroupForRussianDesignStandard!: number;
    public defaultMethodGroupForAustralianDesignStandard!: number;
    public defaultMethodGroupForHongKongDesignStandard!: number;
    public defaultDesignMethodGroupForCsaDesignStandard!: number;
    public defaultMeasureAnchorPlate !: MeasureAnchorPlateMode;
    public defaultMeasureBaseMaterialEdgeFrom !: MeasureBaseMaterialEdgeFromMode;
    public fireLearnMoreUrl?: string;

    public get profisAnchorGuid() {
        return this.commonRegion.profisAnchorGuid;
    }
    public get countryCode() {
        return this.commonRegion.countryCode;
    }
    public get onlineTechnicalLibraryUrl() {
        return this.commonRegion.onlineTechnicalLibraryUrl;
    }
    public get hiltiBimCadLibraryUrl() {
        return this.commonRegion.hiltiBimCadLibraryUrl;
    }
    public get profis3KBUrl() {
        return this.commonRegion.profis3KBUrl;
    }
    public get profis3KBUrlAci() {
        return this.commonRegion.profis3KBUrlAci;
    }
    public get hiltiOnlineUrl() {
        return this.commonRegion.hiltiOnlineUrl;
    }
    public get contactUrl() {
        return this.commonRegion.contactUrl;
    }
    public get supportPhone() {
        return this.commonRegion.supportPhone;
    }
    public get supportHours() {
        return this.commonRegion.supportHours;
    }
    public get worldAreaId() {
        return this.commonRegion.worldAreaId;
    }
    public get onlineTenderTextsUrl() {
        return this.commonRegion.onlineTenderTextsUrl;
    }
    public get hubId() {
        return this.commonRegion.hubId;
    }
    public get culture() {
        return this.commonRegion.culture;
    }
    public get dateTimeFormat() {
        return this.commonRegion.dateTimeFormat;
    }
    public get zipCodeFormat() {
        return this.commonRegion.zipCodeFormat;
    }
    public get hiltiDataConsentUrl() {
        return this.commonRegion.hiltiDataConsentUrl;
    }
    public get bpRigidityCheckUrl() {
        return this.commonRegion.bpRigidityCheckUrl;
    }
    public get dlubalEnabled() {
        return this.commonRegion.dlubalEnabled;
    }
    public get defaultUnitLength() {
        return this.commonRegion.defaultUnitLength;
    }
    public get defaultUnitLengthLarge() {
        return this.commonRegion.defaultUnitLengthLarge;
    }
    public get defaultUnitArea() {
        return this.commonRegion.defaultUnitArea;
    }
    public get defaultUnitStress() {
        return this.commonRegion.defaultUnitStress;
    }
    public get defaultUnitStressSmall() {
        return this.commonRegion.defaultUnitStressSmall;
    }
    public get defaultUnitForce() {
        return this.commonRegion.defaultUnitForce;
    }
    public get defaultUnitMoment() {
        return this.commonRegion.defaultUnitMoment;
    }
    public get defaultUnitTemperature() {
        return this.commonRegion.defaultUnitTemperature;
    }
    public get defaultUnitForcePerLength() {
        return this.commonRegion.defaultUnitForcePerLength;
    }
    public get defaultUnitMomentPerLength() {
        return this.commonRegion.defaultUnitMomentPerLength;
    }
    public get defaultUnitDensity() {
        return this.commonRegion.defaultUnitDensity;
    }
    public get defaultUnitAreaPerLength() {
        return this.commonRegion.defaultUnitAreaPerLength;
    }

    constructor(codeList: IRegionConstructor) {
        super(codeList);

        if (codeList != null) {
            this.commonRegion = codeList.commonRegion;
            this.defaultConcreteDesignStandardId = codeList.defaultConcreteDesignStandardId;
            this.defaultMasonryDesignStandardId = codeList.defaultMasonryDesignStandardId;
            this.defaultHandrailDesignStandardId = codeList.defaultHandrailDesignStandardId;
            this.defaultMetalDeckDesignStandardId = codeList.defaultMetalDeckDesignStandardId;
            this.noHiltiTechnicalDataBrick = codeList.noHiltiTechnicalDataBrick;
            this.defaultAlphaCC = codeList.defaultAlphaCC;
            this.buildingCategoryUrl = codeList.buildingCategoryUrl;
            this.windZoneImage = codeList.windZoneImage;
            this.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard = codeList.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard;
            this.defaultDesignMethodGroupForEurocodeDesignStandard = codeList.defaultDesignMethodGroupForEurocodeDesignStandard;
            this.defaultDesignMethodGroupForISDesignStandard = codeList.defaultDesignMethodGroupForISDesignStandard;
            this.defaultDesignMethodGroupForMSDesignStandard = codeList.defaultDesignMethodGroupForMSDesignStandard;
            this.defaultDesignMethodGroupForNZDesignStandard = codeList.defaultDesignMethodGroupForNZDesignStandard;
            this.ACIDesignMethodGroup = codeList.ACIDesignMethodGroup;
            this.anchorPlateFactor = codeList.anchorPlateFactor;
            this.safetyFactorForPermamentLoads = codeList.safetyFactorForPermamentLoads;
            this.safetyFactorForVariableLoads = codeList.safetyFactorForVariableLoads;
            this.thickness = codeList.thickness;
            this.isStressCheckAllowed = codeList.isStressCheckAllowed;
            this.isWeldConfigAllowed = codeList.isWeldConfigAllowed;
            this.isDisplacementLimitAllowed = codeList.isDisplacementLimitAllowed;
            this.defaultHandrailDisplacement = codeList.defaultHandrailDisplacement;
            this.allowAdvancedBaseplate = codeList.allowAdvancedBaseplate;
            this.defaultMaterialSafetyFactor = codeList.defaultMaterialSafetyFactor;
            this.isBridgesDisplacementLimitAllowed = codeList.isBridgesDisplacementLimitAllowed;
            this.defaultConcreteInCompressionMethod = codeList.defaultConcreteInCompressionMethod;
            this.defaultEurocodeDesignStandardSteelGuideLine = codeList.defaultEurocodeDesignStandardSteelGuideLine;
            this.allowAdvancedBaseplateMetalDeck = codeList.allowAdvancedBaseplateMetalDeck;
            this.includeParallelDesignInReport = codeList.includeParallelDesignInReport;
            this.defaultSteelGuideline = codeList.defaultSteelGuideline;
            this.isSmartAnchorAvailable = codeList.isSmartAnchorAvailable;
            this.allowAdvancedBaseplateHandrail = codeList.allowAdvancedBaseplateHandrail;
            this.isDG1ExtensionAllowed = codeList.isDG1ExtensionAllowed;
            this.defaultDesignMethodGroupForRussianDesignStandard = codeList.defaultDesignMethodGroupForRussianDesignStandard;
            this.defaultMethodGroupForAustralianDesignStandard = codeList.defaultMethodGroupForAustralianDesignStandard;
            this.defaultMethodGroupForHongKongDesignStandard = codeList.defaultMethodGroupForHongKongDesignStandard;
            this.defaultDesignMethodGroupForCsaDesignStandard = codeList.defaultDesignMethodGroupForCsaDesignStandard;
            this.defaultMeasureAnchorPlate = codeList.defaultMeasureAnchorPlate;
            this.defaultMeasureBaseMaterialEdgeFrom = codeList.defaultMeasureBaseMaterialEdgeFrom;
            this.fireLearnMoreUrl = codeList.fireLearnMoreUrl;
        }
    }

    public static fromService(codeList: RegionEntity, commonRegion: CommonRegion) {
        return new Region(CodeList.baseFromService<IRegionConstructor>('RegionEntity', codeList, {
            commonRegion: commonRegion,
            defaultConcreteDesignStandardId: codeList.DefaultConcreteDesignStandard != null ? codeList.DefaultConcreteDesignStandard.Id : undefined,
            defaultMasonryDesignStandardId: codeList.DefaultMasonryDesignStandard != null ? codeList.DefaultMasonryDesignStandard.Id : undefined,
            defaultHandrailDesignStandardId: codeList.DefaultHandrailDesignStandard != null ? codeList.DefaultHandrailDesignStandard.Id : undefined,
            defaultMetalDeckDesignStandardId: codeList.DefaultMetalDeckDesignStandard != null ? codeList.DefaultMetalDeckDesignStandard.Id : undefined,
            noHiltiTechnicalDataBrick: codeList.NoHiltiTechnicalDataBrick,
            buildingCategoryUrl: codeList.BuildingCategoryUrl,
            windZoneImage: codeList.WindZoneImage,
            defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard: codeList.DefaultDesignMethodGroupForConcreteHandrailEtagDesignStandard,
            defaultDesignMethodGroupForEurocodeDesignStandard: codeList.DefaultDesignMethodGroupForEurocodeDesignStandard,
            defaultDesignMethodGroupForISDesignStandard: codeList.DefaultDesignMethodGroupForISDesignStandard,
            defaultDesignMethodGroupForMSDesignStandard: codeList.DefaultDesignMethodGroupForMSDesignStandard,
            defaultDesignMethodGroupForNZDesignStandard: codeList.DefaultDesignMethodGroupForNZDesignStandard,
            ACIDesignMethodGroup: codeList.ACIDesignMethodGroup,
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-' + codeList.Image : undefined,
            anchorPlateFactor: codeList.DefaultAnchorPlateFactor,
            safetyFactorForPermamentLoads: codeList.DefaultSafetyFactorForPermamentLoads,
            safetyFactorForVariableLoads: codeList.DefaultSafetyFactorForVariableLoads,
            thickness: codeList.DefaultAnchorThickness,
            isStressCheckAllowed: codeList.IsStressCheckAllowed,
            isWeldConfigAllowed: codeList.IsWeldConfigAllowed,
            isDisplacementLimitAllowed: codeList.IsDisplacementLimitAllowed,
            defaultHandrailDisplacement: codeList.DefaultHandrailDisplacement,
            defaultAlphaCC: codeList.DefaultAlphaCC,
            allowAdvancedBaseplate: codeList.AllowAdvancedBaseplate,
            defaultMaterialSafetyFactor: codeList.DefaultMaterialSafetyFactor,
            isBridgesDisplacementLimitAllowed: codeList.IsBridgesDisplacementLimitAllowed,
            defaultConcreteInCompressionMethod: codeList.DefaultConcreteInCompressionMethod,
            defaultEurocodeDesignStandardSteelGuideLine: codeList.DefaultEurocodeDesignStandardSteelGuideLine,
            allowAdvancedBaseplateMetalDeck: codeList.AllowAdvancedBaseplateMetalDeck,
            includeParallelDesignInReport: codeList.IncludeParallelDesignInReport,
            defaultSteelGuideline: codeList.DefaultSteelGuideline,
            isSmartAnchorAvailable: codeList.SmartAnchorAvailable,
            allowAdvancedBaseplateHandrail: codeList.AllowAdvancedBaseplateHandrail,
            isDG1ExtensionAllowed: codeList.IsDG1ExtensionAllowed,
            defaultDesignMethodGroupForRussianDesignStandard: codeList.DefaultDesignMethodGroupForRussianDesignStandard,
            defaultMethodGroupForAustralianDesignStandard: codeList.DefaultDesignMethodGroupForAustralianDesignStandard,
            defaultMethodGroupForHongKongDesignStandard: codeList.DefaultDesignMethodGroupForHongKongDesignStandard,
            defaultDesignMethodGroupForCsaDesignStandard: codeList.DefaultDesignMethodGroupForCsaDesignStandard,
            defaultMeasureAnchorPlate: codeList.DefaultMeasureAnchorPlate,
            defaultMeasureBaseMaterialEdgeFrom: codeList.DefaultMeasureBaseMaterialEdgeFrom,
            fireLearnMoreUrl: codeList.FireLearnMoreUrl,
        } as IRegionConstructor));
    }

    public override cloneDeep() {
        const codeList: Region = super.cloneDeep() as Region;

        codeList.defaultConcreteDesignStandardId = this.defaultConcreteDesignStandardId;
        codeList.defaultMasonryDesignStandardId = this.defaultMasonryDesignStandardId;
        codeList.defaultHandrailDesignStandardId = this.defaultHandrailDesignStandardId;
        codeList.defaultMetalDeckDesignStandardId = this.defaultMetalDeckDesignStandardId;
        codeList.noHiltiTechnicalDataBrick = this.noHiltiTechnicalDataBrick;
        codeList.defaultAlphaCC = this.defaultAlphaCC;
        codeList.buildingCategoryUrl = this.buildingCategoryUrl;
        codeList.windZoneImage = this.windZoneImage;
        codeList.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard = this.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard;
        codeList.defaultDesignMethodGroupForEurocodeDesignStandard = this.defaultDesignMethodGroupForEurocodeDesignStandard;
        codeList.defaultDesignMethodGroupForISDesignStandard = this.defaultDesignMethodGroupForISDesignStandard;
        codeList.defaultDesignMethodGroupForMSDesignStandard = this.defaultDesignMethodGroupForMSDesignStandard;
        codeList.ACIDesignMethodGroup = this.ACIDesignMethodGroup;
        codeList.anchorPlateFactor = this.anchorPlateFactor;
        codeList.safetyFactorForPermamentLoads = this.safetyFactorForPermamentLoads;
        codeList.safetyFactorForVariableLoads = this.safetyFactorForVariableLoads;
        codeList.thickness = this.thickness;
        codeList.isStressCheckAllowed = this.isStressCheckAllowed;
        codeList.isWeldConfigAllowed = this.isWeldConfigAllowed;
        codeList.isDisplacementLimitAllowed = this.isDisplacementLimitAllowed;
        codeList.defaultHandrailDisplacement = this.defaultHandrailDisplacement;
        codeList.allowAdvancedBaseplate = this.allowAdvancedBaseplate;
        codeList.defaultMaterialSafetyFactor = this.defaultMaterialSafetyFactor;
        codeList.isBridgesDisplacementLimitAllowed = this.isBridgesDisplacementLimitAllowed;
        codeList.defaultConcreteInCompressionMethod = this.defaultConcreteInCompressionMethod;
        codeList.defaultEurocodeDesignStandardSteelGuideLine = this.defaultEurocodeDesignStandardSteelGuideLine;
        codeList.allowAdvancedBaseplateMetalDeck = this.allowAdvancedBaseplateMetalDeck;
        codeList.includeParallelDesignInReport = this.includeParallelDesignInReport;
        codeList.defaultSteelGuideline = this.defaultSteelGuideline;
        codeList.isSmartAnchorAvailable = this.isSmartAnchorAvailable;
        codeList.allowAdvancedBaseplateHandrail = this.allowAdvancedBaseplateHandrail;
        codeList.isDG1ExtensionAllowed = this.isDG1ExtensionAllowed;
        codeList.defaultDesignMethodGroupForRussianDesignStandard = this.defaultDesignMethodGroupForRussianDesignStandard;
        codeList.defaultMethodGroupForAustralianDesignStandard = this.defaultMethodGroupForAustralianDesignStandard;
        codeList.defaultMethodGroupForHongKongDesignStandard = this.defaultMethodGroupForHongKongDesignStandard;
        codeList.defaultDesignMethodGroupForCsaDesignStandard = this.defaultDesignMethodGroupForCsaDesignStandard;
        codeList.defaultMeasureAnchorPlate = this.defaultMeasureAnchorPlate;
        codeList.defaultMeasureBaseMaterialEdgeFrom = this.defaultMeasureBaseMaterialEdgeFrom;
        codeList.fireLearnMoreUrl = this.fireLearnMoreUrl;

        // Do not deep clone commonRegion, just copy reference... we use this only as reference to common code list.
        codeList.commonRegion = this.commonRegion;

        return codeList;
    }
}
