import { HandrailSafetyClassEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class HandrailSafetyClass extends CodeList {

    public static fromService(codeList: HandrailSafetyClassEntity) {
        return new HandrailSafetyClass(CodeList.baseFromService('HandrailSafetyClass', codeList));
    }

    public override cloneDeep(): HandrailSafetyClass {
        return super.cloneDeep() as HandrailSafetyClass;
    }
}
