import { Injectable } from '@angular/core';
import { GeneralValue } from '@profis-engineering/pe-ui-common/entities/user-settings';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import {
    AppSettingsHelperWithUnit, IConcreteFixingData, IHandrailFixingData, IMasonryFixingData, IMetalDeckFixing,
    reductionFactorSP
} from '../../shared/helpers/app-settings-helper';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { UnitService } from './unit.service';
import { UserSettingsService } from './user-settings.service';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    private appSettingsHelper!: AppSettingsHelperWithUnit;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private codeList: CodeListService,
        private commonCodeList: CommonCodeListService,
        private unit: UnitService,
        private numberService: NumberService,
        sharedEnvironmentService: SharedEnvironmentService
    ) {
        this.appSettingsHelper = new AppSettingsHelperWithUnit(this.localization, this.userSettings, this.codeList, this.commonCodeList, this.unit, this.numberService, sharedEnvironmentService.data?.useDevFeatures ?? false);
    }

    // Concrete fixing
    public loadConcreteSettings() {
        const concreteFixing: IConcreteFixingData = {
            concreteRegion: undefined as unknown as number,
            concreteEtagEnOnly: false
        };

        if (this.userSettings.quickStartConcreteSettings.general_region != null) {
            concreteFixing.concreteRegion = this.userSettings.quickStartConcreteSettings.general_region.id;
        }

        concreteFixing.concreteLength = this.userSettings.quickStartConcreteSettings.units_length?.id;
        concreteFixing.concreteArea = this.userSettings.quickStartConcreteSettings.units_area?.id;
        concreteFixing.concreteStress = this.userSettings.quickStartConcreteSettings.units_stress?.id;
        concreteFixing.concreteForce = this.userSettings.quickStartConcreteSettings.units_force?.id;
        concreteFixing.concreteMoment = this.userSettings.quickStartConcreteSettings.units_moment?.id;
        concreteFixing.concreteTemperature = this.userSettings.quickStartConcreteSettings.units_temperature?.id;
        concreteFixing.concreteForcePerLength = this.userSettings.quickStartConcreteSettings.units_force_per_length?.id;
        concreteFixing.concreteMomentPerLength = this.userSettings.quickStartConcreteSettings.units_moment_per_length?.id;
        concreteFixing.concreteDensity = this.userSettings.quickStartConcreteSettings.units_density?.id;
        concreteFixing.concreteAnchorPlateFrom = this.userSettings.quickStartConcreteSettings.measureAnchorPlate;
        concreteFixing.concreteBaseMaterialEdgeFrom = this.userSettings.quickStartConcreteSettings.measureBaseMaterialEdgeFrom;

        if (this.userSettings.quickStartConcreteSettings.calculation_designStandard != null) {
            concreteFixing.concreteDesignStandardId = this.userSettings.quickStartConcreteSettings.calculation_designStandard.id;
        }
        else {
            concreteFixing.concreteDesignStandardId = undefined;
        }

        if (this.userSettings.quickStartConcreteSettings.calculation_designMethodGroup != null) {
            concreteFixing.concreteDesignMethodGroupId = this.userSettings.quickStartConcreteSettings.calculation_designMethodGroup.id;
        }
        else {
            concreteFixing.concreteDesignMethodGroupId = undefined;
        }

        if (this.userSettings.quickStartConcreteSettings.calculation_etagEnOnly) {
            concreteFixing.concreteEtagEnOnly = this.userSettings.quickStartConcreteSettings.calculation_etagEnOnly;
        }

        concreteFixing.concreteAnchorPlateFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.anchorPlateFactor.value);
        concreteFixing.concreteSafetyFactorForVariableLoads = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.variableLoads.value);
        concreteFixing.concreteSafetyFactorForPermanentLoads = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.permenentLoads.value);

        if (this.userSettings.quickStartConcreteSettings.edgeDistanceDisplayType != null) {
            concreteFixing.concreteEdgeDistanceDisplay = this.userSettings.quickStartConcreteSettings.edgeDistanceDisplayType.id;
        }

        concreteFixing.concreteMinimumAnchorToProfileDistance = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.minimumAnchorToProfileDistance.value);
        concreteFixing.concreteMinimumConcreteCover = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.minimumConcreteCover.value);

        if (this.userSettings.settings.quickStart.concrete.weldsCapacityFactor.value != null) {
            concreteFixing.concreteWeldsCapacityFactor = this.userSettings.settings.quickStart.concrete.weldsCapacityFactor.value;
        }
        else {
            concreteFixing.concreteWeldsCapacityFactor = reductionFactorSP;
        }

        if (this.userSettings.settings.quickStart.concrete.concreteSafetyFactorGammaC.value != null) {
            concreteFixing.concreteSafetyFactorGammaCId = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.concreteSafetyFactorGammaC.value);
        }

        concreteFixing.concreteSteelSafetyFactorGammaM0 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.steelSafetyFactorGammaM0.value);
        concreteFixing.concreteSteelSafetyFactorGammaM1 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.steelSafetyFactorGammaM1.value);
        concreteFixing.concreteSteelSafetyFactorGammaM2 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.steelSafetyFactorGammaM2.value);
        concreteFixing.concreteMaterialSafetyFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.materialSafetyFactor.value);
        concreteFixing.concreteConcreteCapacityFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.concreteCapacityFactor.value);
        concreteFixing.concreteSteelCapacityFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.steelCapacityFactor.value);
        concreteFixing.concreteInSteelSafetyFactorGammaM0 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.inSteelSafetyFactorGammaM0.value);
        concreteFixing.concreteInSteelSafetyFactorGammaM1 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.inSteelSafetyFactorGammaM1.value);
        concreteFixing.concreteInSteelSafetyFactorGammaMw = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.inSteelSafetyFactorGammaMw.value);
        concreteFixing.concreteInSteelSafetyFactorGammaMb = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.concrete.inSteelSafetyFactorGammaMb.value);

        concreteFixing.concreteSteelCalculationMethod = this.userSettings.quickStartConcreteSettings.steelGuideLine;

        return concreteFixing;
    }

    public handleRegionChangeConcrete(concreteFixing: IConcreteFixingData, regionId: number) {
        if (concreteFixing.concreteRegion == SpecialRegion.Default) {
            const region = this.appSettingsHelper.getRegionById(regionId);
            this.appSettingsHelper.setDefaultConcreteUnits(concreteFixing, region);
            this.appSettingsHelper.setDefaultDimensioningStartPreference(concreteFixing, region);
            this.appSettingsHelper.setDefaultConcreteDesignStandard(concreteFixing, region);
            this.appSettingsHelper.setDefaultConcreteDesignMethodGroup(concreteFixing, region);
            this.appSettingsHelper.setDefaultConcreteSteelCalculationMethod(concreteFixing, region);
        }
    }

    public updateConcreteSettings(concreteFixing: IConcreteFixingData) {
        if (concreteFixing == null) {
            return;
        }

        const settings = this.userSettings.settings.quickStart.concrete;

        settings.generalRegionId.value = concreteFixing.concreteRegion;
        this.appSettingsHelper.updateSetting(settings.unitsLengthId, concreteFixing.concreteLength);
        this.appSettingsHelper.updateSetting(settings.unitsAreaId, concreteFixing.concreteArea);
        this.appSettingsHelper.updateSetting(settings.unitsStressId, concreteFixing.concreteStress);
        this.appSettingsHelper.updateSetting(settings.unitsForceId, concreteFixing.concreteForce);
        this.appSettingsHelper.updateSetting(settings.unitsMomentId, concreteFixing.concreteMoment);
        this.appSettingsHelper.updateSetting(settings.unitsTemperatureId, concreteFixing.concreteTemperature);
        this.appSettingsHelper.updateSetting(settings.unitsForcePerLengthId, concreteFixing.concreteForcePerLength);
        this.appSettingsHelper.updateSetting(settings.unitsMomentPerLengthId, concreteFixing.concreteMomentPerLength);
        this.appSettingsHelper.updateSetting(settings.unitsDensityId, concreteFixing.concreteDensity);
        this.appSettingsHelper.updateSetting(settings.calculationDesignStandardId, concreteFixing.concreteDesignStandardId);
        this.appSettingsHelper.updateSetting(settings.calculationDesignMethodGroupId, concreteFixing.concreteDesignMethodGroupId);
        this.appSettingsHelper.updateSetting(settings.edgeDistanceDisplayTypeId, concreteFixing.concreteEdgeDistanceDisplay);
        this.appSettingsHelper.updateSetting(settings.minimumConcreteCover, concreteFixing.concreteMinimumConcreteCover);
        this.appSettingsHelper.updateSetting(settings.anchorPlateFactor, concreteFixing.concreteAnchorPlateFactor);
        this.appSettingsHelper.updateSetting(settings.variableLoads, concreteFixing.concreteSafetyFactorForVariableLoads);
        this.appSettingsHelper.updateSetting(settings.permenentLoads, concreteFixing.concreteSafetyFactorForPermanentLoads);
        this.appSettingsHelper.updateSetting(settings.minimumAnchorToProfileDistance, concreteFixing.concreteMinimumAnchorToProfileDistance);
        settings.concreteSafetyFactorGammaC.value = concreteFixing.concreteSafetyFactorGammaCId ?? null;
        settings.calculationEtagEnOnly.value = concreteFixing.concreteEtagEnOnly;
        this.appSettingsHelper.updateSetting(settings.steelGuideline, concreteFixing.concreteSteelCalculationMethod);
        this.appSettingsHelper.updateSetting(settings.steelSafetyFactorGammaM0, concreteFixing.concreteSteelSafetyFactorGammaM0);
        this.appSettingsHelper.updateSetting(settings.steelSafetyFactorGammaM1, concreteFixing.concreteSteelSafetyFactorGammaM1);
        this.appSettingsHelper.updateSetting(settings.steelSafetyFactorGammaM2, concreteFixing.concreteSteelSafetyFactorGammaM2);
        this.appSettingsHelper.updateSetting(settings.materialSafetyFactor, concreteFixing.concreteMaterialSafetyFactor);
        this.appSettingsHelper.updateSetting(settings.steelCapacityFactor, concreteFixing.concreteSteelCapacityFactor);
        this.appSettingsHelper.updateSetting(settings.concreteCapacityFactor, concreteFixing.concreteConcreteCapacityFactor);
        this.appSettingsHelper.updateSetting(settings.weldsCapacityFactor, concreteFixing.concreteWeldsCapacityFactor);
        this.appSettingsHelper.updateSetting(settings.inSteelSafetyFactorGammaM0, concreteFixing.concreteInSteelSafetyFactorGammaM0);
        this.appSettingsHelper.updateSetting(settings.inSteelSafetyFactorGammaM1, concreteFixing.concreteInSteelSafetyFactorGammaM1);
        this.appSettingsHelper.updateSetting(settings.inSteelSafetyFactorGammaMw, concreteFixing.concreteInSteelSafetyFactorGammaMw);
        this.appSettingsHelper.updateSetting(settings.inSteelSafetyFactorGammaMb, concreteFixing.concreteInSteelSafetyFactorGammaMb);

        if (settings.measureAnchorPlate == null) {
            settings.measureAnchorPlate = new GeneralValue(concreteFixing.concreteAnchorPlateFrom ?? null);
        }
        else {
            this.appSettingsHelper.updateSetting(settings.measureAnchorPlate, concreteFixing.concreteAnchorPlateFrom);
        }

        if (settings.measureBaseMaterialEdgeFrom == null) {
            settings.measureBaseMaterialEdgeFrom = new GeneralValue(concreteFixing.concreteBaseMaterialEdgeFrom ?? null);
        }
        else {
            this.appSettingsHelper.updateSetting(settings.measureBaseMaterialEdgeFrom, concreteFixing.concreteBaseMaterialEdgeFrom);
        }
    }

    // Handrail fixing
    public loadHandrailSettings() {
        const handrailFixing: IHandrailFixingData = {
            handrailRegion: undefined as unknown as number
        };

        if (this.userSettings.quickStartHandrailSettings.general_region != null) {
            handrailFixing.handrailRegion = this.userSettings.quickStartHandrailSettings.general_region.id;
        }

        handrailFixing.handrailDisplacementLimit = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.handrail.displacementLimit.value);
        if (this.userSettings.settings.quickStart.handrail.displacementLimitBridges != null) {
            handrailFixing.handrailDisplacementLimitBridges = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.handrail.displacementLimitBridges.value);
        }

        handrailFixing.handrailLength = this.userSettings.quickStartHandrailSettings.units_length?.id;
        handrailFixing.handrailArea = this.userSettings.quickStartHandrailSettings.units_area?.id;
        handrailFixing.handrailStress = this.userSettings.quickStartHandrailSettings.units_stress?.id;
        handrailFixing.handrailForce = this.userSettings.quickStartHandrailSettings.units_force?.id;
        handrailFixing.handrailMoment = this.userSettings.quickStartHandrailSettings.units_moment?.id;
        handrailFixing.handrailTemperature = this.userSettings.quickStartHandrailSettings.units_temperature?.id;
        handrailFixing.handrailForcePerLength = this.userSettings.quickStartHandrailSettings.units_force_per_length?.id;
        handrailFixing.handrailLengthLarge = this.userSettings.quickStartHandrailSettings.units_length_large?.id;
        handrailFixing.handrailStressSmall = this.userSettings.quickStartHandrailSettings.units_stress_small?.id;

        if (this.userSettings.quickStartHandrailSettings.calculation_designStandard != null) {
            handrailFixing.handrailDesignStandardId = this.userSettings.quickStartHandrailSettings.calculation_designStandard.id;
        }
        else {
            handrailFixing.handrailDesignStandardId = undefined;
        }

        if (this.userSettings.quickStartHandrailSettings.calculation_designMethodGroup != null) {
            handrailFixing.handrailDesignMethodGroupId = this.userSettings.quickStartHandrailSettings.calculation_designMethodGroup.id;
        }
        else {
            handrailFixing.handrailDesignMethodGroupId = undefined;
        }

        handrailFixing.handrailEtagEnOnly = this.userSettings.quickStartHandrailSettings.calculation_etagEnOnly;

        handrailFixing.handrailMinimumAnchorToProfileDistance = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.handrail.minimumAnchorToProfileDistance.value);
        handrailFixing.handrailAnchorPlateFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.handrail.anchorPlateFactor.value);

        if (this.userSettings.quickStartHandrailSettings.edgeDistanceDisplayType != null) {
            handrailFixing.handrailEdgeDistanceDisplay = this.userSettings.quickStartHandrailSettings.edgeDistanceDisplayType.id;
        }

        handrailFixing.handrailSteelSafetyFactorGammaM0 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.handrail.steelSafetyFactorGammaM0.value);
        handrailFixing.handrailSteelSafetyFactorGammaM1 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.handrail.steelSafetyFactorGammaM1.value);
        handrailFixing.handrailSteelSafetyFactorGammaM2 = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.handrail.steelSafetyFactorGammaM2.value);

        return handrailFixing;
    }

    public handleRegionChangeHandrail(handrailFixing: IHandrailFixingData, regionId: number) {
        if (handrailFixing.handrailRegion == SpecialRegion.Default) {
            const region = this.appSettingsHelper.getRegionById(regionId);
            this.appSettingsHelper.setDefaultHandrailUnits(handrailFixing, region);
            this.appSettingsHelper.setDefaultHandrailDesignStandard(handrailFixing, region);
            this.appSettingsHelper.setDefaultHandrailDesignMethodGroup(handrailFixing, region);
        }
    }

    public updateHandrailSettings(handrailFixing: IHandrailFixingData) {
        if (handrailFixing == null) {
            return;
        }

        const settings = this.userSettings.settings.quickStart.handrail;

        this.appSettingsHelper.updateSetting(settings.generalRegionId, handrailFixing.handrailRegion);
        this.appSettingsHelper.updateSetting(settings.unitsLengthId, handrailFixing.handrailLength);
        this.appSettingsHelper.updateSetting(settings.unitsLengthLargeId, handrailFixing.handrailLengthLarge);
        this.appSettingsHelper.updateSetting(settings.unitsAreaId, handrailFixing.handrailArea);
        this.appSettingsHelper.updateSetting(settings.unitsStressId, handrailFixing.handrailStress);
        this.appSettingsHelper.updateSetting(settings.unitsStressSmallId, handrailFixing.handrailStressSmall);
        this.appSettingsHelper.updateSetting(settings.unitsForceId, handrailFixing.handrailForce);
        this.appSettingsHelper.updateSetting(settings.unitsMomentId, handrailFixing.handrailMoment);
        this.appSettingsHelper.updateSetting(settings.unitsTemperatureId, handrailFixing.handrailTemperature);
        this.appSettingsHelper.updateSetting(settings.unitsForcePerLengthId, handrailFixing.handrailForcePerLength);
        this.appSettingsHelper.updateSetting(settings.calculationDesignStandardId, handrailFixing.handrailDesignStandardId);
        this.appSettingsHelper.updateSetting(settings.calculationDesignMethodGroupId, handrailFixing.handrailDesignMethodGroupId);
        this.appSettingsHelper.updateSetting(settings.anchorPlateFactor, handrailFixing.handrailAnchorPlateFactor);
        this.appSettingsHelper.updateSetting(settings.edgeDistanceDisplayTypeId, handrailFixing.handrailEdgeDistanceDisplay);
        this.appSettingsHelper.updateSetting(settings.displacementLimit, handrailFixing.handrailDisplacementLimit);
        this.appSettingsHelper.updateSetting(settings.displacementLimitBridges, handrailFixing.handrailDisplacementLimitBridges);
        this.appSettingsHelper.updateSetting(settings.minimumAnchorToProfileDistance, handrailFixing.handrailMinimumAnchorToProfileDistance);
        settings.calculationEtagEnOnly.value = handrailFixing.handrailEtagEnOnly ?? null;
        this.appSettingsHelper.updateSetting(settings.steelSafetyFactorGammaM0, handrailFixing.handrailSteelSafetyFactorGammaM0);
        this.appSettingsHelper.updateSetting(settings.steelSafetyFactorGammaM1, handrailFixing.handrailSteelSafetyFactorGammaM1);
        this.appSettingsHelper.updateSetting(settings.steelSafetyFactorGammaM2, handrailFixing.handrailSteelSafetyFactorGammaM2);
    }

    // Masonry fixing
    public loadMasonrySettings() {
        const masonryFixing: IMasonryFixingData = {
            masonryRegion: undefined as unknown as number
        };

        if (this.userSettings.quickStartMasonrySettings.general_region != null) {
            masonryFixing.masonryRegion = this.userSettings.quickStartMasonrySettings.general_region.id;
        }

        masonryFixing.masonryLength = this.userSettings.quickStartMasonrySettings.units_length?.id;
        masonryFixing.masonryArea = this.userSettings.quickStartMasonrySettings.units_area?.id;
        masonryFixing.masonryStress = this.userSettings.quickStartMasonrySettings.units_stress?.id;
        masonryFixing.masonryForce = this.userSettings.quickStartMasonrySettings.units_force?.id;
        masonryFixing.masonryMoment = this.userSettings.quickStartMasonrySettings.units_moment?.id;
        masonryFixing.masonryTemperature = this.userSettings.quickStartMasonrySettings.units_temperature?.id;

        if (this.userSettings.quickStartMasonrySettings.calculation_designStandard != null) {
            masonryFixing.masonryDesignStandardId = this.userSettings.quickStartMasonrySettings.calculation_designStandard.id;
        }
        else {
            masonryFixing.masonryDesignStandardId = undefined;
        }

        if (this.userSettings.quickStartMasonrySettings.calculation_designMethodGroup != null) {
            masonryFixing.masonryDesignMethodGroupId = this.userSettings.quickStartMasonrySettings.calculation_designMethodGroup.id;
        }
        else {
            masonryFixing.masonryDesignMethodGroupId = undefined;
        }
        masonryFixing.masonryTechnicalDataEtaData = this.userSettings.quickStartMasonrySettings.calculation_etaData;
        masonryFixing.masonryTechnicalDataOSTInput = this.userSettings.quickStartMasonrySettings.calculation_ostInputsData;

        masonryFixing.masonryMinimumAnchorToProfileDistance = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.masonry.minimumAnchorToProfileDistance.value);
        masonryFixing.masonryAnchorPlateFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.masonry.anchorPlateFactor.value);
        masonryFixing.masonrySafetyFactorForVariableLoads = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.masonry.variableLoads.value);
        masonryFixing.masonrySafetyFactorForPermanentLoads = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.masonry.permenentLoads.value);

        if (this.userSettings.quickStartMasonrySettings.edgeDistanceDisplayType != null) {
            masonryFixing.masonryEdgeDistanceDisplay = this.userSettings.quickStartMasonrySettings.edgeDistanceDisplayType.id;
        }

        return masonryFixing;
    }

    public handleRegionChangeMasonry(masonryFixing: IMasonryFixingData, regionId: number) {
        if (masonryFixing.masonryRegion == SpecialRegion.Default) {
            const region = this.appSettingsHelper.getRegionById(regionId);
            this.appSettingsHelper.setDefaultMasonryUnits(masonryFixing, region);
            this.appSettingsHelper.setDefaultMasonryDesignStandard(masonryFixing, region);
            this.appSettingsHelper.setDefaultMasonryDesignMethodGroup(masonryFixing, region);
        }
    }

    public updateMasonrySettings(masonryFixing: IMasonryFixingData) {
        if (masonryFixing == null) {
            return;
        }

        const settings = this.userSettings.settings.quickStart.masonry;

        this.appSettingsHelper.updateSetting(settings.generalRegionId, masonryFixing.masonryRegion);
        this.appSettingsHelper.updateSetting(settings.unitsLengthId, masonryFixing.masonryLength);
        this.appSettingsHelper.updateSetting(settings.unitsAreaId, masonryFixing.masonryArea);
        this.appSettingsHelper.updateSetting(settings.unitsStressId, masonryFixing.masonryStress);
        this.appSettingsHelper.updateSetting(settings.unitsForceId, masonryFixing.masonryForce);
        this.appSettingsHelper.updateSetting(settings.unitsMomentId, masonryFixing.masonryMoment);
        this.appSettingsHelper.updateSetting(settings.unitsTemperatureId, masonryFixing.masonryTemperature);
        this.appSettingsHelper.updateSetting(settings.calculationDesignStandardId, masonryFixing.masonryDesignStandardId);
        this.appSettingsHelper.updateSetting(settings.calculationDesignMethodGroupId, masonryFixing.masonryDesignMethodGroupId);
        this.appSettingsHelper.updateSetting(settings.edgeDistanceDisplayTypeId, masonryFixing.masonryEdgeDistanceDisplay);
        this.appSettingsHelper.updateSetting(settings.anchorPlateFactor, masonryFixing.masonryAnchorPlateFactor);
        this.appSettingsHelper.updateSetting(settings.minimumAnchorToProfileDistance, masonryFixing.masonryMinimumAnchorToProfileDistance);
        this.appSettingsHelper.updateSetting(settings.permenentLoads, masonryFixing.masonrySafetyFactorForPermanentLoads);
        this.appSettingsHelper.updateSetting(settings.variableLoads, masonryFixing.masonrySafetyFactorForVariableLoads);
        settings.calculationEtaData.value = masonryFixing.masonryTechnicalDataEtaData ?? null;
        settings.calculationOstInputsData.value = masonryFixing.masonryTechnicalDataOSTInput ?? null;
    }

    // MetalDeck fixing
    public loadMetalDeckSettings() {
        const metalDeckFixing: IMetalDeckFixing = {
            metalDeckRegion: undefined as unknown as number
        };

        if (this.userSettings.quickStartMetalDeckSettings.general_region != null) {
            metalDeckFixing.metalDeckRegion = this.userSettings.quickStartMetalDeckSettings.general_region.id;
        }

        metalDeckFixing.metalDeckLength = this.userSettings.quickStartMetalDeckSettings.units_length?.id;
        metalDeckFixing.metalDeckArea = this.userSettings.quickStartMetalDeckSettings.units_area?.id;
        metalDeckFixing.metalDeckStress = this.userSettings.quickStartMetalDeckSettings.units_stress?.id;
        metalDeckFixing.metalDeckForce = this.userSettings.quickStartMetalDeckSettings.units_force?.id;
        metalDeckFixing.metalDeckMoment = this.userSettings.quickStartMetalDeckSettings.units_moment?.id;
        metalDeckFixing.metalDeckTemperature = this.userSettings.quickStartMetalDeckSettings.units_temperature?.id;
        metalDeckFixing.metalDeckForcePerLength = this.userSettings.quickStartMetalDeckSettings.units_force_per_length?.id;
        metalDeckFixing.metalDeckMomentPerLength = this.userSettings.quickStartMetalDeckSettings.units_moment_per_length?.id;
        metalDeckFixing.metalDeckDensity = this.userSettings.quickStartMetalDeckSettings.units_density?.id;

        if (this.userSettings.quickStartMetalDeckSettings.calculation_designStandard != null) {
            metalDeckFixing.metalDeckDesignStandardId = this.userSettings.quickStartMetalDeckSettings.calculation_designStandard.id;
        }
        else {
            metalDeckFixing.metalDeckDesignStandardId = undefined;
        }

        if (this.userSettings.quickStartMetalDeckSettings.calculation_designMethodGroup != null) {
            metalDeckFixing.metalDeckDesignMethodGroupId = this.userSettings.quickStartMetalDeckSettings.calculation_designMethodGroup.id;
        }
        else {
            metalDeckFixing.metalDeckDesignMethodGroupId = undefined;
        }

        metalDeckFixing.metalDeckAnchorPlateFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.metalDeck.anchorPlateFactor.value);
        metalDeckFixing.metalDeckMaterialSafetyFactor = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.metalDeck.materialSafetyFactor.value);
        metalDeckFixing.metalDeckMinimumAnchorToProfileDistance = this.userSettings.getValueWithFallbacks(this.userSettings.settings.quickStart.metalDeck.minimumAnchorToProfileDistance.value);

        metalDeckFixing.metalDeckSteelCalculationMethod = this.userSettings.quickStartMetalDeckSettings.steelGuideLine;

        return metalDeckFixing;
    }

    public handleRegionChangeMetalDeck(metalDeckFixing: IMetalDeckFixing, regionId: number) {
        if (metalDeckFixing.metalDeckRegion == SpecialRegion.Default) {
            const region = this.appSettingsHelper.getRegionById(regionId);
            this.appSettingsHelper.setDefaultMetalDeckUnits(metalDeckFixing, region);
            this.appSettingsHelper.setDefaultMetalDeckDesignStandard(metalDeckFixing, region);
            this.appSettingsHelper.setDefaultMetalDeckDesignMethodGroup(metalDeckFixing, region);
            this.appSettingsHelper.setDefaultMetalDeckSteelCalculationMethod(metalDeckFixing, region);
        }
    }

    public updateMetalDeckSettings(metalDeckFixing: IMetalDeckFixing) {
        if (metalDeckFixing == null) {
            return;
        }

        const settings = this.userSettings.settings.quickStart.metalDeck;

        this.appSettingsHelper.updateSetting(settings.generalRegionId, metalDeckFixing.metalDeckRegion);
        this.appSettingsHelper.updateSetting(settings.unitsLengthId, metalDeckFixing.metalDeckLength);
        this.appSettingsHelper.updateSetting(settings.unitsAreaId, metalDeckFixing.metalDeckArea);
        this.appSettingsHelper.updateSetting(settings.unitsStressId, metalDeckFixing.metalDeckStress);
        this.appSettingsHelper.updateSetting(settings.unitsForceId, metalDeckFixing.metalDeckForce);
        this.appSettingsHelper.updateSetting(settings.unitsMomentId, metalDeckFixing.metalDeckMoment);
        this.appSettingsHelper.updateSetting(settings.unitsTemperatureId, metalDeckFixing.metalDeckTemperature);
        this.appSettingsHelper.updateSetting(settings.unitsForcePerLengthId, metalDeckFixing.metalDeckForcePerLength);
        this.appSettingsHelper.updateSetting(settings.unitsMomentPerLengthId, metalDeckFixing.metalDeckMomentPerLength);
        this.appSettingsHelper.updateSetting(settings.unitsDensityId, metalDeckFixing.metalDeckDensity);
        this.appSettingsHelper.updateSetting(settings.anchorPlateFactor, metalDeckFixing.metalDeckAnchorPlateFactor);
        this.appSettingsHelper.updateSetting(settings.calculationDesignStandardId, metalDeckFixing.metalDeckDesignStandardId);
        this.appSettingsHelper.updateSetting(settings.calculationDesignMethodGroupId, metalDeckFixing.metalDeckDesignMethodGroupId);
        this.appSettingsHelper.updateSetting(settings.minimumAnchorToProfileDistance, metalDeckFixing.metalDeckMinimumAnchorToProfileDistance);
        this.appSettingsHelper.updateSetting(settings.steelGuideline, metalDeckFixing.metalDeckSteelCalculationMethod);
        this.appSettingsHelper.updateSetting(settings.materialSafetyFactor, metalDeckFixing.metalDeckMaterialSafetyFactor);
    }
}
