import { Injectable } from '@angular/core';
import { IDesignSectionExportComponentInput } from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { ModalServiceInjected } from '@profis-engineering/pe-ui-common/services/modal.common';
import {
    AdvancedBaseplateCalculationComponentInput
} from '../../shared/components/advanced-baseplate-calculation-popup';
import { ISelectAnchorComponentInput } from '../../shared/components/select-anchor';
import {
    AsadOptimizeDetailsModalInput, AsadOptimizeScopeCheckDetailsModalInput
} from '../../shared/entities/asad-optimize-modal';
import { IBaseplateResultsPreferenceInput } from '../../shared/entities/baseplate-inputs';
import { AsadOptimizeCase, DesignPe } from '../../shared/entities/design-pe';
import {
    AdvancedCalculationType, ShearLoadDistributionTypes
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    HeadJointSelection, HeadJointSelectionInfoPopupInput
} from '../components/head-joint-selection-info-popup/head-joint-selection-info-popup.component';
import {
    IShearLoadDistributionTypeComponentInput
} from '../components/shear-load-distribution-type-popup/shear-load-distribution-type-popup.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService extends ModalServiceInjected {
    public openAsadOptimizeDetailsModal(input: AsadOptimizeDetailsModalInput): void {
        this.openWebComponentModal('pe-asad-optimize-details-modal', { size: 'lg' }, input);
    }

    public openAsadOptimizeScopeCheckDetailsModal(input: AsadOptimizeScopeCheckDetailsModalInput): void {
        this.openWebComponentModal('pe-asad-optimize-scope-check-details-modal', { size: 'lg' }, input);
    }

    public openDetailedCalculationOutput() {
        this.openWebComponentModal('pe-detailed-calculation-output', { size: 'adaptive' });
    }

    public openLoadsHNAWizard(props?: object) {
        this.openWebComponentModal('pe-loads-hna-wizard', { size: 'lg' }, props);
    }

    public openFatigueLoadsInfo() {
        this.openWebComponentModal('pe-fatigue-loads-info', { size: 'lg' });
    }

    public openImportLoads() {
        this.openWebComponentModal('pe-import-loads', { size: 'xl' });
    }

    public openBaseplateResultsPreference(preferences: IBaseplateResultsPreferenceInput) {
        this.openWebComponentModal('pe-baseplate-results-preference', { size: 'lg' }, preferences);
    }

    public openSolutionDetail(optimizeCase: AsadOptimizeCase) {
        this.openWebComponentModal('pe-solution-detail', { size: 'lg' }, optimizeCase);
    }

    public openSelectAnchor(props?: ISelectAnchorComponentInput) {
        return this.openWebComponentModal('pe-select-anchor', { size: 'xl', noAnimation: true }, props);
    }

    public openMultiselectAnchor(props?: ISelectAnchorComponentInput) {
        return this.openWebComponentModal('pe-multiselect-anchor', { size: 'xl', noAnimation: true }, props);
    }

    public openMultipleApprovals(files: string[], availableLanguages: string[][]) {
        const inputValues = {
            files,
            availableLanguages
        };
        this.openWebComponentModal('pe-multiple-approvals', { size: 'sm' }, inputValues);
    }

    public openCadExport() {
        this.openWebComponentModal('pe-cad-export', { size: 'sm' });
    }

    public openImportRamLoadCombinations() {
        return this.openWebComponentModal('pe-ram-import-loads', { size: 'lg' });
    }

    public openOnSiteTests() {
        return this.openWebComponentModal('pe-on-site-tests', { size: 'lg' });
    }

    public openAsadOptimizeModal() {
        this.openWebComponentModal('pe-asad-optimize-modal', { size: 'lg' });
    }

    public openBuildingTypeCustomizeDetails() {
        this.openWebComponentModal('pe-building-type-customize-details', { size: 'lg' });
    }

    public openInputHandrailLoads(props?: object) {
        this.openWebComponentModal('pe-input-handrail-loads', { size: 'lg' }, props);
    }

    public openWindloads() {
        this.openWebComponentModal('pe-windloads', { size: 'lg' });
    }

    public openHorizontalPostProfileConnection() {
        this.openWebComponentModal('pe-horizontal-post-profile-connection', {});
    }

    public openDlubalImport() {
        return this.openWebComponentModal('pe-dlubal-import', { size: 'lg' });
    }

    public openDlubalExport() {
        return this.openWebComponentModal('pe-dlubal-export', { size: 'lg' });
    }

    public openSapImport() {
        return this.openWebComponentModal('pe-sap-import', { size: 'lg' });
    }

    public openAdvancedCalculationTypeInfo() {
        this.openWebComponentModal('pe-advanced-calculation-type-info');
    }

    public openEtabsImport() {
        return this.openWebComponentModal('pe-etabs-import', { size: 'lg' });
    }

    public openRobotImport() {
        return this.openWebComponentModal('pe-robot-import', { size: 'lg' });
    }

    public openStaadProImport() {
        return this.openWebComponentModal('pe-staad-pro-import', { size: 'lg' });
    }

    public openSupplementaryReinforcementInfo() {
        this.openWebComponentModal('pe-supplementary-reinforcement-info', { size: 'lg' });
    }

    public openFillHolesModal() {
        this.openWebComponentModal('pe-fill-holes-modal');
    }


    public openSeismicFillingSetModal() {
        this.openWebComponentModal('pe-seismic-filling-set-modal');
    }

    public openAutomaticTorquePopup() {
        this.openWebComponentModal('pe-automatic-torque-popup', { size: 'lg' });
    }

    public openHeadJointPopup(headJointSelection: HeadJointSelection) {
        this.openWebComponentModal('pe-head-joint-selection-info-popup', { size: 'md' }, { headJointSelection } as HeadJointSelectionInfoPopupInput);
    }

    public openAdvancedInputs(fromVirtualTour?: boolean) {
        fromVirtualTour = fromVirtualTour ?? false;

        const input = {
            preventDismiss: fromVirtualTour
        };
        return this.openWebComponentModal('pe-advanced-inputs', { size: 'lg', noAnimation: fromVirtualTour, windowClass: 'default-modal generic-modal advanced-inputs' }, input);
    }

    public openWHGInfo() {
        this.openWebComponentModal('pe-whg-info', { size: 'lg' });
    }

    public openMoreInfoOnSOFAModal() {
        this.openWebComponentModal('pe-more-info-on-sofa-modal', { size: 'lg' });
    }

    public openMoreInfoOnFatigueModal() {
        this.openWebComponentModal('pe-more-info-on-fatigue-modal', { size: 'xl' });
    }

    public openSelectProfile(props?: object) {
        return this.openWebComponentModal('pe-select-profile', { size: 'lg' }, props);
    }

    public openProfileSize(props?: object) {
        return this.openWebComponentModal('pe-profile-size', { size: 'lg' }, props);
    }

    public openDefineAnchors(props?: object) {
        this.openWebComponentModal('pe-define-anchors', {}, props);
    }

    public openBaseplateSize(props?: object) {
        return this.openWebComponentModal('pe-baseplate-size', { size: 'sm' }, props);
    }

    public openBrickSize(props?: object) {
        return this.openWebComponentModal('pe-brick-size', { size: 'sm' }, props);
    }

    public openSelectMaterial(props?: object) {
        return this.openWebComponentModal('pe-select-material', { size: 'lg' }, props);
    }

    public openCustomStiffenerLocationInfo() {
        return this.openWebComponentModal('pe-custom-stiffener-location-info', {});
    }

    public openCustomStiffenerPositionInfo() {
        return this.openWebComponentModal('pe-custom-stiffener-position-info', {});
    }

    public openCustomStiffenerWizard() {
        this.openWebComponentModal('pe-custom-stiffener-wizard', { size: 'lg' });
    }

    public openAnchorDesignHelp(props?: object) {
        this.openWebComponentModal('pe-anchor-design-help', { size: 'lg' }, props);
    }

    public openAnchorNeedSolutionPopup(fromVirtualTour?: boolean) {
        // If open from virtual tour:
        // - prevent dismissal (e.g. using ESC key)
        // - do not animate (so top offset can be calculated correctly)
        fromVirtualTour = fromVirtualTour ?? false;

        const input = {
            preventDismiss: fromVirtualTour
        };

        return this.openWebComponentModal('pe-anchor-need-solution-popup', { size: 'lg', noAnimation: fromVirtualTour, windowClass: 'default-modal generic-modal anchor-need-solution' }, input);
    }

    public openDeflectionLimits() {
        this.openWebComponentModal('pe-deflection-limits', { size: 'lg' });
    }

    public openExportReport(props?: object, noAnimation?: boolean) {
        noAnimation = noAnimation ?? false;
        return this.openWebComponentModal('pe-export-report', { size: 'lg', noAnimation: noAnimation }, props);
    }

    public openAdvancedBaseplateCalculation(input: AdvancedBaseplateCalculationComponentInput) {
        return this.openWebComponentModal('pe-advanced-baseplate-calculation', { size: 'lg', noAnimation: true }, input);
    }

    public openAdvancedBaseplateLicenseRequired() {
        this.openWebComponentModal('pe-advanced-baseplate-license-required');
    }

    public openDefaultCalculationMethod(design: DesignPe, calculationType: AdvancedCalculationType) {
        const inputValues = {
            design,
            calculationType
        };

        return this.openWebComponentModal('pe-default-calculation-method', {}, inputValues);
    }

    public openLoadsInput() {
        this.openWebComponentModal('pe-loads-input', { size: 'md' });
    }

    public openGenerateReportFollowUpActionsPopup(props?: IDesignSectionExportComponentInput) {
        return this.openWebComponentModal('pe-generate-report-follow-up-actions-popup', {}, props);
    }

    public openShearLoadDistributionPopup(shearLoadDistributionType: ShearLoadDistributionTypes, designMethodGroup?: number) {
        const inputValue: IShearLoadDistributionTypeComponentInput = {
            shearLoadDistributionType,
            designMethodGroup
        };
        this.openWebComponentModal('pe-shear-load-distribution-type-popup', { size: 'lg' }, inputValue);
    }
}
