import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import {
    DistanceUpwindToShorelineEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface IDistanceUpwindToShoreline extends ICodeListConstructor {
    value: number;
}

export class DistanceUpwindToShoreline extends CodeList {
    public value!: number;

    constructor(codelist: IDistanceUpwindToShoreline) {
        super(codelist);

        if (codelist != null) {
            this.value = codelist.value;
        }
    }

    public static fromService(codeList: DistanceUpwindToShorelineEntity) {
        return new DistanceUpwindToShoreline(CodeList.baseFromService<IDistanceUpwindToShoreline>('DistanceUpwindToShorelineEntity', codeList, {
            value: codeList.Value
        } as IDistanceUpwindToShoreline));
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        if (codeListDeps.numberService == null) {
            throw new Error('codeListDeps.numberService not set!');
        }

        const number = codeListDeps.numberService.format(this.value, Number.MAX_SAFE_INTEGER);
        return `${number} km`;
    }

    public override cloneDeep(): DistanceUpwindToShoreline {
        const codelist: DistanceUpwindToShoreline = super.cloneDeep() as DistanceUpwindToShoreline;

        codelist.value = this.value;

        return codelist;
    }
}
