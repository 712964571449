import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { BrickStrengthEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IBrickStrengthConstructor extends ICodeListConstructor {
    fbVertical?: number;
}

export class BrickStrength extends CodeList {
    public fbVertical?: number;

    constructor(codeList: IBrickStrengthConstructor) {
        super(codeList);

        if (codeList != null) {
            this.fbVertical = codeList.fbVertical;
        }
    }

    public static fromService(codeList: BrickStrengthEntity) {
        return new BrickStrength(CodeList.baseFromService<IBrickStrengthConstructor>('BrickStrengthEntity', codeList, {
            fbVertical: codeList.f_b_vertical
        } as IBrickStrengthConstructor));
    }

    public override cloneDeep() {
        const codeList: BrickStrength = super.cloneDeep() as BrickStrength;

        codeList.fbVertical = this.fbVertical;

        return codeList;
    }
}
