import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IFavoritesData } from './favorites.service';
import { SharedEnvironmentService } from './shared-environment.service';
import { AnchorFilterType } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from './localization.service';

export interface ISmartAnchorItemsOrderEntity {
    categoryId: number;
    applicationIds: number[];
}

@Injectable({
    providedIn: 'root'
})
export class SmartAnchorOrderService {
    public categoriesOrder: number[] = [];
    public applicationsOrder: ISmartAnchorItemsOrderEntity[] = [];

    constructor(
        private apiService: ApiService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private localizationService: LocalizationService
    ) { }

    public initialize(data: IFavoritesData) {
        this.categoriesOrder = data?.smartAnchorItems?.map((item) => item.categoryId) ?? [];
        this.applicationsOrder = data?.smartAnchorItems ?? [];
    }

    public async updateCategorySortOrder(categoryIds: number[]): Promise<void> {
        if (this.sharedEnvironmentService.data?.enableMenuFavorites) {
            const url = `${this.sharedEnvironmentService.data?.userSettingsWebServiceUrl}Favorites/UpdateSmartAnchorCategoriesSortOrder`;

            await this.saveToDatabase({categoryIds}, url);
            this.categoriesOrder = categoryIds;
        }
    }

    public async updateApplicationSortOrder(categoryId: number, applicationIds: number[]): Promise<void> {
        if (this.sharedEnvironmentService.data?.enableMenuFavorites) {
            const url = `${this.sharedEnvironmentService.data?.userSettingsWebServiceUrl}Favorites/UpdateSmartAnchorApplicationsSortOrder`;

            await this.saveToDatabase({categoryId, applicationIds}, url);
            const applicationItems = this.applicationsOrder.find((item) => item.categoryId === categoryId);

            if (applicationItems != null) {
                applicationItems.applicationIds = applicationIds;
            }
            else {
                this.applicationsOrder.push({ categoryId, applicationIds } as ISmartAnchorItemsOrderEntity);
            }
        }
    }

    private async saveToDatabase(data: any, url: string): Promise<void> {
        await this.apiService.request<boolean>(new HttpRequest('PUT', url, data));
    }

    public get constructionOptions(){
        return [
            {
                id: AnchorFilterType.SteelGalvanized.toString(),
                value: AnchorFilterType.SteelGalvanized,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Navigation.ConstructionOptions.GalvanizedSteel.Title')
              },
              {
                id: AnchorFilterType.SheradizedHotDipGalvanized.toString(),
                value: AnchorFilterType.SheradizedHotDipGalvanized,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Navigation.ConstructionOptions.Sheradized.Title')
              },
              {
                id: AnchorFilterType.SheradizedHotDipMechanicallyGalvanized.toString(),
                value: AnchorFilterType.SheradizedHotDipMechanicallyGalvanized,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Navigation.ConstructionOptions.MechanicallyGalvanized.Title')
              },
              {
                id: AnchorFilterType.StainlessSteel.toString(),
                value: AnchorFilterType.StainlessSteel,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Navigation.ConstructionOptions.StainlessSteel.Title')
              },
              {
                id: AnchorFilterType.HighCorrosionResistantSteel.toString(),
                value: AnchorFilterType.HighCorrosionResistantSteel,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Navigation.ConstructionOptions.CorrosionResistant.Title')
              }
        ];
    }
}
