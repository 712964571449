import {
    DesignType
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export interface IUtilization {
    readonly text: string;
    readonly property: string;
    readonly details: ReadonlyArray<IUtilizationDetail>;
}

export interface IUtilizationDetail {
    readonly image: string;
    readonly text: string;
    readonly property: string;
}

export function utilizations(designType: unknown): ReadonlyArray<IUtilization> {
    return [
        {
            text: 'Agito.Hilti.Profis3.Utilizations.Tension',
            property: 'tension',
            details: [
                {
                    image: 'tension-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Steel',
                    property: 'Steel'
                },
                {
                    image: 'tension-breakout',
                    text: designType == DesignType.Masonry ? 'Agito.Hilti.Profis3.Utilizations.Tension.MasonryBreakout' : 'Agito.Hilti.Profis3.Utilizations.Tension.ConcreteBreakout',
                    property: 'ConcreteBreakout'
                },
                {
                    image: 'tension-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.BrickBreakout',
                    property: 'BrickBreakout'
                },
                {
                    image: 'tension-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Pullout',
                    property: 'Pullout'
                },
                {
                    image: 'tension-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.PulloutBond',
                    property: 'PulloutBond'
                },
                {
                    image: 'tension-brick-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.BrickPullout',
                    property: 'BrickPullout'
                },
                {
                    image: 'tension-combined',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.CombinedPulloutBreakout',
                    property: 'CombinedPulloutBreakout'
                },
                {
                    image: 'tension-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SustainedPullout',
                    property: 'SustainedPullout'
                },
                {
                    image: 'tension-splitting',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Splitting',
                    property: 'Splitting'
                },
                {
                    image: 'tension-blowout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.ConcreteBlowout',
                    property: 'ConcreteBlowout'
                },
                {
                    image: 'tension-bond',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Bond',
                    property: 'Bond'
                },
                {
                    image: 'tension-overall',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.Overall',
                    property: 'Overall'
                },
                {
                    image: 'tension-supplementary-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SupplementaryReinforcementSteel',
                    property: 'SupplementaryReinforcementSteel'
                },
                {
                    image: 'tension-supplementary-reinforcement-anchorage',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SupplementaryReinforcementAnchorage',
                    property: 'SupplementaryReinforcementAnchorage'
                },
                {
                    image: 'tension-surface-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Tension.SurfaceReinforcementSteel',
                    property: 'SurfaceReinforcementSteel'
                },
            ]
        },
        {
            text: 'Agito.Hilti.Profis3.Utilizations.Shear',
            property: 'shear',
            details: [
                {
                    image: 'shear-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Steel',
                    property: 'Steel'
                },
                {
                    image: 'shear-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteBvc',
                    property: 'ConcreteBvc'
                },
                {
                    image: 'shear-breakout',
                    text: designType == DesignType.Masonry ? 'Agito.Hilti.Profis3.Utilizations.Shear.MasonryEdgeBreakout' : 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteEdgeBreakout',
                    property: 'ConcreteEdgeBreakout'
                },
                {
                    image: 'shear-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.ConcreteEdgeBreakoutParallel',
                    property: 'ConcreteEdgeBreakoutParallel'
                },
                {
                    image: 'shear-pryout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Pryout',
                    property: 'Pryout'
                },

                {
                    image: 'shear-concrete-bearing',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BearingStrength',
                    property: 'BearingStrength'
                },
                                {
                    image: 'shear-additive-concrete-bearing',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.StressLimit',
                    property: 'StressLimit'
                },

                {
                    image: 'shear-breakout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BrickEdge',
                    property: 'BrickEdge'
                },
                {
                    image: 'shear-local-brick',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BrickLocal',
                    property: 'BrickLocal'
                },
                {
                    image: 'shear-brick-pullout',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.BrickPushout',
                    property: 'BrickPushout'
                },
                {
                    image: 'shear-bond',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Bond',
                    property: 'Bond'
                },
                {
                    image: 'shear-overall',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.Overall',
                    property: 'Overall'
                },
                {
                    image: 'shear-supplementary-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.SupplementaryReinforcementSteel',
                    property: 'SupplementaryReinforcementSteel'
                },
                {
                    image: 'shear-supplementary-reinforcement-anchorage',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.SupplementaryReinforcementAnchorage',
                    property: 'SupplementaryReinforcementAnchorage'
                },
                {
                    image: 'shear-surface-reinforcement-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.SurfaceReinforcementSteel',
                    property: 'SurfaceReinforcementSteel'
                },
                {
                    image: 'shear-bond',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.AnchorageStrength',
                    property: 'AnchorageStrength'
                },
                {
                    image: 'crushing',
                    text: 'Agito.Hilti.Profis3.Utilizations.Shear.MasonryCrushing',
                    property: 'MasonryCrushing'
                },
            ]
        },
        {
            text: 'Agito.Hilti.Profis3.Utilizations.Combination',
            property: 'combination',
            details: [
                {
                    image: 'combination-steel',
                    text: 'Agito.Hilti.Profis3.Utilizations.Combination.Steel',
                    property: 'Steel'
                },
                {
                    image: 'combination-concrete',
                    text: 'Agito.Hilti.Profis3.Utilizations.Combination.Concrete',
                    property: 'Concrete'
                }
            ]
        }
    ];
}
