import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { ISelectAnchorProps, SelectAnchor } from '../../components/main-menu/select-anchor/SelectAnchor';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { getSpriteAsIconStyleResponsive } from '../../sprites';
import { isAnchorFamilyMarkedAsNew, updateMainMenuControl } from './menu-helper';

export function initializeSelectAnchor(
    design: DesignPe,
    controlProps: ISelectAnchorProps,
    localizationService: LocalizationService,
    modalService: ModalService,
    uiPropertyId: number,
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) {

        controlProps.type = SelectAnchor;
        const propertyValue = design.model[uiPropertyId] as number;

        const getAnchorDataFn = (anchorFamilyId?: number) => {
            let buttonText = '';
            let tag = '';
            let buttonImage = '';
            let buttonImageStyle: IResponsiveIconStyle | undefined;

            if (anchorFamilyId != null) {
                const anchorFamilies = DesignMethodGroupHelper.IsLrfdBased(design.designMethodGroup?.id)
                    ? design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
                    : design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

                const selectedAnchorFamily = anchorFamilies.find((anchorFamily) => anchorFamily.id == anchorFamilyId);
                buttonText = selectedAnchorFamily?.getTranslatedNameText({ localizationService }) ?? '';
                tag = selectedAnchorFamily?.detailed?.tag ?? '';

                buttonImage = selectedAnchorFamily?.image ?? '';
                if (selectedAnchorFamily?.image != null && selectedAnchorFamily.image != '') {
                    buttonImageStyle = {
                        elementStyle: {},
                        afterElementStyle: getSpriteAsIconStyleResponsive(selectedAnchorFamily.image)
                    };
                }
            }

            return {
                buttonText,
                tag,
                buttonImage,
                buttonImageStyle
            };
        };

        const buttonData = getAnchorDataFn(propertyValue);
        controlProps.buttonText = buttonData.buttonText;
        controlProps.buttonImage = buttonData.buttonImage;
        controlProps.buttonImageStyle = buttonData.buttonImageStyle;
        controlProps.tag = buttonData.tag;
        controlProps.isNew = isAnchorFamilyMarkedAsNew(propertyValue, design);
        controlProps.internalPortfolioOnly = design.anchorFamily?.internalPortfolioOnly;

        const onStateChanged = (state: IDesignState) => {
            const isNew = isAnchorFamilyMarkedAsNew(state.model[uiPropertyId] as number, design);
            const internalPortfolioOnly = design.anchorFamily?.internalPortfolioOnly;
            const buttonData = getAnchorDataFn(state.model[uiPropertyId] as number);
            return {
                buttonText: buttonData.buttonText,
                buttonImage: buttonData.buttonImage,
                buttonImageStyle: buttonData.buttonImageStyle,
                isNew: isNew,
                internalPortfolioOnly: internalPortfolioOnly,
                tag: buttonData.tag
            } as ISelectAnchorProps;
        };

        controlProps.clicked = () => {
            modalService.openSelectAnchor({
                onSelect: (selectedAnchorFamilyId) => {
                    // change menu
                    setState(menu => updateMainMenuControl<ISelectAnchorProps>(menu, controlProps.controlId, { selectedAnchorFamilyId } as any));
                }
            });
        };

        return {
            controlProps,
            onStateChanged
        };
}
