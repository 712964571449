import {
    TerrainRoughnessClassEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class TerrainRoughness extends CodeList {

    public static fromService(codeList: TerrainRoughnessClassEntity) {
        return new TerrainRoughness(CodeList.baseFromService('TerrainRoughnessClassEntity', codeList));
    }

    public override cloneDeep(): TerrainRoughness {
        return super.cloneDeep() as TerrainRoughness;
    }
}
