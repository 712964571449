import { DesignPe } from '../entities/design-pe';
import { HandrailLoadInstances } from '../generated-modules/Hilti.PE.Core.Calculation.Enums';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { DesignType } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export class HandrailHelper {

    public static calculateLoadInstance(design: DesignPe) {
        let loadInstance = HandrailLoadInstances.None;
        if (design?.designType?.id != DesignType.Handrail) {
            return loadInstance;
        }
        let loadId = design.selectedLoadCombinationId;

        if (loadId == null || loadId == '') {
            // decisive load combination
            if (design.designData.reportData?.DecisiveLoadCombinationIndex != null) {
                const loadCombination = design.designData.reportData.LoadCombinationItems[design.designData.reportData.DecisiveLoadCombinationIndex];

                loadId = loadCombination.Id;

                if (loadId == null || loadId == '') {
                    return loadInstance;
                }
            }
            else {
                return loadInstance;
            }
        }

        const split = loadId.split('-');
        if (design.region.id == KnownRegion.Sweden) {
            // "STR-A 7.1.1-o" --> ["STR", "A 7.1.1", "o"] --> ["A 7.1.1", "o"]
            split.splice(0, 1);
        }

        if (split.length < 1 || split.length > 2) {
            throw new Error('Invalid load id.');
        }

        if (split.length == 2) {
            loadInstance = HandrailHelper.calculateLoadInstanceBase(split, loadInstance);
        }

        switch (design.region.id) {
            case KnownRegion.Australia:
                loadInstance = HandrailHelper.calculateLoadInstanceAustralia(split, loadInstance);
                break;
            case KnownRegion.Sweden:
                loadInstance = HandrailHelper.calculateLoadInstanceSweden(split, loadInstance);
                break;
            default:
                loadInstance = HandrailHelper.calculateLoadInstanceDefault(split, loadInstance);
        }

        return loadInstance;
    }

    private static calculateLoadInstanceBase(split: string[], loadInstance: HandrailLoadInstances) {
        switch (split[1]) {
            case 'i':
                loadInstance |= HandrailLoadInstances.Inwards;

                break;
            case 'o':
                loadInstance |= HandrailLoadInstances.Outwards;

                break;
            case 'd':
                loadInstance |= HandrailLoadInstances.Downwards;

                break;
            default:
                throw new Error('Invalid load id.');
        }
        return loadInstance;
    }

    private static calculateLoadInstanceDefault(split: string[], loadInstance: HandrailLoadInstances) {
        switch (split[0]) {
            case '1.1':
            case '1.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;

                break;
            case '2.1':
            case '2.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                loadInstance |= HandrailLoadInstances.Vertical;

                break;
            case '3.1':
            case '3.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Wind;

                break;
            case '4.1.1':
            case '4.1.2':
            case '4.2.1':
            case '4.2.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                loadInstance |= HandrailLoadInstances.Wind;

                break;
            case '5.1.1':
            case '5.1.2':
            case '5.1.3':
            case '5.2.1':
            case '5.2.2':
            case '5.2.3':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                loadInstance |= HandrailLoadInstances.Vertical;
                loadInstance |= HandrailLoadInstances.Wind;

                break;
            case '5.3.1':
                loadInstance |= HandrailLoadInstances.Dead;

                break;
            case '6.1':
            case '6.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Vertical;

                break;
            default:
                throw new Error('Invalid load id.');
        }
        return loadInstance;
    }

    private static calculateLoadInstanceSweden(split: string[], loadInstance: HandrailLoadInstances) {
        switch (split[0]) {
            case 'A 1.1':
            case 'B 1.1':
                loadInstance |= HandrailLoadInstances.Dead;
                break;

            case 'A 2.1':
            case 'A 2.2':
            case 'B 2.1':
            case 'B 2.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Vertical;
                break;

            case 'A 3.1':
            case 'A 3.2':
            case 'B 3.1':
            case 'B 3.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                break;

            case 'A 4.1':
            case 'A 4.2':
            case 'B 4.1':
            case 'B 4.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Wind;
                break;

            case 'A 5.1.1':
            case 'A 5.2.1':
            case 'B 5.1':
            case 'B 5.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                loadInstance |= HandrailLoadInstances.Vertical;
                break;

            case 'A 5.1.2':
            case 'A 5.2.2':
            case 'B 6.1.1':
            case 'B 6.1.2':
            case 'B 6.2.1':
            case 'B 6.2.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                loadInstance |= HandrailLoadInstances.Wind;
                break;

            case 'A 6.1':
            case 'A 6.2':
            case 'B 7.1.1':
            case 'B 7.1.2':
            case 'B 7.1.3':
            case 'B 7.2.1':
            case 'B 7.2.2':
            case 'B 7.2.3':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                loadInstance |= HandrailLoadInstances.Vertical;
                loadInstance |= HandrailLoadInstances.Wind;
                break;

            default:
                throw new Error('Invalid load id.');
        }
        return loadInstance;
    }

    private static calculateLoadInstanceAustralia(split: string[], loadInstance: HandrailLoadInstances) {
        switch (split[0]) {
            case '1.1':
                loadInstance |= HandrailLoadInstances.Dead;

                break;
            case '2.1':
            case '3.1':
            case '3.2':

                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;

                break;
            case '2.2':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Vertical;

                break;
            case '2.3':
            case '2.4':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Horizontal;
                loadInstance |= HandrailLoadInstances.Vertical;

                break;
            case '4.1':
                loadInstance |= HandrailLoadInstances.Dead;
                loadInstance |= HandrailLoadInstances.Wind;

                break;
            default:
                throw new Error('Invalid load id.');
        }
        return loadInstance;
    }
}
