<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.DeflectionLimits.Title')">
</pe-modal-header>

<pe-modal-body>
    <table class="table-deflection-limits">
        <thead>
            <tr class="table-deflection-limits-row-header">
                <th colspan="4" [innerHtml]="translate('Agito.Hilti.Profis3.DeflectionLimits.Table.Title')"></tr>
        </thead>
        <tbody>
            <tr class="table-deflection-limits-row">
                <td [innerHtml]="translate('Agito.Hilti.Profis3.DeflectionLimits.Table.Requirements')"></td>
                <td [innerHtml]="translate('Agito.Hilti.Profis3.DeflectionLimits.Table.NotObserved')"></td>
                <td [innerHtml]="translate('Agito.Hilti.Profis3.DeflectionLimits.Table.NotDisturbing')"></td>
                <td [innerHtml]="translate('Agito.Hilti.Profis3.DeflectionLimits.Table.NoFeelingOfRisk')"></td>
            </tr>
            <tr class="table-deflection-limits-row">
                <td [innerHtml]="translate('Agito.Hilti.Profis3.DeflectionLimits.Table.SmoothSurface')"></td>
                <td>L/250</td>
                <td>L/200</td>
                <td>L/150</td>
            </tr>
            <tr class="table-deflection-limits-row">
                <td [innerHtml]="translate('Agito.Hilti.Profis3.DeflectionLimits.Table.RoughSurface')"></td>
                <td>L/300</td>
                <td>L/250</td>
                <td>L/200</td>
            </tr>
        </tbody>
    </table>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
