import { FireDurationEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class FireDuration extends CodeList {

    public static fromService(codeList: FireDurationEntity) {
        return new FireDuration(CodeList.baseFromService('FireExposureEntity', codeList));
    }

    public override cloneDeep(): FireDuration {
        return super.cloneDeep() as FireDuration;
    }
}
