import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { BrickLayoutEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class BrickLayout extends CodeList {

    public static fromService(codeList: BrickLayoutEntity) {
        return new BrickLayout(CodeList.baseFromService<ICodeListConstructor>('BrickLayoutEntity', codeList, {
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-masonry-' + codeList.Image : 'sprite-masonry-no-photo-available'
        } as ICodeListConstructor));
    }

    public override cloneDeep(): BrickLayout {
        return super.cloneDeep() as BrickLayout;
    }
}
