import cloneDeep from 'lodash-es/cloneDeep';

import { Injectable } from '@angular/core';
import { Design as DesignCommon, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DesignExternalMetaData as DesignExternalMetaDataCommon, DocumentAccessMode, DocumentServiceInjected,
    IDefferedData as IDefferedDataCommon, IDesignListItem as IDesignListItemCommon
} from '@profis-engineering/pe-ui-common/services/document.common';
import { DesignPe } from '../../shared/entities/design-pe';
import {
    detailedDisplayDesignToProjectDesign, IDetailedDisplayDesign
} from '../../shared/entities/display-design';
import { UIProperty } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    ProjectDesignBaseEntity
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { DesignExternalMetaDataPe, getMetaDataFromDesign } from '../entities/design-external-metadata';

export interface LocalDocument extends IBaseDesign {
    projectDesign: ProjectDesignBaseEntity;
}

export interface LocalDesignListItemCommon extends IDesignListItemCommon {
    projectDesign: ProjectDesignBaseEntity;
}

export type IDefferedDataInternal = IDefferedDataCommon<ProjectDesignBaseEntity, IDetailedDisplayDesign>;

/**
 * Document service base - PE
 */
export abstract class DocumentServicePeBase extends DocumentServiceInjected {
    public abstract openDesignPe(design: IBaseDesign): Promise<ProjectDesignBaseEntity>;
    public abstract openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity>;
    public abstract addDesignPe(projectId: string, design: DesignPe, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItemCommon>;
    public abstract setDesignPropertiesPe(design: DesignPe, documentDesign: IDesignListItemCommon): void;
    public abstract updateDesignWithNewContentPe(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignBaseEntity,
        metadataOverride: DesignExternalMetaDataPe,
        displayDesign: IDetailedDisplayDesign,
        unlock?: boolean,
        exclusiveLock?: boolean,
        documentAccessMode?: DocumentAccessMode
    ): Promise<void>;
}

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends DocumentServicePeBase {
    public override setBaseService(baseService: DocumentServicePeBase) {
        super.setBaseService(baseService);
    }


    public async openDesignPe(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return await this.openDesignCommon<ProjectDesignBaseEntity>(
            design,
            (design: IBaseDesign) => (design as LocalDocument).projectDesign
        );
    }

    /**
     * Takes a basic design file IBaseDesign and returns JSON object of the content.
     * @param design - Design
     */
    public async openDesignExclusivePe(design: IBaseDesign): Promise<ProjectDesignBaseEntity> {
        return await this.openDesignExclusiveBase<ProjectDesignBaseEntity>(
            design,
            (content, designName, projectName) => {
                content.DesignName = designName;
                content.ProjectName = projectName;
                return content;
            },
            (design: IBaseDesign) => (design as LocalDocument).projectDesign
        );
    }

    public async addDesignPe(projectId: string, design: DesignPe, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItemCommon> {
        return await this.addDesignBase({
            projectId,

            designType: design.designTypeId,
            design,

            canGenerateUniqueName,
            ignoreConflict,

            setProjectDesign: (newDesign: IDesignListItemCommon, designCommon: DesignCommon) => {
                (newDesign as IDesignListItemCommon & LocalDocument).projectDesign = (designCommon as DesignPe).designData.projectDesign;
            },
            adjustDesignListItemContents: (newDesign: IDesignListItemCommon, designCommon: DesignCommon, project: Project) => {
                newDesign.integrationDocument = designCommon.integrationDocument;
                newDesign.projectName = project.name ?? '';
                newDesign.isSharedByMe = project.isSharedByMe;

                this.setDesignPropertiesPe(design, newDesign);
            },
            getDesignMetadata: (_designType: number, design: object) => {
                return getMetaDataFromDesign(design as DesignPe);
            },
            getDesignObject: (designCommon: DesignCommon) => {
                const design = designCommon as DesignPe;
                const data = {...design.designData.projectDesign} as any;
                delete data.DesignName;
                delete data.ProjectName;
                return data;
            }
        });
    }

    public setDesignPropertiesPe(design: DesignPe, documentDesign: IDesignListItemCommon) {
        design.projectName = documentDesign.projectName;

        // change project design name if it was changed by document service
        if (design.designData.projectDesign.DesignName != documentDesign.designName) {
            design.designData.projectDesign = {
                ...design.designData.projectDesign,
                DesignName: documentDesign.designName
            };

            if (design.currentState != null) {
                design.currentState.projectDesign = design.designData.projectDesign;
                design.currentState.model[UIProperty.Option_DesignName] = documentDesign.designName;
            }

            design.model[UIProperty.Option_DesignName] = documentDesign.designName;
        }
    }

    public async updateDesignWithNewContentPe(
        designId: string,
        projectId: string,
        designName: string,
        contentOverride: ProjectDesignBaseEntity,
        metadataOverride: DesignExternalMetaDataPe,
        displayDesign: IDetailedDisplayDesign,
        unlock = false,
        exclusiveLock = false,
        documentAccessMode = DocumentAccessMode.Update
    ): Promise<void> {
        const defferedData: IDefferedDataInternal = {
            designId,
            projectId,
            designName,
            contentOverride,
            metadataOverride,
            unlock,
            displayDesign,
            exclusiveLock,
            documentAccessMode
        };

        await this.updateDesignWithNewContentBase({
            designId,

            projectId,
            designName,
            metadata: metadataOverride,
            contentOverride,
            displayDesign,
            defferedData,

            updateFn: async (projectDesign: ProjectDesignBaseEntity, internalDesign: IDesignListItemCommon, metadata: DesignExternalMetaDataCommon, displayDesign?: IDetailedDisplayDesign) => {
                if (displayDesign != null) {
                    detailedDisplayDesignToProjectDesign(projectDesign, displayDesign);
                }

                const design = this.findDesignById(designId);
                (design as LocalDesignListItemCommon).projectDesign = projectDesign;


                if (metadata != null) {
                    // update meta-data in proxy store
                    internalDesign.metaData.productName = metadata.productName;
                    internalDesign.metaData.designType = metadata.designType;
                    internalDesign.metaData.name = metadata.name;
                    internalDesign.metaData.region = metadata.region;
                    internalDesign.metaData.standard = metadata.standard;
                    internalDesign.metaData.designMethod = metadata.designMethod;
                    internalDesign.metaData.approvalNumber = metadata.approvalNumber;

                    const mdSrc = metadata as DesignExternalMetaDataPe;
                    const mdTrgt = internalDesign.metaData as DesignExternalMetaDataPe;
                    mdTrgt.calculationType = mdSrc.calculationType;
                    mdTrgt.handrailConnectionType = mdSrc.handrailConnectionType;
                }
            },
            putSmallDesignChangePromiseFn: (data: IDefferedDataInternal) => {
                return this.putSmallDesignChangePromisePe(data);
            }
        });
    }


    private async smallDesignChangePe(
        internalDesign: IDesignListItemCommon,
        projectDesign: ProjectDesignBaseEntity,
        data: IDefferedDataInternal,
        metaData: DesignExternalMetaDataCommon
    ): Promise<void> {
        await this.smallDesignChangeBase({
            internalDesign,
            projectDesign,
            data,
            metaData,

            getProjectDesign: (projectDesign: ProjectDesignBaseEntity) => projectDesign,
            setDesignListItemMetaData: (internalDesign: IDesignListItemCommon, metaData: DesignExternalMetaDataCommon) => {
                internalDesign.metaData.productName = metaData.productName;
                internalDesign.metaData.designType = metaData.designType;
                internalDesign.metaData.name = metaData.name;
                internalDesign.metaData.region = metaData.region;
                internalDesign.metaData.standard = metaData.standard;
                internalDesign.metaData.designMethod = metaData.designMethod;
                internalDesign.metaData.approvalNumber = metaData.approvalNumber;

                const mdSrc = metaData as DesignExternalMetaDataPe;
                const mdTrgt = internalDesign.metaData as DesignExternalMetaDataPe;
                mdTrgt.calculationType = mdSrc.calculationType;
                mdTrgt.handrailConnectionType = mdSrc.handrailConnectionType;
            },
            putSmallDesignChangePromise: (data: IDefferedDataInternal) => {
                return this.putSmallDesignChangePromisePe(this.defferedRequests[data.designId].defferedData as IDefferedDataInternal);
            }
        });
    }

    private async putSmallDesignChangePromisePe(data: IDefferedDataInternal): Promise<void> {
        // make new meta-data entity
        const internalDesign = this.findDesignById(data.designId);

        const projectDesign = cloneDeep(data.contentOverride);
        delete (projectDesign as any).DesignName;
        delete (projectDesign as any).ProjectName;

        const metaData = cloneDeep(data.metadataOverride);
        if (data.displayDesign != null) {
            detailedDisplayDesignToProjectDesign(projectDesign, data.displayDesign);
            metaData.designMethod = data.displayDesign.designMethodGroup?.id;
        }

        await this.smallDesignChangePe(internalDesign, projectDesign, data, metaData);
    }
}
