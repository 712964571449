import {
    PlateShapeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';

import { CodeList } from '../code-lists/code-list';

export class PlateShape extends CodeList {

    public static fromService(codeList: PlateShapeEntity) {
        return new PlateShape(CodeList.baseFromService('PlateShapeEntity', codeList));
    }

    public override cloneDeep(): PlateShape {
        return super.cloneDeep() as PlateShape;
    }
}
