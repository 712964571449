<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.SeismicFillingSetPopup.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="seismic-filling-set-popup">
        <div class="left">
            <p [innerHtml]="translate('Agito.Hilti.Profis3.SeismicFillingSetPopup.InfoMessage')"></p>
        </div>

        <div class="right">
            <img [src]="hiltiSeismicFillingSet" alt="hilti-seismic-filling-set">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
