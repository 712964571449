import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IDesignState } from '../../../shared/entities/design-pe';
import { CustomBeams } from '../../components/main-menu/custom-beams/CustomBeams';
import { ICustomBeamsProps } from '../../components/main-menu/custom-beams/custom-beams-props';

export function createCustomBeams(controlProps: ICustomBeamsProps, propertyValue: any[]) {
    controlProps.type = CustomBeams;
    if(propertyValue.length == 0){
        return null;
    }

    controlProps.beams = propertyValue;

    const onStateChanged = (state: IDesignState, menu: IMenu): Partial<ICustomBeamsProps> => {
        const control: ICustomBeamsProps = menu.controls[controlProps.controlId];

        return control;
    };

    return {
        controlProps,
        onStateChanged: onStateChanged as (state: IDesignState, menu: IMenu) => ICustomBeamsProps
    };
}