import {
    AnchorFilter as AnchorFilterEntity
} from '../entities/code-lists/anchor-filter';
import { AnchorFamily } from '../entities/code-lists/anchor-family';
import { AnchorFilterGroup } from '../entities/code-lists/anchor-filter-group';
import { DesignPe } from '../entities/design-pe';
import { AnchorFilter } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { PropertyMetaData } from '../properties/properties';
import { DesignType } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export enum SelectAnchorIcon {
    noCleaningRequired,
    automaticCleaningApproved,
    crackedConcreteAllowed,
    fatigueAllowed,
    shock,
    seismicAllowed,
    smallEdgeDistAndSpacing,
    variableEmbedment,
    nppApprovalDiBt,
    fireAllowed,
    cleanTech,
    stainlessSteelOrHCRAvailable,
    shallowEmbedmentDepth,
    diamondCoredHolesSuitable
}

export interface IAnchorIcon {
    readonly image: string;
    readonly tooltip: SelectAnchorIcon;
    readonly visibleExpression: string;
}

export const anchorIcons: ReadonlyArray<IAnchorIcon> = [
    {
        image: 'pe-ui-pe-sprite-anchor-no-cleaning-required',
        tooltip: SelectAnchorIcon.noCleaningRequired,
        visibleExpression: 'isNoCleaningRequired'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-approved-automatic-cleaning',
        tooltip: SelectAnchorIcon.automaticCleaningApproved,
        visibleExpression: 'isAutomaticCleaningApproved'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-tensile-zone-cracked-concrete',
        tooltip: SelectAnchorIcon.crackedConcreteAllowed,
        visibleExpression: 'isCrackedConcreteAllowed'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-fatigue',
        tooltip: SelectAnchorIcon.fatigueAllowed,
        visibleExpression: 'isFatigueAllowed'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-shock',
        tooltip: SelectAnchorIcon.shock,
        visibleExpression: 'isShock'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-seismic',
        tooltip: SelectAnchorIcon.seismicAllowed,
        visibleExpression: 'isSeismicAllowed'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-small-edge-dist-and-spacing',
        tooltip: SelectAnchorIcon.smallEdgeDistAndSpacing,
        visibleExpression: 'isSmallEdgeDistAndSpacing'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-variable-embedment-depth',
        tooltip: SelectAnchorIcon.variableEmbedment,
        visibleExpression: 'isVariableEmbedment'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-npp-approval-di-bt',
        tooltip: SelectAnchorIcon.nppApprovalDiBt,
        visibleExpression: 'isNPPApprovalDiBt'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-fire-resistant',
        tooltip: SelectAnchorIcon.fireAllowed,
        visibleExpression: 'isFireAllowed'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-clean-tech',
        tooltip: SelectAnchorIcon.cleanTech,
        visibleExpression: 'isCleanTech'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-stainless-steel-hcr',
        tooltip: SelectAnchorIcon.stainlessSteelOrHCRAvailable,
        visibleExpression: 'isStainlessSteelOrHCRAvailable'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-shallow-embedment-depth',
        tooltip: SelectAnchorIcon.shallowEmbedmentDepth,
        visibleExpression: 'isShallowEmbedmentDepth'
    },
    {
        image: 'pe-ui-pe-sprite-anchor-diamond-cored-holes-suitable',
        tooltip: SelectAnchorIcon.diamondCoredHolesSuitable,
        visibleExpression: 'isDiamondCoredHolesSuitable'
    }
];

export function isAnchorTypeMarkedAsNew(anchorTypeId: number, design: DesignPe) {
    return design.designData.anchorsMarkedAsNew?.includes(anchorTypeId);
}

export function isAnchorFamilyMarkedAsNew(anchorFamilyId: number, design: DesignPe) {
    // Family should be marked as new if any anchor in family is marked as new.
    const allFamilies = design.designData.designCodeLists[design.globalMetaProperties.productCodeListProperty] as AnchorFamily[];
    const family = allFamilies.find(f => f.id == anchorFamilyId && f.detailed != null);
    return family?.detailed?.filteredFasteners?.some(typeId => isAnchorTypeMarkedAsNew(typeId, design));
}

// We use any[] because we have different anchors interfaces but use only id which is in common to all of them
export function updateAnchorFamilySortOrder(anchors: any[], design: DesignPe) {
    const allowedValues = design.properties.get(PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id).allowedValues;
    const disabledValues = design.properties.get(PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id).disabledValues;

    const availableSuggestedAnchors = allowedValues?.filter((item) => {
        return disabledValues?.indexOf(item) == -1;
    });

    if (availableSuggestedAnchors) {
        for (let i = availableSuggestedAnchors.length; i >= 0; i--) {
            anchors.forEach((item, index) => {
                if (item.id == availableSuggestedAnchors[i]) {
                    anchors.splice(index, 1);
                    anchors.unshift(item);
                }
            });
        }
    }

    return anchors;
}

export function getAnchorIconTooltipTranslationKey(icon: SelectAnchorIcon, isHNABased: boolean) {
    switch (icon) {
        case SelectAnchorIcon.noCleaningRequired:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.NoCleaningRequired';

        case SelectAnchorIcon.automaticCleaningApproved:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.ApprovedAutomaticCleaning';

        case SelectAnchorIcon.crackedConcreteAllowed:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.TensileZoneCrackedConcrete';

        case SelectAnchorIcon.fatigueAllowed:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.Fatigue';

        case SelectAnchorIcon.shock:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.Shock';

        case SelectAnchorIcon.seismicAllowed:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.Seismic';

        case SelectAnchorIcon.smallEdgeDistAndSpacing:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.SmallEdgeDistAndSpacing';

        case SelectAnchorIcon.variableEmbedment:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.VariableEmbedmentDepth';

        case SelectAnchorIcon.nppApprovalDiBt:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.NPPApprovalDiBt' + (isHNABased ? '.ACI.CSA' : '');

        case SelectAnchorIcon.fireAllowed:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.FireResistant';

        case SelectAnchorIcon.cleanTech:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.CleanTech';

        case SelectAnchorIcon.stainlessSteelOrHCRAvailable:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.StainlessSteelHCR' + (isHNABased ? '.ACI.CSA' : '');

        case SelectAnchorIcon.shallowEmbedmentDepth:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.ShallowEmbedmentDepth';

        case SelectAnchorIcon.diamondCoredHolesSuitable:
            return 'Agito.Hilti.Profis3.SelectAnchor.AnchorImage.DiamondCoredHolesSuitable';

        default:
            throw new Error('Unknown icon.');
    }
}

export function filterAnchorFilters(anchorFilters: AnchorFilterEntity[], savedAnchorFilters: AnchorFilter) {
    return anchorFilters.filter(anchorFilter => !savedAnchorFilters.CheckboxFiltersRemoved.some(removed => removed.Id == anchorFilter.id));
}

function filterAnchorFilterGroup (anchorFilterGroup: AnchorFilterGroup, anchorFilters: AnchorFilterEntity[], designTypeId: DesignType): boolean {
    // exception for DesignBasis anchorFilterGroup BUDQBP-35508
    if (designTypeId==DesignType.Masonry && anchorFilterGroup.id == 9) {
        return true;
    }
    return anchorFilters.some((anchorFilter) => anchorFilter.anchorFilterGroupId == anchorFilterGroup.id);
}

export function filterAnchorFilterGroups(anchorFilterGroups: AnchorFilterGroup[], anchorFilters: AnchorFilterEntity[], designTypeId: DesignType) {
    return anchorFilterGroups.filter((anchorFilterGroup) => filterAnchorFilterGroup(anchorFilterGroup, anchorFilters, designTypeId));
}
