import {
    ChangeDetectorRef,
    Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, Output, ViewEncapsulation
} from '@angular/core';
import { CanvasContext3d } from '@profis-engineering/gl-model/canvas-context-3d';
import { RenderType } from '@profis-engineering/gl-model/gl-model';
import { getOrCreateContext3d } from '@profis-engineering/pe-gl-model/context-3d';

import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { MathService } from '../../services/math.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { TooltipService } from '../../services/tooltip.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';

import { Update } from '@profis-engineering/gl-model/base-update';
import { GLModelAbp, GLModelAbpUpdate } from '@profis-engineering/pe-gl-model/gl-model-abp';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';
import { IBaseplateDesignGLModelComponent } from '../../../shared/components/gl-model';
import { DesignPe } from '../../../shared/entities/design-pe';
import { BaseplateDesignDataEntity } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import { GLEvent, GLModelBaseComponent } from '../gl-model/GLModelBase';
import { AnchorUpdate } from '../gl-model/Update/AnchorUpdate';
import { HandrailBaseMaterialUpdate } from '../gl-model/Update/HandrailBaseMaterialUpdate';
import { HandrailUpdate } from '../gl-model/Update/HandrailUpdate';
import { PlateUpdate } from '../gl-model/Update/PlateUpdate';
import { ProfileUpdate } from '../gl-model/Update/ProfileUpdate';
import { StiffenerUpdate } from '../gl-model/Update/StiffenerUpdate';
import { WeldsUpdate } from '../gl-model/Update/WeldsUpdate';

import { GLImages } from '@profis-engineering/gl-model/images';
import existingStructureConcrete from '@profis-engineering/gl-model/images/existing-concrete.jpg';
import fontTexture from '../../gl-model/font-texture';
import newStructureConcrete from '@profis-engineering/gl-model/images/new-concrete.jpg';
import reinforcement from '@profis-engineering/gl-model/images/rebar.jpg';
import { MaterialCachePe } from '@profis-engineering/pe-gl-model/cache/material-cache';
import { MeshCachePe } from '@profis-engineering/pe-gl-model/cache/mesh-cache';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import { SharedEnvironmentService } from '../../services/shared-environment.service';

@Component({
    templateUrl: './baseplate-design-gl-model.component.html',
    styleUrls: ['./baseplate-design-gl-model.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BaseplateDesignGlModelComponent extends GLModelBaseComponent<GLModelAbp> implements OnChanges, IBaseplateDesignGLModelComponent {

    @Input()
    public resetCamera = () => this.resetCameraInternal();
    @Input()
    public update = (model: IModelPe, replace?: boolean, changeDetection?: boolean) => this.updateInternal(model, replace, changeDetection);
    @Input()
    public updateBaseplateData = (baseplateDesignData: BaseplateDesignDataEntity) => this.updateBaseplateDataInternal(baseplateDesignData);
    @Input()
    public propertyValueChanged = (changes: Change[], design: DesignPe, update: Update, model?: IModelPe) => this.propertyValueChangedInternal(changes, design, update, model);
    @Input()
    public zoomToFit = () => this.zoomToFitInternal();
    @Input()
    public renderNextFrame = (count?: number) => this.renderNextFrameInternal(count);
    @Input()
    public resize = () => this.resizeInternal();

    @Output()
    public loaded = new EventEmitter<void>();

    @Input()
    public isHidden = true;

    constructor(
        unitService: UnitService,
        numberService: NumberService,
        localizationService: LocalizationService,
        userService: UserService,
        mathService: MathService,
        tooltipService: TooltipService,
        modalService: ModalService,
        userSettingsService: UserSettingsService,
        calculationServicePE: CalculationServicePE,
        changeDetection: ChangeDetectorRef,
        ngZone: NgZone,
        sharedEnvironmentService: SharedEnvironmentService,
        private elementRef: ElementRef<HTMLElement>
    ) {
        super(
            unitService,
            numberService,
            localizationService,
            userService,
            mathService,
            tooltipService,
            modalService,
            userSettingsService,
            calculationServicePE,
            changeDetection,
            ngZone,
            sharedEnvironmentService
        );
    }

    public override ngOnChanges(): void {
        super.ngOnChanges();

        if (this.model != null && this.isHidden != (this.model.hidden ?? true)) {
            this.updateInternal({ hidden: this.isHidden });
        }
    }

    public zoomToFitInternal() {
        this.glModel3d.zoomToFit();
    }

    protected createGLModelBase3D(): GLModelAbp {
        const modelUpdate: GLModelAbpUpdate = {
            anchorCtor: AnchorUpdate,
            plateCtor: PlateUpdate,
            profileCtor: ProfileUpdate,
            weldsCtor: WeldsUpdate,
            stiffenerCtor: StiffenerUpdate,
            handrailCtor: HandrailUpdate,
            handrailBaseMaterialCtor: HandrailBaseMaterialUpdate
        };

        const images: GLImages = {
            existingStructureConcrete,
            newStructureConcrete,
            reinforcement,
            fontTexture
        };

        const context3d = getOrCreateContext3d(this.context3dKey, () => new CanvasContext3d(this.context3dKey, MaterialCachePe, MeshCachePe));
        context3d.parentContainer = this.elementRef.nativeElement;

        return this.ngZone.runOutsideAngular(() => new GLModelAbp({
            showDebugLayer: false,
            context3d,
            images,
            renderType: this.continuousRender ? RenderType.Continuous : RenderType.Auto,
            unitConverter: this.glUnitConverter,
            propertyInfo: this.glPropertyInfo,
            mathCalculator: this.glMathCalculator,
            tooltip: this.glTooltip,
            eventNotifier: this.glEventNotifier,
            imageMeshProvider: this.glImageMeshProvider,
            inputSettings: this.glInputSettings,
            model: this.model,
            modelUpdate
        }));
    }

    protected override onEvent(event: GLEvent) {
        NgZone.assertInAngularZone();

        if (event == 'Fonts_Loaded') {
            this.loaded.next();
        }
    }
}

