import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { BrickSizeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IBrickSizeConstructor extends ICodeListConstructor {
    height?: number;
    width?: number;
    thickness?: number;
}

export class BrickSize extends CodeList {
    public height?: number;
    public width?: number;
    public thickness?: number;

    constructor(codelist: IBrickSizeConstructor) {
        super(codelist);

        if (codelist != null) {
            this.width = codelist.width;
            this.height = codelist.height;
            this.thickness = codelist.thickness;
        }
    }

    public static fromService(codelist: BrickSizeEntity) {
        return new BrickSize(CodeList.baseFromService<IBrickSizeConstructor>('BrickSize', codelist, {
            width: codelist.Width,
            height: codelist.Height,
            thickness: codelist.Thickness
        } as IBrickSizeConstructor));
    }

    public override cloneDeep(): BrickSize {
        const codelist = super.cloneDeep() as BrickSize;

        codelist.width = this.width;
        codelist.height = this.height;
        codelist.thickness = this.thickness;

        return codelist;
    }
}
