<pe-modal-header
    [modalInstance]="modalInstance"
    [enableClose]="false"
    [title]="translate('Agito.Hilti.Profis3.RamImportLoads.Title')">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" novalidate>
    <pe-modal-body>
        <div class="modal-body-content">
            <div class="box">
                <div class="loads-to-import">
                    <pe-checkbox-button
                        [items]="availableLoadsCheckbox.items"
                        [selectedValues]="availableLoadsCheckbox.selectedValues"
                        (selectedValuesChange)="availableLoadsCheckbox.selectedValues=$any($event).detail; onSelectedLoadsChange()"
                        [disabled]="submitted">
                    </pe-checkbox-button>
                </div>
            </div>

            <div class="box">
                <div class="info-text-container" *ngIf="showInfoMessages">
                    <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.RamImportLoads.InfoText1')"></span>
                    <br>
                    <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.RamImportLoads.InfoText2')"></span>
                    <br>
                    <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.RamImportLoads.InfoText3')"></span>
                </div>
            </div>
        </div>
    </pe-modal-body>
</form>

<pe-modal-footer>
    <pe-button
        id="ram-import-button"
        class="modal-button"
        [look]="'Cta'"
        (buttonClick)="import()">
        <pe-loading-button
            [loading]="submitted"
            [text]="translate('Agito.Hilti.Profis3.RamImportLoads.Import')">
        </pe-loading-button>
    </pe-button>
</pe-modal-footer>
