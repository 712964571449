import { Injectable } from '@angular/core';
import {
    SignalRServicePeBase
} from '../../shared/services/signalr.service.base';

@Injectable({
  providedIn: 'root'
})
export class SignalRService extends SignalRServicePeBase {
    private baseService!: SignalRServicePeBase;

    public get common() {
        return this.baseService.common;
    }

    public get asad() {
        return this.baseService.asad;
    }

    public setBaseService(baseService: SignalRServicePeBase) {
        this.baseService = baseService;
    }
}
