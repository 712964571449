import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { HandrailApplicationTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    HandrailApplicationType as HandrailApplicationTypeEnum, HandrailFasteningType
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from '../code-lists/code-list';

export interface IHandrailApplicationTypeConstructor extends ICodeListConstructor {
    handrailFasteningType: HandrailFasteningType;
    handrailApplicationType: HandrailApplicationTypeEnum;
    disabled: boolean;
}

export class HandrailApplicationType extends CodeList {
    public handrailFasteningType!: HandrailFasteningType;
    public handrailApplicationType!: HandrailApplicationTypeEnum;
    public disabled!: boolean;

    constructor(codeList: IHandrailApplicationTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.handrailFasteningType = codeList.handrailFasteningType;
            this.handrailApplicationType = codeList.handrailApplicationType;
            this.disabled = codeList.disabled;
        }
    }

    public static fromService(codeList: HandrailApplicationTypeEntity) {
        const baseObject = CodeList.baseFromService<IHandrailApplicationTypeConstructor>(
            'HandrailApplicationTypeEntity',
            codeList,
            {
                handrailFasteningType: codeList.HandrailFasteningType,
                handrailApplicationType: codeList.HandrailApplicationType,
                disabled: codeList.Disabled
            } as IHandrailApplicationTypeConstructor
        );

        if (codeList.Disabled) {
            baseObject.nameResourceKey = 'Agito.Hilti.Profis3.CodeList.HandrailApplicationTypeEntity.NotAvailible';
        }

        return new HandrailApplicationType(baseObject);
    }

    public override cloneDeep(): HandrailApplicationType {
        const codeList: HandrailApplicationType = super.cloneDeep() as HandrailApplicationType;

        codeList.handrailFasteningType = this.handrailFasteningType;
        codeList.handrailApplicationType = this.handrailApplicationType;
        codeList.disabled = this.disabled;

        return codeList;
    }
}
