import { HttpRequest } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownItem,
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    CloseButtonStyle
} from '@profis-engineering/pe-ui-common/components/modal-header/modal-header.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { RadioButtonItem } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import { CodeList, getCodeListTextDeps, ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { KnownRegion } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    ModalInstance
} from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { SimpleCheckboxButtonHelper } from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { environment } from '../../../environments/environmentPe';
import hrBridgeImage from '../../../images/handrail-dimensions/hr-bridge.png';
import hrBuildingImage from '../../../images/handrail-dimensions/hr-building.png';
import hrFreestandingwallImage from '../../../images/handrail-dimensions/hr-freestandingwall.png';
import installationZoneImages from '../../../images/installation-zone';
import terrainTypeImages from '../../../images/terrain-type';
import windZoneImages from '../../../images/wind-zone';
import { DistanceInsideTownTerrain } from '../../../shared/entities/code-lists/distance-inside-town-terrain';
import { DistanceUpwindToShoreline } from '../../../shared/entities/code-lists/distance-upwind-to-shoreline';
import { TerrainCategory } from '../../../shared/entities/code-lists/terrain-category';
import { TerrainRoughness } from '../../../shared/entities/code-lists/terrain-roughness';
import {
    TerrainType
} from '../../../shared/entities/code-lists/terrain-type';
import {
    WindLoadCity as City
} from '../../../shared/entities/code-lists/wind-load-city';
import {
    WindLoadState as State
} from '../../../shared/entities/code-lists/wind-load-state';
import { WindVelocity } from '../../../shared/entities/code-lists/wind-velocity';
import { WindZone } from '../../../shared/entities/code-lists/wind-zone';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe } from '../../../shared/entities/design-pe';
import { ZipCodeHandrailLoadsEntity } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    PropertyMetaData
} from '../../../shared/properties/properties';
import { ApiService } from '../../services/api.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import {
    LocalizationService
} from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

enum EnumTerrainType {
    DownwindHill = 4
}

enum EnumDisplayType {
    Default = 0,
    Orography = 1,
    Environment = 2,
    InstallationZone = 3,
    Dimensions = 4
}

@Component({
    templateUrl: './windloads.component.html',
    styleUrls: ['./windloads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class WindloadsComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public activeScreen = EnumDisplayType.Default;
    public submitted = false;
    public zipCodeLoading = false;

    public propertyMetaData = PropertyMetaData;
    public displayTypeEnum = EnumDisplayType;
    public requiredValidator = Validators.required;

    public windZoneImageSourceExists = false;
    public windZoneImageSourceTranslation = '';

    public imageSourceTranslation = '';
    public terrainTypeImage?: string;
    public terrainTypeImageText?: string;

    public isInputLdHidden = false;
    public terrainTypeDropdown!: DropdownProps<TerrainType>;
    public inputHTextBox!: NumericTextBoxProps;
    public inputXTextBox!: NumericTextBoxProps;
    public inputLuTextBox!: NumericTextBoxProps;
    public inputLdTextBox!: NumericTextBoxProps;
    public isOrographyNotRelevantCheckbox!: CheckboxButtonProps<boolean>;

    public isCountyAndCommune = false;
    public windZoneImage?: string;
    public zipCodeFilterTextBox!: TextBoxProps;
    public zipCodeCityDropdown!: DropdownProps<ZipCodeHandrailLoadsEntity>;
    public windZoneDropdown!: DropdownProps<WindZone>;
    public altitudeTextBox!: NumericTextBoxProps;
    public distanceToSeasideTextBox!: NumericTextBoxProps;
    public stateDropdown!: DropdownProps<State>;
    public cityDropdown!: DropdownProps<City>;
    public terrainCategoryDropdown!: DropdownProps<TerrainCategory>;
    public roughnessClassDropdown!: DropdownProps<TerrainRoughness>;
    public returnPeriodTextBox!: NumericTextBoxProps;
    public distanceUpwindToShorelineDropdown!: DropdownProps<DistanceUpwindToShoreline>;
    public distanceInsideTownTerrainDropdown!: DropdownProps<DistanceInsideTownTerrain>;
    public basicWindVelocityDropdown!: DropdownProps<WindVelocity>;
    public fundamentalWindVelocityTextBox!: NumericTextBoxProps;
    public referenceWindVelocityDropdown!: DropdownProps<WindVelocity>;
    public referenceVelocityPressureTextBox!: NumericTextBoxProps;
    public windPressureTextBox!: NumericTextBoxProps;
    public windUpliftTextBox!: NumericTextBoxProps;

    public isFreeStandingWallCheckbox!: CheckboxButtonProps<boolean>;
    public handrailLengthTextBox!: NumericTextBoxProps;
    public buildingWidthTextBox!: NumericTextBoxProps;
    public buildingHeightTextBox!: NumericTextBoxProps;
    public buildingDepthTextBox!: NumericTextBoxProps;
    public handrailHeightOverGroundTextBox!: NumericTextBoxProps;
    public handrailHeightTextBox!: NumericTextBoxProps;
    public buildingZoneGroupItems: RadioButtonItem<number>[] = [];
    public buildingZoneGroupSelected?: number;

    private pendingSave = false;
    private loadedZipCode?: string;


    constructor(
        public offline: OfflineService,
        private localization: LocalizationService,
        private user: UserService,
        private unit: UnitService,
        private numberService: NumberService,
        private apiService: ApiService,
        private modal: ModalService,
        private calculationService: CalculationServicePE,
        private elementRef: ElementRef<HTMLElement>,
        private changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            if (this.activeScreen != EnumDisplayType.Default) {
                return false;
            }

            return !this.pendingSave;
        });

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-open-image'
        );

        this.initControls();
        this.initImages();

        // Fill zip code data for first time
        if (!this.isPropertyHidden(PropertyMetaData.Application_ZipCodeHandrailLoadsId.id)) {
            const selectedItem = this.design?.designData?.reportData?.ZipCodeHandrailLoads?.find(item => item.Id == this.design.zipCodeHandrailLoadsId);

            this.zipCodeFilterTextBox.value = selectedItem?.ZipCode;
            this.loadedZipCode = selectedItem?.ZipCode;

            this.fillZipCodeCityDropdown(this.design?.designData?.reportData?.ZipCodeHandrailLoads ?? []);
            this.zipCodeCityDropdown.selectedValue = selectedItem;
        }

        this.buildingZoneGroupSelected = this.design.buildingZone != null ? this.design.buildingZone.id : undefined;

        this.adjustBuildingZones();
        this.adjustMinMaxHandrailHeightOverGround();
    }

    public get closeButtonId() {
        if (this.activeScreen != EnumDisplayType.Default) {
            return undefined;
        }

        return 'windloads-close-button';
    }

    public get closeButtonStyle() {
        if (this.activeScreen != EnumDisplayType.Default) {
            return CloseButtonStyle.CloseImage;
        }

        return CloseButtonStyle.Close;
    }

    public get design() {
        return this.user.design;
    }

    public get isBridgeCategory() {
        return this.design.isBridge;
    }

    public get isBuildingCategory() {
        return !this.design.isBridge && !this.isSimpleCheckboxChecked(this.isFreeStandingWallCheckbox);
    }

    public get isFreeStandingWallCategory() {
        return !this.design.isBridge && this.isSimpleCheckboxChecked(this.isFreeStandingWallCheckbox);
    }

    public get installationZoneImage() {
        if (this.isBuildingCategory) {
            const windCode = this.determineWindCode();
            return installationZoneImages[`${windCode}.png`];
        }
        if (this.isBridgeCategory || this.isFreeStandingWallCategory) {
            const buildZoneKeys = Object.keys(this.determineZonesBridge());
            return installationZoneImages[`${buildZoneKeys.join('')}.png`];
        }

        return null;
    }

    public get installationZoneImageText() {
        return this.getImageName(this.installationZoneImage);
    }

    public get dimensionsImage() {
        if (this.isFreeStandingWallCategory) {
            return hrFreestandingwallImage;
        }
        if (this.isBridgeCategory) {
            return hrBridgeImage;
        }
        if (this.isBuildingCategory) {
            return hrBuildingImage;
        }

        return null;
    }

    public get dimensionsImageText() {
        return this.getImageName(this.dimensionsImage);
    }

    public get isBuildingDimensionsVisible() {
        return !this.isPropertyHidden(PropertyMetaData.Application_IsFreeStandingWall.id) ||
            !this.isPropertyHidden(PropertyMetaData.Application_BuildingWidth.id) ||
            !this.isPropertyHidden(PropertyMetaData.Application_BuildingHeight.id) ||
            !this.isPropertyHidden(PropertyMetaData.Application_BuildingDepth.id) ||
            !this.isPropertyHidden(PropertyMetaData.Application_HandrailLength.id) ||
            !this.isPropertyHidden(PropertyMetaData.Application_HandrailHeightOverGround.id);
    }

    public get isBuildingZoneVisible() {
        return !this.isPropertyHidden(PropertyMetaData.Application_BuildingZone.id);
    }

    public get buildingZoneTranslation() {
        return this.translate(this.isBuildingCategory ? 'Agito.Hilti.Profis3.Windloads.BuildingZone' : 'Agito.Hilti.Profis3.Windloads.HandrailZone');
    }

    public get isOrographyRelevant() {
        return !this.isSimpleCheckboxChecked(this.isOrographyNotRelevantCheckbox);
    }

    public get isDistanceUpwindToShorelineDisabled() {
        return this.terrainCategoryDropdown.selectedValue != null && this.terrainCategoryDropdown.selectedValue.isSea;
    }

    public get isDistanceInsideTownTerrainDisabled() {
        return this.terrainCategoryDropdown.selectedValue != null && !this.terrainCategoryDropdown.selectedValue.isTown;
    }

    public get handrailLengthTranslation() {
        return this.translate(this.isBridgeCategory ? 'Agito.Hilti.Profis3.Windloads.BridgeLength' : 'Agito.Hilti.Profis3.Windloads.HandrailLength');
    }

    public get formValid() {
        const validationFns: (() => boolean)[] = [];

        if (this.isOrographyRelevant) {
            validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_TerrainType.id) || this.isFieldValid(this.terrainTypeDropdown.isValid); });

            validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_H.id) || this.isFieldValid(this.inputHTextBox.isValid); });

            validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_X.id) || this.isFieldValid(this.inputXTextBox.isValid); });

            validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_Lu.id) || this.isFieldValid(this.inputLuTextBox.isValid); });

            validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_Ld.id) || this.isInputLdHidden || this.isFieldValid(this.inputLdTextBox.isValid); });
        }

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_ZipCodeHandrailLoadsId.id) || this.isFieldValid(this.zipCodeCityDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_WindZone.id) || (this.windZoneDropdown?.items?.length ?? 0) == 0 || this.isFieldValid(this.windZoneDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_Altitude.id) || this.isFieldValid(this.altitudeTextBox.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_DistanceToSeaside.id) || this.isFieldValid(this.distanceToSeasideTextBox.isValid); });

        if (!this.isPropertyHidden(PropertyMetaData.Application_CityAndStateId.id)) {
            validationFns.push(() => { return this.isFieldValid(this.stateDropdown.isValid); });

            validationFns.push(() => { return this.isFieldValid(this.cityDropdown.isValid); });
        }

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_TerrainCategoryId.id) || this.isFieldValid(this.terrainCategoryDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_RoughnessClassId.id) || this.isFieldValid(this.roughnessClassDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_ReturnPeriod.id) || this.isFieldValid(this.returnPeriodTextBox.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_DistanceUpwindToShorelineId.id) || this.isDistanceUpwindToShorelineDisabled || this.isFieldValid(this.distanceUpwindToShorelineDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_DistanceInsideTownTerrainId.id) || this.isDistanceInsideTownTerrainDisabled || this.isFieldValid(this.distanceInsideTownTerrainDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_BasicWindVelocityId.id) || this.isFieldValid(this.basicWindVelocityDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Loads_FundamentalWindVelocity.id) || this.isFieldValid(this.fundamentalWindVelocityTextBox.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_ReferenceWindVelocityId.id) || this.isFieldValid(this.referenceWindVelocityDropdown.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_WindVelocityPressure.id) || this.isFieldValid(this.referenceVelocityPressureTextBox.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_WindPressure.id) || this.isFieldValid(this.windPressureTextBox.isValid); });

        validationFns.push(() => { return this.isPropertyHidden(PropertyMetaData.Application_WindUplift.id) || this.isFieldValid(this.windUpliftTextBox.isValid); });

        if (this.isBuildingDimensionsVisible || this.isBuildingZoneVisible) {
            if (this.isFreeStandingWallCategory || this.isBridgeCategory) {
                validationFns.push(() => { return this.isFieldValid(this.handrailLengthTextBox.isValid); });

                validationFns.push(() => { return this.isFieldValid(this.handrailHeightTextBox.isValid); });
            }

            if (this.isBuildingCategory) {
                validationFns.push(() => { return this.isFieldValid(this.buildingWidthTextBox.isValid); });

                validationFns.push(() => { return this.isFieldValid(this.buildingHeightTextBox.isValid); });

                validationFns.push(() => { return this.isFieldValid(this.buildingDepthTextBox.isValid); });
            }

            validationFns.push(() => { return this.isFieldValid(this.handrailHeightOverGroundTextBox.isValid); });
        }

        for (const fn of validationFns) {
            if (!fn()) {
                return false;
            }
        }

        return true;
    }

    private get zipCodeId() {
        if (this.zipCodeCityDropdown.selectedValue == null) {
            return undefined;
        }

        return this.zipCodeCityDropdown.selectedValue.Id;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        if (this.activeScreen != EnumDisplayType.Default) {
            this.setActiveScreen(EnumDisplayType.Default);
            return;
        }

        this.modalInstance.close();
    }

    public setActiveScreen(screen: EnumDisplayType) {
        this.activeScreen = screen;
    }

    public openLink(url: string) {
        if (this.offline.isOffline) {
            if (url.startsWith('http')) {
                this.offline.nativeExternalLinkOpen(url);
            }
            return;
        }

        const tab = window.open(url, '_blank');
        if (tab != null && tab != undefined) {
            tab.focus();
        }
    }

    public onTerrainTypeChange() {
        this.terrainTypeImage = this.terrainTypeDropdown.selectedValue?.image?.toLowerCase() ?? '';
        if (this.terrainTypeImage) {
            this.terrainTypeImage = terrainTypeImages[`${this.terrainTypeImage}.png`];
            this.terrainTypeImageText = this.getImageName(this.terrainTypeImage);
        }

        this.isInputLdHidden = this.terrainTypeDropdown.selectedValue != null
            && this.terrainTypeDropdown.selectedValue != undefined
            && this.terrainTypeDropdown.selectedValue.id == EnumTerrainType.DownwindHill
            ? false
            : true;
    }

    public onIsOrographyNotRelevantChange(changesSelectedValues: Set<boolean>) {
        this.isOrographyNotRelevantCheckbox.selectedValues = changesSelectedValues;

        if (!changesSelectedValues.has(true)) {
            this.terrainTypeDropdown.selectedValue = this.design.designData.designCodeLists[DesignCodeList.TerrainType][0];
        }
        else {
            this.terrainTypeDropdown.selectedValue = undefined;
        }

        this.onTerrainTypeChange();
    }

    public onZipCodeFilterChanged() {
        const newValue = this.zipCodeFilterTextBox.value ?? '';

        if (this.design.region.zipCodeFormat != null) {
            const regex = new RegExp(this.design.region.zipCodeFormat);
            if (!regex.test(newValue)) {
                const validationData = { ...this.zipCodeFilterTextBox.validationData };
                validationData.customErrorMessage = this.translate('Agito.Hilti.Profis3.Windloads.ZipCodeFilter.InvalidZipCode');
                this.zipCodeFilterTextBox.validationData = validationData;
                this.fillZipCodeCityDropdown([]);
                return;
            }
        }

        this.zipCodeLoading = true;

        const url = `${environment.baseplateApplicationWebServiceUrl}GetZipCodeHandrailLoads?regionId=${this.design.region.id}&zipCode=${newValue}`;

        this.apiService.request<ZipCodeHandrailLoadsEntity[]>(new HttpRequest('GET', url))
            .then((response) => {
                this.loadedZipCode = newValue;

                const validationData = { ...this.zipCodeFilterTextBox.validationData };
                validationData.customErrorMessage = undefined;
                this.fillZipCodeCityDropdown(response.body ?? []);
                this.onZipCodeCityChanged(this.zipCodeCityDropdown.selectedValue);

                if (response?.body?.length == 0) {
                    validationData.customErrorMessage = this.translate('Agito.Hilti.Profis3.Windloads.ZipCodeFilter.NonexistentZipCode');
                } // no cities exists with this zip code
                this.zipCodeFilterTextBox.validationData = validationData;
            })
            .catch(() => {
                this.zipCodeFilterTextBox.value = this.loadedZipCode; // something went wrong, set zip code to existing one
            })
            .finally(() => {
                this.zipCodeLoading = false;
            });
    }

    public onZipCodeCityChanged(newValue?: ZipCodeHandrailLoadsEntity) {
        this.zipCodeCityDropdown.selectedValue = newValue;

        this.windZoneDropdown.selectedValue = newValue != null
            ? (this.windZoneDropdown.items as DropdownItem<WindZone>[])?.find(item => item.value.id == newValue.WindZoneId)?.value
            : undefined;
        this.altitudeTextBox.value = newValue != null
            ? newValue.Altitude
            : undefined;
    }

    public onStateDropdownSelectedValueChange() {
        // Filter-out cities when state is changed
        const filteredItems = (this.design.designData.designCodeLists[DesignCodeList.WindLoadCity] as City[])?.filter((item: City) => {
            return this.stateDropdown.selectedValue?.name === item.federalState;
        });

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        this.cityDropdown.items = filteredItems
            ?.map((item: City) => this.getCityDropdownItem(item, codeListDeps));

        if (this.cityDropdown != null && this.cityDropdown.items.length > 0) {
            this.cityDropdown.selectedValue = this.cityDropdown.items[0].value;
        }
    }

    // Adjust height over ground min max if free standing wall changed
    public onIsFreeStandingWallChange() {
        this.adjustMinMaxHandrailHeightOverGround();
        this.adjustBuildingZones();
    }

    public onBuildingWidthChange() {
        if (
            this.buildingWidthTextBox.value != null
            && this.buildingDepthTextBox.value != null
            && this.buildingWidthTextBox.value > this.buildingDepthTextBox.value
        ) {
            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();
            this.buildingWidthTextBox.value = this.buildingDepthTextBox.value;

            this.showBuildingDimensionsPopup();
        }

        this.onHandrailDimensionsChange();
    }

    public onBuildingDepthChange() {
        if (
            this.buildingDepthTextBox.value != null
            && this.buildingWidthTextBox.value != null
            && this.buildingDepthTextBox.value < this.buildingWidthTextBox.value
        ) {
            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();
            this.buildingDepthTextBox.value = this.buildingWidthTextBox.value;

            this.showBuildingDimensionsPopup();
        }

        this.onHandrailDimensionsChange();
    }

    public onHandrailDimensionsChange() {
        this.adjustMinMaxHandrailHeightOverGround();
        this.adjustBuildingZones();
    }

    public isPropertyDisabled(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.disabled : true;
    }

    public isPropertyHidden(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.hidden : true;
    }

    public isSaveDisabled(form: NgForm) {
        if (this.submitted || this.zipCodeLoading || !this.formValid || (form.enabled && !form.valid)) {
            return true;
        }

        if (this.zipCodeFilterTextBox.value != this.loadedZipCode) {
            // prevent save if zip code is modified and not loaded
            return true;
        }

        return false;
    }

    public save(form: NgForm) {
        if (this.isSaveDisabled(form)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        if (this.windZoneDropdown != null && this.windZoneDropdown.selectedValue != null) {
            this.design.windZone = this.windZoneDropdown.selectedValue;
        }

        this.design.buildingWidth = this.buildingWidthTextBox.value;
        this.design.buildingHeight = this.buildingHeightTextBox.value;
        this.design.buildingDepth = this.buildingDepthTextBox.value;
        this.design.buildingZone = this.design.designData.designCodeLists[DesignCodeList.BuildingArea]?.find(cl => cl.id === this.buildingZoneGroupSelected);
        this.design.isFreeStandingWall = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.isFreeStandingWallCheckbox) ?? false;
        this.design.handrailLength = this.handrailLengthTextBox.value;
        this.design.handrailHeightOverGround = this.handrailHeightOverGroundTextBox.value;

        this.design.altitude = this.altitudeTextBox.value;
        this.design.distanceToSeaside = this.distanceToSeasideTextBox.value;
        this.design.referenceVelocityPressure = this.referenceVelocityPressureTextBox.value;
        this.design.fundamentalWindVelocity = this.fundamentalWindVelocityTextBox.value;
        this.design.terrainCategoryId = this.terrainCategoryDropdown.selectedValue?.id;
        this.design.windLoadCityId = this.cityDropdown.selectedValue?.id;
        this.design.roughnessClassId = this.roughnessClassDropdown.selectedValue?.id;
        this.design.returnPeriod = this.returnPeriodTextBox.value;
        this.design.distanceUpwindToShorelineId = this.distanceUpwindToShorelineDropdown.selectedValue?.id;
        this.design.distanceInsideTownTerrainId = this.distanceInsideTownTerrainDropdown.selectedValue?.id;
        this.design.basicWindVelocityId = this.basicWindVelocityDropdown.selectedValue?.id;
        this.design.referenceWindVelocityId = this.referenceWindVelocityDropdown.selectedValue?.id;
        this.design.windPressure = this.windPressureTextBox.value;
        this.design.windUplift = this.windUpliftTextBox.value;

        const isOrographyRelevant = this.isOrographyRelevant;
        this.design.isOrographyRelevant = isOrographyRelevant;
        this.design.zipCodeHandrailLoadsId = this.zipCodeId;

        // TerrainType, H, Lu.X -> Visible if orography == relevant
        if (isOrographyRelevant) {
            this.design.orographyInputX = this.inputXTextBox.value;
            this.design.orographyInputH = this.inputHTextBox.value;
            this.design.orographyInputLu = this.inputLuTextBox.value;

            // Ld -> Visible if TerrainType == DownwindHill
            if (!this.isInputLdHidden) {
                this.design.orographyInputLd = this.inputLdTextBox.value;
            }

            if (this.terrainTypeDropdown.selectedValue != null) {
                this.design.terrainTypeId = this.terrainTypeDropdown.selectedValue?.id;
            }
        }

        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true })
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    private initControls() {
        const terrainTypeAllowedValues = this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.TerrainType],
            this.design.properties.get(PropertyMetaData.Application_TerrainType.id).allowedValues
        );
        this.terrainTypeDropdown = {
            id: 'windloads-terrain-type',
            title: this.translate('Agito.Hilti.Profis3.Windloads.TerrainType'),
            items: terrainTypeAllowedValues?.map((item) => {
                return {
                    value: item,
                    text: this.translate(item.nameResourceKey ?? '')
                } as DropdownItem<TerrainType>;
            }),
            selectedValue: terrainTypeAllowedValues?.find((item) => item.id === this.design.terrainTypeId),
        };

        let valueRange = this.getPropertyValueRange(PropertyMetaData.Application_H.id);
        this.inputHTextBox = {
            id: 'input-h',
            title: this.translate('Agito.Hilti.Profis3.Windloads.InputH'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.orographyInputH
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_X.id);
        this.inputXTextBox = {
            id: 'input-x',
            title: this.translate('Agito.Hilti.Profis3.Windloads.InputX'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.orographyInputX
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_Lu.id);
        this.inputLuTextBox = {
            id: 'input-lu',
            title: this.translate('Agito.Hilti.Profis3.Windloads.InputLu'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.orographyInputLu
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_Ld.id);
        this.inputLdTextBox = {
            id: 'input-ld',
            title: this.translate('Agito.Hilti.Profis3.Windloads.InputLd'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.orographyInputLd
        };

        this.isOrographyNotRelevantCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            id: 'windloads-wind-load-reductuon',
            checked: !this.design.isOrographyRelevant,
            itemText: this.translate('Agito.Hilti.Profis3.Windloads.IsOrographyNotRelevant'),
        });

        this.zipCodeFilterTextBox = {
            id: 'zip-code-filter',
            title: this.translate('Agito.Hilti.Profis3.Windloads.ZipCodeFilter'),
            validationData: {
                showValidationErrors: true
            }
        };

        this.zipCodeCityDropdown = {
            id: 'zip-code-city',
            title: this.translate('Agito.Hilti.Profis3.Windloads.ZipCodeCity'),
            items: []
        };

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        const windZoneAllowedValues = this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.WindZone],
            this.design.properties.get(PropertyMetaData.Application_WindZone.id).allowedValues
        );
        this.windZoneDropdown = {
            id: 'windloads-wind-zone',
            title: this.translate('Agito.Hilti.Profis3.Windloads.WindZone'),
            items: windZoneAllowedValues?.map((windZone) => {
                return {
                    value: windZone,
                    text: windZone.getTranslatedNameText(codeListDeps)
                } as DropdownItem<WindZone>;
            }),
            selectedValue: windZoneAllowedValues?.find((windZone) => windZone === this.design.windZone)
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_Altitude.id);
        this.altitudeTextBox = {
            id: 'altitude',
            title: this.translate('Agito.Hilti.Profis3.Windloads.Altitude'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.altitude
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_DistanceToSeaside.id);
        this.distanceToSeasideTextBox = {
            id: 'distance-to-seaside',
            title: this.translate('Agito.Hilti.Profis3.Windloads.DistanceToSeaside'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.distanceToSeaside
        };

        this.isCountyAndCommune = this.design.region.id == KnownRegion.Norway;
        const stateAllowedValues = this.design.designData.designCodeLists[DesignCodeList.WindLoadState] ?? [];
        this.stateDropdown = {
            id: 'windloads-state',
            title: this.translate(this.isCountyAndCommune ? 'Agito.Hilti.Profis3.Windloads.County' : 'Agito.Hilti.Profis3.Windloads.State'),
            items: stateAllowedValues?.map((item) => {
                return {
                    value: item,
                    text: item.getTranslatedNameText(codeListDeps)
                } as DropdownItem<State>;
            }),
            selectedValue: stateAllowedValues?.find((item) => item.id === this.design.windLoadStateId)
        };

        const cityAllowedValues = (this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.WindLoadCity],
            this.design.properties.get(PropertyMetaData.Application_CityAndStateId.id).allowedValues
        ) ?? []) as City[];
        this.cityDropdown = {
            id: 'windloads-city',
            title: this.translate(this.isCountyAndCommune ? 'Agito.Hilti.Profis3.Windloads.Commune' : 'Agito.Hilti.Profis3.Windloads.City'),
            items: cityAllowedValues
                ?.filter((item: City) => this.stateDropdown.selectedValue?.name === item.federalState)
                ?.map((item) => this.getCityDropdownItem(item, codeListDeps)),
            selectedValue: cityAllowedValues?.find((item) => item.id === this.design.windLoadCityId)
        };

        const terrainCategoryAllowedValues = this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.TerrainCategory],
            this.design.properties.get(PropertyMetaData.Application_TerrainCategoryId.id).allowedValues
        ) as TerrainCategory[];
        this.terrainCategoryDropdown = {
            id: 'windloads-terain-category',
            title: this.translate('Agito.Hilti.Profis3.Windloads.TerrainCategory'),
            tooltip: this.localization.hasTranslation('Agito.Hilti.Profis3.Windloads.TerrainCategory.Tooltip.Title')
                && this.localization.hasTranslation('Agito.Hilti.Profis3.Windloads.TerrainCategory.Tooltip.Content.' + this.design.region.countryCode)
                ? {
                    title: this.translate('Agito.Hilti.Profis3.Windloads.TerrainCategory.Tooltip.Title'),
                    content: this.translate('Agito.Hilti.Profis3.Windloads.TerrainCategory.Tooltip.Content.' + this.design.region.countryCode)
                }
                : undefined,
            items: terrainCategoryAllowedValues?.map((item) => {
                return {
                    value: item,
                    text: item.getTranslatedNameText(codeListDeps)
                } as DropdownItem<TerrainCategory>;
            }),
            selectedValue: terrainCategoryAllowedValues?.find((item) => item.id === this.design.terrainCategoryId),
        };

        const roughnessClassAllowedValues = this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.TerrainRoughness],
            this.design.properties.get(PropertyMetaData.Application_RoughnessClassId.id).allowedValues
        ) ?? [];
        this.roughnessClassDropdown = {
            id: 'windloads-roughness-class',
            title: this.translate('Agito.Hilti.Profis3.Windloads.RoughnessClass'),
            items: roughnessClassAllowedValues?.map((item) => {
                return {
                    value: item,
                    text: item.getTranslatedNameText(codeListDeps)
                } as DropdownItem<TerrainRoughness>;
            }),
            selectedValue: roughnessClassAllowedValues?.find((item) => item.id === this.design.roughnessClassId)
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_ReturnPeriod.id);
        this.returnPeriodTextBox = {
            id: 'return-period',
            title: this.translate('Agito.Hilti.Profis3.Windloads.ReturnPeriod'),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.returnPeriod
        };

        const distanceUpwindToShorelineAllowedValues = (this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.DistanceUpwindToShoreline],
            this.design.properties.get(PropertyMetaData.Application_DistanceUpwindToShorelineId.id).allowedValues
        ) ?? []) as DistanceUpwindToShoreline[];
        this.distanceUpwindToShorelineDropdown = {
            id: 'windloads-distance-upwind-to-shoreline',
            title: this.translate('Agito.Hilti.Profis3.Windloads.DistanceUpwindToShoreline'),
            items: distanceUpwindToShorelineAllowedValues?.map((item) => {
                return {
                    value: item,
                    text: item.getTranslatedNameText(codeListDeps)
                } as DropdownItem<DistanceUpwindToShoreline>;
            }),
            selectedValue: distanceUpwindToShorelineAllowedValues?.find((item) => item.id === this.design.distanceUpwindToShorelineId)
        };

        const distanceInsideTownTerrainAllowedValues = (this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.DistanceInsideTownTerrain],
            this.design.properties.get(PropertyMetaData.Application_DistanceInsideTownTerrainId.id).allowedValues
        ) ?? []) as DistanceInsideTownTerrain[];
        this.distanceInsideTownTerrainDropdown = {
            id: 'windloads-distance-inside-town-terrain',
            title: this.translate('Agito.Hilti.Profis3.Windloads.DistanceInsideTownTerrain'),
            items: distanceInsideTownTerrainAllowedValues?.map((item) => {
                return {
                    value: item,
                    text: item.getTranslatedNameText(codeListDeps)
                } as DropdownItem<DistanceInsideTownTerrain>;
            }),
            selectedValue: distanceInsideTownTerrainAllowedValues?.find((item) => item.id === this.design.distanceInsideTownTerrainId)
        };

        const basicWindVelocityAllowedValues = (this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.WindVelocity],
            this.design.properties.get(PropertyMetaData.Application_BasicWindVelocityId.id).allowedValues
        ) ?? []) as WindVelocity[];
        this.basicWindVelocityDropdown = {
            id: 'windloads-basic-wind-velocity',
            title: this.translate('Agito.Hilti.Profis3.Windloads.BasicWindVelocity'),
            items: basicWindVelocityAllowedValues?.map((item) => this.getWindVelocityDropdownItem(item, codeListDeps)),
            selectedValue: basicWindVelocityAllowedValues?.find((item) => item.id === this.design.basicWindVelocityId)
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Loads_FundamentalWindVelocity.id);
        this.fundamentalWindVelocityTextBox = {
            id: 'fundamental-wind-velocity',
            title: this.translate('Agito.Hilti.Profis3.Windloads.FundamentalValueForBasicWindVelocity'),
            unit: this.unit.getDefaultUnit(UnitGroup.Velocity),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.fundamentalWindVelocity
        };

        const referenceWindVelocityAllowedValues = (this.allowedValues(
            this.design.designData.designCodeLists[DesignCodeList.WindVelocity],
            this.design.properties.get(PropertyMetaData.Application_ReferenceWindVelocityId.id).allowedValues
        ) ?? []) as WindVelocity[];
        this.referenceWindVelocityDropdown = {
            id: 'windloads-reference-wind-velocity',
            title: this.translate('Agito.Hilti.Profis3.Windloads.ReferenceWindVelocity'),
            items: referenceWindVelocityAllowedValues?.map((item) => this.getWindVelocityDropdownItem(item, codeListDeps)),
            selectedValue: referenceWindVelocityAllowedValues?.find((item) => item.id === this.design.referenceWindVelocityId)
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_WindVelocityPressure.id);
        const unitReferenceVelocityPressure = this.unit.getDefaultUnit(UnitGroup.StressSmall);
        this.referenceVelocityPressureTextBox = {
            id: 'reference-velocity-pressure',
            title: this.translate('Agito.Hilti.Profis3.Windloads.ReferenceVelocityPressure'),
            unit: unitReferenceVelocityPressure,
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.referenceVelocityPressure,
            precision: this.unit.getPrecision(unitReferenceVelocityPressure, PropertyMetaData.Application_WindVelocityPressure.id)
        };

        const unitStressSmall = this.unit.getDefaultUnit(UnitGroup.StressSmall);
        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_WindPressure.id);
        this.windPressureTextBox = {
            id: 'wind-pressure',
            title: this.translate('Agito.Hilti.Profis3.Windloads.WindPressure'),
            unit: unitStressSmall,
            precision: this.unit.getPrecision(unitStressSmall, PropertyMetaData.Application_WindPressure.id),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.windPressure
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_WindUplift.id);
        this.windUpliftTextBox = {
            id: 'wind-uplift',
            title: this.translate('Agito.Hilti.Profis3.Windloads.WindUplift'),
            unit: unitStressSmall,
            precision: this.unit.getPrecision(unitStressSmall, PropertyMetaData.Application_WindUplift.id),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.windUplift
        };

        this.isFreeStandingWallCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            id: 'windloads-is-free-standing-wall',
            title: this.translate('Agito.Hilti.Profis3.Windloads.Installation'),
            checked: this.design.isFreeStandingWall,
            itemText: this.translate('Agito.Hilti.Profis3.Windloads.IsFreeStandingWall'),
        });

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_HandrailLength.id);
        this.handrailLengthTextBox = {
            id: 'windloads-handrail-length',
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.handrailLength
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_BuildingWidth.id);
        this.buildingWidthTextBox = {
            id: 'windloads-building-width',
            title: this.translate('Agito.Hilti.Profis3.Windloads.BuildingWidth'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.buildingWidth
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_BuildingHeight.id);
        this.buildingHeightTextBox = {
            id: 'windloads-building-height',
            title: this.translate('Agito.Hilti.Profis3.Windloads.BuildingHeight'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.buildingHeight
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.Application_BuildingDepth.id);
        this.buildingDepthTextBox = {
            id: 'windloads-building-depth',
            title: this.translate('Agito.Hilti.Profis3.Windloads.BuildingDepth'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.buildingDepth
        };

        this.handrailHeightOverGroundTextBox = {
            id: 'windloads-handrail-height-over-ground',
            title: this.translate('Agito.Hilti.Profis3.Windloads.HandrailHeightOverGround'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            value: this.design.handrailHeightOverGround
        };

        valueRange = this.getPropertyValueRange(PropertyMetaData.BaseMaterial_BeamLength.id);
        this.handrailHeightTextBox = {
            id: 'windloads-handrail-height',
            title: this.translate('Agito.Hilti.Profis3.Windloads.HandrailHeight'),
            unit: this.unit.getDefaultUnit(UnitGroup.LengthLarge),
            minValue: valueRange.min,
            maxValue: valueRange.max,
            value: this.design.beamLength
        };

        this.isInputLdHidden = this.terrainTypeDropdown.selectedValue?.id == EnumTerrainType.DownwindHill
            ? false
            : true;
    }

    private initImages() {
        this.imageSourceTranslation = this.translate('Agito.Hilti.Profis3.Windloads.Environment.Source');

        const windZoneImageSourceKey = this.design.region.nameResourceKey + '.ImageSource';
        this.windZoneImageSourceExists = this.localization.getKeyExists(windZoneImageSourceKey);
        if (this.windZoneImageSourceExists) {
            this.windZoneImageSourceTranslation = this.translate(windZoneImageSourceKey);
        }

        this.windZoneImage = undefined;
        if (this.design.region != null) {
            const regionWindzone = DesignPe.getRegionPe(this.design)?.windZoneImage;
            if (regionWindzone != null) {
                this.windZoneImage = windZoneImages[`${regionWindzone}.png`];
            }
        }

        this.terrainTypeImage = this.terrainTypeDropdown.selectedValue?.image?.toLowerCase() ?? '';
        if (this.terrainTypeImage) {
            this.terrainTypeImage = terrainTypeImages[`${this.terrainTypeImage}.png`];
            this.terrainTypeImageText = this.getImageName(this.terrainTypeImage);
        }
    }

    private determineWindCode() {
        const windCodeShort = this.calculateBuildingShortSide().windCode;
        const windCodeLong = this.calculateBuildingLongSide().windCode;

        return windCodeShort * windCodeLong;
    }

    private calculateBuildingShortSide() {
        let lengthA = 0;
        let lengthB = 0;
        let windCode = 0;

        try {
            const d = this.buildingWidthTextBox.value ?? 0;

            if (this.design.region.id == KnownRegion.Switzerland) {
                return this.calculateBuildingShortSideSwitzerland(d);
            }

            const h = this.buildingHeightTextBox.value ?? 0;
            const b = this.buildingDepthTextBox.value ?? 0;
            const e = Math.min(b, 2 * h);

            if (e >= 5 * d) {
                lengthA = d;
                windCode = 20;
            }
            else {
                lengthA = e / 5;

                if (d <= 2 * lengthA) {
                    lengthA = d;
                    windCode = 20;
                }
                else {
                    lengthB = d - 2 * lengthA;
                    windCode = 10;
                }
            }
        } catch (ex) {
            // math exception might occur (divide by zero, null value, etc)
        }

        return { windCode, lengthA, lengthB };
    }

    private calculateBuildingShortSideSwitzerland(d: number) {
        let lengthA = d / 10;
        let lengthB = 0;
        let windCode = 0;

        if (d <= 2 * lengthA) {
            lengthA = d;
            windCode = 20;
        }
        else {
            lengthB = d - 2 * lengthA;
            windCode = 10;
        }

        return { windCode, lengthA, lengthB };
    }

    private calculateBuildingLongSide() {
        let lengthA = 0;
        let lengthB = 0;
        let windCode = 0;

        try {
            const d = this.buildingDepthTextBox.value ?? 0;

            if (this.design.region.id == KnownRegion.Switzerland) {
                return this.calculateBuildingLongSideSwitzerland(d);
            }

            const h = this.buildingHeightTextBox.value ?? 0;
            const b = this.buildingWidthTextBox.value ?? 0;
            const e = Math.min(b, 2 * h);

            if (e >= 5 * d) {
                lengthA = d;
                windCode = 40;
            }
            else {
                lengthA = e / 5;

                if (d <= 2 * lengthA) {
                    lengthA = d;
                    windCode = 40;
                }
                else {
                    lengthB = d - 2 * lengthA;
                    windCode = 30;
                }
            }
        } catch (ex) {
            // math exception might occur (divide by zero, null value, etc)
        }

        return { windCode, lengthA, lengthB };
    }

    private calculateBuildingLongSideSwitzerland(d: number) {
        let lengthA = d / 10;
        let lengthB = 0;
        let windCode = 0;

        if (d <= 2 * lengthA) {
            lengthA = d;
            windCode = 40;
        }
        else {
            lengthB = d - 2 * lengthA;
            windCode = 30;
        }

        return { windCode, lengthA, lengthB };
    }

    private determineZonesBuilding(): { [id: string]: number[] } {
        const longSide = this.calculateBuildingLongSide();
        const shortSide = this.calculateBuildingShortSide();

        // calculate a and b
        const a1 = longSide.lengthA;
        const a2 = shortSide.lengthA;
        const b1 = longSide.lengthB;
        const b2 = shortSide.lengthB;

        // build zone list
        let zones: { [id: string]: number[] } = {};
        if (this.design.region.id == KnownRegion.Switzerland) {
            zones = { A: [a1, a2], B: [b1, b2] };
            return zones;
        }

        // short
        let b = this.buildingDepthTextBox.value ?? 0;
        let d = this.buildingWidthTextBox.value ?? 0;
        const h = this.buildingHeightTextBox.value ?? 0;
        let e = Math.min(b, 2 * h);

        let short: { [id: string]: number[] } = {};
        if (e < d) {
            short = { A: [a1, a2], B: [b1, b2] };
        }
        else if (e < 5 * d) {
            short = { A: [a1, a2], B: [b1, b2] };
        }
        else {
            short = { A: [a1, a2] };
        }

        // long
        b = this.buildingWidthTextBox.value ?? 0;
        d = this.buildingDepthTextBox.value ?? 0;
        e = Math.min(b, 2 * h);

        let long: { [id: string]: number[] } = {};
        if (e < d) {
            long = { A: [a1, a2], B: [b1, b2] };
        }
        else if (e < 5 * d) {
            long = { A: [a1, a2], B: [b1, b2] };
        }
        else {
            long = { A: [a1, a2] };
        }

        if (Object.keys(short).length < Object.keys(long).length) {
            return short;
        }
        else {
            return long;
        }
    }

    private determineZonesBridge(): { [id: string]: number } {
        // freestanding wall or bridge
        // note: with all freestanding walls, calculation uses handrail length instead of building length => the same applies here
        const h = this.handrailHeightTextBox.value ?? 0;
        const l = this.handrailLengthTextBox.value ?? 0;

        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;

        // calculate a
        a = 0.3 * h;

        // calculate b
        if (l <= 4 * h) {
            b = Math.min(1.7 * h, l - 2 * a);
        }
        else {
            b = 1.7 * h;
        }

        // calculate c
        if (l > 4 * h && l <= 8 * h) {
            c = Math.min(2 * h, l - 2 * (a + b));
        }
        else {
            c = 2 * h;
        }

        // calculate d
        if (l > 8 * h) {
            d = l - 2 * (a + b + c);
        }
        /*
         * If this logic changes also update it
         * in BaseplateCalculation.cs region
         * "Wind zone lengths bridge"
         */
        if (l <= 4 * h) {
            return { A: a, B: b };
        }

        if (l <= 8 * h) {
            return { A: a, B: b, C: c };
        }

        return { A: a, B: b, C: c, D: d };
    }

    private allowedValues<TType extends CodeList>(codeList: TType[], allowedValues?: number[]) {
        if (allowedValues == null) {
            return codeList;
        }

        return codeList.filter(codeListItem => allowedValues.includes(codeListItem.id ?? -1));
    }

    private getPropertyValueRange(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        const retVal: IValueRange = {
            min: propertyInfo.min,
            max: propertyInfo.max
        };
        return retVal;
    }

    private fillZipCodeCityDropdown(values: ZipCodeHandrailLoadsEntity[]) {
        this.zipCodeCityDropdown.items = values.map(value => {
            return {
                value,
                text: value.DisplayName
            } as DropdownItem<ZipCodeHandrailLoadsEntity>;
        });

        this.zipCodeCityDropdown.selectedValue = values.length > 0 ? values[0] : undefined;
    }

    private adjustBuildingZones() {
        this.buildingZoneGroupItems = [];

        if (this.isBuildingCategory) {
            const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
            const zones = this.determineZonesBuilding();
            this.buildingZoneGroupItems = Object.keys(zones)
                .map(zone => {
                    const descriptions: string[] = [];
                    const cl = this.design.designData.designCodeLists[DesignCodeList.BuildingArea]?.find(dcl => dcl.name === zone);
                    if (cl != null) {
                        if (zones[zone][0] != null && zones[zone][0] != 0) {
                            descriptions.push(`${cl.getTranslatedNameText(codeListDeps)}1 = ${this.unit.formatInternalValueAsDefault(zones[zone][0], UnitGroup.LengthLarge)}`);
                        }
                        if (zones[zone][1] != null && zones[zone][1] != 0) {
                            descriptions.push(`${cl.getTranslatedNameText(codeListDeps)}2 = ${this.unit.formatInternalValueAsDefault(zones[zone][1], UnitGroup.LengthLarge)}`);
                        }
                    }

                    return {
                        value: cl?.id,
                        text: this.translate('Agito.Hilti.Profis3.Windloads.BuildingZone.' + cl?.name),
                        description: descriptions.join(', ')
                    } as RadioButtonItem<number>;
                });
        }
        else if (this.isBridgeCategory || this.isFreeStandingWallCategory) {
            const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
            const zones = this.determineZonesBridge();
            this.buildingZoneGroupItems = Object.keys(zones)
                .map(zone => {
                    const descriptions: string[] = [];
                    const cl = this.design.designData.designCodeLists[DesignCodeList.BuildingArea]?.find(dcl => dcl.name === zone);
                    if (cl != null) {
                        descriptions.push(`${cl.getTranslatedNameText(codeListDeps)} = ${this.unit.formatInternalValueAsDefault(zones[zone], UnitGroup.LengthLarge)}`);
                    }

                    return {
                        value: cl?.id,
                        text: cl?.getTranslatedNameText(codeListDeps),
                        description: descriptions.join(', ')
                    } as RadioButtonItem<number>;
                });
        }

        const item = this.buildingZoneGroupSelected == null
            ? null
            : this.buildingZoneGroupItems?.find(bzgi => bzgi.value === this.buildingZoneGroupSelected);
        if (item != null) {
            this.buildingZoneGroupSelected = item.value;
        }
        else {
            this.buildingZoneGroupSelected = this.buildingZoneGroupItems[0].value;
        }
    }

    private adjustMinMaxHandrailHeightOverGround() {
        const propertyInfo = this.design.properties.get(PropertyMetaData.Application_HandrailHeightOverGround.id);

        const min = this.design.beamLength + this.design.balustradeHeight;

        let max = propertyInfo.max;
        if (this.isBuildingCategory) {
            max = this.buildingHeightTextBox.value;
        }

        let newValue = this.handrailHeightOverGroundTextBox.value ?? 0;

        if (min != null && newValue < min) {
            newValue = min;
        }

        if (max != null && newValue > max) {
            newValue = max;
        }

        if (newValue != this.handrailHeightOverGroundTextBox.value) {
            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();

            this.handrailHeightOverGroundTextBox.value = newValue;
        }
    }

    private showBuildingDimensionsPopup() {
        this.modal.openAlertWarning(
            this.translate('Agito.Hilti.Profis3.Warning'),
            this.translate('Agito.Hilti.Profis3.WindLoads.DimensionsLimitation.Message'),
        );
    }

    private getImageName(img: string | null) {
        if (img == null) {
            return undefined;
        }

        return img.substring(img.lastIndexOf('/') + 1);
    }

    private getCityDropdownItem(city: City, codeListDeps: ICodeListTextDeps): DropdownItem<City> {
        return {
            value: city,
            text: city.getTranslatedNameText(codeListDeps) ?? ''
        };
    }

    private getWindVelocityDropdownItem(windVelocity: WindVelocity, codeListDeps: ICodeListTextDeps): DropdownItem<WindVelocity> {
        return {
            value: windVelocity,
            text: windVelocity.getTranslatedNameText(codeListDeps)
        };
    }

    private isSimpleCheckboxChecked(checkbox: CheckboxButtonProps<boolean>) {
        if ((checkbox?.items?.length ?? 0) == 0) {
            return false;
        }

        return SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(checkbox);
    }

    private isFieldValid(isValid: boolean | undefined) {
        return isValid ?? false;
    }
}
