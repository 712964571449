import {
    MasonryUseCategoryEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';

import { CodeList } from '../code-lists/code-list';

export class MasonryUseCategory extends CodeList {

    public static fromService(codeList: MasonryUseCategoryEntity) {
        return new MasonryUseCategory(CodeList.baseFromService('MasonryUseCategoryEntity', codeList));
    }

    public override cloneDeep(): MasonryUseCategory {
        return super.cloneDeep() as MasonryUseCategory;
    }
}
