import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    ReinforcementShearConditionEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface IReinforcementShearConditionConstructor extends ICodeListConstructor {
    perDesignMethodGroupDisplayKeyExtensions?: { [Key: string]: string };
}

export class ReinforcementShearCondition extends CodeList {
    public perDesignMethodGroupDisplayKeyExtensions?: { [Key: string]: string };

    constructor(codeList: IReinforcementShearConditionConstructor) {
        super(codeList);

        if (codeList != null) {
            this.perDesignMethodGroupDisplayKeyExtensions = codeList.perDesignMethodGroupDisplayKeyExtensions;
        }
    }

    public static fromService(codeList: ReinforcementShearConditionEntity) {
        const reinforcementShearCondition = new ReinforcementShearCondition(CodeList.baseFromService<ICodeListConstructor>('ReinforcementShearConditionEntity', codeList));

        // set additional display keys
        reinforcementShearCondition.perDesignMethodGroupDisplayKeyExtensions = codeList.PerDesignMethodGroupDisplayKeyExtensions;

        return reinforcementShearCondition;
    }

    public override cloneDeep(): ReinforcementShearCondition {
        const codeList = super.cloneDeep() as ReinforcementShearCondition;

        codeList.perDesignMethodGroupDisplayKeyExtensions = this.perDesignMethodGroupDisplayKeyExtensions;

        return codeList;
    }
}
