import escape from 'lodash-es/escape';

import { HttpResponseBase } from '@angular/common/http';
import { Component, OnInit, Input, ViewEncapsulation, ElementRef } from '@angular/core';
import {
    CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    LoadType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    IntegrationDataResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities';
import {
    DataIntegrationRequestType, DataIntegrationType, ErrorType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    CSiResponse as CSIResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.CSi.Common';
import {
    CSiNodeInitialData, CSiPointToLoadCombinationCorrelation
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.CSi.Common.Models.General';
import {
    CSiLoadCombinationComposition
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.CSi.Common.Models.LoadCombinations';
import {
    SAP2000LoadCombinationRequest, SAP2000LoadCombinationResponse, SAP2000NodeSelectionResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.CSi.SAP2000';
import {
    UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';

import { environment } from '../../../environments/environmentPe';
import { concatFilterLoadCombinations } from '../../helpers/load-combination-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { GuidService } from '../../services/guid.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { includeSprites } from '../../sprites';
import { getProperty } from '../../helpers/object-helper';
import { SharedEnvironmentService } from '../../services/shared-environment.service';

interface IUserNotification {
    readonly controlEnableFlag: string;
    readonly buttonTranslationKey: string;
    readonly notificationTranslationKey: string;
}

@Component({
    templateUrl: './sap-import.component.html',
    styleUrls: ['./sap-import.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SapImportComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance;

    public readonly allUserNotifications: ReadonlyArray<IUserNotification> = [
        {
            controlEnableFlag: 'isCommunicationStateError',
            buttonTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.IsCommunicationError'
        },
        {
            controlEnableFlag: 'areActiveModelCalculationsMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.RefreshOverlayDescription'
        },
        {
            controlEnableFlag: 'isActiveObjectMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.MissingObjectName'
        },
        {
            controlEnableFlag: 'isActiveModelMissing',
            buttonTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.MissingModelName'
        },
        {
            controlEnableFlag: 'isActiveObjectVersionNotSupported',
            buttonTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.RefreshOverlayRefresh',
            notificationTranslationKey: 'Agito.Hilti.Profis3.SAP2000Import.IsActiveObjectVersionNotSupported'
        }
    ];

    public submitted = true;
    public refreshingNodes = false;
    public refreshOverlayVisible = false;

    public activeModelName?: string;
    public detectedNodes: string[] = [];
    public userNotifications: IUserNotification[] = [];

    public availableLoadsCheckbox!: CheckboxButtonProps<CSiNodeInitialData>;

    public isCommunicationStateError = false;
    public areActiveModelCalculationsMissing = false;
    public isActiveObjectMissing = false;
    public isActiveModelMissing = false;
    public isActiveObjectVersionNotSupported = false;

    private isIntegrationsWorkflowRetry = false;

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private guid: GuidService,
        private modal: ModalService,
        private integrationsData: IntegrationsDataService,
        private calculationService: CalculationServicePE,
        private sharedEnvironmentData: SharedEnvironmentService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get detectedNodesFormatted() {
        return this.detectedNodes.join(', ');
    }

    /**
     * Indicates if the modal should be prevented from closing
     */
    public get preventClosing() {
        if (this.refreshOverlayVisible) {
            return this.submitted || this.refreshingNodes;
        }
        return this.submitted;
    }

    public get hasAvailableLoads() {
        return (this.availableLoadsCheckbox.items?.length ?? 0) > 0;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-refresh-l-dark',
            'sprite-info-tooltip'
        );

        // Don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            return this.preventClosing
                ? false
                : true;
        });

        // controls
        this.availableLoadsCheckbox = {
            items: [],
            selectedValues: new Set()
        };

        this.newIntegrationInit();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    /**
     * Closes the SAP 2000 import modal
     */
    public close() {
        this.modalInstance.close();
    }

    public dismiss(reason?: string) {
        this.modalInstance.dismiss(reason);
    }

    /**
     * Opens the import info pop-up
     */
    public openCombinationImportPopup() {
        this.modal.openConfirmChange({
            id: 'sap2000-import-combinationImport-popup',
            title: this.localization.getString('Agito.Hilti.Profis3.SAP2000Import.CombinationImportPopup.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.SAP2000Import.CombinationImportPopup.Text'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.SAP2000Import.CombinationImportPopup.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    /**
     * Selects all load combinations
     */
    public selectAll() {
        if (this.submitted) {
            return;
        }

        const selectionCount = this.availableLoadsCheckbox.selectedValues?.size;

        let newCheckedStatus = false;
        if (selectionCount != this.availableLoadsCheckbox.items?.length) {
            newCheckedStatus = true;
        }

        this.availableLoadsCheckbox.items?.forEach(v => {
            if (newCheckedStatus) {
                this.availableLoadsCheckbox.selectedValues?.add(v.value);
            }
            else {
                if (this.availableLoadsCheckbox.selectedValues?.has(v.value)) {
                    this.availableLoadsCheckbox.selectedValues.delete(v.value);
                }
            }
        });
    }

    public isButtonDisabled(allowEmptyArray = false): boolean {
        if (this.refreshOverlayVisible ||
            this.submitted ||
            this.isActiveObjectMissing ||
            this.isActiveModelMissing ||
            this.isActiveObjectVersionNotSupported ||
            this.areActiveModelCalculationsMissing) {
            return true;
        }

        if (!allowEmptyArray &&
            !this.validLoadCombinationSelection()) {
            return true;
        }

        return false;
    }

    public formatAvailableLoadName(
        point: CSiNodeInitialData,
        ind?: number): string {
        const loadComposition = this.generateLoadCombinationName(point.LoadCombinationComposition);
        const indexLabel = (ind == null) ?
            '' :
            `${ind + 1};`;

        return `${indexLabel}${point.Name};${point.LoadCombinationName}; ${loadComposition}`;
    }

    /**
     * Refreshes the instance connection
     * @param isRecursiveRefresh If false, allow one recursive refresh, otherwise no additional refreshes will happen after running this function
     */
    public refreshInstance(isRecursiveRefresh?: boolean) {
        this.isIntegrationsWorkflowRetry = (isRecursiveRefresh == null) ?
            false :
            isRecursiveRefresh;

        this.isActiveObjectMissing = false;
        this.isActiveModelMissing = false;
        this.areActiveModelCalculationsMissing = false;
        this.isActiveObjectVersionNotSupported = false;
        this.isCommunicationStateError = false;

        this.activeModelName = undefined;
        this.detectedNodes = [];

        this.availableLoadsCheckbox.items = [];
        this.availableLoadsCheckbox.selectedValues = new Set();

        this.submitted = true;
        this.updateUserNotifications();

        this.integrationsData.requestData(
            DataIntegrationType.SAP2000,
            DataIntegrationRequestType.RunningInstance,
            () => {
                this.isActiveObjectMissing = true;
                this.updateUserNotifications();
                this.handleServiceError();
            },
            (response) => {
                this.onRunningInstanceResponseReceived(response as CSIResponse);
            })
            .catch((err) => {
                this.isActiveObjectMissing = true;
                this.updateUserNotifications();
                this.handleServiceError(err, true);
            });
    }

    /**
     * Import the selected loads
     */
    public import() {
        this.user.design.usageCounter.SAP2000Import++;

        const pointToLoadCombinationCorrelations: CSiPointToLoadCombinationCorrelation[] = [];
        this.availableLoadsCheckbox.selectedValues?.forEach(lc => {
            pointToLoadCombinationCorrelations.push({
                PointName: lc.Name,
                LoadCombinationName: lc.LoadCombinationName
            });
        });

        if (pointToLoadCombinationCorrelations.length == 0) {
            this.submitted = false;
            this.close();

            return;
        }

        this.submitted = true;

        const loadCombinationsRequest = {
            DataIntegrationType: DataIntegrationType.SAP2000, // General Properties
            RequestType: DataIntegrationRequestType.GetLoadCombinations,
            PointToLoadCombinationCorrelations: pointToLoadCombinationCorrelations
        } as SAP2000LoadCombinationRequest;

        this.integrationsData.requestData(
            DataIntegrationType.SAP2000,
            DataIntegrationRequestType.GetLoadCombinations,
            () => {
                this.handleServiceError();
            },
            (response) => {
                this.onReadLoadCombinationResponseReceived(response as SAP2000LoadCombinationResponse);
            },
            loadCombinationsRequest).catch((err) => this.handleServiceError(err, true));
    }

    private onRunningInstanceResponseReceived(response: CSIResponse): void {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            return;
        }

        this.activeModelName = response.Model;

        this.integrationsData.requestData(
            DataIntegrationType.SAP2000,
            DataIntegrationRequestType.GetSelectedNodes,
            () => {
                this.handleServiceError();
            },
            (response2) => {
                this.onSelectedNodesResponseReceived(response2 as SAP2000NodeSelectionResponse);
            });
    }

    private validLoadCombinationSelection(): boolean {
        if (
            (this.availableLoadsCheckbox.items?.length ?? 0) < 1
            ||
            (this.availableLoadsCheckbox.selectedValues?.size ?? 0) < 1
        ) {
            return false;
        }

        return true;
    }

    private onSelectedNodesResponseReceived(response: SAP2000NodeSelectionResponse): void {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            return;
        }
        else if (response.Nodes == null ||
            response.Nodes.length == 0) {
            this.areActiveModelCalculationsMissing = true;
            this.submitted = false;
            this.updateUserNotifications();

            return;
        }

        // set all available points for display.
        this.updateAvailableLoadsCheckboxItems(response.Nodes);
        response.Nodes.forEach(pt => {
            this.detectedNodes.push(pt.Name);
        });

        // from detected nodes display on the left, remove duplicate node values.
        this.detectedNodes = this.detectedNodes
            .filter((elem, index, self) => {
                return index === self.indexOf(elem);
            });

        this.submitted = false;
    }

    private generateLoadCombinationName(loadComposition: CSiLoadCombinationComposition): string {
        let res = '';
        for (let i = 0; i < loadComposition.LoadCaseNames.length; i++) {
            res += `${loadComposition.LoadCaseScaleFactors[i].toFixed(3)} ${loadComposition.LoadCaseNames[i]}`;

            if (i != loadComposition.LoadCaseNames.length - 1) {
                res += ' + ';
            }
        }

        return res;
    }

    private newIntegrationInit(): void {
        this.submitted = true;

        this.integrationsData.requestData<CSIResponse>(
            DataIntegrationType.SAP2000,
            DataIntegrationRequestType.RunningInstance,
            () => {
                this.handleServiceError();
            },
            (response) => {
                this.onRunningInstanceResponseReceived(response);
            })
            .catch((err) => this.handleServiceError(err, true));
    }

    private onReadLoadCombinationResponseReceived(response: SAP2000LoadCombinationResponse): void {
        const validResponse = this.validateResponse(response);

        if (!validResponse) {
            return;
        }

        const newLoads: LoadCombination[] = [];

        response.LoadCombinations.forEach(lc => {
            const extendedNodeLoadCombination: CSiNodeInitialData = {
                Name: lc.Name,
                LoadCombinationName: lc.LoadCombination.Name,
                LoadCombinationComposition: lc.LoadCombination.LoadComposition
            };

            const generatedName = this.formatAvailableLoadName(extendedNodeLoadCombination);

            newLoads.push({
                Name: generatedName,
                Description: null as unknown as string,
                Id: this.guid.new(),
                ForceX: lc.LoadCombination.ForceX,
                ForceY: lc.LoadCombination.ForceY,
                ForceZ: lc.LoadCombination.ForceZ,
                MomentX: lc.LoadCombination.MomentX,
                MomentY: lc.LoadCombination.MomentY,
                MomentZ: lc.LoadCombination.MomentZ,
                DynamicForceX: null as unknown as number,
                DynamicForceY: null as unknown as number,
                DynamicForceZ: null as unknown as number,
                DynamicMomentX: null as unknown as number,
                DynamicMomentY: null as unknown as number,
                DynamicMomentZ: null as unknown as number,
                ActiveLoadType: LoadType.Static,
                Tension: null as unknown as UtilizationValueEntity,
                Shear: null as unknown as UtilizationValueEntity,
                Combination: null as unknown as UtilizationValueEntity,
                ResultMessages: [],
                IsWizardGenerated: false,
                HasSustainedLoads: false,
                LoadCharacteristic: null as unknown as number
            });

            return true;
        });

        this.calculationService.calculateAsync(this.user.design,
            (design) => {
                // Filter out empty load combinations and consider max number of combinations
                design.loadCombinations = concatFilterLoadCombinations(design.loadCombinations, newLoads, environment.maxLoadCombinations, false);
            },
            {
                suppressLoadingFlag: true,
                importingLoadCases: true
            }
        )
            .then(() => {
                this.submitted = false;
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    private validateResponse(response: IntegrationDataResponse) {
        if (response.ErrorType != ErrorType.None) {
            if (!this.isIntegrationsWorkflowRetry) {
                this.refreshInstance(true);

                return false;
            }

            if (response.ErrorType == ErrorType.NoInstanceRunning) {
                this.isActiveObjectMissing = true;
            }
            else if (response.ErrorType == ErrorType.UnsupportedVersion) {
                this.isActiveObjectVersionNotSupported = true;
            }
            else if (response.ErrorType == ErrorType.ModelNotOpened) {
                this.isActiveModelMissing = true;
            }
            else if (response.ErrorType == ErrorType.NoSelectedNodes) {
                this.areActiveModelCalculationsMissing = true;
            }
            else if (response.ErrorType == ErrorType.IntegrationInternalError) {
                this.isCommunicationStateError = true;
            }

            this.submitted = false;
            this.updateUserNotifications();

            return false;
        }

        return true;
    }

    private handleServiceError(response?: HttpResponseBase, logError = false): void {
        if (logError && response instanceof Error) {
            console.error(response);
        }

        this.submitted = false;

        this.modal.openAlertError(
            this.localization.getString('Agito.Hilti.Profis3.SAP2000Import.ServiceError.Title'),
            this.createDownloadUrlMessage('Agito.Hilti.Profis3.SAP2000Import.ServiceError.Message'),
            {
                response,
                endPointUrl: this.integrationsData.requestUrl,
                requestPayload: this.integrationsData.requestDataObject
            }
        );
    }

    private createDownloadUrlMessage(messageKey: string): string {
        const hereValue = escape(
            this.localization.getString(
                'Agito.Hilti.Profis3.SAP2000Import.ServiceError.Message.DownloadUrlText'));

        return `<html lang="en">${formatKeyValue(escape(this.localization.getString(messageKey)), {
            downloadUrl: `<a href="${escape(this.sharedEnvironmentData.data?.thirdPartyInterfaceDownloadUrl)}" class="download-link" target="_blank">${hereValue}</a>`
        })}</html>`;
    }

    private updateAvailableLoadsCheckboxItems(loads: CSiNodeInitialData[]) {
        const checkboxItems: CheckboxButtonItem<CSiNodeInitialData>[] = [];
        const checkboxSelectedValues = new Set<CSiNodeInitialData>();

        loads.forEach((load, index) => {
            const item: CheckboxButtonItem<CSiNodeInitialData> = {
                text: this.formatAvailableLoadName(load, index),
                value: load,
                disabled: !load.Name
            };

            checkboxItems.push(item);
        });

        this.availableLoadsCheckbox.items = checkboxItems;
        this.availableLoadsCheckbox.selectedValues = checkboxSelectedValues;
    }

    private updateUserNotifications() {
        const retVal: IUserNotification[] = [];

        for (const userNotification of this.allUserNotifications) {
            if (getProperty(this, userNotification.controlEnableFlag)) {
                retVal.push(userNotification);
            }
        }

        this.userNotifications = retVal;
    }
}
