import {
    SupplementaryReinforcementCategoryEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class SupplementaryReinforcementCategory extends CodeList {

    public static fromService(codeList: SupplementaryReinforcementCategoryEntity) {
        return new SupplementaryReinforcementCategory(CodeList.baseFromService('SupplementaryReinforcementCategoryEntity', codeList));
    }

    public override cloneDeep(): SupplementaryReinforcementCategory {
        return super.cloneDeep() as SupplementaryReinforcementCategory;
    }
}
