// This is used by UserSettings so no existing value should be changed!
export enum CollapsingControls {
    ValidationError = 118,
    NotificationsDesign = 119,

    Utilization = 122,
    HandrailDesign = 123,
    BaseplateUtilization = 130,
    WeldsUtilization = 131,
    StiffenersUtilization = 132,
    ConcreteUtilization = 133,
    ProfileUtilization = 134,
    AnchorPlateDesign = 135,
    AnchorLoads = 142,

    SmartBaseplateDesign = 150,
}
