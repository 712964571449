import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

export function createKBLink(kbLinkTemplate: string, kbNumber: string) {
    if (kbLinkTemplate == null) {
        return undefined;
    }

    if (kbNumber == null) {
        return kbLinkTemplate;
    }

    return formatKeyValue(kbLinkTemplate, { KBNUMBER: kbNumber });
}
