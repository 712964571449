import {
    WindLoadStateEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class WindLoadState extends CodeList {

    public static fromService(codeList: WindLoadStateEntity) {
        return new WindLoadState(CodeList.baseFromService('WindLoadStateEntity', codeList));
    }

    public override cloneDeep(): WindLoadState {
        return super.cloneDeep() as WindLoadState;
    }
}
