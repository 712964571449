import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { SortablejsModule } from 'ngx-sortablejs';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { APP_BASE_HREF } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule, NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AddEditDesignComponent } from './components/add-edit-design/add-edit-design.component';
import {
    AdvancedBaseplateCalculationComponent
} from './components/advanced-baseplate-calculation/advanced-baseplate-calculation.component';
import {
    AdvancedBaseplateLicenseRequiredComponent
} from './components/advanced-baseplate-license-required/advanced-baseplate-license-required.component';
import {
    AdvancedCalculationInputsComponent
} from './components/advanced-calculation-inputs/advanced-calculation-inputs.component';
import {
    AdvancedCalculationTypeInfoComponent
} from './components/advanced-calculation-type-info/advanced-calculation-type-info.component';
import { AdvancedInputsComponent } from './components/advanced-inputs/advanced-inputs.component';
import {
    AnchorDesignHelpComponent
} from './components/anchor-design-help/anchor-design-help.component';
import { AnchorLoadsComponent } from './components/anchor-loads/anchor-loads.component';
import {
    AnchorNeedSolutionPopupComponent
} from './components/anchor-need-solution-popup/anchor-need-solution-popup.component';
import {
    AnchorPlateDesignComponent
} from './components/anchor-plate-design/anchor-plate-design.component';
import {
    AppSettingsComdFixingComponent
} from './components/app-settings-comd-fixing/app-settings-comd-fixing.component';
import {
    AppSettingsConcreteFixingComponent
} from './components/app-settings-concrete-fixing/app-settings-concrete-fixing.component';
import {
    AppSettingsHandrailFixingComponent
} from './components/app-settings-handrail-fixing/app-settings-handrail-fixing.component';
import {
    AppSettingsMasonryFixingComponent
} from './components/app-settings-masonry-fixing/app-settings-masonry-fixing.component';
import {
    AsadOptimizeDetailsModalComponent
} from './components/asad-optimize-details-modal/asad-optimize-details-modal.component';
import {
    AsadOptimizeModalComponent
} from './components/asad-optimize-modal/asad-optimize-modal.component';
import {
    AsadOptimizeScopeCheckDetailsModalComponent
} from './components/asad-optimize-scope-check-details-modal/asad-optimize-scope-check-details-modal.component';
import {
    AutomaticTorquePopupComponent
} from './components/automatic-torque-popup/automatic-torque-popup.component';
import {
    BaseplateDesignGlModelComponent
} from './components/baseplate-design-gl-model/baseplate-design-gl-model.component';
import {
    BaseplateResultsPreferenceComponent
} from './components/baseplate-results-preference/baseplate-results-preference.component';
import { BaseplateSizeComponent } from './components/baseplate-size/baseplate-size.component';
import {
    BaseplateUtilizationsComponent
} from './components/baseplate-utilizations/baseplate-utilizations.component';
import { BrickSizeComponent } from './components/brick-size/brick-size.component';
import {
    BuildingTypeCustomizeDetailsComponent
} from './components/building-type-customize-details/building-type-customize-details.component';
import { CadExportComponent } from './components/cad-export/cad-export.component';
import {
    ConcreteUtilizationsComponent
} from './components/concrete-utilizations/concrete-utilizations.component';
import {
    CustomStiffenerLocationInfoComponent
} from './components/custom-stiffener-location-info/custom-stiffener-location-info.component';
import {
    CustomStiffenerPositionInfoComponent
} from './components/custom-stiffener-position-info/custom-stiffener-position-info.component';
import {
    CustomStiffenerWizardComponent
} from './components/custom-stiffener-wizard/custom-stiffener-wizard.component';
import {
    CustomStiffenersComponent
} from './components/custom-stiffeners/custom-stiffeners.component';
import {
    DefaultCalculationMethodComponent
} from './components/default-calculation-method/default-calculation-method.component';
import { DefineAnchorsComponent } from './components/define-anchors/define-anchors.component';
import {
    DeflectionLimitsComponent
} from './components/deflection-limits/deflection-limits.component';
import { DesignSectionComponent } from './components/design-section/design-section.component';
import {
    DetailedCalculationOutputComponent
} from './components/detailed-calculation-output/detailed-calculation-output.component';
import { DlubalExportComponent } from './components/dlubal-export/dlubal-export.component';
import { DlubalImportComponent } from './components/dlubal-import/dlubal-import.component';
import { EtabsImportComponent } from './components/etabs-import/etabs-import.component';
import {
    ExportReportRowComponent
} from './components/export-report-row/export-report-row.component';
import { ExportReportComponent } from './components/export-report/export-report.component';
import {
    FatigueLoadsInfoComponent
} from './components/fatigue-loads-info/fatigue-loads-info.component';
import { FillHolesModalComponent } from './components/fill-holes-modal/fill-holes-modal.component';
import { GenerateReportFollowUpActionsPopupComponent } from './components/generate-report-follow-up-actions-popup/generate-report-follow-up-actions-popup.component';
import { GlModelComponent } from './components/gl-model/gl-model.component';
import { HandrailDesignComponent } from './components/handrail-design/handrail-design.component';
import {
    HeadJointSelectionInfoPopupComponent
} from './components/head-joint-selection-info-popup/head-joint-selection-info-popup.component';
import {
    HorizontalPostProfileConnectionComponent
} from './components/horizontal-post-profile-connection/horizontal-post-profile-connection.component';
import { ImportLoadsComponent } from './components/import-loads/import-loads.component';
import { InfoSectionComponent } from './components/info-section/info-section.component';
import {
    InputHandrailLoadsComponent
} from './components/input-handrail-loads/input-handrail-loads.component';
import {
    IntegrationsNotificationPopupComponent
} from './components/integrations-notification-popup/integrations-notification-popup.component';
import { LegendComponent } from './components/legend/legend.component';
import { LoadsHNAWizardComponent } from './components/loads-hna-wizard/loads-hna-wizard.component';
import { LoadsInputComponent } from './components/loads-input/loads-input.component';
import { LoadsRowComponent } from './components/loads-row/loads-row.component';
import { LoadsComponent } from './components/loads/loads.component';
import { MainComponent } from './components/main/main.component';
import { ModalGridComponent } from './components/modal-grid/modal-grid.component';
import {
    MoreInfoOnFatigueModalComponent
} from './components/more-info-on-fatigue-modal/more-info-on-fatigue-modal.component';
import {
    MoreInfoOnSOFAModalComponent
} from './components/more-info-on-sofa-modal/more-info-on-sofa-modal.component';
import {
    MultipleApprovalsComponent
} from './components/multiple-approvals/multiple-approvals.component';
import {
    MultiselectAnchorComponent
} from './components/multiselect-anchor/multiselect-anchor.component';
import { OnSiteTestsComponent } from './components/on-site-tests/on-site-tests.component';
import { ProfileSizeComponent } from './components/profile-size/profile-size.component';
import {
    ProfileUtilizationsComponent
} from './components/profile-utilizations/profile-utilizations.component';
import { RamImportLoadsComponent } from './components/ram-import-loads/ram-import-loads.component';
import { RobotImportComponent } from './components/robot-import/robot-import.component';
import { SapImportComponent } from './components/sap-import/sap-import.component';
import {
    SeismicFillingSetModalComponent
} from './components/seismic-filling-set-modal/seismic-filling-set-modal.component';
import { SelectAnchorComponent } from './components/select-anchor/select-anchor.component';
import { SelectMaterialComponent } from './components/select-material/select-material.component';
import { SelectProfileComponent } from './components/select-profile/select-profile.component';
import { ShearLoadDistributionTypeComponent } from './components/shear-load-distribution-type-popup/shear-load-distribution-type-popup.component';
import {
    SmartBaseplateDesignComponent
} from './components/smart-baseplate-design/smart-baseplate-design.component';
import {
    SmartBaseplateFilterComponent
} from './components/smart-baseplate-filter/smart-baseplate-filter.component';
import { SolutionDetailComponent } from './components/solution-detail/solution-detail.component';
import { StaadProImportComponent } from './components/staad-pro-import/staad-pro-import.component';
import {
    SteelCalculationInputsComponent
} from './components/steel-calculation-inputs/steel-calculation-inputs.component';
import {
    StiffenersUtilizationsComponent
} from './components/stiffeners-utilizations/stiffeners-utilizations.component';
import {
    StressCheckLegendComponent
} from './components/stress-check-legend/stress-check-legend.component';
import {
    SupplementaryReinforcementInfoComponent
} from './components/supplementary-reinforcement-info/supplementary-reinforcement-info.component';
import { UiPeInitComponent } from './components/ui-pe-init/ui-pe-init.component';
import { UtilizationsComponent } from './components/utilizations/utilizations.component';
import { WeldCurvesComponent } from './components/weld-curves/weld-curves.component';
import {
    WeldsUtilizationsComponent
} from './components/welds-utilizations/welds-utilizations.component';
import { WhgInfoComponent } from './components/whg-info/whg-info.component';
import { WindloadsComponent } from './components/windloads/windloads.component';
import {
    CloseOutsideNgbDropdownDirective
} from './directives/close-outside-ngb-dropdown.directive';
import { L10nDirective } from './directives/l10n.directive';
import { L10nPipe } from './pipes/l10n.pipe';

@NgModule({
    declarations: [
        UiPeInitComponent,
        AddEditDesignComponent,
        AdvancedBaseplateCalculationComponent,
        AdvancedBaseplateLicenseRequiredComponent,
        AdvancedCalculationInputsComponent,
        AdvancedCalculationTypeInfoComponent,
        AdvancedInputsComponent,
        AnchorDesignHelpComponent,
        AnchorLoadsComponent,
        AnchorNeedSolutionPopupComponent,
        AnchorPlateDesignComponent,
        AppSettingsComdFixingComponent,
        AppSettingsConcreteFixingComponent,
        AppSettingsHandrailFixingComponent,
        AppSettingsMasonryFixingComponent,
        AsadOptimizeDetailsModalComponent,
        AsadOptimizeModalComponent,
        AsadOptimizeScopeCheckDetailsModalComponent,
        AutomaticTorquePopupComponent,
        BaseplateDesignGlModelComponent,
        BaseplateResultsPreferenceComponent,
        BaseplateSizeComponent,
        BaseplateUtilizationsComponent,
        BrickSizeComponent,
        BuildingTypeCustomizeDetailsComponent,
        CadExportComponent,
        CloseOutsideNgbDropdownDirective,
        ConcreteUtilizationsComponent,
        CustomStiffenerLocationInfoComponent,
        CustomStiffenerPositionInfoComponent,
        CustomStiffenersComponent,
        CustomStiffenerWizardComponent,
        DefaultCalculationMethodComponent,
        DefineAnchorsComponent,
        DeflectionLimitsComponent,
        DesignSectionComponent,
        DetailedCalculationOutputComponent,
        DlubalExportComponent,
        DlubalImportComponent,
        EtabsImportComponent,
        ExportReportComponent,
        ExportReportRowComponent,
        FatigueLoadsInfoComponent,
        FillHolesModalComponent,
        GenerateReportFollowUpActionsPopupComponent,
        GlModelComponent,
        HandrailDesignComponent,
        HeadJointSelectionInfoPopupComponent,
        HorizontalPostProfileConnectionComponent,
        ImportLoadsComponent,
        InfoSectionComponent,
        InputHandrailLoadsComponent,
        IntegrationsNotificationPopupComponent,
        L10nDirective,
        L10nPipe,
        LegendComponent,
        LoadsComponent,
        LoadsHNAWizardComponent,
        LoadsInputComponent,
        LoadsRowComponent,
        MainComponent,
        ModalGridComponent,
        MoreInfoOnFatigueModalComponent,
        MoreInfoOnSOFAModalComponent,
        MultipleApprovalsComponent,
        MultiselectAnchorComponent,
        OnSiteTestsComponent,
        ProfileSizeComponent,
        ProfileUtilizationsComponent,
        RamImportLoadsComponent,
        RobotImportComponent,
        SapImportComponent,
        SeismicFillingSetModalComponent,
        SelectAnchorComponent,
        SelectMaterialComponent,
        SelectProfileComponent,
        ShearLoadDistributionTypeComponent,
        SmartBaseplateDesignComponent,
        SmartBaseplateFilterComponent,
        SolutionDetailComponent,
        StaadProImportComponent,
        SteelCalculationInputsComponent,
        StiffenersUtilizationsComponent,
        StressCheckLegendComponent,
        SupplementaryReinforcementInfoComponent,
        UtilizationsComponent,
        WeldCurvesComponent,
        WeldsUtilizationsComponent,
        WhgInfoComponent,
        WindloadsComponent,
    ],
    imports: [
        BrowserModule,
        NgbTooltipModule,
        NgbDropdownModule,
        FormsModule,
        VirtualScrollerModule,
        SortablejsModule.forRoot({}),
        NgxSliderModule
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: '/cdn/pe-ui-pe/' }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
    constructor(
        private injector: Injector,
        ngbTooltipConfig: NgbTooltipConfig
    ) {
        this.configureTooltip(ngbTooltipConfig);

        this.registerCustomElement('ui-pe-init', UiPeInitComponent);
        this.registerCustomElement('add-edit-design', AddEditDesignComponent);
        this.registerCustomElement('advanced-baseplate-calculation', AdvancedBaseplateCalculationComponent);
        this.registerCustomElement('advanced-baseplate-license-required', AdvancedBaseplateLicenseRequiredComponent);
        this.registerCustomElement('advanced-calculation-inputs', AdvancedCalculationInputsComponent);
        this.registerCustomElement('advanced-calculation-type-info', AdvancedCalculationTypeInfoComponent);
        this.registerCustomElement('advanced-inputs', AdvancedInputsComponent);
        this.registerCustomElement('anchor-design-help', AnchorDesignHelpComponent);
        this.registerCustomElement('anchor-loads', AnchorLoadsComponent);
        this.registerCustomElement('anchor-need-solution-popup', AnchorNeedSolutionPopupComponent);
        this.registerCustomElement('anchor-plate-design', AnchorPlateDesignComponent);
        this.registerCustomElement('app-settings-comd-fixing', AppSettingsComdFixingComponent);
        this.registerCustomElement('app-settings-concrete-fixing', AppSettingsConcreteFixingComponent);
        this.registerCustomElement('app-settings-handrail-fixing', AppSettingsHandrailFixingComponent);
        this.registerCustomElement('app-settings-masonry-fixing', AppSettingsMasonryFixingComponent);
        this.registerCustomElement('asad-optimize-details-modal', AsadOptimizeDetailsModalComponent);
        this.registerCustomElement('asad-optimize-modal', AsadOptimizeModalComponent);
        this.registerCustomElement('asad-optimize-scope-check-details-modal', AsadOptimizeScopeCheckDetailsModalComponent);
        this.registerCustomElement('automatic-torque-popup', AutomaticTorquePopupComponent);
        this.registerCustomElement('baseplate-design-gl-model', BaseplateDesignGlModelComponent);
        this.registerCustomElement('baseplate-results-preference', BaseplateResultsPreferenceComponent);
        this.registerCustomElement('baseplate-size', BaseplateSizeComponent);
        this.registerCustomElement('baseplate-utilizations', BaseplateUtilizationsComponent);
        this.registerCustomElement('brick-size', BrickSizeComponent);
        this.registerCustomElement('building-type-customize-details', BuildingTypeCustomizeDetailsComponent);
        this.registerCustomElement('cad-export', CadExportComponent);
        this.registerCustomElement('concrete-utilizations', ConcreteUtilizationsComponent);
        this.registerCustomElement('custom-stiffener-location-info', CustomStiffenerLocationInfoComponent);
        this.registerCustomElement('custom-stiffener-position-info', CustomStiffenerPositionInfoComponent);
        this.registerCustomElement('custom-stiffener-wizard', CustomStiffenerWizardComponent);
        this.registerCustomElement('custom-stiffeners', CustomStiffenersComponent);
        this.registerCustomElement('default-calculation-method', DefaultCalculationMethodComponent);
        this.registerCustomElement('define-anchors', DefineAnchorsComponent);
        this.registerCustomElement('deflection-limits', DeflectionLimitsComponent);
        this.registerCustomElement('design-section', DesignSectionComponent);
        this.registerCustomElement('detailed-calculation-output', DetailedCalculationOutputComponent);
        this.registerCustomElement('dlubal-export', DlubalExportComponent);
        this.registerCustomElement('dlubal-import', DlubalImportComponent);
        this.registerCustomElement('etabs-import', EtabsImportComponent);
        this.registerCustomElement('export-report', ExportReportComponent);
        this.registerCustomElement('export-report-row', ExportReportRowComponent);
        this.registerCustomElement('fatigue-loads-info', FatigueLoadsInfoComponent);
        this.registerCustomElement('fill-holes-modal', FillHolesModalComponent);
        this.registerCustomElement('generate-report-follow-up-actions-popup', GenerateReportFollowUpActionsPopupComponent);
        this.registerCustomElement('gl-model', GlModelComponent);
        this.registerCustomElement('handrail-design', HandrailDesignComponent);
        this.registerCustomElement('head-joint-selection-info-popup', HeadJointSelectionInfoPopupComponent);
        this.registerCustomElement('horizontal-post-profile-connection', HorizontalPostProfileConnectionComponent);
        this.registerCustomElement('import-loads', ImportLoadsComponent);
        this.registerCustomElement('info-section', InfoSectionComponent);
        this.registerCustomElement('input-handrail-loads', InputHandrailLoadsComponent);
        this.registerCustomElement('integrations-notification-popup', IntegrationsNotificationPopupComponent);
        this.registerCustomElement('legend', LegendComponent);
        this.registerCustomElement('loads', LoadsComponent);
        this.registerCustomElement('loads-hna-wizard', LoadsHNAWizardComponent);
        this.registerCustomElement('loads-input', LoadsInputComponent);
        this.registerCustomElement('loads-row', LoadsRowComponent);
        this.registerCustomElement('main', MainComponent);
        this.registerCustomElement('modal-grid', ModalGridComponent);
        this.registerCustomElement('more-info-on-fatigue-modal', MoreInfoOnFatigueModalComponent);
        this.registerCustomElement('more-info-on-sofa-modal', MoreInfoOnSOFAModalComponent);
        this.registerCustomElement('multiple-approvals', MultipleApprovalsComponent);
        this.registerCustomElement('multiselect-anchor', MultiselectAnchorComponent);
        this.registerCustomElement('on-site-tests', OnSiteTestsComponent);
        this.registerCustomElement('profile-size', ProfileSizeComponent);
        this.registerCustomElement('profile-utilizations', ProfileUtilizationsComponent);
        this.registerCustomElement('ram-import-loads', RamImportLoadsComponent);
        this.registerCustomElement('robot-import', RobotImportComponent);
        this.registerCustomElement('sap-import', SapImportComponent);
        this.registerCustomElement('seismic-filling-set-modal', SeismicFillingSetModalComponent);
        this.registerCustomElement('select-anchor', SelectAnchorComponent);
        this.registerCustomElement('select-material', SelectMaterialComponent);
        this.registerCustomElement('select-profile', SelectProfileComponent);
        this.registerCustomElement('shear-load-distribution-type-popup', ShearLoadDistributionTypeComponent);
        this.registerCustomElement('smart-baseplate-design', SmartBaseplateDesignComponent);
        this.registerCustomElement('smart-baseplate-filter', SmartBaseplateFilterComponent);
        this.registerCustomElement('solution-detail', SolutionDetailComponent);
        this.registerCustomElement('staad-pro-import', StaadProImportComponent);
        this.registerCustomElement('steel-calculation-inputs', SteelCalculationInputsComponent);
        this.registerCustomElement('stiffeners-utilizations', StiffenersUtilizationsComponent);
        this.registerCustomElement('stress-check-legend', StressCheckLegendComponent);
        this.registerCustomElement('supplementary-reinforcement-info', SupplementaryReinforcementInfoComponent);
        this.registerCustomElement('utilizations', UtilizationsComponent);
        this.registerCustomElement('weld-curves', WeldCurvesComponent);
        this.registerCustomElement('welds-utilizations', WeldsUtilizationsComponent);
        this.registerCustomElement('whg-info', WhgInfoComponent);
        this.registerCustomElement('windloads', WindloadsComponent);
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @angular-eslint/use-lifecycle-interface, @typescript-eslint/no-empty-function
    public ngDoBootstrap() {
        // Nothing to do.
    }

    private configureTooltip(config: NgbTooltipConfig) {
        config.container = 'body';
        config.triggers = 'hover';
        config.openDelay = 500;
    }

    private registerCustomElement(componentName: string, component: Type<any>) {
        const customElement = createCustomElement(component, { injector: this.injector });
        customElements.define(`pe-${componentName}`, customElement);
    }
}
