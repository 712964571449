import { FasteningOptionEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class FasteningOption extends CodeList {

    public static fromService(codeList: FasteningOptionEntity) {
        return new FasteningOption(CodeList.baseFromService('FasteningOptionEntity', codeList));
    }

    public override cloneDeep(): FasteningOption {
        return super.cloneDeep() as FasteningOption;
    }
}
