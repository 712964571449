<div class="legend-item" *ngIf="hasLoads">
    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-load-icon"></span>
    <p class="name" [innerHtml]="staticLoads"></p>
</div>

<div class="legend-item" *ngIf="hasLoads">
    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-load-icon-blue"></span>
    <p class="name" [innerHtml]="dynamicLoads"></p>
</div>

<div class="legend-item" *ngIf="hasCharacteristicAndSustained">
    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-load-icon-orange"></span>
    <p class="name" [innerHtml]="sustainedTranslation"></p>
</div>

<div class="legend-item" *ngIf="hasLoadsInWorstPosition">
    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-load-icon-load-in-worst-position"></span>
    <p class="name" [innerHtml]="loadsInWorstPosition"></p>
</div>
