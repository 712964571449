<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.Title')"
    [closeButtonId]="'building-type-customize-details-close-button'"
    [closeButtonDisabled]="submitted">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section">
            <div class="section-row">
                <div class="section-column">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.Type')"></p>

                    <div class="control-row">
                        <pe-radio-button
                            [items]="isBridgeRadio.items"
                            [selectedValue]="isBridgeRadio.selectedValue"
                            (selectedValueChange)="isBridgeRadio.selectedValue = $any($event).detail">
                        </pe-radio-button>
                    </div>
                </div>

                <div class="section-column"></div>

                <div class="section-column"></div>
            </div>
        </div>

        <div class="section">
            <div class="section-row">
                <div class="section-column">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.PermanentLoads')"></p>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaPermanentSupTextBox.id"
                            [title]="gammaPermanentSupTextBox.title"
                            [tooltip]="gammaPermanentSupTextBox.tooltip"
                            [value]="gammaPermanentSupTextBox.value"
                            (valueChange)="gammaPermanentSupTextBox.value = $any($event).detail"
                            [minValue]="gammaPermanentSupTextBox.minValue"
                            [maxValue]="gammaPermanentSupTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaPermanentSupTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaPermanentInfTextBox.id"
                            [title]="gammaPermanentInfTextBox.title"
                            [tooltip]="gammaPermanentInfTextBox.tooltip"
                            [value]="gammaPermanentInfTextBox.value"
                            (valueChange)="gammaPermanentInfTextBox.value = $any($event).detail"
                            [minValue]="gammaPermanentInfTextBox.minValue"
                            [maxValue]="gammaPermanentInfTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaPermanentInfTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>
                </div>

                <div class="section-column" [hidden]="isBridgeRadio.selectedValue == true">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.Loads')"></p>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaVarHBuildingTextBox.id"
                            [title]="gammaVarHBuildingTextBox.title"
                            [tooltip]="gammaVarHBuildingTextBox.tooltip"
                            [value]="gammaVarHBuildingTextBox.value"
                            (valueChange)="gammaVarHBuildingTextBox.value = $any($event).detail"
                            [minValue]="gammaVarHBuildingTextBox.minValue"
                            [maxValue]="gammaVarHBuildingTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaVarHBuildingTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaVarVBuildingTextBox.id"
                            [title]="gammaVarVBuildingTextBox.title"
                            [tooltip]="gammaVarVBuildingTextBox.tooltip"
                            [value]="gammaVarVBuildingTextBox.value"
                            (valueChange)="gammaVarVBuildingTextBox.value = $any($event).detail"
                            [minValue]="gammaVarVBuildingTextBox.minValue"
                            [maxValue]="gammaVarVBuildingTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaVarVBuildingTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaVarHWindTextBox.id"
                            [title]="gammaVarHWindTextBox.title"
                            [tooltip]="gammaVarHWindTextBox.tooltip"
                            [value]="gammaVarHWindTextBox.value"
                            (valueChange)="gammaVarHWindTextBox.value = $any($event).detail"
                            [minValue]="gammaVarHWindTextBox.minValue"
                            [maxValue]="gammaVarHWindTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaVarHWindTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>
                </div>

                <div class="section-column" [hidden]="isBridgeRadio.selectedValue == false">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.Loads')"></p>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaVarHBridgeTextBox.id"
                            [title]="gammaVarHBridgeTextBox.title"
                            [tooltip]="gammaVarHBridgeTextBox.tooltip"
                            [value]="gammaVarHBridgeTextBox.value"
                            (valueChange)="gammaVarHBridgeTextBox.value = $any($event).detail"
                            [minValue]="gammaVarHBridgeTextBox.minValue"
                            [maxValue]="gammaVarHBridgeTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaVarHBridgeTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaVarVBridgeTextBox.id"
                            [title]="gammaVarVBridgeTextBox.title"
                            [tooltip]="gammaVarVBridgeTextBox.tooltip"
                            [value]="gammaVarVBridgeTextBox.value"
                            (valueChange)="gammaVarVBridgeTextBox.value = $any($event).detail"
                            [minValue]="gammaVarVBridgeTextBox.minValue"
                            [maxValue]="gammaVarVBridgeTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaVarVBridgeTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="gammaVarHWindBridgeTextBox.id"
                            [title]="gammaVarHWindBridgeTextBox.title"
                            [tooltip]="gammaVarHWindBridgeTextBox.tooltip"
                            [value]="gammaVarHWindBridgeTextBox.value"
                            (valueChange)="gammaVarHWindBridgeTextBox.value = $any($event).detail"
                            [minValue]="gammaVarHWindBridgeTextBox.minValue"
                            [maxValue]="gammaVarHWindBridgeTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="gammaVarHWindBridgeTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>
                </div>

                <div class="section-column">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.SafetyFactors')"></p>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="combiPsi0HTextBox.id"
                            [title]="combiPsi0HTextBox.title"
                            [tooltip]="combiPsi0HTextBox.tooltip"
                            [value]="combiPsi0HTextBox.value"
                            (valueChange)="combiPsi0HTextBox.value = $any($event).detail"
                            [minValue]="combiPsi0HTextBox.minValue"
                            [maxValue]="combiPsi0HTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="combiPsi0HTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="combiPsi0VTextBox.id"
                            [title]="combiPsi0VTextBox.title"
                            [tooltip]="combiPsi0VTextBox.tooltip"
                            [value]="combiPsi0VTextBox.value"
                            (valueChange)="combiPsi0VTextBox.value = $any($event).detail"
                            [minValue]="combiPsi0VTextBox.minValue"
                            [maxValue]="combiPsi0VTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="combiPsi0VTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>

                    <div class="control-row">
                        <pe-numeric-text-box
                            [id]="combiPsi0HWindTextBox.id"
                            [title]="combiPsi0HWindTextBox.title"
                            [tooltip]="combiPsi0HWindTextBox.tooltip"
                            [value]="combiPsi0HWindTextBox.value"
                            (valueChange)="combiPsi0HWindTextBox.value = $any($event).detail"
                            [minValue]="combiPsi0HWindTextBox.minValue"
                            [maxValue]="combiPsi0HWindTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [validators]="[requiredValidator]"
                            (isValid)="combiPsi0HWindTextBox.isValid = $any($event).detail">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'building-type-customize-details-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'building-type-customize-details-save-button'"
            [look]="'Cta'"
            class="modal-button"
            type="submit"
            [disabled]="!formValid || submitted" (buttonClick)="close()">
             <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.Profis3.BuildingTypeCustomizeDetails.Save')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
