import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IProfileSizeComponentInput } from '../../../shared/components/profile-size';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { MainMenuModal } from '../../../shared/entities/main-menu';
import { customizeProfileSizeModal } from '../../../shared/entities/profile-size';
import { ProfileFormingNorme } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { ProfileShapeType } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { IProfileSizeProps, ProfileSize } from '../../components/main-menu/profile-size/ProfileSize';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { updateMainMenuControl, updateUiProperty } from './menu-helper';

export function initializeProfileSize(
    calculationService: CalculationServicePE,
    commonCodeListService: CommonCodeListService,
    design: DesignPe,
    controlProps: IProfileSizeProps,
    localizationService: LocalizationService,
    unitService: UnitService,
    uiPropertyId: number,
    modals: { [modal: number]: (input?: object) => IModalOpened },
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
    displayUiPropertyId?: number,
    displayKey?: string) {

    controlProps.type = ProfileSize;
    controlProps.selectedValue = design.model[uiPropertyId] as number;

    const displayUIProperty = displayUiPropertyId != null ? design.model[displayUiPropertyId] as any : null;
    const displayText = displayKey != null && displayKey != '' ? localizationService.getString(displayKey) : null;
    controlProps.buttonText = displayUIProperty ?? displayText;

    controlProps.clicked = (selectedValue) => {
        const modalOpenFn = modals[MainMenuModal.profileSize];
        const modalInput: IProfileSizeComponentInput = {
            selectedFilter: design.savedProfileSizeFilter ?? ProfileFormingNorme.Unknown,
            selectedValue,
            onSelect: async (value) => {
                updateUiProperty(calculationService, design, uiPropertyId, value);

                // change menu
                setState(menu => updateMainMenuControl<IProfileSizeProps>(menu, controlProps.controlId, { selectedValue: value } as any));
            }
        };
        const profileFamily = uiPropertyId == UIProperty.Profile_HandrailRailProfileSizeId
            ? design.handrailRailProfileFamily
            : design.profileFamily;

        customizeProfileSizeModal(
            modalInput,
            design.designData.designCodeLists,
            design.unitLength,
            design.designType,
            design.properties.get(uiPropertyId),
            localizationService,
            unitService,
            commonCodeListService,
            profileFamily?.shape ?? ProfileShapeType.Unknown
        );
        modalOpenFn(modalInput).result
            .then((value: any) => {
                // save selected filter
                design.savedProfileSizeFilter = value;
            });
    };

    const onStateChanged = (state: IDesignState) => {
        const displayUIProperty = displayUiPropertyId!= null ? design.model[displayUiPropertyId] as any : null;

        return ({
            selectedValue: state.model[uiPropertyId],
            buttonText: displayUIProperty ?? displayText
        } as any) as IProfileSizeProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}
