<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="translate('Agito.Hilti.Profis3.DefineAnchors.CustomLayout')"
    [closeButtonId]="'baseplate-size-close-button'">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section" *ngIf="activeValue != 4">
            <div class="control-row">
                <div class="control-column">
                    <pe-numeric-text-box
                        [id]="'baseplate-count-input-width'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.NumberOfAnchorsX')"
                        [validators]="[requiredValidator]"
                        [value]="numberOfAnchors.x"
                        (valueChange)="numberOfAnchors.x = $any($event).detail; numberOfAnchorsXChanged($any($event).detail)"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="numberOfAnchorsXValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>

                <div class="control-column">
                    <pe-numeric-text-box
                        [id]="'baseplate-size-input-width'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.SpacingX')"
                        [validators]="[requiredValidator]"
                        [value]="spacingOfAnchors.x"
                        (valueChange)="spacingOfAnchors.x = $any($event).detail"
                        [unit]="unitLength"
                        [minValue]="minSpacing"
                        [maxValue]="maxSpacing"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="spacingOfAnchorsXValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>

                <div class="control-column" *ngIf="activeValue == 3">
                    <pe-numeric-text-box
                        [id]="'baseplate-edge-input-width'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.EdgeX')"
                        [validators]="[requiredValidator]"
                        [value]="edgeOfAnchors.x"
                        (valueChange)="edgeOfAnchors.x = $any($event).detail"
                        [unit]="unitLength"
                        [minValue]="minEdge"
                        [maxValue]="maxEdge"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="edgeOfAnchorsXValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>
            </div>

            <div class="control-row">
                <div class="control-column">
                    <pe-numeric-text-box
                        [id]="'baseplate-count-input-height'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.NumberOfAnchorsY')"
                        [validators]="[requiredValidator]"
                        [value]="numberOfAnchors.y"
                        (valueChange)="numberOfAnchors.y = $any($event).detail; numberOfAnchorsYChanged($any($event).detail)"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="numberOfAnchorsYValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>

                <div class="control-column">
                    <pe-numeric-text-box
                        [id]="'baseplate-size-input-height'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.SpacingY')"
                        [validators]="[requiredValidator]"
                        [value]="spacingOfAnchors.y"
                        (valueChange)="spacingOfAnchors.y = $any($event).detail"
                        [unit]="unitLength"
                        [minValue]="minSpacing"
                        [maxValue]="maxSpacing"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="spacingOfAnchorsYValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>

                <div class="control-column" *ngIf="activeValue == 3">
                    <pe-numeric-text-box
                        [id]="'baseplate-edge-input-height'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.EdgeY')"
                        [validators]="[requiredValidator]"
                        [value]="edgeOfAnchors.y"
                        (valueChange)="edgeOfAnchors.y = $any($event).detail"
                        [unit]="unitLength"
                        [minValue]="minEdge"
                        [maxValue]="maxEdge"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="edgeOfAnchorsYValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>
        <div class="section" *ngIf="activeValue == 4">
            <div class="control-row">
                <div class="control-column">
                    <pe-numeric-text-box
                        [id]="'baseplate-size-input-width'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.NumberOfAnchors')"
                        [validators]="[requiredValidator]"
                        [value]="numberOfAnchors.x"
                        (valueChange)="numberOfAnchors.x = $any($event).detail; numberOfAnchorsXChanged($any($event).detail)"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="numberOfAnchors4XValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>

                <div class="control-column">
                    <pe-numeric-text-box
                        [id]="'baseplate-count-input-radius'"
                        [title]="translate('Agito.Hilti.Profis3.DefineAnchors.Radius')"
                        [validators]="[requiredValidator]"
                        [value]="spacingOfAnchors.x"
                        (valueChange)="spacingOfAnchors.x = $any($event).detail"
                        [unit]="unitLength"
                        [updateOnBlur]="true"
                        [required]="true"
                        (isValid)="spacingOfAnchors4XValid = $any($event).detail">
                    </pe-numeric-text-box>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'baseplate-size-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.DefineAnchors.Cancel')"
            >
        </pe-button>

        <pe-button
            [id]="'baseplate-size-save-button'"
            [look]="'Cta'"
            class="modal-button"
            type="submit"
            [text]="translate('Agito.Hilti.Profis3.DefineAnchors.Create')">
        </pe-button>
    </pe-modal-footer>
</form>
