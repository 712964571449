import {
    ChangeDetectorRef, Component, ElementRef, Input, OnInit, TrackByFunction, ViewEncapsulation
} from '@angular/core';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { hasProperty } from '@profis-engineering/pe-ui-common/helpers/object-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { TooltipType } from '@profis-engineering/pe-ui-common/services/code-list.common';
import cloneDeep from 'lodash-es/cloneDeep';
import difference from 'lodash-es/difference';
import escape from 'lodash-es/escape';
import minBy from 'lodash-es/minBy';
import sortBy from 'lodash-es/sortBy';
import selectAnchorTooltipImages from '../../../images/select-anchor-tooltip';
import { ISelectAnchorComponentInput } from '../../../shared/components/select-anchor';
import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import {
    AnchorFilter as AnchorFilterEntity
} from '../../../shared/entities/code-lists/anchor-filter';
import { AnchorFilterGroup } from '../../../shared/entities/code-lists/anchor-filter-group';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    DesignPe,
    ICalculateAllAnchorDetailed as ICalculateAllAnchorDetailedBase
} from '../../../shared/entities/design-pe';

import { IAnchor } from '../../../shared/entities/select-anchor-models';
import {
    CalculationStatus
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    AnchorFilterGroupOperator as GroupOperator
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import { AnchorFilter, UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    CheckboxFilter
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    AnchorFilterGroupType, AnchorFilterType, DesignType as DesignTypeId,
    EmbedmentDepthOptimizationType, InstallationType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    anchorIcons, filterAnchorFilterGroups, filterAnchorFilters, getAnchorIconTooltipTranslationKey, isAnchorFamilyMarkedAsNew, SelectAnchorIcon,
    updateAnchorFamilySortOrder
} from '../../../shared/helpers/anchor-helper';
import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { getProperty } from '../../helpers/object-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';
import { CalculateAllAnchorUiItemType, CalculateAllCheckboxItem, getFilterGroupStyle, getScrollElement, ICalculateAllAnchor, ICalculateAllAnchorDetailed, ICalculateAllAnchorUiItem, ISelectAnchorFilterGroupInfoPopup, SelectAnchorFilterGroup, SelectAnchorFilterGroupItem, SelectAnchorMode, SelectAnchorSortBy } from './select-anchor.common';

@Component({
    templateUrl: './select-anchor.component.html',
    styleUrls: ['./select-anchor.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectAnchorComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<ISelectAnchorComponentInput>;

    public filtersCalculateAllCheckbox!: CheckboxButtonProps<CalculateAllCheckboxItem>;

    public anchors!: IAnchor[];
    public anchorsData!: IAnchor[];
    public calculateAllAnchors: ICalculateAllAnchorUiItem[] = [];
    public sortDropdown!: DropdownProps<SelectAnchorSortBy>;
    public filter!: string;
    public mode!: SelectAnchorMode;
    public calculateAllLoading = false;
    public calculateAllData!: ICalculateAllAnchor[];
    public loaded = false;
    public filterGroups!: SelectAnchorFilterGroup[];
    public progress = 0;
    public selectedAnchors: number[] = [];
    private initializedSpriteImages: string[] = [];

    public fixtureThicknessMin?: number;
    public fixtureThicknessMax?: number;
    public holeDiameterMin?: number;
    public holeDiameterMax?: number;

    public icons = anchorIcons;

    public unitLength: UnitType;
    public selectAnchorModeEnum = SelectAnchorMode;
    public designTypeId = {
        Concrete: DesignTypeId.Concrete,
        Handrail: DesignTypeId.Handrail,
        Masonry: DesignTypeId.Masonry,
        MetalDeck: DesignTypeId.MetalDeck
    };
    public calculateAllAnchorUiItemTypeEnum = CalculateAllAnchorUiItemType;

    public anchorRowHeight = 90;                 // must be the same value as in css
    public calculateAllAnchorRowHeight = 35;     // must be the same value as in css
    public scrollElement!: Element;

    protected design!: DesignPe;
    private originalFilterValue: { [id: string]: boolean | undefined } = {};

    constructor(
        public localization: LocalizationService,
        protected user: UserService,
        protected unit: UnitService,
        protected userSettings: UserSettingsService,
        protected featuresVisibilityInfo: FeaturesVisibilityInfoService,
        protected modal: ModalService,
        protected offlineService: OfflineService,
        protected calculationService: CalculationServicePE,
        protected sharedEnvironmentData: SharedEnvironmentService,
        protected elementRef: ElementRef<HTMLElement>,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
        this.unitLength = this.unit.getDefaultUnit(UnitGroup.Length);
    }

    public get selectedAnchorFamilyId() {
        if (!this.design.model[UIProperty.SmartAnchor_Enabled]) {
            return this.design.model[this.design.globalMetaProperties.sourceMetaProperty] as number;
        }

        return 0;
    }

    public set selectedAnchorFamilyId(value: number) {
        this.design.model[this.design.globalMetaProperties.sourceMetaProperty] = value;
    }

    public get selectedAnchorFamilySizeId() {
        return this.design.model[PropertyMetaData.AnchorLayout_Size.id] as number;
    }
    public set selectedAnchorFamilySizeId(value: number) {
        this.design.model[PropertyMetaData.AnchorLayout_Size.id] = value;
    }

    public get embedmentDepthVariable() {
        return this.design.model[PropertyMetaData.AnchorLayout_EmbedmentDepthVariable.id] as number;
    }
    public set embedmentDepthVariable(value: number) {
        this.design.model[PropertyMetaData.AnchorLayout_EmbedmentDepthVariable.id] = value;
    }

    public get embedmentDepthType() {
        return this.design.model[PropertyMetaData.AnchorLayout_EmbedmentDepthOptimizationType.id] as number;
    }

    public get isEmbedmentDepthVariableHidden() {
        const item = this.design.properties.properties[PropertyMetaData.AnchorLayout_EmbedmentDepthVariable.id];
        return item !== undefined ? item.hidden : true;
    }

    public get isEmbedmentDepthVariableDisabled() {
        const item = this.design.properties.properties[PropertyMetaData.AnchorLayout_EmbedmentDepthVariable.id];
        return item !== undefined ? item.disabled : true;
    }

    public get calculateAllButtonKey() {
        return 'Agito.Hilti.Profis3.SelectAnchor.' + (this.mode == null || this.mode == SelectAnchorMode.normal
            ? 'CalculateAll'
            : 'CalculateAllClose');
    }

    public get filterPlaceholder() {
        return this.translate(`Agito.Hilti.Profis3.SelectAnchor.${this.mode == null || this.mode == SelectAnchorMode.normal
            ? 'FilterInputPlaceholder'
            : 'FilterInputCalculateAllPlaceholder'}`);
    }

    public get designType() {
        return this.design.designType;
    }

    public get calculateAllDisabled() {
        return this.design.designData.reportData?.CalculateAllDisabled || this.calculateAllLoading;
    }

    public get getCalcAllTooltip() {
        return this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation
            ? this.translate('Agito.Hilti.Profis3.InfoSection.AnchorSection.CalculateAll.Tooltip.IdeaCalculation')
            : null;
    }

    public get anchorFamily() {
        const anchors = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];
        return anchors.find((anchor) => anchor.id == this.selectedAnchorFamilyId);
    }

    public get favoriteTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Menu_Favorites) ||
            this.translate('Agito.Hilti.Profis3.Main.Region.AddRemoveFromFavorites');
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-info-tooltip',
            'sprite-x-circle',
            'sprite-anchor-family-no-photo-available',
            'sprite-anchor-no-cleaning-required',
            'sprite-anchor-approved-automatic-cleaning',
            'sprite-anchor-tensile-zone-cracked-concrete',
            'sprite-anchor-fatigue',
            'sprite-anchor-shock',
            'sprite-anchor-seismic',
            'sprite-anchor-small-edge-dist-and-spacing',
            'sprite-anchor-variable-embedment-depth',
            'sprite-anchor-npp-approval-di-bt',
            'sprite-anchor-fire-resistant',
            'sprite-anchor-clean-tech',
            'sprite-anchor-stainless-steel-hcr',
            'sprite-anchor-shallow-embedment-depth',
            'sprite-anchor-diamond-cored-holes-suitable',
            'sprite-favorite-true',
            'sprite-favorite-false',
            'sprite-notification-ok',
            'sprite-notification-alert'
        );

        this.setModalInstanceClosing();

        this.scrollElement = getScrollElement(this.user.design);

        // Controls
        this.filtersCalculateAllCheckbox = {
            items: [
                {
                    value: CalculateAllCheckboxItem.ExcludeFailures,
                    text: this.translate('Agito.Hilti.Profis3.SelectAnchor.ExcludeFailures')
                },
                {
                    value: CalculateAllCheckboxItem.IncludeOnlyMinimum,
                    text: this.translate('Agito.Hilti.Profis3.SelectAnchor.IncludeOnlyMinimum')
                }
            ],
            selectedValues: new Set()
        };

        this.sortDropdown = {
            id: 'select-anchor-sort-anchor-sort-by-dropdown',
            items: [
                {
                    text: this.translate('Agito.Hilti.Profis3.SelectAnchor.SortBy.Default'),
                    value: SelectAnchorSortBy.default
                },
                {
                    text: this.translate('Agito.Hilti.Profis3.SelectAnchor.SortBy.Name'),
                    value: SelectAnchorSortBy.name
                }
            ],
            selectedValue: SelectAnchorSortBy.default
        };

        this.design = this.user.design;

        // load filters
        const anchorFilters = this.design.designData.designCodeLists[DesignCodeList.AnchorFilter] as AnchorFilterEntity[];
        const anchorFilterGroups = this.getFilterGroups(this.user.design);

        // load saved filters
        const savedAnchorFilters = this.design.model[PropertyMetaData.AnchorLayout_AnchorFilters.id] as AnchorFilter;

        const savedAnchorCheckboxFilters: { [id: number]: CheckboxFilter } =
            Object.fromEntries(savedAnchorFilters.CheckboxFilters.map(checkboxFilter => [checkboxFilter.Id, checkboxFilter]));

        this.fixtureThicknessMin = savedAnchorFilters.MinMaxFilters.FixtureThicknesMin;
        this.fixtureThicknessMax = savedAnchorFilters.MinMaxFilters.FixtureThicknesMax;
        this.holeDiameterMin = savedAnchorFilters.MinMaxFilters.HoleDiameterMin;
        this.holeDiameterMax = savedAnchorFilters.MinMaxFilters.HoleDiameterMax;

        // create filter structure
        const filteredAnchorFilters = filterAnchorFilters(anchorFilters, savedAnchorFilters);
        const filteredAnchorFilterGroups = filterAnchorFilterGroups(anchorFilterGroups, filteredAnchorFilters, this.designType.id);

        this.filterGroups = filteredAnchorFilterGroups.map((anchorFilterGroup) => new SelectAnchorFilterGroup({
            id: anchorFilterGroup.id,
            titleKey: anchorFilterGroup.nameResourceKey,
            groupOperator: anchorFilterGroup.groupOperator,
            items: filteredAnchorFilters.filter((anchorFilter) => anchorFilter.anchorFilterGroupId == anchorFilterGroup.id).map((anchorFilter) => new SelectAnchorFilterGroupItem({
                id: anchorFilter.id,
                checkbox: SimpleCheckboxButtonHelper.createSimpleCheckbox({
                    id: `select-anchor-${anchorFilter.id}-checkbox`,
                    itemText: this.translate(anchorFilter.nameResourceKey ?? ''),
                    disabled: savedAnchorFilters.CheckboxFiltersDisabled.some(disabled => disabled.Id == anchorFilter.id),
                    checked: savedAnchorCheckboxFilters[anchorFilter.id ?? -1] != null
                })
            })),
            infoPopup: this.getAnchorFilterGroupInfoPopup(anchorFilterGroup)
        }));

        // track used filters
        this.trackFilters();

        this.unlockInstallationTypeFilter();

        // load anchor family detailed
        let anchors = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

        const allowedValues = this.design.properties.get(PropertyMetaData.AnchorLayout_AnchorFamily.id).allowedValues;
        if (allowedValues != null) {
            anchors = anchors.filter((anchor) => allowedValues.includes(anchor.id ?? 0));
        }

        const anchorIds = anchors.map((anchor) => anchor.id);
        this.setAnchorsData(anchorIds);

        this.refreshAnchors();

        this.loaded = true;

        if (this.modalInstance.input?.calculateAll) {
            this.onCalculateAllButtonClick();
        }
    }

    public setModalInstanceClosing() {
        // don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            if (this.calculateAllLoading) {
                return false;
            }

            this.setFiltersToProperties();
            this.calculationService.calculateAsync(this.design);
            return true;
        });
    }

    protected getFilterGroups(design: DesignPe): AnchorFilterGroup[] {
        return design.designData.designCodeLists[DesignCodeList.AnchorFilterGroup] as AnchorFilterGroup[];
    }

    public getFilterItemCheckbox(checkbox: CheckboxButtonProps<boolean> | undefined) {
        return checkbox as CheckboxButtonProps<boolean>;
    }

    public getProperty(object: object, property: string) {
        return getProperty(object, property);
    }

    public identifyFilterGroup: TrackByFunction<SelectAnchorFilterGroup> = (_: number, filterGroup: SelectAnchorFilterGroup) => {
        return filterGroup.id;
    };

    public identifyFilterGroupItem: TrackByFunction<SelectAnchorFilterGroupItem> = (_: number, filterGroupItem: SelectAnchorFilterGroupItem) => {
        return filterGroupItem.id;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public identifyAnchor: TrackByFunction<IAnchor> = (index: number, _: IAnchor) => {
        // Returning index (inside virtual scroll's viewport) to ensure
        // the same components are being reused (performance improvement!).
        return index;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public identifyCalculateAllAnchor: TrackByFunction<ICalculateAllAnchorUiItem> = (index: number, _: ICalculateAllAnchorUiItem) => {
        // Returning index (inside virtual scroll's viewport) to ensure
        // the same components are being reused (performance improvement!).
        return index;
    };

    public identifyIcon: TrackByFunction<any> = (index: number) => {
        return index;
    };

    public isTscOk(anchorDetailed?: ICalculateAllAnchorDetailed) {
        return anchorDetailed && anchorDetailed.t <= 100 && anchorDetailed.s <= 100 && anchorDetailed.c <= 100;
    }

    public getMaxTsc(anchorDetailed?: ICalculateAllAnchorDetailedBase) {
        const max = Math.max(anchorDetailed?.t ?? -1, anchorDetailed?.s ?? -1, anchorDetailed?.c ?? -1);
        if (max < 0) {
            return '';
        }

        return this.formatPercentage(max);
    }

    public isAnchorSelected(anchor: IAnchor) {
        return this.selectedAnchors.includes(anchor.id);
    }

    public async selectAnchor(anchor: IAnchor) {
        this.setFiltersToProperties();
        this.selectedAnchorFamilyId = anchor.id;
        await this.calculationService.calculateAsync(this.design);
        if (this.modalInstance.input?.onSelect != null) {
            this.modalInstance.input.onSelect(this.selectedAnchorFamilyId);
        }
        this.close();
    }

    public onCalculateAllButtonClick() {
        if (!this.offlineService.isOffline) {
            if (this.mode == null || this.mode == SelectAnchorMode.normal) {
                this.mode = SelectAnchorMode.calculateAll;

                this.design.usageCounter.CalculateAll++;
                this.lockInstallationTypeFilter();
                this.calculateAllSignalR();
            }
            else {
                this.mode = SelectAnchorMode.normal;

                this.unlockInstallationTypeFilter();
                this.refreshAnchors();
            }
        }
        else {
            this.modal.openConfirmChange({
                id: 'DesktopCalculateAllNotSupported',
                title: this.localization.getString('Agito.Hilti.Profis3.CalculateAll.Desktop.Title'),
                message: this.localization.getString('Agito.Hilti.Profis3.CalculateAll.Desktop.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                onConfirm: (modal) => {
                    modal.close();
                }
            });
        }
    }

    public onClearAllButtonClick() {
        this.filter = '';
        this.fixtureThicknessMax = undefined;
        this.fixtureThicknessMin = undefined;
        this.holeDiameterMax = undefined;
        this.holeDiameterMin = undefined;
        this.filtersCalculateAllCheckbox.selectedValues?.clear();
        this.sortDropdown.selectedValue = SelectAnchorSortBy.default;

        for (const filterGroup of this.filterGroups) {
            for (const filterItem of filterGroup.items ?? []) {
                SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(filterItem.checkbox as CheckboxButtonProps<boolean>, false);
            }
        }

        this.refreshAnchors();
        this.refreshCalculateAllAnchors();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public async selectAnchorDetailed(anchorId: number, anchorDetailed?: ICalculateAllAnchorDetailed) {
        this.selectedAnchorFamilyId = anchorId;
        this.selectedAnchorFamilySizeId = anchorDetailed?.id as number;
        this.setFiltersToProperties();

        // if embedment depth is user selected, update it to selected value
        if (this.embedmentDepthType == EmbedmentDepthOptimizationType.UserSelected) {
            this.embedmentDepthVariable = anchorDetailed?.embedmentDepth as number;
        }

        await this.calculationService.calculateAsync(this.design);

        if (this.modalInstance.input?.onSelect != null) {
            this.modalInstance.input.onSelect(this.selectedAnchorFamilyId, this.selectedAnchorFamilySizeId, this.embedmentDepthVariable);
        }

        this.close();
    }

    public getIconTooltip(icon: SelectAnchorIcon) {
        const isHNABased = isHnaBasedDesignStandard(this.design.designStandard.id);
        const key = getAnchorIconTooltipTranslationKey(icon, isHNABased);

        return this.translate(key);
    }

    public getFavoriteSprite(anchorId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design.region.id)) {
            return 'pe-ui-pe-sprite-favorite-false disabled';
        }

        return hasProperty(this.userSettings?.settings?.anchorFavorites?.value ?? {}, anchorId.toString())
            ? 'pe-ui-pe-sprite-favorite-true'
            : 'pe-ui-pe-sprite-favorite-false';
    }

    public favoriteToggle(anchorId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.design.region.id)) {
            return;
        }

        if (hasProperty(this.userSettings?.settings?.anchorFavorites?.value ?? {}, anchorId.toString())) {
            this.userSettings.settings.anchorFavorites.value = Object.fromEntries(Object.entries(
                this.userSettings?.settings?.anchorFavorites?.value ?? {}).filter(([favoriteId]) => favoriteId != anchorId.toString()));
        }
        else {
            this.userSettings.settings.anchorFavorites.value = {
                ...this.userSettings.settings.anchorFavorites.value,
                [anchorId]: undefined
            };
        }

        this.refreshAnchors();
        this.refreshCalculateAllAnchors();

        this.userSettings.save();
    }

    public close() {
        this.modalInstance.close();
    }

    public resetFilter() {
        this.onFilterChange('');
    }

    public onFilterChange(filter: string) {
        this.filter = filter;

        this.refreshAnchors();
        this.refreshCalculateAllAnchors();
    }

    public filterInputValueChanged(
        numberValue: number,
        property: 'fixtureThicknessMin' | 'fixtureThicknessMax' | 'holeDiameterMin' | 'holeDiameterMax'
    ) {
        // set other value if needed
        switch (property) {
            case 'fixtureThicknessMin': {
                const oldFixtureThicknessMin = this.fixtureThicknessMin;
                this.fixtureThicknessMin = numberValue;

                if (numberValue != null && this.fixtureThicknessMax != null && numberValue > this.fixtureThicknessMax) {
                    this.fixtureThicknessMax = numberValue;
                }

                this.onMinMaxFixtureThicknessChange(numberValue, oldFixtureThicknessMin);
            }
                break;

            case 'fixtureThicknessMax': {
                const oldFixtureThicknessMax = this.fixtureThicknessMax;
                this.fixtureThicknessMax = numberValue;

                if (numberValue != null && this.fixtureThicknessMin != null && numberValue < this.fixtureThicknessMin) {
                    this.fixtureThicknessMin = numberValue;
                }

                this.onMinMaxFixtureThicknessChange(numberValue, oldFixtureThicknessMax);
            }
                break;

            case 'holeDiameterMin': {
                const oldHoleDiameterMin = this.holeDiameterMin;
                this.holeDiameterMin = numberValue;

                if (numberValue != null && this.holeDiameterMax != null && numberValue > this.holeDiameterMax) {
                    this.holeDiameterMax = numberValue;
                }

                this.onMinMaxHoleDiameterChange(numberValue, oldHoleDiameterMin);
            }
                break;

            case 'holeDiameterMax': {
                const oldHoleDiameterMax = this.holeDiameterMax;
                this.holeDiameterMax = numberValue;

                if (numberValue != null && this.holeDiameterMin != null && numberValue < this.holeDiameterMin) {
                    this.holeDiameterMin = numberValue;
                }

                this.onMinMaxHoleDiameterChange(numberValue, oldHoleDiameterMax);
            }
                break;
        }
    }

    public onFilterCheckboxChange(filterItem?: SelectAnchorFilterGroupItem, filterGroup?: SelectAnchorFilterGroup) {
        this.refreshAnchors();
        this.refreshCalculateAllAnchors();

        // tracking
        if (filterItem && filterGroup) {
            this.trackFilters();
        }
    }

    public onSortChange(value: SelectAnchorSortBy) {
        this.sortDropdown.selectedValue = value;
        this.refreshAnchors();
        this.refreshCalculateAllAnchors();
    }

    public openFilterGroupInfoPopup(filterGroup: SelectAnchorFilterGroup) {
        let rawContent = filterGroup.infoPopup?.content;

        if (selectAnchorTooltipImages != null) {
            rawContent = rawContent?.replace(/SelectAnchorTooltip\//g, '')
                ?.replace(/agt-img="(.*?)"/g, (_substring, name) => `src="${selectAnchorTooltipImages[name]}"`);
        }

        this.modal.openConfirmChange({
            id: 'tooltip-popup',
            title: filterGroup.infoPopup?.title,
            message: rawContent,
            additionalStyles: getFilterGroupStyle(),
            confirmButtonText: this.translate('Agito.Hilti.Profis3.ControlTooltip.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    private lockInstallationTypeFilter() {
        const installationTypeFilterGroup = this.filterGroups.find((filterGroup) => filterGroup.id == AnchorFilterGroupType.InstallationType);

        if (installationTypeFilterGroup != null) {
            for (const filter of installationTypeFilterGroup.items as SelectAnchorFilterGroupItem[]) {
                const installationType = this.getFilterInstallationType(filter);
                this.originalFilterValue[filter.id as number] = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filter.checkbox as CheckboxButtonProps<boolean>);
                (filter.checkbox as CheckboxButtonProps<boolean>).disabled = true;
                SimpleCheckboxButtonHelper.setSimpleCheckboxChecked((filter.checkbox as CheckboxButtonProps<boolean>), this.anchorFamily?.detailed?.installationType == installationType);
            }
        }
    }

    protected unlockInstallationTypeFilter() {
        const installationTypeFilterGroup = this.filterGroups.find((filterGroup) => filterGroup.id == AnchorFilterGroupType.InstallationType);

        if (installationTypeFilterGroup != null) {
            for (const filter of installationTypeFilterGroup.items as SelectAnchorFilterGroupItem[]) {
                (filter.checkbox as CheckboxButtonProps<boolean>).disabled = false;
                if (this.originalFilterValue[filter.id as number] != undefined) {
                    SimpleCheckboxButtonHelper.setSimpleCheckboxChecked((filter.checkbox as CheckboxButtonProps<boolean>), this.originalFilterValue[filter.id as number] ?? false);
                }
            }
        }

        this.originalFilterValue = {};
    }

    private getFilterInstallationType(filter: SelectAnchorFilterGroupItem) {
        switch (filter.id) {
            case AnchorFilterType.PostInstalled:
                return InstallationType.PostInstalled;
            case AnchorFilterType.CastInPlace:
                return InstallationType.CastIn;
            default:
                throw new Error('Unknown installation type.');
        }
    }

    private calculateAllSignalR() {
        this.calculateAllLoading = true;
        this.progress = 0;

        this.calculationService.calculateAllSignalR(this.design,
            (prog: any) => {
                this.progress = Math.round(prog.Progress);
                this.changeDetectorRef.detectChanges();
            })
            .then(() => {
                this.setDesignCalculateAll();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.mode = SelectAnchorMode.normal;
            })
            .finally(() => {
                this.calculateAllLoading = false;
            });
    }

    private setDesignCalculateAll() {
        this.calculateAllData = (cloneDeep(this.design.designData.calculateAllData) as any) || [];

        // set other properties
        for (const anchor of this.calculateAllData) {
            const displayAnchor = this.anchorsData.find((a) => a.id == anchor.id);
            anchor.description = displayAnchor?.description ?? '';
            anchor.defaultSortOrder = displayAnchor?.defaultSortOrder ?? 0;

            // detailed anchors
            for (const detailed of anchor.detailed) {
                detailed.embedmentDepthFormated = this.formatLength(detailed.embedmentDepth);
                detailed.isGeometryOk = detailed.isAnchorPlateSizeOk && detailed.isAnchorPlatePositionOk && detailed.isEdgeDistanceOk && detailed.isAxialSpacingOk && detailed.isBaseMaterialThicknessOk;

                if (detailed.calculationStatus == CalculationStatus.OK) {
                    detailed.tFormated = this.formatPercentage(detailed.t);
                    detailed.sFormated = this.formatPercentage(detailed.s);
                    detailed.cFormated = this.formatPercentage(detailed.c);
                }
                else {
                    // TODO: mark as nullable in interface!
                    detailed.t = null as unknown as number;
                    detailed.s = null as unknown as number;
                    detailed.c = null as unknown as number;
                }
            }
        }

        this.refreshCalculateAllAnchors();
    }

    private formatPercentage(value: number) {
        if (value == null) {
            return '';
        }

        return this.unit.formatNumber(value, 2) + '%';
    }

    private formatLength(internalValue: number) {
        if (internalValue == null) {
            return '';
        }

        const internalUnit = this.unit.getInternalUnit(UnitGroup.Length);
        const defaultUnit = this.unitLength;

        const defaultValue = this.unit.convertUnitValueArgsToUnit(internalValue, internalUnit, defaultUnit);

        return this.unit.formatUnitValueArgs(defaultValue, defaultUnit);
    }

    private filterFailures(anchors: ICalculateAllAnchor[]) {
        return anchors.filter(anchor => {
            anchor.detailed = anchor.detailed.filter(detail => detail.c <= 100 && detail.t <= 100 && detail.s <= 100 && detail.calculationStatus == CalculationStatus.OK);
            return anchor.detailed.length > 0;
        });
    }

    private filterMinimum(anchors: ICalculateAllAnchor[]) {
        return anchors.filter(anchor => {
            const min = minBy(anchor.detailed, detail => detail.diameter)?.diameter;
            anchor.detailed = anchor.detailed.filter(detail => detail.diameter == min);
            return anchor.detailed.length > 0;
        });
    }

    private isAllowedInOrGroups(orGroups: SelectAnchorFilterGroup[], allowed?: AnchorFilterType[]) {
        for (const orGroup of orGroups) {
            const allowedGroup = orGroup.items?.filter((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox as CheckboxButtonProps<boolean>)).map((item) => item.id);

            if (difference(allowedGroup, allowed ?? []).length == allowedGroup?.length) {
                return false;
            }
        }

        return true;
    }

    private isAllowedInAndGroups(andGroups: SelectAnchorFilterGroup[], allowed?: AnchorFilterType[]) {
        for (const andGroup of andGroups) {
            const allowedGroup = andGroup.items?.filter((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox as CheckboxButtonProps<boolean>)).map((item) => item.id);

            if (difference(allowedGroup, allowed ?? []).length != 0) {
                return false;
            }
        }

        return true;
    }

    private filterByCheckboxes(anchors: IAnchor[]) {
        const filteredAnchors: IAnchor[] = [];

        const selectedAnchorGroups = this.filterGroups.filter((filterGroup) => filterGroup.items?.some((filterItem) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filterItem.checkbox as CheckboxButtonProps<boolean>)));
        const orGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.Or);
        const andGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.And);

        for (const anchor of anchors) {
            if (orGroups.length == 0 && andGroups.length == 0) {
                filteredAnchors.push(anchor);
            }
            else {
                const allowed = anchor.allowedInAnchorFilters;

                // is allowed in 'or' groups || is allowed in 'and' groups
                const isAnchorAllowed = this.isAllowedInOrGroups(orGroups, allowed) && this.isAllowedInAndGroups(andGroups, allowed);

                if (isAnchorAllowed) {
                    filteredAnchors.push(anchor);
                }
            }
        }

        return filteredAnchors;
    }

    private filterBySearch(anchors: IAnchor[], filter: string) {
        if (this.filter == null || this.filter.trim() == '') {
            return anchors;
        }

        const filteredAnchors: IAnchor[] = [];
        const lowerCaseFilter = filter.toLowerCase();

        for (const anchor of anchors) {
            const containsName = anchor.name != null ? anchor.name.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;
            const containsDescription = anchor.description != null ? anchor.description.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;

            if (containsName || containsDescription) {
                filteredAnchors.push(anchor);
            }
        }

        return filteredAnchors;
    }

    private filterCalculateAllBySearch(anchors: ICalculateAllAnchor[], filter: string) {
        if (this.isEmpty(this.filter)) {
            return anchors;
        }

        const filteredAnchors: ICalculateAllAnchor[] = [];
        const lowerCaseFilter = filter.toLowerCase();

        for (const anchor of anchors) {
            // anchor detailed filter
            const detailed = anchor.detailed.filter((anchorDetailed) => {
                const containsName = anchorDetailed.name != null ? anchorDetailed.name.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;
                const containsSize = anchorDetailed.size != null ? anchorDetailed.size.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;
                const containsEmbedmentDepth = anchorDetailed.embedmentDepth != null ? anchorDetailed.embedmentDepthFormated.toLowerCase().indexOf(lowerCaseFilter) > -1 : false;

                return containsName || containsSize || containsEmbedmentDepth;
            });

            // anchor filter
            if (detailed.length > 0 || anchor.name.toLowerCase().indexOf(lowerCaseFilter) > -1) {
                filteredAnchors.push(anchor);

                anchor.detailed = detailed;
            }
        }

        return filteredAnchors;
    }

    private filterCalculateAllByCheckboxes(anchors: ICalculateAllAnchor[]) {
        const filteredAnchors: ICalculateAllAnchor[] = [];

        const selectedAnchorGroups = this.filterGroups.filter((filterGroup) => filterGroup.items?.some((filterItem) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filterItem.checkbox as CheckboxButtonProps<boolean>)));
        const orGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.Or);
        const andGroups = selectedAnchorGroups.filter((filterGroup) => filterGroup.groupOperator == GroupOperator.And);

        for (const anchor of anchors) {
            if (orGroups.length == 0 && andGroups.length == 0) {
                filteredAnchors.push(anchor);
            }
            else {
                const detailed = anchor.detailed.filter(anchorDetailed => {
                    const allowed = anchorDetailed.allowedInAnchorFilters;

                    // is allowed in 'or' groups || is allowed in 'and' groups
                    return this.isAllowedInOrGroups(orGroups, allowed) && this.isAllowedInAndGroups(andGroups, allowed);
                });

                // anchor filter
                if (detailed.length > 0) {
                    filteredAnchors.push(anchor);

                    anchor.detailed = detailed;
                }
            }
        }

        return filteredAnchors;
    }

    private sortAnchorFamilyBy(anchors: IAnchor[], selectAnchorSortBy: SelectAnchorSortBy) {
        if (selectAnchorSortBy == null) {
            return anchors;
        }

        switch (selectAnchorSortBy) {
            case SelectAnchorSortBy.default:
                anchors = sortBy(anchors,
                    anchor => hasProperty(this.userSettings?.settings?.anchorFavorites?.value ?? {}, anchor.id.toString()) ? 0 : 1,
                    anchor => anchor.defaultSortOrder);
                break;

            case SelectAnchorSortBy.name:
                anchors = sortBy(anchors,
                    anchor => hasProperty(this.userSettings?.settings?.anchorFavorites?.value ?? {}, anchor.id.toString()) ? 0 : 1,
                    anchor => anchor.name.toLowerCase());
                break;

            default:
                throw new Error('Invalid sort by.');
        }

        // Update sort order for anchor filter list in case of smart anchor tab
        if (this.user.design.menuTabSelected == 'tab-SmartAnchorTab') {
            anchors = updateAnchorFamilySortOrder(anchors, this.user.design);
        }

        return anchors;
    }

    private sortCalculateAllBy(calculateAllAnchors: ICalculateAllAnchor[], selectAnchorSortBy: SelectAnchorSortBy) {
        if (selectAnchorSortBy == null) {
            return calculateAllAnchors;
        }

        switch (selectAnchorSortBy) {
            case SelectAnchorSortBy.default:
                calculateAllAnchors = sortBy(calculateAllAnchors,
                    anchor => hasProperty(this.userSettings.settings.anchorFavorites.value ?? {}, anchor.id.toString()) ? 0 : 1,
                    anchor => anchor.defaultSortOrder);
                break;

            case SelectAnchorSortBy.name:
                calculateAllAnchors = sortBy(calculateAllAnchors,
                    // eslint-disable-next-line no-prototype-builtins
                    anchor => hasProperty(this.userSettings.settings.anchorFavorites.value ?? {}, anchor.id.toString()) ? 0 : 1,
                    anchor => anchor.name.toLowerCase());
                break;

            default:
                throw new Error('Invalid sort by.');
        }

        // Update sort order for anchor filter list in case of smart anchor tab
        if (this.user.design.menuTabSelected == 'tab-SmartAnchorTab') {
            calculateAllAnchors = updateAnchorFamilySortOrder(calculateAllAnchors, this.user.design);
        }

        return calculateAllAnchors;
    }

    protected setAnchorsData(anchorIds: (number | undefined)[]) {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

        const anchors = (codeList)
            .filter((anchor) => anchorIds.some((anchorId) => anchorId == anchor.id) && anchor.detailed != undefined && anchor.detailed != null);
        const codeListDeps = getCodeListTextDeps(this.localization);

        this.anchorsData = anchors.map((anchor): IAnchor => ({
            id: anchor.id as number,
            name: anchor.getTranslatedNameText(codeListDeps) as string,
            image: anchor.image as string,
            allowedInAnchorFilters: anchor.detailed?.allowedInAnchorFilters,
            defaultSortOrder: anchor.detailed?.defaultSortOrder,
            holeDiameters: anchor.detailed?.holeDiameters ?? [],
            fixtureThicknesMin: anchor.detailed?.fixtureThicknesMin as number,
            fixtureThicknesMax: anchor.detailed?.fixtureThicknesMax as number,
            description: this.formatDescription(anchor.detailed?.descriptionFormat ?? '', anchor.detailed?.descriptionKeys ?? []),
            isNoCleaningRequired: this.getBoolean(anchor.detailed?.isZeroCleaningAllowed),
            isAutomaticCleaningApproved: this.getBoolean(anchor.detailed?.isAutomaticCleaningApproved),
            isCrackedConcreteAllowed: this.getBoolean(anchor.detailed?.isCrackedConcreteAllowed),
            isFatigueAllowed: this.getBoolean(anchor.detailed?.isFatigueAllowed),
            isShock: this.getBoolean(anchor.detailed?.isShock),
            isSeismicAllowed: this.getBoolean(anchor.detailed?.isSeismicAllowed),
            isSmallEdgeDistAndSpacing: this.getBoolean(anchor.detailed?.isSmallEdgeDistanceAndSpacing),
            isVariableEmbedment: this.getBoolean(anchor.detailed?.isVariableEmbedment),
            isNPPApprovalDiBt: this.getBoolean(anchor.detailed?.isNuclearApproved),
            isFireAllowed: this.getBoolean(anchor.detailed?.isFireAllowed),
            isCleanTech: this.getBoolean(anchor.detailed?.isCleanTech),
            isStainlessSteelOrHCRAvailable: this.getBoolean(anchor.detailed?.isStainlessSteelOrHCRAvailable),
            isShallowEmbedmentDepth: this.getBoolean(anchor.detailed?.isShallowEmbedmentDepth),
            isDiamondCoredHolesSuitable: this.getBoolean(anchor.detailed?.isDiamondDrillingSuitable),
            tag: anchor.detailed?.tag != null && anchor.detailed?.tag.length > 0
                ? this.translate(anchor.detailed.tag)
                : '',
            tested: anchor.detailed?.tested ?? false,
            isNew: this.getBoolean(isAnchorFamilyMarkedAsNew(anchor.id as number, this.design)),
            internalPortfolioOnly: this.getBoolean(anchor.internalPortfolioOnly)
        }));
    }

    protected filterAnchors(noSearchFilter?: boolean) {
        let anchors = this.anchorsData.slice();

        // allowed values
        const allowedValues = this.design.properties.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            anchors = anchors.filter((anchor) => allowedValues.includes(anchor.id));
        }

        // search filter
        if (!noSearchFilter) {
            anchors = this.filterBySearch(anchors, this.filter);
        }

        // search filter - min max
        anchors = this.filterByMinMax(anchors);

        // checkbox filter
        anchors = this.filterByCheckboxes(anchors);

        // sort by
        anchors = this.sortAnchorFamilyBy(anchors, this.sortDropdown.selectedValue as SelectAnchorSortBy);

        return anchors;
    }

    private filterByMinMax(anchors: IAnchor[]) {
        return anchors.filter((anchor) => {
            if (this.holeDiameterMin != null && this.holeDiameterMax != null) {
                if (anchor.holeDiameters.length == 0 || anchor.holeDiameters.every((holeDiameter) => holeDiameter < (this.holeDiameterMin as number) || holeDiameter > (this.holeDiameterMax as number))) {
                    return false;
                }
            }
            else if (this.holeDiameterMin != null) {
                if (anchor.holeDiameters.length == 0 || anchor.holeDiameters.every((holeDiameter) => holeDiameter < (this.holeDiameterMin as number))) {
                    return false;
                }
            }
            else if (this.holeDiameterMax != null) {
                if (anchor.holeDiameters.length == 0 || anchor.holeDiameters.every((holeDiameter) => holeDiameter > (this.holeDiameterMax as number))) {
                    return false;
                }
            }

            if (this.isLess(this.fixtureThicknessMin, anchor.fixtureThicknesMin)) {
                return false;
            }

            if (this.isMore(this.fixtureThicknessMax, anchor.fixtureThicknesMax)) {
                return false;
            }

            return true;
        });
    }

    private filterCalculateAllByMinMax(anchors: ICalculateAllAnchor[]) {
        for (const anchor of anchors) {
            anchor.detailed = anchor.detailed.filter((anchorDetailed) => {
                if (anchor.detailed != null) {
                    if (this.isLess(anchorDetailed.holeDiameter, this.holeDiameterMin)) {
                        return false;
                    }

                    if (this.isMore(anchorDetailed.holeDiameter, this.holeDiameterMax)) {
                        return false;
                    }

                    if (this.isLess(this.fixtureThicknessMin, anchorDetailed.fixtureThicknesMin)) {
                        return false;
                    }

                    if (this.isMore(this.fixtureThicknessMax, anchorDetailed.fixtureThicknesMax)) {
                        return false;
                    }
                }

                return true;
            });
        }

        return anchors;
    }

    protected refreshAnchors() {
        this.anchors = this.filterAnchors();

        this.initializeAnchorFamilySprites();
    }

    private onMinMaxFixtureThicknessChange(minMax: number, oldMinMax?: number) {
        this.onMinMaxChange(minMax, oldMinMax);
        if (minMax !== oldMinMax) {
            this.design.usageCounter.BasePlateThicknessFilter++;
        }
    }

    private onMinMaxHoleDiameterChange(minMax: number, oldMinMax?: number) {
        this.onMinMaxChange(minMax, oldMinMax);
        if (minMax !== oldMinMax) {
            this.design.usageCounter.BasePlateHoleDiameterFilter++;
        }
    }

    private onMinMaxChange(minMax: number, oldMinMax?: number) {
        if (minMax !== oldMinMax) {
            this.refreshAnchors();
            this.refreshCalculateAllAnchors();
        }
    }

    private filterCalculateAllAnchors() {
        if (this.calculateAllData == null) {
            return [];
        }

        const anchors = this.filterAnchors(true);
        let calculateAllAnchors = cloneDeep(this.calculateAllData);

        // anchor filter
        calculateAllAnchors = calculateAllAnchors.filter(calculateAllAnchor => anchors.some((anchor) => anchor.id == calculateAllAnchor.id));

        // search filter
        calculateAllAnchors = this.filterCalculateAllBySearch(calculateAllAnchors, this.filter);

        // search filter - min max
        calculateAllAnchors = this.filterCalculateAllByMinMax(calculateAllAnchors);

        // checkbox filter
        calculateAllAnchors = this.filterCalculateAllByCheckboxes(calculateAllAnchors);

        // sort
        calculateAllAnchors = this.sortCalculateAllBy(calculateAllAnchors, this.sortDropdown.selectedValue as SelectAnchorSortBy);

        const excludeFailures = this.filtersCalculateAllCheckbox.selectedValues?.has(CalculateAllCheckboxItem.ExcludeFailures);
        const includeOnlyMinimum = this.filtersCalculateAllCheckbox.selectedValues?.has(CalculateAllCheckboxItem.IncludeOnlyMinimum);
        if (excludeFailures || includeOnlyMinimum) {
            calculateAllAnchors = this.filterFailures(calculateAllAnchors);
        }

        if (includeOnlyMinimum) {
            calculateAllAnchors = this.filterMinimum(calculateAllAnchors);
        }

        return calculateAllAnchors;
    }

    private flattenCalculateAllAnchorsData(calculateAllAnchors: ICalculateAllAnchor[]) {
        const retVal: ICalculateAllAnchorUiItem[] = [];

        if (calculateAllAnchors != null && calculateAllAnchors.length > 0) {
            for (const anchor of calculateAllAnchors) {
                retVal.push({
                    type: CalculateAllAnchorUiItemType.Header,
                    id: anchor.id,
                    name: anchor.name
                });

                for (const details of anchor.detailed) {
                    retVal.push({
                        type: CalculateAllAnchorUiItemType.Item,
                        id: anchor.id,
                        name: anchor.name,
                        details
                    });
                }
            }
        }

        return retVal;
    }

    private refreshCalculateAllAnchors() {
        this.calculateAllAnchors = this.flattenCalculateAllAnchorsData(this.filterCalculateAllAnchors());
    }

    private formatDescription(descriptionFormat: string, descriptionKeys: string[]) {
        return format(descriptionFormat, ...descriptionKeys.map((descriptionKey) => this.translate(descriptionKey)));
    }

    protected setFiltersToProperties() {
        this.unlockInstallationTypeFilter();
        const filteredGroups = this.filterGroups.filter((filterGroup) => filterGroup.items?.some((filterItem) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(filterItem.checkbox as CheckboxButtonProps<boolean>)));

        this.design.model[PropertyMetaData.AnchorLayout_AnchorFilters.id] = {
            MinMaxFilters: {
                FixtureThicknesMin: this.fixtureThicknessMin,
                FixtureThicknesMax: this.fixtureThicknessMax,
                HoleDiameterMin: this.holeDiameterMin,
                HoleDiameterMax: this.holeDiameterMax
            },
            CheckboxFilters: filteredGroups.flatMap(group => group.items?.filter(item => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox as CheckboxButtonProps<boolean>)).map((item): CheckboxFilter => ({ Id: item.id as number }))),
            CheckboxFiltersDisabled: filteredGroups.flatMap(group => group.items?.filter(item => item.checkbox?.disabled).map((item): CheckboxFilter => ({ Id: item.id as number }))),
            CheckboxFiltersRemoved: filteredGroups.flatMap(group => group.items?.filter(item => item.checkbox).map((item): CheckboxFilter => ({ Id: item.id as number })))
        } as AnchorFilter;
    }

    protected getAnchorFilterGroupInfoPopup(anchorFilterGroup: AnchorFilterGroup) {
        let title = '';
        if (!this.isEmpty(anchorFilterGroup.tooltipTitleDisplayKey)) {
            let value = this.translate(anchorFilterGroup.tooltipTitleDisplayKey as string);

            if (this.localization.isHtml(value)) {
                value = value.replace(/agt-src="~/g, 'src="' + this.sharedEnvironmentData.data?.baseUrl.replace(/\/+$/, ''));
            }

            switch (anchorFilterGroup.tooltipType) {
                case TooltipType.Normal:
                    title = escape(value);
                    break;
                case TooltipType.Popup:
                    title = value;
                    break;
                default:
                    throw new Error('Unknown tooltip type.');
            }
        }

        let content = '';
        if (!this.isEmpty(anchorFilterGroup.tooltipDisplayKey)) {
            let value = this.translate(anchorFilterGroup.tooltipDisplayKey as string);

            if (this.localization.isHtml(value)) {
                value = value.replace(/agt-src="~\/Content\/Images\/(.*?)\.png/g, 'agt-img="$1.png');
                value = value.replace(/agt-src="~/g, 'src="' + this.sharedEnvironmentData.data?.baseUrl.replace(/\/+$/, ''));
            }

            switch (anchorFilterGroup.tooltipType) {
                case TooltipType.Normal:
                    content = escape(value);
                    break;
                case TooltipType.Popup:
                    content = value;
                    break;
                default:
                    throw new Error('Unknown tooltip type.');
            }
        }

        const notEmptyTitleOrContent = !this.isEmpty(title) || !this.isEmpty(content);

        const enabled = (anchorFilterGroup.tooltipType == null || anchorFilterGroup.tooltipType == TooltipType.Normal)
            && notEmptyTitleOrContent;

        const show = anchorFilterGroup.tooltipType == TooltipType.Popup
            && notEmptyTitleOrContent;

        const retVal: ISelectAnchorFilterGroupInfoPopup = {
            enabled,
            show,
            title,
            content
        };
        return retVal;
    }

    protected initializeAnchorFamilySprites() {
        this.anchors.forEach(anchor => {
            if (anchor.image && !this.initializedSpriteImages.includes(anchor.image)) {
                includeSprites(this.elementRef.nativeElement.shadowRoot, !anchor.tested, anchor.image as Sprite);
                this.initializedSpriteImages.push(anchor.image);
            }
        });
    }

    protected trackFilters() {
        this.design.usageCounter.FilterUsed = this.filterGroups.some((filterGroup) => filterGroup.items?.some((item) => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {})));
        this.design.usageCounter.trackFilterGroupUsed(this.filterGroups.filter(group =>
            group.items?.some(item => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(item.checkbox ?? {}) ?? false)
        ).map(group => group.titleKey ?? ''));
    }

    private getBoolean(value?: boolean) {
        return value ?? false;
    }

    private isEmpty(str?: string) {
        return (!str || str.length === 0);
    }

    private isLess(value1?: number, value2?: number) {
        return value1 && value2 && value1 < value2;
    }

    private isMore(value1?: number, value2?: number) {
        return value1 && value2 && value1 > value2;
    }
}
