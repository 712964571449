import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import imgMoreInfoOnSofaA from '../../../images/more-info-on-sofa-a.png';
import imgMoreInfoOnSofaB from '../../../images/more-info-on-sofa-b.png';
import {
    DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './more-info-on-sofa-modal.component.html',
    styleUrls: ['./more-info-on-sofa-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MoreInfoOnSOFAModalComponent implements OnInit {
    public moreInfoOnSofaA!: string;
    public moreInfoOnSofaB!: string;
    public textSofaTitle!: string;
    public textSofaConfiguration!: string;
    public textWhySofaDesign!: string;

    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        private localizationService: LocalizationService,
        private user: UserService,
    ) { }

    ngOnInit(): void {
        this.moreInfoOnSofaA = imgMoreInfoOnSofaA;
        this.moreInfoOnSofaB = imgMoreInfoOnSofaB;

        this.textSofaTitle = this.createPopupText(this.user.design.designStandard.id, this.createTranslationKey('Title'));
        this.textSofaConfiguration = this.createPopupText(this.user.design.designStandard.id, this.createTranslationKey('Configuration'));
        this.textWhySofaDesign = this.createPopupText(this.user.design.designStandard.id, this.createTranslationKey('WhySofa.Line2'));
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public learnMoreClicked() {
        const design = this.user.design;
        if (design != null) {
            design.usageCounter.LearnMore_SOFAClicked++;
        }
    }

    private createPopupText(designStandardId: number | undefined, title: string) {
        switch (designStandardId) {
            case DesignStandard.STO:
                return formatKeyValue(title, { designMethod: this.createTranslationKey('Title.STO') });
            case DesignStandard.SATS:
                return formatKeyValue(title, { designMethod: this.createTranslationKey('Title.SATS') });
            default:
                return formatKeyValue(title, { designMethod: this.createTranslationKey('Title.EC2') });
        }
    }

    private createTranslationKey(keyPart: string) {
        const key = ('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip').concat(('.').concat(keyPart));
        return this.translate(key);
    }
}
