import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    SmartAnchorNeedsSolutionTextsAnchorGroupEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { Tuple } from '../../generated-modules/System';
import { CodeList } from './code-list';

export interface ISmartAnchorNeedsSolutionTextsAnchorGroup extends ICodeListConstructor {
    anchorGroupId: number;
    applicationId: number;
    regionId: number;
    needsSolutionTexts: Tuple<number, number>[];
}

export class SmartAnchorNeedsSolutionTextsAnchorGroup extends CodeList {
    public anchorGroupId!: number;
    public applicationId!: number;
    public regionId!: number;
    public needsSolutionTexts!: Tuple<number, number>[];

    constructor(codeList: ISmartAnchorNeedsSolutionTextsAnchorGroup) {
        super(codeList);

        if (codeList != null) {
            this.anchorGroupId = codeList.anchorGroupId;
            this.applicationId = codeList.applicationId;
            this.regionId = codeList.regionId;
            this.needsSolutionTexts = codeList.needsSolutionTexts;
        }
    }

    public static fromService(codeList: SmartAnchorNeedsSolutionTextsAnchorGroupEntity) {
        return new SmartAnchorNeedsSolutionTextsAnchorGroup(CodeList.baseFromService<ISmartAnchorNeedsSolutionTextsAnchorGroup>('SmartAnchorNeedsSolutionTextsAnchorGroupEntity', codeList,
            {
                anchorGroupId: codeList.AnchorGroupId,
                applicationId: codeList.ApplicationId,
                regionId: codeList.RegionId,
                needsSolutionTexts: codeList.NeedsSolutionTexts
            } as ISmartAnchorNeedsSolutionTextsAnchorGroup));
    }

    public override cloneDeep(): SmartAnchorNeedsSolutionTextsAnchorGroup {
        const codeList: SmartAnchorNeedsSolutionTextsAnchorGroup = super.cloneDeep() as SmartAnchorNeedsSolutionTextsAnchorGroup;
        codeList.anchorGroupId = this.anchorGroupId;
        codeList.applicationId = this.applicationId;
        codeList.regionId = this.regionId;
        codeList.needsSolutionTexts = this.needsSolutionTexts;

        return codeList;
    }
}
