import {
    Component, Input, OnInit, TrackByFunction, ViewChild, ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    IModalGridComponentInput, IModalGridItem
} from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    SimpleCheckboxButtonHelper
} from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import {
    UnitGroup, UnitType as Unit
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';

import { environment } from '../../../environments/environmentPe';
import {
    ILoadsHNAWizardComponentInput
} from '../../../shared/components/loads-hna-wizard';
import {
    DesignMethodGroup as DesignMethodGroupCodeList
} from '../../../shared/entities/code-lists/design-method-group';
import {
    LoadCombinationHNAEquation as LoadCombinationHNAEquationCodeList
} from '../../../shared/entities/code-lists/load-combination-HNA-equation';
import {
    LoadCombinationHNAFactors as LoadCombinationHNAFactorsCodeList
} from '../../../shared/entities/code-lists/load-combination-HNA-factors';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    LoadCombinationHNAWizardFactors, UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignMethodGroup, DesignMethodHNA, DesignType, LoadType, MetalDeckAnchorPosition,
    SeismicShearType, SeismicTensionType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { ModalGridHelper } from '../../../shared/helpers/modal-grid-helper';
import { areAllLoadsForLedgerAngleAvailable } from '../../helpers/load-combination-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

enum Step {
    Equations,
    Factors
}

interface IEquation {
    id: number;
    name: string;
    description: string;
    descriptionParams: number[];

    selectedCheckbox: CheckboxButtonProps<boolean>;
    isDisabled?: boolean;
}

interface IFactors {
    id: number;
    name: string;
    isDisabled: boolean;
    sustainedLoadFactorEnabled?: boolean;
    sustainedLoadFactorTextBox: NumericTextBoxProps;

    loadFactorEnabled?: boolean;
    loadFactorDropdown: DropdownProps<number>;

    forceXTextBox: NumericTextBoxProps;
    forceYTextBox: NumericTextBoxProps;
    forceZTextBox: NumericTextBoxProps;

    momentXTextBox: NumericTextBoxProps;
    momentYTextBox: NumericTextBoxProps;
    momentZTextBox: NumericTextBoxProps;
}

interface ISustainedFactors {
    sustainedFD: number;
    sustainedFF: number;
    sustainedFT: number;
    sustainedFL: number;
    sustainedFH: number;
    sustainedFLr: number;
    sustainedFS: number;
    sustainedFR: number;
    sustainedFW: number;
    sustainedFE: number;
}

interface ICalculateHNAWizardSustainedLoadEquation {
    equation: IEquation;
    D: number;
    F: number;
    T: number;
    L: number;
    H: number;
    Lr: number;
    S: number;
    R: number;
    W: number;
    E: number;
    fD: number;
    fL: number;
    fS: number;
    sustained: ISustainedFactors;
}

@Component({
    templateUrl: './loads-hna-wizard.component.html',
    styleUrls: ['./loads-hna-wizard.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsHNAWizardComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance<ILoadsHNAWizardComponentInput>;

    @ViewChild('equationsForm')
    public equationsForm!: NgForm;

    @ViewChild('factorsForm')
    public factorsForm!: NgForm;

    private static readonly numberOfDecimals = 6;
    private static readonly defaultLoadValue = 0;

    public submitted = false;
    public pendingSave = false;

    public step = Step.Equations;
    public equations!: IEquation[];
    public factors!: IFactors[];

    public selectAllCheckbox!: CheckboxButtonProps<boolean>;

    public stepEnum = Step;
    public unitGroupEnum = {
        Force: UnitGroup.Force,
        Moment: UnitGroup.Moment,
    };
    private readonly valueEToCheckEquation = '(E)';
    private readonly valueEToCheckFactor = '.E';

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private codeList: CodeListService,
        private unit: UnitService,
        private modal: ModalService,
        private calculationService: CalculationServicePE,
        private numberService: NumberService
    ) { }

    public get isSmartAnchorToggleON() {
        return this.design.model[UIProperty.SmartAnchor_Enabled];
    }

    public get title() {
        switch (this.step) {
            case Step.Equations: {
                const designMethodCl = (this.codeList.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroupCodeList[]).find(x => x.id == this.design.designMethodGroup?.id);
                return this.getEquationsStepTitle(designMethodCl?.id ?? 0);
            }

            case Step.Factors:
                return this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.Title');

            default:
                throw new Error('Unknown step.');
        }
    }

    // Moments should be hidden if metalDeckAnchorPosition is not set to top position
    public get areMomentsForMetalDeckAvailable() {
        return this.design.designType.id != DesignType.MetalDeck
            ? true
            : (this.design.model[UIProperty.BaseMaterial_MetalDeckAnchorPosition] as MetalDeckAnchorPosition) == MetalDeckAnchorPosition.Top;
    }

    public get areAllLoadsForLedgerAngleAvailable() {
        return areAllLoadsForLedgerAngleAvailable(this.design.region.hubId, this.design.isCBFEMCalculation, this.design.isLedgerAngle);
    }

    private get design() {
        return this.user.design;
    }

    private get sustainedLoadFactorAll() {
        return {
            sustainedFD: 1,
            sustainedFE: 1,
            sustainedFF: 1,
            sustainedFH: 1,
            sustainedFL: 1,
            sustainedFLr: 1,
            sustainedFR: 1,
            sustainedFS: 1,
            sustainedFT: 1,
            sustainedFW: 1
        } as ISustainedFactors;
    }

    private get sustainedLoadFactorEOnly() {
        return {
            sustainedFD: 0,
            sustainedFE: 1,
            sustainedFF: 0,
            sustainedFH: 0,
            sustainedFL: 0,
            sustainedFLr: 0,
            sustainedFR: 0,
            sustainedFS: 0,
            sustainedFT: 0,
            sustainedFW: 0
        } as ISustainedFactors;
    }

    ngOnInit(): void {
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        const equationsCl = (this.design.designData.designCodeLists[DesignCodeList.LoadCombinationHNAEquation] as LoadCombinationHNAEquationCodeList[]).filter(x => x.designMethodHNA == this.design.designMethodHNA);
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        this.equations = equationsCl.map(cl => {
            const checked = this.design.loadCombinationHNAWizard.LoadCombinationHNAWizardEquations.some(x => x == cl.id);
            const isEquationDisabled = cl.descriptionFormat?.includes(this.valueEToCheckEquation) && this.isSmartAnchorToggleON;

            return {
                id: cl.id,
                name: cl.getTranslatedNameText(codeListDeps),
                description: format(cl.descriptionFormat ?? '', ...cl.descriptionParams?.map(x => this.unit.formatNumber(x, 2)) ?? ''),
                descriptionParams: cl.descriptionParams,
                isDisabled: isEquationDisabled,
                selectedCheckbox: SimpleCheckboxButtonHelper.createSimpleCheckbox({
                    itemId: `loads-hna-wizard-equations-checkbox-${cl.id}`,
                    itemText: '',
                    checked
                })
            } as IEquation;

        });

        const forceXRange: IValueRange = {
            max: this.design.properties.get(PropertyMetaData.Loads_ForceX.id).max,
            min: this.design.properties.get(PropertyMetaData.Loads_ForceX.id).min
        };
        const forceYRange: IValueRange = {
            max: this.design.properties.get(PropertyMetaData.Loads_ForceY.id).max,
            min: this.design.properties.get(PropertyMetaData.Loads_ForceY.id).min
        };
        const forceZRange: IValueRange = {
            max: this.design.properties.get(PropertyMetaData.Loads_ForceZ.id).max,
            min: this.design.properties.get(PropertyMetaData.Loads_ForceZ.id).min
        };
        const momentXRange: IValueRange = {
            max: this.design.properties.get(PropertyMetaData.Loads_MomentX.id).max,
            min: this.design.properties.get(PropertyMetaData.Loads_MomentX.id).min
        };
        const momentYRange: IValueRange = {
            max: this.design.properties.get(PropertyMetaData.Loads_MomentY.id).max,
            min: this.design.properties.get(PropertyMetaData.Loads_MomentY.id).min
        };
        const momentZRange: IValueRange = {
            max: this.design.properties.get(PropertyMetaData.Loads_MomentZ.id).max,
            min: this.design.properties.get(PropertyMetaData.Loads_MomentZ.id).min
        };

        const unitForce = this.unit.getDefaultUnit(UnitGroup.Force, this.design);
        const unitMoment = this.unit.getDefaultUnit(UnitGroup.Moment, this.design);

        const factorsCl = (this.design.designData.designCodeLists[DesignCodeList.LoadCombinationHNAFactors] as LoadCombinationHNAFactorsCodeList[]).filter(x => x.designMethodHNA == this.design.designMethodHNA);
        this.factors = factorsCl.map(cl => {
            const values = this.design.loadCombinationHNAWizard.LoadCombinationHNAWizardFactors.find(x => x.Id == cl.id);
            const isFactorDisabled = cl.displayKey?.endsWith(this.valueEToCheckFactor) && this.isSmartAnchorToggleON;

            return {
                id: cl.id,
                name: cl.getTranslatedNameText(codeListDeps),
                isDisabled: isFactorDisabled,
                sustainedLoadFactorEnabled: cl.sustainedLoadFactorEnabled,
                sustainedLoadFactorTextBox: {
                    unit: Unit.None,
                    value: values != null ? values.SustainedLoadFactor : cl.sustainedLoadFactorDefault,
                    minValue: cl.sustainedLoadFactorMin,
                    maxValue: cl.sustainedLoadFactorMax,
                },

                loadFactorEnabled: cl.loadFactorEnabled,
                loadFactorDropdown: {
                    id: `loads-hna-wizard-factors-load-factor-${cl.id}`,
                    items: cl.loadFactorValues?.map(x => {
                        return {
                            value: x.Id,
                            text: this.unit.formatNumber(x.Value, 2)
                        };
                    }),
                    selectedValue: values != null ? values.LoadFactorId : cl.loadFactorDefault,
                    notSelectedText: !cl.loadFactorEnabled ? ' ' : null
                },

                forceXTextBox: {
                    unit: unitForce,
                    value: values != null ? values.ForceX : LoadsHNAWizardComponent.defaultLoadValue,
                    minValue: forceXRange.min,
                    maxValue: forceXRange.max,
                },
                forceYTextBox: {
                    unit: unitForce,
                    value: values != null ? values.ForceY : LoadsHNAWizardComponent.defaultLoadValue,
                    minValue: forceYRange.min,
                    maxValue: forceYRange.max,
                },
                forceZTextBox: {
                    unit: unitForce,
                    value: values != null ? values.ForceZ : LoadsHNAWizardComponent.defaultLoadValue,
                    minValue: forceZRange.min,
                    maxValue: forceZRange.max,
                },

                momentXTextBox: {
                    unit: unitMoment,
                    value: values != null ? values.MomentX : LoadsHNAWizardComponent.defaultLoadValue,
                    minValue: momentXRange.min,
                    maxValue: momentXRange.max,
                },
                momentYTextBox: {
                    unit: unitMoment,
                    value: values != null ? values.MomentY : LoadsHNAWizardComponent.defaultLoadValue,
                    minValue: momentYRange.min,
                    maxValue: momentYRange.max,
                },
                momentZTextBox: {
                    unit: unitMoment,
                    value: values != null ? values.MomentZ : LoadsHNAWizardComponent.defaultLoadValue,
                    minValue: momentZRange.min,
                    maxValue: momentZRange.max,
                }
            } as IFactors;
        });

        this.selectAllCheckbox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            itemText: ''
        });
        this.itemCheckboxSelectedValuesChange();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public identifyEquation: TrackByFunction<IEquation> = function (_: number, item: IEquation) {
        return item.id;
    };

    public identifyFactor: TrackByFunction<IFactors> = function (_: number, item: IFactors) {
        return item.id;
    };

    public nextStep() {
        switch (this.step) {
            case Step.Equations:
                if (this.submitted || (this.equationsForm.enabled && !this.equationsForm.valid)) {
                    return;
                }

                this.step = Step.Factors;

                return;

            case Step.Factors:
                if (this.submitted || (this.factorsForm.enabled && !this.factorsForm.valid)) {
                    return;
                }

                this.design.usageCounter.LCWizardUsed++;
                this.save();
                return;

            default:
                throw new Error('Unknown step.');
        }
    }

    public prevStep() {
        if (this.step == Step.Factors) {
            this.step = Step.Equations;
            return;
        }

        throw new Error('Unknown step.');
    }

    public close() {
        this.modalInstance.close();
    }

    public getLocalizedTextAndUnit(key: string, unit: UnitGroup) {
        return `${this.localization.getString(key)} [${this.unit.formatUnit(this.unit.getDefaultUnit(unit))}]`;
    }

    public toggleCheckbox(checkBox: CheckboxButtonProps<boolean>) {
        const value = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(checkBox);
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(checkBox, !value);
    }

    public selectAllCheckboxSelectedValuesChange() {
        const value = SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.selectAllCheckbox) ?? false;
        for (const eq of this.equations) {
            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(eq.selectedCheckbox, value);
        }
    }

    public itemCheckboxSelectedValuesChange() {
        const selectAllChecked = this.equations.every(eq => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(eq.selectedCheckbox));
        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(this.selectAllCheckbox, selectAllChecked);
    }

    private save() {
        if (
            this.submitted
            || (this.equationsForm.enabled && !this.equationsForm.valid)
            || (this.factorsForm.enabled && !this.factorsForm.valid)
        ) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));

        if (this.design.loadCombinations.filter(lc => !lc.IsWizardGenerated).length + selectedEquations.length > environment.maxLoadCombinations) {
            this.submitted = false;
            this.pendingSave = false;

            this.modal.openAlertWarning(
                this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.TooManyLoadCases.Title'),
                this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.TooManyLoadCases.Message')
            );
        }
        else {
            this.wizardRulesEnforcer();
        }
    }

    private getEquationsStepTitle(designMethodId: number) {
        switch (designMethodId) {
            case DesignMethodGroup.CSAA23314:
            case DesignMethodGroup.CSAA23319:
                return format(this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Title'), this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.CSATitleExtension'));
            case DesignMethodGroup.ACI31808:
                return format(this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Title'), this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.ACI318_08.TitleExtension'));
            case DesignMethodGroup.ACI31811:
                return format(this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Title'), this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.ACI318_11.TitleExtension'));
            case DesignMethodGroup.ACI31814:
                return format(this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Title'), this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.ACI318_14.TitleExtension'));
            case DesignMethodGroup.ACI31819:
                return format(this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Title'), this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.ACI318_19.TitleExtension'));
            case DesignMethodGroup.LRFD:
            case DesignMethodGroup.LRFD_318_19:
                return format(this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Title'), this.localization.getString('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.LRFD.TitleExtension'));
            default:
                throw new Error('Unsupported design method group.');
        }
    }

    private wizardRulesEnforcer() {
        // Start popup chain only if equations are selected
        if (this.equations.some(equation => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(equation.selectedCheckbox))) {
            // Start a chain of popups to gather necessary information from user
            if (this.design.designMethodHNA == DesignMethodHNA.LRFD || this.design.designMethodHNA == DesignMethodHNA.LRFD_318_19) {
                this.submitWizardData(this.areSeismicEquationsSelected());
            }
            else if (this.design.designMethodHNA == DesignMethodHNA.ACI31808) {
                // ACI31808 is an exception as its a simplified version of popup/dialog chain
                this.wizardACI31808Helper();
            }
            else {
                // First we handle tension side of things, Shear helper is automatically started by Tension helper
                this.wizardTensionHelper();
            }
        }
        else {
            this.submitWizardData(false);
        }
    }

    private wizardACI31808Helper() {
        // Equations with Earthquake component are present
        if (this.areSeismicEquationsSelected()) {
            this.submitWizardData(true);

            // Equations with Earthquake component are NOT present
        }
        else {

            // Static Load type
            if (this.design.loadType == LoadType.Static) {
                this.submitWizardData(false);

                // Seismic Load type
            }
            else if (this.design.loadType == LoadType.Seismic) {
                let seismicDesign = false;

                // If seismic design is selected when entering the load combination wizard, and no equations with the E component are selected,
                // and a value is entered for E, show a popup informing he needs to revise his inputs.
                if (this.checkEPresence()) {
                    this.modal.openConfirmChange({
                        id: 'hna-wizard-confirm-dialog-combined-01',
                        title: this.localization.getString('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Title'),
                        message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeEquationForSeismic.Combined.Message'),
                        confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Confirm'),
                        onConfirm: (modal) => {
                            this.submitted = false;
                            this.pendingSave = false;
                            modal.close();
                        }
                    }).closed.then(() => {
                        this.submitted = false;
                        this.pendingSave = false;
                    });
                }
                else {
                    this.modal.openConfirmChange({
                        id: 'hna-wizard-confirm-dialog-ACI31808',
                        title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                        message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Message'),
                        confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                        cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                        onConfirm: (modal) => {
                            seismicDesign = true;
                            modal.close();
                        },
                        onCancel: (modal) => {
                            modal.close();
                        }
                    }).closed.then(() => {
                        this.submitWizardData(seismicDesign);
                    });
                }
            }
        }
    }

    private wizardTensionNonSeismicEquation() {
        const tensionSeismicDesign = false;
        const tensionType: SeismicTensionType | undefined = undefined;

        // Static Load type
        if (this.design.loadType == LoadType.Static) {
            this.wizardShearHelper(tensionSeismicDesign, tensionType);

            // Seismic Load type
        }
        else if (this.design.loadType == LoadType.Seismic) {

            // If seismic design is selected when entering the load combination wizard, and no equations with the E component are selected,
            // and a value is entered for E, show a popup informing he needs to revise his inputs.
            if (this.checkEPresence()) {
                this.modal.openConfirmChange({
                    id: 'hna-wizard-confirm-dialog-combined-01',
                    title: this.localization.getString('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Title'),
                    message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeEquationForSeismic.Combined.Message'),
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Main.OnSiteTestsEmbedmentDepth.Confirm'),
                    onConfirm: (modal) => {
                        this.submitted = false;
                        this.pendingSave = false;
                        modal.close();
                    }
                }).closed.then(() => {
                    this.submitted = false;
                    this.pendingSave = false;
                });
            } else {
                this.wizardShearHelper(tensionSeismicDesign, tensionType);
            }
        }
    }

    private wizardTensionSeismicEquationSeismic() {
        const tensionE = this.checkTension();
        let tensionSeismicDesign = false;
        let tensionType: SeismicTensionType | undefined = undefined;
        let alreadySet: boolean;

        // E > 20% for tension
        if (tensionE) {
            tensionSeismicDesign = true;
            this.wizardShearHelper(tensionSeismicDesign, tensionType);

            // E <= 20% for tension
        }
        else {
            alreadySet = false;
            this.modal.openConfirmChange({
                id: 'hna-wizard-confirm-dialog-tension-03',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Tension.LessThan20.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    tensionSeismicDesign = true;
                    alreadySet = true;
                    modal.close();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            }).closed.then(() => {
                if (!alreadySet) {
                    tensionSeismicDesign = false;
                    tensionType = SeismicTensionType.None;
                }

                this.wizardShearHelper(tensionSeismicDesign, tensionType);
            });
        }
    }

    private wizardTensionSeismicEquationStatic() {
        const tensionE = this.checkTension();
        let tensionSeismicDesign = false;
        let tensionType: SeismicTensionType | undefined = undefined;
        const tensionTypeCodeList: number = DesignCodeList['SeismicTensionType'];
        let alreadySet: boolean;

        // E > 20% for tension
        if (tensionE) {
            // Open Seismic Tension type grid modal
            const props = this.prepareModalGrid(
                tensionTypeCodeList,
                this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicTensionType.GridTitle'),
                this.design.properties.get(PropertyMetaData.Loads_SeismicTensionType.id).allowedValues as number[]
            );
            props.onSelect = (item) => {
                tensionType = item.value as SeismicTensionType;
                tensionSeismicDesign = true;

                // If None is selected
                if (tensionType == SeismicTensionType.None) {
                    alreadySet = false;
                    this.modal.openConfirmChange({
                        id: 'hna-wizard-confirm-dialog-tension-01',
                        title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                        message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Tension.MoreThan20.Message'),
                        confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                        cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                        onConfirm: (modal) => {
                            // Open Seismic Tension type grid modal without None value
                            const props2 = this.prepareModalGrid(
                                tensionTypeCodeList,
                                this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicTensionType.GridTitle'),
                                this.design.properties.get(PropertyMetaData.Loads_SeismicTensionType.id).allowedValues?.filter(x => x != SeismicTensionType.None) as number[]
                            );
                            props2.onSelect = (item2) => {
                                tensionType = item2.value as SeismicTensionType;
                                tensionSeismicDesign = true;
                                alreadySet = true;
                                modal.close();
                            };
                            this.modal.openModalGrid(props2);
                        },
                        onCancel: (modal) => {
                            modal.close();
                        }
                    }).closed.then(() => {
                        if (!alreadySet) {
                            tensionSeismicDesign = false;
                            tensionType = SeismicTensionType.None;
                        }

                        this.wizardShearHelper(tensionSeismicDesign, tensionType);
                    });
                }
                else {
                    this.wizardShearHelper(tensionSeismicDesign, tensionType);
                }
            };

            this.modal.openModalGrid(props);

            // E <= 20% for tension
        }
        else {
            alreadySet = false;
            this.modal.openConfirmChange({
                id: 'hna-wizard-confirm-dialog-tension-02',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Tension.LessThan20.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    // Open Seismic Tension type grid modal without None value
                    const props = this.prepareModalGrid(
                        tensionTypeCodeList,
                        this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicTensionType.GridTitle'),
                        this.design.properties.get(PropertyMetaData.Loads_SeismicTensionType.id).allowedValues?.filter(x => x != SeismicTensionType.None) as number[]
                    );
                    props.onSelect = (item) => {
                        tensionSeismicDesign = true;
                        tensionType = item.value as SeismicTensionType;
                        alreadySet = true;
                        modal.close();
                    };
                    this.modal.openModalGrid(props);
                },
                onCancel: (modal) => {
                    modal.close();
                }
            }).closed.then(() => {
                if (!alreadySet) {
                    tensionSeismicDesign = false;
                    tensionType = SeismicTensionType.None;
                }

                this.wizardShearHelper(tensionSeismicDesign, tensionType);
            });
        }
    }

    private wizardTensionHelper() {
        // Equations with Earthquake component are present
        if (this.areSeismicEquationsSelected()) {

            // Static Load type
            if (this.design.loadType == LoadType.Static) {
                this.wizardTensionSeismicEquationStatic();

                // Seismic Load type
            }
            else if (this.design.loadType == LoadType.Seismic) {
                this.wizardTensionSeismicEquationSeismic();
            }

            // Equations with Earthquake component are NOT present
        }
        else {
            this.wizardTensionNonSeismicEquation();
        }
    }

    private wizardShearNonSeismicEquation(tensionType?: SeismicTensionType) {
        let seismicDesign = true;
        let shearType: SeismicShearType;
        let alreadySet: boolean;

        // Static Load type
        if (this.design.loadType == LoadType.Static) {
            seismicDesign = false;
            this.submitWizardData(seismicDesign, tensionType);

            // Static Load type
        }
        else if (this.design.loadType == LoadType.Seismic) {
            alreadySet = false;
            this.modal.openConfirmChange({
                id: 'hna-wizard-confirm-dialog-combined-02',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Combined.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    seismicDesign = true;
                    alreadySet = true;
                    modal.close();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            }).closed.then(() => {
                if (!alreadySet) {
                    shearType = SeismicShearType.None;
                    seismicDesign = false;
                }

                this.submitWizardData(seismicDesign, tensionType, shearType);
            });
        }
    }

    private wizardShearSeismicEquationSeismic(tensionSeismicDesign: boolean, tensionType?: SeismicTensionType) {
        const shearE = this.checkShear();
        let seismicDesign = true;
        let shearType: SeismicShearType;
        let alreadySet: boolean;

        // E > 20% for shear
        if (shearE) {
            seismicDesign = true;
            this.submitWizardData(seismicDesign, tensionType);

            // E <= 20% for shear
        }
        else {
            alreadySet = false;
            this.modal.openConfirmChange({
                id: 'hna-wizard-confirm-dialog-shear-03',
                title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Shear.LessThan20.Message'),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    seismicDesign = true;
                    alreadySet = true;
                    modal.close();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            }).closed.then(() => {
                if (!alreadySet) {
                    shearType = SeismicShearType.None;
                    if (tensionSeismicDesign) {
                        seismicDesign = true;
                    }
                    else {
                        seismicDesign = false;
                    }
                }

                this.submitWizardData(seismicDesign, tensionType, shearType);
            });
        }
    }

    private wizardShearSeismicEquationStaticNonShearE(tensionSeismicDesign: boolean, tensionType?: SeismicTensionType) {
        let seismicDesign = true;
        let shearType: SeismicShearType;
        const shearTypeCodeList: number = DesignCodeList['SeismicShearType'];
        let alreadySet: boolean;

        alreadySet = false;
        this.modal.openConfirmChange({
            id: 'hna-wizard-confirm-dialog-shear-02',
            title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
            message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Shear.LessThan20.Message'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
            cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
            onConfirm: (modal) => {
                // Open Seismic Shear type grid modal without None value
                const props = this.prepareModalGrid(
                    shearTypeCodeList,
                    this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicShearType.GridTitle'),
                    this.design.properties.get(PropertyMetaData.Loads_SeismicShearType.id).allowedValues?.filter(x => x != SeismicShearType.None) as number[]
                );
                props.onSelect = (item) => {
                    shearType = item.value as SeismicShearType;
                    seismicDesign = true;
                    alreadySet = true;
                    modal.close();
                };
                this.modal.openModalGrid(props);
            },
            onCancel: (modal) => {
                modal.close();
            }
        }).closed.then(() => {
            if (!alreadySet) {
                shearType = SeismicShearType.None;
                if (tensionSeismicDesign) {
                    seismicDesign = true;
                }
                else {
                    seismicDesign = false;
                }
            }

            this.submitWizardData(seismicDesign, tensionType, shearType);
        });
    }

    private wizardShearSeismicEquationStaticShearE(tensionSeismicDesign: boolean, tensionType?: SeismicTensionType) {
        let seismicDesign = true;
        let shearType: SeismicShearType;
        const shearTypeCodeList: number = DesignCodeList['SeismicShearType'];
        let alreadySet: boolean;

        // Open Seismic Shear type grid modal
        const props = this.prepareModalGrid(
            shearTypeCodeList,
            this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicShearType.GridTitle'),
            this.design.properties.get(PropertyMetaData.Loads_SeismicShearType.id).allowedValues as number[]
        );
        props.onSelect = (item) => {
            shearType = item.value as SeismicShearType;

            // If None is selected
            if (shearType == SeismicShearType.None) {
                alreadySet = false;
                this.modal.openConfirmChange({
                    id: 'hna-wizard-confirm-dialog-shear-01',
                    title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                    message: this.localization.getString('Agito.Hilti.Profis3.HNAWizard.NoEarthquakeComponentForSeismic.Shear.MoreThan20.Message'),
                    confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Yes'),
                    cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.No'),
                    onConfirm: (modal) => {
                        // Open Seismic Shear type grid modal without None value
                        const props2 = this.prepareModalGrid(
                            shearTypeCodeList,
                            this.localization.getString('Agito.Hilti.Profis3.Navigation.TabLoads.RegionSeismicDesign.ControlSeismicShearType.GridTitle'),
                            this.design.properties.get(PropertyMetaData.Loads_SeismicShearType.id).allowedValues?.filter(x => x != SeismicShearType.None) as number[]
                        );
                        props2.onSelect = (item2) => {
                            shearType = item2.value as SeismicShearType;
                            seismicDesign = true;
                            alreadySet = true;
                            modal.close();
                        };
                        this.modal.openModalGrid(props2);
                    },
                    onCancel: (modal) => {
                        modal.close();
                    }
                }).closed.then(() => {
                    if (!alreadySet) {
                        shearType = SeismicShearType.None;
                        if (tensionSeismicDesign) {
                            seismicDesign = true;
                        }
                        else {
                            seismicDesign = false;
                        }
                    }

                    this.submitWizardData(seismicDesign, tensionType, shearType);
                });
            }
            else {
                this.submitWizardData(seismicDesign, tensionType, shearType);
            }
        };

        this.modal.openModalGrid(props);
    }

    private wizardShearHelper(tensionSeismicDesign: boolean, tensionType?: SeismicTensionType) {
        const shearE = this.checkShear();

        // Equations with Earthquake component are present
        if (this.areSeismicEquationsSelected()) {

            // Static Load type
            if (this.design.loadType == LoadType.Static) {

                // E > 20% for shear
                if (shearE) {
                    this.wizardShearSeismicEquationStaticShearE(tensionSeismicDesign, tensionType);

                    // E <= 20% for shear
                }
                else {
                    this.wizardShearSeismicEquationStaticNonShearE(tensionSeismicDesign, tensionType);
                }

                // Seismic Load type
            }
            else if (this.design.loadType == LoadType.Seismic) {
                this.wizardShearSeismicEquationSeismic(tensionSeismicDesign, tensionType);
            }

            // Equations with Earthquake component are NOT present
        }
        else {
            this.wizardShearNonSeismicEquation(tensionType);
        }
    }

    private submitWizardData(seismicDesign: boolean, tensionType?: number, shearType?: number) {
        // Save wizard data and trigger calculation
        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));

        this.calculationService.calculateAsync(this.design,
            (design) => {
                design.loadCombinationHNAWizard.LoadCombinationHNAWizardEquations = selectedEquations.map(x => x.id);

                design.loadCombinationHNAWizard.LoadCombinationHNAWizardFactors = this.factors.map((x): LoadCombinationHNAWizardFactors => ({
                    Id: x.id,
                    SustainedLoadFactor: x.sustainedLoadFactorTextBox.value ?? 0,
                    LoadFactorId: x.loadFactorDropdown.selectedValue ?? 0,
                    ForceX: x.forceXTextBox.value ?? 0,
                    ForceY: x.forceYTextBox.value ?? 0,
                    ForceZ: x.forceZTextBox.value ?? 0,
                    MomentX: x.momentXTextBox.value ?? 0,
                    MomentY: x.momentYTextBox.value ?? 0,
                    MomentZ: x.momentZTextBox.value ?? 0
                }));

                design.loadCombinationHNAWizard.SeismicDesign = seismicDesign;

                if (tensionType) {
                    design.loadCombinationHNAWizard.SeismicTensionType = tensionType;
                }

                if (shearType) {
                    design.loadCombinationHNAWizard.SeismicShearType = shearType;
                }
            },
            { suppressLoadingFlag: true }
        )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                if (this.modalInstance.input?.onSave != null) {
                    this.modalInstance.input.onSave();
                }
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    private areSeismicEquationsSelected() {
        return this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox) && x.description.indexOf('E') !== -1).length > 0;
    }

    private getFactors(factorIds: number[]) {
        return this.factors.filter(x => factorIds.includes(x.id)).find(() => true);
    }

    private getFactorMomentValue(factor?: IFactors) {
        return factor != null && factor.momentZTextBox.value ? factor.momentZTextBox.value : 0;
    }

    private getFactorForceValue(factor?: IFactors) {
        return factor != null && factor.forceZTextBox.value ? factor.forceZTextBox.value : 0;
    }

    private getParameters() {
        const factorsD = this.getFactors([1, 11, 21, 31]);
        const factorsF = this.getFactors([2, 12, 22]);
        const factorsT = this.getFactors([3, 13, 23]);
        const factorsL = this.getFactors([4, 14, 24, 32]);
        const factorsH = this.getFactors([5, 15, 25]);
        const factorsLr = this.getFactors([6, 16, 26]);
        const factorsS = this.getFactors([7, 17, 27, 33]);
        const factorsR = this.getFactors([8, 18, 28]);
        const factorsW = this.getFactors([9, 19, 29, 34]);
        const factorsE = this.getFactors([10, 20, 30, 35]);

        const parameters: ICalculateHNAWizardSustainedLoadEquation = {
            equation: {} as IEquation,
            D: this.getFactorMomentValue(factorsD),
            F: this.getFactorForceValue(factorsF),
            T: this.getFactorForceValue(factorsT),
            L: this.getFactorForceValue(factorsL),
            H: this.getFactorForceValue(factorsH),
            Lr: this.getFactorForceValue(factorsLr),
            S: this.getFactorForceValue(factorsS),
            R: this.getFactorForceValue(factorsR),
            W: this.getFactorForceValue(factorsW),
            E: this.getFactorForceValue(factorsE),
            fD: factorsD != null ? this.getLoadFactorValue(factorsD) : 0,
            fL: factorsL != null ? this.getLoadFactorValue(factorsL) : 0,
            fS: factorsS != null ? this.getLoadFactorValue(factorsS) : 0,
            sustained: this.sustainedLoadFactorAll
        };

        return parameters;
    }

    private checkTension() {
        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));
        const parameters = this.getParameters();

        let check = false;

        for (const equation of selectedEquations) {
            let seismicDesign = false;
            let compression = false;
            // If any but N pass the E(*)/sum(*) > 20% then compression is ignored.
            let ignoreCompression = false;
            parameters.equation = equation;


            // N
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumN = this.calculateHNAWizardSustainedLoadEquation(parameters);

            // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eN = this.calculateHNAWizardSustainedLoadEquation(parameters);

            compression = sumN < 0 || eN < 0;
            seismicDesign = seismicDesign || (Math.abs(eN / sumN) > 0.2);


            // Mx
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumMx = this.calculateHNAWizardSustainedLoadEquation(parameters);

            // // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eMx = this.calculateHNAWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eMx / sumMx) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eMx / sumMx) > 0.2);


            // My
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumMy = this.calculateHNAWizardSustainedLoadEquation(parameters);

            // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eMy = this.calculateHNAWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eMy / sumMy) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eMy / sumMy) > 0.2);


            // Is equation relevant for seismic design
            check = check || ((!compression || ignoreCompression) && seismicDesign);
        }

        return check;
    }

    private checkEPresence() {
        const factorsE = this.factors.filter(x => x.id == 10 || x.id == 20 || x.id == 30 || x.id == 35).find(() => true);
        return factorsE != null
            && (
                factorsE.forceXTextBox.value != 0
                || factorsE.forceYTextBox.value != 0
                || factorsE.forceZTextBox.value != 0
                || factorsE.momentXTextBox.value != 0
                || factorsE.momentYTextBox.value != 0
                || factorsE.momentZTextBox.value != 0
            );
    }

    private checkShear() {
        const selectedEquations = this.equations.filter(x => SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(x.selectedCheckbox));

        const parameters = this.getParameters();

        let check = false;

        for (const equation of selectedEquations) {
            let seismicDesign = false;
            const compression = false;
            // If any but N pass the E(*)/sum(*) > 20% then compression is ignored.
            let ignoreCompression = false;
            parameters.equation = equation;


            // Vx
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumVx = this.calculateHNAWizardSustainedLoadEquation(parameters);

            parameters.sustained = this.sustainedLoadFactorEOnly; // We're looking for value of E alone
            const eVx = this.calculateHNAWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eVx / sumVx) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eVx / sumVx) > 0.2);


            // Vy
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumVy = this.calculateHNAWizardSustainedLoadEquation(parameters);

            parameters.sustained = this.sustainedLoadFactorEOnly; // We're looking for value of E alone
            const eVy = this.calculateHNAWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eVy / sumVy) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eVy / sumVy) > 0.2);


            // Mz
            // Sustained load factors are not taken into account here
            parameters.sustained = this.sustainedLoadFactorAll;
            const sumMz = this.calculateHNAWizardSustainedLoadEquation(parameters);

            // We're looking for value of E alone
            parameters.sustained = this.sustainedLoadFactorEOnly;
            const eMz = this.calculateHNAWizardSustainedLoadEquation(parameters);

            seismicDesign = seismicDesign || (Math.abs(eMz / sumMz) > 0.2);
            ignoreCompression = ignoreCompression || (Math.abs(eMz / sumMz) > 0.2);


            // Is equation relevant for seismic design
            check = check || ((!compression || ignoreCompression) && seismicDesign);
        }

        return check;
    }

    private getLoadFactorValue(factor: IFactors) {
        const factorCL = (this.design.designData.designCodeLists[DesignCodeList.LoadCombinationHNAFactors] as LoadCombinationHNAFactorsCodeList[]).filter(x => x.designMethodHNA == this.design.designMethodHNA && x.id == factor.id)[0];
        const loadFactorValue = factorCL.loadFactorValues?.find(x => x.Id == factor.loadFactorDropdown.selectedValue);
        return loadFactorValue != null ? loadFactorValue.Value : 0;
    }

    private calculateHNAWizardSustainedLoadEquation(parameters: ICalculateHNAWizardSustainedLoadEquation) {
        switch (parameters.equation.id) {
            case 1:
            case 17:
            case 33:
            case 57:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F);
            case 2:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F + parameters.sustained.sustainedFT * parameters.T) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 3:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F + parameters.sustained.sustainedFT * parameters.T) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            case 4:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F + parameters.sustained.sustainedFT * parameters.T) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 5:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 6:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 7:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 8:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + 0.8 * (parameters.sustained.sustainedFW * parameters.W);
            case 9:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + 0.8 * (parameters.sustained.sustainedFW * parameters.W);
            case 10:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + 0.8 * (parameters.sustained.sustainedFW * parameters.W);
            case 11:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 12:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            case 13:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 14:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFE * parameters.E) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + (parameters.sustained.sustainedFS * parameters.fS * parameters.S);
            case 15:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H);
            case 16:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFE * parameters.E) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H);
            case 18:
            case 34:
            case 58:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 19:
            case 35:
            case 59:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            default:
                return this.calculateHNAWizardSustainedLoadEquation2(parameters);
        }
    }

    private calculateHNAWizardSustainedLoadEquation2(parameters: ICalculateHNAWizardSustainedLoadEquation) {
        switch (parameters.equation.id) {
            case 20:
            case 36:
            case 60:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L + parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 21:
            case 37:
            case 61:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 22:
            case 38:
            case 62:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 23:
            case 39:
            case 63:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L);
            case 24:
            case 40:
            case 64:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFLr * parameters.Lr) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[3] * (parameters.sustained.sustainedFW * parameters.W);
            case 25:
            case 41:
            case 65:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[3] * (parameters.sustained.sustainedFW * parameters.W);
            case 26:
            case 42:
            case 66:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFR * parameters.R) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[3] * (parameters.sustained.sustainedFW * parameters.W);
            case 27:
            case 43:
            case 67:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFLr * parameters.Lr);
            case 28:
            case 44:
            case 68:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFS * parameters.S);
            case 29:
            case 45:
            case 69:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFW * parameters.W) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + parameters.equation.descriptionParams[2] * (parameters.sustained.sustainedFR * parameters.R);
            case 30:
            case 46:
            case 70:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFE * parameters.E) + (parameters.sustained.sustainedFL * parameters.fL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H) + (parameters.sustained.sustainedFS * parameters.fS * parameters.S);
            case 31:
            case 47:
            case 71:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H);
            case 32:
            case 48:
            case 72:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D + parameters.sustained.sustainedFF * parameters.F) + (parameters.sustained.sustainedFE * parameters.E) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFH * parameters.H);
            case 49:
            case 89:
                return parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFD * parameters.D);
            case 50:
            case 90:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S);
            case 51:
            case 91:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W);
            case 52:
            case 92:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L);
            case 53:
            case 93:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFS * parameters.S) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFW * parameters.W);
            case 54:
            case 94:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFL * parameters.L);
            case 55:
            case 95:
                return (parameters.sustained.sustainedFD * parameters.fD * parameters.D) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFW * parameters.W) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S);
            case 56:
            case 96:
                return (parameters.sustained.sustainedFD * parameters.D) + (parameters.sustained.sustainedFE * parameters.E) + parameters.equation.descriptionParams[0] * (parameters.sustained.sustainedFL * parameters.L) + parameters.equation.descriptionParams[1] * (parameters.sustained.sustainedFS * parameters.S);
            default:
                throw new Error(`Unsupported load combination HNA equation ${parameters.equation.id}.`);
        }
    }

    private prepareModalGrid(codeList: number, title: string, allowedValues: number[]) {
        const itemMapping = ModalGridHelper.getPopupGridItemMapping(this.localization, this.unit, codeList);
        const codeListItems = this.design.designData.designCodeLists[codeList];
        const allItems = ModalGridHelper.createPopupGridItems(codeListItems, itemMapping);

        // filter group items
        const filteredItems = allItems?.filter((item) => allowedValues != null ? allowedValues.includes(item.value as number) : true);

        const props: IModalGridComponentInput<IModalGridItem<number>> = {
            popupTitle: title,
            items: filteredItems,
            selectedItem: allItems != null && allItems.length > 0
                ? allItems[0]
                : undefined,
            numberOfColumns: 1,
            preventClose: true
        };
        return props;
    }
}
