import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignPe, ReportData } from '../../../shared/entities/design-pe';
import {
    UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    WeldsUtilizationsEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    WeldLocation
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CollapsingControls } from '../../entities/collapsing-controls';
import {
    areLoadCombinationsAvailable, getLoadCombinationNumberText
} from '../../helpers/load-combination-helper';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

interface IWorstResult {
    valueError?: boolean;
    textError?: boolean;
    value: number;
}

@Component({
    templateUrl: './welds-utilizations.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class WeldsUtilizationsComponent implements OnInit {
    @Input()
    public id = '';

    @Input()
    public design?: DesignPe;

    @Input()
    public weldsUtilizations?: WeldsUtilizationsEntity;

    public collapsed = false;
    public unitGroupEnum = {
        Percentage: UnitGroup.Percentage,
        Stress: UnitGroup.Stress
    };

    constructor(
        public localizationService: LocalizationService,
        public unit: UnitService,
        private user: UserService,
        private userSettings: UserSettingsService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get showUtilizations() {
        return this.user.design != null
            && (this.hasUtilizations || this.showAddOn);
    }

    public get hasUtilizations() {
        return (this.design?.designData?.reportData?.WeldDg7Utilization != null && !this.hasButtWeldsOnly)
            || this.profileAnchorPlate != null || this.profileStiffeners != null || this.stiffenersAnchorPlate != null;
    }

    public get showAddOn() {
        // only show upgrade text after calculation is done
        if (this.design?.designData?.reportData?.BaseplateDesignData == null) {
            return false;
        }

        return (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) && !this.featuresVisibilityInfo.hasAdvancedFeature;
    }

    public get profileAnchorPlate() {
        return this.design?.baseplateDesignData?.WeldsUtilizations?.UtilizationProfileAnchorPlate;
    }

    public get profileStiffeners() {
        return this.design?.baseplateDesignData?.WeldsUtilizations?.UtilizationProfileStiffeners;
    }

    public get stiffenersAnchorPlate() {
        return this.design?.baseplateDesignData?.WeldsUtilizations?.UtilizationStiffenersAnchorPlate;
    }

    public get areLoadCombinationsAvailable() {
        return areLoadCombinationsAvailable(this.design?.designData?.reportData as ReportData);
    }

    public get loadCombination() {
        if (this.design == null) {
            return null;
        }

        return getLoadCombinationNumberText(this.design, this.localizationService, this.design.designData?.reportData?.DecisiveLoadCombinationIndexWelds ?? 0, true);
    }

    public get utilizationValue() {
        if (this.profileAnchorPlateWorst == null) {
            return null;
        }

        return this.profileAnchorPlateWorst.value;
    }

    public get profileAnchorPlateWorst(): IWorstResult | null {
        if (this.profileAnchorPlate == null) {
            if (this.design?.designData?.reportData?.WeldDg7Utilization != null) {
                return { value: this.design.designData.reportData.WeldDg7Utilization, valueError: this.design.designData.reportData.WeldDg7Utilization > 100 };
            }
            return null;
        }

        let worst: UtilizationValueEntity | null = null;

        if (this.profileAnchorPlate.Decisive != null) {
            worst = this.profileAnchorPlate.Decisive;
        }

        if (this.profileStiffeners?.Decisive != null && (worst == null || this.profileStiffeners.Decisive.Percentage > worst.Percentage)) {
            worst = this.profileStiffeners.Decisive;
        }

        if (this.stiffenersAnchorPlate?.Decisive != null && (worst == null || this.stiffenersAnchorPlate.Decisive.Percentage > worst.Percentage)) {
            worst = this.stiffenersAnchorPlate.Decisive;
        }

        if (worst == null) {
            return null;
        }

        return {
            value: worst.Percentage,
            textError: worst.ValueError,
            valueError: worst.ValueError
        } as IWorstResult;
    }

    public get utilizationInvalid() {
        if (this.profileAnchorPlateWorst == null) {
            return null;
        }

        return this.profileAnchorPlateWorst.valueError;
    }

    private get hasButtWeldsOnly() {
        const flangeWeldLocation = this.design?.model[UIProperty.AnchorPlate_FlangeWeldLocation];
        const webWeldLocation = this.design?.model[UIProperty.AnchorPlate_WebWeldLocation];

        if (flangeWeldLocation != null) {
            return (webWeldLocation == WeldLocation.Butt || webWeldLocation == WeldLocation.None) &&
                (flangeWeldLocation == WeldLocation.Butt || flangeWeldLocation == WeldLocation.None);
        }

        return webWeldLocation == WeldLocation.Butt;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-double-weld-location-small',
        );

        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.WeldsUtilization);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.WeldsUtilization, collapsed);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
