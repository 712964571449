import {
    IntegrationDataRequest, IntegrationDataResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities';
import {
    DataIntegrationRequestType, DataIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    DlubalApplicationInstanceType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Dlubal.Enums';

export abstract class IntegrationsDataServiceBase {
    public abstract requestUrl?: string;
    public abstract requestDataObject?: object;

    public abstract requestDataDlubal<T extends IntegrationDataResponse>(
        requestType: DataIntegrationRequestType,
        applicationInstanceType: DlubalApplicationInstanceType,
        timeoutHandler: () => void,
        continueWith: (response: T) => void,
        explicitRequest: IntegrationDataRequest,
        timeout: number
    ): Promise<void>;

    public abstract requestData<T extends IntegrationDataResponse>(
        dataIntegrationType: DataIntegrationType,
        requestType: DataIntegrationRequestType,
        timeoutHandler: () => void,
        continueWith: (response: T) => void,
        explicitRequest: IntegrationDataRequest,
        timeout: number
    ): Promise<void>;

    public abstract registerNewDataAvailableHandler(): void;

    public abstract refreshIntegrationAvailability(integrationType: DataIntegrationType): void;
}

export class IntegrationsDataServiceInjected extends IntegrationsDataServiceBase {
    protected baseService!: IntegrationsDataServiceBase;

    public setBaseService(baseService: IntegrationsDataServiceBase) {
        this.baseService = baseService;

        this.requestDataDlubal = baseService.requestDataDlubal.bind(baseService);
        this.requestData = baseService.requestData.bind(baseService);
        this.registerNewDataAvailableHandler = baseService.registerNewDataAvailableHandler.bind(baseService);
        this.refreshIntegrationAvailability = baseService.refreshIntegrationAvailability.bind(baseService);
    }

    public get requestUrl() {
        return this.baseService.requestUrl;
    }

    public get requestDataObject() {
        return this.baseService.requestDataObject;
    }

    // IntegrationsDataService methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public requestDataDlubal<T extends IntegrationDataResponse>(
        _requestType: DataIntegrationRequestType,
        _applicationInstanceType: DlubalApplicationInstanceType,
        _timeoutHandler: (() => void) | null = null,
        _continueWith: ((response: T) => void) | null = null,
        _explicitRequest: IntegrationDataRequest | null = null,
        _timeout = 30000
    ) {
        return {} as Promise<void>;
    }

    public requestData<T extends IntegrationDataResponse>(
        _dataIntegrationType: DataIntegrationType,
        _requestType: DataIntegrationRequestType,
        _timeoutHandler: (() => void) | null = null,
        _continueWith: ((response: T) => void) | null = null,
        _explicitRequest: IntegrationDataRequest | null = null,
        _timeout = 30000
    ) {
        return {} as Promise<void>;
    }

    public registerNewDataAvailableHandler(): void {
        return;
    }

    public refreshIntegrationAvailability(integrationType: DataIntegrationType): void {
        return;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
