import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DesignSectionService } from '../../services/design-section.service';
import { LocalizationService } from '../../services/localization.service';
import { OfflineService } from '../../services/offline.service';
import { includeSprites } from '../../sprites';
import { IExportReportSupportMethods } from '../export-report/export-report.component';

@Component({
    templateUrl: './design-section.component.html',
    styleUrls: ['./design-section.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DesignSectionComponent implements OnInit {
    @Input()
    public exportReportSupportMethods!: IExportReportSupportMethods;

    @Input()
    public openExport = () => this.openGenerateReportPopup(true, false);

    @Input()
    public openExportReport = () => this.openGenerateReportPopup(false, true);

    @Input()
    public closeExportReport = () => this.designSectionService.closeGenerateReportPopup();

    @Input()
    public openFile = () => this.selectOpenFile();


    @ViewChild('openDesignInput')
    public inputElement!: ElementRef<HTMLInputElement>;


    constructor(
        public localizationService: LocalizationService,
        public offlineService: OfflineService,
        public designSectionService: DesignSectionService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get componentVisible() {
        return this.designSectionService.design != null;
    }


    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot, ...this.designSectionService.getSprites());
        this.designSectionService.initExportItems();
    }


    public openGenerateReportPopup(isRisaExport?: boolean, noAnimation?: boolean) {
        this.designSectionService.openGenerateReportPopup( this.exportReportSupportMethods, isRisaExport, noAnimation);
    }

    public selectOpenFile() {
        if (this.designSectionService.openingDesign) {
            return;
        }

        this.designSectionService.design.usageCounter.ImportExistingDesign++;

        if (this.inputElement.nativeElement.value == null || this.inputElement.nativeElement.value == '') {
            setTimeout(() => {
                this.inputElement.nativeElement.click();
            });
        }
    }

    public importFileSelected() {
        if (this.inputElement.nativeElement.value != null
            && this.inputElement.nativeElement.value != ''
            && this.inputElement.nativeElement.files != null) {
            const file = this.inputElement.nativeElement.files[0];

            // clear input
            this.inputElement.nativeElement.value = '';

            this.designSectionService.saveChangesBeforeImport(file);
        }
    }
}
