import _300 from './300.png';
import _300small from './300small.png';
import _400 from './400.png';
import _400small from './400small.png';
import _600 from './600.png';
import _600small from './600small.png';
import _800 from './800.png';
import _800small from './800small.png';
import AB from './AB.png';
import ABC from './ABC.png';
import ABCD from './ABCD.png';

const images = {
    '300.png': _300,
    '300small.png': _300small,
    '400.png': _400,
    '400small.png': _400small,
    '600.png': _600,
    '600small.png': _600small,
    '800.png': _800,
    '800small.png': _800small,
    'AB.png': AB,
    'ABC.png': ABC,
    'ABCD.png': ABCD
} satisfies Record<string, string>;

export default images as typeof images & { [imageName: string]: string };
