import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitService } from '../../services/unit.service';
import { AsadOptimizeDetailsModalInput } from '../../../shared/entities/asad-optimize-modal';
import { AsadSearchAlgorithm } from '../../../shared/entities/design-pe';

@Component({
    templateUrl: './asad-optimize-details-modal.component.html',
    styleUrls: ['./asad-optimize-details-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AsadOptimizeDetailsModalComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<AsadOptimizeDetailsModalInput>;

    @HostBinding('class.fullscreen')
    public logsFullscreen = false;

    public keys = Object.keys.bind(Object);

    public lengthUnitString = '';

    constructor(
        private unitService: UnitService
    ) { }

    public get optimizeCaseOutput() {
        return this.modalInstance.input?.optimizeCase.optimizeCaseOutput;
    }

    public get optimizeCaseDetails() {
        return this.modalInstance.input?.optimizeCase.optimizeCaseDetails;
    }

    public get optimizeCaseId() {
        return this.modalInstance.input?.optimizeCase.optimizeCaseId;
    }

    public get logs() {
        return this.modalInstance.input?.optimizeCase.logs;
    }

    public get includeLogs() {
        return this.modalInstance.input?.includeLogs;
    }

    public get optimizationDate() {
        return this.modalInstance.input?.optimizationDate;
    }

    public get maxUtilizations() {
        return this.optimizeCaseOutput?.maxUtilization != null ? this.formatPercentage(this.optimizeCaseOutput.maxUtilization * 100) : '';
    }

    public ngOnInit(): void {
        this.lengthUnitString = this.unitService.getUnitStrings(Unit.mm)[0];
    }

    public close() {
        this.modalInstance.close();
    }

    public formatLength(value: number) {
        return this.unitService.formatInternalValueAsDefault(value, UnitGroup.Length);
    }

    public formatLengthWithoutUnit(value: number) {
        return this.formatWithoutUnit(value, UnitGroup.Length);
    }

    public formatWithoutUnit(value: number, unitGroup: UnitGroup) {
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);

        return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit), Unit.None);
    }

    public formatPercentage(value: number) {
        return this.unitService.formatInternalValueAsDefault(value, UnitGroup.Percentage);
    }

    public saveLogs() {
        this.download(`optimize-logs-case-${this.optimizationDate}-${this.optimizeCaseId}.txt`, this.logs);
    }

    public searchAlgorithmToString(searchAlgorithm: AsadSearchAlgorithm) {
        if (searchAlgorithm == null) {
            return null;
        }

        switch (searchAlgorithm) {
            case AsadSearchAlgorithm.DirectSearch:
                return 'DirectSearch';
            case AsadSearchAlgorithm.IPOPT:
                return 'IPOPT';
            default:
                throw new Error('Unknown searchAlgorithm');
        }
    }

    private download(name: string, content?: string) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content ?? ''));
        element.setAttribute('download', name);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
}
