import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ISelectMaterialComponentInput } from '../../../shared/components/select-material';
import { SteelType } from '../../../shared/entities/code-lists/steel-type';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { MainMenuModal } from '../../../shared/entities/main-menu';
import { CustomSteelMaterial } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { ISelectMaterialProps, SelectMaterial } from '../../components/main-menu/select-material/SelectMaterial';
import { LocalizationService } from '../../services/localization.service';

export function initializeSelectMaterial(
    design: DesignPe,
    controlProps: ISelectMaterialProps,
    localizationService: LocalizationService,
    uiPropertyId: number,
    modals: { [modal: number]: (input?: object) => IModalOpened }) {

    controlProps.type = SelectMaterial;

    const getSelectMaterialButtonText = (selectedSteelTypeId: number) => {
        const steelTypes = design.designData.designCodeLists[DesignCodeList.SteelType] as SteelType[];
        const isCustomSteelSelected = isSelectMaterialCustomSelected(uiPropertyId, design.currentState);

        if (isCustomSteelSelected) {
            return getCustomMaterialName(uiPropertyId, design.currentState);
        }

        if (selectedSteelTypeId != null) {
            const selectedSteelType = steelTypes.find((steelType) => steelType.id == selectedSteelTypeId);
            const steelTypeName = selectedSteelType != null ? selectedSteelType.getTranslatedNameText({ localizationService }) : '';
            const title = controlProps.title != null ? controlProps.title : localizationService.getString('Agito.Hilti.Profis3.SelectMaterial.ButtonTitle');
            return `${title} : ${steelTypeName}`;
        }

        return localizationService.getString('Agito.Hilti.Profis3.SelectMaterial.NotSelected');
    };

    controlProps.buttonText = getSelectMaterialButtonText(design.model[uiPropertyId] as number);
    controlProps.clicked = () => {
        const modalOpenFn = modals[MainMenuModal.selectMaterial];
        const modalInput: ISelectMaterialComponentInput = {
            uiPropertyId: uiPropertyId
        };
        modalOpenFn(modalInput);
    };

    const onStateChanged = (state: IDesignState) => {
        return { buttonText: getSelectMaterialButtonText(state.model[uiPropertyId] as number) } as ISelectMaterialProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}

function isSelectMaterialCustomSelected(uiPropertyId: number, state: IDesignState) {
    if (uiPropertyId == PropertyMetaData.AnchorPlate_SteelType.id) {
        return state.model[PropertyMetaData.AnchorPlate_IsPlateMaterialCustom.id] as boolean;
    }

    if (uiPropertyId == PropertyMetaData.Profile_SteelType.id) {
        return state.model[PropertyMetaData.Profile_IsProfileMaterialCustom.id] as boolean;
    }

    if (uiPropertyId == PropertyMetaData.AnchorPlate_StiffenerMaterial.id) {
        return state.model[PropertyMetaData.AnchorPlate_IsStiffenerMaterialCustom.id] as boolean;
    }

    return false;
}

function getCustomMaterialName(uiPropertyId: number, state: IDesignState) {
    const isCustomMaterialSelected = isSelectMaterialCustomSelected(uiPropertyId, state);

    if (isCustomMaterialSelected) {
        if (uiPropertyId == PropertyMetaData.AnchorPlate_SteelType.id) {
            return (state.model[PropertyMetaData.AnchorPlate_CustomPlateMaterial.id] as CustomSteelMaterial).Name;
        }

        if (uiPropertyId == PropertyMetaData.Profile_SteelType.id) {
            return (state.model[PropertyMetaData.Profile_CustomProfileMaterial.id] as CustomSteelMaterial).Name;
        }

        if (uiPropertyId == PropertyMetaData.AnchorPlate_StiffenerMaterial.id) {
            return (state.model[PropertyMetaData.AnchorPlate_CustomStiffenerMaterial.id] as CustomSteelMaterial).Name;
        }
    }

    return '';
}
