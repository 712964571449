<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="'SCOPE CHECK DETAILS'"
    [closeButtonId]="'asad-optimize-scope-check-details-modal-close-button'">
</pe-modal-header>

<pe-modal-body *ngIf="scopeCheckError">
    <pe-section [title]="'General'">
        <p class="item">CalculationCount: {{scopeCheckError.calculationCount}}</p>
        <p class="item">ScopeChecks: {{scopeCheckError.scopeChecks.join(', ')}}</p>
        <p class="item">FastenerId: {{scopeCheckError.fastenerId}}</p>
        <p class="item">AnchorPlateWidth: {{formatLength(scopeCheckError.anchorPlateWidth)}}</p>
        <p class="item">AnchorPlateHeight: {{formatLength(scopeCheckError.anchorPlateHeight)}}</p>
        <p class="item">ProfileEccentricityX: {{formatLength(scopeCheckError.profileEccentricityX)}}</p>
        <p class="item">ProfileEccentricityY: {{formatLength(scopeCheckError.profileEccentricityY)}}</p>
        <p class="item" *ngIf="scopeCheckError.embedmentDepth != null">EmbedmentDepth: {{formatLength(scopeCheckError.embedmentDepth)}}</p>
        <p class="item" *ngIf="scopeCheckError.fillHoles != null">FillHoles: {{scopeCheckError.fillHoles}}</p>
        <p class="item" *ngIf="scopeCheckError.isEdgeXNegativeReinforced != null">IsEdgeXNegativeReinforced: {{scopeCheckError.isEdgeXNegativeReinforced}}</p>
        <p class="item" *ngIf="scopeCheckError.isEdgeXPositiveReinforced != null">IsEdgeXPositiveReinforced: {{scopeCheckError.isEdgeXPositiveReinforced}}</p>
        <p class="item" *ngIf="scopeCheckError.isEdgeYNegativeReinforced != null">IsEdgeYNegativeReinforced: {{scopeCheckError.isEdgeYNegativeReinforced}}</p>
        <p class="item" *ngIf="scopeCheckError.isEdgeYPositiveReinforced != null">IsEdgeYPositiveReinforced: {{scopeCheckError.isEdgeYPositiveReinforced}}</p>
        <p class="item" *ngIf="scopeCheckError.baseMaterialEdgeXNegative != null">BaseMaterialEdgeXNegative: {{formatLength(scopeCheckError.baseMaterialEdgeXNegative)}}</p>
        <p class="item" *ngIf="scopeCheckError.baseMaterialEdgeXPositive != null">BaseMaterialEdgeXPositive: {{formatLength(scopeCheckError.baseMaterialEdgeXPositive)}}</p>
        <p class="item" *ngIf="scopeCheckError.baseMaterialEdgeYNegative != null">BaseMaterialEdgeYNegative: {{formatLength(scopeCheckError.baseMaterialEdgeYNegative)}}</p>
        <p class="item" *ngIf="scopeCheckError.baseMaterialEdgeYPositive != null">BaseMaterialEdgeYPositive: {{formatLength(scopeCheckError.baseMaterialEdgeYPositive)}}</p>
    </pe-section>

    <pe-section [title]="'Anchor Coordinates - ' + scopeCheckError.anchorCoordinates.length + ' [' + lengthUnitString + ']'" [collapsed]="true">
        <table class="field-value anchor-coordinates-table">
            <caption>Anchor coordinates table</caption>
            <thead>
                <tr>
                    <th>#</th>
                    <th>x</th>
                    <th>y</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let anchor of scopeCheckError.anchorCoordinates; index as ii">
                    <td>{{ii}}</td>
                    <td>{{formatLengthWithoutUnit(anchor.x)}}</td>
                    <td>{{formatLengthWithoutUnit(anchor.y)}}</td>
                </tr>
            </tbody>
        </table>
    </pe-section>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        class="modal-button"
        id="asad-optimize-scope-check-details-modal-close-button"
        [look]="'Default'"
        (buttonClick)="close()"
        text="Close">
    </pe-button>
</pe-modal-footer>
