import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import {
    SeismicTensionTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export interface ISeismicTensionTypeConstructor extends ICodeListConstructor {
    descriptionResourceKey?: string;
    kbNumberRegion?: { [Key: string]: string };
    kbTooltip?: string;
}

export class SeismicTensionType extends CodeList {
    public descriptionResourceKey?: string;

    constructor(codeList: ISeismicTensionTypeConstructor) {
        super(codeList);

        if (codeList != null) {
            this.descriptionResourceKey = codeList.descriptionResourceKey;
            this.kbNumberRegion = codeList.kbNumberRegion;
            this.kbTooltip = codeList.kbTooltip;
        }
    }

    public static fromService(codeList: SeismicTensionTypeEntity) {
        const seismicTensionType = new SeismicTensionType(CodeList.baseFromService<ICodeListConstructor>('SeismicTensionTypeEntity', codeList, {
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-' + codeList.Image : 'sprite-seismic-type-no-photo-available'
        } as ICodeListConstructor));

        // set description resource key
        seismicTensionType.descriptionResourceKey = seismicTensionType.nameResourceKey != null && seismicTensionType.nameResourceKey != '' ? seismicTensionType.nameResourceKey + '.Description' : undefined;
        seismicTensionType.kbNumberRegion = codeList.KBNumberAciRegion;
        seismicTensionType.kbTooltip = codeList.KBTooltipDisplayKeyAci;

        return seismicTensionType;
    }

    public override getTranslatedDescriptionText(codeListDeps: ICodeListTextDeps) {
        if (this.descriptionResourceKey == null || this.descriptionResourceKey == '') {
            return undefined;
        }

        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        return codeListDeps.localizationService.getString(this.descriptionResourceKey);
    }

    public override cloneDeep(): SeismicTensionType {
        const codeList = super.cloneDeep() as SeismicTensionType;

        codeList.descriptionResourceKey = this.descriptionResourceKey;
        codeList.kbNumberRegion = this.kbNumberRegion;
        codeList.kbTooltip = this.kbTooltip;

        return codeList;
    }
}
