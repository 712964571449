import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { DropdownType as MainMenuDropdownType } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    ButtonGroupDisplay as MainMenuButtonGroupDisplay, ButtonGroupType as MainMenuButtonGroupType, ButtonSize as MainMenuButtonSize,
    ButtonType as MainMenuButtonType, Menu, NavigationTabWidth as MainMenuNavigationTabWidth, TooltipType
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { DesignPe } from '../../shared/entities/design-pe';
import { MainMenuCommandPE } from '../../shared/entities/main-menu';
import { ControlTypePE } from '../../shared/enums/main-menu-control-type';
import { MainMenuPointsTableTypePE } from '../../shared/enums/points-table';
import {
    ButtonGroupDisplay, ButtonGroupType, ButtonSize, ButtonType, Command, Control, ControlType, DropdownType, Navigation as NavigationSrcPe,
    NavigationTabWidth, PointsTableType as PointsTableTypePE, TooltipType as TooltipTypePE
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Navigation';
import { fromPeUnitGroup } from '../../shared/helpers/unit-helper';
import { CalculationServicePE } from '../services/calculation-pe.service';
import { CodeListService } from '../services/code-list.service';
import { CommonCodeListService } from '../services/common-code-list.service';
import { LocalizationService } from '../services/localization.service';
import { LoggerService } from '../services/logger.service';
import { ModalService } from '../services/modal.service';
import { NavigationPE } from '../services/navigation.service';
import { NumberService } from '../services/number.service';
import { UnitService } from '../services/unit.service';
import { getSpriteAsIconStyle } from '../sprites';
import { initializeBaseplateSize } from './main-menu-helpers/baseplate-size';
import { initializeBrickSize } from './main-menu-helpers/brick-size';
import { initializePointsTable } from './main-menu-helpers/points-table';
import { initializeProfileSize } from './main-menu-helpers/profile-size';
import { initializeSelectAnchor } from './main-menu-helpers/select-anchor';
import { initializeSelectMaterial } from './main-menu-helpers/select-material';
import { initializeSelectProfile } from './main-menu-helpers/select-profile';
import { initializeShearLugSize } from './main-menu-helpers/shear-lug-size';
import { initializeSelectSuggestedAnchor } from './main-menu-helpers/suggested-anchor';
import { initializeSelectSuggestedAnchorGroup } from './main-menu-helpers/suggested-anchor-group';
import { initializeSelectUserSelectedAnchorGroup } from './main-menu-helpers/user-selected-anchor-group';
import { initializeInformationPanel } from './main-menu-helpers/information-panel';
import { initializeSelectAnchorSizes } from './main-menu-helpers/anchor-size-multiselect';
import { initializeAdvancedInput } from './main-menu-helpers/advanced-input';
import { createCustomBeams } from './main-menu-helpers/custom-beams';

export function fromService(
    data: NavigationSrcPe,
    design: DesignPe,
    localizationService: LocalizationService,
    modalService: ModalService,
    unitService: UnitService,
    calculationService: CalculationServicePE,
    commonCodeListService: CommonCodeListService,
    codelistService: CodeListService,
    loggerService: LoggerService,
    numberService: NumberService
): NavigationPE {

    return {
        Menus: data.Menus.map(m => {
            m.Footer = m.Footer ?? {};

            const menu: Menu<any, any> = {
                ...m,
                Tabs: m.Tabs.map(t => {
                    return {
                        ...t,
                        IconImage: getSpriteAsIconStyle(`sprite-${t.Image}`),
                        Width: widthFromService(t.Width) ?? MainMenuNavigationTabWidth.Normal,
                        TabRegions: t.TabRegions.map(tr => {
                            return {
                                ...tr,
                                Command: commandFromService(tr.Command),
                                TooltipType: tooltipTypeFromService(tr.TooltipType),
                                Controls: tr.Controls.map(c => {
                                    return {
                                        ...c,
                                        UnitGroup: fromPeUnitGroup(c.UnitGroup),
                                        PointsTableType: pointsTableTypeFromService(c.PointsTableType),
                                        ButtonGroupDisplay: buttonGroupDisplayFromService(c.ButtonGroupDisplay),
                                        ButtonGroupType: buttonGroupTypeFromService(c.ButtonGroupType),
                                        ButtonSize: buttonSizeFromService(c.ButtonSize),
                                        ButtonType: buttonTypeFromService(c.ButtonType),
                                        Command: commandFromService(c.Command),
                                        ControlType: controlTypeFromService(c.ControlType),
                                        DropdownType: dropdownTypeFromService(c.DropdownType),
                                        TooltipType: tooltipTypeFromService(c.TooltipType),
                                        initialize: intializeControls(c)
                                    };
                                })
                            };
                        })
                    };
                }),
                Footer: {
                    Controls: m.Footer.Controls?.map(c => {
                        return {
                            ...c,
                            ButtonSize: buttonSizeFromService(c.ButtonSize),
                            ButtonType: buttonTypeFromService(c.ButtonType),
                            Command: commandFromService(c.Command),
                            ControlType: controlTypeFromService(c.ControlType),
                            initialize: intializeControls(c)
                        };
                    })
                }
            };
            return menu;
        })
    };

    function intializeControls(c: Control) {
        return (controlProps: IControlProps, modals: { [modal: number]: (input?: object) => IModalOpened }, setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu) => initializeCustomControl(
            controlProps,
            design,
            c.UIPropertyId as unknown as number,
            localizationService,
            unitService,
            modalService,
            calculationService,
            commonCodeListService,
            codelistService,
            loggerService,
            numberService,
            c.Name,
            modals,
            setState,
            c.ControlType,
            c.DisplayUIPropertyId,
            c.DisplayKey,
            c.CodelistName,
            c.Image);
    }
}

function widthFromService(width: NavigationTabWidth): MainMenuNavigationTabWidth | undefined {
    switch (width) {
        case null:
        case undefined:
            return undefined;
        case NavigationTabWidth.Extended: return MainMenuNavigationTabWidth.Extended;
        case NavigationTabWidth.Normal: return MainMenuNavigationTabWidth.Normal;
        default:
            throw new Error('Unsupported navigation type width');
    }
}

function buttonGroupDisplayFromService(buttonGroupDisplay?: ButtonGroupDisplay) {
    switch (buttonGroupDisplay) {
        case null:
        case undefined:
            return undefined;
        case ButtonGroupDisplay.Separated: return MainMenuButtonGroupDisplay.Separated;
        case ButtonGroupDisplay.Join: return MainMenuButtonGroupDisplay.Join;
        default: throw new Error('Unsupported button group display');
    }
}

function buttonGroupTypeFromService(buttonGroupType?: ButtonGroupType) {
    switch (buttonGroupType) {
        case null:
        case undefined:
            return undefined;
        case ButtonGroupType.Single: return MainMenuButtonGroupType.Single;
        case ButtonGroupType.Multiple: return MainMenuButtonGroupType.Multiple;
        default:
            throw new Error('Unsupported button group type');
    }
}

function buttonSizeFromService(buttonSize?: ButtonSize) {
    switch (buttonSize) {
        case null:
        case undefined:
            return undefined;
        case ButtonSize.Normal: return MainMenuButtonSize.Normal;
        case ButtonSize.Full: return MainMenuButtonSize.Full;
        default: throw new Error('Unsupported button size');
    }
}

function dropdownTypeFromService(dropdownType?: DropdownType) {
    switch (dropdownType) {
        case null:
        case undefined:
            return undefined;
        case DropdownType.Block: return MainMenuDropdownType.block;
        case DropdownType.Inline: return MainMenuDropdownType.inline;
        default: throw new Error('Unsupported dropdown type');
    }
}

function tooltipTypeFromService(tooltipType?: TooltipTypePE) {
    switch (tooltipType) {
        case null:
        case undefined:
            return undefined;
        case TooltipTypePE.Normal: return TooltipType.Normal;
        case TooltipTypePE.Popup: return TooltipType.Popup;
        default: throw new Error('Unsupported tooltip type');
    }
}

function buttonTypeFromService(buttonType?: ButtonType) {
    switch (buttonType) {
        case null:
        case undefined:
            return undefined;
        case ButtonType.Default: return MainMenuButtonType.Default;
        case ButtonType.Primary: return MainMenuButtonType.Primary;
        case ButtonType.Ghost: return MainMenuButtonType.Ghost;
        case ButtonType.Link: return MainMenuButtonType.Link;
        case ButtonType.LargerGray: return MainMenuButtonType.LargerGray;
        case ButtonType.AnchorAI: return MainMenuButtonType.AnchorAI;
        default: throw new Error('Unsupported button type');
    }
}

function commandFromService(command?: Command): MainMenuCommandPE | undefined {

    switch (command) {
        case null:
        case undefined:
            return undefined;
        case Command.OpenImportLoadsPopup: return 'OpenImportLoadsPopup';
        case Command.OpenCustomizeDetailsPopup: return 'OpenCustomizeDetailsPopup';
        case Command.OpenInputHandrailLoadsPopup: return 'OpenInputHandrailLoadsPopup';
        case Command.OpenWindloadsPopup: return 'OpenWindloadsPopup';
        case Command.Open2dEditor: return 'Open2dEditor';
        case Command.OpenApproval: return 'OpenApproval';
        case Command.OpenDlubalImportPopup: return 'OpenDlubalImportPopup';
        case Command.OpenDlubalExportPopup: return 'OpenDlubalExportPopup';
        case Command.OpenDefineAnchorsPopup: return 'OpenDefineAnchorsPopup';
        case Command.OpenAdvancedSettings: return 'OpenAdvancedSettings';
        case Command.OpenFullscreenLoader: return 'OpenFullscreenLoader';
        case Command.OpenOnSiteTestsValuesPopup: return 'OpenOnSiteTestsValuesPopup';
        case Command.OpenAdvancedCalculation: return 'OpenAdvancedCalculation';
        case Command.OpenNotOwnedLearnMore: return 'OpenNotOwnedLearnMore';
        case Command.OpenExportRisa: return 'OpenExportRisa';
        case Command.OpenSAP2000ImportPopup: return 'OpenSAP2000ImportPopup';
        case Command.OpenHorizontalPostProfileConnectionPopup: return 'OpenHorizontalPostProfileConnectionPopup';
        case Command.OpenAdvancedCalculationTypeInfoPopup: return 'OpenAdvancedCalculationTypeInfoPopup';
        case Command.OpenRobotImportPopup: return 'OpenRobotImportPopup';
        case Command.OpenETABSImportPopup: return 'OpenETABSImportPopup';
        case Command.OpenStaadProImportPopup: return 'OpenStaadProImportPopup';
        case Command.OpenSupplementaryReinforcementInfoPopup: return 'OpenSupplementaryReinforcementInfoPopup';
        case Command.OpenSeismicFillingSetPopup: return 'OpenSeismicFillingSetPopup';
        case Command.OpenAnchorDetailedFilterPopup: return 'OpenAnchorDetailedFilterPopup';
        case Command.OpenAnchorNeedSolutionPopup: return 'OpenAnchorNeedSolutionPopup';
        case Command.OpenAutomaticTorquePopUp: return 'OpenAutomaticTorquePopUp';
        case Command.OpenAdvancedInputs: return 'OpenAdvancedInputs';
        case Command.OpenWHGInfoPopup: return 'OpenWHGInfoPopup';
        case Command.OpenFillHolesPopup: return 'OpenFillHolesPopup';
        case Command.OpenMoreInfoOnSOFAPopup: return 'OpenMoreInfoOnSOFAPopup';
        case Command.OpenMoreInfoOnFatiguePopup: return 'OpenMoreInfoOnFatiguePopup';
        case Command.OpenMoreInfoOnFireRedirect: return 'OpenMoreInfoOnFireRedirect';
        case Command.OpenShearLoadDistributionPopup: return 'OpenShearLoadDistributionPopup';
        default: throw new Error('Unsupported command');
    }
}

function pointsTableTypeFromService(pointsTableType?: PointsTableTypePE): MainMenuPointsTableTypePE | undefined {
    switch (pointsTableType) {
        case null:
        case undefined:
            return undefined;
        case PointsTableTypePE.Anchor: return 'Anchor';
        case PointsTableTypePE.Plate: return 'Plate';
        default: throw new Error('Points table type');
    }
}

function initializeCustomControl(
    controlProps: IControlProps,
    design: DesignPe,
    uiPropertyId: number,
    localizationService: LocalizationService,
    unitService: UnitService,
    modalService: ModalService,
    calculationService: CalculationServicePE,
    commonCodeListService: CommonCodeListService,
    codelistService: CodeListService,
    loggerService: LoggerService,
    numberService: NumberService,
    controlName: string,
    modals: { [modal: number]: (input?: object) => IModalOpened },
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
    controlType?: ControlType,
    displayUiPropertyId?: number,
    displayKey?: string,
    codelistName?: string,
    imageName?: string) {
    switch (controlType) {
        case ControlType.SelectAnchor: return initializeSelectAnchor(design, controlProps, localizationService, modalService, uiPropertyId, setState);
        case ControlType.BrickSize: return initializeBrickSize(design, controlProps, localizationService, unitService, uiPropertyId, modals, setState);
        case ControlType.BaseplateSize: return initializeBaseplateSize(design, controlProps, localizationService, unitService, uiPropertyId, modals, setState);
        case ControlType.ProfileSize: return initializeProfileSize(calculationService, commonCodeListService, design, controlProps, localizationService, unitService, uiPropertyId, modals, setState, displayUiPropertyId, displayKey);
        case ControlType.SelectMaterial: return initializeSelectMaterial(design, controlProps, localizationService, uiPropertyId, modals);
        case ControlType.SelectProfile: return initializeSelectProfile(calculationService, design, controlProps, localizationService, uiPropertyId, modals, setState, displayKey);
        case ControlType.SuggestedAnchor: return initializeSelectSuggestedAnchor(calculationService, design, controlProps, localizationService, uiPropertyId, setState);
        case ControlType.SuggestedAnchorGroup: return initializeSelectSuggestedAnchorGroup(codelistService, calculationService, modalService, loggerService, design, controlProps, localizationService, numberService, uiPropertyId, controlName, setState, codelistName);
        case ControlType.ShearLugSize: return initializeShearLugSize(calculationService, design, controlProps, localizationService, unitService, codelistService, commonCodeListService, uiPropertyId, modals, setState, displayKey, displayUiPropertyId);
        case ControlType.PointsTable: return initializePointsTable(controlProps, unitService);
        case ControlType.UserSelectedAnchorGroup: return initializeSelectUserSelectedAnchorGroup(codelistService, calculationService, loggerService, design, controlProps, localizationService, numberService, uiPropertyId, controlName, codelistName);
        case ControlType.InformationPanel: return initializeInformationPanel(design, controlProps, localizationService, uiPropertyId, displayKey, imageName);
        case ControlType.AdvancedInput: return initializeAdvancedInput(design, controlProps, localizationService, modalService, displayKey);
        case ControlType.AnchorSizeMultiSelect: return initializeSelectAnchorSizes(codelistService, calculationService, design, controlProps, localizationService, numberService, uiPropertyId, controlName, setState, codelistName);
        case ControlType.CustomBeams: return createCustomBeams(controlProps, design.model[uiPropertyId] as unknown as any[]);
        default: return undefined;
    }
}

function controlTypeFromService(controlType?: ControlType): ControlTypePE {
    switch (controlType) {
        case ControlType.TextBox: return 'TextBox';
        case ControlType.Dropdown: return 'Dropdown';
        case ControlType.Checkbox: return 'Checkbox';
        case ControlType.CheckboxGroup: return 'CheckboxGroup';
        case ControlType.RadioButton: return 'RadioButton';
        case ControlType.RadioButtonGroup: return 'RadioButtonGroup';
        case ControlType.Button: return 'Button';
        case ControlType.ToggleButton: return 'ToggleButton';
        case ControlType.ToggleButtonGroup: return 'ToggleButtonGroup';
        case ControlType.ButtonGroup: return 'ButtonGroup';
        case ControlType.Group: return 'Group';
        case ControlType.Label: return 'Label';
        case ControlType.ToggleImageButton: return 'ToggleImageButton';
        case ControlType.Popup: return 'Popup';
        case ControlType.Rotate: return 'Rotate';
        case ControlType.Link: return 'Link';
        case ControlType.PopupGrid: return 'PopupGrid';
        case ControlType.PopupGridPartial: return 'PopupGridPartial';
        case ControlType.DlubalImportExport: return 'DlubalImportExport';
        case ControlType.SAP2000ImportExport: return 'SAP2000ImportExport';
        case ControlType.RobotImportExport: return 'RobotImportExport';
        case ControlType.ETABSImportExport: return 'ETABSImportExport';
        case ControlType.StaadProImportExport: return 'StaadProImportExport';
        case ControlType.ImageNameRadioGroup: return 'ImageNameRadioGroup';
        case ControlType.ImageNameSelectionGroup: return 'ImageNameSelectionGroup';
        case ControlType.SwitchWithDescription: return 'SwitchWithDescription';
        case ControlType.RangeSlider: return 'RangeSlider';
        case ControlType.TabGroup: return 'TabGroup';
        case ControlType.CustomBeams: return 'CustomBeams';

        case ControlType.PointsTable:
        case ControlType.ShearLugSize:
        case ControlType.SuggestedAnchorGroup:
        case ControlType.UserSelectedAnchorGroup:
        case ControlType.InformationPanel:
        case ControlType.AdvancedInput:
        case ControlType.AnchorSizeMultiSelect:
        case ControlType.SuggestedAnchor:
        case ControlType.SelectProfile:
        case ControlType.SelectMaterial:
        case ControlType.ProfileSize:
        case ControlType.BrickSize:
        case ControlType.BaseplateSize:
        case ControlType.SelectAnchor: return 'CustomControl';
        default: throw new Error('Unsupported control type');
    }
}
