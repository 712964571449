import { BeamTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class BeamType extends CodeList {

    public static fromService(codeList: BeamTypeEntity) {
        return new BeamType(CodeList.baseFromService('BeamTypeEntity', codeList));
    }

    public override cloneDeep(): BeamType {
        return super.cloneDeep() as BeamType;
    }
}
