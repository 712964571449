import { Injectable } from '@angular/core';
import {
    DesignSettingsServiceInjected
} from '@profis-engineering/pe-ui-common/services/design-settings.common';

@Injectable({
    providedIn: 'root'
})
export class DesignSettingsService extends DesignSettingsServiceInjected {
    // Nothing to do.
}
