
import { JointMaterialEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class JointMaterial extends CodeList {

    public static fromService(codeList: JointMaterialEntity) {
        return new JointMaterial(CodeList.baseFromService('JointMaterialEntity', codeList));
    }

    public override cloneDeep(): JointMaterial {
        return super.cloneDeep() as JointMaterial;
    }
}
