import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import {
    propertyValueChanged, scopeCheckChanged
} from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { AnchorShape, IAnchorPoint } from '@profis-engineering/pe-gl-model/components/anchor';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import { AnchorEmbedmentDepth } from '../../../../shared/entities/code-lists/anchor-embedment-depth';
import { AnchorFamily } from '../../../../shared/entities/code-lists/anchor-family';
import { AnchorLayout } from '../../../../shared/entities/code-lists/anchor-layout';
import { AnchorSize } from '../../../../shared/entities/code-lists/anchor-size';
import { AnchorType } from '../../../../shared/entities/code-lists/anchor-type';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { DesignPe } from '../../../../shared/entities/design-pe';
import {
    MeasureScopeCheckParameterItem as ScopeCheck
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import { MeasureResultType } from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import { UIProperty } from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { AnchorLayoutPointEntity } from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import { AnchorLayoutSymmetry } from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { DesignMethodGroupHelper } from '../../../../shared/helpers/design-method-group-helper';

export class AnchorUpdate extends BaseUpdate {

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged(UIProperty.AnchorLayout_EmbedmentDepthVariable)
    private AnchorLayout_EmbedmentDepthVariable(value: number, model: IModelPe) {
        if (value != null && model.anchor) {
            model.anchor.embedmentDepth = value;
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_EmbedmentDepthFixedMultiple)
    private AnchorLayout_EmbedmentDepthFixedMultiple(value: number, model: IModelPe, design: DesignPe) {
        if (value != null && model.anchor) {
            const codeList = design.designData.designCodeLists[DesignCodeList.AnchorEmbedmentDepth] as AnchorEmbedmentDepth[];
            const embedmentDepth = codeList.find((embedmentDepth) => embedmentDepth.id == value);

            model.anchor.embedmentDepth = embedmentDepth?.hef;
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_Size)
    private AnchorLayout_Size(anchorSizeId: number, model: IModelPe, design: DesignPe) {
        if (!model.anchor) {
            throw new Error('model anchor is undefined');
        }

        if (anchorSizeId != null) {
            const anchorSizeCodeList = DesignMethodGroupHelper.IsLrfdBased(design.designMethodGroup?.id)
                ? design.designData.designCodeLists[DesignCodeList.AnchorSizeAC58] as AnchorSize[]
                : design.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSize[];

            const anchorSize = anchorSizeCodeList.find((anchorSize) => anchorSize.id == anchorSizeId);

            model.anchor.diameter = anchorSize?.diameter;
        }
        else {
            model.anchor.diameter = 0;
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_CustomLayoutPoints)
    private AnchorLayout_CustomLayoutPoints(points: AnchorLayoutPointEntity[], model: IModelPe, design: DesignPe) {
        if (!model.anchor) {
            throw new Error('model anchor is undefined');
        }

        model.anchor.points = points?.map((point): IAnchorPoint => ({
            position: new Vector2(point.X, point.Y),
            isSlotted: point.IsSlotted,
            slottingOrientation: point.SlottingOrientation
        }));

        // anchor forces
        if (model.anchor.points != null && design.designData.reportData?.AnchorForces != null) {
            for (let i = 0; i < design.designData.reportData.AnchorForces.length; i++) {
                const anchorForce = design.designData.reportData.AnchorForces[i];

                model.anchor.points[i].force = {
                    qx: anchorForce.Qx,
                    qy: anchorForce.Qy,
                    n: anchorForce.N
                };
            }
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_Layout)
    private AnchorLayout_Layout(anchorLayoutId: number, model: IModelPe, design: DesignPe) {
        if (!model.anchor) {
            throw new Error('model anchor is undefined');
        }

        if (anchorLayoutId == 0) {
            model.anchor.shape = AnchorShape.Custom;
        }
        else if (anchorLayoutId != null) {
            const codeList = design.designData.designCodeLists[DesignCodeList.AnchorLayout] as AnchorLayout[];
            const anchorLayout = codeList.find((anchorLayout) => anchorLayout.id == anchorLayoutId);

            model.anchor.shape = this.propertyAnchorShapeToModalAnchorShape(anchorLayout?.shape ?? '') ?? undefined;
        }
        else {
            model.anchor.shape = undefined;
        }
    }


    @propertyValueChanged(UIProperty.AnchorLayout_AnchorLayoutSymmetry)
    private AnchorLAyout_AnchorLayoutSymmetry(value: AnchorLayoutSymmetry, model: IModelPe) {
        if (value != null) {
            model.isAnchorLayoutSymmetric = value == AnchorLayoutSymmetry.Symmetric;
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_FillHolesETAG)
    private AnchorLayout_FillHolesETAG(value: boolean, model: IModelPe, design: DesignPe) {
        if (!model.anchor) {
            throw new Error('model anchor is undefined');
        }

        let countersunk = false;
        const anchorFamilyId = design.model[UIProperty.AnchorLayout_AnchorFamily] as number;

        if (anchorFamilyId != null) {
            const codeList = design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];
            const anchorFamily = codeList.find(anchorFamily => anchorFamily.id == anchorFamilyId);

            countersunk = anchorFamily?.countersunk ?? false;
        }

        // don't fill holes if countersunk is true
        model.anchor.fillHoles = !countersunk && value;
    }

    @propertyValueChanged(UIProperty.AnchorLayout_Rotate)
    private AnchorLayout_Rotate(value: number, model: IModelPe) {
        if (model.anchor) {
            model.anchor.angleY = value;
        }
    }

    @propertyValueChanged(UIProperty.AnchorLayout_AnchorFamily)
    private AnchorLayout_AnchorFamily(anchorFamilyId: number, model: IModelPe, design: DesignPe) {
        if (!model.anchor) {
            throw new Error('model anchor is undefined');
        }

        let countersunk = false;

        if (anchorFamilyId != null) {
            const codeList = DesignMethodGroupHelper.IsLrfdBased(design.designMethodGroup?.id)
                ? design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
                : design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

            const anchorFamily = codeList.find(anchorFamily => anchorFamily.id == anchorFamilyId);

            countersunk = anchorFamily?.countersunk ?? false;
        }

        // don't fill holes if countersunk is true
        model.anchor.fillHoles = !countersunk && (design.model[UIProperty.AnchorLayout_FillHolesETAG] as boolean);
    }

    @propertyValueChanged(UIProperty.AnchorLayout_Type)
    private AnchorLayout_AnchorType(anchorTypeId: number, model: IModelPe, design: DesignPe) {
        if (anchorTypeId != null && model.anchor) {
            const codeList = DesignMethodGroupHelper.IsLrfdBased(design.designMethodGroup?.id)
                ? design.designData.designCodeLists[DesignCodeList.AnchorTypeAC58] as AnchorType[]
                : design.designData.designCodeLists[DesignCodeList.AnchorType] as AnchorType[];

            const anchorType = codeList.find(anchorType => anchorType.id == anchorTypeId);

            model.anchor.isCIPLongPlate = anchorType?.cipLongPlate;
        }
    }

    /* SCOPE CHECK CHANGED */
    @scopeCheckChanged()
    private scopeChecksChanged(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (scopeChecks != null && model.anchor) {
            model.anchor.invalid = model.anchor.invalid ?? {};

            model.anchor.invalid.SY = scopeChecks[MeasureResultType.AnchorSpacingLengthB] != null;
            model.anchor.invalid.SX = scopeChecks[MeasureResultType.AnchorSpacingLengthA] != null;
            model.anchor.invalid.plateSpacing = scopeChecks[MeasureResultType.AnchorToAnchorPlateEdge] != null ? scopeChecks[MeasureResultType.AnchorToAnchorPlateEdge].Value : null;
        }
    }

    private propertyAnchorShapeToModalAnchorShape(shape: string) {
        switch (shape) {
            case 'Matrix':
                return AnchorShape.Matrix;
            case 'Circular':
                return AnchorShape.Circular;
            case 'Custom':
                return AnchorShape.Custom;
            case 'None':
            case undefined:
            case null:
                return null;

            default:
                throw new Error('Unknown anchor shape.');
        }
    }
}
