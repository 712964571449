<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.BrickSize.Title')">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section">
            <div class="control-row">
                <pe-numeric-text-box
                    [id]="widthTextBox.id"
                    [title]="widthTextBox.title"
                    [value]="widthTextBox.value"
                    (valueChange)="widthTextBox.value = $any($event).detail"
                    [unit]="widthTextBox.unit"
                    [minValue]="widthTextBox.minValue"
                    [maxValue]="widthTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="widthTextBox.isValid = $any($event).detail"
                    [disabled]="submitted || widthRange.disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control-row">
                <pe-numeric-text-box
                    [id]="heightTextBox.id"
                    [title]="heightTextBox.title"
                    [value]="heightTextBox.value"
                    (valueChange)="heightTextBox.value = $any($event).detail"
                    [unit]="heightTextBox.unit"
                    [minValue]="heightTextBox.minValue"
                    [maxValue]="heightTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="heightTextBox.isValid = $any($event).detail"
                    [disabled]="submitted || heightRange.disabled">
                </pe-numeric-text-box>
            </div>

            <div class="control-row">
                <pe-numeric-text-box
                    [id]="thicknessTextBox.id"
                    [title]="thicknessTextBox.title"
                    [value]="thicknessTextBox.value"
                    (valueChange)="thicknessTextBox.value = $any($event).detail"
                    [unit]="thicknessTextBox.unit"
                    [minValue]="thicknessTextBox.minValue"
                    [maxValue]="thicknessTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="thicknessTextBox.isValid = $any($event).detail"
                    [disabled]="submitted || thicknessRange.disabled">
                </pe-numeric-text-box>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'brick-size-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.BrickSize.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'brick-size-save-button'"
            [look]="'Cta'"
            type="submit"
            class="modal-button"
            [disabled]="!formValid || submitted || areInputsDisabled">
            <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.Profis3.BrickSize.Save')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
