import {
    HeadJointSelectionEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class HeadJointSelection extends CodeList {

    public static fromService(codeList: HeadJointSelectionEntity) {
        const baseObject = new HeadJointSelection(CodeList.baseFromService('HeadJointSelectionEntity', codeList));
        baseObject.infoPopupCommand = codeList.InfoButtonClick;
        return baseObject;
    }

    public override cloneDeep(): HeadJointSelection {
        return super.cloneDeep() as HeadJointSelection;
    }
}