import sortBy from 'lodash-es/sortBy';

import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { IProfileSizeComponentInput } from '../../../shared/components/profile-size';
import { IProfileSizeItem } from '../../../shared/entities/profile-size';
import {
    ProfileFormingNorme
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

/*
 If other filter rows/controls are added please do not forget to change the max-height calculation!
 Check profile-size.component.scss (search for "max-height: calc")
*/
@Component({
    templateUrl: './profile-size.component.html',
    styleUrls: ['./profile-size.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ProfileSizeComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IProfileSizeComponentInput>;

    @ViewChild('profileSizeTable')
    profileSizeTableElement?: ElementRef;

    public filteredItems!: IProfileSizeItem[];
    public selectedItemFilteredOut = false;
    public profileFilterDropdown!: DropdownProps<ProfileFormingNorme>;

    constructor(
        private localization: LocalizationService,
        private userSettings: UserSettingsService,
        private user: UserService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get showProfileFilter() {
        return (this.modalInstance.input?.showProfileFilter ?? false)
            && !this.user.design.stainlessSteel
            && (this.profileFilterDropdown?.items?.length ?? 0) > 1;
    }

    // Items props
    public get sortedItems() {
        return sortBy(this.filteredItems,
            item => this.getSortingIndex(item)
        );
    }

    public get favoriteTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Menu_Favorites) ||
            this.localization.getString('Agito.Hilti.Profis3.Main.Region.AddRemoveFromFavorites');
    }

    public get columns() {
        return this.modalInstance.input?.columns ?? [];
    }

    public get modalTitle() {
        return this.localization.getString(this.modalInstance.input?.title ?? '');
    }

    public get selectedValue() {
        return this.modalInstance.input?.selectedValue;
    }
    public set selectedValue(value: number | undefined) {
        if (this.modalInstance.input != null) {
            this.modalInstance.input.selectedValue = value;
        }
    }

    // Helper props
    private get selectedFilter() {
        return this.modalInstance.input?.selectedFilter;
    }

    private get items() {
        return this.modalInstance.input?.items ?? [];
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-favorite-false',
            'sprite-favorite-true'
        );

        this.filteredItems = this.items;

        this.profileFilterDropdown = {
            id: 'select-profile-sort-by-dropdown',
            items: this.getItemsForDropdown()
        };
        this.onProfileFilterDropdownChange(
            this.selectedFilter != null
                ? this.selectedFilter
                : ProfileFormingNorme.Unknown
        );

        setTimeout(() => { this.scrollToSelected(); });
    }

    public close() {
        this.modalInstance.close(this.profileFilterDropdown.selectedValue);
    }

    // Filter methods
    public onProfileFilterDropdownChange(selectedValue: ProfileFormingNorme) {
        this.profileFilterDropdown.selectedValue = selectedValue;

        // Filter items
        this.selectedItemFilteredOut = false;

        this.filteredItems = this.filterItems(selectedValue, true, ...this.items);

        const selectedIndex = this.filteredItems.findIndex(item => this.selectedValue == item.id);
        const filteredItems = this.filterItems(selectedValue, false, this.filteredItems[selectedIndex]);
        if (filteredItems.length == 0) {
            this.selectedItemFilteredOut = true;
        }

        this.filteredItems = this.sortedItems;

        setTimeout(() => { this.scrollToSelected(); });
    }

    // Item methods
    public async select(item: IProfileSizeItem) {
        const value = item.id;
        this.selectedValue = value;

        if (this.modalInstance.input?.onSelect != null) {
            await this.modalInstance.input.onSelect(value);
        }

        this.close();
    }

    public isOdd(item: IProfileSizeItem) {
        const index = this.filteredItems.indexOf(item);

        if (index < 0) {
            return false;
        }

        return index % 2 != 0;
    }

    public isEven(item: IProfileSizeItem) {
        const index = this.filteredItems.indexOf(item);

        if (index < 0) {
            return false;
        }

        return index % 2 == 0;
    }

    public isClassFilteredOutEnabled(item: IProfileSizeItem) {
        return this.selectedItemFilteredOut && this.selectedValue == item.id;
    }

    public getFavoriteSprite(itemId: number) {
        const prefix = 'pe-ui-pe-sprite-favorite-';

        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.user.design.region.id)) {
            return `${prefix}false disabled`;
        }

        return this.isFavorite(itemId) ? `${prefix}true` : `${prefix}false`;
    }

    public favoriteToggle(itemId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.user.design.region.id)) {
            return;
        }

        if (
            this.userSettings.settings.profileSizeFavorites.value != null
            && this.isFavorite(itemId)
        ) {
            this.userSettings.settings.profileSizeFavorites.value = Object.fromEntries(Object.entries(
                this.userSettings.settings.profileSizeFavorites.value)
                .filter(([favoriteId]) => favoriteId != itemId.toString()));
        }
        else {
            this.userSettings.settings.profileSizeFavorites.value = {
                ...this.userSettings.settings.profileSizeFavorites.value,
                [itemId]: undefined
            };
        }

        this.userSettings.save();
    }

    public isFavorite(itemId: number) {
        if (this.userSettings.settings.profileSizeFavorites.value != null) {
            return itemId.toString() in this.userSettings.settings.profileSizeFavorites.value;
        }

        return false;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public getItemPropertyValue(item: IProfileSizeItem, property: string) {
        type ObjectKey = keyof typeof item;

        return item[property as ObjectKey];
    }

    // Helpers methods
    private getItemsForDropdown() {
        /*
        Option 'Both' is always shown in dropdown.
        Options 'Hot rolled profiles' and 'Cold formed profiles' in dropdown are shown only if there is at least one item with this forming norme in the array.
         */
        const items: DropdownItem<ProfileFormingNorme>[] = [
            {
                text: this.localization.getString('Agito.Hilti.Profis3.SelectProfile.SortBy.Default'),
                value: ProfileFormingNorme.Unknown
            }
        ];

        if (this.items == null || this.items.length == 0) {
            return items;
        }

        const coldFormed = this.items.some(item => item.codeList != null && item.codeList.formingNorme == ProfileFormingNorme.Coldformed);
        if (coldFormed) {
            items.push({
                text: this.localization.getString('Agito.Hilti.Profis3.SelectProfile.SortBy.Cold'),
                value: ProfileFormingNorme.Coldformed
            });
        }

        const hotRolled = this.items.some(item => item.codeList != null && item.codeList.formingNorme == ProfileFormingNorme.Hotrolled);
        if (hotRolled) {
            items.push({
                text: this.localization.getString('Agito.Hilti.Profis3.SelectProfile.SortBy.Hot'),
                value: ProfileFormingNorme.Hotrolled
            });
        }

        return items;
    }

    private filterItems(filter: ProfileFormingNorme, keepSelected: boolean, ...items: IProfileSizeItem[]) {
        if (
            !this.showProfileFilter
            || filter == null
            || filter == ProfileFormingNorme.Unknown
        ) {
            return items;
        }

        return items.filter(item =>
            (keepSelected && item.id == this.selectedValue)
            ||
            (
                item.codeList != null
                &&
                (
                    item.codeList.formingNorme == filter
                    ||
                    (
                        item.codeList.formingNorme == ProfileFormingNorme.Unknown
                        && !item.codeList.isPostAndRailDesignRelevant
                    )
                )
            )
        );
    }

    private scrollToSelected() {
        if (this.filteredItems.length == 0) {
            return;
        }

        const profileSizeTableHTMLElement = this.profileSizeTableElement?.nativeElement as HTMLElement;
        profileSizeTableHTMLElement.querySelector('.selected')?.previousElementSibling?.scrollIntoView();
    }

    private getSortingIndex(item: IProfileSizeItem) {
        if (
            item?.id == null
            ||
            this.isFavorite(item.id)
        ) {
            return 0;
        }

        return 1;
    }
}
