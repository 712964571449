import {
    WeldingTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class WeldingType extends CodeList {

    public static fromService(codeList: WeldingTypeEntity) {
        return new WeldingType(CodeList.baseFromService('WeldingTypeEntity', codeList));
    }

    public override cloneDeep(): WeldingType {
        return super.cloneDeep() as WeldingType;
    }
}
