import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import {
    WindVelocityEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IWindVelocity extends ICodeListConstructor {
    value: number;
}

export class WindVelocity extends CodeList {
    public value!: number;

    constructor(codelist: IWindVelocity) {
        super(codelist);

        if (codelist != null) {
            this.value = codelist.value;
        }
    }

    public static fromService(codeList: WindVelocityEntity) {
        return new WindVelocity(CodeList.baseFromService<IWindVelocity>('WindVelocityEntity', codeList, {
            value: codeList.Value
        } as IWindVelocity));
    }

    public override getTranslatedNameText(codeListDeps: ICodeListTextDeps) {
        let prefix: string | null = null;
        if (this.nameResourceKey != null && this.nameResourceKey != '') {
            if (codeListDeps.localizationService == null) {
                throw new Error('codeListDeps.localizationService not set!');
            }

            const translation = codeListDeps.localizationService.getString(this.nameResourceKey, { optional: true })?.trim();

            if (translation != null && translation != '') {
                prefix = translation;
            }
        }

        if (codeListDeps.numberService == null) {
            throw new Error('codeListDeps.numberService not set!');
        }

        const number = codeListDeps.numberService.format(this.value, Number.MAX_SAFE_INTEGER);
        return `${prefix != null && prefix != '' ? prefix + ' ' : ''}${number} m/s`;
    }

    public override cloneDeep(): WindVelocity {
        const codelist: WindVelocity = super.cloneDeep() as WindVelocity;

        codelist.value = this.value;

        return codelist;
    }
}
