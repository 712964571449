import { DesignMethodHNAEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class DesignMethodHNA extends CodeList {

    public static fromService(codeList: DesignMethodHNAEntity) {
        return new DesignMethodHNA(CodeList.baseFromService('DesignMethodHNAEntity', codeList));
    }

    public override cloneDeep(): DesignMethodHNA {
        return super.cloneDeep() as DesignMethodHNA;
    }
}
