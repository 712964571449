<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="body-content">
        <span class="li-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionOne.Title')"></span>
        <ul>
            <li>
                <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionOne.Line1')"></span>
            </li>
            <li>
                <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionOne.Line2')"></span>
            </li>
        </ul>
        <span class="li-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionTwo.Title')"></span>
        <hr />
        <div class="more-info-on-fatigue-popup">
            <div class="left">
                <span class="li-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionThree.Title')"></span>
                <ul>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionThree.Line1')"></span>
                    </li>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionThree.Line2')"></span>
                    </li>
                </ul>
            </div>
            <div class="middle">
                <img [src]="moreInfoOnFatigueImage1" alt="more-info-on-fatigue">
            </div>
            <div class="right">
                <img [src]="moreInfoOnFatigueTable1" alt="more-info-on-fatigue">
            </div>
        </div>
        <hr />
        <div class="more-info-on-fatigue-popup">
            <div class="left">
                <span class="li-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionFour.Title')"></span>
                <ul>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionFour.Line1')"></span>
                    </li>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionFour.Line2')"></span>
                    </li>
                </ul>
            </div>
            <div class="middle">
                <img [src]="moreInfoOnFatigueImage2" alt="more-info-on-fatigue">
            </div>
            <div class="right">
                <img [src]="moreInfoOnFatigueTable2" alt="more-info-on-fatigue">
            </div>
        </div>
        <hr />
        <div class="more-info-on-fatigue-popup">
            <div class="left">
                <span class="li-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionFive.Title')"></span>
                <ul>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionFive.Line1')"></span>
                    </li>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.MoreInfoOnFatigue.Tooltip.SectionFive.Line2')"></span>
                    </li>
                </ul>
            </div>
            <div class="middle">
                <img [src]="moreInfoOnFatigueImage3" alt="more-info-on-fatigue">
            </div>
            <div class="right">
                <img [src]="moreInfoOnFatigueTable3" alt="more-info-on-fatigue">
            </div>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
