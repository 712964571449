import { ConcreteGradeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class ConcreteGrade extends CodeList {

    public static fromService(codeList: ConcreteGradeEntity) {
        return new ConcreteGrade(CodeList.baseFromService('ConcreteGradeEntity', codeList));
    }

    public override cloneDeep(): ConcreteGrade {
        return super.cloneDeep() as ConcreteGrade;
    }
}
