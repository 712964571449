import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    ProfileFamilyEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    ProfileShapeType as ProfileShape, ProfileStandard
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from '../code-lists/code-list';

export interface IProfileFamilyConstructor extends ICodeListConstructor {
    shape?: ProfileShape;
    profileStandard?: ProfileStandard;
}

export class ProfileFamily extends CodeList {
    public shape?: ProfileShape;
    public profileStandard?: ProfileStandard;

    constructor(ctor: IProfileFamilyConstructor) {
        super(ctor);

        if (ctor != null) {
            this.shape = ctor.shape;
            this.profileStandard = ctor.profileStandard;
        }
    }

    public static fromService(codeList: ProfileFamilyEntity) {
        return new ProfileFamily(CodeList.baseFromService<IProfileFamilyConstructor>('ProfileFamilyEntity', codeList, {
            shape: codeList.Shape,
            profileStandard: codeList.ProfileStandard
        } as IProfileFamilyConstructor));
    }

    public override cloneDeep(): ProfileFamily {
        const codeList: ProfileFamily = super.cloneDeep() as ProfileFamily;

        codeList.shape = this.shape;
        codeList.profileStandard = this.profileStandard;

        return codeList;
    }
}
