import {
    SupplementaryReinforcementTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class SupplementaryReinforcementType extends CodeList {

    public static fromService(codeList: SupplementaryReinforcementTypeEntity) {
        return new SupplementaryReinforcementType(CodeList.baseFromService('SupplementaryReinforcementTypeEntity', codeList));
    }

    public override cloneDeep(): SupplementaryReinforcementType {
        return super.cloneDeep() as SupplementaryReinforcementType;
    }
}
