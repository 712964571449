import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    propertyValueChanged
} from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { ProfileSize } from '../../../../shared/entities/code-lists/profile-size';

export class ShearLugUpdate extends BaseUpdate {

    /* PROPERTY VALUE CHANGED */

    @propertyValueChanged(UIProperty.Profile_ShearLugCustomDepth)
    private Profile_ShearLugCustomDepth(value: number, model: IModelPe) {
        if (model.shearLug) {
            model.shearLug.customDepth = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_ShearLugCustomWidth)
    private Profile_ShearLugCustomWidth(value: number, model: IModelPe, design: DesignPe) {
        if (design.model[UIProperty.Profile_ShearLugSizeId] == null && model.shearLug) {
            model.shearLug.customWidth = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_ShearLugCustomLength)
    private Profile_ShearLugCustomLength(value: number, model: IModelPe, design: DesignPe) {
        if (design.model[UIProperty.Profile_ShearLugSizeId] == null && model.shearLug) {
            model.shearLug.customLength = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_ShearLugCustomThickness)
    private Profile_ShearLugCustomThickness(value: number, model: IModelPe, design: DesignPe) {
        if (design.model[UIProperty.Profile_ShearLugSizeId] == null && model.shearLug) {
            model.shearLug.customThickness = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_ShearLugFamilyId)
    private Profile_ShearLugFamilyId(value: number, model: IModelPe) {
        if (model.shearLug && !model.shearLug?.hidden) {
            model.shearLug.shearLugFamilyId = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_ShearLugSizeId)
    private Profile_ShearLugSizeId(value: number, model: IModelPe, design: DesignPe) {
        if (!model.shearLug) {
            throw new Error('model shearlug is undefined');
        }

        const shearLugSizes = design.designData.designCodeLists[DesignCodeList.ProfileSize] as ProfileSize[];

        if (value != null) {
            const shearLugSize = shearLugSizes.find((shearLugSizes) => shearLugSizes.id == value);

            model.shearLug.customWidth = shearLugSize?.width;
            model.shearLug.customLength = shearLugSize?.height;
            model.shearLug.customThickness = shearLugSize?.thickness;
            model.shearLug.isCustomSize = false;
        }
        else {
            model.shearLug.customWidth = design.model[UIProperty.Profile_ShearLugCustomWidth] as number;
            model.shearLug.customLength = design.model[UIProperty.Profile_ShearLugCustomLength] as number;
            model.shearLug.customThickness = design.model[UIProperty.Profile_ShearLugCustomThickness] as number;
            model.shearLug.isCustomSize = true;
        }
    }

    @propertyValueChanged(UIProperty.Profile_ShearLugAngle)
    private Profile_ShearLugAngle(value: number, model: IModelPe) {
        if (model.shearLug) {
            model.shearLug.angle = value;
        }
    }

    @propertyValueChanged(UIProperty.Profile_UseShearLug)
    private Profile_UseShearLug(value: boolean, model: IModelPe) {
        if (model.shearLug) {
            model.shearLug.hidden = !value;
        }
    }
}
