import {
    SeismicCategoryEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class SeismicCategory extends CodeList {

    public static fromService(codeList: SeismicCategoryEntity) {
        return new SeismicCategory(CodeList.baseFromService('SeismicCategoryEntity', codeList));
    }

    public override cloneDeep(): SeismicCategory {
        return super.cloneDeep() as SeismicCategory;
    }
}

