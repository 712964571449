import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    StiffenerCustomProfileSideEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    ProfileShapeType
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from '../code-lists/code-list';

export interface IStiffenerCustomProfileSidesConstructor extends ICodeListConstructor {
    allowChangeStiffenerPosition: boolean;
    allowMultipleStiffeners: boolean;
    profileShapeType: ProfileShapeType;
    stiffenerWeldType: number;
    profilePlateStartPoint: number;
    profilePlateEndPoint: number;
    stiffenerAngle: number;
}

export class StiffenerCustomProfileSides extends CodeList {

    public allowChangeStiffenerPosition!: boolean;
    public allowMultipleStiffeners!: boolean;
    public profileShapeType!: ProfileShapeType;
    public stiffenerWeldType!: number;
    public profilePlateStartPoint!: number;
    public profilePlateEndPoint!: number;
    public stiffenerAngle!: number;

    constructor(codeList: IStiffenerCustomProfileSidesConstructor) {
        super(codeList);

        if (codeList != null) {
            this.allowChangeStiffenerPosition = codeList.allowChangeStiffenerPosition;
            this.allowMultipleStiffeners = codeList.allowMultipleStiffeners;
            this.profileShapeType = codeList.profileShapeType;
            this.stiffenerWeldType = codeList.stiffenerWeldType;
            this.profilePlateStartPoint = codeList.profilePlateStartPoint;
            this.profilePlateEndPoint = codeList.profilePlateEndPoint;
            this.stiffenerAngle = codeList.stiffenerAngle;
        }
    }

    public static fromService(codeList: StiffenerCustomProfileSideEntity) {
        return new StiffenerCustomProfileSides(CodeList.baseFromService<IStiffenerCustomProfileSidesConstructor>('StiffenerCustomProfileSideEntity', codeList, {
            allowChangeStiffenerPosition: codeList.AllowChangeStiffenerPosition,
            allowMultipleStiffeners: codeList.AllowMultipleStiffeners,
            profileShapeType: codeList.ProfileShapeType,
            stiffenerWeldType: codeList.StiffenerWeldType,
            profilePlateStartPoint: codeList.ProfileStartPoint,
            profilePlateEndPoint: codeList.ProfileEndPoint,
            stiffenerAngle: codeList.StiffenerAngle
        } as IStiffenerCustomProfileSidesConstructor));
    }

    public override cloneDeep(): StiffenerCustomProfileSides {
        const codeList: StiffenerCustomProfileSides = super.cloneDeep() as StiffenerCustomProfileSides;

        codeList.allowChangeStiffenerPosition = this.allowChangeStiffenerPosition;
        codeList.allowMultipleStiffeners = this.allowMultipleStiffeners;
        codeList.profileShapeType = this.profileShapeType;
        codeList.stiffenerWeldType = this.stiffenerWeldType;

        return codeList;
    }
}
