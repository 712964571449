export enum ProjectCodeList {
    Region,
    DesignStandard,
    DesignType,
    EdgeDistanceDisplayType,
    DesignMethodGroup,
    SteelGuidelines,
    ConcreteInCompressionMethod,
    ConcreteSafetyFactorGammaC,
    AdvancedCalculationType,
    BrickGroup
}
