import {
    SafetyLevelEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class SafetyLevel extends CodeList {

    public static fromService(codeList: SafetyLevelEntity) {
        return new SafetyLevel(CodeList.baseFromService('SafetyLevelEntity', codeList));
    }

    public override cloneDeep(): SafetyLevel {
        return super.cloneDeep() as SafetyLevel;
    }
}
