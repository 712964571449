import uniqBy from 'lodash-es/uniqBy';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { CodeList } from '../../../shared/entities/code-lists/code-list';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { getSpriteAsIconStyleResponsive } from '../../sprites';
import { isAnchorFamilyMarkedAsNew, updateMainMenuControl, updateUiProperty } from './menu-helper';
import { AnchorSize } from '../../../shared/entities/code-lists/anchor-size';
import { IMultiSelectDropdownItem, IMultiSelectDropdownProps, MultiselectDropdown } from '../../components/main-menu/multiselect-dropdown/MultiselectDropdown';

export function initializeSelectAnchorSizes(
    codeListService: CodeListService,
    calculationService: CalculationServicePE,
    design: DesignPe,
    controlProps: IMultiSelectDropdownProps,
    localizationService: LocalizationService,
    numberService: NumberService,
    uiPropertyId: number,
    controlName: string,
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
    codelistName?: string) {
    controlProps.type = MultiselectDropdown;
    controlProps.selectedValues = design.model[uiPropertyId] as number[] ?? [];

    const onStateChanged = (state: IDesignState) => {
        const items = getAnchorSizeFilteredItems(controlProps, uiPropertyId, design);

        return ({
            selectedValues: state.model[uiPropertyId],
            items
        } as any) as IMultiSelectDropdownProps;
    };

    const onAllowedValuesChanged = (design: DesignPe, propertyIds: number[]) => {
        if (!propertyIds.some((id) => id == uiPropertyId)) {
            return {} as IMultiSelectDropdownProps;
        }

        const items = getAnchorSizeFilteredItems(controlProps, uiPropertyId, design);
        return ({ items } as any) as IMultiSelectDropdownProps;
    };

    let codeListItems: CodeList[] | undefined;

    if (codelistName != null && codelistName != '') {
        const designCodeList = getDesignCodeList(codelistName);
        const projectCodeList = getProjectCodeList(codelistName);

        if (designCodeList != null) {
            codeListItems = getCodeListItems(design, designCodeList);
        }
        else if (projectCodeList != null) {
            codeListItems = getProjectCodeListItems(codeListService, controlName, projectCodeList);
        }
    }

    controlProps.initialItems = codeListItems?.map((codeListItem) => codeListMapperFn(design, codeListItem, localizationService, numberService, uiPropertyId));
    controlProps.items = getAnchorSizeFilteredItems(controlProps, uiPropertyId, design);

    controlProps.valueChanged = (values) => {
        let selectedValues: number[] = [];

        if (design.model[UIProperty.SmartAnchor_Enabled]) {
            selectedValues = values;
        }

        // change menu
        setState(menu => updateMainMenuControl<IMultiSelectDropdownProps>(menu, controlProps.controlId, { selectedValues: selectedValues }));

        if (design.model[UIProperty.SmartAnchor_Enabled] as boolean) {
            updateUiProperty(calculationService, design, uiPropertyId, selectedValues);
        }
    };

    return {
        controlProps,
        onStateChanged,
        onAllowedValuesChanged
    };
}

function codeListMapperFn(design: DesignPe, codeListItem: CodeList, localizationService: LocalizationService, numberService: NumberService, uiPropertyId: number) {
    const buttonData = getSuggestedAnchorDataFn(design, localizationService, codeListItem.id);

    return {
        value: codeListItem.id,
        text: codeListItem.getTranslatedNameText({ localizationService, numberService }),
        uiPropertyId: uiPropertyId,
        isNew: isAnchorFamilyMarkedAsNew(codeListItem.id, design),
        tag: buttonData.tag,
    } as IMultiSelectDropdownItem;
}

function getSuggestedAnchorDataFn(design: DesignPe, localizationService: LocalizationService, anchorFamilyId?: number) {
    let text: string | undefined;
    let tag: string | undefined;
    let image: string | undefined;
    let imageStyle: IResponsiveIconStyle | undefined;

    if (anchorFamilyId != null) {
        const anchorSizes = design.designData.designCodeLists[DesignCodeList.AnchorSize] as AnchorSize[];

        const selectedAnchorFamily = anchorSizes.find((anchorFamily) => anchorFamily.id == anchorFamilyId);
        text = selectedAnchorFamily?.getTranslatedNameText({ localizationService });
        tag = selectedAnchorFamily?.tag;

        image = selectedAnchorFamily?.image;
        if (selectedAnchorFamily?.image != null && selectedAnchorFamily.image != '') {
            imageStyle = {
                elementStyle: {},
                afterElementStyle: getSpriteAsIconStyleResponsive(selectedAnchorFamily.image)
            };
        }
    }

    return {
        buttonText: text,
        buttonImage: image,
        buttonImageStyle: imageStyle,
        tag: tag
    };
}

function getAnchorSizeFilteredItems(controlProps: IMultiSelectDropdownProps, uiPropertyId: number, design: DesignPe) {
    // filter group items
    const allowedValues = design.properties.get(uiPropertyId).allowedValues;

    const displayValuesSortIndex = allowedValues?.reduceRight((obj, val, index) => { obj[val] = index; return obj; },
        {} as Record<number, number>);

    const items = controlProps.initialItems?.filter((item) =>
        displayValuesSortIndex != null
            ? displayValuesSortIndex[item.value] != null
            : true);

    const sortAlphaNum = (a: IMultiSelectDropdownItem, b: IMultiSelectDropdownItem) => a.text.localeCompare(b.text, 'en', { numeric: true });
    const uniqueItems = uniqBy(items, x => x.text).sort(sortAlphaNum);

    return uniqueItems ?? [];
}

function getProjectCodeListItems(codeListService: CodeListService, navigationControlName: string, codeList: ProjectCodeList) {
    let codeListItems: CodeList[];

    if (codeList != null && (codeListItems = codeListService.projectCodeLists[codeList]) != null) {
        const uniqCodeListItems = uniqBy(codeListItems, x => x.id);
        if (uniqCodeListItems.length != codeListItems.length) {
            console.error(`Duplicate id found in codelist ${ProjectCodeList[codeList]} for control ${navigationControlName}`);
        }
        return uniqCodeListItems;
    }

    return undefined;
}

function getDesignCodeList(codelistName: string) {
    return DesignCodeList[codelistName as keyof typeof DesignCodeList];
}
function getProjectCodeList(codelistName: string) {
    return ProjectCodeList[codelistName as keyof typeof ProjectCodeList];
}

function getCodeListItems(design: DesignPe, codeList: DesignCodeList) {
    let codeListItems: CodeList[];

    if (codeList != null && (codeListItems = design.designData.designCodeLists[codeList]) != null) {
        return codeListItems;
    }

    return undefined;
}