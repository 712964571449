import { Injectable } from '@angular/core';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { NumericalParameter } from '../../shared/entities/translation-parameters';
import { ConstantParameter, TemplateParameter, TranslatableParameter, TranslationFormat, TranslationParameter } from '../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation';
import { TranslationParameterType } from '../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation.Enums';
import { TranslationFormatServiceBase } from '../../shared/services/translation-format.service.base';
import { LocalizationService } from './localization.service';
import { UnitService } from './unit.service';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Injectable({
    providedIn: 'root'
})
export class TranslationFormatService extends TranslationFormatServiceBase {
    constructor(
        private localizationService: LocalizationService,
        private unitService: UnitService
    ) {
        super();
    }

    public getLocalizedStringWithTranslationFormat(
        translationFormat: TranslationFormat,
        roundValue = true,
        preTransformedParams?: Record<string, string>
    ): string {
        if (translationFormat == null) {
            return '';
        }

        const transformedParams = this.transformTranslationParameters(
            translationFormat.TranslationParameters,
            roundValue,
            preTransformedParams
        );

        const translation = translationFormat.Template != null
            ? translationFormat.Template
            : this.getLocalizedStringWithTranslationFormat(translationFormat.TemplateFormat, roundValue);

        return formatKeyValue(translation, transformedParams);
    }

    public transformTranslationParameters(
        translationParameters: TranslationParameter[],
        roundValue: boolean,
        preTransformedParams?: { [key: string]: string },
        customParameterTransformation?: (parameter: TranslationParameter, roundValue: boolean) => string | undefined
    ): Record<string, string> {
        const keys: Record<string, string> = {};

        translationParameters.forEach((parameter) => {
            let handled = false;

            if (preTransformedParams != null && parameter.Name in preTransformedParams) {
                keys[parameter.Name] = preTransformedParams[parameter.Name];
                handled = true;
            }
            else if (customParameterTransformation != null) {
                const value = customParameterTransformation(parameter, roundValue);
                if (value != null) {
                    keys[parameter.Name] = value;
                    handled = true;
                }
            }

            if (handled) {
                // Nothing to do ...
            }
            else if (parameter.ParameterType == TranslationParameterType.Numerical) {
                const numericalParameter = parameter as NumericalParameter;

                let value: string;
                if (numericalParameter.Value == null) {
                    value = '';
                }
                else {
                    const defaultPrecision = this.unitService.getDefaultPrecision();

                    // The number might not have a unit
                    if (numericalParameter.UnitGroup != null) {
                        const internalUnit = this.unitService.getInternalUnit(numericalParameter.UnitGroup);
                        const defaultUnit = this.unitService.getDefaultUnit(numericalParameter.UnitGroup);
                        const precision = roundValue == false
                            ? defaultPrecision
                            : this.unitService.getPrecision(defaultUnit) + (numericalParameter.AdditionalPrecision ?? 0);
                        const unitValueNumber = this.unitService.convertUnitValueArgsToUnit(numericalParameter.Value, internalUnit, defaultUnit);
                        value = this.unitService.formatUnitValueArgs(unitValueNumber, defaultUnit, precision);
                    }
                    else {
                        const precision = roundValue == false
                            ? defaultPrecision
                            : this.unitService.getPrecision(Unit.None) + (numericalParameter.AdditionalPrecision ?? 0);
                        value = this.unitService.formatNumber(numericalParameter.Value, precision);
                    }
                }

                keys[parameter.Name] = value;
            }
            else if (parameter.ParameterType == TranslationParameterType.Translatable) {
                const translatableParameter = parameter as TranslatableParameter;

                let translated: string;
                if (translatableParameter.Value == null || translatableParameter.Value.trim() == '') {
                    translated = '';
                }
                else {
                    translated = this.localizationService.getString(translatableParameter.Value);
                }

                keys[parameter.Name] = translated;
            }
            else if (parameter.ParameterType == TranslationParameterType.Constant) {
                const constantParameter = parameter as ConstantParameter;

                keys[parameter.Name] = constantParameter.Value;
            }
            else if (parameter.ParameterType == TranslationParameterType.Template) {
                const templateParameter = parameter as TemplateParameter;

                keys[parameter.Name] = this.getLocalizedStringWithTranslationFormat(templateParameter.Value, roundValue);
            }
            else {
                throw new Error('Unknown parameter type');
            }
        });

        return keys;
    }
}
