import { ConcreteTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class ConcreteType extends CodeList {

    public static fromService(codeList: ConcreteTypeEntity) {
        return new ConcreteType(CodeList.baseFromService('ConcreteTypeEntity', codeList));
    }

    public override cloneDeep(): ConcreteType {
        return super.cloneDeep() as ConcreteType;
    }
}
