import { Controls2dEditorBase, IMenu2dContext as IMenu2dContextBase } from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { IGlModelPeComponent } from '../components/gl-model';

export class Controls2dEditor extends Controls2dEditorBase {
    static SnapToGrid = 'Canvas.SnapToGrid';
    static StandardLayout = 'AnchorLayout.StandardLayout';
    static CustomLayout = 'AnchorLayout.CustomLayout';
    static Baseplate = 'Baseplate.PlateShape';
    static LedgerAngle = 'AnchorPlate.LedgerAngleShape';
    static AnchorNodes = 'AnchorNodes.AnchorNodes';
    static BaseplateNodes = 'BaseplateNodes.BaseplateNodes';
    static CoordinateSystemCenter = 'CoordinateSystemCenter.CoordinateSystemCenter';
    static StiffenerMaterial = 'AnchorPlate.StiffenerMaterial';
    static StiffenerShape = 'AnchorPlate.StiffenerShape';
    static StiffenerThickness = 'AnchorPlate.StiffenerThickness';
    static StiffenerWidth = 'AnchorPlate.StiffenerWidth';
    static StiffenerHeight = 'AnchorPlate.StiffenerHeight';
    static StiffenerHorizontalEdgeLength = 'AnchorPlate.StiffenerHorizontalEdgeLength';
    static StiffenerVerticalEdgeLength = 'AnchorPlate.StiffenerVerticalEdgeLength';
    static StiffenerWeldMaterial = 'AnchorPlate.StiffenerWeldMaterial';
    static StiffenerWeldThickness = 'AnchorPlate.StiffenerWeldThickness';
    static StiffenerWeldLocation = 'AnchorPlate.StiffenerWeldLocation';
    static StiffenerLayout = 'AnchorPlate.StiffenerLayout';
    static StiffenerRadialOffset = 'AnchorPlate.StiffenerRadialOffset';
    static StiffenerWeldLegLength = 'AnchorPlate.StiffenerWeldLegLength';
    static StiffenerMaterialUltimateStrength = 'AnchorPlate.StiffenerMaterialUltimateStrength';
    static StiffenerMaterialYieldStrength = 'AnchorPlate.StiffenerMaterialYieldStrength';
    static StiffenerMaterialEModulus = 'AnchorPlate.StiffenerMaterialEModulus';
    static StiffenerMaterialDensity = 'AnchorPlate.StiffenerMaterialDensity';
    static StiffenerMaterialPoisson = 'AnchorPlate.StiffenerMaterialPoisson';
    static StiffenerWeldMaterialUltimateStrength = 'AnchorPlate.StiffenerWeldMaterialUltimateStrength';
    static StiffenerWeldMaterialYieldStrength = 'AnchorPlate.StiffenerWeldMaterialYieldStrength';
    static StiffenerWeldMaterialEModulus = 'AnchorPlate.StiffenerWeldMaterialEModulus';
    static StiffenerWeldMaterialDensity = 'AnchorPlate.StiffenerWeldMaterialDensity';
    static StiffenerWeldMaterialPoisson = 'AnchorPlate.StiffenerWeldMaterialPoisson';
    static StiffenerWeldMaterialBetaW = 'AnchorPlate.StiffenerWeldMaterialBetaW';
    static StiffenerWeldGravity = 'AnchorPlate.StiffenerWeldGravity';
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMenu2dContext extends IMenu2dContextBase<IGlModelPeComponent> {}
