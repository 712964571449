import { InstallationDirectionEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class InstallationDirection extends CodeList {

    public static fromService(codeList: InstallationDirectionEntity) {
        return new InstallationDirection(CodeList.baseFromService('InstallationDirectionEntity', codeList));
    }

    public override cloneDeep(): InstallationDirection {
        return super.cloneDeep() as InstallationDirection;
    }
}
