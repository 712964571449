import {
    MeasureScopeCheckParameterItem as ScopeCheck
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    Dimensions, MeasureResultType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignType, EdgeDistanceDisplayType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    propertyValueChanged, scopeCheckChanged
} from '@profis-engineering/gl-model/base-update';
import { ApplicationType } from '@profis-engineering/pe-gl-model/components/handrail';
import { PlatePosition } from '@profis-engineering/pe-gl-model/gl-model';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class HandrailBaseMaterialUpdate extends BaseUpdate {

    public override shouldUpdate(_model: IModelPe, design: DesignPe) {
        return design.designType.id == DesignType.Handrail;
    }

    @propertyValueChanged(UIProperty.Option_EdgeDistanceDisplayType)
    private Option_EdgeDistanceDisplayType(value: EdgeDistanceDisplayType, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.edgeDistance = (value as number);
    }

    @propertyValueChanged(UIProperty.BaseMaterial_Thickness)
    private BaseMaterial_Thickness(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        const thickness = value;

        model.handrailBaseMaterial.concreteHeight = thickness;
        model.handrailBaseMaterial.stairThickness = thickness;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_OffsetX)
    private BaseMaterial_OffsetX(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.offsetX = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_OffsetY)
    private BaseMaterial_OffsetY(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.offsetY = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BalustradeHeight)
    private BaseMaterial_BalustradeHeight(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.balustradeHeight = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BalustradeWidth)
    private BaseMaterial_BalustradeWidth(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.balustradeDepth = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_IsNonLoadBearingLayerPresent)
    private BaseMaterial_IsNonLoadBearingLayerPresent(value: boolean, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.nonCarryingLayer = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_NonLoadBearingLayer)
    private BaseMaterial_NonLoadBearingLayer(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.nonCarryingLayerHeight = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_StairLength)
    private BaseMaterial_StairLength(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.stairWidth = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_StairHeight)
    private BaseMaterial_StairHeight(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.stairHeight = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteLengthNegativeInfinity)
    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteLengthNegativeInfinityAnchor)
    private BaseMaterial_ConcreteLengthNegativeInfinity(value: boolean, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.infinityLeft = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteLengthPositiveInfinity)
    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteLengthPositiveInfinityAnchor)
    private BaseMaterial_ConcreteLengthPositiveInfinity(value: boolean, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.infinityRight = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteLengthNegative)
    private BaseMaterial_ConcreteLengthNegative(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.concreteLeft = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConcreteLengthPositive)
    private BaseMaterial_ConcreteLengthPositive(value: number, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.concreteRight = value;
    }

    /* SCOPE CHECK CHANGED */
    @scopeCheckChanged()
    private scopeChecksChanged(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (!model.handrailBaseMaterial) {
            throw new Error('model handrailBaseMaterial is undefined');
        }

        model.handrailBaseMaterial.invalid = model.handrailBaseMaterial.invalid ?? {};

        model.handrailBaseMaterial.invalid.offsetY = false;
        model.handrailBaseMaterial.invalid.offsetX = false;
        model.handrailBaseMaterial.invalid.thickness = false;
        model.handrailBaseMaterial.invalid.balustradeDepth = false;
        model.handrailBaseMaterial.invalid.widthNegative = false;
        model.handrailBaseMaterial.invalid.widthPositive = false;

        if (scopeChecks != null) {
            this.setScopeChecks(scopeChecks, model);
        }
    }

    private setScopeChecks(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (!model.handrailBaseMaterial?.invalid) {
            throw new Error('model handrailBaseMaterial invalid is undefined');
        }

        const anchorToBaseMaterialEdgeScopeCheck = scopeChecks[MeasureResultType.AnchorToBaseMaterialEdge];
        let xPositiveInvalid = false;
        let xNegativeInvalid = false;
        let yPositiveInvalid = false;
        let yNegativeInvalid = false;

        if (anchorToBaseMaterialEdgeScopeCheck != null) {
            const dimensions = anchorToBaseMaterialEdgeScopeCheck.Value as Dimensions;

            xPositiveInvalid = (dimensions & Dimensions.XPositive) != 0;
            xNegativeInvalid = (dimensions & Dimensions.XNegative) != 0;
            yPositiveInvalid = (dimensions & Dimensions.YPositive) != 0;
            yNegativeInvalid = (dimensions & Dimensions.YNegative) != 0;
        }

        model.handrailBaseMaterial.invalid.offsetY = scopeChecks[MeasureResultType.AnchorPlateLengthB] != null;
        model.handrailBaseMaterial.invalid.offsetX = scopeChecks[MeasureResultType.AnchorPlateLengthA] != null;

        if (model.handrail?.applicationType == ApplicationType.Concrete) {
            model.handrailBaseMaterial.invalid.widthNegative = scopeChecks[MeasureResultType.BaseMaterialEdgeXNegative] != null || xNegativeInvalid;
            model.handrailBaseMaterial.invalid.widthPositive = scopeChecks[MeasureResultType.BaseMaterialEdgeXPositive] != null || xPositiveInvalid;

            if (model.handrail.platePosition == PlatePosition.Front) {
                model.handrailBaseMaterial.invalid.thickness = scopeChecks[MeasureResultType.BaseMaterialEdgeYNegative] != null || scopeChecks[MeasureResultType.BaseMaterialEdgeYPositive] != null || yPositiveInvalid || yNegativeInvalid;
            }
        }
        else if (model.handrail?.applicationType == ApplicationType.Balustrade) {
            this.setInvalidBalustrade(model, scopeChecks, xNegativeInvalid, xPositiveInvalid, yPositiveInvalid, yNegativeInvalid);
        }
        else if (model.handrail?.applicationType == ApplicationType.Stairs) {
            model.handrailBaseMaterial.invalid.thickness = scopeChecks[MeasureResultType.BaseMaterialEdgeYNegative] != null || scopeChecks[MeasureResultType.BaseMaterialEdgeYPositive] != null || scopeChecks[MeasureResultType.BaseMaterialEdgeXNegative] != null || scopeChecks[MeasureResultType.BaseMaterialEdgeXPositive] != null || xPositiveInvalid || xNegativeInvalid || yPositiveInvalid || yNegativeInvalid;
        }
        else {
            throw new Error('Unsupported handrail application type.');
        }
    }

    private setInvalidBalustrade(model: IModelPe, scopeChecks: { [measureResultType: number]: ScopeCheck }, xNegativeInvalid: boolean, xPositiveInvalid: boolean, yPositiveInvalid: boolean, yNegativeInvalid: boolean) {
        if (!model.handrail || !model.handrailBaseMaterial?.invalid) {
            throw new Error('model handrailBaseMaterial invalid is undefined');
        }

        model.handrailBaseMaterial.invalid.widthNegative = scopeChecks[MeasureResultType.BaseMaterialEdgeXNegative] != null || xNegativeInvalid;
        model.handrailBaseMaterial.invalid.widthPositive = scopeChecks[MeasureResultType.BaseMaterialEdgeXPositive] != null || xPositiveInvalid;

        if (model.handrail.platePosition == PlatePosition.Front) {
            model.handrailBaseMaterial.invalid.thickness = scopeChecks[MeasureResultType.BaseMaterialEdgeYPositive] != null || scopeChecks[MeasureResultType.BaseMaterialEdgeYNegative] != null || yPositiveInvalid || yNegativeInvalid;
        }
        else if (model.handrail.platePosition == PlatePosition.Top) {
            model.handrailBaseMaterial.invalid.balustradeDepth = scopeChecks[MeasureResultType.BaseMaterialEdgeYPositive] != null || scopeChecks[MeasureResultType.BaseMaterialEdgeYNegative] != null || yPositiveInvalid || yNegativeInvalid;
        }
    }
}
