import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignPe, ReportData } from '../../../shared/entities/design-pe';
import {
    TranslationFormat
} from '../../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation';
import {
    BaseplateDesignDataEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign';
import {
    DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { getVonMisesWorst } from '../../helpers/calculation-results-helper';
import {
    areLoadCombinationsAvailable, getLoadCombinationNumberText
} from '../../helpers/load-combination-helper';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

interface IHoleBearingResult {
    image: string;
    valueError?: boolean;
    textError?: boolean;
    value?: number;
    text: TranslationFormat;
}

@Component({
    templateUrl: './baseplate-utilizations.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BaseplateUtilizationsComponent implements OnInit {
    @Input()
    public id = '';

    @Input()
    public design?: DesignPe;

    @Input()
    public baseplateDesignData?: BaseplateDesignDataEntity;

    public collapsed = false;
    public vonMisesOpend = false;
    public unitGroupEnum = {
        Percentage: UnitGroup.Percentage,
        Stress: UnitGroup.Stress
    };

    constructor(
        public localizationService: LocalizationService,
        public unit: UnitService,
        public translationFormatService: TranslationFormatService,
        private user: UserService,
        private userSettings: UserSettingsService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get showUtilizations() {
        return this.user.design != null
            && (this.hasUtilizations || this.showAddOn);
    }

    public get hasUtilizations() {
        return this.plasticStrain != null || this.hasHoleBearing;
    }

    public get showAddOn() {
        // only show upgrade text after calculation is done
        if (this.design == null || this.baseplateDesignData == null) {
            return false;
        }

        return (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) && !this.featuresVisibilityInfo.hasAdvancedFeature;
    }

    public get plasticStrain() {
        if (this.baseplateDesignData?.AnchorPlateUtilizations?.UtilizationPlasticStrain == null ||
            Object.keys(this.baseplateDesignData.AnchorPlateUtilizations.UtilizationPlasticStrain).length == 0) {
            return undefined;
        }

        if (this.design?.hasIntegratedProfile) {
            const verticalPlate = this.baseplateDesignData.ProfileUtilizations.UtilizationPlasticStrain;
            const horizontalPlate = this.baseplateDesignData.AnchorPlateUtilizations.UtilizationPlasticStrain;

            return verticalPlate.MaxStress > horizontalPlate.MaxStress ? verticalPlate : horizontalPlate;
        }

        return this.baseplateDesignData.AnchorPlateUtilizations.UtilizationPlasticStrain;
    }

    public get holeBearing() {
        if (this.baseplateDesignData?.AnchorPlateUtilizations?.UtilizationHoleBearing == null ||
            Object.keys(this.baseplateDesignData.AnchorPlateUtilizations.UtilizationHoleBearing).length == 0) {
            return null;
        }

        return this.baseplateDesignData.AnchorPlateUtilizations.UtilizationHoleBearing;
    }

    public get hasHoleBearing() {
        return this.design?.designData?.reportData?.PlateHoleBearingUtilization != null || this.holeBearing != null;
    }

    public get areLoadCombinationsAvailable() {
        return areLoadCombinationsAvailable(this.design?.designData?.reportData as ReportData);
    }

    public get loadCombination() {
        if (this.design == null) {
            return null;
        }

        return getLoadCombinationNumberText(this.design, this.localizationService, this.design?.designData?.reportData?.DecisiveLoadCombinationIndexAnchorPlate ?? 0, true);
    }

    public get utilizationInvalid() {
        if (this.plasticStrain != null && this.holeBearing != null) {
            return this.holeBearing.Decisive.Percentage > this.plasticStrain.UtilizationPercentage ? this.holeBearing.Decisive.ValueError : !this.plasticStrain.CheckStatus;
        }
        else if (this.plasticStrain != null) {
            return !this.plasticStrain.CheckStatus;
        }
        else if (this.holeBearing != null) {
            return this.holeBearing.Decisive.ValueError;
        }
        else {
            return this.design?.designData?.reportData?.PlateSteelUtilization ?? 0 > 100;
        }
    }

    public get utilizationValue() {
        if (this.plasticStrain != null && this.holeBearing != null) {
            return this.holeBearing.Decisive.Percentage > this.plasticStrain.UtilizationPercentage ? this.holeBearing.Decisive.Percentage : this.plasticStrain.UtilizationPercentage;
        }
        else if (this.plasticStrain != null) {
            return this.plasticStrain.UtilizationPercentage;
        }
        else if (this.holeBearing != null) {
            return this.holeBearing.Decisive.Percentage;
        }
        else {
            return this.design?.designData?.reportData?.PlateSteelUtilization ?? 0;
        }
    }

    public get vonMisesWorst() {
        return getVonMisesWorst(this.plasticStrain);
    }

    public get isSatsStandard() {
        return this.design?.designStandard?.id == DesignStandard.SATS;
    }

    public get vonMisesDetails() {
        return this.vonMisesWorst != null && (this.vonMisesWorst.materialFy || this.vonMisesWorst.limitPlasticStrain);
    }

    public get plateSteelUtilizationText() {
        return this.design?.designData?.reportData?.ConcreteBearingAction != null &&
            this.design.designData.reportData.ConcreteBearingResistance != null &&
            this.design.designData.reportData.ConcreteBearingUtilization != null ?
            'Agito.Hilti.Profis3.ConcreteUtilizations.DG7.PlateCompression' :
            'Agito.Hilti.Profis3.ConcreteUtilizations.DG7.PlateTension';
    }

    public get holeBearingPercentage() {
        if (this.holeBearing == null || this.holeBearing.Decisive == null) {
            return this.design?.designData?.reportData?.PlateHoleBearingUtilization;
        }

        return this.holeBearing.Decisive.Percentage;
    }

    public get holeBearingWorst(): IHoleBearingResult | null {
        const image = this.image('hole-bearing');
        let text: TranslationFormat = {
            Template: '',
            TemplateFormat: {} as TranslationFormat,
            TranslationParameters: []
        };

        if (this.holeBearing == null || this.holeBearing.Decisive == null) {
            if (this.design?.designData?.reportData?.PlateHoleBearingUtilization != null) {
                return {
                    image,
                    text,
                    value: undefined,
                    valueError: this.design.designData.reportData.PlateHoleBearingUtilization > 100
                };
            }

            return null;
        }

        text = this.holeBearing.Decisive.Text;
        const worst = this.holeBearing.Decisive;

        return {
            image,
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            text
        } as IHoleBearingResult;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-tab-base-plate',
            'sprite-arrow-up',
            'sprite-arrow-down',
            'sprite-hole-bearing'
        );
        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.BaseplateUtilization);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public image(image: string) {
        if (image == null || image == '') {
            return '';
        }

        return `pe-ui-pe-sprite-${image}`;
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.BaseplateUtilization, collapsed);
    }
}
