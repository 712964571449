
export abstract class IntegrationsDocumentServiceBase {
    public abstract registerNewDocumentNotificationHandler(): void;
}

export class IntegrationsDocumentServiceInjected extends IntegrationsDocumentServiceBase {
    public setBaseService(baseService: IntegrationsDocumentServiceBase) {
        this.registerNewDocumentNotificationHandler = baseService.registerNewDocumentNotificationHandler.bind(baseService);
    }

    // IntegrationsDocumentService methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public registerNewDocumentNotificationHandler(): void {
        return;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
