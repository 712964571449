<div id="resize-anchor-loads" class="anchor-loads box-section box-section-white" *ngIf="showAnchorLoads">
    <div class="box-section-header-with-img">
        <div class="box-section-title drag-handle-static anchor-loads-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <div class="image">
                <div class="pe-ui-pe-sprite pe-ui-pe-sprite-anchor-loads"></div>
            </div>
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.AnchorLoads')"></span>
            <span class="bar-subtitle"
                *ngIf="areLoadCombinationsAvailable" [innerHtml]="loadCombination"></span>
        </div>

        <pe-collapse
            [container]="'anchor-loads'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>
    <div class="anchor-loads-table" *ngIf="!collapsed">
        <table>
            <caption>Anchor loads table</caption>
            <tbody>
                <tr>
                    <th [innerHtml]="translate('Agito.Hilti.Profis3.AnchorLoads.Table.Anchor')"></th>
                    <th class="notranslate" [innerHtml]="getLocalizedTextAndForceUnit('Agito.Hilti.Profis3.AnchorLoads.Table.N')"></th>
                    <th class="notranslate" [innerHtml]="getLocalizedTextAndForceUnit('Agito.Hilti.Profis3.AnchorLoads.Table.Qx')"></th>
                    <th class="notranslate" [innerHtml]="getLocalizedTextAndForceUnit('Agito.Hilti.Profis3.AnchorLoads.Table.Qy')"></th>
                </tr>
                <tr *ngFor="let anchor of anchorsArray; trackBy: trackAnchorLoadById" class="notranslate">
                    <td [innerHtml]="anchor.id"></td>
                    <td [innerHtml]="anchor.N"></td>
                    <td [innerHtml]="anchor.Qx"></td>
                    <td [innerHtml]="anchor.Qy"></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="resize-element" (mousedown)="beginResizing($event)" *ngIf="showResizer"></div>
</div>
