import { Injectable } from '@angular/core';
import {
  LoggerServiceInjected
} from '@profis-engineering/pe-ui-common/services/logger.common';

@Injectable({
  providedIn: 'root'
})
export class LoggerService extends LoggerServiceInjected {
}
