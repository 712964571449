import { FireExposureEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class FireExposure extends CodeList {
    public static fromService(codeList: FireExposureEntity) {
        return new FireExposure(CodeList.baseFromService('FireExposureEntity', codeList));
    }

    public override cloneDeep(): FireExposure {
        return super.cloneDeep() as FireExposure;
    }
}
