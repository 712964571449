import {
    CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DesignPe,
    ICalculateAllAnchor as IICalculateAllAnchorBase,
    ICalculateAllAnchorDetailed as ICalculateAllAnchorDetailedBase
} from '../../../shared/entities/design-pe';

import {
    AnchorFilterGroupOperator as GroupOperator
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';

export interface ICalculateAllAnchor extends IICalculateAllAnchorBase {
    detailed: ICalculateAllAnchorDetailed[];
    defaultSortOrder: number;
    description: string;
}

export interface ICalculateAllAnchorDetailed extends ICalculateAllAnchorDetailedBase {
    embedmentDepthFormated: string;
    isGeometryOk: boolean;
    tFormated: string;
    sFormated: string;
    cFormated: string;
    diameter: number;
}

export enum SelectAnchorMode {
    normal,
    calculateAll
}

export interface ISelectAnchorFilterGroupInfoPopup {
    enabled: boolean;
    show: boolean;
    title: string;
    content: string;
}

export interface ISelectAnchorFilterGroupConstructor {
    id?: number;
    groupOperator?: GroupOperator;
    titleKey?: string;
    items?: SelectAnchorFilterGroupItem[];
    infoPopup?: ISelectAnchorFilterGroupInfoPopup;
}

export class SelectAnchorFilterGroup {
    public id?: number;
    public groupOperator?: GroupOperator;
    public titleKey?: string;
    public items?: SelectAnchorFilterGroupItem[];
    public infoPopup?: ISelectAnchorFilterGroupInfoPopup;

    constructor(ctor?: ISelectAnchorFilterGroupConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.groupOperator = ctor.groupOperator;
            this.titleKey = ctor.titleKey;
            this.items = ctor.items;
            this.infoPopup = ctor.infoPopup;
        }
    }
}

export interface ISelectAnchorFilterGroupItemConstructor {
    id?: number;
    checkbox?: CheckboxButtonProps<boolean>;
}

export class SelectAnchorFilterGroupItem {
    public id?: number;
    public checkbox?: CheckboxButtonProps<boolean>;

    constructor(ctor?: ISelectAnchorFilterGroupItemConstructor) {
        if (ctor != null) {
            this.id = ctor.id;
            this.checkbox = ctor.checkbox;
        }
    }
}

export const enum CalculateAllCheckboxItem {
    ExcludeFailures,
    IncludeOnlyMinimum
}

export enum CalculateAllAnchorUiItemType {
    Header = 0,
    Item = 1
}

export interface ICalculateAllAnchorUiItem {
    // Type
    type: CalculateAllAnchorUiItemType;

    // Anchor data
    id: number;
    name: string;

    // Anchor details
    details?: ICalculateAllAnchorDetailed;
}

export enum SelectAnchorSortBy {
    default,
    name
}

export function getFilterGroupStyle(): string {
    return `
    .select-anchor-filter-group {
        white-space: normal;
        display: flex;
        flex-direction: column;
    }
    .select-anchor-filter-group .sub-title {
        font-weight: 700;
    }
    .select-anchor-filter-group .text-row {
        flex: 1 1 auto;
        margin-bottom: 16px;
    }
    .select-anchor-filter-group .text-row:last-child {
        margin-bottom: 0;
    }
    .select-anchor-filter-group .text-column {
        flex: 1 1 auto;
        margin-right: 8px;
    }
    .select-anchor-filter-group .text-column:last-child {
        margin-right: 0;
    }
    .select-anchor-filter-group-anchor-type {
        flex-direction: row;
    }
    .select-anchor-filter-group-anchor-type .left {
        flex: 1 1 auto;
        flex-direction: column;
    }
    .select-anchor-filter-group-anchor-type .right {
        flex: 0 0 245px;
        display: flex;
        flex-direction: row;
        margin-left: 16px;
        align-items: flex-end;
    }
    .select-anchor-filter-group-anchor-type .right .img-column {
        display: flex;
        flex-direction: column;
    }
    .select-anchor-filter-group-anchor-type .right .img-column .img-description {
        text-align: center;
    }
    .select-anchor-filter-group-corrosion .warning-row {
        display: flex;
        align-items: center;
    }
    .select-anchor-filter-group-corrosion .warning-row .img-column {
        flex: 0 0 49px;
    }
    .select-anchor-filter-group-cleaning .type-row {
        display: flex;
    }
    .select-anchor-filter-group-cleaning .type-row .img-column {
        flex: 0 0 165px;
    }
    .select-anchor-filter-group-cleaning .warning-row {
        display: flex;
        align-items: center;
    }
    .select-anchor-filter-group-cleaning .warning-row .img-column {
        flex: 0 0 49px;
    }
    .select-anchor-filter-group-thread .type-row {
        display: flex;
    }
    .select-anchor-filter-group-thread .type-row .img-column {
        flex: 0 0 48px;
    }`;
}

export function getScrollElement(design: DesignPe) {
    if (isSmartAnchorEnabled(design)) {
        return undefined as unknown as Element;
    }

    return document.querySelector('.modal') as Element;
}

function isSmartAnchorEnabled(design: DesignPe) {
    return design?.model[UIProperty.SmartAnchor_Enabled] as boolean;
}