import maxBy from 'lodash-es/maxBy';

import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IUtilization } from '../../../shared/entities/utilization';
import {
    UtilizationCombinationEntity, UtilizationShearEntity, UtilizationTensionEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import { LocalizationService } from '../../services/localization.service';
import { includeSprites, SpriteClass } from '../../sprites';
import anchorDesignHelperImages from '../../../images/anchor-design-help';

interface IUtilizationCategory {
    property: string;
    text: string;
    expanded: boolean;
    subcategories: ReadonlyArray<IUtilizationSubCategory>;
}

interface IUtilizationSubCategory {
    property: string;
    image: string;
    text: string;
    value: number;
}

interface IAnchorDesignHelpComponentInput {
    category: string;
    subcategory: string;
    tension: UtilizationTensionEntity;
    shear: UtilizationShearEntity;
    combination: UtilizationCombinationEntity;
    utilizations: ReadonlyArray<IUtilization>;
}

@Component({
    templateUrl: './anchor-design-help.component.html',
    styleUrls: ['./anchor-design-help.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AnchorDesignHelpComponent implements OnInit {
    public utilizationDetails!: IUtilizationCategory[];
    public selectedUtilizationSubCategory!: IUtilizationSubCategory;

    @Input()
    public modalInstance!: ModalInstance<IAnchorDesignHelpComponentInput>;

    constructor(
        private localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    private get category() {
        return this.modalInstance.input?.category;
    }

    private get subcategory() {
        return this.modalInstance.input?.subcategory;
    }

    private get utilizations() {
        if (this.modalInstance.input != null) {
            return this.modalInstance.input.utilizations;
        }

        return [];
    }

    private get tension() {
        return this.modalInstance.input?.tension;
    }

    private get shear() {
        return this.modalInstance.input?.shear;
    }

    private get combination() {
        return this.modalInstance.input?.combination;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-down-gray',
            'sprite-arrow-right-gray',
            'sprite-notification-alert',
            'sprite-notification-ok',
            'sprite-tension-steel-small',
            'sprite-shear-breakout-small',
            'sprite-tension-pullout-small',
            'sprite-tension-combined-small',
            'sprite-tension-splitting-small',
            'sprite-tension-blowout-small',
            'sprite-tension-supplementary-reinforcement-steel-small',
            'sprite-tension-supplementary-reinforcement-anchorage-small',
            'sprite-tension-surface-reinforcement-steel-small',
            'sprite-shear-steel-small',
            'sprite-tension-breakout-small',
            'sprite-shear-pryout-small',
            'sprite-shear-supplementary-reinforcement-steel-small',
            'sprite-shear-supplementary-reinforcement-anchorage-small',
            'sprite-shear-surface-reinforcement-steel-small',
            'sprite-combination-steel-small',
            'sprite-combination-concrete-small',
            'sprite-shear-concrete-bearing-small',
            'sprite-shear-additive-concrete-bearing-small'

        );

        this.utilizationDetails = this.mapUtilizationDetails();

        const category = this.utilizationDetails.find(x => x.property == this.category) ?? this.utilizationDetails[0];
        this.toggleUtilizationExpanded(category);

        const subCategory = category.subcategories.find(x => x.property == this.subcategory) ?? maxBy(category.subcategories, (x) => x.value);
        if (subCategory != null) {
            this.setSelectedUtilizationSubCategory(subCategory);
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public getDescriptionImage() {
        return anchorDesignHelperImages[`${this.selectedUtilizationSubCategory.image}.png`];
    }

    public getDescriptionText() {
        return this.localizationService.getString(`${this.selectedUtilizationSubCategory.text}.Description`);
    }

    public toggleUtilizationExpanded(utilization: IUtilizationCategory) {
        if (utilization.subcategories.length > 1) {
            utilization.expanded = !utilization.expanded;
        }
        else {
            this.selectedUtilizationSubCategory = utilization.subcategories[0];
        }
    }

    public getExpandedImage(utilization: IUtilizationCategory) {
        if (utilization.subcategories.length > 1) {
            const sprite: SpriteClass = utilization.expanded
                ? 'pe-ui-pe-sprite-arrow-down-gray'
                : 'pe-ui-pe-sprite-arrow-right-gray';

            return `pe-ui-pe-sprite ${sprite}`;
        }
        else {
            return 'decisive';
        }
    }

    public setSelectedUtilizationSubCategory(utilizationSubCategory: IUtilizationSubCategory) {
        this.selectedUtilizationSubCategory = utilizationSubCategory;
    }

    public getSelectedClass(subCategory: IUtilizationSubCategory) {
        return this.selectedUtilizationSubCategory == subCategory ? 'selected' : '';
    }

    public getWarningImage(utilizationValue: number): SpriteClass {
        if (utilizationValue > 100) {
            return 'pe-ui-pe-sprite-notification-alert';
        }
        else {
            return 'pe-ui-pe-sprite-notification-ok';
        }
    }

    public getUtilizationCategoryWarningImage(utilization: IUtilizationCategory): SpriteClass {
        if (utilization.subcategories.some(x => x.value > 100)) {
            return 'pe-ui-pe-sprite-notification-alert';
        }
        else {
            return 'pe-ui-pe-sprite-notification-ok';
        }
    }

    private mapUtilizationDetails(): IUtilizationCategory[] {
        const details: IUtilizationCategory[] = [];

        const tensionCategory = this.utilizations.find(x => x.property == 'tension');
        if (tensionCategory != null && this.tension != null) {
            const tension = this.tension;
            const tensionsProperties = Object.getOwnPropertyNames(tension);
            type objKey = keyof UtilizationTensionEntity;

            details.push({
                property: tensionCategory.property,
                text: tensionCategory.text,
                expanded: false,
                subcategories: tensionCategory.details.filter(x => tensionsProperties.includes(x.property)).map((subCategory): IUtilizationSubCategory => ({
                    property: subCategory.property,
                    text: subCategory.text,
                    image: subCategory.image,
                    value: tension[subCategory.property as objKey].Percentage
                }))
            });
        }

        const shearCategory = this.utilizations.find(x => x.property == 'shear');
        if (shearCategory != null && this.shear != null) {
            const shear = this.shear;
            const shearProperties = Object.getOwnPropertyNames(shear);
            type objKey = keyof UtilizationShearEntity;

            details.push({
                property: shearCategory.property,
                text: shearCategory.text,
                expanded: false,
                subcategories: shearCategory.details.filter(x => shearProperties.includes(x.property)).map((subCategory): IUtilizationSubCategory => ({
                    property: subCategory.property,
                    text: subCategory.text,
                    image: subCategory.image,
                    value: shear[subCategory.property as objKey].Percentage
                }))
            });
        }

        const combinationCategory = this.utilizations.find(x => x.property == 'combination');
        if (combinationCategory != null && this.combination != null) {
            const combination = this.combination;
            const combinationProperties = Object.getOwnPropertyNames(combination);
            type objKey = keyof UtilizationCombinationEntity;

            if (combinationProperties.length == 1 && combinationProperties[0] == 'Decisive') {
                details.push({
                    property: combinationCategory.property,
                    text: combinationCategory.text,
                    expanded: false,
                    subcategories: [{
                        property: 'Decisive',
                        text: 'Agito.Hilti.Profis3.Utilizations.Combination.Decisive',
                        image: 'combination-concrete',
                        value: combination.Decisive.Percentage
                    }]
                });
            }
            else {
                details.push({
                    property: combinationCategory.property,
                    text: combinationCategory.text,
                    expanded: false,
                    subcategories: combinationCategory.details.filter(x => combinationProperties.includes(x.property)).map((subCategory): IUtilizationSubCategory => ({
                        property: subCategory.property,
                        text: subCategory.text,
                        image: subCategory.image,
                        value: combination[subCategory.property as objKey].Percentage
                    }))
                });
            }
        }

        return details;
    }
}
