import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    StiffenerLayoutPositionEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    ProfileShapeType, StiffenerCrossSectionPart, StiffenerLocation, StiffenerWeldType
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from '../code-lists/code-list';

export interface IStiffenerLayoutPositionConstructor extends ICodeListConstructor {
    profileShapeType: ProfileShapeType;
    profisKey: number;
    stiffenerLocation: StiffenerLocation;
    stiffenerCrossSectionPart: StiffenerCrossSectionPart;
    stiffenerWeldType: StiffenerWeldType;
}

export class StiffenerLayoutPosition extends CodeList {
    public profileShapeType!: ProfileShapeType;
    public profisKey!: number;
    public stiffenerLocation!: StiffenerLocation;
    public stiffenerCrossSectionPart!: StiffenerCrossSectionPart;
    public stiffenerWeldType!: StiffenerWeldType;

    constructor(codeList: IStiffenerLayoutPositionConstructor) {
        super(codeList);

        if (codeList != null) {
            this.profileShapeType = codeList.profileShapeType;
            this.profisKey = codeList.profisKey;
            this.stiffenerLocation = codeList.stiffenerLocation;
            this.stiffenerCrossSectionPart = codeList.stiffenerCrossSectionPart;
            this.stiffenerWeldType = codeList.stiffenerWeldType;
        }
    }

    public static fromService(codeList: StiffenerLayoutPositionEntity) {
        return new StiffenerLayoutPosition(CodeList.baseFromService<IStiffenerLayoutPositionConstructor>('StiffenerLayoutPositionEntity', codeList, {
            profileShapeType: codeList.ProfileShapeType,
            profisKey: codeList.ProfisKey,
            stiffenerLocation: codeList.StiffenerLocation,
            stiffenerCrossSectionPart: codeList.StiffenerCrossSectionPart,
            stiffenerWeldType: codeList.StiffenerWeldType
        } as IStiffenerLayoutPositionConstructor));
    }

    public override cloneDeep(): StiffenerLayoutPosition {
        const codeList: StiffenerLayoutPosition = super.cloneDeep() as StiffenerLayoutPosition;

        codeList.profileShapeType = this.profileShapeType;
        codeList.profisKey = this.profisKey;
        codeList.stiffenerLocation = this.stiffenerLocation;
        codeList.stiffenerCrossSectionPart = this.stiffenerCrossSectionPart;
        codeList.stiffenerWeldType = this.stiffenerWeldType;

        return codeList;
    }
}
