import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    LoadCombinationHNAFactorsEntity,
    LoadCombinationHNAFactorsLoadFactorEntity as LoadCombinationHNAFactorsLoadFactor
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    DesignMethodHNA
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CodeList } from '../code-lists/code-list';

export interface ILoadCombinationHNAFactorsConstructor extends ICodeListConstructor {
    designMethodHNA?: DesignMethodHNA;
    sustainedLoadFactorEnabled?: boolean;
    sustainedLoadFactorDefault?: number;
    sustainedLoadFactorMin?: number;
    sustainedLoadFactorMax?: number;
    loadFactorEnabled?: boolean;
    loadFactorDefault?: number;
    loadFactorValues?: LoadCombinationHNAFactorsLoadFactor[];
}

export class LoadCombinationHNAFactors extends CodeList {
    public designMethodHNA?: DesignMethodHNA;
    public sustainedLoadFactorEnabled?: boolean;
    public sustainedLoadFactorDefault?: number;
    public sustainedLoadFactorMin?: number;
    public sustainedLoadFactorMax?: number;
    public loadFactorEnabled?: boolean;
    public loadFactorDefault?: number;
    public loadFactorValues?: LoadCombinationHNAFactorsLoadFactor[];

    constructor(codeList: ILoadCombinationHNAFactorsConstructor) {
        super(codeList);

        if (codeList != null) {
            this.designMethodHNA = codeList.designMethodHNA;
            this.sustainedLoadFactorEnabled = codeList.sustainedLoadFactorEnabled;
            this.sustainedLoadFactorDefault = codeList.sustainedLoadFactorDefault;
            this.sustainedLoadFactorMin = codeList.sustainedLoadFactorMin;
            this.sustainedLoadFactorMax = codeList.sustainedLoadFactorMax;
            this.loadFactorEnabled = codeList.loadFactorEnabled;
            this.loadFactorDefault = codeList.loadFactorDefault;
            this.loadFactorValues = codeList.loadFactorValues != null ? codeList.loadFactorValues.slice() : undefined;
        }
    }

    public static fromService(codeList: LoadCombinationHNAFactorsEntity) {
        return new LoadCombinationHNAFactors(CodeList.baseFromService<ILoadCombinationHNAFactorsConstructor>('LoadCombinationHNAFactorsEntity', codeList, {
            designMethodHNA: codeList.DesignMethodHNA,
            sustainedLoadFactorEnabled: codeList.SustainedLoadFactorEnabled,
            sustainedLoadFactorDefault: codeList.SustainedLoadFactorDefault,
            sustainedLoadFactorMin: codeList.SustainedLoadFactorMin,
            sustainedLoadFactorMax: codeList.SustainedLoadFactorMax,
            loadFactorEnabled: codeList.LoadFactorEnabled,
            loadFactorDefault: codeList.LoadFactorDefault,
            loadFactorValues: codeList.LoadFactorValues
        } as ILoadCombinationHNAFactorsConstructor));
    }

    public override cloneDeep() {
        const codeList: LoadCombinationHNAFactors = super.cloneDeep() as LoadCombinationHNAFactors;

        codeList.designMethodHNA = this.designMethodHNA;
        codeList.sustainedLoadFactorEnabled = this.sustainedLoadFactorEnabled;
        codeList.sustainedLoadFactorDefault = this.sustainedLoadFactorDefault;
        codeList.sustainedLoadFactorMin = this.sustainedLoadFactorMin;
        codeList.sustainedLoadFactorMax = this.sustainedLoadFactorMax;
        codeList.loadFactorEnabled = this.loadFactorEnabled;
        codeList.loadFactorDefault = this.loadFactorDefault;
        codeList.loadFactorValues = this.loadFactorValues != null ? this.loadFactorValues.slice() : undefined;

        return codeList;
    }
}
