import { ContolsStyleSheets } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import styles from './react-custom-beams.css';
import { ICustomBeamsProps } from './custom-beams-props';

let sheet = new CSSStyleSheet();
sheet.replaceSync(styles);

const TextBox = (window as any).pe.reactComponents.TextBox;
const Label = (window as any).pe.reactComponents.Label;

export class _CustomBeams extends React.PureComponent<ICustomBeamsProps> {
    public static styleSheets = [sheet];

    constructor(props: ICustomBeamsProps) {
        super(props);
    }

    public override render() {
        let s: string[][] = [];
        this.props.beams?.forEach(beam => {
            s.push(['Profile family', beam.ProfileFamilyId ? beam.ProfileFamilyId.toString() : '']);
            s.push(['Profile size', beam.ProfileSizeId ? beam.ProfileSizeId.toString() : '']);
            s.push(['Steel material', beam.SteelTypeId ? beam.SteelTypeId.toString() : '']);
            s.push(['Checkbot name', beam.BeamName ? beam.BeamName.toString() : '']);
            s.push(['Load x', '0']);
            s.push(['Load y', '0']);
            s.push(['Load z', '0']);
            s.push(['Moment x', '0']);
            s.push(['Moment y', '0']);
            s.push(['Moment z', '0']);
        })

        return (
            <div className='react-custom-beams-wrapper'>
                    {s.map(a => (
                        <div className='textbox-wrapper'>
                            <TextBox
                                value={a[1]}
                                controlId='0'
                                disabled={true}
                                hidden={false}
                                isEditable={false}
                                size={null}
                                title={a[0]}
                                type={Label}
                                localization={this.props.localization}
                            />
                        </div>

                    ))}
            </div>);
    }
}


export const CustomBeams: typeof _CustomBeams & ContolsStyleSheets = _CustomBeams;