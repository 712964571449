<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionLayout.ControlFillHolesETAG.Tooltip.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="fill-holes-popup">
        <div class="left">
            <p [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionLayout.ControlFillHolesETAG.Tooltip')"></p>
        </div>

        <div class="right">
            <img [src]="hiltiFillHoles" alt="hilti-fill-holes">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
