import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

import atToolPopupImage from '../../../images/at-tool-torquing-type-popup.png';

@Component({
    templateUrl: './automatic-torque-popup.component.html',
    styleUrls: ['./automatic-torque-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AutomaticTorquePopupComponent implements OnInit {
    public automaticTooltipImage?: string;

    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        private localizationService: LocalizationService
    ) { }

    public ngOnInit(): void {
        this.automaticTooltipImage = atToolPopupImage;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
