<div id="LC-table" class="loads-container">
    <!-- Tooltips -->
    <ng-template #staticLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.staticTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #seismicLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.seismicTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #fatigueLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.fatigueTooltip"></pe-content-tooltip>
    </ng-template>

    <ng-template #fireLoadTooltip>
        <pe-content-tooltip [tooltip]="helper.fireTooltip"></pe-content-tooltip>
    </ng-template>
    <!-- Tooltips -->

    <pe-resizer
        [disabled]="collapsed"
        [targetHtmlElement]="loadsContainer"
        [parentHtmlElement]="parentElement"
        [direction]="'up'"
        (sizeChange)="onResize()"
        class="loads-resizer">
        <div id="load-combinations" class="control loads"  *ngIf="design != null">
            <!-- Collapsed with a single decisive load combination. -->
            <div class="loads-header-collapsed" *ngIf="collapsed && selectedLoad != null">
                <div class="loads-top-header">
                    <div class="column-selected"></div>

                    <div class="collapsed-loads-space"></div>

                    <div class="column-name" [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.name ?? 1) - 1) + 'px' }">
                        <div class="text">{{translate(titleTranslation)}}</div>
                    </div>

                    <div class="column-type" *ngIf="helper.areLoadTypesAvailableForLoadCombinations">
                        <div class="text">{{translate("Agito.Hilti.Profis3.Loads.Type")}}</div>
                    </div>

                    <div class="column-loads" *ngIf="helper.showDynamic">
                        <div class="text">{{translate("Agito.Hilti.Profis3.Loads.Loads")}}</div>
                    </div>

                    <div class="column-forces"
                        [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.forceVx ?? 1) + (helper.resizerColumnWidth?.forceVy ?? 1) + (helper.resizerColumnWidth?.forceN ?? 1)) + 'px' }">
                        <div class="text">{{forcesHeader}}</div>
                    </div>

                    <div class="column-moments"
                        [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.momentMx ?? 1) + (helper.resizerColumnWidth?.momentMy ?? 1) + (helper.resizerColumnWidth?.momentMz ?? 1)) + 'px' }">
                        <div class="text">{{momentsHeader}}</div>
                    </div>

                    <div class="column-utilization" [class.column-collapsed-hidden]="collapsed"
                        [ngStyle]="{ 'flex-basis': utilizationFlexBasis + 'px' }"></div>

                    <div class="column-handrail" *ngIf="design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-messages" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-collapse"
                        [class.column-collapse-margin]="design.designType.id == helper.designTypeEnum.Handrail">
                        <button
                            id="load-combinations-collapse-button-one"
                            class="collapse-button"
                            type="button"
                            (click)="collapsed = !collapsed; onCollapsedChanged()"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                            <span class="pe-ui-pe-sprite"
                                [class.pe-ui-pe-sprite-lines]="collapsed"
                                [class.pe-ui-pe-sprite-lines-expanded]="!collapsed"></span>
                        </button>
                    </div>
                </div>

                <div class="loads-sub-header">
                    <div class="column-selected">
                        <div class="decisive-icons"
                            *ngIf="design.designType.id == helper.designTypeEnum.Handrail && loads.length > 1 && !isSingleCalculation">
                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-decisive-load-anchor decisive-icon loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseAnchor')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-decisive-load-handrail decisive-icon loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadHandrailId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseHandrail')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-material loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadBaseMaterialId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBaseMatirial')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-profiles loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadProfileId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseProfile')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-plate loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadAnchorPlateId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBasePlate')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-double-weld-location-small"
                                *ngIf="selectedLoad.Id == decisiveLoadWeldsId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseWelds')"></span>
                        </div>

                        <div class="decisive-icons"
                            *ngIf="(design.designType.id == helper.designTypeEnum.Concrete || design.designType.id == helper.designTypeEnum.Masonry || design.designStandard.id == designStandardEnum.ACI) && loads.length > 1 && !isSingleCalculation">
                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-anchor-layout loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseAnchor')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-material loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadBaseMaterialId "
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBaseMatirial')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-profiles loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id  == decisiveLoadProfileId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseProfile')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-plate loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadAnchorPlateId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBasePlate')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-double-weld-location-small loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadWeldsId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseWelds')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-profile-i-all-small loads-img-margin-left-4"
                                *ngIf="selectedLoad.Id == decisiveLoadStiffenersId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseStiffener')"></span>
                        </div>
                    </div>

                    <div class="collapsed-loads-space"></div>

                    <div class="column-name" [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.name ?? 1) - 1) + 'px' }"
                        [ngbTooltip]="selectedLoad.Name">
                        <div class="text">
                            <span class="clip">{{selectedLoad.Name}}</span>
                        </div>
                    </div>

                    <div class="column-type clip" *ngIf="helper.areLoadTypesAvailableForLoadCombinations">
                        <div class="text">{{selectedLoadTypeName}}</div>
                    </div>

                    <div class="column-loads clip" *ngIf="helper.showDynamic">
                        <div class="text">{{helper.firstRowLoadLegendTranslation}}</div>
                        <div class="text">{{helper.secondRowLoadLegendTranslation}}</div>
                    </div>

                    <div class="column-force column-vx clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVx + 'px' }">
                        <div class="text">Vx: {{formatForce(selectedLoad.ForceX)}}</div>
                        <div class="text" *ngIf="helper.showDynamic" [class.invisible]="helper.isSustained">Vx:
                            {{formatForce(selectedLoad.DynamicForceX)}}</div>
                    </div>

                    <div class="column-force column-vy clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVy + 'px' }">
                        <div class="text">Vy: {{formatForce(selectedLoad.ForceY)}}</div>
                        <div class="text" *ngIf="helper.showDynamic" [class.invisible]="helper.isSustained">Vy:
                            {{formatForce(selectedLoad.DynamicForceY)}}</div>
                    </div>

                    <div class="column-force column-n clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceN + 'px' }">
                        <div class="text">N: {{formatForce(selectedLoad.ForceZ)}}</div>
                        <div class="text" *ngIf="helper.showDynamic" [class.invisible]="design.isFatigueExpertMode">N:
                            {{formatForce(selectedLoad.DynamicForceZ)}}</div>
                    </div>

                    <div class="column-moment column-mx clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMx + 'px' }">
                        <div class="text">Mx: {{formatMoment(selectedLoad.MomentX)}}</div>
                        <div class="text" *ngIf="helper.showDynamic" [class.invisible]="design.isFatigueExpertMode">Mx:
                            {{formatMoment(selectedLoad.DynamicMomentX)}}</div>
                    </div>

                    <div class="column-moment column-my clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMy + 'px' }">
                        <div class="text">My: {{formatMoment(selectedLoad.MomentY)}}</div>
                        <div class="text" *ngIf="helper.showDynamic" [class.invisible]="design.isFatigueExpertMode">My:
                            {{formatMoment(selectedLoad.DynamicMomentY)}}</div>
                    </div>

                    <div class="column-moment column-mz clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMz + 'px' }">
                        <div class="text">Mz: {{formatMoment(selectedLoad.MomentZ)}}</div>
                        <div class="text" *ngIf="helper.showDynamic" [class.invisible]="helper.isSustained">Mz:
                            {{formatMoment(selectedLoad.DynamicMomentZ)}}</div>
                    </div>

                    <div class="column-utilization" [class.column-collapsed-hidden]="collapsed"
                        [ngStyle]="{ 'flex-basis': utilizationFlexBasis + 'px' }"></div>

                    <div class="column-handrail" *ngIf="design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-messages" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-delete" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"></div>
                </div>
            </div>

            <!-- Collapsed with multiple decisive load combinations. -->
            <div class="loads-header-collapsed" *ngIf="collapsed && selectedLoad == null">
                <div class="loads-top-header">
                    <div class="column-selected"></div>

                    <div class="collapsed-loads-space"></div>

                    <div class="column-name" [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.name ?? 1) - 1) + 'px' }">
                        <div class="text"></div>
                    </div>

                    <div class="column-type" *ngIf="helper.areLoadTypesAvailableForLoadCombinations">
                        <div class="text">{{translate("Agito.Hilti.Profis3.Loads.Type")}}</div>
                    </div>

                    <div class="column-loads" *ngIf="helper.showDynamic"></div>

                    <div class="column-forces"
                        [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.forceVx ?? 1) + (helper.resizerColumnWidth?.forceVy ?? 1) + (helper.resizerColumnWidth?.forceN ?? 1)) + 'px' }">
                        <div class="text">{{forcesHeader}}</div>
                    </div>

                    <div class="column-moments"
                        [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.momentMx ?? 1) + (helper.resizerColumnWidth?.momentMy ?? 1) + (helper.resizerColumnWidth?.momentMz ?? 1)) + 'px' }">
                        <div class="text">{{momentsHeader}}</div>
                    </div>

                    <div class="column-utilization" [class.column-collapsed-hidden]="collapsed"
                        [ngStyle]="{ 'flex-basis': utilizationFlexBasis + 'px' }"></div>

                    <div class="column-concrete" *ngIf="design.designType.id == helper.designTypeEnum.Concrete"></div>

                    <div class="column-masonry" *ngIf="design.designType.id == helper.designTypeEnum.Masonry"></div>

                    <div class="column-aci" *ngIf="design.designStandard.id == designStandardEnum.ACI"></div>

                    <div class="column-handrail" *ngIf="design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-messages" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-collapse"
                        [class.column-collapse-margin]="design.designType.id == helper.designTypeEnum.Handrail">
                        <button
                            id="load-combinations-collapse-button-one"
                            class="collapse-button"
                            type="button"
                            (click)="collapsed = !collapsed; onCollapsedChanged()"
                            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                            <span class="pe-ui-pe-sprite"
                                [class.pe-ui-pe-sprite-lines]="collapsed"
                                [class.pe-ui-pe-sprite-lines-expanded]="!collapsed"></span>
                        </button>
                    </div>
                </div>

                <div class="loads-sub-header" *ngFor="let decisiveLoad of decisiveLoadCombinations; trackBy: decisiveLoadsTrackById">
                    <div class="column-selected">
                        <div class="decisive-icons"
                            *ngIf="design.designType.id == helper.designTypeEnum.Handrail && loads.length > 1 && !isSingleCalculation">
                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-decisive-load-anchor decisive-icon loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseAnchor')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-decisive-load-handrail decisive-icon loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadHandrailId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseHandrail')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-material loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadBaseMaterialId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBaseMatirial')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-profiles loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadProfileId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseProfile')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-plate loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadAnchorPlateId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBasePlate')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-double-weld-location-small"
                                *ngIf="decisiveLoad.Id == decisiveLoadWeldsId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseWelds')"></span>
                        </div>

                        <div class="decisive-icons"
                            *ngIf="(design.designType.id == helper.designTypeEnum.Concrete || design.designType.id == helper.designTypeEnum.Masonry || design.designStandard.id == designStandardEnum.ACI)  && loads.length > 1 && !isSingleCalculation">
                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-anchor-layout loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id  == decisiveLoadId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseAnchor')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-material loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadBaseMaterialId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBaseMatirial')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-profiles loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadProfileId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseProfile')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-tab-base-plate loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadAnchorPlateId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseBasePlate')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-double-weld-location-small"
                                *ngIf="decisiveLoad.Id == decisiveLoadWeldsId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseWelds')"></span>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-profile-i-all-small loads-img-margin-left-4"
                                *ngIf="decisiveLoad.Id == decisiveLoadStiffenersId"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DecisiveLoadCaseStiffener')"></span>
                        </div>
                    </div>

                    <div class="collapsed-loads-space"></div>

                    <div class="column-name clip" [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.name ?? 1) - 1 ) + 'px' }"
                        [ngbTooltip]="decisiveLoad.Name">
                        <div class="text">{{decisiveLoad.Name}}</div>
                    </div>

                    <div class="column-type clip" *ngIf="helper.areLoadTypesAvailableForLoadCombinations">
                        <div class="text">{{multipleCombinationsSelectedLoadTypeName(decisiveLoad.Id)}}</div>
                    </div>

                    <div class="column-loads clip" *ngIf="helper.isSustained">
                        <div class="text">{{helper.firstRowLoadLegendTranslation}}</div>
                        <div class="text">{{helper.secondRowLoadLegendTranslation}}</div>
                    </div>

                    <div class="column-force column-vx clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVx + 'px' }">
                        <div class="text">Vx: {{formatForce(decisiveLoad.ForceX)}}</div>
                        <div class="text" *ngIf="helper.showDynamic">Vx: {{formatForce(decisiveLoad.DynamicForceX)}}
                        </div>
                    </div>

                    <div class="column-force column-vy clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVy + 'px' }">
                        <div class="text">Vy: {{formatForce(decisiveLoad.ForceY)}}</div>
                        <div class="text" *ngIf="helper.showDynamic">Vy: {{formatForce(decisiveLoad.DynamicForceY)}}
                        </div>
                    </div>

                    <div class="column-force column-n clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceN + 'px' }">
                        <div class="text">N: {{formatForce(decisiveLoad.ForceZ)}}</div>
                        <div class="text" *ngIf="helper.showDynamic"
                            [class.invisible]="design.isFatigueExpertMode">N:
                            {{formatForce(decisiveLoad.DynamicForceZ)}}</div>
                    </div>

                    <div class="column-moment column-mx clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMx + 'px' }">
                        <div class="text">Mx: {{formatMoment(decisiveLoad.MomentX)}}</div>
                        <div class="text" *ngIf="helper.showDynamic"
                            [class.invisible]="design.isFatigueExpertMode">Mx:
                            {{formatMoment(decisiveLoad.DynamicMomentX)}}</div>
                    </div>

                    <div class="column-moment column-my clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMy + 'px' }">
                        <div class="text">My: {{formatMoment(decisiveLoad.MomentY)}}</div>
                        <div class="text" *ngIf="helper.showDynamic"
                            [class.invisible]="design.isFatigueExpertMode">My:
                            {{formatMoment(decisiveLoad.DynamicMomentY)}}</div>
                    </div>

                    <div class="column-moment column-mz clip" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMz + 'px' }">
                        <div class="text">Mz: {{formatMoment(decisiveLoad.MomentZ)}}</div>
                        <div class="text" *ngIf="helper.showDynamic">Mz: {{formatMoment(decisiveLoad.DynamicMomentZ)}}
                        </div>
                    </div>

                    <div class="column-utilization" [class.column-collapsed-hidden]="collapsed"
                        [ngStyle]="{ 'flex-basis': utilizationFlexBasis + 'px' }"></div>

                    <div class="column-concrete" *ngIf="design.designType.id == helper.designTypeEnum.Concrete"></div>

                    <div class="column-masonry" *ngIf="design.designType.id == helper.designTypeEnum.Masonry"></div>

                    <div class="column-aci" *ngIf="design.designStandard.id == designStandardEnum.ACI"></div>

                    <div class="column-handrail" *ngIf="design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-messages" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"
                        [class.column-collapsed-hidden]="collapsed"></div>

                    <div class="column-delete" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"></div>
                </div>
            </div>

            <!-- Expanded. -->
            <div class="resizer-wrapper" *ngIf="!collapsed && rowsLoaded">
                <div class="loads-header">
                    <div class="header-sizer">
                        <div class="column-selected"></div>

                        <div class="column-rank">
                            <div class="text">N°</div>
                        </div>

                        <div class="column-name" [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.name ?? 1) - 1) + 'px' }">
                            <div class="text">{{translate("Agito.Hilti.Profis3.Loads.Name")}}</div>
                        </div>

                        <div class="column-type" *ngIf="helper.areLoadTypesAvailableForLoadCombinations"
                            [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
                            <div class="text">{{translate("Agito.Hilti.Profis3.Loads.Type")}}</div>
                        </div>

                        <div class="column-loads" *ngIf="helper.showDynamic">
                            <div class="text">
                                <span>{{translate("Agito.Hilti.Profis3.Loads.Loads")}}</span>

                                <button
                                    *ngIf="design.isDynamicFatigue"
                                    type="button"
                                    class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip kbTooltip bottom-right-abs loads-tooltip"
                                    (click)="openFatigueLoadsInfo()">
                                </button>
                            </div>
                        </div>

                        <div class="column-forces"
                            [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.forceVx ?? 1) + (helper.resizerColumnWidth?.forceVy ?? 1) + (helper.resizerColumnWidth?.forceN ?? 1)) + 'px' }">
                            <div class="text">{{forcesHeader}}</div>
                        </div>

                        <div class="column-moments"
                            [ngStyle]="{ 'flex-basis': ((helper.resizerColumnWidth?.momentMx ?? 1) + (helper.resizerColumnWidth?.momentMy ?? 1) + (helper.resizerColumnWidth?.momentMz ?? 1)) + 'px' }">
                            <div class="text">{{momentsHeader}}</div>
                        </div>

                        <div class="column-utilization" [ngStyle]="{ 'flex-basis': utilizationFlexBasis + 'px' }">
                            <div class="text">{{translate("Agito.Hilti.Profis3.Loads.Utilization")}}</div>
                        </div>

                        <div class="column-concrete" *ngIf="design.designType.id == helper.designTypeEnum.Concrete"></div>

                        <div class="column-masonry" *ngIf="design.designType.id == helper.designTypeEnum.Masonry"></div>

                        <div class="column-aci" *ngIf="design.designStandard.id == designStandardEnum.ACI"></div>

                        <div class="column-handrail" *ngIf="design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"></div>

                        <div class="column-messages" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"></div>

                        <div class="column-collapse"
                            [class.column-collapse-margin]="design.designType.id == helper.designTypeEnum.Handrail">
                            <button
                                id="load-combinations-collapse-button-two"
                                class="collapse-button"
                                type="button"
                                (click)="collapsed = !collapsed; onCollapsedChanged()"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.ShowHide')">
                                <span class="pe-ui-pe-sprite" [class.pe-ui-pe-sprite-lines]="collapsed" [class.pe-ui-pe-sprite-lines-expanded]="!collapsed"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div id="loads-content" class="loads-content"
                    [class.concrete]="!(design.designType.id == helper.designTypeEnum.Handrail || helper.isPropertyHidden(propertyMetaData.Loads_ImportLoadCombinations.id))">
                    <div class="loads-sub-header">
                        <div class="header-sizer">
                            <div class="column-selected"></div>

                            <div class="column-rank"></div>

                            <div class="column-name" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth != null ? ((helper.resizerColumnWidth.name) - 1) + 'px' : null }"></div>

                            <div class="column-type" *ngIf="helper.areLoadTypesAvailableForLoadCombinations"
                                [ngStyle]="{ 'flex-basis': helper.columnTypeWidth + 'px' }">
                                <div class="type-buttons btn-group" role="group">
                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Static)"
                                        id="load-combinations-all-load-type-static-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(1)"
                                        [disabled]="submitted"
                                        [ngbTooltip]="staticLoadTooltip">
                                        <span class="pe-ui-pe-sprite pe-ui-pe-sprite-anchor-shock"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Seismic)"
                                        id="load-combinations-all-load-type-seismic-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(2)"
                                        [disabled]="submitted || helper.isSmartAnchorToggleON"
                                        [ngbTooltip]="seismicLoadTooltip"
                                        [ngClass]="{'load-type-button-disabled': helper.isSmartAnchorToggleON}">
                                        <span class="pe-ui-pe-sprite pe-ui-pe-sprite-anchor-seismic"></span>
                                    </button>

                                    <button
                                        *ngIf="helper.isLoadTypeAllowed(helper.loadTypeEnum.Fire)"
                                        id="load-combinations-all-load-type-fire-button"
                                        type="button"
                                        class="type-button btn btn-default"
                                        (click)="setAllLoads(4)"
                                        [disabled]="submitted || helper.isSmartAnchorToggleON"
                                        [ngbTooltip]="fireLoadTooltip"
                                        [ngClass]="{'load-type-button-disabled': helper.isSmartAnchorToggleON}">
                                        <span class="pe-ui-pe-sprite pe-ui-pe-sprite-anchor-fire-resistant"></span>
                                    </button>
                                </div>
                            </div>

                            <div class="column-loads" *ngIf="helper.showDynamic"></div>

                            <div class="column-force column-vx" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVx + 'px' }">
                                <div class="text" >{{translate("Agito.Hilti.Profis3.Loads.Vx")}}</div>
                            </div>

                            <div class="column-force column-vy" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceVy + 'px' }">
                                <div class="text">{{translate("Agito.Hilti.Profis3.Loads.Vy")}}</div>
                            </div>

                            <div class="column-force column-n" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.forceN + 'px' }">
                                <div class="text" >{{translate("Agito.Hilti.Profis3.Loads.N")}}</div>
                            </div>

                            <div class="column-moment column-mx" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMx + 'px' }">
                                <div class="text" >{{translate("Agito.Hilti.Profis3.Loads.Mx")}}</div>
                            </div>

                            <div class="column-moment column-my" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMy + 'px' }">
                                <div class="text" >{{translate("Agito.Hilti.Profis3.Loads.My")}}</div>
                            </div>

                            <div class="column-moment column-mz" [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.momentMz + 'px' }">
                                <div class="text" >{{translate("Agito.Hilti.Profis3.Loads.Mz")}}</div>
                            </div>

                            <div class="column-utilization" #utilizationColumnRef [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.utilization + 'px' }">
                                <div class="utilization-text" *ngIf="!helper.isUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.Profis3.Loads.Tension.Short")}}</div>
                                </div>

                                <div class="utilization-text" *ngIf="!helper.isUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.Profis3.Loads.Shear.Short")}}</div>
                                </div>

                                <div class="utilization-text" *ngIf="!helper.isUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.Profis3.Loads.Combination.Short")}}</div>
                                </div>

                                <div class="utilization-text">
                                    <div class="centered-text">{{utilizationTotalText}}</div>
                                </div>
                            </div>

                            <div class="column-handrail" #handrailColumnRef
                                *ngIf="design.designType.id == helper.designTypeEnum.Handrail && showPostAndRailDeflection"
                                [ngStyle]="{ 'flex-basis': helper.resizerColumnWidth?.handrail + 'px' }">

                                <div class="utilization-text" *ngIf="helper.isHandrailUtilizationCompact">
                                    <div class="centered-text">
                                        <span>{{translate("Agito.Hilti.Profis3.Loads.Handrail")}}</span>

                                        <button
                                            *ngIf="isDeflectionLimitsVisible"
                                            id="deflection-limitation"
                                            type="button"
                                            class="deflection-limitation-button"
                                            (click)="openDeflectionLimits()"
                                            [ngbTooltip]="translate('Agito.Hilti.Profis3.DeflectionLimits.Tooltip')">
                                            <span class="notification-icon pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                                        </button>
                                    </div>
                                </div>

                                <div class="utilization-text" *ngIf="!helper.isHandrailUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.Profis3.Loads.Rail")}}</div>
                                </div>

                                <div class="utilization-text" *ngIf="!helper.isHandrailUtilizationCompact">
                                    <div class="centered-text">{{translate("Agito.Hilti.Profis3.Loads.Post")}}</div>
                                </div>

                                <div class="utilization-text utilization-text-wide" *ngIf="!helper.isHandrailUtilizationCompact && design.isHandrailCBFEMCalculation">
                                    <div class="centered-text">{{translate("Agito.Hilti.Profis3.Loads.Connection")}}</div>
                                </div>

                                <div class="utilization-text utilization-text-wide"
                                    *ngIf="!helper.isHandrailUtilizationCompact">
                                    <div class="centered-text">
                                        <span>{{translate("Agito.Hilti.Profis3.Loads.Deflection")}}</span>

                                        <button
                                            *ngIf="isDeflectionLimitsVisible"
                                            id="deflection-limitation"
                                            type="button"
                                            class="deflection-limitation-button"
                                            (click)="openDeflectionLimits()"
                                            [ngbTooltip]="translate('Agito.Hilti.Profis3.DeflectionLimits.Tooltip')">
                                            <span class="notification-icon pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="column-messages" *ngIf="design.designType.id != helper.designTypeEnum.Handrail"></div>

                            <div class="column-delete" *ngIf="design.designType.id != helper.designTypeEnum.Handrail">
                                <div [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DeleteInactive')"
                                    [disableTooltip]="submitted">
                                    <button
                                        id="load-combinations-delete-button"
                                        type="button"
                                        class="delete-button"
                                        (click)="deleteLoads()"
                                        [disabled]="submitted || helper.deleteLoadDisabled"
                                        [disableTooltip]="submitted"
                                        [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Loads.DeleteInactive')">
                                        <span class="pe-ui-pe-sprite pe-ui-pe-sprite-trash"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="loads-rows">
                        <virtual-scroller #scrollLoads
                            [items]="loads" [scrollAnimationTime]="0"
                            [ngStyle]="{ 'height': '100%', 'overflow-x': 'hidden' }" (vsUpdate)="virtualScrollChange($any($event))">
                            <pe-loads-row
                                class="loads-row-component"
                                *ngFor="let load of scrollLoads.viewPortItems; index as viewportIndex; trackBy:identifyLoad;"
                                [resizerWrapper]="resizerWrapper"
                                [helper]="helper"
                                [selectedLoadId]="selectedLoadId"
                                [showPostAndRailDeflection]="showPostAndRailDeflection"
                                [isAdd]="false"
                                [parentId]="'load-combinations'"
                                [load]="load"
                                [loadIndex]="scrollLoads.viewPortInfo.startIndex + viewportIndex"
                                [disabled]="disabled"
                                [submitted]="submitted"
                                [isDecisiveLoadCombinationFn]="isDecisive"
                                (loadSelect)="selectLoad($any($event).detail)"
                                (loadChange)="changeLoad()"
                                (loadDelete)="deleteLoad($any($event).detail)"
                                (columnsResize)="columnsResized()"
                                [isUtilizationCompact]="helper.isUtilizationCompact"
                                [isHandrailUtilizationCompact]="helper.isHandrailUtilizationCompact"
                                [isUtilizationVisible] = "isUtilizationVisible">
                            </pe-loads-row>

                            <pe-loads-row
                                class="loads-row-component"
                                *ngIf="helper.addingNewLoad"
                                [resizerWrapper]="resizerWrapper"
                                [helper]="helper"
                                [selectedLoadId]="selectedLoadId"
                                [showPostAndRailDeflection]="showPostAndRailDeflection"
                                [isAdd]="true"
                                [parentId]="'load-combinations'"
                                [load]="helper.newLoad"
                                [disabled]="disabled"
                                [submitted]="submitted"
                                (loadAdd)="addNewLoad($any($event).detail)"
                                [isUtilizationVisible] = "isUtilizationVisible">
                            </pe-loads-row>
                        </virtual-scroller>
                    </div>
                </div>
            </div>

            <div class="loads-sub-footer" *ngIf="rowsLoaded" [class.visible]="!collapsed"
                [hidden]="design.designType.id == helper.designTypeEnum.Handrail">
                <div class="calculation-mode" *ngIf="!design.isCBFEMCalculation"
                    [hidden]="helper.isPropertyHidden(propertyMetaData.Loads_LoadCombinationsCalculationType.id)">
                    <pe-radio-button
                        [look]="loadsCalculationModeRadio.look"
                        [items]="loadsCalculationModeRadio.items"
                        [selectedValue]="loadsCalculationMode"
                        (selectedValueChange)="loadsCalculationModeRadioSelectedValueChange($any($event).detail)"
                        [disabled]="submitted || helper.isPropertyDisabled(propertyMetaData.Loads_LoadCombinationsCalculationType.id)">
                    </pe-radio-button>
                </div>

                <pe-button
                    [look]="'Default'"
                    [ngbTooltip]="loadTooltip"
                    class="modal-button button sub-footer-button"
                    (buttonClick)="openHNAWizardWarning()"
                    [disabled]="openHNAWizardDisabled"
                    [hidden]="openHNAWizardHidden">{{openHNAWizardTranslation}}</pe-button>


                <pe-button
                    [look]="'Default'"
                    [ngbTooltip]="loadTooltip"
                    class="modal-button button sub-footer-button"
                    (buttonClick)="openShowNewLoadWarning()"
                    [text]="translate('Agito.Hilti.Profis3.Loads.AddLoad')"
                    [disabled]="newLoadDisabled"
                    [hidden]="newLoadHidden">
                </pe-button>


                <pe-button
                    [look]="'Default'"
                    [ngbTooltip]="loadTooltip"
                    class="modal-button button sub-footer-button"
                    [text]="translate(importLoadsTranslation)"
                    (buttonClick)="importLoads()"
                    [disabled]="importLoadDisabled"
                    [hidden]="importLoadHidden">
                </pe-button>

                <pe-button
                    [look]="'Default'"
                    [ngbTooltip]="loadTooltip"
                    class="modal-button button sub-footer-button"
                    (buttonClick)="switchFatigueMode()"
                    [disabled]="switchFatigueModeDisabled"
                    [hidden]="switchFatigueModeHidden">{{switchToFatigueCalculationModeTranslation}}</pe-button>
            </div>
        </div>

    </pe-resizer>
</div>
