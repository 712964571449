import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ProjectCodeList } from '../enums/project-code-list';
import { ProjectDesignCodeLists } from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';

export abstract class CodeListServiceBase {
    public abstract projectCodeLists: Record<ProjectCodeList, CodeList[]>;
    public abstract initialize(data: ProjectDesignCodeLists): void;
}

export class CodeListServiceInjected extends CodeListServiceBase {
    private baseService!: CodeListServiceBase;

    public get projectCodeLists(): Record<ProjectCodeList, CodeList[]> {
        return this.baseService.projectCodeLists;
    }

    public setBaseService(baseService: CodeListServiceBase) {
        this.baseService = baseService;
        this.initialize = baseService.initialize.bind(baseService);
    }

    // CodeListServiceBase methods
    /* eslint-disable @typescript-eslint/no-unused-vars */
    public initialize(_data: ProjectDesignCodeLists): void {
        return;
    }
    /* eslint-enable @typescript-eslint/no-unused-vars */
}
