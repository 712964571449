import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { AnchorFilterGroupEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { AnchorFilterGroupOperator as GroupOperator } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import { CodeList } from './code-list';

export interface IAnchorFilterGroupConstructor extends ICodeListConstructor {
    groupOperator?: GroupOperator;
}

export class AnchorFilterGroup extends CodeList {
    public groupOperator?: GroupOperator;

    constructor(codeList: IAnchorFilterGroupConstructor) {
        super(codeList);

        if (codeList != null) {
            this.groupOperator = codeList.groupOperator;
        }
    }

    public static fromService(codeList: AnchorFilterGroupEntity) {
        return new AnchorFilterGroup(CodeList.baseFromService<IAnchorFilterGroupConstructor>('AnchorFilterGroupEntity', codeList, {
            groupOperator: codeList.GroupOperator
        } as IAnchorFilterGroupConstructor));
    }

    public override cloneDeep() {
        const codeList: AnchorFilterGroup = super.cloneDeep() as AnchorFilterGroup;
        codeList.groupOperator = this.groupOperator;

        return codeList;
    }
}
