import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    WindZoneEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class WindZone extends CodeList {

    public static fromService(codeList: WindZoneEntity) {
        return new WindZone(CodeList.baseFromService<ICodeListConstructor>('WindZoneEntity', codeList));
    }

    public override cloneDeep(): WindZone {
        return super.cloneDeep() as WindZone;
    }
}
