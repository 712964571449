<div class="utilizations box-section box-section-white"
    *ngIf="design != null && (rail?.Decisive != null || post?.Decisive != null || deflection?.Decisive != null)">
    <div class="box-section-header">
        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.HandrailDesign')"></span>
            <span class="bar-subtitle" *ngIf="!collapsed && isHandrailPostPositionAvailable()"
                [innerHtml]="translate(getPostPositionTranslationKey())"></span>
        </div>

        <pe-utilization-bar [collapsed]="collapsed" [utilizationInvalid]="handrailInvalid" [utilizationValue]="handrailValue"></pe-utilization-bar>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content">
        <ng-container *ngFor="let utilization of utilizations">
            <div *ngIf="getUtilizationFromProperty(utilization.property)?.Decisive != null" class="utilization"
            [class.invalid-value]="getUtilizationFromProperty(utilization.property).Decisive.ValueError"
            [class.invalid-text]="getUtilizationFromProperty(utilization.property).Decisive.TextError">
                <div [attr.id]="id + '-' + utilization.property + '-button'" class="utilization-button">
                    <div class="button-content">
                        <div class="text-progress-container">
                            <div class="deflection-limitation-container">
                                <p class="text" [innerHtml]="translate(utilization.text)"></p>
                                <div class="button-container" *ngIf="utilization.showDeflectionInfo == false">
                                    <button id="deflection-limitation" class="deflection-limitation-button" type="button"
                                        *ngIf="isDeflectionLimitsVisible" (click)="openDeflectionLimits()"
                                        [ngbTooltip]="translate('Agito.Hilti.Profis3.DeflectionLimits.Tooltip')"
                                        placement="auto top">
                                        <span class="notification-icon pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="progress-percent-container"
                                *ngIf="getUtilizationFromProperty(utilization.property)?.Decisive?.Percentage != null">
                                <div class="progress-percent"
                                    [style.width]="getUtilizationFromProperty(utilization.property).Decisive.Percentage + '%'"></div>
                            </div>
                        </div>

                        <div class="number-container">
                            <div class="details" *ngIf="getUtilizationOpendFromProperty(utilization.property)">
                                <ng-container *ngFor="let detail of utilization.details">
                                    <div *ngIf="getUtilizationValueEntityFromDetail(utilization.property, detail.property) != null" class="details-line"
                                    [class.invalid-value]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).ValueError"
                                    [class.invalid-text]="getUtilizationValueEntityFromDetail(utilization.property, detail.property).TextError">
                                        <p class="text" [innerHtml]="translate(detail.text)"></p>
                                        <p class="number" [innerHtml]="transformValue(getUtilizationValueEntityFromDetail(utilization.property, detail.property).Value, utilization.unit)"></p>
                                    </div>
                                </ng-container>
                            </div>

                            <p class="number" *ngIf="!getUtilizationDetailsFromProperty(utilization.property)"
                                [innerHtml]="transformValue(getUtilizationFromProperty(utilization.property).Decisive.Value, utilization.unit)"></p>

                            <div class="worst-result details-line"
                                *ngIf="getUtilizationWorstFromProperty(utilization.property) != null && !getUtilizationOpendFromProperty(utilization.property)"
                                [class.invalid-value]="getUtilizationWorstFromProperty(utilization.property).valueError"
                                [class.invalid-text]="getUtilizationWorstFromProperty(utilization.property).textError">
                                <p class="text" [innerHtml]="translate(getUtilizationWorstFromProperty(utilization.property).textKey)"></p>
                                <p class="number" [innerHtml]="transformValue(getUtilizationWorstFromProperty(utilization.property).value, utilization.unit)"></p>
                            </div>
                        </div>

                        <div class="design-right-collapse-container"
                            [class.opened]="getUtilizationOpendFromProperty(utilization.property)"
                            *ngIf="getUtilizationDetailsFromProperty(utilization.property) && getUtilizationLengthFromProperty(utilization.property) > 1">

                            <span class="design-right-collapse-line"></span>

                            <button type="button" class="design-right-collapse-button"
                                (click)="changeUtilizationPropertyOpend(utilization.property)">
                                <span class="pe-ui-pe-sprite" [class]="getUtilizationOpendFromProperty(utilization.property) ? 'pe-ui-pe-sprite-arrow-up' : 'pe-ui-pe-sprite-arrow-down'"></span>
                            </button>

                            <span class="design-right-collapse-line"></span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="details-line" *ngIf="deflection?.Regulatory != null"
            [class.invalid-value]="deflection.Regulatory.ValueError"
            [class.invalid-text]="deflection.Regulatory.TextError">
            <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.HandrailDesign.Deflection.Regulatory')"></p>
            <p class="number" [innerHtml]="transformValue(deflection.Regulatory.Value, 'Length')"></p>
        </div>
    </div>
</div>
