import sortBy from 'lodash-es/sortBy';
import moment from 'moment';

import { HttpRequest } from '@angular/common/http';
import {
    Component, ElementRef, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { View2dModeType } from '@profis-engineering/gl-model/gl-model';
import {
    calculateLoadsVisibilityInfo
} from '@profis-engineering/pe-gl-model/components/handrail-base-material-helper';
import {
    IScreenShotSettingsPe, LoadsVisibilityInfoPe, TextureDisplay
} from '@profis-engineering/pe-gl-model/gl-model';
import {
    InfoIcon, TooltipTarget
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    CodeList, getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import {
    ReportPaperSize
} from '@profis-engineering/pe-ui-common/entities/code-lists/report-paper-size';
import {
    ReportType as ReportTypeCodeList
} from '@profis-engineering/pe-ui-common/entities/code-lists/report-type';
import {
    IExportReportCompanyLayoutComponentInput
} from '@profis-engineering/pe-ui-common/entities/export-report-company-layout';
import {
    IExportReportProjectDetailsInput
} from '@profis-engineering/pe-ui-common/entities/export-report-project-details';
import {
    IExportReportSpecificationTextInput
} from '@profis-engineering/pe-ui-common/entities/export-report-specification-text';
import {
    IExportTrimbleConnectComponentInput
} from '@profis-engineering/pe-ui-common/entities/export-trimble-connect';
import { ITrimbleConnectSession } from '@profis-engineering/pe-ui-common/entities/trimble-connect';
import {
    Feature, KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    ReportTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import {
    PaperSize, ReportType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import {
    UploadDocumentModel
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Document';
import {
    DocumentIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    CalculationServiceBase
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { LanguageCulture } from '@profis-engineering/pe-ui-common/services/localization.common';
import { LogType } from '@profis-engineering/pe-ui-common/services/logger.common';

import { Region } from '../../../shared/entities/code-lists/region';
import { DesignPe } from '../../../shared/entities/design-pe';
import { ReportDetails } from '../../../shared/enums/export-report';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    LoadsLegendType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignMethodGroup, DesignStandard as DesignStandardEnum, DesignType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    GenerateReportRequest
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { HandrailHelper } from '../../../shared/helpers/handrail-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import loadIconBlueImage from '../../bundles/main/load-icon-blue.png';
import loadIconLoadInWorstPositionImage from '../../bundles/main/load-icon-load-in-worst-position.png';
import loadIconOrangeImage from '../../bundles/main/load-icon-orange.png';
import loadIconImage from '../../bundles/main/load-icon.png';
import { ApiService } from '../../services/api.service';
import { BrowserService } from '../../services/browser.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CodeListService } from '../../services/code-list.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { DateTimeService } from '../../services/date-time.service';
import { DocumentService } from '../../services/document.service';
import { ExportService } from '../../services/export.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { LoggerService } from '../../services/logger.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { TourService } from '../../services/tour.service';
import { TrimbleConnectService } from '../../services/trimble-connect.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';
import { TranslationFormatService } from '../../services/translation-format.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';

const customPicturesMaxUploadSize: number = 2 * 1024 * 1024;
const thumbnailSize = 145;
const labelKeyDynamicLoads = 'Agito.Hilti.Profis3.Legend.DynamicLoads';
const labelKeyStaticLoads = 'Agito.Hilti.Profis3.Legend.StaticLoads';
const labelKeyLoadsInWorstPosition = 'Agito.Hilti.Profis3.Legend.LoadsInWorstPosition';
const labelKeyDynamicSustainedLoads = 'Agito.Hilti.Profis3.Legend.DynamicLoads.Sustained';
const customTemplateId = Number.MAX_VALUE;

export interface IExportReportSupportMethods {
    createGlModelScreenshot2D: (extraInfo: IScreenShotSettingsPe, view2dMode: View2dModeType) => Promise<string>;
    createGlModelScreenshot: (extraInfo: IScreenShotSettingsPe) => Promise<string>;
    createZoneDiagramScreenshot: () => Promise<string>;
}

export interface IExportReportComponentInput extends IExportReportSupportMethods {
    isExportRisa?: boolean;

    onModelImagesCreated?: () => void;
    onModelImagesCreated2D?: () => void;
}

interface IImgPromiseObj {
    image: HTMLImageElement;
    promise: Promise<void>;
}

enum ValueWithLengthFormatMode {
    None = 0,
    Hef = 1,
    HNom = 2
}

interface ISectionCollapse {
    summary: boolean;
    specificationText: boolean;
    projectDetails: boolean;
    commentAndNotes: boolean;
    layout: boolean;
    trimbleConnect: boolean;
    customPicturesAvailable: boolean;
}

interface IImages<T> {
    static: T;
    variable: T;
    sustained: T;
    worst: T;
}

@Component({
    templateUrl: './export-report.component.html',
    styleUrls: ['./export-report.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IExportReportComponentInput>;

    public submitted = false;
    public isSectionCollapsed!: ISectionCollapse;
    public modelImageReport!: string;
    public propertyMetaDataEnum = PropertyMetaData;

    @ViewChild('modelImage')
    modelImageElement!: ElementRef;

    @ViewChildren('customPicturesInput')
    customPicturesInputs!: QueryList<ElementRef>;

    // summary
    public anchorTypeAndDiameter!: string;
    public baseMaterial!: string;
    public evaluationServiceTitle!: string;
    public evaluationService!: string;
    public issued!: string;
    public proof!: string;
    public embedmentDepthValue!: string;
    public standOff!: string;
    public anchorImage!: string;

    // specification-text
    public exportReportSpecificationTextInputs!: IExportReportSpecificationTextInput;

    // project-details
    public exportReportProjectDetailsInputs!: IExportReportProjectDetailsInput;

    // comment-and-notes
    public noteLoadCaseResultingAnchorForces!: string;
    public noteTensionLoad!: string;
    public noteShearLoad!: string;
    public noteCombinedTensionAndShearLoad!: string;
    public noteDisplacements!: string;
    public noteInstallationData!: string;

    // section-pictures
    public customPictures: Map<number, { imgUrl: string; imgSize: number }> = new Map<number, { imgUrl: string; imgSize: number }>();
    private customPicturesUploadSize = customPicturesMaxUploadSize;
    public customPicturesChanged = false;
    public customPictureIds!: string[];

    // company-layout
    public exportReportCompanyLayoutInputs!: IExportReportCompanyLayoutComponentInput;

    // trimble-connect
    public exportTrimbleConnectInput!: IExportTrimbleConnectComponentInput;

    constructor(
        public userService: UserService,
        public userSettingsService: UserSettingsService,
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        private browserService: BrowserService,
        private featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private modalService: ModalService,
        private reportTemplateService: ReportTemplateService,
        private codeListService: CodeListService,
        private numberService: NumberService,
        private trimbleConnectService: TrimbleConnectService,
        private calculationServicePE: CalculationServicePE,
        private unitService: UnitService,
        private dateTimeService: DateTimeService,
        private documentService: DocumentService,
        private exportService: ExportService,
        private apiService: ApiService,
        private logger: LoggerService,
        private commonCodeList: CommonCodeListService,
        private tourService: TourService,
        private translationFormatService: TranslationFormatService,
        private sharedEnvironmentData: SharedEnvironmentService
    ) { }

    public get formValid() {
        const exportReportCompanyLayoutValid = this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue != customTemplateId
            || this.exportReportCompanyLayoutInputs.reportEmailValid;

        return exportReportCompanyLayoutValid;
    }

    public get reportDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_Report, this.design.region.id);
    }

    public get displayLoadCombinationDropdown() {
        return this.design.loadCombinations != null
            && this.design.loadCombinations?.length > 1
            && this.design.isLoadCombinationActive;
    }

    public get isLoadCombinationDropdownVisible() {
        // Hong kong FOS3 methods have only one load combination for steel parts and one for anchors.
        // User cannot change load combination for generate report.
        if (this.IsHongKongAnchorCodeDesignMethodGroupLimited) {
            return false;
        }

        return this.displayLoadCombinationDropdown && (this.design.selectedLoadCombinationId == null || !this.design.isCBFEMCalculation);
    }

    public get displayLoadCaseDropdown() {
        return this.design.designType.id == DesignType.Handrail;
    }

    public get isPostAndRailDesign() {
        return this.design.designType.id == DesignType.Handrail || (this.design.showPostAndRailDeflection != null) ? this.design.showPostAndRailDeflection : false;
    }

    public get disableCustomImages() {
        return this.userService.hasfloatingLimitReached || this.userService.hasFreeLicense || this.userService.hasOnlyBasic;
    }

    public get disableTrimbleConnect() {
        return !this.trimbleConnectService.isEnabled;
    }

    public get isExportRisa() {
        return this.modalInstance.input?.isExportRisa ?? false;
    }

    public get formatedReportType() {
        return this.formatReportType(this.templateReportType());
    }

    public get formatedPaperSize() {
        const paperSize = this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize;
        return paperSize ? this.formatPaperSize(paperSize) : undefined;
    }

    private get IsHongKongAnchorCodeDesignMethodGroupLimited() {
        const hongKongDesignMethodGroupLimited = [DesignMethodGroup.ETAG_001_FOS3, DesignMethodGroup.EN_AnchorDesign_FOS3];

        return this.design.designType.id == DesignType.Concrete &&
            this.design.designStandard.id == DesignStandardEnum.HK &&
            hongKongDesignMethodGroupLimited.includes(this.design.designMethodGroup?.id ?? 0);
    }

    private get design() {
        return this.userService.design;
    }

    private get customPicturesInput1() {
        return this.customPicturesInputs.get(0)?.nativeElement as HTMLInputElement;
    }

    private get customPicturesInput2() {
        return this.customPicturesInputs.get(1)?.nativeElement as HTMLInputElement;
    }

    private get customPicturesInput3() {
        return this.customPicturesInputs.get(2)?.nativeElement as HTMLInputElement;
    }

    private get includeAdvanceBaseplateVisible() {
        return this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation;
    }

    private get includeAdvanceBaseplateDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_Report, this.design.region.id) ||
            (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId && this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue == ReportType.Short) ||
            (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue != customTemplateId && this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType == ReportType.Short);
    }

    private get includeAdvancedBaseplateInReport() {
        return (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) &&
            ((this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId && this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue != ReportType.Short) ||
                (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue != customTemplateId && this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType != ReportType.Short));
    }

    private get reportTemplateDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Application_ReportTemplate, this.design.region.id);
    }

    public get reportTypes(): ReportTypeCodeList[] {
        return this.commonCodeList.commonCodeLists[CommonCodeList.ReportType] as ReportTypeCodeList[];
    }

    private get languages() {
        const languages = (this.commonCodeList.commonCodeLists[CommonCodeList.Language] as Language[]).filter(language => language.culture != LanguageCulture.pseudoLanguage);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return sortBy(this.allowedValues(languages, this.design.properties.get(PropertyMetaData.Option_ReportLanguageLcid.id).allowedValues), (reportLanguage) => (reportLanguage.getTranslatedNameText(codeListDeps) || '').toLowerCase());
    }

    private get reportPaperSizes() {
        const reportPaperSizes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSize[];
        return this.allowedValues(reportPaperSizes, this.design.properties.get(PropertyMetaData.Option_ReportPaperSizeId.id).allowedValues);
    }

    private get isStressDistributionDisabled() {
        return this.isPropertyDisabled(PropertyMetaData.AnchorPlate_ShowStressDistribution.id);
    }

    private get decisiveLoadCombination() {
        return (this.design.designData.reportData?.Combination?.Decisive?.Value ?? 0) > 100 || this.design.isAnchorPlateStressNotPossible;
    }

    private get handrailSafetyDesign() {
        return this.exportReportProjectDetailsInputs.includeDetailsInReport?.selectedValues?.has(ReportDetails.HandrailSafetyDesign);
    }

    private get isParallelDesignOptionEnabled() {
        const ret = this.design.designData.reportData?.IsSimultaneousDesignAvailable;
        return ret;
    }

    private get showStressDistribution() {
        return this.design.showStressDistribution != null ? this.design.showStressDistribution : false;
    }

    private get hasLoads() {
        return this.design.designData.reportData?.LoadsLegendType != null && this.design.designData.reportData.LoadsLegendType != LoadsLegendType.None;
    }

    private get hasLoadsInWorstPosition() {
        return this.design.punctualLoadInWorstPosition || this.design.verticalPunctualLoadInWorstPosition;
    }

    private get hasCharacteristicAndSustained() {
        return this.design.designData.reportData?.LoadsLegendType == LoadsLegendType.CharacteristicAndSustained;
    }

    private get reportTemplate() {
        return this.reportTemplateService.templates.find((template) => template.Id === this.design.reportTemplateId);
    }

    private get generalRegionId() {
        return this.userSettingsService.settings.application.general.regionId.value ?? KnownRegion.Unknown;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines-expanded',
            'sprite-lines',
            'sprite-x'
        );

        const specificationText = this.translationFormatService.getLocalizedStringWithTranslationFormat(this.design.designData.reportData?.SpecificationText);
        this.exportReportSpecificationTextInputs = {
            specificationText: specificationText
        } as IExportReportSpecificationTextInput;

        this.exportReportProjectDetailsInputs = {
            designName: this.userService.project.getDisplayName(this.localizationService) + ', ' + this.design.designName,
            fasteningPoint: this.design.reportFateningPoint,
            fasteningPointTitle: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.FasteningPoint'),
            fasteningPointId: 'export-report-fastening-point',
            notes: this.design.reportNotes,
            reportDisabled: this.reportDisabled,
            includeDetailsInReport: {
                items: [],
                title: 'Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeDesign',
                selectedValues: new Set()
            }
        } as IExportReportProjectDetailsInput;

        this.isSectionCollapsed = {
            summary: false,
            specificationText: false,
            commentAndNotes: true,
            layout: true,
            projectDetails: true,
            trimbleConnect: true,
            customPicturesAvailable: true
        };

        this.customPicturesUploadSize = customPicturesMaxUploadSize;
        this.customPicturesChanged = false;

        this.customPictureIds = [...(this.design.customPictures ?? [])];

        // model screenshots;
        const previewLoadsVisibilityInfo: LoadsVisibilityInfoPe = {
            designType: this.design.designType.id ?? DesignType.Unknown,
            preview: true,
            modifyLoads: false,
            showInwardsWindLoad: false,
            showLinearInwardsLoad: false,
            showLinearOutwardsLoad: false,
            showOutwardsWindLoad: false,
            showVerticalLoad: false
        };

        // model preview screenshot
        const screenShotSettings: IScreenShotSettingsPe = {
            imgHeight: 250,
            imgWidth: 250,
            zoomed: false,
            preview: true,
            loadsVisibilityInfo: previewLoadsVisibilityInfo
        };

        const imgPreview = new Promise<void>((resolve, reject) => {
            this.createModelImage(screenShotSettings)
                .then((modelImage: string) => {
                    (this.modelImageElement.nativeElement as HTMLImageElement).src = modelImage;
                    resolve();
                })
                .catch((err: any) => {
                    reject(err);
                });
        });

        // report model image should have no connectors
        const resolution = 1000;
        this.createModelImage({
            imgHeight: resolution,
            imgWidth: resolution,
            zoomed: false,
            preview: false,
            loadsVisibilityInfo: previewLoadsVisibilityInfo
        }).then((image) => {
            this.modelImageReport = image ?? '';
        });

        if (this.modalInstance.input?.onModelImagesCreated != null) {
            Promise.all([imgPreview])
                .catch((err: any) => {
                    this.logError(err);
                })
                .finally(this.modalInstance.input.onModelImagesCreated);
        }

        this.exportTrimbleConnectInput = {
            trimbleConnectChecked: false,
            trimbleConnectLocation: this.design.trimbleConnectLocation,
            trimbleConnectReportName: this.design.trimbleConnectReportName,
            trimbleConnectFolderId: this.design.trimbleConnectFolderId,
            trimbleConnectTooltip: this.reportExportDisabledTooltip(),
            isOfflineOnLine: this.browserService.isOfflineOnLine
        } as IExportTrimbleConnectComponentInput;

        this.setCustomPictures();

        this.createTemplate();

        this.setExportReportCompanyLayoutInputs();

        this.setIncludeDetailsItems();

        this.embedmentDepthValue = this.getEmbedmentDepthValue();
        this.setProof();
        this.setIssued();
        this.setEvaluationService();
        this.setAnchorImage();
        this.setBaseMaterial();
        this.setAnchorTypeAndDiameter();
        this.setStandOff();

        this.setInitialCheckboxStates();
    }

    public setExportReportCompanyLayoutInputs() {
        const selectedReportTypeId = this.reportTypes.find(reportType => reportType.id === this.design.reportTypeId)?.id ?? 0;

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const reportTypesItems = this.reportTypes;

        const selectedLanguage = this.languages.find((language) => language.id === this.design.reportLanguageId);

        const selectedReportPaperSize = this.reportPaperSizes.find((reportPaperSize) => reportPaperSize.id === this.design.reportPaperSizeId);

        let selectedItem = this.design.designData.reportData?.DecisiveLoadCombinationIndexHandrail;
        if (this.design.designData.reportData?.DecisiveLoadCombinationIndexHandrail == undefined) {
            selectedItem = this.design.designData.reportData?.DecisiveLoadCombinationIndex;
        }

        const decisiveLoadCombination = this.design.decisiveLoadCombination;

        this.exportReportCompanyLayoutInputs = {
            reportTemplate: this.reportTemplateService.templates.find((template) => template.Id === this.design.reportTemplateId),
            reportTemplateDisabled: this.reportTemplateDisabled,
            isLoadCombinationDropdownVisible: this.isLoadCombinationDropdownVisible,
            displayLoadCaseDropdown: this.displayLoadCaseDropdown,
            isExternalOnlineRussianUser: this.userService.isExternalOnlineRussianUser,
            handrailSafetyDesign: this.handrailSafetyDesign ?? false,
            firstPageNumber: this.design.reportFirstPage != null ? this.design.reportFirstPage : 1,
            reportCompanyName: this.design.reportCompanyName,
            reportAddress: this.design.reportAddress,
            reportContactPerson: this.design.reportContactPerson,
            reportPhoneNumber: this.design.reportPhoneNumber,
            reportEmail: this.design.reportEmail,
            reportTypeDropdown: this.getReportTypeDropdown(reportTypesItems, selectedReportTypeId, 'export-report-layout-section-report-type-dropdown'),
            languageDropdown: {
                id: 'export-report-layout-section-language-dropdown',
                items: this.languages.map((language) => {
                    return {
                        value: language.id,
                        text: language.getTranslatedNameText(codeListDeps)
                    } as DropdownItem<number>;
                }),
                selectedValue: selectedLanguage != null ? selectedLanguage.id : undefined
            },
            paperSizeDropdown: {
                id: 'export-report-layout-section-papersize-dropdown',
                items: this.reportPaperSizes.map((reportPaperSize) => {
                    return {
                        value: reportPaperSize.id,
                        text: reportPaperSize.getTranslatedNameText(codeListDeps)
                    } as DropdownItem<number>;
                }),
                selectedValue: selectedReportPaperSize != null ? selectedReportPaperSize.id : undefined
            },
            loadCombinationDropdown: !this.design.isLoadCombinationActive ? {} : {
                id: 'export-report-load-combination-dropdown',
                tooltip: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.LoadCombination.Tooltip'),
                items: this.design.loadCombinations.map((loadCombination) => {
                    return {
                        value: loadCombination.Id,
                        text: (decisiveLoadCombination === loadCombination ? '* ' : '') + loadCombination.Name
                    } as DropdownItem<string>;
                }),
                selectedValue: this.design.loadCombinations.find((loadCombination) => loadCombination.Id == decisiveLoadCombination?.Id)?.Id
            },
            loadCaseDropdown: {
                id: 'export-report-load-case-dropdown',
                selectedValue: this.design.designData.reportData?.DecisiveLoadCombinationIndex,
                items: this.design.designData.reportData?.LoadCombinationItems?.map((loadCombinationItem) => {
                    return {
                        value: loadCombinationItem.LoadCaseId,
                        text: (loadCombinationItem.IsDecisive ? '* ' : '') + loadCombinationItem.LoadCaseName
                    } as DropdownItem<number>;
                }),
                tooltip: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.LoadCase.Tooltip')
            },
            loadCaseHandrailDropdown: {
                id: 'export-report-load-case-handrail-dropdown',
                selectedValue: selectedItem,
                items: this.design.designData.reportData?.LoadCombinationItems?.map((loadCombinationItem) => {
                    return {
                        value: loadCombinationItem.LoadCaseId,
                        text: (loadCombinationItem.IsDecisiveHandrail ? '* ' : '') + loadCombinationItem.LoadCaseName
                    } as DropdownItem<number>;
                }),
                tooltip: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.LoadCase.Tooltip')
            },
            templateDropdown: {
                id: 'export-report-layout-section-template-dropdown',
                items: [],
                selectedValue: this.reportTemplate != null ? this.reportTemplate.Id : customTemplateId,
            }
        } as IExportReportCompanyLayoutComponentInput;
    }

    public save() {
        const invalidChars = new RegExp(/[\^{}_]/g);
        if ([
            this.noteLoadCaseResultingAnchorForces, this.noteTensionLoad, this.noteShearLoad,
            this.noteCombinedTensionAndShearLoad, this.noteDisplacements, this.noteInstallationData,
            this.exportReportProjectDetailsInputs.notes, this.exportReportProjectDetailsInputs.fasteningPoint
        ].some(s => invalidChars.test(s as string))) {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.Warning'),
                this.localizationService.getString('Agito.Hilti.Profis3.InvalidCharacters.Message')
            );
            return;
        }

        if (this.submitted || !this.formValid) {
            return;
        }

        if (this.customPictures.size > 0 && this.customPicturesChanged) {
            this.uploadPicturesPE()
                .then(response => {
                    if (this.customPictures.size != response.length) {
                        this.modalService.openAlertWarning(
                            this.translate('Agito.Hilti.Profis3.ExportReport.Pictures.UploadIssuesPopup.Title'),
                            this.translate('Agito.Hilti.Profis3.ExportReport.Pictures.UploadIssuesPopup.Body')
                        );
                        return;
                    }

                    this.customPictureIds = response;

                    // Generate report
                    this.generateAndDownloadReport();
                });
        }
        else {
            // Generate report without custom images
            this.generateAndDownloadReport();
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public getArrowClass(isCollapsed: boolean) {
        return isCollapsed ? 'pe-ui-pe-sprite-lines-expanded' : 'pe-ui-pe-sprite-lines';
    }

    public reportExportDisabledTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_Report);
    }

    public templateReportType(): ReportType {
        let reportType: ReportType;

        // Custom report type
        if (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId) {
            const reportTypeDropdownSelection = this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue;
            reportType = reportTypeDropdownSelection as ReportType;
        }
        else {
            // Defined report types
            reportType = this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType ?? ReportType.Detailed;
        }

        return reportType;
    }

    public onReportTypeChange(reportTemplate: ReportTemplateEntity | undefined) {
        this.exportReportCompanyLayoutInputs.reportTemplate = reportTemplate;

        if (this.includeAdvanceBaseplateVisible) {
            const item = this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.find(x => x.id == 'include-advance-baseplate-checkbox');
            if (item != null) {
                item.disabled = this.includeAdvanceBaseplateDisabled;
                item.tooltip = this.getIncludeAdvancedBaseplateTooltip();
            }

            if (this.includeAdvancedBaseplateInReport) {
                this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.AdvanceBaseplate);
            }
            else {
                this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.delete(ReportDetails.AdvanceBaseplate);
            }
        }
        else {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.delete(ReportDetails.AdvanceBaseplate);
        }
    }

    public getIncludeAdvancedBaseplateTooltip() {
        if ((this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId && this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue == ReportType.Short) ||
            (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue != customTemplateId && this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType == ReportType.Short)) {
            return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeAdvancedBaseplate.Disabled.Tooltip');
        }
        else {
            return '';
        }
    }

    public reportTemplateDisabledTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Application_ReportTemplate);
    }

    public formatReportType(reportTypeId: ReportType) {
        const reportTypes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportType] as ReportTypeCodeList[];
        const reportType = reportTypes.find((item) => item.id === reportTypeId);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.ReportTypeFormat').replace('{reportType}', reportType?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public formatPaperSize(paperSizeId: PaperSize) {
        const reportPaperSizes = this.commonCodeList.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSize[];
        const paperSize = reportPaperSizes.find((item) => item.id === paperSizeId);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.PaperSizeFormat').replace('{paperSize}', paperSize?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public close(reportGenerated?: boolean) {
        this.tourService.onCreateReport();
        this.modalInstance.close(reportGenerated);
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // Image creation related functions
    public removeCustomPicture(index: number) {
        if (this.submitted) {
            return;
        }
        if (this.customPictures.has(index)) {
            this.customPicturesUploadSize += this.customPictures?.get(index)?.imgSize ?? 0;
            this.customPictures.delete(index);
            this.customPicturesChanged = true;
        }

        if (this.customPictures.size == 0) {
            this.customPictureIds = [];
        }
    }

    public customPictureSelected(index: number) {
        switch (index) {
            case 1: {
                if (this.customPicturesInput1.value != null && this.customPicturesInput1.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput1.files as FileList)[0], index);
                    this.customPicturesInput1.value = '';
                }
                break;
            }
            case 2: {
                if (this.customPicturesInput2.value != null && this.customPicturesInput2.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput2.files as FileList)[0], index);
                    this.customPicturesInput2.value = '';
                }
                break;
            }
            case 3: {
                if (this.customPicturesInput3.value != null && this.customPicturesInput3.value != '') {
                    this.uploadCustomPicture((this.customPicturesInput3.files as FileList)[0], index);
                    this.customPicturesInput3.value = '';
                }
                break;
            }
        }
    }

    public selectCustomPicture(index: number) {
        switch (index) {
            case 1: {
                if (this.customPicturesInput1.value == null || this.customPicturesInput1.value == '') {
                    this.customPicturesInput1.click();
                }
                break;
            }
            case 2: {
                if (this.customPicturesInput2.value == null || this.customPicturesInput2.value == '') {
                    this.customPicturesInput2.click();
                }
                break;
            }
            case 3: {
                if (this.customPicturesInput3.value == null || this.customPicturesInput3.value == '') {
                    this.customPicturesInput3.click();
                }
            }
        }
    }

    private uploadCustomPicture(file: File, index: number) {
        if (file.type != 'image/png' && file.type != 'image/jpeg') {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Message')
            );
            return;
        }
        if (file.size > this.customPicturesUploadSize) {
            if (this.customPictures.size < 1) {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FileTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FileTooLargeAlert.Message')
                );
                return;
            }
            else {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FilesTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FilesTooLargeAlert.Message')
                );
                return;
            }
        }

        const reader = new FileReader();

        // ngZone.run() as replacement for this.$scope.$apply in angularJS
        reader.addEventListener('load', () => {
            const img: HTMLImageElement = new ((window as any).Image)();
            img.src = reader.result as string;
            this.ngZone.run(() => {
                img.addEventListener('load', () => {
                    this.ngZone.run(() => {
                        if (img.naturalWidth > 1200 || img.naturalHeight > 800) {
                            this.modalService.openAlertWarning(
                                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Title'),
                                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Message')
                            );
                            return;
                        }
                        this.customPicturesUploadSize -= file.size;
                        this.customPictures.set(index, { imgUrl: img.src, imgSize: file.size });
                        this.customPicturesChanged = true;
                    });
                }, false);
            });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////

    private logError(error: any) {
        if (error instanceof Error) {
            console.error(error);
        }
    }

    private async createModelImage(extraInfo: IScreenShotSettingsPe) {
        const screen = await this.modalInstance.input?.createGlModelScreenshot(extraInfo);
        return screen as unknown as string;
    }

    private setCustomPictures(): void {
        this.calculationServicePE.getCustomImages(this.design.customPictures)
            .then(images => {
                for (let i = 0; i < images.length; i++) {
                    const image = images[i];
                    const imageSize = this.getImageSize(image);
                    this.customPictures.set(i + 1, { imgUrl: image, imgSize: imageSize });
                }
            });
    }
    private getImageSize(value: string): number {
        // The size of the decoded data can be approximated with this formula.
        return 4 * Math.ceil(((value.length - 'data:image/png;base64,'.length) / 3)) * 0.5624896334383812;
    }

    private setIncludeDetailsItems(): void {
        if (this.includeAdvanceBaseplateVisible) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push(
                {
                    id: 'include-advance-baseplate-checkbox',
                    text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeAdvancedBaseplate'),
                    value: ReportDetails.AdvanceBaseplate,
                    tooltip: this.getIncludeAdvancedBaseplateTooltip(),
                    disabled: this.includeAdvanceBaseplateDisabled
                }
            );
        }

        if (this.isPropertyVisible(this.propertyMetaDataEnum.Profile_ShowWeldDesign.id)) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push(
                {
                    id: 'include-weld-design-checkbox',
                    text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeWeldDesign'),
                    value: ReportDetails.WeldDesign,
                    tooltip: this.reportExportDisabledTooltip(),
                    disabled: this.isPropertyDisabled(this.propertyMetaDataEnum.Profile_ShowWeldDesign.id)
                });
        }

        if (this.isPropertyVisible(this.propertyMetaDataEnum.AnchorPlate_ShowStressDistribution.id)) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push(
                {
                    id: 'include-anchor-plate-design-checkbox',
                    text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeAnchorPlateDesign'),
                    value: ReportDetails.AnchorPlateDesign,
                    tooltip: this.reportExportDisabledTooltip(),
                    disabled: this.isStressDistributionDisabled || this.decisiveLoadCombination
                }
            );
        }

        if (this.isPropertyVisible(this.propertyMetaDataEnum.Profile_HandrailSafetyDesign.id)) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push(
                {
                    id: 'handrail-safety-design-checkbox',
                    text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.HandrailSafetyDesign'),
                    value: ReportDetails.HandrailSafetyDesign,
                    tooltip: this.reportExportDisabledTooltip(),
                    disabled: this.isPropertyDisabled(this.propertyMetaDataEnum.Profile_HandrailSafetyDesign.id) || !this.isPostAndRailDesign
                }
            );
        }

        this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push({
            id: 'export-report-details-section-item-number-checkbox',
            text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeItemNumber'),
            value: ReportDetails.IncludeItemNumbers,
            tooltip: this.reportExportDisabledTooltip()
        });

        this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push(
            {
                id: 'include-parallel-design-checkbox',
                text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeParallelDesign'),
                value: ReportDetails.ParallelDesign,
                disabled: !this.isParallelDesignOptionEnabled,
                infoIcon: InfoIcon.Normal,
                tooltipTarget: TooltipTarget.InfoIcon,
                tooltip: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeParallelDesign.Tooltip')
            }
        );
    }

    private createTemplate() {
        this.noteLoadCaseResultingAnchorForces = this.design.noteLoadCaseResultingAnchorForces != null ? this.design.noteLoadCaseResultingAnchorForces : '';
        this.noteTensionLoad = this.design.noteTensionLoad != null ? this.design.noteTensionLoad : '';
        this.noteShearLoad = this.design.noteShearLoad != null ? this.design.noteShearLoad : '';
        this.noteCombinedTensionAndShearLoad = this.design.noteCombinedTensionAndShearLoad != null ? this.design.noteCombinedTensionAndShearLoad : '';
        this.noteDisplacements = this.design.noteDisplacements != null ? this.design.noteDisplacements : '';
        this.noteInstallationData = this.design.noteInstallationData != null ? this.design.noteInstallationData : '';
    }

    private getReportTypeDropdown(items: CodeList[], selectedValue: number, id: string): DropdownProps<number> {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        return {
            id,
            items: items.map(item => {
                return {
                    value: item.id as number,
                    text: item.getTranslatedNameText(codeListDeps) as string
                };
            }),
            selectedValue
        };
    }

    private allowedValues<TType extends CodeList>(codeList: TType[], allowedValues?: number[]) {
        if (allowedValues) {
            return codeList.filter(codeListItem => allowedValues.includes(codeListItem.id as number));
        }

        return codeList;
    }

    private isPropertyVisible(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? !propertyInfo.hidden : true;
    }

    private isPropertyDisabled(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.disabled : true;
    }

    private getEmbedmentDepthValue(): string {
        const effectiveEmbedmentDepth: number = (this.design.designData.reportData?.CalculatedEmbedmentDepth || 0);
        const nominalEmbedmentDepthValue = this.design.designData.reportData?.NominalEmbedmentDepth;

        let embedmentDepthString = this.getValueWithLengthFormat(effectiveEmbedmentDepth, ValueWithLengthFormatMode.Hef);
        if (nominalEmbedmentDepthValue != null) {
            embedmentDepthString = `${embedmentDepthString}, ${this.getValueWithLengthFormat(nominalEmbedmentDepthValue, ValueWithLengthFormatMode.HNom)}`;
        }

        return embedmentDepthString;
    }

    private getValueWithLengthFormat(value: number, mode = ValueWithLengthFormatMode.None) {
        const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);
        const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);
        const defaultUnitValue = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit);
        const precision = this.unitService.getPrecision(defaultUnit);
        const formatedValue = this.unitService.formatUnitValueArgs(defaultUnitValue, defaultUnit, precision);

        switch (mode) {
            case ValueWithLengthFormatMode.Hef:
                return `h_ef = ${formatedValue}`;

            case ValueWithLengthFormatMode.HNom:
                return `h_nom = ${formatedValue}`;

            default:
                break;
        }

        return formatedValue;
    }

    private setProof() {
        if (this.design.designStandard.id == DesignStandardEnum.SATS && this.design.designData.reportData?.DesignMethodName) {
            this.proof = `${this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Summary.Proof.DesignMethod')} ${this.design.designData.reportData.DesignMethodName} ${this.translate('Agito.Hilti.Profis3.ExportReport.Summary.Proof.SATS.Extension')}`;
        }
        else {
            this.proof = `${this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Summary.Proof.DesignMethod')} ${this.design.designData.reportData?.DesignMethodName || '-'}`;
        }
    }

    private getRegionById(regionId: number) {
        const regionCodeList = this.codeListService.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private setIssued() {
        const region = this.getRegionById(this.generalRegionId);
        const culture = this.setCulture(region);
        let issued = '';
        let valid = '';

        let approval = this.design.designData.reportData?.Approval;

        if (DesignMethodGroupHelper.isRussianSofaOrSp63(this.design.designStandard.id ?? DesignStandardEnum.Unknown, this.design.designMethodGroup?.id ?? DesignMethodGroup.Unknown)) {
            approval = this.design.designData.reportData?.ApprovalSTO;
        }
        else if (
            this.design.region.id == KnownRegion.UnitedKingdom
            && this.design.designData.reportData?.ApprovalUKTA.Number != ''
        ) {
            approval = this.design.designData.reportData?.ApprovalUKTA;
        }

        if (approval?.Issued != null) {
            issued = moment(approval.Issued).locale(culture).format(this.dateTimeService.dotNetToMomentFormat(region?.dateTimeFormat?.ShortDatePattern as string));
        }

        if (approval?.Valid != null) {
            valid = moment(approval.Valid).locale(culture).format(this.dateTimeService.dotNetToMomentFormat(region?.dateTimeFormat?.ShortDatePattern as string));
        }

        const separator = issued != '' && valid != '' ? ' - ' : '';
        const issuedValid = issued + separator + valid;
        this.issued = issuedValid != '' ? issuedValid : '-';
    }

    private setCulture(region: Region | undefined) {
        if(region?.id == KnownRegion.Bahrain
            || region?.id == KnownRegion.Qatar
            || region?.id == KnownRegion.Oman) {
                const culture = 'ar-date-format';
                // locale/ar-*.js uses preparse and postformat to convert from numbers to arabic characters.
                // This defines new locale that restores default behavior
                // and does not convert numbers to arabic characters.
                moment.defineLocale(culture, {
                    // This has to be set to 'ar' to use the correct locale settings. Using region.culture (ar-BH, ar-QA, ar-OM) will not work.
                    parentLocale: 'ar',
                    preparse: function (string: string) {
                      return string;
                    },
                    postformat: function (string: string) {
                      return string;
                    }
                });

                return culture;
        }

        return region?.culture as string;
    }

    private setEvaluationService() {
        const approvals: string[] = [];

        if (this.design.designData.reportData.ApprovalSTO?.Number) {
            approvals.push(this.design.designData.reportData?.ApprovalSTO.Number);
        }

        if (this.design.designData.reportData.ApprovalUKTA?.Number) {
            approvals.push(this.design.designData.reportData?.ApprovalUKTA.Number);
        }

        if (this.design.designData.reportData.Approval?.Number) {
            approvals.push(this.design.designData.reportData?.Approval.Number);
        }

        this.evaluationService = approvals.length > 0 ? approvals.join(', ') : '-';


        const designStandard = this.design.designStandard.id;

        let evaluationServiceTitleKey = 'Agito.Hilti.Profis3.ExportReport.Summary.EuropeanTechnicalAssessment';
        if (isHnaBasedDesignStandard(designStandard)) {
            // HNA-based designs: display ESR
            evaluationServiceTitleKey = 'Agito.Hilti.Profis3.ExportReport.Summary.EvaluationService';
        }
        else if (designStandard == DesignStandardEnum.STO || this.design.region.id == KnownRegion.UnitedKingdom) {
            // Russian-based designs or design for UK: display generic text ('Approval')
            evaluationServiceTitleKey = 'Agito.Hilti.Profis3.ExportReport.Summary.Approval';
        }

        this.evaluationServiceTitle = this.localizationService.getString(evaluationServiceTitleKey);
    }

    private setAnchorImage() {
        if (this.design.designType.id == DesignType.Masonry) {
            this.anchorImage = this.design.anchorSize?.image ?? '';

        }
        else {
            const fastenerTypeImage = this.design.anchorFastenerTypeImage != null ? this.design.anchorFastenerTypeImage.image : null;
            const fastenerFamilyImage = (this.design.anchorFastenerFamilyImage != null ? this.design.anchorFastenerFamilyImage.image : null) ?? '';

            this.anchorImage = fastenerTypeImage != null && !fastenerTypeImage.includes('no-photo') ? fastenerTypeImage : fastenerFamilyImage;
        }

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            this.anchorImage as Sprite
        );
    }

    private setBaseMaterial() {
        if (this.design.designType.id == DesignType.Masonry) {
            this.setBaseMaterialMasonry();
        }
        else {
            this.setBaseMaterialOther();
        }
    }

    private setBaseMaterialMasonry() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const brickLayout = this.design.brickLayout != null ? this.design.brickLayout.getTranslatedNameText(codeListDeps) || '' : '';

        let brickGroupName = this.design.brickGroup != null ? this.design.brickGroup.getTranslatedNameText(codeListDeps) || '' : '';
        brickGroupName = brickGroupName != '' ? `, ${brickGroupName}` : '';
        let brickGroupDescription = this.design.brickGroup != null ? this.design.brickGroup.getTranslatedDescriptionText(codeListDeps) || '' : '';
        brickGroupDescription = brickGroupDescription != '' ? ` (${brickGroupDescription})` : '';

        this.baseMaterial = `${brickLayout}${brickGroupName}${brickGroupDescription}`;
    }

    private setBaseMaterialOther() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        const concreteType = this.design.isConcreteTypeSFRC
            ? this.translate('Agito.Hilti.Profis3.ExportReport.Summary.BaseMaterial.ConcreteType.SteelFiberReinforcedConcrete')
            : '';
        const concreteCharacteristic = this.design.concreteCharacteristic != null
            ? this.design.concreteCharacteristic.getTranslatedNameText(codeListDeps) || ''
            : '';
        let concreteGrade = this.design.concreteGrade != null
            ? this.design.concreteGrade.getTranslatedNameText(codeListDeps) || ''
            : '';
        if (concreteGrade == '') {
            const unitGroup = UnitGroup.Stress;
            const defaultUnit = this.unitService.getDefaultUnit(unitGroup);
            const value = this.unitService.convertInternalValueToDefaultValue(this.design.customCompressiveStrength ?? 0, unitGroup);
            concreteGrade = this.unitService.formatUnitValueArgs(value, defaultUnit);
        }

        const baseMaterial = [concreteType, concreteCharacteristic, concreteGrade].filter(x => x != '').join(', ');

        this.baseMaterial = baseMaterial != ''
            ? baseMaterial
            : '-';
    }

    private setAnchorTypeAndDiameter() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const anchorType = this.design.anchorType != null ? this.design.anchorType.getTranslatedNameText(codeListDeps) || '' : '';
        const anchorSize = this.design.anchorSize != null ? this.design.anchorSize.getTranslatedNameText(codeListDeps) || '' : '';
        const anchorTypeAndDiameter = (anchorType + (anchorSize != '' ? ` ${anchorSize}` : '')).trim();

        this.anchorTypeAndDiameter = anchorTypeAndDiameter != '' ? anchorTypeAndDiameter : '-';
    }

    private setStandOff() {
        const unitGroup = UnitGroup.Length;
        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);
        const value = this.unitService.convertInternalValueToDefaultValue(this.design.standOffDistance ?? 0, unitGroup);
        const unitValue = this.unitService.formatUnitValueArgs(value, defaultUnit);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        this.standOff = `eb=${unitValue} (${this.design.standOffNoneIfNull?.getTranslatedNameText(codeListDeps) || ''})`;
    }

    private setInitialCheckboxStates() {
        this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.IncludeItemNumbers);

        if (this.includeAdvancedBaseplateInReport) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.AdvanceBaseplate);
        }

        if (this.design.showWeldDesign) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.WeldDesign);
        }

        if (!this.decisiveLoadCombination &&
            (this.showStressDistribution || this.design.showOptimizedAnchorPlateThickness)
        ) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.AnchorPlateDesign);
        }

        if (this.isPostAndRailDesign) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.HandrailSafetyDesign as number);
        }

        if (this.isParallelDesignOptionEnabled && DesignPe.getRegionPe(this.design).includeParallelDesignInReport) {
            this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.add(ReportDetails.ParallelDesign);
        }

        this.exportTrimbleConnectInput.trimbleConnectChecked = this.design.trimbleConnectUpload;
    }

    private uploadPicturesPE(): Promise<string[]> {
        return this.calculationServicePE.uploadImages(this.design.id, this.getDesignCustomPictures());
    }

    private getDesignCustomPictures(): string[] {
        const map: string[] = [];
        for (const [key, value] of this.customPictures) {
            map[key - 1] = value.imgUrl;
        }
        return map;
    }

    private generateAndDownloadReport() {
        this.submitted = true;
        let trimbleConnectSession: ITrimbleConnectSession;

        // ensure trimble connect session if needed
        const emptyTrimbleConnectSessionPromise = new Promise<ITrimbleConnectSession>((resolve) => {
            resolve(trimbleConnectSession);
        });

        const trimbleConnectSessionPromise = this.exportTrimbleConnectInput.trimbleConnectChecked && this.browserService.isOfflineOnLine
            ? this.trimbleConnectService.ensureSession() ?? emptyTrimbleConnectSessionPromise
            : emptyTrimbleConnectSessionPromise;

        trimbleConnectSessionPromise
            .then(async (trimbleConnectSession: any) => {

                let combination: LoadCombination | undefined;
                if (this.design.designType.id == DesignType.Handrail) {
                    combination = this.design.loadCombinations[this.exportReportCompanyLayoutInputs.loadCaseDropdown.selectedValue as number];
                }
                else if (this.design.isLoadCombinationActive && this.displayLoadCombinationDropdown) {
                    combination = this.design.loadCombinations.find((loadCombination) => loadCombination.Id == this.exportReportCompanyLayoutInputs.loadCombinationDropdown.selectedValue);
                    // combination = this.loadCombinationDropdown.selectedValue as LoadCombination;
                }

                const loadsVisibilityInfo = this.getLoadsVisibilityInfo(combination);
                const handrailLoadCaseIdHandrail = this.includeDetailsInReportHas(ReportDetails.HandrailSafetyDesign)
                    ? this.exportReportCompanyLayoutInputs.loadCaseHandrailDropdown.selectedValue
                    : null;

                const screenShotPromises = this.getScreenShotPromises(loadsVisibilityInfo);

                if (this.modalInstance.input?.onModelImagesCreated != null) {
                    await this.generateAndDownloadReport_ModalImages(screenShotPromises, handrailLoadCaseIdHandrail, trimbleConnectSession);
                }

                return undefined;
            })
            .catch((err: any) => {
                this.logError(err);

                // trimble connect was canceled so we unlock the form
                this.submitted = false;
            });
    }

    private getLoadsVisibilityInfo(combination?: LoadCombination) {
        const loadCaseId: string = (this.design.designType.id == DesignType.Handrail) ? (this.design.designData.reportData?.LoadCombinationItems[this.exportReportCompanyLayoutInputs.loadCaseDropdown.selectedValue ?? 0].Id ?? '') : '';
        const modifyLoads: boolean = (this.design.designType.id == DesignType.Handrail) ? !this.design.showAllLoads : true;

        return (combination)
            ? {
                designType: this.design.designType.id,
                preview: false,
                modifyLoads,
                forceX: combination.ForceX,
                forceY: combination.ForceY,
                forceZ: combination.ForceZ,
                momentX: combination.MomentX,
                momentY: combination.MomentY,
                momentZ: combination.MomentZ,
                ...calculateLoadsVisibilityInfo(loadCaseId)
            } as LoadsVisibilityInfoPe
            : {
                designType: this.design.designType.id,
                preview: false,
                modifyLoads: false,
                showInwardsWindLoad: false,
                showLinearInwardsLoad: false,
                showLinearOutwardsLoad: false,
                showOutwardsWindLoad: false,
                showVerticalLoad: false
            } as LoadsVisibilityInfoPe;
    }

    private getScreenShotPromises(loadsVisibilityInfo: LoadsVisibilityInfoPe) {
        // createModelImage is slow so we delay the image creation
        const screenShotPromises: Promise<string>[] = [];
        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        screenShotPromises.push(delay(300)
            .then(() => this.createModelImage({ zoomed: false, loadsVisibilityInfo })));
        screenShotPromises.push(delay(300)
            .then(() => this.createModelImage({ zoomed: true, loadsVisibilityInfo })));
        screenShotPromises.push(delay(300)
            .then(() => this.createModelImage({ isThumbnail: true, imgHeight: thumbnailSize, imgWidth: thumbnailSize, zoomed: false, preview: false, loadsVisibilityInfo })));

        if (this.design.baseplateDesignData != null) {
            screenShotPromises.push(delay(300)
                .then(() => this.createModelImage({ zoomed: true, advancedBaseplateTexture: TextureDisplay.EquivalentStress, loadsVisibilityInfo: undefined as unknown as LoadsVisibilityInfoPe })));
            screenShotPromises.push(delay(300)
                .then(() => this.createModelImage({ zoomed: true, advancedBaseplateTexture: TextureDisplay.PlasticStrain, loadsVisibilityInfo: undefined as unknown as LoadsVisibilityInfoPe })));
        }

        return screenShotPromises;
    }

    private async generateAndDownloadReport_ModalImages(
        screenShotPromises: Promise<string>[],
        handrailLoadCaseIdHandrail: number | null | undefined,
        trimbleConnectSession: any) {
        const images = await Promise.all(screenShotPromises);
        const modelImage = images[0];
        const modelImageZoomed = images[1];
        const thumbnailImage = images[2];
        const reportLanguage = this.languages.find((language) => language.id === this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue);
        const region = this.getRegionById(this.generalRegionId);
        const stressImage = images.length > 4 ? images[3] : null;
        const strainImage = images.length > 4 ? images[4] : null;
        try {
            await this.generateLegendImage(reportLanguage?.culture ?? '')
                .then((legendImageDataUrl: any) => {
                    this.documentService.uploadDesignImage(this.design.id, modelImage, thumbnailImage);

                    const changes = CalculationServiceBase.changeModel(this.design.model, this.design.modelChanges, this.design.changes, () => this.updateReportSettings());

                    const generateReportdata: GenerateReportRequest = {
                        ImageDataUrl: modelImage,
                        ImageZoomedDataUrl: modelImageZoomed,
                        HandrailLoadCaseId: this.exportReportCompanyLayoutInputs.loadCaseDropdown?.selectedValue as unknown as number,
                        IncludeWeldDesign: this.includeDetailsInReportHas(ReportDetails.WeldDesign),
                        IncludeAnchorPlateDesign: this.includeDetailsInReportHas(ReportDetails.AnchorPlateDesign),
                        HandrailSafetyDesign: this.includeDetailsInReportHas(ReportDetails.HandrailSafetyDesign),
                        HandrailLoadCaseIdHandrail: handrailLoadCaseIdHandrail as unknown as number,
                        HandrailLoadInstance: HandrailHelper.calculateLoadInstance(this.design as unknown as DesignPe),
                        IncludeItemNumber: this.includeDetailsInReportHas(ReportDetails.IncludeItemNumbers),
                        LoadLegendDataUrl: legendImageDataUrl,
                        BaseplateStressImage: stressImage as unknown as string,
                        BaseplateStrainImage: strainImage as unknown as string,
                        IncludeAdvancedBaseplate: this.includeDetailsInReportHas(ReportDetails.AdvanceBaseplate),
                        DateCultureInfo: region?.culture as string,
                        IncludeParallelDesign: this.includeDetailsInReportHas(ReportDetails.ParallelDesign),
                        UserSpecificationText: this.exportReportSpecificationTextInputs.specificationText ?? '',
                        GeneralRegionId: this.generalRegionId
                    };

                    // We download pdf, if export Risa isn't set
                    const downloadPdf = !this.isExportRisa;

                    return this.calculationServicePE.generateAndDownloadReportSignalR(this.design, generateReportdata, Object.values(changes).map(change => change), downloadPdf);
                })
                .then((pdf: any) => {
                    if (trimbleConnectSession != null) {
                        return this.trimbleConnectService.uploadFile(
                            this.exportTrimbleConnectInput.trimbleConnectFolderId,
                            this.exportTrimbleConnectInput.trimbleConnectReportName + '.pdf', pdf);
                    }

                    if (this.modalInstance.input?.isExportRisa) {
                        this.generateAndDownloadReport_ExportRisa(pdf);
                    }
                    return undefined;
                })
                .finally(() => {
                    this.trackGenerateReportDataPE();
                });
            this.close(true);
        }
        catch (error) {
            this.submitted = false;

            throw error;
        }
    }

    private generateAndDownloadReport_ExportRisa(pdf: any) {
        this.exportService.blobToArrayBuffer(pdf).then((arrayBuffer: any) => {
            const content = this.exportService.arrayBufferToByteArray(arrayBuffer);

            // prepare document modal
            const data: UploadDocumentModel = {
                DocumentId: this.design.integrationDocument?.DocumentId as unknown as string,
                DocumentName: this.design.integrationDocument?.DocumentName as unknown as string,
                TransformationDocumentName: this.design.projectName + '_' + this.design.designName + '.pdf',
                IntegrationType: DocumentIntegrationType.Risa,
                Content: content,
                DateCreated: new Date(),
                DateModified: new Date(),
                CustomerOriginId: this.userService.authentication.customerOriginId as unknown as string,
                CountryCode: this.userService.authentication.country as unknown as string
            };

            const url = `${this.sharedEnvironmentData.data?.integrationServicesServerUrl}api/document/uploadTransformationDocument`;

            // make post request and return undefined, when done
            this.apiService.request(new HttpRequest(
                'PUT',
                url,
                data
            ))
                .then((res) => {
                    if (res && res.status == 200) {
                        this.modalService.openConfirmChange({
                            id: 'integrations-pe-file-upload-sucessful',
                            title: this.localizationService.getString('Agito.Hilti.Profis3.Risa.Export.Sucess.Title'),
                            message: this.localizationService.getString('Agito.Hilti.Profis3.Risa.Export.Sucess.Description'),
                            confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Risa.Export.Sucess.Dismiss'),
                            onConfirm: (modal) => {
                                modal.close();
                            }
                        });
                    }
                    else {
                        this.logger.log(
                            'error occurred exporting document to RISA.',
                            LogType.error);
                    }
                })
                .catch((err) => {
                    console.error(err);

                    this.logger.log(
                        'Failed to upload integration report',
                        LogType.error);
                });
        });
    }

    private includeDetailsInReportHas(value: ReportDetails) {
        return this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(value) ?? false;
    }

    private generateLegendImage(culture: string) {
        if (!this.hasLoads && !this.hasLoadsInWorstPosition) {
            return Promise.resolve('');
        }

        const keys = [];

        if (this.hasLoads) {
            keys.push(this.getLoadKey(labelKeyStaticLoads));
            keys.push(this.getLoadKey(labelKeyDynamicLoads));
            keys.push(labelKeyDynamicSustainedLoads);
        }

        if (this.hasLoadsInWorstPosition) {
            keys.push(labelKeyLoadsInWorstPosition);
        }

        return this.localizationService.selectTranslations(culture, keys).then(() => {
            const tempCanvas = document.createElement('canvas');
            const context = tempCanvas.getContext('2d') as CanvasRenderingContext2D;

            context.font = '22px hilti, Arial, sans-serif';

            // Get a length of the longest label in pixels
            const text = [this.staticLoads(culture), this.dynamicLoads(culture), this.loadsInWorstPosition(culture)];
            if (this.hasCharacteristicAndSustained) {
                text.push(this.sustainedTranslation(culture));
            }

            // Set canvas dimensions
            tempCanvas.width = Math.max(...text.map(t => context.measureText(t as string).width)) + 50;
            tempCanvas.height = 180; // if not large enough, part of image might be missing

            context.font = '22px hilti, Arial, sans-serif';

            switch (this.design.designType.id) {
                case DesignType.Concrete:
                case DesignType.Masonry:
                case DesignType.MetalDeck:
                    context.scale(1, 1);
                    break;
                case DesignType.Handrail:
                    context.scale(0.7, 0.7);
                    break;
                default:
                    throw new Error('Design type not supported.');
            }

            return this.generateLegendImages(tempCanvas, context, culture);
        });
    }

    private generateLegendImages(tempCanvas: HTMLCanvasElement, context: CanvasRenderingContext2D, culture: string) {
        const images: IImages<string> = {
            static: loadIconImage,
            variable: loadIconBlueImage,
            sustained: this.hasCharacteristicAndSustained ? loadIconOrangeImage : null as unknown as string,
            worst: loadIconLoadInWorstPositionImage
        };

        // Load required images, returns promise when done.
        const imagesObj = (Object.entries(images) as [keyof IImages<string>, string][]).reduce<IImages<IImgPromiseObj>>((a, [k, v]) => {
            if (!v) return a;

            const el: HTMLImageElement = new window['Image']();
            const d = new Promise<void>((resolve) => {
                el.src = v;
                el.onload = () => resolve();
            });

            a[k] = {
                image: el,
                promise: d
            };

            return a;
        }, {} as IImages<IImgPromiseObj>);


        return Promise.all(Object.values(imagesObj).map((i: IImgPromiseObj) => i.promise)).then(() => {
            const offsetY = 54; // dont know why 54, but it looks ok
            let positionY = 0;

            if (this.hasLoads) {
                context.drawImage(imagesObj.static.image, -imagesObj.static.image.width / 2, positionY, imagesObj.static.image.width * 2, imagesObj.static.image.height * 2);
                context.fillText(this.staticLoads(culture) ?? '', imagesObj.static.image.width + 5, positionY + 43);
                positionY += offsetY;

                context.drawImage(imagesObj.variable.image, -imagesObj.variable.image.width / 2, positionY, imagesObj.variable.image.width * 2, imagesObj.variable.image.height * 2);
                context.fillText(this.dynamicLoads(culture) ?? '', imagesObj.variable.image.width + 5, positionY + 43);
                positionY += offsetY;
            }

            if (this.hasCharacteristicAndSustained) {
                context.drawImage(imagesObj.sustained.image, -imagesObj.sustained.image.width / 2, positionY, imagesObj.sustained.image.width * 2, imagesObj.sustained.image.height * 2);
                context.fillText(this.sustainedTranslation(culture), imagesObj.sustained.image.width + 5, positionY + 43);
                positionY += offsetY;
            }

            if (this.hasLoadsInWorstPosition) {
                context.drawImage(imagesObj.worst.image, -imagesObj.worst.image.width / 2, positionY, imagesObj.worst.image.width * 2, imagesObj.worst.image.height * 2);
                context.fillText(this.loadsInWorstPosition(culture) ?? '', imagesObj.worst.image.width + 5, positionY + 43);
            }

            const url = tempCanvas.toDataURL('image/png');
            tempCanvas.remove();
            return Promise.resolve(url);
        });
    }

    private getLoadKey(key: string) {
        switch (this.design.designData.reportData?.LoadsLegendType) {
            case LoadsLegendType.CharacteristicAndSustained:
            case LoadsLegendType.Characteristic:
                key += '.Characteristic';
                break;

            case LoadsLegendType.Fatigue:
                key += '.Fatigue';
                break;

            case LoadsLegendType.Sustained:
                key += '.Sustained';
                break;

            case LoadsLegendType.None:
            case null:
            case undefined:
                return '';

            default:
                throw new Error('Unknown LoadsLegendType.');
        }

        return key;
    }

    private updateReportSettings() {
        // trimble connect BL
        this.design.trimbleConnectUpload = this.exportTrimbleConnectInput.trimbleConnectChecked;

        if (this.design.trimbleConnectUpload) {
            this.design.trimbleConnectFolderId = this.exportTrimbleConnectInput.trimbleConnectFolderId;
            this.design.trimbleConnectLocation = this.exportTrimbleConnectInput.trimbleConnectLocation;
            this.design.trimbleConnectReportName = this.exportTrimbleConnectInput.trimbleConnectReportName;
        }

        this.design.reportNotes = this.exportReportProjectDetailsInputs.notes;
        this.design.reportFateningPoint = this.exportReportProjectDetailsInputs.fasteningPoint;

        // template
        this.updateReportSettingsForTemplate();

        this.design.customPictures = [...this.customPictureIds];

        if (this.design.isLoadCombinationActive && this.displayLoadCombinationDropdown) {
            this.design.reportLoadCombination = this.exportReportCompanyLayoutInputs.loadCombinationDropdown.selectedValue as string;
        }
    }

    private updateReportSettingsForTemplate() {
        if (this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue == customTemplateId) {
            this.design.reportTemplateId = 0;
            this.design.reportTypeId = this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue as number;
            this.design.reportLanguageId = this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number;
            this.design.reportPaperSizeId = this.exportReportCompanyLayoutInputs.paperSizeDropdown.selectedValue as number;
            this.design.reportFirstPage = this.exportReportCompanyLayoutInputs.firstPageNumber;
            this.design.noteLoadCaseResultingAnchorForces = this.noteLoadCaseResultingAnchorForces;
            this.design.noteTensionLoad = this.noteTensionLoad;
            this.design.noteShearLoad = this.noteShearLoad;
            this.design.noteCombinedTensionAndShearLoad = this.noteCombinedTensionAndShearLoad;
            this.design.noteDisplacements = this.noteDisplacements;
            this.design.noteInstallationData = this.noteInstallationData;
            this.design.reportCompanyName = this.exportReportCompanyLayoutInputs.reportCompanyName;
            this.design.reportAddress = this.exportReportCompanyLayoutInputs.reportAddress;
            this.design.reportContactPerson = this.exportReportCompanyLayoutInputs.reportContactPerson;
            this.design.reportPhoneNumber = this.exportReportCompanyLayoutInputs.reportPhoneNumber;
            this.design.reportEmail = this.exportReportCompanyLayoutInputs.reportEmail;
        }
        else {
            this.design.reportTemplateId = this.exportReportCompanyLayoutInputs.reportTemplate?.Id as number;
            if (this.userService.isExternalOnlineRussianUser) {
                if (this.userSettingsService.ccmsUserSettings != null) {
                    this.design.reportCompanyName = this.userSettingsService.ccmsUserSettings.CompanyName ?? '';
                    this.design.reportAddress = this.userSettingsService.ccmsUserSettings.Address ?? '';
                    this.design.reportContactPerson = this.userSettingsService.ccmsUserSettings.FullName ?? '';
                    this.design.reportPhoneNumber = this.userSettingsService.ccmsUserSettings.Phone ?? '';
                    this.design.reportEmail = this.userSettingsService.ccmsUserSettings.EmailAddress ?? '';
                }
            }
            else {
                this.design.reportCompanyName = this.exportReportCompanyLayoutInputs.reportTemplate?.Company ?? '';
                this.design.reportAddress = this.exportReportCompanyLayoutInputs.reportTemplate?.Address ?? '';
                this.design.reportContactPerson = this.exportReportCompanyLayoutInputs.reportTemplate?.ContactPerson ?? '';
                this.design.reportPhoneNumber = this.exportReportCompanyLayoutInputs.reportTemplate?.PhoneNumber ?? '';
                this.design.reportEmail = this.exportReportCompanyLayoutInputs.reportTemplate?.Email ?? '';
            }
            this.design.reportTypeId = this.templateReportType();
            this.design.reportLanguageId = this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number;
            this.design.reportPaperSizeId = this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize ?? PaperSize.A4;
            this.design.reportFirstPage = this.exportReportCompanyLayoutInputs.firstPageNumber;
            this.design.noteLoadCaseResultingAnchorForces = this.noteLoadCaseResultingAnchorForces;
            this.design.noteTensionLoad = this.noteTensionLoad;
            this.design.noteShearLoad = this.noteShearLoad;
            this.design.noteCombinedTensionAndShearLoad = this.noteCombinedTensionAndShearLoad;
            this.design.noteDisplacements = this.noteDisplacements;
            this.design.noteInstallationData = this.noteInstallationData;
        }
    }

    private dynamicLoads(culture: string) {
        if (this.hasLoads) {
            const key = this.getLoadKey(labelKeyDynamicLoads);
            return key != null ? this.localizationService.getLocalizedStringByCulture(key, culture) : null;
        }

        return null;
    }

    private staticLoads(culture: string) {
        if (this.hasLoads) {
            const key = this.getLoadKey(labelKeyStaticLoads);
            return key != null ? this.localizationService.getLocalizedStringByCulture(key, culture) : null;
        }

        return null;
    }

    private loadsInWorstPosition(culture: string) {
        if (this.hasLoadsInWorstPosition) {
            return this.localizationService.getLocalizedStringByCulture('Agito.Hilti.Profis3.Legend.LoadsInWorstPosition', culture);
        }

        return null;
    }

    private trackGenerateReportDataPE() {
        this.design.usageCounter.ReportsCreated++;

        if (this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.WeldDesign)) {
            this.design.usageCounter.ReportCreatedWithWeldDesign++;
        }

        if (this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.IncludeItemNumbers)) {
            this.design.usageCounter.ReportCreatedWithItemNumbers++;
        }

        if (this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.HandrailSafetyDesign)) {
            this.design.usageCounter.ReportCreatedWithHandrailDesign++;
        }

        if (this.exportReportProjectDetailsInputs.includeDetailsInReport.selectedValues?.has(ReportDetails.AnchorPlateDesign)) {
            this.design.usageCounter.ReportCreatedWithBaseplateDesign++;
        }

        if (this.exportReportCompanyLayoutInputs.reportTemplate == null) {
            this.design.usageCounter.ReportCreatedWithCustomTemplate++;
        }
        else if (this.exportReportCompanyLayoutInputs.reportTemplate.IsTemplate) {
            this.design.usageCounter.ReportCreatedWithDefaultTemplate++;
        }
        else {
            this.design.usageCounter.ReportCreatedWithUserTemplate++;
        }

        if ((this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue == ReportType.Short && this.exportReportCompanyLayoutInputs.reportTemplate == null)
            || (this.exportReportCompanyLayoutInputs.reportTemplate != null && this.templateReportType() == ReportType.Short)) {
            this.design.usageCounter.ShortReportsCreated++;
        }

        this.design.usageCounter.UserSpecificationText = this.exportReportSpecificationTextInputs.specificationText;
    }

    private sustainedTranslation(culture: string) {
        return this.localizationService.getLocalizedStringByCulture(labelKeyDynamicSustainedLoads, culture);
    }
}
