<pe-modal-header
    [title]="title"
    [modalInstance]="modalInstance"
    [closeButtonId]="'modal-grid-close-button'"
    [closeButtonDisabled]="preventClose">
</pe-modal-header>

<pe-modal-body>
    <div class="items-wrapper">
        <div class="item-wrapper"
            *ngFor="let item of items; trackBy:identifyItem"
            [ngClass]="'column-number-' + numberOfColumns"
            [ngStyle]="{'width': (100 / numberOfColumns) + '%'}">
            <div class="item modal-grid-button" tabindex="0"
                [class.selected]="item === selectedItem"
                (click)="select(item)">
                <div class="item-container" [ngClass]="'column-number-' + numberOfColumns">
                    <div class="image-container" [ngClass]="'column-number-' + numberOfColumns">
                        <span class="sprite pe-ui-pe-sprite sprite-responsive" [ngClass]="'pe-ui-pe-' + getItemImage(item)"></span>
                    </div>

                    <div class="content-container" [ngClass]="'column-number-' + numberOfColumns">
                        <div class="name-container">
                            <p class="name" [innerHtml]="item.name"></p>
                            <button type="button" class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip kb-tooltip"
                                *ngIf="isKbTooltipEnabled(getItemkbNumberRegion(item))"
                                (click)="openKBLink($event, getItemkbNumberRegion(item))"
                                [ngbTooltip]="translate(getItemkbTooltip(item))"
                            ></button>
                        </div>

                        <p class="description" [innerHtml]="item.description"></p>
                        <p class="sub-description" [innerHtml]="item.subDescription"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer *ngIf="showWarning">
    <span class="text warning" [innerHtml]="warning"></span>
</pe-modal-footer>
