import { Injectable } from '@angular/core';
import {
    ExportService as ExportServiceCommon
} from '@profis-engineering/pe-ui-common/services/export.common';

@Injectable({
    providedIn: 'root'
})
export class ExportService extends ExportServiceCommon {
    // Nothing to do.
}
