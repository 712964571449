import {
    CodeList, ICodeLists
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { LocalizationServiceBase as LocalizationService } from '@profis-engineering/pe-ui-common/services/localization.common';
import { UnitServiceBase as UnitService } from '@profis-engineering/pe-ui-common/services/unit.common';

import { BrickGroup } from '../entities/code-lists/brick-group';
import { DesignCodeList } from '../entities/design-code-list';

export class ModalGridHelper {
    public static createPopupGridItems(codeListItems: CodeList[], mapping: { [property: string]: string | ((codeListItem: CodeList) => string) }) {
        if (codeListItems != undefined) {
            return codeListItems?.map((codeListItem): IModalGridItem<number> => {
                return {
                    value: codeListItem.id,
                    name: this.mapCodeListProperty<string>('translatedNameText', mapping, codeListItem),
                    description: this.mapCodeListProperty<string>('description', mapping, codeListItem),
                    subDescription: this.mapCodeListProperty<string>('subDescription', mapping, codeListItem),
                    image: this.mapCodeListProperty<string>('image', mapping, codeListItem),
                    kbNumberRegion: codeListItem.kbNumberRegion,
                    kbTooltip: codeListItem.kbTooltip
                };
            });
        }

        return null;
    }

    public static getPopupGridItemMapping(localization: LocalizationService, unit: UnitService, codeList: DesignCodeList): { [property: string]: string | ((codeListItem: CodeList) => string) } {
        switch (codeList) {
            case DesignCodeList.BrickGroup:
                return {
                    translatedNameText: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedDescriptionText({ localizationService: localization }) ?? '';
                    },
                    description: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedNameText({ localizationService: localization }) ?? '';
                    },
                    subDescription: (brickGroup: BrickGroup) => {
                        const defaultUnit = unit.getDefaultUnit(UnitGroup.Length);
                        const internalUnit = unit.getInternalUnit(UnitGroup.Length);
                        const precision = unit.getPrecision(defaultUnit);

                        const defaultUnitWidth = unit.convertUnitValueArgsToUnit(brickGroup.width ?? 0, internalUnit, defaultUnit);
                        const defaultUnitHeight = unit.convertUnitValueArgsToUnit(brickGroup.height ?? 0, internalUnit, defaultUnit);
                        const defaultUnitLength = unit.convertUnitValueArgsToUnit(brickGroup.length ?? 0, internalUnit, defaultUnit);

                        return `${unit.formatNumber(defaultUnitWidth, precision)}/${unit.formatNumber(defaultUnitHeight, precision)}/${unit.formatNumber(defaultUnitLength, precision)}`;
                    }
                };
            case DesignCodeList.SeismicDesignType:
            case DesignCodeList.SeismicTensionType:
            case DesignCodeList.SeismicShearType:
                return {
                    description: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedDescriptionText({ localizationService: localization }) ?? '';
                    },
                    translatedNameText: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedNameText({ localizationService: localization }) ?? '';
                    }
                };
            default:
                return {
                    translatedNameText: (codeListItem: CodeList) => {
                        return codeListItem.getTranslatedNameText({ localizationService: localization }) ?? '';
                    }
                };
        }
    }

    public static createPopupGridPartialItems(codeList: DesignCodeList, designCodeLists: ICodeLists, mapping: { [property: string]: string | ((codeListItem: CodeList) => string) }) {
        if (codeList != null) {
            const codeListItems = designCodeLists[codeList];

            return codeListItems?.map((codeListItem): IModalGridItem<number> => ({
                value: codeListItem.id,
                name: this.mapCodeListProperty<string>('translatedNameText', mapping, codeListItem),
                description: this.mapCodeListProperty<string>('description', mapping, codeListItem),
                subDescription: this.mapCodeListProperty<string>('subDescription', mapping, codeListItem),
                image: this.mapCodeListProperty<string>('image', mapping, codeListItem),
                disabled: this.mapCodeListProperty<boolean>('disabled', {}, codeListItem)
            }));
        }

        return null;
    }

    public static getPopupGridPartialItemMapping(localization: LocalizationService, codeList: DesignCodeList): { [property: string]: string | ((codeListItem: CodeList) => string) } {
        if (codeList == DesignCodeList.HandrailApplication) {
            return {
                translatedNameText: (codeListItem: CodeList) => {
                    return codeListItem.getTranslatedDescriptionText({ localizationService: localization }) ?? '';
                },
                description: (codeListItem: CodeList) => {
                    return codeListItem.getTranslatedNameText({ localizationService: localization }) ?? '';
                }
            };
        }

        return {
            translatedNameText: (codeListItem: CodeList) => {
                return codeListItem.getTranslatedNameText({ localizationService: localization }) ?? '';
            }
        };
    }

    private static mapCodeListProperty<TValue>(property: string, mapping: { [property: string]: string | ((codeListItem: CodeList) => TValue) }, codeListItem: CodeList): TValue {
        if (mapping == null) {
            mapping = {};
        }

        const propertyMap = mapping[property];

        if (propertyMap != null && propertyMap != '') {
            if (typeof propertyMap == 'function') {
                return propertyMap(codeListItem);
            }

            return this.getProperty(codeListItem, propertyMap);
        }

        return this.getProperty(codeListItem, property);
    }

    private static getProperty(object: object, property: string) {
        type ObjectKey = keyof typeof object;
        return object[property as ObjectKey];
    }
}
