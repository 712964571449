<pe-modal-header
    [closeButtonId]="'import-loads-close-button'"
    [title]="translate('Agito.Hilti.Profis3.SAP2000Import.Title')"
    [modalInstance]="modalInstance"
    [closeButtonDisabled]="preventClosing">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" novalidate>
    <pe-modal-body>
        <div class="modal-body-content">
            <div class="left-side">
                <!-- Active Model Name -->
                <div class="block">
                    <div class="orientation-column orientation-left">
                        <div class="title-button">
                            <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.SAP2000Import.ActiveModel.Title')"></p>

                            <span class="pe-ui-pe-sprite pe-ui-pe-sprite-refresh-l-dark refresh-detected-nodes"
                                (click)="refreshInstance()"
                                [class.disabled]="submitted"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.SAP2000Import.RefreshDetectedNodes')">
                            </span>
                        </div>

                        <div class="block">
                            <span class="detected-nodes" *ngIf="activeModelName" [innerHtml]="activeModelName"></span>
                        </div>
                    </div>
                </div>

                <!-- Detected Points -->
                <div class="block" *ngIf="detectedNodes.length > 0">
                    <div class="orientation-column orientation-left">
                        <div class="title-button">
                            <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.SAP2000Import.DetectedNodes.Title')"></p>
                        </div>

                        <div class="block">
                            <span class="detected-nodes" [innerHtml]="detectedNodesFormatted"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right-side">
                <div *ngIf="
                         !isCommunicationStateError &&
                         !refreshOverlayVisible &&
                         !areActiveModelCalculationsMissing &&
                         !isActiveModelMissing &&
                         !isActiveObjectMissing">
                    <div class="title-button">
                        <p class="title-strike title">
                            <span class="title-label" [innerHtml]="translate('Agito.Hilti.Profis3.SAP2000Import.ResultCombinationImports')"></span>

                            <button type="button" class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip"
                                (click)="openCombinationImportPopup()"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.SAP2000Import.CombinationImportPopup')">
                            </button>
                        </p>

                        <button type="button" (click)="selectAll()"
                            *ngIf="hasAvailableLoads"
                            class="select-all-loads" [disabled]="isButtonDisabled(true)"
                            [innerHtml]="translate('Agito.Hilti.Profis3.SAP2000Import.SelectAll')">
                        </button>
                    </div>

                    <div class="box">
                        <div class="loads-to-import box-part">
                            <pe-checkbox-button
                                [items]="availableLoadsCheckbox.items"
                                [selectedValues]="availableLoadsCheckbox.selectedValues"
                                (selectedValuesChange)="availableLoadsCheckbox.selectedValues = $any($event).detail"
                                [disabled]="submitted">
                            </pe-checkbox-button>
                        </div>
                    </div>
                </div>

                <div *ngFor="let userNotification of userNotifications" class="refresh-nodes-overlay">
                    <p class="refresh-nodes-overlay-description" [innerHtml]="translate(userNotification.notificationTranslationKey)"></p>

                    <div class="refresh-nodes-overlay-refresh">
                        <pe-button
                            type="button"
                            class="button sap-custom-button"
                            [look]="'Default'"
                            (buttonClick)="refreshInstance()"
                            [disabled]="submitted || refreshingNodes">
                            <pe-loading-button
                                [loading]="submitted || refreshingNodes"
                                [text]="translate(userNotification.buttonTranslationKey)">
                            </pe-loading-button>
                        </pe-button>
                    </div>

                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <div>
            <span class="text" [innerHtml]="translate('Agito.Hilti.Profis3.SAP2000Import.LoadTransformationRule')"></span>
        </div>

        <pe-button
            type="button"
            class="modal-button"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.SAP2000Import.Cancel')"
            [disabled]="preventClosing">
        </pe-button>

        <pe-button
            type="button"
            class="modal-button"
            [look]="'Cta'"
            (buttonClick)="import()"
            [disabled]="(ngForm.enabled && !ngForm.valid) || isButtonDisabled()">
            <pe-loading-button
                [loading]="submitted || refreshingNodes"
                [text]="translate('Agito.Hilti.Profis3.SAP2000Import.Import')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
