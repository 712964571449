import {
    TorquingTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class TorquingType extends CodeList {

    public static fromService(codeList: TorquingTypeEntity) {
        return new TorquingType(CodeList.baseFromService('TorquingTypeEntity', codeList));
    }

    public override cloneDeep(): TorquingType {
        return super.cloneDeep() as TorquingType;
    }
}
