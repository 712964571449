import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { RadioButtonItem } from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { BrickGroup } from '../../../shared/entities/code-lists/brick-group';
import { DesignPe } from '../../../shared/entities/design-pe';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import { UnitType as Unit, UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { DesignMethodGroup, DesignStandard, OnSiteTestType } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './on-site-tests.component.html',
    styleUrls: ['./on-site-tests.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class OnSiteTestsComponent implements OnInit {
    public submitted = false;
    public pendingSave = false;
    public buildingCategoryLoadsRadioItems!: RadioButtonItem<OnSiteTestType>[];

    public onSiteTestType!: OnSiteTestType;
    public readonly OnSiteTestTypePullout = OnSiteTestType.Pullout;
    public readonly OnSiteTestTypeProofLoad = OnSiteTestType.ProofLoad;

    public unitForce!: Unit;
    public requiredValidator = Validators.required;

    public nrk1TextBox!: NumericTextBoxProps;
    public vrk1TextBox!: NumericTextBoxProps;

    public nrk2TextBox!: NumericTextBoxProps;
    public vrk2TextBox!: NumericTextBoxProps;

    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        public localizationService: LocalizationService,
        private userService: UserService,
        private unitService: UnitService,
        private modalService: ModalService,
        private codeListService: CodeListService,
        private calculationService: CalculationServicePE
    ) { }

    public get isEotaTR054() {
        return this.userService.design != null &&
            this.userService.design.designStandard.id == DesignStandard.EOTA &&
            this.userService.design.designMethodGroup?.id == DesignMethodGroup.TR054;
    }

    public get brickGroup() {
        return (this.codeListService.projectCodeLists[ProjectCodeList.BrickGroup] as BrickGroup[])
            .find((brickGroup) => brickGroup.id == this.userService.design.brickGroup?.id);
    }

    public get onSiteTestsNotes() {
        return this.isEotaTR054
            ? 'Agito.Hilti.Profis3.Main.OnSiteTests.Notes.EOTA'
            : 'Agito.Hilti.Profis3.Main.OnSiteTests.Notes';
    }

    public get formValid() {
        return (this.nrk1TextBox?.isValid ?? false)
            && (this.vrk1TextBox?.isValid ?? false)
            && (this.nrk2TextBox?.isValid ?? false)
            && (this.vrk2TextBox?.isValid ?? false);
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        const design = this.userService.design;
        this.onSiteTestType = (design.model[PropertyMetaData.Loads_OnSiteTestType.id] as OnSiteTestType);
        this.unitForce = this.unitService.getDefaultUnit(UnitGroup.Force);

        let valueRange = this.getPropertyValueRange(design, PropertyMetaData.Loads_OnSiteTestsValues_N_Rk.id);
        this.nrk1TextBox = {
            value: 0,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };
        this.nrk2TextBox = {
            value: 0,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        valueRange = this.getPropertyValueRange(design, PropertyMetaData.Loads_OnSiteTestsValues_V_Rk.id);
        this.vrk1TextBox = {
            value: 0,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };
        this.vrk2TextBox = {
            value: 0,
            minValue: valueRange.min,
            maxValue: valueRange.max
        };

        if (this.onSiteTestType == OnSiteTestType.Pullout) {
            this.nrk1TextBox.value = design.NRk;
            this.vrk1TextBox.value = design.VRk;
        }
        else {
            this.nrk2TextBox.value = design.NRk;
            this.vrk2TextBox.value = design.VRk;
        }

        const pulloutTranslationKey = this.isEotaTR054
            ? 'Agito.Hilti.Profis3.Main.OnSiteTests.PulloutTest.EOTA'
            : 'Agito.Hilti.Profis3.Main.OnSiteTests.PulloutTest';

        const proofLoadTranslationkey = this.isEotaTR054
            ? 'Agito.Hilti.Profis3.Main.OnSiteTests.ProofLoadTest.EOTA'
            : 'Agito.Hilti.Profis3.Main.OnSiteTests.ProofLoadTest';

        this.buildingCategoryLoadsRadioItems = [
            {
                value: OnSiteTestType.Pullout,
                text: this.localizationService.getString(pulloutTranslationKey)
            },
            {
                value: OnSiteTestType.ProofLoad,
                text: this.localizationService.getString(proofLoadTranslationkey)
            }
        ];
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public buildingCategoryLoadsRadioSelectedValueChange(selectedValue: OnSiteTestType) {
        this.onSiteTestType = selectedValue;

        if (selectedValue == OnSiteTestType.Pullout) {
            this.nrk1TextBox.value = this.nrk2TextBox.value;
            this.nrk2TextBox.value = 0;
            this.vrk1TextBox.value = this.vrk2TextBox.value;
            this.vrk2TextBox.value = 0;
        }
        else {
            this.nrk2TextBox.value = this.nrk1TextBox.value;
            this.nrk1TextBox.value = 0;
            this.vrk2TextBox.value = this.vrk1TextBox.value;
            this.vrk1TextBox.value = 0;
        }
    }

    public save() {
        const nrk1 = this.nrk1TextBox.value ?? 0;
        const vrk1 = this.vrk1TextBox.value ?? 0;
        const nrk2 = this.nrk2TextBox.value ?? 0;
        const vrk2 = this.vrk2TextBox.value ?? 0;

        if (this.onSiteTestType == OnSiteTestType.Pullout && nrk1 < vrk1
            ||
            this.onSiteTestType == OnSiteTestType.ProofLoad && nrk2 < vrk2
        ) {
            const messageTranslationKey = this.isEotaTR054 ?
                'Agito.Hilti.Profis3.Main.OnSiteTestsNRkVRkValidation.Message.EOTA' :
                'Agito.Hilti.Profis3.Main.OnSiteTestsNRkVRkValidation.Message';

            this.modalService.openConfirmChange({
                id: 'on-site-tests-nrk-vrk-validation',
                title: this.localizationService.getString('Agito.Hilti.Profis3.Main.OnSiteTestsNRkVRkValidation.Title'),
                message: this.localizationService.getString(messageTranslationKey),
                confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Main.OnSiteTestsNRkVRkValidation.Confirm'),
                onConfirm: (modal) => {
                    modal.close();
                }
            });

            return;
        }

        if (this.submitted) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.calculationService.calculateAsync(this.userService.design,
            (design) => {
                this.setDesignData(design);
            },
            { suppressLoadingFlag: true }
        )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    private getPropertyValueRange(design: DesignPe, propertyId: number) {
        const propertyInfo = design.properties.get(propertyId);
        const retVal: IValueRange = {
            min: propertyInfo.min,
            max: propertyInfo.max
        };
        return retVal;
    }

    private setDesignData(design: DesignPe) {
        design.NRk = (this.onSiteTestType == OnSiteTestType.Pullout
            ? this.nrk1TextBox.value
            : this.nrk2TextBox.value) ?? 0;
        design.VRk = (this.onSiteTestType == OnSiteTestType.Pullout
            ? this.vrk1TextBox.value
            : this.vrk2TextBox.value) ?? 0;
        design.model[PropertyMetaData.Loads_OnSiteTestType.id] = this.onSiteTestType;
    }
}
