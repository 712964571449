<pe-modal-header [modalInstance]="modalInstance" [title]="textSofaTitle">
</pe-modal-header>

<pe-modal-body>
    <div class="body-content">
        <span [innerHtml]="textSofaConfiguration"></span>
        <div class="more-info-on-sofa-popup">
            <div class="left">
                <p>a)</p>
                <img [src]="moreInfoOnSofaA" alt="more-info-on-sofa">
            </div>
            <div class="right">
                <p>b)</p>
                <img [src]="moreInfoOnSofaB" alt="more-info-on-sofa">
            </div>
        </div>
        <ol class="ol-latin">
            <li>
                <span class="li-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.A.WithFilledAnnularGap.Title')"></span>
                <ul>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.A.WithFilledAnnularGap.Line1')"></span>
                    </li>
                    <li>
                        <span class="li-title fake-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.A.WithoutFilledAnnularGap.Title')"></span>
                    </li>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.A.WithoutFilledAnnularGap.Line1')"></span>
                    </li>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.A.WithoutFilledAnnularGap.Line2')"></span>
                    </li>
                </ul>
            </li>
            <li>
                <span class="li-title" [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.B.WithoutFilledAnnularGap.Title')"></span>
                <ul>
                    <li>
                        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.B.WithoutFilledAnnularGap.Line1')"></span>
                    </li>
                </ul>
            </li>
        </ol>
        <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.WhySofa.Title')"></span>
        <ul>
            <li>
                <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.WhySofa.Line1')"></span>
            </li>
        </ul>
        <ol>
            <li>
                <span [innerHtml]="textWhySofaDesign"></span>
            </li>
            <li>
                <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.WhySofa.Line3')"></span>
            </li>
            <li>
                <span [innerHtml]="translate('Agito.Hilti.Profis3.Navigation.TabLoads.RegionDesign.MoreInfoOnSOFA.Tooltip.WhySofa.Line4')"></span>
            </li>
        </ol>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-info-link-button class="float-left" [infoLinkKey]="'LearnMore.PE.SOFA'" (buttonClick)="learnMoreClicked()"></pe-info-link-button>
    <pe-button [look]="'Primary'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Ok')"></pe-button>
</pe-modal-footer>
