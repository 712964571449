import {
    StandOffDesignMethodEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class StandOffDesignMethod extends CodeList {

    public static fromService(codeList: StandOffDesignMethodEntity) {
        return new StandOffDesignMethod(CodeList.baseFromService('StandOffDesignMethodEntity', codeList));
    }

    public override cloneDeep(): StandOffDesignMethod {
        return super.cloneDeep() as StandOffDesignMethod;
    }
}
