import {
    MasonryFastenerConfigurationEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';

import { CodeList } from '../code-lists/code-list';

export class MasonryFastenerConfiguration extends CodeList {

    public static fromService(codeList: MasonryFastenerConfigurationEntity) {
        return new MasonryFastenerConfiguration(CodeList.baseFromService('MasonryFastenerConfigurationEntity', codeList));
    }

    public override cloneDeep(): MasonryFastenerConfiguration {
        return super.cloneDeep() as MasonryFastenerConfiguration;
    }
}
