import { HttpResponse } from '@angular/common/http';
import { CalculationType, IBaseDesign as IBaseDesignBase } from '@profis-engineering/pe-ui-common/entities/design';
import { Deferred } from '@profis-engineering/pe-ui-common/helpers/deferred';
import {
    CalculateDesignRequestDataBase, CalculationServiceBase, ICalculationResultBase, IPropertyChanges
} from '@profis-engineering/pe-ui-common/services/calculation.common';
import { Change } from '@profis-engineering/pe-ui-common/services/changes.common';

import {
    DesignCodeLists as IDesignCodeLists
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { DesignPe, ICalculateInternalOptions, ICodeLists, ReportData } from '../entities/design-pe';
import {
    CalculationResultEntity as ICalculationResultEntity, IdeaProjectFileResponse,
    NewDesignDataEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    DesignReportDataClientEntity as IDesignReportDataClientEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    ProjectDesignBaseEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    GenerateReportRequest, GenerateReportResponse
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Report';
import { UIProperties } from '../properties/properties';

export interface CalculateDesignRequestData extends CalculateDesignRequestDataBase {
    generateReportData: GenerateReportRequest;
}

export interface ICalculationResult extends ICalculationResultBase<DesignPe> {
    reportData: GenerateReportResponse;
    projectDesign?: ProjectDesignBaseEntity;
}

export interface IBaseDesign extends ICalculationResultBase<DesignPe> {
    reportData: GenerateReportResponse;
}

export interface IProjectDesignResult extends DesignPe {
    projectDesign: ProjectDesignBaseEntity;
}

export abstract class CalculationServiceBasePE extends CalculationServiceBase {
    public abstract createAndOpen(dataEntity: NewDesignDataEntity): Promise<DesignPe>;

    public abstract createAndOpenFromProjectDesignFile(
        projectDesign: ProjectDesignBaseEntity, projectId: string, name?: string,
        designCreated?: (projectDesign: ProjectDesignBaseEntity, name: string) => Promise<void> | void,
        ignoreConflict?: boolean, disableCalcMessages?: boolean, trackingEnabled?: boolean): Promise<DesignPe>;

    public abstract openFromProjectDesign(projectDesign: ProjectDesignBaseEntity, designId: string, trackingEnabled: boolean, openOnly: boolean): Promise<ICalculationResult>;

    public abstract openFromDocumentDesign(documentDesign: IBaseDesign, openOnly: boolean): Promise<ICalculationResult>;

    public abstract createAndOpenFromProjectDesign(projectDesign: ProjectDesignBaseEntity, projectId: string, templateId?: string, enableTracking?: boolean): Promise<DesignPe>;

    public abstract createFromProjectDesign(projectDesign: ProjectDesignBaseEntity, projectId: string, openOnly: boolean): Promise<DesignPe>;

    public abstract updateDesignFromExternalFile(oldDesign: IBaseDesignBase, projectDesign: ProjectDesignBaseEntity, disableCalcMessages?: boolean): Promise<DesignPe>;

    public abstract createAndOpenTemplate(projectDesign: ProjectDesignBaseEntity, templateId: string, templateName: string): Promise<DesignPe>;

    public abstract calculateAdvancedBaseplateAll(design: DesignPe): Promise<void>;

    public abstract calculateAdvancedBaseplate(design: DesignPe, selectedLoadCaseId?: string): Promise<void>;

    public abstract generateAndDownloadReportSignalR(design: DesignPe, generateReportData: GenerateReportRequest, changes: Change[], downloadPdf: boolean): Promise<Blob>;

    public abstract calculateAllSignalR(design: DesignPe, onProgress?: (progress: unknown) => void): Promise<void>;

    public abstract calculateAsync(design: DesignPe, changeFn?: (design: DesignPe) => void, options?: ICalculateInternalOptions): Promise<ICalculationResult>;

    public abstract calculate(design: DesignPe, calculateId: string, options: CalculateDesignRequestData): void;

    public abstract updateReportDataFromModel(design: DesignPe): void;

    public abstract updateReportData(design: DesignPe, reportData: IDesignReportDataClientEntity): ReportData;

    public abstract updateFromProperties(design: DesignPe, uiProperties: UIProperties, keepMissingProperties?: boolean): {
        modelChanges: {
            [property: string]: Change;
        };
        propertyChanges: IPropertyChanges;
    };

    public abstract updateCalculationData(
        design: DesignPe,
        data: ICalculationResultEntity,
        calculationLanguage: string,
        calculationType: CalculationType,
        messagesClosedDeferred?: Deferred<void>,
        disableCalcMessages?: boolean,
        keepMissingProperties?: boolean
    ): {
        [property: string]: Change;
    };

    public abstract confirmCalculationResult(design: DesignPe, result: ICalculationResultEntity): void;

    public abstract performAdvancedBaseplateCalculation(design: DesignPe, selectedLoadCaseId?: string): Promise<ICalculationResultEntity>;

    public abstract wizardHNALoadTypeSeismicDialogChain(design: DesignPe, onClose: () => void): void;

    public abstract generateIdeaFile(design: DesignPe): Promise<HttpResponse<IdeaProjectFileResponse>>;

    public abstract createProjectDesignFromFile(projectDesignFile: File | Blob, projectName: string, designName: string, ignoreErrors?: boolean): Promise<ProjectDesignBaseEntity>;

    public abstract loadState(design: DesignPe, index: number): void;

    public abstract undo(design: DesignPe): Promise<void>;

    public abstract redo(design: DesignPe): Promise<void>;

    public abstract getCustomImages(imageNames: string[]): Promise<string[]>;

    public abstract uploadImages(designId: string, images: string[]): Promise<string[]>;

    public abstract createDesignCodeLists(serviceDesignCodeLists: IDesignCodeLists): ICodeLists;
}
