import escape from 'lodash-es/escape';

import { HttpResponseBase } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    CheckboxButtonItem, CheckboxButtonProps, InfoIcon, TooltipTarget
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    RadioButtonItem, RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import { environment } from '../../../environments/environmentPe';
import { SourceFile } from '../../../shared/components/dlubal-export';
import {
    UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    LoadType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    IntegrationDataResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities';
import {
    DataIntegrationRequestType, DataIntegrationType, ErrorType as IntegrationsErrorType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    DlubalLoadCombinationRequest, DlubalLoadCombinationResponse, DlubalLoadEntry, DlubalResponse,
    DlubalSelectedNodesResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Dlubal';
import {
    DlubalApplicationInstanceType, EdgeOrientation, LoadType as IntegrationsLoadType,
    OrientationType, PlatePosition
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Dlubal.Enums';
import { concatFilterLoadCombinations } from '../../helpers/load-combination-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { GuidService } from '../../services/guid.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';
import { SharedEnvironmentService } from '../../services/shared-environment.service';

interface ILoad {
    Description: string;
    LoadType: string;
    Number: number;
    Result: boolean;
}

interface ICalculation {
    Comment: string;
    ForceX: number;
    ForceY: number;
    ForceZ: number;
    MomentX: number;
    MomentY: number;
    MomentZ: number;
    LoadType: IntegrationsLoadType;
}

enum ResultCombinationImports {
    AllForces,
    OnlyMinMax
}

enum NodePosition {
    Top,
    Bottom,
    Side
}

enum NodeOrientation {
    XPlus,
    XMinus,
    YPlus,
    YMinus
}

@Component({
    templateUrl: './dlubal-import.component.html',
    styleUrls: ['./dlubal-import.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DlubalImportComponent implements OnInit {
    @Input()
    modalInstance!: ModalInstance;

    public submitted = false;

    public loadCalculations: ICalculation[] = [];
    public importLoadsCheckbox!: CheckboxButtonProps<ILoad>;
    public rstabLiveInstance = false;
    public rfemLiveInstance = false;
    public detectedNodes: number[] = [];
    public nodePosition: NodePosition = NodePosition.Top;
    public nodeOrientation: NodeOrientation = NodeOrientation.XPlus;
    public loadsToImport: { value: number; name: string; selected: boolean }[] = [];

    public lockedControls = false;
    public refreshOverlayVisible = false;
    public refreshingNodes = false;
    public importButtonLocked = false;

    public sourceFileRadio!: RadioButtonProps<SourceFile>;
    public resultCombinationImportsRadio!: RadioButtonProps<ResultCombinationImports>;

    public technicalInfo!: string;

    public nodePositionEnum = NodePosition;
    public nodeOrientationEnum = NodeOrientation;

    public yplusLocation = 3;
    public yminusLocation = 4;

    public xPlusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-xplus';
    public xMinusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-xminus';
    public yPlusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-yplus';
    public yMinusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-yminus';

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private guid: GuidService,
        private offline: OfflineService,
        private modal: ModalService,
        private integrationsData: IntegrationsDataService,
        private calculationService: CalculationServicePE,
        private sharedEnvironmentData: SharedEnvironmentService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get preventClosing() {
        if (this.refreshOverlayVisible) {
            return this.submitted || this.refreshingNodes;
        }
        else {
            return this.submitted || this.lockedControls;
        }
    }

    public get detectedNodesFormatted() {
        return this.detectedNodes.join(', ');
    }

    public get hasDetectedNodes() {
        return this.detectedNodes.length > 0;
    }

    public get validSourceSelected() {
        return this.sourceFileRadio.selectedValue != null
            &&
            (
                this.sourceFileRadio.selectedValue === SourceFile.RSTAB8
                ||
                this.sourceFileRadio.selectedValue === SourceFile.RFEM5
            );
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-refresh-l-dark',
            'sprite-info-tooltip',
            'sprite-dlubal-nodes-top',
            'sprite-dlubal-nodes-bottom',
            'sprite-dlubal-nodes-side',
            'sprite-dlubal-nodes-xplus',
            'sprite-dlubal-nodes-xminus',
            'sprite-dlubal-nodes-yplus',
            'sprite-dlubal-nodes-yminus',
            'sprite-dlubal-nodes-xminus-upward',
            'sprite-dlubal-nodes-xplus-upward',
            'sprite-dlubal-nodes-yminus-upward',
            'sprite-dlubal-nodes-yplus-upward'
        );

        // don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            if (this.preventClosing) {
                return false;
            }

            this.integrationsData.requestDataDlubal(DataIntegrationRequestType.UnlockLicense, DlubalApplicationInstanceType.RFEM5);
            this.integrationsData.requestDataDlubal(DataIntegrationRequestType.UnlockLicense, DlubalApplicationInstanceType.RSTAB8);
            return true;
        });

        // controls
        this.sourceFileRadio = {
            items: [
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.RSTAB8'),
                    value: SourceFile.RSTAB8
                },
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.RFEM5'),
                    value: SourceFile.RFEM5
                }
            ],
            selectedValue: undefined
        };
        this.updateSourceFileRadioItemsDisabled();

        this.importLoadsCheckbox = {
            items: [],
            selectedValues: new Set()
        };

        this.resultCombinationImportsRadio = {
            items: [
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.AllForces'),
                    value: ResultCombinationImports.AllForces
                },
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.OnlyMinMax'),
                    value: ResultCombinationImports.OnlyMinMax
                }
            ],
            selectedValue: ResultCombinationImports.AllForces
        };

        if (!this.offline.isOffline) {
            this.checkLiveInstanceNewIntegration();
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public refreshInstance(forceRefreshNodes?: boolean) {
        this.checkLiveInstanceNewIntegration(forceRefreshNodes);
    }

    public selectAll(): void {
        if (this.submitted) {
            return;
        }

        const availableLoadsCount: number = (this.importLoadsCheckbox.items as CheckboxButtonItem<ILoad>[])
            .filter(x => x.value.Result)
            .length;

        const selectedLoadsCount: number = this.numberOfSelectedLoadCombinations();

        let check: boolean | null = null;

        if (availableLoadsCount == selectedLoadsCount) {
            // de-select all
            check = false;
        }
        else {
            // select all
            check = true;
        }

        this.importLoadsCheckbox.items?.forEach(v => {
            if (v.value.Result) {
                if (check) {
                    this.importLoadsCheckbox.selectedValues?.add(v.value);
                }
                else {
                    if (this.importLoadsCheckbox.selectedValues?.has(v.value)) {
                        this.importLoadsCheckbox.selectedValues?.delete(v.value);
                    }
                }
            }
        });
    }

    public sourceFileRadioSelectedValueChange() {
        this.readSelectedSoftwareNodes();
    }

    public numberOfSelectedLoadCombinations(): number {
        const result: number = Array.from(this.importLoadsCheckbox.selectedValues?.values() ?? [])
            .filter(x => x.Result)
            .length;

        return result;
    }

    public importDlubal() {
        if (this.lockedControls || !this.validSourceSelected) {
            return;
        }

        this.user.design.usageCounter.DlubalImport++;

        const validLoadEntries = Array.from(this.importLoadsCheckbox.selectedValues?.values() ?? [])
            .filter(x => x.Result)
            .map(entry => {
                return {
                    Description: entry.Description,
                    LoadType: entry.LoadType,
                    Number: entry.Number,
                    Result: entry.Result
                } as DlubalLoadEntry;
            });

        const loadCombinationsRequest = {
            // General Properties
            DataIntegrationType: DataIntegrationType.Dlubal,
            RequestType: DataIntegrationRequestType.GetLoadCombinations,
            ApplicationInstanceType: this.sourceFileRadio.selectedValue == SourceFile.RFEM5
                ? DlubalApplicationInstanceType.RFEM5
                : DlubalApplicationInstanceType.RSTAB8,
            // Load Combinations Request specific
            AllRC: this.resultCombinationImportsRadio.selectedValue == ResultCombinationImports.AllForces,
            EdgeOrientation: this.castEdgeOrientationToIntegrations(this.castEdgeOrientation()),
            LoadEntries: validLoadEntries,
            Nodes: this.detectedNodes,
            Position: this.castPlatePositionToIntegrations(this.castPlatePosition()),
        } as DlubalLoadCombinationRequest;

        this.submitted = true;

        this.integrationsData.requestData<DlubalLoadCombinationResponse>(
            DataIntegrationType.Dlubal,
            DataIntegrationRequestType.GetLoadCombinations,
            () => {
                this.handleServiceError();
            },
            (response) => {
                const validResponse = this.validateResponse(response);
                if (!validResponse) {
                    this.handleServiceError();

                    return;
                }

                this.handleReadLoadCombinationResponse(response);
            },
            loadCombinationsRequest).catch((err) => this.handleServiceError(err, true));
    }

    public isRCLoad(load: ILoad) {
        return load.LoadType == 'RC';
    }

    public isRCloadSelected() {
        return Array.from(this.importLoadsCheckbox.selectedValues?.values() ?? []).some(v => {
            return this.isRCLoad(v);
        });
    }

    public openRCLoadPopup(load: ILoad) {
        this.modal.openConfirmChange({
            id: 'dlubal-import-rcload-popup',
            title: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.RCLoadPopup.Title'),
            message: load.Description,
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.RCLoadPopup.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    public openCombinationImportPopup() {
        this.modal.openConfirmChange({
            id: 'dlubal-import-combinationImport-popup',
            title: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.CombinationImportPopup.Title'),
            message: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.CombinationImportPopup.Text'),
            confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.CombinationImportPopup.Ok'),
            onConfirm: (modal) => {
                modal.close();
            }
        });
    }

    public close() {
        this.modalInstance.close();
    }

    private handleServiceError(response?: HttpResponseBase, logError = false, resetRefreshingNodes = false) {
        if (logError && response instanceof Error) {
            console.error(response);
        }

        if (resetRefreshingNodes) {
            this.refreshingNodes = false;
        }

        this.submitted = false;
        this.importButtonLocked = false;

        this.modal.openAlertError(
            this.localization.getString('Agito.Hilti.Profis3.DlubalImport.ServiceError.Title'),
            this.createDownloadUrlMessage('Agito.Hilti.Profis3.DlubalImport.ServiceError.Message'),
            {
                response,
                endPointUrl: this.integrationsData.requestUrl,
                requestPayload: this.integrationsData.requestDataObject
            }
        );
    }

    private handleMultipleOpenModelsError(): void {
        this.submitted = false;
        this.modal.openAlertWarning(
            this.localization.getString('Agito.Hilti.Profis3.DlubalImport.MultipleModels.Title'),
            this.localization.getString('Agito.Hilti.Profis3.DlubalImport.MultipleModels.Text'),
        );
    }

    private validateResponse(response: IntegrationDataResponse): boolean {
        if (response.ErrorType === IntegrationsErrorType.IntegrationInternalError ||
            response.ErrorType === IntegrationsErrorType.ModelNotOpened ||
            response.ErrorType === IntegrationsErrorType.NoInstanceRunning) {
            return false;
        }

        return true;
    }

    /**
     * Handles the selected nodes response
     * @param response The Dlubal selected nodes response
     */
    private handleSelectedNodesResponse(response: DlubalSelectedNodesResponse): void {
        this.detectedNodes = [];
        this.refreshingNodes = false;
        this.submitted = false;
        this.lockedControls = false;

        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            this.handleServiceError();
        }

        // If no selected nodes, show the refresh overlay
        if (response.ErrorType === IntegrationsErrorType.NoSelectedNodes || !response.Nodes.Nodes || response.Nodes.Nodes.length === 0) {
            this.showRefreshOverlay();

            return;
        }

        const orientationType = response.Nodes.GlobalZAxisOrientation === OrientationType.Downward ?
            OrientationType.Downward : OrientationType.Upward;

        this.detectedNodes = response.Nodes.Nodes;
        this.adjustOrientationType(orientationType);

        const mappedLoads = response.DlubalLoadEntries
            .map(entry => {
                return {
                    Description: entry.Description,
                    LoadType: entry.LoadType,
                    Number: entry.Number,
                    Result: entry.Result,
                } as ILoad;
            });
        this.updateImportLoadsCheckboxItems(mappedLoads);

        this.hideRefreshOverlay();
    }

    /**
     * Handles the load combination response
     * @param response The Dlubal load combination response
     */
    private handleReadLoadCombinationResponse(response: DlubalLoadCombinationResponse): void {
        this.loadCalculations = [];

        response.LoadCombinations.forEach(lc => {
            this.loadCalculations.push(
                {
                    Comment: lc.Comment,
                    ForceX: lc.ForceX,
                    ForceY: lc.ForceY,
                    ForceZ: lc.ForceZ,
                    MomentX: lc.MomentX,
                    MomentY: lc.MomentY,
                    MomentZ: lc.MomentZ,
                    LoadType: lc.LoadType
                });
        });

        this.import();
    }

    private getDataRSTAB8(currentSourceFile?: SourceFile, forceRefreshNodes?: boolean) {
        this.sourceFileRadio.selectedValue = SourceFile.RSTAB8;

        if (currentSourceFile == this.sourceFileRadio.selectedValue && forceRefreshNodes) {
            // Refresh nodes for RSTAB
            this.integrationsData.requestDataDlubal<DlubalSelectedNodesResponse>(
                DataIntegrationRequestType.GetSelectedNodes,
                DlubalApplicationInstanceType.RSTAB8,
                () => {
                    this.refreshingNodes = false;
                    this.handleServiceError();
                },
                (response) => {
                    this.handleSelectedNodesResponse(response);
                })
                .catch((err) => this.handleServiceError(err, true, true));
        }
    }

    private getDataRFEM5(currentSourceFile?: SourceFile, forceRefreshNodes?: boolean) {
        this.sourceFileRadio.selectedValue = SourceFile.RFEM5;

        if (currentSourceFile == this.sourceFileRadio.selectedValue && forceRefreshNodes) {
            // Refresh nodes for RFEM
            this.integrationsData.requestDataDlubal<DlubalSelectedNodesResponse>(
                DataIntegrationRequestType.GetSelectedNodes,
                DlubalApplicationInstanceType.RFEM5,
                () => {
                    this.refreshingNodes = false;
                    this.handleServiceError();
                },
                (response) => {
                    this.handleSelectedNodesResponse(response);
                })
                .catch((err) => this.handleServiceError(err, true, true));
        }
    }

    private getDataFromRunningInstance(response: DlubalResponse, forceRefreshNodes?: boolean) {
        if (response.ErrorType === IntegrationsErrorType.MultipleInstancesRunning) {
            this.handleMultipleOpenModelsError();
            this.refreshingNodes = false;
            return;
        }

        if (response.ErrorType === IntegrationsErrorType.IntegrationInternalError) {
            this.handleServiceError();
            this.refreshingNodes = false;
            return;
        }

        this.rstabLiveInstance = response.ErrorType === IntegrationsErrorType.None ? true : false;

        this.refreshingNodes = true;

        const tmp = this.sourceFileRadio.selectedValue;
        if (this.rstabLiveInstance) {
            this.getDataRSTAB8(tmp, forceRefreshNodes);
        }
        else if (this.rfemLiveInstance) {
            this.getDataRFEM5(tmp, forceRefreshNodes);
        }
        else {
            this.showRefreshOverlay();
            this.submitted = false;
            this.refreshingNodes = false;
        }

        this.updateSourceFileRadioItemsDisabled();

        if (tmp != this.sourceFileRadio.selectedValue) {
            this.sourceFileRadioSelectedValueChange();
        }
        else {
            if (!forceRefreshNodes) {
                this.submitted = false;
                this.refreshingNodes = false;
            }
        }
    }

    private checkLiveInstanceNewIntegration(forceRefreshNodes?: boolean) {
        this.submitted = true;
        this.refreshingNodes = true;

        this.integrationsData.requestDataDlubal<DlubalResponse>(
            DataIntegrationRequestType.RunningInstance,
            DlubalApplicationInstanceType.RFEM5,
            () => {
                this.refreshingNodes = false;
                this.handleServiceError();
            },
            (response) => {
                if (response.ErrorType === IntegrationsErrorType.MultipleInstancesRunning) {
                    this.handleMultipleOpenModelsError();
                    this.refreshingNodes = false;

                    return;
                }

                this.rfemLiveInstance = response.ErrorType === IntegrationsErrorType.None ? true : false;

                this.integrationsData.requestDataDlubal<DlubalResponse>(
                    DataIntegrationRequestType.RunningInstance,
                    DlubalApplicationInstanceType.RSTAB8,
                    () => {
                        this.refreshingNodes = false;
                        this.handleServiceError();
                    },
                    (response2) => this.getDataFromRunningInstance(response2, forceRefreshNodes))
                    .catch((err) => this.handleServiceError(err, true, true));
            })
            .catch((err) => this.handleServiceError(err, true, true));
    }

    private import() {
        const newLoads: LoadCombination[] = [];
        this.loadCalculations.forEach(c => {
            newLoads.push({
                Name: this.translateDlubalText(c.Comment),
                Description: '',
                Id: this.guid.new(),
                ForceX: c.ForceX,
                ForceY: c.ForceY,
                ForceZ: c.ForceZ,
                MomentX: c.MomentX,
                MomentY: c.MomentY,
                MomentZ: c.MomentZ,
                DynamicForceX: null as unknown as number,
                DynamicForceY: null as unknown as number,
                DynamicForceZ: null as unknown as number,
                DynamicMomentX: null as unknown as number,
                DynamicMomentY: null as unknown as number,
                DynamicMomentZ: null as unknown as number,
                ActiveLoadType: this.castLoadTypeFromIntegrations(c.LoadType),
                Tension: null as unknown as UtilizationValueEntity,
                Shear: null as unknown as UtilizationValueEntity,
                Combination: null as unknown as UtilizationValueEntity,
                ResultMessages: [],
                IsWizardGenerated: false,
                HasSustainedLoads: false,
                LoadCharacteristic: null as unknown as number
            });

            return true;
        });

        // calculate
        this.calculationService.calculateAsync(this.user.design,
            (design) => {
                // Filter out empty load combinations and consider max number of combinations
                design.loadCombinations = concatFilterLoadCombinations(design.loadCombinations, newLoads, environment.maxLoadCombinations, false);
            },
            { suppressLoadingFlag: true, importingLoadCases: true })
            .then(() => {
                this.submitted = false;
                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    private castEdgeOrientationToIntegrations(edgeOrientation: EdgeOrientation): EdgeOrientation {
        if (edgeOrientation == EdgeOrientation.xminus) {
            return EdgeOrientation.xminus;
        }
        else if (edgeOrientation == EdgeOrientation.xplus) {
            return EdgeOrientation.xplus;
        }
        else if (edgeOrientation == EdgeOrientation.yminus) {
            return EdgeOrientation.yminus;
        }
        else {
            return EdgeOrientation.yplus;
        }
    }

    private castPlatePositionToIntegrations(platePosition: PlatePosition): PlatePosition {
        if (platePosition == PlatePosition.bottom) {
            return PlatePosition.bottom;
        }
        if (platePosition == PlatePosition.side) {
            return PlatePosition.side;
        }
        else {
            return PlatePosition.top;
        }
    }

    private castLoadTypeFromIntegrations(loadType: IntegrationsLoadType): LoadType {
        if (loadType == IntegrationsLoadType.Seismic) {
            return LoadType.Seismic;
        }
        else if (loadType == IntegrationsLoadType.Static) {
            return LoadType.Static;
        }
        else {
            return LoadType.Unknown;
        }
    }

    private readSelectedSoftwareNodes() {
        if (this.sourceFileRadio.selectedValue != null) {
            this.refreshingNodes = true;
            if (!this.offline.isOffline) {
                const instanceType = this.sourceFileRadio.selectedValue == SourceFile.RFEM5
                    ? DlubalApplicationInstanceType.RFEM5
                    : DlubalApplicationInstanceType.RSTAB8;

                this.refreshingNodes = true;
                this.submitted = true;

                this.integrationsData.requestDataDlubal<DlubalSelectedNodesResponse>(
                    DataIntegrationRequestType.GetSelectedNodes,
                    instanceType,
                    () => {
                        this.refreshingNodes = false;
                        this.handleServiceError();
                    },
                    (response) => {
                        this.handleSelectedNodesResponse(response);
                    })
                    .catch((err) => this.handleServiceError(err, true, true));
            }
        }
    }

    private translateDlubalText(text: string) {
        return text.replace(/LC/g, this.localization.getString('Agito.Hilti.Profis3.DlubalImport.LoadCase.Short'))
            .replace(/CO/g, this.localization.getString('Agito.Hilti.Profis3.DlubalImport.LoadCombination.Short'))
            .replace(/RC/g, this.localization.getString('Agito.Hilti.Profis3.DlubalImport.ResultCombination.Short'));
    }

    private getLoadRecord(load: ILoad) {
        if (load.LoadType == 'RC') {
            return this.translateDlubalText(load.LoadType) + ';' + load.Number + ';' + load.Description.substring(0, 30) + '...' + ';' + load.Result;
        }
        else {
            return this.translateDlubalText(load.LoadType) + ';' + load.Number + ';' + load.Description + ';' + load.Result;
        }
    }

    private castPlatePosition() {
        let position: PlatePosition;
        switch (this.nodePosition) {
            case NodePosition.Bottom:
                position = PlatePosition.bottom;
                break;
            case NodePosition.Side:
                position = PlatePosition.side;
                break;
            case NodePosition.Top:
                position = PlatePosition.top;
                break;
        }

        return position;
    }

    private castEdgeOrientation() {
        let orientation: EdgeOrientation;
        switch (this.nodeOrientation) {
            case NodeOrientation.XMinus:
                orientation = EdgeOrientation.xminus;
                break;
            case NodeOrientation.XPlus:
                orientation = EdgeOrientation.xplus;
                break;
            case NodeOrientation.YMinus:
                orientation = EdgeOrientation.yminus;
                break;
            case NodeOrientation.YPlus:
                orientation = EdgeOrientation.yplus;
                break;
        }

        return orientation;
    }

    private adjustOrientationType(oType: OrientationType) {
        if (oType == OrientationType.Upward) {
            this.xMinusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-xminus-upward';
            this.xPlusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-xplus-upward';
            this.yMinusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-yminus-upward';
            this.yPlusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-yplus-upward';
            this.yplusLocation = 3;
            this.yminusLocation = 4;
        }
        else {
            this.xMinusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-xminus';
            this.xPlusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-xplus';
            this.yMinusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-yminus';
            this.yPlusClass = 'image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-yplus';
            this.yplusLocation = 4;
            this.yminusLocation = 3;
        }
    }

    private showRefreshOverlay() {
        this.refreshOverlayVisible = true;

        this.integrationsData.requestDataDlubal(DataIntegrationRequestType.UnlockLicense, DlubalApplicationInstanceType.RFEM5)
            .catch((err) => this.handleServiceError(err, true));
        this.integrationsData.requestDataDlubal(DataIntegrationRequestType.UnlockLicense, DlubalApplicationInstanceType.RSTAB8)
            .catch((err) => this.handleServiceError(err, true));
    }

    private hideRefreshOverlay() {
        this.refreshOverlayVisible = false;
    }

    private createDownloadUrlMessage(messageKey: string) {
        const hereValue = escape(this.localization.getString('Agito.Hilti.Profis3.DlubalImport.ServiceError.Message.DownloadUrlText'));
        const downloadUrl = `<a href="${escape(this.sharedEnvironmentData.data?.thirdPartyInterfaceDownloadUrl)}" class="download-link" target="_blank">${hereValue}</a>`;

        return `<html lang="en">${formatKeyValue(escape(this.localization.getString(messageKey)), { downloadUrl: downloadUrl })}</html>`;
    }

    private updateImportLoadsCheckboxItems(loads: ILoad[]) {
        const checkboxItems: CheckboxButtonItem<ILoad>[] = [];
        const checkboxSelectedValues = new Set<ILoad>();

        loads.forEach(load => {
            const item: CheckboxButtonItem<ILoad> = {
                text: this.getLoadRecord(load),
                value: load,
                disabled: !load.Result
            };

            if (this.isRCLoad(load)) {
                item.infoIcon = InfoIcon.Button;
                item.tooltipTarget = TooltipTarget.InfoIcon;
                item.tooltip = this.localization.getString('Agito.Hilti.Profis3.DlubalImport.RCLoadPopup');
            }

            checkboxItems.push(item);
        });

        this.importLoadsCheckbox.items = checkboxItems;
        this.importLoadsCheckbox.selectedValues = checkboxSelectedValues;
    }

    private updateSourceFileRadioItemsDisabled() {
        ((this.sourceFileRadio.items as RadioButtonItem<SourceFile>[])
            .find(item => item.value === SourceFile.RSTAB8) as RadioButtonItem<SourceFile>)
            .disabled = !this.rstabLiveInstance;

        ((this.sourceFileRadio.items as RadioButtonItem<SourceFile>[])
            .find(item => item.value === SourceFile.RFEM5) as RadioButtonItem<SourceFile>)
            .disabled = !this.rfemLiveInstance;
    }
}
