import { GroutTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class GroutType extends CodeList {

    public static fromService(codeList: GroutTypeEntity) {
        return new GroutType(CodeList.baseFromService('GroutTypeEntity', codeList));
    }

    public override cloneDeep(): GroutType {
        return super.cloneDeep() as GroutType;
    }
}
