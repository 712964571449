<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.CustomStiffenerWizard.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="select-stiffener-wrapper">
        <div class="select-stiffener-container">
            <pe-dropdown
                [id]="customProfileSideDropdown.id"
                [title]="customProfileSideDropdown.title"
                [items]="customProfileSideDropdown.items"
                [selectedValue]="customProfileSideDropdown.selectedValue"
                (selectedValueChange)="onCustomProfileSideDropdownChange($any($event).detail)">
            </pe-dropdown>

            <div class="warning-container" *ngIf="invalidStiffenerLocation">
                <span [innerHtml]="warningText"></span>
            </div>
        </div>

        <div class="stiffener-location-image">
            <img [src]="profileLocationImage" alt="Profile location">
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        [look]="'Primary'"
        (buttonClick)="save()"
        [text]="translate('Agito.Hilti.Profis3.Ok')"
        [disabled]="invalidStiffenerLocation">
    </pe-button>
</pe-modal-footer>
