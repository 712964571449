import { CoordinateSystemCenterEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class CoordinateSystemCenter extends CodeList {
    public static fromService(codeList: CoordinateSystemCenterEntity) {
        return new CoordinateSystemCenter(CodeList.baseFromService('CoordinateSystemCenterEntity', codeList));
    }

    public override cloneDeep(): CoordinateSystemCenter {
        return super.cloneDeep() as CoordinateSystemCenter;
    }
}
