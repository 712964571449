<input class="file-input" #fileInput (change)="fileSelected()" name="project" type="file"
    (click)="$event.stopPropagation();" />

<pe-modal-header
    [modalInstance]="modalInstance"
    [title]="'ANCHOR.AI'"
    [closeButtonId]="'asad-optimize-modal-close-button'"
    [closeButtonDisabled]="optimizing">
</pe-modal-header>

<form class="form" #ngForm="ngForm" novalidate>
    <pe-modal-body>
        <pe-section [title]="'Input'">
            <p class="field-name">Generic</p>
            <textarea class="field-value" name="generic-input" [(ngModel)]="generic" [disabled]="optimizing"
                autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"></textarea>

            <pe-button
                class="modal-button button field-value asad-button"
                id="asad-optimize-modal-generic-input-browser-button"
                [look]="'Default'"
                (buttonClick)="selectGenericInput()"
                text="Browse"
                [disabled]="optimizing">
            </pe-button>

            <pe-button
                class="modal-button button field-value asad-button"
                id="asad-optimize-modal-generic-input-default-button"
                [look]="'Default'"
                (buttonClick)="setDefaultGenericInput()"
                text="Default"
                [disabled]="optimizing">
            </pe-button>

            <pe-button
                class="modal-button button field-value asad-button"
                id="asad-optimize-modal-generic-input-clear-button"
                [look]="'Default'"
                (buttonClick)="clearGenericInput()"
                text="Clear"
                [disabled]="optimizing">
            </pe-button>

            <pe-button
                class="modal-button button field-value asad-button"
                id="asad-optimize-modal-generic-input-save-button"
                [look]="'Default'"
                (buttonClick)="saveGenericInput()"
                text="Save"
                [disabled]="optimizing || !generic">
            </pe-button>

            <pe-radio-button
                [title]="'Fasteners'"
                [items]="fastenersRadio.items"
                [selectedValue]="fastenersRadio.selectedValue"
                (selectedValueChange)="fastenersRadio.selectedValue=$any($event).detail"
                [disabled]="optimizing">
            </pe-radio-button>

            <pe-checkbox-button id="asad-optimize-modal-generic-input-include-button"
                class="asad-control"
                [title]="'Include'"
                [items]="includeCheckbox.items"
                [selectedValues]="includeCheckbox.selectedValues"
                (selectedValuesChange)="includeCheckbox.selectedValues=$any($event).detail"
                [disabled]="optimizing">
            </pe-checkbox-button>

            <ng-container *ngIf="debugControls">
                <pe-radio-button id="asad-optimize-modal-generic-input-kernel-cache-button"
                    class="asad-control"
                    [title]="'Kernel cache'"
                    [items]="kernelCacheRadio.items"
                    [selectedValue]="kernelCacheRadio.selectedValue"
                    (selectedValueChange)="kernelCacheRadio.selectedValue=$any($event).detail"
                    [disabled]="optimizing">
                </pe-radio-button>

                <pe-checkbox-button id="asad-optimize-modal-generic-input-kernel-file-cache-button"
                    class="asad-control"
                    [items]="kernelFileCacheCheckbox.items"
                    [selectedValues]="kernelFileCacheCheckbox.selectedValues"
                    (selectedValuesChange)="kernelFileCacheCheckbox.selectedValues=$any($event).detail"
                    [disabled]="optimizing">
                </pe-checkbox-button>

                <pe-radio-button id="asad-optimize-modal-generic-input-calculation-cache-button"
                    class="asad-control"
                    [title]="'Calculation cache'"
                    [items]="calculationCacheRadio.items"
                    [selectedValue]="calculationCacheRadio.selectedValue"
                    (selectedValueChange)="calculationCacheRadio.selectedValue=$any($event).detail"
                    [disabled]="optimizing">
                </pe-radio-button>

                <pe-checkbox-button id="asad-optimize-modal-generic-input-profile-code-button"
                    class="asad-control"
                    [items]="profileCodeCheckbox.items"
                    [selectedValues]="profileCodeCheckbox.selectedValues"
                    (selectedValuesChange)="profileCodeCheckbox.selectedValues=$any($event).detail"
                    [disabled]="optimizing">
                </pe-checkbox-button>
            </ng-container>
        </pe-section>

        <pe-section [title]="'General'" *ngIf="output">
            <p class="field-text">Optimization time: {{(output.totalTime / 1000).toFixed(2)}} sec</p>
            <p class="field-text">Calculation count: {{output.totalCalculationCount}}</p>
            <p class="field-text">Cases: {{output.doneCasesCount}} / {{output.maxOptimizeCasesCount}}</p>
            <p class="field-text">Feasible cases: {{output.feasibleCasesCount}}</p>
            <p class="field-text">Parallel calculation: {{output.runningOptimizationsCount}}</p>
            <p class="field-text">Error cases: {{output.errorCasesCount}}</p>
        </pe-section>

        <pe-section [title]="'Time estimation - average'" *ngIf="output">
            <p class="field-text-center">{{output.totalCalculationCount}} / {{output.timeEstimationAverage.estimatedCalculationCount}}</p>
            <p class="field-text-center">{{(output.timeEstimationAverage.progress * 100).toFixed(0)}}%</p>
            <div class="optimize-time-loader-bar">
                <div class="optimize-time-loader-bar-progress" [style.width]="(output.timeEstimationAverage.progress * 100) + '%'"></div>
            </div>
        </pe-section>

        <pe-section [title]="'Cases'" *ngIf="output">
            <pe-dropdown
                class="asad-optimize-cases-sort-by"
                [title]="'Sort by'"
                [items]="optimizeCasesSortByDropdown.items"
                [selectedValue]="optimizeCasesSortByDropdown.selectedValue"
                (selectedValueChange)="onOptimizeCasesSortByChange($any($event).detail)"
            ></pe-dropdown>

            <div class="asad-table field-value">
                <div class="asad-table-header">
                    <div class="asad-table-header-item">FastenerId</div>
                    <div class="asad-table-header-item">Anchors</div>
                    <div class="asad-table-header-item">Layout</div>
                    <div class="asad-table-header-item">Area</div>
                    <div class="asad-table-header-item">Evaluations</div>
                    <div class="asad-table-header-item">Utilization</div>
                    <div class="asad-table-header-item asad-table-header-details-item">
                        <div class="asad-table-header-details-item-icon"></div>
                        <div class="asad-table-header-details-item-icon"></div>
                        <div class="asad-table-header-details-item-icon"></div>
                    </div>
                    <div class="asad-table-header-item asad-table-header-scroll-item" [style.flexBasis]="optimizeCases.length > 10 ? scrollbarWidth + 'px' : '0'"></div>
                </div>
                <div class="asad-table-body">
                    <virtual-scroller class="asad-optimize-cases-virtual-scroller"
                        #optimizeCasesScroll [items]="optimizeCases" [scrollAnimationTime]="0"
                        [style.height]="(min(optimizeCases.length * 35, 350)) + 'px'">
                        <div class="asad-table-body-row"
                            [class.asad-error]="optimizeCase.optimizeCaseOutput?.exitFlag && optimizeCase.optimizeCaseOutput.exitFlag < 0"
                            *ngFor="let optimizeCase of $any(optimizeCasesScroll).viewPortItems; trackBy:trackByIndex">
                            <ng-template #utilizationTooltipTemplate>
                                <div class="asad-optimize-modal-utilization-tooltip-template" *ngIf="optimizeCase.optimizeCaseOutput != null">
                                    <span>Tension: {{formatPercentage(optimizeCase.optimizeCaseOutput.utilizations['Tension.Decisive'] * 100)}}</span><br>
                                    <span>Shear: {{formatPercentage(optimizeCase.optimizeCaseOutput.utilizations['Shear.Decisive'] * 100)}}</span><br>
                                    <span>Combination: {{formatPercentage(optimizeCase.optimizeCaseOutput.utilizations['Combination.Decisive'] * 100)}}</span><br>
                                </div>
                            </ng-template>

                            <div class="asad-table-body-item">{{optimizeCase.optimizeCaseDetails?.fastenerId}}</div>
                            <div class="asad-table-body-item">{{optimizeCase.optimizeCaseDetails?.numberOfAnchors}}</div>
                            <div class="asad-table-body-item">{{optimizeCase.optimizeCaseDetails?.layoutNumber}}</div>

                            <ng-container *ngIf="optimizeCase.optimizeCaseOutput?.exitFlag != null && optimizeCase.optimizeCaseOutput.exitFlag >= 0">
                                <div class="asad-table-body-item">{{optimizeCase.optimizeCaseOutput.area.toFixed(1)}}</div>
                                <div class="asad-table-body-item">{{optimizeCase.optimizeCaseOutput.constraintEvaluationsCount}}</div>
                                <div class="asad-table-body-item">
                                    <span [ngbTooltip]="utilizationTooltipTemplate" [openDelay]="0">
                                        {{formatPercentage(optimizeCase.optimizeCaseOutput.maxUtilization * 100)}}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="optimizeCase.optimizeCaseOutput != null && (optimizeCase.optimizeCaseOutput.exitFlag == null || optimizeCase.optimizeCaseOutput.exitFlag < 0)">
                                <div class="asad-table-body-item">-</div>
                                <div class="asad-table-body-item">-</div>
                                <div class="asad-table-body-item">-</div>
                            </ng-container>
                            <ng-container *ngIf="optimizeCase.optimizeCaseOutput == null">
                                <div class="asad-table-body-item"></div>
                                <div class="asad-table-body-item"></div>
                                <div class="asad-table-body-item"></div>
                            </ng-container>

                            <div class="asad-table-body-item asad-table-body-details-item">
                                <button class="apply-details-item-button asad-table-body-details-item-button" [disabled]="optimizeCase.optimizeCaseOutput?.exitFlag == null || optimizeCase.optimizeCaseOutput.exitFlag < 0"
                                    ngbTooltip="Apply" (click)="applyOptimizeCaseDesign(optimizeCase)" type="button">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-hilti-styled-checkbox"></span>
                                </button>
                                <button class="download-details-item-button asad-table-body-details-item-button" [disabled]="optimizeCase.optimizeCaseOutput?.exitFlag == null || optimizeCase.optimizeCaseOutput.exitFlag < 0"
                                    ngbTooltip="Download" (click)="downloadOptimizeCaseDesign(optimizeCase)" type="button">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-arrow-down"></span>
                                </button>
                                <button class="info-details-item-button asad-table-body-details-item-button" [disabled]="optimizeCase.optimizeCaseDetails == null"
                                    ngbTooltip="Details" (click)="openOptimizeCaseDetails(optimizeCase)" type="button">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                                </button>
                            </div>
                        </div>
                    </virtual-scroller>
                    <div class="asad-table-body-row-none" *ngIf="optimizeCases.length == 0">None</div>
                </div>
            </div>

            <pe-button
                class="modal-button button save-button"
                id="asad-optimize-modal-results-button"
                [look]="'Default'"
                (buttonClick)="saveResults()"
                text="Save"
                [disabled]="optimizeCases.length == 0">
            </pe-button>

            <pe-button
                class="modal-button button save-button"
                id="asad-optimize-modal-results-button"
                [look]="'Default'"
                (buttonClick)="exportToCsv()"
                text="Export to CSV"
                [disabled]="optimizeCases.length == 0">
            </pe-button>
        </pe-section>

        <pe-section [title]="'Scope checks'" *ngIf="output">
            <div class="asad-table field-value">
                <div class="asad-table-header">
                    <div class="asad-table-header-item">FastenerId</div>
                    <div class="asad-table-header-item">Anchors</div>
                    <div class="asad-table-header-item">Layout</div>
                    <div class="asad-table-header-item scope-check-error-names">Scope Checks</div>
                    <div class="asad-table-header-item asad-table-header-details-item">
                        <div class="asad-table-header-details-item-icon"></div>
                        <div class="asad-table-header-details-item-icon"></div>
                        <div class="asad-table-header-details-item-icon"></div>
                    </div>
                    <div class="asad-table-header-item asad-table-header-scroll-item" [style.flexBasis]="scopeCheckErrors.length > 10 ? scrollbarWidth + 'px' : '0'"></div>
                </div>
                <div class="asad-table-body">
                    <virtual-scroller class="asad-optimize-cases-virtual-scroller"
                        #scopeCheckErrorsScroll [items]="scopeCheckErrors" [scrollAnimationTime]="0"
                        [style.height]="(min(scopeCheckErrors.length * 35, 350)) + 'px'">
                        <div class="asad-table-body-row" *ngFor="let scopeCheckError of $any(scopeCheckErrorsScroll).viewPortItems; trackBy:trackByIndex">
                            <div class="asad-table-body-item">{{scopeCheckError.fastenerId}}</div>
                            <div class="asad-table-body-item">{{scopeCheckError.numberOfAnchors != null ? scopeCheckError.numberOfAnchors : '-'}}</div>
                            <div class="asad-table-body-item">{{scopeCheckError.layoutNumber != null ? scopeCheckError.layoutNumber : '-'}}</div>
                            <div class="asad-table-body-item scope-check-error-names">{{scopeCheckError.scopeChecks.join(', ')}}</div>
                            <div class="asad-table-body-item asad-table-body-details-item">
                                <button class="apply-details-item-button asad-table-body-details-item-button"
                                    ngbTooltip="Apply" (click)="applyScopeCheckErrorDesign(scopeCheckError)" type="button">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-hilti-styled-checkbox"></span>
                                </button>
                                <button class="download-details-item-button asad-table-body-details-item-button"
                                    ngbTooltip="Download" (click)="downloadScopeCheckErrorDesign(scopeCheckError)" type="button">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-arrow-down"></span>
                                </button>
                                <button class="info-details-item-button asad-table-body-details-item-button"
                                    ngbTooltip="Details" (click)="openScopeCheckErrorDetails(scopeCheckError)" type="button">
                                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-info"></span>
                                </button>
                            </div>
                        </div>
                    </virtual-scroller>
                    <div class="asad-table-body-row-none" *ngIf="scopeCheckErrors.length == 0">None</div>
                </div>
            </div>

            <pe-button
                class="modal-button button save-button"
                id="asad-optimize-modal-results-button"
                [look]="'Default'"
                (buttonClick)="saveScopeCheckErrors()"
                text="Save"
                [disabled]="scopeCheckErrors.length == 0">
            </pe-button>
        </pe-section>

        <pe-section [title]="'Messages'" *ngIf="output">
            <div class="optimize-messages asad-table field-value">
                <div class="asad-table-header">
                    <div class="asad-table-header-item optimize-messages-id">Id</div>
                    <div class="asad-table-header-item optimize-messages-severity">Severity</div>
                    <div class="asad-table-header-item optimize-messages-message">Message</div>
                </div>
                <div class="asad-table-body">
                    <virtual-scroller class="asad-optimize-cases-virtual-scroller"
                        #optimizeMessagesScroll [items]="output.optimizeMessages" [scrollAnimationTime]="0"
                        [style.height]="(min(output.optimizeMessages.length * 35, 350)) + 'px'">
                        <div class="asad-table-body-row" *ngFor="let optimizeMessage of $any(optimizeMessagesScroll).viewPortItems; trackBy:trackByIndex">
                            <div class="asad-table-body-item optimize-messages-id">{{optimizeMessage.id}}</div>
                            <div class="asad-table-body-item optimize-messages-severity">{{optimizeMessage.severity}}</div>
                            <div class="asad-table-body-item optimize-messages-message">{{optimizeMessage.message}}</div>
                        </div>
                    </virtual-scroller>
                    <div class="asad-table-body-row-none" *ngIf="output.optimizeMessages.length == 0">None</div>
                </div>
            </div>

            <pe-button
                class="modal-button button save-button"
                id="asad-optimize-modal-messages-button"
                [look]="'Default'"
                (buttonClick)="saveOptimizeMessages()"
                text="Save"
                [disabled]="output.optimizeMessages.length == 0">
            </pe-button>
        </pe-section>

        <pe-section [title]="'Logs'" *ngIf="output && input.includeLogs" [collapsed]="true">
            <p>Case logs are located inside case details.</p>
            <div class="logs-container" [class.fullscreen]="logsFullscreen">
                <textarea class="field-value logs-textarea" name="logs" [ngModel]="output.logs" readonly autocomplete="off"
                    autocorrect="off" autocapitalize="off" spellcheck="false"></textarea>
                <div class="logs-buttons">
                    <pe-button
                        class="modal-button button field-value"
                        id="asad-optimize-modal-logs-save-button"
                        [look]="'Default'"
                        (buttonClick)="saveLogs()"
                        text="Save"
                        [disabled]="output.logs.length == 0">
                    </pe-button>

                    <pe-button
                        class="modal-button button field-value"
                        id="asad-optimize-modal-logs-save-all-button"
                        [look]="'Default'"
                        (buttonClick)="saveAllLogs()"
                        text="Save all"
                        [disabled]="allLogsLength == 0">
                    </pe-button>

                    <pe-button
                        *ngIf="!logsFullscreen"
                        class="modal-button button field-value"
                        id="asad-optimize-modal-logs-open-button"
                        [look]="'Default'"
                        (buttonClick)="logsFullscreen = true"
                        text="Full screen"
                        [disabled]="output.logs.length == 0">
                    </pe-button>

                    <pe-button
                        *ngIf="logsFullscreen"
                        class="logs-close-button modal-button button field-value"
                        id="asad-optimize-modal-logs-close-button"
                        [look]="'Default'"
                        (buttonClick)="logsFullscreen = false"
                        text="Close">
                    </pe-button>
                </div>
            </div>
        </pe-section>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            class="modal-button"
            id="asad-optimize-modal-close-button"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="'Close'"
            [disabled]="optimizing">
        </pe-button>

        <pe-button
            *ngIf="!optimizing"
            class="modal-button"
            id="asad-optimize-modal-optimize-button"
            [look]="'Cta'"
            (buttonClick)="optimize()"
            [text]="'Optimize'"
            [disabled]="optimizing">
        </pe-button>

        <pe-button
            *ngIf="optimizing && cancelOptimization"
            class="modal-button"
            id="asad-optimize-modal-cancel-optimization-button"
            [look]="'Cta'"
            (buttonClick)="cancelOptimization()"
            [text]="'Cancel'">
        </pe-button>
    </pe-modal-footer>
</form>
