import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import {
    TextureDisplay
} from '@profis-engineering/pe-gl-model/gl-model';
import stressCheckLegendAdvancedUlsImage from '../../../images/stress-check-legend-advanced-uls.png';
import stressCheckLegendAdvancedImage from '../../../images/stress-check-legend-advanced.png';
import { DesignPe } from '../../../shared/entities/design-pe';

@Component({
    templateUrl: './stress-check-legend.component.html',
    styleUrls: ['./stress-check-legend.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class StressCheckLegendComponent implements OnInit, OnDestroy {
    @Input()
    public textureDisplay?: TextureDisplay;

    public design?: DesignPe;

    constructor(
        public unitService: UnitService,
        public userService: UserService
    ) { }

    public get isStressCheckVisible() {
        if (this.design?.isCBFEMCalculation || this.design?.isHandrailCBFEMCalculation) {
            return this.baseplateDesignImages != null;
        }

        return this.design?.IsAnchorPlatePresent && this.design?.anchorPlateStressImage != null;
    }

    public get useULSLegend() {
        const textureDisplay = this.textureDisplay;
        return (this.design?.isCBFEMCalculation || this.design?.isHandrailCBFEMCalculation) &&
            this.design?.useULSStresses && (textureDisplay == TextureDisplay.EquivalentStress || textureDisplay == TextureDisplay.PlasticStrain);
    }

    public get legendMaxTopOffset() {
        return this.useULSLegend ? 23 : 0;
    }

    public get stressCheckLegend() {
        return (this.design?.isCBFEMCalculation || this.design?.isHandrailCBFEMCalculation) && this.useULSLegend
            ? stressCheckLegendAdvancedUlsImage
            : stressCheckLegendAdvancedImage;
    }

    public get stressCheckLegendMax() {
        if (this.design?.isCBFEMCalculation || this.design?.isHandrailCBFEMCalculation) {
            if (this.baseplateDesignImages == null) {
                return null;
            }

            const defaultUnit = this.unitService.getDefaultUnit(this.baseplateDesignImagesUnitGroup);
            const internalUnit = this.unitService.getInternalUnit(this.baseplateDesignImagesUnitGroup);

            return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(this.baseplateDesignImages.LegendMaxValue || 0, internalUnit, defaultUnit), defaultUnit);
        }
        else {
            const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Stress);
            const internalUnit = this.unitService.getInternalUnit(UnitGroup.Stress);

            return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(this.design?.designData.reportData.StressChartLegendMax || 0, internalUnit, defaultUnit), defaultUnit);
        }
    }

    public get stressCheckLegendMin() {
        if (this.design?.isCBFEMCalculation || this.design?.isHandrailCBFEMCalculation) {
            if (this.baseplateDesignImages == null) {
                return null;
            }

            const defaultUnit = this.unitService.getDefaultUnit(this.baseplateDesignImagesUnitGroup);
            const internalUnit = this.unitService.getInternalUnit(this.baseplateDesignImagesUnitGroup);

            return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(this.baseplateDesignImages.LegendMinValue || 0, internalUnit, defaultUnit), defaultUnit);
        }
        else {
            const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Stress);
            const internalUnit = this.unitService.getInternalUnit(UnitGroup.Stress);

            return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(this.design?.designData.reportData.StressChartLegendMin || 0, internalUnit, defaultUnit), defaultUnit);
        }
    }

    private get baseplateDesignImages() {
        if (this.design?.baseplateDesignData == null || this.textureDisplay == null) {
            return null;
        }

        switch (this.textureDisplay) {
            case TextureDisplay.Solid3D:
                return null;
            case TextureDisplay.Deformation:
                return this.design?.baseplateDesignData.DisplacementMesh;
            case TextureDisplay.EquivalentStress:
                return this.design?.baseplateDesignData.StressMesh;
            case TextureDisplay.PlasticStrain:
                return this.design?.baseplateDesignData.StrainMesh;
            case TextureDisplay.StressInConcrete:
                return this.design?.baseplateDesignData.ConcreteStressMesh;
            default: throw new Error('Unsupported texture display type.');
        }
    }

    private get baseplateDesignImagesUnitGroup() {
        if (this.design?.baseplateDesignData == null || this.textureDisplay == null) {
            return UnitGroup.None;
        }

        switch (this.textureDisplay) {
            case TextureDisplay.Solid3D:
                return UnitGroup.None;
            case TextureDisplay.Deformation:
                return UnitGroup.Length;
            case TextureDisplay.EquivalentStress:
                return UnitGroup.Stress;
            case TextureDisplay.PlasticStrain:
                return UnitGroup.Percentage;
            case TextureDisplay.StressInConcrete:
                return UnitGroup.Stress;
            default: throw new Error('Unsupported texture display type.');
        }
    }

    public ngOnInit(): void {
        this.design = this.userService.design;
    }

    public ngOnDestroy(): void {
        if (this.design != undefined) {
            // angular bug: template render is still called multiple times after ngOnDestroy for some reason
            // this causes errors since this.design is already null
            setTimeout(() => {
                this.design = undefined;
            }, 100);
        }
    }
}
