import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { AnchorFilterCLEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IAnchorFilterConstructor extends ICodeListConstructor {
    anchorFilterGroupId?: number;
}

export class AnchorFilter extends CodeList {
    public anchorFilterGroupId?: number;

    constructor(codeList: IAnchorFilterConstructor) {
        super(codeList);

        if (codeList != null) {
            this.anchorFilterGroupId = codeList.anchorFilterGroupId;
        }
    }

    public static fromService(codeList: AnchorFilterCLEntity) {
        return new AnchorFilter(CodeList.baseFromService<IAnchorFilterConstructor>('AnchorFilterEntity', codeList, {
            anchorFilterGroupId: codeList.AnchorFilterGroupId
        } as IAnchorFilterConstructor));
    }

    public override cloneDeep() {
        const codeList: AnchorFilter = super.cloneDeep() as AnchorFilter;
        codeList.anchorFilterGroupId = this.anchorFilterGroupId;

        return codeList;
    }
}
