import escape from 'lodash-es/escape';

import { HttpResponseBase } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap/tooltip/tooltip';
import {
    CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    RadioButtonItem, RadioButtonProps
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    format, formatKeyValue
} from '@profis-engineering/pe-ui-common/helpers/string-helper';

import {
    IntegrationDataResponse
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities';
import {
    DataIntegrationRequestType, DataIntegrationType, ErrorType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import {
    DlubalNodesResponse, DlubalResponse, DlubalWriteCommentRequest
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Dlubal';
import {
    DlubalApplicationInstanceType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.IntegrationTypes.Dlubal.Enums';
import { SourceFile } from '../../../shared/components/dlubal-export';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { OfflineService } from '../../services/offline.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { UserService } from '../../services/user.service';

enum ExportData {
    Filename,
    Anchors,
}

@Component({
    templateUrl: './dlubal-export.component.html',
    styleUrls: ['./dlubal-export.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DlubalExportComponent implements OnInit, OnChanges {
    @Input()
    modalInstance!: ModalInstance;

    public rstabLiveInstance = false;
    public rfemLiveInstance = false;
    public tooltip!: string;

    public exportCheckbox!: CheckboxButtonProps<ExportData>;
    public nodesCheckbox!: CheckboxButtonProps<string>;
    public sourceFileRadio!: RadioButtonProps<SourceFile>;

    public nodeErrorText!: string;
    public nodeErrorVisible = false;

    public lockedControls = false;

    public submitted = false;

    @ViewChild('manualSelectionTooltip')
    public manualSelectionTooltip!: NgbTooltip;

    private componentInitialized = false;

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private offline: OfflineService,
        private integrationsData: IntegrationsDataService,
        private modal: ModalService,
        private sharedEnvironmentData: SharedEnvironmentService
    ) { }

    public get preventClosing(): boolean {
        return this.submitted || this.lockedControls;
    }

    public get validSourceSelected() {
        return this.sourceFileRadio.selectedValue != null
            &&
            (
                this.sourceFileRadio.selectedValue === SourceFile.RSTAB8
                ||
                this.sourceFileRadio.selectedValue === SourceFile.RFEM5
            );
    }

    private get exportCheckboxAnchorText() {
        if (this.exportCheckbox.selectedValues?.has(ExportData.Anchors)) {
            return '\n';
        }

        return '';
    }

    private get canLockLicense() {
        return !this.offline.isOffline && this.sourceFileRadio.selectedValue != null;
    }

    private get instanceType() {
        return this.sourceFileRadio.selectedValue == SourceFile.RFEM5
            ? DlubalApplicationInstanceType.RFEM5
            : DlubalApplicationInstanceType.RSTAB8;
    }

    ngOnInit(): void {
        // don't close the modal if calculate all is pending
        this.modalInstance.setOnClosing(() => {
            if (this.preventClosing) {
                return false;
            }

            this.integrationsData.requestDataDlubal(DataIntegrationRequestType.UnlockLicense, DlubalApplicationInstanceType.RFEM5);
            this.integrationsData.requestDataDlubal(DataIntegrationRequestType.UnlockLicense, DlubalApplicationInstanceType.RSTAB8);
            return true;
        });

        const addHiltiName = (this.user.design.anchorFamily?.mechName?.toUpperCase() != 'REBAR')
            ? 'Hilti '
            : '';
        this.exportCheckbox = {
            items: [
                {
                    text: this.user.design.designData.projectDesign?.DesignName ?? '',
                    value: ExportData.Filename
                },
                {
                    text: `${(<any[]>this.user.design.model[UIProperty.AnchorLayout_CustomLayoutPoints]).length} x ${addHiltiName}${this.user.design.anchorType?.name} ${this.user.design.anchorSize?.name}`,
                    value: ExportData.Anchors
                }
            ],
            selectedValues: new Set()
        };
        this.exportCheckbox.selectedValues?.add(ExportData.Filename);
        this.exportCheckbox.selectedValues?.add(ExportData.Anchors);

        this.nodesCheckbox = {
            items: [],
            selectedValues: new Set()
        };

        this.sourceFileRadio = {
            items: [
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.RSTAB8'),
                    value: SourceFile.RSTAB8
                },
                {
                    text: this.localization.getString('Agito.Hilti.Profis3.DlubalImport.RFEM5'),
                    value: SourceFile.RFEM5
                }
            ],
            selectedValue: undefined
        };
        this.updateSourceFileRadioItemsDisabled();

        this.tooltip = format(
            this.localization.getString('Agito.Hilti.Profis3.DlubalImport.Tooltip'),
            this.localization.getString('Agito.Hilti.Profis3.DlubalImport.SelectionFinished')
        );
    }

    public ngOnChanges(): void {
        if (!this.componentInitialized) {
            this.checkLiveInstanceNewIntegration();
            this.componentInitialized = true;
        }
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public refresh() {
        if (this.submitted) {
            return;
        }

        this.submitted = true;
        this.integrationsData.requestDataDlubal(
            DataIntegrationRequestType.UnlockLicense,
            DlubalApplicationInstanceType.RFEM5,
            () => this.handleServiceError(),
            () => {
                this.integrationsData.requestDataDlubal(
                    DataIntegrationRequestType.UnlockLicense,
                    DlubalApplicationInstanceType.RSTAB8,
                    () => this.handleServiceError(),
                    () => {
                        this.checkLiveInstanceNewIntegration();
                    });
            })
            .catch((err) => this.handleServiceError(err, true));
    }

    public export() {
        if (this.lockedControls || !this.validSourceSelected) {
            return;
        }

        this.user.design.usageCounter.DlubalExport++;

        const message = (
            this.exportCheckbox.selectedValues?.has(ExportData.Filename)
                ? (this.exportCheckbox.items as CheckboxButtonItem<ExportData>[])[0].text + this.exportCheckboxAnchorText
                : ''
        )
            +
            (
                this.exportCheckbox.selectedValues?.has(ExportData.Anchors)
                    ? (this.exportCheckbox.items as CheckboxButtonItem<ExportData>[])[1].text
                    : ''
            );
        const nodes = this.nodesToInts();

        if (
            this.exportCheckbox.selectedValues?.has(ExportData.Filename)
            ||
            this.exportCheckbox.selectedValues?.has(ExportData.Anchors)
        ) {
            this.submitted = true;
            if (!this.offline.isOffline) {
                const writeCommentRequest = <DlubalWriteCommentRequest>{
                    DataIntegrationType: DataIntegrationType.Dlubal,
                    ApplicationInstanceType: this.instanceType,
                    RequestType: DataIntegrationRequestType.WriteComment,
                    Message: message,
                    Nodes: nodes
                };

                this.integrationsData.requestData<DlubalResponse>(
                    DataIntegrationType.Dlubal,
                    DataIntegrationRequestType.WriteComment,
                    () => {
                        this.handleServiceError();
                    },
                    () => {
                        this.submitted = false;
                        this.close();
                    },
                    writeCommentRequest)
                    .catch((err: any) => this.handleServiceError(err, true));
            }
        }
    }

    public manualSelection() {
        if (this.submitted) {
            return;
        }

        if (this.lockedControls) {
            this.lockLicense();
        }
        else {
            this.unlockLicenseNewIntegration();
        }
    }

    public sourceFileRadioSelectedValueChange() {
        this.connectInstance();
    }

    private handleServiceError(response?: HttpResponseBase, logError = false, unlock = false) {
        if (logError && response instanceof Error) {
            console.error(response);
        }

        if (unlock) {
            this.setLockedControls(false);
        }

        this.submitted = false;

        this.modal.openAlertError(
            this.localization.getString('Agito.Hilti.Profis3.DlubalImport.ServiceError.Title'),
            this.createDownloadUrlMessage('Agito.Hilti.Profis3.DlubalImport.ServiceError.Message'),
            {
                response,
                endPointUrl: this.integrationsData.requestUrl,
                requestPayload: this.integrationsData.requestDataObject
            }
        );
    }

    private handleMultipleOpenModelsError(): void {
        this.submitted = false;
        this.modal.openAlertWarning(
            this.localization.getString('Agito.Hilti.Profis3.DlubalExport.MultipleModels.Title'),
            this.localization.getString('Agito.Hilti.Profis3.DlubalExport.MultipleModels.Text')
        );
    }

    private validateResponse(response: IntegrationDataResponse): boolean {
        if (response.ErrorType === ErrorType.IntegrationInternalError ||
            response.ErrorType === ErrorType.ModelNotOpened ||
            response.ErrorType === ErrorType.NoInstanceRunning) {
            return false;
        }

        return true;
    }

    private checkLiveInstanceNewIntegration() {
        this.submitted = true;

        this.integrationsData.requestDataDlubal<DlubalResponse>(
            DataIntegrationRequestType.RunningInstance,
            DlubalApplicationInstanceType.RFEM5,
            () => {
                this.handleServiceError();
            },
            (response) => {
                if (response.ErrorType === ErrorType.MultipleInstancesRunning) {
                    this.handleMultipleOpenModelsError();

                    return;
                }

                this.rfemLiveInstance = response.ErrorType === ErrorType.None ? true : false;

                this.integrationsData.requestDataDlubal<DlubalResponse>(
                    DataIntegrationRequestType.RunningInstance,
                    DlubalApplicationInstanceType.RSTAB8,
                    () => {
                        this.handleServiceError();
                    },
                    (response2) => {
                        if (response2.ErrorType === ErrorType.MultipleInstancesRunning) {
                            this.handleMultipleOpenModelsError();

                            return;
                        }

                        this.rstabLiveInstance = response2.ErrorType === ErrorType.None ? true : false;

                        const tmp = this.sourceFileRadio.selectedValue;
                        if (this.rstabLiveInstance) {
                            this.sourceFileRadio.selectedValue = SourceFile.RSTAB8;
                        }
                        else if (this.rfemLiveInstance) {
                            this.sourceFileRadio.selectedValue = SourceFile.RFEM5;
                        }
                        else {
                            this.submitted = false;
                        }

                        this.updateSourceFileRadioItemsDisabled();

                        if (tmp == this.sourceFileRadio.selectedValue) {
                            this.submitted = false;
                        }
                        else {
                            this.sourceFileRadioSelectedValueChange();
                        }
                    })
                    .catch((err) => this.handleServiceError(err, true));
            })
            .catch((err) => this.handleServiceError(err, true));
    }

    private connectInstance() {
        if (!this.offline.isOffline) {
            if (!this.validSourceSelected) {
                return;
            }

            this.submitted = true;
            const oldInstanceType = this.instanceType == DlubalApplicationInstanceType.RFEM5
                ? DlubalApplicationInstanceType.RSTAB8
                : DlubalApplicationInstanceType.RFEM5;

            this.integrationsData.requestDataDlubal<DlubalResponse>(DataIntegrationRequestType.UnlockLicense, oldInstanceType)
                .catch((err: any) => this.handleServiceError(err, true));

            this.integrationsData.requestDataDlubal<DlubalNodesResponse>(
                DataIntegrationRequestType.GetNodes,
                this.instanceType,
                () => {
                    this.handleServiceError();
                },
                (response: DlubalNodesResponse) => {
                    return this.connectInstanceHandleResponse(response);
                })
                .catch((err: any) => this.handleServiceError(err, true));
        }
    }

    private connectInstanceHandleResponse(response: DlubalNodesResponse) {
        const validResponse = this.validateResponse(response);
        if (!validResponse) {
            this.handleServiceError();
            return;
        }

        if (response.ErrorType === ErrorType.MultipleInstancesRunning) {
            this.handleMultipleOpenModelsError();
            return;
        }

        const nodesCheckboxItems: CheckboxButtonItem<string>[] = [];
        const nodesCheckboxSelectedValues = new Set<string>();
        response.Nodes.forEach((nodeText: any) =>
            nodesCheckboxItems.push({
                value: nodeText,
                text: nodeText
            })
        );

        if (response.SelectedNodes && response.SelectedNodes.length > 0) {
            const value = response.SelectedNodes.join(',');

            if (!nodesCheckboxItems.some(item => item.value == value)) {
                nodesCheckboxItems.push({
                    value,
                    text: value
                });
            }

            nodesCheckboxSelectedValues.add(value);
        }
        else {
            for (const node of nodesCheckboxItems) {
                nodesCheckboxSelectedValues.add(node.value);
            }
        }

        this.nodesCheckbox.items = nodesCheckboxItems;
        this.nodesCheckbox.selectedValues = nodesCheckboxSelectedValues;

        this.submitted = false;
    }

    /**
     * Unlocks the license using the new integration
     */
    private unlockLicenseNewIntegration() {
        this.submitted = true;

        // Unlock both applications
        this.integrationsData.requestDataDlubal<DlubalResponse>(
            DataIntegrationRequestType.UnlockLicense,
            DlubalApplicationInstanceType.RFEM5,
            () => {
                this.setLockedControls(false);
                this.handleServiceError();
            },
            (response: { ErrorType: ErrorType }) => {
                if (response.ErrorType === ErrorType.IntegrationInternalError) {
                    this.handleServiceError();
                    this.setLockedControls(false);
                    return;
                }

                if (response.ErrorType === ErrorType.MultipleInstancesRunning) {
                    this.handleMultipleOpenModelsError();
                    this.setLockedControls(false);
                    return;
                }

                this.integrationsData.requestDataDlubal<DlubalResponse>(
                    DataIntegrationRequestType.UnlockLicense,
                    DlubalApplicationInstanceType.RSTAB8,
                    () => {
                        this.setLockedControls(false);
                        this.handleServiceError();
                    },
                    (response2: { ErrorType: ErrorType }) => {
                        this.submitted = false;

                        if (response2.ErrorType === ErrorType.MultipleInstancesRunning) {
                            this.handleMultipleOpenModelsError();
                            this.setLockedControls(false);

                            return;
                        }

                        if (response2.ErrorType === ErrorType.IntegrationInternalError) {
                            this.handleServiceError();
                            this.setLockedControls(false);

                            return;
                        }

                        this.setLockedControls(true);
                    })
                    .catch((err: any) => this.handleServiceError(err, true, true));
            })
            .catch((err: any) => this.handleServiceError(err, true, true));
    }

    private lockLicense() {
        if (this.canLockLicense) {
            this.submitted = true;
            this.setLockedControls(true);

            this.integrationsData.requestDataDlubal<DlubalNodesResponse>(
                DataIntegrationRequestType.GetNodes,
                this.instanceType,
                () => {
                    this.setLockedControls(false);
                    this.handleServiceError();
                },
                (response: DlubalNodesResponse) => {
                    const validResponse = this.validateResponse(response);
                    if (!validResponse) {
                        this.handleServiceError();

                        return;
                    }

                    if (response.SelectedNodes && response.SelectedNodes.length > 0) {
                        const value = response.SelectedNodes.join(',');

                        if (!this.nodesCheckbox.items?.some(item => item.value == value)) {
                            this.nodesCheckbox.items?.push({
                                value,
                                text: value
                            });
                        }

                        this.nodesCheckbox.selectedValues?.add(value);
                    }

                    this.setLockedControls(false);
                    this.submitted = false;
                })
                .catch((err: any) => this.handleServiceError(err, true, true));
        }
    }

    private nodesToInts() {
        const res: number[] = [];
        this.nodesCheckbox.selectedValues?.forEach(n => {
            n.split(',').forEach(el => {
                if (el.indexOf('-') < 0) {
                    res.push(parseInt(el, 10));
                }
                else {
                    const n2 = el.split('-');
                    for (let i = parseInt(n2[0], 10); i <= parseInt(n2[1], 10); i++) {
                        res.push(i);
                    }
                }
            });
        });
        return res;
    }

    private createDownloadUrlMessage(messageKey: string) {
        const downloadUrl = `<a href="${escape(this.sharedEnvironmentData.data?.thirdPartyInterfaceDownloadUrl)}" class="download-link" target="_blank">here.</a>`;
        return `<html lang="en">${formatKeyValue(escape(this.localization.getString(messageKey)), { downloadUrl: downloadUrl })}</html>`;
    }

    private updateSourceFileRadioItemsDisabled() {
        ((this.sourceFileRadio.items as RadioButtonItem<SourceFile>[])
            .find(item => item.value === SourceFile.RSTAB8) as RadioButtonItem<SourceFile>)
            .disabled = !this.rstabLiveInstance;

        ((this.sourceFileRadio.items as RadioButtonItem<SourceFile>[])
            .find(item => item.value === SourceFile.RFEM5) as RadioButtonItem<SourceFile>)
            .disabled = !this.rfemLiveInstance;
    }

    private setLockedControls(locked: boolean) {
        if (this.lockedControls == locked) {
            return;
        }

        this.lockedControls = locked;

        if (locked) {
            this.manualSelectionTooltip.open();
        }
        else {
            this.manualSelectionTooltip.close();
        }
    }
}
