<pe-modal-header
    [closeButtonId]="'import-loads-close-button'"
    [title]="translate('Agito.Hilti.Profis3.DlubalImport.Title')"
    [modalInstance]="modalInstance"
    [closeButtonDisabled]="preventClosing">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" novalidate>
    <pe-modal-body>
        <div class="modal-body-content">
            <div class="left-side">
                <div class="title-button">
                    <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.SourceFile')"></p>

                    <span class="pe-ui-pe-sprite pe-ui-pe-sprite-refresh-l-dark refresh-detected-nodes"
                        [class.disabled]="submitted"
                        (click)="refreshInstance(true)"
                        [ngbTooltip]="translate('Agito.Hilti.Profis3.DlubalImport.RefresDetectedNodes')">
                    </span>
                </div>

                <div class="block">
                    <pe-radio-button
                        [items]="sourceFileRadio.items"
                        [selectedValue]="sourceFileRadio.selectedValue"
                        (selectedValueChange)="sourceFileRadio.selectedValue = $any($event).detail; sourceFileRadioSelectedValueChange()"
                        [disabled]="refreshingNodes">
                    </pe-radio-button>

                    <div class="orientation-column orientation-left">
                        <div class="title-button">
                            <p class="title-strike title" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.DetectedNodes')"></p>
                        </div>

                        <div class="block">
                            <span class="detected-nodes" [innerHtml]="detectedNodesFormatted"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right-side">
                <div *ngIf="!refreshOverlayVisible">
                    <p class="title-strike title">
                        <span class="title-label" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.PositionAndOrientationOfNodes')"></span>

                        <span class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip"></span>
                    </p>

                    <div class="box">
                        <div class="node-position-orientation-wrapper box-part">
                            <div class="node-position-list">
                                <button type="button" class="node-button"
                                    [class.selected]="nodePosition == nodePositionEnum.Top"
                                    (click)="nodePosition = nodePositionEnum.Top"
                                    [disabled]="submitted">
                                    <span class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-top"></span>
                                </button>

                                <button type="button" class="node-button"
                                    [class.selected]="nodePosition == nodePositionEnum.Bottom"
                                    (click)="nodePosition = nodePositionEnum.Bottom"
                                    [disabled]="submitted">
                                    <span class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-bottom"></span>
                                </button>

                                <button type="button" class="node-button"
                                    [class.selected]="nodePosition == nodePositionEnum.Side"
                                    (click)="nodePosition = nodePositionEnum.Side"
                                    [disabled]="submitted">
                                    <span class="image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-dlubal-nodes-side"></span>
                                </button>
                            </div>
                            <div class="node-orientation-list" style="display: flex">
                                <button type="button" class="node-button"
                                    [class.selected]="nodeOrientation == nodeOrientationEnum.XMinus"
                                    (click)="nodeOrientation = nodeOrientationEnum.XMinus"
                                    [disabled]="submitted"
                                    [ngStyle]="{ 'order': '1' }">
                                    <span [ngClass]="xMinusClass"></span>
                                </button>

                                <button type="button" class="node-button"
                                    [class.selected]="nodeOrientation == nodeOrientationEnum.XPlus"
                                    (click)="nodeOrientation = nodeOrientationEnum.XPlus"
                                    [disabled]="submitted"
                                    [ngStyle]="{ 'order': '2' }">
                                    <span [ngClass]="xPlusClass"></span>
                                </button>

                                <button type="button" class="node-button"
                                    [class.selected]="nodeOrientation == nodeOrientationEnum.YPlus"
                                    (click)="nodeOrientation = nodeOrientationEnum.YPlus"
                                    [disabled]="submitted"
                                    [ngStyle]="{ 'order': yplusLocation }">
                                    <span [ngClass]="yPlusClass"></span>
                                </button>

                                <button type="button" class="node-button"
                                    [class.selected]="nodeOrientation == nodeOrientationEnum.YMinus"
                                    (click)="nodeOrientation = nodeOrientationEnum.YMinus"
                                    [disabled]="submitted"
                                    [ngStyle]="{ 'order': yminusLocation }">
                                    <span [ngClass]="yMinusClass"></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="title-button">
                        <p class="title-strike title">
                            <span class="title-label" [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.ResultCombinationImports')"></span>

                            <button type="button" class="control-tooltip-popup pe-ui-pe-sprite pe-ui-pe-sprite-info-tooltip"
                                (click)="openCombinationImportPopup()"
                                [ngbTooltip]="translate('Agito.Hilti.Profis3.DlubalImport.CombinationImportPopup')">
                            </button>
                        </p>

                        <button type="button" class="select-all-loads"
                            [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.SelectAll')"
                            (click)="selectAll()"
                            [hidden]="importLoadsCheckbox.items == null || importLoadsCheckbox.items.length == 0"
                            [disabled]="submitted">
                        </button>
                    </div>

                    <div class="box">
                        <div class="loads-to-import box-part">
                            <pe-checkbox-button
                                [items]="importLoadsCheckbox.items"
                                [selectedValues]="importLoadsCheckbox.selectedValues"
                                (selectedValuesChange)="importLoadsCheckbox.selectedValues = $any($event).detail"
                                (itemInfoButtonClick)="openRCLoadPopup($any($event).detail)"
                                [disabled]="submitted">
                            </pe-checkbox-button>
                        </div>

                        <div class="result-combination-imports box-part" *ngIf="isRCloadSelected()">
                            <pe-radio-button
                                [items]="resultCombinationImportsRadio.items"
                                [selectedValue]="resultCombinationImportsRadio.selectedValue"
                                (selectedValueChange)="resultCombinationImportsRadio.selectedValue = $any($event).detail"
                                [disabled]="submitted">
                            </pe-radio-button>
                        </div>
                    </div>
                </div>

                <div class="refresh-nodes-overlay" *ngIf="refreshOverlayVisible">
                    <p class="refresh-nodes-overlay-description"
                        [innerHtml]="translate('Agito.Hilti.Profis3.DlubalImport.RefreshOverlayDescription')">
                    </p>

                    <pe-button
                        class="refresh-nodes-overlay-refresh"
                        [look]="'Default'"
                        (buttonClick)="refreshInstance(true)"
                        [disabled]="submitted || refreshingNodes">
                        <pe-loading-button
                            [loading]="submitted || refreshingNodes"
                            [text]="translate('Agito.Hilti.Profis3.DlubalImport.RefreshOverlayRefresh')">
                        </pe-loading-button>
                    </pe-button>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            class="modal-button"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.DlubalImport.Cancel')"
            [disabled]="preventClosing">
        </pe-button>

        <pe-button
            type="button"
            class="modal-button"
            [look]="'Cta'"
            (buttonClick)="importDlubal()"
            [disabled]="(ngForm.enabled && !ngForm.valid) || !validSourceSelected || submitted || refreshOverlayVisible || importButtonLocked || numberOfSelectedLoadCombinations() < 1">
            <pe-loading-button
                [loading]="submitted || refreshingNodes"
                [text]="translate('Agito.Hilti.Profis3.DlubalImport.Import')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
