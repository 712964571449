<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.CADExport.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="section-row">
        <span class="local-regulation-disclaimer" [innerHtml]="translate('Agito.Hilti.Profis3.CADExport.LocalRegulationDisclaimer')"></span>
    </div>

    <span class="text cad-export-message" [innerHtml]="translate('Agito.Hilti.Profis3.CADExport.ExportMessage')"></span>

    <div class="checkbox data-item">
        <pe-checkbox-button
            [items]="exportBaseplateBox.items"
            [selectedValues]="exportBaseplateBox.selectedValues"
            (selectedValuesChange)="exportBaseplateBox.selectedValues = $any($event).detail"
            [disabled]="!(design.designData.projectDesign.IsAnchorPlatePresent)">
        </pe-checkbox-button>
    </div>

    <div class="checkbox data-item">
        <pe-checkbox-button
            [items]="exportProfileBox.items"
            [selectedValues]="exportProfileBox.selectedValues"
            (selectedValuesChange)="onExportProfileBoxChange($any($event).detail)"
            [disabled]="!(design.designData.projectDesign.IsProfilePresent)">
        </pe-checkbox-button>
    </div>

    <div class="checkbox data-item">
        <pe-checkbox-button
            [items]="exportAnchorsBox.items"
            [selectedValues]="exportAnchorsBox.selectedValues"
            (selectedValuesChange)="onExportAnchorsBoxChange($any($event).detail)">
        </pe-checkbox-button>
    </div>

    <div class="checkbox data-item">
        <pe-checkbox-button
            [items]="exportChemicalBox.items"
            [selectedValues]="exportChemicalBox.selectedValues"
            (selectedValuesChange)="onExportChemicalBoxChange($any($event).detail)"
            [disabled]="!isSelectedAnchorChemical">
        </pe-checkbox-button>
    </div>

    <div class="checkbox data-item" *ngIf="showBlock">
        <pe-checkbox-button
            [items]="exportBaseMaterialBox.items"
            [selectedValues]="exportBaseMaterialBox.selectedValues"
            (selectedValuesChange)="exportBaseMaterialBox.selectedValues = $any($event).detail">
        </pe-checkbox-button>
    </div>

    <div class="checkbox data-item" *ngIf="showWelds">
        <pe-checkbox-button
            [items]="exportWeldsBox.items"
            [selectedValues]="exportWeldsBox.selectedValues"
            (selectedValuesChange)="exportWeldsBox.selectedValues = $any($event).detail"
            [disabled]="!areWeldsPresent">
        </pe-checkbox-button>
    </div>

    <div class="checkbox data-item" *ngIf="showStiffeners">
        <pe-checkbox-button
            [items]="exportStiffenersBox.items"
            [selectedValues]="exportStiffenersBox.selectedValues"
            (selectedValuesChange)="exportStiffenersBox.selectedValues = $any($event).detail"
            [disabled]="!areStiffenersPresent">
        </pe-checkbox-button>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        id="cad-export-cancel-button"
        class="modal-button"
        [look]="'Default'"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.CADExport.Cancel')"
        [disabled]="submitted">
    </pe-button>

    <pe-button
        id="cad-export-save-button"
        class="modal-button"
        [look]="'Cta'"
        (buttonClick)="export()"
        [disabled]="submitted || !isOneCheckboxChecked">
        <pe-loading-button
            [loading]="submitted"
            [text]="translate('Agito.Hilti.Profis3.CADExport.Export')">
        </pe-loading-button>
    </pe-button>
</pe-modal-footer>

<form #cadExportForm id="cadExportForm" name="CadClickForm" [attr.action]="cadClickWebServiceUrl" method="POST">
    <input type="hidden" name="zipContent" id="zipContent" />
</form>
