<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.Main.AdvancedBaseplateLicenseRequired.Title')">
</pe-modal-header>

<pe-modal-body>
    <p class="message" [innerHtml]="translate('Agito.Hilti.Profis3.Main.AdvancedBaseplateLicenseRequired.Message')"></p>
</pe-modal-body>

<pe-modal-footer>
    <pe-button [id]="'add-edit-design-cancel-button'" [look]="'Cta'" (buttonClick)="close()" [text]="translate('Agito.Hilti.Profis3.Close')"></pe-button>
</pe-modal-footer>
