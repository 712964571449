import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { NumericTextBoxProps } from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import { IBrickSizeComponentInput } from '../../../shared/components/brick-size';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { UserService } from '../../services/user.service';

interface IUIProp extends IValueRange {
    disabled?: boolean;
}

@Component({
    templateUrl: './brick-size.component.html',
    styleUrls: ['./brick-size.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class BrickSizeComponent implements OnInit {
    public submitted = false;
    public pendingSave = false;

    public widthTextBox!: NumericTextBoxProps;
    public widthRange!: IUIProp;
    public heightTextBox!: NumericTextBoxProps;
    public heightRange!: IUIProp;
    public thicknessTextBox!: NumericTextBoxProps;
    public thicknessRange!: IUIProp;

    public requiredValidator = Validators.required;

    @Input()
    public modalInstance!: ModalInstance<IBrickSizeComponentInput>;

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private calculationService: CalculationServicePE
    ) { }

    public get areInputsDisabled() {
        return this.widthRange.disabled
            && this.heightRange.disabled
            && this.thicknessRange.disabled;
    }

    public get formValid() {
        return (this.widthTextBox?.isValid ?? false)
            && (this.heightTextBox?.isValid ?? false)
            && (this.thicknessTextBox?.isValid ?? false);
    }

    private get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        // don't close the modal if save is pending
        this.modalInstance.setOnClosing(() => {
            return this.pendingSave
                ? false
                : true;
        });

        this.widthRange = this.getPropertyValueRange(PropertyMetaData.BaseMaterial_BrickWidth.id);
        this.widthTextBox = {
            id: 'brick-size-input-width',
            title: this.localization.getString('Agito.Hilti.Profis3.BrickSize.Width'),
            unit: this.design.unitLength,
            minValue: this.widthRange.min,
            maxValue: this.widthRange.max
        };

        this.heightRange = this.getPropertyValueRange(PropertyMetaData.BaseMaterial_BrickHeight.id);
        this.heightTextBox = {
            id: 'brick-size-input-height',
            title: this.localization.getString('Agito.Hilti.Profis3.BrickSize.Height'),
            unit: this.design.unitLength,
            minValue: this.heightRange.min,
            maxValue: this.heightRange.max
        };

        this.thicknessRange = this.getPropertyValueRange(PropertyMetaData.BaseMaterial_BrickLength.id);
        this.thicknessTextBox = {
            id: 'brick-size-input-thickness',
            title: this.localization.getString('Agito.Hilti.Profis3.BrickSize.Thickness'),
            unit: this.design.unitLength,
            minValue: this.thicknessRange.min,
            maxValue: this.thicknessRange.max
        };

        if (this.design.brickSize != null) {
            this.widthTextBox.value = this.design.brickSize.Width;
            this.heightTextBox.value = this.design.brickSize.Height;
            this.thicknessTextBox.value = this.design.brickSize.Thickness;
        }
    }

    public save(form: NgForm) {
        if (this.submitted || !this.formValid || (form.enabled && !form.valid)) {
            return;
        }

        this.submitted = true;
        this.pendingSave = true;

        this.calculationService
            .calculateAsync(this.design,
                (design) => {
                    design.brickSize = {
                        Width: this.widthTextBox.value ?? 0,
                        Height: this.heightTextBox.value ?? 0,
                        Thickness: this.thicknessTextBox.value ?? 0,
                    };
                },
                { suppressLoadingFlag: true }
            )
            .finally(() => {
                this.pendingSave = false;
            })
            .then(() => {
                if (this.modalInstance.input?.onSave != null) {
                    this.modalInstance.input.onSave(this.design.brickSize);
                }

                this.close();
            })
            .catch((err) => {
                if (err instanceof Error) {
                    console.error(err);
                }

                this.submitted = false;
            });
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    private getPropertyValueRange(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        const retVal: IUIProp = {
            min: propertyInfo.min,
            max: propertyInfo.max,
            disabled: propertyInfo.disabled
        };
        return retVal;
    }
}
