<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.BaseplateSize.Title')">
</pe-modal-header>

<form class="form" name="form" #ngForm="ngForm" (ngSubmit)="save(ngForm)" novalidate>
    <pe-modal-body>
        <div class="section">
            <div class="control-row" *ngIf="widthVisible">
                <pe-numeric-text-box
                    [id]="widthTextBox.id"
                    [title]="widthTextBox.title"
                    [value]="widthTextBox.value"
                    (valueChange)="widthTextBox.value = $any($event).detail"
                    [unit]="widthTextBox.unit"
                    [minValue]="widthTextBox.minValue"
                    [maxValue]="widthTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="widthTextBox.isValid = $any($event).detail">
                </pe-numeric-text-box>
            </div>

            <div class="control-row" *ngIf="heightVisible">
                <pe-numeric-text-box
                    [id]="heightTextBox.id"
                    [title]="heightTextBox.title"
                    [value]="heightTextBox.value"
                    (valueChange)="heightTextBox.value = $any($event).detail"
                    [unit]="heightTextBox.unit"
                    [minValue]="heightTextBox.minValue"
                    [maxValue]="heightTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="heightTextBox.isValid = $any($event).detail">
                </pe-numeric-text-box>
            </div>

            <div class="control-row" *ngIf="sideLengthVisible">
                <pe-numeric-text-box
                    [id]="sideLengthTextBox.id"
                    [title]="sideLengthTextBox.title"
                    [value]="sideLengthTextBox.value"
                    (valueChange)="sideLengthTextBox.value = $any($event).detail"
                    [unit]="sideLengthTextBox.unit"
                    [minValue]="sideLengthTextBox.minValue"
                    [maxValue]="sideLengthTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="sideLengthTextBox.isValid = $any($event).detail">
                </pe-numeric-text-box>
            </div>

            <div class="control-row" *ngIf="diameterVisible">
                <pe-numeric-text-box
                    [id]="diameterTextBox.id"
                    [title]="diameterTextBox.title"
                    [value]="diameterTextBox.value"
                    (valueChange)="diameterTextBox.value = $any($event).detail"
                    [unit]="diameterTextBox.unit"
                    [minValue]="diameterTextBox.minValue"
                    [maxValue]="diameterTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="diameterTextBox.isValid = $any($event).detail">
                </pe-numeric-text-box>
            </div>

            <div class="control-row" *ngIf="thicknessVisible">
                <pe-numeric-text-box
                    [id]="thicknessTextBox.id"
                    [title]="thicknessTextBox.title"
                    [tooltip]="thicknessTextBox.tooltip"
                    [value]="thicknessTextBox.value"
                    (valueChange)="thicknessTextBox.value = $any($event).detail"
                    [unit]="thicknessTextBox.unit"
                    [minValue]="thicknessTextBox.minValue"
                    [maxValue]="thicknessTextBox.maxValue"
                    [updateOnBlur]="true"
                    [required]="true"
                    [validators]="[requiredValidator]"
                    (isValid)="thicknessTextBox.isValid = $any($event).detail">
                </pe-numeric-text-box>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            [id]="'baseplate-size-cancel-button'"
            [look]="'Default'"
            class="modal-button"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.BaseplateSize.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            [id]="'baseplate-size-save-button'"
            [look]="'Cta'"
            class="modal-button"
            type="submit"
            [disabled]="!formValid || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.Profis3.BaseplateSize.Save')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
