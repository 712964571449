import {
    ProfilePositionEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class ProfilePositionsAsadClass extends CodeList {

    public static fromService(codeList: ProfilePositionEntity) {
        return new ProfilePositionsAsadClass(CodeList.baseFromService('ProfilePositionsAsad', codeList));
    }

    public override cloneDeep(): ProfilePositionsAsadClass {
        const codeList: ProfilePositionsAsadClass = super.cloneDeep() as ProfilePositionsAsadClass;

        return codeList;
    }
}
