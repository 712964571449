import {
    ICodeListConstructor, ICodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import {
    BrickGroupEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import {
    BrickMaterial
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.TechnicalData.Enums';
import { CodeList } from './code-list';

export interface IBrickGroupConstructor extends ICodeListConstructor {
    descriptionFormat?: string;
    descriptionKeys?: { [key: string]: string };
    width?: number;
    height?: number;
    length?: number;
    brickMaterial?: BrickMaterial;
    coveredByETA?: boolean;
}

export class BrickGroup extends CodeList {
    public descriptionFormat?: string;
    public descriptionKeys?: { [key: string]: string };
    public width?: number;
    public height?: number;
    public length?: number;
    public brickMaterial?: BrickMaterial;
    public coveredByETA?: boolean;

    constructor(codeList: IBrickGroupConstructor) {
        super(codeList);

        if (codeList != null) {
            this.descriptionFormat = codeList.descriptionFormat;
            this.descriptionKeys = codeList.descriptionKeys;
            this.width = codeList.width;
            this.height = codeList.height;
            this.length = codeList.length;
            this.brickMaterial = codeList.brickMaterial;
            this.coveredByETA = codeList.coveredByETA;
        }
    }

    public static fromService(codeList: BrickGroupEntity) {
        return new BrickGroup(CodeList.baseFromService<IBrickGroupConstructor>('BrickGroupEntity', codeList, {
            image: codeList.Image != null && codeList.Image != '' ? 'sprite-masonry-' + codeList.Image : 'sprite-masonry-no-photo-available',
            descriptionFormat: codeList.DescriptionFormat,
            descriptionKeys: codeList.DescriptionKeys as any,
            width: codeList.Width,
            height: codeList.Height,
            length: codeList.Length,
            brickMaterial: codeList.BrickMaterial,
            coveredByETA: codeList.CoveredByETA
        } as IBrickGroupConstructor));
    }

    public override getTranslatedDescriptionText(codeListDeps: ICodeListTextDeps) {
        if (this.descriptionFormat == null) {
            return undefined;
        }

        if (codeListDeps.localizationService == null) {
            throw new Error('codeListDeps.localizationService not set!');
        }

        const localizationService = codeListDeps.localizationService;
        const descriptionFormat = localizationService.getString(this.descriptionFormat);
        const descriptionKeys = Object.fromEntries(
            Object.entries(this.descriptionKeys ?? {})
                .map(([key, descriptionKey]) => [key, localizationService.getString(descriptionKey)])
        ) as { [key: string]: string };
        return formatKeyValue(descriptionFormat, descriptionKeys);
    }

    public override cloneDeep() {
        const codeList: BrickGroup = super.cloneDeep() as BrickGroup;

        codeList.descriptionFormat = this.descriptionFormat;
        codeList.descriptionKeys = { ...this.descriptionKeys };
        codeList.width = this.width;
        codeList.height = this.height;
        codeList.length = this.length;
        codeList.brickMaterial = this.brickMaterial;
        codeList.coveredByETA = this.coveredByETA;

        return codeList;
    }
}
