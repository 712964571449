import combinationConcrete from './combination-concrete.png';
import combinationSteel from './combination-steel.png';
import shearBreakout from './shear-breakout.png';
import shearPryout from './shear-pryout.png';
import shearSteelLeverArm from './shear-steel-lever-arm.png';
import shearSteel from './shear-steel.png';
import shearSupplementaryReinforcementAnchorage from './shear-supplementary-reinforcement-anchorage.png';
import shearSupplementaryReinforcementSteel from './shear-supplementary-reinforcement-steel.png';
import shearSurfaceReinforcementSteel from './shear-surface-reinforcement-steel.png';
import tensionBreakout from './tension-breakout.png';
import tensionCombined from './tension-combined.png';
import tensionPullout from './tension-pullout.png';
import tensionSplitting from './tension-splitting.png';
import tensionSteel from './tension-steel.png';
import tensionSupplementaryReinforcementAnchorage from './tension-supplementary-reinforcement-anchorage.png';
import tensionSupplementaryReinforcementSteel from './tension-supplementary-reinforcement-steel.png';
import tensionSurfaceReinforcementSteel from './tension-surface-reinforcement-steel.png';
import shearConcreteBearing from './shear-concrete-bearing.png';
import shearConcreteAdditiveBearing from './shear-additive-concrete-bearing.png';

const images = {
    'combination-concrete.png': combinationConcrete,
    'combination-steel.png': combinationSteel,
    'shear-breakout.png': shearBreakout,
    'shear-pryout.png': shearPryout,
    'shear-steel-lever-arm.png': shearSteelLeverArm,
    'shear-steel.png': shearSteel,
    'shear-supplementary-reinforcement-anchorage.png': shearSupplementaryReinforcementAnchorage,
    'shear-supplementary-reinforcement-steel.png': shearSupplementaryReinforcementSteel,
    'shear-surface-reinforcement-steel.png': shearSurfaceReinforcementSteel,
    'tension-breakout.png': tensionBreakout,
    'tension-combined.png': tensionCombined,
    'tension-pullout.png': tensionPullout,
    'tension-splitting.png': tensionSplitting,
    'tension-steel.png': tensionSteel,
    'tension-supplementary-reinforcement-anchorage.png': tensionSupplementaryReinforcementAnchorage,
    'tension-supplementary-reinforcement-steel.png': tensionSupplementaryReinforcementSteel,
    'tension-surface-reinforcement-steel.png': tensionSurfaceReinforcementSteel,
    'shear-concrete-bearing.png': shearConcreteBearing,
    'shear-additive-concrete-bearing.png': shearConcreteAdditiveBearing
} satisfies Record<string, string>;

export default images as typeof images & { [imageName: string]: string };
