<div class="utilizations box-section box-section-white" *ngIf="showUtilizations">
    <div class="box-section-header-with-img" *ngIf="showAddOn">
        <div class="image-container"><span class="image pe-ui-pe-sprite pe-ui-pe-sprite-double-weld-location-small"></span></div>

        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.WeldsUtilizations')"></span>
        </div>

        <div class="add-on" [innerHtml]="translate('Agito.Hilti.Profis3.Main.Region.AddOn')"></div>
    </div>

    <div class="box-section-header-with-img" *ngIf="hasUtilizations">
        <div class="image-container"><span class="image pe-ui-pe-sprite pe-ui-pe-sprite-double-weld-location-small"></span></div>

        <div class="box-section-title drag-handle-static utilizaion-title-wrapper"
            [ngbTooltip]="translate('Agito.Hilti.Profis3.Main.Region.DragToMove')">
            <span class="bar-title" [innerHtml]="translate('Agito.Hilti.Profis3.WeldsUtilizations')"></span>
            <span class="bar-subtitle"
                *ngIf="!collapsed && areLoadCombinationsAvailable" [innerHtml]="loadCombination"></span>
        </div>

        <pe-utilization-bar [collapsed]="collapsed" [utilizationInvalid]="utilizationInvalid" [utilizationValue]="utilizationValue"></pe-utilization-bar>

        <pe-collapse
            [container]="'box-section-content'"
            [collapsed]="collapsed"
            (collapsedChange)="onCollapsedChanged($any($event).detail)">
        </pe-collapse>
    </div>

    <div class="items box-section-content" *ngIf="hasUtilizations">
        <div class="utilization" *ngIf="profileAnchorPlateWorst != null"
            [class.invalid-value]="profileAnchorPlateWorst.valueError">
            <div id="{{id}}-baseplate-utilizations-button" class="utilization-button">
                <div class="button-content">
                    <div class="text-progress-container">
                        <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.WeldsUtilizations.Welds.Utilization')"></p>
                        <div class="progress-percent-container" *ngIf="profileAnchorPlateWorst.value != null">
                            <div class="progress-percent" [ngStyle]="{ 'width': profileAnchorPlateWorst.value + '%' }"></div>
                        </div>
                    </div>

                    <div class="number-container">
                        <div class="worst-result details-line" *ngIf="profileAnchorPlateWorst.value != null"
                            [class.invalid-value]="profileAnchorPlateWorst.valueError">
                            <p class="text" [innerHtml]="translate('Agito.Hilti.Profis3.WeldsUtilizations.Welds')"></p>
                            <p class="number" [innerHtml]="unit.formatInternalValueAsDefault(profileAnchorPlateWorst.value, unitGroupEnum.Percentage)"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
