import { HttpRequest } from '@angular/common/http';
import {
    Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation
} from '@angular/core';
import { DefaultUnitConverter } from '@profis-engineering/gl-model/external/unit-converter';
import { ProfileShape as ModalProfileShape } from '@profis-engineering/pe-gl-model/components/profile';
import { calculateProfilePoints } from '@profis-engineering/pe-gl-model/components/profile-helper';
import {
    IStiffenerWeldPositionInfo, Stiffener
} from '@profis-engineering/pe-gl-model/components/stiffener';
import {
    getCustomStiffenerPosition, getCustomStiffenerValues, getStiffenerData, ICustomStiffenerPositionInfo, IStiffenerPositionInfo
} from '@profis-engineering/pe-gl-model/components/stiffener-helper';
import { IWeldInfo, WeldOnSection } from '@profis-engineering/pe-gl-model/components/welds';
import {
    convertWeldThicknessToSize, getDirectWeldPosition, getWeldPosition, isAdvancedWeldVisible
} from '@profis-engineering/pe-gl-model/components/welds-helper';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import { CheckboxButtonProps } from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import { SimpleCheckboxButtonHelper } from '@profis-engineering/pe-ui-common/helpers/simple-checkbox-button-helper';
import {
    environment
} from '../../../environments/environmentPe';
import {
    CADExportRequest, CADStiffener, CADTransformation, CADWeld
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.CAD';
import {
    UIProperty
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignType, ProfileShapeType as ProfileShape, StiffenerCrossSectionPart, StiffenerLocation,
    StiffenerShape as StiffenerShapeId, WeldLocation, WeldType as WeldingType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    AnchorType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.TechnicalData.Enums';
import {
    ApiService
} from '../../services/api.service';
import {
    LocalizationService
} from '../../services/localization.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import {
    UserSettingsService
} from '../../services/user-settings.service';
import {
    UserService
} from '../../services/user.service';
import { ProfileUpdate } from '../gl-model/Update/ProfileUpdate';


interface IProfileDimensions {
    width: number;
    width2?: number;
    height: number;
    thickness: number;
    flangeThickness: number;
    rotation: number;
    offsetX: number;
    offsetY: number;
}

interface IStiffenerDimensions {
    shape: StiffenerShapeId;
    width: number;
    height: number;
    thickness: number;
    weldThickness: number;
    horizontalEdgeLength: number;
    verticalEdgeLength: number;
    weldLocation: WeldLocation;
}

interface IStiffenerLayout {
    location: StiffenerLocation;
    section: StiffenerCrossSectionPart;
    stiffenerCount: number;
}

interface IStiffenersData {
    stiffenerData: ICustomStiffenerPositionInfo[] | IStiffenerPositionInfo[];
    profileDim: IProfileDimensions;
    stiffenerDim: IStiffenerDimensions;
}

@Component({
    templateUrl: './cad-export.component.html',
    styleUrls: ['./cad-export.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CadExportComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    @ViewChild('cadExportForm')
    public formCadExport!: ElementRef<HTMLFormElement>;

    public exportBaseplateBox!: CheckboxButtonProps<boolean>;
    public exportProfileBox!: CheckboxButtonProps<boolean>;
    public exportAnchorsBox!: CheckboxButtonProps<boolean>;
    public exportChemicalBox!: CheckboxButtonProps<boolean>;
    public exportBaseMaterialBox!: CheckboxButtonProps<boolean>;
    public exportWeldsBox!: CheckboxButtonProps<boolean>;
    public exportStiffenersBox!: CheckboxButtonProps<boolean>;

    public submitted = false;
    public cadClickWebServiceUrl = '';

    constructor(
        public localization: LocalizationService,
        private user: UserService,
        private apiService: ApiService,
        private userSettingsService: UserSettingsService,
        private sharedEnvironmentService : SharedEnvironmentService,
        private translationFormatService: TranslationFormatService,
    ) { }

    public get design() {
        return this.user.design;
    }

    public get isOneCheckboxChecked() {
        return SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportAnchorsBox)
            || SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportBaseplateBox)
            || SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportProfileBox)
            || SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportWeldsBox)
            || SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportStiffenersBox)
            || SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportBaseMaterialBox);
    }

    public get showBlock() {
        return this.design.designType.id == DesignType.Concrete;
    }

    public get showWelds() {
        return this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation;
    }

    public get showStiffeners() {
        return this.design.isCBFEMCalculation;
    }

    public get isSelectedAnchorChemical() {
        return this.design.anchorFamily?.anchorTypeRaw == AnchorType.Chemical;
    }

    public get areWeldsPresent() {
        return this.showWelds &&
            (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportProfileBox) ?? false) &&
            !(this.design.properties.get(UIProperty.AnchorPlate_WebWeldLocation).hidden &&
                this.design.properties.get(UIProperty.AnchorPlate_FlangeWeldLocation).hidden &&
                this.design.properties.get(UIProperty.AnchorPlate_DirectWeldLocation).hidden &&
                this.design.properties.get(UIProperty.AnchorPlate_StiffenerWeldLocation).hidden) &&
            (this.design.weldOnWebs || this.design.weldOnFlanges || this.design.directWelds || this.design.weldOnStiffeners);
    }

    public get areStiffenersPresent() {
        if (!this.showStiffeners || !SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportProfileBox)) {
            return false;
        }

        if (!this.design.isStiffenerPresent) {
            return false;
        }

        const stiffeners = this.setStiffenersData();
        if (stiffeners == null || stiffeners.length == 0) {
            return false;
        }

        if (this.design.isCustomStiffenerLayout) {
            return this.design.customStiffeners.length > 0;
        }

        const siffenerLayout = this.getStiffenerLayout();
        if (siffenerLayout == null) {
            return false;
        }

        const stiffenerLocation = siffenerLayout.location;

        return !this.design.properties.get(UIProperty.AnchorPlate_StiffenerLayout).hidden &&
            stiffenerLocation != StiffenerLocation.None;
    }

    ngOnInit(): void {
        this.cadClickWebServiceUrl = environment.cadClickWebServiceUrl;

        this.exportBaseplateBox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.design.anchorPlateExists,
            itemText: this.localization.getString('Agito.Hilti.Profis3.CADExport.ExportBaseplate'),
        });

        this.exportProfileBox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.design.anchorPlateExists && this.design.profileExists,
            itemText: this.localization.getString('Agito.Hilti.Profis3.CADExport.ExportProfile'),
        });

        this.exportAnchorsBox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: true,
            itemText: this.localization.getString('Agito.Hilti.Profis3.CADExport.ExportAnchors'),
        });

        this.exportChemicalBox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.isSelectedAnchorChemical,
            itemText: this.localization.getString('Agito.Hilti.Profis3.CADExport.ExportChemical'),
        });

        this.exportBaseMaterialBox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.showBlock,
            itemText: this.localization.getString('Agito.Hilti.Profis3.CADExport.ExportBaseMaterial'),
        });

        this.exportWeldsBox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.areWeldsPresent,
            itemText: this.localization.getString('Agito.Hilti.Profis3.CADExport.ExportWelds'),
        });

        this.exportStiffenersBox = SimpleCheckboxButtonHelper.createSimpleCheckbox({
            checked: this.areStiffenersPresent,
            itemText: this.localization.getString('Agito.Hilti.Profis3.CADExport.ExportStiffeners'),
        });
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public onExportProfileBoxChange(selectedValues: Set<boolean>) {
        this.exportProfileBox.selectedValues = selectedValues;
        const exportProfile = selectedValues.has(true);

        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(
            this.exportWeldsBox,
            exportProfile && this.areWeldsPresent);

        SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(
            this.exportStiffenersBox,
            exportProfile && this.areStiffenersPresent);
    }

    public onExportAnchorsBoxChange(selectedValues: Set<boolean>) {
        this.exportAnchorsBox.selectedValues = selectedValues;
        const exportAnchor = selectedValues.has(true);

        if (!exportAnchor) {
            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(
                this.exportChemicalBox,
                false
            );
        }
    }

    public onExportChemicalBoxChange(selectedValues: Set<boolean>) {
        this.exportChemicalBox.selectedValues = selectedValues;
        const exportChemical = selectedValues.has(true);

        if (exportChemical) {
            SimpleCheckboxButtonHelper.setSimpleCheckboxChecked(
                this.exportAnchorsBox,
                true
            );
        }
    }

    public export() {
        if (this.submitted) {
            return;
        }

        this.submitted = true;

        const stiffeners = this.getCADStiffenersData();
        const profileWelds = this.getCADProfileWeldsData();
        const stiffenerWelds = this.getCADStiffenerWeldsData();
        const bimCadLibLanguage = this.localization.selectedBimCadLibLanguage;
        const design = this.design;
        const projectDesign = design.designData.projectDesign;
        if (projectDesign == null) {
            throw new Error('projectDesign not defined');
        }

        let designSpecificationText = '';
        if (design.designData.reportData?.SpecificationText != null && design.designType.id != DesignType.MetalDeck) {
            designSpecificationText = this.translationFormatService.getLocalizedStringWithTranslationFormat(design.designData.reportData.SpecificationText);
        }

        const data: CADExportRequest = {
            DesignSpecificationText: designSpecificationText,
            ExportAnchors: SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportAnchorsBox) ?? false,
            ExportBaseMaterial: SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportBaseMaterialBox) ?? false,
            ExportBaseplate: SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportBaseplateBox) ?? false,
            ExportChemical: SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportChemicalBox) ?? false,
            ExportProfile: SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportProfileBox) ?? false,
            ExportStiffeners: SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportStiffenersBox) ?? false,
            ExportWelds: SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportWeldsBox) ?? false,
            ForceFreeLicense: this.userSettingsService.settings.application.general.forceFreeLicense.value ?? false,
            LanguageLCID: bimCadLibLanguage?.LCID ?? 0,
            ProfileWelds: profileWelds,
            ProjectDesign: projectDesign,
            Stiffeners: stiffeners,
            StiffenerWelds: stiffenerWelds,
            UseDevFeatures: this.sharedEnvironmentService.data?.useDevFeatures ?? false
        };

        const url = `${environment.baseplateCADWebServiceUrl}ExportToCADClick`;
        this.apiService.request<string>(new HttpRequest('POST', url, data, { responseType: 'text' }))
            .then((response) => {
                this.design.usageCounter.DesignExportedToCAD++;
                this.submitted = false;

                this.submitCadData(response.body ?? '');
            })
            .then(() => this.close())
            .catch((err) => {
                console.error(err);

                this.submitted = false;
                this.close();
            });
    }

    public close() {
        this.modalInstance.close();
    }

    private getCADStiffenersData() {
        let stiffeners = [] as CADStiffener[];

        if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportStiffenersBox)) {
            stiffeners = this.setStiffenersData();
        }

        return stiffeners;
    }

    private getCADProfileWeldsData() {
        let profileWelds = [] as CADWeld[];

        if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportWeldsBox)) {
            if (this.design.weldOnWebs || this.design.weldOnFlanges) {
                profileWelds = this.setProfileWeldsData();
            }

            if (this.design.directWelds) {
                profileWelds = this.setHRCBFEMProfileWeldsData();
            }
        }

        return profileWelds;
    }

    private getCADStiffenerWeldsData() {
        let stiffenerWelds = [] as CADWeld[];

        if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportWeldsBox)) {
            if (SimpleCheckboxButtonHelper.isSimpleCheckboxChecked(this.exportStiffenersBox) && this.design.weldOnStiffeners) {
                stiffenerWelds = this.setStiffenerWeldsData();
            }
        }

        return stiffenerWelds;
    }

    private setStiffenersData() {
        const stiffeners = this.getStiffenerData();

        return stiffeners.stiffenerData.map((stiffener) => {
            return {
                StiffenerShape: stiffeners.stiffenerDim.shape,
                Height: stiffeners.stiffenerDim.height,
                Width: this.design.isCustomStiffenerLayout ? this.design.customStiffeners[stiffener.key].Width : stiffeners.stiffenerDim.width,
                Thickness: stiffeners.stiffenerDim.thickness,
                HorizontalEdgeLength: this.design.isCustomStiffenerLayout ? this.design.customStiffeners[stiffener.key].HorizontalEdgeLength : stiffeners.stiffenerDim.horizontalEdgeLength,
                VerticalEdgeLength: this.design.isCustomStiffenerLayout ? this.design.customStiffeners[stiffener.key].VerticalEdgeLength : stiffeners.stiffenerDim.verticalEdgeLength,
                Transformation: {
                    Rotation: this.getRotation(stiffener.rotation, stiffeners.profileDim.rotation),
                    Translation: this.getPositionFromProfile(
                        {
                            X: stiffener.position.x,
                            Y: stiffener.position.z,
                            Z: stiffener.position.y
                        },
                        stiffeners.profileDim.offsetX,
                        stiffeners.profileDim.offsetY,
                        stiffeners.profileDim.rotation)
                } as CADTransformation
            } as CADStiffener;
        });
    }

    private filterWelds(welds: IWeldInfo[]) {
        return welds.filter((weld) => isAdvancedWeldVisible(
            weld,
            this.design.weldOnWebs,
            this.design.weldOnFlanges,
            this.design.directWelds,
            this.design.webWeldLocation as number,
            this.design.flangeWeldLocation as number,
            this.design.directWeldLocation as number
        ));
    }

    private setHRCBFEMProfileWeldsData(): CADWeld[] {
        const profileDim = this.getProfileDimensions();
        let welds = this.getProfileWeldsDataByProfileShape();

        if (welds != null) {
            welds = this.filterWelds(welds);
        }

        return welds.map((weld): CADWeld => ({
            Material: this.getWeldMaterial(weld.section),
            Location: weld.location as number,
            Type: this.getWeldType(weld.section),
            Length: weld.scale,
            Thickness: this.getProfileWeldThickness(weld.section),
            Transformation: {
                Rotation: this.getRotation(weld.rotation, 0),
                Translation: this.getPositionFromProfile(
                    { X: weld.position.x, Y: weld.position.z, Z: weld.position.y },
                    profileDim.offsetX,
                    profileDim.offsetY,
                    0)
            }
        }));
    }

    private getProfileWeldsDataByProfileShape() {
        const profileShape = this.getProfileShape();
        const profileDim = this.getProfileDimensions();
        const rotate0 = 0;
        const rotate90 = Math.PI * 0.5;
        const rotate180 = Math.PI;
        const rotate270 = Math.PI * 1.5;

        switch (profileShape) {
            case ModalProfileShape.DoubleBar:
                if (profileDim.rotation == rotate90 || profileDim.rotation == rotate270) {
                    return getDirectWeldPosition(new DefaultUnitConverter(), profileShape, profileDim.thickness, profileDim.height, profileDim.width, 0, this.design.directWeldLength);
                }
                throw new Error('Cad Export: Invalid profile rotation.');
            case ModalProfileShape.Bar:
            case ModalProfileShape.SquareHollow:
            case ModalProfileShape.Rectangular:
                if (profileDim.rotation == rotate0 || profileDim.rotation == rotate180) {
                    return getDirectWeldPosition(new DefaultUnitConverter(), profileShape, profileDim.thickness, profileDim.height, profileDim.width, 0, this.design.directWeldLength);
                }
                if (profileDim.rotation == rotate90 || profileDim.rotation == rotate270) {
                    return getDirectWeldPosition(new DefaultUnitConverter(), profileShape, profileDim.thickness, profileDim.width, profileDim.height, 0, this.design.directWeldLength);
                }
                throw new Error('Cad Export: Invalid profile rotation.');
            default:
                throw new Error('Cad Export: Unknown profile shape.');
        }
    }

    private setProfileWeldsData(): CADWeld[] {
        const profileShape = this.getProfileShape();
        if (profileShape == null) {
            throw new Error('Profile shape not defined');
        }

        const profileDim = this.getProfileDimensions();
        if (profileShape == ModalProfileShape.Pipe) {
            return [
                {
                    Material: this.getWeldMaterial(WeldOnSection.Web),
                    Location: this.design.webWeldLocation,
                    Type: this.getWeldType(WeldOnSection.Web),
                    Length: (profileDim?.width ?? 0) / 2,
                    Thickness: this.design.webWeldLocation == WeldLocation.Butt ?
                        -this.getProfileWeldThickness(WeldOnSection.Web) :
                        this.getProfileWeldThickness(WeldOnSection.Web),
                    Transformation: {
                        Rotation: this.getRotation(0, profileDim.rotation),
                        Translation: this.getPositionFromProfile(
                            { X: 0, Y: 0, Z: 0 },
                            profileDim.offsetX,
                            profileDim.offsetY,
                            profileDim.rotation)
                    }
                }
            ];
        }

        const profilePoints = calculateProfilePoints({
            flangeThickness: profileDim.flangeThickness ?? 0,
            height: profileDim.height ?? 0,
            thickness: profileDim.thickness ?? 0,
            width: profileDim.width ?? 0,
            width2: profileDim.width2 ?? 0,
            offset: undefined,
            angleY: undefined
        }, profileShape);

        let welds = getWeldPosition(
            profilePoints,
            profileShape,
            profileDim?.thickness ?? 0);

        welds = this.filterWelds(welds);

        return welds.map((weld): CADWeld => ({
            Material: this.getWeldMaterial(weld.section),
            Location: weld.location as number,
            Type: this.getWeldType(weld.section),
            Length: weld.scale,
            Thickness: this.getProfileWeldThickness(weld.section),
            Transformation: {
                Rotation: this.getRotation(weld.rotation, profileDim.rotation),
                Translation: this.getPositionFromProfile(
                    { X: weld.position.x, Y: weld.position.z, Z: weld.position.y },
                    profileDim.offsetX,
                    profileDim.offsetY,
                    profileDim.rotation)
            }
        }));
    }

    private getStiffenerData(): IStiffenersData {
        const profileShape = this.getProfileShape();
        if (profileShape == null) {
            throw new Error('Profile shape not defined');
        }

        const profileDim = this.getProfileDimensions();
        const stiffenerDim = this.getStiffenerDimensions();
        const stiffenerLayout = this.getStiffenerLayout();
        const radialOffset = this.design.stiffenerRadialOffset;

        const profilePoints = calculateProfilePoints({
            flangeThickness: profileDim.flangeThickness,
            height: profileDim.height,
            thickness: profileDim.thickness,
            width: profileDim.width,
            width2: profileDim.width2,
            offset: undefined,
            angleY: undefined
        }, profileShape);

        let stiffenerData;
        if (this.design.isCustomStiffenerLayout) {
            const stiffenerValues = getCustomStiffenerValues(this.design.customStiffeners, this.design.stiffenerShape as number, stiffenerDim.height, stiffenerDim.thickness);

            stiffenerData = getCustomStiffenerPosition(stiffenerValues, profilePoints, profileShape, profileDim.height, profileDim.width);
        }
        else {
            stiffenerData = getStiffenerData(
                profilePoints,
                profileShape,
                profileDim.height,
                profileDim.width,
                profileDim.thickness,
                stiffenerLayout?.stiffenerCount ?? 0,
                radialOffset
            );

            stiffenerData = stiffenerData.filter(info => (stiffenerLayout?.location as number == info.location || stiffenerLayout?.location == StiffenerLocation.All) &&
                (stiffenerLayout?.section as number == info.section || stiffenerLayout?.section == StiffenerCrossSectionPart.All));
        }

        return {
            stiffenerData,
            profileDim,
            stiffenerDim
        };
    }

    private setStiffenerWeldsData() {
        const stiffeners = this.getStiffenerData();
        const customWeldsPosition: { [id: number]: IStiffenerWeldPositionInfo[] } = {};
        let weldPositions: IStiffenerWeldPositionInfo[] = [];
        if (this.design.isCustomStiffenerLayout) {
            for (let i = 0; i < this.design.customStiffeners.length; i++) {
                customWeldsPosition[i] = Stiffener.getStiffenerWeldPositionInfo(
                    stiffeners.stiffenerDim.height,
                    this.design.customStiffeners[i].Width,
                    stiffeners.stiffenerDim.thickness / 2,
                    Stiffener.innerOffset);
            }

        }
        else {
            weldPositions = Stiffener.getStiffenerWeldPositionInfo(
                stiffeners.stiffenerDim.height,
                stiffeners.stiffenerDim.width,
                stiffeners.stiffenerDim.thickness / 2,
                Stiffener.innerOffset);
        }
        return stiffeners.stiffenerData.flatMap((stiffener) => {
            let visibleWeldPositions: IStiffenerWeldPositionInfo[];
            if (this.design.isCustomStiffenerLayout) {
                visibleWeldPositions = customWeldsPosition[stiffener.key].filter((weld) => weld.isVisible(stiffeners.stiffenerDim.weldLocation as number, stiffener.weldType));
            }
            else {
                visibleWeldPositions = weldPositions.filter((weld) => weld.isVisible(stiffeners.stiffenerDim.weldLocation as number, stiffener.weldType));
            }

            return visibleWeldPositions.map((weld): CADWeld => ({
                Material: this.design.stiffenerWeldMaterial,
                Location: weld.location as number,
                Type: WeldingType.Stiffener,
                Length: weld.scale,
                Thickness: stiffeners.stiffenerDim.weldThickness,
                Transformation: {
                    Rotation: this.getStiffenerWeldRotation(
                        {
                            X: weld.rotation.z,
                            Y: weld.rotation.x,
                            Z: -weld.rotation.y
                        },
                        stiffeners.profileDim.rotation,
                        stiffener.rotation
                    ),
                    Translation: this.getPositionFromStiffener(
                        {
                            X: weld.position.z,
                            Y: -weld.position.x,
                            Z: weld.position.y
                        },
                        stiffeners.profileDim.offsetX,
                        stiffeners.profileDim.offsetY,
                        stiffeners.profileDim.rotation,
                        {
                            X: stiffener.position.x,
                            Y: stiffener.position.z,
                            Z: stiffener.position.y
                        },
                        stiffener.rotation
                    )
                }
            }));
        });
    }

    private getProfileDimensions(): IProfileDimensions {
        let rotation = this.design.profileAngle;
        const offsetX = this.design.profileOffsetX;
        const offsetY = this.design.profileOffsetY;

        if (this.design.profileFamily == null) {
            throw new Error('profileFamily not defined');
        }

        switch (this.design.profileFamily.shape) {
            case (ProfileShape.SquareHollow):
            case (ProfileShape.Rectangular):
                rotation = (rotation + Math.PI) % (2 * Math.PI);
        }

        if (this.design.profileSize != null) {
            return {
                width: this.design.profileSize.width ?? 0,
                width2: this.design.profileSize.width2,
                height: this.design.profileSize.height ?? 0,
                thickness: this.design.profileSize.thickness ?? 0,
                flangeThickness: this.design.profileSize.flangeThickness ?? 0,
                rotation,
                offsetX,
                offsetY
            };
        }

        return {
            width: this.design.profileWidth,
            width2: this.design.profileWidth2,
            height: this.design.profileHeight,
            thickness: this.design.profileThickness,
            flangeThickness: this.design.profileFlangeThickness,
            rotation,
            offsetX,
            offsetY
        };
    }

    private getProfileShape() {
        const profileFamily = this.design.profileFamily;

        if (profileFamily?.shape != null) {
            return ProfileUpdate.propertyProfileShapeToModalProfileShape(profileFamily.shape);
        }

        return undefined;
    }

    private getStiffenerLayout(): IStiffenerLayout | undefined {
        const stiffenerLayout = this.design.stiffenerLayout;

        if (stiffenerLayout != null) {
            return {
                location: stiffenerLayout.stiffenerLocation,
                section: stiffenerLayout.stiffenerCrossSectionPart,
                stiffenerCount: stiffenerLayout.stiffenerCount
            };
        }

        return undefined;
    }

    private getStiffenerDimensions(): IStiffenerDimensions {
        return {
            shape: this.design.stiffenerShape,
            height: this.design.stiffenerHeight,
            width: this.design.stiffenerWidth,
            thickness: this.design.stiffenerThickness,
            weldThickness: convertWeldThicknessToSize(this.design.stiffenerWeldThickness),
            horizontalEdgeLength: this.design.stiffenerHorizontalEdgeLength,
            verticalEdgeLength: this.design.stiffenerVerticalEdgeLength,
            weldLocation: this.design.stiffenerWeldLocation
        };
    }

    private getWeldMaterial(weldSection: WeldOnSection) {
        switch (weldSection) {
            case WeldOnSection.Web:
                return this.design.webWeldMaterial;

            case WeldOnSection.Flange:
                return this.design.flangeWeldMaterial;

            case WeldOnSection.Direct:
                return this.design.directWeldMaterial;

            default:
                throw new Error('Cad Export: Unknown weld section.');
        }
    }

    private getWeldType(weldSection: WeldOnSection) {
        switch (weldSection) {
            case WeldOnSection.Web:
                return WeldingType.Web;

            case WeldOnSection.Flange:
                return WeldingType.Flange;

            case WeldOnSection.Direct:
                return WeldingType.DirectWelded;

            default:
                throw new Error('Cad Export: Unknown weld type.');
        }
    }

    private getProfileWeldThickness(weldSection: WeldOnSection) {
        switch (weldSection) {
            case WeldOnSection.Web:
                return convertWeldThicknessToSize(this.design.webWeldThickness);

            case WeldOnSection.Flange:
                return convertWeldThicknessToSize(this.design.flangeWeldThickness);

            case WeldOnSection.Direct:
                return convertWeldThicknessToSize(this.design.directWeldThickness);

            default:
                throw new Error('Cad Export: Unknown weld section.');
        }
    }

    private getRotation(rotation: number, profileRotation: number) {
        return {
            X: 0,
            Y: 0,
            Z: (Math.PI / 2 - (rotation - profileRotation)) % (2 * Math.PI)
        };
    }

    private getStiffenerWeldRotation(rotation: { X: number; Y: number; Z: number }, profileRotation: number, stiffenerRotation: number) {
        const cadStiffenerRotation = this.getRotation(stiffenerRotation, profileRotation);
        return {
            X: (rotation.X + cadStiffenerRotation.X) % (2 * Math.PI),
            Y: (rotation.Y + cadStiffenerRotation.Y) % (2 * Math.PI),
            Z: (rotation.Z + cadStiffenerRotation.Z) % (2 * Math.PI)
        };
    }

    private getPositionFromProfile(position: { X: number; Y: number; Z: number }, profileOffsetX: number, profileOffsetY: number, profileRotation: number) {
        return {
            X: position.X * Math.cos(profileRotation) - position.Y * Math.sin(profileRotation) + profileOffsetX,
            Y: position.X * Math.sin(profileRotation) + position.Y * Math.cos(profileRotation) + profileOffsetY,
            Z: position.Z
        };
    }

    private getPositionFromStiffener(position: { X: number; Y: number; Z: number }, profileOffsetX: number, profileOffsetY: number, profileRotation: number, stiffenerPosition: { X: number; Y: number; Z: number }, stiffenerRotation: number) {
        const cadStiffenerPosition = this.getPositionFromProfile(stiffenerPosition, profileOffsetX, profileOffsetY, profileRotation);
        const cadStiffenerRotation = this.getRotation(stiffenerRotation, profileRotation).Z;

        return {
            X: position.X * Math.cos(cadStiffenerRotation) - position.Y * Math.sin(cadStiffenerRotation) + cadStiffenerPosition.X,
            Y: position.X * Math.sin(cadStiffenerRotation) + position.Y * Math.cos(cadStiffenerRotation) + cadStiffenerPosition.Y,
            Z: position.Z + cadStiffenerPosition.Z
        };
    }

    private submitCadData(data: string) {
        window.open('', 'cadWin');

        const form = this.formCadExport.nativeElement;

        const zipContentElement = form.querySelector('#zipContent') as HTMLInputElement;
        zipContentElement.value = data;

        form.target = 'cadWin';
        form.submit();
    }
}
