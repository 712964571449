import { Color3 } from '@babylonjs/core/Maths/math.color';
import {
    MeasureScopeCheckParameterItem as ScopeCheck
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    Dimensions, MeasureResultType
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    BrickSize, UIProperty
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    AnchorLayoutPointEntity
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    BrickLayout, DesignMethodGroup, DesignStandard, DesignType, EdgeDistanceDisplayType, MasonryAnchorPosition
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    BrickMaterial
} from '../../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.TechnicalData.Enums';
import {
    propertyValueChanged, scopeCheckChanged
} from '@profis-engineering/gl-model/base-update';
import { BaseUpdate } from '@profis-engineering/pe-gl-model/base-update';
import { BrickStart } from '@profis-engineering/pe-gl-model/components/masonry-base-material';

import { BrickGroup } from '../../../../shared/entities/code-lists/brick-group';
import { BrickSize as BrickSizeCodelist } from '../../../../shared/entities/code-lists/brick-size';
import { DesignCodeList } from '../../../../shared/entities/design-code-list';
import { DesignPe } from '../../../../shared/entities/design-pe';
import { IModelPe } from '@profis-engineering/pe-gl-model/gl-model';

export class MasonryBaseMaterialUpdate extends BaseUpdate {

    public override shouldUpdate(_model: IModelPe, design: DesignPe) {
        return design.designType.id == DesignType.Masonry;
    }

    /* PROPERTY VALUE CHANGED */
    @propertyValueChanged()
    private OnPropertiesChange(model: IModelPe, design: DesignPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.leftRightBrick = design.designStandard.id == DesignStandard.ACI;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryThickness)
    private BaseMaterial_MasonryThickness(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            model.masonryBaseMaterial.depth = value;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeXPositive)
    private BaseMaterial_MasonryEdgeXPositive(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeXPositive = value;

            if (edgeXPositive < 0) {
                edgeXPositive = 0;
            }

            model.masonryBaseMaterial.widthPositive = edgeXPositive;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeXPositiveFromAnchor)
    private BaseMaterial_MasonryEdgeXPositiveFromAnchor(value: number, model: IModelPe, design: DesignPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeXPositiveFromAnchor = value;

            if (edgeXPositiveFromAnchor < 0) {
                edgeXPositiveFromAnchor = 0;
            }

            const xMax = Math.max(...(design.model[UIProperty.AnchorLayout_CustomLayoutPoints] as AnchorLayoutPointEntity[] || []).map(point => point.X));

            model.masonryBaseMaterial.widthPositive = edgeXPositiveFromAnchor + xMax;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeXNegative)
    private BaseMaterial_MasonryEdgeXNegative(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeXNegative = value;

            if (edgeXNegative < 0) {
                edgeXNegative = 0;
            }

            model.masonryBaseMaterial.widthNegative = edgeXNegative;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeXNegativeFromAnchor)
    private BaseMaterial_MasonryEdgeXNegativeFromAnchor(value: number, model: IModelPe, design: DesignPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeXNegativeFromAnchor = value;

            if (edgeXNegativeFromAnchor < 0) {
                edgeXNegativeFromAnchor = 0;
            }

            const xMin = Math.min(...(design.model[UIProperty.AnchorLayout_CustomLayoutPoints] as AnchorLayoutPointEntity[] || []).map(point => point.X));

            model.masonryBaseMaterial.widthNegative = edgeXNegativeFromAnchor - xMin;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeYPositive)
    private BaseMaterial_MasonryEdgeYPositive(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeYPositive = value;

            if (edgeYPositive < 0) {
                edgeYPositive = 0;
            }

            model.masonryBaseMaterial.heightPositive = edgeYPositive;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeYPositiveFromAnchor)
    private BaseMaterial_MasonryEdgeYPositiveFromAnchor(value: number, model: IModelPe, design: DesignPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeYPositiveFromAnchor = value;

            if (edgeYPositiveFromAnchor < 0) {
                edgeYPositiveFromAnchor = 0;
            }

            const yMax = Math.max(...(design.model[UIProperty.AnchorLayout_CustomLayoutPoints] as AnchorLayoutPointEntity[] || []).map(point => point.Y));

            model.masonryBaseMaterial.heightPositive = edgeYPositiveFromAnchor + yMax;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeYNegative)
    private BaseMaterial_MasonryEdgeYNegative(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeYNegative = value;

            if (edgeYNegative < 0) {
                edgeYNegative = 0;
            }

            model.masonryBaseMaterial.heightNegative = edgeYNegative;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryEdgeYNegativeFromAnchor)
    private BaseMaterial_MasonryEdgeYNegativeFromAnchor(value: number, model: IModelPe, design: DesignPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            let edgeYNegativeFromAnchor = value;

            if (edgeYNegativeFromAnchor < 0) {
                edgeYNegativeFromAnchor = 0;
            }

            const yMin = Math.min(...(design.model[UIProperty.AnchorLayout_CustomLayoutPoints] as AnchorLayoutPointEntity[] || []).map(point => point.Y));

            model.masonryBaseMaterial.heightNegative = edgeYNegativeFromAnchor - yMin;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConstrainedEdgeLeft)
    private BaseMaterial_ConstrainedEdgeLeft(value: boolean, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.concreteLeft = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConstrainedEdgeRight)
    private BaseMaterial_ConstrainedEdgeRight(value: boolean, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.concreteRight = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConstrainedEdgeTop)
    private BaseMaterial_ConstrainedEdgeTop(value: boolean, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.concreteTop = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_ConstrainedEdgeBottom)
    private BaseMaterial_ConstrainedEdgeBottom(value: boolean, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.concreteBottom = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_PlasterThickness)
    private BaseMaterial_PlasterThickness(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        if (value != null) {
            model.masonryBaseMaterial.plasteredWallThickness = value;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_IsPlasteredWall)
    private BaseMaterial_IsPlasteredWall(value: boolean, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.hasPlasteredWall = value;
    }

    @propertyValueChanged(UIProperty.Loads_CompressiveStress)
    private Loads_CompressiveStress(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.compressiveStress = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BrickSize)
    private BaseMaterial_BrickSize(brickSize: BrickSize, model: IModelPe, design: DesignPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.brickWidth = brickSize.Thickness;
        model.masonryBaseMaterial.brickHeight = brickSize.Height;
        model.masonryBaseMaterial.brickDepth = brickSize.Width;

        if (design.designMethodGroup?.id == DesignMethodGroup.ASD) {
            model.masonryBaseMaterial.brickWidth -= design.jointThicknessVertical;
            model.masonryBaseMaterial.brickHeight -= design.jointThicknessHorizontal;
            model.masonryBaseMaterial.brickDepth -= design.jointThicknessVertical;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BrickSizeId)
    private BaseMaterial_BrickSizeId(brickSizeId: number, model: IModelPe, design: DesignPe) {
        if (brickSizeId != null && model.masonryBaseMaterial) {
            const codelist = design.designData.designCodeLists[DesignCodeList.BrickSize] as BrickSizeCodelist[];

            const brickSize = codelist.find((b) => b.id == brickSizeId);

            model.masonryBaseMaterial.brickWidth = brickSize?.thickness;
            model.masonryBaseMaterial.brickHeight = brickSize?.height;
            model.masonryBaseMaterial.brickDepth = brickSize?.width;
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_JointThicknessVertical)
    private BaseMaterial_JointThicknessVertical(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.verticalFilling = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_VerticalJointsFilled)
    private BaseMaterial_VerticalJointsFilled(value: boolean, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.hasVerticalFilling = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_JointThicknessHorizontal)
    private BaseMaterial_JointThicknessHorizontal(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.horizontalFilling = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_NearestJointDistance)
    private BaseMaterial_NearestJointDistance(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.nearestJointDistance = value;
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BrickGroup)
    private BaseMaterial_BrickGroup(brickGroupId: number, model: IModelPe, design: DesignPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        const codeList = design.designData.designCodeLists[DesignCodeList.BrickGroup] as BrickGroup[];
        const brickGroup = codeList.find((brickGroup) => brickGroup.id == brickGroupId);

        switch (brickGroup?.brickMaterial) {
            case BrickMaterial.Clay:
                model.masonryBaseMaterial.brickColor = Color3.FromHexString('#B7987F');
                model.masonryBaseMaterial.transparentBrickColor = Color3.FromHexString('#9F7654');
                break;

            case BrickMaterial.CalciumSilicate:
                model.masonryBaseMaterial.brickColor = Color3.FromHexString('#B2B2B2');
                model.masonryBaseMaterial.transparentBrickColor = Color3.FromHexString('#989898');
                break;

            case BrickMaterial.LightweightConcrete:
                model.masonryBaseMaterial.brickColor = Color3.FromHexString('#666563');
                model.masonryBaseMaterial.transparentBrickColor = Color3.FromHexString('#33322F');
                break;

            case BrickMaterial.NormalConcrete:
                model.masonryBaseMaterial.brickColor = Color3.FromHexString('#7C7B77');
                model.masonryBaseMaterial.transparentBrickColor = Color3.FromHexString('#504F4A');
                break;

            default:
                throw new Error('Unknown brick group material.');
        }
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BrickLayoutStartLeft)
    private BaseMaterial_BrickLayoutStartLeft(value: number, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.brickStart = value === 1
            ? BrickStart.Left
            : BrickStart.Right;
    }

    @propertyValueChanged(UIProperty.AnchorLayout_CustomLayoutPoints)
    private AnchorLayout_CustomLayoutPoints(value: string, model: IModelPe, design: DesignPe) {
        this.BaseMaterial_MasonryEdgeXPositiveFromAnchor(design.model[UIProperty.BaseMaterial_MasonryEdgeXPositiveFromAnchor] as number, model, design);
        this.BaseMaterial_MasonryEdgeXNegativeFromAnchor(design.model[UIProperty.BaseMaterial_MasonryEdgeXNegativeFromAnchor] as number, model, design);
        this.BaseMaterial_MasonryEdgeYPositiveFromAnchor(design.model[UIProperty.BaseMaterial_MasonryEdgeYPositiveFromAnchor] as number, model, design);
        this.BaseMaterial_MasonryEdgeYNegativeFromAnchor(design.model[UIProperty.BaseMaterial_MasonryEdgeYNegativeFromAnchor] as number, model, design);
    }

    @propertyValueChanged(UIProperty.Option_EdgeDistanceDisplayType)
    private Option_EdgeDistanceDisplayType(value: EdgeDistanceDisplayType, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.edgeDistance = (value as number);
    }

    @propertyValueChanged(UIProperty.BaseMaterial_BrickLayout)
    private BaseMaterial_BrickLayout(value: BrickLayout, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.brickLayout = (value as number);
    }

    @propertyValueChanged(UIProperty.BaseMaterial_MasonryAnchorPosition)
    private BaseMaterial_MasonryAnchorPosition(value: MasonryAnchorPosition, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.anchorPosition = (value as number);
    }

    /* SCOPE CHECK CHANGED */
    @scopeCheckChanged()
    private scopeChecksChanged(scopeChecks: { [measureResultType: number]: ScopeCheck }, model: IModelPe) {
        if (!model.masonryBaseMaterial) {
            throw new Error('model masonryBaseMaterial is undefined');
        }

        model.masonryBaseMaterial.invalid = model.masonryBaseMaterial.invalid ?? {};

        model.masonryBaseMaterial.invalid.widthPositive = false;
        model.masonryBaseMaterial.invalid.widthNegative = false;
        model.masonryBaseMaterial.invalid.heightPositive = false;
        model.masonryBaseMaterial.invalid.heightNegative = false;
        model.masonryBaseMaterial.invalid.thickness = false;

        if (scopeChecks != null) {
            const anchorToBaseMaterialEdgeScopeCheck = scopeChecks[MeasureResultType.AnchorToBaseMaterialEdge];
            let xPositiveInvalid = false;
            let xNegativeInvalid = false;
            let yPositiveInvalid = false;
            let yNegativeInvalid = false;

            if (anchorToBaseMaterialEdgeScopeCheck != null) {
                const dimensions = anchorToBaseMaterialEdgeScopeCheck.Value as Dimensions;

                xPositiveInvalid = (dimensions & Dimensions.XPositive) != 0;
                xNegativeInvalid = (dimensions & Dimensions.XNegative) != 0;
                yPositiveInvalid = (dimensions & Dimensions.YPositive) != 0;
                yNegativeInvalid = (dimensions & Dimensions.YNegative) != 0;
            }

            model.masonryBaseMaterial.invalid.widthPositive = xPositiveInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeXPositive] != null;
            model.masonryBaseMaterial.invalid.widthNegative = xNegativeInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeXNegative] != null;
            model.masonryBaseMaterial.invalid.heightPositive = yPositiveInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeYPositive] != null;
            model.masonryBaseMaterial.invalid.heightNegative = yNegativeInvalid || scopeChecks[MeasureResultType.BaseMaterialEdgeYNegative] != null;

            model.masonryBaseMaterial.invalid.thickness = scopeChecks[MeasureResultType.BaseMaterialThickness] != null;
        }
    }
}
