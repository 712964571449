import { IAdvancedCalculationInputs } from '../entities/advanced-calculation-inputs';
import { IDetailedDisplayDesign } from '../entities/display-design';
import {
    NewDesignDataEntity
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    SteelGuideline
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export interface ISteelCalculationDefaultValues {
    materialSafetyFactor: number;
    steelSafetyFactorGammaM0?: number;
    steelSafetyFactorGammaM1?: number;
    steelSafetyFactorGammaM2?: number;
    inSteelSafetyFactorGammaM0?: number;
    inSteelSafetyFactorGammaM1?: number;
    inSteelSafetyFactorGammaMw?: number;
    inSteelSafetyFactorGammaMb?: number;
}

export interface ISteelCalculationValues {
    concreteCapacityFactor?: number;
    materialSafetyFactor?: number;
    steelCapacityFactor?: number;
    steelSafetyFactorGammaM0?: number;
    steelSafetyFactorGammaM1?: number;
    steelSafetyFactorGammaM2?: number;
    weldsCapacityFactor?: number;
    inSteelSafetyFactorGammaM0?: number;
    inSteelSafetyFactorGammaM1?: number;
    inSteelSafetyFactorGammaMw?: number;
    inSteelSafetyFactorGammaMb?: number;
}

export interface ISteelCalculationInputsData {
    // Context
    steelCalculationValues: () => ISteelCalculationValues | undefined;
    editDesignSettings: boolean;
    regionId: number;
    designType: number;
    designStandardId: number;
    advancedCalculationInputsData: IAdvancedCalculationInputs;

    // Defaults
    defaultValues: ISteelCalculationDefaultValues;

    // Values
    concreteCapacityFactor?: number;
    steelCapacityFactor?: number;
    weldsCapacityFactor?: number;
    materialSafetyFactor?: number;
    steelSafetyFactorGammaM0?: number;
    steelSafetyFactorGammaM1?: number;
    steelSafetyFactorGammaM2?: number;
    inSteelSafetyFactorGammaM0?: number;
    inSteelSafetyFactorGammaM1?: number;
    inSteelSafetyFactorGammaMw?: number;
    inSteelSafetyFactorGammaMb?: number;

    steelGuideline?: SteelGuideline;
}

export function setSteelCalculationValuesToDesign(design: IDetailedDisplayDesign, data: ISteelCalculationInputsData) {
    design.concreteCapacityFactor = data.concreteCapacityFactor;
    design.steelCapacityFactor = data.steelCapacityFactor;
    design.weldsCapacityFactor = data.weldsCapacityFactor;
    design.materialSafetyFactor = data.materialSafetyFactor;
    design.steelSafetyFactorGammaM0 = data.steelSafetyFactorGammaM0;
    design.steelSafetyFactorGammaM1 = data.steelSafetyFactorGammaM1;
    design.steelSafetyFactorGammaM2 = data.steelSafetyFactorGammaM2;
    design.inSteelSafetyFactorGammaM0 = data.inSteelSafetyFactorGammaM0;
    design.inSteelSafetyFactorGammaM1 = data.inSteelSafetyFactorGammaM1;
    design.inSteelSafetyFactorGammaMw = data.inSteelSafetyFactorGammaMw;
    design.inSteelSafetyFactorGammaMb = data.inSteelSafetyFactorGammaMb;
}

export function setSteelCalculationValuesToNewDesign(newDesign: NewDesignDataEntity, data: ISteelCalculationInputsData) {
    newDesign.ConcreteCapacityFactor = data.concreteCapacityFactor as number;
    newDesign.SteelCapacityFactor = data.steelCapacityFactor as number;
    newDesign.WeldsCapacityFactor = data.weldsCapacityFactor as number;
    newDesign.MaterialSafetyFactor = data.materialSafetyFactor as number;
    newDesign.SteelSafetyFactorGammaM0 = data.steelSafetyFactorGammaM0 as number;
    newDesign.SteelSafetyFactorGammaM1 = data.steelSafetyFactorGammaM1 as number;
    newDesign.SteelSafetyFactorGammaM2 = data.steelSafetyFactorGammaM2 as number;
    newDesign.INSteelSafetyFactorGammaM0 = data.inSteelSafetyFactorGammaM0 as number;
    newDesign.INSteelSafetyFactorGammaM1 = data.inSteelSafetyFactorGammaM1 as number;
    newDesign.INSteelSafetyFactorGammaMw = data.inSteelSafetyFactorGammaMw as number;
    newDesign.INSteelSafetyFactorGammaMb = data.inSteelSafetyFactorGammaMb as number;
    newDesign.SteelGuideLine = data.steelGuideline as SteelGuideline;
}
