import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    TerrainCategoryEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface ITerrainCategory extends ICodeListConstructor {
    isSea: boolean;
    isTown: boolean;
}

export class TerrainCategory extends CodeList {

    public isSea!: boolean;
    public isTown!: boolean;

    constructor(codelist: ITerrainCategory) {
        super(codelist);

        if (codelist != null) {
            this.isSea = codelist.isSea;
            this.isTown = codelist.isTown;
        }
    }

    public static fromService(codeList: TerrainCategoryEntity) {
        return new TerrainCategory(CodeList.baseFromService<ITerrainCategory>('TerrainCategoryEntity', codeList, {
            isSea: codeList.IsSea,
            isTown: codeList.IsTown
        } as ITerrainCategory));
    }

    public override cloneDeep(): TerrainCategory {
        const codelist: TerrainCategory = super.cloneDeep() as TerrainCategory;

        codelist.isSea = this.isSea;
        codelist.isTown = this.isTown;

        return codelist;
    }
}
