import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ITabItem } from '@profis-engineering/pe-ui-common/components/tabs/tabs.common';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';

import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ModalInstance, MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import {
    IAnchorNeedSolutionPopupComponentInput, IAnchorNeedSolutionText, INeedSolutionText
} from '../../../shared/components/anchor-need-solution-popup';
import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import {
    SmartAnchorApplication
} from '../../../shared/entities/code-lists/smart-anchor-application';
import { SmartAnchorCategory } from '../../../shared/entities/code-lists/smart-anchor-category';
import {
    ISmartAnchorNeedsSolutionTextsAnchorGroup, SmartAnchorNeedsSolutionTextsAnchorGroup
} from '../../../shared/entities/code-lists/smart-anchor-needs-solution-texts-anchor-group';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { anchorIcons, getAnchorIconTooltipTranslationKey, isAnchorFamilyMarkedAsNew, SelectAnchorIcon } from '../../../shared/helpers/anchor-helper';
import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { getProperty } from '../../helpers/object-helper';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UserService } from '../../services/user.service';
import { includeSprites, Sprite } from '../../sprites';

interface IAnchorNeedSolutionUi extends IAnchorNeedSolutionText, ITabItem {
}

@Component({
    templateUrl: './anchor-need-solution-popup.component.html',
    styleUrls: ['./anchor-need-solution-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AnchorNeedSolutionPopupComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IAnchorNeedSolutionPopupComponentInput>;

    public anchors!: IAnchorNeedSolutionUi[];
    public anchorsData!: IAnchorNeedSolutionUi[];
    public needsSolutionTextsAnchorGroup!: ISmartAnchorNeedsSolutionTextsAnchorGroup[];
    public icons = anchorIcons;

    constructor(
        private localizationService: LocalizationService,
        private userService: UserService,
        private numberService: NumberService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get design() {
        return this.userService.design;
    }

    public get title() {
        return this.translate('Agito.Hilti.Profis3.AnchorNeedSolutionPopup.Title')
            .concat(': ')
            .concat(this.selectedSmartAnchorCategoryName)
            .concat(' & ')
            .concat(this.selectedSmartAnchorApplicationName);
    }

    private get smartAnchorApplication() {
        if (this.design.smartAnchorApplication != null) {
            return this.design.smartAnchorApplication;
        }

        const codeList = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorApplication] as SmartAnchorApplication[];
        const ret = codeList.find((x) => x.categoryId == this.smartAnchorCategory);
        return ret?.id;
    }

    private get smartAnchorCategory() {
        if (this.design.smartAnchorCategory != null) {
            return this.design.smartAnchorCategory;
        }

        // Category not selected, use the first category (used by Virtual Tour!)
        const codeList = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorCategory] as SmartAnchorCategory[];
        return codeList[0].id;
    }

    private get selectedSmartAnchorCategoryName() {
        const codeList = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorCategory] as SmartAnchorCategory[];
        return this.translate(codeList?.find(c => c.id == this.smartAnchorCategory)?.nameResourceKey ?? '');
    }

    private get selectedSmartAnchorApplicationName() {
        const codeList = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorApplication] as SmartAnchorApplication[];
        return this.translate(codeList.find(a => a.id == this.smartAnchorApplication)?.nameResourceKey ?? '');
    }

    private get anchorIds() {
        return this.design.properties.get(PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id).allowedValues;
    }

    ngOnInit(): void {
        this.modalInstance.setOnClosing((result) => {
            // Dismissal prevented, e.g. when opened from Virtual Tour
            const preventDismiss = this.modalInstance.input?.preventDismiss ?? false;
            if (preventDismiss && (result == MODAL_DISMISS_REASON_BACKDROP || result == MODAL_DISMISS_REASON_ESC)) {
                return false;
            }

            return true;
        });

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-anchor-no-cleaning-required',
            'sprite-anchor-approved-automatic-cleaning',
            'sprite-anchor-tensile-zone-cracked-concrete',
            'sprite-anchor-fatigue',
            'sprite-anchor-shock',
            'sprite-anchor-seismic',
            'sprite-anchor-small-edge-dist-and-spacing',
            'sprite-anchor-variable-embedment-depth',
            'sprite-anchor-npp-approval-di-bt',
            'sprite-anchor-fire-resistant',
            'sprite-anchor-clean-tech',
            'sprite-anchor-stainless-steel-hcr',
            'sprite-anchor-shallow-embedment-depth',
            'sprite-anchor-diamond-cored-holes-suitable',
            'sprite-anchor-family-no-photo-available',
        );

        this.initialize();
    }

    public getProperty(object: object, property: string) {
        return getProperty(object, property);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public getIconTooltip(icon: SelectAnchorIcon) {
        const isHNABased = isHnaBasedDesignStandard(this.design.designStandard?.id as number);
        const key = getAnchorIconTooltipTranslationKey(icon, isHNABased);

        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }

    public activeTabChange(tab: ITabItem) {
        this.anchors.forEach(a => a.active = false);

        const activeAnchor = this.anchors.find(a => a.id == tab.id);
        if (activeAnchor != null) {
            activeAnchor.active = true;
        }
    }

    private initialize() {
        let anchors = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

        const allowedValues = this.design.properties.get(PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id).allowedValues;
        if (allowedValues != null) {
            anchors = anchors.filter((anchor) => allowedValues.includes(anchor.id as number));
        }

        this.setNeedsSolutionTextsByRegionAndApplicationId();

        const anchorIds = anchors.map((anchor) => anchor.id) as number[];
        this.setAnchorsData(anchorIds);

        this.refreshAnchors();
    }

    private getBoolean(value?: boolean) {
        return value ?? false;
    }

    private setAnchorsData(anchorIds: number[]) {
        const codeList = DesignMethodGroupHelper.IsLrfdBased(this.design.designMethodGroup?.id)
            ? this.design.designData.designCodeLists[DesignCodeList.AnchorFamilyAC58] as AnchorFamily[]
            : this.design.designData.designCodeLists[DesignCodeList.AnchorFamily] as AnchorFamily[];

        const anchors = (codeList).filter((anchor) => anchorIds.some((anchorId) => anchorId == anchor.id) && anchor.detailed != undefined && anchor.detailed != null);
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);

        this.anchorsData = anchors.map((anchor): IAnchorNeedSolutionUi => {
            return {
                id: anchor.id ?? 0,
                name: anchor.getTranslatedNameText(codeListDeps) ?? '',
                image: anchor.image ? 'pe-ui-pe-' + anchor.image : '',
                holeDiameters: anchor.detailed?.holeDiameters as number[],
                fixtureThicknesMin: anchor.detailed?.fixtureThicknesMin ?? 0,
                fixtureThicknesMax: anchor.detailed?.fixtureThicknesMax ?? 0,
                description: this.formatDescription(anchor.detailed?.descriptionFormat ?? '', anchor.detailed?.descriptionKeys as string[]),
                isNoCleaningRequired: this.getBoolean(anchor.detailed?.isZeroCleaningAllowed),
                isAutomaticCleaningApproved: this.getBoolean(anchor.detailed?.isAutomaticCleaningApproved),
                isCrackedConcreteAllowed: this.getBoolean(anchor.detailed?.isCrackedConcreteAllowed),
                isFatigueAllowed: this.getBoolean(anchor.detailed?.isFatigueAllowed),
                isShock: this.getBoolean(anchor.detailed?.isShock),
                isSeismicAllowed: this.getBoolean(anchor.detailed?.isSeismicAllowed),
                isSmallEdgeDistAndSpacing: this.getBoolean(anchor.detailed?.isSmallEdgeDistanceAndSpacing),
                isVariableEmbedment: this.getBoolean(anchor.detailed?.isVariableEmbedment),
                isNPPApprovalDiBt: this.getBoolean(anchor.detailed?.isNuclearApproved),
                isFireAllowed: this.getBoolean(anchor.detailed?.isFireAllowed),
                isCleanTech: this.getBoolean(anchor.detailed?.isCleanTech),
                isStainlessSteelOrHCRAvailable: this.getBoolean(anchor.detailed?.isStainlessSteelOrHCRAvailable),
                isShallowEmbedmentDepth: this.getBoolean(anchor.detailed?.isShallowEmbedmentDepth),
                isDiamondCoredHolesSuitable: this.getBoolean(anchor.detailed?.isDiamondDrillingSuitable),
                tag: anchor.detailed?.tag != null && anchor.detailed.tag.length > 0 ? this.translate(anchor.detailed.tag) : '',
                tested: this.getBoolean(anchor.detailed?.tested),
                isNew: isAnchorFamilyMarkedAsNew(anchor?.id ?? 0, this.design) ?? false,
                needsSolutionTexts: this.needsSolutionTextsTranslations(anchor?.id ?? 0) as INeedSolutionText[],
                title: '',
                active: false,
                internalPortfolioOnly: this.getBoolean(anchor.internalPortfolioOnly)
            };
        });
    }

    private refreshAnchors() {
        this.anchors = this.filterAnchors();

        this.initializeAnchorFamilySprites();
    }

    private filterAnchors() {
        let anchors = this.anchorsData.slice();

        // allowed values
        const allowedValues = this.design.properties.get(this.design.globalMetaProperties.sourceMetaProperty).allowedValues;
        if (allowedValues != null) {
            anchors = anchors.filter((anchor) => allowedValues.includes(anchor.id));
        }

        anchors = this.filterBySuggestedAnchors(anchors);
        anchors.forEach((value, index) => {
            value.active = false;
            value.title = `Solution ${index + 1}`;
        });

        // always select the first tab
        if (anchors.length > 0) {
            anchors[0].active = true;
        }

        return anchors;
    }

    private filterBySuggestedAnchors(anchors: IAnchorNeedSolutionUi[]) {
        const suggestedAnchorIds = this.anchorIds as number[];
        const retVal = anchors.filter((anchor) => suggestedAnchorIds.includes(anchor.id));

        retVal.sort((a, b) => {
            return suggestedAnchorIds.indexOf(a.id) - suggestedAnchorIds.indexOf(b.id);
        });

        return retVal;
    }

    private formatDescription(descriptionFormat: string, descriptionKeys: string[]) {
        return format(descriptionFormat, ...descriptionKeys.map((descriptionKey) => this.translate(descriptionKey)));
    }

    private setNeedsSolutionTextsByRegionAndApplicationId() {
        const needsSolutiontexts = this.design.designData.designCodeLists[DesignCodeList.SmartAnchorNeedsSolutionTextsAnchorGroup] as SmartAnchorNeedsSolutionTextsAnchorGroup[];

        this.needsSolutionTextsAnchorGroup = needsSolutiontexts?.filter((x) =>
            this.anchorIds?.includes(x.anchorGroupId) &&
            x.regionId == this.design.region.id &&
            x.applicationId == this.smartAnchorApplication);
    }

    private getNeedsSolutionTextsByAnchorGroupId(anchorGroupId: number) {
        return this.needsSolutionTextsAnchorGroup?.find((x) => x.anchorGroupId == anchorGroupId);
    }

    private needsSolutionTextsTranslations(anchorGroupId: number) {
        const needsSolutionTextsCodeList = this.getNeedsSolutionTextsByAnchorGroupId(anchorGroupId);

        return needsSolutionTextsCodeList?.needsSolutionTexts.map(x => {
            return {
                need: this.translate('Agito.Hilti.Profis3.SmartAnchor.Needs.' + x.Item1),
                solutionText: this.translate('Agito.Hilti.Profis3.SmartAnchor.SolutionTexts.' + x.Item2)
            } as INeedSolutionText;
        });
    }

    private initializeAnchorFamilySprites() {
        this.anchors.forEach(anchor => {
            if (anchor.image != '') {
                includeSprites(this.elementRef.nativeElement.shadowRoot, !anchor.tested, anchor.image.substring(9) as Sprite);
            }
        });
    }
}
