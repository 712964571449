import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    WindLoadCityEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export interface IWindLoadCity extends ICodeListConstructor {
    federalState: string;
}

export class WindLoadCity extends CodeList {

    public federalState!: string;

    constructor(codelist: IWindLoadCity) {
        super(codelist);

        if (codelist != null) {
            this.federalState = codelist.federalState;
        }
    }

    public static fromService(codeList: WindLoadCityEntity) {
        return new WindLoadCity(CodeList.baseFromService<IWindLoadCity>('WindLoadCityEntity', codeList, {
            federalState: codeList.FederalState
        } as IWindLoadCity));
    }

    public override cloneDeep(): WindLoadCity {
        const codelist: WindLoadCity = super.cloneDeep() as WindLoadCity;

        codelist.federalState = this.federalState;

        return codelist;
    }
}
