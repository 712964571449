import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import imgMoreInfoOnFatigue1 from '../../../images/fatigue-tool-tip-1.png';
import imgMoreInfoOnFatigue2 from '../../../images/fatigue-tool-tip-2.png';
import imgMoreInfoOnFatigue3 from '../../../images/fatigue-tool-tip-3.png';
import imgMoreInfoOnFatigueTable1 from '../../../images/fatigue-table-1.png';
import imgMoreInfoOnFatigueTable2 from '../../../images/fatigue-table-2.png';
import imgMoreInfoOnFatigueTable3 from '../../../images/fatigue-table-3.png';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './more-info-on-fatigue-modal.component.html',
    styleUrls: ['./more-info-on-fatigue-modal.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MoreInfoOnFatigueModalComponent implements OnInit {
    public moreInfoOnFatigueImage1!: string;
    public moreInfoOnFatigueImage2!: string;
    public moreInfoOnFatigueImage3!: string;
    public moreInfoOnFatigueTable1!: string;
    public moreInfoOnFatigueTable2!: string;
    public moreInfoOnFatigueTable3!: string;

    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        private localizationService: LocalizationService
    ) { }

    ngOnInit(): void {
        this.moreInfoOnFatigueImage1 = imgMoreInfoOnFatigue1;
        this.moreInfoOnFatigueImage2 = imgMoreInfoOnFatigue2;
        this.moreInfoOnFatigueImage3 = imgMoreInfoOnFatigue3;
        this.moreInfoOnFatigueTable1 = imgMoreInfoOnFatigueTable1;
        this.moreInfoOnFatigueTable2 = imgMoreInfoOnFatigueTable2;
        this.moreInfoOnFatigueTable3 = imgMoreInfoOnFatigueTable3;
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
