import clone from 'lodash-es/clone';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import upperFirst from 'lodash-es/upperFirst';

import { ChangeDetectorRef, Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import {
    DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import {
    KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { ISteelCalculationInputsData } from '../../../shared/components/steel-calculation-inputs';
import {
    DesignStandard as DesignStandardEntity
} from '../../../shared/entities/code-lists/design-standard';
import { Region } from '../../../shared/entities/code-lists/region';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    SteelGuideline
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    reductionFactorGP, reductionFactorSP, stoSteelSafetyFactorGammaM
} from '../../../shared/helpers/app-settings-helper';
import {
    findSteelCalculationMethod, getAllowedSteelGuidelines
} from '../../../shared/helpers/steel-guideline-helper';
import { IPropertyMetaData, PropertyMetaData } from '../../../shared/properties/properties';
import { getProperty } from '../../helpers/object-helper';
import { AbpService } from '../../services/abp.service';
import { CodeListService } from '../../services/code-list.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
    templateUrl: './steel-calculation-inputs.component.html',
    styleUrls: ['./steel-calculation-inputs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SteelCalculationInputsComponent implements OnChanges {
    @Input()
    public data!: ISteelCalculationInputsData;

    @Input()
    public selectedSteelGuideline!: SteelGuideline;

    @Input()
    public selectedRegionId!: number;

    @Input()
    public selectedDesignType!: number;

    @Input()
    public selectedDesignStandardId!: number;

    @Input()
    public disabled = false;

    public steelGuideline!: DropdownProps<SteelGuideline>;
    public stoSteelSafetyFactorGammaM!: NumericTextBoxProps;
    public steelSafetyFactorGammaM0!: NumericTextBoxProps;
    public steelSafetyFactorGammaM1!: NumericTextBoxProps;
    public steelSafetyFactorGammaM2!: NumericTextBoxProps;
    public materialSafetyFactor!: NumericTextBoxProps;
    public steelCapacityFactor!: NumericTextBoxProps;
    public concreteCapacityFactor!: NumericTextBoxProps;
    public weldsCapacityFactor!: DropdownProps<number>;
    public inSteelSafetyFactorGammaM0!: NumericTextBoxProps;
    public inSteelSafetyFactorGammaM1!: NumericTextBoxProps;
    public inSteelSafetyFactorGammaMw!: NumericTextBoxProps;
    public inSteelSafetyFactorGammaMb!: NumericTextBoxProps;

    public steelCalculationDropdownVisible = true;
    public componentInitialized = false;
    private oldData!: ISteelCalculationInputsData;

    public noUnit = Unit.None;

    constructor(
        private localization: LocalizationService,
        private numberService: NumberService,
        private codeList: CodeListService,
        private abpService: AbpService,
        private unit: UnitService,
        private userSettingsService: UserSettingsService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    public get enBased() {
        return this.selectedSteelGuideline == SteelGuideline.EU;
    }

    public get aiscAndRlfdBasedR() {
        return this.selectedSteelGuideline == SteelGuideline.ACI
            || this.selectedSteelGuideline == SteelGuideline.KR
            || this.selectedSteelGuideline == SteelGuideline.TW;
    }

    public get asBased() {
        return this.selectedSteelGuideline == SteelGuideline.AU;
    }

    public get stoBased() {
        return this.selectedSteelGuideline == SteelGuideline.STO;
    }

    public get inBased() {
        return this.selectedSteelGuideline == SteelGuideline.IN;
    }

    public get hkBased() {
        return this.selectedSteelGuideline == SteelGuideline.HK;
    }

    public get areAdvancedCalculationInputsVisible() {
        return this.abpService.isAdvancedCalculationPossible(this.selectedDesignType, this.selectedDesignStandard as DesignStandardEntity, this.selectedRegion)
            || this.abpService.isHandrailAdvancedCalculationPossible(this.selectedDesignType, this.selectedDesignStandard as DesignStandardEntity, this.selectedRegion);
    }

    private get selectedRegion() {
        const regionCodeList = this.codeList.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find((region) => region.id == this.selectedRegionId) as Region;
    }

    private get selectedDesignStandard() {
        const designStandardCodeList = this.codeList.projectCodeLists[ProjectCodeList.DesignStandard] as unknown as DesignStandardEntity[];
        return designStandardCodeList.find((designStandard) => designStandard.id == this.selectedDesignStandardId);
    }

    ngOnChanges(): void {
        let update = false;

        if (this.oldData != null) {
            if (this.oldData.regionId != this.selectedRegionId) {
                update = true;
                this.onRegionChange();
            }

            if (this.oldData.designType != this.selectedDesignType) {
                update = true;
                this.setSteelCalculationDropdownVisibility();
            }

            if (this.oldData.designStandardId != this.selectedDesignStandardId) {
                update = true;
                this.onDesignStandardChange();
            }

            if (!isEqual(this.oldData.defaultValues, this.data.defaultValues)) {
                update = true;
                this.onDefaultValuesChanged();
            }
        }
        else if (this.data != null) {
            update = true;
        }

        if (update) {
            this.oldData = clone(this.data);

            if (!this.componentInitialized) {
                this.initSteelCalculationControls();
            }

            this.update();
        }
    }

    public onSteelGuidelineChange(value: SteelGuideline) {
        this.data.advancedCalculationInputsData.selectedSteelGuideline = value;
        this.data.steelGuideline = value;
        this.selectedSteelGuideline = value;
        this.updateMaterialSafetyFactorTitle();
    }

    private update() {
        if (this.data.steelCalculationValues) {
            const steelCalculationValues = this.data.steelCalculationValues();
            this.data.concreteCapacityFactor = steelCalculationValues?.concreteCapacityFactor;
            this.data.materialSafetyFactor = steelCalculationValues?.materialSafetyFactor;
            this.data.steelCapacityFactor = steelCalculationValues?.steelCapacityFactor;
            this.data.steelSafetyFactorGammaM0 = steelCalculationValues?.steelSafetyFactorGammaM0;
            this.data.steelSafetyFactorGammaM1 = steelCalculationValues?.steelSafetyFactorGammaM1;
            this.data.steelSafetyFactorGammaM2 = steelCalculationValues?.steelSafetyFactorGammaM2;
            this.data.weldsCapacityFactor = steelCalculationValues?.weldsCapacityFactor;
            this.data.inSteelSafetyFactorGammaM0 = steelCalculationValues?.inSteelSafetyFactorGammaM0;
            this.data.inSteelSafetyFactorGammaM1 = steelCalculationValues?.inSteelSafetyFactorGammaM1;
            this.data.inSteelSafetyFactorGammaMw = steelCalculationValues?.inSteelSafetyFactorGammaMw;
            this.data.inSteelSafetyFactorGammaMb = steelCalculationValues?.inSteelSafetyFactorGammaMb;
        }
        else {
            const settings = this.userSettingsService.settings;
            this.data.steelSafetyFactorGammaM0 = settings.application.defaults.steelSafetyFactorGammaM0.value ?? undefined;
            this.data.steelSafetyFactorGammaM1 = settings.application.defaults.steelSafetyFactorGammaM1.value ?? undefined;
            this.data.steelSafetyFactorGammaM2 = settings.application.defaults.steelSafetyFactorGammaM2.value ?? undefined;
            this.data.materialSafetyFactor = settings.application.defaults.materialSafetyFactor.value ?? undefined;
            this.data.steelCapacityFactor = settings.application.defaults.steelCapacityFactor.value ?? undefined;
            this.data.concreteCapacityFactor = settings.application.defaults.concreteCapacityFactor.value ?? undefined;
            this.data.weldsCapacityFactor = settings.application.defaults.weldsCapacityFactor.value ?? reductionFactorSP;
            this.data.inSteelSafetyFactorGammaM0 = settings.application.defaults.inSteelSafetyFactorGammaM0.value ?? undefined;
            this.data.inSteelSafetyFactorGammaM1 = settings.application.defaults.inSteelSafetyFactorGammaM1.value ?? undefined;
            this.data.inSteelSafetyFactorGammaMw = settings.application.defaults.inSteelSafetyFactorGammaMw.value ?? undefined;
            this.data.inSteelSafetyFactorGammaMb = settings.application.defaults.inSteelSafetyFactorGammaMb.value ?? undefined;
        }

        this.updateSteelCalculationMethod();
        this.updateMaterialSafetyFactorTitle();
        this.setSteelCalculationDropdownVisibility();
        this.setMaterialSafetyFactorValue(this.selectedRegionId);

        this.componentInitialized = true;

        this.changeDetectorRef.detectChanges();
    }

    private onDesignStandardChange() {
        this.updateSteelCalculationMethod();
        this.setSteelCalculationDropdownVisibility();
    }

    private onRegionChange() {
        this.setSteelCalculationDropdownVisibility();
        this.setMaterialSafetyFactorValue(this.selectedRegionId);
        this.updateSteelCalculationMethod();
    }

    private onDefaultValuesChanged() {
        if (this.oldData.defaultValues.materialSafetyFactor != this.data.defaultValues.materialSafetyFactor) {
            this.materialSafetyFactor.placeholder =
                this.getTextBoxPlaceholder('materialSafetyFactor', PropertyMetaData.Option_MaterialSafetyFactor);
        }

        if (this.oldData.defaultValues.steelSafetyFactorGammaM0 != this.data.defaultValues.steelSafetyFactorGammaM0) {
            this.steelSafetyFactorGammaM0.placeholder =
                this.getTextBoxPlaceholder('steelSafetyFactorGammaM0', PropertyMetaData.Option_SteelSafetyFactorGammaM0);
        }

        if (this.oldData.defaultValues.steelSafetyFactorGammaM1 != this.data.defaultValues.steelSafetyFactorGammaM1) {
            this.steelSafetyFactorGammaM1.placeholder =
                this.getTextBoxPlaceholder('steelSafetyFactorGammaM1', PropertyMetaData.Option_SteelSafetyFactorGammaM1);
        }

        if (this.oldData.defaultValues.steelSafetyFactorGammaM2 != this.data.defaultValues.steelSafetyFactorGammaM2) {
            this.steelSafetyFactorGammaM2.placeholder =
                this.getTextBoxPlaceholder('steelSafetyFactorGammaM2', PropertyMetaData.Option_SteelSafetyFactorGammaM2);
        }

        if (this.oldData.defaultValues.inSteelSafetyFactorGammaM0 != this.data.defaultValues.inSteelSafetyFactorGammaM0) {
            this.inSteelSafetyFactorGammaM0.placeholder =
                this.getTextBoxPlaceholder('inSteelSafetyFactorGammaM0', PropertyMetaData.Option_INSteelSafetyFactorGammaM0);
        }

        if (this.oldData.defaultValues.inSteelSafetyFactorGammaM1 != this.data.defaultValues.inSteelSafetyFactorGammaM1) {
            this.inSteelSafetyFactorGammaM1.placeholder =
                this.getTextBoxPlaceholder('inSteelSafetyFactorGammaM1', PropertyMetaData.Option_INSteelSafetyFactorGammaM1);
        }

        if (this.oldData.defaultValues.inSteelSafetyFactorGammaMw != this.data.defaultValues.inSteelSafetyFactorGammaMw) {
            this.inSteelSafetyFactorGammaMw.placeholder =
                this.getTextBoxPlaceholder('inSteelSafetyFactorGammaMw', PropertyMetaData.Option_INSteelSafetyFactorGammaMw);
        }

        if (this.oldData.defaultValues.inSteelSafetyFactorGammaMb != this.data.defaultValues.inSteelSafetyFactorGammaMb) {
            this.inSteelSafetyFactorGammaMb.placeholder =
                this.getTextBoxPlaceholder('inSteelSafetyFactorGammaMb', PropertyMetaData.Option_INSteelSafetyFactorGammaMb);
        }
    }

    private initSteelCalculationControls() {
        // Safety Factor GammaM for Russian-based designs
        this.stoSteelSafetyFactorGammaM = {
            id: 'steel-calculation-stoSteelSafetyFactorGammaM',
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.StoSteelSafetyFactorGammaM'),
            value: stoSteelSafetyFactorGammaM,
            disabled: true
        };

        // Common controls
        this.steelSafetyFactorGammaM0 = this.createNumericTextBox('steelSafetyFactorGammaM0', PropertyMetaData.Option_SteelSafetyFactorGammaM0);
        this.steelSafetyFactorGammaM1 = this.createNumericTextBox('steelSafetyFactorGammaM1', PropertyMetaData.Option_SteelSafetyFactorGammaM1);
        this.steelSafetyFactorGammaM2 = this.createNumericTextBox('steelSafetyFactorGammaM2', PropertyMetaData.Option_SteelSafetyFactorGammaM2);
        this.materialSafetyFactor = this.createNumericTextBox('materialSafetyFactor', PropertyMetaData.Option_MaterialSafetyFactor);
        this.steelCapacityFactor = this.createNumericTextBox('steelCapacityFactor', PropertyMetaData.Option_SteelCapacityFactor);
        this.concreteCapacityFactor = this.createNumericTextBox('concreteCapacityFactor', PropertyMetaData.Option_ConcreteCapacityFactor);
        this.weldsCapacityFactor = this.createCapacityFactorDropdownControl();
        this.inSteelSafetyFactorGammaM0 = this.createNumericTextBox('inSteelSafetyFactorGammaM0', PropertyMetaData.Option_INSteelSafetyFactorGammaM0);
        this.inSteelSafetyFactorGammaM1 = this.createNumericTextBox('inSteelSafetyFactorGammaM1', PropertyMetaData.Option_INSteelSafetyFactorGammaM1);
        this.inSteelSafetyFactorGammaMw = this.createNumericTextBox('inSteelSafetyFactorGammaMw', PropertyMetaData.Option_INSteelSafetyFactorGammaMw);
        this.inSteelSafetyFactorGammaMb = this.createNumericTextBox('inSteelSafetyFactorGammaMb', PropertyMetaData.Option_INSteelSafetyFactorGammaMb);

        // Steel guideline
        const steelGuidelines = getAllowedSteelGuidelines(this.codeList.projectCodeLists, this.selectedDesignStandardId, this.selectedRegionId);
        const defaultSteelGuideline = findSteelCalculationMethod(this.codeList.projectCodeLists, this.selectedDesignStandardId, this.selectedRegion);

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        this.steelGuideline = {
            id: 'steel-guideline',
            items: steelGuidelines.map((cl) => {
                return {
                    id: `steel-guideline-${cl.id}`,
                    text: cl.getTranslatedNameText(codeListDeps) ?? '',
                    value: cl.id as SteelGuideline,
                };
            }),
            disabled: true,
        };
        this.data.steelGuideline = this.data.editDesignSettings
            ? this.selectedSteelGuideline ?? defaultSteelGuideline
            : defaultSteelGuideline;
        this.selectedSteelGuideline = this.data.steelGuideline;
        this.onSteelGuidelineChange(this.data.steelGuideline);
    }

    private createNumericTextBox(key: string, propertyMetaData: IPropertyMetaData) {
        const retVal: NumericTextBoxProps = {
            id: `steel-calculation-${key}-textbox`,
            title: this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.' + upperFirst(key)),
            placeholder: this.getTextBoxPlaceholder(key, propertyMetaData),
            value: undefined,
            minValue: propertyMetaData.minValue,
            maxValue: propertyMetaData.maxValue
        };
        return retVal;
    }

    private createCapacityFactorDropdownControl() {
        const retVal: DropdownProps<number> = {
            id: 'add-edit-design-capacity-factor-dropdown',
            title: this.localization.getString('Agito.Hilti.Profis3.AddEditDesign.WeldsCapacityFactor'),
            items: [
                {
                    value: reductionFactorSP,
                    text: this.unit.formatNumber(reductionFactorSP, 1)
                },
                {
                    value: reductionFactorGP,
                    text: this.unit.formatNumber(reductionFactorGP, 1)
                }
            ]
        };

        return retVal;
    }

    private getTextBoxPlaceholder(key: string, propertyMetaData: IPropertyMetaData) {
        const placeholderValue = (getProperty(this.data.defaultValues, key) ?? propertyMetaData.defaultValue) as number;
        return placeholderValue ?? this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.Default');
    }

    private setSteelCalculationDropdownVisibility() {
        this.steelCalculationDropdownVisible = this.areAdvancedCalculationInputsVisible;
    }

    private updateSteelCalculationMethod() {
        if (this.data.editDesignSettings) {
            return;
        }

        this.modifySteelGuidelineControlItems();

        const steelGuideline = findSteelCalculationMethod(this.codeList.projectCodeLists, this.selectedDesignStandardId, this.selectedRegion);

        this.data.advancedCalculationInputsData.selectedSteelGuideline = steelGuideline;
        this.data.steelGuideline = steelGuideline;
        this.selectedSteelGuideline = steelGuideline;
        this.onSteelGuidelineChange(this.data.steelGuideline);

        this.steelGuideline.disabled = getAllowedSteelGuidelines(this.codeList.projectCodeLists, this.selectedDesignStandardId, this.selectedRegionId).length <= 1;
    }

    private setMaterialSafetyFactorValue(regionId: number) {
        // TODO: get min/max from DB.
        // min/max
        const materialSafetyFactorProperty = cloneDeep(PropertyMetaData.Option_MaterialSafetyFactor);
        materialSafetyFactorProperty.minValue = this.getMaterialSafetyFactorMinValue(regionId);

        this.materialSafetyFactor.minValue = materialSafetyFactorProperty.minValue;
        this.materialSafetyFactor.maxValue = materialSafetyFactorProperty.maxValue;

        if (this.data.materialSafetyFactor != null &&
            this.data.materialSafetyFactor < (materialSafetyFactorProperty.minValue as number)) {
            this.data.materialSafetyFactor = materialSafetyFactorProperty.minValue;
        }
    }

    private updateMaterialSafetyFactorTitle() {
        let translation = '';

        if (this.selectedSteelGuideline == SteelGuideline.KR) {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MaterialSafetyFactor.KR');
        }
        else if (this.selectedSteelGuideline == SteelGuideline.TW) {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MaterialSafetyFactor.TW');
        }
        else {
            translation = this.localization.getString('Agito.Hilti.Profis3.ApplicationSettings.MaterialSafetyFactor');
        }

        this.materialSafetyFactor.title = translation;
    }

    private getMaterialSafetyFactorMinValue(regionId: number) {
        if (regionId == KnownRegion.UnitedStates || regionId == KnownRegion.Canada) {
            return 1;
        }

        return PropertyMetaData.Option_MaterialSafetyFactor.minValue;
    }

    private modifySteelGuidelineControlItems(): void {
        const steelGuidelines = getAllowedSteelGuidelines(this.codeList.projectCodeLists, this.selectedDesignStandardId, this.selectedRegionId);

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        this.steelGuideline.items = steelGuidelines.map((steelGuideline) => {
            return {
                id: `steel-guideline-${steelGuideline.id}`,
                text: steelGuideline.getTranslatedNameText(codeListDeps) as string,
                value: steelGuideline.id as SteelGuideline,
            };
        });
        this.onSteelGuidelineChange(this.selectedSteelGuideline);
    }
}
