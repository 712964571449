import {
    MasonryAnchorPositionEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';

import { CodeList } from '../code-lists/code-list';

export class MasonryAnchorPosition extends CodeList {

    public static fromService(codeList: MasonryAnchorPositionEntity) {
        return new MasonryAnchorPosition(CodeList.baseFromService('MasonryAnchorPositionEntity', codeList));
    }

    public override cloneDeep(): MasonryAnchorPosition {
        return super.cloneDeep() as MasonryAnchorPosition;
    }
}
