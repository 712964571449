<pe-modal-header
    [title]="title"
    [modalInstance]="modalInstance"
    [closeButtonId]="'loads-hna-wizard-close-button'"
    [closeButtonDisabled]="submitted">
</pe-modal-header>

<form class="form equations-form" name="equationsForm" #equationsForm="ngForm" (ngSubmit)="nextStep()" novalidate
    [hidden]="step != stepEnum.Equations">
    <pe-modal-body>
        <div class="form-container">
            <div class="list-header">
                <div class="checkbox">
                    <pe-checkbox-button
                        [items]="selectAllCheckbox.items"
                        [selectedValues]="selectAllCheckbox.selectedValues"
                        (selectedValuesChange)="selectAllCheckbox.selectedValues = $any($event).detail; selectAllCheckboxSelectedValuesChange()">
                    </pe-checkbox-button>
                </div>

                <div class="name" [innerHtml]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.SelectAll')"
                    (click)="toggleCheckbox(selectAllCheckbox); selectAllCheckboxSelectedValuesChange();">
                </div>

                <div class="description" [innerHtml]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Equation')"></div>
            </div>

            <div class="list">
                <div *ngFor="let item of equations; trackBy:identifyEquation"
                    class="item"
                    (click)="toggleCheckbox(item.selectedCheckbox); itemCheckboxSelectedValuesChange();"
                    [ngClass]="{'greyed-out': item.isDisabled}">
                    <div class="checkbox">
                        <pe-checkbox-button
                            [items]="item.selectedCheckbox.items"
                            [selectedValues]="item.selectedCheckbox.selectedValues"
                            (selectedValuesChange)="item.selectedCheckbox.selectedValues = $any($event).detail; itemCheckboxSelectedValuesChange()">
                        </pe-checkbox-button>
                    </div>

                    <div class="name" [innerHtml]="item.name"></div>

                    <div class="description" [innerHtml]="item.description"></div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            class="modal-button"
            [id]="'loads-hna-wizard-equations-cancel-button'"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            type="submit"
            class="modal-button"
            [id]="'loads-hna-wizard-equations-next-button'"
            [look]="'Cta'"
            [disabled]="(equationsForm.enabled && !equationsForm.valid) || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Equations.Next')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>

<form class="form factors-form" name="factorsForm" #factorsForm="ngForm" (ngSubmit)="nextStep()" novalidate
    [hidden]="step != stepEnum.Factors">
    <pe-modal-body>
        <div class="form-container">
            <div class="grid-header">
                <div class="column-name">
                    <div class="name-title"></div>
                </div>
                <div class="column-number column-sustained-load-factor">
                    <div class="number-title" [innerHtml]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.SustainedLoadFactor')"></div>
                </div>
                <div class="column-number column-load-factor">
                    <div class="number-title" [innerHtml]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.LoadFactor')"></div>
                </div>
                <div class="column-number column-force-x">
                    <div class="number-title" [innerHtml]="getLocalizedTextAndUnit('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.ForceX', unitGroupEnum.Force)">
                    </div>
                </div>
                <div class="column-number column-force-y">
                    <div class="number-title" [innerHtml]="getLocalizedTextAndUnit('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.ForceY', unitGroupEnum.Force)">
                    </div>
                </div>
                <div class="column-number column-force-z">
                    <div class="number-title" [innerHtml]="getLocalizedTextAndUnit('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.ForceZ', unitGroupEnum.Force)">
                    </div>
                </div>
                <div class="column-number column-moment-x">
                    <div class="number-title" [innerHtml]="getLocalizedTextAndUnit('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.MomentX', unitGroupEnum.Moment)">
                    </div>
                </div>
                <div class="column-number column-moment-y">
                    <div class="number-title" [innerHtml]="getLocalizedTextAndUnit('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.MomentY', unitGroupEnum.Moment)">
                    </div>
                </div>
                <div class="column-number column-moment-z">
                    <div class="number-title" [innerHtml]="getLocalizedTextAndUnit('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.MomentZ', unitGroupEnum.Moment)">
                    </div>
                </div>
            </div>
            <div class="grid-container">
                <div *ngFor="let item of factors; trackBy:identifyFactor" class="grid-item"
                    [ngClass]="{'greyed-out': item.isDisabled}">
                    <div class="column-name">
                        <span class="name-text" [innerHtml]="item.name"></span>
                    </div>
                    <div class="column-number column-sustained-load-factor">
                        <pe-numeric-text-box
                            [value]="item.sustainedLoadFactorTextBox.value"
                            (valueChange)="item.sustainedLoadFactorTextBox.value = $any($event).detail"
                            [unit]="item.sustainedLoadFactorTextBox.unit"
                            [minValue]="item.sustainedLoadFactorTextBox.minValue"
                            [maxValue]="item.sustainedLoadFactorTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="item.sustainedLoadFactorEnabled"
                            [showStepper]="false"
                            [appendUnit]="false"
                            [disabled]="item.isDisabled || submitted || !item.sustainedLoadFactorEnabled"
                            [textAlign]="'Center'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="column-number column-load-factor">
                        <pe-dropdown
                            [id]="item.loadFactorDropdown.id"
                            [items]="item.loadFactorDropdown.items"
                            [selectedValue]="item.loadFactorDropdown.selectedValue"
                            (selectedValueChange)="item.loadFactorDropdown.selectedValue = $any($event).detail"
                            [notSelectedText]="item.loadFactorDropdown.notSelectedText"
                            [disabled]="item.isDisabled || submitted || !item.loadFactorEnabled"
                            [borderBottom]="'None'">
                        </pe-dropdown>
                    </div>
                    <div class="column-number column-force-x">
                        <pe-numeric-text-box
                            [value]="item.forceXTextBox.value"
                            (valueChange)="item.forceXTextBox.value = $any($event).detail"
                            [unit]="item.forceXTextBox.unit"
                            [minValue]="item.forceXTextBox.minValue"
                            [maxValue]="item.forceXTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [showStepper]="false"
                            [appendUnit]="false"
                            [disabled]="item.isDisabled || submitted"
                            [textAlign]="'Center'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="column-number column-force-y">
                        <pe-numeric-text-box
                            [value]="item.forceYTextBox.value"
                            (valueChange)="item.forceYTextBox.value = $any($event).detail"
                            [unit]="item.forceYTextBox.unit"
                            [minValue]="item.forceYTextBox.minValue"
                            [maxValue]="item.forceYTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [showStepper]="false"
                            [appendUnit]="false"
                            [disabled]="item.isDisabled || submitted"
                            [textAlign]="'Center'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="column-number column-force-z">
                        <pe-numeric-text-box
                            [value]="item.forceZTextBox.value"
                            (valueChange)="item.forceZTextBox.value = $any($event).detail"
                            [unit]="item.forceZTextBox.unit"
                            [minValue]="item.forceZTextBox.minValue"
                            [maxValue]="item.forceZTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [showStepper]="false"
                            [appendUnit]="false"
                            [disabled]="item.isDisabled || submitted || !areAllLoadsForLedgerAngleAvailable"
                            [textAlign]="'Center'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="column-number column-moment-x">
                        <pe-numeric-text-box
                            [value]="item.momentXTextBox.value"
                            (valueChange)="item.momentXTextBox.value = $any($event).detail"
                            [unit]="item.momentXTextBox.unit"
                            [minValue]="item.momentXTextBox.minValue"
                            [maxValue]="item.momentXTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [showStepper]="false"
                            [appendUnit]="false"
                            [disabled]="item.isDisabled || submitted || !areMomentsForMetalDeckAvailable || !areAllLoadsForLedgerAngleAvailable"
                            [textAlign]="'Center'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="column-number column-moment-y">
                        <pe-numeric-text-box
                            [value]="item.momentYTextBox.value"
                            (valueChange)="item.momentYTextBox.value = $any($event).detail"
                            [unit]="item.momentYTextBox.unit"
                            [minValue]="item.momentYTextBox.minValue"
                            [maxValue]="item.momentYTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [showStepper]="false"
                            [appendUnit]="false"
                            [disabled]="item.isDisabled || submitted || !areMomentsForMetalDeckAvailable || !areAllLoadsForLedgerAngleAvailable"
                            [textAlign]="'Center'">
                        </pe-numeric-text-box>
                    </div>
                    <div class="column-number column-moment-z">
                        <pe-numeric-text-box
                            [value]="item.momentZTextBox.value"
                            (valueChange)="item.momentZTextBox.value = $any($event).detail"
                            [unit]="item.momentZTextBox.unit"
                            [minValue]="item.momentZTextBox.minValue"
                            [maxValue]="item.momentZTextBox.maxValue"
                            [updateOnBlur]="true"
                            [required]="true"
                            [showStepper]="false"
                            [appendUnit]="false"
                            [disabled]="item.isDisabled || submitted || !areMomentsForMetalDeckAvailable || !areAllLoadsForLedgerAngleAvailable"
                            [borderRight]="'None'"
                            [textAlign]="'Center'">
                        </pe-numeric-text-box>
                    </div>
                </div>
            </div>
        </div>
    </pe-modal-body>

    <pe-modal-footer>
        <pe-button
            class="modal-button"
            [id]="'loads-hna-wizard-factors-cancel-button'"
            [look]="'Default'"
            (buttonClick)="close()"
            [text]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.Cancel')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            class="modal-button"
            [id]="'loads-hna-wizard-factors-prev-button'"
            [look]="'Default'"
            (buttonClick)="prevStep()"
            [text]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.Prev')"
            [disabled]="submitted">
        </pe-button>

        <pe-button
            type="submit"
            class="modal-button"
            [id]="'loads-hna-wizard-factors-next-button'"
            [look]="'Cta'"
            [disabled]="(factorsForm.enabled && !factorsForm.valid) || submitted">
            <pe-loading-button
                [loading]="submitted"
                [text]="translate('Agito.Hilti.Profis3.LoadsHNAWizard.Factors.Next')">
            </pe-loading-button>
        </pe-button>
    </pe-modal-footer>
</form>
