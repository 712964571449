import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './advanced-baseplate-license-required.component.html',
    styleUrls: ['./advanced-baseplate-license-required.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AdvancedBaseplateLicenseRequiredComponent {
    @Input()
    public modalInstance!: ModalInstance;

    constructor(
        private localizationService: LocalizationService
    ) { }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public close() {
        this.modalInstance.close();
    }
}
