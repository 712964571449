import {
    CodeList as CodeListBase, ICodeListConstructor
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';

import { TooltipType } from '@profis-engineering/pe-ui-common/services/code-list.common';

export abstract class CodeList extends CodeListBase {
    public declare tooltipType?: TooltipType;
    public declare tooltipDisabledType?: TooltipType;

    constructor(codeList: ICodeListConstructor) {
        super(codeList);
    }

    protected static override baseFromService<TConstructor extends ICodeListConstructor>(
        displayKeyPrefix: string,
        codeList: { [property: string]: any },
        constructor?: TConstructor
    ): TConstructor {
        const displayKey = this.getCodeListProperty<string>(codeList, 'DisplayKey');
        const imageProperty = this.getCodeListProperty<string>(codeList, 'Image');
        const tooltipTypeProperty = this.getCodeListProperty<number>(codeList, 'TooltipType');
        const tooltipDisabledTypeProperty = this.getCodeListProperty<number>(codeList, 'TooltipDisabledType');
        const nameResourceKey = displayKey != null && displayKey != ''
            ? `Agito.Hilti.Profis3.CodeList.${displayKeyPrefix}.${displayKey}`
            : undefined;

        const image = imageProperty != null && imageProperty != ''
            ? `sprite-${imageProperty}`
            : undefined;

        const tooltipType = tooltipTypeProperty != null ? tooltipTypeProperty : TooltipType.Normal;
        const tooltipDisabledType = tooltipDisabledTypeProperty != null ? tooltipDisabledTypeProperty : TooltipType.Normal;
        const tag = this.getCodeListProperty<string>(codeList, 'Tag');

        return {
            id: this.getCodeListProperty<number>(codeList, 'Id'),
            name: this.getCodeListProperty<string>(codeList, 'DisplayName'),
            nameResourceKey,
            image,
            tooltipDisplayKey: this.getCodeListProperty(codeList, 'TooltipDisplayKey'),
            tooltipTitleDisplayKey: this.getCodeListProperty(codeList, 'TooltipTitleDisplayKey'),
            tooltipType,
            tooltipDisabledDisplayKey: this.getCodeListProperty(codeList, 'TooltipDisabledDisplayKey'),
            tooltipDisabledTitleDisplayKey: this.getCodeListProperty(codeList, 'TooltipDisabledTitleDisplayKey'),
            tooltipDisabledType,
            kbNumberRegion: this.getCodeListProperty<{ [key: string]: string }>(codeList, 'kbNumberRegion'),
            kbTooltip: this.getCodeListProperty<string>(codeList, 'KBTooltip'),
            displayKey: this.getCodeListProperty<string>(codeList, 'DisplayKey'),
            tag: tag != null && tag != '' ? `Agito.Hilti.Profis3.${displayKey}.Tag` : undefined,
            ...constructor
        } as TConstructor;
    }

    protected static override getCodeListProperty<T>(codeList: { [property: string]: any }, property: string): T {
        return codeList[property] as T;
    }
}
