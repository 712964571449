<pe-modal-header
    [closeButtonId]="'asad-optimize-modal-close-button'"
    [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.Title')"
    [modalInstance]="modalInstance">
</pe-modal-header>

<pe-modal-body>
    <div class="options">
        <div class="content-container separate-line">
            <p class="content-container-text content-container-header" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.BaseplateSize')"></p>
            <div *ngIf="baseplateSizeHighValue != baseplateSizeOptions.ceil" class="content-container-text">
                <p>{{formatArea(baseplateSizeHighValue)}}</p>
                <p [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.OrLess')"></p>
            </div>
            <p *ngIf="baseplateSizeHighValue == baseplateSizeOptions.ceil" class="content-container-text" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.Any')"></p>
            <div class="content-container-option">
                <div class="custom-ngx-slider">
                    <ngx-slider (userChange)="changeDetector()" [(value)]="baseplateSizeHighValue" [options]="baseplateSizeOptions"></ngx-slider>
                </div>
            </div>
        </div>
        <div class="content-container separate-line">
            <p class="content-container-text content-container-header" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.EmbedmentDepth')"></p>
            <div *ngIf="embedmentDepthHighValue != embedmentDepthOptions.ceil" class="content-container-text">
                <p>{{formatLength(embedmentDepthHighValue)}}</p>
                <p [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.OrLess')"></p>
            </div>
            <p *ngIf="embedmentDepthHighValue == embedmentDepthOptions.ceil" class="content-container-text" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.Any')"></p>
            <div class="content-container-option">
                <div class="custom-ngx-slider">
                    <ngx-slider (userChange)="changeDetector()" [(value)]="embedmentDepthHighValue" [options]="embedmentDepthOptions"></ngx-slider>
                </div>
            </div>
        </div>
        <div class="content-container separate-line">
            <p class="content-container-text content-container-header" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.NumberOfAnchors')"></p>
            <div *ngIf="numberOfAnchorsHighValue != numberOfAnchorsOptions.ceil" class="content-container-text">
                <p>{{numberOfAnchorsHighValue}}</p>
                <p [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.OrLess')"></p>
            </div>
            <p *ngIf="numberOfAnchorsHighValue == numberOfAnchorsOptions.ceil" class="content-container-text" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.Any')"></p>
            <div class="content-container-option">
                <div class="custom-ngx-slider">
                    <ngx-slider (userChange)="changeDetector()" [(value)]="numberOfAnchorsHighValue" [options]="numberOfAnchorsOptions"></ngx-slider>
                </div>
            </div>
        </div>
        <div class="content-container separate-line">
            <p class="content-container-text content-container-header" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.MinimumUtilization')"></p>
            <p class="content-container-text">{{utilizationLowValue}}%</p>
            <div class="content-container-option">
                <div class="custom-ngx-slider">
                    <ngx-slider (userChange)="changeDetector()" [(value)]="utilizationLowValue" [options]="utilizationOptions"></ngx-slider>
                </div>
            </div>
        </div>
        <div class="content-container">
            <p class="content-container-text content-container-header" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.ProductivityHealthSafety')"></p>
            <div class="content-container-productivity-health-safety">
                <div class="option-checkbox-and-text">
                    <label class="option-checkbox">
                        <input type="checkbox" [checked]="noCleaningRequired" (change)="noCleaningRequired = !noCleaningRequired"/>
                        <span class="checkmark"></span>
                    </label>
                    <p class="option-checkbox-text" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.NoCleaningRequired.Checkbox')"></p>
                </div>
                <div class="option-checkbox-and-text">
                    <label class="option-checkbox">
                        <input type="checkbox" [checked]="noAutomaticCleaning" (change)="noAutomaticCleaning = !noAutomaticCleaning"/>
                        <span class="checkmark"></span>
                    </label>
                    <p class="option-checkbox-text" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.NoAutomaticCleaning.Checkbox')"></p>
                </div>
                <div class="option-checkbox-and-text">
                    <label class="option-checkbox">
                        <input type="checkbox" [checked]="isAdaptiveTorqueAllowed" (change)="isAdaptiveTorqueAllowed = !isAdaptiveTorqueAllowed"/>
                        <span class="checkmark"></span>
                    </label>
                    <p class="option-checkbox-text" [innerHtml]="translate('Agito.Hilti.Profis3.SmartBasePlate.BaseplateResultsPreferences.AdaptiveTorque.Checkbox')"></p>
                </div>
            </div>
        </div>
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        [look]="'Default'"
        class="modal-button"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.BaseplateSize.Cancel')">
    </pe-button>

    <pe-button
        [id]="'baseplate-size-save-button'"
        [look]="'Cta'"
        class="modal-button"
        (buttonClick)="update()"
        [text]="updateButtonText"
        [disabled]="updateButtonDisabled">
    </pe-button>
</pe-modal-footer>
