import {
    Tooltip
} from '@profis-engineering/pe-ui-common/components/content-tooltip/content-tooltip.common';
import {
    ToggleButtonGroupItem
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import {
    UnitGroup
} from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { IValueRange } from '@profis-engineering/pe-ui-common/helpers/validation-helper';
import {
    TranslationFormat
} from '../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation';
import {
    LoadCombination, LoadCombinationHandrail, UIProperty
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    DesignStandard, DesignType, LoadCharacteristic, LoadType, MetalDeckAnchorPosition
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { getDesignStandardNameByDesignStandardId } from '../../shared/helpers/design-standard-helper';
import { PropertyMetaData } from '../../shared/properties/properties';

import { GuidServiceBase as GuidService } from '@profis-engineering/pe-ui-common/services/guid.common';
import {
    RegionHub
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { DesignPe } from '../../shared/entities/design-pe';
import { LocalizationService } from '../services/localization.service';
import { UnitService } from '../services/unit.service';
import { ModalService } from '../services/modal.service';
import { TranslationFormatService } from '../services/translation-format.service';

export enum InternalLoadType {
    Unknown = 0,
    Static = 1,
    Seismic = 2,
    Fatigue = 3,
    Fire = 4
}

export interface LoadCombinationColumnProps<T> {
    name: T;
    forceVx: T;
    forceVy: T;
    forceN: T;
    momentMx: T;
    momentMy: T;
    momentMz: T;
    utilization: T;
    handrail: T;
}

export interface MousePosition {
    x: number;
    y: number;
}

export class LoadsComponentHelper {
    public static readonly minResizerColumnWidth: LoadCombinationColumnProps<number> = {
        name: 50,
        forceVx: 50,
        forceVy: 50,
        forceN: 50,
        momentMx: 50,
        momentMy: 50,
        momentMz: 50,
        utilization: 55,
        handrail: 50
    };

    private static readonly typeButtonWidth: number = 32; // sync with css variable @type-button-width

    // Column Resizing
    public resizerColumnWidth?: LoadCombinationColumnProps<number> | null;
    public resizerOverlayElement!: HTMLElement;
    public resizerTriggerPosition?: MousePosition;
    public resizerTriggerElement!: HTMLElement;

    public designTypeEnum = {
        Concrete: DesignType.Concrete,
        Handrail: DesignType.Handrail,
        Masonry: DesignType.Masonry,
        MetalDeck: DesignType.MetalDeck
    };

    public isUtilizationCompact = false;
    public isHandrailUtilizationCompact = false;

    // Load
    private _addingNewLoad = false;
    public newLoad!: LoadCombination;

    public loadTypeEnum = InternalLoadType;
    public staticTooltip?: Tooltip;
    public seismicTooltip?: Tooltip;
    public fireTooltip?: Tooltip;
    public fatigueTooltip?: Tooltip;

    public forceXRange?: IValueRange;
    public dynamicForceXRange?: IValueRange;
    public forceYRange?: IValueRange;
    public dynamicForceYRange?: IValueRange;
    public forceZRange?: IValueRange;
    public dynamicForceZRange?: IValueRange;
    public momentXRange?: IValueRange;
    public dynamicMomentXRange?: IValueRange;
    public momentYRange?: IValueRange;
    public dynamicMomentYRange?: IValueRange;
    public momentZRange?: IValueRange;
    public dynamicMomentZRange?: IValueRange;

    constructor(
        private localization: LocalizationService,
        private unit: UnitService,
        private modal: ModalService,
        private translationFormatService: TranslationFormatService,
        private guid: GuidService,
        private design: DesignPe,
        private maxLoadCombinations: number
    ) { }

    public get columnTypeWidth() {
        const count = (this.isLoadTypeAllowed(InternalLoadType.Static) ? 1 : 0) +
            (this.isLoadTypeAllowed(InternalLoadType.Seismic) ? 1 : 0) +
            (this.isLoadTypeAllowed(InternalLoadType.Fire) ? 1 : 0) +
            (this.isLoadTypeAllowed(InternalLoadType.Fatigue) ? 1 : 0);

        return (count * LoadsComponentHelper.typeButtonWidth + 1) + 1; // +1 add left border for each button and +1 add right border for the last button
    }

    public get loads() {
        return this.design.loadCombinations;
    }

    public get maxLoads() {
        return this.maxLoadCombinations;
    }

    public get areLoadTypesAvailableForLoadCombinations() {
        return (this.design.designType.id == DesignType.Concrete && !this.design.isDynamicFatigue) ||
            (this.design.designType.id == DesignType.Masonry) ||
            (this.design.designType.id == DesignType.MetalDeck);
    }

    public get addingNewLoad() {
        return this._addingNewLoad;
    }

    public set addingNewLoad(value: boolean) {
        this._addingNewLoad = value;
    }

    public get isDynamicFatigueExpertMode() {
        return this.design.isDynamicFatigue && this.design.isFatigueExpertMode;
    }

    public get deleteLoadDisabled() {
        return this.isPropertyDisabled(PropertyMetaData.Loads_DeleteLoad.id);
    }

    public get haveSingleLoad() {
        const loads = this.loads;
        return loads == null || loads.length <= 1;
    }

    public get showDynamic() {
        return this.loads?.some(l => l.HasSustainedLoads) || this.design.isDynamicFatigue;
    }

    public get isSustained() {
        return this.loads?.some(l => l.HasSustainedLoads);
    }

    public get isFactoredSustainedLoadsActive() {
        return this.design.isFactoredSustainedLoadsActive;
    }

    public get areAllLoadsForLedgerAngleAvailable() {
        return this.areAllLoadsForLedgerAngleAvailablePvt(this.design.region.hubId, this.design.isCBFEMCalculation, this.design.isLedgerAngle);
    }

    // Moments should be hidden if metalDeckAnchorPosition is not set to top position
    public get areMomentsForMetalDeckAvailable() {
        return this.design.designType.id != DesignType.MetalDeck ?
            true :
            (this.design.model[UIProperty.BaseMaterial_MetalDeckAnchorPosition] as MetalDeckAnchorPosition) == MetalDeckAnchorPosition.Top;
    }

    public get forceUnit() {
        return this.unit.getDefaultUnit(UnitGroup.Force);
    }

    public get momentUnit() {
        return this.unit.getDefaultUnit(UnitGroup.Moment);
    }

    // Misc
    public get firstRowLoadLegendTooltip() {
        if (!this.design.isDynamicFatigue) {
            return this.translate('Agito.Hilti.Profis3.Loads.Loads.Design.Tooltip');
        }

        if (this.isDynamicFatigueExpertMode) {
            return this.translate('Agito.Hilti.Profis3.Loads.FatigueExpertMode.UpperLoad.Tooltip');
        }

        return this.translate('Agito.Hilti.Profis3.Loads.FatigueSimpleMode.UpperLoad.Tooltip');
    }

    public get firstRowLoadLegendTranslation() {
        if (!this.design.isDynamicFatigue) {
            return this.translate('Agito.Hilti.Profis3.Loads.Loads.Design');
        }

        if (this.isDynamicFatigueExpertMode) {
            return this.translate('Agito.Hilti.Profis3.Loads.FatigueExpertMode.UpperLoad');
        }

        return this.translate('Agito.Hilti.Profis3.Loads.FatigueSimpleMode.UpperLoad');
    }

    public get secondRowLoadLegendTooltip() {
        if (!this.design.isDynamicFatigue) {
            return this.translate('Agito.Hilti.Profis3.Loads.Loads.Sustained.Tooltip');
        }

        if (this.isDynamicFatigueExpertMode) {
            return this.translate('Agito.Hilti.Profis3.Loads.FatigueExpertMode.LowerLoad.Tooltip');
        }

        return this.translate('Agito.Hilti.Profis3.Loads.FatigueSimpleMode.LowerLoad.Tooltip');
    }

    public get secondRowLoadLegendTranslation() {
        if (!this.design.isDynamicFatigue) {
            return this.translate('Agito.Hilti.Profis3.Loads.Loads.Sustained');
        }

        if (this.isDynamicFatigueExpertMode) {
            return this.translate('Agito.Hilti.Profis3.Loads.FatigueExpertMode.LowerLoad');
        }

        return this.translate('Agito.Hilti.Profis3.Loads.FatigueSimpleMode.LowerLoad');
    }

    public get isSmartAnchorToggleON() {
        return this.design.model[UIProperty.SmartAnchor_Enabled];
    }

    public initialize() {
        // Load type tooltips
        this.setLoadTypeTooltips();

        // Resizing
        this.resizerOverlayElement = document.createElement('div');
        this.resizerOverlayElement.setAttribute('id', 'resize-drag-handle-vertical');

        // New load
        this.initializeNewLoad();

        // Load value ranges
        this.loadValueRanges();
    }

    public setLoadTypeTooltips() {
        this.staticTooltip = {
            title: this.translate('Agito.Hilti.Profis3.LoadType.Static.Tooltip.Title'),
            content: this.translate('Agito.Hilti.Profis3.LoadType.Static.Tooltip.' + getDesignStandardNameByDesignStandardId(this.design.designStandard?.id ?? 0))
        };

        this.seismicTooltip = {
            title: (this.design.designType.id == DesignType.Masonry && this.design.designStandard.id == DesignStandard.ACI)
                ? this.translate('Agito.Hilti.Profis3.LoadType.Seismic.TooltipTitle.Masonry.ACI')
                : this.translate('Agito.Hilti.Profis3.LoadType.Seismic.Tooltip.Title'),
            content: (this.design.designType.id == DesignType.Masonry && this.design.designStandard.id == DesignStandard.ACI)
                ? this.translate('Agito.Hilti.Profis3.LoadType.Seismic.Tooltip.Masonry.ACI')
                : this.isSmartAnchorToggleON
                    ? this.translate('Agito.Hilti.Profis3.LoadType.Seismic.Tooltip.Disabled')
                    : this.translate('Agito.Hilti.Profis3.LoadType.Seismic.Tooltip.' + getDesignStandardNameByDesignStandardId(this.design.designStandard?.id ?? 0))
        };

        this.fireTooltip = {
            title: this.translate('Agito.Hilti.Profis3.LoadType.Fire.Tooltip.Title'),
            content: this.isSmartAnchorToggleON
                ? this.translate('Agito.Hilti.Profis3.LoadType.Fire.Tooltip.Disabled')
                : this.translate('Agito.Hilti.Profis3.LoadType.Fire.Tooltip.' + getDesignStandardNameByDesignStandardId(this.design.designStandard?.id ?? 0))
        };
    }

    public setCompactCollapsed(collapsed: boolean) {
        if (collapsed) {
            // Loads collapsed --> compact = true
            this.isUtilizationCompact = true;
            this.isHandrailUtilizationCompact = true;
            return true;
        }

        return false;
    }

    public calculateCompact(utilizationElement?: HTMLElement, handrailElement?: HTMLElement) {
        this.isUtilizationCompact = (utilizationElement?.offsetWidth ?? 0) < 210;
        this.isHandrailUtilizationCompact = (handrailElement?.offsetWidth ?? 0) < 210;
    }

    public beginColumnResizing(mouse: MousePosition, resizerTriggerElement: HTMLElement) {
        this.resizerOverlayElement.style.top = '0';
        this.resizerOverlayElement.style.bottom = '0';
        this.resizerOverlayElement.style.left = `${mouse.x}px`;

        this.resizerTriggerPosition = mouse;
        this.resizerTriggerElement = resizerTriggerElement;
    }

    public continueColumnResizing(mouse: MousePosition) {
        this.resizerOverlayElement.style.left = `${mouse.x}px`;
    }

    public endColumnResizing(mouse: MousePosition) {
        const diffMouse = {
            x: mouse.x - (this.resizerTriggerPosition?.x ?? 0),
            y: mouse.y - (this.resizerTriggerPosition?.y ?? 0)
        };

        this.resizeColumns(this.resizerTriggerElement, diffMouse.x);
    }

    public calculateMouseInsideElement(event: MouseEvent, element: HTMLElement): MousePosition {
        return {
            x: event.pageX - (element.getBoundingClientRect().left + (window.scrollX || document.documentElement.scrollLeft)),
            y: event.pageY - (element.getBoundingClientRect().top + (window.scrollY || document.documentElement.scrollTop))
        };
    }

    public isPropertyDisabled(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.disabled : true;
    }

    public isPropertyHidden(propertyId: number) {
        const propertyInfo = this.design.properties.get(propertyId);
        return propertyInfo !== undefined ? propertyInfo.hidden : true;
    }

    public isLoadDynamic(load: LoadCombination) {
        return (load.HasSustainedLoads && this.isLoadNormalOrUnfactored(load))
            || this.design.isDynamicFatigue;
    }

    public isLoadNormalOrUnfactored(load?: LoadCombination) {
        return load?.LoadCharacteristic == null ||
            load?.LoadCharacteristic == LoadCharacteristic.Unfactored;
    }

    public isHandrailOk(load: Partial<LoadCombinationHandrail>) {
        return (load.StructuralSafetyForDisplay == null
            ? true
            : load.StructuralSafetyForDisplay <= 100 && load.StructuralSafetyForDisplay >= 0);
    }

    public isLoadTypeAllowed(loadType: InternalLoadType) {
        return this.design.properties?.get(PropertyMetaData.Loads_LoadType.id).allowedValues?.some(allowed => allowed == loadType);
    }

    public hasMessages(load: LoadCombination) {
        if (load == null || load.ResultMessages == null) {
            return false;
        }

        return load.ResultMessages.length > 0;
    }

    public showMessages(load: LoadCombination) {
        this.modal.openLoadsMessages(this.getScopeCheckMessages(load.ResultMessages));
    }

    public isTensionInvalid(load: LoadCombination) {
        if (load.Tension != null) {
            return load.Tension.ValueError;
        }

        return false;
    }

    public isShearInvalid(load: LoadCombination) {
        if (load.Shear != null) {
            return load.Shear.ValueError;
        }

        return false;
    }

    public isCombinationInvalid(load: LoadCombination) {
        if (load.Combination != null) {
            return load.Combination.ValueError;
        }

        return false;
    }

    public isTotalInvalid(load: LoadCombination) {
        return this.isTensionInvalid(load) || this.isShearInvalid(load) || this.isCombinationInvalid(load);
    }

    public isLoadCharacteristicSet(load: LoadCombination) {
        return load.LoadCharacteristic != null;
    }

    public formatPercentage(valueStr?: number): string {
        if (valueStr == null || valueStr == undefined) {
            return '';
        }

        const value = valueStr;
        switch (value) {
            case undefined:
            case null:
                return '';
            case Number.NEGATIVE_INFINITY:
                return '-∞ %';
            case Number.POSITIVE_INFINITY:
                return '∞ %';
            default:
                return this.unit.formatNumber(value, 2) + '%';
        }
    }

    public formatLength(value?: number): string {
        if (value == null || value == undefined) {
            return '';
        }

        const defaultUnit = this.unit.getDefaultUnit(UnitGroup.Length);
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Length);
        const defaultPrecision = this.unit.getPrecision(defaultUnit);
        const valueToFormat = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(valueToFormat, defaultPrecision)
            + ' ' + this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Length));
    }

    public initializeNewLoad() {
        this.resetNewLoad();
    }

    public loadLoadTypeToggleItems(parentId: string, loadIndex: number, loadImages: Map<InternalLoadType, IIconStyle>) {
        const loadTypeToggleItems: ToggleButtonGroupItem<InternalLoadType>[] = [];

        let type = InternalLoadType.Static;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<InternalLoadType> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-shock',
                tooltip: this.staticTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = InternalLoadType.Seismic;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<InternalLoadType> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-seismic',
                tooltip: this.seismicTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        type = InternalLoadType.Fire;
        if (this.isLoadTypeAllowed(type)) {
            const item: ToggleButtonGroupItem<InternalLoadType> = {
                id: '',
                value: type,
                image: loadImages.has(type) ? loadImages.get(type) : {},
                class: 'sprite-anchor-fire-resistant',
                tooltip: this.fireTooltip
            };
            this.setLoadTypeToggleItemId(item, parentId, loadIndex);
            loadTypeToggleItems.push(item);
        }

        if(this.isSmartAnchorToggleON){
            loadTypeToggleItems.filter(x => x.value != InternalLoadType.Static).forEach(load => load.disabled = true);
        }

        return loadTypeToggleItems;
    }

    public setLoadTypeToggleItemId(item: ToggleButtonGroupItem<InternalLoadType>, parentId: string, loadIndex: number) {
        switch (item.value) {
            case InternalLoadType.Static:
                item.id = `${parentId}-${loadIndex != null ? loadIndex : 'new-load'}-load-type-static-button`;
                break;

            case InternalLoadType.Seismic:
                item.id = `${parentId}-${loadIndex != null ? loadIndex : 'new-load'}-load-type-seismic-button`;
                break;

            case InternalLoadType.Fire:
                item.id = `${parentId}-${loadIndex != null ? loadIndex : 'new-load'}-load-type-fire-button`;
                break;
        }
    }

    public utilizationValue(value1?: number | null, value2?: number | null, value3?: number | null, value4?: number | null, value5?: number | null) {
        if (value1 == null && value2 == null && value3 == null && value4 == null && value5 == null) {
            return null;
        }

        return this.unit.formatNumber(Math.max(value1 ?? 0, value2 ?? 0, value3 ?? 0, value4 ?? 0, value5 ?? 0), 0) + '%';
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    private getMinResizerColumnWidth(property: keyof LoadCombinationColumnProps<number>): number {
        return LoadsComponentHelper.minResizerColumnWidth[property];
    }

    private resizeColumns(resizer: HTMLElement, sizeDifference: number) {
        if (resizer == null) {
            resizer = document.querySelector<HTMLElement>('.resizer-left') as HTMLElement;
        }

        let column: HTMLElement | undefined = resizer.closest<HTMLElement>('.resizer-column') as HTMLElement;
        const row = resizer.closest<HTMLElement>('.resizer-row') as HTMLElement;

        this.resizerColumnWidth = { // reset width of each column before distributing resized width
            name: row?.querySelector<HTMLElement>('.column-name')?.offsetWidth || 0,
            forceVx: row?.querySelector<HTMLElement>('.column-vx')?.offsetWidth || 0,
            forceVy: row?.querySelector<HTMLElement>('.column-vy')?.offsetWidth || 0,
            forceN: row?.querySelector<HTMLElement>('.column-n')?.offsetWidth || 0,
            momentMx: row?.querySelector<HTMLElement>('.column-mx')?.offsetWidth || 0,
            momentMy: row?.querySelector<HTMLElement>('.column-my')?.offsetWidth || 0,
            momentMz: row?.querySelector<HTMLElement>('.column-mz')?.offsetWidth || 0,
            utilization: row?.querySelector<HTMLElement>('.column-utilization, .column-add')?.offsetWidth || 0,
            handrail: row?.querySelector<HTMLElement>('.column-handrail')?.offsetWidth || 0
        };

        const mapResizerColumnClassToProperty = (col: HTMLElement | undefined) => { // finds property name from column class
            const propertyNames: { [key: string]: string } = {
                'column-name': 'name',
                'column-vx': 'forceVx',
                'column-vy': 'forceVy',
                'column-n': 'forceN',
                'column-mx': 'momentMx',
                'column-my': 'momentMy',
                'column-mz': 'momentMz',
                'column-utilization': 'utilization',
                'column-handrail': 'handrail',
                'column-add': 'utilization'
            };

            for (const key in propertyNames) {
                if (col?.classList.contains(key)) {
                    return propertyNames[key];
                }
            }

            throw new Error('Unknown column class.');
        };

        const allColumns = Array.from(column.parentElement?.children ?? []) as HTMLElement[];
        const resizableColumns = allColumns.filter(col => col.classList.contains('resizer-column'));
        const columnIndex = resizableColumns.indexOf(column);

        const nextColumns = resizableColumns.slice(columnIndex + 1);
        const prevColumns = resizableColumns.slice(0, columnIndex).reverse(); // reverse previous columns, as we want to prioritize them from the closest one to the first one

        if (sizeDifference > 0) { // mouse move to right means that next columns should narrow
            if (resizer.classList.contains('resizer-left')) {
                nextColumns.unshift(column); // make column that has resizer next column and previous column as the one being resized
                column = prevColumns.shift();
            }

            const columnProp = mapResizerColumnClassToProperty(column);
            const columnPropKey = columnProp as keyof LoadCombinationColumnProps<number>;

            for (const nextColumn of nextColumns) {
                if (sizeDifference <= 0) {
                    break;
                }

                const nextColumnProp = mapResizerColumnClassToProperty(nextColumn);
                const nextColumnPropKey = nextColumnProp as keyof LoadCombinationColumnProps<number>;
                const minColumnWidth = this.getMinResizerColumnWidth(nextColumnPropKey);

                const availableSize = Math.max(this.resizerColumnWidth[nextColumnPropKey] - minColumnWidth, 0);
                const applicableSize = Math.min(availableSize, sizeDifference);

                this.resizerColumnWidth[columnPropKey] += applicableSize;
                this.resizerColumnWidth[nextColumnPropKey] -= applicableSize;
                sizeDifference -= applicableSize;
            }
        }
        else { // mouse move to left means that previous columns should narrow
            if (resizer.classList.contains('resizer-right')) {
                prevColumns.unshift(column); // make column that has resizer previous column and next column as the one being resized
                column = nextColumns.shift();
            }

            if (column !== undefined) {
                const columnProp = mapResizerColumnClassToProperty(column);
                const columnPropKey = columnProp as keyof LoadCombinationColumnProps<number>;

                sizeDifference = Math.abs(sizeDifference);
                for (const prevColumn of prevColumns) {
                    if (sizeDifference <= 0) {
                        break;
                    }

                    const prevColumnProp = mapResizerColumnClassToProperty(prevColumn);
                    const prevColumnPropKey = prevColumnProp as keyof LoadCombinationColumnProps<number>;
                    const minColumnWidth = this.getMinResizerColumnWidth(prevColumnPropKey);

                    const availableSize = Math.max(this.resizerColumnWidth[prevColumnPropKey] - minColumnWidth, 0);
                    const applicableSize = Math.min(availableSize, sizeDifference);

                    this.resizerColumnWidth[columnPropKey] += applicableSize;
                    this.resizerColumnWidth[prevColumnPropKey] -= applicableSize;
                    sizeDifference -= applicableSize;
                }
            }
        }
    }

    private getScopeCheckMessages(scopeCheckMessages: TranslationFormat[]) {
        const translatedMessages: string[] = [];

        scopeCheckMessages.forEach((message) => {
            const translation = this.translationFormatService.getLocalizedStringWithTranslationFormat(message);

            if (translation != null)
                translatedMessages.push(translation);
        });

        return translatedMessages;
    }

    private resetNewLoad() {
        const isDynamic = this.showDynamic ? 0 : undefined;
        // type definition for LoadCombination is wrong
        // remove model generator and type/fix declarations by hand
        this.newLoad = {
            Id: this.guid.new(),
            Name: '',
            Description: undefined as unknown as string,
            ActiveLoadType: this.isDynamicFatigueExpertMode ? LoadType.Fatigue : LoadType.Static,
            ForceX: 0,
            ForceY: 0,
            ForceZ: 0,
            MomentX: 0,
            MomentY: 0,
            MomentZ: 0,
            DynamicForceX: isDynamic as unknown as number,
            DynamicForceY: isDynamic as unknown as number,
            DynamicForceZ: isDynamic as unknown as number,
            DynamicMomentX: isDynamic as unknown as number,
            DynamicMomentY: isDynamic as unknown as number,
            DynamicMomentZ: isDynamic as unknown as number,
            Tension: undefined,
            Shear: undefined,
            Combination: undefined,
            ResultMessages: [],
            IsWizardGenerated: false,
            HasSustainedLoads: this.loads?.some(l => l.HasSustainedLoads),
            LoadCharacteristic: undefined as unknown as LoadCharacteristic
        };
    }

    private loadValueRanges() {
        this.forceXRange = this.getLoadsRange(PropertyMetaData.Loads_ForceX.id);
        this.forceYRange = this.getLoadsRange(PropertyMetaData.Loads_ForceY.id);
        this.forceZRange = this.getLoadsRange(PropertyMetaData.Loads_ForceZ.id);

        this.dynamicForceXRange = this.getLoadsRange(PropertyMetaData.Loads_ForceXVariable.id);
        this.dynamicForceYRange = this.getLoadsRange(PropertyMetaData.Loads_ForceYVariable.id);
        this.dynamicForceZRange = this.getLoadsRange(PropertyMetaData.Loads_ForceZVariable.id);

        this.momentXRange = this.getLoadsRange(PropertyMetaData.Loads_MomentX.id);
        this.momentYRange = this.getLoadsRange(PropertyMetaData.Loads_MomentY.id);
        this.momentZRange = this.getLoadsRange(PropertyMetaData.Loads_MomentZ.id);

        this.dynamicMomentXRange = this.getLoadsRange(PropertyMetaData.Loads_MomentXVariable.id);
        this.dynamicMomentYRange = this.getLoadsRange(PropertyMetaData.Loads_MomentYVariable.id);
        this.dynamicMomentZRange = this.getLoadsRange(PropertyMetaData.Loads_MomentZVariable.id);
    }

    private getLoadsRange(uiProperty: UIProperty): IValueRange {
        const propertyValue = this.design.properties.get(uiProperty);

        return {
            max: propertyValue?.max,
            min: propertyValue?.min
        };
    }

    private areAllLoadsForLedgerAngleAvailablePvt(regionHub: number, isCBFEMCalculation: boolean, isLedgerAngle: boolean) {
        return !isLedgerAngle ? true :
            (
                regionHub != RegionHub.W1_HNA
            )
            && !isCBFEMCalculation;
    }
}
