import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { IResponsiveIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { IModalOpened } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { ISelectProfileComponentInput } from '../../../shared/components/select-profile';
import { ProfileFamily } from '../../../shared/entities/code-lists/profile-family';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { MainMenuModal } from '../../../shared/entities/main-menu';
import { ISelectProfileProps, SelectProfile } from '../../components/main-menu/select-profile/SelectProfile';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { getSpriteAsIconStyle } from '../../sprites';
import { updateMainMenuControl, updateUiProperty } from './menu-helper';

export function initializeSelectProfile(
    calculationService: CalculationServicePE,
    design: DesignPe,
    controlProps: ISelectProfileProps,
    localizationService: LocalizationService,
    uiPropertyId: number,
    modals: { [modal: number]: (input?: object) => IModalOpened },
    setState: (fn?: (prevMenu: IMenu) => IMenu) => IMenu,
    displayKey?: string) {
    controlProps.type = SelectProfile;
    const propertyValue = design.model[uiPropertyId] as number;
    controlProps.selectedValue = propertyValue;

    const getSelectProfileButtonData = (selectedValue: number) => {
        const items = design.designData.designCodeLists[DesignCodeList.ProfileFamily] as ProfileFamily[];

        const selectedItem = items.find((item) => item.id == selectedValue);
        let selectedItemStyle: IResponsiveIconStyle | undefined = undefined;
        if (selectedItem?.image != null && selectedItem.image != '') {
            selectedItemStyle = {
                elementStyle: getSpriteAsIconStyle(selectedItem.image)
            };
        }

        return {
            buttonText: selectedItem?.getTranslatedNameText({ localizationService }),
            buttonImage: selectedItem?.image,
            buttonImageStyle: selectedItemStyle
        };
    };

    const buttonData = getSelectProfileButtonData(propertyValue);
    controlProps.buttonText = buttonData.buttonText;
    controlProps.buttonImage = buttonData.buttonImage;
    controlProps.buttonImageStyle = buttonData.buttonImageStyle;

    controlProps.clicked = (selectedValue) => {
        const modalOpenFn = modals[MainMenuModal.selectProfile];
        const modalInput: ISelectProfileComponentInput = {
            popupTitle: displayKey != null && displayKey != '' ? localizationService.getString(displayKey) : undefined,
            allowedValues: design.properties.get(uiPropertyId).allowedValues,
            selectedValue,
            onSelect: async (value: number) => {
                updateUiProperty(calculationService, design, uiPropertyId, value);

                // change menu
                setState(menu => updateMainMenuControl<ISelectProfileProps>(menu, controlProps.controlId, { selectedValue: value } as any));
            }
        };
        modalOpenFn(modalInput);
    };

    const onStateChanged = (state: IDesignState) => {
        const buttonData = getSelectProfileButtonData(state.model[uiPropertyId] as number);
        controlProps.buttonText = buttonData.buttonText;
        controlProps.buttonImage = buttonData.buttonImage;
        controlProps.buttonImageStyle = buttonData.buttonImageStyle;
        return {
            selectedValue: state.model[uiPropertyId],
            buttonText: buttonData.buttonText,
            buttonImage: buttonData.buttonImage,
            buttonImageStyle: buttonData.buttonImageStyle
        } as ISelectProfileProps;
    };

    return {
        controlProps,
        onStateChanged
    };
}
