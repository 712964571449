import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadsLegendType } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import { LocalizationService } from '../../services/localization.service';
import { includeSprites } from '../../sprites';

@Component({
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LegendComponent implements OnInit {
    @Input()
    public loadsLegendType?: LoadsLegendType;

    @Input()
    public hasLoadsInWorstPosition = false;


    constructor(
        private localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>
    ) { }


    public get hasLoads() {
        return this.loadsLegendType != null
            && this.loadsLegendType != LoadsLegendType.None;
    }

    public get hasCharacteristicAndSustained() {
        return this.loadsLegendType == LoadsLegendType.CharacteristicAndSustained;
    }

    public get staticLoads() {
        if (this.hasLoads) {
            const key = this.getLoadKey('Agito.Hilti.Profis3.Legend.StaticLoads');
            return key != null ? this.localizationService.getString(key) : null;
        }

        return null;
    }

    public get dynamicLoads() {
        if (this.hasLoads) {
            const key = this.getLoadKey('Agito.Hilti.Profis3.Legend.DynamicLoads');
            return key != null ? this.localizationService.getString(key) : null;
        }

        return null;
    }

    public get sustainedTranslation() {
        return this.localizationService.getString('Agito.Hilti.Profis3.Legend.DynamicLoads.Sustained');
    }

    public get loadsInWorstPosition() {
        if (this.hasLoadsInWorstPosition) {
            return this.localizationService.getString('Agito.Hilti.Profis3.Legend.LoadsInWorstPosition');
        }

        return null;
    }

    public ngOnInit() {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-load-icon',
            'sprite-load-icon-blue',
            'sprite-load-icon-orange',
            'sprite-load-icon-load-in-worst-position'
        );
    }

    private getLoadKey(key: string) {
        switch (this.loadsLegendType) {
            case LoadsLegendType.CharacteristicAndSustained:
            case LoadsLegendType.Characteristic:
                key += '.Characteristic';
                break;

            case LoadsLegendType.Fatigue:
                key += '.Fatigue';
                break;

            case LoadsLegendType.Sustained:
                key += '.Sustained';
                break;

            case LoadsLegendType.None:
                return null;

            default:
                throw new Error('Unknown LoadsLegendType.');
        }

        return key;
    }
}
