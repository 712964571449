<div *ngIf="isLoaded">
    <div class="section no-margin">
        <div class="section-row" *ngIf="!isFromCheckBotDisplay">
            <span class="local-steel-material-disclaimer all"
                l10n="Agito.Hilti.Profis3.AddEditDesign.LocalSteelMaterialDisclaimer"></span>
        </div>
    </div>
    <div class="section data" [class.no-margin]="collapseRegion.MethodAndApprovals">
        <p class="title-strike title" [class.no-margin]="collapseRegion.MethodAndApprovals">
            <span l10n="Agito.Hilti.Profis3.AnchorCalculation"></span>

            <pe-collapse class="section-collapse" [container]="'section-methods-approvals'"
                [collapsed]="collapseRegion.MethodAndApprovals"
                (collapsedChange)="collapseRegion.MethodAndApprovals = $any($event).detail">
            </pe-collapse>
        </p>

        <div class="section-row section-methods-approvals" [ngClass]="{'row' : isFromCheckBotDisplay}"
            [hidden]="collapseRegion.MethodAndApprovals">
            <div class="section-column separator design-standard">
                <!-- Design Standard -->
                <div class="content">
                    <div class="control-column length-large-dropdown">
                        <pe-dropdown [id]="projectDesignStandardDropdown.id" [title]="projectDesignStandardDropdown.title"
                            [notSelectedText]="projectDesignStandardDropdown.notSelectedText"
                            [items]="projectDesignStandardDropdown.items"
                            [selectedValue]="projectDesignStandardDropdown.selectedValue"
                            (selectedValueChange)="onDesignStandardDropdownSelectedValueChange($any($event).detail)"
                            [validators]="projectDesignStandardDropdown.validators"
                            [disabled]="submitted || addEditType == AddEditType.edit || designStandard?.disabled || isDesignStandardDisabled(designStandard?.id)">
                        </pe-dropdown>
                    </div>

                    <div class="design-standard-note" *ngIf="selectedDesignStandardDescription != null"
                        [l10n]="selectedDesignStandardDescription"></div>
                </div>
            </div>

            <div class="section-column separator design-method-group">
                <!-- Design Method -->
                <div class="content">
                    <div class="control-column length-large-dropdown">
                        <pe-dropdown [id]="projectDesignMethodDropdown.id" [title]="projectDesignMethodDropdown.title"
                            [notSelectedText]="projectDesignMethodDropdown.notSelectedText"
                            [items]="projectDesignMethodDropdown.items"
                            [selectedValue]="projectDesignMethodDropdown.selectedValue"
                            (selectedValueChange)="onDesignMethodDropdownSelectedValueChange($any($event).detail)"
                            [validators]="[requiredValidator]" [disabled]="submitted || isSmartAnchorToggleON">
                        </pe-dropdown>
                    </div>

                    <div class="design-method-note" *ngIf="selectedDesignMethodDescription"
                        [l10n]="selectedDesignMethodDescription"></div>

                    <div class="data-item">
                        <pe-checkbox-button *ngIf="isEtagEnOnlyCheckboxAvailable" [items]="etagEnOnlyCheckbox.items"
                            [selectedValues]="etagEnOnlyCheckbox.selectedValues"
                            [disabled]="submitted || isSmartAnchorToggleON"
                            (selectedValuesChange)="etagEnOnlyCheckbox.selectedValues = $any($event).detail; onEtagEnGuidelineOnlyChange()">
                        </pe-checkbox-button>
                    </div>
                </div>
            </div>

            <div class="section-column separator technical-data">
                <div class="content">
                    <label class="control-label sub-title" l10n="Agito.Hilti.Profis3.AddEditDesign.TechnicalData"></label>

                    <div class="data-item">
                        <pe-checkbox-button *ngIf="isHiltiTechnicalDataRelevant" [items]="etaDataCheckbox.items"
                            [selectedValues]="etaDataCheckbox.selectedValues"
                            (selectedValuesChange)="etaDataCheckbox.selectedValues = $any($event).detail"
                            [disabled]="submitted || noHiltiTechnicalDataBrick">
                        </pe-checkbox-button>
                    </div>

                    <div class="data-item">
                        <pe-checkbox-button *ngIf="isHiltiTechnicalDataRelevant" [items]="ostInputsDataCheckbox.items"
                            [selectedValues]="ostInputsDataCheckbox.selectedValues"
                            (selectedValuesChange)="ostInputsDataCheckbox.selectedValues = $any($event).detail"
                            [disabled]="submitted">
                        </pe-checkbox-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section units" [class.no-margin]="collapseRegion.UnitsAndParams">
        <p class="title-strike title" [class.no-margin]="collapseRegion.UnitsAndParams">
            <span l10n="Agito.Hilti.Profis3.AddEditDesign.Units"></span>

            <pe-collapse class="section-collapse" [id]="'section-units-params'" [container]="'section-units-params'"
                [collapsed]="collapseRegion.UnitsAndParams"
                (collapsedChange)="collapseRegion.UnitsAndParams = $any($event).detail">
            </pe-collapse>
        </p>

        <div class="control-row section-units-params" [ngClass]="{'row' : isFromCheckBotDisplay}">
            <div class="control-column length-large-dropdown" *ngIf="isLengthLargeDropdownAvailable">
                <pe-dropdown [id]="lengthLargeDropdown.id" [title]="lengthLargeDropdown.title"
                    [items]="lengthLargeDropdown.items"
                    [selectedValue]="lengthLargeDropdown.selectedValue"
                    (selectedValueChange)="lengthLargeDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column length-dropdown">
                <pe-dropdown [id]="lengthDropdown.id" [title]="lengthDropdown.title" [items]="lengthDropdown.items"
                    [selectedValue]="lengthDropdown.selectedValue"
                    (selectedValueChange)="lengthDropdown.selectedValue = $any($event).detail; onLengthDropdownChange()"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column area-dropdown">
                <pe-dropdown [id]="areaDropdown.id" [title]="areaDropdown.title" [items]="areaDropdown.items"
                    [selectedValue]="areaDropdown.selectedValue"
                    (selectedValueChange)="areaDropdown.selectedValue = $any($event).detail;"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column stress-dropdown">
                <pe-dropdown [id]="stressDropdown.id" [title]="stressDropdown.title" [items]="stressDropdown.items"
                    [selectedValue]="stressDropdown.selectedValue"
                    (selectedValueChange)="stressDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column stress-small-dropdown" *ngIf="isStressSmallDropdownAvailable">
                <pe-dropdown [id]="stressSmallDropdown.id" [title]="stressSmallDropdown.title"
                    [items]="stressSmallDropdown.items"
                    [selectedValue]="stressSmallDropdown.selectedValue"
                    (selectedValueChange)="stressSmallDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column force-dropdown">
                <pe-dropdown [id]="forceDropdown.id" [title]="forceDropdown.title" [items]="forceDropdown.items"
                    [selectedValue]="forceDropdown.selectedValue"
                    (selectedValueChange)="forceDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column moment-dropdown">
                <pe-dropdown [id]="momentDropdown.id" [title]="momentDropdown.title" [items]="momentDropdown.items"
                    [selectedValue]="momentDropdown.selectedValue"
                    (selectedValueChange)="momentDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column temperature-dropdown">
                <pe-dropdown [id]="temperatureDropdown.id" [title]="temperatureDropdown.title"
                    [items]="temperatureDropdown.items"
                    [selectedValue]="temperatureDropdown.selectedValue"
                    (selectedValueChange)="temperatureDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column force-per-length-dropdown" *ngIf="isForcePerLengthDropdownAvailable">
                <pe-dropdown [id]="forcePerLengthDropdown.id" [title]="forcePerLengthDropdown.title"
                    [items]="forcePerLengthDropdown.items"
                    [selectedValue]="forcePerLengthDropdown.selectedValue"
                    (selectedValueChange)="forcePerLengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column moment-per-length-dropdown" *ngIf="isMomentPerLengthDropdownAvailable">
                <pe-dropdown [id]="momentPerLengthDropdown.id" [title]="momentPerLengthDropdown.title"
                    [items]="momentPerLengthDropdown.items"
                    [selectedValue]="momentPerLengthDropdown.selectedValue"
                    (selectedValueChange)="momentPerLengthDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column density-dropdown" *ngIf="isDensityDropdownAvailable">
                <pe-dropdown [id]="densityDropdown.id" [title]="densityDropdown.title" [items]="densityDropdown.items"
                    [selectedValue]="densityDropdown.selectedValue"
                    (selectedValueChange)="densityDropdown.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column anchor-plate-factor" *ngIf="isAnchorPlateFactorAvailable">
                <pe-numeric-text-box
                    [id]="anchorPlateFactorTextBox.id"
                    [title]="anchorPlateFactorTextBox.title"
                    [tooltip]="anchorPlateFactorTextBox.tooltip"
                    [placeholder]="anchorPlateFactorTextBox.placeholder"
                    [defaultStepperValue]="anchorPlateFactorTextBox.defaultStepperValue"
                    [value]="anchorPlateFactorTextBox.value"
                    (valueChange)="anchorPlateFactorTextBox.value = $any($event).detail"
                    [minValue]="anchorPlateFactorTextBox.minValue"
                    [maxValue]="anchorPlateFactorTextBox.maxValue"
                    [updateOnBlur]="true"
                    [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <div class="control-column permanent-loads-factor" *ngIf="isPermanentOrVariableLoadsDropdownAvailable">
                <pe-numeric-text-box [id]="safetyFactorForPermamentLoadsTextBox.id"
                    [title]="safetyFactorForPermamentLoadsTextBox.title"
                    [placeholder]="safetyFactorForPermamentLoadsTextBox.placeholder"
                    [defaultStepperValue]="safetyFactorForPermamentLoadsTextBox.defaultStepperValue"
                    [value]="safetyFactorForPermamentLoadsTextBox.value"
                    (valueChange)="safetyFactorForPermamentLoadsTextBox.value = $any($event).detail"
                    [minValue]="safetyFactorForPermamentLoadsTextBox.minValue"
                    [maxValue]="safetyFactorForPermamentLoadsTextBox.maxValue" [updateOnBlur]="true" [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <div class="control-column variable-loads-factor" *ngIf="isPermanentOrVariableLoadsDropdownAvailable">
                <pe-numeric-text-box [id]="safetyFactorForVariableLoadsTextBox.id"
                    [title]="safetyFactorForVariableLoadsTextBox.title"
                    [placeholder]="safetyFactorForVariableLoadsTextBox.placeholder"
                    [defaultStepperValue]="safetyFactorForVariableLoadsTextBox.defaultStepperValue"
                    [value]="safetyFactorForVariableLoadsTextBox.value"
                    (valueChange)="safetyFactorForVariableLoadsTextBox.value = $any($event).detail"
                    [minValue]="safetyFactorForVariableLoadsTextBox.minValue"
                    [maxValue]="safetyFactorForVariableLoadsTextBox.maxValue" [updateOnBlur]="true" [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <div class="control-column edge-distance-dropdown" *ngIf="isEdgeDistanceAvailable">
                <pe-dropdown [id]="edgeDistanceDisplay.id" [title]="edgeDistanceDisplay.title"
                    [items]="edgeDistanceDisplay.items"
                    [selectedValue]="edgeDistanceDisplay.selectedValue"
                    (selectedValueChange)="edgeDistanceDisplay.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]" [disabled]="submitted">
                </pe-dropdown>
            </div>

            <div class="control-column minimum-anchor-to-profile-distance"
                *ngIf="isMinimumAnchorToProfileDistanceAvailable">
                <pe-numeric-text-box [id]="minimumAnchorToProfileDistanceTextBox.id"
                    [title]="minimumAnchorToProfileDistanceTextBox.title"
                    [placeholder]="minimumAnchorToProfileDistanceTextBox.placeholder"
                    [defaultStepperValue]="minimumAnchorToProfileDistanceTextBox.defaultStepperValue"
                    [value]="minimumAnchorToProfileDistanceTextBox.value"
                    (valueChange)="minimumAnchorToProfileDistanceTextBox.value = $any($event).detail"
                    [minValue]="minimumAnchorToProfileDistanceTextBox.minValue"
                    [maxValue]="minimumAnchorToProfileDistanceTextBox.maxValue" [unit]="defaultUnitLength"
                    [updateOnBlur]="true" [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <div class="control-column minimum-concrete-cover" *ngIf="isMinimumConcreteCoverAvailable">
                <pe-numeric-text-box [id]="minimumConcreteCoverTextBox.id" [title]="minimumConcreteCoverTextBox.title"
                    [placeholder]="minimumConcreteCoverTextBox.placeholder"
                    [defaultStepperValue]="minimumConcreteCoverTextBox.defaultStepperValue"
                    [value]="minimumConcreteCoverTextBox.value"
                    (valueChange)="minimumConcreteCoverTextBox.value = $any($event).detail"
                    [minValue]="minimumConcreteCoverTextBox.minValue"
                    [maxValue]="minimumConcreteCoverTextBox.maxValue" [unit]="defaultUnitLength" [updateOnBlur]="true"
                    [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <div class="control-column concrete-safety-factor-gamma-c-dropdown"
                *ngIf="isConcreteSafetyFactorGammaCDropdownVisible">
                <pe-dropdown [id]="concreteSafetyFactorGammaC.id" [title]="concreteSafetyFactorGammaC.title"
                    [tooltip]="concreteSafetyFactorGammaC.tooltip" [items]="concreteSafetyFactorGammaC.items"
                    [selectedValue]="concreteSafetyFactorGammaC.selectedValue"
                    (selectedValueChange)="concreteSafetyFactorGammaC.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]"
                    [disabled]="isConcreteSafetyFactorGammaCDropdownDisabled">
                </pe-dropdown>
            </div>

            <div class="control-column handrail-displacement-limit" *ngIf="isDisplacementLimitAvailable">
                <pe-numeric-text-box [id]="handrailDisplacementLimitTextBox.id"
                    [title]="handrailDisplacementLimitTextBox.title"
                    [infoPopup]="isDeflectionLimitsVisible ? handrailDisplacementLimitTextBox.infoPopup : undefined"
                    [infoPopupTooltip]="isDeflectionLimitsVisible ? handrailDisplacementLimitTextBox.infoPopupTooltip : undefined"
                    [prefix]="handrailDisplacementLimitTextBox.prefix" [placeholder]="displacementPlaceHolder"
                    [defaultStepperValue]="displacementPlaceHolder"
                    [value]="handrailDisplacementLimitTextBox.value"
                    (valueChange)="handrailDisplacementLimitTextBox.value = $any($event).detail"
                    [unit]="displacementLimitUnit" [minValue]="getDisplacementLimitMin()"
                    [maxValue]="getDisplacementLimitMax()" [disabled]="submitted">
                </pe-numeric-text-box>
            </div>

            <div class="control-column handrail-displacement-limit" *ngIf="isDisplacementLimitBridgesAvailable">
                <pe-numeric-text-box [id]="handrailDisplacementLimitBridgesTextBox.id"
                    [title]="handrailDisplacementLimitBridgesTextBox.title"
                    [prefix]="handrailDisplacementLimitBridgesTextBox.prefix"
                    [placeholder]="handrailDisplacementLimitBridgesTextBox.placeholder"
                    [defaultStepperValue]="handrailDisplacementLimitBridgesTextBox.defaultStepperValue"
                    [value]="handrailDisplacementLimitBridgesTextBox.value"
                    (valueChange)="handrailDisplacementLimitBridgesTextBox.value = $any($event).detail"
                    [minValue]="getDisplacementLimitMin(true)"
                    [maxValue]="getDisplacementLimitMax(true)" [disabled]="submitted">
                </pe-numeric-text-box>
            </div>
        </div>
    </div>

    <div class="section steel-calculation" [hidden]="!steelCalculation" [class.no-margin]="collapseRegion.SteelCalculation">
        <p class="title-strike title" [class.no-margin]="collapseRegion.SteelCalculation">
            <span l10n="Agito.Hilti.Profis3.AddEditDesign.SteelCalculation"></span>

            <pe-collapse class="section-collapse" [container]="'section-steel-calculation'"
                [collapsed]="collapseRegion.SteelCalculation"
                (collapsedChange)="collapseRegion.SteelCalculation = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-steel-calculation">
            <pe-steel-calculation-inputs [data]="steelCalculationInputsData"
                [selectedSteelGuideline]="steelCalculationInputsData.steelGuideline"
                [selectedRegionId]="steelCalculationInputsData.regionId"
                [selectedDesignType]="steelCalculationInputsData.designType"
                [selectedDesignStandardId]="steelCalculationInputsData.designStandardId" [disabled]="submitted">
            </pe-steel-calculation-inputs>
        </div>
    </div>

    <div class="section concrete-method" [hidden]="!concreteCalculationMethodAvailable"
        [class.no-margin]="collapseRegion.ConcreteMethod">
        <p class="title-strike title" [class.no-margin]="collapseRegion.ConcreteMethod">
            <span l10n="Agito.Hilti.Profis3.AddEditDesign.ConcreteMethod"></span>

            <pe-collapse class="section-collapse" [container]="'section-concrete-method'"
                [collapsed]="collapseRegion.ConcreteMethod"
                (collapsedChange)="collapseRegion.ConcreteMethod = $any($event).detail">
            </pe-collapse>
        </p>
        <div class="content-group section-concrete-method">
            <div class="concrete-method-dropdown">
                <pe-dropdown [id]="concreteMethod.id" [items]="concreteMethod.items"
                    [selectedValue]="concreteMethod.selectedValue"
                    (selectedValueChange)="concreteMethod.selectedValue = $any($event).detail"
                    [validators]="[requiredValidator]"
                    [disabled]="concreteMethod.disabled">
                </pe-dropdown>
            </div>
        </div>
    </div>

    <div class="section advanced-calculation" *ngIf="areAdvancedCalculationInputsVisible"
        [class.no-margin]="collapseRegion.AdvancedSettings">
        <p class="title-strike title" [class.no-margin]="collapseRegion.AdvancedSettings">
            <span l10n="Agito.Hilti.Profis3.ApplicationSettings.Menu.AdvancedBaseplateCalculation"></span>

            <pe-collapse class="section-collapse" [container]="'section-advanced-calculation'"
                [collapsed]="collapseRegion.AdvancedSettings"
                (collapsedChange)="collapseRegion.AdvancedSettings = $any($event).detail">
            </pe-collapse>
        </p>

        <pe-advanced-calculation-inputs class="section-advanced-calculation" [design]="displayDesign"
            [advancedCalculationData]="advancedBaseplateData" [advancedCalculationInputs]="advancedBaseplateInputsData"
            [selectedSteelGuideline]="advancedBaseplateInputsData.selectedSteelGuideline" [submitted]="submitted">
        </pe-advanced-calculation-inputs>
    </div>
</div>
