import { Injectable } from '@angular/core';
import {
  ImportServiceInjected
} from '@profis-engineering/pe-ui-common/services/import.common';

@Injectable({
  providedIn: 'root'
})
export class ImportService extends ImportServiceInjected {
}
