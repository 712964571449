import orderBy from 'lodash-es/orderBy';
import upperFirst from 'lodash-es/upperFirst';

import {
    ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewEncapsulation
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    NumericTextBoxProps
} from '@profis-engineering/pe-ui-common/components/numeric-text-box/numeric-text-box.common';
import {
    TextBoxProps
} from '@profis-engineering/pe-ui-common/components/text-box/text-box.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { SteelType as SteelTypeEntity } from '../../../shared/entities/code-lists/steel-type';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import { ISelectMaterialComponentInput } from '../../../shared/components/select-material';
import { ProjectCodeList } from '../../../shared/enums/project-code-list';
import {
    Feature
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import {
    CustomSteelMaterial
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignStandard, DesignType, ProfileShapeType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import {
    IPropertyMetaData, PropertyMetaData, UIPropertyName
} from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { CodeListService } from '../../services/code-list.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

enum PendingType {
    select,
    applyToAll
}

enum SteelGuidelineType {
    GuidelineCarbonSteel,
    GuidelineStainlessSteel,
    Guideline,
    Local,
    Custom
}

interface IDisplayItem {
    id: number;
    name: string;
    isStainlessSteel: boolean;
}

interface UIPropertyCombo {
    propertyId: number;
    isCustomPropertyId?: number;
    customPropertyId?: number;
    customPropertiesPrefix?: string;
}

@Component({
    templateUrl: './select-material.component.html',
    styleUrls: ['./select-material.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class SelectMaterialComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<ISelectMaterialComponentInput>;

    public pending?: PendingType;
    public items!: IDisplayItem[];
    public userSelectedValue?: number;
    public steelGuidelineTypeDropdown!: DropdownProps<SteelGuidelineType>;

    public pendingTypeEnum = PendingType;
    public requiredValidator = Validators.required;

    public nameTextBox!: TextBoxProps;
    public fyTextBox!: NumericTextBoxProps;
    public fuTextBox!: NumericTextBoxProps;
    public esTextBox!: NumericTextBoxProps;
    public densityTextBox!: NumericTextBoxProps;
    public poissonTextBox!: NumericTextBoxProps;

    private isHNA = false;
    private isSATS = false;
    private isWeldMaterialControl = false;

    private static readonly ultimateStrengthUIPropertyName = 'MaterialUltimateStrength';
    private static readonly yieldStrengthUIPropertyName = 'MaterialYieldStrength';
    private static readonly eModulusUIPropertyName = 'MaterialEModulus';
    private static readonly densityUIPropertyName = 'MaterialDensity';
    private static readonly poissonUIPropertyName = 'MaterialPoisson';

    constructor(
        public localization: LocalizationService,
        private userSettings: UserSettingsService,
        private user: UserService,
        private modal: ModalService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private codeList: CodeListService,
        private unit: UnitService,
        private changeDetector: ChangeDetectorRef,
        private calculationService: CalculationServicePE,
        private numberService: NumberService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get translationKeySuffix() {
        if (this.user.design.designStandard.id == DesignStandard.STO) {
            return '.STO';
        }

        if (
            this.user.design.designStandard.id == DesignStandard.SATS
            || this.user.design.designStandard.id == DesignStandard.NZ
        ) {
            return '.AS';
        }

        return '';
    }

    public get customMaterialSelected() {
        return this.steelGuidelineTypeDropdown.selectedValue == SteelGuidelineType.Custom;
    }

    public get isSteelGuidelineDisclaimerHidden() {
        return this.allowedSteelTypes.every(steelType => !steelType.allowedForSteelGuideline);
    }

    public get isLocalDisclaimerHidden() {
        return this.allowedSteelTypes.filter(steelType => steelType.allowedForRegion && !steelType.allowedForSteelGuideline).length == 0;
    }

    public get sortedItems() {
        return orderBy(this.items, [
            item => !this.isDisabled(item) ? 0 : 1,
            item => this.isFavorite(item.id) ? 0 : 1
        ]);
    }

    public get selectedValue() {
        return this.user.design.model[this.uiPropertyId] as number;
    }
    public set selectedValue(value) {
        this.user.design.model[this.uiPropertyId] = value;
    }

    public get isStainlessSteelHidden() {
        return this.user.design.isStainlessSteelHidden;
    }

    public get isStainlessSteelDisabled() {
        return this.user.design.isStainlessSteelDisabled;
    }

    public get isAppliedToAll() {
        for (const combo of this.applyToAllProperties) {
            const allowedValues = this.user.design.properties.get(combo.propertyId).allowedValues;
            if (!allowedValues?.some(allowed => allowed == this.selectedValue)) {
                return false;
            }
        }

        return true;
    }

    public get isApplyToAllVisible() {
        return this.applyToAllProperties.length > 1;
    }

    public get localSteelMaterialDisclaimer() {
        const translation = this.localization.getString('Agito.Hilti.Profis3.SelectMaterial.LocalSteelMaterialDisclaimer');
        const steelGuideline = this.codeList.projectCodeLists[ProjectCodeList.SteelGuidelines].find(cl => cl.id == this.user.design.steelGuideline);

        return translation.replace('{steelGuideline}', steelGuideline?.getTranslatedNameText(getCodeListTextDeps(this.localization, this.numberService)) ?? '');
    }

    public get favoriteTooltip() {
        return this.featuresVisibilityInfo.tooltip(Feature.Menu_Favorites) ||
            this.localization.getString('Agito.Hilti.Profis3.Main.Region.AddRemoveFromFavorites');
    }

    public get materialButtonText() {
        let translationKey = 'Agito.Hilti.Profis3.SelectMaterial.ApplyToAll';

        if (this.user.design.designType.id == DesignType.Handrail &&
            !this.user.design.isHandrailCBFEMCalculation &&
            this.user.design.profileFamily?.shape != ProfileShapeType.DoubleBar
        ) {
            translationKey = 'Agito.Hilti.Profis3.SelectMaterial.ApplyToAll.Handrail';
        }
        else if ((this.isHNA || this.isSATS) && this.isWeldMaterialControl) {
            translationKey = 'Agito.Hilti.Profis3.SelectMaterial.ApplyToAll.Weld';
        }

        return this.localization.getString(translationKey);
    }

    public get isApplyDisabled() {
        if (this.customMaterialSelected) {
            return this.nameTextBox.value == '';
        }

        if (this.userSelectedValue == null) {
            return true;
        }

        const selectedItem = this.sortedItems.find(item => item.id == this.userSelectedValue);

        if (selectedItem == null) {
            return true;
        }

        return this.isDisabled(selectedItem);
    }

    private get isCustomSteelAvailable() {
        const propertyId = this.isCustomMaterialPropertyId;
        if (propertyId != null) {
            const prop = this.user.design.properties.get(propertyId);
            return !prop.hidden && !prop.disabled;
        }

        return false;
    }

    private get stainlessSteelSelected() {
        return this.steelGuidelineTypeDropdown.selectedValue == SteelGuidelineType.GuidelineStainlessSteel;
    }

    private get allowedSteelTypes() {
        return this.steelTypes.filter(steelType => this.user.design.properties.get(this.uiPropertyId).allowedValues?.some(allowed => allowed == steelType.id));
    }

    private get steelTypes() {
        return this.user.design.designData.designCodeLists[DesignCodeList.SteelType] as SteelTypeEntity[];
    }

    private get customMaterialPropertyId() {
        return this.allProperties.find(x => x.propertyId == this.uiPropertyId)?.customPropertyId;
    }

    private get isCustomMaterialPropertyId() {
        return this.allProperties.find(x => x.propertyId == this.uiPropertyId)?.isCustomPropertyId;
    }

    private get customMaterialPropertiesPrefix() {
        return this.allProperties.find(x => x.propertyId == this.uiPropertyId)?.customPropertiesPrefix;
    }

    private get allSteelProperties() {
        const combos: UIPropertyCombo[] = [
            {
                propertyId: PropertyMetaData.AnchorPlate_SteelType.id,
                isCustomPropertyId: PropertyMetaData.AnchorPlate_IsPlateMaterialCustom.id,
                customPropertyId: PropertyMetaData.AnchorPlate_CustomPlateMaterial.id,
                customPropertiesPrefix: 'AnchorPlate_'
            },
            {
                propertyId: PropertyMetaData.Profile_SteelType.id,
                isCustomPropertyId: PropertyMetaData.Profile_IsProfileMaterialCustom.id,
                customPropertyId: PropertyMetaData.Profile_CustomProfileMaterial.id,
                customPropertiesPrefix: 'Profile_'
            },
            {
                propertyId: PropertyMetaData.Profile_SteelTypeHandrail.id
            },
            {
                propertyId: PropertyMetaData.Profile_HorizontalPostSteelTypeHandrail.id
            },
            {
                propertyId: PropertyMetaData.Profile_HandrailRailProfileSteelType.id
            },
            {
                propertyId: PropertyMetaData.AnchorPlate_StiffenerMaterial.id,
                isCustomPropertyId: PropertyMetaData.AnchorPlate_IsStiffenerMaterialCustom.id,
                customPropertyId: PropertyMetaData.AnchorPlate_CustomStiffenerMaterial.id,
                customPropertiesPrefix: 'AnchorPlate_Stiffener'
            }
        ];

        return combos;
    }

    private get allWeldProperties() {
        const combos: UIPropertyCombo[] = [
            {
                propertyId: PropertyMetaData.AnchorPlate_WebWeldMaterial.id
            },
            {
                propertyId: PropertyMetaData.AnchorPlate_FlangeWeldMaterial.id
            },
            {
                propertyId: PropertyMetaData.AnchorPlate_StiffenerWeldMaterial.id
            },
            {
                propertyId: PropertyMetaData.AnchorPlate_DirectWeldMaterial.id
            },
            {
                propertyId: PropertyMetaData.Profile_WebWeldMaterial.id
            }
        ];

        return combos;
    }

    private get allProperties() {
        return [...this.allSteelProperties, ...this.allWeldProperties];
    }

    private get applyToAllProperties() {
        let combos: UIPropertyCombo[] = [];

        if (this.isHNA || this.isSATS) {
            if (this.isWeldMaterialControl) {
                combos = [...this.allWeldProperties];
            }
            else {
                combos = [...this.allSteelProperties];
            }
        }
        else {
            combos = [...this.allProperties];
        }

        return combos.filter(x => {
            const prop = this.user.design.properties.get(x.propertyId);
            return !prop.hidden && !prop.disabled;
        });
    }

    private get uiPropertyId() {
        return this.modalInstance.input?.uiPropertyId ?? 0;
    }

    ngOnInit(): void {
        this.modalInstance.setOnClosing(() => {
            return this.pending
                ? false
                : true;
        });

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-favorite-true',
            'sprite-favorite-false'
        );

        this.isWeldMaterialControl = this.allWeldProperties.some(x => x.propertyId == this.uiPropertyId);

        this.isSATS = this.user.design.designStandard.id == DesignStandard.SATS;
        this.isHNA = isHnaBasedDesignStandard(this.user.design.designStandard.id ?? 0);

        this.initCustomSteelControls();

        const dropdownValues = this.getDropdownValues();
        this.steelGuidelineTypeDropdown = {
            id: 'select-material-steel-guideline-type-dropdown',
            items: dropdownValues.map(key => {
                return {
                    value: this.getValueOfSteelGuidelineType(key),
                    text: this.localization.getString(`Agito.Hilti.Profis3.SelectMaterial.SteelGuidelineType.${key}`)
                } as DropdownItem<SteelGuidelineType>;
            }),
            selectedValue: (() => {
                const selectedSteel = this.steelTypes.find(cl => cl.id == this.selectedValue);

                if (this.isCustomSteelAvailable && (this.user.design.model[this.isCustomMaterialPropertyId ?? 0] as boolean)) {
                    return SteelGuidelineType.Custom;
                }

                if (selectedSteel) {
                    if (!this.isStainlessSteelHidden) {
                        if (selectedSteel.isStainlessSteel) {
                            return SteelGuidelineType.GuidelineStainlessSteel;
                        }
                        else {
                            return SteelGuidelineType.GuidelineCarbonSteel;
                        }
                    }

                    if (selectedSteel.allowedForSteelGuideline) {
                        return SteelGuidelineType.Guideline;
                    }
                    if (selectedSteel.allowedForRegion) {
                        return SteelGuidelineType.Local;
                    }
                }
                throw new Error('Unsupported steel guideline type.');
            })()
        };

        this.loadItems();
        this.userSelectedValue = this.getUserSelectedSteel() ?? undefined;

        this.scrollToSelected();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public getUserSelectedSteel() {
        return this.allowedSteelTypes.find(x => x.id == this.selectedValue) != null ? this.selectedValue : null;
    }

    public close() {
        this.modalInstance.close();
    }

    public select(item: IDisplayItem) {
        if (this.isDisabled(item) || this.pending != null) {
            return;
        }

        this.userSelectedValue = item.id;
    }

    public applyToAll() {
        if (this.pending != null) {
            return;
        }

        this.apply(true);
    }

    public apply(applyToAll = false) {
        if (this.pending != null) {
            return;
        }

        let translationKey = 'Agito.Hilti.Profis3.SelectMaterial.StainlessSteelChangedPopup.Message';

        if (this.user.design.designType.id == DesignType.Handrail && !this.user.design.isHandrailCBFEMCalculation) {
            translationKey = 'Agito.Hilti.Profis3.SelectMaterial.StainlessSteelChangedPopup.Message.Handrail';
        }

        if (this.stainlessSteelSelected !== (this.user.design.stainlessSteel || false)) {
            this.modal.openConfirmChange({
                id: 'select-material-stainless-steel-changed-popup',
                title: this.localization.getString('Agito.Hilti.Profis3.SelectMaterial.StainlessSteelChangedPopup.Title'),
                message: this.localization.getString(translationKey),
                confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.SelectMaterial.StainlessSteelChangedPopup.Confirm'),
                cancelButtonText: this.localization.getString('Agito.Hilti.Profis3.SelectMaterial.StainlessSteelChangedPopup.Cancel'),
                onConfirm: (modal) => {
                    this.user.design.stainlessSteel = this.stainlessSteelSelected;

                    this.changeSelectedMaterial(applyToAll);

                    modal.close();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.changeSelectedMaterial(applyToAll);
        }
    }

    public getFavoriteSprite(itemId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.user.design.region.id)) {
            return 'pe-ui-pe-sprite-favorite-false disabled';
        }

        return this.isFavorite(itemId) ? 'pe-ui-pe-sprite-favorite-true' : 'pe-ui-pe-sprite-favorite-false';
    }

    public isFavorite(itemId: number) {
        // eslint-disable-next-line no-prototype-builtins
        return this.userSettings.settings.steelTypeFavorites.value?.hasOwnProperty(itemId.toString());
    }

    public isDisabled(item: IDisplayItem) {
        return this.stainlessSteelSelected ? !item.isStainlessSteel : item.isStainlessSteel;
    }

    public formatType(item: IDisplayItem) {
        return item.isStainlessSteel
            ? this.localization.getString('Agito.Hilti.Profis3.SelectMaterial.StainlessSteel')
            : this.localization.getString('Agito.Hilti.Profis3.SelectMaterial.CarbonSteel');
    }

    public favoriteToggle(itemId: number) {
        if (this.featuresVisibilityInfo.isDisabled(Feature.Menu_Favorites, this.user.design.region.id)) {
            return;
        }

        if (this.isFavorite(itemId)) {
            this.userSettings.settings.steelTypeFavorites.value = Object.fromEntries(Object.entries(
                this.userSettings.settings.steelTypeFavorites.value ?? '')
                .filter(([favoriteId]) => favoriteId != itemId.toString())
            );
        }
        else {
            Object.assign(this.userSettings.settings.steelTypeFavorites.value ?? {}, { [itemId]: null });
        }

        this.userSettings.save();
    }

    public customMaterialNameChanged(value: string) {
        const oldValue = this.nameTextBox.value;
        this.nameTextBox.value = value;

        if (value == null || value == '') {
            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();
            this.nameTextBox.value = oldValue;
        }
    }

    public steelGuidelineTypeChanged() {
        this.loadItems();

        if (!this.isStainlessSteelHidden) {
            const item = this.sortedItems.find(i => i.id == this.selectedValue);
            if (item == null) {
                return;
            }

            if (item.isStainlessSteel == this.stainlessSteelSelected) {
                this.userSelectedValue = this.selectedValue;
            }
            else {
                this.userSelectedValue = this.sortedItems.length > 0 ? this.sortedItems[0].id : undefined;
            }

            this.scrollToSelected();
        }

        if (!this.isSteelGuidelineDisclaimerHidden) {
            if (this.sortedItems.find(item => item.id == this.userSelectedValue) == null) {
                this.userSelectedValue = undefined;
            }
            else {
                this.scrollToSelected();
            }
        }
    }

    private getDropdownValues() {
        // enums have both keys and values as object keys -> get only string keys (value is number)
        let allValues = Object.keys(SteelGuidelineType).filter(k => isNaN(parseInt(k)));

        if (!this.isCustomSteelAvailable) {
            allValues = allValues.filter(x => x != 'Custom');
        }

        if (this.isLocalDisclaimerHidden || !this.isStainlessSteelHidden) {
            allValues = allValues.filter(x => x != 'Local');
        }

        if (this.isSteelGuidelineDisclaimerHidden || !this.isStainlessSteelHidden) {
            allValues = allValues.filter(x => x != 'Guideline');
        }

        if (this.isStainlessSteelHidden) {
            allValues = allValues.filter(x => x != 'GuidelineCarbonSteel' && x != 'GuidelineStainlessSteel');
        }
        else {
            allValues = allValues.filter(x => x != 'GuidelineStainlessSteel' || !this.isStainlessSteelDisabled);
        }

        return allValues;
    }

    private initCustomSteelControls() {
        if (this.isCustomSteelAvailable) {
            const prop = this.user.design.model[this.customMaterialPropertyId ?? 0] as CustomSteelMaterial;

            this.nameTextBox = this.createTextBoxControl('Name', prop.Name);

            const ultimateStrengthUIProperty = this.getPropertyMetaData(SelectMaterialComponent.ultimateStrengthUIPropertyName);
            this.fyTextBox = this.createNumericTextBoxControl('Fy', UnitGroup.Stress, ultimateStrengthUIProperty, 'Fy' + this.translationKeySuffix, prop.Fy);

            const yieldStrengthUIProperty = this.getPropertyMetaData(SelectMaterialComponent.yieldStrengthUIPropertyName);
            this.fuTextBox = this.createNumericTextBoxControl('Fu', UnitGroup.Stress, yieldStrengthUIProperty, 'Fu' + this.translationKeySuffix, prop.Fu);

            const eModulusUIProperty = this.getPropertyMetaData(SelectMaterialComponent.eModulusUIPropertyName);
            this.esTextBox = this.createNumericTextBoxControl('Es', UnitGroup.Stress, eModulusUIProperty, 'Es', prop.Es);

            const densityUIProperty = this.getPropertyMetaData(SelectMaterialComponent.densityUIPropertyName);
            this.densityTextBox = this.createNumericTextBoxControl('Density', UnitGroup.Density, densityUIProperty, 'Density', prop.Density);

            const poissonUIProperty = this.getPropertyMetaData(SelectMaterialComponent.poissonUIPropertyName);
            this.poissonTextBox = this.createNumericTextBoxControl('Poisson', UnitGroup.None, poissonUIProperty, 'Poisson', prop.Poisson);
        }
    }

    private getPropertyMetaData(selectedMaterialComponent: string) {
        const prefix = this.customMaterialPropertiesPrefix;
        const propertyName = `${prefix}${selectedMaterialComponent}` as UIPropertyName;
        return PropertyMetaData[propertyName];
    }

    private createTextBoxControl(valueKey: string, value: string) {
        const retVal: TextBoxProps = {
            id: 'select-material-' + valueKey,
            title: this.localization.getString(`Agito.Hilti.Profis3.SelectMaterial.CustomSteel.${upperFirst(valueKey)}`),
            maxLength: 100,
            value
        };
        return retVal;
    }

    private createNumericTextBoxControl(valueKey: string, unitGroup: UnitGroup, propertyMetaData: IPropertyMetaData, translationKey: string, value: number) {
        const retVal: NumericTextBoxProps = {
            id: 'select-material-' + valueKey,
            title: this.localization.getString(`Agito.Hilti.Profis3.SelectMaterial.CustomSteel.${upperFirst(translationKey)}`),
            unit: this.unit.getDefaultUnit(unitGroup),
            minValue: propertyMetaData.minValue,
            maxValue: propertyMetaData.maxValue,
            value
        };
        return retVal;
    }

    private scrollToSelected() {
        const sortedItems = this.sortedItems;
        if (sortedItems.length == 0) {
            return;
        }

        if (this.allowedSteelTypes.find(x => x.id == this.selectedValue) == null) {
            return;
        }

        const selectedIndex = sortedItems.findIndex(item => this.userSelectedValue == item.id);
        const previousIndex = selectedIndex - 1 > 0 ? selectedIndex - 1 : 0;

        const scrollElement = document.querySelector('.modal');
        if (scrollElement) {
            setTimeout(() => {
                scrollElement.scrollTop = 50 * previousIndex;
            });
        }
    }

    private loadItems() {
        let filteredSteelTypes: SteelTypeEntity[];

        switch (this.steelGuidelineTypeDropdown.selectedValue) {
            case SteelGuidelineType.Guideline:
                filteredSteelTypes = this.allowedSteelTypes.filter(steelType => steelType.allowedForSteelGuideline);
                break;
            case SteelGuidelineType.Local:
                filteredSteelTypes = this.allowedSteelTypes.filter(steelType => steelType.allowedForRegion && !steelType.allowedForSteelGuideline);
                break;
            case SteelGuidelineType.GuidelineStainlessSteel:
                filteredSteelTypes = this.allowedSteelTypes.filter(steelType => steelType.isStainlessSteel);
                break;
            case SteelGuidelineType.GuidelineCarbonSteel:
                filteredSteelTypes = this.allowedSteelTypes.filter(steelType => !steelType.isStainlessSteel);
                break;
            case SteelGuidelineType.Custom:
                filteredSteelTypes = [];
                break;
            default:
                throw new Error('Unsupported steel guideline type.');
        }

        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);

        this.items = filteredSteelTypes.map((steelType): IDisplayItem => ({
            id: steelType.id ?? 0,
            name: steelType.getTranslatedNameText(codeListDeps) ?? '',
            isStainlessSteel: steelType.isStainlessSteel
        }));
    }

    private changeSelectedMaterialProperties() {
        this.pending = PendingType.select;

        if (this.customMaterialSelected) {
            if (this.isCustomMaterialPropertyId != null && this.customMaterialPropertyId != null) {
                this.user.design.model[this.isCustomMaterialPropertyId] = true;
                this.saveCustomSteelMaterialToUIProperty(this.customMaterialPropertyId);
            }
        }
        else {
            if (this.isCustomSteelAvailable && this.isCustomMaterialPropertyId != null) {
                this.user.design.model[this.isCustomMaterialPropertyId] = false;
            }
            this.selectedValue = this.userSelectedValue ?? 0;
        }
    }

    private changeSelectedMaterialPropertiesApplyToAll() {
        this.pending = PendingType.applyToAll;

        for (const combo of this.applyToAllProperties) {
            if (this.customMaterialSelected) {
                if (combo.isCustomPropertyId != null && combo.customPropertyId != null) {
                    this.user.design.model[combo.isCustomPropertyId] = true;
                    this.saveCustomSteelMaterialToUIProperty(combo.customPropertyId);
                }
            }
            else {
                if (this.isCustomSteelAvailable && combo.isCustomPropertyId != null) {
                    this.user.design.model[combo.isCustomPropertyId] = false;
                }
                this.user.design.model[combo.propertyId] = this.userSelectedValue;
            }
        }
    }

    private changeSelectedMaterial(applyToAll: boolean) {
        if (this.pending != null) {
            return;
        }

        if (applyToAll) {
            this.changeSelectedMaterialPropertiesApplyToAll();
        }
        else {
            this.changeSelectedMaterialProperties();
        }

        this.calculationService
            .calculateAsync(this.user.design, undefined, { suppressLoadingFlag: true })
            .finally(() => {
                this.pending = undefined;
            })
            .then(() => {
                if (applyToAll && !this.isAppliedToAll) {
                    let translationKey = 'Agito.Hilti.Profis3.SelectMaterial.ApplyToAllPopup.Message';

                    if (this.user.design.designType.id == DesignType.Handrail && !this.user.design.isHandrailCBFEMCalculation) {
                        translationKey = 'Agito.Hilti.Profis3.SelectMaterial.ApplyToAllPopup.Message.Handrail';
                    }

                    this.modal.openConfirmChange({
                        id: 'select-material-apply-to-all-popup',
                        title: this.localization.getString('Agito.Hilti.Profis3.Warning'),
                        message: this.localization.getString(translationKey),
                        confirmButtonText: this.localization.getString('Agito.Hilti.Profis3.Ok'),
                        onConfirm: (modal) => {
                            modal.close();
                        }
                    });
                }

                this.close();
            });
    }

    private saveCustomSteelMaterialToUIProperty(id: number) {
        const prop = this.user.design.model[id] as CustomSteelMaterial;
        prop.Name = this.nameTextBox.value ?? '';
        prop.Fu = this.fuTextBox.value ?? 0;
        prop.Fy = this.fyTextBox.value ?? 0;
        prop.Es = this.esTextBox.value ?? 0;
        prop.Density = this.densityTextBox.value ?? 0;
        prop.Poisson = this.poissonTextBox.value ?? 0;
    }

    private getValueOfSteelGuidelineType(value: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return Object.entries(SteelGuidelineType).find(([key, _val]) => key === value)?.[1];
    }
}
