import { ICodeListConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { AnchorLayoutEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { AnchorLayoutType } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists.Enums';
import { CodeList } from './code-list';

export interface IAnchorLayoutConstructor extends ICodeListConstructor {
    anchorLayoutType?: AnchorLayoutType;
    shape?: string;
    anchorLayoutPoint?: { X: number; Y: number }[];
}

export class AnchorLayout extends CodeList {
    public anchorLayoutType?: AnchorLayoutType;
    public shape?: string;
    public anchorLayoutPoint?: { X: number; Y: number }[];

    constructor(codeList: IAnchorLayoutConstructor) {
        super(codeList);

        if (codeList != null) {
            this.anchorLayoutType = codeList.anchorLayoutType;
            this.shape = codeList.shape;
            this.anchorLayoutPoint = codeList.anchorLayoutPoint;
        }
    }

    public static fromService(codeList: AnchorLayoutEntity) {
        return new AnchorLayout(CodeList.baseFromService<IAnchorLayoutConstructor>('AnchorLayoutEntity', codeList, {
            anchorLayoutType: codeList.AnchorLayoutType,
            shape: codeList.Shape,
            anchorLayoutPoint: codeList.AnchorLayoutPoint
        } as IAnchorLayoutConstructor));
    }

    public override cloneDeep() {
        const codeList: AnchorLayout = super.cloneDeep() as AnchorLayout;

        codeList.anchorLayoutType = this.anchorLayoutType;
        codeList.shape = this.shape;
        codeList.anchorLayoutPoint = this.anchorLayoutPoint;

        return codeList;
    }
}
