<pe-modal-header [modalInstance]="modalInstance" [title]="translate('Agito.Hilti.Profis3.CustomStiffenerPositionInfo.Title')">
</pe-modal-header>

<pe-modal-body>
    <div class="body-wrapper">
        <img [src]="profilePositionImage" alt="Profile position">
    </div>
</pe-modal-body>

<pe-modal-footer>
    <pe-button
        [look]="'Primary'"
        (buttonClick)="close()"
        [text]="translate('Agito.Hilti.Profis3.Ok')">
    </pe-button>
</pe-modal-footer>