import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignPe, ReportData } from '../../../shared/entities/design-pe';
import {
    UtilizationLoadBearingEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData.BaseplateDesign.UtilizationConcrete';
import {
    DesignStandard
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { CollapsingControls } from '../../entities/collapsing-controls';
import {
    areLoadCombinationsAvailable, getLoadCombinationNumberText
} from '../../helpers/load-combination-helper';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { LocalizationService } from '../../services/localization.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

interface IWorstResult {
    value: number;
    averageStress: number;
    unityCheckStress: number;
    valueError?: boolean;
    textError?: boolean;
}

@Component({
    templateUrl: './concrete-utilizations.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ConcreteUtilizationsComponent implements OnInit {
    @Input()
    public id = '';

    @Input()
    public design?: DesignPe;

    @Input()
    public utilizationLoadBearing?: UtilizationLoadBearingEntity;

    public collapsed = false;
    public concreteOpend = false;
    public unitGroupEnum = {
        Percentage: UnitGroup.Percentage,
        Stress: UnitGroup.Stress
    };

    constructor(
        public unit: UnitService,
        public localizationService: LocalizationService,
        private user: UserService,
        private userSettings: UserSettingsService,
        private featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get showUtilizations() {
        return this.user.design != null
            && (this.hasUtilizations || this.showAddOn);
    }

    public get hasUtilizations() {
        return this.loadBearing != null || this.DG7ConcreteUtilizations;
    }

    public get showAddOn() {
        // only show upgrade text after calculation is done
        if (this.design?.designData?.reportData?.BaseplateDesignData == null) {
            return false;
        }

        return (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) && !this.featuresVisibilityInfo.hasAdvancedFeature;
    }

    public get loadBearing() {
        return this.utilizationLoadBearing;
    }

    public get areLoadCombinationsAvailable() {
        return areLoadCombinationsAvailable(this.design?.designData?.reportData as ReportData);
    }

    public get loadCombination() {
        if (this.design == null) {
            return null;
        }

        return getLoadCombinationNumberText(this.design, this.localizationService, this.design.designData?.reportData?.DecisiveLoadCombinationIndexBaseMaterial ?? 0, true);
    }

    public get loadBearingWorst(): IWorstResult | null {
        if (this.loadBearing == null) {
            if (this.DG7ConcreteUtilizations) {
                return {
                    unityCheckStress: this.design?.designData?.reportData?.ConcreteBearingUtilization ?? 0,
                    averageStress: this.design?.designData?.reportData?.ConcreteBearingAction ?? 0,
                    value: this.design?.designData?.reportData?.ConcreteBearingResistance ?? 0,
                    valueError: (this.design?.designData?.reportData?.ConcreteBearingUtilization ?? 0) > 100
                };
            }

            return null;
        }

        const worst = this.loadBearing;
        const isSatsValue = this.isSATS ? worst.CrtBearingStrength : worst.F_jd;

        return {
            value: this.isHNA ? worst.Fp : isSatsValue,
            averageStress: worst.AverageStress,
            unityCheckStress: worst.UnityCheckStress,
            valueError: !worst.CheckStatus,
            textError: !worst.CheckStatus
        } as IWorstResult;
    }

    public get isHNA() {
        return isHnaBasedDesignStandard(this.design?.designStandard.id ?? 0);
    }

    public get isSATS() {
        return this.design?.designStandard.id == DesignStandard.SATS;
    }

    public get unityCheckStressText() {
        return this.DG7ConcreteUtilizations
            ? 'Agito.Hilti.Profis3.ConcreteUtilizations.DG7.Utilization'
            : 'Agito.Hilti.Profis3.ConcreteUtilizations.Sigma';
    }

    public get utilizationInvalid() {
        return !(this.loadBearing != null && this.loadBearing.CheckStatus || this.DG7ConcreteUtilizations);
    }

    public get DG7ConcreteUtilizations(): boolean {
        return this.design?.designData?.reportData?.ConcreteBearingAction != null &&
            this.design?.designData?.reportData?.ConcreteBearingResistance != null &&
            this.design?.designData?.reportData?.ConcreteBearingUtilization != null;
    }

    public get valueText() {
        if (this.isHNA) {
            return 'F<sub>p</sub>';
        }
        if (this.isSATS) {
            return 'f<sub>b</sub>';
        }

        return 'f<sub>jd</sub>';
    }

    public get valueUnits() {
        return this.DG7ConcreteUtilizations ? UnitGroup.Force : UnitGroup.Stress;
    }

    public get utilizationValue() {
        if (this.loadBearing == null) {
            return this.design?.designData?.reportData?.ConcreteBearingUtilization;
        }

        return this.loadBearing.UnityCheckStress;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-tab-base-material',
            'sprite-arrow-up',
            'sprite-arrow-down'
        );
        this.collapsed = this.userSettings.isSectionCollapsed(CollapsingControls.ConcreteUtilization);
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.ConcreteUtilization, collapsed);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }
}
