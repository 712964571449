<pe-modal-header [closeButtonId]="'asad-optimize-modal-close-button'"
    [title]="translate('Agito.Hilti.Profis3.SmartBasePlate.SolutionDetail.Title')" [modalInstance]="modalInstance">
</pe-modal-header>

<pe-modal-body>
    <div class="solution-detail-body">
        <div class="anchor">
            <div class="image-container">
                <div class="no-image-container">
                    <span
                        class="no-image pe-ui-pe-sprite sprite-responsive pe-ui-pe-sprite-anchor-family-no-photo-available"></span>
                </div>
                <span class="anchor-image pe-ui-pe-sprite sprite-responsive" [ngClass]="anchor.image"></span>
            </div>
            <div class="text-container">
                <div class="text-wrapper">
                    <p class="anchor-name" [innerHtml]="anchor.name"></p>
                    <p class="anchor-tags" *ngIf="(anchor.tag || anchor.isNew)">
                        <span class="tag" *ngIf="anchor.tag" [innerHtml]="anchor.tag"></span>
                        <span class="new" *ngIf="anchor.isNew"
                            [innerHtml]="translate('Agito.Hilti.Profis3.Anchors.New')"></span>
                    </p>
                </div>
            </div>
            <div class="icons-container">
                <div class="icons-wrapper">
                    <div *ngFor="let icon of icons">
                        <span class="icon pe-ui-pe-sprite {{icon.image}}" [ngbTooltip]="getIconTooltip(icon.tooltip)"
                            [ngClass]="{'visible': getProperty(anchor, icon.visibleExpression)}"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="body-content" *ngIf="anchor.needsSolutionTexts && anchor.needsSolutionTexts.length > 0">
            <div class="column">
                <span class="column-title"
                    [innerHtml]="translate('Agito.Hilti.Profis3.AnchorNeedSolutionPopup.TabSelectedAnchor.Needs.Title')"></span>
                <ul class="needs">
                    <li *ngFor="let needsSolutionText of anchor.needsSolutionTexts">
                        <span [innerHtml]="needsSolutionText.need"></span>
                    </li>
                </ul>
            </div>
            <div class="column">
                <span class="column-title"
                    [innerHtml]="translate('Agito.Hilti.Profis3.AnchorNeedSolutionPopup.TabSelectedAnchor.Solutions.Title')"></span>
                <ul class="solutions">
                    <li *ngFor="let needsSolutionText of anchor.needsSolutionTexts">
                        <span [innerHtml]="needsSolutionText.solutionText"></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</pe-modal-body>