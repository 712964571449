import {
    DesignType, EdgeDistanceDisplayType
} from '../generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';

export function defaultEdgeDistance(type: DesignType): EdgeDistanceDisplayType {
    if (type == DesignType.Masonry) {
        return EdgeDistanceDisplayType.FromWCSOrigin;
    }

    return EdgeDistanceDisplayType.FromAnchorCenter;
}
