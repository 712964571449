import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IDesignSectionExportComponentInput, IDesignSectionExportStateChange } from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { ModalInstance, MODAL_DISMISS_REASON_BACKDROP, MODAL_DISMISS_REASON_ESC } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { LocalizationService } from '../../services/localization.service';

@Component({
    templateUrl: './generate-report-follow-up-actions-popup.component.html',
    styleUrls: ['./generate-report-follow-up-actions-popup.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class GenerateReportFollowUpActionsPopupComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance<IDesignSectionExportComponentInput>;

    public loading = false;
    public closing = false;
    public pendingSave = false;

    constructor(
        public localizationService: LocalizationService
    ) { }


    public get items() {
        return this.modalInstance?.input?.exportItems;
    }


    ngOnInit(): void {
        // don't close the modal if an action is pending
        this.modalInstance?.setOnClosing((result) => {
            if (result == MODAL_DISMISS_REASON_BACKDROP || result == MODAL_DISMISS_REASON_ESC) {
                this.handleDoNotShowAgainAndClose();
                return false;
            }

            if (!(!this.loading || this.closing)) {
                return false;
            }

            return true;
        });
    }

    public async onStateChange(state: IDesignSectionExportStateChange) {
        this.loading = state.loading;
        if (state.buttonClickSuccess) {
            this.loading = false;
            await this.close(true);
        }
    }

    public async close(closing = false) {
        this.closing = closing;
        this.handleDoNotShowAgainAndClose();
    }

    private handleDoNotShowAgainAndClose() {
        this.modalInstance.close(true);
    }
}
