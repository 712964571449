import {
    ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation
} from '@angular/core';
import {
    DropdownItem
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import {
    StiffenerCustomProfileSides
} from '../../../shared/entities/code-lists/stiffener-custom-profile-sides';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    ValidationErrorEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation';
import {
    StiffenerCustomProfileSide
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Enums';
import {
    CustomStiffenerEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    ProfileShapeType, StiffenerShape
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

@Component({
    templateUrl: './custom-stiffeners.component.html',
    styleUrls: ['./custom-stiffeners.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CustomStiffenersComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public resize3d!: (resetHighlightedComponents?: boolean) => void;

    public collapsed = false;

    public minStiffenerLength = PropertyMetaData.AnchorPlate_StiffenerWidth.minValue;
    public maxStiffenerLength = PropertyMetaData.AnchorPlate_StiffenerWidth.maxValue;
    public minStiffenerHorizontalEdgeLength = PropertyMetaData.AnchorPlate_StiffenerHorizontalEdgeLength.minValue;
    public maxStiffenerHorizontalEdgeLength = PropertyMetaData.AnchorPlate_StiffenerHorizontalEdgeLength.maxValue;
    public minStiffenerVerticalEdgeLength = PropertyMetaData.AnchorPlate_StiffenerVerticalEdgeLength.minValue;
    public maxStiffenerVerticalEdgeLength = PropertyMetaData.AnchorPlate_StiffenerVerticalEdgeLength.maxValue;
    public unitDegree = Unit.degree;
    public allowedStiffenerLocations!: DropdownItem<number>[];

    constructor(
        public localizationService: LocalizationService,
        private user: UserService,
        private modal: ModalService,
        private calculationService: CalculationServicePE,
        private numberService: NumberService,
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get isLocationVisible() {
        return !this.isPipeProfile;
    }

    public get isPipeProfile() {
        return this.design.profileFamily?.shape == ProfileShapeType.Pipe;
    }

    public get deleteAllDisabled() {
        return this.customStiffeners.length < 1;
    }

    public get design() {
        return this.user.design;
    }

    public get customStiffeners() {
        return this.user.design.customStiffeners ?? [];
    }

    public get showStiffenerEdgeLength() {
        return this.design.stiffenerShape == StiffenerShape.Chamfered;
    }

    public get getAllowedLocationItems() {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        return (this.allowedStiffenersSides).map(profileside => ({
            value: profileside.id,
            text: profileside.getTranslatedNameText(codeListDeps)
        }) as DropdownItem<number>);
    }

    private get allowedStiffenersSides() {
        const profileSides = this.design.designData.designCodeLists[DesignCodeList.StiffenerCustomProfileSides] as StiffenerCustomProfileSides[];
        return profileSides.filter(side => side.profileShapeType == this.design.profileFamily?.shape);
    }


    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines',
            'sprite-lines-expanded',
            'sprite-info-tooltip',
            'sprite-trash'
        );
        this.allowedStiffenerLocations = this.getAllowedLocationItems;
    }

    ngOnChanges(): void {
        this.resize3d();
    }

    public ngOnDestroy() {
        this.resize3d();
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public customStiffenerIndex(customStiffeners: CustomStiffenerEntity) {
        return this.customStiffeners.indexOf(customStiffeners) + 1;
    }

    public customStiffenerId(customStiffeners: CustomStiffenerEntity) {
        return this.customStiffeners.indexOf(customStiffeners);
    }

    public async deleteStiffeners() {
        // Update model and run calculation
        await this.calculationService.calculateAsync(this.design,
            (design) => {
                design.customStiffeners = [];
            });

        this.resize3d();
    }

    public async deleteStiffener(index: number) {
        // Update model and run calculation
        await this.calculationService.calculateAsync(this.design,
            (design) => {
                design.customStiffeners.splice(index, 1);
            });

        this.resize3d();
    }

    public stiffenerLocationTooltip(id: number) {
        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        return this.allowedStiffenersSides.find(side => side.id == this.customStiffeners[id].ProfileSide)?.getTranslatedNameText(codeListDeps);
    }

    public async newCustomStiffener() {
        if (this.design.profileFamily?.shape == ProfileShapeType.Pipe) {
            const newCustomStiffener: CustomStiffenerEntity = {
                Name: '',
                HorizontalEdgeLength: 0,
                VerticalEdgeLength: 0,
                Width: 0,
                Position: 0,
                RadialOffset: 0,
                ProfileSide: this.allowedStiffenersSides[0].id ?? 0
            };

            // Update model and run calculation
            await this.calculationService.calculateAsync(this.design,
                (design) => {
                    design.customStiffeners.push(newCustomStiffener);
                });
        }
        else {
            this.openCustomStiffenerWizard();
        }
    }

    public openLocationInfo() {
        this.modal.openCustomStiffenerLocationInfo();
    }

    public openPositionInfo() {
        this.modal.openCustomStiffenerPositionInfo();
    }

    public collapsedChanged() {
        this.collapsed = !this.collapsed;
        this.resize3d();
    }

    public isStiffenerPositionConrolDisabled(profileSideId: number) {
        const selectedProfileSide = this.allowedStiffenersSides.find(profileSide => profileSide.id == profileSideId);

        return !selectedProfileSide?.allowChangeStiffenerPosition;
    }

    public runCalculation() {
        this.calculationService.calculateAsync(this.design);
    }

    public getMaxStiffenerOffset(profileSide: StiffenerCustomProfileSide) {
        return this.getProfilePlateSize(profileSide);
    }

    public nameChanged(value: string, customStiffener: CustomStiffenerEntity) {
        if (value == null || value == '') {
            const oldName = customStiffener.Name;
            customStiffener.Name = value;

            // The value is changed inside the same cycle so change detection
            // needs to be run again before the new change
            this.changeDetector.detectChanges();

            customStiffener.Name = oldName;
            return;
        }

        this.calculationService.calculateAsync(this.design,
            () => {
                customStiffener.Name = value;
            });
    }

    public positionChanged(value: number, customStiffener: CustomStiffenerEntity) {
        customStiffener.Position = this.convertDefaultToInternalStiffenerOffset(value, customStiffener.ProfileSide);
        this.runCalculation();
    }

    public locationChanged(value: number, customStiffener: CustomStiffenerEntity) {
        const selectedProfileSide = this.allowedStiffenersSides.find(profileSide => profileSide.id == value);

        if (
            selectedProfileSide?.allowMultipleStiffeners
            ||
            (
                !selectedProfileSide?.allowMultipleStiffeners
                && !this.design.customStiffeners.some(stiffener => stiffener.ProfileSide == selectedProfileSide?.id)
            )
        ) {
            this.calculationService.calculateAsync(this.design,
                () => {
                    customStiffener.ProfileSide = value;
                });
        }
        else {
            const stiffenerOnSelectedSide = this.design.customStiffeners
                .filter(x => x != customStiffener)
                .find(stiffener => stiffener.ProfileSide == selectedProfileSide?.id);
            if (stiffenerOnSelectedSide != null) {
                const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
                const validationMessage = this.localizationService.getString('Agito.Hilti.Profis3.CustomStiffener.PositionValidation.Message')
                    .replace('{sideName}', selectedProfileSide?.getTranslatedNameText(codeListDeps) ?? '')
                    .replace('{stifenerName}', stiffenerOnSelectedSide.Name);

                this.design.validationError = ({
                    Id: 0,
                    Message: validationMessage
                } as ValidationErrorEntity);
            }
            else {
                this.design.validationError = undefined;
            }

            this.design.reloadState();
        }
    }

    public convertInternalToDefaultStiffenerOffset(internalValue: number, profileSide: StiffenerCustomProfileSide) {
        internalValue = this.isStiffenerPositionDirectionReversed(profileSide) ? -internalValue : internalValue;
        return internalValue + this.getProfilePlateSize(profileSide) / 2;
    }

    private openCustomStiffenerWizard() {
        this.modal.openCustomStiffenerWizard();
    }

    private getProfilePlateSize(profileSide: StiffenerCustomProfileSide) {
        switch (profileSide) {
            case StiffenerCustomProfileSide.BarFlangeLeft:
            case StiffenerCustomProfileSide.BarFlangeRight:
            case StiffenerCustomProfileSide.SquareBarFlangeTop:
            case StiffenerCustomProfileSide.SquareBarFlangeBottom:
            case StiffenerCustomProfileSide.SquareBarFlangeLeft:
            case StiffenerCustomProfileSide.SquareBarFlangeRight:
            case StiffenerCustomProfileSide.IFlange2Inner:
            case StiffenerCustomProfileSide.IFlange2Outer:
            case StiffenerCustomProfileSide.TFlangeLeft:
            case StiffenerCustomProfileSide.TFlangeRight:
                return this.design.profileWidth;

            case StiffenerCustomProfileSide.IFlange1Inner:
            case StiffenerCustomProfileSide.IFlange1Outer:
                return this.design.profileWidth2 != null && this.design.profileWidth2 != 0
                    ? this.design.profileWidth2
                    : this.design.profileWidth;

            case StiffenerCustomProfileSide.BarFlangeTop:
            case StiffenerCustomProfileSide.BarFlangeBottom:
                return this.design.profileHeight;

            case StiffenerCustomProfileSide.IFlange1Side1:
            case StiffenerCustomProfileSide.IFlange1Side2:
            case StiffenerCustomProfileSide.IFlange2Side1:
            case StiffenerCustomProfileSide.IFlange2Side2:
            case StiffenerCustomProfileSide.TFlangeBottom:
            case StiffenerCustomProfileSide.TFlangeTop:
            case StiffenerCustomProfileSide.TWebLeft:
                return this.design.profileFlangeThickness;

            case StiffenerCustomProfileSide.IWebSide1:
            case StiffenerCustomProfileSide.IWebSide2:
                return this.design.profileHeight - 2 * this.design.profileFlangeThickness;

            case StiffenerCustomProfileSide.TWebBottom:
            case StiffenerCustomProfileSide.TWebTop:
                return this.design.profileHeight - this.design.profileFlangeThickness;

            default:
                throw new Error('Invalid stiffener profile side');
        }
    }

    private convertDefaultToInternalStiffenerOffset(defaultValue: number, profileSide: StiffenerCustomProfileSide) {
        let internalValue = defaultValue - this.getProfilePlateSize(profileSide) / 2;
        internalValue = this.isStiffenerPositionDirectionReversed(profileSide) ? -internalValue : internalValue;
        return internalValue;
    }

    private isStiffenerPositionDirectionReversed(profileSide: StiffenerCustomProfileSide) {
        // NOTE: Profile I, bar and square bar are wrongly done on server side, thats why all plates are reversed!!!
        // T flange has arrow pointing from top to bottom (when image is rotated to match our default orientation), thats why we reverse positioning (only on UI input).
        return profileSide == StiffenerCustomProfileSide.IFlange1Inner ||
            profileSide == StiffenerCustomProfileSide.IFlange1Outer ||
            profileSide == StiffenerCustomProfileSide.IFlange1Side1 ||
            profileSide == StiffenerCustomProfileSide.IFlange1Side2 ||
            profileSide == StiffenerCustomProfileSide.IFlange2Inner ||
            profileSide == StiffenerCustomProfileSide.IFlange2Outer ||
            profileSide == StiffenerCustomProfileSide.IFlange2Side1 ||
            profileSide == StiffenerCustomProfileSide.IFlange2Side2 ||
            profileSide == StiffenerCustomProfileSide.IWebSide1 ||
            profileSide == StiffenerCustomProfileSide.IWebSide2 ||
            profileSide == StiffenerCustomProfileSide.BarFlangeBottom ||
            profileSide == StiffenerCustomProfileSide.BarFlangeTop ||
            profileSide == StiffenerCustomProfileSide.BarFlangeLeft ||
            profileSide == StiffenerCustomProfileSide.BarFlangeRight ||
            profileSide == StiffenerCustomProfileSide.SquareBarFlangeBottom ||
            profileSide == StiffenerCustomProfileSide.SquareBarFlangeTop ||
            profileSide == StiffenerCustomProfileSide.SquareBarFlangeLeft ||
            profileSide == StiffenerCustomProfileSide.SquareBarFlangeRight ||
            profileSide == StiffenerCustomProfileSide.TFlangeLeft ||
            profileSide == StiffenerCustomProfileSide.TFlangeRight ||
            profileSide == StiffenerCustomProfileSide.TWebLeft;
    }

}
