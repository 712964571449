import { GroutEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class Grout extends CodeList {

    public static fromService(codeList: GroutEntity) {
        return new Grout(CodeList.baseFromService('GroutEntity', codeList));
    }

    public override cloneDeep(): Grout {
        return super.cloneDeep() as Grout;
    }
}
