import { AnchorTorqueTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class AnchorTorqueType extends CodeList {

    public static fromService(codeList: AnchorTorqueTypeEntity) {
        return new AnchorTorqueType(CodeList.baseFromService('AnchorTorqueTypeEntity', codeList));
    }

    public override cloneDeep(): AnchorTorqueType {
        return super.cloneDeep() as AnchorTorqueType;
    }
}
