import { ConcreteReinforcementEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from './code-list';

export class ConcreteReinforcement extends CodeList {

    public static fromService(codeList: ConcreteReinforcementEntity) {
        return new ConcreteReinforcement(CodeList.baseFromService('ConcreteReinforcementEntity', codeList));
    }

    public override cloneDeep(): ConcreteReinforcement {
        return super.cloneDeep() as ConcreteReinforcement;
    }
}
