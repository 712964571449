import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';

import barProfileLocationImg from '../../../images/custom-stiffener-location/location-bar-profile.png';
import iProfileLocationImg from '../../../images/custom-stiffener-location/location-i-profile.png';
import tProfileLocationImg from '../../../images/custom-stiffener-location/location-t-profile.png';
import {
    StiffenerCustomProfileSides
} from '../../../shared/entities/code-lists/stiffener-custom-profile-sides';
import { DesignCodeList } from '../../../shared/entities/design-code-list';
import {
    CustomStiffenerEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    ProfileShapeType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';

@Component({
    templateUrl: './custom-stiffener-wizard.component.html',
    styleUrls: ['./custom-stiffener-wizard.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class CustomStiffenerWizardComponent implements OnInit {
    @Input()
    public modalInstance!: ModalInstance;

    public iProfileImage!: string;
    public barProfileImage!: string;
    public tProfileImage!: string;
    public customProfileSideDropdown!: DropdownProps<number>;

    constructor(
        private unit: UnitService,
        private user: UserService,
        private localization: LocalizationService,
        private calculationService: CalculationServicePE,
        private numberService: NumberService,
    ) { }

    public get profileLocationImage() {
        switch (this.design.profileFamily?.shape) {
            case ProfileShapeType.I:
                return this.iProfileImage;

            case ProfileShapeType.SquareBar:
            case ProfileShapeType.Bar:
                return this.barProfileImage;

            case ProfileShapeType.T:
                return this.tProfileImage;

            default:
                throw new Error('Invalid profile shape');
        }
    }

    public get invalidStiffenerLocation() {
        const selectedProfileSide = this.allowedProfileSides.find(profileSide => profileSide.id == this.customProfileSideDropdown.selectedValue);
        return !selectedProfileSide?.allowMultipleStiffeners && this.design.customStiffeners.some(stiffener => stiffener.ProfileSide == selectedProfileSide?.id);
    }

    public get warningText() {
        const selectedProfileSide = this.allowedProfileSides.find(profileSide => profileSide.id == this.customProfileSideDropdown.selectedValue);
        const codeListDeps = getCodeListTextDeps(this.localization, this.numberService);
        return this.localization.getString('Agito.Hilti.Profis3.CustomStiffenerWizard.WrongPosition.Warning')
            .replace('{profileSide}', selectedProfileSide?.getTranslatedNameText(codeListDeps) ?? '');
    }

    private get allowedProfileSides() {
        const profileSides = this.design.designData.designCodeLists[DesignCodeList.StiffenerCustomProfileSides] as StiffenerCustomProfileSides[];
        return profileSides.filter(side => side.profileShapeType == this.design.profileFamily?.shape);
    }

    private get design() {
        return this.user.design;
    }

    ngOnInit(): void {
        this.iProfileImage = iProfileLocationImg;
        this.barProfileImage = barProfileLocationImg;
        this.tProfileImage = tProfileLocationImg;

        const dropdownText = this.localization.getString('Agito.Hilti.Profis3.CustomStiffenerWizard.Select').replace('{index}', this.unit.formatNumber(this.design.customStiffeners.length + 1));
        const allowedProfileSides = this.allowedProfileSides;

        this.customProfileSideDropdown = {
            id: 'select-custom-stiffeners-position-dropdown',
            title: dropdownText,
            items: allowedProfileSides.map(profileSide => ({
                value: profileSide.id,
                text: this.localization.getString(profileSide.nameResourceKey ?? '')
            }) as DropdownItem<number>),
            selectedValue: allowedProfileSides[0].id
        };
        this.onCustomProfileSideDropdownChange(this.customProfileSideDropdown.selectedValue ?? 0);
    }

    public onCustomProfileSideDropdownChange(value: number) {
        this.customProfileSideDropdown.selectedValue = value;
    }

    public async save() {
        const newCustomStiffener: CustomStiffenerEntity = {
            Name: '',
            HorizontalEdgeLength: 0,
            VerticalEdgeLength: 0,
            Width: 0,
            Position: 0,
            RadialOffset: 0,
            ProfileSide: this.customProfileSideDropdown.selectedValue ?? 0
        };

        await this.calculationService.calculateAsync(this.design,
            (design) => {
                design.customStiffeners.push(newCustomStiffener);
            }
        );

        this.close();
    }

    public close() {
        this.modalInstance.close();
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }
}
