<pe-modal-header [modalInstance]="modalInstance" [title]="translate(titleKey)" [enableClose]="false">
</pe-modal-header>

<pe-modal-body>
    <p [innerHtml]="translate(messageKey)"></p>

    <a class="url"
        [hidden]="infoUrl == null"
        [href]="infoUrl"
        href="#" target="_blank"
        [innerHtml]="translate(infoKey)">
    </a>
</pe-modal-body>

<pe-modal-footer>
    <div class="apply-to-all">
        <pe-checkbox-button
            [items]="applyToAllFeatures.items"
            [selectedValues]="applyToAllFeatures.selectedValues"
            (selectedValuesChange)="applyToAllFeatures.selectedValues = $any($event).detail">
        </pe-checkbox-button>
    </div>

    <pe-button class="modal-button"
        [id]="'default-calculation-method-cancel-button'"
        [look]="'Default'"
        (buttonClick)="no()"
        [text]="translate('Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.No')"
        [disabled]="pendingSave">
    </pe-button>

    <pe-button class="modal-button"
        [id]="'default-calculation-method-save-button'"
        [look]="'Cta'"
        (buttonClick)="yes()"
        [disabled]="pendingSave">
        <pe-loading-button
            [loading]="pendingSave"
            [text]="translate('Agito.Hilti.Profis3.Main.AdvancedBaseplateDesign.Yes')">
        </pe-loading-button>
    </pe-button>
</pe-modal-footer>
