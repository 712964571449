import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { DesignPe } from '../../../shared/entities/design-pe';
import {
    UtilizationMomentEntity, UtilizationValueEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    CalculationStatus, HandrailPostPosition
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    DesignType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { LocalizationService } from '../../services/localization.service';
import { NumberService } from '../../services/number.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { includeSprites } from '../../sprites';

interface IWorstResult {
    valueError: boolean;
    textError: boolean;
    image: string;
    textKey: string;
    value: number;
}

@Component({
    templateUrl: './anchor-plate-design.component.html',
    styleUrls: ['../utilizations/utilizations-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class AnchorPlateDesignComponent implements OnInit {
    @Input()
    public id = '';

    @Input()
    public moment?: UtilizationMomentEntity;

    @Input()
    public design?: DesignPe;

    public collapsed = false;

    public momentOpend = false;

    public CalculationStatusOK = CalculationStatus.OK;

    constructor(
        public localizationService: LocalizationService,
        private userSettings: UserSettingsService,
        private unitService: UnitService,
        private numberService: NumberService,
        private elementRef: ElementRef<HTMLElement>
    ) { }

    public get momentLength() {
        if (this.moment == null) {
            return 0;
        }

        const props = Object.getOwnPropertyNames(this.moment);
        let count = 0;
        props.forEach((propertyName) => {
            if (this.getUtilizationMomentPropertyValue(propertyName) != null
                && propertyName != 'Decisive'
                && propertyName != 'Thickness') {
                // [Thickness] is positioned outside of moment collapse region
                count++;
            }
        });

        return count;
    }

    public get momentDetails() {
        return this.moment != null &&
            this.moment.Bending != null;
    }

    public get momentWorst() {
        if (this.moment == null) {
            return null;
        }

        let worst: UtilizationValueEntity | null = null;
        let image: string | null = null;
        let textKey: string | null = null;

        if (this.moment.Bending != null) {
            worst = this.moment.Bending;
            image = '';
            textKey = 'Agito.Hilti.Profis3.AnchorPlateDesign.Moment.Bending';
        }

        if (worst == null) {
            return null;
        }

        return {
            image,
            value: worst.Value,
            textError: worst.TextError,
            valueError: worst.ValueError,
            textKey
        } as IWorstResult;
    }

    public get utilizationValue() {
        if (this.moment == null || this.moment.Decisive == null) {

            return undefined;
        }

        return this.moment.Decisive.Percentage;
    }

    public get utilizationInvalid() {
        return this.moment == null || this.moment.Decisive == null || this.moment.Decisive.ValueError || this.moment.Decisive.TextError;
    }

    public get postPositionText() {

        if (this.design?.designData?.reportData?.DecisiveHandrailPostPosition == null) {
            return '';
        }

        let key = '';
        switch (this.design?.designData?.reportData?.DecisiveHandrailPostPosition) {
            case HandrailPostPosition.Edge:
                key = 'Agito.Hilti.Profis3.HandrailPostPosition.Edge';
                break;
            case HandrailPostPosition.Middle:
                key = 'Agito.Hilti.Profis3.HandrailPostPosition.Middle';
                break;
            default:
                throw new Error('Unknown handrail post position');
        }

        return this.translate(key);
    }

    public get isHandrailPostPositionAvailable() {
        return this.design?.designData?.projectDesign?.ProjectDesignType == DesignType.Handrail && this.design?.designData?.reportData?.DecisiveHandrailPostPosition != null;
    }

    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-tab-base-plate',
        );
    }

    public image(image: string) {
        if (image == null || image == '') {
            return null;
        }

        return `sprite-${this.design?.designType.id == DesignType.Masonry ? 'masonry-' : ''}${image}`;
    }

    public transformValue(value?: number, unit?: string) {
        if (unit == 'Length') {
            const internalUnit = this.unitService.getInternalUnit(UnitGroup.Length);
            const defaultUnit = this.unitService.getDefaultUnit(UnitGroup.Length);

            return this.unitService.formatUnitValueArgs(this.unitService.convertUnitValueArgsToUnit(value ?? 0, internalUnit, defaultUnit), defaultUnit);
        }

        return this.numberService.format(value ?? 0, 0) + '%';
    }

    public onCollapsedChanged(collapsed: boolean) {
        this.collapsed = collapsed;
        this.userSettings.setSectionCollapsed(CollapsingControls.AnchorPlateDesign, collapsed);
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private getUtilizationMomentPropertyValue(property: string) {
        const moment = this.moment;
        if (moment == null) {
            return null;
        }

        type ObjectKey = keyof typeof moment;
        return moment[property as ObjectKey];
    }
}
