import {
    StaticLoadTypeEntity
} from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class StaticLoadType extends CodeList {

    public static fromService(codeList: StaticLoadTypeEntity) {
        return new StaticLoadType(CodeList.baseFromService('StaticLoadTypeEntity', codeList));
    }

    public override cloneDeep(): StaticLoadType {
        return super.cloneDeep() as StaticLoadType;
    }
}
