import { EnvironmentTypeEntity } from '../../generated-modules/Hilti.PE.Core.Entities.Baseplate.Codelists';
import { CodeList } from '../code-lists/code-list';

export class EnvironmentType extends CodeList {
    public static fromService(codeList: EnvironmentTypeEntity) {
        return new EnvironmentType(CodeList.baseFromService('EnvironmentTypeEntity', codeList));
    }

    public override cloneDeep(): EnvironmentType {
        return super.cloneDeep() as EnvironmentType;
    }
}
