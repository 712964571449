import { IControlProps } from '@profis-engineering/pe-ui-common/entities/main-menu/control-props';
import { IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { Button } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { AnchorFamily } from '../../../shared/entities/code-lists/anchor-family';
import { DesignPe } from '../../../shared/entities/design-pe';
import { UIProperty } from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { DesignMethodGroupHelper } from '../../../shared/helpers/design-method-group-helper';
import { isHnaBasedDesignStandard } from '../../../shared/helpers/design-standard-helper';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { LocalizationService } from '../../services/localization.service';

export function isAnchorFamilyMarkedAsNew(anchorFamilyId: number, design: DesignPe) {
    // Family should be marked as new if any anchor in family is marked as new.
    const allFamilies = design.designData.designCodeLists[design.globalMetaProperties.productCodeListProperty] as AnchorFamily[];
    const family = allFamilies.find(f => f.id == anchorFamilyId && f.detailed != null);
    return family?.detailed?.filteredFasteners?.some(typeId => isAnchorTypeMarkedAsNew(typeId, design));
}

export function isAnchorTypeMarkedAsNew(anchorTypeId: number, design: DesignPe) {
    return design.designData.anchorsMarkedAsNew?.includes(anchorTypeId);
}

export function updateMainMenuControl<TControl extends IControlProps>(menu: IMenu, id: string, control: Partial<TControl>): IMenu {
    return {
        ...menu,
        controls: {
            ...menu.controls,
            [id]: {
                ...menu.controls[id],
                ...control
            }
        }
    };
}

export function updateUiProperty(calculationService: CalculationServicePE, design: DesignPe, uiPropertyId: number, value: any) {
    if (uiPropertyId != null) {
        // Update model and run calculation
        calculationService.calculateAsync(design,
            (design) => {
                design.model[uiPropertyId] = value;
            }
        );
    }
}

export function getButtonDisplayText(design: DesignPe, navigationControl: Button<string>, localizationService: LocalizationService): string | undefined {
    const isViewApproval = [PropertyMetaData.AnchorLayout_ViewApproval.id].find(x => x == navigationControl.UIPropertyId);

    const displayUIProperty = navigationControl.DisplayUIPropertyId != null ? design.model[navigationControl.DisplayUIPropertyId] as any : null;
    let translationKeySuffix = '';
    if (isViewApproval) {
        if (isHnaBasedDesignStandard(design.designStandard.id)) {
            translationKeySuffix = '.HNA';
        }
        else if (design.isFatigueDesign && design.isConcreteTypeSFRC) {
            translationKeySuffix = '.SFRC';
        }
        else {
            translationKeySuffix = '.ETA';
        }
    }

    let displayText = navigationControl.DisplayKey != null && navigationControl.DisplayKey != '' ? localizationService.getString(navigationControl.DisplayKey + translationKeySuffix) : null;

    // For STO approval change translation when design method group is SP63 or SOFA_Based
    if (navigationControl.UIPropertyId == PropertyMetaData.AnchorLayout_ViewApproval_STO.id) {
        if (DesignMethodGroupHelper.isRussianSofaOrSp63(design.designStandard.id, design.designMethodGroup?.id as number)) {
            displayText = localizationService.getString('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionAnchor.ViewApproval.SP63');
        }
    }

    // Change translation according to the number of user selected anchor groups
    if (navigationControl.UIPropertyId == PropertyMetaData.SmartAnchor_AddMoreUserSelectedAnchors.id) {
        displayText = changeTranslationForAddMoreButton(design, localizationService);
    }

    return displayUIProperty ?? displayText;
}

function changeTranslationForAddMoreButton(design: DesignPe, localizationService: LocalizationService) {
    let displayText = '';
    const userSelectedAnchorGroupsArray = <any>design.model[UIProperty.SmartAnchor_UserSelectedAnchorGroupsForAsad];
    const maxUserSelectedAnchorLimit = 3;
    if (userSelectedAnchorGroupsArray?.length > 0 && userSelectedAnchorGroupsArray?.length < maxUserSelectedAnchorLimit) {
        const displayKey = localizationService.getString('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.SuggestedAnchorsRegion.AddMoreUserSelectedAnchors');
        switch (userSelectedAnchorGroupsArray?.length) {
            case 1:
                displayText = displayKey
                    .replace('{remainingAnchorCount}', (maxUserSelectedAnchorLimit - userSelectedAnchorGroupsArray.length).toString())
                    .replace('{anchorKey}', localizationService.getString('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.SuggestedAnchorsRegion.AddMoreUserSelectedAnchors.Anchors'));
                break;
            case 2:
                displayText = displayKey
                    .replace('{remainingAnchorCount}', (maxUserSelectedAnchorLimit - userSelectedAnchorGroupsArray.length).toString())
                    .replace('{anchorKey}', localizationService.getString('Agito.Hilti.Profis3.Navigation.TabSmartAnchor.SuggestedAnchorsRegion.AddMoreUserSelectedAnchors.Anchor'));
                break;
        }
    }
    return displayText;
}
